import * as React from 'react';
import { ConsentModal as ConsentModalComponent } from '@appsource/components/modals/consentModal';
import {
  createInstructionsModalAction,
  createDriveModalAction,
  createUserSignOutAction,
  createUpdateUserProfileLicenseAction,
} from '@shared/actions/actions';
import {
  getAppDetail,
  getUserAuthStatus,
  updatePurchaseStatus,
  sendLeadInfo,
} from '@shared/actions/thunkActions';
import { IAppDataItem, ILicense, SendLeadInfoOptions } from '@shared/Models';
import { IState } from '@src/State';
import { Constants } from '@shared/utils/constants';
import { getTelemetryDetailContentCurrentView } from '@shared/utils/appUtils';
import { connect } from 'react-redux';
import { withRouter } from '@shared/routerHistory';

export const mapStateToProps = (state: IState, ownProps: any) => {
  return {
    userInfo: state.users,
    userProfile: state.users.profile,
    isNationalCloud: state.config.nationalCloud,
    billingCountryCode: state.config.billingCountryCode,
    flightCodes: state.config.flightCodes,
    featureFlags: state.config.featureFlags,
    query: ownProps?.query,
    embedHost: state.config.embedHost,
    currentView: getTelemetryDetailContentCurrentView(state.config.currentView, state.config.currentGalleryViewIsCurated),
    modal: state.modal,
    isLoadingUserProfile: state.users.isLoadingUserProfile,
  };
};

export const mapDispatchToProps = (dispatch: any) => {
  return {
    updatePurchaseStatus: () => dispatch(updatePurchaseStatus()),
    openInstructionsModal: () =>
      dispatch(
        createInstructionsModalAction({
          showModal: true,
          isFromDownload: true,
        })
      ),
    openDriveModal: (driveUrl: string) =>
      dispatch(
        createDriveModalAction({
          showModal: true,
          driveUrl,
        })
      ),
    fetchAppDetails: (targetApp: IAppDataItem) => dispatch(getAppDetail(targetApp.entityId, false)),
    sendLeadInfo: ({ item, ctaType, notes, planId }: SendLeadInfoOptions) =>
      dispatch(sendLeadInfo({ item, ctaType, notes, planId })),
    getUserAuthStatus: () => dispatch(getUserAuthStatus()),
    signOutUser: () => dispatch(createUserSignOutAction(null)),
    updateLicense: (licenseType: number, license: ILicense) =>
      dispatch(createUpdateUserProfileLicenseAction({ licenseType, license })),
  };
};

export const ConsentModal = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConsentModalComponent) as React.StatelessComponent<any>
);
