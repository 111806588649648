import { IURLQuery, ITileDataApps, ITileDataServices, IDataItem } from '@shared/Models';
import { ActionType, actionCreator } from '@shared/actions/actions';
import { Constants } from '@shared/utils/constants';
import { IEntityDataState } from '@src/State';

export const TileDataLoadingAction: ActionType<{
  urlQuery: IURLQuery;
}> = 'TileDataLoadingAction';
export const createTileDataLoadingAction = actionCreator(TileDataLoadingAction);

export const TileDataReceivedAction: ActionType<{
  entityType: Constants.EntityType;
  entityData: IEntityDataState<IDataItem> | ITileDataApps | ITileDataServices;
  urlQuery: IURLQuery;
  requestId: string;
}> = 'TileDataReceivedAction';
export const createTileDataReceivedAction = actionCreator(TileDataReceivedAction);
