import React from 'react';
import * as PropTypes from 'prop-types';
import { ISimpleSKU } from '@shared/Models';
import { Stack, Text, mergeStyleSets, PrimaryButton } from '@fluentui/react';
import type { IStyle } from '@fluentui/react';
import { FreeTrialBadge, PrivatePlanBadge } from '@shared/components';
import classNames from 'classnames';
import { NeutralColors } from '@fluentui/theme';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';

interface Props {
  sku: ISimpleSKU;
  offerHasPrivatePlans: boolean;
  offerHasFreeTrialPlans: boolean;
  onGetInNowClicked?(): void;
}

interface BadgeItemProps {
  sku: ISimpleSKU;
}

interface BadgesProps {
  sku: ISimpleSKU;
  offerHasPrivatePlans: boolean;
  offerHasFreeTrialPlans: boolean;
}

interface Styles {
  planNameText: IStyle;
  hidden: IStyle;
  getItNowButton: IStyle;
}

const classes = mergeStyleSets<Styles>({
  planNameText: {
    minWidth: 144,
    maxWidth: 296,
    whiteSpace: 'break-spaces',
    fontWeight: 600,
    color: NeutralColors.gray160,
  },
  hidden: {
    visibility: 'hidden', // The element is hidden BUT is in the dom and takes up space and affects the layout(which is what we want)
  },
  getItNowButton: {
    marginTop: 16,
  },
});

const FreeTrialBadgeItem = ({ sku }: BadgeItemProps) => {
  return (
    <Stack.Item className={classNames({ [classes.hidden]: !sku.freeTrialDurationDays })}>
      <FreeTrialBadge />
    </Stack.Item>
  );
};

const PrivateBadgeItem = ({ sku }: BadgeItemProps) => {
  return (
    <Stack.Item className={classNames({ [classes.hidden]: !sku.isPrivate })}>
      <PrivatePlanBadge />
    </Stack.Item>
  );
};

const Badges = ({ sku, offerHasFreeTrialPlans, offerHasPrivatePlans }: BadgesProps) => {
  if (!offerHasFreeTrialPlans && !offerHasPrivatePlans) {
    return null;
  }

  return (
    <Stack horizontal tokens={{ childrenGap: 8 }}>
      {offerHasFreeTrialPlans && <FreeTrialBadgeItem sku={sku} />}
      {offerHasPrivatePlans && <PrivateBadgeItem sku={sku} />}
    </Stack>
  );
};

export const SaasPlanNameCell = (
  { offerHasPrivatePlans, offerHasFreeTrialPlans, sku, onGetInNowClicked }: Props,
  context: ILocContext & ILocParamsContext
) => {
  return (
    <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 16 }}>
      <Stack.Item>
        <Text className={classes.planNameText} block>
          {sku.title}
        </Text>
        <PrimaryButton
          className={classes.getItNowButton}
          text={context.loc('CTA_Get', 'Get it now')}
          onClick={onGetInNowClicked}
        />
      </Stack.Item>
      <Badges sku={sku} offerHasFreeTrialPlans={offerHasFreeTrialPlans} offerHasPrivatePlans={offerHasPrivatePlans} />
    </Stack>
  );
};

(SaasPlanNameCell as any).contextTypes = {
  locParams: PropTypes.func,
  loc: PropTypes.func,
};
