import React, { useEffect, useState } from 'react';
import { getLocaleDate } from '@shared/utils/appUtils';
import * as PropTypes from 'prop-types';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { mergeStyleSets, Text, Persona, PersonaSize, Stack } from '@fluentui/react';
import type { IStackTokens } from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import { AppReviewItemCommentComposer } from '@shared/components/appReviewItemCommentComposer';
import { Constants } from '@shared/utils/constants';
import { launchTelemetry } from '@shared/utils/reviewsUtils';
import { IAppReviewComment } from '@shared/interfaces/reviews/comments';
import { logger } from '@src/logger';
import { AppReviewCommentItemToolbar } from '@shared/components/appReviewCommentItemToolbar';

export interface IAppReviewCommentItemProps {
  comment: IAppReviewComment;
  locale: string;
  onDelete: () => void;
  onEdit: (updatedContent: string) => void;
}

interface AppReviewCommentItemAuthorNameProps {
  comment: IAppReviewComment;
}

interface AppReviewCommentItemContentProps {
  comment: IAppReviewComment;
}

interface AppReviewCommentItemPostTimeProps {
  comment: IAppReviewComment;
  locale: string;
}

interface AppReviewCommentItemEditedLabelProps {
  context: ILocContext;
}

const appReviewCommentItemStyles = mergeStyleSets({
  commentItem: {
    marginTop: '24px',
  },
  commentAuthorName: {
    color: NeutralColors.gray130,
    fontWeight: 600,
  },
  deletedItemText: {
    color: '#4f4f4f',
    fontStyle: 'italic',
  },
  commentAuthorInfoBar: {
    marginBottom: '10px',
    minHeight: '35px',
  },
  editedLabel: {
    color: NeutralColors.gray120,
  },
  postTime: {
    color: NeutralColors.gray130,
    overflow: 'hidden',
  },
  smallScreenToolbar: ['ms-hiddenLgUp', { marginTop: 10, marginBottom: 10 }],
  mediumScreenToolbar: ['ms-hiddenMdDown', { margin: '0 4px' }],
  personaDetails: {
    paddingRight: 0,
  },
});

const commentAuthorInfoBarStackTokenDef: IStackTokens = { childrenGap: 4 };
export function AppReviewCommentItemContent({ comment }: AppReviewCommentItemContentProps) {
  return <Text>{comment.content}</Text>;
}
export function AppReviewCommentItemEditedLabel({ context }: AppReviewCommentItemEditedLabelProps) {
  return (
    <Text className={appReviewCommentItemStyles.editedLabel}>
      {context.loc('ReviewItem_CommentSection_Comment_Updated_Label', 'Edited')}
    </Text>
  );
}

(AppReviewCommentItemEditedLabel as any).contextTypes = {
  loc: PropTypes.func,
};

export function AppReviewCommentItemPostTime({ comment, locale }: AppReviewCommentItemPostTimeProps, context: ILocParamsContext) {
  return (
    <div className={appReviewCommentItemStyles.postTime}>
      <Text nowrap block className="ms-hiddenMdDown">
        {context.locParams(
          'ReviewItem_CommentSection_Comment_Posted_Time_Label_Long',
          [getLocaleDate(comment.updatedAt, locale)],
          'replied on {0}'
        )}
      </Text>
      <Text nowrap block className="ms-hiddenLgUp">
        {context.locParams(
          'ReviewItem_CommentSection_Comment_Posted_Time_Label_Short',
          [getLocaleDate(comment.updatedAt, locale)],
          '- {0}'
        )}
      </Text>
    </div>
  );
}

(AppReviewCommentItemPostTime as any).contextTypes = {
  locParams: PropTypes.func,
};

export function AppReviewCommentItemAuthorName({ comment }: AppReviewCommentItemAuthorNameProps) {
  return (
    <Persona
      onRenderPrimaryText={() => (
        <Text className={appReviewCommentItemStyles.commentAuthorName}>{comment.customerInfo?.name}</Text>
      )}
      text={comment.customerInfo?.name}
      size={PersonaSize.size24}
      styles={{ details: appReviewCommentItemStyles.personaDetails }}
    />
  );
}

export const AppReviewCommentItem: React.FunctionComponent<IAppReviewCommentItemProps> = (
  { comment, locale, onDelete, onEdit }: IAppReviewCommentItemProps,
  context: ILocParamsContext & ILocContext
) => {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedContent, setUpdatedContent] = useState(comment.content);

  const toggleEdit = () => {
    setIsEditing((prevState) => !prevState);
  };

  const onDeleteClick = () => {
    launchTelemetry(Constants.Telemetry.Action.Click, Constants.Telemetry.ActionModifier.ReviewItemCommentDeleteButton, {
      commentId: comment.id,
      reviewId: comment.reviewId,
    });
    logger.info(
      JSON.stringify({
        commentId: comment.id,
        reviewId: comment.reviewId,
      }),
      {
        action: Constants.Telemetry.Action.Click,
        actionModifier: Constants.Telemetry.ActionModifier.ReviewItemCommentDeleteButton,
      }
    );
    onDelete();
  };

  const onEditClick = () => {
    launchTelemetry(Constants.Telemetry.Action.Click, Constants.Telemetry.ActionModifier.ReviewItemCommentEditButton, {
      commentId: comment?.id,
      reviewId: comment?.reviewId,
    });
    logger.info(
      JSON.stringify({
        commentId: comment?.id,
        reviewId: comment?.reviewId,
      }),
      {
        action: Constants.Telemetry.Action.Click,
        actionModifier: Constants.Telemetry.ActionModifier.ReviewItemCommentEditButton,
      }
    );
    toggleEdit();
  };

  useEffect(() => {
    setIsEditing(false);
  }, [comment]);

  return (
    <div className={appReviewCommentItemStyles.commentItem} tabIndex={0}>
      {!comment?.isDeleted ? (
        <div>
          <Stack
            tokens={commentAuthorInfoBarStackTokenDef}
            horizontal
            verticalAlign="center"
            className={appReviewCommentItemStyles.commentAuthorInfoBar}
          >
            <Stack.Item disableShrink>
              <AppReviewCommentItemAuthorName comment={comment} />
            </Stack.Item>
            <AppReviewCommentItemPostTime comment={comment} locale={locale} />
            {comment.isOwner && (
              <AppReviewCommentItemToolbar
                className={appReviewCommentItemStyles.mediumScreenToolbar}
                onDeleteToggle={onDeleteClick}
                onEditToggle={onEditClick}
                isEditing={isEditing}
              />
            )}
            {comment.createdAt !== comment.updatedAt && <AppReviewCommentItemEditedLabel context={context} />}
          </Stack>
          {!isEditing && <AppReviewCommentItemContent comment={comment} />}
          {comment.isOwner && !isEditing && (
            <AppReviewCommentItemToolbar
              className={appReviewCommentItemStyles.smallScreenToolbar}
              onDeleteToggle={onDeleteClick}
              onEditToggle={onEditClick}
              isEditing={isEditing}
            />
          )}
          {isEditing && (
            <AppReviewItemCommentComposer
              initialContent={updatedContent}
              editMode
              onCloseEdit={toggleEdit}
              onChange={(content) => setUpdatedContent(content)}
              onSubmit={() => onEdit(updatedContent)}
              onDeleteToggle={onDeleteClick}
              onEditToggle={onEditClick}
            />
          )}
        </div>
      ) : (
        <div>
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
            <Persona
              size={PersonaSize.size24}
              initialsColor="#919191"
              onRenderPrimaryText={() => (
                <Text className={appReviewCommentItemStyles.deletedItemText}>
                  {context.loc('ReviewItem_CommentSection_Comment_Deleted_Label', 'This comment was deleted.')}
                </Text>
              )}
              styles={{ details: appReviewCommentItemStyles.personaDetails }}
            />
          </Stack>
        </div>
      )}
    </div>
  );
};

(AppReviewCommentItem as any).contextTypes = {
  locParams: PropTypes.func,
  loc: PropTypes.func,
};
