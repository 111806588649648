import React from 'react';
import { getTotalExternalRatingSummariesInfo, getRatingSummaryByType } from '@shared/utils/reviewsUtils';
import * as PropTypes from 'prop-types';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { Text, mergeStyleSets } from '@fluentui/react';
import { IAppDataItem } from '@shared/Models';
import { NeutralColors } from '@fluentui/theme';
import { Constants } from '@shared/utils/constants';
import { RatingsInfo } from '@shared/components/ratings/ratingsInfo';

interface IAppDetailsHeaderRatingBarProps {
  app: IAppDataItem;
}

const contentStyles = mergeStyleSets({
  separator: {
    color: NeutralColors.gray90,
    margin: '0px 12px',
    fontSize: 18,
  },
});

enum HeaderRatingBarInfoKey {
  Internal = 'internal',
  External = 'external',
  All = 'all',
}

export const AppDetailsHeaderRatingBar: React.FunctionComponent<IAppDetailsHeaderRatingBarProps> = (
  { app }: IAppDetailsHeaderRatingBarProps,
  context: ILocContext & ILocParamsContext
) => {
  const allRatingSummaryObj = getRatingSummaryByType(app?.ratingSummaries, Constants.Reviews.ReviewsSource.All);
  const avgRating = allRatingSummaryObj?.AverageRating || app.AverageRating || 0;
  const ratingsCount = allRatingSummaryObj?.TotalRatings || app.NumberOfRatings || 0;

  const externalSummariesInfo = getTotalExternalRatingSummariesInfo(app.ratingSummaries);

  if (ratingsCount <= 0 && externalSummariesInfo.totalRatings <= 0) {
    return null;
  }
  return (
    <div itemProp="itemReviewed" itemScope itemType={Constants.Reviews.Microdata.AppItemType}>
      <meta itemProp="name" content={app.title} />
      <meta itemProp="applicationCategory" content={Constants.Reviews.Microdata.AppCategory} />
      <div className="appRatingCell" itemProp="aggregateRating" itemScope itemType="https://schema.org/AggregateRating">
        <div>
          <div className="detailsRating">
            {allRatingSummaryObj ? (
              <RatingsInfo
                avgRating={avgRating}
                ratingsCount={ratingsCount}
                key={HeaderRatingBarInfoKey.All}
                title={context.loc('Global_Loc_ratingsLower', 'ratings')}
                context={context}
              />
            ) : (
              <>
                <RatingsInfo
                  avgRating={avgRating}
                  ratingsCount={ratingsCount}
                  key={HeaderRatingBarInfoKey.Internal}
                  title={context.loc('RatingSummary_Title', 'Marketplace ratings')}
                  context={context}
                />
                {externalSummariesInfo.totalRatings > 0 && <Text className={contentStyles.separator}>|</Text>}
                {externalSummariesInfo.totalRatings > 0 && (
                  <RatingsInfo
                    avgRating={externalSummariesInfo.averageRatings}
                    ratingsCount={externalSummariesInfo.totalRatings}
                    key={HeaderRatingBarInfoKey.External}
                    title={context.loc('appDetails_ExternalRatings', 'external ratings')}
                    context={context}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <meta itemProp="reviewCount" content={ratingsCount.toString()} />
        <meta itemProp="ratingValue" content={parseFloat(avgRating.toString()).toFixed(2)} />
      </div>
    </div>
  );
};

(AppDetailsHeaderRatingBar as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
};
