import { logger } from '@src/logger';
import { ClientLogsCollection } from '@shared/logger';

interface IFeedbackPayload {
  action: string;
  actionModifier: string;
  details: string;
}

export function storeFeedback({ action, actionModifier, details }: IFeedbackPayload) {
  logger.clientLogger.info({ collection: ClientLogsCollection.Feedback, action, actionModifier, details });
}
