import { Stack } from '@fluentui/react';
import { IAppReviewQAContent } from '@shared/Models';
import * as React from 'react';

export interface IReviewQAContent {
  QAs: IAppReviewQAContent[];
}

export const ReviewQAContent = ({ QAs = [] }: IReviewQAContent) => {
  return (
    <Stack tokens={{ childrenGap: 14, padding: 1 }}>
      {QAs?.map((reviewQA: IAppReviewQAContent, index: number) => (
        <div className="reviewQAContent" key={`reviewQAContent-${index}`}>
          <div className="reviewContentQuestion">{reviewQA.question}</div>
          <div className="reviewContentAnswer">{reviewQA.answer}</div>
        </div>
      ))}
    </Stack>
  );
};
