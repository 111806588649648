/* tslint:disable: quotemark max-line-length */

import { hasUserAgreedPrivacyConsent } from './browserStorageUtils';

export interface ICookieBannerObj {
  Market: string;
  Message: string;
  MoreInfo: string;
  MoreInfoAriaLabel: string;
  MoreInfoUrl: string;
}

/**
 * check whether page should render cookie banner (top)
 *
 * @returns true if any of following apllies:
 *  1) user has not agreed to privacy consent
 *  2) agreed privacy consent has expired
 */
export function shouldShowCookieBanner() {
  return false;
}

// Hack for the missing Region codes.
// Once we get the translations for these regions, we can remove this hack.
function handleMissingLocales(locale: string) {
  let newLocaleCode = '';
  let lowerCaseLocale = locale ? locale.toLowerCase() : 'en';

  // the special cases:
  if (lowerCaseLocale === 'sr-cyrl' || lowerCaseLocale === 'sr-latn') {
    newLocaleCode = 'sr';
  } else if (lowerCaseLocale === 'sl-si') {
    newLocaleCode = 'sl';
  } else if (lowerCaseLocale === 'ca-es' || lowerCaseLocale === 'eu-es' || lowerCaseLocale === 'gl-es') {
    newLocaleCode = 'es';
  } else {
    // default case
    newLocaleCode = lowerCaseLocale;
  }

  return newLocaleCode;
}

// To Investigate: Why is this hard coded?
// List of consent messages in differnt locales
export const ConsentMessage: ICookieBannerObj[] = [
  {
    Market: 'en',
    Message:
      'This site uses cookies for analytics, personalized content and ads. By continuing to browse this site, you agree to this use.',
    MoreInfo: 'Learn more',
    MoreInfoAriaLabel: 'Learn more about Microsoft’s Cookie Policy',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'ar',
    Message:
      'يستخدم هذا الموقع ملفات تعريف الارتباط للتحليلات، والمحتوى المخصص والإعلانات. ومن خلال متابعة استعراض هذا الموقع، فأنت توافق على ذلك الاستخدام.',
    MoreInfo: 'معرفة المزيد',
    MoreInfoAriaLabel: 'معرفة المزيد حول سياسة ملفات تعريف ارتباط Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'ar-SA',
    Message:
      'يستخدم هذا الموقع ملفات تعريف الارتباط للتحليلات، والمحتوى المخصص والإعلانات. ومن خلال متابعة استعراض هذا الموقع، فأنت توافق على ذلك الاستخدام.',
    MoreInfo: 'معرفة المزيد',
    MoreInfoAriaLabel: 'معرفة المزيد حول سياسة ملفات تعريف ارتباط Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'bg',
    Message:
      'Този сайт използва бисквитки за аналитични цели, персонализирано съдържание и реклами. Ако продължите да го разглеждате, вие се съгласявате с използването на бисквитки.',
    MoreInfo: 'Научете повече',
    MoreInfoAriaLabel: 'Научете повече за политиката за бисквитките на Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'bg-BG',
    Message:
      'Този сайт използва бисквитки за аналитични цели, персонализирано съдържание и реклами. Ако продължите да го разглеждате, вие се съгласявате с използването на бисквитки.',
    MoreInfo: 'Научете повече',
    MoreInfoAriaLabel: 'Научете повече за политиката за бисквитките на Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'bs',
    Message:
      'Ova stranica koristi kolačiće za analizu, personalizovane sadržaje i reklame. Pretraživanjem ove stranice, prihvatate ovu upotrebu.',
    MoreInfo: 'Saznajte više',
    MoreInfoAriaLabel: 'Saznajte više o Microsoftovim pravilima o kolačićima',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'bs-BA',
    Message:
      'Ova stranica koristi kolačiće za analizu, personalizovane sadržaje i reklame. Pretraživanjem ove stranice, prihvatate ovu upotrebu.',
    MoreInfo: 'Saznajte više',
    MoreInfoAriaLabel: 'Saznajte više o Microsoftovim pravilima o kolačićima',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'cs',
    Message:
      'Tento web využívá soubory cookie pro analýzu, přizpůsobený obsah a reklamy. Pokračováním na tento web souhlasíte s jejich používáním.',
    MoreInfo: 'Další informace',
    MoreInfoAriaLabel: 'Další informace o zásadách Microsoftu pro soubory cookie',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'cs-CZ',
    Message:
      'Tento web využívá soubory cookie pro analýzu, přizpůsobený obsah a reklamy. Pokračováním na tento web souhlasíte s jejich používáním.',
    MoreInfo: 'Další informace',
    MoreInfoAriaLabel: 'Další informace o zásadách Microsoftu pro soubory cookie',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'da',
    Message:
      'Dette websted bruger cookies til analyse, personligt tilpasset indhold og annoncer. Hvis du fortsætter med at bruge dette websted, accepterer du denne brug.',
    MoreInfo: 'Lær mere',
    MoreInfoAriaLabel: 'Lær mere om Microsofts cookiepolitik',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'da-DK',
    Message:
      'Dette websted bruger cookies til analyse, personligt tilpasset indhold og annoncer. Hvis du fortsætter med at bruge dette websted, accepterer du denne brug.',
    MoreInfo: 'Lær mere',
    MoreInfoAriaLabel: 'Lær mere om Microsofts cookiepolitik',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'de',
    Message:
      'Diese Website verwendet Cookies für Analysen, personalisierte Inhalte und interessenbezogene Anzeigen. Indem Sie diese Website weiter nutzen, erklären Sie sich mit dieser Verwendung einverstanden.',
    MoreInfo: 'Weitere Informationen',
    MoreInfoAriaLabel: 'Weitere Informationen über die Microsoft Cookie-Richtlinie',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'de-DE',
    Message:
      'Diese Website verwendet Cookies für Analysen, personalisierte Inhalte und interessenbezogene Anzeigen. Indem Sie diese Website weiter nutzen, erklären Sie sich mit dieser Verwendung einverstanden.',
    MoreInfo: 'Weitere Informationen',
    MoreInfoAriaLabel: 'Weitere Informationen über die Microsoft Cookie-Richtlinie',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'el',
    Message:
      'Αυτή η τοποθεσία χρησιμοποιεί cookies για την ανάλυση πληροφοριών, εξατομικευμένο περιεχόμενο και διαφημίσεις. Συνεχίζοντας την περιήγηση σε αυτήν την τοποθεσία, αποδέχεστε αυτήν τη χρήση.',
    MoreInfo: 'Μάθετε περισσότερα',
    MoreInfoAriaLabel: 'Μάθετε περισσότερα σχετικά με την πολιτική της Microsoft για τα cookies',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'el-GR',
    Message:
      'Αυτή η τοποθεσία χρησιμοποιεί cookies για την ανάλυση πληροφοριών, εξατομικευμένο περιεχόμενο και διαφημίσεις. Συνεχίζοντας την περιήγηση σε αυτήν την τοποθεσία, αποδέχεστε αυτήν τη χρήση.',
    MoreInfo: 'Μάθετε περισσότερα',
    MoreInfoAriaLabel: 'Μάθετε περισσότερα σχετικά με την πολιτική της Microsoft για τα cookies',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'en-GB',
    Message:
      'This site uses cookies for analytics, personalized content and ads. By continuing to browse this site, you agree to this use.',
    MoreInfo: 'Learn more',
    MoreInfoAriaLabel: 'Learn more about Microsoft’s Cookie Policy',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'es',
    Message:
      'Este sitio utiliza cookies para análisis y para mostrar contenido y anuncios personalizados. Al continuar navegando por este sitio, aceptas este uso.',
    MoreInfo: 'Más información',
    MoreInfoAriaLabel: 'Más información sobre la política de cookies de Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'es-ES',
    Message:
      'Este sitio utiliza cookies para análisis y para mostrar contenido y anuncios personalizados. Al continuar navegando por este sitio, aceptas este uso.',
    MoreInfo: 'Más información',
    MoreInfoAriaLabel: 'Más información sobre la política de cookies de Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'es-MX',
    Message:
      'Este sitio utiliza cookies para análisis, contenido personalizado y anuncios. Al continuar explorando este sitio, acepta este uso.',
    MoreInfo: 'Obtener más información',
    MoreInfoAriaLabel: 'Obtener más información sobre la política de cookies de Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'et',
    Message:
      'See sait kasutab küpsiseid analüüsiks, isikupärastatud sisuks ja reklaamiks. Nõustute selle kasutamisega, kui jätkate saidi vaatamist.',
    MoreInfo: 'Lisateave',
    MoreInfoAriaLabel: 'Lisateave Microsofti küpsistepoliitika kohta',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'et-EE',
    Message:
      'See sait kasutab küpsiseid analüüsiks, isikupärastatud sisuks ja reklaamiks. Nõustute selle kasutamisega, kui jätkate saidi vaatamist.',
    MoreInfo: 'Lisateave',
    MoreInfoAriaLabel: 'Lisateave Microsofti küpsistepoliitika kohta',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'fi',
    Message:
      'Tämä sivusto käyttää evästeitä analytiikkaan sekä mukautetun sisällön ja mainosten näyttämiseen. Jatkamalla sivuston käyttöä hyväksyt tällaisen käytön.',
    MoreInfo: 'Lisätietoja',
    MoreInfoAriaLabel: 'Lisätietoja Microsoftin evästekäytännöstä',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'fi-FI',
    Message:
      'Tämä sivusto käyttää evästeitä analytiikkaan sekä mukautetun sisällön ja mainosten näyttämiseen. Jatkamalla sivuston käyttöä hyväksyt tällaisen käytön.',
    MoreInfo: 'Lisätietoja',
    MoreInfoAriaLabel: 'Lisätietoja Microsoftin evästekäytännöstä',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'fr-CA',
    Message:
      'Ce site utilise des témoins pour les analyses, le contenu personnalisé et les publicités. En continuant à naviguer sur ce site, vous acceptez cette utilisation.',
    MoreInfo: 'Détails',
    MoreInfoAriaLabel: 'En savoir plus sur la politique en matière de témoins de Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'fr',
    Message:
      "Ce site utilise des cookies pour l'analyse, ainsi pour que les contenus et les publicités personnalisés. En continuant à naviguer sur ce site, vous acceptez cette utilisation.",
    MoreInfo: 'En savoir plus',
    MoreInfoAriaLabel: 'En savoir plus sur la politique relative aux cookies de Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'fr-FR',
    Message:
      "Ce site utilise des cookies pour l'analyse, ainsi pour que les contenus et les publicités personnalisés. En continuant à naviguer sur ce site, vous acceptez cette utilisation.",
    MoreInfo: 'En savoir plus',
    MoreInfoAriaLabel: 'En savoir plus sur la politique relative aux cookies de Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'he',
    Message: 'אתר זה משתמש בקובצי Cookie לצורך ניתוח, תוכן מותאם אישית ומודעות. על ידי המשך הגלישה באתר אתה מסכים לשימוש זה.',
    MoreInfo: 'מידע נוסף',
    MoreInfoAriaLabel: 'למד עוד על המדיניות של Microsoft בנושא קובצי Cookie',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'he-IL',
    Message: 'אתר זה משתמש בקובצי Cookie לצורך ניתוח, תוכן מותאם אישית ומודעות. על ידי המשך הגלישה באתר אתה מסכים לשימוש זה.',
    MoreInfo: 'מידע נוסף',
    MoreInfoAriaLabel: 'למד עוד על המדיניות של Microsoft בנושא קובצי Cookie',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'hi',
    Message:
      'यह साइट विश्लेषण, वैयक्तिकृत सामग्री और विज्ञापनों के लिए कुकीज़ का उपयोग करती है. इस साइट को ब्राउज़ करना जारी रखकर आप इस उपयोग पर अपनी सहमति जताते हैं.',
    MoreInfo: 'अधिक जानें',
    MoreInfoAriaLabel: 'Microsoft की कुकी नीति के बारे में अधिक जानें',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'hi-IN',
    Message:
      'यह साइट विश्लेषण, वैयक्तिकृत सामग्री और विज्ञापनों के लिए कुकीज़ का उपयोग करती है. इस साइट को ब्राउज़ करना जारी रखकर आप इस उपयोग पर अपनी सहमति जताते हैं.',
    MoreInfo: 'अधिक जानें',
    MoreInfoAriaLabel: 'Microsoft की कुकी नीति के बारे में अधिक जानें',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'hr',
    Message:
      'Ovo web-mjesto koristi kolačiće za analizu, prilagođeni sadržaj i reklame. Pretraživanjem ovog web-mjesta pristajete na ovu uporabu.',
    MoreInfo: 'Saznajte više',
    MoreInfoAriaLabel: 'Saznajte više o Pravilima uporabe kolačića Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'hr-HR',
    Message:
      'Ovo web-mjesto koristi kolačiće za analizu, prilagođeni sadržaj i reklame. Pretraživanjem ovog web-mjesta pristajete na ovu uporabu.',
    MoreInfo: 'Saznajte više',
    MoreInfoAriaLabel: 'Saznajte više o Pravilima uporabe kolačića Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'hu',
    Message:
      'Ez a weboldal cookie-kat használ az elemzésekhez, valamint a testreszabott tartalmak és hirdetések megjelenítéséhez. Az oldal használatának folytatásával elfogadja ezek alkalmazását.',
    MoreInfo: 'További információ',
    MoreInfoAriaLabel: 'További információk a Microsoft cookie-kra vonatkozó irányelvéről',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'hu-HU',
    Message:
      'Ez a weboldal cookie-kat használ az elemzésekhez, valamint a testreszabott tartalmak és hirdetések megjelenítéséhez. Az oldal használatának folytatásával elfogadja ezek alkalmazását.',
    MoreInfo: 'További információ',
    MoreInfoAriaLabel: 'További információk a Microsoft cookie-kra vonatkozó irányelvéről',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'id',
    Message:
      'Situs ini menggunakan cookie untuk analisis, konten dan iklan yang disesuaikan. Dengan terus menelusuri situs ini, Anda menyetujui penggunaannya.',
    MoreInfo: 'Pelajari selengkapnya',
    MoreInfoAriaLabel: 'Pelajari lebih lanjut tentang kebijakan Cookie Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'id-ID',
    Message:
      'Situs ini menggunakan cookie untuk analisis, konten dan iklan yang disesuaikan. Dengan terus menelusuri situs ini, Anda menyetujui penggunaannya.',
    MoreInfo: 'Pelajari selengkapnya',
    MoreInfoAriaLabel: 'Pelajari lebih lanjut tentang kebijakan Cookie Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'it',
    Message:
      'Questo sito utilizza cookie per analitica, contenuti personalizzati e pubblicità. Continuando a navigare questo sito, accetti tale utilizzo.',
    MoreInfo: 'Scopri di più',
    MoreInfoAriaLabel: 'Ulteriori informazioni sulla politica dei cookie di Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'it-IT',
    Message:
      'Questo sito utilizza cookie per analitica, contenuti personalizzati e pubblicità. Continuando a navigare questo sito, accetti tale utilizzo.',
    MoreInfo: 'Scopri di più',
    MoreInfoAriaLabel: 'Ulteriori informazioni sulla politica dei cookie di Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'ja',
    Message:
      'このサイトでは、分析、カスタマイズされたコンテンツ、および広告に Cookie を使用します。このサイトを引き続き閲覧すると、Cookie の使用に同意するものと見なされます。',
    MoreInfo: '詳細情報',
    MoreInfoAriaLabel: 'Microsoft の Cookie ポリシーに関する詳細について',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'ja-JP',
    Message:
      'このサイトでは、分析、カスタマイズされたコンテンツ、および広告に Cookie を使用します。このサイトを引き続き閲覧すると、Cookie の使用に同意するものと見なされます。',
    MoreInfo: '詳細情報',
    MoreInfoAriaLabel: 'Microsoft の Cookie ポリシーに関する詳細について',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'kk',
    Message:
      'Бұл сайт талдау, жекелендірілген мазмұн және жарнама үшін куки файлдарын пайдаланады. Бұл сайтты шолуды жалғастыру арқылы оны пайдалануға келісесіз.',
    MoreInfo: 'Қосымша ақпарат',
    MoreInfoAriaLabel: 'Microsoft корпорациясының куки файлдарына қатысты саясаты туралы қосымша ақпарат',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'kk-KZ',
    Message:
      'Бұл сайт талдау, жекелендірілген мазмұн және жарнама үшін куки файлдарын пайдаланады. Бұл сайтты шолуды жалғастыру арқылы оны пайдалануға келісесіз.',
    MoreInfo: 'Қосымша ақпарат',
    MoreInfoAriaLabel: 'Microsoft корпорациясының куки файлдарына қатысты саясаты туралы қосымша ақпарат',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'ko',
    Message:
      '이 사이트에서는 분석, 개인 설정 콘텐츠 및 광고를 위해 쿠키가 사용됩니다. 이 사이트 탐색을 계속하면 이러한 사용에 동의하게 됩니다.',
    MoreInfo: '자세히 보기',
    MoreInfoAriaLabel: 'Microsoft 쿠키 정책에 대해 자세히 보기',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'ko-KR',
    Message:
      '이 사이트에서는 분석, 개인 설정 콘텐츠 및 광고를 위해 쿠키가 사용됩니다. 이 사이트 탐색을 계속하면 이러한 사용에 동의하게 됩니다.',
    MoreInfo: '자세히 보기',
    MoreInfoAriaLabel: 'Microsoft 쿠키 정책에 대해 자세히 보기',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'lt',
    Message:
      'Ši svetainė naudoja slapukus analizei, suasmenintam turiniui ir skelbimams. Toliau naršydami šioje svetainėje, jūs sutinkate su šiuo naudojimu.',
    MoreInfo: 'Sužinoti daugiau',
    MoreInfoAriaLabel: 'Sužinoti daugiau apie „Microsoft“ slapukų politiką',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'lt-lT',
    Message:
      'Ši svetainė naudoja slapukus analizei, suasmenintam turiniui ir skelbimams. Toliau naršydami šioje svetainėje, jūs sutinkate su šiuo naudojimu.',
    MoreInfo: 'Sužinoti daugiau',
    MoreInfoAriaLabel: 'Sužinoti daugiau apie „Microsoft“ slapukų politiką',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'lv',
    Message:
      'Šī vietne izmanto sīkfailus, lai veiktu analīzi un rādītu personalizētu saturu un reklāmas. Turpinot šīs vietnes pārlūkošanu, jūs piekrītat šādai izmantošanai.',
    MoreInfo: 'Uzzināt vairāk',
    MoreInfoAriaLabel: 'Uzziniet vairāk par Microsoft sīkfailu politiku',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'lv-LV',
    Message:
      'Šī vietne izmanto sīkfailus, lai veiktu analīzi un rādītu personalizētu saturu un reklāmas. Turpinot šīs vietnes pārlūkošanu, jūs piekrītat šādai izmantošanai.',
    MoreInfo: 'Uzzināt vairāk',
    MoreInfoAriaLabel: 'Uzziniet vairāk par Microsoft sīkfailu politiku',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'ms',
    Message:
      'Laman ini menggunakan kuki untuk analitis, kandungan yang diperibadikan dan iklan. Dengan terus melayari laman ini, anda bersetuju terhadap penggunaan ini.',
    MoreInfo: 'Ketahui lebih lanjut',
    MoreInfoAriaLabel: 'Ketahui lebih lanjut tentang Dasar Kuki Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'ms-MY',
    Message:
      'Laman ini menggunakan kuki untuk analitis, kandungan yang diperibadikan dan iklan. Dengan terus melayari laman ini, anda bersetuju terhadap penggunaan ini.',
    MoreInfo: 'Ketahui lebih lanjut',
    MoreInfoAriaLabel: 'Ketahui lebih lanjut tentang Dasar Kuki Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'nb',
    Message:
      'Dette nettstedet bruker informasjonskapsler for analyser, tilpasset innhold og annonser. Ved å fortsette å bruke nettstedet godtar du å dette.',
    MoreInfo: 'Lær mer',
    MoreInfoAriaLabel: 'Lær mer om Microsofts policy for informasjonskapsler',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'nb-NO',
    Message:
      'Dette nettstedet bruker informasjonskapsler for analyser, tilpasset innhold og annonser. Ved å fortsette å bruke nettstedet godtar du å dette.',
    MoreInfo: 'Lær mer',
    MoreInfoAriaLabel: 'Lær mer om Microsofts policy for informasjonskapsler',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'nl',
    Message:
      'Deze site maakt gebruik van cookies om analyses te kunnen uitvoeren en persoonlijke inhoud en reclame te kunnen weergeven. Door verder te bladeren op deze site, gaat u akkoord met dit gebruik.',
    MoreInfo: 'Meer informatie',
    MoreInfoAriaLabel: 'Meer informatie over het cookiebeleid van Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'nl-NL',
    Message:
      'Deze site maakt gebruik van cookies om analyses te kunnen uitvoeren en persoonlijke inhoud en reclame te kunnen weergeven. Door verder te bladeren op deze site, gaat u akkoord met dit gebruik.',
    MoreInfo: 'Meer informatie',
    MoreInfoAriaLabel: 'Meer informatie over het cookiebeleid van Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'pl',
    Message:
      'W celu gromadzenia danych analitycznych oraz wyświetlania reklam i zawartości dostosowanych do preferencji użytkownika w tej witrynie używane są pliki cookie. Kontynuując jej przeglądanie, zgadzasz się na to użycie.',
    MoreInfo: 'Dowiedz się więcej',
    MoreInfoAriaLabel: 'Dowiedz się więcej o zasadach firmy Microsoft dotyczących plików cookie',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'pl-PL',
    Message:
      'W celu gromadzenia danych analitycznych oraz wyświetlania reklam i zawartości dostosowanych do preferencji użytkownika w tej witrynie używane są pliki cookie. Kontynuując jej przeglądanie, zgadzasz się na to użycie.',
    MoreInfo: 'Dowiedz się więcej',
    MoreInfoAriaLabel: 'Dowiedz się więcej o zasadach firmy Microsoft dotyczących plików cookie',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'pt-BR',
    Message:
      'Este site utiliza cookies para análise, conteúdo personalizado e anúncios. Continuando a navegar neste site, você concorda com esse uso.',
    MoreInfo: 'Saiba mais',
    MoreInfoAriaLabel: 'Saiba mais sobre a Política de Cookies da Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'pt',
    Message:
      'Este site utiliza cookies para análise de dados, conteúdo personalizado e anúncios. Ao continuar a navegar neste site, concorda com a sua utilização.',
    MoreInfo: 'Mais informações',
    MoreInfoAriaLabel: 'Saiba mais sobre a Política de Cookies da Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'pt-PT',
    Message:
      'Este site utiliza cookies para análise de dados, conteúdo personalizado e anúncios. Ao continuar a navegar neste site, concorda com a sua utilização.',
    MoreInfo: 'Mais informações',
    MoreInfoAriaLabel: 'Saiba mais sobre a Política de Cookies da Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'ro',
    Message:
      'Acest site folosește module cookie pentru analiză, conținut personalizat și reclame. Dacă răsfoiți în continuare acest site, sunteți de acord cu această utilizare.',
    MoreInfo: 'Aflați mai multe',
    MoreInfoAriaLabel: 'Aflați mai multe despre Politica Microsoft referitoare la modulele cookie',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'ro-RO',
    Message:
      'Acest site folosește module cookie pentru analiză, conținut personalizat și reclame. Dacă răsfoiți în continuare acest site, sunteți de acord cu această utilizare.',
    MoreInfo: 'Aflați mai multe',
    MoreInfoAriaLabel: 'Aflați mai multe despre Politica Microsoft referitoare la modulele cookie',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'ru',
    Message:
      'Этот сайт использует файлы cookie для аналитики, персонализированного контента и рекламы. Продолжая просмотр этого сайта, вы соглашаетесь с этими условиями использования.',
    MoreInfo: 'Узнайте больше',
    MoreInfoAriaLabel: 'Узнайте больше о политике Microsoft в отношении файлов cookie',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'ru-RU',
    Message:
      'Этот сайт использует файлы cookie для аналитики, персонализированного контента и рекламы. Продолжая просмотр этого сайта, вы соглашаетесь с этими условиями использования.',
    MoreInfo: 'Узнайте больше',
    MoreInfoAriaLabel: 'Узнайте больше о политике Microsoft в отношении файлов cookie',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'sk',
    Message:
      'Táto stránka používa súbory cookie kvôli analýze, prispôsobeniu obsahu a reklamám. Pokračovaním v prehliadaní tejto stránky súhlasíte s týmto použitím.',
    MoreInfo: 'Ďalšie informácie',
    MoreInfoAriaLabel: 'Ďalšie informácie o zásadách používania súborov cookie spoločnosti Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'sk-SK',
    Message:
      'Táto stránka používa súbory cookie kvôli analýze, prispôsobeniu obsahu a reklamám. Pokračovaním v prehliadaní tejto stránky súhlasíte s týmto použitím.',
    MoreInfo: 'Ďalšie informácie',
    MoreInfoAriaLabel: 'Ďalšie informácie o zásadách používania súborov cookie spoločnosti Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'sl',
    Message:
      'To mesto uporablja piškotke za analitiko, vsebino po meri in oglase. Če nadaljujete z brskanjem po tem mestu, sprejemate ta način uporabe.',
    MoreInfo: 'Več o tem',
    MoreInfoAriaLabel: 'Več o Microsoftovem pravilniku o piškotkih',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'sl-SL',
    Message:
      'To mesto uporablja piškotke za analitiko, vsebino po meri in oglase. Če nadaljujete z brskanjem po tem mestu, sprejemate ta način uporabe.',
    MoreInfo: 'Več o tem',
    MoreInfoAriaLabel: 'Več o Microsoftovem pravilniku o piškotkih',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'sr',
    Message:
      'Ova lokacija koristi kolačiće za analitiku, personalizovani sadržaj i oglase. Daljim pregledanjem ove lokacije pristajete na ovo korišćenje.',
    MoreInfo: 'Saznajte više',
    MoreInfoAriaLabel: 'Saznajte više o politici kolačića kompanije Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'sr-LATN-RS',
    Message:
      'Ova lokacija koristi kolačiće za analitiku, personalizovani sadržaj i oglase. Daljim pregledanjem ove lokacije pristajete na ovo korišćenje.',
    MoreInfo: 'Saznajte više',
    MoreInfoAriaLabel: 'Saznajte više o politici kolačića kompanije Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'sv',
    Message:
      'Denna webbplats använder cookies för statistik, anpassat innehåll och annonser. Genom att fortsätta navigera på webbplatsen godkänner du denna användning.',
    MoreInfo: 'Läs mer',
    MoreInfoAriaLabel: 'Läs mer om Microsofts policy för cookies',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'sv-SE',
    Message:
      'Denna webbplats använder cookies för statistik, anpassat innehåll och annonser. Genom att fortsätta navigera på webbplatsen godkänner du denna användning.',
    MoreInfo: 'Läs mer',
    MoreInfoAriaLabel: 'Läs mer om Microsofts policy för cookies',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'th',
    Message:
      'ไซต์นี้ใช้คุกกี้เพื่อการวิเคราะห์ เนื้อหาที่ปรับตามบุคคลและโฆษณา เมื่อเรียกดูข้อมูลในไซต์นี้ต่อไป จะถือว่าคุณตกลงยอมรับการใช้ดังกล่าว',
    MoreInfo: 'เรียนรู้เพิ่มเติม',
    MoreInfoAriaLabel: 'เรียนรู้เพิ่มเติมเกี่ยวกับนโยบายคุกกี้ของ Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'th-TH',
    Message:
      'ไซต์นี้ใช้คุกกี้เพื่อการวิเคราะห์ เนื้อหาที่ปรับตามบุคคลและโฆษณา เมื่อเรียกดูข้อมูลในไซต์นี้ต่อไป จะถือว่าคุณตกลงยอมรับการใช้ดังกล่าว',
    MoreInfo: 'เรียนรู้เพิ่มเติม',
    MoreInfoAriaLabel: 'เรียนรู้เพิ่มเติมเกี่ยวกับนโยบายคุกกี้ของ Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'tr',
    Message:
      'Bu site çözümleme, kişiselleştirilmiş içerik ve reklamlar için tanımlama bilgileri kullanmaktadır. Bu sitede gezinmeye devam ederek bu kullanımı kabul etmiş olursunuz.',
    MoreInfo: 'Daha fazla bilgi',
    MoreInfoAriaLabel: "Microsoft'un Tanımlama Bilgileri İlkesi hakkında daha fazla bilgi edinin",
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'tr-TR',
    Message:
      'Bu site çözümleme, kişiselleştirilmiş içerik ve reklamlar için tanımlama bilgileri kullanmaktadır. Bu sitede gezinmeye devam ederek bu kullanımı kabul etmiş olursunuz.',
    MoreInfo: 'Daha fazla bilgi',
    MoreInfoAriaLabel: "Microsoft'un Tanımlama Bilgileri İlkesi hakkında daha fazla bilgi edinin",
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'uk',
    Message:
      'На цьому сайті використовуються файли cookie для аналізу, персоналізації вмісту й оголошень. Продовжуючи перегляд сайту, ви погоджується з цим.',
    MoreInfo: 'Докладніше',
    MoreInfoAriaLabel: 'Докладніше про Політику Microsoft щодо файлів cookie',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'uk-UA',
    Message:
      'На цьому сайті використовуються файли cookie для аналізу, персоналізації вмісту й оголошень. Продовжуючи перегляд сайту, ви погоджується з цим.',
    MoreInfo: 'Докладніше',
    MoreInfoAriaLabel: 'Докладніше про Політику Microsoft щодо файлів cookie',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'vi',
    Message:
      'Trang này sử dụng cookie để phục vụ mục đích phân tích, nội dung cá nhân hóa và quảng cáo. Tiếp tục duyệt xem trang này có nghĩa là bạn đồng ý với việc sử dụng này.',
    MoreInfo: 'Tìm hiểu thêm',
    MoreInfoAriaLabel: 'Tìm hiểu thêm về Chính sách Cookie của Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'vi-VN',
    Message:
      'Trang này sử dụng cookie để phục vụ mục đích phân tích, nội dung cá nhân hóa và quảng cáo. Tiếp tục duyệt xem trang này có nghĩa là bạn đồng ý với việc sử dụng này.',
    MoreInfo: 'Tìm hiểu thêm',
    MoreInfoAriaLabel: 'Tìm hiểu thêm về Chính sách Cookie của Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'zh',
    Message: '此站点使用 Cookie 进行分析、显示个性化内容和广告。继续浏览此站点，即表示您同意使用。',
    MoreInfo: '了解详情',
    MoreInfoAriaLabel: '了解有关 Microsoft Cookie 政策的详情',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'zh-CN',
    Message: '此站点使用 Cookie 进行分析、显示个性化内容和广告。继续浏览此站点，即表示您同意使用。',
    MoreInfo: '了解详情',
    MoreInfoAriaLabel: '了解有关 Microsoft Cookie 政策的详情',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'zh-HK',
    Message: '此網站會使用 Cookie 進行分析，並為您提供個人化內容與廣告。如繼續瀏覽此網站，即表示您同意上述用途。',
    MoreInfo: '深入瞭解',
    MoreInfoAriaLabel: '深入瞭解 Microsoft Cookie 政策',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'zh-TW',
    Message: '本網站為了分析、個人化內容和廣告目的而使用 Cookie。繼續瀏覽本網站，表示您同意這個用途。',
    MoreInfo: '了解詳情',
    MoreInfoAriaLabel: '深入了解 Microsoft 的 Cookie 政策',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'mt-MT',
    Message:
      'Dan is-sit juża cookies għal analytics, kontenut personalizzat u reklami. Billi tkompli tfittex dan is-sit, taċċetta dan l-użu.',
    MoreInfo: 'Itagħllem iżjed',
    MoreInfoAriaLabel: "Tgħallem aktar dwar il-Politika Cookie ta 'Microsoft",
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
  {
    Market: 'is-IS',
    Message:
      'Þessi síða notar fótspor fyrir greiningu, sérsniðið efni og auglýsingar. Með því að halda áfram að skoða þetta vefsvæði samþykkir þú þessa notkun.',
    MoreInfo: 'Læra meira',
    MoreInfoAriaLabel: 'Frekari upplýsingar um Cookie Policy Microsoft',
    MoreInfoUrl: 'https://go.microsoft.com/fwlink/?linkid=845480',
  },
];
