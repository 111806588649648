import { IState } from '../../State';
import { Footer as FooterComponent } from '../components/footer';
import { createIsManageModalOpenAction, createToggleShowContextPanel } from '@shared/actions/actions';
import { withRouter } from '@shared/routerHistory';

import { connect } from 'react-redux';

const mapStateToProps = (state: IState) => {
  return {
    isManageCookieBtnRequired: state.config.cookiesConsent.isConsentRequired,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    openCookieManagementModal: () => dispatch(createIsManageModalOpenAction(true)),
    toggleContextPanel: () => dispatch(createToggleShowContextPanel()),
  };
};

export const Footer = withRouter(connect(mapStateToProps, mapDispatchToProps)(FooterComponent));
