/* eslint-disable react/no-find-dom-node */
import { findDOMNode } from 'react-dom';
import { getWindow, getDocument } from './../services/window';
import { Constants } from './../utils/constants';

export interface Offset {
  x: number;
  y: number;
}

// The ranking of Componet
//  rank: component index out of total similar components
//  total:
//      total number of similar components, since its value varies with screen size
export interface IComponentRank {
  rank: number;
  total: number;
}

/**
 * get Component Coordinate on current rendered page
 *
 *  origin of Coodinate (0, 0) is top left point on current rendered page
 *
 * @param componentRef: reference of tile component
 *
 * @return:
 *  The Rank of Component
 *      rank: componet ranking, or -1 if tile not found
 *      total: total number of components with same class name, or -1 if tile not found
 */
export function getComponentXYCoordinate(componentRef: any): Offset {
  if (componentRef) {
    const domNode = findDOMNode(componentRef);

    if (domNode) {
      // get relative boundary position, left, right, top, bottom
      const boundingRectangle = domNode.getBoundingClientRect();

      // get relative center position
      // by adding top left point of a tile rectangle with half of width, and height respectively of tile rectangle
      const relativeCenterX: number = boundingRectangle.left + Math.floor(boundingRectangle.width / 2);
      const relativeCenterY: number = boundingRectangle.top + Math.floor(boundingRectangle.height / 2);

      // get absolute center position
      // by adding user scroll offset against origin (0, 0)
      return {
        x: relativeCenterX + getWindow().scrollX,
        y: relativeCenterY + getWindow().scrollY,
      };
    }
  }

  return {
    x: -1,
    y: -1,
  };
}

/**
 * get tile Component Rank based on coordinate out of Total Components with same class name
 *
 *  ranking is based on Coordinates sorted on x first and then y (zigzag)
 *
 * @param componentRef: reference of tile component
 *
 * @return:
 *  The Rank of Component
 *      rank: componet ranking, or -1 if tile not found
 *      total: total number of components with same class name, or -1 if tile not found
 */
export const getTileComponentRank = (componentRef: any): IComponentRank => {
  const coordinateRanking: IComponentRank = {
    rank: -1,
    total: -1,
  };

  // get collection of tile elements whose css classname is tile
  const elementHTMLCollection: HTMLCollectionOf<Element> = getDocument().getElementsByClassName(
    Constants.HTMLClassNameStrings.tile
  );

  // Note: the return value of getElementsByClassName is not an array, so convert it
  const elements: HTMLElement[] = Object.keys(elementHTMLCollection).map((key: string) => elementHTMLCollection[`${key}`]);
  if (elements && elements.length) {
    coordinateRanking.total = elements.length;
  }

  if (componentRef) {
    const domNode = findDOMNode(componentRef);

    if (domNode) {
      const attributeAriaLabeledBy = domNode.getAttribute(Constants.HTMLAttribute.ariaLabeledBy);

      // find position of element out of similar elements by matching with attribute 'aria-labelledby'
      // Note: 'aria-labelledby' is unique for each tile, it contains entity ID, see baseTile.tsx
      elements.some((element: HTMLElement, elementIndex: number) => {
        const match: boolean = element.getAttribute(Constants.HTMLAttribute.ariaLabeledBy) === attributeAriaLabeledBy;
        if (match) {
          coordinateRanking.rank = elementIndex;
        }
        // return immediately if found
        return match;
      });
    }
  }

  return coordinateRanking;
};
