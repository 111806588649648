import { IState } from '../../State';
import HomeComponent, { IHomeProps } from '@shared/components/home';
import { Constants } from '@shared/utils/constants';
import { parseCuratedSections } from '@shared/utils/hashMapUtils';
import { getTelemetryDetailContentCurrentView } from '@shared/utils/appUtils';
import homeBanner from '@shared/images/homepage/BannerHome4.jpg';
import { connect } from 'react-redux';
import { isOneTimePaymentOffer } from '@shared/utils/onetimepaymentoffers';
import { withRouter } from '@shared/routerHistory';

export const mapStateToProps = (state: IState, ownProps: IHomeProps) => {
  const query = ownProps.location.query;
  const filterTab = query && query.filterTab ? query.filterTab : null;
  const successStoryTab = query?.successStoryTab;
  const dataList = state.apps.dataList.filter(({ entityId }) => !isOneTimePaymentOffer(entityId));
  return {
    appList: dataList,
    hashMap: state.apps.idMap,
    featuredApps: parseCuratedSections(state.apps.curatedData[Constants.Curated.top], dataList, state.apps.idMap)[0],
    searchID: state.search.searchIdCurrentlyOngoing,
    appSearchResults: state.apps.subsetData,
    servicesSeachResults: state.services.subsetData,
    allApps: dataList,
    allServices: state.services.dataList,
    searchQuery: state.apps.subsetSearchQuery,
    urlQuery: query,
    selectedLocale: state.config.locale,
    currentView: getTelemetryDetailContentCurrentView(state.config.currentView, state.config.currentGalleryViewIsCurated),
    appsCuratedData: state.apps.curatedData,
    dataMap: state.apps.dataMap,
    appsActiveFilters: state.apps.activeFilters,
    servicesActiveFilters: state.services.activeFilters,
    appsCount: state.apps.count,
    servicesCount: state.services.count,
    showPopularity: state.config.featureFlags.ShowPopularity === 'true',
    defaultTab: filterTab,
    valueProposition: {
      imageUrl: homeBanner,
      title: 'valueProposition_title',
      description: 'valueProposition_description',
    },
    defaultSuccessStoryTab: successStoryTab,
  };
};

const Home = withRouter(connect(mapStateToProps)(HomeComponent));

export default Home;
