import {
  IAppSearchResult,
  IServiceSearchResult,
  IDataItem,
  IAppDataItem,
  IUserInfo,
  ICuratedSection,
  UserSegment,
  IUserLeadGenProfile,
  ICartItem,
  ICustomer,
  IPublisherOfferResponse,
  IURLQuery,
  IAppsPricingsPayload,
  IAppReview,
  IUserTenantsDetails,
  ICloudsIndustry,
  IPartnerSearchResult,
  ISetSeatsAction,
  ISetTermIdAction,
  ISetTermDurationAction,
  ISetRecurringBilling,
  IBillingAddress,
  CheckoutSource,
  IAccountsDetails,
  ICheckoutId,
  SendLeadInfoOptions,
} from '@shared/Models';

import { IDataMap, IDataValues } from '@shared/utils/dataMapping';
import { Constants } from '@shared/utils/constants';
import { Service } from '@shared/serviceViewModel';

import { WCPconsent } from '@shared/components/cookieBanner';
import { IContactDetails, IFilterLocation } from '@shared/partnersProtocol/partnersHostInboundMessages';
import { IUserFavouriteState, IUserFavouriteApp, IUserFavouriteService } from '@shared/interfaces/userFavouriteModels';

// eslint-disable-next-line import/named
import type { IReceiptView } from '@microsoft-commerce/purchase-blends-component-library';
import { enrichReviewCommentsApiVersion } from '@shared/specifics';
import { IAppReviewCommentsState } from '@shared/interfaces/reviews/comments';
import { IAppReviewMarkedAsHelpfulState } from '@shared/interfaces/reviews/markAsHelpful/stateModels';
import type { i18n } from 'i18next';

export interface IReviewsState {
  entityId: string;
  reviews: IAppReview[];
  isPurchased: boolean;
  userReview?: IAppReview;
  reviewComments?: { [reviewId: string]: IAppReviewCommentsState };
  markedAsHelpful: IAppReviewMarkedAsHelpfulState;
  userCommentedReviewIds?: string[];
}

export interface ILinkedInCustomer {
  id: string;
  name: string;
  logoUrl: string;
  followersCount: number;
  businessCategory: string;
  detailsUrl: string;
}

export interface ILinkedInEvent {
  id: string;
  name: string;
  logoUrl: string;
  eventType: string;
  startTime: number;
  endTime: number;
  attendeeCount: number;
  detailsUrl: string;
}

export interface ILinkedInProductGroup {
  offerId: string;
  id: string;
  productGroupId: string;
  membersCount: number;
  featuredCustomers: ILinkedInCustomer[];
  upcomingEvents: ILinkedInEvent[];
  detailsUrl: string;
  eventsUrl: string;
}

const initialAppReviewsDataState: IReviewsState = {
  entityId: '',
  reviews: [],
  isPurchased: false,
  reviewComments: {},
  markedAsHelpful: {
    userReviewIdsMarkedAsHelpful: [],
    userReviewIdsLoading: {},
  },
};

// add your feature flags here
// for now the type should be string
// we can explore other types in the future
// DON'T FORGET to initialize it in the constructor
// ALSO DON'T FORGET to initialize it in the initial state
// your new feature flag value will be automatically update in
// state.config.featureFlags.myNewFeatureFlag
export class IFeatureFlags {
  // i.e. myNewFeatureFlag: string;
  AppReviews: string;
  ProfileExp: string;
  AltAuth: string;
  SaasLinkingEnabled: string;
  RedesignEnabled: string;
  ShowPopularity: string;
  UseSecondarySearch: string;
  privateOffers: string;
  purchaseBlends: string;
  newCustomerFlow: string;
  PartnersEnabled: string;
  forceCompletePurchase: string;
  allowAdminUser: string;
  showConsent: string;
  ensureprewarm: string;
  useSearchV2: string;
  ReviewsMyCommentsFilter: string;
  sortByRecentlyUpdatedEnabled: string;
  sortByRatingEnabled: string;
  allNewSortsEnabled: string;
  I18nextEnabled: string;
  cloudMarketplaceModeOn: boolean;
  recommendations: boolean;
  openInNewWindowButton: boolean;
  logToConsole: boolean;
  expredirect: boolean;
  partnersTestMode: boolean;

  cloudMarketplaceModeFF = ['newHeader'];
  constructor() {
    // i.e. this.myNewFeatureFlag = 'false';
    this.AppReviews = 'false';
    this.ProfileExp = 'false';
    this.AltAuth = 'false';
    this.SaasLinkingEnabled = 'false';
    this.RedesignEnabled = 'false';
    this.ShowPopularity = 'false';
    this.UseSecondarySearch = 'false';
    this.privateOffers = 'false';
    this.purchaseBlends = 'false';
    this.newCustomerFlow = 'false';
    this.PartnersEnabled = 'false';
    this.forceCompletePurchase = 'false';
    this.allowAdminUser = 'false';
    this.showConsent = 'false';
    this.ensureprewarm = 'false';
    this.useSearchV2 = 'false';
    this.ReviewsMyCommentsFilter = 'true';
    this.sortByRecentlyUpdatedEnabled = 'false';
    this.sortByRatingEnabled = 'false';
    this.allNewSortsEnabled = 'false';
    this.I18nextEnabled = 'false';
    this.cloudMarketplaceModeOn = false;
    this.recommendations = true;
    this.openInNewWindowButton = false;
    this.logToConsole = false;
    this.expredirect = false;
    this.partnersTestMode = false;
  }

  activeCloudMarketplaceFeatures = () => {
    if (this.cloudMarketplaceModeOn) {
      this.cloudMarketplaceModeFF.forEach((ff) => {
        this[`${ff}`] = true;
      });
    }
  };
}
export interface ISearchState {
  searchText: string;
  searchSortingOption: Constants.SearchSortingOption;
  appSearchResults: IAppSearchResult[];
  servicesSearchResults: IServiceSearchResult[];
  filteredAppSearchResults: IAppDataItem[];
  filteredServicesSearchResults: Service[];
  filteredCloudsIndustrySearchResults: IAppDataItem[];
  appsCount: number;
  servicesCount: number;
  cloudsIndustryCount: number;
  searchIdCurrentlyOngoing: number;
  partnerSearchResults: IPartnerSearchResult[];
  showingResultsFor?: string;
  previousSearchText?: string;
  didYouMean?: string;
  requestId: string;
  suggestionsRequestId?: string;
}

export interface IModalState {
  entityId?: string;
  modalId: number;
  isSignedIn: boolean;
  showModal: boolean;
  payload: any;
  options?: any;
  entityType: Constants.EntityType;
  onModalDismiss?: () => void;
  disableDismissModal?: boolean;
  isOpenedFromPDP?: boolean;
}

export interface IRelatedItems {
  suggestedItems: IDataItem[];
  saasLinkingItems?: IAppDataItem[];
  linkedAddIns?: IAppDataItem[];
}
export interface ISuggestedItems {
  entityId: string;
  items: IRelatedItems;
}
export type RecommendedWhatsNewApps = IDataItem[];
export type RecommendedTrendingApps = IDataItem[];

export type RecommendedWhatsNewService = Service[];
export type RecommendedTrendingService = Service[];

export type RecommendedMicrosoft365WhatsNewApps = IDataItem[];
export type RecommendedMicrosoft365TrendingApps = IDataItem[];
export interface IRecommendedApps {
  whatsNewApps: RecommendedWhatsNewApps;
  trendingApps: RecommendedTrendingApps;
  microsoft365WhatsNewApps: RecommendedMicrosoft365WhatsNewApps;
  microsoft365TrendingApps: RecommendedMicrosoft365TrendingApps;
}

export interface IRecommendedServies {
  whatsNewService: RecommendedWhatsNewService;
  trendingService: RecommendedTrendingService;
}

export interface IEntityDataState<T extends IDataItem> {
  idMap: { [id: string]: number };
  dataList: T[];
  dataMap: IDataMap;
  dataMapLoaded: boolean;
  entityIdFailed: string;
  entityIdLoading: string;
  activeFilters: IDataValues[];
  count: number;
  dataLoaded: { [id: string]: boolean };
  curatedData: { [id: string]: ICuratedSection<T>[] };
  curatedDataLoaded: { [id: string]: boolean };
  subsetData: T[];
  subsetSearchQuery: string;
  tileDataRequestId: string;
  relatedAppsItems: ISuggestedItems;
  appReviewsData: IReviewsState;
  linkedInProductGroup: ILinkedInProductGroup;
}

export interface IRecommendedSizesState {
  loading: boolean;
  entityId: string;
  planId: string;
  recommendedSizes: string[];
}

const initialRecommendedSizesState: IRecommendedSizesState = {
  loading: false,
  entityId: '',
  planId: '',
  recommendedSizes: [],
};

export interface IAppDataState extends IEntityDataState<IAppDataItem> {
  recommendedApps: IRecommendedApps;
  pricingPayload: IAppsPricingsPayload;
  partnerAppDataLoaded?: boolean;
  partnerAppDataLoadedError?: boolean;
  recommendedSizesState: IRecommendedSizesState;
}

export interface ICloudsIndustryDataState extends IEntityDataState<ICloudsIndustry> {
  pricingPayload: IAppsPricingsPayload;
}

export interface IAppCheckoutState {
  checkoutId?: string;
  items: ICartItem[];
  customers?: ICustomer[];
  activeCustomer?: ICustomer;
  retryAfter?: number;
  publisherToken?: IPublisherOfferResponse;
  error: boolean;
  currentStep: number;
  purchaseAttempt: number;
  receipt?: IReceiptView;
  billingAddress: IBillingAddress;
  productFetched?: boolean;
  source?: CheckoutSource;
}

export interface IWizardProps extends IWizardDispatchProps {
  checkoutId: string;
  checkout: IAppCheckoutState;
  app: IAppDataItem;
  currentStep: number;
  users: IUserDataState;
  config: IConfigState;
  // While customers aren't fetched, the wizard is considered to be loading
  wizardLoading: boolean;
  featureFlags: IFeatureFlags;
  billingCountry: string;
  productFetched: boolean;
  adminFlow: boolean;
  isBagOffer: boolean;
  source: CheckoutSource;
  isProfessionalService?: boolean;
}

export interface IWizardDispatchProps {
  setActiveStep?: (targetStepId: number) => void;
  setPurchaseAttemptNumber?: (purchaseAttemptNumber: number) => void;
  setBillingCountry?: (countryCode: string) => any;
  fetchPricing?: (entityId: string, isPrivate: boolean) => void;
  createCustomer?: () => void;
  setCartItem?: (cartItem: ICartItem) => void;
  createCheckoutId?: ({ checkoutId }: ICheckoutId) => void;
  setPurchaseReceipt?: (receipt: IReceiptView) => void;
  generatePublisherToken?: (subscriptionId: string) => void;
  setBillingTerm?: (termDurationAction: ISetTermDurationAction) => void;
  setRecurringBilling?: (recurringBillingActionProps: ISetRecurringBilling) => void;
  setSeatNumber?: (setSeatsProps: ISetSeatsAction) => void;
  setSoldToAddress: (soldToAddress: IBillingAddress) => void;
  fetchProduct?: (id: string, market: string) => void;
  setSelectedTerm?: (termIdAction: ISetTermIdAction) => void;
  sendLeadInfo: (options: SendLeadInfoOptions) => Promise<unknown>;
}

export interface ICookiesConsent {
  WCPConsent: WCPconsent;
  isManageModalOpen: boolean;
  isConsentRequired: boolean;
}
export interface IAccessToken {
  arm: string;
  graph: string;
  spza: string;
  pifd: string;
  commerce: string;
  jarvis: string;
  marketplaceLeads: string;
  agreement: string;
}
export interface IUserLoginStatus {
  signedIn?: boolean;
  refreshToken?: string;
  homeAccountId?: string;
}
export interface IUserLoginFetchStatus {
  loading: boolean;
}

export interface IUserDataState extends IUserInfo {
  id: string;
  loading: boolean;
  signedIn: boolean;
  homeAccountId: string;
  isLoadingUserProfile: boolean;
  group: string[];
  idToken: string;
  idTokenPayload?: string;
  accessToken: IAccessToken;
  refreshToken: string;
  graphApi?: string;
  hasReview?: boolean;
  profile?: IUserLeadGenProfile;
  purchases?: IUserPurchases;
  privateOffers?: IUserPrivateOffers;
  tenantsDetails: IUserTenantsDetails;
  /** List of AAD role definitions -> Global Admin / Billing Admin */
  roleDefinitions?: string[];
  /** String including all treatments and ids for specific user, i.e. "search-acs:31428" */
  assignmentContext: string;
  /** ClientId for the ab testing platform  */
  abClientId: string;
  expTimestamp: number;
  accounts: IAccountsDetails;
  isMaccTenant: boolean;
}

export interface IUserPurchases {
  hasPurchases?: boolean;
  loading?: boolean;
}
export interface IUserPrivateOffers {
  dataList: IAppDataItem[];
  loading?: boolean;
}
export interface IAppCheckoutStateIUserDataState extends IUserInfo {
  id: string;
  signedIn: boolean;
  isLoadingUserProfile: boolean;
  group: string[];
  idToken: string;
  idTokenPayload?: string;
  accessToken: IAccessToken;
  refreshToken: string;
  graphApi?: string;
  hasReview?: boolean;
  profile?: IUserLeadGenProfile;
  purchases?: IUserPurchases;
  privateOffers?: IUserPrivateOffers;
  tenantsDetails: IUserTenantsDetails;
}

export interface IExperiment {
  // eslint-disable-next-line camelcase
  abTestingVersion_AMP: number;
  // eslint-disable-next-line camelcase
  abTestingVersion_newFilterAMP: number;
}

export interface IConfigState {
  i18nInstance: i18n;
  showContextPanel: boolean;
  initLoading: boolean;
  isEmbedded: boolean;
  experiment: IExperiment;
  embedHost: string;
  locStrings: any;
  locale: string;
  user: string;
  flightCodes: string;
  correlationId: string;
  requestId: string;
  breadcrumbUrl: string;
  query: IURLQuery;
  requestFilteredLoading: boolean;
  requestFilteredEntityType: Constants.TileDataEntityType;
  requestFilteredQuery: IURLQuery;
  responseFilteredQuery: IURLQuery;
  cookiesConsent: ICookiesConsent;
  telemetryEndpoint: string;
  appVersion: string;
  region: string;

  // current rendered view, one of routes property `name` in routerHistory.tsx
  currentView: string;

  // very first view when user enters our sites, one of routes property `name` in routerHistory.tsx
  landingView: string;

  // determine current rendered gallery page is curated view or filtered view
  // it should be undefined when currentView is not a gallery page
  currentGalleryViewIsCurated: boolean;
  appViewTelemetryLoggedCount: number;
  nationalCloud: string;
  billingCountryCode: string;
  pricingDataLoaded: boolean;
  featureFlags: IFeatureFlags;
  marketplaceApiHost: string;
  saasRPHost: string;
  MPRPHost: string;
  armApiHost: string;
  isMobile: boolean;
  isAppSource?: boolean;
  pidlEnv?: string;
  partnersIframeUrl: string;
  partnersIframeUrlTest: string;
  telemetryInstrumentationKey: string;
  loggingInstrumentationKey: string;
  partnersApiHost: string;
  reviewsAPI: string;
  reviewsAPIVersion: string;
  linkedinAPI: string;
  linkedinAPIVersion: string;
  communityAPI: string;
  communityAPIVersion: string;
  newNpsEnabled: boolean;
  npsLogicVerified: boolean;
  siteEnvironment: string;
  commerceApiHost: string;
  isDisabledBilling: boolean;
  storefrontName: string;
  msClarityId: string;
  searchApiEndpoint: string;
  oneCatalogApiHost: string;
  expHeaderDisabled: boolean;
  marketplaceLeadsHost: string;
  marketplaceLeadsAudienceId: string;
  enableOneTaxonomy: boolean;
  aadApplicationId: string;
  aadLoginUrl: string;
  jarvisCM: string;
  mprpAadApplicationId: string;
  pifdEndpoint: string;
  commerceApi: string;
  graphApi: string;
  pcaInstanceInitialized: boolean;
  includeTestProducts: boolean;
  agreementAppId: string;
  isConverged: boolean;
}

export interface ServiceCountries {
  [regionCode: string]: string;
}

export interface IServiceDataState extends IEntityDataState<Service> {
  recommendedServices: IRecommendedServies;
  countriesList: ServiceCountries;
  entityType: Constants.EntityType.Service;
  selectedCountry: string;
  selectedRegion: string;
}

export interface IDynamicCampaignState {
  html: string;
}

export interface IPartnersState {
  filterLocation: IFilterLocation;
  contactDetails: IContactDetails;
}
export interface IState {
  search: ISearchState;
  modal: IModalState;
  apps: IAppDataState;
  cloudsIndustry: ICloudsIndustryDataState;
  users: IUserDataState;
  config: IConfigState;
  services: IServiceDataState;
  dynamicCampaign?: IDynamicCampaignState;
  userFavourite: IUserFavouriteState;
  checkout?: IAppCheckoutState;
  partners?: IPartnersState;
}

export const initialUserFavouriteState: IUserFavouriteState = {
  fetchDataStatus: Constants.UserFavourite.FetchDataStatus.Invalid,
  apps: [] as IUserFavouriteApp[],
  services: [] as IUserFavouriteService[],
};

export const initialDynamicCampaignState: IDynamicCampaignState = {
  html: '',
};

export const initialSearchState: ISearchState = {
  searchText: '',
  searchSortingOption: Constants.SearchSortingOption.BestMatch,
  appSearchResults: [] as IAppSearchResult[],
  servicesSearchResults: [] as IServiceSearchResult[],
  filteredAppSearchResults: [] as IAppDataItem[],
  filteredServicesSearchResults: [] as Service[],
  filteredCloudsIndustrySearchResults: [] as IAppDataItem[],
  appsCount: 0,
  servicesCount: 0,
  cloudsIndustryCount: 0,
  searchIdCurrentlyOngoing: 0,
  partnerSearchResults: [] as IPartnerSearchResult[],
  requestId: Constants.ReservedCorrelationIds.EmptyId,
};

export const initialModalState = {
  appId: '',
  parnerId: '',
  modalId: 0,
  isSignedIn: false,
  showModal: false,
  payload: null as any,
  entityType: Constants.EntityType.App,
  isOpenedFromPDP: false,
};

export const initialEntityDataState: IEntityDataState<IDataItem> = {
  idMap: {},
  dataList: [] as IDataItem[],
  dataMap: {} as IDataMap,
  dataMapLoaded: false,
  entityIdFailed: '',
  entityIdLoading: '',
  activeFilters: [] as IDataValues[],
  count: 0,
  curatedData: {},
  dataLoaded: {},
  curatedDataLoaded: {},
  subsetData: [] as IAppDataItem[],
  subsetSearchQuery: '',
  tileDataRequestId: '',
  relatedAppsItems: {
    entityId: '',
    items: {
      suggestedItems: [],
      saasLinkingItems: [],
      linkedAddIns: [],
    },
  },
  appReviewsData: initialAppReviewsDataState,
  linkedInProductGroup: null,
};

export const initialAppDataState: IAppDataState = {
  idMap: {},
  dataList: [] as IAppDataItem[],
  recommendedApps: {
    whatsNewApps: [],
    trendingApps: [],
    microsoft365WhatsNewApps: [],
    microsoft365TrendingApps: [],
  },
  dataMap: {} as IDataMap,
  dataMapLoaded: false,
  entityIdFailed: '',
  entityIdLoading: '',
  activeFilters: [] as IDataValues[],
  count: 0,
  curatedData: {},
  dataLoaded: {},
  pricingPayload: null,
  partnerAppDataLoaded: false,
  curatedDataLoaded: {},
  subsetData: [] as IAppDataItem[],
  subsetSearchQuery: '',
  tileDataRequestId: '',
  relatedAppsItems: {
    entityId: '',
    items: {
      suggestedItems: [],
      saasLinkingItems: [],
      linkedAddIns: [],
    },
  },
  appReviewsData: initialAppReviewsDataState,
  recommendedSizesState: initialRecommendedSizesState,
  linkedInProductGroup: null,
};

export const initialCloudsIndustryDataState: ICloudsIndustryDataState = {
  idMap: {},
  dataList: [] as ICloudsIndustry[],
  dataMap: {} as IDataMap,
  dataMapLoaded: false,
  entityIdFailed: '',
  entityIdLoading: '',
  activeFilters: [] as IDataValues[],
  count: 0,
  curatedData: {},
  dataLoaded: {},
  pricingPayload: null,
  curatedDataLoaded: {},
  subsetData: [] as ICloudsIndustry[],
  subsetSearchQuery: '',
  tileDataRequestId: '',
  relatedAppsItems: {
    entityId: '',
    items: {
      suggestedItems: [],
      saasLinkingItems: [],
      linkedAddIns: [],
    },
  },
  appReviewsData: initialAppReviewsDataState,
  linkedInProductGroup: null,
};

export const initialUserDataState: IUserDataState = {
  assignmentContext: '',
  id: '',
  group: [''],
  signedIn: false,
  idToken: '',
  idTokenPayload: '',
  accessToken: {},
  refreshToken: '',
  firstName: '',
  lastName: '',
  uniqueName: '',
  displayName: '',
  oid: '',
  tid: '',
  puid: '',
  email: '',
  graphApi: process.env.graphApi, // todo: this should be removed, will crash in client
  alternateEmail: '',
  isMSAUser: false,
  userSegment: UserSegment.unauthenticated,
  hasReview: false,
  fieldHubUserType: Constants.FieldHubUserType.None,
  isLoadingUserProfile: false,
  loading: true,
  givenName: '',
  familyName: '',
  profile: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    country: '',
    company: '',
    title: '',
    updateRequired: null,
    isLatestProfile: false,
    uiRole: null,
    managedLicenses: [],
  },
  tenantsDetails: {
    details: {
      countryCode: null,
      defaultDomain: null,
      displayName: null,
      domains: null,
      id: null,
      tenantCategory: null,
      tenantId: null,
      tenantType: null,
      azureSubscriptions: {},
    },
    status: {
      hasError: false,
      isLoading: false,
    },
  },
  purchases: {},
  privateOffers: {
    dataList: [],
    loading: true,
  },
  roleDefinitions: [],
  accounts: { isEAAccount: false, isLoading: false, accounts: null, hasError: false },
  isMaccTenant: false,
};

export const initialExperimentState: IExperiment = {
  abTestingVersion_AMP: Constants.VaritionKey.Default,
  abTestingVersion_newFilterAMP: Constants.VaritionKey.Default,
};

export const initialConfigState: IConfigState = {
  initLoading: true,
  isEmbedded: false,
  experiment: initialExperimentState,
  embedHost: null,
  locStrings: '',
  locale: 'en-us',
  user: '',
  flightCodes: null,
  partnersIframeUrl: '',
  partnersApiHost: '',
  partnersIframeUrlTest: '',
  correlationId: '',
  currentView: 'home',
  landingView: '',
  currentGalleryViewIsCurated: false,
  breadcrumbUrl: null,
  appViewTelemetryLoggedCount: 0,
  nationalCloud: '',
  billingCountryCode: 'us',
  pricingDataLoaded: false,
  featureFlags: new IFeatureFlags(),
  marketplaceApiHost: '',
  saasRPHost: '',
  MPRPHost: '',
  armApiHost: '',
  isMobile: false,
  isAppSource: process.env ? process.env.applicationTarget === Constants.appSourceApplicationTargetName : false,
  pidlEnv: '',
  query: {} as IURLQuery,
  requestFilteredLoading: false,
  requestFilteredEntityType: Constants.TileDataEntityType.All,
  requestFilteredQuery: {} as IURLQuery,
  responseFilteredQuery: {} as IURLQuery,
  cookiesConsent: {
    isManageModalOpen: false,
    WCPConsent: {
      Advertising: false,
      Analytics: false,
      Required: true,
      SocialMedia: false,
    },
    isConsentRequired: false,
  },
  siteEnvironment: '',
  newNpsEnabled: false,
  pcaInstanceInitialized: false,
  npsLogicVerified: false,
  reviewsAPIVersion: enrichReviewCommentsApiVersion,
  showContextPanel: false,
  commerceApiHost: '',
  storefrontName: null,
  marketplaceLeadsHost: '',
  marketplaceLeadsAudienceId: '',
  agreementAppId: '',
};

export const initialServiceDataState: IServiceDataState = {
  idMap: {},
  dataList: [] as Service[],
  recommendedServices: {
    whatsNewService: [],
    trendingService: [],
  },
  countriesList: {} as ServiceCountries,
  dataMap: {} as IDataMap,
  dataMapLoaded: false,
  entityIdFailed: '',
  entityIdLoading: '',
  activeFilters: [] as IDataValues[],
  count: 0,
  curatedData: {},
  dataLoaded: {},
  curatedDataLoaded: {},
  subsetData: [] as Service[],
  subsetSearchQuery: '',
  tileDataRequestId: '',
  entityType: Constants.EntityType.Service,
  selectedCountry: Constants.usCountryCode.toUpperCase(),
  selectedRegion: Constants.allStatesCode.toUpperCase(),
  relatedAppsItems: {
    entityId: '',
    items: {
      suggestedItems: [],
      saasLinkingItems: [],
      linkedAddIns: [],
    },
  },
  appReviewsData: initialAppReviewsDataState,
  linkedInProductGroup: null,
};

export const initialCheckoutState: IAppCheckoutState = {
  items: [],
  customers: null,
  activeCustomer: null,
  publisherToken: null,
  error: false,
  currentStep: 0,
  purchaseAttempt: 0,
  billingAddress: null,
  checkoutId: '',
};

export const initialPartnersState: IPartnersState = {
  filterLocation: null,
  contactDetails: null,
};

export const initialState: IState = {
  search: initialSearchState,
  modal: initialModalState,
  apps: initialAppDataState,
  cloudsIndustry: initialCloudsIndustryDataState,
  users: initialUserDataState,
  config: initialConfigState,
  services: initialServiceDataState,
  dynamicCampaign: initialDynamicCampaignState,
  userFavourite: initialUserFavouriteState,
  checkout: initialCheckoutState,
  partners: initialPartnersState,
};

// This function does a deep copy a 'DataMap' object
// It is sometimes used (in the server code) after calling 'performFilter' to
// ignore objects that have a 'count' property set to '0'.  This removes 'filters'
// from the filter pane that aren't applicable to a specific group of data
export function deepCopyDataMap(DataMap: any): any {
  const newDataMap: any = {};
  if (DataMap == null || typeof DataMap !== 'object') {
    return DataMap;
  } else if (DataMap.count === 0) {
    return null;
  }
  for (const k in DataMap) {
    const prop = DataMap[`${k}`];
    const copy = deepCopyDataMap(prop);
    if (copy) {
      newDataMap[`${k}`] = copy;
    }
  }
  return newDataMap;
}

export function copyState<T>(oldState: T): T {
  const newState: any = {};

  for (const k in oldState) {
    newState[`${k}`] = oldState[`${k}`];
  }

  return newState as T;
}
