import React from 'react';
import { ILocContext } from '@shared/interfaces/context';
import { Text, Icon, Label } from '@fluentui/react';
import type { IFontStyles, IIconStyles } from '@fluentui/react';
import classNames from 'classnames';
import { NeutralColors } from '@fluentui/theme';

export interface IRatingsInfoProps {
  avgRating: number;
  ratingsCount: number;
  key: string;
  title: string;
  context: ILocContext;
  textSize?: keyof Pick<IFontStyles, 'smallPlus' | 'medium'>;
  starClass?: string;
  ratingClass?: string;
}

export const RatingsInfo = ({
  avgRating,
  ratingsCount,
  key,
  title,
  context,
  textSize,
  starClass,
  ratingClass,
}: IRatingsInfoProps) => {
  const styles: IIconStyles = {
    root: [
      'detailsRatingStar',
      textSize
        ? {
            fontSize: 13,
            margin: '0 4px 0 0',
          }
        : {
            fontSize: 16,
            margin: '0 4px 3px 0',
          },
      !ratingsCount && { color: NeutralColors.gray90 },
    ],
  };
  return (
    <div key={`${key}DetailsRating`} className="ratingInfoSection">
      <Icon
        iconName={ratingsCount ? 'FavoriteStarFill' : 'FavoriteStar'}
        title={title || context.loc('RatingSummary_Title', 'Marketplace ratings')}
        styles={styles}
      />
      <Label className={classNames([starClass || 'detailsRatingAvgNumOfStars'])}>{avgRating.toFixed(1)}</Label>
      <Text className={classNames([ratingClass || 'detailsRatingNumOfRatingText'])} variant={textSize || 'medium'}>
        ({ratingsCount} {title})
      </Text>
    </div>
  );
};
