import * as React from 'react';
import SpzaComponent from './spzaComponent';
import { urlPush } from '../../shared/routerHistory';
import { IBuildHrefContext, ILocContext, ICommonContext } from './../interfaces/context';
import { ITelemetryData } from '@shared/Models';
import { getWindow } from '@shared/services/window';
import { Constants } from '@shared/utils/constants';
import { IProvider } from '@shared/services/telemetry/core/provider';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { logger } from '@src/logger';
import { omit } from 'lodash-es';

interface IInternalLinkProps {
  children: React.ReactNode;
  href?: string;
  scrollToTop?: any;
  onClick?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  additionalCommand?: (e: any) => void;
  telemetryDetails?: string;
  className?: string;
  role?: string;
  accEnabled?: boolean;
  title?: string;
}

export class InternalLink extends SpzaComponent<IInternalLinkProps, any> {
  private internalLinkElement: Element;
  private provider: IProvider;

  constructor(props: IInternalLinkProps, context: ILocContext & ICommonContext & IBuildHrefContext) {
    super(props, context);
    this.provider = SpzaInstrumentService.getProvider();
    this.addHiddenFocus = this.addHiddenFocus.bind(this);
    this.removeHiddenFocus = this.removeHiddenFocus.bind(this);
  }

  addHiddenFocus() {
    if (this.internalLinkElement) {
      this.internalLinkElement.classList.add('x-hidden-focus');
    }
  }

  removeHiddenFocus() {
    if (this.internalLinkElement) {
      this.internalLinkElement.classList.remove('x-hidden-focus');
    }
  }

  renderImpl() {
    const { telemetryDetails, ...rest } = this.props;
    let href: string = null;
    let onClick: (e: any) => void = null;
    let children: any = null;
    const aTagAccEnabled = rest.role !== 'checkbox' && rest.accEnabled ? 0 : -1;
    const otherProps = {};
    let additionalCommand: (e: any) => void = null;

    for (const p in omit(rest, 'accEnabled')) {
      const val: any = rest[`${p}`];
      if (p === 'href') {
        href = val;
      } else if (p === 'onClick') {
        onClick = val;
      } else if (p === 'children') {
        children = val;
      } else if (p === 'additionalCommand') {
        additionalCommand = val;
      } else {
        otherProps[`${p}`] = val;
      }
    }

    let callback: any;

    if (onClick) {
      callback = (e: any) => {
        if (additionalCommand) {
          additionalCommand(e);
        }

        onClick(e);
        e.preventDefault();
      };
    } else {
      callback = (e: any) => {
        if (additionalCommand) {
          additionalCommand(e);
        }

        e.preventDefault();
        urlPush(href, this.props.scrollToTop);
      };
    }

    const { scrollToTop, ...domProps } = otherProps as Partial<IInternalLinkProps>;
    return (
      <a
        {...domProps}
        href={href}
        onClick={(event) => {
          const payload: ITelemetryData = {
            page: getWindow().location.href,
            action: Constants.Telemetry.Action.Click,
            actionModifier: Constants.Telemetry.ActionModifier.InternalLink,
            details: telemetryDetails || JSON.stringify(href),
          };
          this.provider.probe<ITelemetryData>(Constants.Telemetry.ProbeName.LogInfo, payload);
          logger.info(payload.details, {
            action: payload.action,
            actionModifier: payload.actionModifier,
          });
          return callback(event);
        }}
        tabIndex={aTagAccEnabled}
        ref={(element) => (this.internalLinkElement = element)}
        onMouseDown={() => this.addHiddenFocus()}
        onBlur={() => this.removeHiddenFocus()}
      >
        {children}
      </a>
    );
  }
}
