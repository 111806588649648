import React from 'react';
import { IBillingCountry, ICustomMeter, IPrice, ISimpleSKU } from '@shared/Models';
import * as PropTypes from 'prop-types';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
// eslint-disable-next-line import/named
import { Text, IStyle, mergeStyleSets, IFontStyles } from '@fluentui/react';
import { getPricingLocale } from '@shared/utils/pricing';

export interface CustomMeterPricingProps {
  sku: ISimpleSKU;
  price: IPrice;
  billingCountry: IBillingCountry;
  variant?: keyof IFontStyles;
  locale?: string;
}

interface MetersIncludesProps {
  customMeter: ICustomMeter[];
  variant?: keyof IFontStyles;
}

interface MetersBreakdownProps {
  sku: ISimpleSKU;
  customMeter: ICustomMeter[];
  variant?: keyof IFontStyles;
  billingCountry: IBillingCountry;
  locale?: string;
}

interface Styles {
  customMeterRoot: IStyle;
  plusText: IStyle;
}

const classes = mergeStyleSets<Styles>({
  customMeterRoot: {
    whiteSpace: 'break-spaces',
  },
  plusText: {
    marginTop: 10,
  },
});

export const CustomMeterPricingMaxFractionDigits = 5;

const MetersInclude = ({ customMeter, variant }: MetersIncludesProps, context: ILocContext & ILocParamsContext) => {
  const includedMeters = customMeter.filter((cm) => cm.includedQuantity !== '0');

  if (!includedMeters.length) {
    return null;
  }

  return (
    <>
      <Text variant={variant} block>
        {context.loc('CustomMeter_Includes', 'Includes:')}
      </Text>
      {includedMeters.map((meter) => (
        <Text key={meter.availabilityId} block variant={variant}>
          {`${meter.title}: ${meter.includedQuantity}`}
        </Text>
      ))}
    </>
  );
};

const MetersBreakdown = (
  { sku, customMeter, billingCountry, variant, locale }: MetersBreakdownProps,
  context: ILocContext & ILocParamsContext
) => {
  const { countryCode } = billingCountry;

  return (
    <>
      <Text block className={classes.plusText} variant={variant}>
        {context.loc('CustomMeter_Plus', 'Plus:')}
      </Text>
      {customMeter.map((meter) => {
        const localeString = getPricingLocale({ locale, countryCode });
        const priceString = meter.rate.toLocaleString(localeString, {
          style: 'currency',
          currency: sku.currencyCode,
          maximumFractionDigits: CustomMeterPricingMaxFractionDigits,
        });

        return (
          <Text block key={meter.availabilityId} variant={variant}>
            {`${meter.title}: ${priceString} ${meter.unit.toLowerCase()}`}
          </Text>
        );
      })}
    </>
  );
};

export const CustomMeterPricing = ({ price, billingCountry, sku, variant, locale }: CustomMeterPricingProps) => {
  const { customMeter } = price;
  if (!customMeter?.length) {
    return null;
  }

  return (
    <div className={classes.customMeterRoot}>
      <MetersInclude customMeter={customMeter} variant={variant} />
      <MetersBreakdown sku={sku} billingCountry={billingCountry} customMeter={customMeter} variant={variant} locale={locale} />
    </div>
  );
};

(MetersInclude as any).contextTypes = {
  locParams: PropTypes.func,
  loc: PropTypes.func,
};

(MetersBreakdown as any).contextTypes = {
  locParams: PropTypes.func,
  loc: PropTypes.func,
};
