import React from 'react';
import { Icon, IStackTokens, Link, mergeStyleSets, Stack } from '@fluentui/react';
import { DataBIProps } from '@shared/interfaces/dataBi';

export interface IAppReviewCommentCollectionPaginationButtonProps extends DataBIProps {
  iconName: string;
  text: string;
  onClick: () => void;
}

const contentStyles = mergeStyleSets({
  stack: {
    alignItems: 'center',
  },
  link: {
    padding: '5px 0',
  },
});

const stackTokens: IStackTokens = {
  childrenGap: 8,
};

export const AppReviewCommentCollectionPaginationButton: React.FunctionComponent<IAppReviewCommentCollectionPaginationButtonProps> =
  ({ iconName, text, onClick, ...additionalProps }: IAppReviewCommentCollectionPaginationButtonProps) => {
    return (
      <Link className={contentStyles.link} onClick={onClick} {...additionalProps}>
        <Stack horizontal className={contentStyles.stack} tokens={stackTokens}>
          <Stack.Item>{text}</Stack.Item>
          <Stack.Item>
            <Icon iconName={iconName} />
          </Stack.Item>
        </Stack>
      </Link>
    );
  };
