/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useRef } from 'react';
import { Callout, CommandBar, CommandBarButton, DirectionalHint, mergeStyleSets } from '@fluentui/react';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { IAppReview } from '@shared/Models';
import * as PropTypes from 'prop-types';

export interface IAppReviewItemToolbarProps {
  onToggleComments: () => void;
  onMarkAsHelpful: () => void;
  onReportAbuse: () => void;
  review: IAppReview;
  isMarkedAsHelpful?: boolean;
  showMarkAsHelpfulThanks: boolean;
}

const contentStyles = mergeStyleSets({
  reviewButtonsStack: {
    padding: 0,
  },
  markedAsHelpfulThanksCallout: {
    padding: '8px 24px',
  },
});

export const AppReviewItemToolbar: React.FunctionComponent<IAppReviewItemToolbarProps> = (
  {
    onToggleComments = () => {},
    onReportAbuse = () => {},
    onMarkAsHelpful = () => {},
    review,
    isMarkedAsHelpful = false,
    showMarkAsHelpfulThanks,
  }: IAppReviewItemToolbarProps,
  context: ILocParamsContext & ILocContext
) => {
  const markAsHelpfulButtonRef = useRef(null);
  const toolbarButtonsContainerRef = useRef(null);

  const initializeItems = useCallback(() => {
    const items: ICommandBarItemProps[] = [];

    items.push({
      key: 'markAsHelpful',
      onClick: onMarkAsHelpful,
      iconProps: { iconName: !isMarkedAsHelpful ? 'Like' : 'LikeSolid' },
      text: context.locParams(
        'ReviewItem_MarkAsHelpfulButton_Label',
        [review.mark_as_helpful_count.toString()],
        'Mark as helpful ({0})'
      ),
      commandBarButtonAs: ({ key, onClick, iconProps, text }: ICommandBarItemProps) => {
        const buttonProps = {
          key,
          role: 'button',
          onClick,
          iconProps,
          text,
          elementRef: markAsHelpfulButtonRef,
        } as IButtonProps;
        return <CommandBarButton {...buttonProps} role="menuitem" />;
      },
    });

    items.push({
      key: 'comments',
      onClick: onToggleComments,
      iconProps: { iconName: 'Chat' },
      text: context.locParams('ReviewItem_CommentsButton_Label', [review.comments_count.toString()], 'Comments ({0})'),
    });

    items.push({
      key: 'report',
      onClick: onReportAbuse,
      iconProps: { iconName: 'Flag' },
      text: context.loc('ReportButton_Label', 'Report review'),
    });

    return items;
  }, [isMarkedAsHelpful, onMarkAsHelpful]);

  const items = initializeItems();

  return (
    <div className="appReviewButtons" ref={toolbarButtonsContainerRef}>
      <CommandBar
        items={items}
        overflowButtonProps={{ menuProps: { isBeakVisible: false, items: [] } }}
        styles={{ root: contentStyles.reviewButtonsStack }}
      />
      {showMarkAsHelpfulThanks && (
        <Callout
          directionalHint={DirectionalHint.topCenter}
          className={contentStyles.markedAsHelpfulThanksCallout}
          target={markAsHelpfulButtonRef.current ? markAsHelpfulButtonRef : toolbarButtonsContainerRef}
          doNotLayer
        >
          {context.loc('ReviewItem_MarkAsHelpful_Submitted_Thanks_Label', 'Thank you')}
        </Callout>
      )}
    </div>
  );
};

(AppReviewItemToolbar as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  reportAbuseCallback: PropTypes.func,
};
