import { matchPath } from 'react-router-dom';
import { getLocalStorageItem, saveLocalStorageItem } from '@shared/utils/browserStorageUtils';
import { INpsMetadata, INpsPersistentMetadata, NpsPersistentJsonMetadata, NpsEvent } from '@shared/Models';
import { getFeedbackSubmittedTime, getComscorePresentedTime } from '@appsource/utils';
import { getWindow } from '@shared/services/window';
import { routes } from '@shared/routerHistory';

const npsMaxDeclinedDays = 14;
const npsMaxDeclinedDaysInMs = npsMaxDeclinedDays * 1000 * 3600 * 24;

const npsMaxSubmittedDays = 90;
const npsMaxSubmittedDaysInMs = npsMaxSubmittedDays * 1000 * 3600 * 24;

const feedbackMaxSubmittedDays = 14;
const feedbackSubmittedDaysInMs = feedbackMaxSubmittedDays * 1000 * 3600 * 24;

const comscoreMaxSubmittedDays = 14;
const comscoreMaxSubmittedDaysInMs = comscoreMaxSubmittedDays * 1000 * 3600 * 24;

const npsLocalStorageKey = 'nps';

let npsModule: Nps = null;

export const getNpsModule = (): Nps => {
  return npsModule;
};

const getEmptyNpsPersistentMetadata = (): INpsPersistentMetadata => {
  return {
    lastSubmittedTime: null,
    lastDismissedTime: null,
    lastCtaTime: null,
  };
};

const getEmptyNpsMetadata = (): INpsMetadata => {
  return {
    ...getEmptyNpsPersistentMetadata(),
    pdpViewsCount: 0,
    searchActionsCount: 0,
    filterActionsCount: 0,
    galleryChangesCount: 0,
    categoryChangesCount: 0,
    paginationActionsCount: 0,
    seeAllActionsCount: 0,
  };
};

const getNpsFromLocalStorage = (): INpsPersistentMetadata => {
  const npsMetadataTimesString: string = getLocalStorageItem(npsLocalStorageKey);
  try {
    const npsMetadata = JSON.parse(npsMetadataTimesString) as NpsPersistentJsonMetadata;
    return {
      lastSubmittedTime: Date.parse(npsMetadata?.lastSubmittedTime) ? new Date(npsMetadata.lastSubmittedTime) : null,
      lastDismissedTime: Date.parse(npsMetadata?.lastDismissedTime) ? new Date(npsMetadata.lastDismissedTime) : null,
      lastCtaTime: Date.parse(npsMetadata?.lastCtaTime) ? new Date(npsMetadata.lastCtaTime) : null,
    };
  } catch (err) {
    return getEmptyNpsPersistentMetadata();
  }
};

const comscoreAlreadySubmitted = () => {
  const lastPresentedTime = getComscorePresentedTime();
  if (!lastPresentedTime || !lastPresentedTime.getTime?.()) return false;
  return Date.now() - lastPresentedTime.getTime() < comscoreMaxSubmittedDaysInMs;
};

const feedbackAlreadySubmitted = () => {
  const lastSubmittedTime = getFeedbackSubmittedTime();
  if (!lastSubmittedTime || !lastSubmittedTime.getTime?.()) return false;
  return Date.now() - lastSubmittedTime.getTime() < feedbackSubmittedDaysInMs;
};

const otherFeedbacksAlreadySubmitted = (): boolean => {
  return feedbackAlreadySubmitted() || comscoreAlreadySubmitted();
};

const storeNpsModule = () => {
  if (npsModule) {
    const { lastCtaTime, lastDismissedTime, lastSubmittedTime } = npsModule.metadata;
    const objectToStore: INpsPersistentMetadata = {
      lastCtaTime,
      lastDismissedTime,
      lastSubmittedTime,
    };
    saveLocalStorageItem(npsLocalStorageKey, JSON.stringify(objectToStore));
  }
};

const homePageMatch = () => {
  const currentPathname = getWindow().location.pathname.toLowerCase();
  return !!matchPath(`/:locale${routes.home.getPath()}`, currentPathname) || !!matchPath('/:locale', currentPathname);
};

class Nps {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    private npsMetadata: INpsMetadata,
    private checkForOtherFeedbacks: () => boolean,
    private onVerified: (metadata: INpsMetadata) => void
  ) {}

  get metadata(): INpsMetadata {
    return this.npsMetadata;
  }

  private hasDeclinedBefore(): boolean {
    const lastDismissedTime = this.npsMetadata.lastDismissedTime;
    if (!lastDismissedTime || !lastDismissedTime.getTime?.()) return false;
    return Date.now() - lastDismissedTime.getTime() < npsMaxDeclinedDaysInMs;
  }

  private hasSubmittedBefore(): boolean {
    const lastSubmittedTime = this.npsMetadata.lastSubmittedTime;
    if (!lastSubmittedTime || !lastSubmittedTime.getTime?.()) return false;
    return Date.now() - lastSubmittedTime.getTime() < npsMaxSubmittedDaysInMs;
  }

  private hasCtaBefore(): boolean {
    const lastCtaTime = this.npsMetadata.lastCtaTime;
    if (!lastCtaTime || !lastCtaTime.getTime?.()) return false;
    return lastCtaTime.getTime() < Date.now();
  }

  private calculateTotalActions(): number {
    const metadata = this.npsMetadata;

    return (
      metadata.categoryChangesCount +
      metadata.pdpViewsCount +
      metadata.filterActionsCount +
      metadata.searchActionsCount +
      metadata.seeAllActionsCount +
      metadata.galleryChangesCount +
      metadata.paginationActionsCount
    );
  }

  private checkForCompliance() {
    if (this.hasDeclinedBefore() || this.hasSubmittedBefore() || this.checkForOtherFeedbacks()) return;
    const totalActionsCount = this.calculateTotalActions();
    if ((this.hasCtaBefore() && totalActionsCount >= 2) || totalActionsCount >= 10) {
      this.onVerified?.(this.npsMetadata);
    }
  }

  public ctaClicked(trigger = true) {
    if (!homePageMatch()) {
      if (!(this.hasDeclinedBefore() || this.hasSubmittedBefore() || this.checkForOtherFeedbacks())) {
        this.npsMetadata.lastCtaTime = new Date();
        if (trigger) {
          this.onVerified?.(this.npsMetadata);
        }
      }
      this.npsMetadata.lastCtaTime = new Date();
      storeNpsModule();
    }
  }

  public npsSubmitted() {
    this.npsMetadata.lastSubmittedTime = new Date();
    storeNpsModule();
  }

  public npsDismissed() {
    this.npsUpdateLastDismissTime();
  }

  public npsUpdateLastDismissTime() {
    this.npsMetadata.lastDismissedTime = new Date();
    storeNpsModule();
  }

  public increaseActionsCounter(event: NpsEvent) {
    if (!homePageMatch()) {
      this.npsMetadata[`${event}`] += 1;
      this.checkForCompliance();
    }
  }
}

export const initializeNpsModule = (onVerify: () => void): void => {
  const npsPersistentMetadata = getNpsFromLocalStorage();
  const npsMetadata = {
    ...getEmptyNpsMetadata(),
    ...npsPersistentMetadata,
  };
  npsModule = new Nps(npsMetadata, otherFeedbacksAlreadySubmitted, onVerify);
};
