import * as React from 'react';
import SpzaComponent from './../spzaComponent';
import Animation from '../animation';

export default class AnimationModal extends SpzaComponent<any, any> {
  renderImpl() {
    return (
      <div role="dialog" aria-label="animation is now open and playing" className="animationModalClass">
        <div className="animationCanvas">
          <Animation isCircular={true} />
        </div>
      </div>
    );
  }
}
