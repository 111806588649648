import { Request } from 'express';
import * as React from 'react';
import { Constants } from '../utils/constants';
import { getTrimmedWords } from './../utils/stringUtils';

enum css {
  searchInputClassName = 'resizable-input',
  searchButtonClassName = '.spza-c-search .react-selectize-control .react-selectize-toggle-button-container .c-glyph',
  searchInputIdName = 'marketplace-selectize-search-input',
  searchDivInputClassName = ' .spza-header-search-section',
  searchFocusInputClassName = ' .spza-header-row-segment-middle',
  headerPrefixClassName = '.spza-header-',
}

export function getSearchElement(prefix: string, key: string): HTMLElement {
  const elements = document.querySelectorAll(prefix + key);
  const searchInput = document.querySelector(prefix + css.searchDivInputClassName);
  let searchElement: HTMLElement = null;
  if (elements) {
    for (let i = 0; i < elements.length; i++) {
      if (elements[`${i}`].contains(searchInput)) {
        searchElement = elements[`${i}`] as HTMLElement;
        break;
      }
    }
    return searchElement;
  }
}

export function onKeyBlurOptionHandle(event: React.KeyboardEvent<Element>, componentKey: string) {
  const searchInput = getSearchElement(
    `${css.headerPrefixClassName}${componentKey.substring(0, componentKey.indexOf('-'))}`,
    css.searchFocusInputClassName
  );
  if (searchInput) {
    searchInput.classList.remove('spza-header-row-segment-middle-focus');
  }
}

export function onKeyFocusOptionHandle(event: React.KeyboardEvent<Element>, componentKey: string) {
  if (event.keyCode === Constants.SystemKey.Tab) {
    const searchInput = getSearchElement(
      `${css.headerPrefixClassName}${componentKey.substring(0, componentKey.indexOf('-'))}`,
      css.searchFocusInputClassName
    );
    if (searchInput) {
      searchInput.classList.add('spza-header-row-segment-middle-focus');
    }
  }
}

/**
 * This method is to escape any special characters required on https://aka.ms/azure-search-full-query
 * In that way, the escaped search text could then be used as search queries when calling azure search api
 *
 * @param rawSearchText raw search text from user input
 * @param excludeQuotes true if double quotes are to be excluded from the escape sequence.
 */
export function getEscapedSearchText(rawSearchText: string, excludeQuotes = false): string {
  // characters required to escape, reference: https://aka.ms/azure-search-full-query
  const charactersToEscape: string = '+ - && || ! ( ) { } [ ] ^ ~ ? : / \\ * AND OR NOT' + (excludeQuotes ? '' : ' "');

  // make an array of characters to escape, each element is a string, which consists of characters,
  // e.g. an array of characters to escape ['+', '&&'], '+' and '&&' are strings
  // and escape every character in each string
  // e.g. ['+', '&&'] => ['\+', '\&\&']
  // the purpose of this array is to use in regex pattern
  const escapedCharactersList: string[] = getTrimmedWords(charactersToEscape)
    // get each string, e.g. +, &&
    .map((stringToEscape: string) =>
      stringToEscape

        // get each character in current string
        .split('')

        // escape each character, e.g. \+, \&
        .map((character: string) => `\\${character}`)

        // rejoin each escaped character to form as an escaped string, e.g. \+, \&\&
        .join('')
    );

  // escape pattern is a regex pattern, by OR-ing each escaped string of escaped array,
  // which means that'd be a match, if an input string include any of escaped string in the escaped array
  const escapePattern = `${escapedCharactersList

    // wrap parenthesis around each element as a regex group
    .map((escapedString: string) => `(${escapedString})`)

    // make regex to match any of them
    .join('|')}`;

  // use global to match all occurrences of escape pattern
  // eslint-disable-next-line security/detect-non-literal-regexp
  const escapeRegex = new RegExp(escapePattern, Constants.RegExpFlag.Global);

  // escape input string whenever there's a match with escape pattern
  const escapedSearchText: string = rawSearchText.replace(
    escapeRegex,
    (matchedString: string) =>
      // escape each character in each matched substring
      `${matchedString
        .split('')
        .map((character: string) => `\\${character}`)
        .join('')}`
  );

  return escapedSearchText;
}

/**
 * Perform a search with the following guidelines:
 * 1) Escape special characters.
 * 2) Add a '+' character as a prefix for a search that starts and ends with a '"'.
 * 3) Add 'And' between the search words.
 * @param rawSearchText Raw search text from user input.
 */
export function getSearchQuery(rawSearchText = '') {
  rawSearchText = typeof rawSearchText === 'string' ? rawSearchText : '';
  if (rawSearchText.length > Constants.Search.maxSearchTextLength) {
    const isCutInTheMiddleOfWord: boolean =
      rawSearchText[Constants.Search.maxSearchTextLength - 1] !== ' ' &&
      rawSearchText[Constants.Search.maxSearchTextLength] !== ' ';
    rawSearchText = rawSearchText.slice(0, Constants.Search.maxSearchTextLength);
    const splittedBySpaces = rawSearchText.split(/\s+/);
    if (splittedBySpaces.length > 1 && isCutInTheMiddleOfWord) {
      splittedBySpaces.pop();
    }

    rawSearchText = splittedBySpaces.join(' ');
  }

  // catches all phrases surrounded by double quotes.
  const re = /"(.*?)"/g;
  const phrases = rawSearchText.match(re);
  const searchPhrases = phrases ? phrases.map((p) => `+${getEscapedSearchText(p)}`) : [];
  const words = rawSearchText
    .replace(re, '')
    .split(' ')
    .map((w) => w.trim())
    .filter((w) => !!w)
    .map((w) => `${getEscapedSearchText(w)}`);

  const searchTerm = [...searchPhrases, ...words].join(' ');

  return searchTerm;
}

export function parseSearchAction(request: Request): Constants.Search.ActionType {
  return request.headers?.searchactiontype === Constants.Search.ActionType.Suggestions
    ? Constants.Search.ActionType.Suggestions
    : Constants.Search.ActionType.Search;
}

export function removeDuplicateAddIns<T>(
  apps: T[],
  idField: keyof T
): { apps: T[]; bundleCount: number; duplicateAddInsCount: number } {
  const linkedAddIns = Constants.SearchFieldNames.linkedAddIns;

  let bundleCount = 0;
  const linkedAddInIds: { [id: string]: boolean } = {};

  for (const currentApp of apps) {
    if (currentApp && currentApp[`${linkedAddIns}`] && currentApp[`${linkedAddIns}`].length > 0) {
      bundleCount++;
      for (const currentAddIns of currentApp[`${linkedAddIns}`]) {
        if (currentAddIns && currentAddIns[`${idField}`]) {
          linkedAddInIds[currentAddIns[`${idField}`]] = true;
        }
      }
    }
  }

  const uniqueApps: T[] = apps.filter((currentApp) => {
    const currentAppId: string = (currentApp && currentApp[`${idField}`] && currentApp[`${idField}`].toString()) || '';

    return !(currentAppId && linkedAddInIds[`${currentAppId}`]);
  });

  const duplicateAddInsCount = apps.length - uniqueApps.length;

  return { apps, bundleCount, duplicateAddInsCount };
}
