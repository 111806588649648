import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Text, mergeStyleSets, Stack, ScreenWidthMinXLarge } from '@fluentui/react';
import type { IStackTokens } from '@fluentui/react';
import { IAppDataItem } from '@shared/Models';
import { IBuildHrefContext, ICTACallbackContext } from '@shared/interfaces/context';
import { Constants } from '@shared/utils/constants';
import { routes } from '@shared/routerHistory';
import { NeutralColors } from '@fluentui/theme';
import { LinkedItem } from '@shared/components/linkedItem';
import classNames from 'classnames';

export interface IAppDetailsLinkedItemsProps {
  headerString: string;
  linkedItems: IAppDataItem[];
  isSaaSRendering: boolean;
  productId: string;
  dataBiId: string;
  nationalCloud: string;
  app?: IAppDataItem;
  billingCountryCode?: string;
  isLoadingUserData?: boolean;
  locale?: string;
}

const titleAndTileStackStyle = { childrenGap: 16 };

const appDetailsLinkedItemsStyles = mergeStyleSets({
  container: {
    marginTop: 20,
  },
  linkedItemsContainer: {
    marginTop: 15,
  },
  header: {
    color: NeutralColors.gray130,
    fontWeight: 600,
  },
  LinkedItemsStyle: {
    width: '456px',
    [`@media (max-width: ${ScreenWidthMinXLarge}px)`]: {
      width: '312px',
    },
  },
});

const horizontalGapStackTokens: IStackTokens = {
  childrenGap: 24,
};

const getLinkedItemLink = ({
  linkedItem,
  productId,
  context,
}: {
  linkedItem: IAppDataItem;
  productId: string;
  context: IBuildHrefContext;
}): string => {
  return context.buildHref(
    routes.appDetails,
    {
      productId,
      entityId: linkedItem.entityId,
    },
    { tab: 'Overview' }
  );
};

const addinOnClick = ({
  linkedItem,
  context,
  event,
}: {
  linkedItem: IAppDataItem;
  context: ICTACallbackContext;
  event: React.MouseEvent<HTMLElement>;
}) => {
  const lastCtaType = linkedItem.ctaTypes[linkedItem.ctaTypes.length - 1];
  context.ctaCallback({
    entity: linkedItem,
    entityType: Constants.EntityType.App,
    ctaType: lastCtaType,
    actionModifier: Constants.Telemetry.ActionModifier.AppDetailCTAButton,
    hasLicense: false,
  });
  event.preventDefault();
};

const renderLinkedItems = ({
  billingCountryCode,
  context,
  productId,
  linkedItems,
  isSaaSRendering,
  isLoadingUserData,
  locale,
}: {
  billingCountryCode: string;
  context: IBuildHrefContext & ICTACallbackContext;
  productId: string;
  linkedItems: IAppDataItem[];
  isSaaSRendering: boolean;
  isLoadingUserData?: boolean;
  locale?: string;
}): JSX.Element => {
  return (
    <Stack horizontal wrap tokens={horizontalGapStackTokens}>
      {linkedItems.map((item, index) => (
        <Stack.Item key={index} className={classNames([appDetailsLinkedItemsStyles.LinkedItemsStyle])}>
          <LinkedItem
            linkedItem={item}
            billingCountryCode={billingCountryCode}
            isInAppDetail
            isSaaSRendering={isSaaSRendering}
            ctaButtonClick={
              isSaaSRendering
                ? ({ linkedItem }: { linkedItem: IAppDataItem; event: React.MouseEvent<HTMLElement> }) => {
                    getLinkedItemLink({ linkedItem, productId, context });
                  }
                : ({ linkedItem, event }: { linkedItem: IAppDataItem; event: React.MouseEvent<HTMLElement> }) => {
                    addinOnClick({ linkedItem, context, event });
                  }
            }
            isLoadingUserData={isLoadingUserData}
            getLinkedItemLink={getLinkedItemLink}
            locale={locale}
          />
        </Stack.Item>
      ))}
    </Stack>
  );
};

export const AppDetailsLinkedItemsComponent: React.FunctionComponent<IAppDetailsLinkedItemsProps> = (
  {
    billingCountryCode,
    productId,
    linkedItems,
    isSaaSRendering,
    isLoadingUserData,
    headerString,
    locale,
  }: IAppDetailsLinkedItemsProps,
  context: IBuildHrefContext & ICTACallbackContext
) => {
  return linkedItems?.length ? (
    <Stack
      tokens={titleAndTileStackStyle}
      className={classNames(['linkedItemsContainer', [appDetailsLinkedItemsStyles.container]])}
    >
      <Stack.Item>
        {headerString && (
          <Text variant="mediumPlus" className={appDetailsLinkedItemsStyles.header}>
            {headerString}
          </Text>
        )}
      </Stack.Item>
      <Stack.Item>
        {renderLinkedItems({
          billingCountryCode,
          productId,
          linkedItems,
          isSaaSRendering,
          isLoadingUserData,
          context,
          locale,
        })}
      </Stack.Item>
    </Stack>
  ) : null;
};

(AppDetailsLinkedItemsComponent as any).contextTypes = {
  buildHref: PropTypes.func,
  ctaCallback: PropTypes.func,
};

export const AppDetailsLinkedItems = AppDetailsLinkedItemsComponent;
