import { Constants } from '@shared/utils/constants';
import { logError } from '@shared/utils/httpClientUtil';
import { MPRPAppData } from '@shared/Models';
import { getAppConfig } from '@shared/services/init/appConfig';
import { logger } from '@src/logger';
import * as httpProtocol from './httpProtocol';

export function getProduct(productId: string, hideKey: string, market = 'US'): Promise<MPRPAppData> {
  const endpoint = `${getAppConfig('MPRPHost')}/offers/${productId}`;
  const options = {
    requireCorrelationId: true,
    stringifyPostData: false,
    allowOrigin: true,
    authenticationType: httpProtocol.AuthenticationTypes.Unauthenticated,
    returnRawResponse: false,
  };

  const get = httpProtocol.get(endpoint, options);

  if (hideKey) {
    get.addQueryEntry(`${Constants.QueryStrings.hideKeys}[0]`, hideKey);
  }

  return get
    .addQueryEntry(Constants.QueryStrings.apiVersion, Constants.mprpApiVersion)
    .addQueryEntry(Constants.QueryStrings.market, market)
    .request()
    .catch((error: Error) => {
      logError('getProductById', error);
      logger.error('getProductById', {
        action: Constants.Telemetry.Action.getProduct,
        actionModifier: Constants.Telemetry.ActionModifier.Error,
        error,
      });
      return Promise.reject(error);
    });
}
