import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { Provider, useStore } from 'react-redux';
import type { AnyAction, Dispatch } from 'redux';
import { unstable_HistoryRouter as HistoryRouter, Routes, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { appHistory } from '@shared/routerHistory';
import { useHydration } from '@shared/hooks/useHydration';

import { IState } from '@src/State';
import { ensureCheckoutApp } from '@shared/actions/thunkActions';
import { EnsureAsyncDataSSR } from '@shared/hooks/useLocationChange';

const Checkout = React.lazy(() => import('./checkout'));

/**
 * This component is in charge of hydrating only the PurchaseBlends Checkout wrapper by using React 17's hydrate function
 * on a pre-defined <div /> root because The PurchaseBlends UI does not support React 18.
 * @param _ props is never used
 * @param context React.context inherited by <AppView />
 * @returns JSX
 */
const LegacyCheckoutHydration = (_: never, context: Record<string, () => string | string>) => {
  const checkoutDOMRef = useRef<HTMLDivElement>(null);
  const [contextValue] = useState(() => {
    return { ...context };
  });

  const isHydrated = useHydration();
  const store = useStore<IState>();

  const renderCheckout = useCallback(() => {
    render(
      <Provider store={store}>
        <HistoryRouter history={appHistory}>
          <Routes>
            <Route
              path=":locale/marketplace/checkout/:entityId"
              element={
                <Suspense fallback={null}>
                  <Checkout context={contextValue} />
                </Suspense>
              }
            />
          </Routes>
        </HistoryRouter>
      </Provider>,
      checkoutDOMRef.current
    );
  }, [store, contextValue]);

  useEffect(() => {
    if (!isHydrated) {
      return;
    }

    const current = checkoutDOMRef.current;
    if (current && !current.children.length) {
      renderCheckout();
    }
    return () => {
      if (current) {
        unmountComponentAtNode(current);
      }
    };
  }, [checkoutDOMRef, renderCheckout, isHydrated]);

  return <div data-react-legacy-root="checkout" ref={checkoutDOMRef} />;
};

LegacyCheckoutHydration.contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  locDateString: PropTypes.func,
  locale: PropTypes.string,
  buildHref: PropTypes.func,
  ctaCallback: PropTypes.func,
  contactCallback: PropTypes.func,
  openTileCallback: PropTypes.func,
  renderErrorModal: PropTypes.func,
  reportAbuseCallback: PropTypes.func,
  getAppHeaderHeight: PropTypes.func,
};

export const ensureAsyncDataSSR: EnsureAsyncDataSSR = async (
  dispatch: Dispatch<AnyAction>,
  getState: () => IState,
  params: Record<string, string>
) => {
  const { entityId } = params;
  await dispatch(ensureCheckoutApp({ entityId }));
};

export default LegacyCheckoutHydration;
