import { getFormattedString } from './appUtils';
import { loadPartnerManifest } from './partnerManifestLoader';
const config = loadPartnerManifest();
export function getPowerBIEmbedReportURL(groupId: string, reportId: string) {
  let handOffUrl = config.fieldHub.embedEndpoint;
  let urlArgs = [reportId, groupId];

  return getFormattedString(handOffUrl, urlArgs);
}

export function getPowerBIEmbedParams(reportName: string) {
  let defaultFieldObj = config.fieldHub.queryParams['Default'];
  let fieldObj = reportName && typeof reportName === 'string' ? config.fieldHub.queryParams[reportName] : defaultFieldObj;

  let result = {
    groupId: config.fieldHub.groupId,
    reportId: config.fieldHub.reportId,
    sectionId: defaultFieldObj.sectionId,
    datasetId: config.fieldHub.datasetId,
  };

  if (fieldObj && fieldObj.sectionId) {
    result.sectionId = fieldObj.sectionId;
  }

  return result;
}
