import { IHttpOption, AuthenticationTypes, IHttpHeader } from '@shared/services/http/httpProtocol';
import { Constants } from '@shared/utils/constants';
import { logger } from '@src/logger';
import { logError } from '@shared/utils/httpClientUtil';
import * as httpProtocol from './httpProtocol';

const commerceHttpOptions: IHttpOption = {
  requireCorrelationId: true,
  stringifyPostData: false,
  allowOrigin: true,
  authenticationType: AuthenticationTypes.CommerceApi,
  setBearerHeader: true,
  returnRawResponse: true,
};

/**
 * PreWarm an account
 * @param Object containing named parameters for the call
 * @returns Promise with the response, containing a list of comments for the given review
 */
export async function preWarmAccount({
  addTestHeader,
  commerceApiHost,
}: {
  commerceApiHost: string;
  addTestHeader: boolean;
}): Promise<void> {
  try {
    const endpoint = `${commerceApiHost}/v1/my-org/purchaseService.PreloadData`;
    const post = httpProtocol.post(endpoint, commerceHttpOptions);

    const headers: IHttpHeader = addTestHeader ? { 'x-ms-test': 'test' } : {};
    post.addHeader(headers);

    return await post.request();
  } catch (error: any) {
    logError('preWarmingCommerceAPIAJAX', error);
    logger.error('preWarmingCommerceAPIAJAX', {
      action: Constants.Telemetry.Action.PreWarmAJAX,
      actionModifier: Constants.Telemetry.ActionModifier.Error,
      error,
    });
    return Promise.resolve(error);
  }
}
