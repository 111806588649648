import { IAccessToken, IUserDataState } from '@src/State';
import { Constants } from '@shared/utils/constants';
import { IAADIdTokenClaim, IMSAIdTokenClaim, UserSegment } from '@shared/Models';
import { jwtDecode } from 'jwt-decode';
import { AccountInfo } from '@azure/msal-browser';
import { isEmpty, startCase } from 'lodash-es';
import { getAppConfig } from '@shared/services/init/appConfig';
import { logger } from '@src/logger';
import { stringifyError } from '@shared/utils/errorUtils';

export const EMPTY_ACCESS_TOKEN: IAccessToken = {
  arm: '',
  graph: '',
  spza: '',
  pifd: '',
  commerce: '',
  jarvis: '',
  marketplaceLeads: '',
};

export const isMSAUser = ({ claims }: { claims: Partial<IAADIdTokenClaim | IMSAIdTokenClaim> }): boolean => {
  return !isEmpty(claims) && !Object.keys(claims).includes('oid');
};

export const getUserSegment = ({ claims }: { claims: Partial<IAADIdTokenClaim | IMSAIdTokenClaim> }) => {
  if (!claims || isEmpty(claims)) {
    return UserSegment.unauthenticated;
  }

  return isMSAUser({ claims }) ? UserSegment.consumer : UserSegment.business;
};

export const createGuestUser = (): Partial<IUserDataState> => {
  return {
    id: '',
    group: ['guest'],
    signedIn: false,
    tid: '',
    accessToken: EMPTY_ACCESS_TOKEN,
    oid: '',
    displayName: 'guest',
    givenName: '',
    puid: '',
    lastName: '',
    uniqueName: '',
    firstName: '',
    familyName: '',
    email: 'guest@',
    alternateEmail: '',
    isMSAUser: false,
    userSegment: UserSegment.unauthenticated,
    fieldHubUserType: Constants.FieldHubUserType.None,
    homeAccountId: null,
  };
};

export const createAuthenticatedUser = ({
  accessTokens,
  account,
  ssoStartTime,
}: {
  accessTokens: IAccessToken;
  account: AccountInfo;
  ssoStartTime: number | null;
}): Partial<IUserDataState> => {
  try {
    const { username, homeAccountId } = account;
    const { puid, tid, name, preferred_username: preferredUsername, oid } = account.idTokenClaims;
    const { spza } = accessTokens;
    if (!spza) throw new Error('Spza token is missing');
    const userClaims = jwtDecode<Partial<IAADIdTokenClaim | IMSAIdTokenClaim>>(spza);
    const { given_name: givenName, family_name: familyName } = userClaims;

    const user = {
      id: puid as string,
      group: ['aad'],
      signedIn: true,
      tid,
      displayName: startCase(name ?? username.split('@')[0]),
      givenName: startCase(givenName ?? name?.split(' ')?.[0] ?? username.split('@')[0]),
      firstName: startCase(givenName ?? name?.split(' ')?.[0] ?? username.split('@')[0]),
      familyName: startCase(familyName ?? name?.split(' ')?.[1] ?? username.split('@')[0]),
      lastName: startCase(familyName ?? name?.split(' ')?.[1] ?? username.split('@')[0]),
      uniqueName: startCase(familyName ?? name?.split(' ')?.[1] ?? username.split('@')[0]),
      puid: puid as string,
      email: preferredUsername,
      oid,
      alternateEmail: preferredUsername,
      isMSAUser: isMSAUser({ claims: userClaims }),
      userSegment: getUserSegment({ claims: userClaims }),
      fieldHubUserType: Constants.FieldHubUserType.None,
      homeAccountId,
      accessToken: accessTokens,
      graphApi: getAppConfig('graphApi'),
      refreshToken: '',
    };

    logger.info(
      JSON.stringify({
        isMSAUser: user.isMSAUser,
        UserSegment: user.userSegment,
        durationInMS: ssoStartTime ? Date.now() - ssoStartTime : null,
      }),
      {
        action: Constants.Telemetry.Action.Login,
        actionModifier: Constants.Telemetry.ActionModifier.CreateAuthenticatedUserSuccess,
      }
    );
    return user;
  } catch (error) {
    logger.error(stringifyError(error), {
      action: Constants.Telemetry.Action.Login,
      actionModifier: Constants.Telemetry.ActionModifier.CreateAuthenticatedUserFailure,
      error,
    });
    return createGuestUser();
  }
};
