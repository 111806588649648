import * as React from 'react';
import { buildStore } from '@shared/store/createStore';
import ErrorModal from '../containers/modals/errorModal';
import { render } from 'react-dom';
import { getWindow } from '../services/window';
import combineReducers from '../reducers/reducers';
import { isEmbedded } from '../utils/appUtils';
import thunk from 'redux-thunk';
import { IState } from '@src/State';
import { Provider } from 'react-redux';
import classNames from 'classnames';

declare let window: any;

export function renderErrorModal(store: any, isEmbed = false) {
  if (!store) {
    const initialState: IState = window.__INITIAL_STATE__;
    store = buildStore<IState>({ combinedReducers: combineReducers, initialState, middleware: [thunk] });

    isEmbed = initialState.config.isEmbedded;
  }

  const modalBackgroundClasses = classNames({
    spza_presentation: true,
    spza_background_1: true,
    spza_background_2: false,
  });

  const modalClasses = classNames({
    spza_dialog: true,
    spza_dialog_design2: false,
  });

  let dismissModal: any = null;
  if (!isEmbed) {
    dismissModal = () => {
      const href = getWindow().location.origin + '/home';
      getWindow().location.assign(href);
    };
  } else {
    dismissModal = () => {
      getWindow().parent.postMessage({ msgType: 'closeSaasMarketplace' }, '*');
    };
  }

  render(
    <Provider store={store}>
      <div className={classNames({ spza_root: !isEmbedded(), spza_root_embedded: isEmbedded() })}>
        <div className={modalClasses}>
          <div className={modalBackgroundClasses}>
            <ErrorModal dismissModal={dismissModal} />
          </div>
        </div>
      </div>
    </Provider>,
    document.getElementById('react-view')
  );
}
