import {
  IFuturePlan,
  IPrice,
  ISimpleSKU,
  ISKU,
  TermsEntity,
  IPricingInformation,
  IFuturePrices,
  IAvailabilityActions,
  TermsStrings,
  IStartingPrice,
} from '@shared/Models';
import { cloneDeep } from 'lodash-es';
import { logError } from '@shared/utils/httpClientUtil';
import { ILocDateStringContext } from '@shared/interfaces/context';
import { OfferType } from '@shared/utils/constants';

export enum OS {
  Windows = 1,
  Linux,
}

export interface IThirdPartyPricing {
  currencyCode: string;
  pricePerCore: { [meterID: string]: IPrice };
}

export interface ISKUInstance {
  id: string;
}

export interface IStorageSize {
  capacity: number;
  unit: string;
}

export interface IVMSKUInstance extends ISKUInstance {
  tier: string;
  category: string;
  coreCount: number;
  ram: IStorageSize;
  diskSize: IStorageSize;
  driveType: string;
  hardwareCost: { [region: string]: IPrice };
  hardwareCurrencyCode: string;
}
export interface IVMSKU extends ISKU {
  instances: IVMSKUInstance[];
  recommendedInstanceIDs: string[];
  createUiDefinitionEndpoint: string;
  thirdPartyPricing: IThirdPartyPricing;
  os: OS;
  isBYOL: boolean;
  startingPrice?: IStartingPrice;
}

const MONTH_UNIT = 'Month';
const YEAR_UNIT = 'Year';
const MONTH_THERM_UNIT = 'P1M';
const YEAR_THERM_UNIT = 'P1Y';

const isUnitEqual = (term: TermsEntity, termPrice: IPrice, mightContainMultiyearTerms: boolean): boolean => {
  if (mightContainMultiyearTerms) {
    if (!term?.termId || !termPrice?.termId) {
      return false;
    }
    return term.termId === termPrice.termId;
  }

  return (
    term &&
    termPrice &&
    ((term.termUnits === MONTH_THERM_UNIT && termPrice.unit === MONTH_UNIT) ||
      (term.termUnits === YEAR_THERM_UNIT && termPrice.unit === YEAR_UNIT))
  );
};

const filterDecreasePrices = (
  sku: ISimpleSKU,
  hasMonthlyPriceIncrease: boolean,
  hasYearlyPriceIncrease: boolean,
  hasMeterPriceIncrease: boolean
): ISimpleSKU => {
  if (!hasMeterPriceIncrease) {
    const increaseTermPrices = sku.termPrices.filter(
      (termPrice) =>
        (termPrice.unit === MONTH_UNIT && hasMonthlyPriceIncrease) || (termPrice.unit === YEAR_UNIT && hasYearlyPriceIncrease)
    );

    if (increaseTermPrices) {
      sku.termPrices = increaseTermPrices;
    } else {
      sku = null;
    }
  }

  return sku;
};

const updateTermPrices = (futurePlan: IFuturePlan, sku: ISimpleSKU): ISimpleSKU => {
  let hasMonthlyPriceIncrease = false;
  let hasYearlyPriceIncrease = false;
  let hasMeterPriceIncrease = false;
  sku.startDate = futurePlan.availabilities[0].startDate;
  const mightContainMultiyearTerms = sku.offerType === OfferType.SaaSApp;
  const increasedTermIds: string[] = [];

  futurePlan.availabilities.forEach((futureAvailability) => {
    // Update termPrice value
    const { actions } = futureAvailability;
    if (actions.includes(IAvailabilityActions.Purchase) && actions.includes(IAvailabilityActions.Browse)) {
      futureAvailability.terms.forEach((futureTerm) => {
        const term = sku.termPrices.find((termPrice) => isUnitEqual(futureTerm, termPrice, mightContainMultiyearTerms));

        if (!term) {
          return;
        }

        const wasPriceIncreased = futureTerm.price.listPrice > term.value;
        if (wasPriceIncreased) {
          if (mightContainMultiyearTerms) {
            increasedTermIds.push(term.termId);
          } else {
            hasMonthlyPriceIncrease = hasMonthlyPriceIncrease || term.unit === TermsStrings.MonthString;
            hasYearlyPriceIncrease = hasYearlyPriceIncrease || term.unit === TermsStrings.YearString;
          }
        }

        term.value = futureTerm.price.listPrice;
        if (term.billingPlan?.price && futureTerm.billingPlan?.price) {
          term.billingPlan.price = { ...futureTerm.billingPlan.price };
        }
      });
    }

    // Update customMeter price
    if (actions.includes(IAvailabilityActions.Consume) && actions.includes(IAvailabilityActions.Browse)) {
      sku.termPrices.forEach((termPrice) => {
        const meter = termPrice.customMeter.find(
          (customMeter) => customMeter.availabilityId === futureAvailability.currentAvailabilityId
        );
        if (meter) {
          if (futureAvailability.meter.price.listPrice > meter.rate) {
            hasMeterPriceIncrease = true;
          }
          meter.rate = futureAvailability.meter.price.listPrice;
        }
      });
    }
  });

  if (mightContainMultiyearTerms) {
    if (!hasMeterPriceIncrease) {
      sku.termPrices = sku.termPrices.filter((termPrice) => increasedTermIds.includes(termPrice.termId));
    }
    return sku;
  } else {
    return filterDecreasePrices(sku, hasMonthlyPriceIncrease, hasYearlyPriceIncrease, hasMeterPriceIncrease);
  }
};

const updateThirdPartyPricing = (futurePlan: IFuturePlan, sku: IVMSKU): IVMSKU => {
  const futurePricePerCore = {};
  sku.startDate = futurePlan.availabilities[0].startDate;

  futurePlan.availabilities.forEach((futureAvailability) => {
    if (futureAvailability.meter && sku.thirdPartyPricing.pricePerCore) {
      const currentMeter = sku.thirdPartyPricing.pricePerCore[futureAvailability.meter.meterId];

      if (
        currentMeter &&
        currentMeter.availabilityId === futureAvailability.currentAvailabilityId &&
        futureAvailability.meter.price.listPrice > currentMeter.value
      ) {
        currentMeter.value = futureAvailability.meter.price.listPrice;
        futurePricePerCore[futureAvailability.meter.meterId] = currentMeter;
      }
    }
  });

  sku.thirdPartyPricing.pricePerCore = futurePricePerCore;
  return Object.keys(futurePricePerCore).length ? sku : null;
};

const getFuturePricesSkus = (futurePlans: IFuturePlan[], currentSkus: ISKU[]): ISKU[] => {
  const futureSkus: ISKU[] = [];

  if (futurePlans && currentSkus) {
    futurePlans.forEach((futurePlan) => {
      const currentSku = currentSkus.find((skus) => skus.id === futurePlan.planId);

      if (!currentSku) {
        logError(`getFuturePricesSkus failed to find current sku: ${futurePlan.planId}`);
      } else if (!futurePlan.availabilities?.length) {
        logError(`getFuturePricesSkus futurePlan: ${futurePlan.planId} - not contains availabilities`);
      } else {
        let futureSku: ISKU = cloneDeep(currentSku);

        if ((futureSku as ISimpleSKU).termPrices) {
          futureSku = updateTermPrices(futurePlan, futureSku as ISimpleSKU);
        }

        if ((futureSku as IVMSKU).thirdPartyPricing) {
          futureSku = updateThirdPartyPricing(futurePlan, futureSku as IVMSKU);
        }

        if (futureSku) {
          futureSkus.push(futureSku);
        }
      }
    });
  }

  return futureSkus;
};

export const getFuturePricesInformation = (
  futurePrices: IFuturePrices,
  currentPrices: IPricingInformation
): IPricingInformation => {
  if (futurePrices && currentPrices) {
    const futureSkus = getFuturePricesSkus(futurePrices.plans, currentPrices.skus);
    if (futureSkus?.length) {
      return {
        billingRegion: currentPrices.billingRegion,
        skus: futureSkus,
      };
    }
  }
  return null;
};

export const getUTCDateString = (startDateUtcInMinutes: number, context: ILocDateStringContext): string => {
  return `${context.locDateString(startDateUtcInMinutes * 60000, { timeZone: 'UTC' })} (UTC)`;
};
