import * as React from 'react';
import {
  Dialog,
  DialogFooter,
  PrimaryButton,
  TextField,
  DefaultButton,
  Stack,
  RatingSize,
  Rating,
  Checkbox,
  Link,
  Text,
} from '@fluentui/react';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { IAppReview } from '@shared/Models';
import { Constants } from '@shared/utils/constants';
import { getReviewModalDialogProps } from '@shared/utils/reviewsUtils';
import greyExclamationMark from '@shared/images/greyExclamationMark.svg';

export interface IReviewActionModal {
  context: ILocContext & ILocParamsContext;
  title: string;
  appTitle: string;
  appPublisher: string;
  userEmail: string;
  userDisplayName: string;
  appIcon: string;
  curRating: number;
  curReviewTitle: string;
  curReviewContent: string;
  isAnonymous: boolean;
  userReview: IAppReview;
  closeModal: () => void;
  handleRatingChange: (rating: number) => void;
  handleReviewTitleChange: (titleText: string) => void;
  handleReviewContentChange: (contentText: string) => void;
  handleSendEmailCheckbox: () => void;
  handleDisplayUserNameCheckbox: () => void;
  handleSubmitReview: () => void;
  handleDeleteReview: () => void;
}

export const renderOfferInfoSection = (appIcon: string, appTitle: string, appPublisher: string): JSX.Element => {
  return (
    <div className="ReviewActionModalAppDetails">
      <img className="ReviewActionModalAppIcon" src={appIcon} alt={`${appTitle} icon`} />
      <Stack className="ReviewActionModalAppNameAndPublisher">
        <Text className="ReviewActionModalAppName">{appTitle}</Text>
        <Text className="ReviewActionModalAppPublisher">{appPublisher}</Text>
      </Stack>
    </div>
  );
};

export const renderRatingSection = (
  context: ILocContext,
  IsvReplyExists: boolean,
  curRating: number,
  handleRatingChange: (rating: number) => void
): JSX.Element => {
  return (
    <Stack className="ReviewActionModalRating">
      <Text className="ReviewActionModalRatingTitle">
        {context.loc('ReviewActionModal_RatingSectionTitle', 'Rating')}{' '}
        <Text className="ReviewActionModalMandatoryFieldSign">*</Text>
      </Text>
      <Rating
        allowZeroStars
        disabled={IsvReplyExists}
        max={5}
        size={RatingSize.Large}
        rating={curRating}
        ariaLabel={context.loc('ReviewActionModal_RatingAriaLabel', 'Rating')}
        ariaLabelFormat={context.loc('ReviewActionModal_RatingAriaLabelFormat', `{0} of {1} stars`)}
        getAriaLabel={(rating: number, max: number) =>
          context.loc('ReviewActionModal_RatingAriaLabelReadOnly', `Rating value is ${rating} of ${max}`)
        }
        onChange={(event: React.FormEvent<HTMLElement>, rating: number) => handleRatingChange(rating)}
      />
    </Stack>
  );
};

export const renderLinksSection = (context: ILocContext): JSX.Element => {
  return (
    <div className="ReviewActionModalLinks">
      <Link target="_blank" href={Constants.MicrosoftTermsURL}>
        {context.loc('ReviewActionModal_Terms', 'Terms')}
      </Link>
      <Text className="LinksSeperator"> | </Text>
      <Link target="_blank" href={Constants.MicrosoftPrivacyStatementURL}>
        {context.loc('ReviewActionModal_PrivacyStatement', 'Privacy statement')}
      </Link>
    </div>
  );
};

export const renderWriteDialogButtonsSection = (
  context: ILocContext,
  curRating: number,
  handleSubmitReview: () => void,
  closeModal: () => void
): JSX.Element => {
  return (
    <DialogFooter>
      <PrimaryButton
        onClick={() => handleSubmitReview()}
        disabled={!(!!curRating || curRating === 0)}
        text={context.loc('ReviewActionModal_Submit', 'Submit')}
      />
      <DefaultButton onClick={() => closeModal()} text={context.loc('Cancel', 'Cancel')} />
    </DialogFooter>
  );
};

export const renderEditDialogButtonsSection = (
  context: ILocContext,
  IsvReplyExists: boolean,
  handleDeleteReview: () => void,
  handleSubmitReview: () => void,
  closeModal: () => void
): JSX.Element => {
  return (
    <DialogFooter>
      <PrimaryButton onClick={() => handleDeleteReview()} text={context.loc('Rating_DeleteButton', 'Delete')} />
      <PrimaryButton
        onClick={() => handleSubmitReview()}
        disabled={IsvReplyExists}
        text={context.loc('Rating_UpdateButton', 'Update')}
      />
      <DefaultButton onClick={() => closeModal()} text={context.loc('Cancel', 'Cancel')} />
    </DialogFooter>
  );
};

export const ReviewActionModal = ({
  context,
  title,
  appTitle,
  appPublisher,
  userEmail,
  userDisplayName,
  appIcon,
  curRating,
  curReviewTitle,
  curReviewContent,
  isAnonymous,
  userReview,
  closeModal,
  handleRatingChange,
  handleReviewTitleChange,
  handleReviewContentChange,
  handleSendEmailCheckbox,
  handleDisplayUserNameCheckbox,
  handleSubmitReview,
  handleDeleteReview,
}: IReviewActionModal) => {
  const dialogProps = getReviewModalDialogProps(title);
  const isEditMode = !!userReview;
  const IsvReplyExists: boolean = isEditMode && !!userReview.isv_reply;
  return (
    <Dialog
      hidden={false}
      onDismiss={() => closeModal()}
      dialogContentProps={dialogProps.dialogContentProps}
      maxWidth={dialogProps.maxWidth}
      modalProps={dialogProps.modalProps}
    >
      <Stack tokens={{ childrenGap: 12 }} styles={{ root: { width: 500 } }}>
        {renderOfferInfoSection(appIcon, appTitle, appPublisher)}
        {IsvReplyExists && (
          <div className="ReviewActionModalEditComment">
            <img className="ReviewActionModalEditCommentImg" src={greyExclamationMark} />
            <Text className="ReviewActionModalEditCommentText">
              {context.loc(
                'ReviewActionModal_EditingNotAllowedIsvReply',
                'Editing this comment is disabled because it has already\nreceived a response. You can still delete it.'
              )}
            </Text>
          </div>
        )}
        {renderRatingSection(context, IsvReplyExists, curRating, handleRatingChange)}
        <TextField
          label={context.loc('ReviewActionModal_ReviewTitle', 'Review title')}
          placeholder={context.loc('ReviewActionModal_ReviewTitle', 'Review title')}
          value={curReviewTitle}
          disabled={IsvReplyExists}
          inputClassName="reviewActionModalTitleTextField"
          onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
            handleReviewTitleChange(newValue)
          }
        />
        <TextField
          label={context.loc('ReviewActionModal_ReviewDescription', 'Review description')}
          multiline
          rows={3}
          placeholder={context.loc('ReviewActionModal_ReviewDescriptionPlaceholder', 'Write your review')}
          value={curReviewContent}
          disabled={IsvReplyExists}
          onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
            handleReviewContentChange(newValue)
          }
        />
        <Checkbox
          label={context.locParams(
            'ReviewActionModal_EmailCheckboxText',
            [appPublisher, userEmail],
            `I agree to have ${appPublisher} email me at ${userEmail} about my feedback.`
          )}
          disabled={IsvReplyExists}
          onChange={() => handleSendEmailCheckbox()}
        ></Checkbox>
        <Checkbox
          label={context.locParams(
            'ReviewActionModal_DisplayNameCheckboxText',
            [userDisplayName],
            `Post my review publicly as ${userDisplayName} (if this is unchecked, the review will be posted as 'Anonymous')`
          )}
          checked={!isAnonymous}
          defaultChecked
          disabled={IsvReplyExists}
          onChange={() => handleDisplayUserNameCheckbox()}
        ></Checkbox>
        {renderLinksSection(context)}
        {isEditMode
          ? renderEditDialogButtonsSection(context, IsvReplyExists, handleDeleteReview, handleSubmitReview, closeModal)
          : renderWriteDialogButtonsSection(context, curRating, handleSubmitReview, closeModal)}
      </Stack>
    </Dialog>
  );
};
