export enum AzureEnvironment {
  Public = 0,
  FairFax,
  Mooncake,
  BlackForest,
}

export interface AzureEnvironmentSettings {
  environment: AzureEnvironment;
  defaultCountryCode: string;
  defaultLocale: string;
  allowMSA: boolean;
}

const settings: { [environment: number]: AzureEnvironmentSettings } = {};
settings[AzureEnvironment.Public] = {
  environment: AzureEnvironment.Public,
  defaultCountryCode: 'us',
  defaultLocale: 'en-us',
  allowMSA: true,
};
settings[AzureEnvironment.Mooncake] = {
  environment: AzureEnvironment.Mooncake,
  defaultCountryCode: 'cn',
  defaultLocale: 'zh-cn',
  allowMSA: false,
};

// Settings for the Azure environment
export const AzureEnvironmentSettings = settings[AzureEnvironment.Public];
