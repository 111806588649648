import React from 'react';
import * as PropTypes from 'prop-types';
import { IBillingCountry, IPrice, ISimpleSKU } from '@shared/Models';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { DirectionalHint, mergeStyleSets, Text } from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import Tooltip from '@shared/components/tooltip';
import { getPriceWithCurrencySymbol } from '@shared/utils/pricing';

export interface SaasSubtotalCellProps {
  sku: ISimpleSKU;
  price: IPrice;
  billingCountry: IBillingCountry;
  locale: string;
}

const classes = mergeStyleSets({
  subtotalText: {
    fontWeight: 600,
    color: NeutralColors.gray160,
  },
});

export const SaasSubtotalCell = (
  { sku, price, billingCountry, locale }: SaasSubtotalCellProps,
  context: ILocContext & ILocParamsContext
) => {
  const { countryCode } = billingCountry;

  const subtotalPriceText = getPriceWithCurrencySymbol(price.value, countryCode, sku.currencyCode, 2, locale);

  const subtotalText = sku.isQuantifiable
    ? context.locParams('Pricing_Multiyear_Subtotal_Per_User', [subtotalPriceText], `${subtotalPriceText}/user`)
    : subtotalPriceText;

  return (
    <Tooltip tooltipHostProps={{ content: subtotalText, directionalHint: DirectionalHint.bottomLeftEdge }}>
      <Text className={classes.subtotalText}>{subtotalText}</Text>
    </Tooltip>
  );
};

(SaasSubtotalCell as any).contextTypes = {
  locParams: PropTypes.func,
  loc: PropTypes.func,
};
