import { IState } from '../../State';
import { createSetUserPrivateOffersLoadingAction, createUserPrivateOffersAction } from './actions';
import * as armRestClient from '@shared/services/http/armRestClient';

import { Constants } from '../utils/constants';
import { IAppDataItem } from '@shared/Models';

import { stringifyError } from '@shared/utils/errorUtils';
import { telemtry } from './thunkActions';

export async function ensurePrivateOffer(id: string, billingRegion: string, getState: () => IState): Promise<IAppDataItem> {
  const { isMSAUser, privateOffers } = getState().users;

  if (isMSAUser) {
    return null;
  }

  const offer = privateOffers.dataList.find(({ entityId }) => entityId === id);

  telemtry(Constants.Telemetry.Action.GetPrivateOffer, 'checking for spesific private offer', { id });

  if (offer?.pricingInformation.billingRegion === billingRegion) {
    return offer;
  } else {
    return armRestClient.getUserPrivateApp(id, billingRegion);
  }
}

export function getUserPrivateOffers() {
  return async function (dispatch: any, getState: () => IState): Promise<IAppDataItem[]> {
    const {
      config: { isEmbedded, billingCountryCode: billingRegion },
      users: { isMSAUser, signedIn, tid },
    } = getState();

    if (!signedIn || isEmbedded || isMSAUser) {
      dispatch(createSetUserPrivateOffersLoadingAction(false));
      return null;
    }

    const action = Constants.Telemetry.Action.GetPrivateOffers;
    const start = Date.now();

    telemtry(action, Constants.Telemetry.ActionModifier.Start, { tid, billingRegion });

    try {
      dispatch(createSetUserPrivateOffersLoadingAction(true));

      const privateOffersResponse = await armRestClient.getUserPrivateApps(billingRegion);

      dispatch(createUserPrivateOffersAction({ apps: privateOffersResponse, billingRegion }));
      dispatch(createSetUserPrivateOffersLoadingAction(false));

      telemtry(action, Constants.Telemetry.ActionModifier.End, {
        tid,
        duration: Date.now() - start,
      });

      return null;
    } catch (error) {
      dispatch(createSetUserPrivateOffersLoadingAction(false));

      telemtry(action, Constants.Telemetry.ActionModifier.Error, {
        tid,
        duration: Date.now() - start,
        error: stringifyError(error),
      });

      return null;
    }
  };
}
