import {
  Action,
  isType,
  NationalCloudSettingAction,
  EmbeddedAppSettingAction,
  EmbeddedHostAction,
  LocStringsReceivedAction,
  ChangeLocaleLanguage,
  FlightCodesReceivedAction,
  CorrelationIdReceivedAction,
  BreadcrumbAction,
  TelemetryLoggedAction,
  SetCurrentViewAction,
  SetLandingViewAction,
  UpdateCurrentGalleryViewIsCuratedAction,
  AppPricingDataRequestedAction,
  AppsPricingDataReceivedAction,
  FeatureFlagsReceivedAction,
  BillingRegionReadFromCookieAction,
  ABTestingEnableAction,
  GlobalSettingsReceivedAction,
  IsMobileReceivedAction,
  CheckoutConfigAction,
  IsManageModalOpenAction,
  SetWCPConsentAction,
  SetIsConsentRequired,
  SetInitLoadingAction,
  EnableNewNpsAction,
  SetIsNpsLogicVerifiedAction,
  ToggleShowContextPanel,
  RequestIdReceivedAction,
  SetIsDisabledBilling,
  SetStorefrontNameAction,
  I18nextReceivedAction,
  SetInitializedPCAInstanceAction,
  IncludeTestProductsReceivedAction,
  IsConverged,
} from '@shared/actions/actions';
import {
  SetQueryAction,
  SetRequestFilteredEntityTypeAction,
  SetRequestFilteredLoadedAction,
  SetRequestFilteredLoadingAction,
  SetRequestFilteredQueryAction,
  SetResponseFilteredQueryAction,
} from './../actions/queryActions';
import { pick } from 'lodash-es';

import { IConfigState, initialConfigState, copyState } from './../../State';

export default function configReducer(state: IConfigState = initialConfigState, action: Action<any>): IConfigState {
  const newState = copyState(state);

  if (isType(action, NationalCloudSettingAction)) {
    newState.nationalCloud = action.payload.nationalCloud;
  } else if (isType(action, EmbeddedAppSettingAction)) {
    newState.isEmbedded = action.payload.isEmbedded;
  } else if (isType(action, SetInitLoadingAction)) {
    newState.initLoading = action.payload.initLoading;
  } else if (isType(action, LocStringsReceivedAction)) {
    newState.locStrings = action.payload.localizedstrings;
    newState.locale = action.payload.locale;
  } else if (isType(action, I18nextReceivedAction)) {
    newState.i18nInstance = action.payload.i18nInstance;
  } else if (isType(action, EmbeddedHostAction)) {
    newState.embedHost = action.payload.embedHost;
  } else if (isType(action, FlightCodesReceivedAction)) {
    newState.flightCodes = action.payload.flightCodes;
  } else if (isType(action, IncludeTestProductsReceivedAction)) {
    newState.includeTestProducts = action.payload.includeTestProducts;
  } else if (isType(action, IsConverged)) {
    newState.isConverged = action.payload.isConverged;
  } else if (isType(action, CorrelationIdReceivedAction)) {
    newState.correlationId = action.payload.correlationId;
  } else if (isType(action, RequestIdReceivedAction)) {
    newState.requestId = action.payload.requestId;
  } else if (isType(action, BreadcrumbAction)) {
    newState.breadcrumbUrl = action.payload.breadcrumbUrl;
  } else if (isType(action, TelemetryLoggedAction)) {
    newState.appViewTelemetryLoggedCount = state.appViewTelemetryLoggedCount + 1;
  } else if (isType(action, SetCurrentViewAction)) {
    // currentView is one of routes property `name` in routerHistory.tsx
    newState.currentView = action.payload.currentView;
  } else if (isType(action, SetLandingViewAction)) {
    // landingView is one of routes property `name` in routerHistory.tsx
    newState.landingView = action.payload.landingView;
  } else if (isType(action, UpdateCurrentGalleryViewIsCuratedAction)) {
    // determine whether current render gallery view is curated
    newState.currentGalleryViewIsCurated = action.payload.isCuratedGalleryView;
  } else if (isType(action, AppPricingDataRequestedAction)) {
    newState.pricingDataLoaded = false;
  } else if (isType(action, AppsPricingDataReceivedAction)) {
    newState.billingCountryCode = action.payload.countryCode;
    newState.pricingDataLoaded = true;
  } else if (isType(action, FeatureFlagsReceivedAction)) {
    const keys = Object.keys(action.payload);
    const keysLength = keys.length;

    for (let i = 0; i < keysLength; i++) {
      newState.featureFlags[keys[`${i}`]] = action.payload[keys[`${i}`]];
    }
  } else if (isType(action, SetQueryAction)) {
    newState.query = action.payload.query;
  } else if (isType(action, SetRequestFilteredLoadingAction)) {
    newState.requestFilteredLoading = true;
  } else if (isType(action, SetRequestFilteredLoadedAction)) {
    newState.requestFilteredLoading = false;
  } else if (isType(action, SetRequestFilteredQueryAction)) {
    newState.requestFilteredQuery = action.payload.requestFilteredQuery;
  } else if (isType(action, SetRequestFilteredEntityTypeAction)) {
    newState.requestFilteredEntityType = action.payload.requestFilteredEntityType;
  } else if (isType(action, SetResponseFilteredQueryAction)) {
    newState.responseFilteredQuery = action.payload.responseFilteredQuery;
  } else if (isType(action, BillingRegionReadFromCookieAction)) {
    newState.billingCountryCode = action.payload.billingRegion;
  } else if (isType(action, ABTestingEnableAction)) {
    newState.experiment.abTestingVersion_AMP = action.payload.abTestingVersion_AMP;
    newState.experiment.abTestingVersion_newFilterAMP = action.payload.abTestingVersion_newFilterAMP;
  } else if (isType(action, GlobalSettingsReceivedAction)) {
    Object.assign(newState, {
      ...pick(action.payload.globalSettings, [
        'marketplaceApiHost',
        'saasRPHost',
        'marketplaceLeadsHost',
        'marketplaceLeadsAudienceId',
        'armApiHost',
        'MPRPHost',
        'partnersIframeUrl',
        'partnersIframeUrlTest',
        'telemetryInstrumentationKey',
        'loggingInstrumentationKey',
        'partnersApiHost',
        'appVersion',
        'region',
        'requestId',
        'correlationId',
        'reviewsAPI',
        'linkedinAPI',
        'linkedinAPIVersion',
        'communityAPI',
        'communityAPIVersion',
        'siteEnvironment',
        'aadApplicationId',
        'aadLoginUrl',
        'commerceApiHost',
        'oneCatalogApiHost',
        'telemetryEndpoint',
        'msClarityId',
        'searchApiEndpoint',
        'expHeaderDisabled',
        'enableOneTaxonomy',
        'jarvisCM',
        'mprpAadApplicationId',
        'pifdEndpoint',
        'commerceApi',
        'graphApi',
        'agreementAppId',
        'isConverged',
      ]),
    });
  } else if (isType(action, IsMobileReceivedAction)) {
    newState.isMobile = action.payload.isMobile;
  } else if (isType(action, CheckoutConfigAction)) {
    newState.pidlEnv = action.payload.checkoutSettings.pidlEnv;
  } else if (isType(action, IsManageModalOpenAction)) {
    newState.cookiesConsent.isManageModalOpen = action.payload;
  } else if (isType(action, SetWCPConsentAction)) {
    newState.cookiesConsent.WCPConsent = action.payload;
  } else if (isType(action, SetIsConsentRequired)) {
    newState.cookiesConsent.isConsentRequired = action.payload;
  } else if (isType(action, EnableNewNpsAction)) {
    newState.newNpsEnabled = action.payload.enable;
  } else if (isType(action, SetInitializedPCAInstanceAction)) {
    newState.pcaInstanceInitialized = action.payload.initialized;
  } else if (isType(action, SetIsNpsLogicVerifiedAction)) {
    newState.npsLogicVerified = action.payload.verified;
  } else if (isType(action, ToggleShowContextPanel)) {
    newState.showContextPanel = !state.showContextPanel;
  } else if (isType(action, ChangeLocaleLanguage)) {
    newState.locale = action.payload.locale;
  } else if (isType(action, SetIsDisabledBilling)) {
    newState.isDisabledBilling = action.payload;
  } else if (isType(action, SetStorefrontNameAction)) {
    newState.storefrontName = action.payload;
  } else {
    return state;
  }

  return newState;
}
