import {
  Action,
  isType,
  ModalAction,
  VideoModalAction,
  RatingAction,
  DriveModalAction,
  IFieldHubModalAction,
  IDisclaimerModalAction,
  MediaModalAction,
  InstructionsModalAction,
  EntityReviewCommentModalAction,
  EntityReviewMarkAsHelpfulModalAction,
} from './../actions/actions';
import { IModalState, initialModalState, copyState } from './../../State';
import { Constants } from '../utils/constants';
import { IVideo, IReviewPayload, IDrive, IFieldHub, IDisclaimer, IInstructions } from './../Models';

export default function modalReducer(state: IModalState = initialModalState, action: Action<any>): IModalState {
  const newState = copyState(state);

  if (isType(action, ModalAction)) {
    newState.showModal = action.payload.showModal;
    newState.entityId = action.payload.entityId ? action.payload.entityId : '';
    newState.modalId = action.payload.modalId ? action.payload.modalId : Constants.ModalType.NoModalShow;
    newState.options = action.payload.options;
    newState.entityType = action.payload.entityType;
    newState.onModalDismiss = action.payload.onModalDismiss;
    newState.disableDismissModal = action.payload.disableDismissModal;
    newState.isOpenedFromPDP = action.payload.isOpenedFromPDP;
    newState.payload = null;
  } else if (isType(action, VideoModalAction)) {
    newState.showModal = action.payload.showModal;
    newState.modalId = Constants.ModalType.Video;
    const videoPayload: IVideo = {
      videoLink: action.payload.videoUrl,
      thumbnailURL: action.payload.videoThumbnail,
      videoName: action.payload.videoName,
    };
    newState.payload = videoPayload;
  } else if (isType(action, MediaModalAction)) {
    newState.showModal = action.payload.showModal;
    newState.modalId = Constants.ModalType.MediaModal;
    newState.payload = action.payload.payload;
  } else if (isType(action, DriveModalAction)) {
    newState.showModal = action.payload.showModal;
    newState.modalId = Constants.ModalType.Drive;
    const drivePayload: IDrive = {
      urlLink: action.payload.driveUrl,
    };
    newState.payload = drivePayload;
  } else if (isType(action, IFieldHubModalAction)) {
    newState.showModal = action.payload.showModal;
    newState.modalId = Constants.ModalType.Iframe;
    const iframePayload: IFieldHub = {
      reportName: action.payload.reportName,
    };
    newState.payload = iframePayload;
  } else if (isType(action, IDisclaimerModalAction)) {
    newState.showModal = action.payload.showModal;
    newState.modalId = Constants.ModalType.Disclaimer;
    const payload: IDisclaimer = {
      title: action.payload.title,
      description: action.payload.description,
    };
    newState.payload = payload;
  } else if (isType(action, RatingAction)) {
    newState.showModal = action.payload.showModal;
    newState.modalId = Constants.ModalType.Rating;
    const reviewPayload: IReviewPayload = {
      showModal: true,
      app: action.payload.app,
      accessKey: action.payload.accessKey,
      ctaType: action.payload.ctaType,
      callback: action.payload.callback,
    };
    newState.payload = reviewPayload;
  } else if (isType(action, EntityReviewCommentModalAction)) {
    newState.showModal = action.payload.showModal;
    newState.modalId = Constants.ModalType.ReviewComment;
    newState.payload = { ...action.payload };
  } else if (isType(action, EntityReviewMarkAsHelpfulModalAction)) {
    newState.showModal = action.payload.showModal;
    newState.modalId = Constants.ModalType.ReviewMarkAsHelpful;
    newState.payload = { ...action.payload };
  } else if (isType(action, InstructionsModalAction)) {
    newState.modalId = Constants.ModalType.Instructions;
    newState.showModal = action.payload.showModal;
    const instructionsPayload: IInstructions = {
      isFromDownload: action.payload.isFromDownload,
    };
    newState.payload = instructionsPayload;
  } else {
    return state;
  }

  return newState;
}
