/* istanbul ignore file */
import { useCallback, useEffect } from 'react';
import { matchRoutes, useLocation } from 'react-router';
import type { RouteObject } from 'react-router';
import type { AnyAction, Dispatch } from 'redux';
import { IState } from '@src/State';
import { Constants } from '@shared/utils/constants';
import { Request } from 'express';
import { useHydration } from './useHydration';
import { useDispatch, useSelector } from 'react-redux';
import { createSetInitLoadingAction } from 'actions/actions';

export type EnsureAsyncDataSSR = (
  dispatch: Dispatch<AnyAction>,
  getState?: () => IState,
  params?: unknown,
  req?: Request
) => Promise<void>;

export type RouteView = {
  view: Constants.Views;
  featureFlag?: string;
  ensureAsyncDataSSR?: EnsureAsyncDataSSR;
};

export interface PathsViewMap {
  [key: string]: RouteView;
}

type UseLocationChangeOptions = {
  setCurrentView: (...args: unknown[]) => void;
  map: PathsViewMap;
  routes: RouteObject[];
};

export function useLocationChange(options: UseLocationChangeOptions) {
  const isHydrated = useHydration();
  const location = useLocation();
  const isEmbedded = useSelector<IState, boolean>((state) => state.config.isEmbedded);
  const dispatch = useDispatch();

  const { setCurrentView, map, routes } = options;

  const handleLocationChange = useCallback(() => {
    const matches = matchRoutes(routes, location.pathname);
    const matchedView = map[matches[matches.length - 1]?.route?.path];
    setCurrentView(matchedView?.view);
  }, [setCurrentView, location, map, routes]);

  useEffect(() => {
    if (!isHydrated) {
      return;
    }

    if (isEmbedded) {
      // Embedded starts in a loading state, therefore client side hydration expects the same state to be met.
      // Loading is disabled once client has been hydrated.
      dispatch(createSetInitLoadingAction({ initLoading: false }));
    }

    handleLocationChange();
  }, [location, handleLocationChange, isHydrated, isEmbedded, dispatch]);
}
