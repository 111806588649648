import { ICommonContext } from 'interfaces/context';
import SpzaComponent from './spzaComponent';
// eslint-disable-next-line import/named
import { Text, ITextStyles } from '@fluentui/react';
import * as React from 'react';
import { ITabsProps, Tab, Tabs } from './tabs';
import { TabChild } from '@shared/Models';

export interface IStickyCard {
  title: string;
  logoURL: string;
  tabsProps: ITabsProps;
  tabsChilds: TabChild[];
}

export class StickyCard extends SpzaComponent<IStickyCard, any> {
  context: ICommonContext;

  static handleScroll(curWindow: Window, appearUnderElementId: string): boolean {
    const elementToAppearAfter = document.getElementById(appearUnderElementId);
    if (curWindow && elementToAppearAfter && elementToAppearAfter.offsetTop) {
      const tabsYOffset = elementToAppearAfter.offsetTop + 43;
      if (curWindow.pageYOffset > tabsYOffset) {
        return true;
      }
      return false;
    }
  }

  renderImpl() {
    const titleStyles: ITextStyles = { root: { marginLeft: 8, fontWeight: 600, lineHeight: 20 } };

    return (
      <div className="stickyCard">
        <div className="stickyCardContainer">
          <div className="stickyCardSubContainer">
            <div className="stickyCardLeft">
              <div className="stickCardPublisher">
                <div className="stickyCardIcon">
                  <div>
                    <img
                      className="StickyIconImg"
                      src={this.props.logoURL}
                      alt={this.props.title + ' icon'}
                      onError={(e) =>
                        e && e.target && (e.target as HTMLElement).style
                          ? ((e.target as HTMLElement).style.display = 'none')
                          : null
                      }
                    />
                  </div>
                </div>
                <Text variant="medium" block nowrap styles={titleStyles}>
                  {this.props.title}
                </Text>
              </div>
              <div className="stickyCardTabs">
                <Tabs
                  defaultTab={this.props.tabsProps.defaultTab}
                  ownerType={this.props.tabsProps.ownerType}
                  ownerId={this.props.tabsProps.ownerId}
                  changeTabCallback={this.props.tabsProps.changeTabCallback}
                  getTabHref={this.props.tabsProps.getTabHref}
                >
                  {this.props.tabsChilds.map((tabChild, index) => (
                    <Tab key={index} title={tabChild.title} name={tabChild.name} textBadge={tabChild.textBadge}>
                      <div></div>
                    </Tab>
                  ))}
                </Tabs>
              </div>
            </div>
          </div>
          <div>
            <div className="stickyCardRight">
              <div className="stickyCardSubContainer">{this.props.children}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
