import * as React from 'react';
import {
  IReviewCommentModalProps,
  ReviewCommentModal as ReviewCommentModalComponent,
} from '@shared/components/modals/reviewCommentModal';
import { deleteReviewComment, updateReviewComment } from '@shared/actions/thunkActions';
import { IState } from '../../../State';

const { connect } = require('react-redux');

export const mapStateToProps = (state: IState, ownProps: IReviewCommentModalProps): IReviewCommentModalProps => {
  return {
    userInfo: state.users,
    onDeleteComment: ownProps.onDeleteComment,
    onEditComment: ownProps.onEditComment,
    dismissModal: ownProps.dismissModal,
    payload: ownProps.payload,
  };
};

export const mapDispatchToProps = (dispatch: any, ownProps: IReviewCommentModalProps): IReviewCommentModalProps => {
  return {
    onDeleteComment: ({ offerId, reviewId, commentId, isNewlyAddedComment }) =>
      dispatch(deleteReviewComment({ offerId, reviewId, commentId, isNewlyAddedComment })),
    onEditComment: ({ offerId, reviewId, commentId, commentContent, isNewlyAddedComment }) =>
      dispatch(updateReviewComment({ offerId, reviewId, commentId, commentContent, isNewlyAddedComment })),
    userInfo: ownProps.userInfo,
    payload: ownProps.payload,
    dismissModal: ownProps.dismissModal,
  };
};

export const ReviewCommentModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewCommentModalComponent) as React.StatelessComponent<any>;
