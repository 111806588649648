import * as React from 'react';
import { ILocContext, ILocParamsContext } from '../../interfaces/context';
import { Text } from '@fluentui/react';
import instructionsImg1 from '@shared/images/power-bi-instructions-1.png';
import instructionsImg2 from '@shared/images/power-bi-instructions-2.png';
import instructionsImg3 from '@shared/images/power-bi-instructions-3.png';
import instructionsImg4 from '@shared/images/power-bi-instructions-4.png';

interface IInstructionsModalStepProps {
  step: number;
  text: any;
  imageSrc: string;
}

const InstructionsModalStep = ({ step, text, imageSrc }: IInstructionsModalStepProps) => {
  return (
    <div className="instructionsStep">
      <img className="instructionsImg" src={imageSrc} />
      <div className="instructionsStepText">
        <Text className="instructionsNumber">{step}. </Text>
        {text}
      </div>
    </div>
  );
};

interface IInstructionsModalBodyProps {
  context: ILocContext & ILocParamsContext;
}

export const InstructionsModalBody = ({ context }: IInstructionsModalBodyProps) => {
  const stepsMap = {
    stepOne: { text: <Text key="stepOne">{context.loc('PowerBIVisual_Instructions_1')}</Text>, image: instructionsImg1 },
    stepTwo: { text: <Text key="stepTwo">{context.loc('PowerBIVisual_Instructions_2')}</Text>, image: instructionsImg2 },
    stepThree: {
      text: (
        <span
          key="stepThree"
          dangerouslySetInnerHTML={{
            __html: context.locParams('PowerBIVisual_Instructions_3', [
              `<span class='instructionsOpen'>${context.loc('PowerBIVisual_Instructions_Open')}</span>`,
            ]),
          }}
        />
      ),
      image: instructionsImg3,
    },
    stepFour: {
      text: <Text key="stepFour">{context.loc('PowerBIVisual_Instructions_4')}</Text>,
      image: instructionsImg4,
    },
  };
  return (
    <div className="instructionsStepsContainer">
      {Object.keys(stepsMap).map((key: string, step: number) => (
        <InstructionsModalStep key={key} step={step + 1} text={stepsMap[key].text} imageSrc={stepsMap[key].image} />
      ))}
    </div>
  );
};
