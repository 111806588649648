import React from 'react';
import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton } from '@fluentui/react';
import type { IDialogContentStyles, IDialogContentProps, IModalProps, IDialogStyles } from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import type { ICSSPixelUnitRule, ICSSRule } from '@fluentui/merge-styles';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';

const dialogStyles: Partial<IDialogStyles> = {
  root: {
    position: 'relative',
    height: 'auto',
  },
  main: {
    position: 'fixed',
    zIndex: 999,
    right: 10,
    bottom: 20,
  },
};

const dialogContentStyles: Partial<IDialogContentStyles> = {
  header: { color: NeutralColors.gray160 },
  subText: { color: NeutralColors.gray130 },
};

const dialogContentProps = (context: ILocContext & ILocParamsContext): IDialogContentProps => {
  return {
    type: DialogType.close,
    title: context.loc('Nps_PreviewModal_Title', 'Can we ask a quick question?'),
    closeButtonAriaLabel: context.loc('Nps_PreviewModal_CloseButton_AriaLabel', 'Close'),
    subText: context.loc(
      'Nps_PreviewModal_SubHeader_Text',
      'We’re always looking for ways to improve AppSource. Can you tell us about your experience?'
    ),
    styles: dialogContentStyles,
  };
};

const modalMinWidth: ICSSRule | ICSSPixelUnitRule = '536px';
const modalProps: IModalProps = { isModeless: true };

interface INpsPreviewModalProps {
  hidden: boolean;
  onDismiss: () => void;
  onSubmit: () => void;
  context: ILocContext & ILocParamsContext;
}

export const NpsPreviewModal: React.FunctionComponent<INpsPreviewModalProps> = ({
  hidden,
  onDismiss,
  onSubmit,
  context,
}: INpsPreviewModalProps) => {
  return (
    <Dialog
      hidden={hidden}
      dialogContentProps={dialogContentProps(context)}
      minWidth={modalMinWidth}
      modalProps={modalProps}
      styles={dialogStyles}
      onDismiss={onDismiss}
    >
      <DialogFooter>
        <PrimaryButton text={context.loc('Nps_PreviewModal_SubmitButton_Text', 'Share feedback')} onClick={onSubmit} />
        <DefaultButton text={context.loc('Nps_PreviewModal_DismissButton_Text', 'Maybe later')} onClick={onDismiss} />
      </DialogFooter>
    </Dialog>
  );
};
