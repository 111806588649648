/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable camelcase */
/* eslint-disable no-redeclare */
/* eslint-disable no-use-before-define */
/* This file should contain all the app constants which we use in the SPZA project */

import { IAvertAbuseCategory } from '@shared/Models';
import { stringifyError } from '@shared/utils/errorUtils';

export enum IConsentOption {
  /** Requires users to manually tick the lead generation box to enable - default is disabled */
  OptIn = 'optin',
  /** Requires users to manually tick the lead generation box to disable - default is enabled */
  OptOut = 'optout',
  /** No checkbox, leads should be sent and this is for a notice only */
  Notice = 'notice',
  /** Text is different no leads are sent. */
  Exclude = 'exclude',
  /** Treated as OptIn */
  Unknown = 'unknown',
}

export enum LoginType {
  Silent = 'Silent',
  Implicit = 'Implicit',
}
export type ConsentType =
  | IConsentOption.OptIn
  | IConsentOption.OptOut
  | IConsentOption.Notice
  | IConsentOption.Exclude
  | IConsentOption.Unknown;

export interface SupportedCountry {
  countryCode: string;
  name: string;
  currencyCode: string;
  consentRequired?: ConsentType;
}

export interface SupportedRegion {
  code: string;
  name: string;
}

export interface AllCountry {
  countryCode: string;
  name: string;
  code: string;
}

export namespace Constants {
  export enum Environment {
    development = 'development',
    df = 'df',
    canary = 'canary',
    prod = 'prod',
  }

  export const MSTenantId = '72f988bf-86f1-41af-91ab-2d7cd011db47';

  export enum AppName {
    AppSource = 'appsource',
  }

  export const UNKNOWN_NETWORK_INFO = 'unknown';
  export const notAvailable = 'N/A';

  export namespace Featured {
    export const AppDocId = 'featuredapps';
    export const ServiceDocId = 'featuredservices';
  }

  export enum Tabs {
    PlansAndPrice = 'PlansAndPrice',
    Overview = 'Overview',
    DetailsAndSupport = 'DetailsAndSupport',
    Reviews = 'Reviews',
    Linkedin = 'Linkedin',
  }

  export enum FilteredSearchType {
    All = 'All',
    Apps = 'Apps',
    Services = 'Services',
    CloudsIndustry = 'CloudsIndustry',
    Partners = 'Partners',
  }

  export enum FilteredSearchPhase {
    Started = 'Started',
    SearchResultsReturned = 'Search Results Returned',
    DuplicateAddInsRemoved = 'Duplicate Add Ins Removed',
    SearchActionCreated = 'Search Action Created',
    SearchTelemetryLogged = 'Search Telemetry Logged',
  }

  export enum HTMLElements {
    INPUT = 'INPUT',
    BUTTON = 'BUTTON',
  }

  export enum SigninStatus {
    ValidTokenValidCookie = 1,
    ExpiredTokenValidCookie = 2,
    ExpiredTokenInvalidCookie = 3,
    ValidTokenInvalidCookie = 4,
    // Only client side state, where you cannot read the cookie and tell
    ValidTokenUnknownCookie = 5,
    ValidationSkipped = 6,
  }

  export enum PageToGiveMetaTag {
    PageNotReady = -1,
    SpzaHomePage = 1,
    SpzaGalleryPage = 2,
    SpzaCSGalleryPage = 3,
    SpzaDetailPage = 4,
    SpzaCSDetailPage = 5,
    SpzaWordGalleryPage = 6,
    SpzaExcelGalleryPage = 7,
    SpzaPowerpointGalleryPage = 8,
    SpzaOutlookGalleryPage = 9,
    SpzaOfficeGalleryPage = 10,
  }

  export enum RatingShowMode {
    Error = 1,
    ContentViolation = 2,
    Success = 3,
    DeleteSuccess = 4,
    WriteNewReview = 5,
    Loading = 6,
    EnsureDelete = 7,
    EditReview = 8,
  }

  export namespace CookieExpiry {
    // As per EU guidelines, we should not save any cookie for more than 13 months.
    // The below value is 13 months time in milli seconds
    export const Default = 13 * 30 * 24 * 60 * 60 * 1000;

    // 13 days
    export const Spza = 1000 * 60 * 60 * 24 * 13;

    // 14 days
    export const RefreshToken = 1000 * 60 * 60 * 24 * 14;

    // 90 days
    export const ExperimentDuration = 1000 * 60 * 60 * 24 * 90;
  }

  export namespace Reviews {
    export const CommentsPerPage = 10;
    export const MaxSubsequentDeletedComments = 4;
    export const MaxCommentComposeLength = 500;

    export enum ReviewCommentModalMode {
      ContentViolation,
      ConfirmDelete,
      DeleteError,
      ConfirmUpdate,
      UpdateError,
      CreateError,
      Loading,
    }

    export enum ReviewCommentActionError {
      ContentViolation = 'ContentViolation',
    }

    export namespace Microdata {
      export const AppItemType = 'https://schema.org/SoftwareApplication';
      export const AppCategory = 'UtilitiesApplication';
    }

    export namespace ReviewsSource {
      export const Internal = 'Internal';
      export const External = 'External';
      export const All = 'All';
    }
  }

  // Please be super careful of updating the following enum values because some other code maps string to enum value (e.g. 'App' to EntityType.App)
  export enum EntityType {
    App = 1,
    Service = 2,
    Partner = 5,
    CloudsIndustry = 6,
  }

  export const ECAPayloadAction = 'Create';

  export enum Dynamics365LicenseType {
    dynamics365License = 1,
    dynamics365forBusinessLicense = 2,
    noLicense = -1,
  }

  export enum Dynamics365LicenseIndex {
    dynamics365LicenseIndex = 0,
    dynamics365ForBusinessLicenseIndex = 1,
  }

  export enum SearchBy {
    Id,
    Class,
    Tag,
    None,
    QuerySelector,
  }

  export enum SystemKey {
    Space = 32,
    Tab = 9,
    Enter = 13,
    Esc = 27,
    Up = 38,
    Down = 40,
  }

  export enum SystemKeyAsString {
    Space = ' ',
    Tab = 'Tab',
    Enter = 'Enter',
    Esc = 'Escape',
    ArrowUp = 'ArrowUp',
    ArrowDown = 'ArrowDown',
  }

  export enum SystemKeyString {
    Space = 'Space',
    Tab = 'Tab',
    Enter = 'Enter',
    Esc = 'Esc',
    Up = 'Up',
    Down = 'Down',
  }

  export enum Recommendations {
    WhatsNew = 'WhatsNew',
    Trending = 'Trending',
    WhatsNewMicrosoft365 = 'WhatsNewMicrosoft365',
    TrendingMicrosoft365 = 'TrendingMicrosoft365',
  }

  export enum FieldHubUserType {
    None, // no account hub access (no tab)
    Limited, // Microsoft employee with no account hub access
    Full, // full account hub experience
  }

  export enum VaritionKey {
    Default = 0,
    Treatment = 1,
    TreatmentNewFilterAMP = 2,
  }

  export enum TenantType {
    None, // signed out state
    Viral,
    Managed,
    Msa,
    Unknown, // error returned, not able to determine the type
  }

  // This is the terms URL which you see in the consent modal in the CTA pages.
  export const InvoiceLink = 'https://aka.ms/appsource/paybyinvoice';
  export const AzureSubscriptionLink = 'http://aka.ms/appsource/azuresub';
  export const AzureBenefitURL = 'http://aka.ms/appsource/azurebenefit';
  export const MicrosoftTermsURL = 'https://aka.ms/azuremarketplaceterms';
  export const MyOrdersLink = 'https://admin.microsoft.com/Adminportal/Home#/subscriptions/apps';
  export const AdminCenterLink = 'https://admin.microsoft.com/Adminportal/Home';
  export const AdminCenterYourProductsLink = 'https://admin.microsoft.com/adminportal/home?#/subscriptions';
  export const AdminCenterLicensesLink = 'https://admin.microsoft.com/adminportal/home?#/licenses';
  export const MicrosoftPrivacyStatementURL = 'https://aka.ms/AppSourcePrivacyStatement';
  export const MicrosoftRatingReviewPolicy = 'https://docs.microsoft.com/en-us/legal/marketplace/rating-review-policies';
  export const FeaturedAppsId = '99';
  export const InitializationRetryAttempts = process.env.initializationRetryAttempts
    ? parseInt(process.env.initializationRetryAttempts, 10)
    : 1;
  export const InitializationRetryDelay = process.env.initializationRetryDelay
    ? parseInt(process.env.initializationRetryDelay, 10)
    : 1000 * 60 * 5; // 5 minutes
  export const MaxItemsPerPageInReviews = 10;

  export const mooncakeNationalCloud = 'mooncake';
  export const fairfaxNationalCloud = 'fairfax';
  export const blackforestNationalCloud = 'blackforest';
  // eslint-disable-next-line security/detect-unsafe-regex
  export const EmailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
  export const LocaleRegex = /^[a-zA-Z- ]*$/;
  export const AlphaNumericRegex = /^[a-zA-Z0-9 ]+$/;
  export const SeoLocaleRegex = /[/][a-zA-Z]{2}-[a-zA-Z]{2,4}/;
  export const removeHtmlRegex = /<(.|\n)*?>/g;
  export const crossListingRibbonItemCount = 5;
  export const memCacheExpiry = 1200000;
  export const testDriveDocumentationLink = 'https://go.microsoft.com/fwlink/?linkid=845086';
  export const ampTestDriveDocumentationLink = 'https://azure.microsoft.com/en-us/blog/azure-marketplace-test-drive/';
  export const purchaseInAppSourceLink = 'https://aka.ms/AppSourceDocOverview';
  export const appSourceContactUsLink = 'https://aka.ms/AppSourceContactUs';

  export const GraphResourceUrl = 'https://graph.windows.net';
  export const AccessTokenUrl = 'https://login.microsoftonline.com/common/oauth2/token';
  export const PowerBIResourceUrl = 'https://analysis.windows.net/powerbi/api';
  export const PowerBIVisualInstructionsUrl = 'https://go.microsoft.com/fwlink/?linkid=2161918';
  export const learnMoreCertifiedPBI = 'https://aka.ms/power-bi-custom-visuals-certified';
  export const learnMoreBillingPane = 'https://aka.ms/appsourcecountries';

  export const refreshAccessTokenInterval = 1000 * 60 * 45; // 45 minutes in ms

  export const ServicesUserSegment = 'Services';
  export const UserSegmentQueryParameter = 'usersegment';
  export const InitialStateCacheKey = 'initialState';
  export const everywhereFilterView = 'everywhere';
  export const URLCacheKeyIsMobileView = 'ismobileview';

  export const ABTesting = 'ABTesting';

  export const canoninalfilterQuery = 'filters=';
  export const canonicalAMPEnvWebAppName = 'mac';
  export const canonicalAMPEnvAppName = 'azureMarketPlace';

  export const tfsStringFieldMaxLength = 255;
  export const mpnIdMaxDigits = 7;

  export const enterpriseContractLink = 'https://go.microsoft.com/fwlink/?linkid=2041178';

  export const M365Admin = 'M365Admin';

  export const PanelWidth = '340px';

  export const PrimaryProductBitMaskKey = 'product_mask_1';

  export const AppSourceStorefronts: string[] = ['appsource', 'teamsadmin', 'm365admin'];

  export const redirectRoute = 'redirect';

  export namespace ReviewsEnrichApiResponse {
    export const ContentViolation = 'ContentViolation';
    export const UserDidntPurchase = 'user has not acquired the app';
  }

  export namespace Checkout {
    export const MaxQuantity = 1000000;
    export const IsSaasBundle = 'isSaaSBundle';
    export const ContentId = 'contentId';
    export const OperationLocation = 'operation-location';
    export const RetryAfter = 'retry-after';
    export const DefaultPlanIdQueryParam = 'defaultPlanId';
    export namespace TermId {
      export const Monthly = 'hjdtn7tfnxcy';
      export const Yearly = 'nm5o4wf9fbfy';
    }
  }

  export namespace OrderConfirmation {
    export const InternalServerErrorLimit = 3;
    export const PreviewOffer = '-preview';
    export const OfferNotFoundErrorMessage = 'was not found in the catalog';
    export const TestHeaderLowerCase = 'test header';
    export const CustomMetersErrorMessage =
      'The purchase has failed because the plan you selected is not available for purchase from AppSource. It is available for purchase in Azure Portal.';
    export const RiskPrepaidCardErrorReason = 'AzurePrepaidBINPolicy_Reject_Terminate';
  }

  export namespace StatusError {
    export const Conflict = 409;
    export const InternalServerError = 500;
  }

  export namespace Cookies {
    export const AppSourceCookie = 'appsource';
    export const AppSourceLeadCookie = 'appsourcelead';
    export const LocaleCookie = 'spzaLocale';
    export const billingRegionCookie = 'appsourcebillingregion';
    export const correlationId = 'correlationId';
    // cookie user-accepted date for privacy consent banner at the top
    // we could not save cookie in user browser, if
    //  a) cookie for this key is undefined (user has not accept our privacy consent)
    //  b) cookie for this key is expired after 13 months
    export const privacyConsentAcceptedDate = 'privacyConsentAcceptedDate';
    export const WCPBannerName = 'WCPcookieBannerId';
    export const WCPManageCookieBtnId = 'c-uhff-footer_managecookies';
    export const WCPDefaultLocale = 'en-US';
    export const WCPEventName = 'wcpconsent-download-done';
    export const AMC3PAdsOptOut = '3PAdsOptOut';
    export enum Errors {
      ParsingExperimentsCookieError = 'Failed to parse experiments cookie',
      ParsingVariantsError = 'Failed to parse variants within experiments cookie',
    }
    export const Essential = {
      /** Cookie to track the session, essential for tracking user behavior */
      [Cookies.correlationId]: true,
      [Cookies.AMC3PAdsOptOut]: true,
    } as const;
  }

  export enum ConsentAnalyticAssets {
    GA = 'GA',
  }

  export namespace Catalog {
    export const RatingAverageDecimalPoints = 3;
    export const RatingAverageDecimalPointsHelper = Math.pow(10, RatingAverageDecimalPoints);
    export const ImageNameUnavailable = 'Unavailable';
    export const DefaultTestDriveVideoName = 'About this test drive';
    export const DefaultTestDriveManual = 'Test drive manual';
    export const DefaultOfficeLocale = 'en';
    export const AllMarkets = 'ALL';
    export const legacyPlanIdARM = '-arm';

    export enum CategoryId {
      MicrosoftIotEdgeModule = 'microsoft-iot-edge-module',
    }

    export enum MeterUnit {
      HR = 'HR',
    }

    export enum MeterType {
      FreeTrialMeterType = '1 Compute Hour - FreeTrial',
    }

    export enum ArtificatsName {
      DefaultTemplate = 'DefaultTemplate',
      UiDefinition = 'UiDefinition.json',
      CreateUiDefinition = 'createuidefinition',
    }
  }

  export namespace LocalStorage {
    export const spzaUserId = 'spzaUserId';
    export const trailId = 'trailId';
    export const nps = 'nps';
    export const npsNext = 'next';
    export const npsNextText = 'nextText';
    export const dontShowInstructions = 'dontShowInstructions';
    export const LoginHint = 'loginHint';

    export const Essentials = [LocalStorage.LoginHint];
  }

  export const usCountryCode = 'us';
  export const usCountryCodeUpper = usCountryCode.toUpperCase();
  export const caCountryCode = 'ca';
  export const auCountryCode = 'au';
  export const allCountriesCode = 'ALL';
  export const allStatesCode = 'all';
  export const allStatesCodeUpper = allStatesCode.toUpperCase();
  export const usdCurrency = 'USD';
  export const countryCodeQueryParam = 'country';
  export const regionCodeQueryParam = 'region';
  export const usFilterText = 'United States';
  export const auFilterText = 'Austrilia';
  export const allRegionsFilterText = 'All States/Provinces';
  export const userAcceptField = 'accepted';
  export const userProfileEmbedCookieName = 'userProfileEmbedHost';
  export const Country = 'country';

  export namespace EventsApi {
    export const View = 'view';
    export const BusinessApplicationsType = 'BusinessApplications';
  }

  export enum UrlParamCacheKeyWhiteListKey {
    Product = 'product',
    Service = 'service',
    Default = 'default',
  }

  export namespace Search {
    export const numberOfTops = 1000;
    export const searchFullQueryType = 'full';
    export const maxNumOfSuggestions = 5;
    export const cloudIndustryCategoriesFilter = 'CloudIndustryCategories/any()';
    export const maxSearchTextLength = 200;

    export enum SuggestionTypes {
      WordSearch = 'WordSearch',
      Entity = 'Entity',
    }

    export namespace QueryParams {
      export const SuggestionTypes = 'suggestionTypes';
      export const SearchQuery = 'searchQuery';
      export const IndustryCloud = 'industryCloud';
      export const Language = 'language';
      export const Gallery = 'gallery';
      export const ProductTypes = 'productTypes';
    }

    export namespace Endpoints {
      export const Search = '/search';
      export const Suggestions = 'suggestions/products';
    }

    export namespace ApiVersions {
      export const SearchApiSuggestions = '2022-09-25-preview';
    }
    export namespace SelectEntry {
      export const ApplicationId = 'ApplicationId';
      export const ServiceId = 'ServiceId';
    }

    export namespace SearchMode {
      export const All = 'all';
    }

    export enum ActionType {
      Suggestions = 'Suggestions',
      Search = 'Search',
    }

    export enum OrderBy {
      asc = 'asc',
      desc = 'desc',
    }
  }

  export namespace FilteredGallery {
    export const pageSize = 60;
    export const officeFilter = 'office';
  }

  export enum TileDataEntityType {
    App = 'App',
    Service = 'Service',
    CloudsIndustry = 'CloudsIndustry',
    All = 'All',
  }

  export enum TileDataAdditionalQueryParams {
    sortBy = 'sortBy',
    page = 'page',
    region = 'region',
    country = 'country',
    includeTestProducts = 'includeTestProducts',
  }

  export enum TileDataFilteredQueryParams {
    search = 'search',
    subcategories = 'subcategories',
    subindustries = 'subindustries',
    filters = 'filters',
    serviceType = 'serviceType',
  }

  export enum TileDataCuratedQueryParams {
    category = 'category',
    industry = 'industry',
    product = 'product',
  }

  export enum DataMapCollectionKeys {
    categories = 'categories',
    industries = 'industries',
    cloudIndustries = 'cloudIndustries',
    products = 'products',
    serviceTypes = 'serviceTypes',
    trials = 'trials',
    pricingModel = 'pricingModel',
    ratings = 'ratings',
    appCompliance = 'appCompliance',
    azureBenefitEligible = 'AzureBenefitEligible',
    copilotExtension = 'CopilotExtension',
    productType = 'productType',
  }

  export enum CategoriesDataMapCollectionKeys {
    default = 'default',
    office = 'office',
    powerBiVisuals = 'powerBiVisuals',
    addIns = 'addIns',
  }

  export const CuratedQueryParamsToDataMapCollectionKeyMap: { [param in TileDataCuratedQueryParams]: DataMapCollectionKeys } = {
    [TileDataCuratedQueryParams.category]: DataMapCollectionKeys.categories,
    [TileDataCuratedQueryParams.industry]: DataMapCollectionKeys.industries,
    [TileDataCuratedQueryParams.product]: DataMapCollectionKeys.products,
  };

  export const UrlParamCacheKeyWhiteList: { [param: string]: boolean } = {
    country: true,
    region: true,
    tab: true,
    product: true,
    embedhost: true,
  };

  export const UrlParamCacheKeyBlackList: UrlParamCacheKeyBlackList = {
    search: true,
    product: true,
    category: true,
    industry: true,
    subcategories: true,
    subindustries: true,
    filters: true,
    servicetype: true,
  };

  export const EmbedUrlParamCacheKeyBlackList: UrlParamCacheKeyBlackList = {
    ...UrlParamCacheKeyBlackList,
    product: undefined,
  };

  enum UserPII {
    firstName = 'firstName',
    lastName = 'lastName',
    email = 'email',
    phone = 'phone',
    oid = 'oid',
    alternateEmail = 'alternateEmail',
  }

  export const PIIFields: string[] = [
    UserPII.firstName,
    UserPII.lastName,
    UserPII.email,
    UserPII.phone,
    UserPII.oid,
    UserPII.alternateEmail,
  ];

  export const RawDataCacheKeyPrefix = 'RawData|';

  export namespace Headers {
    export const CV = 'ms-cv';
    export const RequestId = 'x-ms-requestid';
    export const CorrelationId = 'x-ms-correlationid';
    export const XMSUserAgent = 'x-ms-useragent';
    export const Source = 'x-ms-source';
    export const Bearer = 'Bearer '; // Please do not remove the whitespace at the end
    export const AccessKey = 'accesskey';
    export const Authorization = 'authorization';
    export const Referer = 'referer';
    export const InternalAuthorizationHeader = 'x-ms-authorization';
    export const Cookie = 'cookie';
    export const Host = 'host';
    export const iframeOptions = 'X-Frame-Options';
    export const ContentType = 'Content-Type';
    export const UserAgent = 'user-agent';
    export const XMSSourceAppSource = 'AppSource';
    export const XMSSourceAMP = 'AzureMarketplace';
    export const XMSClientName = 'x-ms-client-name';
    export const XForwardedFor = 'x-forwarded-for';
    export const XIISNodeRemoteAddr = 'x-iisnode-remote_addr';
    export const AcceptLanguages = 'accept-language';
    export const ApplicationType = 'x-ms-applicationType';
    export const CatalogApiClientRequestId = 'x-ms-client-request-id';
    export const ETag = 'ETag';
    export const CacheControl = 'Cache-Control';
    export const Pragma = 'Pragma';
    export const Expires = 'Expires';
    export const ApiKey = 'api-key';
    export const Origin = 'Origin';
    export const Accept = 'accept';
    export const XMsApp = 'x-ms-app';
    export const SetCookie = 'set-cookie';
    /** Azure AppService healthcheck {@link https://learn.microsoft.com/en-us/azure/app-service/monitor-instances-health-check?tabs=node#authentication-and-security monitoring} token */
    export const XMSAuthInternalToken = 'x-ms-auth-internal-token';
    export const XAPIKey = 'X-API-Key';
    export const XProofToken = 'x-proof-token';
    export const ProxyAuthorization = 'proxy-authorization';
    export const XMSClientObjectId = 'x-ms-client-object-id';
    export const XMSClientTenantId = 'x-ms-client-tenant-id';
    export const XMSClientRegion = 'x-ms-client-region';
  }

  export namespace OperationNames {
    export const PassThroughLog = 'PassThroughLog ';
    export const PostTokens = 'Post Tokens';
    export const Server = 'Server';
    export const Request = 'Request';
    export const PreRequest = 'Potential AAD Callback Request';
    export const RateLimit = 'Rate Limit';
    export const TokenLog = 'Token Log';
    export const ParamsValidation = 'params Validation';
    export const MainRequest = 'Request Received';
    export const EnsureAsyncData = 'Ensure Async Data';
    export const GetHeaderData = 'Get Header Data';
    export const RenderReactApp = 'Render React App';
    export const WarmupRemoteModulesRendering = 'Warmup Remote Modules Rendering';
    export const FulFillRequest = 'Ful Fill Request';
    export const ProcessReduxStore = 'Process Redux Store';
    export const PreSSRPromises = 'Pre SSR Promises';
    export const SSRRequest = 'SSR Request';
    export const Embed = 'Embed';
    export const Signin = 'Signin';
    export const Signout = 'Logout';
    export const AADCallback = 'AAD Callback Request';
    export const LeadGen = 'LeadGeneration';
    export const SaveLeadGenSettings = 'SaveLeadGenSettings';
    export const SolutionsTestDriveAcquisition = 'Solutions Test Drive Acquisition';
    export const SolutionsInstanceStatus = 'Solutions Instance Status';
    export const SolutionsInstanceAction = 'Solutions Instance Action';
    export const AmpTestDriveAcquisition = 'AmpTestDriveAcquisition';
    export const AmpSolutionsInstanceStatus = 'AmpSolutionsInstanceStatusProcessor';
    export const AmpSolutionsInstanceAction = 'AmpSolutionsInstanceAction';
    export const AutoSignin = 'Auto Signin';
    export const Acquisition = 'Acquisition';
    export const LogCreateLeadPayload = 'LogCreateLeadPayload';
    export const LogAcquisitionPayload = 'LogAcquisitionPayload';
    export const UserAgent = 'User Agent';
    export const ObtainAccessToken = 'Obtain Access Token';
    export const CreateUser = 'create User';
    export const MsalGetTokenOnBehalf = 'msal.getTokenOnBehalf';
    export const SearchApi = 'searchRestClient.callSearch';
    export const SearchApiRemovedOffers = 'searchRestClient.callSearch.removedOffers';
    export const MarkoApi = 'searchRestClient.callMarkoSearch';
    export const CombinedSearch = 'searchRestClient.callSearchWithMarko';
    export const GetRefreshTokenFromCache = 'GetRefreshTokenFromCache';
  }

  export enum CacheStatus {
    Healthy = 'Healthy',
    Unhealthy = 'Unhealthy',
  }

  export enum BrowserConfigs {
    appsource = 'appsource',
    azureMarketPlace = 'azureMarketPlace',
    embed = 'embed',
  }

  export enum ClientNames {
    AppSource = 'AppSource',
    AMP = 'AMP',
    Embedded = 'Embedded',
    Default = '',
  }

  export enum AppConfig {
    correlationId = 'correlationId',
  }

  export const browserConfigsToClientNames: { [key in BrowserConfigs]: ClientNames } = {
    [BrowserConfigs.appsource]: ClientNames.AppSource,
    [BrowserConfigs.azureMarketPlace]: ClientNames.AMP,
    [BrowserConfigs.embed]: ClientNames.Embedded,
  };

  export namespace HttpMessage {
    export const InvalidQueryParams = 'Invalid query parameters';
    export const OneOrMoreErrors = 'One or more errors occured';
    export const Forbidden = 'Forbidden';
    export const NotFound = 'Not Found';
    export const InternalServerError = 'Internal Server Error';
    export const BadRequest = 'BadRequest';
    export const AccessKeyExpired = 'Access key expired';
    export const AccessKeyInvalid = 'Invalid access key, invalid cipher: Message integrity check failed';
  }

  export namespace ControllerOperations {
    export const ValidateQueryParams = 'Validation of query parameters';
    export const ValidatePayload = 'Validation of payload';
    export const GenerateLead = 'Generation of lead';
    export const SaveLeadSettings = 'Saving lead settings';
    export const AuthoriseRequest = 'Authorization of request';
  }

  export namespace QueryStrings {
    export const isConverged = 'isConverged';
    export const includeStopSoldPlans = 'includeStopSoldPlans';
    export const CorrelationId = 'correlationId';
    export const Referer = 'referer';
    export const ApplicationId = 'appId';
    export const PartnerId = 'partnerId';
    export const Product = 'product';
    export const PlanId = 'planId';
    export const DemoId = 'demoId';
    export const LabId = 'labId';
    export const PublisherId = 'publisherId';
    export const DemoSessionId = 'demoSessionId';
    export const UrlQuery = 'urlQuery';
    export const flightCodes = 'flightCodes';
    export const flightCode = 'flightCode';
    export const locale = 'locale';
    export const tab = 'tab';
    export const region = 'region';
    export const billingRegion = 'billingregion';
    export const entityType = 'entityType';
    export const modalAppId = 'modalAppId';
    export const modalECAId = 'modalECAId';
    export const signInModalType = 'signInModalType';
    export const productId = 'productId';
    export const modalServiceId = 'modalServiceId';
    export const modalReviewId = 'modalReviewId';
    export const modalReviewAppId = 'modalReviewAppId';
    export const modalReviewTitle = 'modalReviewTitle';
    export const country = 'country';
    export const ctaType = 'ctaType';
    export const ete = 'ete';
    export const apiVersion = 'api-version';
    export const version = 'version';
    export const showAllApps = 'showAllApps';
    export const applicationType = 'applicationType';
    export const modalRatingId = 'modalRatingId';
    export const extraData = 'extraData';
    export const embedHost = 'embedHost';
    export const includeTestProducts = 'includeTestProducts';
    export const isEmbedded = 'isEmbedded';
    export const search = 'search';
    export const industry = 'industry';
    export const serviceType = 'serviceType';
    export const category = 'category';
    export const product = 'product';
    export const page = 'page';
    export const certification = 'certification';
    export const UseSecondarySearch = 'UseSecondarySearch';
    export const market = 'market';
    export const productsFlow = 'productsFlow';
    export const ExcludePublic = 'excludePublic';
    export const hideKeys = 'HideKeys';
    export const searchLocale = 'searchLocale';
    export const searchQuery = 'searchQuery';
    export const filter = '$filter';
    export const skiptoken = '$skiptoken';
    export const storefront = 'storefront';
    export const HideKeys = 'HideKeys[]';
    export const includePrivateOffers = 'includePrivateOffers';
    export const expand = '$expand';

    export namespace Auth {
      export const HOST_NAME = 'hostName';
      export const LOGIN_TYPE = 'loginType';
      export const MSAL_USER_HOME_ID = 'msalUserHomeId';
      export const FROM = 'from';
      export const REFRESH_TOKEN = 'refreshToken';
    }
  }

  export namespace Curated {
    export const top = 'top';
    export const everything = 'everything';
    export const office365 = 'Office365';
    export const dynamics365 = 'Dynamics 365';
    export const powerPlatform = 'PowerPlatform';
    export const powerBi = 'Power BI';
  }

  export namespace AppActionTypes {
    export const GET = 'GET';
    export const CREATE = 'CREATE';
    export const FREE = 'FREE';
    export const TRIAL = 'TRIAL';
    export const REQUESTFORTRIAL = 'REQUESTFORTRIAL';
  }

  // These constants are the CTA resource keys
  export namespace ActionStrings {
    export const Get = 'CTA_Get';
    export const Try = 'CTA_Try';
    export const RequestTrial = 'CTA_ContactMe';
    export const Contact = 'Contact';
    export const TestDrive = 'CTA_TestDrive';
    export const Service = 'Inquire';
    export const Purchase = 'CTA_Get';
    export const Select = 'Select';
    export const Add = 'CTA_Add';
    export const BuyLicense = 'CTA_BuyLicense';
    export const Install = 'CTA_Install';
  }

  export enum NpsActions {
    pdpViewsCount = 'pdpViewsCount',
    searchActionsCount = 'pdpViewsCount',
    filterActionsCount = 'filterActionsCount',
    galleryChangesCount = 'galleryChangesCount',
    categoryChangesCount = 'categoryChangesCount',
    paginationActionsCount = 'paginationActionsCount',
    seeAllActionsCount = 'seeAllActionsCount',
  }

  export enum BadgeType {
    OfficeAwards = 'OfficeAwards',
    SolutionMap = 'SolutionMap',
    PBICertified = 'PBICertified',
    FreeTrial = 'FreeTrial',
    AzureExpertsMSP = 'AzureExpertsMSP',
    MicrosoftCertified = 'MicrosoftCertified',
    SelfAttested = 'SelfAttested',
    PrivateOffer = 'PrivateOffer',
    IntegratedSolution = 'IntegratedSolution',
    AzureBenefitEligible = 'AzureBenefitEligible',
    CertifiedSoftware = 'CertifiedSoftware',
  }

  export enum ButtonType {
    Link = 'link',
    Label = 'label',
  }

  export enum CTAType {
    Get = 1,
    Try = 2,
    RequestTrial = 3,
    Contact = 4,
    TestDrive = 5,
    Service = 6,
    Purchase = 7,
    Select = 8,
    AlreadyPurchased = 9,
    Partner = 10,
    DownloadSample = 11,
    Add = 12,
    BuyLicense = 13,
    Install = 14,
  }

  export enum CertificationType {
    Undefined = 'Undefined',
    None = 'None',
    SelfAttested = 'SelfAttested',
    MicrosoftCertified = 'MicrosoftCertified',
  }

  export enum currentView {
    AppDetails = 'appDetails',
    AppGallery = 'curated appGallery',
    SearchAppGallery = 'search filtered appGallery',
  }

  // There are 3 different type of sigin modals. Based on this enum value, we show corresponding modal.
  // const is removed here because const enums do not produce a lookup object when compiled. We are referencing this object in aad.js file.
  // It will give an error when we reference SignInType except as part of a member reference.
  export enum SignInType {
    OldSignInModal, // This is added to support old legacy modal
    SignInWith_MSA_AAD,
    SignInWith_AAD,
    SignInWith_MSA,
    SwitchTo_AAD,
    SwitchTo_MSA,
    Authorized, // This will be set when you are signed in with a proper authentication which that app supports
  }

  export namespace SignInRedirect {
    export const App = 'app';
    export const Service = 'service';
    export const ECA = 'eca';
    export const Review = 'review';
    export const Report = 'reportAbuse';
    export const Checkout = 'checkout';
  }

  // These action codes are for lead gen api which has to be added in the request headers.
  export namespace ActionCodes {
    export const RequestTrial = 'PLT';
    export const TryGet = 'INS';
    export const Contact = 'CTT';
    export const CrossListingContact = 'DNC';
    export const TestDrive = 'StartTestDrive';
    export const ConsultingServices = 'CSC';
  }

  export const TestDriveOfferIdVersion = '0.1';

  export enum ModalType {
    NoModalShow,
    SignIn,
    SignOut,
    CTA,
    Contact,
    Image,
    Video,
    MediaModal,
    NPS,
    Rating,
    Error,
    Drive,
    Iframe,
    Disclaimer,
    Animation,
    ReportAbuse,
    Instructions,
    DownloadSample,
    ReviewComment,
    ReviewMarkAsHelpful,
  }

  export namespace dynamicsLicense {
    export const dynamics365License = 'Dynamics 365';
    export const dynamics365FinancialLicense = 'Dynamics 365 for Financials';
    export const noLicense = 'No License';
    export const currentD365LicenseNumber = 2;
  }

  export const adminPortal = 'admin-portal';
  export const adminPortalBackendKey = 'Admin Portal';
  export const officeBackendKey = 'Office 365';
  export const officeFlatBackendKey = 'Office365';

  export namespace dynamics365 {
    export const dynamics365 = 'dynamics-365';
    export const operationsED = 'dynamics-365-for-operations';
    export const financeED = 'dynamics-365-for-finance-and-operations';
    export const salesED = 'dynamics-365-for-sales';
    export const fieldServicesED = 'dynamics-365-for-field-services';
    export const serviceAutomationED = 'dynamics-365-for-project-service-automation';
    export const customerServiceED = 'dynamics-365-for-customer-services';
    export const customerInsightsED = 'dynamics-365-customer-insights';
    export const financeAndOperationsBD = 'dynamics-365-business-central';
    export const marketingED = 'dynamics-365-marketing';
    export const commerceED = 'dynamics-365-commerce';
    export const supplyChainManagementED = 'dynamics-365-supply-chain-management';
  }

  export namespace PowerBI {
    export const powerBi = 'power-bi';
    export const visuals = 'power-bi-visuals';
  }
  export namespace InternetOfThings {
    export const urlKey = 'internet-of-things';
  }

  export enum filterTileTypes {
    search = 'search',
    product = 'product',
    industry = 'industry',
    category = 'category',
    serviceType = 'serviceType',
  }
  export type TypeFilterTileTypes = keyof typeof Constants.filterTileTypes;

  export enum filterMaps {
    products = 'products',
    industries = 'industries',
    categories = 'categories',
    serviceTypes = 'serviceTypes',
  }
  export type TypeFilterMaps = keyof typeof Constants.filterMaps;

  export namespace embedMessage {
    export const refreshToken = 'refreshToken';
    export const loadMarketplaceV2 = 'loadMarketplaceV2';
    export const loadMarketplace = 'loadMarketplace';
    export const renewToken = 'renewToken';
  }

  export namespace Avert {
    export const ReportAbuseCategories: IAvertAbuseCategory[] = [
      {
        locKey: 'ReportModal_None_Label',
        dropDownValue: 'None',
        avertIdentifier: 'None',
      },
      {
        locKey: 'ReportModal_OffensiveContent_Label',
        dropDownValue: 'OffensiveContent',
        avertIdentifier: 'OffensiveContent',
      },
      {
        locKey: 'ReportModal_SpamAndAdvertising_Label',
        dropDownValue: 'SpamAndAdvertising',
        avertIdentifier: 'Other',
      },
      {
        locKey: 'ReportModal_InvalidReview_Label',
        dropDownValue: 'InvalidReview',
        avertIdentifier: 'InvalidReview',
      },
      {
        locKey: 'ReportModal_Other_Label',
        dropDownValue: 'Other',
        avertIdentifier: 'Other',
      },
    ];
  }

  export namespace TeachingBubblePurchaseConfirmation {
    export const AdminCenterLinkId = 'adminCenter';
    export const AdminCenterLinkName = 'Admin center link teaching bubble';
    export const AdminCenterYourProductsLinkId = 'adminCenterYourProducts';
    export const AdminCenterYourProductsLinkName = 'Admin center your products link teaching bubble';
    export const AdminCenterLicensesLinkId = 'adminCenterLicenses';
    export const AdminCenterLicensesLinkName = 'Admin center licenses link teaching bubble';
  }

  export enum RequestsMethods {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
    OPTIONS = 'OPTIONS',
    HEAD = 'HEAD',
  }

  export enum SearchSources {
    Search = 'Search',
    Marko = 'Marko',
    SearchApi = 'SearchApi',
  }

  export enum LeadCategory {
    Public = 'public',
    Private = 'private',
  }

  export const LeadSource = {
    SPZA: 'SPZA',
    D365BusinessCentral: 'D365BusinessCentral',
    D365Sales: 'D365Sales',
    Office: 'Office',
    PowerBI: 'PowerBI',
  } as const;

  type LeadSourceKeys = keyof typeof LeadSource;
  export type LeadSourceType = typeof LeadSource[LeadSourceKeys];

  export const LeadSubSource = {
    InClient: 'InClient',
    Admin: 'Admin',
    Web: 'Web',
  } as const;

  type LeadSubSourceKeys = keyof typeof LeadSubSource;
  export type LeadSubSourceType = typeof LeadSubSource[LeadSubSourceKeys];

  export namespace Telemetry {
    export namespace Action {
      // Primary Actions
      export const EmbedMessaging = 'Embed Messaging';
      export const Country = 'Country';
      export const Click = 'Click';
      export const Skip = 'Skip';
      export const VariantAssignment = 'VariantAssignment';
      export const Search = 'Search';
      export const MarkoSearchTestTraffic = 'Marko Search Test Traffic';
      export const NewSearchApi = 'New Search Api';
      export const ACS = 'ACS';
      export const SortingSearch = 'SortingSearch';
      export const Landing = 'Landing';
      export const PageLoad = 'Page Load';
      export const openInNewWindow = 'Open In New Window';
      export const PerfResources = 'Perf resources';
      export const EmbedMessageListener = 'Embed Message Listener';
      export const NetworkCall = 'Network Call';
      export const FilterClick = 'Filter Click';
      export const FilterExpand = 'Filter Expand';
      export const FilterChange = 'Filter Change';
      export const FilterSearchChange = 'Filter Search Change';
      export const NotifyResult = 'Notify Result';
      export const ImageLoad = 'Image Load';
      export const RefreshToken = 'Refresh Token';
      export const ErrorModal = 'Error Modal';
      export const MyOrgApps = 'My Org Apps';
      export const PublishAcquisitionInfo = 'Publish AcquisitionInfo';
      export const Check = 'Check';
      export const Uncheck = 'Uncheck';
      export const CreateUserFromToken = 'createUserFromToken';
      // Secondary Actions
      export const SpzaUserId = 'spzaUserId';
      export const GoogleId = 'googleClientId';
      export const UserSettings = 'User Settings';
      export const UserTenantInfo = 'User Tenant Info';
      export const PerfEvents = 'Perf Telemetry Events';
      export const NPS = 'NPS';
      export const Feedback = 'Feedback';
      export const FieldHub = 'FieldHub';
      export const CrossListingInfo = 'Cross Listing Info';
      export const EnableFeature = 'EnableFeature';
      export const ClientLocation = 'ClientLocation';
      export const Open = 'Open';
      export const Close = 'Close';
      export const Cancel = 'Cancel';
      export const Expand = 'Expand';
      export const Collapse = 'Collapse';
      export const ReadAccess = 'Read Access';
      export const WriteAccess = 'Write Access';
      export const Copy = 'Copy';
      export const Upsert = 'Upsert';
      export const Delete = 'Delete';
      export const Redirecting = 'Redirecting';
      export const Submitting = 'Submitting';
      export const Submitted = 'Submitted';
      export const TileData = 'Tile Data';
      export const MarkoSearch = 'Marko Search';
      export const SearchCombinedResults = 'search combined results';

      export const Error = 'Error';
      export const SubmitError = 'Submit Error';
      export const SessionError = 'Session Error';

      export const LocalStorage = 'LocalStorage';

      export const Hover = 'Hover';

      export const ConsentBanner = 'ConsentBanner';
      export const RouteChanged = 'Route Changed';

      export const GetTenantType = 'Get Tenant Type';
      export const GetTenantDetails = 'Get Tenant Details';
      export const GetUserTenantInfo = "Get user's tenant info";

      /** This product could be either public or private */
      export const GetProduct = 'Get product';
      export const GetPreviewProduct = 'GetPreviewProduct';
      export const GetAppSuggestedItems = 'Get Suggested App Items';
      export const GetAppRecommendedItems = 'Get Recommended App Items';
      export const GetServiceRecommendedItems = 'Get Recommended Service Items';
      export const GetPrivateOffers = 'Get Private Offers API';
      export const GetPrivateOffer = 'Get Private Offer by ID API';
      export const GetServiceSuggestedItems = 'Get Suggested Service Items';

      export const EnsureAppDetailsData = 'Ensure App Details Data';
      export const EnsureAppReviewsData = 'Ensure App Reviews Data';
      export const EnsureServiceDetailsData = 'Ensure Service Details Data';

      export const DeleteUserFavourite = 'Delete User Favourite';
      export const UpsertUserFavourite = 'Upsert User Favourite';
      export const FetchUserFavourites = 'Fetch User Favourites';

      export const InputValidations = 'Inputs validations';
      export const PartnerMessageListener = 'Partners Message Listener';
      export const PartnerMessageSender = 'Partners Message Sender';

      export const getUserBillingAccounts = 'get User BillingAccounts';
      export const getUserProfile = 'get User Profile';
      // Flighting (a/b or splitting) testing actions
      export const AssignFlight = 'Assign Flight';

      export const OldCheckout = 'OldCheckout';

      // New checkout expeience
      export const PlaceOrder = 'Place order';
      export const Checkout = 'Checkout';
      export const Purchase = 'Purchase';
      export const PreWarm = 'PreWarm';
      export const PreWarmAJAX = 'PreWarmAJAX';

      export const TrailData = 'Trail Data';
      export const RootHandler = 'Root Handler';

      // Long Term SaaS
      export const LongTermSaaS = 'LongTermSaaS';

      export const PerfTiming = 'Perf timing';
      export const PerfRedirect = 'Perf redirect';
      export const PerfFetchStart = 'Perf fetch start';
      export const PerfRequestStart = 'Perf request start';
      export const PerfResponseStart = 'Perf response start';
      export const PerfResponseEnd = 'Perf response end';
      export const PerfDOMLoading = 'Perf DOM loading';
      export const PerfDOMInteractive = 'Perf DOM interactive';
      export const PerfDOMContentLoaded = 'Perf DOM content loaded';

      export const Logout = 'Logout';
      export const Login = 'Login';
      export const LoginIframe = 'Login Iframe';
      export const CVA = 'CVA';
      export const TeachingBubble = 'Teaching bubble';

      // Embed UX
      export const EmbedBuyLicenseClick = 'Embed Buy License Click';
      export const EmbedInstallClick = 'Embed Install Click';

      // Federated modules
      export const RemoteStatus = 'RemoteStatus';

      // Leads
      export const SendLead = 'SendLead';
      export const SendLeadInfo = 'SendLeadInfo';
      export const GetLeadActionCode = 'GetLeadActionCode';
      export const ProcessLeadInfo = 'ProcessLeadInfo';

      export const GetCustomers = 'getCustomers';
    }

    export namespace ActionModifier {
      export const ReceiveMessage = 'Receive Message';
      export const CloseAuth = 'close_auth';
      export const PaymentLoaded = 'Payment Loaded';
      export const SaasLinkTooltip = 'saaslinking tooltip';
      export const SaaSLinkingTile = 'saaslinking tile';
      export const RedirectButton = 'RedirectButton';
      export const RedirectModal = 'RedirectModal';
      export const FlagClicked = 'Country clicked';
      export const FooterLocale = 'Footer locale';
      export const CountryModal = 'Country modal';
      export const SearchV1End = 'SearchV1End';
      export const UserType = 'User Type';
      export const AdminCenter = 'Admin Center';
      export const AdminCenterYourProducts = 'Admin Center Your Products';
      export const AdminCenterLicenses = 'Admin Center Licenses';
      export const Start = 'Start';
      export const InProgress = 'InProgress';
      export const End = 'End';
      export const Abandon = 'Abandon';
      export const Skip = 'Skip';
      export const StartBack = 'StartBack';
      export const Error = 'Error';
      export const Info = 'Info';
      export const RRSubmit = 'R&R';
      export const RRDelete = 'R&R Delete';
      export const Link = 'Link';
      export const Tab = 'Tab';
      export const Debug = 'Debug';
      export const Exception = 'Exception';
      export const Warning = 'Warning';
      export const Declined = 'Declined';
      export const ConsentModal = 'Consent Modal Dialog';
      export const LoginModal = 'Login Modal';
      export const MyOrders = 'My Orders';
      export const Signup = 'Signup';
      export const ChangeCheckoutView = 'Change checkout view';
      export const CrossListingContact = 'Cross Listing Contact';
      export const SubmitLeadGen = 'Submit Lead Gen';
      export const AppSearchSuggestions = 'App Search Suggestions';
      export const ServiceSearchSuggestions = 'Consulting Service Search Suggestions';
      export const ECASearchSuggestions = 'ECA Offer Search Suggestions';
      export const AppSearchForAll = 'App Search For All';
      export const ServicesSearchForAll = 'Consulting Services Search For All';
      export const CloudsIndustrySearchForAll = 'Industry Clouds Search For All';
      export const ParnerSearchAll = 'Partners Search For All';
      export const ECASearchForAll = 'ECA Offers Search For All';
      export const SearchReturned = 'Search Returned From Server';
      export const TrailIdUpdate = 'TrailId update';
      export const TrailIdValid = 'TrailId valid';
      export const HeaderFromUrl = 'HeaderFromUrl';
      export const HeaderFromCookie = 'HeaderFromCookie';
      export const SpzaUserIdNew = 'New User';
      export const SpzaUserIdReturn = 'Returning User';
      export const Tile = 'Tile';
      export const CookieData = 'CookieData';
      export const BillingCountry = 'Billing Country';
      export const ChooseSKU = 'Choose SKU';
      export const OfferUnavailableInRegion = 'Offer Unavailable in Region';
      export const DownloadCSV = 'Download CSV';
      export const DownloadPBIVisual = 'Download Power BI Visual';
      export const DownloadPBIVisualSample = 'Download Power BI Visual Sample';
      export const VMFilter = 'VM Filter';
      export const TestDrive = 'Test Drive';
      export const AzureTestDrive = 'Azure Test Drive';
      export const NavigatingToTestDrive = 'Navigating to test drive';
      export const TestDriveDismissed = 'Test Drive Dismissed';
      export const FilterClear = 'Filter Clear';
      export const FilterPane = 'Filter Pane';
      export const FilterTag = 'Filter Tag';
      export const Feedback = 'feedback';
      export const FeedbackModal = 'feedbackModal';
      export const ShowingResultsForFeedback = 'ShowingResultsFor feedback';
      export const Breadcrumb = 'Breadcrumb';
      export const Results = 'Results';
      export const FirstPartyAppPromotion = 'First Party App Promotion';
      export const CTAInfo = 'CTA Info';
      export const ResetFilters = 'Reset Filters';
      export const GoogleInfo = 'Google Info';
      export const SearchApiSuggestions = 'SearchApi Suggestions';
      export const SearchApiSuggestionsError = 'SearchApi Suggestions Error';
      export const Suggestions = 'Suggestions';
      export const MailToCTA = 'CTA MailTo';
      export const ContentDrawer = 'Content Drawer';
      export const ReportCTA = 'Report CTA';
      export const ReportModal = 'Report Modal';
      export const D365License = 'Dynamic license dispute';
      export const EmbedD365InstallButton = 'Dynamic Install Button';
      export const EmbedBAGBuyLicenseButton = 'BAG Offer Buy License Button';
      export const UserProfileEdit = 'User profile edit';
      export const LeadCampaignGeneration = 'Lead Campaign Generate';
      export const CTACheckBox = 'CTA Dialog Checkbox';
      export const AppDetailCTAButton = 'App Detail Page CTA Button';
      export const AppDetailCTAButtonFromTable = 'App Detail Page CTA Button From Table';
      export const ECADetailCTAButton = 'ECA Detail Page CTA Button';
      export const AppDynamicCampaignCTAButton = 'Dynamic Campaign CTA Button';
      export const AppTileCTAButton = 'App Tile CTA Button';
      export const ServiceDetailCTAButton = 'Service Detail Page CTA Button';
      export const ServiceTileCTAButton = 'Service Tile CTA Button';
      export const PartnerCTAButton = 'Partner CTA Button';
      export const ReviewItemMoreButton = 'Review Item More Button';
      export const ReviewPageNavigationButton = 'Review Page Navigation Button';
      export const ReviewsGetMarkedAsHelpful = 'Reviews Get Marked As Helpful';
      export const ReviewSetMarkedAsHelpful = 'Review Set Marked As Helpful';
      export const ReviewItemMarkAsHelpfulUnauthorizedMessage = 'Review Item Mark As Helpful Unauthorized Message';
      export const ReviewItemMarkAsHelpfulButton = 'Review Item Mark As Helpful Button';
      export const ReviewItemMarkAsHelpfulSignIn = 'Review Item Mark As Helpful SignIn';
      export const ReviewItemMarkAsHelpfulDialog = 'Review Item Mark As Helpful Dialog';
      export const ReviewItemCommentsToggleButton = 'Review Item Comments Toggle Button';
      export const ReviewItemCommentsSeeMore = 'Review Item Comments See More';
      export const ReviewItemCommentsSignIn = 'Review Item Comments Sign In';
      export const ReviewItemCommentsSeeLess = 'Review Item Comments See Less';
      export const ReviewItemCommentDialog = 'Review Item Comment Dialog';
      export const ReviewItemCommentDialogUpdate = 'Review Item Comment Dialog Update';
      export const ReviewItemCommentDialogDelete = 'Review Item Comment Dialog Delete';
      export const ReviewItemCommentCreationSubmit = 'Review Item Comment Creation Submit';
      export const ReviewItemCommentEditButton = 'Review Item Comment Edit Button';
      export const ReviewItemCommentDeleteButton = 'Review Item Comment Delete Button';
      export const ReviewItemCommentedReviewIds = 'Review Item Commented Review Ids';
      export const ReportReview = 'Report review';
      export const LocalStorageItem = 'Local Storage Item';
      export const SessionStorageItem = 'Session Storage Item';
      export const CookieItem = 'Cookie Item';
      export const BrowserStorageCookieDataBuffer = 'Browser Storage Cookie Data Buffer';
      export const BrowserStorageLocalStorageDataBuffer = 'Browser Storage Local Storage Data Buffer';
      export const AssignLicensesButton = 'Assign Licenses Button';
      export const UserFavouriteNavigationBarButton = 'User Favourite Navigation Bar Button';
      export const UserFavouriteTileDetailButton = 'User Favourite Tile Detail Button';
      export const UserFavouriteTileButton = 'User Favourite Tile Button';
      export const UserFavouriteDetailPage = 'User Favourite Detail Page';
      export const OpenRatingModalFromReviewTab = 'Open Rating Modal From Review Tab';
      export const HeaderTextItem = 'Header Text Item';
      export const Purchase = 'Purchase';
      export const CompletePurchase = 'CompletePurchase';
      export const ToggleAdminExperience = 'ToggleAdminExperience';
      export const PidlNoSelectionOptionsFound = 'No Selection PI Option Found';
      export const PidlAddNewPaymentMethod = 'Add New Payment Method Button';
      export const PidlAddPILoad = 'PIDL Load - Add Payment Method';
      export const PidlHandlePaymentChallenge = 'PIDL Handle Payment Challenge';
      export const ConfigureNowButton = 'Configure Now Button';
      export const ConfigureNowLink = 'Configure Now Link';
      export const PlanSelected = 'PlanSelected';
      export const TermSelected = 'TermSelected';
      export const PlaceOrderClicked = 'PlaceOrderClicked';
      export const HandleTransactCheckout = 'Handle Transact Checkout Modal Redirect';
      export const ScrollToTopButton = 'ScrollToTopButton';
      export const FilterPDPReviews = 'Filter PDP Reviews';
      export const SortPDPReviews = 'Sort PDP Reviews';
      export const SortSearchResults = 'Sort Search Results';
      export const SortSearchOpened = 'Sort Search Opened';
      export const SearchWithinCurrentFilter = 'Search within current filter';
      export const HomeApps = 'Home Apps Info';
      export const PrivateOffers = 'private offers nav bar';
      export const WriteReview = 'Write a review';
      export const UpdateReview = 'Update a review';
      export const DeleteReview = 'Delete a review';
      export const OfficeOffersPurchase = 'Office Offers Purchase';
      export const SitemapLinkClick = 'Sitemap Link Click';
      export const ExternalRatingSummaryLink = 'External Rating Summary Link';
      export const ExternalReviewLink = 'External Review Link';
      export const ExternalLink = 'External Link';
      export const InternalLink = 'Internal Link';
      export const PurchaseErrorLink = 'Purchase Error Link';
      export const CreateCustomer = 'Create customer';
      export const NextCheckoutStep = 'Next Checkout Page';
      export const ViewAllButton = 'View all button';
      export const ClearFilters = 'Clear filters';
      export const ClearSearchBoxText = 'Clear search box text';
      export const ClearSearch = 'Clear search';
      export const NoSearchResultsPage = 'No search results page';
      export const NpsPreviewDismissed = 'Preview dismissed';
      export const NpsSurveyDismissed = 'Survey dismissed';
      export const NpsSurveySubmitted = 'Survey submitted';
      export const NpsPresented = 'Presented';
      export const SmileFrownFeedbackOpened = 'Opened';
      export const SmileFrownFeedbackDismissed = 'Dismissed';
      export const SearchApiNoResults = 'search api no results';
      export const MarkoApiNoResults = 'marko api no results';
      export const LongTermSaasTablePresented = 'Long Term SaaS Table Presented';
      export const Complete = 'complete';
      export const BillingCountrySelected = 'BillingCountrySelected';
      export const BannerHyperLink = 'BannerHL';
      export const BannerClick = 'BannerCL';
      export const SSO_DETACHED_IFRAME_FROM_DOM = 'SSO iframe detached from DOM';
      export const SSO_LOGIN_SUCCEEDED = 'SSO Login succeeded';
      export const WINDOW_LISTENERS_INITIATED = 'Window listeners initiated';
      export const SSR_LOGIN_STARTED = 'SSR login in process';
      export const SSO_LOGIN_STARTED = 'SSO login started';
      export const SSO_IFRAME_LOADED = 'SSO iframe loaded';
      export const SSO_IFRAME_ATTACHED_TO_DOM = 'SSO iframe attached to DOM';
      export const SSO_GET_LOGIN_URL = 'got user login url';
      export const SSO_EXTRACT_CODE_NO_IFRAME = 'missing iframe element to extract code';
      export const SSO_EMPTY_IFRAME_CODE = 'missing code in iframe';
      export const SSO_LOGIN_ERROR = 'SSO login error';
      export const SSO_LOGIN_TIMEOUT = 'SSO login timeout';
      export const SSO_IFRAME_CODE_FOUND = 'found iframe code to extract refresh token';
      export const SSO_FIRST_ATTEMPT = 'silent login attempt';
      export const SSO_FALLBACK_ATTEMPT = 'explicit login attempt';
      export const AccessTokenSilentFetchingError = 'AccessTokenSilentFetchingError';
      export const AccessTokenSilentFetchingSuccess = 'AccessTokenSilentFetchingSuccess';
      export const CreateAuthenticatedUserFailure = 'CreateAuthenticatedUserFailure';
      export const CreateAuthenticatedUserSuccess = 'CreateAuthenticatedUserSuccess';
      export const MsalInitializationSuccess = 'MsalInitializationSuccess';
      export const MsalInitializationError = 'MsalInitializationError';
      export const PreAuthRedirectFailed = 'PreAuthRedirectFailed';
      export const AuthRedirectFailed = 'AuthRedirectFailed';
      export const AuthRedirectSucceed = 'AuthRedirectSucceed';
      export const AuthRedirectNotFromAAD = 'AuthRedirectNotFromAAD';
      export const LogoutFailed = 'LogoutFailed';
      export const AzureRedirect = 'AzureRedirect';
      export const GetLinkedInProductGroup = 'get LinkedIn product group';
      export const MenuClick = 'Menu Click';
      export const Linkedin = 'Linkedin';
      export const LinkedinFeedbackButton = 'Linkedin feedback button';
      export const LinkedinLink = 'Linkedin link';
      export const LinkedinEventLink = 'Linkedin event link';
      export const LinkedinFeedbackSubmit = 'Linkedin feedback submit';
      export const LinkedinFeedbackDismiss = 'Linkedin feedback dismiss';
      export const OfflineRemote = 'Offline Remote';
      export const OnlineRemote = 'Online Remote';
      export const RemoveEventListener = 'Remove event listener';
      export const HandleNewConsent = 'Handle new consent';
      export const GetConsent = 'Get consent';
      export const WcpConsentCallBack = 'WCP consent callback';
      export const GetItem = 'GetItem';
      export const SaveItem = 'SaveItem';
      export const MigrationStateOldOnly = 'OldOnly';
      export const MigrationStateNewOnly = 'NewOnly';
      export const MigrationStateSideBySide = 'SideBySide';
    }

    export namespace LeadsDropReason {
      export const PreviewOffer = 'Preview offer.';
      export const AppNotAvailableOrPrivate = 'App is not available or private.';
      export const PowerBiTransact = 'Power BI Visuals leads allowed only for transact apps, with Buy Now CTA.';
      export const ConsultingServices = 'Consulting services app is not part of the leads migration for now.';
      export const EmbedWithoutLead = 'Embedded with offer type that defined not to generate lead.';
      export const UserNotSignedIn = 'User not signed in.';
      export const ItemNotAvailable = 'Item not available';
    }
    export namespace OutRequest {
      export const Start = 'HttpOutboundRequest';
      export const EndWithSuccess = 'HttpOutboundRequestEndWithSuccess';
      export const EndWithClientError = 'HttpOutboundRequestEndWithClientError';
      export const EndWithServerError = 'HttpOutboundRequestEndWithServerError';
    }

    export namespace ProbeName {
      export const LogInfo = 'logTelemetryInfo';
      export const FlushInfo = 'flushTelemetryInfo';
      export const LogAndFlushInfo = 'logAndFlushTelemetryInfo';
    }

    export namespace AreaName {
      export const FilterPane = 'Filter Pane';
      export const AppDetails = 'App Details';
      export const SuggestedSearch = 'Suggested Search';
      export const SearchEntityDropDown = 'Search Entity Drop Down';
      export const AppTile = 'App Tile';
      export const ParentSaaSTile = 'Parent SaaS Tile';
      export const BundleAddIn = 'Bundle Add-In';
      export const ConsentModal = 'Consent Modal Dialog';
    }

    export namespace FeedbackName {
      export const Nps = 'NPS';
    }

    export namespace TemplateName {
      export const SaaSBundle = 'SaaS Bundle';
    }

    export namespace Id {
      export const SuggestedClick = 'Suggested Click';
      export const SearchDropDownClick = 'Search Drop Down Click';
      export const Nps = 'NPS';
    }

    export namespace ContentType {
      export const LinkExpanded = 'Gallery filter link expanded';
      export const LinkClicked = 'Gallery filter link clicked';
      export const SaaSBundlePageView = 'SaaS Bundle Page View';
      export const AddInBundlePageView = 'AddIn Bundle Page View';
      export const CheckoutBundlePageView = 'SaaSBundle Checkout PageView';
      export const OrderConfirmationBundlePageView = 'SaaSBundle OrderConfirmation PageView';
      export const PlaceOrderSaaSBundle = 'SaaSBundle Place Order';
      export const SkipConsentModal = 'SKIP_CONSENT';
    }

    export namespace PurchaseBlendsTelemetry {
      // PREWARM
      export const Bootstrapped = 'Bootstrapped';

      export namespace Errors {
        export const PBErrorPrefix = '[PB] Failure with status: ';
        export const ErrorIdPrefix = 'Error ids: ';
      }
      export const DismissedPrefix = 'Dismissed: ';

      export const TotalTimeForPlaceOrderPrefix = 'Total time for purchase since place order: ';
      export const PlaceOrderSuccess = '[PB] Place order success: ';
      export const FinishedLoading = '[PB] FinishedLoading';
      export const PageName = 'PurchaseBlends';
      export const EditProfile = 'EditProfile';
      export const EditProfileName = '[PB] Edit profile';
      export const AddProfile = 'AddProfile';
      export const AddProfileName = '[PB] Add profile';
      export const PickBillingFrequency = 'PickBillingFrequency';
      export const BillingFrequencyComponent = 'BillingFrequencyComponent';
      export const PickBillingTerm = 'PickBillingTerm';
      export const BillingTermComponent = 'BillingTermComponent';
      export const AddPayment = 'AddPayment';
      export const AddPaymentName = '[PB] Add payment';
      export const AddBillingProfile = 'AddBillingProfile';
      export const AddBillingProfileName = '[PB] Add billing profile';
      export const EditBillingProfile = 'EditBillingProfile';
      export const EditBillingProfileName = '[PB] Edit billing profile';
      export const PurchaseBlendsDisclaimerComponent = 'PurchaseBlendsDisclaimerComponent';
      export const PurchaseBlendsDisclaimerComponentName = 'Show user consent for disclaimer';
    }
  }

  export namespace PurchaseBlends {
    export const NewCheckout = 'NewCheckout';
    export const OldCheckout = 'OldCheckout';
    export const AlreadyPurchasedKey = 'AlreadyPurchasedWithNewExperience';
    export const AdminFlow = 'AdminFlow';
    export const IWFlow = 'IWFlow';

    export const Enrolled = 'true';
    export const Excluded = 'false';
    export const AlreadyPurchased = 'v';
  }
  /**
   * https://docs.microsoft.com/en-us/rest/api/authorization/role-definitions
   */
  export namespace RoleDefinitions {
    export const globalAdmin = '62e90394-69f5-4237-9190-012177145e10';
    export const billingAdmin = 'b0f54661-2d74-4c50-afa3-1ec803f12efe';
    export const rolesToCheck = [globalAdmin, billingAdmin];
  }

  export namespace Flighting {
    export const OldCheckout = 'OldCheckout';
    export const NewCheckout = 'NewCheckout';
  }

  export const scrubbedHeaderValue = 'SCRUBBED';
  export const apiServicePrefix = 'api/v2.0';
  export const marketplaceApiServicePrefix = 'api';
  export const armSubscriptionApiVersion = '2020-01-01';
  export const armBillingAccountsApiVersion = '2019-10-01-preview';
  export const armIsMaccApiVersion = '2018-08-01-beta';
  export const mprpApiVersion = '2018-08-01-beta';
  export const appsourceApiVersion = '2017-04-24';
  export const dbSchemaVersion = '2017-04-24';
  export const userCollectionSchemaVersion = '2016-10-14';
  export const acquisitionCollectionSchemaVersion = '2017-04-27';
  export const TestDrivePath = 'JwtTokenDemoSession';
  export const MetadataDocType = 'Metadata';
  export const partitionedCollectionVersion = '2017-04-24';
  export const apiVersion = 'apiVersion';
  export const reviewsAPI = 'reviewsAPI';
  export const reviewsAPIVersion = 'reviewsAPIVersion';
  export const bundleVersion = 'bundleVersion';
  export const regionName = 'regionName';
  export const marketplaceApiVersion = 'marketplaceApiVersion';
  export const azureMarketplace = 'AzureMarketplace';
  export const appSource = 'AppSource';
  export const appSourceEmbed = 'AppSource_Embed';

  export enum CompetencyBadge {
    Gold,
    Silver,
    BadgeNotFound,
  }

  export const FeaturedAppsOrder = ['99', '64', '128', '1024', '256', '1', '8', '512', '4', '32', '16'];

  export const validEmbedExperienceProducts = [
    'power-bi',
    'power-bi-visuals',
    'dynamics-365-business-central',
    'dynamics-365-for-sales',
    'dynamics-365',
  ];
  export const validEmbedExperienceEmbedHost = ['admin-portal', 'dynamics-365'];

  export namespace TestDriveType {
    export const Showcase = 'Showcase';
    export const AzureTestDriveService = 'AzureTestDriveService';
    export const SolutionsTestDrive = 'SolutionsTestDrive';
  }

  export namespace TestDriveOutputType {
    export const HandOffUrl = 'handoffurl';
    export const MetadataMarkdown = 'metadatamarkdown';
  }

  export namespace EmailCategories {
    export const ContactSI = 'Contact SI';
    export const ContactSICrossListing = 'Contact SI (Cross listing)';
    export const CorrelationIdArgKey = 'correlationId';
    export const UserIdArgKey = 'userId';
  }

  export namespace ReservedCorrelationIds {
    export const EmptyId = '00000000-0000-0000-0000-000000000000';
    export const RepoCacheRefresh = '00000000-0000-0000-0000-000000000001';
    export const SignInEmptyId = '00000000-0000-0000-0000-000000000002';
    // error id used if no correlation id is available
    export const ErrorId = '00000000-0000-0000-0000-111111111111';
  }

  export namespace ReservedOperationNames {
    export const RepoCacheRefresh = 'RepoCacheRefresh';
  }

  export namespace CloudsIndustry {
    export const bitmaskPrefix = 'clouds_';
  }

  export namespace Endpoints {
    export namespace View {
      export const AppPricing = '/view/appPricing';
      export const TileData = '/view/tiledata';
      export const FilteredApps = '/view/filteredapps';
      export const AppFuturePricing = '/view/appFuturePricing';
      export const CloudsIndustryDataMap = '/view/cloudsindustrydatamap';
      export const CloudsIndustryFeatured = '/view/featuredcloudsindustry';
      export const CloudsIndustryFiltered = '/view/filteredcloudsindustry';
    }
  }

  export namespace TestDriveResponse {
    export const DemoSessionId = 'demoSessionId';
    export const Status = 'status';
    export const OutputItemList = 'outputItemList';
    export const HandOffUrl = 'HandOffUrl';
    export const StringKey = 'String';
    export const AppUri = 'app Uri';
    export const TestDrive = 'TestDrive';
    export const SessionSource = 'sessionSource';
    export const StoppedTime = 'stoppedTime';
    export const MarketPlaceMetadataMarkdownKey = '_marketPlaceMetadataMarkdown';
  }

  export namespace VersionNeutralCollection {
    export const Reviews = 'reviews';
    export const Users = 'users';
  }

  export namespace DocTypes {
    export const App = 'App';
    export const Pricing = 'Pricing';
    export const SolutionManifest = 'SolutionManifest';
  }

  export enum TestDriveState {
    Hot,
    Cold,
    Error,
    Unknown,
  }

  export namespace StorefrontName {
    export const AppSource = 'AppSource';
    export const Amp = 'Amp';
    export const Ibiza = 'Ibiza';
    export const G2 = 'G2';
    export const Embed = 'Embed';
  }

  export const InternalStoreFronts = [StorefrontName.AppSource, StorefrontName.Amp, StorefrontName.Ibiza];

  // These values for auth type are given by Office Store team
  export enum AuthType {
    MSA = 1,
    AAD = 2,
  }

  /**
   * All resource types
   * @export
   * @enum {number}
   */
  export enum ResourceTypes {
    'AppRelatedItems',
    'ServiceRelatedItems',
    'DataMapCloudsIndustry',
    'DataMapApps',
    'DataMapServices',
    'FilteredApps',
    'FilteredServices',
    'FilteredCloudsIndustry',
    'CuratedServices',
    'CuratedApps',
    'CuratedCloudsIndustry',
    'FlightCodedApp',
    'PreviewServices',
    'PreviewPricing',
    'PreviewApps',
    'PreviewCloudsIndustry',
    'HashMapApps',
    'HashMapServices',
    'HashMapPreviewApps',
    'HashMapPreviewServices',
    'App',
    'Service',
    'AllPublicCloudsIndustry',
    'AllCloudsIndustry',
    'AllApps',
    'RawAllApps',
    'RawAllAppsCatalog',
    'AllAppsExtra',
    'FeaturedApps',
    'FeaturedCloudsIndustry',
    'FlightCodedAndLiveApps',
    'User',
    'Pricing',
    'AppPricing',
    'SolutionMetadata',
    'SolutionRuntime',
    'Acquisition',
    'AllServices',
    'CatalogStartingPrices',
    'RawAllServices',
    'AllServicesExtra',
    'FeaturedServives',
    'UserProfile',
    'Event',
    'Review',
    'AllConsultingServices',
    'FeaturedServicesSections',
    'ServiceAcquisitions',
    'Favourites',
    'AllECAOffers',
    'AllAppReviews',
    'ECAAcquisitions',
    'ReviewsByAppId',
    'ReviewByIdByUser',
    'Customer',
    'CustomerAddress',
    'CalculatePrice',
    'Purchase',
    'PublisherOffer',
    'AllServicesCountries',
    'PrivateApps',
    'BillingAccounts',
  }

  export const Office365Mapping = {
    // Legacy Online

    SharePoint_Online: ['SharePoint Online'],
    Access_Online: ['Access Online'],
    OneNote_Online: ['OneNote Online'],
    Project_Online: ['Project Online'],

    // On The Web

    Outlook_Online: ['ProductOnTheWeb', 'Outlook'],
    Excel_Online: ['ProductOnTheWeb', 'Excel'],
    PowerPoint_Online: ['ProductOnTheWeb', 'PowerPoint'],
    Word_Online: ['ProductOnTheWeb', 'Word'],
    Office_Online: ['ProductOnTheWeb', 'Office app'],

    // iPad

    Excel_iPad: ['ProductOnIpad', 'Excel'],
    Word_iPad: ['ProductOnIpad', 'Word'],
    PowerPoint_iPad: ['ProductOnIpad', 'PowerPoint'],

    // iOS

    Office_iOS: ['ProductOnIos', 'Office app'],
    Outlook_iOS: ['ProductOnIos', 'Outlook'],

    // Android

    Office_Android: ['ProductOnAndroid', 'Office app'],
    Outlook_Android: ['ProductOnAndroid', 'Outlook'],

    // Other

    App_Details_Requirements_Products_Office365: ['Office 365'],
    App_Details_Requirements_Products_ExcelIOS: ['Excel for iPad'],
    App_Details_Requirements_Products_WordIOS: ['Word for iPad'],
    App_Details_Requirements_Products_OutlookOnWeb: ['ProductOnTheWeb', 'Outlook'],
    App_Details_Requirements_Products_PowerPointIOS: ['PowerPoint for iPad'],
    App_Details_Requirements_Products_OneNoteWindows10: ['OneNote for Windows 10'],
    App_Details_Requirements_Products_PowerBI: ['Power BI'],
    App_Details_Requirements_Products_Teams: ['Teams'],
    OfficeProductForAndroid: ['Outlook for Android'],
    OfficeProductForIOS: ['Outlook for iOS'],
    Office: ['Office app on Windows'],

    // MAC products

    Word_2016_Mac: ['ProductVersionOrLaterOnPlatform', ['Word 2016', 'Mac']],
    Word_2019_Mac: ['ProductVersionOrLaterOnPlatform', ['Word 2019', 'Mac']],
    Excel_2016_Mac: ['ProductVersionOrLaterOnPlatform', ['Excel 2016', 'Mac']],
    Excel_2019_Mac: ['ProductVersionOrLaterOnPlatform', ['Excel 2019', 'Mac']],
    PowerPoint_2016_Mac: ['ProductVersionOrLaterOnPlatform', ['PowerPoint 2016', 'Mac']],
    PowerPoint_2019_Mac: ['ProductVersionOrLaterOnPlatform', ['PowerPoint 2019', 'Mac']],
    Outlook_2016_Mac: ['ProductVersionOrLaterOnPlatform', ['Outlook 2016', 'Mac']],
    Outlook_2019_Mac: ['ProductVersionOrLaterOnPlatform', ['Outlook 2019', 'Mac']],

    // WIN products

    Word_2013: ['ProductVersionOrLaterOnPlatform', ['Word 2013', 'Windows']],
    Word_2013_SP1: ['ProductVersionOrLaterOnPlatform', ['Word 2013 SP1', 'Windows']],
    Word_2016: ['ProductVersionOrLaterOnPlatform', ['Word 2016', 'Windows']],
    Word_2019: ['ProductVersionOrLaterOnPlatform', ['Word 2019', 'Windows']],
    Excel_2013: ['ProductVersionOrLaterOnPlatform', ['Excel 2013', 'Windows']],
    Excel_2013_SP1: ['ProductVersionOrLaterOnPlatform', ['Excel 2013 SP1', 'Windows']],
    Excel_2016: ['ProductVersionOrLaterOnPlatform', ['Excel 2016', 'Windows']],
    Excel_2019: ['ProductVersionOrLaterOnPlatform', ['Excel 2019', 'Windows']],
    PowerPoint_2013: ['ProductVersionOrLaterOnPlatform', ['PowerPoint 2013', 'Windows']],
    PowerPoint_2013_SP1: ['ProductVersionOrLaterOnPlatform', ['PowerPoint 2013 SP1', 'Windows']],
    PowerPoint_2016: ['ProductVersionOrLaterOnPlatform', ['PowerPoint 2016', 'Windows']],
    PowerPoint_2019: ['ProductVersionOrLaterOnPlatform', ['PowerPoint 2019', 'Windows']],
    Outlook_2013: ['ProductVersionOrLaterOnPlatform', ['Outlook 2013', 'Windows']],
    Outlook_2013_SP1: ['ProductVersionOrLaterOnPlatform', ['Outlook 2013 SP1', 'Windows']],
    Outlook_2016: ['ProductVersionOrLaterOnPlatform', ['Outlook 2016', 'Windows']],
    Outlook_2019: ['ProductVersionOrLaterOnPlatform', ['Outlook 2019', 'Windows']],

    // M365 products

    Excel_M365_Win: ['ProductOnM365Plaform', ['Excel', 'Windows']],
    Excel_M365_Mac: ['ProductOnM365Plaform', ['Excel', 'Mac']],
    Word_M365_Win: ['ProductOnM365Plaform', ['Word', 'Windows']],
    Word_M365_Mac: ['ProductOnM365Plaform', ['Word', 'Mac']],
    Outlook_M365_Win: ['ProductOnM365Plaform', ['Outlook', 'Windows']],
    Outlook_M365_Mac: ['ProductOnM365Plaform', ['Outlook', 'Mac']],
    PowerPoint_M365_Win: ['ProductOnM365Plaform', ['PowerPoint', 'Windows']],
    PowerPoint_M365_Mac: ['ProductOnM365Plaform', ['PowerPoint', 'Mac']],

    // Legacy products

    OfficeProductOrLater_SharePoint_2013: ['ProductVersionOrLater', 'SharePoint 2013'],
    OfficeProductOrLater_SharePoint_2016: ['ProductVersionOrLater', 'SharePoint 2016'],
    Access_2016_SP1: ['ProductVersionOrLater', 'Access 2016 SP1'],
    Excel_2016_SP1: ['ProductVersionOrLater', 'Excel 2016 SP1'],
    OneNote_2016_SP1: ['ProductVersionOrLater', 'OneNote 2016 SP1'],
    Outlook_2016_SP1: ['ProductVersionOrLater', 'Outlook 2016 SP1'],
    Project_2016_SP1: ['ProductVersionOrLater', 'Project 2016 SP1'],
    PowerPoint_2016_SP1: ['ProductVersionOrLater', 'PowerPoint 2016 SP1'],
    Word_2016_SP1: ['ProductVersionOrLater', 'Word 2016 SP1'],
    Access_2013_SP1: ['ProductVersionOrLater', 'Access 2013 SP1'],
    OneNote_2013_SP1: ['ProductVersionOrLater', 'OneNote 2013 SP1'],
    Project_2013_SP1: ['ProductVersionOrLater', 'Project 2013 SP1'],
    Access_2016: ['ProductVersionOrLater', 'Access 2016'],
    OneNote_2016: ['ProductVersionOrLater', 'OneNote 2016'],
    Project_2016: ['ProductVersionOrLater', 'Project 2016'],
    Access_2013: ['ProductVersionOrLater', 'Access 2013'],
    OneNote_2013: ['ProductVersionOrLater', 'OneNote 2013'],
    Project_2013: ['ProductVersionOrLater', 'Project 2013'],
  };

  export const OfficeAwardsMapping = {
    // Office 2017 App Awards
    AppAwards_2017_BestBizValue_Place1: ['Best_Business_Value_for_An', 'Merchandise_Winner', '2017'],
    AppAwards_2017_BestBizValue_Place2: ['Best_Business_Value_for_An', 'Merchandise_Second', '2017'],
    AppAwards_2017_BestBizValue_Place3: ['Best_Business_Value_for_An', 'Merchandise_Third', '2017'],
    AppAwards_2017_BestUX_Place1: ['Best_User_Experience_For_An', 'Merchandise_Winner', '2017'],
    AppAwards_2017_BestUX_Place2: ['Best_User_Experience_For_An', 'Merchandise_Second', '2017'],
    AppAwards_2017_BestUX_Place3: ['Best_User_Experience_For_An', 'Merchandise_Third', '2017'],
    AppAwards_2017_BestInt_Place1: ['Best_Office_Integration_For_An', 'Merchandise_Winner', '2017'],
    AppAwards_2017_BestInt_Place2: ['Best_Office_Integration_For_An', 'Merchandise_Second', '2017'],
    AppAwards_2017_BestInt_Place3: ['Best_Office_Integration_For_An', 'Merchandise_Third', '2017'],
    AppAwards_2017_Mobile_Place1: ['Mobile_Award_For_An', 'Merchandise_Winner', '2017'],
    AppAwards_2017_Overall_Place1: ['Best_Overall_For_An', 'Merchandise_Winner', '2017'],
    AppAwards_2017_Overall_Place2: ['Best_Overall_For_An', 'Merchandise_Second', '2017'],
    AppAwards_2017_Overall_Place3: ['Best_Overall_For_An', 'Merchandise_Third', '2017'],
    AppAwards_2017_Peoples_Choice: ['Peoples_Choice_For_An', 'Merchandise_Winner', '2017'],
    // Office 2018 App Awards
    AppAwards_2018_BestBizValue_Place1: ['Best_Business_Value_for_An', 'Merchandise_Winner', '2018'],
    AppAwards_2018_BestBizValue_Place2: ['Best_Business_Value_for_An', 'Merchandise_Second', '2018'],
    AppAwards_2018_BestBizValue_Place3: ['Best_Business_Value_for_An', 'Merchandise_Third', '2018'],
    AppAwards_2018_BestUX_Place1: ['Best_User_Experience_For_An', 'Merchandise_Winner', '2018'],
    AppAwards_2018_BestUX_Place2: ['Best_User_Experience_For_An', 'Merchandise_Second', '2018'],
    AppAwards_2018_BestUX_Place3: ['Best_User_Experience_For_An', 'Merchandise_Third', '2018'],
    AppAwards_2018_BestInt_Place1: ['Best_Office_Integration_For_An', 'Merchandise_Winner', '2018'],
    AppAwards_2018_BestInt_Place2: ['Best_Office_Integration_For_An', 'Merchandise_Second', '2018'],
    AppAwards_2018_BestInt_Place3: ['Best_Office_Integration_For_An', 'Merchandise_Third', '2018'],
    AppAwards_2018_Overall_Place1: ['Best_Overall_For_An', 'Merchandise_Winner', '2018'],
    AppAwards_2018_Overall_Place2: ['Best_Overall_For_An', 'Merchandise_Second', '2018'],
    AppAwards_2018_Overall_Place3: ['Best_Overall_For_An', 'Merchandise_Third', '2018'],
    AppAwards_2018_Peoples_Choice: ['Peoples_Choice_For_An', 'Merchandise_Winner', '2018'],
    // Office 2019+ App Awards
    AppAwards: ['Office App Awards', 'General Office App Awards', '2019'],
  };

  export const AcquisitionCtaContactIgnored = 'CTA_Contact';

  export const DefaultLocale = 'en-us';
  export const DefaultLanguage = 'en';

  export namespace AcquisitionCategories {
    export const Private = 'private';
    export const Public = 'public';
  }

  export const defaultRoute = '/';
  export const privateOffers = 'private-offers';
  export const home = 'home';
  export const csGallery = 'marketplace/consulting-services';
  export const appsGallery = 'marketplace/apps';
  export const cloudsIndustryGallery = 'marketplace/cloudsIndustry';
  export const appDetailsCanonicalTabSuffix = '?tab=overview';
  export const partnersAppSource = 'marketplace/partner-dir';
  export const partnersIframe = 'partners';
  export const partnersSearchApiRoute = 'api/search/autocomplete';
  export const product = 'product';
  export const category = 'category';
  export const serviceType = 'serviceType';
  export const industry = 'industry';
  export const KeepInCanonicalFilters = ['product', 'category', 'serviceType', 'industry'];
  export const semicolon = '%3B';

  export namespace GalleryTypes {
    export const consultingServices = 'consulting-services';
    export const apps = 'apps';
  }

  export enum GalleryOffersTypes {
    ConsultingServices = 'consulting services',
    Apps = 'apps',
    CloudsIndustry = 'Industry Clouds',
    Partners = 'Partners',
  }

  export enum Views {
    locale = 'locale',
    appDetails = 'appDetails',
    checkout = 'checkout',
    orderConfirmation = 'orderConfirmation',
    partners = 'partners',
    blog = 'blog',
    reviews = 'reviews',
    sell = 'sell',
    about = 'about',
    billingregion = 'billingregion',
    testdrive = 'testdrive',
    fieldHub = 'fieldHub',
    serviceDetails = 'serviceDetails',
    partnerDetails = 'partnerDetails',
    partnerDetailsContact = 'partnerDetailsContact',
    partnerSuggestion = 'partnerSuggestion',
    userFavouriteDetail = 'userFavouriteDetail',
    privateOffers = 'privateOffers',
    privateOfferDetails = 'privateOfferDetails',
    campaigns = 'campaigns',
    sitemap = 'sitemap',
    home = 'home',
    serviceGallery = 'serviceGallery',
    appGallery = 'appGallery',
    cloudsIndustryGallery = 'cloudsIndustryGallery',
    partnersHost = 'partnersHost',
  }

  export namespace ReportAbuseDialog {
    export const dropdownHTMLIdName = 'report-abuse-dialog-category-dropdown';
  }

  export namespace HTMLAttribute {
    export const href = 'href';
    export const className = 'class';
    export const alt = 'alt';
    export const text = 'text';
    export const ariaExpanded = 'aria-expanded';
    export const textContent = 'textContent';
    export const ariaSelected = 'aria-selected';
    export const ariaChecked = 'aria-checked';
    export const ariaLabel = 'aria-label';
    export const role = 'role';
    export const id = 'id';
    export const ariaActivedescendant = 'aria-activedescendant';
    export const ariaLabeledBy = 'aria-labelledby';
    export const tabIndex = 'tabIndex';
    export const title = 'title';
    export const autoComplete = 'autoComplete';
  }

  export namespace HTMLTag {
    export const button = 'button';
  }

  // HTML Class Name Strings
  export namespace HTMLClassNameStrings {
    export const tile = 'spza_tileWrapper';
  }

  export namespace searchItemType {
    export const app = 'AppSearchResult';
    export const service = 'ServicesSearchResult';
    export const cloudsIndustry = 'CloudsIndustrySearchResult';
    export const showAll = 'SearchShowAll';
    export const searchWithinFilters = 'searchWithinFilters';
    export const eca = 'ECASearchResult';
    export const partner = 'PartnerSearchResult';
  }

  export namespace searchTypeString {
    export const AppsAndServices = 'Apps and Services';
    export const ECAoffers = 'ECA Offers';
    export const Partners = 'Partners';
  }

  export const defaultCountryForPrivacy = 'EU';

  export enum TokenClaims {
    givenName = 'given_name',
    familyName = 'family_name',
  }

  export namespace AzurePortal {
    // Takes the user to PDP in Azure Portal
    export const PDPBladeById = 'https://portal.azure.com/#blade/Microsoft_Azure_Marketplace/galleryItemDetailsBladeNopdl/id/{0}';
    export const CreateOfferById = 'https://portal.azure.com/#create/';
    export const CreateOfferPreview = '/preview';
  }

  export const partnerCenterLink = 'https://partner.microsoft.com/en-us/dashboard/account/v3/enrollment/introduction/azureisv';

  export const FilterCategoryToGlobalStrings = ['virtual-machine-images', 'solution-templates'];

  export enum FilterQuery {
    // azure marketplace
    filters = 'filters',
    subcategories = 'subcategories',
    subindustries = 'subindustries',

    // appsource
    product = 'product',
    industry = 'industry',
    category = 'category',
    serviceType = 'serviceType',

    trials = 'trials',
    pricingModel = 'pricingModel',
    ratings = 'ratings',
    appCompliance = 'appCompliance',
    sortBy = 'sortBy',
    azureBenefitEligible = 'azureBenefitEligible',
    copilotExtension = 'copilotExtension',
    productType = 'productType',

    country = 'country',
    region = 'region',
    search = 'search',
    page = 'page',
  }

  export namespace MACSubCategoryStrings {
    export const FreeTrial = 'trials_freeTrial';
    export const Windows = 'windows';
    export const Linux = 'linux';
    export const WindowsBased = 'windowsBased';
    export const LinuxBased = 'linuxBased';
    export const SystemWindows = 'operatingSystem_windows';
    export const SystemLinux = 'operatingSystem_linux';
    export const TestDrive = 'trials_testDrive';
    export const Microsoft = 'Microsoft';
    export const PublisherMicrosoft = 'publisher_microsoft';
    export const PublisherPartner = 'publisher_partners';
    export const SaaS = 'SaaS';
    export const Containers = 'Containers_MP';
    export const ContainerImages = 'ContainerImages_MP';
    export const Countries = 'country';
  }

  export namespace TypeOf {
    export const objectName = 'object';
    export const functionName = 'function';
    export const stringName = 'string';
    export const numberName = 'number';
    export const booleanName = 'boolean';
    export const undefinedName = 'undefined';
    export const nullName = 'null';
  }

  export enum ServiceTypes {
    Assessment = 1,
    Briefing = 2,
    Implementation = 4,
    ProofOfConcept = 8,
    WorkShop = 16,
  }

  export namespace RegExpFlag {
    // don't return after first match
    export const Global = 'g';

    // ^ and $ match start/end of line
    export const MultiLine = 'm';

    // case insensitive match
    export const CaseInsensitive = 'i';

    // proceed matching from where previous match ended only
    export const Sticky = 'y';

    // match with full unicode
    export const Unicode = 'g';

    // dot matches new line
    export const SingleLine = 'i';
  }

  export namespace UserFavourite {
    export enum ApplicationTypes {
      ITApplications = 'ITApplications',
      BusinessApplications = 'BusinessApplications',
      ITConsultingServices = 'ITConsultingServices',
      BusinessConsultingServices = 'BusinessConsultingServices',
    }

    export enum NotCriticalFailure {
      ItemExist = 'ItemExist',
      ItemNotExist = 'ItemNotExist',
      MaximumQuotaReached = 'MaximumQuotaReached',
    }

    export enum FetchDataStatus {
      Invalid = 'Invalid',
      IsFetching = 'IsFetching',
      Valid = 'Valid',
      Failure = 'Failure',
    }

    export enum DataItemModifyStatus {
      Delete = 'Delete',
      Upsert = 'Upsert',
      None = 'None',

      // after an operation has finished
      Done = 'Done',
    }

    export const ViewEndpoint = '/view/favourites';
  }

  export namespace ODataError {
    export const DefaultCode = 500;
    export const DefaultMessage = 'Error Object Not Found, Default to Internal Server Error';
    export const DefaultMessageCircularObject = 'Circular Object Cannot Expand for Details';
    export const DefaultMessageHandle = (error: any) =>
      `Error Message Not Found, Showing Original Error Object: ${stringifyError(error)}`;
  }

  export namespace AccessToken {
    export const DefaultHost = 'https://login.microsoftonline.com/';
    export const DefaultCommonPath = '/oauth2/token';
  }

  export namespace appSourceUrls {
    export const prod = 'https://appsource.microsoft.com';
    export const df = 'https://appgallery.spza-staging.net';
    export const canary = 'https://appgallery.spza-canary.net';
    export const development = 'http://localhost:3000/';
  }

  // Todo :: once the df and prod servers are set the urls are to be updated

  export namespace Header {
    export const classNamePrefix = 'spza-header';
    export const localizationNamePrefix = 'Appsource_Header';
    export namespace Classname {
      export const itemActive = 'active';
      export const itemNotActive = 'inactive';
    }
  }

  export enum UserInteractionType {
    Touch = 'Touch',
    Mouse = 'Mouse',
  }

  export enum EventType {
    None = 'none',
    Click = 'click',
    MouseEnter = 'mouseenter',
    ReactMouseEnter = 'react-mouseenter',
    MouseLeave = 'mouseleave',
    ReactMouseLeave = 'react-mouseleave',
    KeyDown = 'keydown',
    KeyUp = 'keyup',
    Blur = 'blur',
  }

  export enum UserActionComponentType {
    Dialog = 'Dialog',
    DropdownOptionItem = 'DropdownOptionItem',
  }

  export namespace UserComponent {
    export enum DropdownItemName {
      userAction = 'userAction',
      userFavourite = 'userFavourite',
      feedback = 'feeback',
    }

    export const dropdownItemNameToClassName = {
      [DropdownItemName.userAction]: 'userActions .userTabSignOut button',
      [DropdownItemName.userFavourite]: 'spza-user-favourite-navigation-bar-button-container',
      [DropdownItemName.feedback]: 'spza_feedback',
    };

    export const dropdownItemClassNameArray: string[] = Object.keys(dropdownItemNameToClassName).map(
      (key) => dropdownItemNameToClassName[`${key}`]
    );

    export const activeClassName = 'dropdown-item-active';
    export const dropdownOptionContainerClassName = 'spza-user-mobile-nav-bar-dropdown';
    export const dropdownActiveOptionContainerClassName = `${dropdownOptionContainerClassName}.active`;
    export const dropdownValueClassName = 'spza-user-mobile-container .spza-user-nav-bar-button';
    export const dropdownContainerClassName = 'spza-user-mobile-container';
  }

  // Microsoft Azure Marketplace Terms
  export const MACPrivacyTermsURL = 'https://aka.ms/azuremarketplaceterms';

  // Hyperfish is being populated form CPP. Hence, the office award values are not populated
  // So, we need to hardcode the office award for this particular app.
  export const HyperfishEntityId = 'hyperfish.8437f635-56d2-402b-bb8b-2b5549267c61';

  export const IgnoredPricingEntityId = 'dummy';

  export enum ErrorCodes {
    XSS = 1,
  }

  export const ServicesIdWithBadge = {
    'bardess.customer-360-powered-by-zero2hero': 1,
    'publicis-sapient.rapid_commerce': 2,
    'xentit.azure-xentit-trendmicro-qualys-hcss-12months': 3,
    'rcg-us.rcg-enable-credit-analytics': 4,
    'sirrus7.sirrus7-devops-acceleration-engine': 5,
    'new-signature.eos_appfactory': 6,
    'prodware.devicetone_iot_for_connected_field_service': 7,
    'avanadeinc.intelligent_ma_due_diligence': 8,
    'rivermeadowsoftwareinc.rapid-datacenter-consolidation-jumpstart': 9,
    'barhead_solutions.unison_p2p': 10,
  };

  export const appSourceApplicationTargetName = 'appsource';

  export namespace JsllCTAId {
    export const Contact = 'CTA_Contact';
    export const ContactMe = 'CTA_ContactMe';
    export const Select = 'CTA_Select';
    export const Get = 'CTA_Get';
    export const Try = 'CTA_Try';
    export const RequestTrial = 'CTA_ContactMe';
    export const TestDrive = 'CTA_TestDrive';
    export const Service = 'CTA_Inquire';
    export const Purchase = 'CTA_Get';
    export const MailALink = 'CTA_MailMeALink';
    export const Configure = 'CTA_Configure';
    export const ContactPartner = 'CTA_ContactPartner';
    export const RepeatTestDrive = 'CTA_RepeatTestDrive';
    export const RetryTestDrive = 'CTA_RetryTestDrive';
    export const Continue = 'CTA_Continue';
    export const Close = 'CTA_Close';
    export const Done = 'CTA_Done';
    export const SubmitFeedback = 'CTA_SubmitFeedback';
    export const SendFeedbackEmail = 'CTA_SendFeedbackEmail';
    export const WriteReview = 'CTA_WriteReview';
    export const UpdateReview = 'CTA_UpdateReview';
    export const SubmitReview = 'CTA_SubmitReview';
    export const DeleteReview = 'CTA_DeleteReview';
    export const SignIn = 'CTA_SignIn';
    export const SignUp = 'CTA_SignUp';
    export const ViewAllButton = 'CTA_ViewAllButton';
    export const ClearFilters = 'CTA_ClearFilters';
    export const DownloadSample = 'CTA_DownloadSample';
    export const Add = 'CTA_Add';
    export const BuyLicense = 'CTA_BuyLicense';
    export const Install = 'CTA_Install';
    export const AlreadyPurchased = 'CTA_AlreadyPurchased';
    export const Partner = 'CTA_Partner';
  }

  export namespace JsllSaas {
    export const OrderConfirmed = 'OrderConfirmed';
    export const TransactableSaasPdpView = 'TransactableSaasPdpView';
    export const SaasTransactable = 'SaaS transactable';
    export const SaasNonTransactable = 'SaaS non-transactable';
    export const ViewImage = 'ViewImage';
    export const ViewImageOfVideo = 'ViewImageOfVideo';
    export const WatchVideo = 'WatchVideo';
    export const SignIn = 'SignIn';
    export const SignInModalShow = 'SignInModalShow';
  }

  export namespace JsllCheckout {
    export const RedirectButton = 'RedirectButton';
    export const NewCustomer = 'NewCustomer';
  }

  export enum AzureExpertsMSPApps {
    'ans_group.glasssaas',
    'capside.enzime-azure-code-deployments-capside',
    'capside.replica-azure-cloud-governance-capside',
    'elasticbox.elasticbox-enterprise',
    'comparex.portfolio-management-platform',
    'comparex.sam2go-nextgen-platform',
    'comparex.ucm-insights',
    'coretek.coretekams',
    'crayon-group-as-1129016.cloud_iq_saas',
    'hanu.hanu-insight-v2',
    'hanu.hanu-managed-services',
    'hcl-technologies.hcl17cp1104',
    'ibm.ibm_security_access_manager',
    'ibm.qradar_security_analytics',
    'infosysltd.infosys-analytics-workbench',
    'infront-consulting-group-ltd.beekeeper-patch_maangement',
    'infront-consulting-group-ltd.hive-self-service-portal',
    'linkbynet.linkbynet_1',
    'new-signature.ef2bfc07-9398-483d-b46f-78bccae4cb42',
    'wipro_india.botwiproapi',
    'wipro_india.facewiproapi',
    'wipro_india.imageclassificationwiproapi',
    'wipro_india.wca',
    'wipro_india.wiprotexact',
  }

  export enum ProductBitmaskFieldNames {
    backendKey = 'backendKey',
    urlKey = 'urlKey',
  }

  export enum SearchFieldNames {
    linkedAddIns = 'linkedAddIns',
    linkedAddInsTypes = 'LinkedAddInsTypes',
  }

  export enum SearchSortingOption {
    BestMatch = 'Best Match',
    Popularity = 'Popularity',
    RatingAverage = 'Rating Average',
    RecentlyUpdated = 'Recently Updated',
  }

  export enum LicenseManagementType {
    MicrosoftManaged = 'MicrosoftManaged',
    ISVManaged = 'ISVManaged',
  }

  export enum LicenseModel {
    Freemium = 'Freemium',
  }

  export enum UiRole {
    Admin = 1,
    Other = 2,
  }

  export enum TermPeriod {
    Month = 'month',
    Year = 'year',
  }

  export enum TermId {
    // Entitlement renew monthly
    Monthly = 'hjdtn7tfnxcy',
    // Entitlement renew yearly
    Yearly = 'nm5o4wf9fbfy',
    // Entitlement first month free then renew monthly
    TrialMonthly = 'ccdjsqgba7zo',
    // Entitlement first month free then renew yearly
    TrialYearly = 'o6p5ue5yng9o',
  }

  export type SaaSTermId = TermId.Monthly | TermId.Yearly | TermId.TrialMonthly | TermId.TrialYearly;
}

export enum OfferType {
  'Other',
  'VirtualMachine',
  'DevService',
  'SolutionTemplate',
  'ManagedApp',
  'SaaSApp',
  'Container',
  'ContainerApp',
  'CrossChargedManagedApp',
  'AAD',
  'IoTEdgeModule',
  'PowerBICustomVisual',
  // 'Mixed' should be the last value and is special
  // It implies multiple offer types
  // If we ever used this as a flag (bitwise operation),
  // remember that 'Mixed' is a special case
  // Add new offer types above
  'Mixed' = 2147483647,
}

export enum PlanPricingType {
  None = 1,
  VM,
  SimplePlanPricing,
  SolutionTemplate,
}

export namespace Plan {
  export const displayRank = 'displayRank';
}

export const supportedCountries: SupportedCountry[] = [
  {
    countryCode: 'AF',
    name: 'Afghanistan',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Unknown,
  },
  {
    countryCode: 'AL',
    name: 'Albania',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'DZ',
    name: 'Algeria',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptOut,
  },
  {
    countryCode: 'AD',
    name: 'Andorra',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.Unknown,
  },
  {
    countryCode: 'AO',
    name: 'Angola',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'AR',
    name: 'Argentina',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'AM',
    name: 'Armenia',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'AU',
    name: 'Australia',
    currencyCode: 'AUD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'AT',
    name: 'Austria',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'AZ',
    name: 'Azerbaijan',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'BH',
    name: 'Bahrain',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Notice,
  },
  {
    countryCode: 'BD',
    name: 'Bangladesh',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Unknown,
  },
  {
    countryCode: 'BB',
    name: 'Barbados',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Unknown,
  },
  {
    countryCode: 'BY',
    name: 'Belarus',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'BE',
    name: 'Belgium',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'BZ',
    name: 'Belize',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Unknown,
  },
  {
    countryCode: 'BM',
    name: 'Bermuda',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Unknown,
  },
  {
    countryCode: 'BO',
    name: 'Bolivia',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Notice,
  },
  {
    countryCode: 'BA',
    name: 'Bosnia and Herzegovina',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'BW',
    name: 'Botswana',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Notice,
  },
  {
    countryCode: 'BR',
    name: 'Brazil',
    currencyCode: 'BRL',
    consentRequired: IConsentOption.Notice,
  },
  {
    countryCode: 'BN',
    name: 'Brunei',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Unknown,
  },
  {
    countryCode: 'BG',
    name: 'Bulgaria',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.Notice,
  },
  {
    countryCode: 'CM',
    name: 'Cameroon',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Notice,
  },
  {
    countryCode: 'CA',
    name: 'Canada',
    currencyCode: 'CAD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'CV',
    name: 'Cabo Verde',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'KY',
    name: 'Cayman Islands',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Unknown,
  },
  {
    countryCode: 'CL',
    name: 'Chile',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptOut,
  },
  {
    countryCode: 'CO',
    name: 'Colombia',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'CR',
    name: 'Costa Rica',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'CI',
    name: 'Côte d"Ivoire',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Notice,
  },
  {
    countryCode: 'HR',
    name: 'Croatia',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'CW',
    name: 'Curaçao',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Unknown,
  },
  {
    countryCode: 'CY',
    name: 'Cyprus',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'CZ',
    name: 'Czech Republic',
    currencyCode: 'CZK',
    consentRequired: IConsentOption.OptOut,
  },
  {
    countryCode: 'DK',
    name: 'Denmark',
    currencyCode: 'DKK',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'DO',
    name: 'Dominican Republic',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Notice,
  },
  {
    countryCode: 'EC',
    name: 'Ecuador',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Notice,
  },
  {
    countryCode: 'EG',
    name: 'Egypt',
    currencyCode: 'EGP',
    consentRequired: IConsentOption.OptOut,
  },
  {
    countryCode: 'SV',
    name: 'El Salvador',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Notice,
  },
  {
    countryCode: 'EE',
    name: 'Estonia',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'ET',
    name: 'Ethiopia',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptOut,
  },
  {
    countryCode: 'FO',
    name: 'Faroe Islands',
    currencyCode: 'DKK',
    consentRequired: IConsentOption.Unknown,
  },
  {
    countryCode: 'FJ',
    name: 'Fiji',
    currencyCode: 'AUD',
    consentRequired: IConsentOption.Unknown,
  },
  {
    countryCode: 'FI',
    name: 'Finland',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'FR',
    name: 'France',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'GE',
    name: 'Georgia',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'DE',
    name: 'Germany',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'GH',
    name: 'Ghana',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'GR',
    name: 'Greece',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'GT',
    name: 'Guatemala',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptOut,
  },
  {
    countryCode: 'HN',
    name: 'Honduras',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Notice,
  },
  {
    countryCode: 'HK',
    name: 'Hong Kong SAR',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptOut,
  },
  {
    countryCode: 'HU',
    name: 'Hungary',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'IS',
    name: 'Iceland',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'IN',
    name: 'India',
    currencyCode: 'INR',
    consentRequired: IConsentOption.Notice,
  },
  {
    countryCode: 'ID',
    name: 'Indonesia',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptOut,
  },
  {
    countryCode: 'IQ',
    name: 'Iraq',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptOut,
  },
  {
    countryCode: 'IE',
    name: 'Ireland',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'IL',
    name: 'Israel',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'IT',
    name: 'Italy',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'JM',
    name: 'Jamaica',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Notice,
  },
  {
    countryCode: 'JP',
    name: 'Japan',
    currencyCode: 'JPY',
    consentRequired: IConsentOption.OptOut,
  },
  {
    countryCode: 'JO',
    name: 'Jordan',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptOut,
  },
  {
    countryCode: 'KZ',
    name: 'Kazakhstan',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'KE',
    name: 'Kenya',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'KR',
    name: 'Korea (South)',
    currencyCode: 'KRW',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'KW',
    name: 'Kuwait',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'KG',
    name: 'Kyrgyzstan',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Exclude,
  },
  {
    countryCode: 'LV',
    name: 'Latvia',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'LB',
    name: 'Lebanon',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptOut,
  },
  {
    countryCode: 'LY',
    name: 'Libya',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Notice,
  },
  {
    countryCode: 'LI',
    name: 'Liechtenstein',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'LT',
    name: 'Lithuania',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'LU',
    name: 'Luxembourg',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'MO',
    name: 'Macao SAR',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Notice,
  },
  {
    countryCode: 'MK',
    name: 'North Macedonia',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptOut,
  },
  {
    countryCode: 'MY',
    name: 'Malaysia',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'MT',
    name: 'Malta',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'MU',
    name: 'Mauritius',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'MX',
    name: 'Mexico',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptOut,
  },
  {
    countryCode: 'MD',
    name: 'Moldova',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Unknown,
  },
  {
    countryCode: 'MC',
    name: 'Monaco',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'MN',
    name: 'Mongolia',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'ME',
    name: 'Montenegro',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'MA',
    name: 'Morocco',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'NA',
    name: 'Namibia',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptOut,
  },
  {
    countryCode: 'NP',
    name: 'Nepal',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Unknown,
  },
  {
    countryCode: 'NL',
    name: 'Netherlands',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'NI',
    name: 'Nicaragua',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Unknown,
  },
  {
    countryCode: 'NZ',
    name: 'New Zealand',
    currencyCode: 'NZD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'NG',
    name: 'Nigeria',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptOut,
  },
  {
    countryCode: 'NO',
    name: 'Norway',
    currencyCode: 'NOK',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'OM',
    name: 'Oman',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Notice,
  },
  {
    countryCode: 'PK',
    name: 'Pakistan',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'PS',
    name: 'Palestinian Authority',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Unknown,
  },
  {
    countryCode: 'PA',
    name: 'Panama',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptOut,
  },
  {
    countryCode: 'PY',
    name: 'Paraguay',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Notice,
  },
  {
    countryCode: 'PE',
    name: 'Peru',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'PH',
    name: 'Philippines',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'PL',
    name: 'Poland',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'PT',
    name: 'Portugal',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'PR',
    name: 'Puerto Rico',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Unknown,
  },
  {
    countryCode: 'QA',
    name: 'Qatar',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Notice,
  },
  {
    countryCode: 'RO',
    name: 'Romania',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'RU',
    name: 'Russia',
    currencyCode: 'RUB',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'RW',
    name: 'Rwanda',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Notice,
  },
  {
    countryCode: 'KN',
    name: 'Saint Kitts and Nevis',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Unknown,
  },
  {
    countryCode: 'SA',
    name: 'Saudi Arabia',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'SN',
    name: 'Senegal',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptOut,
  },
  {
    countryCode: 'RS',
    name: 'Serbia',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'SG',
    name: 'Singapore',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'SK',
    name: 'Slovakia',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'SI',
    name: 'Slovenia',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'ZA',
    name: 'South Africa',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptOut,
  },
  {
    countryCode: 'ES',
    name: 'Spain',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'LK',
    name: 'Sri Lanka',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Unknown,
  },
  {
    countryCode: 'SE',
    name: 'Sweden',
    currencyCode: 'SEK',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'CH',
    name: 'Switzerland',
    currencyCode: 'CHF',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'TW',
    name: 'Taiwan',
    currencyCode: 'TWD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'TJ',
    name: 'Tajikistan',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'TZ',
    name: 'Tanzania',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Notice,
  },
  {
    countryCode: 'TH',
    name: 'Thailand',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptOut,
  },
  {
    countryCode: 'TT',
    name: 'Trinidad and Tobago',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Notice,
  },
  {
    countryCode: 'TN',
    name: 'Tunisia',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'TR',
    name: 'Turkey',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'TM',
    name: 'Turkmenistan',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'VA',
    name: 'Vatican City (Holy See)',
    currencyCode: 'EUR',
    consentRequired: IConsentOption.Unknown,
  },
  {
    countryCode: 'VI',
    name: 'Virgin Islands of the United States',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Unknown,
  },
  {
    countryCode: 'UG',
    name: 'Uganda',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Notice,
  },
  {
    countryCode: 'UA',
    name: 'Ukraine',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'AE',
    name: 'United Arab Emirates',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'GB',
    name: 'United Kingdom',
    currencyCode: 'GB',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'US',
    name: 'United States',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Notice,
  },
  {
    countryCode: 'UY',
    name: 'Uruguay',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'UZ',
    name: 'Uzbekistan',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'VE',
    name: 'Venezuela',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'VN',
    name: 'Vietnam',
    currencyCode: 'USD',
    consentRequired: IConsentOption.OptIn,
  },
  {
    countryCode: 'YE',
    name: 'Yemen',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Notice,
  },
  {
    countryCode: 'ZM',
    name: 'Zambia',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Notice,
  },
  {
    countryCode: 'ZW',
    name: 'Zimbabwe',
    currencyCode: 'USD',
    consentRequired: IConsentOption.Notice,
  },
];

export enum Environment {
  Development = 'development',
  Dogfood = 'df',
  Canary = 'canary',
  Test = 'test',
  Production = 'prod',
}
export const UNAUTHENTICATED_ROUTES = {
  '/api/log': false,
  '/api/logOutRequests': false,
  '/view': false,
  '/api': false,
  '/api/userreview': true,
};

export enum OneDSEnvs {
  Production = 'production',
  Canary = 'preprod',
  Dogfood = 'ppe',
  Internal = 'dev',
  Test = 'test',
}

export const LocalizationSettings = {
  defaultLng: 'en-US',
  defaultNS: 'header',
};

export const ResourcesFiles = {
  resources: 'resources',
  resourcesMac: 'resourcesMac',
  resourcesAppSource: 'resourcesAppSource',
  header: 'header',
  search: 'search',
};

export enum AwaMetaTags {
  correlationId = 'awa-correlationId',
  locale = 'awa-locale',
  signedIn = 'awa-signedIn',
  version = 'awa-version',
  region = 'awa-region',
}

export enum LocaleStringStyles {
  Currency = 'currency',
}

export interface IBillingCountries {
  name: string;
  currency: string;
  countryCode: string;
  transactable?: boolean;
}

export interface UrlParamCacheKeyBlackList {
  [param: string]: true;
}

export const allBillingCountries: IBillingCountries[] = [
  {
    name: 'United States',
    transactable: true,
    countryCode: 'US',
    currency: 'USD',
  },
  {
    name: 'Afghanistan',
    transactable: true,
    countryCode: 'AF',
    currency: 'USD',
  },
  {
    name: 'Åland Islands',
    currency: 'USD',
    countryCode: 'AX',
  },
  {
    name: 'Albania',
    transactable: true,
    countryCode: 'AL',
    currency: 'USD',
  },
  {
    name: 'Algeria',
    transactable: true,
    countryCode: 'DZ',
    currency: 'USD',
  },
  {
    name: 'American Samoa',
    countryCode: 'AS',
    currency: 'USD',
  },
  {
    name: 'Andorra',
    transactable: true,
    countryCode: 'AD',
    currency: 'EUR',
  },
  {
    name: 'Angola',
    transactable: true,
    countryCode: 'AO',
    currency: 'USD',
  },
  {
    name: 'Anguilla',
    countryCode: 'AI',
    currency: 'USD',
  },
  {
    name: 'Antarctica',
    countryCode: 'AQ',
    currency: 'USD',
  },
  {
    name: 'Antigua and Barbuda',
    countryCode: 'AG',
    currency: 'USD',
  },
  {
    name: 'Argentina',
    transactable: true,
    countryCode: 'AR',
    currency: 'USD',
  },
  {
    name: 'Armenia',
    transactable: true,
    countryCode: 'AM',
    currency: 'USD',
  },
  {
    name: 'Aruba',
    countryCode: 'AW',
    currency: 'USD',
  },
  {
    name: 'Australia',
    transactable: true,
    countryCode: 'AU',
    currency: 'AUD',
  },
  {
    name: 'Austria',
    transactable: true,
    countryCode: 'AT',
    currency: 'EUR',
  },
  {
    name: 'Azerbaijan',
    transactable: true,
    countryCode: 'AZ',
    currency: 'USD',
  },
  {
    name: 'Bahamas',
    countryCode: 'BS',
    currency: 'USD',
  },
  {
    name: 'Bahrain',
    transactable: true,
    countryCode: 'BH',
    currency: 'USD',
  },
  {
    name: 'Bangladesh',
    transactable: true,
    countryCode: 'BD',
    currency: 'USD',
  },
  {
    name: 'Barbados',
    transactable: true,
    countryCode: 'BB',
    currency: 'USD',
  },
  {
    name: 'Belarus',
    transactable: true,
    countryCode: 'BY',
    currency: 'USD',
  },
  {
    name: 'Belgium',
    transactable: true,
    countryCode: 'BE',
    currency: 'EUR',
  },
  {
    name: 'Belize',
    transactable: true,
    countryCode: 'BZ',
    currency: 'USD',
  },
  {
    name: 'Benin',
    countryCode: 'BJ',
    currency: 'USD',
  },
  {
    name: 'Bermuda',
    transactable: true,
    countryCode: 'BM',
    currency: 'USD',
  },
  {
    name: 'Bhutan',
    countryCode: 'BT',
    currency: 'USD',
  },
  {
    name: 'Bolivia',
    transactable: true,
    countryCode: 'BO',
    currency: 'USD',
  },
  {
    name: 'Bonaire, Saint Eustatius and Saba',
    countryCode: 'BQ',
    currency: 'USD',
  },
  {
    name: 'Bosnia and Herzegovina',
    transactable: true,
    countryCode: 'BA',
    currency: 'USD',
  },
  {
    name: 'Botswana',
    transactable: true,
    countryCode: 'BW',
    currency: 'USD',
  },
  {
    name: 'Bouvet Island',
    countryCode: 'BV',
    currency: 'USD',
  },
  {
    name: 'Brazil',
    transactable: true,
    countryCode: 'BR',
    currency: 'BRL',
  },
  {
    name: 'British Indian Ocean Territory',
    countryCode: 'IO',
    currency: 'USD',
  },
  {
    name: 'British Virgin Islands',
    countryCode: 'VG',
    currency: 'USD',
  },
  {
    name: 'Brunei',
    transactable: true,
    countryCode: 'BN',
    currency: 'USD',
  },
  {
    name: 'Bulgaria',
    transactable: true,
    countryCode: 'BG',
    currency: 'EUR',
  },
  {
    name: 'Burkina Faso',
    countryCode: 'BF',
    currency: 'USD',
  },
  {
    name: 'Burundi',
    countryCode: 'BI',
    currency: 'USD',
  },
  {
    name: 'Cabo Verde',
    transactable: true,
    countryCode: 'CV',
    currency: 'USD',
  },
  {
    name: 'Cambodia',
    countryCode: 'KH',
    currency: 'KHR',
  },
  {
    name: 'Cameroon',
    transactable: true,
    countryCode: 'CM',
    currency: 'USD',
  },
  {
    name: 'Canada',
    transactable: true,
    countryCode: 'CA',
    currency: 'CAD',
  },
  {
    name: 'Cayman Islands',
    transactable: true,
    countryCode: 'KY',
    currency: 'USD',
  },
  {
    name: 'Central African Republic',
    countryCode: 'CF',
    currency: 'USD',
  },
  {
    name: 'Chad',
    countryCode: 'TD',
    currency: 'USD',
  },
  {
    name: 'Chile',
    transactable: true,
    countryCode: 'CL',
    currency: 'USD',
  },
  {
    name: 'China',
    countryCode: 'CN',
    currency: 'CNY',
  },
  {
    name: 'Christmas Island',
    countryCode: 'CX',
    currency: 'USD',
  },
  {
    name: 'Cocos (Keeling) Islands',
    countryCode: 'CC',
    currency: 'USD',
  },
  {
    name: 'Colombia',
    transactable: true,
    countryCode: 'CO',
    currency: 'USD',
  },
  {
    name: 'Comoros',
    countryCode: 'KM',
    currency: 'USD',
  },
  {
    name: 'Congo',
    countryCode: 'CG',
    currency: 'USD',
  },
  {
    name: 'Congo (DRC)',
    countryCode: 'CD',
    currency: 'USD',
  },
  {
    name: 'Cook Islands',
    countryCode: 'CK',
    currency: 'USD',
  },
  {
    name: 'Costa Rica',
    transactable: true,
    countryCode: 'CR',
    currency: 'USD',
  },
  {
    name: "Côte d'Ivoire",
    transactable: true,
    countryCode: 'CI',
    currency: 'USD',
  },
  {
    name: 'Croatia',
    transactable: true,
    countryCode: 'HR',
    currency: 'EUR',
  },
  {
    name: 'Curaçao',
    transactable: true,
    countryCode: 'CW',
    currency: 'USD',
  },
  {
    name: 'Cyprus',
    transactable: true,
    countryCode: 'CY',
    currency: 'EUR',
  },
  {
    name: 'Czechia',
    transactable: true,
    countryCode: 'CZ',
    currency: 'EUR',
  },
  {
    name: 'Denmark',
    transactable: true,
    countryCode: 'DK',
    currency: 'DKK',
  },
  {
    name: 'Djibouti',
    countryCode: 'DJ',
    currency: 'USD',
  },
  {
    name: 'Dominica',
    countryCode: 'DM',
    currency: 'USD',
  },
  {
    name: 'Dominican Republic',
    transactable: true,
    countryCode: 'DO',
    currency: 'USD',
  },
  {
    name: 'Ecuador',
    transactable: true,
    countryCode: 'EC',
    currency: 'USD',
  },
  {
    name: 'Egypt',
    transactable: true,
    countryCode: 'EG',
    currency: 'USD',
  },
  {
    name: 'El Salvador',
    transactable: true,
    countryCode: 'SV',
    currency: 'USD',
  },
  {
    name: 'Equatorial Guinea',
    countryCode: 'GQ',
    currency: 'USD',
  },
  {
    name: 'Eritrea',
    countryCode: 'ER',
    currency: 'USD',
  },
  {
    name: 'Estonia',
    transactable: true,
    countryCode: 'EE',
    currency: 'EUR',
  },
  {
    name: 'Ethiopia',
    transactable: true,
    countryCode: 'ET',
    currency: 'USD',
  },
  {
    name: 'Falkland Islands',
    countryCode: 'FK',
    currency: 'USD',
  },
  {
    name: 'Faroe Islands',
    transactable: true,
    countryCode: 'FO',
    currency: 'DKK',
  },
  {
    name: 'Fiji',
    transactable: true,
    countryCode: 'FJ',
    currency: 'AUD',
  },
  {
    name: 'Finland',
    transactable: true,
    countryCode: 'FI',
    currency: 'EUR',
  },
  {
    name: 'France',
    transactable: true,
    countryCode: 'FR',
    currency: 'EUR',
  },
  {
    name: 'French Guiana',
    countryCode: 'GF',
    currency: 'USD',
  },
  {
    name: 'French Polynesia',
    countryCode: 'PF',
    currency: 'USD',
  },
  {
    name: 'French Southern Territories',
    countryCode: 'TF',
    currency: 'USD',
  },
  {
    name: 'Gabon',
    countryCode: 'GA',
    currency: 'USD',
  },
  {
    name: 'Gambia',
    countryCode: 'GM',
    currency: 'USD',
  },
  {
    name: 'Georgia',
    transactable: true,
    countryCode: 'GE',
    currency: 'USD',
  },
  {
    name: 'Germany',
    transactable: true,
    countryCode: 'DE',
    currency: 'EUR',
  },
  {
    name: 'Ghana',
    transactable: true,
    countryCode: 'GH',
    currency: 'USD',
  },
  {
    name: 'Gibraltar',
    countryCode: 'GI',
    currency: 'EUR',
  },
  {
    name: 'Greece',
    transactable: true,
    countryCode: 'GR',
    currency: 'EUR',
  },
  {
    name: 'Greenland',
    countryCode: 'GL',
    currency: 'USD',
  },
  {
    name: 'Grenada',
    countryCode: 'GD',
    currency: 'USD',
  },
  {
    name: 'Guadeloupe',
    countryCode: 'GP',
    currency: 'USD',
  },
  {
    name: 'Guam',
    countryCode: 'GU',
    currency: 'USD',
  },
  {
    name: 'Guatemala',
    transactable: true,
    countryCode: 'GT',
    currency: 'USD',
  },
  {
    name: 'Guernsey',
    countryCode: 'GG',
    currency: 'EUR',
  },
  {
    name: 'Guinea',
    countryCode: 'GN',
    currency: 'USD',
  },
  {
    name: 'Guinea-Bissau',
    countryCode: 'GW',
    currency: 'USD',
  },
  {
    name: 'Guyana',
    countryCode: 'GY',
    currency: 'USD',
  },
  {
    name: 'Haiti',
    countryCode: 'HT',
    currency: 'USD',
  },
  {
    name: 'Heard Island and McDonald Islands',
    countryCode: 'HM',
    currency: 'USD',
  },
  {
    name: 'Honduras',
    transactable: true,
    countryCode: 'HN',
    currency: 'USD',
  },
  {
    name: 'Hong Kong SAR',
    transactable: true,
    countryCode: 'HK',
    currency: 'USD',
  },
  {
    name: 'Hungary',
    transactable: true,
    countryCode: 'HU',
    currency: 'EUR',
  },
  {
    name: 'Iceland',
    transactable: true,
    countryCode: 'IS',
    currency: 'EUR',
  },
  {
    name: 'India',
    transactable: true,
    countryCode: 'IN',
    currency: 'INR',
  },
  {
    name: 'Indonesia',
    transactable: true,
    countryCode: 'ID',
    currency: 'USD',
  },
  {
    name: 'Iraq',
    transactable: true,
    countryCode: 'IQ',
    currency: 'USD',
  },
  {
    name: 'Ireland',
    transactable: true,
    countryCode: 'IE',
    currency: 'EUR',
  },
  {
    name: 'Isle of Man',
    countryCode: 'IM',
    currency: 'USD',
  },
  {
    name: 'Israel',
    transactable: true,
    countryCode: 'IL',
    currency: 'USD',
  },
  {
    name: 'Italy',
    transactable: true,
    countryCode: 'IT',
    currency: 'EUR',
  },
  {
    name: 'Jamaica',
    transactable: true,
    countryCode: 'JM',
    currency: 'USD',
  },
  {
    name: 'Japan',
    transactable: true,
    countryCode: 'JP',
    currency: 'JPY',
  },
  {
    name: 'Jersey',
    countryCode: 'JE',
    currency: 'EUR',
  },
  {
    name: 'Jordan',
    transactable: true,
    countryCode: 'JO',
    currency: 'USD',
  },
  {
    name: 'Kazakhstan',
    transactable: true,
    countryCode: 'KZ',
    currency: 'USD',
  },
  {
    name: 'Kenya',
    transactable: true,
    countryCode: 'KE',
    currency: 'USD',
  },
  {
    name: 'Kiribati',
    countryCode: 'KI',
    currency: 'USD',
  },
  {
    name: 'Korea (South)',
    transactable: true,
    countryCode: 'KR',
    currency: 'KRW',
  },
  {
    name: 'Kosovo',
    countryCode: 'XK',
    currency: 'USD',
  },
  {
    name: 'Kuwait',
    transactable: true,
    countryCode: 'KW',
    currency: 'USD',
  },
  {
    name: 'Kyrgyzstan',
    transactable: true,
    countryCode: 'KG',
    currency: 'USD',
  },
  {
    name: 'Laos',
    countryCode: 'LA',
    currency: 'USD',
  },
  {
    name: 'Latvia',
    transactable: true,
    countryCode: 'LV',
    currency: 'EUR',
  },
  {
    name: 'Lebanon',
    transactable: true,
    countryCode: 'LB',
    currency: 'USD',
  },
  {
    name: 'Lesotho',
    countryCode: 'LS',
    currency: 'USD',
  },
  {
    name: 'Liberia',
    countryCode: 'LR',
    currency: 'USD',
  },
  {
    name: 'Libya',
    transactable: true,
    countryCode: 'LY',
    currency: 'USD',
  },
  {
    name: 'Liechtenstein',
    transactable: true,
    countryCode: 'LI',
    currency: 'EUR',
  },
  {
    name: 'Lithuania',
    transactable: true,
    countryCode: 'LT',
    currency: 'EUR',
  },
  {
    name: 'Luxembourg',
    transactable: true,
    countryCode: 'LU',
    currency: 'EUR',
  },
  {
    name: 'Macao SAR',
    transactable: true,
    countryCode: 'MO',
    currency: 'USD',
  },
  {
    name: 'Madagascar',
    countryCode: 'MG',
    currency: 'USD',
  },
  {
    name: 'Malawi',
    countryCode: 'MW',
    currency: 'USD',
  },
  {
    name: 'Malaysia',
    transactable: true,
    countryCode: 'MY',
    currency: 'USD',
  },
  {
    name: 'Maldives',
    countryCode: 'MV',
    currency: 'USD',
  },
  {
    name: 'Mali',
    countryCode: 'ML',
    currency: 'USD',
  },
  {
    name: 'Malta',
    transactable: true,
    countryCode: 'MT',
    currency: 'EUR',
  },
  {
    name: 'Marshall Islands',
    countryCode: 'MH',
    currency: 'USD',
  },
  {
    name: 'Martinique',
    countryCode: 'MQ',
    currency: 'EUR',
  },
  {
    name: 'Mauritania',
    countryCode: 'MR',
    currency: 'MRO',
  },
  {
    name: 'Mauritius',
    transactable: true,
    countryCode: 'MU',
    currency: 'USD',
  },
  {
    name: 'Mayotte',
    countryCode: 'YT',
    currency: 'EUR',
  },
  {
    name: 'Mexico',
    transactable: true,
    countryCode: 'MX',
    currency: 'USD',
  },
  {
    name: 'Micronesia',
    countryCode: 'FM',
    currency: 'USD',
  },
  {
    name: 'Moldova',
    transactable: true,
    countryCode: 'MD',
    currency: 'USD',
  },
  {
    name: 'Monaco',
    transactable: true,
    countryCode: 'MC',
    currency: 'EUR',
  },
  {
    name: 'Mongolia',
    transactable: true,
    countryCode: 'MN',
    currency: 'USD',
  },
  {
    name: 'Montenegro',
    transactable: true,
    countryCode: 'ME',
    currency: 'USD',
  },
  {
    name: 'Montserrat',
    countryCode: 'MS',
    currency: 'USD',
  },
  {
    name: 'Morocco',
    transactable: true,
    countryCode: 'MA',
    currency: 'USD',
  },
  {
    name: 'Mozambique',
    countryCode: 'MZ',
    currency: 'USD',
  },
  {
    name: 'Myanmar',
    countryCode: 'MM',
    currency: 'USD',
  },
  {
    name: 'Namibia',
    transactable: true,
    countryCode: 'NA',
    currency: 'USD',
  },
  {
    name: 'Nauru',
    countryCode: 'NR',
    currency: 'USD',
  },
  {
    name: 'Nepal',
    transactable: true,
    countryCode: 'NP',
    currency: 'USD',
  },
  {
    name: 'Netherlands',
    transactable: true,
    countryCode: 'NL',
    currency: 'EUR',
  },
  {
    name: 'New Caledonia',
    countryCode: 'NC',
    currency: 'USD',
  },
  {
    name: 'New Zealand',
    transactable: true,
    countryCode: 'NZ',
    currency: 'NZD',
  },
  {
    name: 'Nicaragua',
    transactable: true,
    countryCode: 'NI',
    currency: 'USD',
  },
  {
    name: 'Niger',
    countryCode: 'NE',
    currency: 'USD',
  },
  {
    name: 'Nigeria',
    transactable: true,
    countryCode: 'NG',
    currency: 'USD',
  },
  {
    name: 'Niue',
    countryCode: 'NU',
    currency: 'USD',
  },
  {
    name: 'Norfolk Island',
    countryCode: 'NF',
    currency: 'USD',
  },
  {
    name: 'North Macedonia',
    transactable: true,
    countryCode: 'MK',
    currency: 'USD',
  },
  {
    name: 'Northern Mariana Islands',
    countryCode: 'MP',
    currency: 'USD',
  },
  {
    name: 'Norway',
    transactable: true,
    countryCode: 'NO',
    currency: 'NOK',
  },
  {
    name: 'Oman',
    transactable: true,
    countryCode: 'OM',
    currency: 'USD',
  },
  {
    name: 'Pakistan',
    transactable: true,
    countryCode: 'PK',
    currency: 'USD',
  },
  {
    name: 'Palau',
    countryCode: 'PW',
    currency: 'USD',
  },
  {
    name: 'Palestinian Authority',
    transactable: true,
    countryCode: 'PS',
    currency: 'USD',
  },
  {
    name: 'Panama',
    transactable: true,
    countryCode: 'PA',
    currency: 'USD',
  },
  {
    name: 'Papua New Guinea',
    countryCode: 'PG',
    currency: 'USD',
  },
  {
    name: 'Paraguay',
    transactable: true,
    countryCode: 'PY',
    currency: 'USD',
  },
  {
    name: 'Peru',
    transactable: true,
    countryCode: 'PE',
    currency: 'USD',
  },
  {
    name: 'Philippines',
    transactable: true,
    countryCode: 'PH',
    currency: 'USD',
  },
  {
    name: 'Pitcairn Islands',
    countryCode: 'PN',
    currency: 'USD',
  },
  {
    name: 'Poland',
    transactable: true,
    countryCode: 'PL',
    currency: 'EUR',
  },
  {
    name: 'Portugal',
    transactable: true,
    countryCode: 'PT',
    currency: 'EUR',
  },
  {
    name: 'Puerto Rico',
    transactable: true,
    countryCode: 'PR',
    currency: 'USD',
  },
  {
    name: 'Qatar',
    transactable: true,
    countryCode: 'QA',
    currency: 'USD',
  },
  {
    name: 'Réunion',
    countryCode: 'RE',
    currency: 'EUR',
  },
  {
    name: 'Romania',
    transactable: true,
    countryCode: 'RO',
    currency: 'EUR',
  },
  {
    name: 'Russia',
    transactable: true,
    countryCode: 'RU',
    currency: 'RUB',
  },
  {
    name: 'Rwanda',
    transactable: true,
    countryCode: 'RW',
    currency: 'USD',
  },
  {
    name: 'Saint Barthélemy',
    countryCode: 'BL',
    currency: 'EUR',
  },
  {
    name: 'Saint Helena, Ascension, Tristan da Cunha',
    countryCode: 'SH',
    currency: 'EUR',
  },
  {
    name: 'Saint Kitts and Nevis',
    transactable: true,
    countryCode: 'KN',
    currency: 'USD',
  },
  {
    name: 'Saint Lucia',
    countryCode: 'LC',
    currency: 'USD',
  },
  {
    name: 'Saint Martin',
    countryCode: 'MF',
    currency: 'USD',
  },
  {
    name: 'Saint Pierre and Miquelon',
    countryCode: 'PM',
    currency: 'USD',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    countryCode: 'VC',
    currency: 'USD',
  },
  {
    name: 'Samoa',
    countryCode: 'WS',
    currency: 'USD',
  },
  {
    name: 'San Marino',
    countryCode: 'SM',
    currency: 'EUR',
  },
  {
    name: 'São Tomé and Príncipe',
    countryCode: 'ST',
    currency: 'USD',
  },
  {
    name: 'Saudi Arabia',
    transactable: true,
    countryCode: 'SA',
    currency: 'USD',
  },
  {
    name: 'Senegal',
    transactable: true,
    countryCode: 'SN',
    currency: 'USD',
  },
  {
    name: 'Serbia',
    transactable: true,
    countryCode: 'RS',
    currency: 'USD',
  },
  {
    name: 'Seychelles',
    countryCode: 'SC',
    currency: 'USD',
  },
  {
    name: 'Sierra Leone',
    countryCode: 'SL',
    currency: 'USD',
  },
  {
    name: 'Singapore',
    transactable: true,
    countryCode: 'SG',
    currency: 'USD',
  },
  {
    name: 'Sint Maarten',
    countryCode: 'SX',
    currency: 'USD',
  },
  {
    name: 'Slovakia',
    transactable: true,
    countryCode: 'SK',
    currency: 'EUR',
  },
  {
    name: 'Slovenia',
    transactable: true,
    countryCode: 'SI',
    currency: 'EUR',
  },
  {
    name: 'Solomon Islands',
    countryCode: 'SB',
    currency: 'USD',
  },
  {
    name: 'Somalia',
    countryCode: 'SO',
    currency: 'USD',
  },
  {
    name: 'South Africa',
    transactable: true,
    countryCode: 'ZA',
    currency: 'USD',
  },
  {
    name: 'South Georgia and South Sandwich Islands',
    countryCode: 'GS',
    currency: 'USD',
  },
  {
    name: 'South Sudan',
    countryCode: 'SS',
    currency: 'USD',
  },
  {
    name: 'Spain',
    transactable: true,
    countryCode: 'ES',
    currency: 'EUR',
  },
  {
    name: 'Sri Lanka',
    transactable: true,
    countryCode: 'LK',
    currency: 'USD',
  },
  {
    name: 'Suriname',
    countryCode: 'SR',
    currency: 'USD',
  },
  {
    name: 'Svalbard and Jan Mayen',
    countryCode: 'SJ',
    currency: 'USD',
  },
  {
    name: 'Swaziland',
    countryCode: 'SZ',
    currency: 'USD',
  },
  {
    name: 'Sweden',
    transactable: true,
    countryCode: 'SE',
    currency: 'SEK',
  },
  {
    name: 'Switzerland',
    transactable: true,
    countryCode: 'CH',
    currency: 'CHF',
  },
  {
    name: 'Taiwan',
    transactable: true,
    countryCode: 'TW',
    currency: 'TWD',
  },
  {
    name: 'Tajikistan',
    transactable: true,
    countryCode: 'TJ',
    currency: 'USD',
  },
  {
    name: 'Tanzania',
    transactable: true,
    countryCode: 'TZ',
    currency: 'USD',
  },
  {
    name: 'Thailand',
    transactable: true,
    countryCode: 'TH',
    currency: 'USD',
  },
  {
    name: 'Timor-Leste',
    countryCode: 'TL',
    currency: 'USD',
  },
  {
    name: 'Togo',
    countryCode: 'TG',
    currency: 'USD',
  },
  {
    name: 'Tokelau',
    countryCode: 'TK',
    currency: 'USD',
  },
  {
    name: 'Tonga',
    countryCode: 'TO',
    currency: 'USD',
  },
  {
    name: 'Trinidad and Tobago',
    transactable: true,
    countryCode: 'TT',
    currency: 'USD',
  },
  {
    name: 'Tunisia',
    transactable: true,
    countryCode: 'TN',
    currency: 'USD',
  },
  {
    name: 'Turkey',
    transactable: true,
    countryCode: 'TR',
    currency: 'USD',
  },
  {
    name: 'Turkmenistan',
    transactable: true,
    countryCode: 'TM',
    currency: 'USD',
  },
  {
    name: 'Turks and Caicos Islands',
    countryCode: 'TC',
    currency: 'USD',
  },
  {
    name: 'Tuvalu',
    countryCode: 'TV',
    currency: 'USD',
  },
  {
    name: 'U.S. Outlying Islands',
    countryCode: 'UM',
    currency: 'USD',
  },
  {
    name: 'U.S. Virgin Islands',
    transactable: true,
    countryCode: 'VI',
    currency: 'USD',
  },
  {
    name: 'Uganda',
    transactable: true,
    countryCode: 'UG',
    currency: 'USD',
  },
  {
    name: 'Ukraine',
    transactable: true,
    countryCode: 'UA',
    currency: 'USD',
  },
  {
    name: 'United Arab Emirates',
    transactable: true,
    countryCode: 'AE',
    currency: 'USD',
  },
  {
    name: 'United Kingdom',
    transactable: true,
    countryCode: 'GB',
    currency: 'GBP',
  },
  {
    name: 'Uruguay',
    transactable: true,
    countryCode: 'UY',
    currency: 'USD',
  },
  {
    name: 'Uzbekistan',
    transactable: true,
    countryCode: 'UZ',
    currency: 'USD',
  },
  {
    name: 'Vanuatu',
    countryCode: 'VU',
    currency: 'USD',
  },
  {
    name: 'Vatican City (Holy See)',
    transactable: true,
    countryCode: 'VA',
    currency: 'EUR',
  },
  {
    name: 'Venezuela',
    transactable: true,
    countryCode: 'VE',
    currency: 'USD',
  },
  {
    name: 'Vietnam',
    transactable: true,
    countryCode: 'VN',
    currency: 'USD',
  },
  {
    name: 'Wallis and Futuna',
    countryCode: 'WF',
    currency: 'USD',
  },
  {
    name: 'Yemen',
    transactable: true,
    countryCode: 'YE',
    currency: 'USD',
  },
  {
    name: 'Zambia',
    transactable: true,
    countryCode: 'ZM',
    currency: 'USD',
  },
  {
    name: 'Zimbabwe',
    transactable: true,
    countryCode: 'ZW',
    currency: 'USD',
  },
];

export enum SearchType {
  App = 'App',
  ConsultingService = 'ConsultingService',
  CloudIndustry = 'CloudIndustry',
}

export interface ServerQueryOptions {
  includeTestProducts?: boolean;
  isConverged?: boolean;
}
