import React from 'react';
import ReactDOM from 'react-dom';
import * as PropTypes from 'prop-types';
import { getId } from '@fluentui/utilities';
import { SignInModal } from '@shared/components/modals/signInModal';
import MediaModal from './mediaModal';
import VideoModal from './videoModal';
import DriveModal from './driveModal';
import { FieldHubModal } from '@shared/containers/modals/fieldHubModal';
import ErrorModal from '@shared/containers/modals/errorModal';
import AnimationModal from './animationModal';
import DisclaimerModal from './disclaimerModal';
import NpsModal from './npsModal';
import { InstructionsModal } from './instructionsModal';
import { DownloadSampleModal } from './downloadSampleModal';
import SpzaComponent from './../spzaComponent';
import { Constants } from '@shared/utils/constants';
import { IAppDataItem, ITelemetryData } from '@shared/Models';
import { IUserDataState, IFeatureFlags } from '../../../State';
import { IBuildHrefContext, ICommonContext, ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { urlReplace, routes, appendQueryParams } from '@shared/routerHistory';
import { getWindow } from '@shared/services/window';
import { removeURLParameter } from '@shared/utils/appUtils';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { NpsModule } from '@shared/utils/npsUtils';
import { handleConsentModalCall } from '@shared/handlers/consentModalHandler';
import { handleRatingModal } from 'handlers/ratingModalHandler';
import { Service } from '@shared/serviceViewModel';
import { handleReportAbuseModalCall } from '@shared/handlers/reportAbuseModalHandler';
import { stringifyError } from '@shared/utils/errorUtils';
import { FocusTrapZone } from '@fluentui/react';
import { ReviewCommentModal } from '@shared/containers/modals/reviewCommentModal';
import { logger } from '@src/logger';
import { ReviewMarkAsHelpfulModal } from '@shared/components/modals/reviewMarkAsHelpfulModal';
import classNames from 'classnames';

export interface IModalProps {
  entityId: string;
  modalId: number;
  allApps: IAppDataItem[];
  onModalDismiss?: () => void;
  disableDismissModal?: boolean;
  allCloudsIndustry: (IAppDataItem | Service)[];
  allPrivateOffers: IAppDataItem[];
  allFavouriteApps: IAppDataItem[];
  allServices: Service[];
  partnerId: string;
  userInfo: IUserDataState;
  isSignedIn: boolean;
  isEmbedded: boolean;
  featureFlags: IFeatureFlags;
  entityType: Constants.EntityType;
  billingCountryCode?: string;
  options?: any;
  query?: { [key: string]: string };
  dismissModal: () => void;
  dismissModalWithRedirect: (href: string) => void;
  setUserReviewStatus: (hasReview: boolean) => void;
  ensureAppData: () => void;
  updateAppEntity: (app: IAppDataItem) => void;
  fetchAppDetails: (entityId: string) => void;
  payload: any;
  embedHost: string;
}

export class Modal extends SpzaComponent<IModalProps, any> {
  context: IBuildHrefContext & ICommonContext & ILocContext & ILocParamsContext;
  trigger: any = null;
  domTreeElement: HTMLElement;

  constructor(props: IModalProps, context: IBuildHrefContext & ICommonContext & ILocContext & ILocParamsContext) {
    super(props, context);

    this.domTreeElement = document.createElement('div');
    this.domTreeElement.setAttribute('id', getId('modal'));
    this.trigger = document.activeElement;
  }

  attachElementToDOM = () => document.body.appendChild(this.domTreeElement);
  removeElementFromDOM = () => document.body.removeChild(this.domTreeElement);

  componentDidMount() {
    this.attachElementToDOM();

    const perfPayload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.PageLoad,
      actionModifier: Constants.Telemetry.ActionModifier.End,
      details: JSON.stringify({ ModalId: this.props.modalId }),
    };
    SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', perfPayload);
    logger.info(perfPayload.details, {
      action: perfPayload.action,
      actionModifier: perfPayload.actionModifier,
    });

    getWindow().addEventListener('keydown', this.handleKeyPress.bind(this), false);

    NpsModule.BlockShowingNPS();
  }

  handleKeyPress(e: KeyboardEvent) {
    const { disableDismissModal } = this.props;

    if (e.code === 'Escape' && !disableDismissModal) {
      this.dismissModal();
    }
  }

  componentWillUnmount() {
    const currWindow = getWindow();
    currWindow.removeEventListener('keydown', this.handleKeyPress.bind(this), false);
    // During CTA experience, when the user gets back from the Sign-in/Sign-up page to the modal,
    // there will be a query parameter(modalAppId) added in the end.
    // So, when the modal is dismissed, we need to get rid of this parameter
    if (currWindow.location.href.indexOf('modalAppId') > -1) {
      const pageURL = removeURLParameter(currWindow.location.href, 'modalAppId');
      urlReplace(pageURL);
    }

    NpsModule.AllowShowingNPS();
    if (this.trigger) {
      this.trigger.focus();
    }

    this.removeElementFromDOM();
  }

  dismissModal() {
    const { disableDismissModal } = this.props;
    if (disableDismissModal) {
      return;
    }
    if (this.props.modalId === Constants.ModalType.Error) {
      if (!this.props.isEmbedded) {
        this.props.dismissModalWithRedirect(
          this.context.buildHref(routes.home, null, {
            category: null,
            industry: null,
            product: null,
            search: null,
          }) as string
        );
      } else {
        let href = this.context.buildHref(routes.marketplace, null, {
          category: null,
          industry: null,
          product: null,
          showPrivateApps: false,
          search: null,
          page: null,
        });
        // For the embedded app we need to convert the relative path to an absolute path by adding '/embed'
        href =
          '/embed' +
          appendQueryParams(href, {
            embedHost: this.props.embedHost,
          });
        this.props.dismissModalWithRedirect(href);
      }
    } else {
      this.props.dismissModal();
    }
    if (this.props.onModalDismiss) {
      this.props.onModalDismiss();
    }
  }

  getAppDetails = async () => {
    const { entityId, fetchAppDetails } = this.props;
    fetchAppDetails(entityId);
  };

  renderImpl() {
    const { disableDismissModal } = this.props;
    let modalContent: any = null;
    let dismissModal = this.dismissModal.bind(this);

    let modalBackgroundClasses = classNames({
      spza_presentation: true,
      spza_background_1: true,
      spza_background_2: false,
    });

    let modalClasses = classNames({
      spza_dialog: true,
      spza_dialog_design2: false,
    });

    let appData: IAppDataItem = null;
    let service: Service = null;

    switch (this.props.modalId) {
      case Constants.ModalType.SignIn:
        modalContent = (
          <SignInModal
            dismissModal={dismissModal}
            altAuth={this.props.featureFlags.AltAuth}
            signInModalType={Constants.SignInType.SignInWith_MSA_AAD}
            disableDismissModal={disableDismissModal}
          />
        );
        break;
      case Constants.ModalType.CTA:
        if (this.props.entityType === Constants.EntityType.App) {
          try {
            appData = [...this.props.allApps, ...(this.props.allCloudsIndustry as IAppDataItem[])].find(
              (x) => x.entityId === this.props.entityId
            );

            // check the public offers first and then the private
            if (!appData) {
              appData = this.props.allPrivateOffers.find((app) => app.entityId === this.props.entityId);
              if (!appData) {
                appData = this.props.allFavouriteApps.find((app) => app.item.entityId === this.props.entityId)?.item;
              }
              if (appData) {
                this.props.updateAppEntity(appData);
              }
            }
            if (!appData) {
              this.getAppDetails();
            }
          } catch (error) {
            const payload: ITelemetryData = {
              page: getWindow().location.href,
              action: Constants.Telemetry.Action.PageLoad,
              actionModifier: Constants.Telemetry.ActionModifier.Error,
              details: `Case Constants.ModalType.CTA - this.props.allApps= ${JSON.stringify(
                this.props.allApps
              )}, error msg= ${stringifyError(error)}`,
            };
            SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
            logger.error(payload.details, {
              action: payload.action,
              actionModifier: payload.actionModifier,
            });

            throw error;
          }
        } else if (this.props.entityType === Constants.EntityType.Service) {
          service = [...this.props.allServices, ...(this.props.allCloudsIndustry as Service[])].filter((x) => {
            return x && x.entityId === this.props.entityId;
          })[0];
        }

        if (appData?.CertificationState === Constants.CertificationType.MicrosoftCertified) {
          const payloadInfo: ITelemetryData = {
            page: getWindow().location.href,
            action: Constants.Telemetry.Action.Click,
            actionModifier: Constants.Telemetry.ActionModifier.Info,
            details: JSON.stringify({ contentName: 'M365 Certified' }),
          };
          SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payloadInfo);
          logger.info(payloadInfo.details, {
            action: payloadInfo.action,
            actionModifier: payloadInfo.actionModifier,
          });
        }

        modalContent = handleConsentModalCall(this.props, appData, dismissModal, service);
        break;
      case Constants.ModalType.Video:
        modalContent = <VideoModal video={this.props.payload} dismissModal={dismissModal} />;
        break;
      case Constants.ModalType.MediaModal:
        modalContent = <MediaModal payload={this.props.payload} dismissModal={dismissModal} />;
        break;
      case Constants.ModalType.Drive:
        modalContent = <DriveModal drive={this.props.payload} dismissModal={dismissModal} />;
        break;
      case Constants.ModalType.Iframe:
        modalBackgroundClasses = classNames({
          spza_presentation: true,
          spza_background_1: false,
          spza_background_2: true,
        });

        modalContent = <FieldHubModal iframeProps={this.props.payload} dismissModal={dismissModal} />;
        dismissModal = null;
        break;
      case Constants.ModalType.Error:
        modalContent = <ErrorModal dismissModal={dismissModal} />;
        break;
      case Constants.ModalType.Disclaimer:
        modalBackgroundClasses = classNames({
          spza_presentation: true,
          spza_background_1: false,
          spza_background_2: true,
        });

        modalClasses = classNames({
          spza_dialog: true,
          spza_dialog_design2: true,
        });

        modalContent = <DisclaimerModal payload={this.props.payload} dismissModal={dismissModal} />;
        dismissModal = null;
        break;
      case Constants.ModalType.NPS:
        modalContent = (
          <NpsModal
            dismissModal={dismissModal}
            emailAddress={this.props.userInfo.signedIn ? this.props.userInfo.email : 'no user'}
          />
        );
        dismissModal = null;
        break;
      case Constants.ModalType.Rating:
        try {
          appData = this.props.allApps.filter((x) => {
            return x.entityId.toLowerCase() === this.props.payload.app.entityId.toLowerCase();
          })[0];
        } catch (error) {
          const payload: ITelemetryData = {
            page: getWindow().location.href,
            action: Constants.Telemetry.Action.PageLoad,
            actionModifier: Constants.Telemetry.ActionModifier.Error,
            details: `Case Constants.ModalType.Rating - this.props.allApps= ${JSON.stringify(
              this.props.allApps
            )}, error msg= ${stringifyError(error)}`,
          };
          SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
          logger.error(payload.details, {
            action: payload.action,
            actionModifier: payload.actionModifier,
          });
          throw error;
        }
        modalContent = handleRatingModal(this.props, appData, dismissModal, service);
        dismissModal = null;
        break;
      case Constants.ModalType.ReviewComment:
        modalContent = <ReviewCommentModal payload={this.props.payload} dismissModal={dismissModal} />;
        dismissModal = null;
        break;
      case Constants.ModalType.ReviewMarkAsHelpful:
        modalContent = <ReviewMarkAsHelpfulModal payload={this.props.payload} dismissModal={dismissModal} />;
        dismissModal = null;
        break;
      case Constants.ModalType.Animation:
        modalContent = <AnimationModal />;
        break;
      case Constants.ModalType.ReportAbuse:
        modalContent = handleReportAbuseModalCall(
          this.props.options?.appId,
          this.props.options?.reviewId,
          this.props.options?.reviewTitle,
          this.props.userInfo,
          this.props.options?.reviewSource,
          dismissModal
        );
        break;
      case Constants.ModalType.Instructions:
        modalContent = (
          <InstructionsModal
            context={this.context}
            isFromDownload={this.props.payload.isFromDownload}
            dismissModal={dismissModal}
          />
        );
        break;
      case Constants.ModalType.DownloadSample:
        modalContent = <DownloadSampleModal context={this.context} dismissModal={dismissModal} />;
        break;
      default:
        break;
    }

    return ReactDOM.createPortal(
      <FocusTrapZone>
        <div className={modalClasses}>
          {/* This presentation role is the grey background which we use behind the modal. On click, it closes the modal */}
          <div className={modalBackgroundClasses} onClick={dismissModal}></div>
          {modalContent}
        </div>
      </FocusTrapZone>,
      this.domTreeElement
    );
  }
}

(Modal as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  buildHref: PropTypes.func,
  renderErrorModal: PropTypes.func,
};
