import { urlPush, buildHref, IRouteConfig, withRouter } from '@shared/routerHistory';
import { changeBillingRegion, getUserAuthStatus, performSearchAll } from '@shared/actions/thunkActions';
import {
  createSearchboxInputChangedAction,
  createSearchDataReceivedAction,
  createModalAction,
  createVideoModalAction,
  createAppViewTelemetryLoggedAction,
  createRatingAction,
  createSearchSortingOptionChangedAction,
  createBreadcrumbAction,
  createSetIsNpsLogicVerifiedAction,
  createChangeLocaleLanguage,
} from '@shared/actions/actions';
import { AppView as AppViewComponent, IAppViewProps } from '@shared/components/appView';
import { Constants } from '@shared/utils/constants';
import { IAppDataItem, IURLQuery } from '@shared/Models';
import { queryChanged } from '@shared/actions/queryThunkActions';
import { getAllEntityTypes, getEntityRegistrationByView } from '@shared/utils/entityRegistration';
import { getTelemetryDetailContentCurrentView } from '@shared/utils/appUtils';
import { IState } from '@src/State';
import { connect } from 'react-redux';
import { saveCookieItem } from '@shared/utils/browserStorageUtils';
import { getUserFavouriteViewEntityList } from '@shared/utils/userFavouriteUtils';

export const mapStateToProps = (state: IState, ownProps: IAppViewProps) => {
  const userFavorites = state.userFavourite;
  const viewApps = getUserFavouriteViewEntityList(userFavorites.apps);
  const viewServices = getUserFavouriteViewEntityList(userFavorites.services);

  return {
    isConverged: state.config.isConverged,
    initLoading: state.config.initLoading,
    searchSortingOption: state.search && state.search.searchSortingOption,
    performedSubsetQueryString: state.apps && state.apps.subsetSearchQuery,
    localizedStrings: state.config.locStrings,
    locale: state.config.locale,
    isEmbedded: state.config.isEmbedded,
    storefrontName: state.config.storefrontName,
    refreshToken: state.users.refreshToken,
    homeAccountId: state.users.homeAccountId,
    embedHost: state.config.embedHost,
    enableNewNps: state.config.newNpsEnabled,
    npsLogicVerified: state.config.npsLogicVerified,
    correlationId: state.config.correlationId,
    // eslint-disable-next-line @typescript-eslint/ban-types
    buildHref: function <T>(
      route: IRouteConfig<T>,
      routeParams: T,
      newQuery: object,
      keepAllParams?: boolean,
      oldParams?: object,
      keepCampaignParams?: boolean
    ) {
      return buildHref(
        route,
        ownProps.params as any,
        routeParams,
        oldParams || ownProps.location.query,
        newQuery,
        keepAllParams,
        state.config.locale,
        keepCampaignParams
      );
    },
    locationQuery: {
      ...(ownProps.location.query || {}),
      ...(ownProps.params || {}),
    },
    entityId: state.modal.entityId,
    showModal: state.modal.showModal,
    userInfo: state.users,
    isInErrorDialog: state.modal.modalId === Constants.ModalType.Error,
    telemetryLoggedCount: state.config.appViewTelemetryLoggedCount,
    nationalCloud: state.config.nationalCloud,
    entityType: getEntityRegistrationByView(state.config.currentView),
    isMobile: state.config.isMobile,
    currentView: getTelemetryDetailContentCurrentView(state.config.currentView, state.config.currentGalleryViewIsCurated),
    allApps: state.apps ? state.apps.dataList : null,
    tileDataRequestId: state.apps ? state.apps.tileDataRequestId : '',
    uiRole: state.users.profile && state.users.profile.uiRole,
    regionCode: state.config.billingCountryCode,
    regionDisabled: state.config.isDisabledBilling,
    favoritesCount: viewApps.length + viewServices.length,
    pcaInstanceInitialized: state.config.pcaInstanceInitialized,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    searchSortingOptionChanged: (searchSortingOption: Constants.SearchSortingOption) =>
      dispatch(createSearchSortingOptionChangedAction({ searchSortingOption })),
    changeSearchText: (searchvalue: string) => dispatch(createSearchboxInputChangedAction({ searchString: searchvalue })),
    updateQuery: (query: IURLQuery) => dispatch(queryChanged(query)),
    performSearchAll: (searchvalue: string, searchSortingOption: Constants.SearchSortingOption) =>
      dispatch(performSearchAll(searchvalue, searchSortingOption)),
    clearSearch: () => {
      getAllEntityTypes().forEach((entityType) => {
        dispatch(
          createSearchDataReceivedAction({
            entityType,
            entityIdData: [],
            performedQuery: '',
          })
        );
      });
    },
    showCTAModal: ({
      entityId,
      entityType,
      ctaType,
      skuId,
      planId,
      isOpenedFromPDP,
      ribbonKey,
    }: {
      entityId: string;
      entityType: Constants.EntityType;
      ctaType: Constants.CTAType;
      skuId?: string;
      planId?: string;
      isOpenedFromPDP?: boolean;
      ribbonKey?: string;
    }) => {
      dispatch(
        createModalAction({
          showModal: true,
          modalId: Constants.ModalType.CTA,
          entityId,
          options: {
            ctaType,
            skuId,
            planId,
            ribbonKey,
          },
          entityType,
          isOpenedFromPDP,
        })
      );
    },
    showHowItWorksModal: () => {
      dispatch(
        createVideoModalAction({
          showModal: true,
          videoThumbnail: null,
          videoUrl: 'https://aka.ms/spzavideo',
          videoName: 'How It Works',
        })
      );
    },
    showErrorModal: () => {
      dispatch(
        createModalAction({
          showModal: true,
          modalId: Constants.ModalType.Error,
        })
      );
    },
    showNPSModal: () => {
      dispatch(
        createModalAction({
          showModal: true,
          modalId: Constants.ModalType.NPS,
        })
      );
    },
    showAnimationModal: () => {
      dispatch(
        createModalAction({
          showModal: true,
          modalId: Constants.ModalType.Animation,
        })
      );
    },
    showRaingsModal: (app: IAppDataItem, accessKey: string, ctaType: string, callback: any) => {
      dispatch(
        createRatingAction({
          showModal: true,
          app,
          accessKey,
          ctaType,
          callback,
        })
      );
    },
    showReportAbuseModal: (appId: string, reviewId: string, reviewTitle: string, reviewSource: string) => {
      dispatch(
        createModalAction({
          showModal: true,
          modalId: Constants.ModalType.ReportAbuse,
          options: {
            appId,
            reviewId,
            reviewTitle,
            reviewSource,
          },
        })
      );
    },
    updateTelemetryLoggedCount: () => dispatch(createAppViewTelemetryLoggedAction(null)),
    openTile: (detailUrl: string) => {
      const breadcrumbUrl = '';
      dispatch(
        createBreadcrumbAction({
          breadcrumbUrl,
        })
      );
      urlPush(detailUrl, true);
    },
    getUserAuthStatus: () => {
      return dispatch(getUserAuthStatus());
    },
    setIsNpsLogicVerified: (verified: boolean) => dispatch(createSetIsNpsLogicVerifiedAction({ verified })),
    openSignInModal: () => {
      return dispatch(
        createModalAction({
          showModal: true,
          modalId: Constants.ModalType.SignIn,
        })
      );
    },
    onMarketChange: (countryCode: string) => {
      saveCookieItem(Constants.Cookies.billingRegionCookie, countryCode);
      return dispatch(changeBillingRegion({ countryCode }));
    },
    onLocaleChange: (locale: string) => {
      saveCookieItem(Constants.Cookies.LocaleCookie, locale);
      const pathSegments = window.location.pathname.split('/');
      pathSegments[1] = locale;
      const url = `${pathSegments.join('/')}${window.location.search}`;
      window.location.href = url;
      return dispatch(createChangeLocaleLanguage({ locale }));
    },
    dispatch,
  };
};

const AppView = withRouter(connect(mapStateToProps, mapDispatchToProps)(AppViewComponent));

export default AppView;
