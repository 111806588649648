import { copyState, IServiceDataState, initialServiceDataState, ServiceCountries } from '@src/State';
import { Constants } from '@shared/utils/constants';
import {
  Action,
  CountriesDataReceivedAction,
  isType,
  RecommendedTrendingServiceReceivedAction,
  RecommendedWhatsNewServiceReceivedAction,
  SetEntityDataRegionFiltersAction,
} from '@shared/actions/actions';
import { commonEntityDataReducer } from '@shared/reducers/commonEntityDataReducer';
import { AzureEnvironmentSettings } from '@shared/AzureEnvironmentSettings';

function isSelectedCountryValid(countriesList: ServiceCountries, selectedCountry: string): boolean {
  // If countries list not defined yet, this is valid country selection.
  // We will change the selected country to the default one if we get list with out it.
  if (!countriesList || Object.keys(countriesList).length === 0 || !selectedCountry) {
    return true;
  }
  return selectedCountry in countriesList;
}

export default function serviceDataReducer(
  state: IServiceDataState = initialServiceDataState,
  action: Action<any>
): IServiceDataState {
  const newState = copyState(state);

  if (isType(action, CountriesDataReceivedAction)) {
    newState.countriesList = action.payload.countries;

    if (!isSelectedCountryValid(action.payload.countries, state.selectedCountry)) {
      newState.selectedCountry = AzureEnvironmentSettings.defaultCountryCode.toUpperCase();
    }
  } else if (isType(action, SetEntityDataRegionFiltersAction)) {
    if (state.selectedCountry !== action.payload.countryCode || state.selectedRegion !== action.payload.regionCode) {
      if (isSelectedCountryValid(state.countriesList, action.payload.countryCode)) {
        newState.selectedCountry = action.payload.countryCode;
      }
      newState.selectedRegion = action.payload.regionCode;
    }
  } else if (isType(action, RecommendedWhatsNewServiceReceivedAction)) {
    newState.recommendedServices.whatsNewService = action.payload;
  } else if (isType(action, RecommendedTrendingServiceReceivedAction)) {
    newState.recommendedServices.trendingService = action.payload;
  } else {
    return commonEntityDataReducer(Constants.EntityType.Service, state, action) as IServiceDataState;
  }
  return newState;
}
