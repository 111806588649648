import React, { startTransition } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import { getWindow } from '@shared/services/window';
import { initRouterHistory, appHistory } from '@shared/routerHistory';
import { handleRecoverableHydrationError } from '@shared/handlers/recoverableHydrationError';
import { setHydrationStart, setScriptStart } from '@shared/utils/pltUtils';
import { getRoutes } from './Router';

import App, { initApp } from '@appsource/App';

import '@appsource/styles/styles-LTR.scss';

let didInitRouter = false;

setScriptStart();

async function hydrateAppSource() {
  getWindow().disablePriorityWorkaround?.();

  if (!didInitRouter) {
    initRouterHistory({ appRoutes: getRoutes() });
    didInitRouter = true;
  }

  const store = initApp();
  const { siteEnvironment } = store.getState().config;

  const root = document.getElementById('react-view');

  setHydrationStart();

  startTransition(() => {
    hydrateRoot(
      root,
      <HistoryRouter history={appHistory}>
        <App store={store} />
      </HistoryRouter>,
      {
        onRecoverableError: (error: unknown) => handleRecoverableHydrationError(error, siteEnvironment),
      }
    );
  });
}

window.HYDRATE = hydrateAppSource;

if (window.LOADED) {
  hydrateAppSource();
}
