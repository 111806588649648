/* eslint-disable react/forbid-dom-props */
import React from 'react';
// eslint-disable-next-line import/named
import * as PropTypes from 'prop-types';
import { AppTile } from '@shared/containers/appTile';
import { getUserFavouriteEntityAppType } from '@shared/utils/userFavouriteDedicatedUtils';
import { ILinkedInProductGroup, IUserDataState } from '@src/State';
import { AppReviewCollection } from '../containers/appReviewCollection';
import Overview from '../containers/overview';
import {
  IBuildHrefContext,
  ICommonContext,
  IContactCallbackContext,
  ICTACallbackContext,
  ILocContext,
  ILocDateStringContext,
  ILocParamsContext,
  IOpenTileCallbackContext,
} from '@shared/interfaces/context';
import { routes, urlPush, urlReplace, WithRouterProps } from '@shared/routerHistory';
import { SpzaInstrumentProvider, SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { getWindow } from '@shared/services/window';
import {
  isMSASupported,
  isTransactApp,
  isAvailableInThisRegion,
  getPDPRoute,
  getGalleryRoute,
  isPrivateOffer,
  isPowerBIVisuals,
  isMigratedPowerBIVisual,
  getAppTelemetryDetailContentText,
  isAppForMSAAccountOnly,
  isD365App,
  isD365BCApp,
  isBagTransactable,
  isSignedInChanged,
  canRenderPricing,
  getActionStringForCTAType,
  getProductByUrlKey,
  getProdcutBitMaskInfo,
  isCertifiedSoftware,
} from '@shared/utils/appUtils';
import { Constants, OfferType } from '@shared/utils/constants';
import { IDataValues, DataMap, ProductsType } from '@shared/utils/dataMapping';
import { getPrimaryProductUrl, getProductFilter } from '@shared/utils/datamappingHelpers';

import * as DetailUtils from '@shared/utils/detailUtils';
import {
  getBillingCountryByCountryCode,
  getPriceString,
  getStartingFromPriceText,
  hidePowerBiVisualPricing,
} from '@shared/utils/pricing';
import UserFavouriteTileDetailButton from '@shared/containers/userFavouriteTileDetailButton';
import {
  AppTag,
  IAppDataItem,
  ISimpleSKU,
  IStartingPrice,
  ITelemetryData,
  PricingStates,
  IFilterElement,
  IPricingElement,
  TabChild,
  ICollateralDocuments,
  ILinksList,
  IDataItem,
  CheckoutSource,
  CheckoutSourcePayload,
  IFilterItemDetail,
} from '@shared/Models';
import { InternalLink } from '@shared/components/internalLink';
import { ExternalLink } from '@shared/components/externalLink';
import { SimplePlanPricing } from '@shared/components/simplePlanPricing';
import SpzaComponent from '@shared/components/spzaComponent';
import { Tab, Tabs, ITabsProps, SELECTED_TAB_QUERY } from '@shared/components/tabs';
import { TelemetryImage } from '@shared/components/telemetryImage';
import { IconLink } from '@shared/components/iconLink';
import { Card } from '@shared/components/card';
import { ButtonLink } from '@shared/components/ButtonLink';
import { AppDetailsLinkedItems } from '@shared/components/appDetailsLinkedItems';
import { StickyCard } from '@shared/components/stickyCard';
import {
  UsefulInfo,
  UsefulInfoMetadataColumns,
  UsefulInfoMetadataCellItemInternalLink,
  UsefulInfoMetadataCellItemLabel,
  UsefulInfoMetadataCellItemLink,
  UsefulInfoMetadataCell,
  UsefulInfoMetadataCellItemType,
  UsefulInfoMetadataCellItemFilter,
} from './UsefulInfo';
import { getWorkBreakEnabledString } from '@shared/utils/stringUtils';
import { BreadcrumbUrl } from '@shared/components/breadcrumbUrl';
import {
  Icon,
  mergeStyleSets,
  Stack,
  ScreenWidthMinXLarge,
  ScreenWidthMinXXLarge,
  DefaultButton,
  PrimaryButton,
  Text,
  Spinner,
} from '@fluentui/react';
import { MicrosoftManagedIndicatorWrapper } from 'components/microsoftManagedIndicatorWrapper';
import { useTelemetry } from '@shared/hooks/useTelemetry';
import { logger } from '@src/logger';
import { CtaButtons } from '@shared/components/ctaButtons';
import { SaasPricingTable } from '@appsource/components';
import { AppDetailsHeaderRatingBar } from '@shared/components/appDetailsHeaderRatingBar';
import { NeutralColors, CommunicationColors } from '@fluentui/theme';
import classNames from 'classnames';
import { isOneTimePaymentOffer } from '@shared/utils/onetimepaymentoffers';
import { UserTerms } from '@appsource/components/userTerms';
import type { IStackTokens } from '@fluentui/react';
import { IAppEntityLinkedInProductGroupThunkActionParams } from '@shared/interfaces/linkedIn/models';
import { LinkedinProductGroupBar } from '@appsource/components/linkedin/linkedinProductGroupBar';
import { LinkedinProductGroup } from '@appsource/components/linkedin/linkedinProductGroup';
import { WithHydrationProps } from '@shared/hooks/useHydration';
import noResults from '@shared/images/noResults.svg';
import Badge from '@appsource/components/Badge';
import { isMaccUser } from '@shared/utils/userUtils';

// eslint-disable-next-line react-hooks/rules-of-hooks
const [{ pageAction }] = useTelemetry();
const BI_AREA = 'Private Offer';

export interface IAppDetailsDispatchProps {
  setCheckoutSourcePDP: ({ source }: CheckoutSourcePayload) => void;
  ensureAsyncData: ({ entityId, isPrivate }: { entityId: string; isPrivate?: boolean }) => Promise<boolean>;
  ensureAppReviewsData(entityId: string, forceUpdate?: boolean): Promise<boolean>;
  openInstructionsModal: () => void;
  openRatingModal: (app: IAppDataItem, accessKey: string, ctaType: string, callback: () => void) => void;
  loadLinkedInProductGroup: (args: IAppEntityLinkedInProductGroupThunkActionParams) => void;
  fetchUserPrivateOffers: () => void;
}

export interface IAppDetailsRouterProps {
  entityId: string;
  privateOffer?: string;
}

export interface IAppDetailsOwnProps extends WithRouterProps, WithHydrationProps {
  entityId: string;
  app: IAppDataItem;
  startingPrice: IStartingPrice;
  breadcrumbUrl: string;
  defaultTab: string;
  allApps: IAppDataItem[];
  nationalCloud: string;
  billingCountryCode: string;
  correlationId: string;
  currentView: string;
  userInfo: IUserDataState;
  parentApps: IAppDataItem[];
  linkedAddIns: IAppDataItem[];
  locale: string;
  suggestedItems: IDataItem[];
  isLoadingUserProfile: boolean;
  doesTenantHasLicenseForApp: boolean;
  uiRole: Constants.UiRole;
  isPrivate: boolean;
  isEmbedded: boolean;
  isLoadingUserData: boolean;
  hideBreadcrumbUrl: boolean;
  linkedInProductGroup: ILinkedInProductGroup;
  openInNewWindowButtonFlag: boolean;
  ribbonKey?: string;
}

export interface IAppDetailsProps extends IAppDetailsOwnProps, IAppDetailsDispatchProps {}

interface AppDetailsComponentState {
  isPageScrolled: boolean;
  pageLoadLogged: boolean;
}

interface FilterPathParams {
  [key: string]: string;
  industry: string;
  category: string;
  product: string;
  search: string;
}

const contentStyles = mergeStyleSets({
  pdpDetailsContainer: [
    'pdpDetails',
    {
      padding: 0,
      '@media (min-width: 640px) and (max-width: 1024px)': { paddingLeft: '32px !important', paddingRight: '32px !important' },
      '@media (max-width: 640px)': { paddingLeft: '20px !important', paddingRight: '20px !important' },
    },
  ],
  autoScrollTabContent: {
    overflow: 'auto',
  },
  tabsContainer: {
    display: 'flex',
    [`@media (min-width: ${ScreenWidthMinXLarge}px)`]: {
      flexDirection: 'row',
    },
    [`@media (max-width: ${ScreenWidthMinXLarge - 1}px)`]: {
      flexDirection: 'column-reverse',
    },
  },
  userTermsContainer: {
    backgroundColor: NeutralColors.white,
    border: '1px solid #EAEAEA',
    borderRadius: '2px',
    height: 'fit-content',
    [`@media (max-width: ${ScreenWidthMinXLarge - 1}px)`]: {
      margin: '36 0 24 0',
    },
    [`@media (min-width: ${ScreenWidthMinXLarge - 1}px)`]: {
      marginBottom: 28,
    },
    [`@media (min-width: ${ScreenWidthMinXLarge}px) and (max-width: ${ScreenWidthMinXXLarge - 1}px)`]: {
      marginLeft: 40,
      minWidth: 200,
      maxWidth: 200,
    },
    [`@media (min-width: ${ScreenWidthMinXXLarge}px)`]: {
      marginLeft: 32,
      minWidth: 256,
      maxWidth: 256,
    },
    '& a:link': {
      color: CommunicationColors.primary,
      textDecoration: 'underline',
    },
    '& a:visited': {
      color: CommunicationColors.primary,
    },
    '& a:hover': {
      color: CommunicationColors.shade20,
    },
    '& a:active': {
      color: CommunicationColors.shade30,
    },
  },
  ctaSampleButton: {
    paddingTop: 10,
    '&:active': {
      color: '#323130',
    },
    '&:hover': {
      color: '#323130',
      opacity: '0.7',
    },
  },
  instructionLink: {
    marginTop: '10px !important',
  },
  bagdeContainer: {
    marginTop: '12px',
  },
});

const consentDisclaimerTokens: IStackTokens = { padding: 16 };

export class AppDetails extends SpzaComponent<IAppDetailsProps, AppDetailsComponentState> {
  private static pricingTabName = 'PlansAndPrice';
  private static pdpTabsId = 'pdpTabs';
  private static pdpTabsClass = 'pdpTabs';
  private stickyCardRef: React.RefObject<HTMLDivElement> = null;
  private instrument: SpzaInstrumentProvider;

  context: IBuildHrefContext &
    ILocContext &
    ILocParamsContext &
    ILocDateStringContext &
    ICTACallbackContext &
    IContactCallbackContext &
    ICommonContext &
    IOpenTileCallbackContext;

  constructor(
    props: IAppDetailsProps,
    context: IBuildHrefContext &
      ILocContext &
      ILocParamsContext &
      ICommonContext &
      ILocDateStringContext &
      ICTACallbackContext &
      IContactCallbackContext &
      IOpenTileCallbackContext
  ) {
    super(props, context);
    this.instrument = SpzaInstrumentService.getProvider();
    this.stickyCardRef = React.createRef();
    this.state = {
      isPageScrolled: false,
      pageLoadLogged: false,
    };
  }

  /** This temporary function blocks pricing for BAG offers that are not yet ready to be transacted. */
  shouldBlockPricing() {
    // hasPrices check is necessary to ensure we don't block pricing for valid Dynamics 365 and PBI Visual offers
    const { app } = this.props;
    return (
      (isD365App(getPrimaryProductUrl(app.primaryProduct)) ||
        isD365BCApp(getPrimaryProductUrl(app.primaryProduct)) ||
        isPowerBIVisuals(getPrimaryProductUrl(app.primaryProduct))) &&
      !isBagTransactable(app) &&
      app.hasPrices
    );
  }

  getProductFilterListElements(filterItem: string, filterTileTypes: string): IFilterElement[] {
    let primaryProductFilter: IDataValues = null;
    const filteritems: IFilterElement[] = [];

    Object.keys(DataMap.products).map((value: ProductsType) => {
      const currentDatamapBlock = DataMap.products[`${value}`];

      if (this.props.app && !currentDatamapBlock.ShortcutFilters && DataMap.products[`${value}`]?.match?.(this.props.app)) {
        const filter = currentDatamapBlock;
        if (!filter) {
          return null;
        }

        const pathParams: FilterPathParams = {
          industry: null,
          category: null,
          product: null,
          search: null,
        };
        pathParams[`${filterTileTypes}`] = filter.ShortcutUrlKey;
        // we are preselecting L1 product in detail page category redirect link back to gallery page.
        // we are only doing this for primary product now which mean no L2 product will be preselected.
        if (filterTileTypes === Constants.filterTileTypes.category && primaryProductFilter) {
          pathParams.product = primaryProductFilter.UrlKey;
        }

        const newPath = this.context.buildHref(routes.marketplace, null, pathParams);

        // Temporary fix for PowerApps to have their builtfor displayed as Apps
        // until we come up with the complete design
        const { property, mask } = getProdcutBitMaskInfo(DataMap.products.PowerApps);
        const isPowerApp =
          filter.UrlKey === 'web-apps' &&
          this.props.app.products[`${property}`] &&
          (this.props.app.products[`${property}`] & mask) > 0;
        if (isPowerApp) {
          filter.LocKey = 'Apps';
          filter.LongTitle = 'Apps';
        }
        filteritems.push({
          filterItem,
          locKey: filter.LocKey,
          newPath,
          title: filter.LongTitle,
          urlKey: filter.UrlKey,
        });
      }
      return null;
    });
    return filteritems;
  }

  getFilterListInfoElements = ({
    filterType,
    filterItem,
    details,
    primaryProductFilter,
  }: {
    filterType: Constants.TypeFilterTileTypes;
    filterItem: Constants.TypeFilterMaps;
    details: IFilterItemDetail[];
    primaryProductFilter?: IDataValues;
  }): UsefulInfoMetadataCellItemFilter[] => {
    const { app } = this.props;
    return details?.map(({ urlKey, longTitle, locKey }) => {
      const pathParams: Partial<FilterPathParams> = {
        [filterType]: urlKey,
        product: primaryProductFilter?.UrlKey,
      };

      const newPath = this.context.buildHref(routes.marketplace, null, pathParams);
      const filter: IFilterElement = { filterItem, locKey, newPath, title: longTitle, urlKey };

      return {
        id: filter.title,
        type: UsefulInfoMetadataCellItemType.Filter,
        text: filter.locKey,
        entityId: app.entityId,
        filter,
        isEmbedded: false,
        nationalCloud: this.props.nationalCloud,
      };
    });
  };

  showDialog(ctaType: number) {
    const app = { ...this.props.app, actionString: getActionStringForCTAType(ctaType) };
    this.context.ctaCallback({
      entity: app,
      entityType: Constants.EntityType.App,
      ctaType,
      actionModifier: Constants.Telemetry.ActionModifier.AppDetailCTAButton,
      hasLicense: this.props.doesTenantHasLicenseForApp,
      isOpenedFromPDP: true,
      ribbonKey: this.props.ribbonKey,
    });
  }

  fetchData(nextProps: IAppDetailsProps) {
    if (nextProps.entityId) {
      const { entityId, isPrivate } = nextProps;
      const { arm } = this.props.userInfo.accessToken;

      // If it private, we must have ARM token ready
      if (isPrivate && arm) {
        this.props.ensureAsyncData({ entityId, isPrivate });
      } else if (!isPrivate) {
        this.props.ensureAsyncData({ entityId, isPrivate });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: IAppDetailsProps) {
    this.fetchData(nextProps);
  }

  componentDidMount() {
    this.fetchData(this.props);
    const { entityId, app, loadLinkedInProductGroup } = this.props;

    if (loadLinkedInProductGroup) {
      loadLinkedInProductGroup({ legacyOfferId: entityId });
    }
    if (app) {
      this.telemetryPageLoad();

      // User profile isn't being loaded so we can send the log
      if (!this.props.isLoadingUserProfile) {
        this.logPageLoadAfterUserLoaded();
      }

      this.openRatingModal();
    }

    if (getWindow()) {
      getWindow().addEventListener('scroll', this.handleScroll.bind(this));
    }
  }

  componentDidUpdate(prevProps: IAppDetailsProps) {
    const userProfileLoaded = prevProps.isLoadingUserProfile && !this.props.isLoadingUserProfile;
    if (userProfileLoaded && !this.state.pageLoadLogged) {
      this.logPageLoadAfterUserLoaded();
    }

    if (isSignedInChanged(this.props.userInfo?.signedIn, prevProps.userInfo.signedIn)) {
      const entityId = this.props.app?.entityId || this.props.entityId;
      const { arm } = this.props.userInfo.accessToken;
      const { isPrivate } = this.props;
      this.props.fetchUserPrivateOffers();

      if (!this.props.app && arm) {
        // If it is a private app, the n app will be undefined and we'll need to refetch it from private
        this.props.ensureAsyncData({ entityId, isPrivate });
      }

      // Get review details
      this.props.ensureAppReviewsData(entityId, true);
    }

    this.handleScroll();
  }

  componentWillUnmount() {
    const curWindow = getWindow();
    if (curWindow) {
      curWindow.removeEventListener('scroll', this.handleScroll.bind(this));
    }
  }

  openRatingModal() {
    if (this.props.location && this.props.location.query && this.props.location.query.survey) {
      const accessKey = this.props.location.query.survey === 'user' ? null : this.props.location.query.survey;
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.props.openRatingModal(this.props.app, accessKey, this.props.app.actionString, () => {});
    }
  }

  openInstructionsModal = () => {
    if (this.props.app.downloadLink) {
      this.props.openInstructionsModal();
    }
  };

  getAllAppsBtn() {
    const seeAllUrl = this.context.buildHref(routes.marketplace, null, {
      category: null,
      industry: null,
      product: null,
      search: null,
    });
    return (
      <InternalLink
        className="allAppsButton"
        href={seeAllUrl}
        accEnabled
        aria-label={`${BI_AREA} see all apps`}
        data-bi-name={`${BI_AREA} See all apps button`}
        data-bi-area={BI_AREA}
      >
        {this.context.loc('ValueProposition_ActionBanner_Button', 'See all apps')}
      </InternalLink>
    );
  }

  renderEmptyPrivatePage() {
    const itemAlignmentsStackTokens: IStackTokens = {
      childrenGap: 28,
      padding: 10,
    };

    return (
      <div className="privateOffersContainer">
        <div className="emptyGalleryWrapper">
          <Stack tokens={itemAlignmentsStackTokens}>
            <Stack.Item align="center">
              <img className="noResultsImg" src={noResults} alt={this.context.loc('UserPrivateOffer_No_Results', 'No results')} />
            </Stack.Item>
            <Stack.Item align="center">
              <Text variant="large">
                {this.context.loc('UserPrivateOffer_Pdp_NoAccess', "You don't have access to this private plan.")}
              </Text>
            </Stack.Item>
            <Stack.Item align="center">
              <ExternalLink
                aria-label={this.context.loc('App_LearnMore', 'Learn more')}
                data-bi-name={`${BI_AREA} learn more button`}
                data-bi-area={BI_AREA}
                accessibilityEnabled
                href="https://go.microsoft.com/fwlink/?linkid=2162844"
                shouldOpenInTab
              >
                {this.context.loc('App_LearnMore', 'Learn more')}
              </ExternalLink>
            </Stack.Item>
            <Stack.Item align="center">{this.getAllAppsBtn()}</Stack.Item>
          </Stack>
        </div>
      </div>
    );
  }

  logPageLoadAfterUserLoaded() {
    const {
      app: appInfo,
      currentView,
      doesTenantHasLicenseForApp,
      nationalCloud,
      uiRole,
      billingCountryCode,
      isEmbedded,
    } = this.props;
    const { entityId: appName, ctaTypes } = appInfo || {};
    const payload: ITelemetryData = {
      page: getWindow().location?.href,
      action: Constants.Telemetry.Action.PageLoad,
      actionModifier: Constants.Telemetry.ActionModifier.End,
      appName,
      details: getAppTelemetryDetailContentText({
        appInfo,
        isNationalCloud: !!nationalCloud,
        isEmbedded,
        ctaTypes,
        currentView,
        uiRole,
        hasLicense: uiRole ? doesTenantHasLicenseForApp : null,
        shouldShowUserTerms:
          appInfo && billingCountryCode ? DetailUtils.shouldShowUserTerms({ app: appInfo, billingCountryCode }) : null,
      }),
    };
    this.instrument.probe<ITelemetryData>('logTelemetryInfo', payload);
    logger.info(payload.details, { action: payload.action, actionModifier: payload.actionModifier, appName: payload.appName });

    this.setState({ pageLoadLogged: true });
  }

  telemetryPageLoad() {
    const { billingCountryCode, ctaTypes, entityId, linkedAddIns, linkedSaaS } = this.props.app;

    if (ctaTypes && isTransactApp({ isEmbedded: false, ctaTypes, appData: this.props.app, billingRegion: billingCountryCode })) {
      pageAction(null, { content: { contentType: Constants.JsllSaas.TransactableSaasPdpView } });
    }

    if (linkedAddIns && linkedAddIns.length > 0) {
      pageAction(null, {
        content: {
          contentType: Constants.Telemetry.ContentType.SaaSBundlePageView,
          areaName: Constants.Telemetry.AreaName.AppDetails,
          contentName: entityId,
        },
      });
    } else if (linkedSaaS) {
      pageAction(null, {
        content: {
          contentType: Constants.Telemetry.ContentType.AddInBundlePageView,
          areaName: Constants.Telemetry.AreaName.AppDetails,
          contentName: entityId,
        },
      });
    }
  }

  getOfficeWorksWithList(property: string): string[] {
    const list = DetailUtils.getDetailArrayItem(this.props.app, property);

    if (!Array.isArray(list)) {
      return [];
    }

    return list
      .filter((item: string) => !!Constants.Office365Mapping[`${item}`])
      .map((item: string) => {
        const value = Constants.Office365Mapping[`${item}`];

        // value will be of the form
        // [actualName]
        // [localizationResource, param1, ...] or
        if (value.length === 1) {
          return value[0];
        } else {
          if (Array.isArray(value[1])) {
            return this.context.locParams(value[0], value[1]);
          } else {
            return this.context.locParams(value[0], [value[1]]);
          }
        }
      });
  }

  checkDup(res: string[][], item: string[]) {
    for (let i = 0; i < res.length; i++) {
      const existAward: string[] = res[`${i}`];
      if (
        existAward.length === item.length &&
        existAward.every(function (v, j) {
          return v === item[`${j}`];
        })
      ) {
        return true;
      }
    }
    return false;
  }

  getOfficeAwards(awardKeys: string[]) {
    const res: string[][] = [];
    for (let i = 0; i < awardKeys.length; i++) {
      const award = Constants.OfficeAwardsMapping[awardKeys[`${i}`]];
      if (award) {
        if (i === 0) {
          res.push(award);
        } else {
          if (!this.checkDup(res, award)) {
            res.push(award);
          }
        }
      }
    }
    return res;
  }

  isLatestAppAward(awardKeys: string[]) {
    for (let i = 0; i < awardKeys.length; i++) {
      const award = Constants.OfficeAwardsMapping[awardKeys[`${i}`]];
      if (award && award[2] === this.context.loc('Latest_App_Awards_Year')) {
        return true;
      }
    }
    return false;
  }

  isPowerBICertifiedApp(keys: string[]) {
    for (let i = 0; i < keys.length; i++) {
      const key = keys[`${i}`];
      if (AppTag[`${key}`] === AppTag.PowerBICertified) {
        return true;
      }
    }
    return false;
  }

  isMSPApp() {
    return this.props.app.tags && this.props.app.tags.indexOf(Constants.BadgeType.AzureExpertsMSP) >= 0;
  }

  getBreadcrumbElement() {
    const { hideBreadcrumbUrl } = this.props;
    const { href, locKey } = getGalleryRoute(this.props.app.appType);
    const linksList: ILinksList[] = [
      {
        locKey,
      },
      { locKey: this.props.app.title },
    ];

    return (
      <BreadcrumbUrl
        href={this.context.buildHref(href, null, {})}
        linksList={linksList}
        hideBreadcrumbUrl={hideBreadcrumbUrl}
      ></BreadcrumbUrl>
    );
  }

  getIconElement() {
    const iconUri = DetailUtils.getDetailInformation(this.props.app, 'LargeIconUri');
    if (!iconUri) {
      return <div className="iconHost"></div>;
    }

    let iconBackgroundColor = DetailUtils.getDetailInformation(this.props.app, 'IconBackgroundColor');
    if (!iconBackgroundColor) {
      iconBackgroundColor = this.props.app.iconBackgroundColor;
    }

    return (
      <div className="iconHost" style={{ backgroundColor: iconBackgroundColor }}>
        <span className="thumbnailSpacer"></span>
        <TelemetryImage
          src={iconUri}
          className="appLargeIcon"
          itemPropName="image"
          title={this.props.app ? this.props.app.title : ''}
        />
      </div>
    );
  }

  getCTAElement() {
    const { setCheckoutSourcePDP } = this.props;
    return (
      <CtaButtons
        app={this.props.app}
        billingCountryCode={this.props.billingCountryCode}
        startingPrice={this.props.startingPrice}
        isLoadingUserData={this.props.isLoadingUserData}
        onCTAClick={(ctaType) => {
          setCheckoutSourcePDP({ source: CheckoutSource.PdpTop });
          this.showDialog(ctaType);
        }}
        isEmbedded={false}
        openInNewWindowButtonFlag={this.props.openInNewWindowButtonFlag}
      />
    );
  }

  getDownloadSampleButton() {
    if (!this.props.app.downloadSampleLink) {
      return null;
    }
    const {
      app: { entityId },
    } = this.props;

    return (
      <PrimaryButton
        className={classNames([contentStyles.ctaSampleButton, 'CTAbutton', 'downloadSampleButton'])}
        name="downloadSampleButton"
        key={this.props.app.downloadSampleLink}
        href={this.props.app.downloadSampleLink}
        target="_self"
        data-bi-id={Constants.JsllCTAId.DownloadSample}
        data-bi-type={Constants.JsllCTAId.DownloadSample}
        data-bi-name={entityId}
        onClick={() => {
          const payload: ITelemetryData = {
            page: getWindow().location.href,
            action: Constants.Telemetry.Action.Click,
            actionModifier: Constants.Telemetry.ActionModifier.DownloadPBIVisualSample,
            appName: this.props.entityId,
          };
          this.instrument.probe<ITelemetryData>(Constants.Telemetry.ProbeName.LogInfo, payload);
          logger.info('', {
            action: payload.action,
            actionModifier: payload.actionModifier,
            appName: payload.appName,
          });
        }}
      >
        {this.context.loc('DownloadSample', 'Download Sample')}
      </PrimaryButton>
    );
  }

  getInstructionsButton() {
    const linkTitle = 'Instructions';

    return (
      <InternalLink
        role="link"
        onClick={this.openInstructionsModal}
        className={classNames([contentStyles.instructionLink, 'c-hyperlink instructionsButton'])}
        telemetryDetails={linkTitle}
      >
        {this.context.loc('Instructions', linkTitle)}
      </InternalLink>
    );
  }

  getNotAvailableInRegionText() {
    return (
      <div className="notAvailableInRegionContainer">
        <span className="icon-info-14" />
        <span className="notAvailableInRegionText">
          {this.context.loc('NotAvailableInBillingRegion', 'Not available in selected billing region')}
        </span>
      </div>
    );
  }

  getUserFavouriteButton() {
    const app = this.props.app;
    if (!app || isPrivateOffer(this.props.app.appType)) {
      return null;
    }

    return <UserFavouriteTileDetailButton entityId={app.entityId} entityType={getUserFavouriteEntityAppType()} item={app} />;
  }

  getMicrosoftManagedIndicator() {
    const { indicatorText, indicatorClasses, iconName, iconClasses } = this.getMicrosoftManagedIndicatorUiVariables();

    if (indicatorText) {
      return (
        <div className="microsoftManagedIndicator">
          <Icon iconName={iconName} className={iconClasses} />
          <div className={indicatorClasses}>{indicatorText}</div>
        </div>
      );
    }
    return null;
  }

  getMicrosoftManagedIndicatorUiVariables(): {
    indicatorText: string;
    indicatorClasses: string;
    iconName: string;
    iconClasses: string;
  } {
    const indicator = {
      indicatorText: '',
      indicatorClasses: '',
      iconName: '',
      iconClasses: '',
    };
    if (isD365BCApp(getPrimaryProductUrl(this.props.app.primaryProduct))) {
      indicator.indicatorText = this.context.loc('MicrosoftManagedIndicator_D365BC');
      indicator.indicatorClasses = 'indicatorText';
      indicator.iconName = 'Info';
      indicator.iconClasses = 'indicatorIcon';
    } else if (isD365App(getPrimaryProductUrl(this.props.app.primaryProduct))) {
      indicator.indicatorText = this.context.loc('MicrosoftManagedIndicator_D365_NotAdmin');
      indicator.indicatorClasses = 'indicatorText';
      indicator.iconName = 'Info';
      indicator.iconClasses = 'indicatorIcon';
    }
    return indicator;
  }

  renderBadge(name: string) {
    return <Badge name={name} />;
  }

  handleBadgeClick(contentName: string) {
    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.Info,
      details: JSON.stringify({ contentName }),
    };

    this.instrument.probe<ITelemetryData>('logTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });
  }

  // TODO: move to utils
  hasOfficeAwards() {
    if (this.props.app.awards) {
      const awards = this.getOfficeAwards(this.props.app.awards);
      if (awards && awards.length > 0) {
        return true;
      }
    }

    if (this.props.app.entityId.toUpperCase() === Constants.HyperfishEntityId.toUpperCase()) {
      const awards = this.getOfficeAwards(['AppAwards_2018_BestUX_Place2']);
      if (awards && awards.length > 0) {
        return true;
      }
    }

    return false;
  }

  // TODO: move to utils
  isPBICertified() {
    if (this.props.app.tags) {
      if (this.isPowerBICertifiedApp(this.props.app.tags)) {
        return true;
      }
    }

    return false;
  }

  // TODO: move to utils
  isMicrosoftCertified() {
    if (this.props.app.CertificationState === Constants.CertificationType.MicrosoftCertified) {
      return true;
    }

    return false;
  }

  // TODO: move to utils
  isFreeTrial() {
    const { app } = this.props;

    if (app.actionString === Constants.ActionStrings.Try) {
      return true;
    }

    if (app.startingPrice?.hasFreeTrial) {
      return true;
    }

    if (app.trials === DataMap.trials['free-trial'].FilterID) {
      return true;
    }

    // vm
    if (app.pricingInformation?.skus) {
      const length = app.pricingInformation.skus.length;

      // loop through all skus to see if any of them have a free trial days duration
      for (let i = 0; i < length; i++) {
        const sku = app.pricingInformation.skus[`${i}`] as ISimpleSKU;
        if (sku.freeTrialDurationDays) {
          return true;
        }
      }
    }

    return false;
  }

  renderLinkedItems(app: IAppDataItem, nationalCloud: string) {
    let dataBiId = '';
    let productId = '';
    let isSaaSRendering = false;
    let headerString = '';

    if (app.linkedSaaS) {
      isSaaSRendering = true;
      dataBiId = 'View Parent';
      productId = DataMap.products.AzureforWebApps.UrlKey;
    } else if (app.linkedAddIns && app.linkedAddIns.length > 0) {
      dataBiId = 'View AddIn';
      productId = DataMap.products.Office365.UrlKey;
    }
    headerString = isSaaSRendering
      ? this.context.loc(
          'Saas_Linking_App_Details_Parent_Title',
          'For the full capabilities of this add-in, get the SaaS package:'
        )
      : this.context.loc('Saas_Linking_App_Details_AddIns_Title', 'This app package includes premium versions of these add-ins:');

    return (
      <AppDetailsLinkedItems
        headerString={headerString}
        linkedItems={isSaaSRendering ? this.props.parentApps : this.props.linkedAddIns}
        isSaaSRendering={isSaaSRendering}
        productId={productId}
        dataBiId={dataBiId}
        nationalCloud={nationalCloud}
        app={app}
        billingCountryCode={this.props.billingCountryCode}
        isLoadingUserData={this.props.isLoadingUserData}
        locale={this.props.locale}
      />
    );
  }

  renderLinkedAddIns() {
    if (this.props.app.linkedAddIns && this.props.app.linkedAddIns.length) {
      return (
        <div className="addInsContainer">
          <h2 className="addInsHeader">
            {this.context.loc(
              'Saas_Linking_App_Details_AddIns_Title',
              'This SaaS package includes full capability versions of these add-ins:'
            )}
          </h2>
          <div>
            {this.props.app.linkedAddIns.map((addIn) => (
              <InternalLink
                key={addIn.entityId}
                href={this.context.buildHref(
                  this.getPDProute(),
                  {
                    productId: DataMap.products.Office365.UrlKey,
                    entityId: addIn.entityId,
                  },
                  { tab: 'Overview' }
                )}
                data-bi-id="viewAddIn"
                data-bi-area={Constants.Telemetry.AreaName.AppDetails}
                data-bi-view="SaaSBundle"
                name={'LinkedAddIn-' + addIn.type}
              >
                <div className={'addInsIcon ms-BrandIcon--icon48 ms-BrandIcon--' + addIn.type}></div>
              </InternalLink>
            ))}
          </div>
        </div>
      );
    }
  }

  renderAlreadyPurchased() {
    if (this.props.app.ctaTypes && this.props.app.ctaTypes.some((ctaType) => ctaType === Constants.CTAType.Purchase)) {
      return (
        <div className="alreadyPurchasedContainer">
          <div className="alreadyPurchasedLabel">
            {this.context.locParams('Saas_Linking_App_Details_Already_Title', [this.props.app.title])}
          </div>
          <button className="alreadyPurchasedButton" onClick={() => this.showDialog(Constants.CTAType.AlreadyPurchased)}>
            {this.context.loc('Saas_Linking_App_Details_Already_Button')}
          </button>
        </div>
      );
    }
  }

  renderRatings() {
    return <AppDetailsHeaderRatingBar app={this.props.app} />;
  }

  renderLinkedIn() {
    const { linkedInProductGroup, locale, billingCountryCode } = this.props;
    return (
      linkedInProductGroup && (
        <LinkedinProductGroupBar linkedInProductGroup={linkedInProductGroup} locale={locale} countryCode={billingCountryCode} />
      )
    );
  }

  getDetailCellElement(information: string, headerContent: string, itemPropName?: string) {
    const microTaggingItemPropName = itemPropName || '';

    if (information) {
      if (microTaggingItemPropName === 'publisher') {
        return (
          <div className="cell" itemProp={microTaggingItemPropName} itemScope itemType="https://schema.org/Organization">
            <header role="complementary" aria-label={headerContent}>
              {headerContent}
            </header>
            <span itemProp="name">{information}</span>
          </div>
        );
      } else {
        return (
          <div className="cell" itemProp={microTaggingItemPropName}>
            <h2 role="complementary" aria-label={headerContent}>
              {headerContent}
            </h2>
            <span>{information}</span>
          </div>
        );
      }
    } else {
      return null;
    }
  }

  // Refactor Candidate: Consider using detailsUtils.getDetailHyperlinkElement
  getDetailHyperlinkElement(information: string, id: string, locString: string) {
    if (information) {
      return (
        <a
          className="c-hyperlink"
          rel="noreferrer"
          target="_blank"
          href={information}
          title={locString}
          onClick={() => {
            DetailUtils.generateLinkPayloadAndLogTelemetry(
              DetailUtils.OwnerType.App,
              this.props.app.entityId,
              id,
              information,
              'Default'
            );
          }}
        >
          {locString}
        </a>
      );
    } else {
      return null;
    }
  }

  changeTabCallback(tabTitle: string) {
    const newPath = this.getTabHref(tabTitle);

    urlReplace(newPath);
  }

  getPDProute() {
    return getPDPRoute(this.props.app.appType);
  }

  getTabHref(tabTitle: string) {
    const productString = getPrimaryProductUrl(this.props.app.primaryProduct);

    const productData = getProductByUrlKey({ urlKey: productString });
    const product = productData ? productData.UrlKey : productString;

    return this.context.buildHref(
      this.getPDProute(),
      { productId: product, entityId: this.props.app.entityId },
      { tab: tabTitle },
      true,
      false,
      true
    );
  }

  getStartingPriceElement(startingPrice: IStartingPrice, isDetailed = false): IPricingElement {
    const {
      app,
      billingCountryCode,
      app: { entityId },
    } = this.props;

    if (
      startingPrice &&
      startingPrice.pricingData &&
      startingPrice.pricingData !== PricingStates.NoPricingData &&
      startingPrice.pricingData !== PricingStates.NotAvailableInThisRegion &&
      startingPrice.pricingData !== PricingStates.AlwaysAvailable &&
      startingPrice.pricingData !== PricingStates.Loading
    ) {
      const pricingElement: IPricingElement = { type: null, label: '', value: '', href: '' };
      if (startingPrice.pricingData === PricingStates.FreeApp) {
        pricingElement.type = Constants.ButtonType.Label;
        pricingElement.label = this.context.loc('Pricing');
        pricingElement.value = this.context.loc('Pricing_Free');
      } else if (startingPrice.pricingData === PricingStates.AdditionalPurchasesRequired) {
        pricingElement.type = Constants.ButtonType.Label;
        pricingElement.label = this.context.loc('Pricing');
        pricingElement.value = this.context.loc('AdditionalPurchaseMayBeRequired');
      } else if (startingPrice.pricingData === PricingStates.StartFromFree) {
        // Freemium offers with pricing plans
        pricingElement.type = Constants.ButtonType.Label;
        pricingElement.label = this.context.loc('Pricing_StartsAt');
        pricingElement.value = this.context.loc(
          !this.shouldBlockPricing() && isDetailed ? 'Pricing_StartsFromFree' : 'Pricing_Free'
        );
      } else if (startingPrice.pricingData === PricingStates.BYOL) {
        Object.assign(pricingElement, {
          type: Constants.ButtonType.Label,
          label: this.context.loc('Pricing'),
          value: this.context.loc('Bring_Your_Own_License'),
        });
      } else if (typeof startingPrice.pricingData === 'object') {
        if (this.canRenderPricing()) {
          const isOneTimePayment = isOneTimePaymentOffer(entityId);
          pricingElement.href = this.getTabHref(AppDetails.pricingTabName);
          pricingElement.type = Constants.ButtonType.Link;
          pricingElement.label = this.context.loc('StartingAt');
          pricingElement.value = getStartingFromPriceText({
            startingPrice,
            offerType: app.offerType,
            context: this.context,
            countryCode: billingCountryCode,
            isOneTimePaymentOffer: isOneTimePayment,
            locale: this.props.locale,
          });
        } else {
          pricingElement.type = Constants.ButtonType.Label;
          pricingElement.label = this.context.loc('Pricing');
          pricingElement.value = getPriceString({
            context: this.context,
            price: startingPrice.pricingData,
            countryCode: this.props.billingCountryCode,
            currency: startingPrice.pricingData.currency,
            locale: this.props.locale,
          });
        }
      }
      if (typeof startingPrice.pricingData === 'object' && !this.canRenderPricing()) {
        if (pricingElement.value) {
          pricingElement.value = pricingElement.value + ' ' + this.context.loc('SiteLicenseAlsoAvailable');
        } else {
          pricingElement.type = Constants.ButtonType.Label;
          pricingElement.label = this.context.loc('Pricing');
          pricingElement.value = this.context.loc('SiteLicenseAlsoAvailable');
        }
      }
      return pricingElement;
    }
    return null;
  }

  bulidStartingPriceElement({ pricingLinkClassName }: { pricingLinkClassName: string }) {
    const { app, isEmbedded } = this.props;
    const startingPrice = this.shouldBlockPricing() ? null : this.props.startingPrice;
    const pricingElement = this.getStartingPriceElement(startingPrice);
    if (pricingElement) {
      return (
        <div className="cell" itemProp="offers" itemScope itemType="https://schema.org/Offer">
          <div className="pricingText">
            {pricingElement &&
            pricingElement.label &&
            pricingElement.type &&
            pricingElement.value &&
            !hidePowerBiVisualPricing({ app, isEmbedded }) ? (
              <ButtonLink
                href={pricingElement.href ? pricingElement.href : ''}
                className={pricingLinkClassName}
                type={pricingElement.type}
                accEnabled
                label={pricingElement.label}
                value={pricingElement.value}
              />
            ) : null}
            {startingPrice.pricingData === PricingStates.FreeApp ? (
              <div>
                <meta itemProp="price" content="0" />
                <meta itemProp="priceCurrency" content="USD" />
                <meta itemProp="category" content="free" />
              </div>
            ) : typeof startingPrice.pricingData === 'object' ? (
              <div>
                <meta itemProp="priceCurrency" content={startingPrice.pricingData.currency} />
                <meta
                  itemProp="price"
                  content={getPriceString({
                    context: this.context,
                    price: startingPrice.pricingData,
                    countryCode: this.props.billingCountryCode,
                    currency: startingPrice.pricingData.currency,
                    locale: this.props.locale,
                  }).replace(/[^0-9.]/g, '')}
                />
              </div>
            ) : null}
          </div>
        </div>
      );
    }
    return null;
  }

  renderAADWorkAccountMetaData() {
    const appData = this.props.app;
    const primaryProduct = appData && appData.primaryProduct;
    const products = appData && appData.products;

    const ctaTypes: Constants.CTAType[] = (appData && appData.ctaTypes) || [];

    const shouldRenderAADWorkAccountMetaData: boolean = ctaTypes.some((ctaType: Constants.CTAType) => {
      return !isAppForMSAAccountOnly(primaryProduct, products, ctaType);
    });

    return shouldRenderAADWorkAccountMetaData && 'SignInModal_AccountType2';
  }

  buildFilterElements(filteritems: IFilterElement[]): JSX.Element[] {
    if (filteritems) {
      let filterElements = filteritems.map((filter) => {
        if (this.props.nationalCloud) {
          return <div key={filter.title}>{this.context.loc(filter.locKey, filter.title)}</div>;
        } else {
          return (
            <IconLink
              iconClassName={'icon-' + filter.urlKey + '-16'}
              href={filter.newPath}
              title={this.context.loc(filter.locKey, filter.title)}
              onClick={(e: MouseEvent) => {
                DetailUtils.generateLinkPayloadAndLogTelemetry(
                  DetailUtils.OwnerType.App,
                  this.props.app.entityId,
                  filter.filterItem,
                  filter.newPath,
                  'Default'
                );
                urlPush(filter.newPath);
                window.scrollTo(0, 0);

                // The preventDefault prevents the route from transitioning and thus refreshing the page.
                e.preventDefault();
              }}
              key={filter.title}
              accEnabled={true}
              className="c-hyperlink detailsCategories"
            >
              <div className="productItem">
                {this.context.loc(filter.locKey, filter.title)}
                {filter.filterItem === Constants.filterMaps.categories ? (
                  <meta itemProp="applicationCategory" content={this.context.loc(filter.locKey, filter.title)} />
                ) : null}
              </div>
            </IconLink>
            // todo: need to figure out how to link on server. Need a Link component
          );
        }
      });
      filterElements = filterElements.filter((element: JSX.Element) => !!element);
      return filterElements;
    }
    return null;
  }

  /** Returns Plans + Pricing tab if any SKUs have a price, otherwise returns Plans tab */
  getPlanAndPricingTabTitle() {
    if (!this.shouldBlockPricing() && this.props.app.pricingInformation?.skus?.some((sku: ISimpleSKU) => !!sku.startingPrice)) {
      return this.context.loc('Detail_PlansPricingTab');
    }
    return this.context.loc('Detail_PlansTab');
  }

  renderReviewsTab() {
    return (
      <AppReviewCollection
        appId={this.props.app.entityId}
        appData={this.props.app}
        location={this.props.location}
        isEmbed={false}
      />
    );
  }

  renderOverviewTab() {
    const { app, billingCountryCode, suggestedItems } = this.props;
    const { ctaTypes, detailInformation, entityId, offerType, products, shortDescription, tags, title, autoRunLaunchEvents } =
      app;
    const isOneTimePayment = isOneTimePaymentOffer(entityId);
    return (
      <Overview
        isOneTimePayment={isOneTimePayment}
        ownerType={DetailUtils.OwnerType.App}
        ownerId={entityId}
        ownerTitle={title}
        products={products}
        shortDescription={shortDescription}
        description={detailInformation.Description}
        images={detailInformation.Images}
        videos={detailInformation.DemoVideos}
        capabilities={DetailUtils.getDetailArrayItem(app, 'Capabilities')}
        autoRunLaunchEvents={autoRunLaunchEvents}
        TileType={AppTile}
        crossListings={suggestedItems as IAppDataItem[]}
        isPowerBICertified={tags ? this.isPowerBICertifiedApp(tags) : null}
        isSaaSTransactableApp={
          offerType === OfferType.SaaSApp
            ? isTransactApp({ isEmbedded: false, ctaTypes, appData: app, billingRegion: billingCountryCode })
              ? Constants.JsllSaas.SaasTransactable
              : Constants.JsllSaas.SaasNonTransactable
            : ''
        }
      />
    );
  }

  renderLinkedinTab() {
    const {
      linkedInProductGroup,
      app,
      locale,
      billingCountryCode,
      userInfo: { email: userEmail, signedIn: userSignedIn },
    } = this.props;
    return (
      <LinkedinProductGroup
        linkedInProductGroup={linkedInProductGroup}
        offerName={app.title}
        countryCode={billingCountryCode}
        locale={locale}
        userEmail={userSignedIn ? userEmail : null}
        feedbackScoreRequired={true}
        parentTabQuery={SELECTED_TAB_QUERY}
      />
    );
  }

  getTabChildren(): TabChild[] {
    const tabsChilds: TabChild[] = [
      {
        title: this.context.loc('PartnerDetail_OverviewTab', 'Overview'),
        name: Constants.Tabs.Overview,
        getContent: () => this.renderOverviewTab(),
      },
    ];

    if (this.canRenderPricing()) {
      tabsChilds.push({
        title: this.getPlanAndPricingTabTitle(),
        name: AppDetails.pricingTabName,
        getContent: () => this.renderPricing(),
      });
    }

    const usefulInfo = () =>
      this.props.isHydrated && (
        <UsefulInfo ownerType={DetailUtils.OwnerType.App} metadata={this.getUsefulInfoMetadata(this.props.app)} />
      );

    const {
      app: { entityId = '' },
    } = this.props;

    const isOneTimePayment = isOneTimePaymentOffer(entityId);
    if (!isOneTimePayment) {
      tabsChilds.push({
        title: this.context.loc('AppDetail_ReviewsTab', 'Ratings + reviews'),
        name: Constants.Tabs.Reviews,
        getContent: () => this.renderReviewsTab(),
      });
    }
    tabsChilds.push({
      title: this.context.loc('AppDetail_DetilsAndSupportTab', 'Details + support'),
      name: Constants.Tabs.DetailsAndSupport,
      getContent: usefulInfo,
    });

    const { linkedInProductGroup } = this.props;

    if (linkedInProductGroup) {
      tabsChilds.push({
        title: this.context.loc('AppDetail_LinkedinTab', 'LinkedIn community'),
        name: Constants.Tabs.Linkedin,
        textBadge: this.context.loc('New', 'New'),
        getContent: () => this.renderLinkedinTab(),
      });
    }

    return tabsChilds;
  }

  getTabsProps(): ITabsProps {
    const tabsProps: ITabsProps = {
      defaultTab: this.props.defaultTab,
      ownerType: DetailUtils.OwnerType.App,
      ownerId: this.props.app.entityId,
      changeTabCallback: this.changeTabCallback.bind(this),
      getTabHref: this.getTabHref.bind(this),
    };

    return tabsProps;
  }

  /**
   * Returns the link element for either the publisher's terms of use, the standard contract, or the standard contract
   * with amendments
   */
  // TODO - move to utils
  getTermsOfUseLinks() {
    const terms = [{ information: this.props.app.licenseTermsUrl, id: 'LicenseAgreement', locString: 'App_LicenseAgreement' }];
    if (this.props.app.universalAmendmentUrl) {
      terms.push({
        information: this.props.app.universalAmendmentUrl,
        id: 'App_UniversalAmendment',
        locString: 'App_UniversalAmendment',
      });
    }
    return terms;
  }

  canRenderPricing() {
    const { app, billingCountryCode } = this.props;

    return canRenderPricing({
      app,
      isEmbedded: false,
      billingRegion: billingCountryCode,
    });
  }

  getItNowTableCallback = (planId?: string) => {
    const { app, ribbonKey, setCheckoutSourcePDP } = this.props;

    setCheckoutSourcePDP({ source: CheckoutSource.PdpTable });
    this.context.ctaCallback({
      entity: app,
      entityType: Constants.EntityType.App,
      ctaType: app.ctaTypes[0],
      skuId: null,
      actionModifier: Constants.Telemetry.ActionModifier.AppDetailCTAButtonFromTable,
      tileDataRequestId: null,
      hasLicense: this.props.doesTenantHasLicenseForApp,
      planId,
      ribbonKey,
    });
  };

  renderPricing() {
    const { app, billingCountryCode } = this.props;
    const { offerType, pricingInformation, futurePricesInformation, licenseManagement, entityId } = app;
    const billingCountry = getBillingCountryByCountryCode(billingCountryCode);

    if (offerType === OfferType.SaaSApp) {
      return (
        <SaasPricingTable
          app={app}
          billingCountry={billingCountry}
          shouldBlockPrices={this.shouldBlockPricing()}
          onGetInNowClicked={this.getItNowTableCallback}
        />
      );
    }

    return (
      <SimplePlanPricing
        pricing={pricingInformation}
        futurePrices={futurePricesInformation}
        billingCountry={billingCountry}
        isMicrosoftManaged={licenseManagement?.isMicrosoftManaged}
        shouldBlockPrices={this.shouldBlockPricing()}
        isOneTimePaymentOffer={isOneTimePaymentOffer(entityId)}
        locale={this.props.locale}
      />
    );
  }

  // TODO - move to utils
  getUsefulInfoMetadata(app: IAppDataItem): UsefulInfoMetadataColumns[] {
    const { entityId } = this.props;
    const oneTimePaymentOffer = isOneTimePaymentOffer(entityId);
    // colomn 1 - Useful Information
    const usefulInformaionCells: UsefulInfoMetadataCell[] = [];
    const supportCells: UsefulInfoMetadataCell[] = [];
    const moreLinksCells: UsefulInfoMetadataCell[] = [];
    const powerBIVisualCells: UsefulInfoMetadataCell[] = [];
    const metadata: UsefulInfoMetadataColumns[] = [];

    // publisher
    usefulInformaionCells.push({
      id: 'publisher',
      title: 'App_Publisher',
      cellItems: [
        {
          id: 'publisherItem',
          type: UsefulInfoMetadataCellItemType.Label,
          text: app.publisher,
        },
      ],
    });

    // MicrosoftCertified
    if (!DetailUtils.hideCertificateBadges()) {
      if (
        app.CertificationState === Constants.CertificationType.MicrosoftCertified ||
        app.CertificationState === Constants.CertificationType.SelfAttested
      ) {
        usefulInformaionCells.push({
          id: 'App_Certification',
          title: 'App_Certification',
          cellItems: [
            {
              id: 'M365ertificationDetails',
              type: UsefulInfoMetadataCellItemType.Link,
              text:
                app.CertificationState === Constants.CertificationType.MicrosoftCertified
                  ? 'App_CertificationLink'
                  : 'App_CertificationLinkSelfAttested',
              information: app.CertificationLink,
              entityId: app.entityId,
            } as UsefulInfoMetadataCellItemLink,
          ],
        });
      }
    }

    // pricing
    const isDetailedPricing = true;
    const pricingObject: IPricingElement = this.getStartingPriceElement(this.props.startingPrice, isDetailedPricing);
    if (pricingObject) {
      let cell: UsefulInfoMetadataCellItemLabel | UsefulInfoMetadataCellItemLink;
      if (pricingObject.type === Constants.ButtonType.Link) {
        cell = {
          information: pricingObject.href,
          id: 'SupportLink',
          text: this.context.locParams('StartingAtPrice', [pricingObject.value]),
          type: UsefulInfoMetadataCellItemType.Link,
          entityId: app.entityId,
          props: {
            target: '_self',
            accEnabled: true,
          },
        } as UsefulInfoMetadataCellItemLink;
      } else {
        cell = {
          id: 'AppVersionItem',
          type: UsefulInfoMetadataCellItemType.Label,
          text: pricingObject.value,
        } as UsefulInfoMetadataCellItemLabel;
      }
      usefulInformaionCells.push({
        id: 'Pricing',
        title: 'Pricing',
        cellItems: [cell],
      });
    }
    // aad or microsoft account
    const AADWorkAccount = this.renderAADWorkAccountMetaData();
    usefulInformaionCells.push({
      id: 'Accounts_Supported',
      title: 'Accounts_Supported',
      cellItems: [
        AADWorkAccount && {
          id: 'AADWorkAccount',
          type: UsefulInfoMetadataCellItemType.Label,
          text: AADWorkAccount,
        },
        isMSASupported(app.products) && {
          id: 'MSASupported',
          type: UsefulInfoMetadataCellItemType.Label,
          text: 'Microsoft_Account',
        },
      ],
    });

    // app version
    if (!oneTimePaymentOffer && DetailUtils.getDetailInformation(app, 'AppVersion')) {
      usefulInformaionCells.push({
        id: 'AppVersion',
        title: 'App_Version',
        cellItems: [
          {
            id: 'AppVersionItem',
            type: UsefulInfoMetadataCellItemType.Label,
            text: DetailUtils.getDetailInformation(app, 'AppVersion'),
          },
        ],
      });
    }

    // app updated
    if (DetailUtils.getDetailInformation(app, 'ReleaseDate')) {
      usefulInformaionCells.push({
        id: 'ReleaseDate',
        title: 'App_Updated',
        cellItems: [
          {
            id: 'AppReleaseDateItem',
            type: UsefulInfoMetadataCellItemType.Label,
            text: this.context.locDateString(DetailUtils.getDetailInformation(app, 'ReleaseDate')),
          },
        ],
      });
    }

    if (!oneTimePaymentOffer && this.props.app.products && Object.keys(this.props.app.products).length > 0) {
      // products
      const products = this.getProductFilterListElements(
        Constants.filterMaps.products,
        Constants.filterTileTypes.product
      ).map<UsefulInfoMetadataCellItemFilter>((product: IFilterElement) => {
        return {
          id: product.title,
          type: UsefulInfoMetadataCellItemType.Filter,
          text: product.locKey,
          entityId: app.entityId,
          filter: product,
          isEmbedded: false,
          nationalCloud: this.props.nationalCloud,
        };
      });

      usefulInformaionCells.push({
        id: 'FilterType_Products',
        title: 'FilterType_Products',
        cellItems: products,
      });
    }

    // categories
    const primaryProductFilter = getProductFilter(app.primaryProduct);
    const categories = this.getFilterListInfoElements({
      filterType: Constants.filterTileTypes.category,
      filterItem: Constants.filterMaps.categories,
      details: app.categoriesDetails,
      primaryProductFilter,
    });
    if (categories?.length) {
      usefulInformaionCells.push({
        id: 'Categories',
        title: 'App_Categories',
        cellItems: categories,
      });
    }

    // industries
    const industries = this.getFilterListInfoElements({
      filterType: Constants.filterTileTypes.industry,
      filterItem: Constants.filterMaps.industries,
      details: app.industriesDetails,
    });
    if (industries?.length) {
      usefulInformaionCells.push({
        id: 'Industries',
        title: 'App_Industries',
        cellItems: industries,
      });
    }

    // Work with - Products supported
    const worksWith = this.getOfficeWorksWithList('WorksWith');
    if (worksWith && worksWith.length) {
      const products = worksWith.map((productName: string) => {
        return {
          id: 'WorksWith' + productName,
          type: UsefulInfoMetadataCellItemType.Label,
          text: productName,
        };
      });
      if (products.length) {
        usefulInformaionCells.push({
          id: 'WorksWith',
          title: 'App_WorksWith',
          cellItems: products,
        });
      }
    }

    // ### Support section ###
    // support
    if (
      DetailUtils.getDetailInformation(this.props.app, 'SupportLink') ||
      DetailUtils.getDetailInformation(this.props.app, 'HelpLink')
    ) {
      const supportitems: UsefulInfoMetadataCellItemLink[] = [];
      if (DetailUtils.getDetailInformation(this.props.app, 'SupportLink')) {
        supportitems.push({
          information: DetailUtils.getDetailInformation(this.props.app, 'SupportLink'),
          id: 'SupportLink',
          text: 'App_Support',
          type: UsefulInfoMetadataCellItemType.Link,
          entityId: app.entityId,
        });
      }
      if (DetailUtils.getDetailInformation(this.props.app, 'HelpLink')) {
        supportitems.push({
          information: DetailUtils.getDetailInformation(this.props.app, 'HelpLink'),
          id: 'HelpLink',
          text: 'App_Help',
          type: UsefulInfoMetadataCellItemType.Link,
          entityId: app.entityId,
        });
      }

      supportCells.push({
        id: 'supportLinks',
        title: null,
        cellItems: supportitems,
      });
    }

    // Legal
    if (DetailUtils.getDetailInformation(app, 'PrivacyPolicyUrl') || app.licenseTermsUrl) {
      const cells: UsefulInfoMetadataCellItemLink[] = [
        ...this.getTermsOfUseLinks().map<UsefulInfoMetadataCellItemLink>((link) => {
          return {
            information: link.information,
            id: link.id,
            text: link.locString,
            type: UsefulInfoMetadataCellItemType.Link,
            entityId: app.entityId,
          };
        }),
        {
          information: DetailUtils.getDetailInformation(this.props.app, 'PrivacyPolicyUrl'),
          id: 'PrivacyPolicyUrl',
          text: 'App_PrivacyPolicy',
          type: UsefulInfoMetadataCellItemType.Link,
          entityId: app.entityId,
        },
      ];

      usefulInformaionCells.push({
        id: 'App_Legal',
        title: 'App_Legal',
        cellItems: cells,
      });
    }

    // more links
    const documents: ICollateralDocuments[] = this.props.app.detailInformation.CollateralDocuments;
    if (documents && documents.length > 0) {
      const documentsCells = documents.map<UsefulInfoMetadataCellItemLink>((doc: ICollateralDocuments) => {
        return {
          information: doc.DocumentUri,
          id: 'LearnMore',
          text: getWorkBreakEnabledString(doc.DocumentName),
          type: UsefulInfoMetadataCellItemType.Link,
          entityId: app.entityId,
        };
      });

      moreLinksCells.push({
        id: 'App_LearnMoreTitle',
        title: '',
        cellItems: documentsCells,
      });
    }

    // ### Power BI Visual Instructions
    if (isPowerBIVisuals(getPrimaryProductUrl(this.props.app.primaryProduct)) && isMigratedPowerBIVisual(this.props.app)) {
      const cells: (UsefulInfoMetadataCellItemLabel | UsefulInfoMetadataCellItemInternalLink)[] = [
        {
          id: 'PowerBIVisual_Instructions',
          type: UsefulInfoMetadataCellItemType.Label,
          text: 'PowerBIVisual_DetailsAndSupport_Text',
        },
        {
          type: UsefulInfoMetadataCellItemType.InternalLink,
          information: '',
          text: 'PowerBIVisual_Instructions',
          onClick: () => this.openInstructionsModal(),
        } as UsefulInfoMetadataCellItemInternalLink,
      ];
      powerBIVisualCells.push({
        id: 'PowerBIVisual_Instructions',
        title: '',
        cellItems: cells,
      });
    }

    // create root metadata item
    if (usefulInformaionCells.length) {
      metadata.push({
        id: 'UsefulInformationDetails',
        title: 'AppDetail_UsefulInformaionDetails',
        titleFallback: 'Details',
        cells: usefulInformaionCells,
      });
    }
    if (supportCells.length) {
      metadata.push({
        id: 'Support',
        title: 'AppDetail_Support',
        cells: supportCells,
      });
    }
    if (moreLinksCells.length) {
      metadata.push({
        id: 'App_Links',
        title: 'App_Links',
        titleFallback: 'Links',
        cells: moreLinksCells,
      });
    }
    if (powerBIVisualCells.length) {
      metadata.push({
        id: 'PowerBIVisual_DetailsAndSupport',
        title: this.context.loc('PowerBIVisual_DetailsAndSupport_Title'),
        cells: powerBIVisualCells,
      });
    }

    return metadata;
  }

  renderStickyCard(tabsProps: ITabsProps, tabsChilds: TabChild[]) {
    return (
      <div ref={this.stickyCardRef}>
        <StickyCard
          title={this.props.app.title}
          logoURL={this.props.app.iconURL ? this.props.app.iconURL : ''}
          tabsProps={tabsProps}
          tabsChilds={tabsChilds}
        >
          <div className="stickyCardPricing">{this.bulidStartingPriceElement({ pricingLinkClassName: 'light' })}</div>
          <div className="stickyCardButton">{this.getCTAElement()}</div>
          <div className="stickyCardFavourite">{this.getUserFavouriteButton()}</div>
        </StickyCard>
      </div>
    );
  }

  getChildContext() {
    return {
      getAppDetailsStickyCardHeight: (): number => {
        return this.stickyCardRef?.current?.clientHeight;
      },
    };
  }

  handleScroll() {
    const curWindow = getWindow();
    const isPageScrolled = StickyCard.handleScroll(curWindow, AppDetails.pdpTabsId);
    if (this.state.isPageScrolled !== isPageScrolled) {
      this.setState({ isPageScrolled });
    }
  }

  renderTabContent(tabChild: TabChild) {
    const { app, billingCountryCode } = this.props;

    return (
      <Stack className={contentStyles.tabsContainer}>
        <Stack.Item
          grow
          className={classNames({ [contentStyles.autoScrollTabContent]: tabChild.name === Constants.Tabs.PlansAndPrice })}
        >
          {tabChild.getContent()}
        </Stack.Item>
        {DetailUtils.shouldShowUserTerms({ app, billingCountryCode }) &&
          this.props.isHydrated &&
          tabChild.name !== Constants.Tabs.Reviews && (
            <Stack.Item tokens={consentDisclaimerTokens} className={contentStyles.userTermsContainer}>
              <UserTerms app={app} />
            </Stack.Item>
          )}
      </Stack>
    );
  }

  renderImpl() {
    const { uiRole, entityId, userInfo, app } = this.props;

    const isOneTimePayment = isOneTimePaymentOffer(entityId);
    if (this.props.app) {
      const tabsProps: ITabsProps = this.getTabsProps();
      const tabsChilds: TabChild[] = this.getTabChildren();

      return (
        <div className="ASdesktopAppDetails" itemScope itemType="https://schema.org/SoftwareApplication" role="main">
          <div className="spza_detailContainer spza_partnerDetailContainer">
            {this.state.isPageScrolled ? this.renderStickyCard(tabsProps, tabsChilds) : null}
            <div className={contentStyles.pdpDetailsContainer}>
              {this.getBreadcrumbElement()}
              <div className="detailContent">
                <div className="deatilPageContent">
                  <Card
                    title={this.props.app.title}
                    subtitle={this.context.locParams('By_Publisher', [this.props.app.publisher])}
                    logoURL={DetailUtils.getDetailInformation(this.props.app, 'LargeIconUri')}
                  >
                    {this.props.isHydrated && !isOneTimePayment && this.props.app.products != null
                      ? this.buildFilterElements(
                          this.getProductFilterListElements(Constants.filterMaps.products, Constants.filterTileTypes.product)
                        )
                      : null}
                    <Stack className={contentStyles.bagdeContainer} tokens={{ childrenGap: 5 }} wrap horizontal>
                      {isPrivateOffer(this.props.app.appType) && this.renderBadge(Constants.BadgeType.PrivateOffer)}
                      {this.isFreeTrial() && this.renderBadge(Constants.BadgeType.FreeTrial)}
                      {this.isMicrosoftCertified() && this.renderBadge(Constants.BadgeType.MicrosoftCertified)}
                      {isCertifiedSoftware(app) && this.renderBadge(Constants.BadgeType.CertifiedSoftware)}
                      {this.props.app.isSolutionMap && this.renderBadge(Constants.BadgeType.SolutionMap)}
                      {this.hasOfficeAwards() && this.renderBadge(Constants.BadgeType.OfficeAwards)}
                      {this.isPBICertified() && this.renderBadge(Constants.BadgeType.PBICertified)}
                      {this.isMSPApp() && this.renderBadge(Constants.BadgeType.AzureExpertsMSP)}
                      {app.AzureBenefitEligible &&
                        isMaccUser(userInfo) &&
                        this.renderBadge(Constants.BadgeType.AzureBenefitEligible)}
                    </Stack>
                    {this.renderRatings()}
                    {this.renderLinkedIn()}
                    <div className="container">
                      {this.props.isHydrated && this.bulidStartingPriceElement({ pricingLinkClassName: 'dark' })}
                      {this.getCTAElement()}
                      {isPowerBIVisuals(getPrimaryProductUrl(this.props.app.primaryProduct)) &&
                        isMigratedPowerBIVisual(this.props.app) &&
                        this.getDownloadSampleButton()}
                      {isPowerBIVisuals(getPrimaryProductUrl(this.props.app.primaryProduct)) &&
                        isMigratedPowerBIVisual(this.props.app) &&
                        this.getInstructionsButton()}
                      {this.getUserFavouriteButton()}
                    </div>
                    <MicrosoftManagedIndicatorWrapper
                      app={this.props.app}
                      isAvailableInRegion={isAvailableInThisRegion(this.props)}
                      uiRole={uiRole}
                      renderIndicator={this.getMicrosoftManagedIndicator.bind(this)}
                    />
                    {!isAvailableInThisRegion(this.props) && this.getNotAvailableInRegionText()}
                  </Card>
                  {this.props.isHydrated && <>{this.renderLinkedItems(this.props.app, this.props.nationalCloud)}</>}
                  {!this.props.app.detailInformation ? null : (
                    <div id={AppDetails.pdpTabsId} className={AppDetails.pdpTabsClass}>
                      <Tabs
                        defaultTab={tabsProps.defaultTab}
                        ownerType={tabsProps.ownerType}
                        ownerId={tabsProps.ownerId}
                        changeTabCallback={tabsProps.changeTabCallback}
                        getTabHref={tabsProps.getTabHref}
                      >
                        {tabsChilds.map((tabChild) => (
                          <Tab key={tabChild.name} title={tabChild.title} name={tabChild.name} textBadge={tabChild.textBadge}>
                            {this.renderTabContent(tabChild)}
                          </Tab>
                        ))}
                      </Tabs>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (!userInfo.privateOffers.loading && !userInfo.privateOffers.dataList.find((offer) => offer.entityId === entityId)) {
      return this.renderEmptyPrivatePage();
    } else {
      // there is no app
      return <Spinner className="nullAppContainer">{this.context.loc('AppDetail_LoadingText', 'Loading...')}</Spinner>;
    }
  }
}

(AppDetails as any).childContextTypes = {
  getAppDetailsStickyCardHeight: PropTypes.func,
};

(AppDetails as any).contextTypes = {
  loc: PropTypes.func,
  locDateString: PropTypes.func,
  locParams: PropTypes.func,
  buildHref: PropTypes.func,
  ctaCallback: PropTypes.func,
  contactCallback: PropTypes.func,
  renderErrorModal: PropTypes.func,
  openTileCallback: PropTypes.func,
};
