import React, { useState, FunctionComponent, useEffect } from 'react';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  Icon,
  Link,
  mergeStyleSets,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
} from '@fluentui/react';
import * as PropTypes from 'prop-types';
import { ILocContext } from '@shared/interfaces/context';
import {
  IAppEntityReviewCommentModalActionPayload,
  IAppsReviewCommentContentOperationPayload,
  IReviewCommentOperationResponse,
} from '@shared/interfaces/reviews/comments';
import { Constants } from '@shared/utils/constants';
import { getReviewCommentsModalDialogProps, launchTelemetry } from '@shared/utils/reviewsUtils';
import { IUserDataState } from '@src/State';
import { logger } from '@src/logger';
import { SharedColors } from '@fluentui/theme';

const ReviewCommentModalStyles = mergeStyleSets({
  infoIcon: {
    color: SharedColors.red20,
    fontSize: '16px',
  },
  loader: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2200,
  },
});

export interface ReviewCommentModalOnDeleteArgs {
  offerId: string;
  reviewId: string;
  commentId: string;
  isNewlyAddedComment: boolean;
}
export interface ReviewCommentModalOnEditArgs {
  offerId: string;
  reviewId: string;
  commentId: string;
  commentContent: string;
  isNewlyAddedComment: boolean;
}

export interface IReviewCommentModalProps {
  userInfo: IUserDataState;
  payload: IAppEntityReviewCommentModalActionPayload;
  dismissModal: () => void;
  onDeleteComment: (args: ReviewCommentModalOnDeleteArgs) => Promise<IReviewCommentOperationResponse>;
  onEditComment: (args: ReviewCommentModalOnEditArgs) => Promise<IReviewCommentOperationResponse>;
}

export function ReviewCommentModal(
  { payload, onDeleteComment, onEditComment, dismissModal }: IReviewCommentModalProps,
  context: ILocContext
) {
  const { modalMode: initialModalMode } = payload;
  const [modalMode, setModalMode] = useState(initialModalMode);

  useEffect(() => {
    // Update the current modal mode state if the prop value changes
    setModalMode(initialModalMode);
  }, [initialModalMode]);

  const dismissModalWithTelemetry = () => {
    launchTelemetry(Constants.Telemetry.Action.Close, Constants.Telemetry.ActionModifier.ReviewItemCommentDialog, {
      payload: payload.actionPayload,
    });
    logger.info(
      JSON.stringify({
        payload: payload.actionPayload,
      }),
      {
        action: Constants.Telemetry.Action.Close,
        actionModifier: Constants.Telemetry.ActionModifier.ReviewItemCommentDialog,
      }
    );
    dismissModal();
  };

  const onInnerDialogDelete = async () => {
    try {
      launchTelemetry(Constants.Telemetry.Action.Submitting, Constants.Telemetry.ActionModifier.ReviewItemCommentDialogDelete, {
        payload: payload.actionPayload,
        commentId: payload.actionPayload.commentId,
        appId: payload.actionPayload.offerId,
        reviewId: payload.actionPayload.reviewId,
      });
      logger.info(
        JSON.stringify({
          payload: payload.actionPayload,
          commentId: payload.actionPayload.commentId,
          appId: payload.actionPayload.offerId,
          reviewId: payload.actionPayload.reviewId,
        }),
        {
          action: Constants.Telemetry.Action.Submitting,
          actionModifier: Constants.Telemetry.ActionModifier.ReviewItemCommentDialogDelete,
        }
      );
      setModalMode(Constants.Reviews.ReviewCommentModalMode.Loading);
      const { offerId, reviewId, commentId, isNewlyAddedComment } = payload.actionPayload;
      await onDeleteComment({
        offerId,
        reviewId,
        commentId,
        isNewlyAddedComment,
      });
      dismissModalWithTelemetry();
      launchTelemetry(Constants.Telemetry.Action.Submitted, Constants.Telemetry.ActionModifier.ReviewItemCommentDialogDelete, {
        payload: payload.actionPayload,
        commentId: payload.actionPayload.commentId,
        appId: payload.actionPayload.offerId,
        reviewId: payload.actionPayload.reviewId,
      });
      logger.info(
        JSON.stringify({
          payload: payload.actionPayload,
          commentId: payload.actionPayload.commentId,
          appId: payload.actionPayload.offerId,
          reviewId: payload.actionPayload.reviewId,
        }),
        {
          action: Constants.Telemetry.Action.Submitted,
          actionModifier: Constants.Telemetry.ActionModifier.ReviewItemCommentDialogDelete,
        }
      );
    } catch (e) {
      setModalMode(Constants.Reviews.ReviewCommentModalMode.DeleteError);
      launchTelemetry(Constants.Telemetry.Action.SubmitError, Constants.Telemetry.ActionModifier.ReviewItemCommentDialogDelete, {
        payload: payload.actionPayload,
        commentId: payload.actionPayload?.commentId,
        appId: payload.actionPayload?.offerId,
        reviewId: payload.actionPayload?.reviewId,
        error: `Failed deleting a review comment from the review comment modal, error: ${e}`,
      });
      logger.error(
        JSON.stringify({
          payload: payload.actionPayload,
          commentId: payload.actionPayload?.commentId,
          appId: payload.actionPayload?.offerId,
          reviewId: payload.actionPayload?.reviewId,
          error: `Failed deleting a review comment from the review comment modal, error: ${e}`,
        }),
        {
          action: Constants.Telemetry.Action.SubmitError,
          actionModifier: Constants.Telemetry.ActionModifier.ReviewItemCommentDialogDelete,
        }
      );
    }
  };

  const onInnerDialogUpdate = async () => {
    try {
      launchTelemetry(Constants.Telemetry.Action.Submitting, Constants.Telemetry.ActionModifier.ReviewItemCommentDialogUpdate, {
        payload: payload.actionPayload,
        commentId: payload.actionPayload.commentId,
        appId: payload.actionPayload.offerId,
        reviewId: payload.actionPayload.reviewId,
      });
      logger.info(
        JSON.stringify({
          payload: payload.actionPayload,
          commentId: payload.actionPayload.commentId,
          appId: payload.actionPayload.offerId,
          reviewId: payload.actionPayload.reviewId,
        }),
        {
          action: Constants.Telemetry.Action.Submitting,
          actionModifier: Constants.Telemetry.ActionModifier.ReviewItemCommentDialogUpdate,
        }
      );
      setModalMode(Constants.Reviews.ReviewCommentModalMode.Loading);
      const { offerId, reviewId, commentId, commentContent, isNewlyAddedComment } =
        payload.actionPayload as IAppsReviewCommentContentOperationPayload;
      await onEditComment({
        offerId,
        reviewId,
        commentId,
        commentContent,
        isNewlyAddedComment,
      });
      dismissModalWithTelemetry();
      launchTelemetry(Constants.Telemetry.Action.Submitted, Constants.Telemetry.ActionModifier.ReviewItemCommentDialogUpdate, {
        payload: payload.actionPayload,
        commentId: payload.actionPayload.commentId,
        appId: payload.actionPayload.offerId,
        reviewId: payload.actionPayload.reviewId,
      });
      logger.info(
        JSON.stringify({
          payload: payload.actionPayload,
          commentId: payload.actionPayload.commentId,
          appId: payload.actionPayload.offerId,
          reviewId: payload.actionPayload.reviewId,
        }),
        {
          action: Constants.Telemetry.Action.Submitted,
          actionModifier: Constants.Telemetry.ActionModifier.ReviewItemCommentDialogUpdate,
        }
      );
    } catch (e) {
      setModalMode(Constants.Reviews.ReviewCommentModalMode.UpdateError);
      launchTelemetry(Constants.Telemetry.Action.SubmitError, Constants.Telemetry.ActionModifier.ReviewItemCommentDialogUpdate, {
        payload: payload.actionPayload,
        commentId: payload.actionPayload?.commentId,
        appId: payload.actionPayload?.offerId,
        reviewId: payload.actionPayload?.reviewId,
        error: `Failed updating a review comment from the review comment modal, error: ${e}`,
      });
      logger.error(
        JSON.stringify({
          payload: payload.actionPayload,
          commentId: payload.actionPayload?.commentId,
          appId: payload.actionPayload?.offerId,
          reviewId: payload.actionPayload?.reviewId,
          error: `Failed updating a review comment from the review comment modal, error: ${e}`,
        }),
        {
          action: Constants.Telemetry.Action.SubmitError,
          actionModifier: Constants.Telemetry.ActionModifier.ReviewItemCommentDialogUpdate,
        }
      );
    }
  };

  const innerDialogProps = {
    onDelete: onInnerDialogDelete,
    onUpdate: onInnerDialogUpdate,
    dismissModal: dismissModalWithTelemetry,
    context,
    payload,
  };

  switch (modalMode) {
    case Constants.Reviews.ReviewCommentModalMode.ConfirmUpdate:
      return <ReviewCommentModalConfirmUpdateDialog {...innerDialogProps} />;
    case Constants.Reviews.ReviewCommentModalMode.ConfirmDelete:
      return <ReviewCommentModalConfirmDeleteDialog {...innerDialogProps} />;
    case Constants.Reviews.ReviewCommentModalMode.UpdateError:
      return <ReviewCommentModalUpdateErrorDialog {...innerDialogProps} />;
    case Constants.Reviews.ReviewCommentModalMode.DeleteError:
      return <ReviewCommentModalDeleteErrorDialog {...innerDialogProps} />;
    case Constants.Reviews.ReviewCommentModalMode.ContentViolation:
      return <ReviewCommentModalContentViolationDialog {...innerDialogProps} />;
    case Constants.Reviews.ReviewCommentModalMode.CreateError:
      return <ReviewCommentModalCreateErrorDialog {...innerDialogProps} />;
    default:
      return (
        <Spinner
          className={ReviewCommentModalStyles.loader}
          size={SpinnerSize.large}
          label={context.loc('AppDetail_LoadingText', 'Loading...')}
          ariaLive="assertive"
        />
      );
  }
}

(ReviewCommentModal as FunctionComponent).contextTypes = {
  loc: PropTypes.func,
};

interface ReviewCommentModalBaseDialogProps {
  context: ILocContext;
  onDelete: () => Promise<void>;
  onUpdate: () => Promise<void>;
  dismissModal: () => void;
  payload: IAppEntityReviewCommentModalActionPayload;
}

export function ReviewCommentModalConfirmUpdateDialog({
  context,
  onUpdate,
  dismissModal,
  payload,
}: ReviewCommentModalBaseDialogProps) {
  const { modalProps, dialogContentProps, maxWidth } = getReviewCommentsModalDialogProps(
    context.loc('ReviewCommentActionModal_ConfirmUpdate_Title', 'Update your comment?')
  );

  return (
    <Dialog
      hidden={!payload.showModal}
      onDismiss={dismissModal}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
      maxWidth={maxWidth}
    >
      <Text>{context.loc('ReviewCommentActionModal_ConfirmUpdate_Description', 'Your comment will be updated.')}</Text>
      <DialogFooter>
        <PrimaryButton
          onClick={onUpdate}
          text={context.loc('ReviewCommentActionModal_ConfirmUpdate_Submit_Button_Label', 'Update')}
        />
        <DefaultButton
          onClick={dismissModal}
          text={context.loc('ReviewCommentActionModal_ConfirmUpdate_Cancel_Button_Label', 'Discard')}
        />
      </DialogFooter>
    </Dialog>
  );
}

export function ReviewCommentModalConfirmDeleteDialog({
  context,
  onDelete,
  dismissModal,
  payload,
}: ReviewCommentModalBaseDialogProps) {
  const { modalProps, dialogContentProps, maxWidth } = getReviewCommentsModalDialogProps(
    context.loc('ReviewCommentActionModal_ConfirmDelete_Title', 'Delete your comment?')
  );

  return (
    <Dialog
      hidden={!payload.showModal}
      onDismiss={dismissModal}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
      maxWidth={maxWidth}
    >
      <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center">
        <Text>
          {context.loc(
            'ReviewCommentActionModal_ConfirmDelete_Description',
            "It won't appear in the reviews feed and won’t be available any longer."
          )}
        </Text>
      </Stack>
      <DialogFooter>
        <PrimaryButton
          onClick={onDelete}
          text={context.loc('ReviewCommentActionModal_ConfirmDelete_Submit_Button_Label', 'Delete')}
        />
        <DefaultButton
          onClick={dismissModal}
          text={context.loc('ReviewCommentActionModal_ConfirmDelete_Cancel_Button_Label', 'Cancel')}
        />
      </DialogFooter>
    </Dialog>
  );
}

export function ReviewCommentModalUpdateErrorDialog({ context, dismissModal, payload }: ReviewCommentModalBaseDialogProps) {
  const { modalProps, dialogContentProps, maxWidth } = getReviewCommentsModalDialogProps(
    context.loc('ReviewCommentActionModal_UpdateError_Title', "Couldn't update your comment")
  );

  return (
    <Dialog
      hidden={!payload.showModal}
      onDismiss={dismissModal}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
      maxWidth={maxWidth}
    >
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Icon iconName="info" className={ReviewCommentModalStyles.infoIcon} />
        <Text>
          {context.loc(
            'ReviewCommentActionModal_UpdateError_Description',
            'There was a problem updating your comment. Please try again.'
          )}
        </Text>
      </Stack>
      <DialogFooter>
        <PrimaryButton
          onClick={dismissModal}
          text={context.loc('ReviewCommentActionModal_UpdateError_Cancel_Button_Label', 'Close')}
        />
      </DialogFooter>
    </Dialog>
  );
}

export function ReviewCommentModalDeleteErrorDialog({ context, dismissModal, payload }: ReviewCommentModalBaseDialogProps) {
  const { modalProps, dialogContentProps, maxWidth } = getReviewCommentsModalDialogProps(
    context.loc('ReviewCommentActionModal_DeleteError_Title', "Couldn't delete your comment")
  );

  return (
    <Dialog
      hidden={!payload.showModal}
      onDismiss={dismissModal}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
      maxWidth={maxWidth}
    >
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Icon iconName="info" className={ReviewCommentModalStyles.infoIcon} />
        <Text>
          {context.loc(
            'ReviewCommentActionModal_DeleteError_Description',
            'There was a problem deleting your comment. Please try again.'
          )}
        </Text>
      </Stack>
      <DialogFooter>
        <PrimaryButton
          onClick={dismissModal}
          text={context.loc('ReviewCommentActionModal_DeleteError_Cancel_Button_Label', 'Close')}
        />
      </DialogFooter>
    </Dialog>
  );
}

export function ReviewCommentModalContentViolationDialog({ context, dismissModal, payload }: ReviewCommentModalBaseDialogProps) {
  const { modalProps, dialogContentProps, maxWidth } = getReviewCommentsModalDialogProps(
    context.loc('ReviewCommentActionModal_ContentViolation_Title', "Your comment wasn't published")
  );

  return (
    <Dialog
      hidden={!payload.showModal}
      onDismiss={dismissModal}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
      maxWidth={maxWidth}
    >
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Icon iconName="info" className={ReviewCommentModalStyles.infoIcon} />
        <Text>
          {context.loc(
            'ReviewCommentActionModal_ContentViolation_Description',
            "We couldn't publish your comment because we detected some offensive content which violates Microsoft terms."
          )}{' '}
          <Link target="_blank" href={Constants.MicrosoftRatingReviewPolicy}>
            {context.loc(
              'ReviewCommentActionModal_ContentViolation_Policy_Link_Label',
              'View Microsoft AppSource review policies'
            )}
          </Link>
        </Text>
      </Stack>
      <DialogFooter>
        <PrimaryButton
          onClick={dismissModal}
          text={context.loc('ReviewCommentActionModal_ContentViolation_Cancel_Button_Label', 'Close')}
        />
      </DialogFooter>
    </Dialog>
  );
}

export function ReviewCommentModalCreateErrorDialog({ context, dismissModal, payload }: ReviewCommentModalBaseDialogProps) {
  const { modalProps, dialogContentProps, maxWidth } = getReviewCommentsModalDialogProps(
    context.loc('ReviewCommentActionModal_CreateError_Title', "Couldn't publish your comment")
  );

  return (
    <Dialog
      hidden={!payload.showModal}
      onDismiss={dismissModal}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
      maxWidth={maxWidth}
    >
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Icon iconName="info" className={ReviewCommentModalStyles.infoIcon} />
        <Text>
          {context.loc(
            'ReviewCommentActionModal_CreateError_Description',
            'There was a problem publishing your comment. Please try again.'
          )}
        </Text>
      </Stack>
      <DialogFooter>
        <PrimaryButton
          onClick={dismissModal}
          text={context.loc('ReviewCommentActionModal_CreateError_Cancel_Button_Label', 'Close')}
        />
      </DialogFooter>
    </Dialog>
  );
}
