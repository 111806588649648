import * as httpProtocol from './httpProtocol';
import {
  IURLQuery,
  IAppDataItemBasicData,
  FilterDataResults,
  ITileData,
  IAppDataItem,
  ICuratedSections,
  ICuratedSectionItem,
  IFuturePrices,
  ICreauteUiDefinition,
  IAppsPricings,
  ICloudsIndustry,
  ICommonDataMap,
} from '@shared/Models';
import { Constants, ServerQueryOptions } from '@shared/utils/constants';
import { getAppConfig } from '@shared/services/init/appConfig';
import { logError } from '@shared/utils/httpClientUtil';
import { Service } from '@shared/serviceViewModel';
import { IRelatedItems } from '@src/State';

const option: httpProtocol.IHttpOption = {
  requireCorrelationId: true,
  stringifyPostData: false,
  authenticationType: httpProtocol.AuthenticationTypes.Unauthenticated,
};

export interface ExtendableRequest<T> {
  request: () => httpProtocol.IHttpRequest;
  data$: Promise<T>;
}

export interface IGetTileDataParams {
  flightCodes: string;
  embedHost: string;
  billingRegion: string;
  requestId: string;
  entityType: Constants.TileDataEntityType;
  urlQuery: IURLQuery;
  suggestionsRequestId?: string;
}

export interface IGetTileDataParamsLog extends IGetTileDataParams {
  origin: string | null;
}

export function getTileData({
  flightCodes,
  embedHost,
  billingRegion,
  requestId,
  entityType,
  urlQuery,
}: IGetTileDataParams): ExtendableRequest<ITileData> {
  const endpoint = Constants.Endpoints.View.TileData;

  let _request: httpProtocol.IHttpRequest;

  const tileDataOption: httpProtocol.IHttpOption = {
    ...option,
    requestId,
    requestCallback: (request) => (_request = request),
  };

  const get = httpProtocol.get(endpoint, tileDataOption);

  if (flightCodes) {
    get.addQueryEntry(Constants.QueryStrings.flightCodes, flightCodes);
  }

  if (embedHost) {
    get.addQueryEntry(Constants.QueryStrings.embedHost, embedHost);
  }

  if (billingRegion) {
    get.addQueryEntry(Constants.QueryStrings.billingRegion, billingRegion);
  }

  if (entityType) {
    get.addQueryEntry(Constants.QueryStrings.entityType, entityType);
  }

  if (urlQuery) {
    for (const key in urlQuery) {
      if (Object.prototype.hasOwnProperty.call(urlQuery, key)) {
        get.addQueryEntry(key, urlQuery[`${key}`]);
      }
    }
  }

  const data$ = get.request().catch((error: any) => {
    logError('getTileData', error);
    return Promise.reject(error);
  });

  return {
    data$,
    request: () => _request,
  };
}

export async function getAppPricing({
  entityId,
  isEmbedded,
  flightCodes,
  billingRegion,
  urlQuery = {},
}: {
  entityId: string;
  isEmbedded: boolean;
  flightCodes: string;
  billingRegion: string;
  urlQuery: IURLQuery;
}) {
  let endpoint = `${Constants.Endpoints.View.AppPricing}/${entityId}`;

  if (billingRegion) {
    endpoint += '/' + billingRegion;
  }

  const get = httpProtocol.get(endpoint, option);

  if (isEmbedded) {
    get.addQueryEntry(Constants.QueryStrings.isEmbedded, true.toString());
  }

  if (flightCodes) {
    get.addQueryEntry(Constants.QueryStrings.flightCodes, flightCodes);
  }

  for (const key in urlQuery) {
    get.addQueryEntry(key, urlQuery[`${key}`]);
  }

  try {
    return await get.request();
  } catch (error) {
    logError('getAppPricing', error);
    throw error;
  }
}

export async function getFuturePrices({
  entityId,
  isEmbedded,
  billingRegion,
  flightCodes,
}: {
  entityId: string;
  isEmbedded: boolean;
  flightCodes: string;
  billingRegion: string;
}): Promise<IFuturePrices> {
  let endpoint = `${Constants.Endpoints.View.AppFuturePricing}/${entityId}`;

  if (billingRegion) {
    endpoint += '/' + billingRegion;
  }

  const get = httpProtocol.get(endpoint, option);

  if (isEmbedded) {
    get.addQueryEntry(Constants.QueryStrings.isEmbedded, true.toString());
  }

  if (flightCodes) {
    get.addQueryEntry(Constants.QueryStrings.flightCodes, flightCodes);
  }

  try {
    return await get.request();
  } catch (error) {
    logError('getFuturePrices', error);
    throw error;
  }
}

export async function getApp({
  entityId,
  flightCodes,
}: {
  entityId: string;
  flightCodes: string;
}): Promise<IAppDataItemBasicData> {
  const endpoint = `/view/app/${entityId}`;

  const get = httpProtocol.get(endpoint, option);

  if (flightCodes) {
    get.addQueryEntry(Constants.QueryStrings.flightCodes, flightCodes);
  }

  try {
    return await get.request();
  } catch (error) {
    logError('getApp', error);
    return await Promise.reject(error);
  }
}

export function getAppRelatedAppsItems(entityId: string, embedHost: string): Promise<IRelatedItems> {
  const endpoint = `/view/app/${entityId}/relatedApps`;

  const get = httpProtocol.get(endpoint, option);

  if (embedHost) {
    get.addQueryEntry(Constants.QueryStrings.embedHost, embedHost);
  }

  return get
    .addQueryEntry(Constants.QueryStrings.version, getAppConfig(Constants.apiVersion))
    .request()
    .catch((error: any) => {
      logError('getAppRelatedAppsItems', error);
      return Promise.reject(error);
    });
}

export function getFilteredApps({
  billingRegion,
  flightCodes,
  embedHost,
  options: { includeTestProducts = false, isConverged = false } = {},
  urlQuery,
  searchActionType = Constants.Search.ActionType.Search,
  requestId = '',
}: {
  billingRegion?: string;
  flightCodes?: string;
  embedHost?: string;
  options?: ServerQueryOptions;
  urlQuery: IURLQuery;
  searchActionType?: string;
  requestId: string;
}): Promise<FilterDataResults<IAppDataItem>> {
  const endpoint = '/view/filteredapps';

  const get = httpProtocol.get(endpoint, option);
  get.setRetry(2);

  if (flightCodes) {
    get.addQueryEntry(Constants.QueryStrings.flightCodes, flightCodes);
  }

  if (includeTestProducts) {
    get.addQueryEntry(Constants.QueryStrings.includeTestProducts, 'true');
  }

  if (isConverged) {
    get.addQueryEntry(Constants.QueryStrings.isConverged, 'true');
  }

  if (embedHost) {
    get.addQueryEntry(Constants.QueryStrings.embedHost, embedHost);
  }

  if (billingRegion) {
    get.addQueryEntry(Constants.QueryStrings.billingRegion, billingRegion);
  }

  for (const key in urlQuery) {
    get.addQueryEntry(key, urlQuery[`${key}`]);
  }

  get.addHeader({ SearchActionType: searchActionType });

  return get
    .addQueryEntry(Constants.QueryStrings.version, getAppConfig(Constants.apiVersion))
    .setHeader(Constants.Headers.CatalogApiClientRequestId, requestId)
    .request()
    .catch((error: any) => {
      logError('getFilteredApps', error);
      return Promise.reject(error);
    });
}

export function getFilteredCloudsIndustry({
  urlQuery,
  requestId,
  searchActionType = Constants.Search.ActionType.Search,
}: {
  urlQuery: IURLQuery;
  requestId: string;
  searchActionType?: string;
}): Promise<FilterDataResults<ICloudsIndustry>> {
  const queryParams = {
    [Constants.QueryStrings.version]: getAppConfig(Constants.apiVersion),
    ...urlQuery,
  };

  const get = httpProtocol.get(Constants.Endpoints.View.CloudsIndustryFiltered, { ...option, queryParams });
  get.setRetry(2);
  get.addHeader({ SearchActionType: searchActionType });

  return get
    .setHeader(Constants.Headers.CatalogApiClientRequestId, requestId)
    .request()
    .catch((error: any) => {
      logError('getFilteredCloudsIndustry', error);
      return Promise.reject(error);
    });
}

export async function getAllAppsPricing({
  billingRegion,
  urlQuery = {},
}: {
  billingRegion: string;
  urlQuery?: IURLQuery;
}): Promise<IAppsPricings> {
  let endpoint = Constants.Endpoints.View.AppPricing;

  if (billingRegion) {
    endpoint += '/' + billingRegion;
  }

  const get = httpProtocol.get(endpoint, option);

  for (const key in urlQuery) {
    get.addQueryEntry(key, urlQuery[`${key}`]);
  }

  try {
    return get.request();
  } catch (error) {
    logError('getAllAppsPricing', error);
    throw error;
  }
}

export function getServices(id = '', country = '', region = '', flightCode = '') {
  let endpoint = '';

  if (id) {
    endpoint += '/view/consultingservice/' + id;
  } else {
    endpoint += '/view/services';
  }

  const get = httpProtocol.get(endpoint, option);

  if (country) {
    get.addQueryEntry(Constants.QueryStrings.country, country);
  }

  if (region) {
    get.addQueryEntry(Constants.QueryStrings.region, region);
  }

  if (flightCode) {
    get.addQueryEntry(Constants.QueryStrings.flightCodes, flightCode);
  }

  return get.request().catch((error: any) => {
    logError('getServices', error);
    return Promise.reject(error);
  });
}

export function getServiceRelatedAppsItems(entityId: string, country: string, region: string) {
  const endpoint = `/view/consultingservice/${entityId}/relatedServices`;

  const get = httpProtocol.get(endpoint, option);

  if (country) {
    get.addQueryEntry(Constants.QueryStrings.country, country);
  }

  if (region) {
    get.addQueryEntry(Constants.QueryStrings.region, region);
  }

  return get.request().catch((error: any) => {
    logError('getServiceRelatedItems', error);
    return Promise.reject(error);
  });
}

export function getFilteredServices({
  urlQuery,
  searchActionType = Constants.Search.ActionType.Search,
  requestId,
  options: { includeTestProducts = false, isConverged = false } = {},
}: {
  urlQuery: IURLQuery;
  searchActionType?: Constants.Search.ActionType;
  requestId: string;
  options?: ServerQueryOptions;
}): Promise<FilterDataResults<Service>> {
  const endpoint = '/view/filteredservices';

  const get = httpProtocol.get(endpoint, option);
  get.setRetry(2);

  for (const key in urlQuery) {
    if (Object.prototype.hasOwnProperty.call(urlQuery, key)) {
      get.addQueryEntry(key, urlQuery[`${key}`]);
    }
  }

  if (includeTestProducts) {
    get.addQueryEntry(Constants.QueryStrings.includeTestProducts, 'true');
  }

  if (isConverged) {
    get.addQueryEntry(Constants.QueryStrings.isConverged, 'true');
  }

  get.addHeader({ SearchActionType: searchActionType });

  return get
    .setHeader(Constants.Headers.CatalogApiClientRequestId, requestId)
    .request()
    .catch((error: any) => {
      logError('getFilteredServices', error);
      return Promise.reject(error);
    });
}

export async function searchPartners(query: string, locale: string, partnersApiHost: string): Promise<any> {
  const searchOption: httpProtocol.IHttpOption = {
    clientType: 'webBrowser',
    authenticationType: httpProtocol.AuthenticationTypes.Unauthenticated,
  };
  const endpoint = `${partnersApiHost}/${Constants.partnersSearchApiRoute}`;
  try {
    return httpProtocol
      .get(endpoint, searchOption)
      .addQueryEntry(Constants.QueryStrings.searchLocale, locale)
      .addQueryEntry(Constants.QueryStrings.searchQuery, query)
      .request();
  } catch (error) {
    logError('searchPartners error', error);
    return Promise.reject(error);
  }
}

export function getAppsDataMap({ embedHost }: { embedHost: string }): Promise<ICommonDataMap> {
  const endpoint = '/view/appsdatamap';

  const get = httpProtocol.get(endpoint, option);

  if (embedHost) {
    get.addQueryEntry(Constants.QueryStrings.embedHost, embedHost);
  }

  return get
    .addQueryEntry(Constants.QueryStrings.version, getAppConfig(Constants.apiVersion))
    .request()
    .catch((error: any) => {
      logError('getAppsDataMap', error);
      return Promise.reject(error);
    });
}

export function getCloudsIndustryDataMap() {
  const get = httpProtocol.get(Constants.Endpoints.View.CloudsIndustryDataMap, option);

  return get
    .addQueryEntry(Constants.QueryStrings.version, getAppConfig(Constants.apiVersion))
    .request()
    .catch((error: any) => {
      logError('getCloudsIndustryDataMap', error);
      return Promise.reject(error);
    });
}

export function getServicesDataMap() {
  const endpoint = '/view/servicesdatamap';

  const get = httpProtocol.get(endpoint, option);

  return get.request().catch((error: any) => {
    logError('getServicesDataMap', error);
    return Promise.reject(error);
  });
}

export async function getCreateUiDefinition({
  createUiDefinitionEndpoint,
}: {
  createUiDefinitionEndpoint: string;
}): Promise<ICreauteUiDefinition> {
  const get = httpProtocol.get(createUiDefinitionEndpoint, option);

  return get.request().catch((error: any) => {
    logError('getCreateUiDefinition', error);
    return Promise.reject(error);
  });
}
