import { Constants } from '../../shared/utils/constants';
import { IAppDataItem, IAppDetailInformation } from '../../shared/Models';
import { DataMap, IProductValue } from '../../shared/utils/dataMapping';
import { getProductByUrlKey } from '@shared/utils/appUtils';

interface IPartnerApp {
  id: string;
  appActionType: string;
  applicationId: string;
  categories: number;
  details: IPartnerDetailInformation;
  enableLeadSharing: boolean;
  flightCodes: string[];
  handOffURL: string;
  iconBackgroundColor: string;
  industries: number;
  products: IProductValue;
  licenseTermsUrl: string;
  privateApp: boolean;
  publisher: string;
  shortDescription: string;
  smallIconUri: string;
  title: string;
}

interface IPartnerDocument {
  documentName: string;
  documentUri: string;
}

interface IPartnerVideo {
  thumbnailUrl: string;
  videoLink: string;
  videoName: string;
}

interface IPartnerImage {
  imageName: string;
  imageUri: string;
}

interface IPartnerDetailInformation {
  additionalPurchasesRequired: boolean;
  appVersion: string;
  collateralDocuments: IPartnerDocument[];
  countries: string[];
  demoVideos: IPartnerVideo[];
  description: string;
  helpLink: string;
  images: IPartnerImage[];
  keywords: string[];
  languagesSupported: string[];
  largeIconUri: string;
  platformVersion: string;
  privacyPolicyUrl: string;
  releaseDate: string;
  subCategories: string[];
  supportLink: string;
}

const convertPartnerDocument = (d: IPartnerDocument) => ({
  DocumentName: d.documentName,
  DocumentUri: d.documentUri,
});

const convertPartnerImage = (i: IPartnerImage) => ({
  ImageName: i.imageName,
  ImageUri: i.imageUri,
});

const convertPartnerVideo = (v: IPartnerVideo) => ({
  ThumbnailURL: v.thumbnailUrl,
  VideoLink: v.videoLink,
  VideoName: v.videoName,
});

function attemptMap<T>(a: T[], f: (v: T) => any) {
  if (a) {
    return a.map(f);
  } else {
    return [];
  }
}

export const parsePartnerApp = (app: IPartnerApp, product: string): IAppDataItem => {
  let detailInformation: IAppDetailInformation = null;
  if (app.details) {
    const d = app.details;
    detailInformation = {
      AdditionalPurchasesRequired: d.additionalPurchasesRequired,
      AppVersion: d.appVersion,
      CollateralDocuments: attemptMap(d.collateralDocuments, convertPartnerDocument),
      Countries: d.countries,
      DemoVideos: attemptMap(d.demoVideos, convertPartnerVideo),
      Description: d.description,
      HelpLink: d.helpLink,
      Images: attemptMap(d.images, convertPartnerImage),
      Keywords: d.keywords,
      LanguagesSupported: d.languagesSupported,
      LargeIconUri: d.largeIconUri,
      PlatformVersion: d.platformVersion,
      PrivacyPolicyUrl: d.privacyPolicyUrl,
      ReleaseDate: d.releaseDate,
      Subcategories: d.subCategories,
      SupportLink: d.supportLink,
    };
  }

  let builtFor = '';
  let productMask: IProductValue;

  const productInfo = getProductByUrlKey({ urlKey: product });
  if (productInfo?.UrlKey) {
    builtFor = productInfo.LongTitle;
    productMask = {
      [productInfo.FilterGroup]: productInfo.FilterID,
    };
  }

  const appDataItem: IAppDataItem = {
    entityId: app.applicationId,
    title: app.title,
    publisher: app.publisher,
    shortDescription: app.shortDescription,
    builtFor, // TODO -- convert ProductEnum to whatever builtFor string
    iconURL: app.smallIconUri,
    iconBackgroundColor: app.iconBackgroundColor,
    actionString: Constants.ActionStrings.Get,
    ctaTypes: [Constants.CTAType.Get],
    primaryProduct: productMask,
    privateApp: app.privateApp,
    products: app.products ? app.products : productMask,
    industries: app.industries ? app.industries : 0,
    categories: app.categories ? app.categories : 0, // TODO Support L2 categories
    handoffURL: app.handOffURL,
    leadgenEnabled: app.enableLeadSharing,
    licenseTermsUrl: app.licenseTermsUrl,
    detailInformation,
    filtermatch: true,
    detailLoadFailed: false,
  };

  return appDataItem;
};

export const parsePartnerApps = (appData: IPartnerApp[], embedHost: string): IAppDataItem[] => {
  let product = embedHost;
  if (embedHost === DataMap.products.Dynamics365.UrlKey) {
    product = DataMap.products.PowerApps.UrlKey;
  }
  const appList = appData.map((a) => parsePartnerApp(a, product));

  // TODO: sort?
  // PowerBI Apps are wanting to be sorted alphabetically
  /* appList = appList.sort((a: IAppDataItem, b: IAppDataItem) => {
        let aTitle = a.title.toLowerCase();
        let bTitle = b.title.toLowerCase();
        return aTitle > bTitle ? 1 : ( aTitle < bTitle ? -1 : 0 );
    }); */

  return appList;
};
