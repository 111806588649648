import { IState } from '@src/State';
import {
  ServiceDetails as ServiceDetailsComponent,
  IServiceDetailsDispatchProps,
  IServiceDetailsOwnProps,
} from '../components/serviceDetails';
import { Service } from '@shared/serviceViewModel';
import { ensureServiceDetailsData } from '@shared/actions/thunkActions';
import { extractCountryCodeFromQueryParam } from '@shared/utils/consultingServicesUtils';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from '@shared/routerHistory';
import { Constants } from '@shared/utils/constants';
import { isProfessionalService, getTelemetryDetailContentCurrentView } from '@shared/utils/appUtils';
import { EnsureAsyncDataSSR } from '@shared/hooks/useLocationChange';
import { withHydration } from '@shared/hooks/useHydration';

export const mapStateToProps = (state: IState, ownProps: IServiceDetailsOwnProps & WithRouterProps): IServiceDetailsOwnProps => {
  const services = state.services.dataList as Service[];
  const hashMap = state.services.idMap;
  const query = ownProps.location ? ownProps.location.query : null;
  const serviceIndex = hashMap[ownProps.routeParams.entityId.toString().toLowerCase()];

  let targetService: Service = null;
  if (serviceIndex >= 0) {
    targetService = services[`${serviceIndex}`];
  }

  const breadcrumbUrl = state.config.breadcrumbUrl
    ? state.config.breadcrumbUrl
    : '/' + state.config.locale + '/marketplace/consulting-services';

  const defaultTab = query && query.tab ? query.tab : null;

  // If the query has the country code that wins, else select first available country from the service.
  let countryCode = extractCountryCodeFromQueryParam(query);
  if (!countryCode && targetService && targetService.countryRegion && targetService.countryRegion[0]) {
    countryCode = targetService.countryRegion[0];
  }

  const offerIsProfessionalService =
    targetService?.entityType === Constants.EntityType.Service && isProfessionalService({ service: targetService });

  return {
    ...ownProps,
    isLoadingUserData: state.users.loading,
    service: targetService,
    serviceId: ownProps.routeParams.entityId.toString().toLowerCase(),
    breadcrumbUrl,
    defaultTab,
    selectedLocale: state.config.locale,
    isNationalCloud: !!state.config.nationalCloud,
    currentView: getTelemetryDetailContentCurrentView(state.config.currentView, state.config.currentGalleryViewIsCurated),
    countryCode,
    query: query,
    isAppSource: state.config.isAppSource,
    suggestedItems: state.services.relatedAppsItems.items.suggestedItems || [],
    isProfessionalService: offerIsProfessionalService,
  };
};

function ensureAsyncData(dispatch: any, entityId: string) {
  return dispatch(ensureServiceDetailsData(entityId));
}

export const mapDispatchToProps = (dispatch: any): IServiceDetailsDispatchProps => {
  return {
    ensureAsyncData: (entityId: string) => ensureAsyncData(dispatch, entityId),
  };
};

const ServiceDetails = withRouter(connect(mapStateToProps, mapDispatchToProps)(withHydration(ServiceDetailsComponent)));

export const ensureAsyncDataSSR: EnsureAsyncDataSSR = async (dispatch: any, getState: () => IState, params: any) => {
  await ensureAsyncData(dispatch, params.entityId);
};

export default ServiceDetails;
