import { logger } from '@src/logger';
import { SpzaInstrumentService } from '../services/telemetry/spza/spzaInstrument';
import { ITelemetryData } from '../Models';
import { urlPush } from '../routerHistory';
import { IDataValues as SharedIDataValues } from './dataMapping';
import { onClickTrackHitCountsForTreatmentNewFilter } from './filterHelpers';
import { Constants } from './constants';
import { BusinessTelemetry } from './businessConstants';

export interface IFilterItemProps {
  filter: SharedIDataValues;
  isActive: boolean;
  category: string;
  queryValue: string;
  href: string;
  showExpandedIcon?: boolean;
  hideCheckbox?: boolean;
  onClick?: () => void;
  onClickHandle?: () => void;
  iconClassName?: string;
  resultCount?: number;
  className?: string;
  role?: string;
  otherProps?: any;
  accEnabled?: any;
  shouldShowFilterBoldStyle?: boolean;
  isEmbed?: boolean;
  filterTextClass?: string;
  tooltip?: string;
}

export function handleFilterToggleAction(
  filterType: string,
  filterValue: string,
  isActive: boolean,
  href: string,
  actionModifier: string,
  clickCallback?: () => void
): void {
  if (!href) {
    return;
  }

  const data = {
    filterType,
    filterValue,
    isActive,
    filterState: !isActive ? BusinessTelemetry.Filters.Activating : BusinessTelemetry.Filters.Deactivating,
  };
  const payload: ITelemetryData = {
    page: href,
    action: Constants.Telemetry.Action.FilterClick,
    actionModifier: actionModifier,
    details: JSON.stringify(data),
  };
  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
  logger.info(payload.details, {
    action: payload.action,
    actionModifier: payload.actionModifier,
    page: payload.page,
  });

  onClickTrackHitCountsForTreatmentNewFilter(filterValue);

  urlPush(href);

  if (clickCallback) {
    clickCallback();
  }
}

export function handleFilterToggle(
  filterType: string,
  filterValue: string,
  isActive: boolean,
  href: string,
  actionModifier: string,
  clickCallback?: () => void
) {
  return () => {
    handleFilterToggleAction(filterType, filterValue, isActive, href, actionModifier, clickCallback);
  };
}

export function getFilterText(context: any, filter: any, showExpandedIcon?: boolean, href?: string): string {
  let text = context.loc(
    (filter as any).locKey ? (filter as any).locKey : filter.LocKey,
    (filter as any).title ? (filter as any).title : filter.Title
  );

  // !!! Temporary HACK: If filterText is 'Power BI apps' and we are in consulting services tab,
  // then we make the text 'Power BI'.
  if (text === 'Power BI apps' && href && href.indexOf('consulting-services') > -1) {
    text = 'Power BI';
  }

  if (showExpandedIcon) {
    text += ' >';
  }
  return text;
}

export function keyDownHandler(event: React.KeyboardEvent<any>, clickHandler: any) {
  if (event.keyCode === Constants.SystemKey.Space) {
    return clickHandler;
  }
}
