import {
  ensureAppReviewCommentCollectionData,
  ensureClearAppReviewCommentCollectionData,
  createAppReviewComment,
  ensureAppReviewCommentCollectionStateInitialized,
} from '@shared/actions/thunkActions';
import {
  AppReviewCommentCollection as AppReviewCommentCollectionComponent,
  IAppReviewCommentCollectionCreateCommentArgs,
  IAppReviewCommentCollectionDeleteCommentArgs,
  IAppReviewCommentCollectionEditCommentArgs,
  IAppReviewCommentCollectionEnsureStateInitializedArgs,
  IAppReviewCommentCollectionLoadDataArgs,
  IAppReviewCommentCollectionOpenModalArgs,
  IAppReviewCommentCollectionProps,
  IAppReviewCommentCollectionBaseArgs,
} from '@shared/components/appReviewCommentCollection';
import { connect, Dispatch } from 'react-redux';
import { IState } from '../../State';
import {
  createModalAction,
  createEntityReviewCommentModalAction,
  createEntityReviewCommentsUnloadPagesAction,
} from '@shared/actions/actions';
import { Action } from 'redux';
import { Constants } from '@shared/utils/constants';
import { IAppReviewCommentsState, IAppsReviewCommentBaseActionPayload } from '@shared/interfaces/reviews/comments';

const mapStateToProps = (state: IState, ownProps: IAppReviewCommentCollectionProps): IAppReviewCommentCollectionProps => {
  const commentsState: IAppReviewCommentsState =
    (state.apps?.appReviewsData?.reviewComments && state.apps?.appReviewsData?.reviewComments[ownProps.review?.id]) || null;
  return {
    signedIn: state.users?.signedIn,
    comments: commentsState,
    expanded: ownProps.expanded,
    locale: ownProps.locale,
    review: ownProps.review,
    isStateInitialized: commentsState !== null,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action<IAppsReviewCommentBaseActionPayload>>) => {
  return {
    loadData: ({ offerId, reviewId, page, itemsPerPage }: IAppReviewCommentCollectionLoadDataArgs) =>
      dispatch(
        ensureAppReviewCommentCollectionData({
          offerId,
          reviewId,
          page,
          itemsPerPage,
        })
      ),
    clearData: ({ offerId, reviewId }: IAppReviewCommentCollectionBaseArgs) =>
      dispatch(
        ensureClearAppReviewCommentCollectionData({
          offerId,
          reviewId,
        })
      ),
    createComment: ({ offerId, reviewId, content, addToNewlyAdded }: IAppReviewCommentCollectionCreateCommentArgs) =>
      dispatch(
        createAppReviewComment({
          offerId,
          reviewId,
          commentContent: content,
          isNewlyAddedComment: addToNewlyAdded,
        })
      ),
    editComment: ({
      offerId,
      reviewId,
      commentId,
      updatedContent,
      isNewlyAddedComment = false,
    }: IAppReviewCommentCollectionEditCommentArgs) =>
      dispatch(
        createEntityReviewCommentModalAction({
          showModal: true,
          modalMode: Constants.Reviews.ReviewCommentModalMode.ConfirmUpdate,
          actionPayload: {
            offerId,
            reviewId,
            commentId,
            commentContent: updatedContent,
            isNewlyAddedComment,
          },
        })
      ),
    deleteComment: ({
      offerId,
      reviewId,
      commentId,
      isNewlyAddedComment = false,
    }: IAppReviewCommentCollectionDeleteCommentArgs) =>
      dispatch(
        createEntityReviewCommentModalAction({
          showModal: true,
          modalMode: Constants.Reviews.ReviewCommentModalMode.ConfirmDelete,
          actionPayload: {
            commentId,
            reviewId,
            offerId,
            isNewlyAddedComment,
          },
        })
      ),
    openModal: ({ modalId }: IAppReviewCommentCollectionOpenModalArgs) => {
      return dispatch(
        createModalAction({
          showModal: true,
          modalId: modalId,
        })
      );
    },
    ensureStateInitialized: ({ offerId, reviewId, totalComments }: IAppReviewCommentCollectionEnsureStateInitializedArgs) => {
      return dispatch(ensureAppReviewCommentCollectionStateInitialized({ offerId, reviewId, totalComments }));
    },
    unloadPages: ({ offerId, reviewId }: IAppReviewCommentCollectionBaseArgs) => {
      return dispatch(createEntityReviewCommentsUnloadPagesAction({ offerId, reviewId }));
    },
  };
};

export const AppReviewCommentCollection = connect(mapStateToProps, mapDispatchToProps)(AppReviewCommentCollectionComponent);
