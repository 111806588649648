import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getWindow } from '@fluentui/react';
import { i18nLocales } from '@shared/utils/locales';
import { LocalizationSettings, ResourcesFiles } from '@shared/utils/constants';
import { DEBUG_STATE } from '@shared/utils/debug';

const i18nInit = () => {
  const i18nInstanceFromServer = getWindow()?.__INITIAL_STATE__.config.i18nInstance;
  const { language, store } = i18nInstanceFromServer;

  return use(initReactI18next).init({
    debug: DEBUG_STATE,
    fallbackLng: LocalizationSettings.defaultLng,
    lng: language,
    supportedLngs: i18nLocales,
    ns: [LocalizationSettings.defaultNS, ResourcesFiles.header, ResourcesFiles.search],
    defaultNS: LocalizationSettings.defaultNS,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    load: 'currentOnly',
    resources: store.data,
  });
};

export default i18nInit;
