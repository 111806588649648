import React from 'react';
import PropTypes from 'prop-types';
import { SafeHtmlWrapper } from '@shared/components/safeHtmlWrapper';
import { IAppDataItem, ITermsLink } from '@shared/Models';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { Constants } from '@shared/utils/constants';

export interface IUserTermsProps {
  app: IAppDataItem;
}

const getTermsLink = (app: IAppDataItem) => {
  const { UsesEnterpriseContract = false, licenseTermsUrl = '' } = app;
  let terms: ITermsLink;
  if (UsesEnterpriseContract) {
    terms = {
      link: Constants.enterpriseContractLink,
      locKey: 'ECA_Link',
    };
  } else {
    terms = {
      link: licenseTermsUrl,
      locKey: 'TRY_Terms2',
    };
  }
  return terms;
};

const generateLink = (text: string, link: string): string => {
  return `<a href='${link}' rel='noopener' target='_blank'>${text}</a>`;
};

export const UserTerms: React.FunctionComponent<IUserTermsProps> = (
  { app }: IUserTermsProps,
  context: ILocContext & ILocParamsContext
) => {
  const terms = getTermsLink(app);
  const { detailInformation } = app;
  const privacyPolicyUrl = detailInformation?.PrivacyPolicyUrl;

  // eslint-disable-next-line security/detect-non-literal-fs-filename
  const termsOfUse = generateLink(context.loc('Provider_Terms', 'terms of use'), terms.link);
  const privacyPolicy = generateLink(context.loc('TRY_PrivacyPolicy', 'privacy policy'), privacyPolicyUrl);
  const termsLink = generateLink(context.loc('TRY_Terms', 'terms'), Constants.MicrosoftTermsURL);
  const privacyLink = generateLink(context.loc('TRY_Privacy', 'privacy'), Constants.MicrosoftPrivacyStatementURL);
  const userTermsFallback = `By getting this product, I give Microsoft permission to use or share my account
   information so that the provider can contact me regarding this product and related products.
   I agree to the provider's ${termsOfUse} and ${privacyPolicy} and understand that the rights to use this product do not come from Microsoft, unless Microsoft is the provider.
    Use of AppSource is governed by separate ${termsLink} and ${privacyLink}.`;

  return (
    <SafeHtmlWrapper
      html={context.locParams(
        'Apps_PDP_Consent_Disclaimer',
        [termsOfUse, privacyPolicy, termsLink, privacyLink],
        userTermsFallback
      )}
    />
  );
};

(UserTerms as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
};
