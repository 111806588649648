import * as React from 'react';
import * as PropTypes from 'prop-types';
import SpzaComponent from '@shared/components/spzaComponent';
import { IAppReview, IAppDataItem } from '@shared/Models';
import { AppReviewItem } from '@shared/components/appReviewItem';
import { Constants } from '@shared/utils/constants';
import { ILocContext, ICommonContext, ILocParamsContext, IComponentsInfoContext } from '@shared/interfaces/context';
import { IReviewsState, IUserDataState } from '@src/State';
import { RatingSummary } from '@shared/components/ratingSummary';
import {
  createDeepLinkPagination,
  createDefaultPagination,
  getAddReviewSectionDescription,
  getReviewCtaButtonText,
  IUpdateReviewsStateByDropDowns,
  launchTelemetry,
  IPaginationIndexes,
  getExternalRatingSummaries,
  findReviewsSources,
  getRatingSummaryByType,
} from '@shared/utils/reviewsUtils';
import { ReviewsDropDowns } from '@shared/components/reviewsDropDowns';
import { DefaultButton, mergeStyleSets, Spinner, Stack, ScreenWidthMinXLarge, ScreenWidthMinXXLarge } from '@fluentui/react';
import type { IStackTokens } from '@fluentui/react';
import { NoReviewsToShow } from '@shared/components/noReviewsToShow';
import { getWindow } from '@shared/services/window';
import { logger } from '@src/logger';
import { isSignedInChanged } from '@shared/utils/appUtils';
import {
  IAppEntityMarkAsHelpfulBaseThunkActionParams,
  IAppEntitySetReviewMarkAsHelpfulParams,
  IAppReviewMarkedAsHelpfulState,
} from '@shared/interfaces/reviews/markAsHelpful';
import { NeutralColors, CommunicationColors } from '@fluentui/theme';
import { IExternalRatingSummary } from '@shared/interfaces/reviews/external/rating';
import { ExternalRatingSummaries } from '@shared/containers/externalRatingSummaries';
import { shouldShowUserTerms } from '@shared/utils/detailUtils';
import { UserTerms } from '@appsource/components/userTerms';
import classNames from 'classnames';

export interface IAppReviewCollectionState {
  reviewList?: IAppReview[];
  originalReviewList?: IAppReview[];
  currentStart: number;
  currentEnd: number;
  currentPageNumber: number;
  sourceFilter?: string;
  ratingsFilter?: number;
  sortBy?: string;
  scrollToReview: boolean;
  scrollToReviewItemRef: React.RefObject<HTMLDivElement>;
}

export interface IAppReviewCollectionProps {
  reviewsData: IReviewsState;
  appId: string;
  locale: string;
  appData: IAppDataItem;
  location: {
    query: {
      [key: string]: string;
    };
  };
  isEmbed: boolean;
  reviewId: string;
  openRatingModal: (app: IAppDataItem, accessKey: string, ctaType: string, callback: () => void) => void;
  ensureAppReviewsData(entityId: string, forceUpdate?: boolean): Promise<boolean>;
  userInfo: IUserDataState;
  toggleMarkAsHelpful: (args: IAppEntitySetReviewMarkAsHelpfulParams) => void;
  loadReviewIdsMarkedAsHelpful: (args: IAppEntityMarkAsHelpfulBaseThunkActionParams) => Promise<string[]>;
  markedAsHelpful?: IAppReviewMarkedAsHelpfulState;
  openSignInModal: () => void;
  userCommentedReviewIds?: string[];
  loadUserProductReviewMetadata: (args: { legacyId: string }) => Promise<string[]>;
  billingCountryCode: string;
  showMyCommentsFilter: boolean;
}

const DEFAULT_STICKY_HEIGHT = 64;
const DEFAULT_MENU_HEIGHT = 54;
const SCROLL_TO_REVIEW_TOP_MARGIN = 16;

const contentStyles = mergeStyleSets({
  reviewsTopBar: [
    'reviewsTopBar',
    {
      marginBottom: 24,
    },
  ],
  reviewsDateLabel: {
    color: NeutralColors.gray130,
  },
  reviewsDetailsTopBlockContainer: {
    display: 'flex',
    width: '100%',
    [`@media (min-width: ${ScreenWidthMinXLarge}px)`]: {
      flexDirection: 'row',
    },
    [`@media (max-width: ${ScreenWidthMinXLarge - 1}px)`]: {
      flexDirection: 'column-reverse',
    },
  },
  reviewsTabTop: {
    paddingTop: 24,
    [`@media (max-width: ${ScreenWidthMinXLarge - 1}px)`]: {
      paddingTop: 0,
    },
  },
  reviewsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  userTermsContainer: {
    backgroundColor: NeutralColors.white,
    border: '1px solid #EAEAEA',
    borderRadius: '2px',
    height: 'fit-content',
    [`@media (max-width: ${ScreenWidthMinXLarge - 1}px)`]: {
      margin: '36 0 24 0',
    },
    [`@media (min-width: ${ScreenWidthMinXLarge - 1}px)`]: {
      marginBottom: 28,
    },
    [`@media (min-width: ${ScreenWidthMinXLarge}px) and (max-width: ${ScreenWidthMinXXLarge - 1}px)`]: {
      marginLeft: 40,
      minWidth: 200,
      maxWidth: 200,
    },
    [`@media (min-width: ${ScreenWidthMinXXLarge}px)`]: {
      marginLeft: 32,
      minWidth: 256,
      maxWidth: 256,
    },
    '& a:link': {
      color: CommunicationColors.primary,
      textDecoration: 'underline',
    },
    '& a:visited': {
      color: CommunicationColors.primary,
    },
    '& a:hover': {
      color: CommunicationColors.shade20,
    },
    '& a:active': {
      color: CommunicationColors.shade30,
    },
  },
});

const consentDisclaimerTokens: IStackTokens = { padding: 16 };

// Displays a collection of AppReviews
export class AppReviewCollection extends SpzaComponent<IAppReviewCollectionProps, IAppReviewCollectionState> {
  context: ILocContext & ILocParamsContext & ICommonContext & IComponentsInfoContext;

  constructor(props: IAppReviewCollectionProps, state?: IAppReviewCollectionState) {
    super(props);
    this.state = state || {
      currentStart: 1,
      currentEnd: 1,
      currentPageNumber: 1,
      scrollToReview: false,
      scrollToReviewItemRef: null,
    };
  }

  componentDidMount() {
    const { ensureAppReviewsData, appId, userInfo } = this.props;
    ensureAppReviewsData(appId).then(() => this.loadReviewsToState(true));
    if (userInfo.signedIn) {
      this.loadReviewIdsMarkedAsHelpful();
      this.loadUserProductReviewMetadata();
    }
  }

  componentDidUpdate(prevProps: IAppReviewCollectionProps) {
    const { ensureAppReviewsData, appId } = this.props;
    if (prevProps.reviewsData !== this.props.reviewsData) {
      this.loadReviewsToState(false);
    }
    if (isSignedInChanged(this.props.userInfo.signedIn, prevProps.userInfo.signedIn)) {
      ensureAppReviewsData(appId);
      this.loadReviewIdsMarkedAsHelpful();
      this.loadUserProductReviewMetadata();
    }
    this.scrollToReviewIfNeeded();
  }

  loadReviewIdsMarkedAsHelpful() {
    const { loadReviewIdsMarkedAsHelpful, appId: offerId } = this.props;
    loadReviewIdsMarkedAsHelpful({ offerId });
  }

  loadUserProductReviewMetadata() {
    const { loadUserProductReviewMetadata, appId: legacyId } = this.props;
    loadUserProductReviewMetadata({ legacyId });
  }

  loadReviewsToState(deepLinkMode: boolean): IPaginationIndexes {
    const reviews: IAppReview[] = this.props.reviewsData.reviews;
    // If there are no reviews we get an empty string, we just need to convert it into an array.
    let returnedReviews: IAppReview[] = [];
    if (reviews && Array.isArray(returnedReviews)) {
      // Filter out any reviews that lack both a title and a description
      returnedReviews = reviews.filter((review) => review.content || review.title);
    }

    let pagination: IPaginationIndexes = createDefaultPagination(returnedReviews);
    let scrollToReview = false;

    // Deep link into review. Select the right page and "trigger" scroll action.
    if (deepLinkMode && this.props.reviewId) {
      pagination = createDeepLinkPagination(reviews, this.props.reviewId);
      scrollToReview = true;
    }

    this.setState({
      reviewList: returnedReviews,
      originalReviewList: returnedReviews,
      currentStart: pagination.startIndex,
      currentEnd: pagination.endIndex,
      currentPageNumber: pagination.pageIndex,
      scrollToReview: scrollToReview,
    });
    return pagination;
  }

  onReviewItemShown = (reviewId: string, reviewCompRef: React.RefObject<HTMLDivElement>) => {
    if (this.props.reviewId && reviewCompRef && this.props.reviewId === reviewId) {
      this.setState({ scrollToReviewItemRef: reviewCompRef });
    }
  };

  scrollToReviewIfNeeded = () => {
    if (
      this.state.scrollToReview &&
      this.state.scrollToReviewItemRef &&
      this.props.reviewId &&
      this.state.scrollToReviewItemRef.current
    ) {
      const elementPosition = this.state.scrollToReviewItemRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition - this.getReviewsScrollOffset();
      getWindow().scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
      this.setState({ scrollToReview: false });
    }
  };

  getReviewsScrollOffset = (): number => {
    const stickyCardHeight = this.context.getAppDetailsStickyCardHeight();
    const mainMenuHeight = this.context.getAppHeaderHeight();
    const headerOffset =
      (stickyCardHeight || DEFAULT_STICKY_HEIGHT) + (mainMenuHeight || DEFAULT_MENU_HEIGHT) + SCROLL_TO_REVIEW_TOP_MARGIN;
    return headerOffset;
  };

  renderReviews(start: number, end: number, reviewList: IAppReview[]): JSX.Element[] {
    const { appId, locale, isEmbed, appData, userInfo, openSignInModal, reviewsData, markedAsHelpful } = this.props;
    const { userReviewIdsMarkedAsHelpful = [] } = markedAsHelpful;

    if (!reviewList) {
      return [
        <div className="reviewsLoadingSpinner" key="reviewsLoading">
          <Spinner label={this.context.loc('AppDetail_LoadingText', 'Loading...')} ariaLive="assertive" labelPosition="left" />
        </div>,
      ];
    }

    if (reviewList && reviewList.length === 0) {
      return [
        <NoReviewsToShow
          key={0}
          context={this.context}
          isOfferPurchased={reviewsData.isPurchased}
          openReviewModal={this.openReviewModal}
        />,
      ];
    }

    // This is to handle the last page. If there number of reviews is less than the end of page, then
    // we don't have to loop until end. We can stop at the point when there are no more reviews.

    const listEnd = reviewList.length < end ? reviewList.length : end;

    const appReviewItems: JSX.Element[] = [];

    let i = 0;
    // 'start' begins from 1.
    for (i = start - 1; i < listEnd; i++) {
      const review = reviewList[`${i}`];
      appReviewItems.push(
        <AppReviewItem
          reviewItemShown={this.onReviewItemShown}
          appId={appId}
          review={review}
          locale={locale}
          isEmbed={isEmbed}
          publisher={appData ? appData.publisher : ''}
          iconUrl={appData ? appData.iconURL : ''}
          isSignedIn={userInfo.signedIn}
          isMarkedAsHelpful={userReviewIdsMarkedAsHelpful.includes(review.id)}
          onToggleMarkAsHelpful={({ isHelpful }) => this.toggleMarkAsHelpful({ review, isHelpful })}
          openSignInModal={openSignInModal}
          fallbackReviewSourceName={Constants.StorefrontName.AppSource}
          authorPersona
          dateLabelClassName={contentStyles.reviewsDateLabel}
        />
      );
    }

    return appReviewItems;
  }

  toggleMarkAsHelpful(args: { review: IAppReview; isHelpful: boolean }) {
    const { appId: offerId, toggleMarkAsHelpful } = this.props;
    toggleMarkAsHelpful({ ...args, offerId });
  }

  getReviewCounterText(): string {
    if (!this.state.reviewList || this.state.reviewList.length === 0) {
      return null;
    }

    return this.context.locParams(
      'ReviewCollection_ReviewsCounterOfTotal',
      [
        this.state.currentStart?.toString() || '',
        this.state.currentEnd?.toString() || '',
        this.state.reviewList.length?.toString() || '',
      ],
      'Showing {0}-{1} of {2} reviews'
    );
  }

  setCurrentPage(event: React.MouseEvent<HTMLElement>) {
    const idClicked = (event?.target as HTMLElement)?.id;
    if (!idClicked) {
      return;
    }

    // This indicates the current page which should be displayed
    let pageNumberClicked = 0;
    if (idClicked === 'previous') {
      pageNumberClicked = this.state.currentPageNumber - 1;
    } else if (idClicked === 'next') {
      pageNumberClicked = this.state.currentPageNumber + 1;
    } else {
      // if it was not prev/next, then we have a page number.
      pageNumberClicked = parseInt(idClicked, 10);
    }

    // Page number * number of items per page gives the total number of items displayed.
    // Since we want the total number of items displayed so far, we do -1.
    const startArrayIndex = (pageNumberClicked - 1) * Constants.MaxItemsPerPageInReviews;

    // startArrayIndex is the total number of items displayed so far. We don't want to display the
    // last item from the prev page. So currentPageStart should be a +1 from that.
    const currentPageStart = startArrayIndex + 1;
    let currentPageEnd = startArrayIndex + Constants.MaxItemsPerPageInReviews;

    // the number of reviews in the last page can be less then 10, in this case currentPageEnd should be the current total number of reviews
    currentPageEnd = currentPageEnd > this.state.reviewList.length ? this.state.reviewList.length : currentPageEnd;

    this.setState((prevState: IAppReviewCollectionState) => {
      return {
        reviewList: prevState.reviewList,
        currentStart: currentPageStart,
        currentEnd: currentPageEnd,
        currentPageNumber: pageNumberClicked,
      };
    });

    const telemetryDetails = {
      currentPageNumber: pageNumberClicked,
      previousPageNumber: this.state.currentPageNumber,
      currentReviewListStart: currentPageStart,
      currentReviewListEnd: currentPageEnd,
      reviewListLength: (this.state.reviewList && this.state.reviewList.length) || 0,
    };

    launchTelemetry(
      Constants.Telemetry.Action.Click,
      Constants.Telemetry.ActionModifier.ReviewPageNavigationButton,
      telemetryDetails
    );
    logger.info(JSON.stringify(telemetryDetails), {
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.ReviewPageNavigationButton,
    });
  }

  getPaginationList() {
    // If the number of reviews is less than the items per page, then pagination div is not necessary.
    if (!this.state.reviewList || this.state.reviewList.length < Constants.MaxItemsPerPageInReviews) {
      return null;
    }

    const reviewListLength = this.state.reviewList.length;
    let numberOfPages = Math.floor(reviewListLength / Constants.MaxItemsPerPageInReviews);

    // The final page has lesser number of items than 'MaxItemsPerPageInReviews'
    // then we'll have a reminder set of reviews which should go into its own page.
    if (reviewListLength % Constants.MaxItemsPerPageInReviews > 0) {
      numberOfPages++;
    }

    let i = 0;
    const pageNumberList: JSX.Element[] = [];
    // Only for pages greater than 1 we would show 'previous'.
    if (this.state.currentPageNumber > 1) {
      pageNumberList.push(
        <li key="prev">
          <a
            href="#"
            className="c-glyph paginationPrevious"
            aria-label="Previous page"
            // eslint-disable-next-line react/forbid-dom-props
            id="previous"
            onClick={(event) => this.setCurrentPage(event)}
          >
            {this.context.loc('PAGINATION_Previous')}
          </a>
        </li>
      );
    }

    let pageNumberStart = 0;
    let pageNumberEnd = Math.min(numberOfPages, 9);

    if (this.state.currentPageNumber > 4 && numberOfPages >= 9) {
      // if there are more than 9 pages, then we want to show only 9 at a time.
      // 4 (or how many ever exists) after the current one - if exists. Otherwise upto the maximum number of pages.
      pageNumberEnd = Math.min(this.state.currentPageNumber + 4, numberOfPages);

      // pageNumberStart should be 4 pages before the current one.
      // pageNumberStart can never be negative for the following reasons.
      // This condition is executed only if number of pages is greater than 9.
      // Also min value of currentPageNumber > 4 is 5. pageNumberEnd can either be currentPageNumber + 4 (which is 9)
      // or number of pages (which, if it has entered this block is at least 9).
      pageNumberStart = pageNumberEnd - 9;
    }

    for (i = pageNumberStart; i < pageNumberEnd; i++) {
      const isActive = this.state.currentPageNumber === i + 1;

      pageNumberList.push(
        <li key={i} className={isActive ? 'f-active' : null}>
          <a
            href="#"
            aria-current={isActive}
            aria-label={'Page ' + (i + 1)}
            // eslint-disable-next-line react/forbid-dom-props
            id={(i + 1).toString()}
            onClick={(event) => this.setCurrentPage(event)}
          >
            {i + 1}
          </a>
        </li>
      );
    }

    // If we are at the last page we shouldn't show the 'next' button.
    if (this.state.currentPageNumber < numberOfPages) {
      pageNumberList.push(
        <li key="next">
          <a
            href="#"
            className="c-glyph paginationNext"
            aria-label="Next page"
            // eslint-disable-next-line react/forbid-dom-props
            id="next"
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => this.setCurrentPage(event)}
          >
            {this.context.loc('PAGINATION_Next')}
          </a>
        </li>
      );
    }

    return <ul className="m-pagination">{pageNumberList}</ul>;
  }

  openReviewModal = () => {
    let ctaType: string = null;
    if (this.props.location?.query?.ctatype) {
      ctaType = this.props.location.query.ctatype;
    }

    launchTelemetry(Constants.Telemetry.Action.Click, Constants.Telemetry.ActionModifier.OpenRatingModalFromReviewTab, {
      appId: this.props.appId,
    });
    logger.info(
      JSON.stringify({
        appId: this.props.appId,
      }),
      {
        action: Constants.Telemetry.Action.Click,
        actionModifier: Constants.Telemetry.ActionModifier.OpenRatingModalFromReviewTab,
      }
    );

    this.props.openRatingModal(this.props.appData, null, ctaType, () => {
      launchTelemetry(Constants.Telemetry.Action.Open, Constants.Telemetry.ActionModifier.OpenRatingModalFromReviewTab, {
        appId: this.props.appId,
      });
      logger.info(
        JSON.stringify({
          appId: this.props.appId,
        }),
        {
          action: Constants.Telemetry.Action.Open,
          actionModifier: Constants.Telemetry.ActionModifier.OpenRatingModalFromReviewTab,
        }
      );
      this.clearFiltersByState();
    });
  };

  updatePaginationState = (curPageNumber: number, curStart: number, currentEnd: number) => {
    this.setState({ currentPageNumber: curPageNumber, currentStart: curStart, currentEnd: currentEnd });
  };

  updateStateByFilters = (updatedRatingsFilter: number, updatedReviewList: IAppReview[], updatedSourceFilter: string) => {
    this.setState({ ratingsFilter: updatedRatingsFilter, reviewList: updatedReviewList, sourceFilter: updatedSourceFilter });
  };

  clearFiltersByState = () => {
    this.setState({ sourceFilter: null, ratingsFilter: null, sortBy: null });
  };

  updateStateBySortFilter = (updatedSortBy: string) => {
    this.setState({
      sortBy: updatedSortBy,
    });
  };

  renderReviewsDetailsTopBlock(externalRatingSummaries: IExternalRatingSummary[]) {
    const { appData, billingCountryCode } = this.props;
    const internalSummaries = getRatingSummaryByType(appData?.ratingSummaries, Constants.Reviews.ReviewsSource.Internal);
    const textButton = getReviewCtaButtonText(this.context, this.props.reviewsData.userReview);

    return (
      this.props.reviewsData.entityId === this.props.appId && (
        <Stack.Item className={classNames(['reviewTabTop', contentStyles.reviewsTabTop])}>
          <Stack className={contentStyles.reviewsDetailsTopBlockContainer}>
            <Stack.Item grow className={contentStyles.reviewsContainer}>
              <RatingSummary
                ratingSummary={internalSummaries || appData?.ratingSummary}
                context={this.context}
                title={
                  internalSummaries
                    ? this.context.loc('RatingSummary_Title', 'Marketplace ratings')
                    : this.context.loc('AppDetails_ASRatings', 'AppSource ratings')
                }
              />
              {externalRatingSummaries?.length > 0 && (
                <ExternalRatingSummaries externalRatingSummaries={externalRatingSummaries} />
              )}
              <div className="addReview">
                <div className="addReviewTitle">{this.context.loc('AppReviewCollection_SubHeaderTitle_Text', 'Your review')}</div>
                <DefaultButton
                  className="reviewCTA"
                  text={textButton}
                  onClick={this.openReviewModal}
                  data-bi-id={Constants.JsllCTAId.WriteReview}
                  data-bi-area="Review"
                  aria-label={textButton}
                  disabled={!this.props.reviewsData.isPurchased}
                />
                <div className="addReviewDescription">
                  {getAddReviewSectionDescription(this.context, this.props.reviewsData.isPurchased)}
                </div>
              </div>
            </Stack.Item>
            {shouldShowUserTerms({ app: appData, billingCountryCode }) && (
              <Stack.Item tokens={consentDisclaimerTokens} className={contentStyles.userTermsContainer}>
                <UserTerms app={appData} />
              </Stack.Item>
            )}
          </Stack>
        </Stack.Item>
      )
    );
  }

  renderImpl() {
    const externalRatingSummaries = getExternalRatingSummaries(this.props.appData?.ratingSummaries);

    const updateStateByFilters: IUpdateReviewsStateByDropDowns = {
      updatePaginationState: this.updatePaginationState,
      updateStateByFilters: this.updateStateByFilters,
      updateStateBySortFilter: this.updateStateBySortFilter,
    };

    const { userCommentedReviewIds, reviewsData, showMyCommentsFilter } = this.props;
    const { hasInternalReviews, hasExternalReviews } = findReviewsSources(reviewsData);

    return (
      <Stack className="spza_ASappReviewContainer">
        {this.props.isEmbed ? null : this.renderReviewsDetailsTopBlock(externalRatingSummaries)}
        {this.props.reviewsData.entityId === this.props.appId && (
          <div className={contentStyles.reviewsTopBar}>
            <div className="reviewCounter">
              <div>{this.getReviewCounterText()}</div>
            </div>
            <ReviewsDropDowns
              curSourceFilter={this.state.sourceFilter}
              curSortBy={this.state.sortBy}
              curRatingFilter={this.state.ratingsFilter}
              context={this.context}
              updateReviewsState={updateStateByFilters}
              originalReviewList={this.state.originalReviewList}
              curReviewsList={this.state.reviewList}
              externalReviewsExist={hasExternalReviews}
              internalReviewsExist={hasInternalReviews}
              userReview={reviewsData.userReview}
              userCommentedReviewIds={userCommentedReviewIds}
              showMyCommentsFilter={showMyCommentsFilter}
            />
          </div>
        )}
        {this.renderReviews(this.state.currentStart, this.state.currentEnd, this.state.reviewList)}
        <div className="reviewCounter-bottom">
          {this.state.reviewList && this.state.reviewList.length >= Constants.MaxItemsPerPageInReviews
            ? this.getReviewCounterText()
            : null}
        </div>
        <div className="reviewPagination" key={this.state.currentPageNumber}>
          {this.getPaginationList()}
        </div>
      </Stack>
    );
  }
}

(AppReviewCollection as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  getAppDetailsStickyCardHeight: PropTypes.func,
  getAppHeaderHeight: PropTypes.func,
};
