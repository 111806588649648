/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react';
import { Link, mergeStyleSets, MessageBar, PrimaryButton, TextField, DefaultButton, Stack, IStackTokens } from '@fluentui/react';
import { Constants } from '@shared/utils/constants';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import * as PropTypes from 'prop-types';
import { FunctionComponent, useEffect, useState } from 'react';
import { AppReviewCommentItemToolbar } from './appReviewCommentItemToolbar';

const BUTTON_SPACING = 10;

const appReviewCommentComposerStyles = mergeStyleSets({
  commentTextArea: {
    marginBottom: '10px',
  },
  submitButton: {
    marginLeft: `${BUTTON_SPACING / 2} !important`,
  },
  commentToolbar: [
    'ms-hiddenLgUp',
    {
      marginRight: BUTTON_SPACING / 2,
    },
  ],
});

const tokenDef: IStackTokens = { childrenGap: BUTTON_SPACING };

interface AppReviewItemCommentComposerProps {
  signedIn?: boolean;
  openSignInModal?: () => void;
  onSubmit: () => void;
  onCloseEdit?: () => void; // Triggered when the user chooses to toggle off the comment edit mode
  initialContent?: string;
  editMode?: boolean;
  onChange: (content: string) => void;
  onDeleteToggle?: () => void; // Only relevant when the component is in editing mode
  onEditToggle?: () => void; // Only relevant when the component is in editing mode
}

export function AppReviewItemCommentComposer(
  {
    signedIn = true,
    openSignInModal = () => {},
    onSubmit,
    initialContent = '',
    editMode = false,
    onCloseEdit = () => {},
    onChange = () => {},
    onDeleteToggle = () => {},
    onEditToggle = () => {},
  }: AppReviewItemCommentComposerProps,
  context: ILocContext & ILocParamsContext
) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [isShowContentLengthError, setIsShowContentLengthError] = useState(false);
  const isContentValid = (content: string) => {
    if (content) {
      return content.length > 0 && content.length <= Constants.Reviews.MaxCommentComposeLength;
    }
    return false;
  };

  useEffect(() => {
    setIsDisabled(!isContentValid(initialContent));
    setIsShowContentLengthError(false);
  }, [initialContent]);

  if (!signedIn) {
    return (
      <MessageBar>
        {context.loc('ReviewItem_CommentSection_Unauthenticated_Compose_Info_SignIn_Description', 'To post comments please ')}
        {
          <Link onClick={openSignInModal}>
            {context.loc('ReviewItem_CommentSection_Unauthenticated_Compose_Info_SignIn_Link', 'sign in.')}
          </Link>
        }
      </MessageBar>
    );
  } else {
    return (
      <div>
        <TextField
          ariaLabel={context.loc('ReviewItem_CommentSection_Compose_TextBox_Aria_Label', 'Review comment text box')}
          multiline
          resizable={false}
          value={initialContent}
          maxLength={Constants.Reviews.MaxCommentComposeLength}
          className={appReviewCommentComposerStyles.commentTextArea}
          onChange={(e, value) => {
            onChange(value);
            // If the content length warning is showing and the user reduced the content length, hide the warning
            if (isShowContentLengthError && value.length < Constants.Reviews.MaxCommentComposeLength) {
              setIsShowContentLengthError(false);
            }
          }}
          onKeyDown={() => {
            setIsShowContentLengthError(initialContent.length >= Constants.Reviews.MaxCommentComposeLength);
          }}
          errorMessage={
            (isShowContentLengthError &&
              context.locParams(
                'ReviewItem_CommentSection_Compose_LongContent_Warning',
                [Constants.Reviews.MaxCommentComposeLength.toString()],
                'You can enter a comment with a maximum of {0} characters'
              )) ||
            null
          }
        />
        <Stack horizontal wrap tokens={tokenDef}>
          {editMode && (
            <AppReviewCommentItemToolbar
              className={appReviewCommentComposerStyles.commentToolbar}
              onDeleteToggle={onDeleteToggle}
              onEditToggle={onEditToggle}
              isEditing
            />
          )}
          <PrimaryButton
            className={appReviewCommentComposerStyles.submitButton}
            text={context.loc(
              !editMode
                ? 'ReviewItem_CommentSection_Compose_Post_Button_Label'
                : 'ReviewItem_CommentSection_Compose_Edit_Button_Label'
            )}
            onClick={onSubmit}
            allowDisabledFocus
            disabled={isDisabled}
          />
          {editMode && (
            <DefaultButton
              text={context.loc('ReviewItem_CommentSection_Compose_Edit_Discard_Button_Label')}
              onClick={onCloseEdit}
              allowDisabledFocus
            />
          )}
        </Stack>
      </div>
    );
  }
}

(AppReviewItemCommentComposer as FunctionComponent).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
};
