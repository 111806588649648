import * as React from 'react';
import * as ReactDOM from 'react-dom';
import SpzaComponent from './spzaComponent';
import { ICommonContext } from '../interfaces/context';
import { getWindow, getDocument } from '@shared/services/window';
import { logTelemetry } from '../utils/telemetryUtils';
import { Constants } from '../utils/constants';
import { logger } from '@src/logger';
import { withHydration, WithHydrationProps } from 'hooks/useHydration';

export interface IScrollToTop extends WithHydrationProps {
  offsetYToAppear?: number;
}

class ScrollToTopButton extends SpzaComponent<IScrollToTop, any> {
  private static defaultOffsetY = 90;
  context: ICommonContext;
  childElement: HTMLElement;

  constructor(props: IScrollToTop, context: ICommonContext) {
    super(props, context);
    this.state = { isShowButton: false };
    if (__CLIENT__) {
      this.childElement = getDocument()?.createElement('div');
    }
  }

  componentDidMount() {
    if (__CLIENT__ && this.childElement) {
      getDocument().body.appendChild(this.childElement);
      getWindow().addEventListener('scroll', this.handleScroll.bind(this));
    }
  }

  componentWillUnmount() {
    if (__CLIENT__ && this.childElement) {
      getWindow().removeEventListener('scroll', this.handleScroll.bind(this));
      getDocument().body.removeChild(this.childElement);
    }
  }

  get offsetY(): number {
    return this.props.offsetYToAppear ? this.props.offsetYToAppear : ScrollToTopButton.defaultOffsetY;
  }

  handleScroll() {
    const curWindow = getWindow();
    const yOffsetToAppear = this.offsetY;
    this.setState({ isShowButton: curWindow && curWindow.pageYOffset > yOffsetToAppear });
  }

  goToThePageTop() {
    const curWindow = getWindow();
    if (curWindow) {
      curWindow.scrollTo(0, 0);
      logTelemetry(
        Constants.Telemetry.Action.Click,
        Constants.Telemetry.ActionModifier.ScrollToTopButton,
        'Go back to the top of the page using the scroll to top button'
      );
      logger.info('Go back to the top of the page using the scroll to top button', {
        action: Constants.Telemetry.Action.Click,
        actionModifier: Constants.Telemetry.ActionModifier.ScrollToTopButton,
      });
    }
  }

  renderButton() {
    return (
      this.state.isShowButton && (
        <button className="appSourceScrollToTop" onClick={() => this.goToThePageTop()} aria-label="Scroll to top"></button>
      )
    );
  }

  renderImpl() {
    if (!this.props.isHydrated) {
      return null;
    }
    return ReactDOM.createPortal(this.renderButton(), this.childElement);
  }
}

export default withHydration(ScrollToTopButton);
