import * as React from 'react';
import SpzaComponent from './spzaComponent';
import { IBuildHrefContext, ICommonContext, ILocContext, ILocParamsContext } from '../interfaces/context';
import { getAttributes } from '../utils/externalLinkUtils';
import { IExternalLinkProps, ITelemetryData } from '@shared/Models';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { getWindow } from '@shared/services/window';
import { Constants } from '@shared/utils/constants';
import { IProvider } from 'services/telemetry/core/provider';
import { logger } from '@src/logger';
/**
 * Behaves similar with HTML DOM <a></a>
 * But dedicated to open a new tab, and log telemetry for link clicked.
 * When a link contains a 'mail:to', instead of opening a new tab, showing an email dialog
 */

export class ExternalLink extends SpzaComponent<IExternalLinkProps, any> {
  context: IBuildHrefContext & ICommonContext & ILocContext & ILocParamsContext;
  private provider: IProvider;

  constructor(props: IExternalLinkProps, context: IBuildHrefContext & ICommonContext & ILocContext & ILocParamsContext) {
    super(props, context);
    this.provider = SpzaInstrumentService.getProvider();
  }

  renderImpl() {
    const { href, children, telemetryDetails, ...rest } = this.props;
    const attributes = getAttributes({ href, children, ...rest });

    return (
      <a
        {...attributes}
        onClick={(event) => {
          const payload: ITelemetryData = {
            page: getWindow().location.href,
            action: Constants.Telemetry.Action.Click,
            actionModifier: Constants.Telemetry.ActionModifier.ExternalLink,
            details: telemetryDetails || JSON.stringify(href),
          };
          this.provider.probe<ITelemetryData>(Constants.Telemetry.ProbeName.LogInfo, payload);
          logger.info(payload.details, {
            action: payload.action,
            actionModifier: payload.actionModifier,
          });
          this.props.onClickHandle?.(event);
        }}
      >
        {children}
      </a>
    );
  }
}
