import * as React from 'react';
import { ReportAbuseModal } from '../containers/modals/reportAbuseModal';
import { IUserDataState } from '../../State';
import { Constants } from '../../shared/utils/constants';
import { SignInModal } from '../../shared/components/modals/signInModal';

export function handleReportAbuseModalCall(
  appId: string,
  reviewId: string,
  reviewTitle: string,
  userInfo: IUserDataState,
  reviewSource: string,
  dismissModal: () => void
) {
  if (!userInfo.signedIn) {
    return (
      <SignInModal
        redirect="reportAbuse"
        entityId={reviewId}
        reviewAppId={appId}
        reviewTitle={reviewTitle}
        dismissModal={dismissModal}
        signInModalType={Constants.SignInType.SignInWith_MSA_AAD}
      />
    );
  } else {
    return (
      <ReportAbuseModal
        appId={appId}
        reviewId={reviewId}
        reviewTitle={reviewTitle}
        reviewSource={reviewSource}
        dismissModal={dismissModal}
      />
    );
  }
}
