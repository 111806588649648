import * as React from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { IState } from '@src/State';
import { getTelemetryDetailContentCurrentView, isWebApp, isOfficeSaasApp } from '@shared/utils/appUtils';
import { PricingStates, LinkedSaaS, CheckoutSource } from '@shared/Models';
import { isAppSource, isEmbed } from '@embed/embedHostUtils';
import { getPrimaryProductUrl } from '@shared/utils/datamappingHelpers';
import { createSetCheckoutSource } from '@shared/actions/checkoutActions';
import { ITileDispatchProps } from '@shared/components/commonTile';
import { IAppTileProps, AppTile as AppTileComponent } from '@shared/components/appTile/appTile';
import { isMaccUser } from '@shared/utils/userUtils';

// If the Addin has a preview offer in his linkedSaas, we dont need to count them. only the non-preview offers
const previewOffers = '-preview';

const skipPreviewSaaS = (linkedSaas: LinkedSaaS[]) => {
  const linkedItemsIds = linkedSaas.map(({ entityId }) => entityId);
  return linkedItemsIds.filter((entityId) => !entityId.endsWith(previewOffers));
};

const mapStateToProps = (state: IState, ownProps: IAppTileProps): IAppTileProps => {
  const productUrlkey = getPrimaryProductUrl(ownProps.primaryProduct);

  let shouldShowLinkedAddIn = ownProps.shouldShowLinkedAddIn;
  let shouldShowLinkedWebApps = ownProps.linkedSaaS?.length > 0 && skipPreviewSaaS(ownProps.linkedSaaS).length > 0;
  if (isWebApp(productUrlkey) || isOfficeSaasApp(productUrlkey, ownProps.products)) {
    if (
      (isAppSource(ownProps.embedHost) || isEmbed(ownProps.embedHost)) &&
      ownProps.linkedAddIns !== undefined &&
      ownProps.linkedAddIns.length > 0
    ) {
      shouldShowLinkedAddIn = true;
      shouldShowLinkedWebApps = false;
    }
  } else if (!(isWebApp(productUrlkey) && isOfficeSaasApp(productUrlkey, ownProps.products)) && shouldShowLinkedWebApps) {
    shouldShowLinkedAddIn = false;
  }

  const {
    billingCountryCode,
    correlationId,
    isEmbedded,
    currentView,
    currentGalleryViewIsCurated,
    isMobile,
    featureFlags,
    query,
    requestId: searchReqId,
    locale,
  } = state.config;
  const { signedIn, profile, loading } = state.users;
  const { ShowPopularity } = featureFlags;
  const { activeFilters: appsActiveFilters, count: appsCount } = state.apps;
  const { activeFilters: servicesActiveFilters, count: servicesCount } = state.services;

  const managedLicenses = profile?.managedLicenses || [];
  const doesTenantHasLicenseForApp = managedLicenses.some((x) => x.productId === ownProps.bigId);

  const startingPrice =
    isEmbedded && !(ownProps.licenseManagement && ownProps.licenseManagement.isMicrosoftManaged)
      ? { pricingData: PricingStates.AlwaysAvailable }
      : ownProps.startingPrice;

  return {
    ...ownProps,
    billingCountryCode,
    startingPrice,
    tileDataRequestId: state.apps.tileDataRequestId,
    correlationId,
    isEmbedded,
    signedIn,
    currentView: getTelemetryDetailContentCurrentView(currentView, currentGalleryViewIsCurated),
    isMobile,
    showPopularity: ShowPopularity === 'true',
    uiRole: profile && profile.uiRole,
    shouldShowLinkedAddIn,
    shouldShowLinkedWebApps,
    doesTenantHasLicenseForApp,
    isLoadingUserData: loading,
    searchSortingBy: query.sortBy,
    activeFilters: currentView === 'appGallery' ? appsActiveFilters : servicesActiveFilters,
    count: currentView === 'appGallery' ? appsCount : servicesCount,
    searchReqId,
    locale,
    isMaccUser: isMaccUser(state.users),
  };
};

export const mapDispatchToProps = (dispatch: Dispatch): ITileDispatchProps => {
  return { setTileCheckoutSource: () => dispatch(createSetCheckoutSource({ source: CheckoutSource.Tile })) };
};

export const AppTile = connect(mapStateToProps, mapDispatchToProps)(AppTileComponent) as unknown as React.StatelessComponent<any>;
