import * as PropTypes from 'prop-types';
import SpzaComponent from '@shared/components/spzaComponent';
import { IBuildHrefContext, ICommonContext } from '@shared/interfaces/context';
import { attachClickHandlerToElement } from '@shared/utils/appUtils';
import { Constants } from '@shared/utils/constants';
import { getWindow } from 'services/window';
import { SpzaInstrumentProvider, SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { ITelemetryData } from '@shared/Models';
import { logger } from '@src/logger';

export class Footer extends SpzaComponent<any, any> {
  context: IBuildHrefContext & ICommonContext;
  private instrument: SpzaInstrumentProvider;

  constructor(props: unknown, context: IBuildHrefContext & ICommonContext) {
    super(props, context);
    this.instrument = SpzaInstrumentService.getProvider();
  }

  componentDidMount() {
    const { toggleContextPanel } = this.props;

    const handleShowContextPanel = () => {
      return (event: any) => {
        const payload: ITelemetryData = {
          page: this.props.location.pathname,
          action: Constants.Telemetry.Action.Country,
          actionModifier: Constants.Telemetry.ActionModifier.FooterLocale,
        };
        this.instrument.probe<ITelemetryData>(Constants.Telemetry.ProbeName.LogInfo, payload);
        logger.info(payload.details, {
          page: payload.page,
          action: payload.action,
          actionModifier: payload.actionModifier,
        });

        toggleContextPanel();
        event.preventDefault();
      };
    };

    // locale picker
    attachClickHandlerToElement('locale-picker-link', handleShowContextPanel());

    this.manageCookiesBtn();
  }

  componentDidUpdate() {
    this.manageCookiesBtn();
  }

  // manage Cookies button in Appsource footer.
  manageCookiesBtn() {
    const hiddenClass = 'x-hidden';

    if (
      document &&
      document.getElementById(Constants.Cookies.WCPManageCookieBtnId) &&
      document.getElementById(Constants.Cookies.WCPManageCookieBtnId).className.indexOf(hiddenClass) >= 0 &&
      this.props.isManageCookieBtnRequired
    ) {
      const manageCookieOnClick = () => {
        const telemetryData: ITelemetryData = {
          page: getWindow().location.href,
          action: Constants.Telemetry.Action.ConsentBanner,
          actionModifier: Constants.Telemetry.ActionModifier.Info,
          details: 'manage button clicked',
        };
        SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', telemetryData);
        logger.info(telemetryData.details, {
          action: telemetryData.action,
          actionModifier: telemetryData.actionModifier,
        });

        this.props.openCookieManagementModal();
      };

      document.getElementById(Constants.Cookies.WCPManageCookieBtnId).classList.remove(hiddenClass);
      attachClickHandlerToElement(Constants.Cookies.WCPManageCookieBtnId, manageCookieOnClick);
    }
  }

  renderImpl() {
    return null as any;
  }
}

(Footer as any).contextTypes = {
  buildHref: PropTypes.func,
  renderErrorModal: PropTypes.func,
};
