/* eslint-disable react/forbid-dom-props */
import { ICommonContext, ILocContext, ILocParamsContext } from 'interfaces/context';
import React from 'react';
import { IAppReview } from '@shared/Models';
import { ISortByHelper, IUpdateReviewsStateByDropDowns, launchTelemetry } from '@shared/utils/reviewsUtils';
import { Constants } from '@shared/utils/constants';
import { Dropdown } from '@fluentui/react';
import type { IDropdownOption } from '@fluentui/react';

import { logger } from '@src/logger';

export interface IReviewDropDowns {
  curSourceFilter: string;
  curSortBy: string;
  curRatingFilter: number;
  originalReviewList: IAppReview[];
  curReviewsList: IAppReview[];
  context: ILocContext & ILocParamsContext & ICommonContext;
  updateReviewsState: IUpdateReviewsStateByDropDowns;
  externalReviewsExist: boolean;
  internalReviewsExist: boolean;
  userReview: IAppReview;
  userCommentedReviewIds: string[];
  showMyCommentsFilter: boolean;
}

export enum SourceFilterOptions {
  AllReviews = 'All reviews',
  MarketplaceReviews = 'Marketplace reviews',
  G2Reviews = 'G2 reviews',
  MyReview = 'My review',
  MyComments = 'My comments',
}

export enum RatingsFilterOptions {
  AllRatings = 'All ratings',
  OneStar = '1 star',
  TwoStars = '2 stars',
  ThreeStars = '3 stars',
  FourStars = '4 stars',
  FiveStars = '5 stars',
}

export enum SortByOptions {
  MostRecent = 'Most recent',
  HighToLow = 'High to low',
  LowToHigh = 'Low to high',
  MostHelpful = 'Most Helpful',
}

export const isUserCommentsExist = (userCommentedReviewIds: string[]): boolean => userCommentedReviewIds.length > 0;

export function getFilterBySourceCategories(
  context: ILocContext,
  externalReviewsExist: boolean,
  userReviewExists: boolean,
  internalReviewsExist: boolean,
  userCommentsExist = false,
  showMyCommentsFilter = false
): IDropdownOption[] {
  const filterBySourceCategories: IDropdownOption[] = [
    {
      text: context.loc('AppReviewCollection_sourceFilter_AllReviews', 'All reviews'),
      key: SourceFilterOptions.AllReviews,
    },
  ];

  filterBySourceCategories.push({
    text: context.loc('AppReviewCollection_sourceFilter_MarketplaceReviews', 'Marketplace reviews'),
    key: SourceFilterOptions.MarketplaceReviews,
    disabled: !internalReviewsExist,
  });

  filterBySourceCategories.push({
    text: context.loc('AppReviewCollection_sourceFilter_G2Reviews', 'G2 reviews'),
    key: SourceFilterOptions.G2Reviews,
    disabled: !externalReviewsExist,
  });

  filterBySourceCategories.push({
    text: context.loc('AppReviewCollection_sourceFilter_MyReview', 'My review'),
    key: SourceFilterOptions.MyReview,
    disabled: !userReviewExists,
  });

  if (showMyCommentsFilter) {
    filterBySourceCategories.push({
      text: context.loc('AppReviewCollection_sourceFilter_MyComments', 'My comments'),
      key: SourceFilterOptions.MyComments,
      disabled: !userCommentsExist,
    });
  }

  return filterBySourceCategories;
}

export function getSourceFilterOptionByLocaliztionText({
  text,
  context,
  userCommentsExist = false,
  showMyCommentsFilter = false,
}: {
  text: string;
  context: ILocContext;
  userCommentsExist: boolean;
  showMyCommentsFilter: boolean;
}): IDropdownOption {
  const allSourceOptions: IDropdownOption[] = getFilterBySourceCategories(
    context,
    true,
    true,
    true,
    userCommentsExist,
    showMyCommentsFilter
  );
  const optionsByText: IDropdownOption = allSourceOptions.find((option) => option.text === text);
  return optionsByText || allSourceOptions[0];
}

export function getSortByCategories(context: ILocContext): IDropdownOption[] {
  const sortByCategories: IDropdownOption[] = [
    { text: context.loc('AppReviewCollection_sortReviews_MostRecent', 'Most recent'), key: SortByOptions.MostRecent },
    { text: context.loc('AppReviewCollection_sortReviews_ByRatingHighToLow', 'Highest rating'), key: SortByOptions.HighToLow },
    { text: context.loc('AppReviewCollection_sortReviews_ByRatingLowToHigh', 'Lowest rating'), key: SortByOptions.LowToHigh },
    { text: context.loc('AppReviewCollection_sortReviews_MostHelpful', 'Most helpful'), key: SortByOptions.MostHelpful },
  ];
  return sortByCategories;
}

export function getSortByOptionByLocaliztionText(text: string, context: ILocContext): IDropdownOption {
  const allSortByOptions: IDropdownOption[] = getSortByCategories(context);
  const optionsByText: IDropdownOption = allSortByOptions.find((option) => option.text === text);
  return optionsByText || allSortByOptions[0];
}

export function getRatingsFilterNumber(option: IDropdownOption): number {
  switch (option.key) {
    case RatingsFilterOptions.OneStar:
      return 1;

    case RatingsFilterOptions.TwoStars:
      return 2;

    case RatingsFilterOptions.ThreeStars:
      return 3;

    case RatingsFilterOptions.FourStars:
      return 4;

    case RatingsFilterOptions.FiveStars:
      return 5;

    default:
      return null;
  }
}

export function getRatingsFilterOptionByRating(rating: number, context: ILocContext): IDropdownOption {
  switch (rating) {
    case 1:
      return {
        text: context.loc('AppReviewCollection_ratingsFilter_OneStar', '1 star'),
        key: RatingsFilterOptions.OneStar,
      };

    case 2:
      return {
        text: context.loc('AppReviewCollection_ratingsFilter_TwoStars', '2 stars'),
        key: RatingsFilterOptions.TwoStars,
      };

    case 3:
      return {
        text: context.loc('AppReviewCollection_ratingsFilter_ThreeStars', '3 stars'),
        key: RatingsFilterOptions.ThreeStars,
      };

    case 4:
      return { text: context.loc('AppReviewCollection_ratingsFilter_FourStars', '4 stars'), key: RatingsFilterOptions.FourStars };

    case 5:
      return { text: context.loc('AppReviewCollection_ratingsFilter_FiveStars', '5 stars'), key: RatingsFilterOptions.FiveStars };

    default:
      return {
        text: context.loc('AppReviewCollection_ratingsFilter_AllRatings', 'All ratings'),
        key: RatingsFilterOptions.AllRatings,
      };
  }
}

export function getRatingsFilterCategories(context: ILocContext): IDropdownOption[] {
  const ratingsFilterCategories: IDropdownOption[] = [
    {
      text: context.loc('AppReviewCollection_ratingsFilter_AllRatings', 'All ratings'),
      key: RatingsFilterOptions.AllRatings,
    },
    {
      text: context.loc('AppReviewCollection_ratingsFilter_OneStar', '1 star'),
      key: RatingsFilterOptions.OneStar,
    },
    {
      text: context.loc('AppReviewCollection_ratingsFilter_TwoStars', '2 stars'),
      key: RatingsFilterOptions.TwoStars,
    },
    {
      text: context.loc('AppReviewCollection_ratingsFilter_ThreeStars', '3 stars'),
      key: RatingsFilterOptions.ThreeStars,
    },
    { text: context.loc('AppReviewCollection_ratingsFilter_FourStars', '4 stars'), key: RatingsFilterOptions.FourStars },
    { text: context.loc('AppReviewCollection_ratingsFilter_FiveStars', '5 stars'), key: RatingsFilterOptions.FiveStars },
  ];
  return ratingsFilterCategories;
}

export function handleRatingsFilter(
  option: IDropdownOption,
  isDropDownChanged: boolean,
  originalReviewList: IAppReview[]
): IAppReview[] {
  let filteredArray: IAppReview[] = [];
  const updatedRatingsFilter: number = getRatingsFilterNumber(option);

  if (isDropDownChanged) {
    filteredArray = updatedRatingsFilter
      ? originalReviewList.filter((review: IAppReview) => review.rating === updatedRatingsFilter)
      : originalReviewList;

    const telemetryDetails = {
      ratingsFilter: updatedRatingsFilter,
    };

    launchTelemetry(Constants.Telemetry.Action.Click, Constants.Telemetry.ActionModifier.FilterPDPReviews, telemetryDetails);
    logger.info(JSON.stringify(telemetryDetails), {
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.FilterPDPReviews,
    });
  }

  return filteredArray;
}

export function filterBySource(
  option: IDropdownOption,
  reviewsList: IAppReview[],
  userReview: IAppReview,
  userCommentedReviewIds: string[]
) {
  switch (option.key) {
    case SourceFilterOptions.MarketplaceReviews:
      return reviewsList.filter((review) => Constants.InternalStoreFronts.indexOf(review.source) > -1);
    case SourceFilterOptions.G2Reviews:
      return reviewsList.filter((review) => review.source === Constants.StorefrontName.G2);
    case SourceFilterOptions.MyReview:
      reviewsList = userReview ? [userReview] : [];
      return reviewsList;
    case SourceFilterOptions.MyComments:
      return reviewsList.filter((review) => userCommentedReviewIds.indexOf(review.id) > -1);
    default:
      return reviewsList;
  }
}

export function handleSourceFilter(
  option: IDropdownOption,
  isDropDownChanged: boolean,
  reviewsList: IAppReview[],
  userReview: IAppReview,
  userCommentedReviewIds: string[]
): IAppReview[] {
  let filteredArray: IAppReview[] = [];

  if (isDropDownChanged) {
    filteredArray = filterBySource(option, reviewsList, userReview, userCommentedReviewIds);

    const telemetryDetails = {
      reviewsSourceFilter: option.text,
    };

    launchTelemetry(Constants.Telemetry.Action.Click, Constants.Telemetry.ActionModifier.FilterPDPReviews, telemetryDetails);
    logger.info(JSON.stringify(telemetryDetails), {
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.FilterPDPReviews,
    });
  }

  return filteredArray;
}

export function getSortByStatus(
  curReviewsList: IAppReview[],
  option: IDropdownOption,
  isDropDownChanged: boolean,
  curSortBy: string,
  context: ILocContext
) {
  const sortByHelper: ISortByHelper = { sortedReviewsList: curReviewsList, updatedSortBy: curSortBy };
  switch (option.key) {
    case SortByOptions.HighToLow:
      sortByHelper.updatedSortBy = context.loc('AppReviewCollection_sortReviews_ByRatingHighToLow');
      if (isDropDownChanged) {
        sortByHelper.sortedReviewsList = sortByHelper.sortedReviewsList.sort((first, second) => second.rating - first.rating);
      }
      break;

    case SortByOptions.LowToHigh:
      sortByHelper.updatedSortBy = context.loc('AppReviewCollection_sortReviews_ByRatingLowToHigh');
      if (isDropDownChanged) {
        sortByHelper.sortedReviewsList = sortByHelper.sortedReviewsList.sort((first, second) => first.rating - second.rating);
      }
      break;
    case SortByOptions.MostHelpful:
      sortByHelper.updatedSortBy = context.loc('AppReviewCollection_sortReviews_MostHelpful');
      if (isDropDownChanged) {
        sortByHelper.sortedReviewsList = sortByHelper.sortedReviewsList.sort(
          (first, second) => second.mark_as_helpful_count - first.mark_as_helpful_count
        );
      }
      break;

    default:
      sortByHelper.updatedSortBy = context.loc('AppReviewCollection_sortReviews_MostRecent');
      if (isDropDownChanged) {
        sortByHelper.sortedReviewsList = sortByHelper.sortedReviewsList.sort(
          (first, second) => new Date(second.updated_at).valueOf() - new Date(first.updated_at).valueOf()
        );
      }
      break;
  }
  return sortByHelper;
}

export function handleSortBy(
  option: IDropdownOption,
  updatedReviewsList: IAppReview[],
  curReviewsList: IAppReview[],
  isDropDownChanged: boolean,
  sortBy: string,
  context: ILocContext,
  updateReviewsSortByState: (updatedSortBy: string) => void
): IAppReview[] {
  const sortByHelper: ISortByHelper = getSortByStatus(updatedReviewsList, option, isDropDownChanged, sortBy, context);

  if (curReviewsList && isDropDownChanged) {
    updateReviewsSortByState(sortByHelper.updatedSortBy);

    const telemetryDetails = {
      sortBy: sortByHelper.updatedSortBy,
    };

    launchTelemetry(Constants.Telemetry.Action.Click, Constants.Telemetry.ActionModifier.SortPDPReviews, telemetryDetails);
    logger.info(JSON.stringify(telemetryDetails), {
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.SortPDPReviews,
    });
  }

  return sortByHelper.sortedReviewsList;
}

export function handleDropDowns(
  sourceFilterOption: IDropdownOption,
  ratingFilterOption: IDropdownOption,
  sortByOption: IDropdownOption,
  updateReviewsState: IUpdateReviewsStateByDropDowns,
  originalReviewList: IAppReview[],
  curReviewsList: IAppReview[],
  sourceFilter: string,
  sortBy: string,
  ratingFilter: number,
  context: ILocContext,
  userReview: IAppReview,
  userCommentedReviewIds: string[] = []
) {
  const updatedRatingsFilter = getRatingsFilterNumber(ratingFilterOption);
  const isDropDownChanged: boolean =
    ratingFilter !== updatedRatingsFilter || sortBy !== sortByOption.text || sourceFilterOption.text !== sourceFilter;
  let filteredArray: IAppReview[] = handleRatingsFilter(ratingFilterOption, isDropDownChanged, originalReviewList);
  filteredArray = handleSourceFilter(sourceFilterOption, isDropDownChanged, filteredArray, userReview, userCommentedReviewIds);
  const fliteredAndSortedArray = handleSortBy(
    sortByOption,
    filteredArray,
    curReviewsList,
    isDropDownChanged,
    sortBy,
    context,
    updateReviewsState.updateStateBySortFilter
  );

  if (isDropDownChanged) {
    updateReviewsState.updateStateByFilters(updatedRatingsFilter, fliteredAndSortedArray, sourceFilterOption.text);
  }

  // Go to first page and update pagination if the reviews list was changed
  if (fliteredAndSortedArray.length) {
    const updatedCurrentEnd =
      Constants.MaxItemsPerPageInReviews < filteredArray.length ? Constants.MaxItemsPerPageInReviews : filteredArray.length;
    updateReviewsState.updatePaginationState(1, 1, updatedCurrentEnd);
  }
}

export function renderSourceFilterDropDown({
  curSortBy,
  curRatingFilter,
  context,
  updateReviewsState,
  originalReviewList,
  curReviewsList,
  curSourceFilter,
  externalReviewsExist,
  userReview,
  internalReviewsExist,
  userCommentedReviewIds,
  showMyCommentsFilter,
}: IReviewDropDowns) {
  const sourceFilterCategories: IDropdownOption[] = getFilterBySourceCategories(
    context,
    externalReviewsExist,
    !!userReview,
    internalReviewsExist,
    isUserCommentsExist(userCommentedReviewIds),
    showMyCommentsFilter
  );
  let activeFilterOption: IDropdownOption = sourceFilterCategories.find((option) => option.text === curSourceFilter);
  activeFilterOption = activeFilterOption ?? sourceFilterCategories[0];
  return (
    <Dropdown
      className="reviewDropDown sourceFilter"
      defaultSelectedKey={activeFilterOption.key}
      options={sourceFilterCategories}
      dropdownWidth={'grow'}
      ariaLabel={activeFilterOption.text}
      onChange={(e, option: IDropdownOption) => {
        const sortBy: IDropdownOption = getSortByOptionByLocaliztionText(curSortBy, context);
        const ratingFilter: IDropdownOption = getRatingsFilterOptionByRating(curRatingFilter, context);
        handleDropDowns(
          option,
          ratingFilter,
          sortBy,
          updateReviewsState,
          originalReviewList,
          curReviewsList,
          curSourceFilter,
          curSortBy,
          curRatingFilter,
          context,
          userReview,
          userCommentedReviewIds
        );
      }}
    />
  );
}

export function renderRatingFilterDropDown(
  curSortBy: string,
  curSourceFilter: string,
  updateReviewsState: IUpdateReviewsStateByDropDowns,
  originalReviewList: IAppReview[],
  curReviewsList: IAppReview[],
  curRatingFilter: number,
  context: ILocContext & ILocParamsContext & ICommonContext,
  userReview: IAppReview,
  userCommentedReviewIds: string[],
  showMyCommentsFilter: boolean
) {
  const ratingsFilterCategories = getRatingsFilterCategories(context);
  const curRatingFilterOption: IDropdownOption = getRatingsFilterOptionByRating(curRatingFilter, context);
  let activeFilterOption: IDropdownOption = ratingsFilterCategories.find((option) => option.text === curRatingFilterOption.text);
  activeFilterOption = activeFilterOption ?? ratingsFilterCategories[0];
  return (
    <Dropdown
      className="reviewDropDown ratingFilter"
      defaultSelectedKey={activeFilterOption.key}
      options={ratingsFilterCategories}
      ariaLabel={curRatingFilterOption.text}
      dropdownWidth={'grow'}
      onChange={(e, option: IDropdownOption) => {
        const sortBy: IDropdownOption = getSortByOptionByLocaliztionText(curSortBy, context);
        const sourceFilter: IDropdownOption = getSourceFilterOptionByLocaliztionText({
          text: curSourceFilter,
          context: context,
          userCommentsExist: isUserCommentsExist(userCommentedReviewIds),
          showMyCommentsFilter: showMyCommentsFilter,
        });
        handleDropDowns(
          sourceFilter,
          option,
          sortBy,
          updateReviewsState,
          originalReviewList,
          curReviewsList,
          curSourceFilter,
          curSortBy,
          curRatingFilter,
          context,
          userReview,
          userCommentedReviewIds
        );
      }}
    />
  );
}

export function renderSortByDropDown({
  curRatingFilter,
  context,
  curSourceFilter,
  updateReviewsState,
  originalReviewList,
  curReviewsList,
  curSortBy,
  userReview,
  userCommentedReviewIds,
  showMyCommentsFilter,
}: {
  curRatingFilter: number;
  context: ILocContext & ILocParamsContext & ICommonContext;
  curSourceFilter: string;
  updateReviewsState: IUpdateReviewsStateByDropDowns;
  originalReviewList: IAppReview[];
  curReviewsList: IAppReview[];
  curSortBy: string;
  userReview: IAppReview;
  userCommentedReviewIds: string[];
  showMyCommentsFilter: boolean;
}) {
  const sortByCategories = getSortByCategories(context);
  let activeFilterOption: IDropdownOption = sortByCategories.find((option) => option.text === curSortBy);
  activeFilterOption = activeFilterOption ?? sortByCategories[0];
  return (
    <Dropdown
      className="reviewDropDown sortByDropDown"
      defaultSelectedKey={activeFilterOption.key}
      options={sortByCategories}
      aria-labelledby="reviewsSortByLabel"
      dropdownWidth={'grow'}
      ariaLabel={context.loc('AppReviewCollection_sortReviews_TitleText', 'Sort by')}
      onChange={(e, option: IDropdownOption) => {
        const ratingFilter: IDropdownOption = getRatingsFilterOptionByRating(curRatingFilter, context);
        const sourceFilter: IDropdownOption = getSourceFilterOptionByLocaliztionText({
          text: curSourceFilter,
          context: context,
          userCommentsExist: isUserCommentsExist(userCommentedReviewIds),
          showMyCommentsFilter: showMyCommentsFilter,
        });
        handleDropDowns(
          sourceFilter,
          ratingFilter,
          option,
          updateReviewsState,
          originalReviewList,
          curReviewsList,
          curSourceFilter,
          curSortBy,
          curRatingFilter,
          context,
          userReview,
          userCommentedReviewIds
        );
      }}
    />
  );
}

export const ReviewsDropDowns = ({
  curSourceFilter = null,
  curSortBy = null,
  curRatingFilter = null,
  context = null,
  updateReviewsState = null,
  originalReviewList = [],
  curReviewsList = [],
  externalReviewsExist = false,
  userReview = null,
  internalReviewsExist = false,
  userCommentedReviewIds = [],
  showMyCommentsFilter = false,
}: IReviewDropDowns) => {
  return (
    <div className="reviewsTopBarDropDowns">
      <div className="reviewsFilter">
        <div className="sourceFilterIcon"></div>
        {renderSourceFilterDropDown({
          curSortBy,
          curRatingFilter,
          context,
          updateReviewsState,
          originalReviewList,
          curReviewsList,
          curSourceFilter,
          externalReviewsExist,
          internalReviewsExist,
          userReview,
          userCommentedReviewIds,
          showMyCommentsFilter,
        })}
      </div>
      <div className="reviewsFilter">
        <div className="ratingsFilterIcon"></div>
        {renderRatingFilterDropDown(
          curSortBy,
          curSourceFilter,
          updateReviewsState,
          originalReviewList,
          curReviewsList,
          curRatingFilter,
          context,
          userReview,
          userCommentedReviewIds,
          showMyCommentsFilter
        )}
      </div>
      <div className="reviewsSortBy">
        <div className="sortByIconAndText" id="reviewsSortByLabel">
          {context.loc('AppReviewCollection_sortReviews_TitleText')}
        </div>
        {renderSortByDropDown({
          curRatingFilter,
          context,
          curSourceFilter,
          updateReviewsState,
          originalReviewList,
          curReviewsList,
          curSortBy,
          userReview,
          userCommentedReviewIds,
          showMyCommentsFilter,
        })}
      </div>
    </div>
  );
};
