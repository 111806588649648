import * as httpProtocol from './httpProtocol';
import { logError } from '../../utils/httpClientUtil';
import { getAppConfig } from '../../services/init/appConfig';

const option: httpProtocol.IHttpOption = {
  requireCorrelationId: true,
  stringifyPostData: false,
  authenticationType: httpProtocol.AuthenticationTypes.Unauthenticated,
  contentType: 'text/html',
};

export function getDynamicCampaign(request: any) {
  const campaignId = request.params.id;
  const publisher = request.params.publisher;
  const isPreview: boolean = request.query.preview === 'true';
  const endpoint = `/view/campaigns/${publisher}/${campaignId}`;
  const get = httpProtocol.get(endpoint, option);
  if (isPreview) get.addQueryEntry('preview', request.query.preview);

  return get
    .addQueryEntry('version', getAppConfig('apiVersion'))
    .request()
    .catch((error: any) => {
      logError('getDynamicCampaign', error);
      return Promise.reject(error);
    });
}
