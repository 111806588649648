/* istanbul ignore file */
import React, { useEffect, useState } from 'react';

/**
 * This hook is used in functional components.
 * Use it whenever you must render only when the hydration phase is complete (i.e. first lifecycle has been invoked)
 */
export function useHydration(): boolean {
  const [isHydrated, setIsHydrated] = useState<boolean>(false);

  useEffect(() => {
    setIsHydrated(true);
  }, []);

  return isHydrated;
}

export interface WithHydrationProps {
  isHydrated: boolean;
}
/**
 * Used for legacy class components. Please avoid using this and use the hook {@link useHydration} instead inside a functional component.
 */
export function withHydration<T extends WithHydrationProps>(WrappedComponent: React.ComponentClass<T>) {
  return function ComponentWithHydration(props: Omit<T, keyof WithHydrationProps>) {
    const isHydrated = useHydration();

    return <WrappedComponent {...(props as T)} isHydrated={isHydrated} />;
  };
}
