import { BrowserCacheLocation, Configuration } from '@azure/msal-browser';
import { getWindow } from '@shared/services/window';
import { getRedirectLoginUrl } from './windowUtils';

export const getSilentRedirectUri = () => `${getRedirectLoginUrl()}/auth-redirect.html`;
export const getImplicitRedirectUri = () => `${getRedirectLoginUrl()}/redirect`;

export const getConfig = (): Configuration => {
  const { aadApplicationId, aadLoginUrl } = getWindow().__INITIAL_STATE__.config;

  return {
    auth: {
      clientId: aadApplicationId,
      authority: `${aadLoginUrl}organizations`,
      redirectUri: getSilentRedirectUri(),
      navigateToLoginRequestUrl: true,
    },
    system: {
      allowNativeBroker: false,
      iframeHashTimeout: 20000,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.MemoryStorage,
      storeAuthStateInCookie: true,
      secureCookies: true,
    },
  };
};
