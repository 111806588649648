import React, { useEffect } from 'react';
import {
  // eslint-disable-next-line import/named
  IProgressIndicatorStyles,
  mergeStyleSets,
  Stack,
  Text,
  FontSizes,
  ProgressIndicator,
  ScreenWidthMinXLarge,
} from '@fluentui/react';
import OptimizingImage from '@shared/images/optimizingImage.svg';
import { TelemetryImage } from '@shared/components/telemetryImage';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';

const OrderLoadingStyles: Partial<IProgressIndicatorStyles> = {
  itemDescription: { textAlign: 'center' },
};
const contentStyles = mergeStyleSets({
  itemName: { fontSize: FontSizes.size28, fontWeight: 900, marginTop: '20px', textAlign: 'center' },
  progressContainer: {
    width: '640px',
    height: '680px',
    zIndex: '101',
    backgroundColor: '#FFFFFF',
    position: 'absolute',
    margin: 'auto',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    padding: '120px 80px',
    [`@media (max-width: ${ScreenWidthMinXLarge}px)`]: {
      width: '320px',
      height: '440px',
      padding: '60px 40px',
    },
  },
});

interface IOrderLoading {
  context: ILocContext & ILocParamsContext;
}

export const OrderLoading: React.FunctionComponent<IOrderLoading> = ({ context }: IOrderLoading) => {
  const progressMessages = ['Loader_Starting', 'Loader_Verifiying', 'Loader_Processing'];
  const intervalDelay = 2500;
  const intervalIncrement = 0.1;
  const [progressMessage, setProgressMessage] = React.useState(progressMessages[0]);
  const [percentComplete, setPercentComplete] = React.useState(0);

  useEffect(() => {
    if (percentComplete <= 0.9) {
      const id = setInterval(() => {
        setPercentComplete((intervalIncrement + percentComplete) % 1);
        switch (Math.round((percentComplete * 10) / 2)) {
          case 0:
          case 1:
            setProgressMessage(progressMessages[0]);
            break;
          case 2:
            setProgressMessage(progressMessages[1]);
            break;
          case 3:
            setProgressMessage(progressMessages[2]);
            break;
        }
      }, intervalDelay);
      return () => {
        clearInterval(id);
      };
    }
  }, [percentComplete, progressMessages]);

  return (
    <Stack className={contentStyles.progressContainer} tokens={{ childrenGap: 20 }}>
      <Stack.Item align="center">
        <TelemetryImage alt="placeOrderImage" src={OptimizingImage} />
      </Stack.Item>
      <Text title={context.loc(progressMessage)} className={contentStyles.itemName}>
        {context.loc(progressMessage)}
      </Text>
      <ProgressIndicator
        description={context.loc('Loader_Description')}
        percentComplete={percentComplete}
        barHeight={2}
        styles={OrderLoadingStyles}
      />
    </Stack>
  );
};
