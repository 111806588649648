import { IAppDataItem } from '../../shared/Models';
import React, { ReactNode } from 'react';
import { Constants } from '../../shared/utils/constants';
import SpzaComponent from '../../shared/components/spzaComponent';

export interface MicrosoftManagedIndicatorWrapperProps {
  uiRole?: Constants.UiRole;
  app: IAppDataItem;
  isAvailableInRegion: boolean;
  renderIndicator(): ReactNode;
}

export class MicrosoftManagedIndicatorWrapper extends SpzaComponent<MicrosoftManagedIndicatorWrapperProps, any> {
  renderImpl() {
    const { uiRole, app, isAvailableInRegion, renderIndicator } = this.props;

    const userIsAdmin = uiRole === Constants.UiRole.Admin;

    if (!app?.licenseManagement?.isMicrosoftManaged || !isAvailableInRegion || !uiRole || userIsAdmin) {
      return null;
    }

    return renderIndicator();
  }
}
