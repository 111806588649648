import { ApplicationInsights } from '@microsoft/1ds-analytics-web-js';
import { ClientLoggerTelemetry, clientLoggerConfig, SystemLoggerTelemetry } from '@shared/logger';
import { ServerLogger, winstonLogger } from '@server/serverLogger';
import { Logger } from './logger';
import type { Store } from 'redux';
import { IState } from '@src/State';

const clientLogger = new ClientLoggerTelemetry();
const serverLogger = new ServerLogger();

export const logger = new Logger(clientLogger, serverLogger);
export const systemLogger = new SystemLoggerTelemetry();

export const ensureClientLoggerInitialization = (store: Store<IState>) => {
  if (!logger.clientLogger.logger) {
    const _1dsLogger: ApplicationInsights = new ApplicationInsights();
    _1dsLogger.initialize(clientLoggerConfig(), []);
    logger.clientLogger.initialize(_1dsLogger, store);
    systemLogger.initialize(_1dsLogger, store);
  }
};

export const initServerLogger = () => {
  if (!logger.serverLogger.logger) {
    const _winstonLogger = winstonLogger();
    logger.serverLogger.init(_winstonLogger);
  }
};
