import * as React from 'react';
import { IState } from '@src/State';
import { upsertUserFavourite, deleteUserFavourite, fetchUserFavourites } from '@shared/actions/userFavouriteThunkActions';
import { UserFavouriteTileDetailButton as UserFavouriteTileDetailButtonComponent } from '@shared/components/userFavouriteTileDetailButton';
import {
  IUserFavouritePostPayload,
  IUserFavouriteTileDetailButtonContainerProps,
  IUserFavouriteItem,
} from '@shared/interfaces/userFavouriteModels';
import { Constants } from '@shared/utils/constants';
import { connect, Dispatch } from 'react-redux';

export const mapStateToProps = (state: IState, ownProps: IUserFavouriteTileDetailButtonContainerProps) => {
  return {
    userInfo: state.users,
    userFavourite: state.userFavourite,
    entityId: ownProps.entityId,
    entityType: ownProps.entityType,
    item: ownProps.item,
    className: ownProps.className,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchUserFavouriteData: () => dispatch(fetchUserFavourites()),
    savefavourite: (payload: IUserFavouritePostPayload, item: IUserFavouriteItem) => dispatch(upsertUserFavourite(payload, item)),
    deleteFavourite: (entityGuid: string, applicationType: Constants.UserFavourite.ApplicationTypes) =>
      dispatch(deleteUserFavourite(entityGuid, applicationType)),
  };
};

const UserFavouriteTileDetailButton = connect(mapStateToProps, mapDispatchToProps)(UserFavouriteTileDetailButtonComponent);

export default UserFavouriteTileDetailButton as React.StatelessComponent<any>;
