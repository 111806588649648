import { getLocalStorageItem, saveLocalStorageItem } from '@shared/utils/browserStorageUtils';

const smileFrownSubmittedTimeKey = 'lastFeedbackTime';

export const storeFeedbackSubmittedTime = (): void => {
  saveLocalStorageItem(smileFrownSubmittedTimeKey, JSON.stringify(new Date()));
};

export const getFeedbackSubmittedTime = (): Date => {
  try {
    const lastSubmittedTime = JSON.parse(getLocalStorageItem(smileFrownSubmittedTimeKey));
    return Date.parse(lastSubmittedTime) ? new Date(lastSubmittedTime) : null;
  } catch (error) {
    return null;
  }
};
