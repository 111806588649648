import { IExternalLinkProps, IExternalLinkAttribute, IExternalLinkOverrideAttribute, ITelemetryData } from './../Models';
import { Constants } from '../utils/constants';
import { urlPush, urlAssign } from './../routerHistory';
import { getWindow } from './../services/window';
import { SpzaInstrumentService } from '../services/telemetry/spza/spzaInstrument';
import { logger } from '@src/logger';

export function getOverrideAttributes(props: IExternalLinkProps): IExternalLinkOverrideAttribute {
  const isEmailLink = props.href.indexOf('mailto:') === 0;

  const overrideAttributes: IExternalLinkOverrideAttribute = {} as any;

  // override attribute onClick event
  overrideAttributes.onClick = (event: any) => {
    if (props.onClickHandle) {
      props.onClickHandle(event);
    }

    if (props.onClick) {
      props.onClick(event);
    }

    const detailText = isEmailLink ? 'External Link Clicked, Email Dialog opened' : 'External Link Clicked, New Tab opened';

    logTelemetry(Constants.Telemetry.Action.Click, Constants.Telemetry.ActionModifier.Link, detailText);
    logger.info(detailText, {
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.Link,
    });

    event.preventDefault();

    // props.shouldOpenInTab is true as default
    const shouldOpenInTab = !isEmailLink && props.shouldOpenInTab !== false;

    urlAssign(props.href, shouldOpenInTab);
  };

  // override attribute tabIndex
  overrideAttributes.tabIndex = props.tabIndex || (props.accessibilityEnabled ? 0 : -1);

  return overrideAttributes;
}

export function getAttributes(props: IExternalLinkProps): IExternalLinkAttribute {
  // override attributes
  const overrideAttributes: IExternalLinkOverrideAttribute = getOverrideAttributes(props);

  // attributes
  let attributes: IExternalLinkAttribute = {};

  Object.keys(props)
    .filter((propName: string) => ['children', 'accessibilityEnabled', 'onClickHandle', 'shouldOpenInTab'].indexOf(propName) < 0)
    .forEach((propName: string) => (attributes[`${propName}`] = props[`${propName}`]));

  attributes = {
    ...attributes,
    ...overrideAttributes,
  };

  return attributes;
}

/**
 * log telemetry
 */
export function logTelemetry(action: string, actionModifier: string, details?: string) {
  const pageUrl: string = getWindow() && getWindow().location && getWindow().location.href;

  const payload: ITelemetryData = {
    page: pageUrl,
    action: action,
    actionModifier: actionModifier,
  };

  if (details) {
    const detailsName: string = 'details';

    payload[`${detailsName}`] = details;
  }

  SpzaInstrumentService.getProvider().probe<ITelemetryData>(Constants.Telemetry.ProbeName.LogInfo, payload);
}
