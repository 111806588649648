import React from 'react';
import * as PropTypes from 'prop-types';
import SpzaComponent from './spzaComponent';
import {
  IUserFavouritePostPayload,
  IUserFavouriteTileButtonProps,
  IUserFavouriteTileButtonState,
} from './../interfaces/userFavouriteModels';
import { ITelemetryData } from './../Models';
import { SpzaInstrumentProvider, SpzaInstrumentService } from './../services/telemetry/spza/spzaInstrument';
import { IBuildHrefContext, ILocContext, ILocParamsContext, ICommonContext } from './../interfaces/context';
import { Constants } from './../utils/constants';
import { getUserFavouriteEntity } from './../utils/userFavouriteUtils';
import { getWindow } from '../../shared/services/window';
import { ActionButton } from '@fluentui/react';
import type { IIconProps } from '@fluentui/react';
import { CommunicationColors, NeutralColors } from '@fluentui/theme';
import { logger } from '@src/logger';

const favoriteStarFilled: IIconProps = {
  iconName: 'FavoriteStarFill',
  styles: {
    root: { color: NeutralColors.gray130 },
  },
};

const favoriteStarAdd: IIconProps = {
  iconName: 'AddFavorite',
  styles: {
    root: { color: NeutralColors.gray130 },
  },
};

const favoriteStarFilledHover: IIconProps = {
  iconName: 'FavoriteStarFill',
  styles: {
    root: { color: CommunicationColors.primary },
  },
};

const favoriteStarAddHover: IIconProps = {
  iconName: 'AddFavorite',
  styles: {
    root: { color: CommunicationColors.primary },
  },
};

export class UserFavouriteTileButton extends SpzaComponent<IUserFavouriteTileButtonProps, IUserFavouriteTileButtonState> {
  context: ILocContext & ILocParamsContext & IBuildHrefContext & ICommonContext;
  private instrument: SpzaInstrumentProvider;
  private disposed = false;

  constructor(props: IUserFavouriteTileButtonProps, context: ILocContext & ICommonContext & IBuildHrefContext) {
    super(props, context);

    this.instrument = SpzaInstrumentService.getProvider();
    this.state = {
      // this state property is to fix the flicker when user (un)save an favourite, due to the delay of a REST call to server
      // makes user think (un)save is an immediate action to effect,
      // this is done by changing the classname before the REST call, and reset the classname after REST call
      // in order to change and change it back, we need to record the user action
      itemModifyStatus: Constants.UserFavourite.DataItemModifyStatus.None,
      isCardHover: false,
    };

    this.onClickUserFavouriteButton = this.onClickUserFavouriteButton.bind(this);
  }

  componentDidMount() {
    this.props.fetchUserFavouriteData();
  }

  componentWillUnmount() {
    this.disposed = true;
  }

  setFavouriteItemModifyComplete() {
    if (!this.disposed) {
      this.setState({
        itemModifyStatus: Constants.UserFavourite.DataItemModifyStatus.Done,
      });
    }
  }

  onClickUserFavouriteButton(event: React.MouseEvent<HTMLElement>) {
    const entityId = this.props.entityId;
    const userFavouriteEntity = getUserFavouriteEntity(this.props.userFavourite, entityId);

    const upsertPayload: IUserFavouritePostPayload = {
      applicationType: this.props.entityType,
      applicationId: entityId,

      // plan Id will be updated when savefavourite called
      planId: '',
    };

    // telemetry
    const details = userFavouriteEntity
      ? {
          action: Constants.Telemetry.Action.Delete,
          userFavouriteInfo: {
            ...userFavouriteEntity,
          },
        }
      : {
          action: Constants.Telemetry.Action.Upsert,
          userFavouriteInfo: {
            ...upsertPayload,
          },
        };

    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.UserFavouriteTileButton,
      details: JSON.stringify(details),
    };

    this.instrument.probe<ITelemetryData>(Constants.Telemetry.ProbeName.LogInfo, payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });

    // toggle user favourite on entity
    if (userFavouriteEntity) {
      // Delete a favourite
      // save the user action
      // display saved result beforehand
      this.setState({
        itemModifyStatus: Constants.UserFavourite.DataItemModifyStatus.Delete,
      });

      // REST DELETE
      this.props
        .deleteFavourite(userFavouriteEntity.entityGuid, userFavouriteEntity.applicationType)

        // TO-DO: cannot use .finally() on promise
        .then(() => {
          this.setFavouriteItemModifyComplete();
        })
        .catch(() => {
          this.setFavouriteItemModifyComplete();
        });
    } else {
      // Upsert a favourite
      // save the user action
      // display saved result beforehand
      this.setState({
        itemModifyStatus: Constants.UserFavourite.DataItemModifyStatus.Upsert,
      });

      // REST POST
      this.props
        .savefavourite(entityId, { ...upsertPayload }, this.props.item)

        // TO-DO: cannot use .finally() on promise
        .then(() => {
          this.setFavouriteItemModifyComplete();
        })
        .catch(() => {
          this.setFavouriteItemModifyComplete();
        });
    }
  }

  shouldShowSavedStatus(hasUserSavedFavourite: boolean) {
    const { itemModifyStatus } = this.state;
    return (
      (hasUserSavedFavourite &&
        (itemModifyStatus === Constants.UserFavourite.DataItemModifyStatus.Done ||
          itemModifyStatus === Constants.UserFavourite.DataItemModifyStatus.None)) ||
      (!hasUserSavedFavourite && itemModifyStatus === Constants.UserFavourite.DataItemModifyStatus.Upsert)
    );
  }

  handleMouseEnter() {
    this.setState({ isCardHover: true });
  }

  handleMouseLeave() {
    this.setState({ isCardHover: false });
  }

  renderImpl() {
    const hasUserSavedFavourite = !!getUserFavouriteEntity(this.props.userFavourite, this.props.entityId);

    const shouldShowSavedStatus = this.shouldShowSavedStatus(hasUserSavedFavourite);

    const title = shouldShowSavedStatus
      ? this.context.loc('UserFavourite_TileButton_Remove')
      : this.context.loc('UserFavourite_TileButton_Save');

    return (
      this.props.userInfo.signedIn && (
        <ActionButton
          data-bi-id={'TileAddToFavorites'}
          iconProps={
            hasUserSavedFavourite
              ? this.state.isCardHover
                ? favoriteStarFilledHover
                : favoriteStarFilled
              : this.state.isCardHover
              ? favoriteStarAddHover
              : favoriteStarAdd
          }
          onClick={this.onClickUserFavouriteButton}
          title={title}
          aria-label={title}
          onMouseEnter={this.handleMouseEnter.bind(this)}
          onMouseLeave={this.handleMouseLeave.bind(this)}
        />
      )
    );
  }
}

(UserFavouriteTileButton as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  buildHref: PropTypes.func,
  renderErrorModal: PropTypes.func,
};
