export const oneTimePaymentOffers: string[] = [
  'microsoft_teams.microsoft_teams_virtual_events_basic',
  'microsoft_teams.microsoft_teams_virtual_events_professional',
  'microsoft_teams.microsoft_teams_virtual_events_premium',
  'isvtestuklegacy.teams_virtual_events_premium',
  'isvtestuklegacy.teams_virtual_events_premium-preview',
];

export const isOneTimePaymentOffer = (entityId: string): boolean => {
  return oneTimePaymentOffers.includes(entityId);
};
