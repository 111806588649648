import Finance from '@shared/images/homepage/Finance.jpg';
import LowCode from '@shared/images/homepage/LowCode.jpg';
import Service from '@shared/images/homepage/Service.jpg';
import SupplyChain from '@shared/images/homepage/SupplyChain.jpg';
import CustomerExperience from '@shared/images/homepage/CustomerExperience.jpg';

interface TestimonialAuthor {
  name: string;
  title: string;
}

export interface TestimonialRecommendation {
  /** Also known as productId */
  productFamily: string;
  entityId: string;
  title: string;
  iconURL: string;
  shortDescription: string;
  shortDescriptionFallback: string;
}

export interface TestimonialProps {
  id: string;
  title: string;
  image: string;
  description: string;
  descriptionFallback: string;
  author: TestimonialAuthor;
  recommendations: TestimonialRecommendation[];
}

/**  ™  */
const trademark = '\u2122';

export const TestimonialsId = {
  Finance: 'Finance',
  LowCode: 'LowCode',
  Service: 'Service',
  SupplyChain: 'SupplyChain',
  CustomerExperience: 'CustomerExperience',
} as const;

export type TestimonialId = typeof TestimonialsId[keyof typeof TestimonialsId];

export const testimonials: TestimonialProps[] = [
  {
    id: TestimonialsId.LowCode,
    title: 'Success_Story_Area1_Title',
    image: LowCode,
    description: 'Testimonial_LowCode',
    descriptionFallback:
      '"The smart features in Maplytics have made our search process faster and easier for users, saving hours of employee time."',
    author: {
      name: 'Abby Torres',
      title: 'Director of Information Technology ReEmployAbility',
    },
    recommendations: [
      {
        productFamily: 'dynamics-365',
        entityId: 'inogic.f6f3c73f-29de-4fa8-a396-87ea8a07b6c4',
        title: 'Maplytics: Maps + CRM',
        iconURL:
          'https://store-images.s-microsoft.com/image/apps.6214.3b6ff61e-e12d-44db-aeaf-5145a958ce40.be7576ed-81f3-4957-bbba-9c34161e56b6.dbc37bc4-11b0-4899-b229-23d1be6473b8',
        shortDescription: 'Testimonial_LowCode_Product_Description',
        shortDescriptionFallback: 'Leverage the power of location-based intelligence',
      },
    ],
  },
  {
    id: TestimonialsId.Finance,
    title: 'Success_Story_Area2_Title',
    image: Finance,
    description: 'Testimonial_Finance',
    descriptionFallback:
      '"With Binary Stream, we can produce auditable reports with certainty and confidence. This has proven very effective, and we are now reaping the cost savings."',
    author: {
      name: 'Lyn Donnelly',
      title: 'Management Accountant at PR Finance Group Ltd.',
    },
    recommendations: [
      {
        productFamily: 'dynamics-365-business-central',
        entityId: 'binary-stream-software.ec403ee3-66eb-4565-95db-30cacf093e19',
        title: 'Subscription Billing Suite',
        iconURL:
          'https://store-images.s-microsoft.com/image/apps.48588.41a6f731-100c-49a6-b081-360e15c6678f.0c64271c-afe2-48ae-821f-bf6276ecf4be.c7a3b37e-4942-4970-bd07-7c335ae0507b',
        shortDescription: 'Testimonial_Finance_Product_Description',
        shortDescriptionFallback: 'Manage contracts with ease by automating complex recurring billing cycles',
      },
    ],
  },
  {
    id: TestimonialsId.Service,
    title: 'Success_Story_Area3_Title',
    image: Service,
    description: 'Testimonial_Service',
    descriptionFallback:
      '"b+s has been a great partner! Our contact center has unique use cases that their technical team helped us navigate and resolve."',
    author: {
      name: 'Lee Christiansen',
      title: 'Director – Network Infrastructure and Operations Mountain America Credit Union',
    },
    recommendations: [
      {
        productFamily: 'dynamics-365',
        entityId: 'buchersuterag1580892733541.connects',
        title: 'b+s Connects',
        iconURL:
          'https://store-images.s-microsoft.com/image/apps.27812.5094e61f-ca74-440b-bc07-9e3ff81af5a9.7985c97a-706b-455e-aa98-552c5b2be291.39338e28-b888-4224-9893-835a4feb9b24',
        shortDescription: 'Testimonial_Service_Product_Description',
        shortDescriptionFallback: 'Designed to assist contact center agents in their delivery of outstanding customer service',
      },
    ],
  },
  {
    id: TestimonialsId.SupplyChain,
    title: 'Success_Story_Area4_Title',
    image: SupplyChain,
    description: 'Testimonial_SupplyChain',
    descriptionFallback:
      '"We decided to look for a new ERP system that would integrate with SPS Commerce because we had such a positive experience working with SPS."',
    author: {
      name: 'Anthony Flores',
      title: 'Demand Planning and Analytics Manager',
    },
    recommendations: [
      {
        productFamily: 'dynamics-365-for-operations',
        entityId: 'spscommerce1602261903756.spscommerce_edi',
        title: 'SPS Commerce EDI for Dynamics 365',
        iconURL:
          'https://store-images.s-microsoft.com/image/apps.35888.58b103f3-e263-4602-a5e2-63ef90aacf25.e9d91376-8efa-481f-8994-56316cded698.16f16b87-f998-4e36-aef4-041694ea7a11',
        shortDescription: 'Testimonial_SupplyChain_Product_Description',
        shortDescriptionFallback: 'Eliminate the time and cost of human errors due to manual data entry',
      },
    ],
  },
  {
    id: TestimonialsId.CustomerExperience,
    title: 'Success_Story_Area5_Title',
    image: CustomerExperience,
    description: 'Testimonial_CustomerExperience',
    descriptionFallback:
      '"Powerful deduplication solution with many options to refine the merging options. On top: an excellent support, fast and competent!"',
    author: {
      name: 'Georg Grawatsch',
      title: 'Product Owner CRM Falstaff Verlags-GmbH',
    },
    recommendations: [
      {
        productFamily: 'dynamics-365',
        entityId: 'data8.duplicare',
        title: `Data8 Duplicare${trademark}`,
        iconURL:
          'https://store-images.s-microsoft.com/image/apps.13144.a4207375-054f-4781-b7c7-91f123382727.05a75a43-af0e-4390-9bd9-73f7902f3853.d772ae32-0844-4d46-b68a-d30493d2ce55',
        shortDescription: 'Testimonial_CustomerExperience_Product_Description',
        shortDescriptionFallback: 'Deduplication via advanced fuzzy duplicate detection and multi-record merging',
      },
    ],
  },
];
