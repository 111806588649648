import { createStore, applyMiddleware } from 'redux';
import type { Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

export function buildStore<S>({
  combinedReducers,
  initialState,
  middleware = [],
}: {
  combinedReducers: any;
  initialState: any;
  middleware: any;
}): Store<S> {
  return createStore<S, any, any, any>(combinedReducers, initialState, composeWithDevTools({})(applyMiddleware(...middleware)));
}
