import { Constants } from '@shared/utils/constants';

const fallbackCountryCode = 'US';

export const getLocaleLanguage = ({ locale }: { locale?: string }) => {
  const localeLanguage = locale?.includes('-') ? locale.split('-')[0] : Constants.DefaultLocale.split('-')[0];
  return localeLanguage;
};

function getCountryCodeRegion({ countryCode }: { countryCode?: string }) {
  const countryCodeRegion = countryCode
    ? countryCode.includes('-')
      ? countryCode.split('-')[1]
      : countryCode
    : fallbackCountryCode;
  return countryCodeRegion;
}

export function getLocale({ locale, countryCode }: { locale?: string; countryCode?: string }) {
  const localeLanguage = getLocaleLanguage({ locale });
  const countryCodeRegion = getCountryCodeRegion({ countryCode });
  const localeString = `${localeLanguage.toLowerCase()}-${countryCodeRegion.toUpperCase()}`;
  return localeString;
}

export function timeToLocalLocaleString({
  timeUTCSeconds,
  locale,
  countryCode,
}: {
  timeUTCSeconds: number;
  locale: string;
  countryCode: string;
}): string {
  const date = new Date(timeUTCSeconds * 1000);
  const localeString = getLocale({ locale, countryCode });
  return date.toLocaleTimeString(localeString);
}

export function timeToLocalString({
  timeUTCSeconds,
  locale,
  countryCode,
}: {
  timeUTCSeconds: number;
  locale: string;
  countryCode: string;
}): string {
  const date = new Date(timeUTCSeconds * 1000);
  const localeString = getLocale({ locale, countryCode });
  return date.toLocaleString(localeString);
}

export function numberToLocaleString({
  number,
  locale,
  countryCode,
}: {
  number: number;
  locale: string;
  countryCode: string;
}): string {
  const localeString = getLocale({ locale, countryCode });
  return number.toLocaleString(localeString);
}

const catalogSupportedLocales = [
  'en',
  'cs',
  'de',
  'es',
  'fr',
  'hu',
  'it',
  'ja',
  'ko',
  'nl',
  'pl',
  'ru',
  'sv',
  'tr',
  'pt-br',
  'pt-pt',
  'zh-hans',
  'zh-hant',
];
const languageCodesThatShouldUseLocaleInstead = ['pt', 'zh'];

export const doesSearchSupportLanguage = ({ language }: { language: string }) => catalogSupportedLocales.indexOf(language) >= 0;
export const shouldUseLocaleInsteadOfLanguageCode = ({ language }: { language: string }) =>
  languageCodesThatShouldUseLocaleInstead.indexOf(language) >= 0;
