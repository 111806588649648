/* istanbul ignore file */

export const catalogToStorefrontsProducts = {
  'dynamics-365-for-business-central': 'Dynamics 365 for Business Central',
  'cortana-intelligence': 'Azure for Cortana Intelligence',
  'dynamics-365-for-customer-services': 'Dynamics 365 for Customer Services',
  'dynamics-365-for-field-services': 'Dynamics 365 for Field Services',
  'dynamics-365-for-operations': 'Dynamics 365 for Financials',
  'dynamics-365-for-financials': 'Dynamics 365 for Business Central',
  'dynamics-365-for-project-services-automation': 'Dynamics 365 for Project Services Automation',
  'dynamics-365-for-sales': 'Dynamics 365 for Sales',
  'dynamics-365-customer-voice': 'Dynamics 365 Customer Voice',
  'dynamics-365-human-resources': 'Dynamics 365 Human Resources',
  'dynamics-365-marketing': 'Dynamics 365 Marketing',
  'dynamics-365-commerce': 'Dynamics 365 Commerce',
  'dynamics-365-supply-chain-management': 'Dynamics 365 Supply Chain Management',
  'dynamics-365-mixed-reality': 'Dynamics 365 Mixed Reality',
  'dynamics-365-customer-insights': 'Dynamics 365 Customer Insights',
  Office365: 'Office365',
  powerapps: 'PowerApps',
  'power-bi': 'Power BI',
  'power-automate': 'Power Automate',
  'power-virtual-agents': 'Power Virtual Agents',
  'microsoft-365': 'Office365',
  'dynamics-365-project-operations': 'Dynamics 365 Project Operations',
  'dynamics-365-project-services': 'Dynamics 365 Project Services',
  'm365-sa-adoption-and-change-management': 'M365 Sa Adoption And Change Management',
  'm365-sa-calling-for-microsoft-teams': 'M365 Sa Calling For Microsoft Teams',
  'm365-sa-cloud-security': 'M365 Sa Cloud Security',
  'm365-sa-compliance-advisory-services': 'M365 Sa Compliance Advisory Services',
  'm365-sa-device-deployment-and-management': 'M365 Sa Device Deployment And Management',
  'm365-sa-firstline-workers': 'M365 Sa Firstline Workers',
  'm365-sa-identity-and-access-management': 'M365 Sa Identity And Access Management',
  'm365-sa-information-protection-and-governance': 'M365 Sa Information Protection And Governance',
  'm365-sa-insider-risk': 'M365 Sa Insider Risk',
  'm365-sa-knowledge-and-insights': 'M365 Sa Knowledge And Insights',
  'm365-sa-meeting-rooms-for-microsoft-teams': 'M365 Sa Meeting Rooms For Microsoft Teams',
  'm365-sa-meetings-for-microsoft-teams': 'M365 Sa Meetings For Microsoft Teams',
  'm365-sa-microsoft-365-live-events': 'M365 Sa Microsoft 365 Live Events',
  'm365-sa-mobile-device-management': 'M365 Sa Mobile Device Management',
  'm365-sa-power-platform-for-teams': 'M365 Sa Power Platform For Teams',
  'm365-sa-teams-custom-solutions': 'M365 Sa Teams Custom Solutions',
  'm365-sa-teamwork-deployment': 'M365 Sa Teamwork Deployment',
  'm365-sa-threat-protection': 'M365 Sa Threat Protection',
  'm365-sa-workplace-analytics': 'M365 Sa Workplace Analytics',
  PowerPages: 'Power Pages',
};

export const BackendKeyToURLKey = {
  home: 'apps',
  featured: 'featured',
  trending: 'trending',
  featuredtestdrives: 'top-test-drives',
  operatingsystems: 'operating-systems',
  devops: 'devops',
  inframigration: 'onprem-to-cloud-migration',
  Analytics_MP: 'analytics',
  dataInsights: 'data-insights',
  analytics: 'data-analytics',
  bigData: 'big-data',
  predictiveAnalytics: 'predictive-analytics',
  realTimeStreamingAnalytics: 'real-time-streaming-analytics',
  All_Analytics_MP: 'all',
  CognitiveServices_MP: 'ai-plus-machine-learning',
  AutomatedML: 'automated-ml',
  BotService: 'bot-service',
  BusinessRoboticAutomation: 'business-robotic-process-automation',
  CognitiveService: 'cognitive-service',
  DataLabelling: 'data-labelling',
  DataPreparation: 'data-preparation',
  KnowledgeMining: 'knowledge-mining',
  MlOperations: 'ml-operations',
  MachineLearning: 'ml-service',
  All_CognitiveServices_MP: 'all',
  AADapps: 'azure-active-directory-apps',
  Featured: 'featured',
  BusinessManagement: 'business-management',
  Collaboration: 'collaboration',
  Construction: 'construction',
  Consumer: 'consumer',
  ContentManagement: 'content-management',
  CRM: 'crm',
  DataServices: 'data-services',
  DeveloperServices: 'developer-services',
  ECommerce: 'ecommerce',
  Education: 'education',
  ERP: 'erp',
  Finance: 'finance',
  Health: 'health',
  HR: 'human-resources',
  ITInfra: 'it-infrastructure',
  Mail: 'mail',
  Marketing: 'marketing',
  Media: 'media',
  Productivity: 'productivity',
  ProjectMgmt: 'project-management',
  Security: 'security',
  Social: 'social',
  SupplyMgmt: 'supply-management',
  Telecommunications: 'telecommunications',
  tools: 'tools',
  Travel: 'travel',
  WebDesign: 'web-design-hosting',
  'media-and-communications': 'media-and-communications',
  'architecture-and-construction': 'architecture-and-construction',
  'hospitality-and-travel': 'hospitality-and-travel',
  'real-estate': 'real-estate',
  'other-public-sector-industries': 'other-public-sector-industries',
  Blockchain_MP: 'blockchain',
  BlockChainAppAccelerators_MP: 'app-accelerators',
  BlockChainSingleNodeLedgers_MP: 'single-node-ledger',
  BlockChainMultiNodeLedgers_MP: 'multi-node-ledger',
  BlockchainTools_MP: 'tools',
  All_Blockchain_MP: 'all',
  Compute_MP: 'compute',
  recommended: 'featured',
  applicationInfrastructure: 'application-infrastructure',
  operatingSystems: 'operating-systems',
  cache: 'cache',
  All_Compute_MP: 'all',
  Containers_MP: 'containers',
  WebDH: 'featured',
  ContainerApps_MP: 'container-apps',
  All_Containers_MP: 'all',
  Databases_MP: 'databases',
  noSql: 'nosql-databases',
  relationalDatabases: 'relational-databases',
  ledgerBlockchainDatabases: 'ledger-blockchain-databases',
  dataLakes: 'data-lakes',
  dataWarehouse: 'data-warehouse',
  All_Databases_MP: 'all',
  DeveloperTools_MP: 'developer-tools',
  toolsDeveloperTools: 'tools',
  scripts: 'scripts',
  devService: 'devService',
  All_DeveloperTools_MP: 'all',
  Devops_MP: 'devops',
  All_Devops_MP: 'all',
  Identity_MP: 'identity',
  Identity: 'identity-access-mgmt',
  All_Identity_MP: 'all',
  Integration_MP: 'integration',
  messaging: 'messaging',
  All_Integration_MP: 'all',
  InternetOfThings_MP: 'internet-of-things',
  IoTConnectivity: 'iot-connectivity',
  IoTSolutions: 'iot-solutions',
  IOTVisualizations: 'data-analytics-visualizations',
  IoTSecurity: 'iot-security',
  IoTAndIIoTPlatforms: 'iot-iiot-platforms',
  IoTCoreServices: 'iot-core-services',
  IoTEdgeModules: 'iot-edge-modules',
  All_InternetOfThings_MP: 'all',
  MonitoringAndManagement_MP: 'management-tools',
  managementSolutions: 'management-solutions',
  bizApps: 'business-applications',
  All_MonitoringAndManagement_MP: 'all',
  MonitoringAndDiagnostics_MP: 'monitoring-and-diagnostics',
  All_MonitoringAndDiagnostics_MP: 'all',
  Media_MP: 'media',
  mediaServices: 'media-services',
  contentProtection: 'content-protection',
  liveOnDemandStreaming: 'live-and-on-demand-streaming',
  All_Media_MP: 'all',
  Migration_MP: 'migration',
  dataMigration: 'data-migration',
  All_Migration_MP: 'all',
  MixedReality_MP: 'mixed-reality',
  gaming: 'gaming',
  All_MixedReality_MP: 'all',
  Networking_MP: 'networking',
  applianceManagers: 'appliance-managers',
  connectivity: 'connectivity',
  firewalls: 'firewalls',
  loadBalancers: 'load-balancers',
  All_Networking_MP: 'all',
  Security_MP: 'security',
  identityAccessManagement: 'identity-and-access-management',
  SecurityProtection: 'threat-protection',
  informationProtection: 'information-protection',
  All_Security_MP: 'all',
  Storage_MP: 'storage',
  backupAndRecovery: 'backup-and-recovery',
  hybridStorageAndGlobalDataAccess: 'enterprise-hybrid-storage',
  fileSharing: 'file-sharing',
  dataLifecycleManagement: 'data-lifecycle-management',
  All_Storage_MP: 'all',
  WebAndMobile_MP: 'web',
  blogsCMS: 'blogs-cmss',
  starterSites: 'starter-web-apps',
  eCommerce: 'ecommerce',
  appFrameworks: 'web-app-frameworks',
  web_recommended: 'web-apps',
  All_WebAndMobile_MP: 'all',
  trials: 'trials',
  freeTrial: 'free-trial',
  testDrive: 'test-drive',
  operatingSystem: 'operating-system',
  linux: 'linux',
  windows: 'windows',
  publisher: 'publisher',
  microsoft: 'microsoft',
  partners: 'partners',
  pricingModel: 'pricing-model',
  byol: 'byol',
  free: 'pricing-free',
  payAsYouGo: 'pay-as-you-go',
  paid: 'pricing-paid',
  virtualMachineImages: 'virtual-machine-images',
  solutionTemplates: 'solution-templates',
  SaaS: 'product-type',
  Assessment: 'assessment',
  Briefing: 'briefing',
  Implementation: 'implementation',
  ProofofConcept: 'proof-of-concept',
  Training: 'training',
  Workshop: 'workshop',
  getStarted: 'consulting-services',
  appModernization: 'app-modernization',
  archive: 'archive',
  artificialIntelligence: 'ai-plus-machine-learning',
  backup: 'backup',
  bigdata: 'big-data',
  dataPlatform: 'data-platform',
  datacenterManagemenet: 'datacenter-managemenet',
  disasterRecovery: 'disaster-recovery',
  identity: 'identity',
  internetOfThings: 'internet-of-things',
  migration: 'migration',
  networking: 'networking',
  security: 'security',
  storage: 'storage',
  Automotive_CS: 'automotive',
  Agriculture: 'agriculture',
  ArchitectureConstruction_CS: 'architecture-construction',
  Distribution: 'distribution',
  FinancialServices: 'finance',
  Government: 'government',
  HealthCareandLifeSciences: 'healthcare',
  HospitalityTravel_CS: 'hospitality-travel',
  Manufacturing: 'manufacturing',
  MediaCommunications_CS: 'Media-communications',
  ProfessionalServices: 'professional-services',
  RealEstate_CS: 'real-estate',
  RetailandConsumerGoods: 'retail',
  Sustainability_AS: 'sustainability',
  OtherPublicSectorIndustries_CS: 'other-public-sector-industries',
  country: 'country',
  AF: 'country-afghanistan',
  AL: 'country-albania',
  DZA: 'country-algeria',
  AD: 'country-andorra',
  AO: 'country-angola',
  AR: 'country-argentina',
  AM: 'country-armenia',
  AU: 'country-australia',
  AT: 'country-austria',
  AZ: 'country-azerbaijan',
  BH: 'country-bahrain',
  BD: 'country-bangladesh',
  BB: 'country-barbados',
  BY: 'country-belarus',
  BE: 'country-belgium',
  BZ: 'country-belize',
  BM: 'country-bermuda',
  BO: 'country-bolivia',
  BA: 'country-bosnia and herzegovina',
  BW: 'country-botswana',
  BR: 'country-brazil',
  BN: 'country-brunei',
  BG: 'country-bulgaria',
  CM: 'country-cameroon',
  CA: 'country-canada',
  CV: 'country-cabo-verde',
  KY: 'country-cayman-islands',
  CL: 'country-chile',
  CO: 'country-colombia',
  CR: 'country-costa-rica',
  CI: 'country-côte-divoire',
  CW: 'country-curaçao',
  CY: 'country-cyprus',
  CZ: 'country-czech-republic',
  DK: 'country-denmark',
  DO: 'country-dominican-republic',
  EC: 'country-ecuador',
  EG: 'country-egypt',
  SV: 'country-el-salvador',
  EE: 'country-estonia',
  ET: 'country-ethiopia',
  FO: 'country-faroe-islands',
  FJ: 'country-fiji',
  FI: 'country-finland',
  FR: 'country-france',
  GE: 'country-georgia',
  DE: 'country-germany',
  GH: 'country-ghana',
  GR: 'country-greece',
  GT: 'country-guatemala',
  HN: 'country-honduras',
  HK: 'country-hong-kong',
  HU: 'country-hungary',
  IS: 'country-iceland',
  IN: 'country-india',
  ID: 'country-indonesia',
  IQ: 'country-iraq',
  IE: 'country-ireland',
  IL: 'country-israel',
  IT: 'country-italy',
  JM: 'country-jamaica',
  JP: 'country-japan',
  JO: 'country-jordan',
  KZ: 'country-kazakhstan',
  KE: 'country-kenya',
  KR: 'country-korea',
  KW: 'country-kuwait',
  KG: 'country-kyrgyzstan',
  LV: 'country-latvia',
  LB: 'country-lebanon',
  LY: 'country-libya',
  LI: 'country-liechtenstein',
  LT: 'country-lithuania',
  LU: 'country-luxembourg',
  MO: 'country-macao-sar',
  MK: 'country-macedonia',
  MY: 'country-malaysia',
  MT: 'country-malta',
  MX: 'country-mexico',
  MD: 'country-moldova',
  MC: 'country-monaco',
  MN: 'country-mongolia',
  ME: 'country-montenegro',
  MA: 'country-morocco',
  NA: 'country-namibia',
  NP: 'country-nepal',
  NL: 'country-netherlands',
  NI: 'country-nicaragua',
  NZ: 'country-newzealand',
  NG: 'country-nigeria',
  NO: 'country-norway',
  OM: 'country-oman',
  PK: 'country-pakistan',
  PS: 'country-palestinian-authority',
  PA: 'country-panama',
  PY: 'country-paraguay',
  PE: 'country-peru',
  PH: 'country-philippines',
  PL: 'country-poland',
  PT: 'country-portugal',
  PR: 'country-puerto-rico',
  QA: 'country-qatar',
  RO: 'country-romania',
  RU: 'country-russia',
  RW: 'country-rwanda',
  KN: 'country-saint-kitts-and-nevis',
  SA: 'country-saudi-arabia',
  SN: 'country-Senegal',
  RS: 'country-serbia',
  SG: 'country-singapore',
  SK: 'country-slovakia',
  SI: 'country-slovenia',
  ZA: 'country-southafrica',
  ES: 'country-spain',
  LK: 'country-sri-lanka',
  SE: 'country-sweden',
  CH: 'country-switzerland',
  TW: 'country-taiwan',
  TJ: 'country-tajikistan',
  TZ: 'country-tanzania',
  TH: 'country-thailand',
  TT: 'country-trinidad-and-tobago',
  TN: 'country-tunisia',
  TR: 'country-turkey',
  TM: 'country-turkmenistan',
  VA: 'country-vatican-city',
  VI: 'country-virgin-islands-of-the-united-states',
  UG: 'country-uganda',
  UA: 'country-ukraine',
  AE: 'country-united-arab-emirates',
  GB: 'country-unitedkingdom',
  US: 'country-unitedstates',
  UY: 'country-uruguay',
  UZ: 'country-uzbekistan',
  VE: 'country-venezuela',
  VN: 'country-vietnam',
  YE: 'country-yemen',
  ZM: 'country-zambia',
  ZW: 'country-zimbabwe',
};
