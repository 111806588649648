import { ResponseError } from 'superagent';

class RequestError extends Error {
  public status: ResponseError['status'];
  public statusCode: ResponseError['status'];
  public name: ResponseError['name'];
  public message: string;
  public stack: ResponseError['stack'];

  constructor(err: ResponseError) {
    super(err.toString());

    const requestUrl = this.getRequestUrl(err);
    this.message = `failed requesting ${requestUrl}: ${err.message}; status code ${err.status}`;
    this.status = err.status;
    this.statusCode = err.status;
    this.name = err.name;
    this.stack = err.stack;
  }

  private getRequestUrl(err: ResponseError) {
    // @ts-expect-error - superagent types do not reflect the existence of the req property
    return err.response?.request?.url || err.response?.req?.url;
  }
}

export default RequestError;
