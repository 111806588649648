import { routes } from '@shared/routerHistory';
import { PathsViewMap } from '@shared/hooks/useLocationChange';
import { Constants } from '@shared/utils/constants';

import {
  appDetailsEnsureAsyncData,
  checkoutEnsureAsyncData,
  dynamicCampaignEnsureAsyncData,
  serivceDetailsEnsureAsyncData,
} from './ensureAsyncData';

export const PATHS_TO_VIEW_MAP: PathsViewMap = {
  [Constants.home]: { view: routes.home.name },
  marketplace: { view: routes.marketplace.name },
  [Constants.appsGallery]: { view: routes.marketplace.name },
  [Constants.cloudsIndustryGallery]: { view: routes.marketplaceCloudsIndustry.name },
  'product/:productId/:entityId': {
    view: routes.appDetails.name,
    ensureAsyncDataSSR: appDetailsEnsureAsyncData,
  },
  'marketplace/checkout/:entityId': { view: routes.checkout.name, ensureAsyncDataSSR: checkoutEnsureAsyncData },
  'marketplace/order-confirmation': { view: routes.orderConfirmation.name },
  partners: { view: routes.partners.name },
  [Constants.csGallery]: { view: routes.marketplaceServices.name },
  [`${Constants.csGallery}/:entityId`]: { view: routes.serviceDetail.name, ensureAsyncDataSSR: serivceDetailsEnsureAsyncData },
  [`${Constants.partnersAppSource}/*`]: { view: routes.marketplacePartners.name },
  [`${Constants.partnersAppSource}/:entityId/:tab`]: { view: routes.marketplacePartnerDetail.name },
  [`${Constants.partnersAppSource}/:entityId/:tab/contact`]: { view: routes.marketplacePartnerDetailContact.name },
  [`${Constants.partnersAppSource}/suggestion`]: { view: routes.marketplacePartnerSuggestion.name },
  'marketplace/consultingservices/:entityId': { view: routes.serviceDetail.name },
  'campaigns/:publisher/:id': { view: routes.campaigns.name, ensureAsyncDataSSR: dynamicCampaignEnsureAsyncData },
  'user/my-favourites': { view: routes.userFavouriteDetail.name },
  'user/private-offers': { view: routes.privateOffers.name },
  ':privateOffer/product/:productId/:entityId': {
    view: routes.privateOfferDetails.name,
    ensureAsyncDataSSR: appDetailsEnsureAsyncData,
  },
  sitemap: {
    view: routes.sitemap.name,
  },
  redirect: {
    view: routes.redirect.name,
  },
};
