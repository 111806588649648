/* eslint-disable react/forbid-dom-props */
import * as React from 'react';
import * as PropTypes from 'prop-types';
import SpzaComponent from './../spzaComponent';
import { NpsModule } from './../../utils/npsUtils';
import { Constants } from './../../utils/constants';
import { SpzaInstrumentProvider, SpzaInstrumentService } from '../../services/telemetry/spza/spzaInstrument';
import { ITelemetryData } from './../../Models';
import { ILocContext, ICommonContext } from '../../interfaces/context';
import { getWindow } from '../../services/window';
import { isEmailValid } from './../../utils/appUtils';
import { storeFeedback } from '@shared/services/feedback';
import { logger } from '@src/logger';
import classNames from 'classnames';

interface INpsModalProps {
  emailAddress: string;
  dismissModal: () => void;
}

interface INpsState {
  rating?: string;
  description?: string;
  privacy: boolean;
  validEmailUI: boolean;
  emailAddress: string;
}

export default class NpsModal extends SpzaComponent<INpsModalProps, INpsState> {
  context: ILocContext & ICommonContext;
  private description: string;
  private instrument: SpzaInstrumentProvider;

  constructor(props: any, context: ILocContext & ICommonContext) {
    super(props, context);
    this.instrument = SpzaInstrumentService.getProvider();

    // if this poped-up, the timer is automatically delay a short
    // interval (two-hours).
    NpsModule.SetShortInterval();
    NpsModule.ResetNPSCount();

    this.description = '';
    this.state = {
      rating: null,
      description: '',
      privacy: false,
      validEmailUI: true,
      emailAddress: this.props.emailAddress !== 'no user' ? this.props.emailAddress : '',
    };
  }

  editDescription(e: any) {
    this.description = e.target ? e.target.value : '';
    this.setState({
      description: this.description,
      privacy: this.state.privacy,
      validEmailUI: this.state.validEmailUI,
      emailAddress: this.state.emailAddress,
    });
  }

  handleRatingChange(ratingNumber: string) {
    this.setState({
      rating: ratingNumber,
      description: this.description,
      privacy: this.state.privacy,
      validEmailUI: this.state.validEmailUI,
      emailAddress: this.state.emailAddress,
    });
  }

  decline() {
    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.NPS,
      actionModifier: Constants.Telemetry.ActionModifier.Declined,
      details: '',
    };
    this.instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });
    NpsModule.Declined();
    this.props.dismissModal();
  }

  handleSubmit() {
    if (!this.state.rating || (this.state.privacy && !this.state.validEmailUI)) {
      return this.decline();
    }

    const npsResult = {
      rating: this.state.rating,
      description: this.state.description,
      email: this.state.privacy ? this.state.emailAddress : '',
    };

    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.NPS,
      actionModifier: Constants.Telemetry.ActionModifier.Info,
      details: JSON.stringify(npsResult),
      isFeedback: true,
    };
    this.instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', payload);

    storeFeedback({
      action: Constants.Telemetry.Action.NPS,
      actionModifier: Constants.Telemetry.ActionModifier.Info,
      details: JSON.stringify(npsResult),
    });

    NpsModule.Submitted();
    this.props.dismissModal();
  }

  onChangePrivacy() {
    this.setState({
      privacy: !this.state.privacy,
      validEmailUI: this.state.validEmailUI,
      emailAddress: this.state.emailAddress,
    });
  }

  editEmail(e: any) {
    this.setState({
      privacy: this.state.privacy,
      validEmailUI: this.state.validEmailUI,
      emailAddress: e.target ? e.target.value : '',
    });

    if (this.state.privacy) {
      if (isEmailValid(this.state.emailAddress)) {
        this.setState({
          privacy: this.state.privacy,
          validEmailUI: true,
          emailAddress: e.target ? e.target.value : '',
        });
      } else {
        this.setState({
          privacy: this.state.privacy,
          validEmailUI: false,
          emailAddress: e.target ? e.target.value : '',
        });
      }
    }
  }

  handleNumberBtnKeyPress(evt: any, value: string) {
    evt = evt || window.event;
    // only the enter key can toggle the user profile button to show/hide dropdown menu
    if (evt.charCode === Constants.SystemKey.Enter) {
      this.handleRatingChange(value, evt);
    }
    evt.preventDefault();
  }

  ratingPane(self: any) {
    const paneContent: JSX.Element[] = [];
    const arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
    // eslint-disable-next-line array-callback-return
    arr.map((value) => {
      const classes = classNames({
        ratingSelected: self.state.rating === value,
        ratingBlock: true,
        ratingTen: value === '10',
      });
      if (value === '0') {
        paneContent.push(
          <li
            className={classes}
            value={value.toString()}
            tabIndex={0}
            onClick={() => this.handleRatingChange.bind(this)(value)}
            onKeyPress={(event: any) => {
              this.handleNumberBtnKeyPress(event, value);
            }}
          >
            <a className="ratingNumber">{value}</a>
          </li>
        );
      } else {
        paneContent.push(
          <li
            className={classes}
            value={value.toString()}
            tabIndex={0}
            onClick={() => this.handleRatingChange.bind(this)(value)}
            onKeyPress={(event: any) => {
              this.handleNumberBtnKeyPress(event, value);
            }}
          >
            <a className="ratingNumber">{value}</a>
          </li>
        );
      }
    });
    return paneContent;
  }

  renderImpl() {
    const emailInputClasses = classNames({
      'c-text-field': true,
      npsEmailInput: true,
      invalidEmailInput: this.state.privacy && !this.state.validEmailUI,
    });
    return (
      <div role="dialog" aria-label="nps is now open, tell us what you think" className="npsModalClass">
        <div className="prompContainer">
          <div className="toolBar">
            <button id="cancelButton" aria-label="close" className="cancel" onClick={this.decline.bind(this)}>
              <span className="c-glyph"></span>
            </button>
          </div>
          <div className="npsModal">
            <div className="contentHeader">{this.context.loc('Feedback_Header')}</div>
            <div className="rateHeader">{this.context.loc('NPS_RatingQuestion')}</div>
            <div className="rateSlider">
              <ul className="ratingControl">{this.ratingPane(this)}</ul>
              <div className="ratingLabel">
                <div className="ratingLeftLabel">{this.context.loc('NPS_NotLikely', 'Not at all likely')}</div>
                <div className="ratingRightLabel">{this.context.loc('NPS_Likely', 'Very likely')}</div>
              </div>
            </div>
            <div className="rateReasonInput">
              <textarea
                id="subscription"
                className="c-text-field f-flex npsTextArea"
                onChange={this.editDescription.bind(this)}
                value={this.description}
                autoFocus={true}
                placeholder={`${this.context.loc(
                  'NPS_RatingReasonQuestion',
                  'Tell us more about your experience'
                )}.\n${this.context.loc('Specific_app_feedback')}`}
                name="default"
              />
            </div>
            <div className="c-checkbox npsPrivacy">
              <label className="c-label privacyHeader">
                <input
                  aria-label="Control label (unselected)"
                  type="checkbox"
                  id="checkboxId1"
                  name="checkboxId1"
                  value="value1"
                  aria-checked="false"
                  checked={this.state.privacy}
                  onChange={this.onChangePrivacy.bind(this)}
                />
                <span aria-hidden="true">
                  {this.context.loc('Feedback_privacy_content')}
                  <a target="_blank" href="https://aka.ms/AppSourcePrivacyStatement" rel="noreferrer" className="c-hyperlink">
                    {this.context.loc('Feedback_privacy')}
                  </a>
                </span>
              </label>
            </div>
            <input
              className={emailInputClasses}
              type="text"
              onChange={this.editEmail.bind(this)}
              value={this.state.emailAddress}
              placeholder={this.props.emailAddress === 'no user' ? 'email address' : ''}
              name="default"
              disabled={!this.state.privacy}
            />
            <div className="npsButton">
              {!this.state.privacy || (this.state.privacy && this.state.validEmailUI) ? null : (
                <span className="invalidEmailText">{this.context.loc('Feedback_invalidEmail')}</span>
              )}
              <button
                id="submit"
                name="button"
                disabled={(this.state.privacy && !this.state.validEmailUI) || this.state.rating === null}
                className="c-button submitButton"
                type="submit"
                onClick={this.handleSubmit.bind(this)}
              >
                {this.context.loc('NPS_SubmitButton', 'Submit')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

(NpsModal as any).contextTypes = {
  loc: PropTypes.func,
  renderErrorModal: PropTypes.func,
};
