import {
  ICartItem,
  TermsStrings,
  ITelemetryData,
  PricingStates,
  IStartingPrice,
  ICustomer,
  MPRPPlansEntity,
  IAvailabilityActions,
  Price,
  IBillingPlan,
  AdvancedTerm,
  IAppDataItem,
} from '@shared/Models';
import {
  Constants,
  Environment,
  supportedCountries as SUPPORTED_COUNTRIES,
  IConsentOption,
  ConsentType,
  allBillingCountries,
  IBillingCountries,
} from '@shared/utils/constants';
import { getWindow } from '@shared/services/window';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
// eslint-disable-next-line import/named
import { IPaymentInstrument } from '@microsoft-commerce/purchase-blends-component-library/dist/lib/store/payments/state';
import { IWizardProps, IConfigState } from '@src/State';
import { ILocParamsContext, ILocContext } from '@shared/interfaces/context';
import { getFullPriceString } from './pricing';
import { logger } from '@src/logger';
import { getPrimaryProductUrl } from '@shared/utils/datamappingHelpers';
import { getPDPRoute, getProductByUrlKey } from '@shared/utils/appUtils';
import { urlReplace } from '@shared/routerHistory';
// eslint-disable-next-line import/named
import { IMessages } from '@microsoft-commerce/purchase-blends-component-library/dist/lib/providers/app-dependencies/messages';

export interface IUpdateCartProps {
  props: IWizardProps;
  id: string;
  title: string;
  description: string;
  quantity?: string;
  currency?: string;
  isQuantifiable?: boolean;
  hasFreeTrial?: boolean;
  price: number;
  minQuantity?: number;
  maxQuantity?: number;
  /** The commitment of the purchase */
  billingTerm?: TermsStrings;
  /** The id of the commitment of the purchase */
  termId?: string;
  isRecurring?: boolean;
  bigId?: string;
  skuId?: string;
}

export const updateCart = ({
  props,
  id,
  title,
  description,
  quantity,
  isQuantifiable,
  minQuantity,
  currency,
  maxQuantity,
  billingTerm,
  termId,
  isRecurring,
  bigId,
  skuId,
  hasFreeTrial,
  price,
}: IUpdateCartProps): void => {
  const prevCartItem = props.checkout.items[0];
  const cartItem: ICartItem = {
    id,
    title,
    description,
    entityId: props.app.entityId,
    appTitle: props.app.title,
    appImageUri: props.app.iconURL,
    unitPrice: {
      currency: currency || (prevCartItem && prevCartItem.unitPrice.currency),
      unit: billingTerm,
      value: price,
    },
    linkedAddIns: props.app.linkedAddIns,
  };

  cartItem.isQuantifiable = typeof isQuantifiable !== 'undefined' ? isQuantifiable : prevCartItem?.isQuantifiable;
  let quantityAsInt = parseInt(quantity, 10);
  if (isNaN(quantityAsInt)) {
    quantityAsInt = prevCartItem.quantity;
  }

  cartItem.quantityString = quantity || prevCartItem.quantityString;
  cartItem.quantity = quantityAsInt;
  cartItem.minQuantity = minQuantity || prevCartItem?.minQuantity;
  cartItem.maxQuantity = maxQuantity || prevCartItem?.maxQuantity;

  cartItem.termId = termId || prevCartItem?.termId;
  cartItem.termDuration = billingTerm || prevCartItem?.termDuration;
  cartItem.recurringBilling = isRecurring || prevCartItem?.recurringBilling;
  cartItem.bigId = bigId || prevCartItem?.bigId;
  cartItem.skuId = skuId || prevCartItem?.skuId;

  cartItem.hasFreeTrial = hasFreeTrial;

  // No need for input as it won't change during plan update
  cartItem.product = prevCartItem?.product;

  // every time something changes in the cart we reset status for calculate now link,
  // this should only be done if we also have a payment instrument selected
  if (JSON.stringify(cartItem) !== JSON.stringify(prevCartItem)) {
    props.setCartItem(cartItem);
  }
};

export const isNewCustomer = ({ activeCustomer, customers }: { activeCustomer?: ICustomer; customers?: ICustomer[] }) => {
  return !activeCustomer && customers?.length === 0;
};

export const checkoutBillingCountry = ({
  activeCustomer,
  customers,
  defaultBillingCountry,
}: {
  activeCustomer: ICustomer;
  customers: ICustomer[];
  defaultBillingCountry: string;
}) => {
  const billingCountry = isNewCustomer({ activeCustomer, customers })
    ? defaultBillingCountry
    : activeCustomer?.address?.country ?? customers[0].address.country;

  return billingCountry.toLowerCase();
};

export function isAvailableInThisRegion({ startingPrice }: { startingPrice?: IStartingPrice }): boolean {
  return startingPrice && startingPrice.pricingData !== PricingStates.NotAvailableInThisRegion;
}

interface IGetRuntimeEnv {
  [origin: string]: Environment;
}

export const isDevelopmentEnvironment = (env: string) => {
  return env !== Environment.Production;
};

export function getCountries() {
  const countriesList = allBillingCountries
    .filter((country: IBillingCountries) => !!country.transactable)
    .map((country: IBillingCountries) => {
      return { text: country.name, value: country.countryCode };
    });
  return countriesList;
}

export function logConfirmTelemetry(countryCode: string) {
  const logPayload: ITelemetryData = {
    page: getWindow().location.href,
    action: Constants.Telemetry.Action.Click,
    actionModifier: Constants.Telemetry.ActionModifier.BillingCountry,
    details: `Country ${countryCode} confirmed`,
  };
  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', logPayload);
  logger.info(logPayload.details, {
    action: logPayload.action,
    actionModifier: logPayload.actionModifier,
  });
}

export async function confirmBillingCountry({
  config,
  setBillingCountry,
  createCustomer,
}: {
  config: IConfigState;
  setBillingCountry?: (countryCode: string) => any;
  createCustomer?: () => void;
}) {
  try {
    await setBillingCountry(config.billingCountryCode);
    createCustomer();
  } catch {
    const logPayload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.CreateCustomer,
      details: `Error during create customer, or setting billing country, retrying create customer.`,
    };
    createCustomer();
    SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', logPayload);
    logger.info(logPayload.details, {
      action: logPayload.action,
      actionModifier: logPayload.actionModifier,
    });
  } finally {
    logConfirmTelemetry(config.billingCountryCode);
  }
}

export const getConsentState = (countryCode: string): ConsentType => {
  const selectedCountry = SUPPORTED_COUNTRIES.find((country) => country.countryCode.toLowerCase() === countryCode.toLowerCase());
  return selectedCountry.consentRequired;
};

/**
 * Determine if should show contact info in purchase disclaimer
 * @param consentOption The consent type to check if requires checkbox
 * @returns true if country doesn't require consent checkbox
 */
export const isContactInfoHiddenInDisclaimer = (consentOption: ConsentType) => {
  const consentsNotContactInfo = [IConsentOption.Exclude];
  return consentsNotContactInfo.includes(consentOption);
};

/**
 * These shouldn't be localized, they are used for logging purposes only.
 * @param stepId The stepId to convert back to string
 */
export const convertStepIdToName = (stepId: number): string => {
  interface stepIdToNameMapping {
    [stepId: number]: string;
  }
  const mapping: stepIdToNameMapping = {
    0: 'Billing region picker',
    1: 'Select plan',
    2: 'Price and payment options',
    3: 'Purchase Blends screen',
    4: 'Configure your purchase',
  };
  return mapping[`${stepId}`];
};

interface IMiniTermWithPrice {
  totalPrice: Price;
  termDuration: TermsStrings;
  billingPlan: IBillingPlan;
  termId: string;
}

export const getBillingPlanPricesPerTermDuration = ({
  plan,
  billingDuration,
}: {
  plan: MPRPPlansEntity;
  billingDuration: TermsStrings;
}): IMiniTermWithPrice[] => {
  const renewAvailability = plan.availabilities.find((availability) => {
    const { actions } = availability;
    return actions.includes(IAvailabilityActions.Renew) && actions.includes(IAvailabilityActions.Browse);
  });
  const prices: IMiniTermWithPrice[] = renewAvailability?.terms
    ?.filter((term) => term.termUnits === billingDuration)
    ?.map((term) => ({
      totalPrice: term.price,
      billingPlan: term.billingPlan,
      termDuration: term.termUnits,
      /** This is the RENEW termId in a free-trial availability-term */
      termId: term.termId,
    }));
  return prices || [];
};

/**
 * This method returns the full price string in the format of: "First month three, then <SomePrice+Currency>"
 * Depends on hasFree trial for first month free prefix
 * TermId should always be the renew termId with price, if it's the free trial termId, the price you'll see is 0.0 which is misleading.
 */
export const convertTermToString = ({
  context,
  term,
  countryCode,
  isQuantifiable,
  hasFreeTrial,
  locale,
}: {
  context: ILocContext & ILocParamsContext;
  term: AdvancedTerm;
  countryCode: string;
  isQuantifiable: boolean;
  hasFreeTrial: boolean;
  locale?: string;
}): string => {
  return getFullPriceString({
    context,
    countryCode,
    currency: term.price.currencyCode,
    priceValue: term.price.msrp,
    billingPlan: term.billingPlan,
    termDuration: term.renewTermUnits,
    isQuantifiable,
    hasFreeTrial,
    locale,
  });
};

export const pbAnnually = 'Annually';
export const pbMonthly = 'Monthly';
export const pbOther = 'Other';
export const getBillingCycle = (term: TermsStrings, billingPlan?: IBillingPlan): string => {
  const MultiYearTerms = [TermsStrings.Term3YString, TermsStrings.Term2YString];
  if (MultiYearTerms.includes(term) || billingPlan) {
    return pbOther;
  }
  if ([TermsStrings.Term1YString, TermsStrings.YearString].includes(term)) {
    return pbAnnually;
  }
  if ([TermsStrings.Term1MString, TermsStrings.MonthString].includes(term)) {
    return pbMonthly;
  }
};

export const buildUrlToAppDetails = (tab: Constants.Tabs, app: IAppDataItem, buildHref: any) => {
  const productString = getPrimaryProductUrl(app.primaryProduct);
  const productData = getProductByUrlKey({ urlKey: productString });
  const product = productData ? productData.UrlKey : productString;

  // redirect to PDP page, Plan and Price tab
  const pdpUrl = buildHref(getPDPRoute(app.appType), { productId: product, entityId: app.entityId }, { tab }, true);
  return pdpUrl;
};

export const redirectToAppDetails = (tab: Constants.Tabs, app: IAppDataItem, buildHref: any) => {
  const pdpUrl = buildUrlToAppDetails(tab, app, buildHref);

  urlReplace(pdpUrl);
};

export const getPurchaseBlendsMessageOverrides = (
  context: ILocContext & ILocParamsContext,
  isOneTimePayment: boolean,
  isProfessionalService: boolean
): Partial<IMessages> => {
  const riskRejected = context.locParams(
    'PurchaseBlends_Risk_Rejected_Error',
    ['https://go.microsoft.com/fwlink/?linkid=2101400&clcid=0x409'],
    'This purchase cannot be completed. Please contact Microsoft support via this link: https://go.microsoft.com/fwlink/?linkid=2101400&clcid=0x409'
  );
  return isOneTimePayment
    ? {
        'component.message-center.risk_rejected_error': riskRejected,
        'component.line-item.product-details.billing_term': ' ',
        'component.line-item.product-details.recurring_billing': ' ',
        'component.line-item.recurring-billing.off': ' ',
        'component.line-item.duration.one_month': ' ',
        'component.line-item.monthly_billing_cycle_per_user': ' ',
        'component.line-item.duration.total.one_month': ' ',
        'component.common.line-item.subtotal_term_message': ' ',
      }
    : isProfessionalService
    ? {
        'component.message-center.risk_rejected_error': riskRejected,
        'component.line-item.product-details.recurring_billing': ' ',
        'component.line-item.recurring-billing.off': ' ',
      }
    : {
        'component.message-center.risk_rejected_error': riskRejected,
      };
};
