/* eslint-disable react/forbid-dom-props */
import * as React from 'react';
import * as PropTypes from 'prop-types';
import SpzaComponent from './spzaComponent';
import { InternalLink } from './internalLink';
import { ILocContext, ICommonContext } from '../interfaces/context';
import * as DetailUtils from './../utils/detailUtils';
import { ILinksList } from './../Models';

export interface IbreadcrumbUrl {
  linksList: ILinksList[];
  href: string;
  isNewCheckout?: boolean;
  hideBreadcrumbUrl?: boolean;
}

export class BreadcrumbUrl extends SpzaComponent<IbreadcrumbUrl, any> {
  context: ILocContext & ICommonContext;

  buildGalleryLink(title: string, href: string) {
    return (
      <InternalLink
        href={href}
        role="link"
        className={'c-button goBackButton'}
        additionalCommand={() => {
          DetailUtils.generateBreadcrumbPayloadAndLogTelemetry('Apps');
        }}
        accEnabled={true}
        aria-label={`${title} link breadcrumb region`}
        aria-current="page"
      >
        {title}
      </InternalLink>
    );
  }

  renderImpl() {
    const { hideBreadcrumbUrl } = this.props;
    return (
      <div>
        <div className="navigationBar" id="maincontent">
          {!hideBreadcrumbUrl && (
            <nav className="breadcrumb" aria-label="breadcrumbs">
              {this.props.linksList.map((link: ILinksList, index) => {
                const title = this.context.loc(link.locKey);
                const key = `breadcrumb-${link.href}-${title}`;
                link.href = link.href || this.props.href;
                if (index === this.props.linksList.length - 1) {
                  return (
                    <h1 key={key} className="appTabButton" aria-label="breadcrumb" aria-current="page">
                      {this.context.loc(link.locKey)}
                    </h1>
                  );
                }

                return (
                  <div key={key} className="breadcrumbItem">
                    {this.buildGalleryLink(title, link.href)}
                    <span className="c-glyph" />
                  </div>
                );
              })}
            </nav>
          )}
        </div>
      </div>
    );
  }
}

(BreadcrumbUrl as any).contextTypes = {
  loc: PropTypes.func,
};
