/* eslint-disable no-prototype-builtins */
import { Constants } from './constants';

export const isUndefined = (item: any): boolean => {
  return item === undefined || typeof item === Constants.TypeOf.undefinedName;
};

/**
 *  determine item is null or undefined mainly for primitive values
 *
 *   primitive values are booleans (e.x. false), numbers (e.x. 0), and strings (e.x. '')
 *   otherwise for object, array, function, using `!item` is sufficient
 */
export const isNullorUndefined = (item: any): boolean => {
  return isUndefined(item) || item === null;
};

export const isObject = (item: any) => {
  return !!item && typeof item === Constants.TypeOf.objectName;
};

/**
 * check if an existing object, or array is empty
 */
export const isEmptyObject = (object: any) => {
  if (!isObject(object)) {
    return false;
  }

  const isNotEmpty: boolean = Object.keys(object).some((key) => {
    // return as soon as object is not empty
    return object.hasOwnProperty(key);
  });
  return !isNotEmpty;
};
export const isArray = (item: any) => {
  return !!item && Array.isArray(item);
};

/**
 * check if it is a pure object, not an array
 */
export const isPureObject = (item: any) => {
  return isObject(item) && !isArray(item);
};

export const isFunction = (item: any) => {
  return !!item && typeof item === Constants.TypeOf.functionName;
};

export const isBoolean = (item: any) => {
  return typeof item === Constants.TypeOf.booleanName;
};

export const isString = (item: any) => {
  return typeof item === Constants.TypeOf.stringName;
};

export const isNumber = (item: any) => {
  return typeof item === Constants.TypeOf.numberName;
};

/**
 * deep merge two objects
 */
export const mergeDeep = (target: any, source: any) => {
  let output = (Object as any).assign({}, target);
  if (isObject(target) && isObject(source)) {
    if (Array.isArray(source)) {
      output = source.concat(target.filter((element: any) => source.indexOf(element) < 0));
    } else {
      Object.keys(source).forEach((key) => {
        const sourceKey = source[`${key}`];
        if (isObject(sourceKey)) {
          if (!(key in target)) {
            (Object as any).assign(output, { [key]: source[`${key}`] });
          } else {
            output[`${key}`] = mergeDeep(target[`${key}`], source[`${key}`]);
          }
        } else {
          (Object as any).assign(output, { [key]: source[`${key}`] });
        }
      });
    }
  }
  return output;
};

/**
 * deep equal between two objects
 *
 *  NOTE:
 *      EXCEPTION: if two objects have functions they would never be equal
 *      reference:
 *          chai deep equal
 *          https://github.com/chaijs/chai/issues/697
 *
 *          Deep equal is designed to compare deeply not loosely, if that makes sense.
 *          In other words, it is designed to traverse the keys of an object or array (and soon iterables) and
 *          compare the values of those properties with each other.
 *          Importantly, when it gets to a value that isn't an iterable, array, or object,
 *          it actually uses a stricter-than-strict equality comparison (it uses SameValue).
 *          Functions are not iterables, they have no keys which could make a good quality comparison,
 *          and as such are given the SameValue treatment.
 */
export const deepEqual = function (x: any, y: any, shouldSkipInlineFunctionCheck = false): boolean {
  if (x === y) {
    return true;
  }
  if (isFunction(x) && isFunction(y) && shouldSkipInlineFunctionCheck) {
    return true;
  } else if (isObject(x) && isObject(y)) {
    if (Object.keys(x).length !== Object.keys(y).length) {
      return false;
    }

    for (const prop in x) {
      if (y.hasOwnProperty(prop)) {
        if (!deepEqual(x[`${prop}`], y[`${prop}`], shouldSkipInlineFunctionCheck)) {
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  }
  return false;
};
