import * as httpProtocol from './httpProtocol';
import { IUserFavouritePostPayload, IRawUserFavourites } from './../../interfaces/userFavouriteModels';
import { Constants } from './../../utils/constants';
import { getAppConfig } from './../init/appConfig';
import { logError } from './../../utils/httpClientUtil';

const options = {
  requireCorrelationId: true,
  stringifyPostData: false,
  authenticationType: httpProtocol.AuthenticationTypes.Agreement,
  setBearerHeader: true,
};

export function getUserFavouriteItems(): Promise<IRawUserFavourites> {
  const endpoint = Constants.UserFavourite.ViewEndpoint;
  const httpGet = httpProtocol.get(endpoint, options);

  return httpGet
    .addQueryEntry(Constants.QueryStrings.version, getAppConfig(Constants.marketplaceApiVersion))
    .request()
    .catch((error) => {
      logError('getUserFavouriteItems', error);
      return Promise.reject(error);
    });
}

export function addUserFavouriteItem(payload: IUserFavouritePostPayload) {
  const endpoint = Constants.UserFavourite.ViewEndpoint;
  const httpPost = httpProtocol.post(endpoint, options);

  return httpPost
    .addQueryEntry(Constants.QueryStrings.version, getAppConfig(Constants.marketplaceApiVersion))
    .setData(payload)
    .request()
    .catch((error: any) => {
      logError('addUserFavouriteItem', error);
      return Promise.reject(error);
    });
}

export function deleteUserFavouriteItem(entityGuid: string, applicationType: Constants.UserFavourite.ApplicationTypes) {
  const params = entityGuid;
  const endpoint = `${Constants.UserFavourite.ViewEndpoint}/${params}`;
  const httpDelete = httpProtocol.del(endpoint, options);

  return httpDelete
    .addQueryEntry(Constants.QueryStrings.version, getAppConfig(Constants.marketplaceApiVersion))
    .addQueryEntry(Constants.QueryStrings.applicationType, applicationType)
    .request()
    .catch((error: any) => {
      logError('deleteUserFavouriteItem', error);
      return Promise.reject(error);
    });
}
