import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import type { FC } from 'react';
import { Text, mergeStyleSets, Stack, Icon } from '@fluentui/react';
import classNames from 'classnames';
import { isNil } from 'lodash-es';
import { useSelector } from 'react-redux';
import { NeutralColors, SharedColors } from '@fluentui/theme';

import { ILocContext } from '@shared/interfaces/context';
import { storeFeedback } from 'services/feedback';
import { Constants } from '@shared/utils/constants';
import { IState } from '@src/State';
import { logTelemetry } from '@shared/utils/telemetryUtils';

export type IThumbsUpDownProps = { localizedQuestion?: string };

const contentStyles = mergeStyleSets({
  containerClass: { paddingTop: 8 },
  feedbackButtons: { color: NeutralColors.gray160, '&:hover': { color: SharedColors.cyanBlue10 } },
  feedbackButtonsSelected: { color: SharedColors.cyanBlue10, cursor: 'default' },
  defaultCursor: { cursor: 'default' },
  pointerCursor: { cursor: 'pointer' },
});

const Negative = 'Negative';
const Positive = 'Positive';

type feedbackType = typeof Positive | typeof Negative;

const getAriaLabel = ({
  isFeedbackNull,
  isFeedbackPositive,
  feedbackNoun,
  context,
}: {
  isFeedbackNull: boolean;
  isFeedbackPositive: boolean;
  feedbackNoun: feedbackType;
  context: ILocContext;
}): string => {
  const isPositiveFeedbackSelected = !isFeedbackNull && isFeedbackPositive;
  const isNegativeFeedbackSelected = !isFeedbackNull && !isFeedbackPositive;
  const selectionCondition = feedbackNoun === Negative ? isPositiveFeedbackSelected : isNegativeFeedbackSelected;
  const feedbackText = feedbackNoun === Positive ? Positive : Negative;

  return isFeedbackNull || selectionCondition
    ? context.loc(`Feedback_ShowingResultsFor_${feedbackText}Feedback`, `${feedbackText} feedback`)
    : context.loc(`Feedback_ShowingResultsFor_${feedbackText}FeedbackProvided`, `Provided ${feedbackText} feedback`);
};

export const sendFeedback = ({
  isPositive,
  requestId,
  showingResultsFor,
  didYouMean,
  previousSearchText,
}: {
  isPositive: boolean;
  requestId: string;
  showingResultsFor: string;
  didYouMean: string;
  previousSearchText: string;
}) => {
  const details = { positiveFeedBack: isPositive, requestId, showingResultsFor, didYouMean, searchTerm: previousSearchText };

  logTelemetry(
    Constants.Telemetry.Action.Feedback,
    Constants.Telemetry.ActionModifier.ShowingResultsForFeedback,
    JSON.stringify(details),
    true
  );

  storeFeedback({
    action: Constants.Telemetry.Action.Click,
    actionModifier: Constants.Telemetry.ActionModifier.ShowingResultsForFeedback,
    details: JSON.stringify(details),
  });
};

const likeIconName = 'Like';
const solidLikeIconName = 'LikeSolid';
const dislikeIconName = 'Dislike';
const solidDislikeIconName = 'DislikeSolid';
export const ThumbsUpDown: FC<IThumbsUpDownProps> = ({ localizedQuestion = '' }: IThumbsUpDownProps, context: ILocContext) => {
  const { containerClass, feedbackButtons, feedbackButtonsSelected, defaultCursor, pointerCursor } = contentStyles;
  const [isFeedbackPositive, setFeedback] = useState<boolean>(null);
  const { requestId, showingResultsFor, didYouMean, previousSearchText } = useSelector(
    ({ search: { requestId, showingResultsFor, didYouMean, previousSearchText } }: IState) => ({
      requestId,
      showingResultsFor,
      didYouMean,
      previousSearchText,
    })
  );
  const isFeedbackNull = isNil(isFeedbackPositive);

  const likeAria = getAriaLabel({ isFeedbackNull, feedbackNoun: Positive, isFeedbackPositive, context });
  const dislikeAria = getAriaLabel({ isFeedbackNull, feedbackNoun: Negative, isFeedbackPositive, context });

  const handleFeedbackClick = ({ isPositive }: { isPositive: boolean }) => {
    if (isFeedbackNull) {
      setFeedback(isPositive);
      sendFeedback({ isPositive, requestId, showingResultsFor, didYouMean, previousSearchText });
    }
  };

  const detectKeyboardClick = ({ event: { key }, isPositive }: { event: React.KeyboardEvent<Element>; isPositive: boolean }) => {
    if (key === Constants.SystemKeyAsString.Enter || key === Constants.SystemKeyAsString.Space) {
      handleFeedbackClick({ isPositive });
    }
  };

  const positiveIconName = isFeedbackNull ? likeIconName : isFeedbackPositive ? solidLikeIconName : likeIconName;
  const negativeIconName = isFeedbackNull ? dislikeIconName : !isFeedbackPositive ? solidDislikeIconName : dislikeIconName;
  const thumbsUpClass = classNames({
    [defaultCursor]: !isFeedbackNull && !isFeedbackPositive,
    [feedbackButtons]: isFeedbackNull,
    [pointerCursor]: isFeedbackNull,
    [feedbackButtonsSelected]: !isFeedbackNull && isFeedbackPositive,
  });
  const thumbsDownClass = classNames({
    [defaultCursor]: !isFeedbackNull && isFeedbackPositive,
    [feedbackButtons]: isFeedbackNull,
    [pointerCursor]: isFeedbackNull,
    [feedbackButtonsSelected]: !isFeedbackNull && !isFeedbackPositive,
  });

  return (
    <div>
      <Stack horizontal className={containerClass} tokens={{ childrenGap: 6 }}>
        <Stack.Item>
          <Text>{localizedQuestion}</Text>
        </Stack.Item>
        <Stack.Item>
          <Icon
            role={'button'}
            aria-label={likeAria}
            tabIndex={0}
            onClick={() => handleFeedbackClick({ isPositive: true })}
            onKeyDown={(event: React.KeyboardEvent<Element>) => detectKeyboardClick({ event, isPositive: true })}
            iconName={positiveIconName}
            className={thumbsUpClass}
          />
        </Stack.Item>
        <Stack.Item>
          <Icon
            role={'button'}
            aria-label={dislikeAria}
            tabIndex={0}
            onClick={() => handleFeedbackClick({ isPositive: false })}
            onKeyDown={(event: React.KeyboardEvent<Element>) => detectKeyboardClick({ event, isPositive: false })}
            iconName={negativeIconName}
            className={thumbsDownClass}
          />
        </Stack.Item>
      </Stack>
    </div>
  );
};

(ThumbsUpDown as React.FC).contextTypes = {
  loc: PropTypes.func,
};
