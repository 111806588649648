export interface IBillingAccounts {
  value: IBillingAccount[];
}

export interface IBillingAccount {
  id: string;
  name: string;
  properties: IBillingAccountProperties;
}

export interface Enrollment {
  channel: EnrollmentChannelType;
}

export interface IBillingAccountProperties {
  accountType: BillingAccountType;
  agreementType: BillingAgreementType;
  accountId?: string;
  accountStatus: string;
  displayName: string;
  hasReadAccess: string;
  organizationId: string;
  billingProfiles: IBillingProfile[];
  enrollmentDetails: Enrollment;
}

export interface IBillingProfile {
  id: string;
  name: string;
  properties: IBillingProfileProperties;
  type: string;
}

export interface IBillingProfileProperties {
  address: object;
  billingRelationshipType: string;
  currency: string;
  displayName: string;
  enabledAzurePlans: any[];
  hasReadAccess: boolean;
  invoiceDay: number;
  invoiceEmailOptIn: boolean;
  spendingLimit: string;
  status: string;
  systemId: string;
  targetClouds: any[];
  billingProfileId: string;
  billingProfileDisplayName: string;
}

export enum BillingAccountType {
  Enterprise = 'Enterprise',
  Individual = 'Individual',
  Partner = 'Partner',
}

export enum BillingAgreementType {
  MicrosoftCustomerAgreement = 'MicrosoftCustomerAgreement',
  EnterpriseAgreement = 'EnterpriseAgreement',
  MicrosoftOnlineServicesProgram = 'MicrosoftOnlineServicesProgram',
  MicrosoftPartnerAgreement = 'MicrosoftPartnerAgreement',
}

export enum EnrollmentChannelType {
  EaDirect = 'EaDirect',
  EaIndirect = 'EaIndirect',
}
