/* eslint-disable react/forbid-dom-props */
import * as React from 'react';
import { Checkbox, IconButton, Modal, PrimaryButton, Text } from '@fluentui/react';
import type { IIconProps } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { InstructionsModalBody } from './instructionsModalBody';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { Constants } from '@shared/utils/constants';
import { saveLocalStorageItem } from '@shared/utils/browserStorageUtils';
import { getNpsModule } from '@appsource/utils/nps';

export interface IInstructionsModalProps {
  context: ILocContext & ILocParamsContext;
  isFromDownload: boolean;
  dismissModal: () => void;
}

const cancelIcon: IIconProps = { iconName: 'Cancel' };

export const InstructionsModal = ({ context, isFromDownload, dismissModal }: IInstructionsModalProps) => {
  const [isModalOpen, { setFalse: hideModal }] = useBoolean(true);
  const [isChecked, setIsChecked] = React.useState(false);

  const closeModal = () => {
    if (isFromDownload && isChecked) {
      saveLocalStorageItem(Constants.LocalStorage.dontShowInstructions, true);
    }
    hideModal();
    dismissModal();
    getNpsModule()?.ctaClicked();
  };

  return (
    <Modal containerClassName="instructionsModalContainer" isOpen={isModalOpen} onDismiss={closeModal}>
      <div role="dialog" aria-label="instructionsModal" className="instructionsModalClass">
        <div className="toolBar">
          <Text className="instructionsHeader">{context.loc('PowerBIVisual_Instructions_Header')}</Text>
          <IconButton aria-label="close" className="cancel" iconProps={cancelIcon} onClick={closeModal} />
        </div>

        <span
          className="instructionsText"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: context.locParams('PowerBIVisual_Instructions_Intro', [
              `<a class='c-hyperlink' rel='noreferrer' target='_blank' href=${
                Constants.PowerBIVisualInstructionsUrl
              }>${context.loc('PowerBIVisual_Instructions')}</a>`,
            ]),
          }}
        />

        <InstructionsModalBody context={context} />

        <div className="instructionsFooter">
          {isFromDownload && (
            <Checkbox
              className="instructionsCheckbox"
              label={context.loc('Dialog_Dont_Show_Again')}
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
            />
          )}
          <PrimaryButton name="button" type="submit" onClick={closeModal}>
            {context.loc('Dialog_Close')}
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};
