import { deepEqual, isArray } from './objectUtils';
import { IRichTextDropDownItem } from './../components/richTextDropDown';
import { Constants } from './constants';

/**
 * get option index
 *
 *  get option index by comparing with option to-find by value, instead of reference
 *
 *  NOTE:
 *      Always use this function to get option index,
 *      finds a match by comparing two options are deep equal
 *
 * @param options: array of options
 * @param optionToFind: option to find in array of options
 */
export function getOptionIndex<T>(options: IRichTextDropDownItem<T>[], optionToFind: IRichTextDropDownItem<T>): number {
  let optionIndex: number = -1;

  if (!isArray(options) || !optionToFind) {
    return optionIndex;
  }

  options.some((option: IRichTextDropDownItem<T>, index: number) => {
    const match = deepEqual(option, optionToFind);

    if (match) {
      optionIndex = index;
    }

    return match;
  });

  return optionIndex;
}

// for search input
export function getFilteredOptions<T>(options: IRichTextDropDownItem<T>[], optionToFind: string) {
  let res = options;
  if (optionToFind.length) {
    res = res.filter((o) => o.text.toLowerCase().indexOf(optionToFind.toLowerCase()) !== -1);
  }
  return res;
}

export function getContainerClassName(className: string): string {
  return `c-select-menu richTextDropDown outline-focus ${className || ''}`;
}

export function getContainerAriaLabel(dropdownLabel: string): string {
  return dropdownLabel || '';
}

export const CONTAINER_ROLE = 'button';

export function getOptionClassName<T>(option: IRichTextDropDownItem<T>, highLightOption: IRichTextDropDownItem<T>): string {
  const highlightClassName: string = isOptionSelected(option, highLightOption) ? 'highlight' : '';

  return `c-menu-item ${highlightClassName}`;
}

export function isOptionSelected<T>(option: IRichTextDropDownItem<T>, highLightOption: IRichTextDropDownItem<T>): boolean {
  return deepEqual(highLightOption, option, true);
}

export function isHoverEvent(eventType: Constants.EventType) {
  return (
    eventType === Constants.EventType.MouseEnter ||
    eventType === Constants.EventType.MouseLeave ||
    eventType === Constants.EventType.ReactMouseEnter ||
    eventType === Constants.EventType.ReactMouseLeave
  );
}
