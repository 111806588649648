import { BrowserPerformanceClient, PublicClientApplication } from '@azure/msal-browser';
import { logger } from '@src/logger';
import { Constants } from '@shared/utils/constants';
import { stringifyError } from '@shared/utils/errorUtils';
import { getConfig } from './config';

class AuthClient {
  private static instance: PublicClientApplication;
  private static initialized = false;

  // eslint-disable-next-line no-useless-constructor
  private constructor() {
    // Private constructor to prevent direct construction calls
  }

  public static getInstance() {
    if (__CLIENT__) {
      if (!AuthClient.instance) {
        const config = getConfig();
        AuthClient.instance = new PublicClientApplication({
          ...config,
          telemetry: { client: new BrowserPerformanceClient(config) },
        });
      }
      return AuthClient.instance;
    } else {
      console.warn('Cannot get PCA instance on server');
      return null;
    }
  }

  public static async initialize({ onSuccess, onFailure }: { onSuccess: () => void; onFailure?: () => void }): Promise<void> {
    const instance = AuthClient.getInstance();
    if (!AuthClient.initialized) {
      try {
        await instance.initialize();
        AuthClient.initialized = true;
        onSuccess();
        logger.info('MSAL initialized successfully', {
          action: Constants.Telemetry.Action.Login,
          actionModifier: Constants.Telemetry.ActionModifier.MsalInitializationSuccess,
        });
      } catch (error) {
        onFailure?.();
        logger.error(stringifyError(error), {
          action: Constants.Telemetry.Action.Login,
          actionModifier: Constants.Telemetry.ActionModifier.MsalInitializationError,
          error,
        });
      }
    } else {
      onSuccess();
    }
  }
}

export default AuthClient;
