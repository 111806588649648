import {
  Action,
  isType,
  PartnerAppDataReceivedAction,
  PowerBIDataReceivedAction,
  AppsPricingDataReceivedAction,
  FirstPartyPricingDataReceivedAction,
  AppDetailPricingReceivedAction,
  RelatedAppsItemsPricingDataReceivedAction,
  AppPricingDataReceivedAction,
  EntityReviewCommentsReceivedAction,
  EntityReviewCommentsCleanupAction,
  EntityReviewCommentDeleteResult,
  EntityReviewCommentUpdateResult,
  EntityReviewCommentsLoadingAction,
  EntityReviewCommentsInitializeStateAction,
  EntityReviewCommentsUpdateTotalCommentCountAction,
  EntityReviewCommentCreateResult,
  EntityReviewCommentsUnloadPagesAction,
  EntityMarkedAsHelpfulReviewUpdateListAction,
  EntityLinkedInProductGroupUpdateAction,
  EntityReviewSetIsHelpfulAction,
  EntityReviewSetMarkAsHelpfulCount,
  EntityReviewSetIsHelpfulLoadingAction,
  SetRecommenededSizes,
  LoadingRecommenededSizes,
  EntityUserCommentedReviewIdsUpdateListAction,
  RecommendedWhatsNewAppsReceivedAction,
  RecommendedTrendingAppsReceivedAction,
  RecommendedAppsItemsPricingDataReceivedAction,
  RecommendedMicrosoft365WhatsNewAppsReceivedAction,
  RecommendedMicrosoft365TrendingAppsReceivedAction,
} from '@shared/actions/actions';
import { commonEntityDataReducer } from './commonEntityDataReducer';
import { IAppDataState, initialAppDataState, copyState, deepCopyDataMap } from './../../State';
import { Constants } from './../utils/constants';
import { loadAppPricing } from './../utils/pricing';
import { parsePBIApps } from '../../embed/reducers/pbiAppDataReducer';
import { parsePartnerApps } from '../../embed/reducers/partnerAppDataReducer';
import { IAppDataItem, ITelemetryData } from '@shared/Models';
import { SpzaInstrumentService } from '../services/telemetry/spza/spzaInstrument';
import { generateHashMap } from '../utils/hashMapUtils';
import { updateMatchFunctions } from 'utils/filterHelpers';
import { getFuturePricesInformation } from '@shared/utils/futurePricesUtils';
import {
  appendReviewCommentsToState,
  calculateTotalReviewCommentsPagesCount,
  combineDeletedComments,
  createInitialReviewCommentsState,
} from '@shared/utils/reviewsUtils';
import { IAppReviewCommentsState } from '@shared/interfaces/reviews/comments';
import { take, pick, values, map, differenceBy } from 'lodash-es';
import { logger } from '@src/logger';
import { getProductByUrlKey } from '@shared/utils/appUtils';

export default function appDataReducer(state: IAppDataState = initialAppDataState, action: Action<any>): IAppDataState {
  const newState = copyState(state);
  const newCommonState = commonEntityDataReducer(Constants.EntityType.App, state, action) as IAppDataState;
  if (isType(action, AppPricingDataReceivedAction)) {
    if (!action.payload?.pricingPayload?.pricingData) {
      return state;
    }

    newState.dataList = newState.dataList.map((app: IAppDataItem) => {
      if (app.entityId?.toLowerCase() === action.payload.entityId?.toLowerCase()) {
        return loadAppPricing(app, action.payload.pricingPayload.pricingData);
      }

      return app;
    });
  } else if (isType(action, RelatedAppsItemsPricingDataReceivedAction)) {
    if (!action.payload.pricingData || Object.keys(action.payload.pricingData).length === 0) {
      return state;
    }

    if (state.relatedAppsItems && state.relatedAppsItems.items) {
      let updatedLinkedAddIns: IAppDataItem[] = null;
      let updatedSaasLinkingItems: IAppDataItem[] = null;
      let updatedSuggestedItems: IAppDataItem[] = null;
      let updatedDataList: IAppDataItem[] = null;

      if (state.relatedAppsItems.items.saasLinkingItems) {
        updatedSaasLinkingItems = map(state.relatedAppsItems.items.saasLinkingItems, (app: IAppDataItem) =>
          loadAppPricing(app, action.payload.pricingData)
        );
      }

      if (state.relatedAppsItems.items.suggestedItems) {
        updatedSuggestedItems = map(state.relatedAppsItems.items.suggestedItems, (app: IAppDataItem) =>
          loadAppPricing(app, action.payload.pricingData)
        );
      }

      if (state.relatedAppsItems.items.linkedAddIns) {
        updatedLinkedAddIns = map(state.relatedAppsItems.items.linkedAddIns, (app: IAppDataItem) =>
          loadAppPricing(app, action.payload.pricingData)
        );

        updatedDataList = [
          ...state.dataList,
          ...differenceBy(state.relatedAppsItems.items.linkedAddIns, state.dataList, 'entityId'),
        ];
      }

      return {
        ...state,
        relatedAppsItems: {
          ...state.relatedAppsItems,
          items: {
            linkedAddIns: updatedLinkedAddIns ?? state.relatedAppsItems.items.linkedAddIns,
            suggestedItems: updatedSuggestedItems ?? state.relatedAppsItems.items.suggestedItems,
            saasLinkingItems: updatedSaasLinkingItems ?? state.relatedAppsItems.items.saasLinkingItems,
          },
        },
        dataList: updatedDataList ?? state.dataList,
      };
    }
  } else if (isType(action, RecommendedWhatsNewAppsReceivedAction)) {
    newState.recommendedApps.whatsNewApps = action.payload;
  } else if (isType(action, RecommendedTrendingAppsReceivedAction)) {
    newState.recommendedApps.trendingApps = action.payload;
  } else if (isType(action, RecommendedMicrosoft365WhatsNewAppsReceivedAction)) {
    newState.recommendedApps.microsoft365WhatsNewApps = action.payload;
  } else if (isType(action, RecommendedMicrosoft365TrendingAppsReceivedAction)) {
    newState.recommendedApps.microsoft365TrendingApps = action.payload;
  } else if (isType(action, AppsPricingDataReceivedAction)) {
    const { payload } = action;
    const { pricingData = {} } = payload;

    newState.dataList = state.dataList.map((app: IAppDataItem) => loadAppPricing(app, pricingData));
    const dataMap = deepCopyDataMap(state.dataMap);
    updateMatchFunctions(dataMap);
    newState.dataMap = dataMap;
    newState.pricingPayload = payload;
  } else if (isType(action, RecommendedAppsItemsPricingDataReceivedAction)) {
    const { payload } = action;
    const { pricingData = {} } = payload;

    newState.recommendedApps.whatsNewApps = state.recommendedApps.whatsNewApps.map((app: IAppDataItem) =>
      loadAppPricing(app, pricingData)
    );
    newState.recommendedApps.trendingApps = state.recommendedApps.trendingApps.map((app: IAppDataItem) =>
      loadAppPricing(app, pricingData)
    );

    newState.recommendedApps.microsoft365WhatsNewApps = state.recommendedApps.microsoft365WhatsNewApps.map((app: IAppDataItem) =>
      loadAppPricing(app, pricingData)
    );
    newState.recommendedApps.microsoft365TrendingApps = state.recommendedApps.microsoft365TrendingApps.map((app: IAppDataItem) =>
      loadAppPricing(app, pricingData)
    );

    newState.pricingPayload = payload;
  } else if (isType(action, PowerBIDataReceivedAction)) {
    if (action.payload.appData) {
      newState.dataList = newState.dataList.concat(parsePBIApps(action.payload.appData));
      newState.idMap = generateHashMap(newState.dataList, ['entityId', 'friendlyURL']);
      newState.partnerAppDataLoaded = true;

      // This event is used to indicate that the Power BI data is fetched and parsing is done.
      const payload: ITelemetryData = {
        page: 'In App Gallery(PowerBI)',
        action: Constants.Telemetry.Action.PageLoad,
        actionModifier: Constants.Telemetry.ActionModifier.Info,
        details: '[End] PowerBI data parsing done',
      };
      SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
      logger.info(payload.details, {
        action: payload.action,
        actionModifier: payload.actionModifier,
        page: payload.page,
      });
    }
  } else if (isType(action, PartnerAppDataReceivedAction)) {
    if (action.payload.appData) {
      const appData = parsePartnerApps(action.payload.appData, action.payload.embedHost);
      newState.dataList = state.dataList.concat(appData).sort((a: IAppDataItem, b: IAppDataItem) => {
        const aTitle = a.title.toLowerCase();
        const bTitle = b.title.toLowerCase();
        return aTitle > bTitle ? 1 : aTitle < bTitle ? -1 : 0;
      });
      newState.partnerAppDataLoaded = true;
      newState.idMap = generateHashMap(newState.dataList, ['entityId']);
    } else {
      newState.partnerAppDataLoaded = true;
      newState.partnerAppDataLoadedError = true;
    }

    // This event is used to indicate that the Power BI data is fetched and parsing is done.
    const payload: ITelemetryData = {
      page: 'In App Gallery(' + getProductByUrlKey({ urlKey: action.payload.embedHost })?.UrlKey + ')',
      action: Constants.Telemetry.Action.PageLoad,
      actionModifier: Constants.Telemetry.ActionModifier.Info,
      details: '[End] Partner App data parsing done',
    };
    SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
      page: payload.page,
    });
  } else if (isType(action, AppDetailPricingReceivedAction)) {
    if (action.payload.pricing) {
      const index = state.idMap[action.payload.entityId.toString().toLowerCase()];
      if (index !== -1) {
        newState.dataList = newState.dataList.slice();
        const currentData = newState.dataList[`${index}`] as any;
        const { futurePrices, pricing } = action.payload;
        newState.dataList[`${index}`] = {
          ...currentData,
          pricingInformation: action.payload.pricing,
          futurePricesInformation: getFuturePricesInformation(futurePrices, pricing),
        };
      }
    }
  } else if (isType(action, EntityReviewCommentsReceivedAction)) {
    const { appReviewsData } = newState;
    // Merge data with the previous comment data for the review id
    const { [action.payload.reviewId]: prevReviewCommentsState } = appReviewsData.reviewComments;
    const newReviewCommentsState: IAppReviewCommentsState = {
      ...appendReviewCommentsToState(prevReviewCommentsState, action.payload.comments),
    };

    appReviewsData.reviewComments = {
      ...appReviewsData.reviewComments,
      // Convert the new comment list to normalized data
      [action.payload.reviewId]: {
        ...newReviewCommentsState,
        // Remove newly added comments if they were now loaded into the main list
        newlyAddedComments: newReviewCommentsState.newlyAddedComments.filter((c) => !newReviewCommentsState.commentsById[c.id]),
        currentLoadedPage: action.payload.pageNumber,
      },
    };
  } else if (isType(action, EntityReviewCommentsCleanupAction)) {
    if (newState.appReviewsData.reviewComments && newState.appReviewsData.reviewComments[action.payload.reviewId]) {
      // Clean the comments of the given review from the state
      const { [action.payload.reviewId]: removedCommentData, ...newReviewCommentsState } = newState.appReviewsData.reviewComments;
      newState.appReviewsData.reviewComments = newReviewCommentsState;
    }
  } else if (isType(action, EntityReviewCommentUpdateResult) || isType(action, EntityReviewCommentDeleteResult)) {
    const { payload } = action;
    const { reviewId, isNewlyAddedComment, actionResult } = payload;
    const { [reviewId]: prevReviewCommentsState } = newState.appReviewsData.reviewComments;
    const newReviewCommentsState = { ...prevReviewCommentsState };
    if (isNewlyAddedComment) {
      const updatedComment = actionResult.reviewComment;
      newReviewCommentsState.newlyAddedComments = newReviewCommentsState.newlyAddedComments.map((c) =>
        c.id === updatedComment.id ? updatedComment : c
      );
    } else {
      // Upon deletion or update of a comment, update it in the state immediately with the returned comment representation
      const commentId = actionResult.reviewComment.id;
      newReviewCommentsState.commentsById = {
        ...newReviewCommentsState.commentsById,
        [commentId]: actionResult.reviewComment,
      };
      newReviewCommentsState.reducedCommentIds = combineDeletedComments(newReviewCommentsState);
    }
    // When deleting comments, reduce the comments counter
    if (isType(action, EntityReviewCommentDeleteResult)) {
      const reviewIdx = newState.appReviewsData.reviews.findIndex((r) => r.id === action.payload.reviewId);
      if (reviewIdx > -1) {
        newState.appReviewsData.reviews[`${reviewIdx}`].comments_count -= 1;
      }
    }
    newState.appReviewsData.reviewComments = {
      ...newState.appReviewsData.reviewComments,
      [reviewId]: newReviewCommentsState,
    };
  } else if (isType(action, EntityReviewCommentsLoadingAction)) {
    // Update the loading state for a given review's comments list (ex. when initially expanding the comments section for a review)
    const { [action.payload.reviewId]: prevReviewCommentsState } = newState.appReviewsData.reviewComments;

    // Update the review comments state with the change
    newState.appReviewsData.reviewComments = {
      ...newState.appReviewsData.reviewComments,
      [action.payload.reviewId]: { ...prevReviewCommentsState, isLoading: action.payload.isLoading },
    };
  } else if (isType(action, EntityReviewCommentsUnloadPagesAction)) {
    // Update the current page for a given review's comments list
    const { [action.payload.reviewId]: prevReviewCommentsState } = newState.appReviewsData.reviewComments;
    const { commentIds: prevCommentIds, commentsById: prevCommentsById } = prevReviewCommentsState;
    newState.appReviewsData.reviewComments = {
      ...newState.appReviewsData.reviewComments,
      [action.payload.reviewId]: appendReviewCommentsToState(
        // Erase loaded data from the comments state
        { ...prevReviewCommentsState, reducedCommentIds: [], commentsById: {}, commentIds: [], currentLoadedPage: 1 },
        // Collect the comments from the first page and add them to the state
        values(pick(prevCommentsById, take(prevCommentIds, Constants.Reviews.CommentsPerPage)))
      ),
    };
  } else if (isType(action, EntityReviewCommentsUpdateTotalCommentCountAction)) {
    const { reviewId, totalComments } = action.payload;
    // Update the loading state for a given review's comments list (ex. when initially expanding the comments section for a review)
    const { [reviewId]: reviewCommentsState = createInitialReviewCommentsState() } = newState.appReviewsData.reviewComments;

    // Update the review comments state with the change
    newState.appReviewsData.reviewComments = {
      ...newState.appReviewsData.reviewComments,
      [reviewId]: {
        ...reviewCommentsState,
        totalComments,
        totalPages: calculateTotalReviewCommentsPagesCount(totalComments),
      },
    };
  } else if (isType(action, EntityReviewCommentsInitializeStateAction)) {
    const { [action.payload.reviewId]: newReviewCommentsState = createInitialReviewCommentsState() } =
      newState.appReviewsData.reviewComments || {};
    newState.appReviewsData.reviewComments = {
      ...newState.appReviewsData.reviewComments,
      [action.payload.reviewId]: { ...newReviewCommentsState, totalComments: action.payload.totalComments },
    };
  } else if (isType(action, EntityReviewCommentCreateResult)) {
    if (!action.payload.actionResult.error) {
      const { [action.payload.reviewId]: prevReviewCommentsState } = newState.appReviewsData.reviewComments;
      let newReviewCommentsState: IAppReviewCommentsState;
      if (action.payload.isNewlyAddedComment) {
        // Add the comment to the newly added comments list
        newReviewCommentsState = {
          ...prevReviewCommentsState,
          newlyAddedComments: [...prevReviewCommentsState.newlyAddedComments, action.payload.actionResult.reviewComment],
        };
      } else {
        // Add the comment to the main list
        newReviewCommentsState = {
          ...appendReviewCommentsToState(prevReviewCommentsState, [action.payload.actionResult.reviewComment]),
        };
      }
      // Inject the new comments state and append the total comments count
      newState.appReviewsData.reviewComments = {
        ...newState.appReviewsData.reviewComments,
        [action.payload.reviewId]: { ...newReviewCommentsState, totalComments: prevReviewCommentsState.totalComments + 1 },
      };
      const reviewIdx = newState.appReviewsData.reviews.findIndex((r) => r.id === action.payload.reviewId);
      if (reviewIdx > -1) {
        newState.appReviewsData.reviews[`${reviewIdx}`].comments_count += 1;
      }
    }
  } else if (isType(action, EntityMarkedAsHelpfulReviewUpdateListAction)) {
    const { reviewIds } = action.payload;
    newState.appReviewsData.markedAsHelpful.userReviewIdsMarkedAsHelpful = reviewIds;
  } else if (isType(action, EntityLinkedInProductGroupUpdateAction)) {
    const { linkedInProductGroup } = action.payload;
    newState.linkedInProductGroup = linkedInProductGroup;
  } else if (isType(action, EntityReviewSetIsHelpfulAction)) {
    const { reviewId, isHelpful } = action.payload;
    const { markedAsHelpful } = newState.appReviewsData;
    const { userReviewIdsMarkedAsHelpful: prevReviewIds } = markedAsHelpful;
    if (isHelpful) {
      markedAsHelpful.userReviewIdsMarkedAsHelpful = [...prevReviewIds, reviewId];
    } else {
      markedAsHelpful.userReviewIdsMarkedAsHelpful = prevReviewIds.filter((r) => r !== reviewId);
    }
  } else if (isType(action, EntityReviewSetIsHelpfulLoadingAction)) {
    const { isLoading, reviewId } = action.payload;
    const { markedAsHelpful: prevMarkedAsHelpfulState } = newState.appReviewsData;
    const { userReviewIdsLoading: prevLoadingIds } = prevMarkedAsHelpfulState;
    if (isLoading) {
      prevMarkedAsHelpfulState.userReviewIdsLoading = { ...prevLoadingIds, [reviewId]: true };
    } else {
      const { [reviewId]: removedListing, ...newUserReviewIdsLoading } = prevMarkedAsHelpfulState.userReviewIdsLoading;
      prevMarkedAsHelpfulState.userReviewIdsLoading = newUserReviewIdsLoading;
    }
  } else if (isType(action, EntityReviewSetMarkAsHelpfulCount)) {
    const { reviewId, count } = action.payload;
    const { reviews } = newState.appReviewsData;
    const reviewIdx = reviews.findIndex((r) => r.id === reviewId);
    if (reviewIdx > -1) {
      reviews[`${reviewIdx}`].mark_as_helpful_count = count;
    }
  } else if (isType(action, LoadingRecommenededSizes)) {
    const { entityId, planId } = action.payload;
    newState.recommendedSizesState = {
      entityId,
      planId,
      loading: true,
      recommendedSizes: [],
    };
  } else if (isType(action, SetRecommenededSizes)) {
    const { entityId, planId, recommendedSizes } = action.payload;
    newState.recommendedSizesState = {
      entityId,
      planId,
      loading: false,
      recommendedSizes,
    };
  } else if (isType(action, EntityUserCommentedReviewIdsUpdateListAction)) {
    const { commentReviewIds } = action.payload;
    newState.appReviewsData.userCommentedReviewIds = commentReviewIds;
  } else {
    return newCommonState;
  }
  return newState;
}
