import * as React from 'react';
import { ILocContext, ILocParamsContext, IBuildHrefContext } from '@shared/interfaces/context';
import {
  IPrice,
  ILocalPrice,
  PricingStates,
  IStartingPrices,
  IAppDataItem,
  OdataRawObjectAppType,
  ISimpleSKU,
  IBillingCountry,
  TermsStrings,
  IStartingPrice,
  IBillingPlan,
  IFuturePriceFormat,
  IPricingElement,
} from '@shared/Models';
import { AzureEnvironmentSettings, AzureEnvironment } from '@shared/AzureEnvironmentSettings';
import { Constants, OfferType, allBillingCountries, PlanPricingType } from '@shared/utils/constants';
import FuturePriceTooltip from '@shared/components/futurePriceTooltip';
import { CustomMeterPricingMaxFractionDigits } from '@shared/components/customMeterPricing';
import { ButtonLink } from '@shared/components/ButtonLink';
import {
  isD365App,
  isD365BCApp,
  isPowerBIVisuals,
  isBagTransactable,
  isTransactApp,
  getPDPRoute,
  getProductByUrlKey,
} from '@shared/utils/appUtils';
import { getPrimaryProductUrl } from '@shared/utils/datamappingHelpers';

const fallbackCountryCode = 'US';

interface StartingFromPriceOptions {
  maximumFractionDigits?: number;
  formatStringLocalizationKey?: string;
  ignoreFreeString?: boolean;
}

export function loadAppPricing(rawApp: IAppDataItem, pricesData: IStartingPrices) {
  if (!rawApp || rawApp.appType === OdataRawObjectAppType.private) {
    return rawApp;
  }

  const app = { ...rawApp };

  const pricingData = pricesData[app.entityId];

  if (pricingData) {
    app.startingPrice = pricingData;
    if (!app.startingPrice.pricingData) {
      app.startingPrice.pricingData = PricingStates.NoPricingData;
    }

    if (pricingData.pricingBitmask) {
      Object.keys(pricingData.pricingBitmask).forEach((key) => {
        if (!app[`${key}`]) {
          app[`${key}`] = 0;
        }
        app[`${key}`] |= pricingData.pricingBitmask[`${key}`];
      });
    }
  } else {
    app.startingPrice = {
      pricingData: PricingStates.NoPricingData,
    };
  }

  return app;
}

const mooncakeBillingCountries: IBillingCountry[] = [
  {
    countryCode: 'cn',
    currency: 'CNY',
    name: 'China',
  },
];

export const billingCountries =
  AzureEnvironmentSettings.environment === AzureEnvironment.Mooncake ? mooncakeBillingCountries : allBillingCountries;

export function getBillingCountryByCountryCode(countryCode: string): IBillingCountry {
  return billingCountries.filter((item) => item.countryCode.toLowerCase() === countryCode.toLowerCase())[0];
}

export function renderNotAvailablePriceUI(context: ILocContext) {
  return (
    <div className="noAvailablePrice">
      <h6 className="c-heading-6">{context.loc('Pricing_SoftwarePlanDetail_Title')}</h6>
      <p>{context.loc('Pricing_SoftwarePlanDetail_NotAvailableDescription')}</p>
    </div>
  );
}

export function getContactPublisherText(context: ILocContext & ILocParamsContext) {
  return `<span class='contactPublisherText'>${context.loc('Pricing_ContactPublisher')}</span>`;
}

const DAYS_IN_MONTH = 30;
const DAYS_IN_YEAR = 365;
export const TermsStringsToDays = (term: string | TermsStrings): number => {
  switch (term) {
    case TermsStrings.Term1MString:
    case TermsStrings.MonthString:
      return DAYS_IN_MONTH;
    case TermsStrings.Term1YString:
    case TermsStrings.YearString:
      return DAYS_IN_YEAR;
    case TermsStrings.Term2YString:
      return DAYS_IN_YEAR * 2; // 365*2 years
    case TermsStrings.Term3YString:
      return DAYS_IN_YEAR * 3; // 365*3 years
    default:
      return DAYS_IN_MONTH;
  }
};

function getFreeTrialText({
  context,
  asInlineHTML,
  hasFreeTrial,
  freeTrialTerm,
  freeDurationDays,
}: {
  context: ILocContext & ILocParamsContext;
  asInlineHTML: boolean;
  hasFreeTrial: boolean;
  freeTrialTerm: TermsStrings | string;
  freeDurationDays?: number;
}) {
  if (!hasFreeTrial) {
    return '';
  }

  const months = freeDurationDays ? freeDurationDays / DAYS_IN_MONTH : TermsStringsToDays(freeTrialTerm) / DAYS_IN_MONTH;
  const singleMonthText = asInlineHTML
    ? context.locParams('FreeTrialFirstMonthFree', ['<span>', '</span>'])
    : context.loc('First_Month_Free_Trial', `First month free, then `);
  const multiMonthText = asInlineHTML
    ? context.locParams('FreeTrialFirstSomeMonthsFree', ['<span>', months.toString(), '</span>'])
    : context.locParams('First_Months_Free_Trial', [months.toString()], `First ${months.toString()} months free, then`);

  return months === 1 ? singleMonthText : multiMonthText;
}

function getCustomMeterPricing(
  context: ILocContext & ILocParamsContext,
  countryCode: string,
  currency: string,
  sku: ISimpleSKU,
  price: IPrice
) {
  if (!price.customMeter?.length) {
    return '';
  }

  let customMeterPriceText = '';

  const customMeters = price.customMeter;
  let firstLine = true;
  for (let i = 0; i < customMeters.length; i++) {
    if (customMeters[`${i}`].includedQuantity === '0') {
      continue;
    }
    if (firstLine) {
      customMeterPriceText += context.locParams('PricingInclude', [
        "<span id='includedPrice'>",
        ':</span><span>',
        customMeters[`${i}`].title + ': ' + customMeters[`${i}`].includedQuantity,
        '</span>',
      ]);
      firstLine = false;
    } else {
      customMeterPriceText += '<span>' + customMeters[`${i}`].title + ': ' + customMeters[`${i}`].includedQuantity + '</span>';
    }
  }

  for (let i = 0; i < customMeters.length; i++) {
    const priceString = customMeters[`${i}`].rate.toLocaleString(countryCode, {
      style: 'currency',
      currency: sku.currencyCode || currency,
      maximumFractionDigits: CustomMeterPricingMaxFractionDigits,
    });
    if (i === 0) {
      customMeterPriceText += context.locParams('PricingPlus', [
        "<span id='plusPrice'>",
        ':</span><span>',
        customMeters[`${i}`].title + ': ' + priceString,
        customMeters[`${i}`].unit.toLowerCase(),
        '</span>',
      ]);
    } else {
      customMeterPriceText +=
        '<span>' + customMeters[`${i}`].title + ': ' + priceString + ' ' + customMeters[`${i}`].unit.toLowerCase() + '</span>';
    }
  }

  return customMeterPriceText;
}

function getLocaleLanguage({ locale }: { locale?: string }) {
  const localeLanguage = locale?.includes('-') ? locale.split('-')[0] : Constants.DefaultLocale.split('-')[0];
  return localeLanguage;
}

function getCountryCodeRegion({ countryCode }: { countryCode?: string }) {
  const countryCodeRegion = countryCode
    ? countryCode.includes('-')
      ? countryCode.split('-')[1]
      : countryCode
    : fallbackCountryCode;
  return countryCodeRegion;
}

export function getPricingLocale({ locale, countryCode }: { locale?: string; countryCode?: string }) {
  const localeLanguage = getLocaleLanguage({ locale });
  const countryCodeRegion = getCountryCodeRegion({ countryCode });
  const localeString = `${localeLanguage.toLowerCase()}-${countryCodeRegion.toUpperCase()}`;
  return localeString;
}

export function getPriceWithCurrencySymbol(
  price: number,
  countryCode: string,
  currency: string,
  maximumFractionDigits = 5,
  locale: string
): string {
  const localeString = getPricingLocale({ locale, countryCode });
  let priceString = price.toLocaleString(localeString, {
    style: 'currency',
    currency,
    maximumFractionDigits,
  });

  // If the currency code is not converted to a symbol, then we want to display a space between the text and number.
  // For ex, PHP11.166 should be PHP 11.166. toLocaleString(..) does not support formatting. So we shd do this manually.
  // We first find out if the currency is present in the resulting priceString. This makes sure we aren't inserting space in a symbol.
  // We find out the position to insert a space (currencyValuePosition). We make sure that we are inserting
  // a space in between the price string - we don't want to insert a space at the end. Also, we don't want to insert a space if we already have one.
  const currencyCodePosition = priceString.indexOf(currency);
  const currencyValuePosition = currencyCodePosition + currency.length;
  const matchWhitespace = /\s/;
  if (
    currencyCodePosition >= 0 &&
    currencyValuePosition < priceString.length &&
    !matchWhitespace.test(priceString[`${currencyValuePosition}`])
  ) {
    priceString =
      priceString.slice(0, currencyValuePosition) + ' ' + priceString.slice(currencyValuePosition, priceString.length);
  }

  return priceString;
}

export function getPriceString({
  context,
  price,
  countryCode,
  currency,
  maximumFractionDigits = 5,
  formatStringLocalizationKey = '{0}/{1}',
  isQuantifiable = false,
  ignoreFreeString = false,
  isOneTimePaymentOffer = false,
  locale,
  offerType,
}: {
  context: ILocContext & ILocParamsContext;
  price: IPrice | ILocalPrice | PricingStates;
  countryCode: string;
  currency: string;
  maximumFractionDigits?: number;
  formatStringLocalizationKey?: string;
  isQuantifiable?: boolean;
  ignoreFreeString?: boolean;
  isOneTimePaymentOffer?: boolean;
  locale?: string;
  offerType?: OfferType;
}): string {
  if (!price || typeof price !== 'object') {
    return '';
  }

  let text = '';
  if (price.value === 0 && !ignoreFreeString) {
    text = context.loc('Pricing_Free');
  } else {
    let unitLocalizationKey = '';
    if (price.unit.toLowerCase() === 'hr' || price.unit.toLowerCase() === 'hour' || price.unit.toLowerCase() === 'hours') {
      unitLocalizationKey = 'Pricing_Hour';
    } else if (price.unit.toLowerCase() === 'month') {
      unitLocalizationKey = 'Pricing_Month';
    } else if (price.unit.toLowerCase() === 'year') {
      unitLocalizationKey = 'Pricing_Year';
    } else if (price.unit.toLowerCase() === 'perpetual') {
      unitLocalizationKey = '';
    } else {
      unitLocalizationKey = price.unit;
    }

    if (isQuantifiable && unitLocalizationKey && offerType !== OfferType.VirtualMachine) {
      unitLocalizationKey = `User_${unitLocalizationKey}`;
    }

    if (countryCode && countryCode.toLowerCase() === 'dk') {
      countryCode = 'CA';
    }

    const priceString = getPriceWithCurrencySymbol(price.value, countryCode, currency, maximumFractionDigits, locale);
    if (isOneTimePaymentOffer) {
      return priceString;
    }
    if (unitLocalizationKey) {
      text = context.locParams(formatStringLocalizationKey, [priceString, context.loc(unitLocalizationKey)]);
    } else {
      text = priceString;
    }
  }

  return text;
}

export function getVariablePriceString(
  sku: ISimpleSKU,
  countryCode: string,
  currency: string,
  context: ILocContext & ILocParamsContext,
  isOneTimePaymentOffer?: boolean,
  locale?: string
): {
  monthlyPrice?: string;
  yearlyPrice?: string;
  price?: string;
} {
  const returnObj = {
    monthlyPrice: '',
    yearlyPrice: '',
    price: '',
  };

  if (sku.priceVaries) {
    if (sku.offerType === OfferType.SolutionTemplate) {
      returnObj.price = context.loc('CostOfDeployedTemplateComponents');
      return returnObj;
    } else {
      returnObj.price = context.loc('PriceVaries');
      return returnObj;
    }
  }

  if (sku.termPrices) {
    sku.termPrices.forEach((termPrice) => {
      let termPriceString = getPriceString({
        context,
        price: termPrice,
        countryCode,
        currency: sku.currencyCode || currency,
        locale,
        isQuantifiable: sku.isQuantifiable,
        ignoreFreeString: true,
        isOneTimePaymentOffer,
      });

      if (sku.azureInfraCostsExtra) {
        if (sku.offerType !== OfferType.ManagedApp) {
          returnObj.price = context.locParams('PriceWithAzureInfraCostsExtra', [termPriceString]);
          return returnObj;
        }

        // Managed Apps support custom meters, we should show Azure Infra Costs along with custom meters
        termPriceString = context.locParams('PriceWithAzureInfraCostsExtra', [termPriceString]);
      }

      termPriceString = '<span>' + termPriceString + '</span>';
      if (termPrice.customMeter && termPrice.customMeter.length > 0) {
        const customMeterText = getCustomMeterPricing(context, countryCode, currency, sku, termPrice);
        termPriceString += customMeterText;
      }

      const freeTrialText = getFreeTrialText({
        context,
        asInlineHTML: true,
        hasFreeTrial: termPrice.freeDurationDays > 0,
        freeTrialTerm: termPrice.unit,
        freeDurationDays: termPrice.freeDurationDays,
      });
      termPriceString = freeTrialText + termPriceString;

      if (termPrice.unit.toLowerCase() === Constants.TermPeriod.Month) {
        returnObj.monthlyPrice = termPriceString;
      } else if (termPrice.unit.toLowerCase() === Constants.TermPeriod.Year) {
        returnObj.yearlyPrice = termPriceString;
      }
    });
    return returnObj;
  }

  const price = getPriceString({
    context,
    price: sku.startingPrice,
    countryCode,
    currency: sku.currencyCode || currency,
    locale,
    isQuantifiable: sku.isQuantifiable,
    ignoreFreeString: true,
    isOneTimePaymentOffer,
  });

  if (sku.azureInfraCostsExtra) {
    returnObj.price = context.locParams('PriceWithAzureInfraCostsExtra', [price]);
    return returnObj;
  }

  returnObj.price = price;
  return returnObj;
}

function getDefaultPrice({ loc }: ILocContext): string {
  return `<span class='contactPublisherText'>${loc('PriceVaries')}</span>`;
}

export function getFuturePriceVariablePriceString({
  countryCode,
  currency,
  context,
  futureSku,
  isOneTimePaymentOffer,
  locale,
}: {
  countryCode: string;
  currency: string;
  context: ILocContext & ILocParamsContext;
  futureSku?: ISimpleSKU;
  isOneTimePaymentOffer?: boolean;
  locale?: string;
}) {
  const futurePriceObj =
    futureSku && getVariablePriceString(futureSku, countryCode, currency, context, isOneTimePaymentOffer, locale);
  const futureMonthlyPrice = futurePriceObj?.monthlyPrice;
  const futureYearlyPrice = futurePriceObj?.yearlyPrice;
  return {
    futurePriceMonthly: futureMonthlyPrice ? { price: futureMonthlyPrice, startDate: futureSku?.startDate } : null,
    futurePriceYearly: futureYearlyPrice ? { price: futureYearlyPrice, startDate: futureSku?.startDate } : null,
  };
}

export function getVariablePriceStringWithDefault({
  sku,
  showPriceColumn,
  showYearlyPrice,
  showMonthlyPrice,
  isMicrosoftManaged,
  countryCode,
  currency,
  context,
  futureSku,
  shouldBlockPrices,
  isOneTimePaymentOffer,
  locale,
}: {
  sku: ISimpleSKU;
  showPriceColumn: boolean;
  showYearlyPrice: boolean;
  showMonthlyPrice: boolean;
  isMicrosoftManaged: boolean;
  countryCode: string;
  currency: string;
  context: ILocContext & ILocParamsContext;
  futureSku?: ISimpleSKU;
  shouldBlockPrices?: boolean;
  isOneTimePaymentOffer?: boolean;
  locale?: string;
}) {
  const priceObj =
    showPriceColumn && sku ? getVariablePriceString(sku, countryCode, currency, context, isOneTimePaymentOffer, locale) : {};

  const monthlyPrice = priceObj?.monthlyPrice;
  const yearlyPrice = priceObj?.yearlyPrice;

  let defaultMonthlyPrice = getDefaultPrice(context);
  let defaultYearlyPrice = getDefaultPrice(context);
  const contactPublisherText = getContactPublisherText(context);

  if (isMicrosoftManaged && !monthlyPrice && !yearlyPrice) {
    if (!showMonthlyPrice && showYearlyPrice) {
      defaultYearlyPrice = contactPublisherText;
    } else {
      defaultMonthlyPrice = contactPublisherText;
    }
  }

  // Block prices for non-transactable offer types
  if (shouldBlockPrices) {
    return {
      monthly: monthlyPrice ? contactPublisherText : defaultMonthlyPrice,
      yearly: yearlyPrice ? contactPublisherText : defaultYearlyPrice,
      price: '',
      futurePriceMonthly: null,
      futurePriceYearly: null,
    };
  }

  const { futurePriceMonthly, futurePriceYearly } = getFuturePriceVariablePriceString({
    countryCode,
    currency,
    context,
    futureSku,
    isOneTimePaymentOffer,
    locale,
  });
  return {
    monthly: monthlyPrice || (showMonthlyPrice ? defaultMonthlyPrice : ''),
    yearly: yearlyPrice || (showYearlyPrice ? defaultYearlyPrice : ''),
    price: priceObj && priceObj.price ? priceObj.price : '',
    futurePriceMonthly,
    futurePriceYearly,
  };
}

export function getBillingFrequencyTranslated(
  context: ILocContext & ILocParamsContext,
  term?: TermsStrings,
  onetimePaymentTranslation = 'Pricing_Onetime_Payment'
) {
  if (!term) {
    return context.loc(onetimePaymentTranslation, 'one-time payment');
  }

  switch (term) {
    case TermsStrings.Term1MString:
    case TermsStrings.MonthString:
      return context.loc('Pricing_Month', 'month');
    case TermsStrings.Term1YString:
    case TermsStrings.YearString:
      return context.loc('Pricing_Year', 'year');
    default:
      return '';
  }
}

export function getBillingTermTranslated({
  context,
  term,
  useHyphen = true,
  isSubscription,
}: {
  context: ILocContext & ILocParamsContext;
  term: string;
  useHyphen?: boolean;
  isSubscription: boolean;
}) {
  switch (term) {
    case TermsStrings.Term1MString:
    case TermsStrings.MonthString:
      return !useHyphen
        ? context.loc('Pricing_Term_1_Month', '1 month')
        : context.locParams(
            'Pricing_Term_termUnit_termDuration',
            [
              '1',
              `${
                isSubscription
                  ? context.loc('Pricing_Month_Subscription', 'month subscription')
                  : context.loc('Pricing_Month', 'month')
              }`,
            ],
            '1-month'
          );
    case TermsStrings.Term1YString:
    case TermsStrings.YearString:
      return !useHyphen
        ? context.loc('Pricing_Term_1_Year', '1 year')
        : context.locParams(
            'Pricing_Term_termUnit_termDuration',
            [
              '1',
              `${
                isSubscription
                  ? context.loc('Pricing_Year_Subscription', 'year subscription')
                  : context.loc('Pricing_Year', 'year')
              }`,
            ],
            '1-year'
          );
    case TermsStrings.Term2YString:
      return !useHyphen
        ? context.locParams('Pricing_Term_Years', ['2'], '2 years')
        : context.locParams(
            'Pricing_Term_termUnit_termDuration',
            [
              '2',
              `${
                isSubscription
                  ? context.loc('Pricing_Year_Subscription', 'year subscription')
                  : context.loc('Pricing_Year', 'year')
              }`,
            ],
            '2-year'
          );
    case TermsStrings.Term3YString:
      return !useHyphen
        ? context.locParams('Pricing_Term_Years', ['3'], '3 years')
        : context.locParams(
            'Pricing_Term_termUnit_termDuration',
            [
              '3',
              `${
                isSubscription
                  ? context.loc('Pricing_Year_Subscription', 'year subscription')
                  : context.loc('Pricing_Year', 'year')
              }`,
            ],
            '3-year'
          );
    default:
      return '';
  }
}

export function getPriceWithFrequencyText({
  context,
  priceObject,
  isQuantifiable,
  countryCode,
  currency,
  maximumFractionDigits = 5,
  onetimePaymentTranslation = 'Pricing_Onetime_Payment',
  locale,
}: {
  context: ILocContext & ILocParamsContext;
  priceObject: IPrice;
  isQuantifiable: boolean;
  countryCode: string;
  currency: string;
  maximumFractionDigits?: number;
  onetimePaymentTranslation?: string;
  locale?: string;
}) {
  const { unit, billingPlan, value } = priceObject;
  const priceValue = billingPlan?.price ? billingPlan.price.listPrice : value;
  const priceText = getPriceWithCurrencySymbol(priceValue, countryCode, currency, maximumFractionDigits, locale);
  const isOnetimePayment = !billingPlan;
  const template = isQuantifiable ? 'Pricing_Per_User_Template' : 'Pricing_Flat_Template';

  if (unit === TermsStrings.Term1MString || unit === TermsStrings.MonthString) {
    const billingFrequency = getBillingTermTranslated({ context, term: unit, useHyphen: true, isSubscription: false });
    return context.locParams(template, [priceText, billingFrequency]);
  } else if (unit === TermsStrings.Term1YString || unit === TermsStrings.YearString) {
    const billingFrequency = isOnetimePayment
      ? getBillingTermTranslated({ context, term: unit, useHyphen: true, isSubscription: false })
      : context.loc('Pricing_Month', 'month');

    return context.locParams(template, [priceText, billingFrequency]);
  } else {
    const billingFrequency = getBillingFrequencyTranslated(context, billingPlan?.billingPeriod, onetimePaymentTranslation);
    return context.locParams(template, [priceText, billingFrequency]);
  }
}

export function getDetailedPriceText(
  context: ILocContext & ILocParamsContext,
  countryCode: string,
  currency: string,
  sku: ISimpleSKU,
  termPrice: IPrice,
  locale: string
) {
  const priceWithFrequency = getPriceWithFrequencyText({
    context,
    priceObject: termPrice,
    isQuantifiable: sku.isQuantifiable,
    countryCode,
    currency,
    locale,
  });

  let priceText = `<span>${priceWithFrequency}</span>`;
  if (termPrice.customMeter?.length) {
    priceText += getCustomMeterPricing(context, countryCode, currency, sku, termPrice);
  }
  const freeTrialText = getFreeTrialText({
    context,
    asInlineHTML: true,
    hasFreeTrial: termPrice.freeDurationDays > 0,
    freeTrialTerm: termPrice.unit,
    freeDurationDays: termPrice.freeDurationDays,
  });

  return `${freeTrialText}${priceText}`;
}

export function getFullPriceString({
  context,
  countryCode,
  currency,
  priceValue,
  billingPlan,
  termDuration,
  isQuantifiable,
  hasFreeTrial,
  locale,
}: {
  context: ILocContext & ILocParamsContext;
  countryCode: string;
  currency: string;
  priceValue: number;
  billingPlan: IBillingPlan;
  termDuration: TermsStrings;
  isQuantifiable: boolean;
  hasFreeTrial: boolean;
  locale?: string;
}) {
  const priceWithFrequency = getPriceWithFrequencyText({
    context,
    priceObject: { unit: termDuration, billingPlan, value: priceValue },
    isQuantifiable,
    countryCode,
    currency,
    locale,
    maximumFractionDigits: 5,
  });

  // Always sent in 1M free trial as
  const freeTrialText = getFreeTrialText({
    context,
    asInlineHTML: false,
    hasFreeTrial,
    freeTrialTerm: TermsStrings.Term1MString,
  });

  return `${freeTrialText}${priceWithFrequency}`;
}

/** Returns ascending order, i.e: 0 is first, then 1, then 2 and so on */
export function getTermPriorityForSorting(termsStrings?: string | TermsStrings): number {
  const lastPriority = 1000;
  switch (termsStrings) {
    case TermsStrings.Term1MString:
    case TermsStrings.MonthString:
      return 0;
    case TermsStrings.Term1YString:
    case TermsStrings.YearString:
      return 1;
    case TermsStrings.Term2YString:
      return 2;
    case TermsStrings.Term3YString:
      return 3;
    default:
      return lastPriority;
  }
}

/** Returns a new array of ascendingly sorted term prices. First by billing term and if the billing terms are equal then by the billing period frequency */
export function sortTermPrices(termPrices: IPrice[]): IPrice[] {
  if (!termPrices?.length) {
    return [];
  }

  // Cloning before calling ".sort" is a must because ".sort" sorts the array in-place.
  return [...termPrices].sort((a, b) => {
    if (a.unit === b.unit) {
      return getTermPriorityForSorting(a.billingPlan?.billingPeriod) - getTermPriorityForSorting(b.billingPlan?.billingPeriod);
    }

    return getTermPriorityForSorting(a.unit) - getTermPriorityForSorting(b.unit);
  });
}

export const addFuturePricing = (
  billingTermPeriod: TermsStrings,
  monthlyFuturePrice: IFuturePriceFormat,
  yearlyFuturePrice: IFuturePriceFormat
) => {
  if ((billingTermPeriod === TermsStrings.Term1MString || billingTermPeriod === TermsStrings.MonthString) && monthlyFuturePrice) {
    return <FuturePriceTooltip futurePrice={{ ...monthlyFuturePrice, className: 'checkoutCell ms-fontSize-12' }} />;
  } else if (
    (billingTermPeriod === TermsStrings.Term1YString || billingTermPeriod === TermsStrings.YearString) &&
    yearlyFuturePrice
  ) {
    return <FuturePriceTooltip futurePrice={{ ...yearlyFuturePrice, className: 'checkoutCell ms-fontSize-12' }} />;
  }

  return null;
};

function saasStartsFromPricing({
  startingPrice,
  context,
  countryCode,
  maximumFractionDigits = 5,
  locale,
  isOneTimePaymentOffer,
}: {
  startingPrice: IStartingPrice;
  context: ILocContext & ILocParamsContext;
  countryCode: string;
  maximumFractionDigits: number;
  locale?: string;
  isOneTimePaymentOffer?: boolean;
}): string {
  const { pricingData, isQuantifiable } = startingPrice;

  if (!pricingData || typeof pricingData !== 'object') {
    return '';
  }

  if (pricingData.value === 0) {
    return context.loc('Pricing_Free');
  }
  const { unit } = pricingData;
  const priceText = getPriceWithFrequencyText({
    context,
    priceObject: pricingData,
    isQuantifiable,
    countryCode,
    currency: pricingData.currency,
    maximumFractionDigits,
    locale,
  });
  if (isOneTimePaymentOffer) {
    return priceText;
  }
  const termTranslated = getBillingTermTranslated({ context, term: unit, useHyphen: false });
  return context.locParams('Pricing_Multiyear_Starts_From_Template', [priceText, termTranslated]);
}

export function getStartingFromPriceText({
  startingPrice,
  offerType,
  context,
  countryCode,
  startingPriceOptions = {},
  isOneTimePaymentOffer,
  locale,
}: {
  startingPrice: IStartingPrice;
  offerType: OfferType;
  context: ILocContext & ILocParamsContext;
  countryCode: string;
  startingPriceOptions?: StartingFromPriceOptions;
  isOneTimePaymentOffer?: boolean;
  locale?: string;
}): string {
  const { pricingData } = startingPrice;
  const { maximumFractionDigits, formatStringLocalizationKey, ignoreFreeString } = startingPriceOptions;
  if (!pricingData || typeof pricingData !== 'object') {
    return '';
  }

  if (offerType === OfferType.SaaSApp) {
    return saasStartsFromPricing({
      startingPrice,
      context,
      countryCode,
      maximumFractionDigits,
      locale,
      isOneTimePaymentOffer,
    });
  } else {
    return getPriceString({
      context,
      price: startingPrice.pricingData,
      countryCode,
      currency: pricingData.currency,
      locale,
      isQuantifiable: startingPrice.isQuantifiable,
      ignoreFreeString,
      isOneTimePaymentOffer,
      formatStringLocalizationKey,
      maximumFractionDigits,
      offerType,
    });
  }
}

const canRenderPricing = (app: IAppDataItem, billingCountryCode: string) => {
  const { ctaTypes, licenseManagement, planPricingType } = app;

  // Render plans and pricing only for Transactable Apps and MicrosoftManaged offers
  return (
    planPricingType === PlanPricingType.SimplePlanPricing &&
    (isTransactApp({ isEmbedded: false, ctaTypes, appData: app, billingRegion: billingCountryCode }) ||
      licenseManagement?.isMicrosoftManaged)
  );
};

const getTabHref = (tabTitle: string, app: IAppDataItem, context: ILocContext & ILocParamsContext & IBuildHrefContext) => {
  const productString = getPrimaryProductUrl(app.primaryProduct);
  const productData = getProductByUrlKey({ urlKey: productString });
  const product = productData ? productData.UrlKey : productString;

  return context.buildHref(getPDPRoute(app.appType), { productId: product, entityId: app.entityId }, { tab: tabTitle });
};

export const getStartingPriceElement = ({
  app,
  billingCountryCode,
  startingPrice,
  context,
  locale,
}: {
  app: IAppDataItem;
  billingCountryCode: string;
  startingPrice: IStartingPrice;
  context: ILocContext & ILocParamsContext & IBuildHrefContext;
  locale?: string;
}): IPricingElement => {
  if (
    startingPrice &&
    startingPrice.pricingData &&
    startingPrice.pricingData !== PricingStates.NoPricingData &&
    startingPrice.pricingData !== PricingStates.NotAvailableInThisRegion &&
    startingPrice.pricingData !== PricingStates.AlwaysAvailable &&
    startingPrice.pricingData !== PricingStates.Loading
  ) {
    const pricingElement: IPricingElement = { type: null, label: '', value: '', href: '' };
    if (startingPrice.pricingData === PricingStates.FreeApp) {
      pricingElement.type = Constants.ButtonType.Label;
      pricingElement.label = context.loc('Pricing');
      pricingElement.value = context.loc('Pricing_Free');
    } else if (startingPrice.pricingData === PricingStates.AdditionalPurchasesRequired) {
      pricingElement.type = Constants.ButtonType.Label;
      pricingElement.label = context.loc('Pricing');
      pricingElement.value = context.loc('AdditionalPurchaseMayBeRequired');
    } else if (startingPrice.pricingData === PricingStates.StartFromFree) {
      // Freemium offers with pricing plans
      pricingElement.type = Constants.ButtonType.Label;
      pricingElement.label = context.loc('Pricing_StartsAt');
      pricingElement.value = context.loc('Pricing_Free');
    } else if (typeof startingPrice.pricingData === 'object') {
      if (canRenderPricing(app, billingCountryCode)) {
        pricingElement.href = getTabHref('PlansAndPrice', app, context);
        pricingElement.type = Constants.ButtonType.Link;
        pricingElement.label = context.loc('StartingAt');
        pricingElement.value = getStartingFromPriceText({
          startingPrice,
          offerType: app.offerType,
          context,
          countryCode: billingCountryCode,
          locale,
        });
      } else {
        pricingElement.type = Constants.ButtonType.Label;
        pricingElement.label = context.loc('Pricing');
        pricingElement.value = getPriceString({
          context,
          price: startingPrice.pricingData,
          countryCode: billingCountryCode,
          currency: startingPrice.pricingData.currency,
          locale,
        });
      }
    }
    if (typeof startingPrice.pricingData === 'object' && !canRenderPricing(app, billingCountryCode)) {
      if (pricingElement.value) {
        pricingElement.value = pricingElement.value + ' ' + context.loc('SiteLicenseAlsoAvailable');
      } else {
        pricingElement.type = Constants.ButtonType.Label;
        pricingElement.label = context.loc('Pricing');
        pricingElement.value = context.loc('SiteLicenseAlsoAvailable');
      }
    }
    return pricingElement;
  }
  return null;
};

export const shouldBlockPricing = (app: IAppDataItem) => {
  // hasPrices check is necessary to ensure we don't block pricing for valid Dynamics 365 and PBI Visual offers
  return (
    (isD365App(getPrimaryProductUrl(app.primaryProduct)) ||
      isD365BCApp(getPrimaryProductUrl(app.primaryProduct)) ||
      isPowerBIVisuals(getPrimaryProductUrl(app.primaryProduct))) &&
    !isBagTransactable(app) &&
    app.hasPrices
  );
};

export const bulidStartingPriceElement = ({
  app,
  pricingLinkClassName,
  billingCountryCode,
  context,
  locale,
}: {
  app: IAppDataItem;
  pricingLinkClassName: 'light' | 'dark';
  billingCountryCode: string;
  context: ILocContext & ILocParamsContext & IBuildHrefContext;
  locale?: string;
}) => {
  const startingPrice = shouldBlockPricing(app) ? null : app.startingPrice;
  const pricingElement = getStartingPriceElement({
    app,
    billingCountryCode,
    startingPrice,
    context,
    locale,
  });
  if (pricingElement) {
    return (
      <div className="cell" itemProp="offers" itemScope itemType="https://schema.org/Offer">
        <div className="pricingText">
          {pricingElement && pricingElement.label && pricingElement.type && pricingElement.value ? (
            <ButtonLink
              href={pricingElement.href ? pricingElement.href : ''}
              className={pricingLinkClassName}
              type={pricingElement.type}
              accEnabled={true}
              label={pricingElement.label}
              value={pricingElement.value}
            ></ButtonLink>
          ) : null}
          {startingPrice.pricingData === PricingStates.FreeApp ? (
            <div>
              <meta itemProp="price" content="0" />
              <meta itemProp="priceCurrency" content="USD" />
              <meta itemProp="category" content="free" />
            </div>
          ) : typeof startingPrice.pricingData === 'object' ? (
            <div>
              <meta itemProp="priceCurrency" content={startingPrice.pricingData.currency} />
              <meta
                itemProp="price"
                content={getPriceString({
                  context,
                  price: startingPrice.pricingData,
                  countryCode: billingCountryCode,
                  currency: startingPrice.pricingData.currency,
                  locale,
                }).replace(/[^0-9.]/g, '')}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
  return null;
};

export const hidePowerBiVisualPricing = ({ app, isEmbedded }: { app: IAppDataItem; isEmbedded: boolean }) => {
  return !isEmbedded && isPowerBIVisuals(getPrimaryProductUrl(app.primaryProduct)) && app.hasPrices;
};
