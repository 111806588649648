/* eslint-disable security/detect-non-literal-fs-filename */
import History, { createBrowserHistory } from 'history';
import React from 'react';
import { matchRoutes, useLocation, useNavigate, useParams } from 'react-router';
import type ReactRouter from 'react-router';
import Redux from 'react-redux';
import { Constants } from '@shared/utils/constants';
import { ITelemetryData } from '@shared/Models';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { getWindow, getDocument } from '@shared/services/window';
import { IFeatureFlags } from '../State';
import { campaignParams, getAppViewParams } from '@shared/utils/routeUtils';
import { saveDataToBrowserAfterUserAgreedPrivacyConsent } from '@shared/utils/browserStorageUtils';
import { getCanonicalLink } from '@shared/utils/seoUtils';
import { getHostFromBrowserUrl, getProtocolFromBrowserUrl } from './utils/urlUtils';
import { getAppConfig } from '@shared/services/init/appConfig';
import { useTelemetry } from '@shared/hooks/useTelemetry';
import { logger } from '@src/logger';
import { getXssDiffString } from '@shared/utils/xssUtils';

const EMBED_PREFIX = '/embed';

let isEmbedded = false;
let appRoutes: ReactRouter.RouteObject[] = [];
export let previousPage = '';

export let appHistory: History.History | null = null;

export const canonicalLink = (originalUrl: string) => {
  const document = getDocument();
  const canonical = document?.documentElement?.querySelector("link[rel='canonical']");
  if (canonical) {
    const appName = getAppConfig('appName');
    const isMac: boolean = appName === Constants.canonicalAMPEnvAppName;
    const host = getHostFromBrowserUrl();
    const protocol = getProtocolFromBrowserUrl();
    const canonicalLinkPrefix = `${protocol}//${host}`;
    const canonicalLink = getCanonicalLink(originalUrl, isMac);
    const documentCanonicalLink = `${canonicalLinkPrefix}${canonicalLink}`;
    canonical.setAttribute('href', documentCanonicalLink);
  }
};

function embedURL(url: string) {
  return url.startsWith(EMBED_PREFIX) ? url : `${EMBED_PREFIX}${url}`;
}

function isRouteChangingAction(action: string) {
  return ['PUSH', 'POP', 'REPLACE'].indexOf(action) > -1;
}

export function isValidRoute(location: string) {
  const base = isEmbedded ? EMBED_PREFIX : '/';
  return Boolean(matchRoutes(appRoutes, location, base));
}

function onRouteChanged(location: Location) {
  const originalUrl = location.pathname + location.search;
  const payload = {
    page: originalUrl,
    action: Constants.Telemetry.Action.RouteChanged,
    actionModifier: Constants.Telemetry.ActionModifier.Start,
    details: JSON.stringify({
      previousPage,
    }),
  };

  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
  logger.info(payload.details, {
    action: payload.action,
    actionModifier: payload.actionModifier,
    page: payload.page,
  });

  canonicalLink(originalUrl);
}

function refererByCallbackUrl(location: Location): string | null {
  const { pathname, search } = location;
  if (pathname.includes('/callback')) {
    const searchParams = new URLSearchParams(search);
    const state = searchParams.get('state');
    if (!state) {
      return null;
    }

    try {
      const _state: { from: string; hostName: string } = JSON.parse(state);
      const decodedFrom = decodeURIComponent(_state.from);
      const stateHostname = decodeURIComponent(_state.hostName);
      return decodedFrom?.startsWith(stateHostname) && !getXssDiffString({ url: decodedFrom }) ? decodedFrom : pathname;
    } catch {
      return null;
    }
  }

  return null;
}

export function initRouterHistory(args: { embedded?: boolean; appRoutes: ReactRouter.RouteObject[] }) {
  const embedded = args.embedded ?? false;
  appRoutes = args.appRoutes;

  isEmbedded = embedded;

  appHistory = createBrowserHistory({ window: getWindow() });

  let previousLocation = getWindow().location;

  // in case client receives a /callback path as a result of post-sso-login we want the client to render the referer url and not the callback url
  if (previousLocation.pathname.includes('/callback')) {
    const refererPathname = refererByCallbackUrl(previousLocation);
    if (refererPathname) {
      appHistory.push(refererPathname);
    }
  }

  appHistory.listen((update: History.Update) => {
    if (isRouteChangingAction(update.action)) {
      onRouteChanged(previousLocation);
    }
    previousLocation = getWindow().location;
  });
}

// These object defines valid queryParams for the different states in the app.  This allows us to
// 'smartly' drop and add query parameters when navigating between pages
const FilterQuery = Constants.FilterQuery;

export const routeParams = {
  appView: getAppViewParams().concat(Object.keys(new IFeatureFlags())),
  home: [FilterQuery.industry, FilterQuery.category, FilterQuery.product, 'search', 'filterTab', 'successStoryTab'],
  marketplace: [
    FilterQuery.industry,
    FilterQuery.category,
    FilterQuery.subcategories,
    FilterQuery.subindustries,
    FilterQuery.product,
    'search',
    'showPrivateApps',
    'page',
    'pageSize',
    'serviceType',
    'country',
    'region',
    'certification',
    'filters',
    FilterQuery.sortBy,
  ],
  partnersDir: ['filter', 'contact'],
  partnerSuggestion: ['text', 'type', 'value'],
  blog: [] as string[],
  appDetails: ['breadcrumbUrl', 'tab', 'survey'],
  checkout: [] as string[],
  orderConfirmation: [] as string[],
  myReviews: [] as string[],
  localePicker: [] as string[],
  partners: [] as string[],
  partnersForm: [] as string[],
  partnerDetail: ['breadcrumbUrl', 'tab'] as string[],
  sell: [] as string[],
  about: [] as string[],
  serviceDetail: ['breadcrumbUrl'] as string[],
  userFavouriteDetail: [] as string[],
  privateOffersDetail: [] as string[],
  sitemap: [] as string[],
};

export interface IRouteConfig<T> {
  name: string;
  getPath: (routeArgs?: T) => string;
  params: string[];
  initialParamsValue?: { [id: string]: string };
  featureFlag?: string;
}

export interface IAppDetailsParams {
  productId?: string;
  entityId: string;
}

export interface ICheckoutParams {
  entityId: string;
}

export interface IServiceDetailsParams {
  entityId: string;
}
export interface IPartnerDetailsParams {
  entityId: string;
  tabId: string;
}

export interface ICampaignsParams {
  publisher: string;
  id: string;
}

export interface IRoutes<T> {
  [name: string]: IRouteConfig<T>;
}

export const routes = {
  localePicker: {
    name: Constants.Views.locale,
    getPath: () => '/localepicker',
    params: routeParams.localePicker.concat(routeParams.appView),
  } as IRouteConfig<any>,
  home: {
    name: Constants.Views.home,
    getPath: () => '/home',
    params: routeParams.home.concat(routeParams.appView),
  } as IRouteConfig<any>,
  marketplace: {
    name: Constants.Views.appGallery,
    getPath: () => `/${Constants.appsGallery}`,
    params: routeParams.marketplace.concat(routeParams.appView),
    initialParamsValue: {
      page: '1',
      search: null,
      sortBy: null,
      [FilterQuery.filters]: null,
      [FilterQuery.category]: null,
      [FilterQuery.industry]: null,
      [FilterQuery.subcategories]: null,
      [FilterQuery.subindustries]: null,
      [FilterQuery.product]: null,
      serviceType: null,
    },
  } as IRouteConfig<any>,
  marketplaceCloudsIndustry: {
    name: Constants.Views.cloudsIndustryGallery,
    getPath: () => `/${Constants.cloudsIndustryGallery}`,
    params: routeParams.marketplace.concat(routeParams.appView),
    initialParamsValue: {
      page: '1',
      search: null,
      sortBy: null,
      [FilterQuery.filters]: null,
      [FilterQuery.category]: null,
      [FilterQuery.industry]: null,
      [FilterQuery.subcategories]: null,
      [FilterQuery.subindustries]: null,
      [FilterQuery.product]: null,
      serviceType: null,
    },
  } as IRouteConfig<any>,
  appDetails: {
    name: Constants.Views.appDetails,
    getPath: (routeArgs: IAppDetailsParams) => '/product/' + routeArgs.productId + '/' + routeArgs.entityId,
    params: routeParams.appDetails.concat(routeParams.appView),
  } as IRouteConfig<IAppDetailsParams>,
  checkout: {
    name: Constants.Views.checkout,
    getPath: (routeArgs: ICheckoutParams) => '/marketplace/checkout/' + routeArgs.entityId,
    params: routeParams.checkout.concat(routeParams.appView),
  } as IRouteConfig<ICheckoutParams>,
  orderConfirmation: {
    name: Constants.Views.orderConfirmation,
    getPath: () => '/marketplace/order-confirmation',
    params: routeParams.orderConfirmation.concat(routeParams.appView),
  } as IRouteConfig<any>,
  partners: {
    name: Constants.Views.partners,
    getPath: () => '/partners',
    params: routeParams.partners.concat(routeParams.appView),
  } as IRouteConfig<any>,
  blog: {
    name: Constants.Views.blog,
    getPath: () => '/blog',
    params: routeParams.blog.concat(routeParams.appView),
  } as IRouteConfig<any>,
  myReviews: {
    name: Constants.Views.reviews,
    getPath: () => '/user/my-reviews',
    params: routeParams.myReviews.concat(routeParams.appView),
  } as IRouteConfig<any>,
  marketing: {
    name: Constants.Views.sell,
    getPath: () => '/sell',
    params: routeParams.sell.concat(routeParams.appView),
  } as IRouteConfig<any>,
  about: {
    name: Constants.Views.about,
    getPath: () => '/about',
    params: routeParams.about.concat(routeParams.appView),
  } as IRouteConfig<any>,
  billingRegion: {
    name: Constants.Views.billingregion,
    getPath: () => '/billingregion',
    params: routeParams.about.concat(routeParams.appView),
  } as IRouteConfig<any>,
  fieldHub: {
    name: Constants.Views.fieldHub,
    getPath: () => '/account-hub',
    params: routeParams.about.concat(routeParams.appView),
  } as IRouteConfig<any>,
  marketplaceServices: {
    name: Constants.Views.serviceGallery,
    getPath: () => `/${Constants.csGallery}`,
    params: routeParams.marketplace.concat(routeParams.appView),
    featureFlag: 'ServiceOffers',
  } as IRouteConfig<any>,
  serviceDetail: {
    name: Constants.Views.serviceDetails,
    getPath: (routeArgs: IServiceDetailsParams) => `/${Constants.csGallery}/` + routeArgs.entityId,
    params: routeParams.serviceDetail.concat(routeParams.appView),
  } as IRouteConfig<any>,
  marketplacePartners: {
    name: Constants.Views.partnersHost,
    getPath: () => `/${Constants.partnersAppSource}`,
    params: routeParams.partnersDir.concat(routeParams.appView),
  } as IRouteConfig<any>,
  marketplacePartnerDetail: {
    name: Constants.Views.partnerDetails,
    getPath: (routeArgs: IPartnerDetailsParams) =>
      `/${Constants.partnersAppSource}/` + routeArgs.entityId + '/' + routeArgs.tabId,
    params: routeParams.partnerDetail.concat(routeParams.appView),
  } as IRouteConfig<any>,
  marketplacePartnerDetailContact: {
    name: Constants.Views.partnerDetailsContact,
    getPath: (routeArgs: IPartnerDetailsParams) =>
      `/${Constants.partnersAppSource}/` + routeArgs.entityId + '/' + routeArgs.tabId + '/contact',
    params: routeParams.partnerDetail.concat(routeParams.appView),
  } as IRouteConfig<any>,
  marketplacePartnerSuggestion: {
    name: Constants.Views.partnerSuggestion,
    getPath: () => `/${Constants.partnersAppSource}/suggestion`,
    params: routeParams.partnerSuggestion.concat(routeParams.appView),
  } as IRouteConfig<void>,
  userFavouriteDetail: {
    name: Constants.Views.userFavouriteDetail,
    getPath: () => '/user/my-favourites',
    params: routeParams.userFavouriteDetail.concat(routeParams.appView),
  } as IRouteConfig<void>,
  privateOffers: {
    name: Constants.Views.privateOffers,
    getPath: () => '/user/private-offers',
    params: routeParams.privateOffersDetail.concat(routeParams.appView),
  } as IRouteConfig<void>,
  privateOfferDetails: {
    name: Constants.Views.privateOfferDetails,
    getPath: (routeArgs: IAppDetailsParams) => `/${Constants.privateOffers}/product/${routeArgs.productId}/${routeArgs.entityId}`,
    params: routeParams.appDetails.concat(routeParams.appView),
  } as IRouteConfig<IAppDetailsParams>,
  campaigns: {
    name: Constants.Views.campaigns,
    getPath: (routeArgs: ICampaignsParams) => `campaigns/${routeArgs.publisher}/${routeArgs.id}`,
    params: routeParams.sitemap.concat(routeParams.appView),
  } as IRouteConfig<any>,
  sitemap: {
    name: Constants.Views.sitemap,
    getPath: () => '/sitemap',
    params: routeParams.sitemap.concat(routeParams.appView),
  } as IRouteConfig<any>,
  redirect: {
    name: 'redirect',
    getPath: () => '/redirect',
    params: routeParams.sitemap.concat(routeParams.appView),
  } as IRouteConfig<any>,
};

// TODO: Support L2 categories
export function buildHref<T>(
  route: IRouteConfig<T>,
  oldRouteParams: T,
  newRouteParams: T,
  oldParams: any,
  newParams: any,
  keepAllParams?: boolean,
  locale?: string,
  keepCampaignParams?: boolean
) {
  const validQueryParams = route.params;

  // merge old route params
  if (oldRouteParams) {
    const routeKeys = Object.keys(oldRouteParams);
    if (!newRouteParams) {
      newRouteParams = {} as T;
    }
    routeKeys.forEach(function (key) {
      if (oldRouteParams && newRouteParams[`${key}`] === undefined) {
        newRouteParams[`${key}`] = oldRouteParams[`${key}`];
      }
    });
  }

  let path = route.getPath(newRouteParams);

  if (locale) {
    path = '/' + locale + path;
  }

  const targetParams = {};

  for (const op in oldParams) {
    targetParams[`${op}`] = oldParams[`${op}`];
  }

  if (!keepCampaignParams) {
    campaignParams.forEach((campaignParam) => {
      if (targetParams[`${campaignParam}`]) {
        delete targetParams[`${campaignParam}`];
      }
    });
  }

  for (const np in newParams) {
    const v: string = newParams[`${np}`];
    if (!v) {
      if (targetParams[`${np}`]) {
        delete targetParams[`${np}`];
      }
      continue;
    }
    const startChar = v.charAt(0);

    // ';' leading a string means to append to exist params
    if (startChar === ';') {
      const newParam = v.substr(1);
      // No existing params for the current key
      if (!oldParams[`${np}`]) {
        targetParams[`${np}`] = newParam;
      } else {
        const splitParams = newParam.split(';');
        targetParams[`${np}`] = splitParams.reduce((acc, key) => {
          const keyLength = key.length;
          const keyIndex = targetParams[`${np}`].indexOf(key);

          // make sure it's not a subset of another item
          // e.g web-apps: starter-web-apps or web-apps-framework
          // the word web-apps is found in both however it is also an option on its own
          if (keyIndex > 0) {
            const previousCharacter = targetParams[`${np}`][keyIndex - 1];
            const endCharacter = targetParams[`${np}`][keyIndex + keyLength];

            // if it is encapsulated by ';'
            // or preceded by ';' and is the last word
            // it means we found the perfect match
            if (previousCharacter === ';' && (!endCharacter || endCharacter === ';')) {
              return acc;
            }
          }

          return acc + ';' + key;
        }, targetParams[`${np}`]);
        // should split and join without dupes
      }
    } else if (startChar === '!') {
      const newParam = v.substr(1);
      if (oldParams[`${np}`]) {
        // should split and join without dupes
        const splitNewParams = newParam.split(';');
        const splitOldParams = oldParams[`${np}`].split(';');
        // eslint-disable-next-line array-callback-return
        splitNewParams.map((key: string) => {
          const pos = splitOldParams.indexOf(key);
          if (pos >= 0) {
            splitOldParams.splice(pos, 1);
          }
        });

        targetParams[`${np}`] = splitOldParams.join(';');
        if (targetParams[`${np}`].length === 0) {
          delete targetParams[`${np}`];
        }
      }
    } else {
      targetParams[`${np}`] = v;
    }
  }

  // DIY createHref because browserHistory doesn't work
  // return browserHistory.createHref({ pathname: path, query: targetParams });
  let queryString = '';
  let emptyQuery = true;
  for (const tp in targetParams) {
    if (validQueryParams.indexOf(tp) >= 0 || validQueryParams.indexOf(tp && tp.toLowerCase()) >= 0 || keepAllParams) {
      queryString += (emptyQuery ? '?' : '&') + tp + '=' + encodeURIComponent(targetParams[`${tp}`]);
      emptyQuery = false;
    }
  }

  const relativeUrl = `${path}${queryString}`;
  return relativeUrl;
}

export function appendQueryParams(path: string, queryParams: Record<string, unknown>) {
  if (!path) {
    path = '';
  }
  if (queryParams) {
    const keys = Object.keys(queryParams);
    if (keys.length > 0) {
      path += path.indexOf('?') > 0 ? '&' : '?';
      path += keys.map((k) => k + '=' + queryParams[`${k}`]).join('&');
    }
  }

  return path;
}

// This function returns a full relative path
// for '/embed' paths in the embedded app, it will remove the '/embed' part
export const getCurrentRelativeUrl = () => {
  const currWindow = getWindow();

  let breadcrumbUrl = currWindow.location.pathname;
  if (currWindow.location.search) {
    breadcrumbUrl += currWindow.location.search;
  }
  if (breadcrumbUrl.indexOf(EMBED_PREFIX) === 0) {
    breadcrumbUrl = breadcrumbUrl.substr(6);
  }
  return breadcrumbUrl;
};

export const urlPush = (location: any, scrollToTop = false) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [{ pageView }] = useTelemetry();

  if (!appHistory) {
    return;
  }
  previousPage = getWindow().location.href;

  const data = {
    previousPage,
  };
  const payload: ITelemetryData = {
    page: location,
    action: Constants.Telemetry.Action.PageLoad,
    actionModifier: Constants.Telemetry.ActionModifier.Start,
    details: JSON.stringify(data),
  };
  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
  logger.info(payload.details, {
    action: payload.action,
    actionModifier: payload.actionModifier,
    page: payload.page,
  });

  // The embedded app should never push anything onto the history stack
  if (isEmbedded) {
    const embedLocation = embedURL(location);
    if (isValidRoute(embedLocation)) {
      appHistory.replace(embedLocation); // CodeQL [SM01507] already checking if safe redirect by checking isValidRoute
    }
  } else {
    // save cookie and local storage if needed and not show cookie banner, until the cookie is expired (which is for 13 months)
    saveDataToBrowserAfterUserAgreedPrivacyConsent();
    if (isValidRoute(location)) {
      appHistory.push(location);
    }

    if (scrollToTop) {
      scrollTo(0, 0);
    }
  }

  pageView({
    referrerUri: previousPage,
  });
};

export const urlReplace = (location: any) => {
  if (!appHistory) {
    return;
  }

  // save cookie and local storage if needed and not show cookie banner, until the cookie is expired (which is for 13 months)
  saveDataToBrowserAfterUserAgreedPrivacyConsent();
  previousPage = getWindow().location.href;

  const nextLocation = isEmbedded ? embedURL(location) : location;
  if (isValidRoute(nextLocation)) {
    appHistory.replace(nextLocation); // CodeQL [SM01507] already checking if safe redirect by checking isValidRoute
  }
};

export const urlBack = (fallbackLocation?: any) => {
  if (appHistory && previousPage) {
    appHistory.back();
  } else if (fallbackLocation) {
    urlPush(fallbackLocation);
  }
};

/**
 * simple assign to window.location.href
 * @param href should be fully constructed, with host, pathname, and etc.
 */
export const urlAssign = (href: string, shouldOpenInNewTab: boolean): void => {
  if (shouldOpenInNewTab) {
    getWindow() && getWindow().open(href, '_blank');
  } else if (getWindow() && getWindow().location) {
    getWindow().location.href = href;
  }
};

/**
 * @deprecated
 * Legacy interface to address different use cases of expeced props from the {@link withRouter} HOC
 */

export interface WithRouterProps {
  location: History.Location & { query: Record<string, string> };
  params: ReactRouter.Params<string>;
  router: {
    location: History.Location;
    navigate: ReactRouter.NavigateFunction;
    params: ReactRouter.Params<string>;
  };
  routeParams: ReactRouter.Params<string>;
}

export function getQueryParamsObject(search: string): Record<string, string> {
  const searchParams = new URLSearchParams(search);
  const result = {};
  searchParams.forEach((value, key) => {
    result[`${key}`] = value;
  });

  return result;
}

export const removeUserUniqueQueryParams = () => {
  const currentLocation = getWindow().location;
  const urlToReplace = new URL(currentLocation.href);
  if (urlToReplace.searchParams.get(Constants.QueryStrings.CorrelationId)) {
    urlToReplace.searchParams.delete(Constants.QueryStrings.CorrelationId);
  }
  if (urlToReplace.searchParams.get(Constants.QueryStrings.Referer)) {
    urlToReplace.searchParams.delete(Constants.QueryStrings.Referer);
  }

  if (urlToReplace.href !== currentLocation.href) urlReplace(urlToReplace.href);
};

/**
 * @deprecated
 * This function exists for migrating react-router v3 to react-router-dom v6
 * and will serve only legacy Class Components that haven't been transitioned to functional components
 * Please use the relevant hooks instead in functional components
 */
export function withRouter(
  Component: React.ComponentClass<unknown> | Redux.ConnectedComponent<React.ComponentClass, unknown> | React.FC<unknown>
) {
  return function ComponentWithRouter(props: Record<string, unknown>): React.ReactElement {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const query = getQueryParamsObject(location?.search);

    return (
      <Component
        {...props}
        // @ts-expect-error location with backwards compatible query
        location={{ ...location, query }}
        params={params}
        router={{ location, navigate, params }}
        routeParams={params}
      />
    );
  };
}
