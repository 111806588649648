import { logger } from '@src/logger';
import { LibAction } from '../core/action';
import { IInfoBuffer, Buffer, AggregationBuffer, IBufferItem } from '../core/buffer';
import { ConsumerService } from '../core/consumer';
import { IInstrumentService } from '../core/instrument';
import { StaticProvider } from '../providers/staticProvider';
import { ITelemetryEvent, ITelemetryData } from '../../../Models';
import { Constants } from '../../../utils/constants';
import * as httpProtocol from '../../http/httpProtocol';
import { getAppConfig } from '../../init/appConfig';
import { getNetworkStats } from '@shared/utils/appUtils';
import { getWindow } from '@shared/services/window';

const PUBLISH_RETRY_COUNT = 2;

interface ITelemetryPayload {
  events: ITelemetryEvent[];
  correlationId: string;
}

interface ITenantInfo {
  oid: string;
  tid: string;
}

class TelemetryActions extends LibAction {
  private timeoutID = -1;

  public initSystemInfo(probeName: string, service: SpzaTelemetryService) {
    service.systemInfo.correlationId = getAppConfig('correlationId');
  }

  public loggerFlush(probeName: string, service: SpzaTelemetryService): void {
    const buffer = service.buffer;
    const events: any = [];
    if (buffer && buffer.getSize() > 0) {
      while (!buffer.isEmpty()) {
        const event = buffer.pop().data;
        events.push(event);
      }

      const correlationId = getAppConfig('correlationId');

      const telemetryPayload: ITelemetryPayload = {
        events: events,
        correlationId: correlationId,
      };
      service.action.publishTelemetryEvent(telemetryPayload);
    }
  }

  public logEvent(probeName: string, service: SpzaTelemetryService, payload: ITelemetryData): void {
    const event: ITelemetryEvent = service.createDebugEvent(payload);
    service.action.pushRecordInBuffer(probeName, service, event, payload.flushLog);
  }

  public logAndFlushEvent(probeName: string, service: SpzaTelemetryService, payload: ITelemetryData): void {
    const event: ITelemetryEvent = service.createDebugEvent(payload);
    service.action.pushRecordInBuffer(probeName, service, event, true);
  }

  public logUserSystemInfo(probeName: string, service: SpzaTelemetryService, locale: string): void {
    const screenResolution = {
      Width: screen.width,
      Height: screen.height,
      PixelRatio: window.devicePixelRatio ? window.devicePixelRatio : 0,
    };

    const browser = service.action.detectBrowser().split(' ');
    const browserInfo = {
      Browser: browser[0],
      version: browser[1],
    };

    const data = {
      'Screen Resolution': screenResolution,
      'Browser Info': browserInfo,
      Locale: locale,
      connection: getNetworkStats(),
    };

    const obj: ITelemetryData = {
      page: '',
      action: Constants.Telemetry.Action.UserSettings,
      actionModifier: 'Info',
      details: JSON.stringify(data),
    };

    const event: ITelemetryEvent = service.createDebugEvent(obj);
    service.action.pushRecordInBuffer(probeName, service, event, false);
    logger.info(obj.details, { action: obj.action, actionModifier: obj.actionModifier, page: obj.page });
  }

  public logOneTimeInfo(probeName: string, service: SpzaTelemetryService, payload: any): void {
    const obj: ITelemetryData = {
      page: '',
      action: payload.eventName,
      actionModifier: payload.actionModifier ? payload.actionModifier : 'Info',
      details: payload.data,
      isFeedback: payload.isFeedback ? payload.isFeedback : false,
    };

    const event: ITelemetryEvent = service.createDebugEvent(obj);
    service.action.pushRecordInBuffer(probeName, service, event, payload.flushLog);
  }

  private publishTelemetryEvent(telemetryPayload: ITelemetryPayload): void {
    const outEvents = { TelemetryEvents: telemetryPayload.events };
    const serializedEvents = JSON.stringify(outEvents);
    const options: httpProtocol.IHttpOption = {
      authenticationType: httpProtocol.AuthenticationTypes.Unauthenticated,
      retry: PUBLISH_RETRY_COUNT,
    };
    const { region } = getAppConfig('config');

    httpProtocol
      .post('/api/log', options)
      .setData(serializedEvents)
      .setHeader(Constants.Headers.CorrelationId, telemetryPayload.correlationId)
      .setHeader(Constants.Headers.XMSClientRegion, region)
      .request()
      .then(
        () => {
          console.log('[Success] Telemetry Logs submitted successfully');
        },
        (err: any) => {
          console.log('[Fail] Telemetry Log failed : ' + err);
        }
      );
  }

  private detectBrowser(): string {
    const ua = navigator.userAgent;
    let tem: any;
    let M: any = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) {
        return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }

    return M.join(' ');
  }

  private pushRecordInBuffer(probeName: string, service: SpzaTelemetryService, event: ITelemetryEvent, flushLog: boolean) {
    const record: IBufferItem = {
      provider: 'static',
      probe: probeName,
      time: new Date().getTime(),
      data: event,
    };

    service.buffer.push(record);
    if (service.buffer.getSize() > service.maxBufferSize || flushLog) {
      service.action.loggerFlush(probeName, service);
    } else {
      // let's start a timer to flush the buffer
      // this will help safeguard against never reaching the maxBufferSize
      // and risk not storing anything at all
      service.action.startBufferTimer(probeName, service);
    }
  }

  private startBufferTimer(probeName: string, service: SpzaTelemetryService) {
    const flushTimer = 30000; // 30 seconds

    if (this.timeoutID > -1) {
      window.clearTimeout(this.timeoutID);
      this.timeoutID = -1;
    }

    this.timeoutID = getWindow()?.setTimeout?.(() => {
      // flush the buffer if we have anything in it
      if (service.buffer.getSize() > 0) {
        service.action.loggerFlush(probeName, service);
      }

      this.timeoutID = -1;
    }, flushTimer);
  }
}

export class SpzaTelemetryService extends ConsumerService {
  public buffer: Buffer;
  public action: TelemetryActions;
  public systemInfo: IInfoBuffer;
  public host: string;
  public maxBufferSize: number;
  private _spzaId: string;
  private _trailId: string;
  private _tenantInfo: ITenantInfo;
  private _hostType: string;

  constructor(instrumentService: IInstrumentService, isClient: boolean) {
    const name = isClient ? 'client telemetry' : 'server telemetry';
    super(name, instrumentService);
    this._spzaId = '';
    this._trailId = '';
    this._tenantInfo = {
      oid: '',
      tid: '',
    };
    this._hostType = '';

    if (isClient) {
      this.action = new TelemetryActions();
      this.buffer = new Buffer(150);
      // This is the maximum number of records stored in the buffer before flushing
      this.maxBufferSize = 20;
      this.aggregation = new AggregationBuffer();

      if (instrumentService) {
        this.systemInfo = instrumentService.systemInfo;
      }

      this.registerProbes();
    }
  }

  public createDebugEvent(obj: ITelemetryData): ITelemetryEvent {
    const event: ITelemetryEvent = {
      page: obj.page ? obj.page : '',
      action: obj.action ? obj.action : '',
      actionModifier: obj.actionModifier ? obj.actionModifier : '',
      clientTimestamp: new Date().toISOString(),
      appName: obj.appName ? obj.appName : '',
      product: obj.product ? obj.product : -1,
      featureFlag: obj.featureFlag ? obj.featureFlag : '',
      details: obj.details ? obj.details : '',
      oid: this._tenantInfo && this._tenantInfo.oid ? this._tenantInfo.oid : '',
      tid: this._tenantInfo && this._tenantInfo.tid ? this._tenantInfo.tid : '',
      spzaId: this._spzaId ? this._spzaId : '',
      mshash: this._trailId ? this._trailId : '',
      hostType: this._hostType ? this._hostType : '',
      isFeedback: obj.isFeedback ? obj.isFeedback : false,
    };

    return event;
  }

  public registerProbes(): void {
    const p = <StaticProvider>this.instrumentService.getProvider('static');

    if (p) {
      p.enableProbe('initSystemInfo', this, this.action.initSystemInfo);
      p.enableProbe('logTelemetryInfo', this, this.action.logEvent);
      p.enableProbe('logAndFlushTelemetryInfo', this, this.action.logAndFlushEvent);
      p.enableProbe('flushTelemetryInfo', this, this.action.loggerFlush);

      // These are one time telemetry events
      p.enableProbe('logUserSystemInfo', this, this.action.logUserSystemInfo);
      p.enableProbe('logOneTimeInfo', this, this.action.logOneTimeInfo);
    }
  }

  public registerSpzaId(spzaId: string): void {
    this._spzaId = spzaId;
  }

  public registerTrailId(trailId: string): void {
    this._trailId = trailId;
  }

  public registerTenantInfo(oid: string, tid: string): void {
    this._tenantInfo.oid = oid;
    this._tenantInfo.tid = tid;
  }

  public registerHostType(hostType: string): void {
    this._hostType = hostType;
  }
}
