import React from 'react';
import PropTypes from 'prop-types';
import { ILocContext } from '@shared/interfaces/context';
import {
  Modal,
  Stack,
  Text,
  IconButton,
  ScreenWidthMinXLarge,
  ScreenWidthMinLarge,
  Icon,
  mergeStyleSets,
  PrimaryButton,
} from '@fluentui/react';
import type { IStackTokens, IButtonStyles, IIconProps, IIconStyles, IModalStyles } from '@fluentui/react';
import { NeutralColors, SharedColors } from '@fluentui/theme';

export interface IFailureConsentModal {
  shouldSkipModal?: boolean;
  dismissModal: () => void;
  showContactMeError: boolean;
}

const titleAndContentTokens: IStackTokens = { childrenGap: 20 };
const contentAndBottomTokens: IStackTokens = { childrenGap: 24 };
const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: NeutralColors.gray160,
  },
};

const infoIconStyles: Partial<IIconStyles> = {
  root: {
    fontSize: 16,
    marginRight: 8,
    color: SharedColors.red20,
  },
};

const failureConsentModalClassNames = mergeStyleSets({
  description: {
    color: NeutralColors.gray130,
  },
});

const modalStyles: Partial<IModalStyles> = {
  main: {
    [`@media (min-width: ${ScreenWidthMinXLarge}px)`]: {
      width: '30%',
    },
    [`@media (min-width: ${ScreenWidthMinLarge}px) and (max-width: ${ScreenWidthMinXLarge}px)`]: {
      width: '50%',
    },
    [`@media (max-width: ${ScreenWidthMinLarge}px)`]: {
      width: '80%',
    },
    display: 'flex',
  },
  scrollableContent: {
    padding: '16px 27px 24px 24px',
    [`@media (max-width: ${ScreenWidthMinLarge}px)`]: {
      padding: '16px 20px 24px 20px',
    },
  },
};

export const FailureConsentModal: React.FunctionComponent<IFailureConsentModal> = (
  { dismissModal, shouldSkipModal, showContactMeError }: IFailureConsentModal,
  context: ILocContext
) => {
  return (
    <Modal isOpen={!shouldSkipModal} onDismiss={dismissModal} styles={modalStyles}>
      <Stack tokens={titleAndContentTokens}>
        <Stack.Item>
          <Stack horizontal>
            <Stack.Item grow>
              <Text variant="xLarge">{context.loc('Failure_Consent_Modal_Title', "Couldn't send contact request")}</Text>
            </Stack.Item>
            <Stack.Item>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel={context.loc('Dialog_Close')}
                onClick={dismissModal}
              />
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Stack tokens={contentAndBottomTokens}>
            <Stack.Item>
              <Stack horizontal>
                <Stack.Item>
                  <Icon iconName="Info" styles={infoIconStyles} />
                </Stack.Item>
                <Stack.Item>
                  <Text variant="medium" className={failureConsentModalClassNames.description}>
                    {showContactMeError
                      ? context.loc(
                          'ContactMeError_Description_Text',
                          'There was a problem sending your request. Please try again later.'
                        )
                      : context.loc(
                          'Failure_Consent_Modal_Description',
                          'There was a problem sending the contact request. Check your network connection and try again.'
                        )}
                  </Text>
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item align="end">
              <PrimaryButton text={context.loc('Dialog_Close')} ariaLabel={context.loc('Dialog_Close')} onClick={dismissModal} />
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </Modal>
  );
};

(FailureConsentModal as any).contextTypes = {
  loc: PropTypes.func,
};
