/* istanbul ignore file */

import { combineReducers } from 'redux';

import { IState } from '../../State';
import searchReducer from '@shared/reducers/searchReducer';
import modalReducer from '@shared/reducers/modalReducer';
import appDataReducer from '@shared/reducers/appDataReducer';
import userDataReducer from '@shared/reducers/userDataReducer';
import configReducer from '@shared/reducers/configReducer';
import dynamicCampaignReducer from '@shared/reducers/dynamicCampaignReducer';
import userFavouriteReducer from '@shared/reducers/userFavouriteReducer';
import checkoutReducer from '@shared/reducers/checkoutReducer';
import partnersReducer from '@shared/reducers/partnersReducer';
import cloudsIndustryDataReducer from '@shared/reducers/cloudsIndustryDataReducer';
import serviceDataReducer from '@shared/reducers/serviceDataReducer';

// ordering matters here, we first update the filters and then the app data
export default combineReducers<IState>({
  search: searchReducer,
  modal: modalReducer,
  apps: appDataReducer,
  cloudsIndustry: cloudsIndustryDataReducer,
  users: userDataReducer,
  config: configReducer,
  dynamicCampaign: dynamicCampaignReducer,
  userFavourite: userFavouriteReducer,
  checkout: checkoutReducer,
  partners: partnersReducer,
  services: serviceDataReducer,
});
