import React from 'react';
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react';

export function getTooltipSilentLogIn(element: JSX.Element, isLoadingUserData: boolean): JSX.Element {
  const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };
  const calloutProps = { gapSpace: 0, hidden: !isLoadingUserData };

  return (
    <TooltipHost styles={hostStyles} calloutProps={calloutProps} content="Just a moment, logging you in...">
      {element}
    </TooltipHost>
  );
}
