import { IAccessToken } from '@src/State';
import { getWindow } from '@shared/services/window';
import { isEmbedded } from '@shared/utils/appUtils';

export const getScopeByAADResource = (): Record<keyof IAccessToken, string> => {
  const {
    aadApplicationId,
    marketplaceLeadsAudienceId,
    jarvisCM,
    mprpAadApplicationId,
    pifdEndpoint,
    commerceApi,
    graphApi,
    agreementAppId,
  } = getWindow().__INITIAL_STATE__.config;

  return {
    spza: `${aadApplicationId}/.default`,
    arm: `${mprpAadApplicationId}.default`,
    graph: `${graphApi}/.default`,
    pifd: `${pifdEndpoint}.default`,
    commerce: `${commerceApi}/.default`,
    jarvis: `${jarvisCM}.default`,
    marketplaceLeads: `${marketplaceLeadsAudienceId}/.default`,
    agreement: `${agreementAppId}/.default`,
  };
};

export const getExtraScopesToConsent = () => {
  return isEmbedded() ? [] : [getScopeByAADResource().arm];
};
