import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import type { RouteObject, RouteMatch } from 'react-router-dom';

import { Constants } from '@shared/utils/constants';
import { createSetCurrentViewAction } from '@shared/actions/actions';
import { loadTileData } from '@shared/actions/tileDataThunkActions';
import { useLocationChange, RouteView } from '@shared/hooks/useLocationChange';
import { useClientRoutes } from '@shared/hooks/useClientRoutes';

import { GetView } from '@server/matchClientRoutes';

import { PATHS_TO_VIEW_MAP } from './pathsViewMap';

import AppView from '@shared/containers/AppView';

import Home from '@shared/containers/home';
import GalleryPage from '@shared/containers/galleryPage';
import AppDetails from './containers/appDetails';
import ServiceDetails from './containers/serviceDetails';
import AuthRedirect from '@shared/components/AuthRedirect';

const Checkout = React.lazy(() => import('./containers/LegacyCheckoutHydration'));
const SitemapContainer = React.lazy(() => import('./containers/sitemap'));
const PartnerContainer = React.lazy(() => import('@shared/containers/partners'));
const PartnersHostContainer = React.lazy(() => import('@shared/containers/partnersHost'));
const MyReviews = React.lazy(() => import('@shared/containers/myReview'));
const DynamicCampaign = React.lazy(() => import('@shared/containers/dynamicCampaign'));
const UserFavouriteDetail = React.lazy(() => import('@shared/containers/userFavouriteDetail'));
const UserPrivateOffersGallery = React.lazy(() => import('@shared/containers/userPrivateOffersGallery'));

/**
 * Where actual routes should be declared, because the object is also used in the SSR to identify matched routes
 */
export const getRoutes = (): RouteObject[] => [
  {
    path: ':locale',
    element: <AppView />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: Constants.home,
        element: <Home />,
      },
      {
        path: 'marketplace',
        element: <GalleryPage />,
      },
      { path: Constants.appsGallery, element: <GalleryPage /> },
      { path: Constants.cloudsIndustryGallery, element: <GalleryPage /> },
      { path: 'product/:productId/:entityId', element: <AppDetails /> },
      { path: 'marketplace/checkout/:entityId', element: <Checkout /> },
      { path: 'partners', element: <PartnerContainer /> },
      { path: 'user/my-reviews', element: <MyReviews /> },
      { path: Constants.csGallery, element: <GalleryPage /> },
      { path: `${Constants.csGallery}/:entityId`, element: <ServiceDetails /> },
      { path: `${Constants.partnersAppSource}/*`, element: <PartnersHostContainer /> },
      { path: `marketplace/consultingservices/:entityId`, element: <ServiceDetails /> },
      { path: `campaigns/:publisher/:id`, element: <DynamicCampaign /> },
      { path: `user/my-favourites`, element: <UserFavouriteDetail /> },
      { path: `user/private-offers`, element: <UserPrivateOffersGallery /> },
      { path: ':privateOffer/product/:productId/:entityId', element: <AppDetails /> },
      { path: 'sitemap', element: <SitemapContainer /> },
    ],
  },
  { path: 'redirect', element: <AuthRedirect /> },
];

export const getView: GetView = (routeMatch: RouteMatch): RouteView =>
  routeMatch.route.index ? PATHS_TO_VIEW_MAP.home : PATHS_TO_VIEW_MAP[routeMatch.route.path];

const Router: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const setCurrentView = useCallback(
    async function (view: string, featureFlag = '') {
      if (!view) {
        return;
      }

      const query = new URLSearchParams(location.search);
      if (featureFlag && query.get(featureFlag) === 'true') {
        // the route is under a feature flag but the query param didn't have the feature flag set
        // treat is as a route never existed
        navigate('/404');
      }

      dispatch(createSetCurrentViewAction({ currentView: view }));

      // @ts-expect-error known dispatch type error
      dispatch(loadTileData());
    },
    [dispatch, location, navigate]
  );

  const clientRoutes = useMemo(() => getRoutes(), []);
  const routes = useClientRoutes({ routes: clientRoutes });

  useLocationChange({ setCurrentView, map: PATHS_TO_VIEW_MAP, routes });

  return useRoutes(routes);
};

export default Router;
