import { ITelemetryOutRequest } from '@shared/Models';
import { Logger } from './base';

export enum SystemLogsCollection {
  OutgoingRequests = 'OutgoingRequests',
}

interface SystemLogPayload {
  collection?: SystemLogsCollection;
  event: ITelemetryOutRequest;
}

export class SystemLoggerTelemetry extends Logger {
  public info({ collection = SystemLogsCollection.OutgoingRequests, event }: SystemLogPayload) {
    this.log('Info', collection, { ...event });
  }

  public debug({ collection = SystemLogsCollection.OutgoingRequests, event }: SystemLogPayload) {
    this.log('Debug', collection, { ...event });
  }

  public error({ collection = SystemLogsCollection.OutgoingRequests, event }: SystemLogPayload) {
    this.log('Error', collection, { ...event });
  }

  public warning({ collection = SystemLogsCollection.OutgoingRequests, event }: SystemLogPayload) {
    this.log('Warn', collection, { ...event });
  }
}
