import * as React from 'react';
import { IState } from '../../State';
import { upsertUserFavourite, deleteUserFavourite, fetchUserFavourites } from './../actions/userFavouriteThunkActions';
import { UserFavouriteTileButton as UserFavouriteTileButtonComponent } from '@shared/components/userFavouriteTileButton';
import {
  IUserFavouritePostPayload,
  IUserFavouriteTileButtonContainerProps,
  IUserFavouriteItem,
} from './../interfaces/userFavouriteModels';
import { Constants } from './../utils/constants';

const { connect } = require('react-redux');

export const mapStateToProps = (state: IState, ownProps: IUserFavouriteTileButtonContainerProps) => {
  return {
    userInfo: state.users,
    userFavourite: state.userFavourite,
    entityId: ownProps.entityId,
    entityType: ownProps.entityType,
    item: ownProps.item,
  };
};

export const mapDispatchToProps = (dispatch: Function, ownProps: IUserFavouriteTileButtonContainerProps) => {
  return {
    fetchUserFavouriteData: () => dispatch(fetchUserFavourites()),
    savefavourite: (entityId: string, payload: IUserFavouritePostPayload, item: IUserFavouriteItem) =>
      dispatch(upsertUserFavourite(payload, item, entityId)),
    deleteFavourite: (entityGuid: string, applicationType: Constants.UserFavourite.ApplicationTypes) =>
      dispatch(deleteUserFavourite(entityGuid, applicationType)),
  };
};

const UserFavouriteTileButton = connect(mapStateToProps, mapDispatchToProps)(UserFavouriteTileButtonComponent);

export default UserFavouriteTileButton;
