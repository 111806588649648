import React, { useEffect, useRef } from 'react';
import { NeutralColors, SharedColors } from '@fluentui/theme';

import { Link, mergeStyles, mergeStyleSets, Persona, PersonaSize, Rating, RatingSize, Stack, Text, Icon } from '@fluentui/react';
import * as PropTypes from 'prop-types';
import { ILocContext, ICommonContext, IReviewContext, ILocParamsContext } from '@shared/interfaces/context';
import { IAppReview, ITelemetryData } from '@shared/Models';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { getWindow } from '@shared/services/window';
import { Constants } from '@shared/utils/constants';
import { LongContent } from '@shared/components/longContent';
import { getLocaleDate } from '@shared/utils/appUtils';
import { ReviewQAContent } from '@shared/components/reviewQAContent';
import { getProviderNameByReviewSource, launchTelemetry } from '@shared/utils/reviewsUtils';
import { logger } from '@src/logger';
import { AppReviewSocialContent } from '@shared/components/appReviewSocialContent';
import { noop } from 'lodash-es';

// 60px is the height of 3 lines.
const contentClipHeight = 60;

export interface IAppReviewItemProps {
  appId: string;
  iconUrl: string;
  publisher: string;
  review: IAppReview;
  locale: string;
  isEmbed: boolean;
  reviewItemShown(reviewId: string, reviewCompRef: React.RefObject<HTMLDivElement>): void;
  isSignedIn?: boolean;
  isMarkedAsHelpful?: boolean;
  onToggleMarkAsHelpful: (args: { isHelpful: boolean }) => void;
  openSignInModal: () => void;
  fallbackReviewSourceName: string;
  authorPersona?: boolean;
  dateLabelClassName?: string;
}

const contentStyles = mergeStyleSets({
  providerLogo: {
    height: 16,
    width: 16,
    marginRight: 10,
  },
  reviewLinkToSource: {
    ':link': {
      color: SharedColors.cyanBlue10,
      textDecoration: 'underline',
    },
  },
  externalReviewProvider: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  providedByWrapper: [
    'providedBy',
    'c-paragraph-3',
    {
      lineHeight: 14,
      fontSize: 10,
      color: NeutralColors.gray130,
      display: 'flex',
      width: '75%',
      borderTopStyle: 'solid',
      borderTopWidth: 0.5,
      borderTopColor: NeutralColors.gray30,
    },
  ],
  reviewContent: ['reviewContent'],
  reviewAndIsvReply: ['reviewAndIsvReply'],
  reviewItem: ['reviewItem'],
  authorDetailsContainer: ['authorName', 'c-paragraph-3'],
  authorPersona: ['spza-user-default-thumbnail-image', { marginBottom: 8 }],
  reviewRating: ['reviewRating'],
  reviewTitle: ['c-heading-6', 'contentHeader'],
  reviewRightContent: ['rightContent', { overflow: 'hidden' }],
  reviewDateWrapper: ['c-paragraph-3', { marginBottom: 4 }],
  dateLabel: { fontSize: 12, lineHeight: 16 },
  editedLabel: { color: NeutralColors.gray130, overflow: 'hidden' },
  externalSummaryUrlImage: { height: 13, width: 13, color: SharedColors.cyanBlue10, marginTop: 1 },
});

// Displays an individual app review
export const AppReviewItem = (
  {
    authorPersona = false,
    appId,
    reviewItemShown = noop,
    review,
    locale,
    isEmbed,
    publisher,
    iconUrl,
    isSignedIn = false,
    isMarkedAsHelpful = false,
    onToggleMarkAsHelpful,
    openSignInModal,
    fallbackReviewSourceName,
    dateLabelClassName,
  }: IAppReviewItemProps,
  context: ILocContext & ILocParamsContext & ICommonContext & IReviewContext
) => {
  const rootDivRef: React.MutableRefObject<HTMLDivElement> = useRef();
  const instrument = SpzaInstrumentService.getProvider();
  useEffect(() => {
    if (review) {
      reviewItemShown(review.id, rootDivRef);
    }
  }, []);

  useEffect(() => {
    if (review) {
      reviewItemShown(review.id, rootDivRef);
    }
  }, [review?.id]);

  const showReportAbuseDialog = () => {
    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.ReportCTA,
      appName: appId,
    };

    instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', payload);

    logger.info('', {
      action: payload.action,
      actionModifier: payload.actionModifier,
      appName: payload.appName,
    });

    context.reportAbuseCallback(appId, review.id, review.title, review.source);
  };

  const getFullName = (review: IAppReview) => {
    let result = '';
    if (review.customer_info && review.customer_info.name) {
      result += review.customer_info.name;
    }

    if (review.customer_info?.is_anonymous) {
      result = '';
    }

    if (!result) {
      return context.loc('ReviewItem_AnonymousName');
    }

    return result;
  };

  const renderProviderSection = (isThirdPartyReview: boolean) => {
    return (
      <div className={contentStyles.providedByWrapper}>
        {isThirdPartyReview ? (
          <div className={contentStyles.externalReviewProvider}>
            <Text block variant="xSmall">
              <Stack horizontal tokens={{ childrenGap: 4 }}>
                <Link
                  className={contentStyles.reviewLinkToSource}
                  href={review.third_party_review_url}
                  target="_blank"
                  onClick={() => {
                    launchTelemetry(Constants.Telemetry.Action.Click, Constants.Telemetry.ActionModifier.ExternalReviewLink, {
                      offerId: review.offer_id,
                      source: review.source,
                      reviewId: review.id,
                    });
                    logger.info(
                      JSON.stringify({
                        offerId: review.offer_id,
                        source: review.source,
                        reviewId: review.id,
                      }),
                      {
                        action: Constants.Telemetry.Action.Click,
                        actionModifier: Constants.Telemetry.ActionModifier.ExternalReviewLink,
                      }
                    );
                  }}
                >
                  {getProviderNameByReviewSource(review.source, fallbackReviewSourceName)}{' '}
                  {context.loc('Global_Loc_review', 'review')}
                </Link>
                <Icon iconName="NavigateExternalInline" className={contentStyles.externalSummaryUrlImage} />
              </Stack>
            </Text>
          </div>
        ) : (
          <Text variant="xSmall">{context.loc('Global_Loc_Marketplace_review', 'Marketplace review')}</Text>
        )}
      </div>
    );
  };

  const getAriaLabel =
    (review: IAppReview) =>
    (rating: number, max: number): string =>
      context.locParams('ReviewItem_AriaText', [`${rating}`, `${max}`, getFullName(review)]);

  const isReviewEdited = (review: IAppReview) => review.updated_at !== review.created_at;

  const reviewerName = getFullName(review);
  const isThirdPartyReview = review.source === Constants.StorefrontName.G2;

  return (
    <div ref={rootDivRef} className={contentStyles.reviewAndIsvReply}>
      <div className={contentStyles.reviewItem} itemProp="review" itemScope itemType="https://schema.org/Review">
        <div className="leftBar">
          <div className={contentStyles.reviewRating}>
            <Rating
              max={5}
              size={RatingSize.Small}
              rating={review.rating}
              styles={{
                ratingStarBack: { color: NeutralColors.gray100 },
              }}
              getAriaLabel={getAriaLabel(review)}
              readOnly
            />
          </div>
          <div className={contentStyles.reviewDateWrapper}>
            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
              <Text block className={mergeStyles(contentStyles.dateLabel, dateLabelClassName)}>
                {getLocaleDate(review.updated_at, locale)}
              </Text>
              {isReviewEdited(review) && (
                <Text variant="xSmall" className={contentStyles.editedLabel}>
                  {context.loc('ReviewItem_EditedLabel')}
                </Text>
              )}
            </Stack>
          </div>
          <div
            className={contentStyles.authorDetailsContainer}
            title={reviewerName}
            itemProp="author"
            itemScope
            itemType="https://schema.org/Person"
          >
            {authorPersona ? (
              <Persona className={contentStyles.authorPersona} text={reviewerName} size={PersonaSize.size24} />
            ) : (
              reviewerName
            )}
            <meta itemProp="name" content={getFullName(review)} />
          </div>
          {renderProviderSection(isThirdPartyReview)}
        </div>
        <div className={contentStyles.reviewRightContent}>
          <div className="rightBar">
            <div className={contentStyles.reviewContent}>
              {review.title ? <h5 className={contentStyles.reviewTitle}>{review.title}</h5> : null}
              <LongContent
                entityId={review.id}
                content={
                  isThirdPartyReview ? ReviewQAContent({ QAs: review.review_content?.question_answer_content }) : review.content
                }
                clipHeight={contentClipHeight}
              />
            </div>
          </div>
          <AppReviewSocialContent
            iconUrl={iconUrl}
            isEmbed={isEmbed}
            locale={locale}
            onReportClick={() => showReportAbuseDialog()}
            publisherName={publisher}
            review={review}
            isSignedIn={isSignedIn}
            isMarkedAsHelpful={isMarkedAsHelpful}
            onToggleMarkAsHelpful={onToggleMarkAsHelpful}
            openSignInModal={openSignInModal}
          />
        </div>
      </div>
    </div>
  );
};

(AppReviewItem as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  reportAbuseCallback: PropTypes.func,
};
