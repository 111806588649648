// the hookup for the client, this is the code that gets executed when
// the client finally gets its bundle.js. At that point, the dom needs to be
// understood by react so it can take over from here on.

import React, { Suspense, useInsertionEffect } from 'react';
import type { Store } from 'redux';
import { buildStore } from '@shared/store/createStore';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { getAccessTokenCB } from '@shared/components/FederatedModules/RestClient';
import { ThemeProvider, initializeIcons, Stylesheet } from '@fluentui/react';

import { Constants } from '@shared/utils/constants';
import combineReducers from '@shared/reducers/reducers';
import { RootHandler } from '@shared/handlers/rootHandler';

import { getWindow } from '@shared/services/window';
import {
  decodeVulnerableDataInInitalState,
  generateGuid,
  logInitialTelemetryEvents,
  setProfileFeatureFlag,
} from '@shared/utils/appUtils';
import {
  createEnableNewNpsAction,
  createRehydrateClientStateAction,
  createChangeLocaleLanguage,
  createSetInitializedPCAInstanceAction,
} from '@shared/actions/actions';
import { initBrowserConfig } from '@shared/services/init/initBrowser';
import {
  changeBillingRegion,
  getTenantType,
  getUserTenantsDetails,
  setStorefrontName,
  updateUser,
} from '@shared/actions/thunkActions';

import { HttpModule } from '@shared/services/http/httpProtocol';
import { ensureClientLoggerInitialization, logger } from '@src/logger';

import { getCookieItem, saveCookieItem } from '@shared/utils/browserStorageUtils';
import ScrollToTopButton from '@shared/components/scrollToTopButton';

import { IState } from './../State';
import Router from './Router';
import { refreshTokenIfNeeded } from './refreshTokenHandler';

import i18next from 'i18next';
import i18nInit from '@shared/i18n/client';
import { I18nextProvider } from 'react-i18next';
import { onError } from '@shared/handlers/onError';
import { appendHeaderTags } from '@shared/handlers/seo';
import RemoteLogger from '@layout/logger';
import RestClient from '@layout/RestClient';
import AuthClient from '@shared/msal/client';
import { createGuestUser } from '@shared/msal/user';
import { postRedirect } from '@shared/msal/postRedirect';

const CHECK_TOKEN_INTERVAL_MSEC = 1000 * 60 * 10; // 10 minutes in milliseconds

const refreshTokenIfNeededInterval = async (store: Store<IState>) => {
  await refreshTokenIfNeeded(store);
  getWindow()?.setTimeout(refreshTokenIfNeededInterval, CHECK_TOKEN_INTERVAL_MSEC, store);
};

export function initApp(): Store<IState> {
  const currentWindow: Window = getWindow();

  const initialState = currentWindow.__INITIAL_STATE__;
  decodeVulnerableDataInInitalState(initialState);

  const prevCorrelationId = getCookieItem(Constants.Cookies.correlationId, false);
  if (!prevCorrelationId) {
    const correlationId = generateGuid();
    initialState.config.correlationId = correlationId;
    currentWindow.__INITIAL_STATE__ = initialState;
    saveCookieItem(Constants.Cookies.correlationId, correlationId, 'expires=0');
  }

  const store = buildStore<IState>({ combinedReducers: combineReducers, initialState, middleware: [thunk] });

  const localeFromUrl = currentWindow.location.pathname.split('/')[1];
  saveCookieItem(Constants.Cookies.LocaleCookie, localeFromUrl);

  initBrowserConfig(Constants.BrowserConfigs.appsource, store);
  initializeIcons();
  i18nInit();

  HttpModule.updateAccessToken(store.getState().users?.accessToken);
  HttpModule.updateStore(store);

  logInitialTelemetryEvents(store);
  setProfileFeatureFlag(store);

  currentWindow?.setTimeout(refreshTokenIfNeededInterval, CHECK_TOKEN_INTERVAL_MSEC, store);

  const stylesheet = Stylesheet.getInstance();
  stylesheet.setConfig({
    namespace: Constants.AppName.AppSource,
  });

  store.dispatch(setStorefrontName(Constants.StorefrontName.AppSource));
  store.dispatch(getUserTenantsDetails());
  store.dispatch(getTenantType());
  store.dispatch(changeBillingRegion({ shouldSetCountyCodeCookie: false }));
  store.dispatch(createRehydrateClientStateAction());
  store.dispatch(createEnableNewNpsAction({ enable: true }));
  store.dispatch(createChangeLocaleLanguage({ locale: localeFromUrl }));

  onError(currentWindow, store);

  ensureClientLoggerInitialization(store);
  RemoteLogger.init(logger.clientLogger.logger, {
    correlationId: initialState.config.correlationId,
    hostType: Constants.StorefrontName.AppSource,
  });
  RestClient.init(() => getAccessTokenCB(store));

  AuthClient.initialize({
    onSuccess: () => {
      store.dispatch(createSetInitializedPCAInstanceAction({ initialized: true }));
      postRedirect();
    },
    onFailure: () => store.dispatch(updateUser({ userDetails: createGuestUser() })),
  });

  return store;
}

const App: React.FC<{ store: Store<IState> }> = ({ store }) => {
  useInsertionEffect(() => {
    appendHeaderTags(store.getState());
  }, []);

  return (
    <RootHandler landingView={store.getState().config.landingView} store={store}>
      <ThemeProvider>
        <I18nextProvider i18n={i18next}>
          <Provider store={store}>
            <Suspense fallback={null}>
              <Router />
            </Suspense>
          </Provider>
        </I18nextProvider>
        <ScrollToTopButton />
      </ThemeProvider>
    </RootHandler>
  );
};

export default App;
