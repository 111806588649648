import React from 'react';
import { Spinner, SpinnerSize, Stack } from '@fluentui/react';

const AuthRedirect = () => {
  return (
    <Stack>
      <Spinner
        size={SpinnerSize.large}
        styles={{
          root: { position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' },
        }}
      />
    </Stack>
  );
};

export default AuthRedirect;
