import React, { useState } from 'react';
import Tooltip from '@shared/components/tooltip';
import { DirectionalHint, Stack, Text, Spinner, SpinnerSize } from '@fluentui/react';
import type { ITooltipHostStyles, IFontStyles } from '@fluentui/react';
import { AppTileLinkedItems } from '@shared/components/appTileLinkedItems';
import { IAppDataItem, IItemData, ITelemetryData } from '@shared/Models';
import { logger } from '@src/logger';
import { Constants } from '@shared/utils/constants';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { ItemsContainerComponent } from '@shared/components/itemsContainer';
import { IUserFavouriteItem } from '@shared/interfaces/userFavouriteModels';
import { getAppRelatedAppsItems } from '@shared/services/http/entityRestClient';

const tooltipHostStyles: ITooltipHostStyles = { root: { overflow: 'hidden', display: 'inline-block' } };
const stackContainerTokens = { childrenGap: 8 };
const textSize: keyof IFontStyles = 'smallPlus';
const maxTooltipWidth = 450;
const calloutProps = {
  isBeakVisible: true,
  gapSpace: 6,
};

interface IItemsTooltipComponentProps {
  children: React.ReactNode;
  linkedItemsIds?: string[];
  title?: string;
  items?: IItemData[];
  parentItem?: IUserFavouriteItem;
}

export const ItemsTooltip: React.FunctionComponent<IItemsTooltipComponentProps> = ({
  children,
  title,
  linkedItemsIds,
  items,
  parentItem,
}: IItemsTooltipComponentProps) => {
  const [linkedItemsData, setLinkedItemsData] = useState<IAppDataItem[]>([]);
  const getLinkedAppDataItems = async () => {
    const items = await getAppRelatedAppsItems(parentItem.entityId, '');
    if (items.saasLinkingItems.length) {
      setLinkedItemsData(items.saasLinkingItems);
    }
    if (items.linkedAddIns.length) {
      setLinkedItemsData(items.linkedAddIns);
    }
  };

  const toolipToggled = (isTooltipVisible: boolean) => {
    const instrument = SpzaInstrumentService.getProvider();
    const { title, entityId } = parentItem;

    if (isTooltipVisible) {
      const payload: ITelemetryData = {
        page: location.pathname,
        action: Constants.Telemetry.Action.Hover,
        actionModifier: Constants.Telemetry.ActionModifier.SaasLinkTooltip,
        details: JSON.stringify({ title, entityId }),
      };
      instrument.probe<ITelemetryData>(Constants.Telemetry.ProbeName.LogInfo, payload);
      logger.info('', payload);

      if (!linkedItemsData?.length && linkedItemsIds?.length) {
        getLinkedAppDataItems();
      }
    }
  };

  const renderAppTileLinkedItem = () => {
    return linkedItemsData?.length ? (
      <AppTileLinkedItems title={title} linkedItems={linkedItemsData} hoveredItem={parentItem} />
    ) : (
      <Spinner size={SpinnerSize.large} />
    );
  };

  return linkedItemsIds?.length || items?.length ? (
    <Tooltip
      onTooltipToggle={linkedItemsIds?.length ? toolipToggled : null}
      tooltipHostProps={{
        tooltipProps: {
          onRenderContent: () =>
            linkedItemsIds?.length ? (
              renderAppTileLinkedItem()
            ) : items?.length ? (
              <Stack tokens={stackContainerTokens}>
                {title && <Text variant={textSize}>{title}</Text>}
                <ItemsContainerComponent items={items} withText wrap textSize={textSize} withDivider={false} />
              </Stack>
            ) : null,
          maxWidth: `${maxTooltipWidth}px`,
        },
        calloutProps,
        styles: tooltipHostStyles,
        directionalHint: linkedItemsIds?.length ? DirectionalHint.rightCenter : DirectionalHint.topCenter,
      }}
    >
      {children}
    </Tooltip>
  ) : null;
};
