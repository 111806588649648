import type { Store } from 'redux';
import { IState } from '@src/State';
import { getWindow } from '@shared/services/window';
import * as appConfig from './appConfig';
import { Constants } from '../../utils/constants';
import { getXMSClientName } from '../../utils/appUtils';
import { ApiVersion, marketplaceApiVersion } from '@shared/specifics';

export function initBrowserConfig(appName: Constants.BrowserConfigs, store: Store<IState>, embedded = false) {
  if (!store) {
    throw Error('store object is NOT found. Is program running under browser?');
  }

  const hostname = getWindow()?.location.origin;

  const state = store.getState();
  appConfig.init({
    runtimeEnvironment: 'browser',
    embedded,
    appName,
    graphApi: state.users.graphApi,
    correlationId: state.config.correlationId,
    hostname,
    config: state.config,
    basePath: `${hostname}/${state.config.locale}`,
    apiVersion: ApiVersion,
    store,
    marketplaceApiVersion,
    marketplaceApiHost: state.config.marketplaceApiHost,
    saasRPHost: state.config.saasRPHost,
    marketplaceLeadsHost: state.config.marketplaceLeadsHost,
    marketplaceLeadsAudienceId: state.config.marketplaceLeadsAudienceId,
    MPRPHost: state.config.MPRPHost,
    armApiHost: state.config.armApiHost,
    pidlEnv: state.config.pidlEnv,
    partnersIframeUrl: state.config.partnersIframeUrl,
    partnersApiHost: state.config.partnersApiHost,
    XMSClientName: getXMSClientName(appName),
    oneCatalogApiHost: state.config.oneCatalogApiHost,
    expHeaderDisabled: state.config.expHeaderDisabled,
    enableOneTaxonomy: state.config.enableOneTaxonomy,
    siteConsent: state.config.cookiesConsent.WCPConsent,
    agreementAppId: state.config.agreementAppId,
    isConverged: state.config.isConverged,
  });
}
