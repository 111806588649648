import React from 'react';
import { ContextualMenu, DialogType } from '@fluentui/react';
import type { IContextualMenuProps } from '@fluentui/react';
import { IAppReview, IRatingSummary, ITelemetryData } from '@shared/Models';
import { getWindow } from '../services/window';
import { SpzaInstrumentService } from '../services/telemetry/spza/spzaInstrument';
import { ILocContext } from '@shared/interfaces/context';
import { Constants } from '@shared/utils/constants';
import g2Logo from '@shared/images/g2-logo.svg';
import { IAppReviewComment, IAppReviewCommentsState, EnrichReviewComment } from '@shared/interfaces/reviews/comments';
import { camelCase, keyBy, keys, mapKeys } from 'lodash-es';
import { IExternalRatingSummary } from '@shared/interfaces/reviews/external/rating';
import { IAllExternalRatingSummaries, IRatingTotals } from '@shared/interfaces/reviews/rating';
import { IReviewsState } from '@src/State';

export interface ISortByHelper {
  updatedSortBy: string;
  sortedReviewsList: IAppReview[];
}

export interface IReviewDialogProps {
  dialogContentProps: {
    type: DialogType;
    title: string;
  };
  modalProps: {
    isBlocking: boolean;
    styles: {
      main: {
        maxWidth: number;
        minHeight?: number;
      };
      scrollableContent: {
        width: number;
      };
    };
    dragOptions: {
      moveMenuItemText: string;
      closeMenuItemText: string;
      menu: React.FunctionComponent<IContextualMenuProps>;
    };
  };
  maxWidth: number;
}

export interface IUpdateReviewsStateByDropDowns {
  updatePaginationState: (curPageNumber: number, curStart: number, currentEnd: number) => void;
  updateStateByFilters: (updatedRatingsFilter: number, updatedReviewList: IAppReview[], updatedSourceFilter: string) => void;
  updateStateBySortFilter: (updatedSortBy: string) => void;
}

export interface IPaginationIndexes {
  startIndex: number;
  endIndex: number;
  pageIndex: number;
}

export function launchTelemetry(action: string, actionModifier: string, telemetryDetails: any) {
  const payload: ITelemetryData = {
    page: getWindow().location.href,
    action,
    actionModifier,
    details: JSON.stringify(telemetryDetails),
  };

  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
}

export const getProviderNameByReviewSource = (provider: string, defaultName: string) => {
  switch (provider) {
    case Constants.StorefrontName.AppSource:
      return Constants.StorefrontName.AppSource;

    case Constants.StorefrontName.Amp:
      return 'Azure Marketplace';

    case Constants.StorefrontName.G2:
      return Constants.StorefrontName.G2;

    default:
      return defaultName;
  }
};

export function getReviewCtaButtonText(context: ILocContext, userReview: IAppReview): string {
  if (userReview) {
    return context.loc('AppReviewCollection_Button_Text_Edit', 'Edit your review');
  }
  return context.loc('AppReviewCollection_Button_Text_Write', 'Write a review');
}

export function getAddReviewSectionDescription(context: ILocContext, isPurchased: boolean): string {
  if (!isPurchased) {
    return context.loc(
      'AppReviewCollection_addReviewDescription_Text',
      'To submit your rating and review, first get the \napp and let us know about your experience'
    );
  }
  return '';
}

export const getReviewModalDialogProps = (title: string): IReviewDialogProps => {
  const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
  };
  const modalPropsStyles = { main: { maxWidth: 650 }, scrollableContent: { width: 560 } };

  return {
    dialogContentProps: {
      type: DialogType.normal,
      title: title,
    },
    modalProps: {
      isBlocking: true,
      styles: modalPropsStyles,
      dragOptions: dragOptions,
    },
    maxWidth: 560,
  };
};

export const getReviewCommentsModalDialogProps = (title: string): IReviewDialogProps => {
  const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
  };
  const modalPropsStyles = { main: { maxWidth: 650 }, scrollableContent: { width: 560 } };

  return {
    dialogContentProps: {
      type: DialogType.normal,
      title: title,
    },
    modalProps: {
      isBlocking: true,
      styles: modalPropsStyles,
      dragOptions: dragOptions,
    },
    maxWidth: 560,
  };
};

export const createDefaultPagination = (reviews: IAppReview[]): IPaginationIndexes => {
  const defaultPagination: IPaginationIndexes = { startIndex: 1, endIndex: 1, pageIndex: 1 };
  if (reviews && reviews.length > 0) {
    defaultPagination.endIndex =
      reviews.length > Constants.MaxItemsPerPageInReviews ? Constants.MaxItemsPerPageInReviews : reviews.length;
  }
  return defaultPagination;
};

export const createDeepLinkPagination = (reviews: IAppReview[], reviewId: string): IPaginationIndexes => {
  const result: IPaginationIndexes = createDefaultPagination(reviews);
  if (reviews?.length > 0 && reviewId) {
    reviews.some((review, index) => {
      if (review.id === reviewId) {
        const currentPageIndex = Math.trunc(index / Constants.MaxItemsPerPageInReviews);
        result.startIndex = currentPageIndex * Constants.MaxItemsPerPageInReviews + 1;
        result.pageIndex = currentPageIndex + 1;
        result.endIndex = Math.min(result.startIndex + Constants.MaxItemsPerPageInReviews - 1, reviews.length);
        return true;
      }
      return false;
    });
  }
  return result;
};

export const combineDeletedComments = ({ commentsById, commentIds }: IAppReviewCommentsState) => {
  return commentIds.reduce(
    (acc, currId, currIdx) => {
      const currentComment = commentsById[`${currId}`];
      // Accumulate deleted comments
      if (currentComment.isDeleted) {
        acc.deletedAccumulator = [...acc.deletedAccumulator, currId];
      }

      // At the end of the array, or if encountering a normal comment, add the deleted comments or just one if they surpass the limit
      if (!currentComment.isDeleted || currIdx >= commentIds.length - 1) {
        // If the subsequent deleted comments number reaches the limit, only add one
        acc.result = [
          ...acc.result,
          ...(acc.deletedAccumulator.length >= Constants.Reviews.MaxSubsequentDeletedComments
            ? [acc.deletedAccumulator[0]] // Add first one
            : acc.deletedAccumulator), // Add them all
        ];
        // Reset the accumulator
        acc.deletedAccumulator = [];
      }

      if (!currentComment.isDeleted) {
        acc.result = [...acc.result, currId];
      }

      return acc;
    },
    {
      deletedAccumulator: [],
      result: [],
    }
  ).result;
};

export const createInitialReviewCommentsState: () => IAppReviewCommentsState = () => {
  return {
    commentIds: [],
    commentsById: {},
    reducedCommentIds: [],
    isLoading: false,
    newlyAddedComments: [],
    totalComments: 0,
    currentLoadedPage: 0,
    totalPages: 0,
  };
};

export const appendReviewCommentsToState = (
  state: IAppReviewCommentsState,
  comments: IAppReviewComment[]
): IAppReviewCommentsState => {
  const commentsById = keyBy(comments, 'id');
  const newState = {
    ...state,
    commentsById: { ...state.commentsById, ...commentsById },
    commentIds: [...state.commentIds, ...keys(commentsById)],
  };
  return {
    ...newState,
    // Merge consequent deleted comments
    reducedCommentIds: combineDeletedComments(newState),
  };
};

export const mapEnrichReviewCommentToIAppReviewComment = (comment: EnrichReviewComment): IAppReviewComment => {
  const keyMapper = (v: EnrichReviewComment, key: string) => camelCase(key);
  const { customer_info: customerInfo } = comment;
  return {
    ...mapKeys(comment, keyMapper),
    customerInfo: mapKeys(customerInfo, keyMapper),
  } as IAppReviewComment;
};

export const calculateTotalReviewCommentsPagesCount = (totalComments: number) => {
  if (totalComments) {
    return Math.ceil(totalComments / Constants.Reviews.CommentsPerPage);
  }
  return 0;
};

export const getExternalRatingSummaries = (ratingSummaries: IRatingSummary[] = []) => {
  const externalRatingSummaries: IExternalRatingSummary[] = [];
  const g2Summary = ratingSummaries.find((summary) => summary.Source === Constants.StorefrontName.G2);
  if (g2Summary) {
    externalRatingSummaries.push({
      ratingSummary: g2Summary,
      image: g2Logo,
    });
  }

  return externalRatingSummaries;
};

export const getRatingTotals = (ratingSummaries: IRatingSummary[]): IRatingTotals => {
  return (ratingSummaries || [])
    .filter(
      (externalRatingSummary: IRatingSummary) =>
        externalRatingSummary.AverageRating &&
        !isNaN(externalRatingSummary.AverageRating) &&
        externalRatingSummary.TotalRatings &&
        externalRatingSummary.Source === Constants.StorefrontName.G2
    )
    .reduce(
      (accumulatedRatings: IRatingTotals, currentRating: IRatingSummary) => ({
        totalRatingCounts: accumulatedRatings.totalRatingCounts + currentRating.TotalRatings,
        totalAverageRatingsSum:
          accumulatedRatings.totalAverageRatingsSum + currentRating.AverageRating * currentRating.TotalRatings,
      }),
      { totalRatingCounts: 0, totalAverageRatingsSum: 0 }
    );
};

export const getTotalExternalRatingSummariesInfo = (ratingSummaries: IRatingSummary[]): IAllExternalRatingSummaries => {
  const { totalRatingCounts, totalAverageRatingsSum } = getRatingTotals(ratingSummaries);
  return {
    totalRatings: totalRatingCounts,
    averageRatings: totalRatingCounts ? totalAverageRatingsSum / totalRatingCounts : 0,
  };
};

export const getRatingSummaryByType = (ratingSummaries: IRatingSummary[], source: string): IRatingSummary => {
  return ratingSummaries?.find((option) => option.Source === source);
};

export const findReviewsSources = ({ reviews }: IReviewsState): { hasExternalReviews: boolean; hasInternalReviews: boolean } => {
  const { AppSource, Amp, Ibiza } = Constants.StorefrontName;
  const hasInternalReviews = reviews.some((review) => [AppSource, Amp, Ibiza].includes(review.source));
  const hasExternalReviews = reviews.some((review) => review.source === Constants.StorefrontName.G2);
  return { hasExternalReviews, hasInternalReviews };
};
