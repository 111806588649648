import { routes, IRouteConfig } from 'routerHistory';
import { AppTile } from '@shared/containers/appTile';

import { IDataItem } from '@shared/Models';
import { Constants } from '@shared/utils/constants';
import { ServiceTile } from '@shared/containers/serviceTile';
import CommonTile from '@shared/components/commonTile';
import { IState, IEntityDataState } from '@src/State';

export interface IEntityRegistration {
  route: IRouteConfig<any>;
  tileType: typeof AppTile | typeof ServiceTile | typeof CommonTile;
  identifier: string;
  statePropertyName: string;
}

const registration: { [entityType: string]: IEntityRegistration } = {
  // The following keys map to the enum values of Constants.EntityType
  App: {
    route: routes.marketplace,
    tileType: AppTile,
    identifier: 'ApplicationId',
    statePropertyName: 'apps',
  },
  Service: {
    route: routes.marketplaceServices,
    tileType: ServiceTile,
    identifier: 'ServiceId',
    statePropertyName: 'services',
  },
  CloudsIndustry: {
    route: routes.marketplaceCloudsIndustry,
    tileType: CommonTile,
    identifier: 'ApplicationId',
    statePropertyName: 'cloudsIndustry',
  },
};

export function getEntityRegistration(entityType: Constants.EntityType): IEntityRegistration {
  return registration[Constants.EntityType[`${entityType}`]];
}

export function getEntityRegistrationByView(viewName: string): Constants.EntityType {
  let result: Constants.EntityType = null;

  for (const entityType in registration) {
    if (registration[`${entityType}`].route && registration[`${entityType}`].route.name === viewName) {
      result = Constants.EntityType[`${entityType}`];
      break;
    }
  }

  return result;
}

export function getEntityDataState({
  state,
  entityType,
}: {
  state: IState;
  entityType: Constants.EntityType;
}): IEntityDataState<IDataItem> {
  return state[registration[Constants.EntityType[`${entityType}`]].statePropertyName] as IEntityDataState<IDataItem>;
}

export function getAllEntityTypes(): Constants.EntityType[] {
  const keys = Object.keys(Constants.EntityType);
  const result: Constants.EntityType[] = [];
  keys.forEach((key) => {
    if (typeof Constants.EntityType[`${key}`] === 'number') {
      result.push(Constants.EntityType[`${key}`]);
    }
  });

  return result;
}
