import React from 'react';
import * as PropTypes from 'prop-types';
import { NeutralColors } from '@fluentui/theme';
import { mergeStyleSets } from '@fluentui/react';
import { ILocContext } from '@shared/interfaces/context';

const appReviewCommentStyles = mergeStyleSets({
  commentsContainer: {
    marginBottom: '20px',
  },
  paginationContainer: {
    marginTop: '10px',
  },
  emptyMessage: {
    margin: '15px 0',
    color: NeutralColors.gray130,
  },
  loader: {
    margin: '25px 0',
  },
});

export type AppReviewItemCommentEmptyMessageProps = Record<string, never>;

export function AppReviewItemCommentEmptyMessage(params: AppReviewItemCommentEmptyMessageProps, context: ILocContext) {
  return (
    <div className={appReviewCommentStyles.emptyMessage}>
      {context.loc('ReviewItem_CommentSection_Empty_Message', 'No comments yet. Be the first one to comment.')}
    </div>
  );
}

(AppReviewItemCommentEmptyMessage as any).contextTypes = {
  loc: PropTypes.func,
};
