import { Action, isType } from '../actions/actions';
import { IPartnersState, initialPartnersState, copyState } from '../../State';
import { SetContactDetailsAction, SetFilterLocationAction } from '../actions/partnersActions';

export default function partnersReducer(state: IPartnersState = initialPartnersState, action: Action<any>): IPartnersState {
  if (isType(action, SetFilterLocationAction)) {
    const newState = { ...state };
    newState.filterLocation = action.payload;
    return newState;
  } else if (isType(action, SetContactDetailsAction)) {
    const newState = { ...state };
    newState.contactDetails = action.payload;
    return newState;
  }
  return state;
}
