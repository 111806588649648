import { getWindow } from '@shared/services/window';

export const COMSCORE_URL = 'https://www.microsoft.com/library/svy/app/broker.js';

export const getComscorePresentedTime = (): Date => {
  try {
    const comscoreCookie = JSON.parse(getWindow()?.COMSCORE?.SiteRecruit?.Utils?.UserPersistence?.getCookieValue('msresearch'));
    const userId: string = comscoreCookie?.userid;
    const lastPresentedTimeInMs = parseInt(userId?.substring(0, 13));
    return lastPresentedTimeInMs > 0 ? new Date(lastPresentedTimeInMs) : null;
  } catch (error) {
    return null;
  }
};
