/* istanbul ignore file */
export function getAppGalleryPageTitle(context: any) {
  return context.loc('appGallery_pageTitle');
}

export function getFilteredAppPageTitle(context: any) {
  return context.loc('filteredApp_pageTitle');
}

export function getMyReviewsPageTitle(context: any) {
  return context.loc('review_pageTitle');
}

export function getFeaturedAppsTitle() {
  return 'FeaturedAppsInFilter';
}
