import React, { FormEvent, useCallback } from 'react';
import * as PropTypes from 'prop-types';

import { Text, Icon, Label, Stack, ChoiceGroup } from '@fluentui/react';
import type { IChoiceGroupOption } from '@fluentui/react';
import { Constants } from '@shared/utils/constants';
import { ILocParamsContext, ILocContext } from '@shared/interfaces/context';
import {
  ICartItem,
  IAvailabilityActions,
  AdvancedTerm,
  IFuturePriceFormat,
  TermsStrings,
  ISetTermIdAction,
  MPRPPlansEntity,
} from '@shared/Models';
import { convertTermToString } from '@shared/utils/checkoutUtils';
import Tooltip from '@shared/components/tooltip';
import { addFuturePricing } from '@shared/utils/pricing';

const pbTelemetry = Constants.Telemetry.PurchaseBlendsTelemetry;

export interface IBillingFrequencyChoiceGroupProps {
  billingCountryCode: string;
  items: ICartItem[];
  setSelectedTerm: (termIdAction: ISetTermIdAction) => void;
  futurePriceMonthly: IFuturePriceFormat;
  futurePriceYearly: IFuturePriceFormat;
  locale?: string;
}

export interface ILabelAndTooltipProps {
  context: ILocParamsContext & ILocContext;
}
export const GenerateLabelAndTooltip: React.FunctionComponent<ILabelAndTooltipProps> = ({ context }: ILabelAndTooltipProps) => {
  return (
    <Label className="mt-20">
      <Stack horizontal verticalAlign="center" wrap={false}>
        <Text className="ms-fontWeight-semibold	">
          {`${context.loc('Checkout_PriceAndPaymentOptions', 'Price/payment options')} `}
          <Tooltip
            tooltipHostProps={{
              content: context.loc('Checkout_PriceAndBilling_Tooltip_PaymentOptions', "The plan's price and payment frequency"),
              calloutProps: { gapSpace: 0 },
            }}
          >
            <Icon
              iconName="Info"
              ariaLabel={context.loc('Checkout_PriceAndBilling_Tooltip_PaymentOptions', "The plan's price and payment frequency")}
            />
          </Tooltip>
          <Text className="requiredColor">{` *`}</Text>
        </Text>
      </Stack>
    </Label>
  );
};

export const getBillingFrequencyOptions = (
  { billingCountryCode, items, futurePriceMonthly, futurePriceYearly, locale }: Partial<IBillingFrequencyChoiceGroupProps>,
  context: ILocParamsContext & ILocContext
): IChoiceGroupOption[] => {
  const { product, id, termDuration, hasFreeTrial } = items[0];
  const plan: MPRPPlansEntity = product.getPlanByPlanId(id);
  const availability = plan.availabilities.find((availability) => {
    const { actions } = availability;
    return actions.includes(IAvailabilityActions.Renew) && actions.includes(IAvailabilityActions.Browse);
  });
  const termsByBillingTermDuration = availability.terms.filter((term) => term.termUnits === termDuration);

  const billingFrequencyOptions = termsByBillingTermDuration
    .map((term) => {
      const termEntity: AdvancedTerm = product.getTermByTermId({ planId: id, termId: term.termId });
      if (!termEntity) {
        return null;
      }
      return {
        // If there's no free trial termId, assign the renew termId
        key: termEntity.freeTrialTermId || termEntity.renewTermId,
        // This value isn't displayed, but rather used to pass in the relevant contract term in the case there's only one option
        value: termEntity.renewTermUnits,
        text: convertTermToString({
          context,
          term: termEntity,
          countryCode: billingCountryCode,
          isQuantifiable: items[0].isQuantifiable,
          hasFreeTrial,
          locale,
        }),
        onRenderLabel: (option, defaultRender) => (
          <>
            {defaultRender(option)}
            {addFuturePricing(term.renewTermUnits, futurePriceMonthly, futurePriceYearly)}
          </>
        ),
      } as IChoiceGroupOption;
    })
    .filter(Boolean);

  return billingFrequencyOptions;
};

export const getDefaultOrSelectedBillingFrequency = (
  { items }: IBillingFrequencyChoiceGroupProps,
  options: IChoiceGroupOption[]
): string => {
  const availableTermFrequencies = options?.map((option: IChoiceGroupOption) => option.key);
  const defaultBillingFrequency = availableTermFrequencies?.includes(items[0]?.termId)
    ? items[0]?.termId
    : availableTermFrequencies[0];

  return defaultBillingFrequency || '';
};

export const BillingFrequencyChoiceGroup: React.FunctionComponent<IBillingFrequencyChoiceGroupProps> = (
  props: IBillingFrequencyChoiceGroupProps,
  context: ILocParamsContext & ILocContext
) => {
  const { setSelectedTerm, futurePriceMonthly, futurePriceYearly, billingCountryCode, items, locale } = props;
  const { termId, id } = items[0];

  const options = useCallback(
    () => getBillingFrequencyOptions({ billingCountryCode, items, futurePriceMonthly, futurePriceYearly, locale }, context),
    [billingCountryCode, items, futurePriceMonthly, futurePriceYearly, context, locale]
  );

  const { text, value } = options()[0];

  return (
    <>
      <GenerateLabelAndTooltip context={context} />
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Stack.Item>
          {options().length > 1 ? (
            <ChoiceGroup
              data-bi-id={pbTelemetry.PickBillingFrequency}
              data-bi-name={pbTelemetry.BillingFrequencyComponent}
              options={options()}
              onChange={(_?: FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) =>
                setSelectedTerm({ id, termId: option.key })
              }
              selectedKey={termId}
              defaultSelectedKey={getDefaultOrSelectedBillingFrequency(props, options())}
              ariaLabelledBy={'billTermSelection'}
            />
          ) : (
            <>
              <Text>{text}</Text>
              {addFuturePricing(value as TermsStrings, futurePriceMonthly, futurePriceYearly)}
            </>
          )}
        </Stack.Item>
      </Stack>
    </>
  );
};

(BillingFrequencyChoiceGroup as any).contextTypes = {
  locParams: PropTypes.func,
  loc: PropTypes.func,
};
