import { ITelemetryData } from '@shared/Models';
import { getWindow } from '@shared/services/window';
import { Constants } from '@shared/utils/constants';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';

/**
 * log telemetry
 *
 * @param action action on nomination form
 * @param actionModifier action modifier on nomintaion form
 * @param details action details on nomintation form
 */
export const logTelemetry = (action: string, actionModifier: string, details: string = undefined, isFeedback = false) => {
  const pageUrl: string = (getWindow() && getWindow().location && getWindow().location.href) || Constants.TypeOf.undefinedName;

  const payload: ITelemetryData = {
    page: pageUrl,
    action,
    actionModifier,
    isFeedback,
    details,
  };

  SpzaInstrumentService.getProvider().probe<ITelemetryData>(Constants.Telemetry.ProbeName.LogInfo, payload);
};
