import { updateMatchFunctions } from '@shared/utils/filterHelpers';
import { IAppDataItem } from '@shared/Models';
import { Constants } from '@shared/utils/constants';
import { loadAppPricing } from '@shared/utils/pricing';
import { Action, AppsPricingDataReceivedAction, isType } from '@shared/actions/actions';
import { copyState, deepCopyDataMap, ICloudsIndustryDataState, initialCloudsIndustryDataState } from './../../State';
import { commonEntityDataReducer } from './commonEntityDataReducer';

export default function cloudsIndustryDataReducer(
  state: ICloudsIndustryDataState = initialCloudsIndustryDataState,
  action: Action<any>
): ICloudsIndustryDataState {
  const newState = copyState(state);

  if (isType(action, AppsPricingDataReceivedAction)) {
    const { payload } = action;
    const { pricingData = {} } = payload;

    newState.dataList = state.dataList.map((app: IAppDataItem) => loadAppPricing(app, pricingData));

    const dataMap = deepCopyDataMap(state.dataMap);
    updateMatchFunctions(dataMap);
    newState.dataMap = dataMap;
    newState.pricingPayload = payload;
  } else {
    return commonEntityDataReducer(Constants.EntityType.CloudsIndustry, state, action) as ICloudsIndustryDataState;
  }
  return newState;
}
