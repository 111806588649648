import { IAppsPricingsPayload } from '@shared/Models';
import { ActionType, actionCreator } from '@shared/actions/actions';
import {
  IUpdateUserFavouriteFetchDataStatusActionPayload,
  IReceiveUserFavouriteDataActionPayload,
  IDeleteUserFavouriteItemActionPayload,
  IUpsertUserFavouriteItemActionPayload,
} from '@shared/interfaces/userFavouriteModels';

export const UpdateUserFavouriteFetchDataStatusAction: ActionType<IUpdateUserFavouriteFetchDataStatusActionPayload> =
  'UpdateUserFavouriteFetchDataStatusAction';
export const createUpdateUserFavouriteFetchDataStatusAction = actionCreator(UpdateUserFavouriteFetchDataStatusAction);

export const ReceiveUserFavouriteDataAction: ActionType<IReceiveUserFavouriteDataActionPayload> =
  'ReceiveUserFavouriteDataAction';
export const createReceiveUserFavouriteDataAction = actionCreator(ReceiveUserFavouriteDataAction);

export const DeleteUserFavouriteItemAction: ActionType<IDeleteUserFavouriteItemActionPayload> = 'DeleteUserFavouriteItemAction';
export const createDeleteUserFavouriteItemAction = actionCreator(DeleteUserFavouriteItemAction);

export const UpsertUserFavouriteItemAction: ActionType<IUpsertUserFavouriteItemActionPayload> = 'UpsertUserFavouriteItemAction';
export const createUpsertUserFavouriteItemAction = actionCreator(UpsertUserFavouriteItemAction);

export const FavouriteAppsPricingDataReceivedAction: ActionType<IAppsPricingsPayload> = 'FavouriteAppsPricingDataReceivedAction';
export const createFavouriteAppsPricingDataReceivedAction = actionCreator(FavouriteAppsPricingDataReceivedAction);
