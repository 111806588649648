import { Constants } from '@shared/utils/constants';
import { createSearchQueryChangedAction } from '@shared/actions/actions';
import { IAppDataItem, ICloudsIndustry } from '@shared/Models';
import { Service } from '@shared/serviceViewModel';
import type { Dispatch } from 'redux';

export function dispatchSearchQueryChangedAction(dispatch: Dispatch, searchText: string): Promise<void> {
  return Promise.resolve().then(() => {
    dispatch(
      createSearchQueryChangedAction({
        performedQuery: searchText,
        entityType: Constants.EntityType.App,
      })
    );
    dispatch(
      createSearchQueryChangedAction({
        performedQuery: searchText,
        entityType: Constants.EntityType.Service,
      })
    );
  });
}

const numProductsToTake = 5;
const takeTopN = (entities: ICloudsIndustry[]) => {
  return entities
    .slice(0, numProductsToTake)
    .map(({ entityId }) => entityId)
    .join(', ');
};

export const takeTopResults = ({
  apps = [],
  cloudsIndustry = [],
  services = [],
}: {
  apps: IAppDataItem[];
  services: Service[];
  cloudsIndustry: IAppDataItem[];
}) => {
  const topApps = takeTopN(apps);
  const topConsultingServices = takeTopN(services);
  const topIndustryClouds = takeTopN(cloudsIndustry);

  return JSON.stringify({
    topApps,
    topConsultingServices,
    topIndustryClouds,
  });
};
