/* eslint-disable @typescript-eslint/no-use-before-define */
import { DataMap } from '@shared/utils/dataMapping';
import { getWindow } from '@shared/services/window';
import { ITelemetryData, IAppDataItem, UserSegment } from '@shared/Models';
import { Constants } from '@shared/utils/constants';
import { createEmbedUserSignInAction } from '@shared/actions/actions';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import {
  getEmbedHostName,
  getTelemetryResponseUrl,
  getHandoffUrlForProduct,
  checkOriginSource,
  getPaymentRedirectUrl,
  postMessageToHost,
  getProductByUrlKey,
} from '@shared/utils/appUtils';
import { HttpModule } from '@shared/services/http/httpProtocol';
import { renderErrorModal } from '@shared/utils/errorHandlerUtil';
import { getPrimaryProductUrl } from '@shared/utils/datamappingHelpers';
import { logger } from '@src/logger';
import * as embedHostUtils from './embedHostUtils';
import { constants } from './constants';
import { fetchPowerBIData, fetchEmbedAppData, finishProcessingAppData } from './actions/embedThunks';
import { stringifyError } from '@shared/utils/errorUtils';

let appDataRequestStart = 0;

// DO NOT EVER CHANGE THE MAPPING FOR dynamics-365-business-central THIS WILL BREAK EMBED CTA
const legacyProductsMap = {
  'dynamics-365-for-finance-and-operations-enterprise-edition': 'dynamics-365-for-operations',
  'dynamics-365-for-sales-enterprise-edition': 'dynamics-365-for-sales',
  'dynamics-365-for-finance-and-operations-business-edition': 'dynamics-365-for-financials',
  'dynamics-365-for-customer-services-enterprise-edition': 'dynamics-365-for-customer-services',
  'dynamics-365-for-field-services-enterprise-edition': 'dynamics-365-for-field-services',
  'dynamics-365-for-project-service-automation-enterprise-edition': 'dynamics-365-for-project-service-automation',
  'dynamics-365-business-central': 'dynamics-365-for-financials',
};

export function postEmbedCTAMessage(app: IAppDataItem) {
  const data = {
    applicationId: app.entityId,
  };

  postMessageToHost({
    msgType: constants.actionTypes.ctaClicked,
    data,
  });
}

export function postEmbedAcquisitionMessage(app: IAppDataItem, ctaClicked: Constants.CTAType, embedHost = '') {
  const productTitle = getPrimaryProductUrl(app.primaryProduct);
  // eslint-disable-next-line no-prototype-builtins
  const productName = legacyProductsMap.hasOwnProperty(productTitle) ? legacyProductsMap[`${productTitle}`] : productTitle;
  const data = {
    applicationId: app.entityId,
    category: app.privateApp ? 'private' : 'public',
    redirectUrl:
      app.handoffURL || getHandoffUrlForProduct(productTitle, app.entityId, false, app.products, app.locale, ctaClicked),
    product: productName,
    responseUrl: getTelemetryResponseUrl(app.entityId),
  };

  if (embedHost === DataMap.products.AdminPortal.UrlKey && ctaClicked === Constants.CTAType.Purchase) {
    data.paymentRedirectUrl = getPaymentRedirectUrl(app.entityId, Constants.M365Admin);
  }

  postMessageToHost({
    msgType: constants.actionTypes.acquireApp,
    data,
  });
}

export function convertBitmaskValueToRawData(result: number, dataMapping: any) {
  const data: string[] = [];
  if (result) {
    const keys = Object.keys(dataMapping);

    for (let i = 0; i < keys.length; i++) {
      const value = dataMapping[keys[`${i}`]];
      if (result & value) {
        data.push(keys[`${i}`]);
      }
    }
  }

  return data;
}

export function logInitListenerFinished(embedHost: string) {
  const payload: ITelemetryData = {
    page: 'In App Gallery',
    action: Constants.Telemetry.Action.EmbedMessageListener,
    actionModifier: Constants.Telemetry.ActionModifier.End,
    details: `embedHost: ${getEmbedHostName(embedHost)}`,
  };

  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
  logger.info(payload.details, {
    action: payload.action,
    actionModifier: payload.actionModifier,
    page: payload.page,
  });

  postMessageToHost({
    msgType: constants.actionTypes.initListenerFinished,
  });
}

export function logPageLoadFinished(embedHost?: string) {
  const timeAtTelemetryMethodCall = new Date().getTime();

  // This event is used to indicate that the In App gallery apps rendering is finished.
  const payload: ITelemetryData = {
    page: 'In App Gallery',
    action: Constants.Telemetry.Action.PageLoad,
    actionModifier: Constants.Telemetry.ActionModifier.End,
    details: 'In App Gallery tiles rendering finished',
  };
  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
  logger.info(payload.details, {
    action: payload.action,
    actionModifier: payload.actionModifier,
    page: payload.page,
  });

  const currentWindow = getWindow();

  if (currentWindow && currentWindow.performance && embedHostUtils.hasDynamicData(embedHost)) {
    const timing = currentWindow.performance.timing;
    // Telemetry for Network Latency
    const networkLatency = timing.responseEnd - timing.fetchStart;

    const currentTime = new Date().getTime();
    // The time taken for page load once the page is received from the server
    // we may find timing.loadEventEnd as 0 if called before load event fires.
    // If so, use current time as approximation till we fix it properly
    const loadEventEnd = timing.loadEventEnd !== 0 ? timing.loadEventEnd : currentTime;

    // The time taken for page load once the page is received from the server
    const pageLoadTime = loadEventEnd - timing.responseEnd;

    // The whole process of navigation and page load
    const navigationToPageLoad = currentTime - timing.navigationStart;
    const dataLatency = currentTime - appDataRequestStart;

    // the data we are getting here is valuable, but seems very browser specific (some browsers do not properly support)
    // so introducing a new number here that will always be consistent:
    const timePassedSinceNavigationStart = timeAtTelemetryMethodCall - timing.navigationStart;

    const perfTimings = {
      networkLatency,
      pageLoadTime,
      navigationToPageLoad,
      timePassedSinceNavigationStart,
      allTimings: timing, // Dumping raw timings for perf telemetry analysis
      isEmbedded: true,
      embedHost: getEmbedHostName(embedHost),
      isAuthenticated: false,
      dataLatency,
    };

    const perfPayload = {
      eventName: Constants.Telemetry.Action.PerfEvents,
      data: JSON.stringify(perfTimings),
      flushLog: true,
    };

    SpzaInstrumentService.getProvider().probe<any>('logOneTimeInfo', perfPayload);
    logger.info(perfPayload.data, {
      action: perfPayload.eventName,
      actionModifier: Constants.Telemetry.ActionModifier.Info,
      page: '',
    });
  }

  postMessageToHost({
    msgType: constants.actionTypes.finishedLoadingContentProviderList,
  });
}

export function initializeListener(dispatch: any, embedHost: string) {
  const currWindow = getWindow();
  if (currWindow.parent !== currWindow) {
    currWindow.addEventListener('message', (e: any) => {
      receiveMessage(e, dispatch, embedHost);
    });
  }

  logInitListenerFinished(embedHost);

  if (!embedHostUtils.hasDynamicData(embedHost)) {
    // If it is not dynamic data on embed host, we are done
    // with fetching the app data.
    appDataRequestStart = new Date().getTime();
    logPageLoadFinished(embedHost);
  }
}

// handle message from parent window
export function receiveMessage(event: any = {}, dispatch: any, embedHost: string) {
  const { origin, data = {} } = event;
  const { hostData, msgType } = data;
  const validOrigin = checkOriginSource(origin);

  const payload: ITelemetryData = {
    page: getWindow().location.href,
    action: Constants.Telemetry.Action.EmbedMessaging,
    actionModifier: Constants.Telemetry.ActionModifier.ReceiveMessage,
    details: JSON.stringify({
      origin,
      validOrigin,
      msgType,
    }),
  };
  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
  logger.info(payload.details, {
    action: payload.action,
    actionModifier: payload.actionModifier,
  });

  if (!validOrigin) {
    return;
  }

  if (hostData) {
    hostData.userSegment = UserSegment.unauthenticated;

    const {
      error,
      hostCorrelationId,
      accessToken,
      privateApps,
      getAppsEndpoint,
      firstName,
      lastName,
      workEmail,
      backendUrlOverride,
    } = hostData;

    switch (msgType) {
      case Constants.embedMessage.loadMarketplaceV2:
        if (error) {
          handleError(true, hostCorrelationId, error);
        } else if (!accessToken) {
          handleError(true, hostCorrelationId, 'No access token');
        } else if (!hostCorrelationId) {
          handleError(true, '', 'No host correlation ID');
        } else {
          if (embedHostUtils.hasPrivateApps(embedHost) && !privateApps) {
            handleError(
              false,
              hostCorrelationId,
              'privateApps is required but is null. EmbedHost: ' + getProductByUrlKey({ urlKey: embedHost })?.UrlKey
            );
          }
          finishProcessingAppData(dispatch, embedHost, privateApps);
          dispatch(createEmbedUserSignInAction(hostData));
          HttpModule.updateAccessToken(hostData);
        }
        break;
      case Constants.embedMessage.loadMarketplace:
        // PowerBI won't log page load finished until after it fetches and parses
        // the app list from the PowerBI backend
        if (getAppsEndpoint) {
          // This event is used to indicate that the additional data is about to be fetched.
          appDataRequestStart = new Date().getTime();
          const detailsObject = {
            message: '[Start] Partner App data request start (' + getProductByUrlKey({ urlKey: embedHost })?.UrlKey + ')',
            endPoint: getAppsEndpoint,
            receivedFirstNameFromEmbedHost: !!firstName,
            receivedLastNameFromEmbedHost: !!lastName,
            receivedWorkEmailFromEmbedHost: !!workEmail,
            receivedAccessTokenFromEmbedHost: !!accessToken,
          };

          const payload: ITelemetryData = {
            page: 'In App Gallery(' + getProductByUrlKey({ urlKey: embedHost })?.UrlKey + ')',
            action: Constants.Telemetry.Action.NetworkCall,
            actionModifier: Constants.Telemetry.ActionModifier.Start,
            details: JSON.stringify(detailsObject),
          };
          SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
          logger.info(payload.details, {
            action: payload.action,
            actionModifier: payload.actionModifier,
            page: payload.page,
          });

          dispatch(fetchEmbedAppData(embedHost, hostData));
        } else if (backendUrlOverride) {
          // This event is used to indicate that the Power BI data is about to be fetched.
          appDataRequestStart = new Date().getTime();
          const detailsObject = {
            message: '[Start] Power BI Request Start. loadMarketplace message Received from Power BI',
            endPoint: backendUrlOverride,
            receivedFirstNameFromEmbedHost: !!firstName,
            receivedLastNameFromEmbedHost: !!lastName,
            receivedWorkEmailFromEmbedHost: !!workEmail,
            receivedAccessTokenFromEmbedHost: !!accessToken,
          };
          const payload: ITelemetryData = {
            page: 'In App Gallery(PowerBI)',
            action: Constants.Telemetry.Action.NetworkCall,
            actionModifier: Constants.Telemetry.ActionModifier.Start,
            details: JSON.stringify(detailsObject),
          };
          SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
          logger.info(payload.details, {
            action: payload.action,
            actionModifier: payload.actionModifier,
            page: payload.page,
          });
          dispatch(fetchPowerBIData(hostData));
        }

        hostData.email = workEmail;
        dispatch(createEmbedUserSignInAction(hostData));
        HttpModule.updateAccessToken(hostData);
        break;
      default:
        // eslint-disable-next-line no-useless-return
        return;
    }
  }
}

function handleError(showErrorModal: boolean, hostCorrelationId: string, error: any) {
  renderErrorModal(null);

  const payload: ITelemetryData = {
    page: getWindow().location.href,
    action: Constants.Telemetry.Action.MyOrgApps,
    actionModifier: Constants.Telemetry.ActionModifier.Error,
    details: JSON.stringify({
      hostCorrelationId,
      error,
    }),
  };

  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
  logger.error(payload.details, {
    action: payload.action,
    actionModifier: payload.actionModifier,
  });
}
