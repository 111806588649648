import { Constants } from '@shared/utils/constants';
import { logError } from '@shared/utils/httpClientUtil';
import { IAppDataItem, IAzureSubscriptionsResponse, MPRPAppData } from '@shared/Models';
import { getAppConfig } from '@shared/services/init/appConfig';
import { logger } from '@src/logger';
import * as httpProtocol from './httpProtocol';

export function getUserPrivateApps(billingRegion: string): Promise<IAppDataItem[]> {
  const endpoint = '/view/privateApps';
  const options = {
    requireCorrelationId: true,
    stringifyPostData: false,
    authenticationType: httpProtocol.AuthenticationTypes.Arm,
    setBearerHeader: true,
  };

  const get = httpProtocol.get(endpoint, options);

  return get
    .addQueryEntry(Constants.QueryStrings.version, Constants.mprpApiVersion)
    .addQueryEntry(Constants.QueryStrings.billingRegion, billingRegion)
    .request()
    .catch((error: any) => {
      logError('getUserPrivateOffers', error);
      return Promise.reject(error);
    });
}

export function getUserPrivateApp(entitiyId: string, billingRegion: string): Promise<IAppDataItem> {
  const endpoint = '/view/privateApp/' + entitiyId;
  const options = {
    requireCorrelationId: true,
    stringifyPostData: false,
    authenticationType: httpProtocol.AuthenticationTypes.Arm,
    setBearerHeader: true,
  };

  const get = httpProtocol.get(endpoint, options);

  return get
    .addQueryEntry(Constants.QueryStrings.version, Constants.mprpApiVersion)
    .addQueryEntry(Constants.QueryStrings.billingRegion, billingRegion)
    .request()
    .catch((error: any) => {
      logError('getUserPrivateOffers', error);
      return Promise.reject(error);
    });
}

/** This method uses ARM to get the signed-in user's subscriptions (via bearer) */
export function getUserSubscriptions(): Promise<IAzureSubscriptionsResponse> {
  const endpoint = `${getAppConfig('armApiHost')}/subscriptions`;
  const options = {
    requireCorrelationId: true,
    stringifyPostData: false,
    allowOrigin: true,
    authenticationType: httpProtocol.AuthenticationTypes.Arm,
    contentType: 'application/json',
    returnRawResponse: false,
  };

  const get = httpProtocol.get(endpoint, options);

  return get
    .addQueryEntry(Constants.QueryStrings.apiVersion, Constants.armSubscriptionApiVersion)
    .request()
    .catch((error: any) => {
      logError('getUserSubscriptions', error);
      return Promise.reject(error);
    });
}

export function getPrivateOrPublicPublicId(productId: string, market = 'US'): Promise<MPRPAppData> {
  const endpoint = `${getAppConfig('armApiHost')}/providers/Microsoft.Marketplace/offers/${productId}`;
  const options = {
    requireCorrelationId: true,
    stringifyPostData: false,
    allowOrigin: true,
    authenticationType: httpProtocol.AuthenticationTypes.Arm,
    contentType: 'application/json',
    returnRawResponse: false,
  };

  const get = httpProtocol.get(endpoint, options);
  return get
    .addQueryEntry(Constants.QueryStrings.apiVersion, Constants.mprpApiVersion)
    .addQueryEntry(Constants.QueryStrings.market, market?.toUpperCase())
    .addQueryEntry(Constants.QueryStrings.ExcludePublic, 'false') // Hardcoded false since we always want to get an offer, public or private, during checkout
    .addQueryEntry(Constants.QueryStrings.includeStopSoldPlans, 'false')
    .addQueryEntry(Constants.QueryStrings.includePrivateOffers, 'True')
    .request()
    .catch((error: any) => {
      logError('getPrivateOfferById', error);
      logger.error('getPrivateOfferById', {
        action: 'Get product from catalog',
        actionModifier: Constants.Telemetry.ActionModifier.Error,
        error,
      });
      return Promise.reject(error);
    });
}
