import { connect } from 'react-redux';
import { IMediaModalPayload } from '@shared/Models';
import { IState } from '../../State';
import { Overview as OverviewComponent, IOverviewProps, IOverviewDispatchProps } from '../components/overview';
import { createMediaModalAction } from '@shared/actions/actions';
import type { Dispatch } from 'redux';

export const mapStateToProps = (state: IState, ownProps: IOverviewProps): IOverviewProps => {
  return ownProps;
};

export const mapDispatchToProps = (dispatch: Dispatch<any, any>): IOverviewDispatchProps => {
  return {
    openMediaModal: (payload: IMediaModalPayload) => {
      dispatch(createMediaModalAction({ showModal: true, payload }));
    },
  };
};

const Overview = connect<IOverviewProps, IOverviewDispatchProps>(mapStateToProps, mapDispatchToProps)(OverviewComponent);

export default Overview;
