import * as React from 'react';
import * as PropTypes from 'prop-types';
import { ILink } from '@shared/Models';
import { ILocContext, ILocParamsContext, ICommonContext } from '@shared/interfaces/context';
import { ExternalLink } from '@shared/components/externalLink';
import SpzaComponent from '@shared/components/spzaComponent';

export interface IBannerLinks {
  links: ILink[];
  onLinkClick?: ({ event }: { event: React.MouseEvent<HTMLAnchorElement> }) => void;
}

export class BannerLinks extends SpzaComponent<IBannerLinks, any> {
  context: ICommonContext & ILocContext & ILocParamsContext;

  onLinkClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    this.props.onLinkClick?.({ event });
  };

  renderImpl() {
    if (!this.props.links?.length) {
      return;
    }

    return this.props.links.map(({ text, url, params }) => {
      const bannerTitle: string = params ? this.context.locParams(text, params) : this.context.loc(text);
      const telemetryDetails = JSON.stringify({ bannerTitle, bannerTargetUrl: url });

      return (
        <div key={`banner-link-${bannerTitle}-${url}`}>
          <ExternalLink
            onClickHandle={this.onLinkClick}
            rel="noreferrer"
            target="_blank"
            className="c-hyperlink"
            href={url}
            accessibilityEnabled
            telemetryDetails={telemetryDetails}
          >
            {bannerTitle}
          </ExternalLink>
        </div>
      );
    });
  }
}

(BannerLinks as any).contextTypes = {
  loc: PropTypes.func,
  locDateString: PropTypes.func,
  locParams: PropTypes.func,
};
