/* istanbul ignore file */
import { logClientError } from '@shared/utils/appUtils';
import { getWindow } from '@shared/services/window';

const KNOWN_HYDRATION_INTERRUPTION_ERRORS = ['Root did not complete', 'Minified React error #345'];

/**
 * We are avoiding error propagation. This error happens when the client is not fully hydrated, yet user attempts to interact with it.
 * The client is still recoverable due to server-side navigation, although we will re-attempt to hydrate and trigger the client-side for full functionality.
 */
export function handleInterruptedHydration(errorMsg: string): boolean {
  const isKnownIssue = KNOWN_HYDRATION_INTERRUPTION_ERRORS.some((issue) => errorMsg?.includes?.(issue));

  if (isKnownIssue) {
    getWindow()?.HYDRATE?.();
  }

  return isKnownIssue;
}

export function handleRecoverableHydrationError(error: unknown, env: string) {
  if (env !== 'prod') {
    // this error should be visible in non-production environments as awareness when recovrable hydration issues occur and should be fixed!
    console.error(error);
    throw error;
  }

  logClientError(error, `client side error: React recoverable hydration error ${error}`, '', true);
}
