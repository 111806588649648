/* istanbul ignore file */
import { getAwaMetaTags } from '@shared/utils/seoUtils';
import { IState } from '@src/State';

export const appendHeaderTags = (state: IState) => {
  const awaMetaTags = getAwaMetaTags(state);
  awaMetaTags.forEach((awaMetaTag) => {
    if (awaMetaTag) {
      document.head.appendChild(awaMetaTag);
    }
  });
};
