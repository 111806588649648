import * as React from 'react';
import * as PropTypes from 'prop-types';
import { SearchBox } from '@fluentui/react';
import type { ISearchBox } from '@fluentui/react';

import SpzaComponent from './spzaComponent';
import { ICommonContext, ILocContext } from './../interfaces/context';
import { Constants } from './../utils/constants';
import { deepEqual } from './../utils/objectUtils';
import {
  getOptionIndex,
  getContainerAriaLabel,
  getContainerClassName,
  getOptionClassName,
  CONTAINER_ROLE,
  isHoverEvent,
  getFilteredOptions,
  isOptionSelected,
} from './../utils/richTextDropDownUtils';
import classNames from 'classnames';

export interface IRichTextDropDownProps<T> {
  options: IRichTextDropDownItem<T>[];
  isSorted?: boolean;
  // this property is to decide what value should be shown in input box
  value: IRichTextDropDownItem<T>;
  onChangeHandle: (option: IRichTextDropDownItem<T>, options?: IRichTextDropDownItem<T>[]) => void;
  className?: string;
  id?: string;
  dropdownLabel?: string;
  hoverEnabled?: boolean;
  searchEnabled?: boolean;
  isDisabled?: boolean;

  // customized render method for dropdown option item, default is using option text property to render
  renderOptionHandle?: (option: IRichTextDropDownItem<T>) => JSX.Element;

  // customized render method for dropdown input box value item, default is using option value property to render
  renderValueHandle?: (option: IRichTextDropDownItem<T>) => JSX.Element;
  onBlurHandle?: () => void;
}

export interface IRichTextDropDownStates<T> {
  value?: IRichTextDropDownItem<T>;
  highLightOption?: IRichTextDropDownItem<T>;
  isOpen?: boolean;
  searchValue: string;
}

export interface IRichTextDropDownItem<T> {
  // default properties, for rendering dropdown
  // Note: if you choose not to have text, or value as option properties
  // You need to implement your own renderOptionHandle, or renderValueHandle, and decide what defaultValue should be
  text?: string;
  value?: T;

  // customized option properties
  [itemName: string]: any;
}

export class RichTextDropDown<T> extends SpzaComponent<IRichTextDropDownProps<T>, IRichTextDropDownStates<T>> {
  context: ICommonContext & ILocContext;

  private dropdownElement: HTMLElement;
  private searchBoxElement: ISearchBox;
  private lastFocusableIndex = -1;
  private lastFocusableGroupIndex = -1;
  private shouldCloseOnBlur: boolean;
  private userInteractionType: Constants.UserInteractionType = Constants.UserInteractionType.Mouse;

  constructor(props: IRichTextDropDownProps<T>, context: ICommonContext & ILocContext) {
    super(props, context);

    this.state = {
      value: this.props.value,
      searchValue: '',
    };

    this.renderValueMethod = this.renderValueMethod.bind(this);
    this.renderOptionMethod = this.renderOptionMethod.bind(this);
  }

  renderValueMethod(item: IRichTextDropDownItem<T>): JSX.Element {
    if (this.props.renderValueHandle) {
      return this.props.renderValueHandle(item);
    }

    return (
      <div className="item">
        <span className="text-item">{item.text}</span>
      </div>
    );
  }

  renderOptionMethod(item: IRichTextDropDownItem<T>): JSX.Element {
    if (this.props.renderOptionHandle) {
      return this.props.renderOptionHandle(item);
    }
    if (this.isSearchEnabled() && this.state.searchValue.length) {
      const searchValue = this.state.searchValue;
      const countryName = item.text;
      const start = countryName.toLowerCase().indexOf(searchValue.toLowerCase());
      const end = start + searchValue.length - 1;
      return (
        <div className="item">
          {countryName.substr(0, start)}
          <b className="item-search">{countryName.substr(start, end - start + 1)}</b>
          {countryName.substr(end + 1)}
        </div>
      );
    }
    return <div className="item">{item.text}</div>;
  }

  UNSAFE_componentWillReceiveProps(nextProps: IRichTextDropDownProps<T>) {
    if (!deepEqual(this.props.options, nextProps.options) || !deepEqual(this.props.value, nextProps.value)) {
      this.setState({ value: nextProps.value });
    }
  }

  openDropDown(eventType: Constants.EventType) {
    this.dropdownElement?.classList.remove('outline-focus');

    if (this.shouldSkipEventHandle(eventType)) {
      return;
    }

    this.setState({ isOpen: true }, () => {
      if (this.isHoverEnabled()) {
        this.dropdownElement?.focus();
      }
      if (this.isSearchEnabled() && this.searchBoxElement) {
        this.searchBoxElement.focus();
      }
    });

    if (this.state.value) {
      this.setState({ highLightOption: this.state.value });
    }

    // reset to true everytime dropdown opens
    this.shouldCloseOnBlur = true;
  }

  closeDropDown(eventType: Constants.EventType) {
    if (this.shouldSkipEventHandle(eventType)) {
      return;
    }

    this.dropdownElement?.setAttribute('aria-expanded', 'false');
    this.setState({ isOpen: false, searchValue: '' });
    this.removeTabIndex();
    this.lastFocusableIndex = -1;
    this.lastFocusableGroupIndex = -1;

    // reset to true everytime dropdown closes
    this.shouldCloseOnBlur = true;
  }

  onBlur(eventType: Constants.EventType, event: any) {
    this.dropdownElement?.classList.add('outline-focus');

    // after change onMouseDown to onClick for menu item need not to close dropdown.
    // header menu items have tabindex and this onBlur method fires before item onClick handler
    if (this.isHoverEnabled() || this.isEventOnSearch(event)) {
      return;
    }

    if (this.shouldCloseOnBlur) {
      this.closeDropDown(eventType);

      if (this.props.onBlurHandle) {
        this.props.onBlurHandle();
      }
    }

    // reset to true everytime dropdown loses focues
    this.shouldCloseOnBlur = true;
  }

  isHoverEnabled() {
    return (
      (this.props.hoverEnabled && this.userInteractionType === Constants.UserInteractionType.Mouse) || !this.props.isDisabled
    );
  }

  isSearchEnabled() {
    return (this.props.searchEnabled || false) && !this.props.isDisabled;
  }

  isEventOnSearch(event: any) {
    if (event.type === Constants.EventType.Click) {
      return this.isSearchEnabled() && event.target.tagName === Constants.HTMLElements.INPUT;
    } else if (event.type === Constants.EventType.Blur) {
      return (
        this.isSearchEnabled() &&
        event.currentTarget.contains(event.relatedTarget) &&
        (event.relatedTarget.tagName === Constants.HTMLElements.INPUT || Constants.HTMLElements.BUTTON)
      );
    } else {
      return false;
    }
  }

  shouldSkipEventHandle(eventType: Constants.EventType) {
    return isHoverEvent(eventType) && !this.isHoverEnabled();
  }

  removeTabIndex() {
    if (this.lastFocusableIndex !== -1 && this.lastFocusableGroupIndex !== -1) {
      const previousMenu = document.getElementsByClassName('richTextDropDown')[this.lastFocusableGroupIndex] as HTMLElement;
      if (previousMenu) {
        const previousMenuItem = previousMenu.getElementsByClassName('c-menu-item')[this.lastFocusableIndex] as HTMLElement;
        if (previousMenuItem) {
          previousMenuItem.removeAttribute('tabIndex');
        }
      }
    }
  }

  resetFocus(targetIndex: number) {
    this.removeTabIndex();
    const richMenus: HTMLCollection = document.getElementsByClassName('richTextDropDown');
    if (richMenus) {
      for (let i = 0; i < richMenus.length; i++) {
        if (richMenus[`${i}`].getAttribute('aria-expanded') === 'true') {
          this.lastFocusableGroupIndex = i;
          break;
        }
      }
    }
    this.lastFocusableIndex = targetIndex;
    const currentMenu = document.getElementsByClassName('richTextDropDown')[this.lastFocusableGroupIndex] as HTMLElement;
    if (currentMenu) {
      const currentMenuItem = currentMenu.getElementsByClassName('c-menu-item')[`${targetIndex}`] as HTMLElement;
      if (currentMenuItem) {
        currentMenuItem.setAttribute('tabIndex', '0');
        currentMenuItem.focus();
      }
    }
  }

  onKeyDown(event: React.KeyboardEvent<Element>, eventType: Constants.EventType) {
    let isEventDefaultEnabled = false;
    const options = this.props.options;
    const optionLength = this.props.options.length;

    if (this.props.isDisabled) return;

    switch (event.keyCode) {
      case Constants.SystemKey.Up:
        if (event.altKey) {
          this.toggleOpen(eventType);
        } else {
          if (!this.state.isOpen) {
            let index = getOptionIndex(options, this.state.value);
            index = index >= 0 ? index : 0;
            const prevItemIndex = (index - 1 + optionLength) % optionLength;
            const value = options[`${prevItemIndex}`];
            this.setState({ value: value });
            this.props.onChangeHandle(value, options);
          } else {
            this.shouldCloseOnBlur = false;

            if (!this.state.highLightOption) {
              this.setState({ highLightOption: options[optionLength - 1] });
            } else {
              const itemIndex = getOptionIndex(options, this.state.highLightOption);
              const prevItemIndex = (itemIndex - 1 + optionLength) % optionLength;
              this.setState({ highLightOption: options[`${prevItemIndex}`] });
              this.resetFocus(prevItemIndex);
            }
          }
        }
        break;
      case Constants.SystemKey.Down:
        if (event.altKey) {
          this.toggleOpen(eventType);
        } else {
          if (!this.state.isOpen) {
            let index = getOptionIndex(options, this.state.value);
            index = index >= 0 ? index : 0;
            const nextItemIndex = (index + 1) % optionLength;
            const value = options[`${nextItemIndex}`];
            this.setState({ value: value });
            this.props.onChangeHandle(value, options);
          } else {
            this.shouldCloseOnBlur = false;
            if (!this.state.highLightOption) {
              this.setState({ highLightOption: options[0] });
            } else {
              const itemIndex = getOptionIndex(options, this.state.highLightOption);
              const nextItemIndex = (itemIndex + 1) % optionLength;
              this.setState({ highLightOption: options[`${nextItemIndex}`] });
              this.resetFocus(nextItemIndex);
            }
          }
        }
        break;
      case Constants.SystemKey.Enter:
      case Constants.SystemKey.Space:
        this.toggleOpen(eventType);
        break;
      case Constants.SystemKey.Esc:
        this.dropdownElement?.focus();
        this.closeDropDown(eventType);
        break;
      case Constants.SystemKey.Tab:
        isEventDefaultEnabled = true;
        this.dropdownElement?.focus();
        this.closeDropDown(eventType);
        break;
    }

    if (!isEventDefaultEnabled && !this.isSearchEnabled()) {
      event.preventDefault();
    }
  }

  onDropdownClicked(eventType: Constants.EventType, event: any) {
    if (this.state.isOpen && !this.isEventOnSearch(event)) {
      this.closeDropDown(eventType);
    } else if (!this.props.isDisabled) {
      this.openDropDown(eventType);
    }

    if (eventType === Constants.EventType.Click) {
      this.userInteractionType = Constants.UserInteractionType.Mouse;
    }
  }

  onOptionClicked(option: IRichTextDropDownItem<T>, eventType: Constants.EventType) {
    this.setState({ value: option });
    this.props.onChangeHandle(option, this.props.options);
    this.closeDropDown(eventType);
  }

  toggleOpen(eventType: Constants.EventType) {
    if (!this.state.isOpen) {
      this.openDropDown(eventType);
    } else {
      if (this.state.highLightOption) {
        this.onOptionClicked(this.state.highLightOption, eventType);
      }
      this.dropdownElement?.focus();
      this.closeDropDown(eventType);
    }
  }

  sortOptions(options: IRichTextDropDownItem<T>[]) {
    const sortedOptions = options.sort((a: IRichTextDropDownItem<T>, b: IRichTextDropDownItem<T>) => {
      const titleA = a.title.toUpperCase();
      const titleB = b.title.toUpperCase();
      return titleA < titleB ? 1 : titleA > titleB ? -1 : 0;
    });
    return sortedOptions;
  }

  renderImpl() {
    const labelPrefix =
      this.props.dropdownLabel ||
      (this.dropdownElement && this.dropdownElement.innerText ? this.dropdownElement.innerText.split('\n')[0].trim() : '');
    let options = !this.isSearchEnabled() ? this.props.options : getFilteredOptions(this.props.options, this.state.searchValue);
    options = this.props.isSorted ? this.sortOptions(options) : options;
    const dropdownClass = classNames({
      [getContainerClassName(this.props.className)]: true,
      disabledDropdown: this.props.isDisabled,
    });
    const selectedOption = this.state.value ? this.state.value.text + ' selected' : this.context.loc('Menu_Aria_Label', 'Menu');
    return (
      <div
        className={dropdownClass}
        onBlur={(event) => this.onBlur(event.type as Constants.EventType, event)}
        onTouchStart={() => (this.userInteractionType = Constants.UserInteractionType.Touch)}
        onClick={(event) => this.onDropdownClicked(event.type as Constants.EventType, event)}
        onMouseEnter={(event) => this.openDropDown(event.type as Constants.EventType)}
        onMouseLeave={(event) => this.closeDropDown(event.type as Constants.EventType)}
        onKeyDown={(event) => this.onKeyDown(event, event.type as Constants.EventType)}
        suppressContentEditableWarning={true}
        tabIndex={0}
        aria-expanded={this.state.isOpen}
        aria-label={getContainerAriaLabel(labelPrefix) + ' ' + selectedOption}
        ref={(element) => (this.dropdownElement = element)}
        role={CONTAINER_ROLE}
        aria-haspopup="listbox"
      >
        {!this.state.isOpen || (this.state.isOpen && !this.isSearchEnabled()) ? (
          <a className="valueBox" aria-hidden={true}>
            {this.renderValueMethod(this.state.value)}
            <div className="toggle" aria-hidden={true}>
              <span className="c-glyph"></span>
            </div>
          </a>
        ) : (
          <SearchBox
            placeholder={this.context.loc('Search_Placeholder')}
            value={this.state.searchValue}
            onEscape={(event) => this.closeDropDown(event.type as Constants.EventType)}
            onClear={() => {
              this.setState({ searchValue: '' });
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, newValue: string) => this.setState({ searchValue: newValue })}
            onSearch={(newValue) => this.setState({ searchValue: newValue })}
            disableAnimation
            disabled={this.props.isDisabled}
            componentRef={(element) => (this.searchBoxElement = element)}
          />
        )}
        {this.state.isOpen && (
          <ul className="c-menu" role="listbox">
            {options.map((option: IRichTextDropDownItem<T>, index: number) => (
              <li
                key={index}
                role="option"
                className={getOptionClassName(option, this.state.highLightOption)}
                onClick={(event) => this.onOptionClicked(option, event.type as Constants.EventType)}
                onTouchEnd={(event: React.TouchEvent<Element>) => this.onOptionClicked(option, event.type as Constants.EventType)}
                onMouseEnter={() => this.setState({ highLightOption: option })}
                aria-selected={isOptionSelected(option, this.state.highLightOption)}
              >
                <div className="value">{this.renderOptionMethod(option)}</div>
                <div className="placeholder"></div>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

(RichTextDropDown as any).contextTypes = {
  loc: PropTypes.func,
  renderErrorModal: PropTypes.func,
};
