import * as React from 'react';
import * as PropTypes from 'prop-types';
import SpzaComponent from './../spzaComponent';
import { logClientError } from '../../utils/appUtils';

interface IErrorModalProps {
  dismissModal: () => void;
  localizedStrings?: any;
  isEmbed?: boolean;
}

export class ErrorModal extends SpzaComponent<IErrorModalProps, any> {
  constructor(props: IErrorModalProps, context: any) {
    super(props, context);
  }

  renderFailImpl(err: any): any {
    // We don't want error modal while trying to render error modal since it goes into a loop.
    // So, we are seperately handling the error case.
    logClientError(err, 'client side error: [From errorModal] ');
    return null;
  }

  // Error modal is rendered from the main client hookup
  // It may not have context hence the localization is handled here via localizedStrings props
  translate(key: string, fallback: string) {
    let context = this.context as any;
    if (context && context.loc) {
      return context.loc(key, fallback);
    } else if (this.props.localizedStrings) {
      let translated = this.props.localizedStrings[key];
      translated = translated ? translated : fallback ? fallback : key;
      return translated;
    }
  }

  renderImpl() {
    return (
      <div role="dialog" aria-label="error is now open. something went wrong" className="errorModalClass">
        <div className="prompContainer">
          <div className="toolBar">
            <button id="cancelButton" aria-label="close" className="cancel" onClick={this.props.dismissModal}>
              <span className="c-glyph"></span>
            </button>
          </div>
          <div className="errorModal">
            <div className="contentHeader">
              {this.props.isEmbed
                ? this.translate('ERROR_Title_Embed', 'Sorry, an error occurred.')
                : this.translate('ERROR_Title', 'Something went wrong')}
            </div>
            <div className="errorContent">
              <div className="errorMessage">
                {this.props.isEmbed
                  ? this.translate('ERROR_Subtitle_Embed', 'Please try again.')
                  : this.translate('ERROR_Subtitle', 'Go to the homepage to try that again.')}
              </div>
              <div className="dismissErrorButton">
                <button name="button" className="c-button" type="submit" onClick={this.props.dismissModal}>
                  {this.props.isEmbed ? this.translate('Dialog_Close', 'Close') : this.translate('ERROR_Link', 'Home')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

(ErrorModal as any).contextTypes = {
  loc: PropTypes.func,
};
