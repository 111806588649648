import { connect } from 'react-redux';
import { IState } from '@src/State';
import { Constants } from '@shared/utils/constants';
import { CuratedGallery as CuratedGalleryComponent } from '@shared/components/curatedGallery';
import { parseCuratedSections } from '@shared/utils/hashMapUtils';
import { DataMap, IDataValues } from '@shared/utils/dataMapping';
import { getEntityDataState } from '@shared/utils/entityRegistration';

// controls the curation header size in case of L3 level curation like in case of industries
let renderL3LevelCuration = false;

function getFilterFromQueryParam(query: any): string {
  if (!query) {
    return null;
  }
  renderL3LevelCuration = false;

  // For products, if there is an L3 filter we want to show the curated view if available
  if (query[Constants.filterTileTypes.product]) {
    let bottomLevelProductFilter: IDataValues;
    (typeof query[Constants.filterTileTypes.product] === 'string' ? query[Constants.filterTileTypes.product] : '')
      .split(';')
      .forEach((filter: string) => {
        const activeFilter: IDataValues = DataMap.products[`${filter}`];
        if (activeFilter && !!bottomLevelProductFilter && activeFilter.Level > bottomLevelProductFilter.Level) {
          bottomLevelProductFilter = activeFilter;
        }

        if (activeFilter && !bottomLevelProductFilter) {
          bottomLevelProductFilter = activeFilter;
        }
      });

    if (bottomLevelProductFilter) {
      renderL3LevelCuration = !bottomLevelProductFilter.ShortcutFilters;
      return bottomLevelProductFilter.BackendKey;
    }
  }

  if (query[Constants.filterTileTypes.category] && DataMap.categories[query[Constants.filterTileTypes.category]]) {
    return DataMap.categories[query[Constants.filterTileTypes.category]][0].BackendKey;
  }
  if (query[Constants.filterTileTypes.industry] && DataMap.industries[query[Constants.filterTileTypes.industry]]) {
    renderL3LevelCuration = true;
    return DataMap.industries[query[Constants.filterTileTypes.industry]].BackendKey;
  }
  return Constants.Curated.everything;
}

const mapStateToProps = (state: IState, ownProps: any) => {
  const category = getFilterFromQueryParam(ownProps.category);
  const entityDataState = getEntityDataState({ state, entityType: ownProps.entityType });
  const { curatedData } = entityDataState;
  const section =
    curatedData[`${category}`] || curatedData[category.toLowerCase()]
      ? curatedData[`${category}`] || curatedData[category.toLowerCase()]
      : curatedData[Constants.Curated.everything];
  const { whatsNewApps, trendingApps } = state.apps.recommendedApps;
  const { whatsNewService, trendingService } = state.services.recommendedServices;
  const showRecommendations = state.config.featureFlags.recommendations;

  return {
    entityType: ownProps.entityType,
    sections: parseCuratedSections(section, entityDataState.dataList, entityDataState.idMap),
    isMobile: state.config.isMobile,
    selectedLocale: state.config.locale,
    renderL3CurationStyle: renderL3LevelCuration,
    selectedCountry: state.services ? state.services.selectedCountry : null,
    recoWhatsNewItems:
      showRecommendations &&
      (ownProps.entityType === Constants.EntityType.App
        ? whatsNewApps
        : ownProps.entityType === Constants.EntityType.Service
        ? whatsNewService
        : []),
    recoTrendingItems:
      showRecommendations &&
      (ownProps.entityType === Constants.EntityType.App
        ? trendingApps
        : ownProps.entityType === Constants.EntityType.Service
        ? trendingService
        : []),
  };
};

const CuratedGallery = connect(mapStateToProps, null)(CuratedGalleryComponent);

export default CuratedGallery;
