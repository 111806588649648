import * as React from 'react';
import * as PropTypes from 'prop-types';
import SpzaComponent from './spzaComponent';
import {
  IUserFavouritePostPayload,
  IUserFavouriteTileDetailButtonProps,
  IUserFavouriteTileDetailButtonState,
} from '@shared/interfaces/userFavouriteModels';
import { ITelemetryData } from '@shared/Models';
import { SpzaInstrumentProvider, SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { IBuildHrefContext, ILocContext, ILocParamsContext, ICommonContext } from '@shared/interfaces/context';
import { Constants } from '@shared/utils/constants';
import { getUserFavouriteEntity } from '@shared/utils/userFavouriteUtils';
import { getWindow } from '@shared/services/window';
import { ActionButton } from '@fluentui/react';
import type { IIconProps } from '@fluentui/react';
import { logger } from '@src/logger';

const favoriteStarHollow: IIconProps = { iconName: 'FavoriteStar' };
const favoriteStarFilled: IIconProps = { iconName: 'FavoriteStarFill' };

export class UserFavouriteTileDetailButton extends SpzaComponent<
  IUserFavouriteTileDetailButtonProps,
  IUserFavouriteTileDetailButtonState
> {
  context: ILocContext & ILocParamsContext & IBuildHrefContext & ICommonContext;
  private instrument: SpzaInstrumentProvider;

  constructor(props: IUserFavouriteTileDetailButtonProps, context: ILocContext & ICommonContext & IBuildHrefContext) {
    super(props, context);

    this.instrument = SpzaInstrumentService.getProvider();
    this.state = {
      // this state property is to fix the flicker when user (un)save an favourite, due to the delay of a REST call to server
      // makes user think (un)save is an immediate action to effect,
      // this is done by changing the classname before the REST call, and reset the classname after REST call
      // in order to change and change it back, we need to record the user action
      itemModifyStatus: Constants.UserFavourite.DataItemModifyStatus.None,
    };

    this.onClickUserFavouriteButton = this.onClickUserFavouriteButton.bind(this);
  }

  componentDidMount() {
    this.props.fetchUserFavouriteData();
  }

  setFavouriteItemModifyComplete() {
    this.setState({
      itemModifyStatus: Constants.UserFavourite.DataItemModifyStatus.Done,
    });
  }

  onClickUserFavouriteButton() {
    const userFavouriteEntity = getUserFavouriteEntity(this.props.userFavourite, this.props.entityId);

    const upsertPayload: IUserFavouritePostPayload = {
      applicationType: this.props.entityType,
      applicationId: this.props.entityId,
    };

    // telemetry
    const details = userFavouriteEntity
      ? {
          action: Constants.Telemetry.Action.Delete,
          userFavouriteInfo: {
            ...userFavouriteEntity,
          },
        }
      : {
          action: Constants.Telemetry.Action.Upsert,
          userFavouriteInfo: {
            ...upsertPayload,
          },
        };

    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.UserFavouriteTileDetailButton,
      details: JSON.stringify(details),
    };

    this.instrument.probe<ITelemetryData>(Constants.Telemetry.ProbeName.LogInfo, payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });

    // toggle user favourite on entity
    if (userFavouriteEntity) {
      // Delete a favourite
      // save the user action
      // display saved result beforehand
      this.setState({
        itemModifyStatus: Constants.UserFavourite.DataItemModifyStatus.Delete,
      });

      // REST DELETE
      this.props
        .deleteFavourite(userFavouriteEntity.entityGuid, userFavouriteEntity.applicationType)

        // TO-DO: cannot use .finally() on promise
        .then(() => {
          this.setFavouriteItemModifyComplete();
        })
        .catch(() => {
          this.setFavouriteItemModifyComplete();
        });
    } else {
      // Upsert a favourite
      // save the user action
      // display saved result beforehand
      this.setState({
        itemModifyStatus: Constants.UserFavourite.DataItemModifyStatus.Upsert,
      });

      // REST POST
      this.props
        .savefavourite({ ...upsertPayload }, this.props.item)

        // TO-DO: cannot use .finally() on promise
        .then(() => {
          this.setFavouriteItemModifyComplete();
        })
        .catch(() => {
          this.setFavouriteItemModifyComplete();
        });
    }
  }

  renderImpl() {
    const hasUserSavedFavourite = !!getUserFavouriteEntity(this.props.userFavourite, this.props.entityId);
    const itemModifyStatus = this.state.itemModifyStatus;
    const shouldShowSavedStatus =
      (hasUserSavedFavourite &&
        (itemModifyStatus === Constants.UserFavourite.DataItemModifyStatus.Done ||
          itemModifyStatus === Constants.UserFavourite.DataItemModifyStatus.None)) ||
      (!hasUserSavedFavourite && itemModifyStatus === Constants.UserFavourite.DataItemModifyStatus.Upsert);

    const title = shouldShowSavedStatus
      ? this.context.loc('UserFavourite_TileDetailButton_Saved')
      : this.context.loc('UserFavourite_TileDetailButton_Save');

    return (
      this.props.userInfo.signedIn && (
        <ActionButton
          iconProps={hasUserSavedFavourite ? favoriteStarFilled : favoriteStarHollow}
          allowDisabledFocus
          onClick={this.onClickUserFavouriteButton}
          className={this.props.className}
        >
          {title}
        </ActionButton>
      )
    );
  }
}

(UserFavouriteTileDetailButton as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  buildHref: PropTypes.func,
  renderErrorModal: PropTypes.func,
};
