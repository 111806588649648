import * as httpProtocol from './httpProtocol';
import { logError } from '@shared/utils/httpClientUtil';
import { CustomerType, IBillableAccount, ICustomer, ICustomerAddress, IPublisherOfferResponse } from '@shared/Models';
import { getAppConfig } from '@shared/services/init/appConfig';
import { IConfigState } from '@src/State';
import { Constants } from '@shared/utils/constants';
import { logger } from '@src/logger';
import { getStatusCodeFromError } from '@src/server/apiUtils/ODataError';
import { flightingThreshold } from '@shared/utils/flightingControl';

const option: httpProtocol.IHttpOption = {
  requireCorrelationId: true,
  authenticationType: httpProtocol.AuthenticationTypes.Spza,
  retry: 3,
};

/**
 * Returns customer object with address and other customer information.
 *
 * @export
 * @returns {Promise<ICustomer[]>}
 */
export async function getCustomers(): Promise<ICustomer[]> {
  const endpoint = '/view/customers';

  return httpProtocol
    .get(endpoint, option)
    .request()
    .catch((error: any) => {
      logError('purchaseRestClient.getCustomersMPAPI', error);
      return Promise.reject(error);
    });
}

/**
 * Creates a new object with the data that's passed in.
 *
 * @export
 * @param {ICustomer} customerRequest
 * @returns
 */
export function createCustomer(customer: ICustomer): Promise<ICustomer> {
  const endpoint = '/view/customer';

  const post = httpProtocol.post(endpoint, option);

  return post
    .setData(customer)
    .request()
    .catch((error: any) => {
      logError('purchaseRestClient.createCustomer', error);
      return Promise.reject(error);
    });
}

/**
 * Updates the customer address with the data that's passed in.
 *
 * @export
 * @param {ICustomer} customerRequest
 * @returns
 */
export function updateCustomer(address: ICustomerAddress) {
  const endpoint = '/view/address';

  const post = httpProtocol.post(endpoint, option);

  return post
    .addQueryEntry('version', getAppConfig('apiVersion'))
    .setData(address)
    .request()
    .catch((error: any) => {
      logError('purchaseRestClient.updateCustomer', error);
      return Promise.reject(error);
    });
}

/**
 * Gets publisher offer details for the subscription
 *
 * @export
 * @param {subscriptionId} request
 * @returns
 */
export function getPublisherOfferInfo(subscriptionId: string): Promise<IPublisherOfferResponse> {
  const endpoint = '/view/publisherOfferInfo';

  const post = httpProtocol.post(endpoint, option);

  return post
    .addQueryEntry('version', getAppConfig('apiVersion'))
    .setData({ subscriptionId: subscriptionId })
    .request()
    .catch((error: any) => {
      logError('purchaseRestClient.publisherOfferInfo', error);
      return Promise.reject(error);
    });
}
