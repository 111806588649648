import React from 'react';
import { AppReviewCommentItem } from '@shared/components/appReviewCommentItem';
import { mergeStyleSets, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { Constants } from '@shared/utils/constants';
import { NeutralColors } from '@fluentui/theme';
import { IAppReviewComment, IAppReviewCommentsState } from '@shared/interfaces/reviews/comments';
import { AppReviewItemCommentPagination } from '@shared/components/appReviewCommentCollection/appReviewItemCommentPagination';
import { AppReviewItemCommentEmptyMessage } from '@shared/components/appReviewCommentCollection/appReviewItemCommentEmptyMessage';

const contentStyles = mergeStyleSets({
  commentsContainer: {
    marginBottom: '20px',
  },
  paginationContainer: {
    marginTop: '10px',
  },
  emptyMessage: {
    margin: '15px 0',
    color: NeutralColors.gray130,
  },
  loader: {
    margin: '25px 0',
  },
});

export interface AppReviewItemCommentCollectionListSectionProps {
  comments: IAppReviewCommentsState;
  currentPage: number;
  locale: string;
  totalPageCount: number;
  onSeeMoreClick: () => void;
  onSeeLessClick: () => void;
  onCommentDelete: (commentId: string, isNewlyAddedComment: boolean) => void;
  onCommentEdit: (commentId: string, updatedContent: string, isNewlyAddedComment: boolean) => void;
}

export function AppReviewItemCommentCollectionListSection({
  comments,
  currentPage,
  locale,
  totalPageCount,
  onSeeMoreClick,
  onSeeLessClick,
  onCommentDelete,
  onCommentEdit,
}: AppReviewItemCommentCollectionListSectionProps) {
  const mapCommentsToComponents = ({ reducedCommentIds, commentsById }: IAppReviewCommentsState) => {
    return reducedCommentIds.slice(0, currentPage * Constants.Reviews.CommentsPerPage).map((commentId) => (
      <AppReviewCommentItem
        onEdit={(updatedContent) => {
          onCommentEdit(commentId, updatedContent, false);
        }}
        onDelete={() => {
          onCommentDelete(commentId, false);
        }}
        key={commentId}
        locale={locale}
        comment={commentsById[`${commentId}`]}
      />
    ));
  };

  const mapNewlyAddedCommentsToComponents = (comments: IAppReviewComment[]) => {
    return comments.map((comment) => (
      <AppReviewCommentItem
        onEdit={(updatedContent) => onCommentEdit(comment.id, updatedContent, true)}
        onDelete={() => onCommentDelete(comment.id, true)}
        key={comment.id}
        locale={locale}
        comment={comment}
      />
    ));
  };

  return (
    <div className={contentStyles.commentsContainer}>
      {comments?.reducedCommentIds.length
        ? [
            ...mapCommentsToComponents(comments),
            <AppReviewItemCommentPagination
              key="pagination"
              currentPage={currentPage}
              totalPageCount={totalPageCount}
              onSeeMoreClick={() => onSeeMoreClick()}
              onSeeLessClick={() => onSeeLessClick()}
            />,
          ]
        : !comments?.isLoading && <AppReviewItemCommentEmptyMessage />}
      {comments?.isLoading && (
        <Stack horizontal className={contentStyles.loader}>
          <Spinner size={SpinnerSize.large} />
        </Stack>
      )}
      {comments?.newlyAddedComments ? mapNewlyAddedCommentsToComponents(comments.newlyAddedComments) : null}
    </div>
  );
}
