import * as path from 'path';
import * as fs from 'fs';
import { Clients } from './constants';

declare const __COMMIT_HASH__: string;

const isIIS = process.env.isIIS === 'true';
const appDirectory = !isIIS ? process.cwd() : fs.realpathSync('../../');
const resolveApp = (relativePath: string) => path.resolve(appDirectory, relativePath);
const clientDirectory = 'client';
const serverDirectory = 'server';
const clientBuild = resolveApp(`build/${clientDirectory}`);
const serverBuild = resolveApp(`build/${serverDirectory}`);

interface WebpackPaths {
  appHtml: string;
  clientDirectory: string;
  clientBuild: string;
  serverBuild: string;
  dotenv: string;
  src: string;
  srcSSRApp: string;
  srcAppsourceClient: string;
  srcAppsourceServer: string;
  srcAppsourceRoot: string;
  srcEmbedHostEntry: string;
  srcEmbedEntry: string;
  srcServerRoot: string;
  srcShared: string;
  types: string;
  locales: string;
  buildLocales: string;
  publicStoragePath: (args: { hash?: string; name?: Clients }) => string;
  dllLoggerPath: string;
  embedHostServer: string;
  clientManifestFile: (args: { name: Clients }) => string;
  clientStatsFile: (args: { name: Clients }) => string;
  serverManifestFile: () => string;
  externalArtifacts: string;
  serverDotenvFiles: string;
  serverDotenvBundleFiles: () => string;
  localizationFiles: string;
}

const os = process.env.DOCKER ? 'linux' : '';
const publicStoragePath = ({ hash = __COMMIT_HASH__, name }: { hash?: string; name: Clients }) =>
  ['staticstorage', os, hash, name].filter(Boolean).join('/');

const paths: WebpackPaths = {
  appHtml: resolveApp('config/webpack.config.ts/template.html'),
  clientDirectory,
  clientBuild,
  serverBuild,
  dotenv: resolveApp('.env'),
  src: resolveApp('src'),
  srcSSRApp: resolveApp('src/server/utils/ssr/components/App.ssr.tsx'),
  srcAppsourceClient: resolveApp('src/appsource/mainClientHookupWrapper.tsx'),
  srcAppsourceServer: resolveApp('src/server/appsource/server.ts'),
  srcAppsourceRoot: resolveApp('src/appsource'),
  srcEmbedHostEntry: resolveApp('src/server/embedHost/mainClientHookup.tsx'),
  srcEmbedEntry: resolveApp('src/embed/mainClientHookupWrapper.tsx'),
  srcServerRoot: resolveApp('src/server/'),
  srcShared: resolveApp('src/shared'),
  types: resolveApp('node_modules/@types'),
  locales: resolveApp('src/loc'),
  buildLocales: resolveApp(`${serverBuild}/locales`),
  localizationFiles: resolveApp('src/localization/translations'),
  publicStoragePath,
  dllLoggerPath: resolveApp(`${serverBuild}/Microsoft.SaaSMarketPlace.Common.dll`),
  embedHostServer: resolveApp(`${serverBuild}/embedHost/index.html`),
  clientManifestFile: ({ name }): string => resolveApp(`${clientBuild}/${publicStoragePath({ name })}/manifest.json`),
  clientStatsFile: ({ name }): string => resolveApp(`${clientBuild}/${publicStoragePath({ name })}/stats.json`),
  serverManifestFile: () => resolveApp(`${serverBuild}/manifest.json`),
  externalArtifacts: resolveApp('external_artifacts'),
  serverDotenvFiles: resolveApp(`${serverBuild}/config`),
  serverDotenvBundleFiles: () => path.resolve(path.join(__dirname, '..', 'config')),
};

export default paths;
