import { logger } from '@src/logger';
import { ICampaign, ICampaigns, ITelemetryData } from './../Models';
import { IRoutes } from '@shared/routerHistory';
import { Constants } from './constants';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { stringifyError } from './errorUtils';

// special params which are also campaign ones
export const specialParams = [
  'debug',
  'preview',
  'embedHost',
  'fullScreenMode',
  Constants.QueryStrings.includeTestProducts,
  Constants.QueryStrings.isConverged,
];
export const campaignParams = [
  'mktcmpid',
  'mktvid',
  'ref',
  'src',
  'campaignCorrelationId',
  'embedCorrelationId',
  'mkt_tok',
  'utm_source',
  'utm_medium',
  'utm_term',
  'utm_content',
  'utm_campaign',
  'source',
  'placement',
  'ad_id',
  'adset_id',
  'campaign_id',
  'ad_name',
  'adset_name',
  'campaign_name',
  'fb',
  'ig',
  'msg',
  'an',
  'ocid',
];
export const excludedCampaignsForAcquisition: [ICampaign] = [{ src: 'office' }];

interface ILogGetCampaignTelemetry {
  landingView: string;
  currentView: string;
  shouldSkipGetCampaigns: boolean;
  campaigns: ICampaigns;
  entityId: string;
  error: any;
}

const logGetCampaignTelemetry = ({
  landingView,
  currentView,
  shouldSkipGetCampaigns,
  campaigns,
  entityId,
  error,
}: ILogGetCampaignTelemetry) => {
  let detailText: string;

  if (error) {
    detailText =
      'Failed Generating Campaigns Before Passing To Lead, ' +
      `LANDING VIEW: ${landingView}, ` +
      `CURRENT VIEW: ${currentView}, ` +
      `ERROR: ${stringifyError(error)}`;
  } else if (shouldSkipGetCampaigns) {
    detailText =
      'Success Skipping Generating Campaigns Before Passing To Lead, ' +
      `LANDING VIEW: ${landingView}, ` +
      `CURRENT VIEW: ${currentView}, ` +
      `CAMPAIGNS: ${JSON.stringify(campaigns)}`;
  } else {
    detailText =
      'Success Generating Campaigns Before Passing To Lead, ' +
      `LANDING VIEW: ${landingView}, ` +
      `CURRENT VIEW: ${currentView}, ` +
      `CAMPAIGNS: ${JSON.stringify(campaigns)}`;
  }

  const payload: ITelemetryData = {
    page: currentView,
    action: Constants.Telemetry.Action.PublishAcquisitionInfo,
    actionModifier: Constants.Telemetry.ActionModifier.LeadCampaignGeneration,
    appName: entityId,
    details: detailText,
  };
  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
  logger.info(payload.details, {
    action: payload.action,
    actionModifier: payload.actionModifier,
    appName: entityId,
    page: payload.page,
  });
};

// returns the list of the valid route params for app view.
export const getAppViewParams = (): string[] => {
  return specialParams.concat(campaignParams);
};

function getParameterByName(param: string, url: string) {
  param = param.replace(/[\\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + param + '(=([^&#]*)|&|#|$)', 'i');
  const results = regex.exec(url);
  if (!results) {
    return null;
  }
  // 2 represents the value of the param.
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

// 1) we should always generate, after user redirects to a non-detail page from a campaign
// 2) we should generate current and only current, if user redirects to a detail page from a campaign
export const shouldGetCampaignsForAcquistion = (landingView: string, previousPage: string, routes: IRoutes<any>): boolean => {
  const isInitiaViewDetailPage: boolean = landingView === routes.appDetails.name || landingView === routes.serviceDetail.name;

  const isCurrentViewLandingView = !previousPage;

  return !isInitiaViewDetailPage || isCurrentViewLandingView;
};

function serializeCampaignObject(obj: any) {
  const str: string[] = [];
  for (const p in obj) {
    str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[`${p}`]));
  }
  return str.join('&');
}

export const extractCampaignsFromUrl = (url: string): ICampaigns => {
  const campaigns: ICampaigns = {};
  for (let i = 0; i < campaignParams.length; i++) {
    const campaignParam: string = getParameterByName(campaignParams[`${i}`], url);
    if (campaignParam) {
      campaigns[campaignParams[`${i}`]] = campaignParam;
    }
  }
  return campaigns;
};

export const excludeCampaignsForAcquisition = (campaigns: ICampaigns) => {
  const keys: string[] = Object.keys(campaigns);

  keys.forEach((key: string) => {
    const value: string = campaigns[`${key}`];

    const foundToDelete: boolean = excludedCampaignsForAcquisition.some((aExludeCampaign: ICampaign) => {
      const excludeKey: string = Object.keys(aExludeCampaign)[0];
      const excludeValue: string = aExludeCampaign[`${key}`];
      return key === excludeKey && value === excludeValue;
    });

    if (foundToDelete) {
      delete campaigns[`${key}`];
    }
  });
};

interface ICampaignTelemetryPayload {
  url: string;
  landingView: string;
  currentView: string;
  previousPage: string;
  routes: IRoutes<any>;
  entityId?: string;
}

export const getCampaignsForAcquisitionFromUrl = ({
  url,
  landingView,
  currentView,
  previousPage,
  routes,
  entityId,
}: ICampaignTelemetryPayload): ICampaigns => {
  try {
    if (!shouldGetCampaignsForAcquistion(landingView, previousPage, routes)) {
      logGetCampaignTelemetry({ landingView, currentView, shouldSkipGetCampaigns: true, campaigns: null, error: null, entityId });
      return null;
    }

    const campaigns: ICampaigns = extractCampaignsFromUrl(url);

    excludeCampaignsForAcquisition(campaigns);

    logGetCampaignTelemetry({ landingView, currentView, shouldSkipGetCampaigns: false, campaigns, error: null, entityId });
    return campaigns;
  } catch (error) {
    logGetCampaignTelemetry({ landingView, currentView, shouldSkipGetCampaigns: false, campaigns: null, error, entityId });

    return null;
  }
};

// This methods returns just the campaign params from the URL
// for e.g. if the URL is http://localhost:3000/en-us/home?debug=true&mktcmpid=foo&mktvid=bar
// the output would be mktcmpid=foo&mktvid=bar
function getCampaingQueryParamsFromUrl(url: string) {
  const campaignObj = extractCampaignsFromUrl(url);
  return serializeCampaignObject(campaignObj);
}

export function updateHandOffUrlWithCampaignParams(handoffUrl: string, pageUrl: string) {
  if (!handoffUrl) {
    return;
  }

  const params = getCampaingQueryParamsFromUrl(pageUrl);
  if (params) {
    if (handoffUrl.indexOf('?') >= 0) {
      handoffUrl = handoffUrl.replace('?', `?${params}&`);
    } else {
      handoffUrl = handoffUrl + '?' + params;
    }
  }
  return handoffUrl;
}

export const getEntityIdFromUrl = ({ pathName = '' }: { pathName: string }) => {
  let offerIdLocation = 0;
  let entityId = '';

  offerIdLocation = pathName.lastIndexOf('/');
  entityId = pathName.substring(offerIdLocation + 1).split('?')[0];

  return entityId;
};
