import React from 'react';
import { IconButton, Stack, TooltipDelay, TooltipHost } from '@fluentui/react';
import { ILocContext } from '@shared/interfaces/context';
import * as PropTypes from 'prop-types';

export interface IAppReviewCommentItemToolbarProps {
  onDeleteToggle: () => void;
  onEditToggle: () => void;
  className?: string;
  isEditing?: boolean;
}

export const AppReviewCommentItemToolbar: React.FunctionComponent<IAppReviewCommentItemToolbarProps> = (
  { onDeleteToggle, onEditToggle, className, isEditing = false }: IAppReviewCommentItemToolbarProps,
  context: ILocContext
) => {
  const icons = {
    delete: { iconName: 'Delete' },
    edit: { iconName: 'Edit' },
  };
  const tooltipCalloutProps = { gapSpace: 5 };
  return (
    <div className={className}>
      <Stack horizontal tokens={{ childrenGap: 4 }}>
        <TooltipHost
          content={context.loc('ReviewItem_CommentSection_Comment_Edit_Button_Tooltip', 'Edit your comment')}
          calloutProps={tooltipCalloutProps}
          delay={TooltipDelay.zero}
        >
          <IconButton
            iconProps={icons.edit}
            onClick={() => {
              !isEditing && onEditToggle();
            }}
            checked={isEditing}
            text={context.loc('ReviewItem_CommentSection_Comment_Edit_Button_Label', 'Edit')}
            ariaLabel={context.loc('ReviewItem_CommentSection_Comment_Edit_Button_Label', 'Edit')}
          />
        </TooltipHost>
        <TooltipHost
          content={context.loc('ReviewItem_CommentSection_Comment_Delete_Button_Tooltip', 'Delete your comment')}
          calloutProps={tooltipCalloutProps}
          delay={TooltipDelay.zero}
        >
          <IconButton
            iconProps={icons.delete}
            onClick={onDeleteToggle}
            text={context.loc('ReviewItem_CommentSection_Comment_Delete_Button_Label', 'Delete')}
            ariaLabel={context.loc('ReviewItem_CommentSection_Comment_Delete_Button_Label', 'Delete')}
          />
        </TooltipHost>
      </Stack>
    </div>
  );
};
(AppReviewCommentItemToolbar as any).contextTypes = {
  loc: PropTypes.func,
};
