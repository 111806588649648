import ProductsComponent from '@shared/components/products';
import { IBuildHrefContext, ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import * as PropTypes from 'prop-types';
import { IAppDataItem, IRatingData, IFilterIcon, ITelemetryData } from '@shared/Models';
import { DataMap, IProductValue } from '@shared/utils/dataMapping';
import { getProductsList } from '@shared/utils/detailUtils';
import { TelemetryImage } from '@shared/components/telemetryImage';
import { Stack, Text, mergeStyleSets, FontIcon, StackItem, Separator } from '@fluentui/react';
import type { IStackStyles, ISeparatorStyles } from '@fluentui/react';
import React, { Fragment } from 'react';
import classNames from 'classnames';
import { NeutralColors, SharedColors } from '@fluentui/theme';
import { logger } from '@src/logger';
import { Constants } from '@shared/utils/constants';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { routes } from '@shared/routerHistory';
import { Link, useLocation } from 'react-router-dom';
import type History from 'history';
import { IUserFavouriteItem } from '@shared/interfaces/userFavouriteModels';
import { RatingsInfo } from '@shared/components/ratings/ratingsInfo';

export interface IAppTileLinkedItemsProps {
  linkedItems?: IAppDataItem[];
  title?: string;
  hoveredItem?: IUserFavouriteItem;
}

interface ILinkedItemProps {
  linkedItem: IAppDataItem;
  hoveredItem?: IUserFavouriteItem;
}

const containerTokens = { childrenGap: 24, padding: 20 };
const itemsTokens = { childrenGap: 8 };
const itemsInsideLinkedTokens = { childrenGap: 8 };
const titleAndStarsStackToken = { childrenGap: 8 };
const parentStackTokens = { childrenGap: 8, padding: 8 };

const appTileLinkedItemsStyles = mergeStyleSets({
  itemImage: {
    width: 60,
    height: 60,
    display: 'flex',
    backgroundColor: NeutralColors.white,
  },
  textWeight: {
    fontWeight: 600,
  },
  linkItemTitle: {
    fontWeight: 600,
    color: NeutralColors.gray160,
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
  },
  linkItemContainer: {
    justifyContent: 'space-around',
  },
  moreProductsStyle: {
    paddingTop: 3,
  },
  imageStackItemStyle: {
    '& .appTileHoverImage': {
      width: 40,
      height: 40,
      margin: 'auto',
    },
  },
  starsAndRattingContainer: {
    '& .ratingInfoSection .detailsRatingStar': {
      marginBottom: 0,
    },
  },
  rattingText: {
    color: NeutralColors.gray130,
  },
  rattingStarText: {
    marginRight: 4,
    padding: 0,
    fontSize: 12,
  },
  iconClass: {
    height: 16,
    width: 16,
    color: SharedColors.cyanBlue10,
  },
});

const seperatorStyle: Partial<ISeparatorStyles> = {
  root: [
    {
      selectors: {
        '::before': {
          background: NeutralColors.gray40,
        },
      },
    },
  ],
};

const tileStyle: IStackStyles = {
  root: {
    selectors: {
      '&:hover': {
        backgroundColor: NeutralColors.gray10,
      },
    },
  },
};

const getProducts = (products: IProductValue): IFilterIcon[] => {
  return (products && Object.keys(products).length > 0 && getProductsList(products)) ?? [];
};

const getRatingsData = ({
  context,
  ratingsAverage,
  ratingsNum,
}: {
  context: ILocContext;
  ratingsAverage: number;
  ratingsNum: number;
}): IRatingData => {
  return (
    ratingsNum >= 1 && {
      avgRating: ratingsAverage,
      ratingsCount: ratingsNum,
      key: 'internal',
      text: context.loc('Tile_ASRatings', 'ratings'),
    }
  );
};

const getLinkedItemLink = ({ linkedItem, context }: { linkedItem: IAppDataItem; context: IBuildHrefContext }): string => {
  const { entityId, linkedSaaS } = linkedItem;

  const productId = linkedSaaS ? DataMap.products.AzureforWebApps.UrlKey : DataMap.products.Office365.UrlKey;

  return context.buildHref(
    routes.appDetails,
    {
      productId,
      entityId,
    },
    { tab: 'Overview' }
  );
};

const logTelemetryInfo = ({
  linkedItem,
  hoveredItem,
  location,
}: {
  linkedItem: IAppDataItem;
  hoveredItem: IUserFavouriteItem;
  location: History.Location;
}) => {
  const instrument = SpzaInstrumentService.getProvider();
  const { title, entityId } = linkedItem;
  const { title: hoveredTitle, entityId: hoveredEntityId } = hoveredItem;

  const payload: ITelemetryData = {
    page: location.pathname,
    action: Constants.Telemetry.Action.Click,
    actionModifier: Constants.Telemetry.ActionModifier.SaasLinkTooltip,
    details: JSON.stringify({ title, entityId, hoveredTitle, hoveredEntityId }),
  };
  instrument.probe<ITelemetryData>(Constants.Telemetry.ProbeName.LogInfo, payload);
  logger.info('', payload);
};

const LinkedItem: React.FunctionComponent<ILinkedItemProps> = (
  { linkedItem, hoveredItem }: ILinkedItemProps,
  context: ILocContext & ILocParamsContext & IBuildHrefContext
) => {
  const location = useLocation();
  const itemProducts = getProducts(linkedItem.products);
  const ratingsData = getRatingsData({
    context,
    ratingsAverage: linkedItem.AverageRating,
    ratingsNum: linkedItem.NumberOfRatings,
  });

  return (
    <Link
      to={getLinkedItemLink({ linkedItem, context })}
      onClick={() => {
        logTelemetryInfo({ linkedItem, hoveredItem, location });
      }}
    >
      <Stack tokens={parentStackTokens} styles={tileStyle}>
        <Stack.Item>
          <Stack horizontal tokens={itemsInsideLinkedTokens}>
            <Stack.Item className={classNames([appTileLinkedItemsStyles.imageStackItemStyle])} align="center">
              <TelemetryImage
                src={linkedItem.iconURL}
                alt={context.loc('Linked_Item_Icon', 'linked item icon')}
                className={classNames([appTileLinkedItemsStyles.itemImage], 'ms-depth-8')}
                imgClassName="appTileHoverImage"
              />
            </Stack.Item>
            <Stack.Item grow={1}>
              <Stack tokens={titleAndStarsStackToken} className={appTileLinkedItemsStyles.linkItemContainer}>
                <Stack.Item>
                  <Text variant="mediumPlus" block className={classNames([appTileLinkedItemsStyles.linkItemTitle])}>
                    {linkedItem.title}
                  </Text>
                </Stack.Item>
                {itemProducts?.length && (
                  <Stack.Item grow={1} className={classNames([appTileLinkedItemsStyles.moreProductsStyle])}>
                    <ProductsComponent textSize="smallPlus" withText productsItems={itemProducts} />
                  </Stack.Item>
                )}
                {ratingsData && (
                  <Stack.Item className={classNames([appTileLinkedItemsStyles.starsAndRattingContainer])}>
                    <RatingsInfo
                      avgRating={ratingsData.avgRating}
                      ratingsCount={ratingsData.ratingsCount}
                      key={ratingsData.key}
                      title={ratingsData.text}
                      context={context}
                      textSize="smallPlus"
                      starClass={classNames([appTileLinkedItemsStyles.rattingStarText])}
                      ratingClass={classNames([appTileLinkedItemsStyles.rattingText])}
                    />
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
            <Stack.Item align="center">
              <FontIcon iconName="ChevronRight" className={appTileLinkedItemsStyles.iconClass} />
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </Link>
  );
};

export const AppTileLinkedItems: React.FunctionComponent<IAppTileLinkedItemsProps> = ({
  title,
  linkedItems,
  hoveredItem,
}: IAppTileLinkedItemsProps) => {
  return (
    <Stack tokens={containerTokens}>
      <Stack.Item>
        {title && (
          <Text variant="medium" className={appTileLinkedItemsStyles.textWeight}>
            {title}
          </Text>
        )}
      </Stack.Item>
      <Stack.Item>
        <Stack tokens={itemsTokens}>
          {linkedItems?.map((item, index, linkedItems) => (
            <Fragment key={index}>
              <Stack.Item>
                <LinkedItem linkedItem={item} hoveredItem={hoveredItem} />
              </Stack.Item>
              {index + 1 !== linkedItems.length && (
                <Stack.Item>
                  <Stack>
                    <StackItem>
                      <Separator styles={seperatorStyle} />
                    </StackItem>
                  </Stack>
                </Stack.Item>
              )}
            </Fragment>
          ))}
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

(LinkedItem as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  buildHref: PropTypes.func,
};
