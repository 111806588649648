import { get, post, IHttpOption, AuthenticationTypes } from '@shared/services/http/httpProtocol';
import { EnrichMarkAsHelpfulResponse } from '@shared/interfaces/reviews/markAsHelpful';
import {
  GetUserReviewsMarkedAsHelpfulResult,
  SetUserReviewMarkedAsHelpfulResult,
} from '@shared/interfaces/reviews/markAsHelpful/models';

const commonHttpOptions: IHttpOption = {
  requireCorrelationId: true,
  stringifyPostData: false,
  authenticationType: AuthenticationTypes.Spza,
  setBearerHeader: true,
};

export const ReviewCommentsQueryStrings = {
  OfferId: 'offerId',
  ApiVersion: 'api-version',
};

export interface SetMarkedAsHelpfulArgs {
  offerId: string;
  reviewId: string;
  reviewsAPI: string;
  reviewsAPIVersion: string;
  isHelpful: boolean;
  source?: string;
}

/**
 * Toggles marked as helpful for a given review
 * @param Object containing named parameters for the call
 * @returns Promise with the response, both for success and failure
 */
export async function setReviewMarkedAsHelpful({
  offerId,
  reviewId,
  reviewsAPI,
  reviewsAPIVersion,
  isHelpful,
  source,
}: SetMarkedAsHelpfulArgs): Promise<SetUserReviewMarkedAsHelpfulResult> {
  try {
    const result = await (post(`${reviewsAPI}/${reviewId}/helpful`, commonHttpOptions)
      .addQueryEntry(ReviewCommentsQueryStrings.ApiVersion, reviewsAPIVersion)
      .addQueryEntry(ReviewCommentsQueryStrings.OfferId, offerId)
      .setData({ is_helpful: isHelpful, source })
      .request() as Promise<EnrichMarkAsHelpfulResponse>);
    return {
      result,
    };
  } catch (error) {
    return {
      error: error.response?.text || error,
    };
  }
}

/**
 * Get a list of review ids which the current user marked as helpful for a given offer
 * @param Object containing named parameters for the call
 * @returns Promise with the response, containing a list of comments for the given review
 */
export async function getUserReviewsMarkedAsHelpful({
  offerId,
  reviewsAPI,
  reviewsAPIVersion,
}: {
  offerId: string;
  reviewsAPI: string;
  reviewsAPIVersion: string;
}): Promise<GetUserReviewsMarkedAsHelpfulResult> {
  try {
    const reviewIds = await get(`${reviewsAPI}/helpful`, commonHttpOptions)
      .addQueryEntry(ReviewCommentsQueryStrings.ApiVersion, reviewsAPIVersion)
      .addQueryEntry(ReviewCommentsQueryStrings.OfferId, offerId)
      .request();
    return {
      reviewIds,
    };
  } catch (error) {
    return {
      error,
      reviewIds: [],
    };
  }
}
