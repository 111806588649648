/* eslint-disable react/prop-types */
import { useHydration } from '@shared/hooks/useHydration';
import React, { PropsWithChildren } from 'react';

interface GalleryContentProps {
  loadingComponent: JSX.Element;
  requestFilteredLoading: boolean;
}
const GalleryContent: React.FC<PropsWithChildren<GalleryContentProps>> = ({
  children,
  requestFilteredLoading,
  loadingComponent,
}) => {
  const isHydrated = useHydration();
  return isHydrated && requestFilteredLoading ? loadingComponent : <>{children}</>;
};

export default GalleryContent;
