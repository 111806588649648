import { IState } from '@src/State';
import { ITileData, ITelemetryData } from '@shared/Models';
import { isPricingFilter } from 'utils/filterHelpers';
import { routes } from '@shared/routerHistory';
import { Constants } from '@shared/utils/constants';
import { deepEqual } from '@shared/utils/objectUtils';
import { stringifyError } from '@shared/utils/errorUtils';
import {
  generateGuid,
  getActiveFeaturedFlags,
  getTileDataEntityType,
  getTileDataQueryParams,
  getProductByUrlKey,
} from '@shared/utils/appUtils';
import { createTileDataReceivedAction, createTileDataLoadingAction } from '@shared/actions/tileDataActions';
import {
  ExtendableRequest,
  getTileData,
  IGetTileDataParams,
  IGetTileDataParamsLog,
} from '@shared/services/http/entityRestClient';
import { getWindow } from '@shared/services/window';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';

import { createAppsPricingDataReceivedAction, createShowingResultsForReceivedAction } from '@shared/actions/actions';
import {
  createSetRequestFilteredEntityTypeAction,
  createSetRequestFilteredLoadedAction,
  createSetRequestFilteredLoadingAction,
  createSetRequestFilteredQueryAction,
  createSetResponseFilteredQueryAction,
} from './queryActions';
import { logger } from '@src/logger';

let prevTileDataRequest: ExtendableRequest<ITileData>;

function generateTileDataLog(requestParams: IGetTileDataParamsLog, tileData: ITileData, duration: number) {
  const details = {
    duration,
    requestParams,
    searchResponseId: tileData.apps.dataList[0]?.searchResponseId,
    appsResultsCount: tileData.apps && tileData.apps.count,
    appsTopResults: tileData.apps && tileData.apps.dataList && tileData.apps.dataList.slice(0, 20).map((app) => app.entityId),
    servicesResultsCount: tileData.services && tileData.services.count,
    servicesTopResults:
      tileData.services &&
      tileData.services.dataList &&
      tileData.services.dataList.slice(0, 20).map((service) => service.entityId),
  };

  const payload: ITelemetryData = {
    page: getWindow()?.location?.href,
    action: Constants.Telemetry.Action.TileData,
    actionModifier: Constants.Telemetry.ActionModifier.End,
    details: JSON.stringify(details),
  };

  SpzaInstrumentService.getProvider().probe<ITelemetryData>(Constants.Telemetry.ProbeName.LogInfo, payload);
  logger.info(payload.details, {
    action: payload.action,
    actionModifier: payload.actionModifier,
  });
}

export function loadTileData() {
  return function (dispatch: any, getState: () => IState): Promise<ITileData> {
    const start = Date.now();

    const currentView = getState().config.currentView;

    const isBrowseView =
      currentView === routes.marketplace.name ||
      currentView === routes.marketplaceServices.name ||
      currentView === routes.marketplaceCloudsIndustry.name ||
      currentView === routes.home.name;

    // Load tile data only in Borwses Apps views, like Home and Gallery
    if (!isBrowseView) {
      return Promise.resolve(null);
    }

    const currentEntityType = getState().config.isAppSource
      ? getTileDataEntityType(currentView)
      : Constants.TileDataEntityType.All;

    const currentFilteredQuery = getTileDataQueryParams(getState().config.query);
    const prevEntityType = getState().config.requestFilteredEntityType;
    const prevFilteredQuery = getState().config.requestFilteredQuery;

    if (deepEqual(currentFilteredQuery, prevFilteredQuery) && currentEntityType === prevEntityType) {
      return Promise.resolve(null);
    }

    dispatch(
      createSetRequestFilteredEntityTypeAction({
        requestFilteredEntityType: currentEntityType,
      })
    );

    dispatch(
      createSetRequestFilteredQueryAction({
        requestFilteredQuery: currentFilteredQuery,
      })
    );

    dispatch(createSetRequestFilteredLoadingAction());

    if (currentView !== routes.home.name) {
      dispatch(
        createTileDataLoadingAction({
          urlQuery: currentFilteredQuery,
        })
      );
    }

    const urlQuery = {
      ...currentFilteredQuery,
      ...getActiveFeaturedFlags(getState().config.featureFlags),
    };

    const requestParams: IGetTileDataParams = {
      requestId: generateGuid(),
      flightCodes: getState().config.flightCodes,
      embedHost: getProductByUrlKey({ urlKey: getState().config.embedHost })?.UrlKey,
      billingRegion: isPricingFilter(currentFilteredQuery) ? getState().config.billingCountryCode : '',
      urlQuery,
      entityType: currentEntityType,
    };

    prevTileDataRequest?.request()?.abort();

    prevTileDataRequest = getTileData(requestParams);

    return prevTileDataRequest.data$
      .then((tileData: ITileData) => {
        prevTileDataRequest = null;

        generateTileDataLog(
          { ...requestParams, origin: tileData.origin, suggestionsRequestId: getState().search.suggestionsRequestId },
          tileData,
          Date.now() - start
        );

        dispatch(createSetRequestFilteredLoadedAction());

        dispatch(createSetResponseFilteredQueryAction({ responseFilteredQuery: currentFilteredQuery }));

        dispatch(
          createTileDataReceivedAction({
            entityType: Constants.EntityType.App,
            entityData: tileData.apps,
            urlQuery: currentFilteredQuery,
            requestId: requestParams.requestId,
          })
        );
        dispatch(
          createTileDataReceivedAction({
            entityType: Constants.EntityType.Service,
            entityData: tileData.services,
            urlQuery: currentFilteredQuery,
            requestId: requestParams.requestId,
          })
        );
        dispatch(
          createTileDataReceivedAction({
            entityType: Constants.EntityType.CloudsIndustry,
            entityData: tileData.cloudsIndustry,
            urlQuery: currentFilteredQuery,
            requestId: requestParams.requestId,
          })
        );

        if (getState().apps.pricingPayload) {
          dispatch(createAppsPricingDataReceivedAction(getState().apps.pricingPayload));
        }

        if (getState().cloudsIndustry.pricingPayload) {
          dispatch(createAppsPricingDataReceivedAction(getState().cloudsIndustry.pricingPayload));
        }

        dispatch(
          createShowingResultsForReceivedAction({
            showingResultsFor: tileData.showingResultsFor,
            previousSearchText: currentFilteredQuery.search,
          })
        );

        return tileData;
      })
      .catch((err) => {
        prevTileDataRequest = null;

        const payload: ITelemetryData = {
          page: getWindow()?.location?.href,
          action: Constants.Telemetry.Action.TileData,
          actionModifier: Constants.Telemetry.ActionModifier.Error,
          details: JSON.stringify({
            duration: Date.now() - start,
            requestParams,
            error: stringifyError(err),
          }),
        };

        SpzaInstrumentService.getProvider().probe<ITelemetryData>(Constants.Telemetry.ProbeName.LogInfo, payload);
        logger.error(payload.details, {
          action: payload.action,
          actionModifier: payload.actionModifier,
        });
        throw err;
      });
  };
}
