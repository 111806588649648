import React, { PropsWithChildren } from 'react';

import { ITelemetryData } from '@shared/Models';
import { getWindow } from '@shared/services/window';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { Constants } from '@shared/utils/constants';

import { stringifyError } from '@shared/utils/errorUtils';
import { telemetryPageLoadTime } from '@shared/utils/pltUtils';
import { ensureClientLoggerInitialization, logger } from '@src/logger';
import { getNetworkStats } from '@shared/utils/appUtils';
import type { Store } from 'redux';
import { IState } from '@src/State';
import { initializeListener } from '@embed/embedMessaging';
import { initBusinessTelemetryClient } from '@shared/hooks/useTelemetry';

interface RootHandlerProps extends PropsWithChildren {
  isEmbedded?: boolean;
  landingView: string;
  store: Store<IState>;
}
export class RootHandler extends React.Component<RootHandlerProps> {
  componentDidMount(): void {
    const { store, landingView, isEmbedded } = this.props;

    telemetryPageLoadTime({ landingView, isEmbedded });
    ensureClientLoggerInitialization(store);
    initBusinessTelemetryClient();
    if (isEmbedded) {
      // This will register a listener for the javascript 'message' event that
      // the host window uses to communicate with the iframe
      initializeListener(store.dispatch, store.getState().config.embedHost);
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.telemetry(Constants.Telemetry.Action.RootHandler, Constants.Telemetry.ActionModifier.Error, {
      errorInfo: stringifyError(errorInfo),
      error: stringifyError(error),
    });
  }

  static getDerivedStateFromError(error: unknown) {
    // The error is logged here for dev mode (with babel) to catch the hydration mismatch stack trace
    console.error('React Error', error);
  }

  telemetry(action: string, actionModifier: string, details: Record<string, unknown>) {
    const { isEmbedded = false, landingView } = this.props;

    const connection = getNetworkStats();
    const userAgent = navigator.userAgent;

    const payload = {
      page: getWindow().location.href,
      action,
      actionModifier,
      details: JSON.stringify({ ...details, landingView, isEmbedded, connection, userAgent }),
    };
    SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });
  }

  flushTelemetry(): void {
    SpzaInstrumentService.getProvider().probe<ITelemetryData>('flushTelemetryInfo');
  }

  render() {
    return <>{this.props.children}</>;
  }
}
