import { ApplicationInsights } from '@microsoft/1ds-analytics-web-js';
import type { IExtendedConfiguration, IWebAnalyticsConfiguration } from '@microsoft/1ds-analytics-web-js';
import { AuthPlugin, AuthType } from '@microsoft/1ds-auth-js';
import type { IAuthenticationConfiguration } from '@microsoft/1ds-auth-js';
import * as appConfig from '@shared/services/init/appConfig';

import { PropertiesPlugin } from '@microsoft/1ds-properties-js';
import type { IPropertyConfiguration } from '@microsoft/1ds-properties-js';

import { CorrelationVectorPlugin } from '@microsoft/1ds-cv-js';
import { getWindow } from '@shared/services/window';
import { Constants, Environment, OneDSEnvs } from '@shared/utils/constants';
import { getGPCValue } from '@shared/utils/privacyUtils';

type IOneDsEnvMapping = {
  [key in Environment]: OneDSEnvs;
};

const envsMap: IOneDsEnvMapping = {
  [Environment.Production]: OneDSEnvs.Production,
  [Environment.Canary]: OneDSEnvs.Canary,
  [Environment.Development]: OneDSEnvs.Internal,
  [Environment.Dogfood]: OneDSEnvs.Dogfood,
  [Environment.Test]: OneDSEnvs.Test,
};

const getEnvName = () => {
  const siteEnvironment = getWindow()?.__INITIAL_STATE__?.config?.siteEnvironment?.toLowerCase() ?? process.env.siteEnvironment;
  const env = envsMap[`${siteEnvironment}`];
  if (!env) {
    throw new Error('failed to load env name for telemetry');
  }
  return env;
};

const initiate1DS = () => {
  const analytics: ApplicationInsights = new ApplicationInsights();
  const authPlugin: AuthPlugin = new AuthPlugin();
  const propertiesPlugin: PropertiesPlugin = new PropertiesPlugin();
  const cvPlugin: CorrelationVectorPlugin = new CorrelationVectorPlugin();

  const webAnalyticsConfig: IWebAnalyticsConfiguration = {
    urlCollectQuery: true,
    urlCollectHash: true,
    // Web Analytics Plugin configuration
    autoCapture: {
      scroll: true,
      pageView: true,
      onLoad: true,
      onUnload: true,
      click: true,
      resize: true,
      lineage: true,
      jsError: true,
    },
    shareAuthStatus: true,
    callback: {
      signedinStatus: () => getWindow()?.__INITIAL_STATE__?.users?.signedIn ?? false,
    },
  };
  const authConfig: IAuthenticationConfiguration = {
    authType: AuthType.AAD,
    loggedInStatusCallback: () => {
      return getWindow()?.__INITIAL_STATE__?.users?.signedIn ?? false;
    },
  };

  const propertiesPluginConfig: IPropertyConfiguration = {
    populateBrowserInfo: true,
    populateOperatingSystemInfo: true,
    enableApplicationInsightsTrace: true,
    enableApplicationInsightsUser: true,
    userConsentCookieName: Constants.Cookies.privacyConsentAcceptedDate,
    env: getEnvName(),
    // eslint-disable-next-line camelcase
    gpcDataSharingOptIn: getGPCValue() ?? true,
    callback: {
      userConsentDetails: () => appConfig.get('siteConsent'),
    },
  };
  const config: IExtendedConfiguration = {
    propertyConfiguration: propertiesPluginConfig,
    instrumentationKey: getWindow()?.__INITIAL_STATE__?.config?.telemetryInstrumentationKey,
    endpointUrl: getWindow()?.__INITIAL_STATE__?.config?.telemetryEndpoint,
    webAnalyticsConfiguration: webAnalyticsConfig,
    extensions: [authPlugin, propertiesPlugin, cvPlugin],
    extensionConfig: {
      [authPlugin.identifier]: authConfig,
      [propertiesPlugin.identifier]: propertiesPluginConfig,
    },
    perfEvtsSendAll: true,
  };

  analytics.initialize(config, []);
  return analytics;
};

export default () => __CLIENT__ && initiate1DS();
