import * as React from 'react';
import { Label, Stack, Text, mergeStyleSets, Image } from '@fluentui/react';
import { ICommonContext, ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { IRatingSummary } from '@shared/Models';
import { ExternalRatingSummary } from './externalRatingSummary';
import ratingStar from '@shared/images/RatingStarFilled.svg';
import { getTotalExternalRatingSummariesInfo } from '@shared/utils/reviewsUtils';
import { IAllExternalRatingSummaries } from '@shared/interfaces/reviews/rating';
import { IExternalRatingSummary } from '@shared/interfaces/reviews/external/rating';
import * as PropTypes from 'prop-types';

const contentStyles = mergeStyleSets({
  ratingDisplayWrapper: { marginTop: 16 },
  externalRatingSummariesAverage: [
    'externalRatingSummariesAverage',
    {
      '& .externalRatingSummariesNumOfStars': { padding: 0 },
    },
  ],
});

export interface IExternalRatingSummariesProps {
  externalRatingSummaries: IExternalRatingSummary[];
  storefrontName?: string;
}

export const ExternalRatingSummaries = (
  { externalRatingSummaries = [] }: IExternalRatingSummariesProps,
  context: ILocContext & ICommonContext & ILocParamsContext
) => {
  const rawRatingSummaries: IRatingSummary[] = externalRatingSummaries?.map(
    (externalRatingSummary: IExternalRatingSummary) => externalRatingSummary.ratingSummary
  );
  const externalSummariesInfo: IAllExternalRatingSummaries = getTotalExternalRatingSummariesInfo(rawRatingSummaries);

  return (
    <div className="externalRatingSummariesContainer">
      <Stack tokens={{ childrenGap: 2, padding: 1 }}>
        {externalRatingSummaries?.map((externalRatingSummary: IExternalRatingSummary) => (
          <>
            <ExternalRatingSummary
              ratingSummary={externalRatingSummary.ratingSummary}
              image={externalRatingSummary.image}
              key={`${externalRatingSummary.ratingSummary.Source}-ExternalRatingSummary`}
              showImage
              source={externalRatingSummary.ratingSummary.Source}
            />
          </>
        ))}
      </Stack>
      <div>
        {externalRatingSummaries && (
          <Stack horizontal className={contentStyles.ratingDisplayWrapper}>
            <Stack horizontal className={contentStyles.externalRatingSummariesAverage}>
              <Image
                className="externalRatingSummariesStar"
                src={ratingStar}
                alt={context.loc('Rating_Star_Image_Alt', 'Rating star')}
              />
              <Label className="externalRatingSummariesNumOfStars">{externalSummariesInfo.averageRatings.toFixed(1)}</Label>
              <Text className="externalRatingSummariesNumOfRatingText" variant="mediumPlus">
                ({context.locParams('Global_Loc_NumOfRatingsLower', [externalSummariesInfo.totalRatings.toString()])})
              </Text>
            </Stack>
          </Stack>
        )}
      </div>
    </div>
  );
};

(ExternalRatingSummaries as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
};
