/* eslint-disable react/forbid-dom-props */
/* eslint-disable no-prototype-builtins */
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Callout, DirectionalHint, PrimaryButton, mergeStyleSets } from '@fluentui/react';

import SpzaComponent from '@shared/components/spzaComponent';
import { ITelemetryData, IURLQuery, TabChild, ICollateralDocuments, IFilterElement, IDataItem } from '@shared/Models';
import { Service, IMultiGeoCountryAndRegions } from '@shared/serviceViewModel';
import {
  getCountryRegionByCountry,
  getReplacedCountryQueryParam,
  createCountryFilterQueryParam,
  getStartingPriceHeader,
  getStartingPriceCurrency,
} from '@shared/utils/consultingServicesUtils';
import { isFilterItemMatch, getFilterItemRoute, getDetailsPageTitle } from '@appsource/utils/consultingServicesUtils';
import { InternalLink } from '@shared/components/internalLink';
import { Constants } from '@shared/utils/constants';
import { SpzaInstrumentProvider, SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import {
  IBuildHrefContext,
  ILocContext,
  ILocParamsContext,
  ICTACallbackContext,
  ICommonContext,
  IOpenTileCallbackContext,
} from '@shared/interfaces/context';
import { DataMap } from '@shared/utils/dataMapping';
import { urlPush, urlReplace, routes } from 'routerHistory';
import { getWindow } from '@shared/services/window';
import { ServiceTile } from '@shared/containers/serviceTile';
import Overview from '@appsource/containers/overview';
import * as DetailUtils from '@shared/utils/detailUtils';
import { serviceOffersSupportedCountries, findServiceSupportedCountry } from '@shared/utils/filterHelpers';
import { serviceOffersSupportedCAStates, serviceOffersSupportedUSStates } from '@shared/utils/serviceOffersSupportedStatesCodes';
import { getServiceTelemetryDetailContentText } from '@shared/utils/appUtils';
import { getUserFavouriteEntityServiceType } from '@shared/utils/userFavouriteDedicatedUtils';
import UserFavouriteTileDetailButton from '@shared/containers/userFavouriteTileDetailButton';
import { Card } from '@shared/components/card';
import { ButtonLink } from '@shared/components/ButtonLink';
import { ITabsProps, Tab, Tabs } from '@shared/components/tabs';
import {
  UsefulInfo,
  UsefulInfoMetadataCell,
  UsefulInfoMetadataCellItemBase,
  UsefulInfoMetadataCellItemFilter,
  UsefulInfoMetadataCellItemInternalLink,
  UsefulInfoMetadataCellItemLabel,
  UsefulInfoMetadataCellItemLink,
  UsefulInfoMetadataCellItemModal,
  UsefulInfoMetadataCellItemType,
  UsefulInfoMetadataColumns,
} from './UsefulInfo';
import { getWorkBreakEnabledString } from 'utils/stringUtils';
import { StickyCard } from 'components/stickyCard';
import { IconLink } from 'components/iconLink';
import { getTooltipSilentLogIn } from '@shared/utils/silentLogInUtils';
import { logger } from '@src/logger';
import pdpSolutionBadge from '@shared/images/pdp_solutionBadge.svg';
import pdpMspBadge from '@shared/images/pdp_mspBadge.svg';
import classNames from 'classnames';
import { WithRouterProps } from '@shared/routerHistory';
import { WithHydrationProps } from '@shared/hooks/useHydration';

const MAX_REGIONS_IN_VIEW_COUNT = 5;
const MAX_COUNTRIES_IN_VIEW_COUNT = 5;

export interface IServiceDetailsDispatchProps {
  ensureAsyncData: (entityId: string) => Promise<boolean>;
}

export interface IServiceDetailsRouterProps {
  entityId: string;
}

export interface IServiceDetailsOwnProps extends WithRouterProps, WithHydrationProps {
  service: Service;
  serviceId: string;
  serviceIdLoaded: string;
  breadcrumbUrl: string;
  defaultTab: string;
  selectedLocale: string;
  isNationalCloud?: boolean;
  currentView?: string;
  query: IURLQuery;
  countryCode: string;
  isAppSource: boolean;
  suggestedItems: IDataItem[];
  isLoadingUserData: boolean;
  isProfessionalService?: boolean;
  ribbonKey?: string;
}

export interface IServiceDetailsProps extends IServiceDetailsOwnProps, IServiceDetailsDispatchProps {}

export interface CountryCodeDisplay {
  countryCode: string;
  countryDisplayName: string;
}

export interface RegionLists {
  topCountries: (UsefulInfoMetadataCellItemBase | UsefulInfoMetadataCellItemModal)[];
  countriesMoreModalList: string[];
}

export interface CountryLists {
  topCountries: (UsefulInfoMetadataCellItemModal | UsefulInfoMetadataCellItemInternalLink)[];
  countriesMoreModalList: CountryCodeDisplay[];
}

const ctaButtonsClassNames = mergeStyleSets({
  ctaButton: {
    padding: '5px 25px',
    marginTop: 4,
    marginRight: 8,
  },
});

export class ServiceDetails extends SpzaComponent<IServiceDetailsProps, any> {
  context: IBuildHrefContext & ILocContext & ILocParamsContext & ICTACallbackContext & ICommonContext & IOpenTileCallbackContext;
  private static pdpTabsId = 'pdpTabs';
  private static pdpTabsClass = 'pdpTabs';
  private instrument: SpzaInstrumentProvider;
  private curWindow = getWindow();

  constructor(
    props: IServiceDetailsProps,
    context: IBuildHrefContext & ILocContext & ILocParamsContext & ICTACallbackContext & ICommonContext & IOpenTileCallbackContext
  ) {
    super(props, context);
    this.instrument = SpzaInstrumentService.getProvider();
    this.state = {
      showRegionModal: false,
      showMoreCountriesModal: false,
      isPageScrolled: false,
    };
    this.closeRegionModal = this.closeRegionModal.bind(this);
    this.closeMoreCountriesModal = this.closeMoreCountriesModal.bind(this);
    this.getServiceDetailsHref = this.getServiceDetailsHref.bind(this);
  }

  static getRegionColumns(countryCode: string, regions: string[]): string[][] {
    const regionLists: string[][] = [];
    if (!countryCode || !regions) {
      return regionLists;
    }

    const columnSize = 15;
    const maxColumns = 4;
    for (let i = 0; i < maxColumns; i++) {
      regionLists.push(
        regions.slice(columnSize * i, columnSize * i + columnSize).map(function (listValue) {
          let region: any = {};
          if (countryCode.toLowerCase() === Constants.usCountryCode) {
            region = serviceOffersSupportedUSStates.filter((item: any) => {
              return item.code === listValue;
            });
          } else {
            region = serviceOffersSupportedCAStates.filter((item: any) => {
              return item.code === listValue;
            });
          }
          return region && region.length > 0 ? region[0].name : undefined;
        })
      );
    }
    return regionLists;
  }

  static getMoreCountriesColumns(moreCountries: CountryCodeDisplay[]): CountryCodeDisplay[][] {
    const countriesLists: CountryCodeDisplay[][] = [];
    if (!moreCountries) {
      return countriesLists;
    }
    const columnSize = 25;
    const maxColumns = 6;
    for (let i = 0; i < maxColumns; i++) {
      countriesLists.push(
        moreCountries.slice(columnSize * i, columnSize * i + columnSize).map((moreCountryVal) => {
          const foundCountry = serviceOffersSupportedCountries.filter((supportedCountry: any) => {
            return supportedCountry.countryCode === moreCountryVal.countryCode;
          });
          return foundCountry && foundCountry.length > 0 ? moreCountryVal : undefined;
        })
      );
    }
    return countriesLists;
  }

  handleScroll() {
    const curWindow = getWindow();
    const isPageScrolled = StickyCard.handleScroll(curWindow, ServiceDetails.pdpTabsId);
    if (this.state.isPageScrolled !== isPageScrolled) {
      this.setState({ isPageScrolled });
    }
  }

  buildGalleryLink() {
    return (
      <InternalLink
        href={this.props.breadcrumbUrl}
        role="button"
        className="c-button goBackButton"
        onClick={() => {
          DetailUtils.generateBreadcrumbPayloadAndLogTelemetry('Services');
          urlPush(this.props.breadcrumbUrl, true);
        }}
        accEnabled={true}
      >
        {this.context.loc('Services_BackButton', 'Consulting services')}
      </InternalLink>
    );
  }

  buildStateListItems(region: string[]) {
    return (
      <div className="column">
        {region.map(function (listValue) {
          return <li key={listValue}>{listValue}</li>;
        })}
      </div>
    );
  }

  getServiceDetailsHref(countryCode: string) {
    const query = this.props.query;
    let countryParams = getReplacedCountryQueryParam(query, countryCode);
    if (!countryParams) {
      countryParams = createCountryFilterQueryParam(countryCode, this.props.isAppSource);
    }
    return this.context.buildHref(routes.serviceDetail, { entityId: this.props.service.entityId }, countryParams, true);
  }

  buildMoreCountriesListItems(moreCountries: CountryCodeDisplay[], colIndex: number) {
    const closeMoreCountriesModal = this.closeMoreCountriesModal;
    return (
      <div className="column" key={colIndex}>
        {moreCountries.map((moreCountry) => {
          const serviceDetailHref = this.getServiceDetailsHref(moreCountry.countryCode);
          return (
            <InternalLink
              href={serviceDetailHref}
              scrollToTop={true}
              className="c-hyperlink"
              key={moreCountry.countryCode}
              additionalCommand={closeMoreCountriesModal}
            >
              {moreCountry.countryDisplayName}
            </InternalLink>
          );
        })}
      </div>
    );
  }

  closeRegionModal(event: any) {
    event.stopPropagation();
    this.setState({ showRegionModal: false });
  }

  buildRegionModal(regions: string[]) {
    const countryAndRegion: IMultiGeoCountryAndRegions = getCountryRegionByCountry(
      this.props.service.detailInformation,
      this.props.countryCode
    );
    const countryName = this.getCountryDisplayName(countryAndRegion.countryRegion);
    const regionColumns = ServiceDetails.getRegionColumns(countryAndRegion.countryRegion, regions);
    const regionLists = regionColumns.map((columnRegions) => {
      return this.buildStateListItems(columnRegions);
    });
    return (
      this.state.showRegionModal && (
        <Callout
          onMouseLeave={this.closeRegionModal}
          target=".regionLink"
          directionalHint={DirectionalHint.rightCenter}
          className="regionCallout"
        >
          <div className="regionsHeader">{this.context.locParams('ServicesRegions_Header', [countryName])}</div>
          <div className="regionColumns">{regionLists}</div>
        </Callout>
      )
    );
  }

  buildRegionList() {
    const countryAndRegion: IMultiGeoCountryAndRegions = getCountryRegionByCountry(
      this.props.service.detailInformation,
      this.props.countryCode
    );
    const regions = countryAndRegion.regions;
    let regionsAddedToView = 0;
    const regionList: string[] = [];
    if (regions && regions.length > 0) {
      for (let i = 0; i < regions.length; i++) {
        let region: any = {};
        if (countryAndRegion.countryRegion.toLowerCase() === Constants.usCountryCode) {
          region = serviceOffersSupportedUSStates.filter((item: any) => {
            return item.code === regions[`${i}`];
          });
        } else if (countryAndRegion.countryRegion.toLowerCase() === Constants.caCountryCode) {
          region = serviceOffersSupportedCAStates.filter((item: any) => {
            return item.code === regions[`${i}`];
          });
        }
        if (region && region.length > 0) {
          regionsAddedToView++;
          if (regionsAddedToView <= MAX_REGIONS_IN_VIEW_COUNT) {
            regionList.push(region[0].name);
          }
        }
      }
    }
    return regionList;
  }

  getShowRegionModal(regions: string[]) {
    return (
      <div>
        {this.state.showRegionModal ? <div className="overlay"></div> : null}
        {
          <div
            className="regionLink"
            onClick={() => {
              this.setState({ showRegionModal: true });
            }}
            onKeyDown={(event: React.KeyboardEvent<any>) => {
              if (event.keyCode === Constants.SystemKey.Enter) {
                this.setState({ showRegionModal: true });
              }
            }}
            tabIndex={0}
          >
            {this.context.locParams('CountryLink_More', [regions.length.toString()])}
            <div>{this.buildRegionModal(regions)}</div>
          </div>
        }
      </div>
    );
  }

  getMoreCountriesCodeAndDisplay(countries: IMultiGeoCountryAndRegions[]): CountryCodeDisplay[] {
    const moreCountries: CountryCodeDisplay[] = [];
    const countryCodeLowerCase: string = this.props.countryCode ? this.props.countryCode.toLowerCase() : null;
    for (const country of countries) {
      if (country.countryRegion.toLowerCase() !== countryCodeLowerCase) {
        const foundCountry = findServiceSupportedCountry(country.countryRegion);
        if (foundCountry) {
          moreCountries.push({
            countryCode: country.countryRegion,
            countryDisplayName: foundCountry.name,
          });
        } else {
          const errorMessage = `Country code not found in service supported countries, code =  ${country.countryRegion}.`;
          const payload: ITelemetryData = {
            page: getWindow().location.href,
            action: Constants.Telemetry.Action.Error,
            actionModifier: Constants.Telemetry.ActionModifier.Error,
            details: JSON.stringify(errorMessage),
          };
          const instrument = SpzaInstrumentService.getProvider();
          instrument.probe<ITelemetryData>(Constants.Telemetry.ProbeName.LogInfo, payload);
          logger.error(payload.details, {
            action: payload.action,
            actionModifier: payload.actionModifier,
          });
        }
      }
    }
    return moreCountries;
  }

  closeMoreCountriesModal(event?: any) {
    if (event) {
      event.stopPropagation();
    }
    this.setState({ showMoreCountriesModal: false });
  }

  buildMoreCountriesModal(moreCountries: CountryCodeDisplay[]) {
    const moreCountriesColumns = ServiceDetails.getMoreCountriesColumns(moreCountries);
    const moreCountriesLists = moreCountriesColumns.map((columnMoreCountries, colIndex) => {
      return this.buildMoreCountriesListItems(columnMoreCountries, colIndex);
    });
    return (
      this.state.showMoreCountriesModal && (
        <Callout
          onMouseLeave={this.closeMoreCountriesModal}
          target=".moreCountriesLink"
          directionalHint={DirectionalHint.rightCenter}
          className="regionCallout"
        >
          <div className="regionsHeader">{this.context.loc('ServiceDetails_MoreCountries')}</div>
          <div className="regionColumns">{moreCountriesLists}</div>
        </Callout>
      )
    );
  }

  buildMoreCountriesList(countries: IMultiGeoCountryAndRegions[]): CountryLists {
    const countryLists: CountryLists = { topCountries: [], countriesMoreModalList: [] };
    const moreCountries: CountryCodeDisplay[] = this.getMoreCountriesCodeAndDisplay(countries);
    // If any they will be shown in popup
    for (let i = 0; i < moreCountries.length; i++) {
      if (i < MAX_COUNTRIES_IN_VIEW_COUNT) {
        // Set url with the right country for each link to details page.
        const serviceDetailHref = this.getServiceDetailsHref(moreCountries[`${i}`].countryCode);
        countryLists.topCountries.push({
          information: serviceDetailHref,
          id: moreCountries[`${i}`].countryCode,
          text: moreCountries[`${i}`].countryDisplayName,
          type: UsefulInfoMetadataCellItemType.InternalLink,
          props: {
            scrollToTop: true,
            additionalCommand: this.closeMoreCountriesModal,
            target: '_self',
          },
        });
      } else {
        countryLists.countriesMoreModalList.push(moreCountries[`${i}`]);
      }
    }
    return countryLists;
  }

  getMoreCountriesModal(extraMoreCountries: CountryCodeDisplay[]) {
    return (
      <div
        className="moreCountriesLink"
        key="show_more"
        onClick={() => {
          this.setState({ showMoreCountriesModal: true });
        }}
        onKeyDown={(event: React.KeyboardEvent<any>) => {
          if (event.keyCode === Constants.SystemKey.Enter) {
            this.setState({ showMoreCountriesModal: true });
          }
        }}
        tabIndex={0}
      >
        {this.context.locParams('CountryLink_More', [extraMoreCountries.length.toString()])}
        <div key="show_more_model">{this.buildMoreCountriesModal(extraMoreCountries)}</div>
      </div>
    );
  }

  buildCompetencyList(items: any[]) {
    const itemRender: JSX.Element[] = [];
    if (items && items.length > 0) {
      for (let i = 0; i < items.length; i++) {
        itemRender.push(
          <div key={i} className="displayItem">
            {items[`${i}`].competencyName}
          </div>
        );
      }
    }
    return itemRender;
  }

  buildFilterElements(filteritems: IFilterElement[]) {
    if (filteritems) {
      let filterElements = filteritems.map((filter) => {
        return (
          <IconLink
            iconClassName={'icon-' + filter.urlKey + '-16'}
            href={filter.newPath}
            title={this.context.loc(filter.locKey, filter.title)}
            onClick={(e: any) => {
              DetailUtils.generateLinkPayloadAndLogTelemetry(
                DetailUtils.OwnerType.Service,
                this.props.service.entityId,
                filter.filterItem,
                filter.newPath,
                'Default'
              );
              urlPush(filter.newPath, true);
              e.preventDefault();
            }}
            key={filter.title}
            accEnabled={true}
            className="c-hyperlink detailsCategories"
          >
            <div className="productItem">{this.context.loc(filter.locKey, filter.title)}</div>
          </IconLink>
          // todo: need to figure out how to link on server. Need a Link component
        );
      });
      filterElements = filterElements.filter((element: JSX.Element) => !!element);
      return filterElements;
    }
    return null;
  }

  getFilterListElements(filterItem: string, filterTileType: string): IFilterElement[] {
    const filteritems: IFilterElement[] = [];
    if (this.props.service) {
      Object.keys(DataMap[`${filterItem}`]).forEach((filterValue) => {
        // Ugly but necessary. We need to show office365 product (office in data map file) even if it have ShortcutFilters.
        const filterValueAllowed =
          !DataMap[`${filterItem}`][`${filterValue}`].ShortcutFilters ||
          (filterTileType === Constants.filterTileTypes.product && filterValue === 'office');
        if (
          filterValueAllowed &&
          (DataMap[`${filterItem}`][`${filterValue}`].match
            ? DataMap[`${filterItem}`][`${filterValue}`].match(this.props.service)
            : isFilterItemMatch({ service: this.props.service, filterItem, filterValue }))
        ) {
          const filter = DataMap[`${filterItem}`][`${filterValue}`];
          if (!filter) {
            return null;
          }

          // !!! Temporary HACK: If longTitle is 'Power BI apps' and we are in consulting services tab,
          // then we make the text 'Power BI'.
          const longTitle = filter.LongTitle || filter.title;
          if (longTitle === 'Power BI apps') {
            filter.LocKey = 'Power_BI';
            filter.LongTitle = 'Power BI';
          }

          const routeParams = getFilterItemRoute({ filterItem, filterValue, filterTileType });

          const newPath = this.context.buildHref(routes.marketplaceServices, routeParams.routeArgs, routeParams.params);

          filteritems.push({
            filterItem,
            locKey: filter.LocKey,
            newPath,
            title: filter.LongTitle,
            urlKey: filter.UrlKey,
          });
        }
      });
    }
    return filteritems;
  }

  showDialog() {
    this.context.ctaCallback({
      entity: this.props.service,
      entityType: Constants.EntityType.Service,
      ctaType: Constants.CTAType.Service,
      actionModifier: Constants.Telemetry.ActionModifier.ServiceDetailCTAButton,
      ribbonKey: this.props.ribbonKey,
    });
  }

  getRegionList(): RegionLists {
    const countryLists: RegionLists = { topCountries: [], countriesMoreModalList: [] };
    const countryAndRegion: IMultiGeoCountryAndRegions = getCountryRegionByCountry(
      this.props.service.detailInformation,
      this.props.countryCode
    );
    const regions = countryAndRegion.regions;
    let regionsAddedToView = 0;
    if (regions && regions.length > 0) {
      for (let i = 0; i < regions.length; i++) {
        let region: any = {};
        if (countryAndRegion.countryRegion.toLowerCase() === Constants.usCountryCode) {
          region = serviceOffersSupportedUSStates.filter((item: any) => {
            return item.code === regions[`${i}`];
          });
        } else if (countryAndRegion.countryRegion.toLowerCase() === Constants.caCountryCode) {
          region = serviceOffersSupportedCAStates.filter((item: any) => {
            return item.code === regions[`${i}`];
          });
        }
        if (region && region.length > 0) {
          regionsAddedToView++;
          if (regionsAddedToView <= MAX_REGIONS_IN_VIEW_COUNT) {
            countryLists.topCountries.push({
              id: region[0].name,
              type: UsefulInfoMetadataCellItemType.Label,
              text: region[0].name,
            });
          } else {
            countryLists.countriesMoreModalList.push(region[0].code);
          }
        }
      }
    }
    return countryLists;
  }

  ensureAsyncData(nextProps: IServiceDetailsProps) {
    if (nextProps.serviceId) {
      this.props.ensureAsyncData(nextProps.serviceId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: IServiceDetailsProps) {
    this.ensureAsyncData(nextProps);
  }

  componentDidMount() {
    this.ensureAsyncData(this.props);

    if (this.props.service) {
      this.telemetryPageLoad();

      this.updateWindowTitle();

      this.addScrollListener();
    }
  }

  componentDidUpdate() {
    this.handleScroll();
  }

  componentWillUnmount() {
    this.removeScrollListener();
  }

  telemetryPageLoad() {
    const { service: serviceInfo, currentView, isNationalCloud } = this.props;
    const { title, entityId: serviceId } = serviceInfo || {};
    const payload: ITelemetryData = {
      page: getWindow().location.href,
      appName: title,
      action: Constants.Telemetry.Action.PageLoad,
      actionModifier: Constants.Telemetry.ActionModifier.End,
      serviceId,
      details: getServiceTelemetryDetailContentText({
        serviceInfo,
        isNationalCloud,
        ctaTypes: [Constants.CTAType.Service],
        currentView,
      }),
    };

    this.instrument.probe<ITelemetryData>('logTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
      appName: payload.appName,
    });
  }

  updateWindowTitle() {
    if (document.getElementsByTagName('title')?.length) {
      document.getElementsByTagName('title')[0].innerHTML = this.context.locParams(getDetailsPageTitle(), [
        this.props.service.title,
      ]);
    }
  }

  addScrollListener() {
    if (this.curWindow) {
      this.curWindow.addEventListener('scroll', this.handleScroll.bind(this));
    }
  }

  removeScrollListener() {
    if (this.curWindow) {
      this.curWindow.addEventListener('scroll', this.handleScroll.bind(this));
    }
  }

  getBreadcrumbElement() {
    return (
      // eslint-disable-next-line react/forbid-dom-props
      <div className="navigationBar" id="maincontent">
        <nav className="breadcrumb">
          {this.buildGalleryLink()}
          <span className="c-glyph" tabIndex={0} aria-label="greater than" role="contentinfo"></span>
          <header className="appTabButton">{this.props.service.title}</header>
        </nav>
      </div>
    );
  }

  renderButtonLink({ label, value, pricingLinkClassName }: { label: string; value: string; pricingLinkClassName: string }) {
    return (
      <ButtonLink
        href={''}
        className={pricingLinkClassName}
        type={Constants.ButtonType.Label}
        accEnabled={true}
        label={label}
        value={value}
      ></ButtonLink>
    );
  }

  bulidStartingPriceElement({ pricingLinkClassName }: { pricingLinkClassName: string }) {
    const { service, countryCode, selectedLocale, isProfessionalService } = this.props;
    const pricingValue = getStartingPriceHeader(
      service?.extraData?.multiCountryPricing,
      countryCode,
      selectedLocale,
      this.context
    );

    return (
      <div className="serviceDetailsPricingSection" itemProp="offers" itemScope itemType="https://schema.org/Offer">
        {isProfessionalService
          ? this.renderButtonLink({
              label: this.context.loc('ProfessionalService_Price', 'Contact for price'),
              value: '',
              pricingLinkClassName,
            })
          : this.renderButtonLink({
              label: this.context.loc('Pricing', 'Pricing'),
              value: pricingValue,
              pricingLinkClassName,
            })}
        {pricingValue === this.context.loc('Pricing_Free') ? (
          <div className="metaTagForPrice">
            <meta itemProp="price" content="0" />
            <meta itemProp="priceCurrency" content="USD" />
            <meta itemProp="category" content="free" />
          </div>
        ) : (
          <div className="metaTagForPrice">
            <meta itemProp="price" content={pricingValue.replace(/[^0-9.]/g, '')} />
            <meta
              itemProp="priceCurrency"
              content={getStartingPriceCurrency(this.props.service?.extraData?.multiCountryPricing, this.props.countryCode)}
            />
          </div>
        )}
      </div>
    );
  }

  getCTAElement() {
    const { isLoadingUserData, service } = this.props;
    const button = (
      <PrimaryButton
        text={this.context.loc('PartnerDetail_ContactPartner', 'CONTACT PARTNER')}
        disabled={isLoadingUserData}
        data-bi-id={Constants.JsllCTAId.ContactPartner}
        data-bi-name={service.entityId}
        data-bi-area="Service Details"
        className={classNames([ctaButtonsClassNames.ctaButton, 'requestButton'])}
        onClick={this.showDialog.bind(this)}
      />
    );
    return getTooltipSilentLogIn(button, isLoadingUserData);
  }

  getUserFavouriteButton() {
    const service = this.props.service;
    if (!service) {
      return null;
    }

    return (
      <UserFavouriteTileDetailButton
        entityId={service.entityId}
        entityType={getUserFavouriteEntityServiceType()}
        planId={undefined}
        item={service}
        customCSS={' AMPappDetails appSource-spza-user-favourite-tile-detail-button'}
      />
    );
  }

  getBadges() {
    return (
      <div className="badgesBlock">
        {Constants.ServicesIdWithBadge.hasOwnProperty(this.props.service.entityId) ? (
          <a href="https://aka.ms/integratedsolutionslearnmore" rel="noreferrer" target="_blank">
            <img id="pdp_solution_badge" className="banner" src={pdpSolutionBadge} />
          </a>
        ) : null}
        {this.props.service.tags && this.props.service.tags.indexOf(Constants.BadgeType.AzureExpertsMSP) >= 0 ? (
          <a href="https://aka.ms/AzureExpertMSP" rel="noreferrer" target="_blank">
            <img id="pdp_msp_badge" className="banner" src={pdpMspBadge} />
          </a>
        ) : null}
      </div>
    );
  }

  getChildContext() {
    return {
      openTileCallback: (detailUrl: string) => {
        const detailsPaylod: any = {
          linkType: 'App',
          link: detailUrl,
        };
        const payload: ITelemetryData = {
          page: getWindow().location.href,
          action: Constants.Telemetry.Action.Click,
          actionModifier: Constants.Telemetry.ActionModifier.Tile,
          details: JSON.stringify(detailsPaylod),
        };
        this.instrument.probe<ITelemetryData>('logTelemetryInfo', payload);
        logger.info(payload.details, {
          action: payload.action,
          actionModifier: payload.actionModifier,
        });
        this.context.openTileCallback(detailUrl);
      },
    };
  }

  getDetailCellElement(information: any, headerContent: string, itemPropName?: string) {
    const microTaggingItemPropName = itemPropName || '';
    if (information) {
      if (microTaggingItemPropName === 'publisher') {
        return (
          <div className="cell" itemProp={microTaggingItemPropName} itemScope itemType="https://schema.org/Organization">
            <h2>{headerContent}</h2>
            <span itemProp="name">{information}</span>
          </div>
        );
      } else {
        return (
          <div className="cell" itemProp={microTaggingItemPropName}>
            <header>{headerContent}</header>
            <span>{information}</span>
          </div>
        );
      }
    } else {
      return null;
    }
  }

  getCountryDisplayName(countryCode: string) {
    const country = findServiceSupportedCountry(countryCode);
    if (country) {
      return country.name;
    }
    return '';
  }

  getCompetencyList(competencyType: string) {
    return this.props.service.detailInformation.competencies.filter(
      (item: any) => item.competencyLevel && item.competencyLevel.toString().toLowerCase() === competencyType
    );
  }

  getTabsProps(): ITabsProps {
    const tabsProps: ITabsProps = {
      defaultTab: this.props.defaultTab,
      ownerType: DetailUtils.OwnerType.Service,
      ownerId: this.props.service.entityId,
      changeTabCallback: this.changeTabCallback.bind(this),
      getTabHref: this.getTabHref.bind(this),
    };

    return tabsProps;
  }

  changeTabCallback(tabTitle: string) {
    const newPath = this.getTabHref(tabTitle);

    urlReplace(newPath);
  }

  getTabHref(tabTitle: string) {
    return this.context.buildHref(
      routes.serviceDetail,
      { entityId: this.props.service.entityId },
      { tab: tabTitle },
      true,
      false,
      true
    );
  }

  getTabChildren(): TabChild[] {
    const tabsChilds: TabChild[] = [
      {
        title: this.context.loc('PartnerDetail_OverviewTab', 'Overview'),
        name: 'Overview',
        getContent: () => this.renderOverviewTab(),
      },
      {
        title: this.context.loc('ServiceDetail_DetilsAndSupportTab', 'Details + support'),
        name: Constants.Tabs.DetailsAndSupport,
        // eslint-disable-next-line react/display-name
        getContent: () => (
          <UsefulInfo ownerType={DetailUtils.OwnerType.Service} metadata={this.getUsefulInfoMetadata(this.props.service)} />
        ),
      },
    ];

    return tabsChilds;
  }

  getIndustriesFilterListElements = (): UsefulInfoMetadataCellItemFilter[] => {
    const { service } = this.props;
    return service?.industriesDetails?.map((industryDetail) => {
      const { urlKey, longTitle, locKey } = industryDetail;

      const newPath = this.context.buildHref(routes.marketplaceServices, null, { industry: urlKey });

      const filter: IFilterElement = { filterItem: Constants.filterMaps.industries, locKey, newPath, title: longTitle, urlKey };

      return {
        id: filter.title,
        type: UsefulInfoMetadataCellItemType.Filter,
        text: filter.locKey,
        entityId: service.entityId,
        filter,
      };
    });
  };

  getUsefulInfoMetadata(service: Service): UsefulInfoMetadataColumns[] {
    const { isProfessionalService } = this.props;
    // colomn 1 - Useful Information
    const usefulInformaionCells: UsefulInfoMetadataCell[] = [];
    const supportCells: UsefulInfoMetadataCell[] = [];
    const moreLinksCells: UsefulInfoMetadataCell[] = [];
    const metadata: UsefulInfoMetadataColumns[] = [];
    const contextCountryAndRegion: IMultiGeoCountryAndRegions = getCountryRegionByCountry(
      this.props.service.detailInformation,
      this.props.countryCode
    );
    const allCountryAndRegions: IMultiGeoCountryAndRegions[] = this.props.service.detailInformation
      ? this.props.service.detailInformation.MultiCountryRegions
      : null;
    // publisher
    usefulInformaionCells.push({
      id: 'publisher',
      title: 'App_Publisher',
      cellItems: [
        {
          id: 'publisherItem',
          type: UsefulInfoMetadataCellItemType.Label,
          text: service.publisher,
        },
      ],
    });

    // pricing
    const { countryCode, selectedLocale } = this.props;
    const pricingValue = getStartingPriceHeader(
      this.props.service?.extraData?.multiCountryPricing,
      countryCode,
      selectedLocale,
      this.context
    );

    if (pricingValue) {
      const cell: UsefulInfoMetadataCellItemLabel | UsefulInfoMetadataCellItemLink = {
        id: 'AppVersionItem',
        type: UsefulInfoMetadataCellItemType.Label,
        text: isProfessionalService ? this.context.loc('ProfessionalService_Price', 'contact for price') : pricingValue,
      };

      usefulInformaionCells.push({
        id: 'Pricing',
        title: 'Pricing',
        cellItems: [cell],
      });
    }
    // goldCompetencies
    if (
      this.props.service.detailInformation &&
      this.props.service.detailInformation.competencies &&
      this.props.service.detailInformation.competencies.length > 0 &&
      this.getCompetencyList('gold').length > 0
    ) {
      const goldCompetencies = this.props.service.detailInformation.competencies
        .filter((item: any) => item.competencyLevel && item.competencyLevel.toString().toLowerCase() === 'gold')
        .map((goldCompetency: any) => {
          return {
            id: goldCompetency.competencyName,
            type: UsefulInfoMetadataCellItemType.Label,
            text: goldCompetency.competencyName,
          };
        });
      if (goldCompetencies.length) {
        usefulInformaionCells.push({
          id: 'GoldCompetencies',
          title: 'ServiceDetails_GoldCompetencies',
          cellItems: goldCompetencies,
        });
      }
    }
    // silverCompetencies
    if (
      this.props.service.detailInformation &&
      this.props.service.detailInformation.competencies &&
      this.props.service.detailInformation.competencies.length > 0 &&
      this.getCompetencyList('silver').length > 0
    ) {
      const silverCompetencies = this.props.service.detailInformation.competencies
        .filter((item: any) => item.competencyLevel && item.competencyLevel.toString().toLowerCase() === 'silver')
        .map((silverCompetency: any) => {
          return {
            id: silverCompetency.competencyName,
            type: UsefulInfoMetadataCellItemType.Label,
            text: silverCompetency.competencyName,
          };
        });
      if (silverCompetencies.length) {
        usefulInformaionCells.push({
          id: 'SilverCompetencies',
          title: 'ServiceDetails_SilverCompetencies',
          cellItems: silverCompetencies,
        });
      }
    }
    if (this.props.service.products && Object.keys(this.props.service.products).length > 0) {
      // products
      const products = this.getFilterListElements(
        Constants.filterMaps.products,
        Constants.filterTileTypes.product
      ).map<UsefulInfoMetadataCellItemFilter>((product: IFilterElement) => {
        return {
          id: product.title,
          type: UsefulInfoMetadataCellItemType.Filter,
          text: product.locKey,
          entityId: service.entityId,
          filter: product,
        };
      });

      usefulInformaionCells.push({
        id: 'FilterType_Products',
        title: 'FilterType_Products',
        cellItems: products,
      });
    }

    if (this.props.service.industriesDetails?.length) {
      // industries
      const industries = this.getIndustriesFilterListElements();

      usefulInformaionCells.push({
        id: 'Industries',
        title: 'App_Industries',
        cellItems: industries,
      });
    }
    if (this.props.service.serviceTypes > 0) {
      // type
      const serviceTypes = this.getFilterListElements(
        Constants.filterMaps.serviceTypes,
        Constants.filterTileTypes.serviceType
      ).map<UsefulInfoMetadataCellItemFilter>((serviceType: IFilterElement) => {
        return {
          id: serviceType.title,
          type: UsefulInfoMetadataCellItemType.Filter,
          text: serviceType.locKey,
          entityId: service.entityId,
          filter: serviceType,
        };
      });

      usefulInformaionCells.push({
        id: 'serviceTypes',
        title: 'FilterType_ServiceType',
        cellItems: serviceTypes,
      });
    }
    // country
    if (contextCountryAndRegion && contextCountryAndRegion.countryRegion) {
      usefulInformaionCells.push({
        id: 'Country',
        title: 'ServiceDetails_Country',
        cellItems: [
          {
            id: 'countryRegionItem',
            type: UsefulInfoMetadataCellItemType.Label,
            text: this.getCountryDisplayName(contextCountryAndRegion.countryRegion),
          },
        ],
      });
    }
    // Region
    if (contextCountryAndRegion && contextCountryAndRegion.regions && contextCountryAndRegion.regions.length > 0) {
      const countryLists = this.getRegionList();
      if (countryLists.topCountries.length > 0) {
        if (countryLists.countriesMoreModalList && countryLists.countriesMoreModalList.length > 0)
          countryLists.topCountries.push({
            id: 'more',
            text: 'CountryLink_More',
            type: UsefulInfoMetadataCellItemType.Modal,
            getContent: () => this.getShowRegionModal(countryLists.countriesMoreModalList),
          });
        usefulInformaionCells.push({
          id: 'Region',
          title: 'ServiceDetails_Regions',
          cellItems: countryLists.topCountries,
        });
      }
    }
    // MoreCountries
    if (allCountryAndRegions && allCountryAndRegions.length > 1) {
      const countryLists = this.buildMoreCountriesList(allCountryAndRegions);
      if (countryLists.topCountries.length > 0) {
        if (countryLists.countriesMoreModalList && countryLists.countriesMoreModalList.length > 0) {
          countryLists.topCountries.push({
            id: 'more',
            text: 'CountryLink_More',
            type: UsefulInfoMetadataCellItemType.Modal,
            getContent: () => this.getMoreCountriesModal(countryLists.countriesMoreModalList),
          });
        }

        usefulInformaionCells.push({
          id: 'MoreCountries',
          title: 'ServiceDetails_MoreCountries',
          cellItems: countryLists.topCountries,
        });
      }
    }
    // more links
    const documents: ICollateralDocuments[] = this.props.service.detailInformation.CollateralDocuments;
    if (documents && documents.length > 0) {
      const documentsCells = documents.map<UsefulInfoMetadataCellItemLink>((doc: ICollateralDocuments) => {
        return {
          information: doc.DocumentUri,
          id: 'LearnMore',
          text: getWorkBreakEnabledString(doc.DocumentName),
          type: UsefulInfoMetadataCellItemType.Link,
          entityId: service.entityId,
        };
      });

      moreLinksCells.push({
        id: 'App_LearnMoreTitle',
        title: '',
        cellItems: documentsCells,
      });
    }

    // create root metadata item
    if (usefulInformaionCells.length) {
      metadata.push({
        id: 'UsefulInformationDetails',
        title: 'AppDetail_UsefulInformaionDetails',
        titleFallback: 'Details',
        cells: usefulInformaionCells,
      });
    }
    if (supportCells.length) {
      metadata.push({
        id: 'Support',
        title: 'AppDetail_Support',
        cells: supportCells,
      });
    }
    if (moreLinksCells.length) {
      metadata.push({
        id: 'App_Links',
        title: 'App_Links',
        titleFallback: 'Links',
        cells: moreLinksCells,
      });
    }

    return metadata;
  }

  renderOverviewTab() {
    return this.props.service.detailInformation ? (
      <Overview
        ownerType={DetailUtils.OwnerType.App}
        ownerId={this.props.service.entityId}
        ownerTitle={this.props.service.title}
        shortDescription={this.props.service.extraData.shortDescription}
        description={this.props.service.detailInformation.Description}
        images={this.props.service.detailInformation.Images}
        videos={this.props.service.detailInformation.DemoVideos}
        capabilities={null}
        TileType={ServiceTile}
        crossListings={this.props.suggestedItems as Service[]}
        //   selectedLocale={this.props.selectedLocale}
        countryCode={this.props.countryCode}
      />
    ) : null;
  }

  renderStickyCard(tabsProps: ITabsProps, tabsChilds: TabChild[]) {
    return (
      <StickyCard
        title={this.props.service.title}
        logoURL={DetailUtils.getDetailInformation(this.props.service, 'LargeIconUri')}
        tabsProps={tabsProps}
        tabsChilds={tabsChilds}
      >
        <div className="stickyCardPricing">{this.bulidStartingPriceElement({ pricingLinkClassName: 'light' })}</div>
        <div className="stickyCardButton">{this.getCTAElement()}</div>
        <div className="stickyCardFavourite">{this.getUserFavouriteButton()}</div>
      </StickyCard>
    );
  }

  renderImpl() {
    if (this.props.service) {
      const tabsProps: ITabsProps = this.getTabsProps();
      const tabsChilds: TabChild[] = this.getTabChildren();
      return (
        <div className="ASdesktopAppDetails" tabIndex={0} itemScope itemType="https://schema.org/SoftwareApplication">
          <div className="spza_detailContainer spza_partnerDetailContainer">
            {this.state.isPageScrolled ? this.renderStickyCard(tabsProps, tabsChilds) : null}
            <div className="pdpDetails">
              {this.getBreadcrumbElement()}
              <div className="detailContent">
                <div className="deatilPageContent">
                  {
                    <Card
                      title={this.props.service.title}
                      subtitle={this.props.service.publisher}
                      logoURL={DetailUtils.getDetailInformation(this.props.service, 'LargeIconUri')}
                    >
                      {this.props.isHydrated && this.props.service.products && Object.keys(this.props.service.products).length > 0
                        ? this.buildFilterElements(
                            this.getFilterListElements(Constants.filterMaps.products, Constants.filterTileTypes.product)
                          )
                        : null}
                      {this.props.isHydrated && this.getBadges()}
                      <div className="container">
                        {this.bulidStartingPriceElement({ pricingLinkClassName: 'dark' })}
                        {this.getCTAElement()}
                        {this.getUserFavouriteButton()}
                      </div>
                    </Card>
                  }
                  {!this.props.service.detailInformation ? null : (
                    <div id={ServiceDetails.pdpTabsId} className={ServiceDetails.pdpTabsClass}>
                      <Tabs
                        defaultTab={tabsProps.defaultTab}
                        ownerType={tabsProps.ownerType}
                        ownerId={tabsProps.ownerId}
                        changeTabCallback={tabsProps.changeTabCallback}
                        getTabHref={tabsProps.getTabHref}
                      >
                        {tabsChilds.map((tabChild, index) => (
                          <Tab title={tabChild.title} name={tabChild.name} key={index} textBadge={tabChild.textBadge}>
                            {tabChild.getContent()}
                          </Tab>
                        ))}
                      </Tabs>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      // there is no app
      return <div className="nullAppContainer">{this.context.loc('APPDETAIL_AppNotFound', 'App not Found')}</div>;
    }
  }
}

(ServiceDetails as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  buildHref: PropTypes.func,
  ctaCallback: PropTypes.func,
  renderErrorModal: PropTypes.func,
  openTileCallback: PropTypes.func,
};

(ServiceDetails as any).childContextTypes = {
  openTileCallback: PropTypes.func,
};
