/* eslint-disable react/forbid-dom-props */
import * as React from 'react';
import * as PropTypes from 'prop-types';
import SpzaComponent from '@shared/components/spzaComponent';
import { ITelemetryData } from '@shared/Models';
import { SpzaInstrumentProvider, SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { removeURLParameter, isEmailValid } from '@shared/utils/appUtils';
import { urlReplace, routes } from '@shared/routerHistory';
import { stringifyError } from '@shared/utils/errorUtils';

import { getWindow } from '@shared/services/window';
import * as signInUtils from '@shared/utils/signin';
import { ILocContext, ILocParamsContext, ICommonContext, IBuildHrefContext } from '@shared/interfaces/context';
import { useTelemetry } from '@shared/hooks/useTelemetry';
import { logger } from '@src/logger';
import { Constants } from '@shared/utils/constants';
import { loginRedirectUser } from '@shared/msal/loginRedirect';

// eslint-disable-next-line react-hooks/rules-of-hooks
const [{ pageAction }] = useTelemetry();
export interface ISignInModalProps {
  dismissModal: () => void;
  signInModalType?: number;
  redirect?: string;
  entityId?: string;
  appName?: string;
  email?: string;
  productId?: string;
  reviewAppId?: string;
  reviewTitle?: string;
  serviceName?: string;
  entityType?: Constants.EntityType;
  altAuth?: string;
  ctaType?: Constants.CTAType;
  disableDismissModal?: boolean;
}

export class SignInModal extends SpzaComponent<ISignInModalProps, any> {
  context: ILocContext & ILocParamsContext & ICommonContext & IBuildHrefContext;
  signUp: string;
  private instrument: SpzaInstrumentProvider;
  private isEmailEmpty = true;

  constructor(props: ISignInModalProps, context: ILocContext & ILocParamsContext & ICommonContext & IBuildHrefContext) {
    super(props, context);

    this.instrument = SpzaInstrumentService.getProvider();
    this.state = {
      inputEmail: '',
      validEmail: false,
    };

    this.signUp = signInUtils.getSignUpHost(this.props.signInModalType);
    if (process.env.SIGNUP_ORIGIN) {
      this.signUp += '&origin=' + process.env.SIGNUP_ORIGIN;
    }
  }

  componentDidMount() {
    const modalType = Constants.SignInType[this.props.signInModalType];

    const details = {
      signInModal: modalType,
      ctaType: Constants.CTAType[this.props.ctaType],
      message: `Opened sign in modal ${modalType}`,
    };

    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.Open,
      actionModifier: Constants.Telemetry.ActionModifier.LoginModal,
      details: JSON.stringify(details),
    };

    this.instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });
    pageAction(null, {
      content: {
        contentType: Constants.JsllSaas.SignInModalShow,
      },
    });
  }

  validateEmail(inputEmail: string) {
    if (isEmailValid(inputEmail)) {
      this.setState({
        inputEmail,
        validEmail: true,
      });
    } else {
      this.setState({
        inputEmail,
        validEmail: false,
      });
    }
  }

  getRedirectUrl(id?: string): string {
    let redirectUrl: string = encodeURIComponent(getWindow().location.href);

    if (this.props.redirect === 'app') {
      if (!this.props.ctaType) {
        throw new Error('CTA Type is required');
      }

      const entityId = id || this.props.entityId;
      if (entityId) {
        redirectUrl = encodeURIComponent(
          getWindow().location.href +
            this.getQueryParam(Constants.QueryStrings.modalAppId) +
            entityId +
            this.getQueryParam(Constants.QueryStrings.signInModalType, true) +
            this.props.signInModalType +
            this.getQueryParam(Constants.QueryStrings.ctaType, true) +
            this.props.ctaType
        );
      }
    }

    if (this.props.redirect === 'service') {
      if (!this.props.ctaType) {
        throw new Error('CTA Type is required');
      }

      const entityId = id || this.props.entityId;
      if (entityId) {
        redirectUrl = encodeURIComponent(
          getWindow().location.href +
            this.getQueryParam(Constants.QueryStrings.modalServiceId) +
            entityId +
            this.getQueryParam(Constants.QueryStrings.signInModalType, true) +
            this.props.signInModalType +
            this.getQueryParam(Constants.QueryStrings.ctaType, true) +
            this.props.ctaType
        );
      }
    }

    if (this.props.redirect === Constants.SignInRedirect.Review) {
      const entityId = this.props.entityId;
      if (entityId) {
        redirectUrl = encodeURIComponent(
          getWindow().location.href +
            this.getQueryParam(Constants.QueryStrings.modalRatingId) +
            entityId +
            this.getQueryParam(Constants.QueryStrings.signInModalType, true) +
            this.props.signInModalType
        );
      }
    }

    if (this.props.redirect === Constants.SignInRedirect.Checkout) {
      const entityId = this.props.entityId;
      if (entityId) {
        // redirect to checkout page
        // if there are any query params, keep those as well
        redirectUrl = encodeURIComponent(
          getWindow().location.origin + this.context.buildHref(routes.checkout, { entityId }, {}, true)
        );
      }
    }

    if (this.props.redirect === 'reportAbuse') {
      const entityId = id || this.props.entityId;
      const reviewAppId = this.props.reviewAppId;
      if (entityId) {
        redirectUrl = encodeURIComponent(
          getWindow().location.href +
            this.getQueryParam(Constants.QueryStrings.modalReviewId) +
            entityId +
            this.getQueryParam(Constants.QueryStrings.modalReviewAppId, true) +
            reviewAppId +
            this.getQueryParam(Constants.QueryStrings.modalReviewTitle, true) +
            this.props.reviewTitle +
            this.getQueryParam(Constants.QueryStrings.signInModalType, true) +
            this.props.signInModalType
        );
      }
    }

    return redirectUrl;
  }

  getQueryParam(param: string, isMultiParam = false): string {
    const queryParam = getWindow().location.href.indexOf('?') > -1 || isMultiParam ? '&' + param + '=' : '?' + param + '=';
    // If we do a sign-up before the sign-in, we will have modalAppId in the queryParams.
    // We need to get rid of it before signing in since there will be duplicate params.
    if (getWindow().location.href.indexOf(param) > -1) {
      const pageURL = removeURLParameter(getWindow().location.href, param);
      urlReplace(pageURL);
    }
    return queryParam;
  }

  // Sign up flow also uses SignIn URL as redirect (with absolute path) so that when user lands back on SPZA
  // (s)he gets redirected for sign-in flow and gets signed in
  async getSignInRedirectUrl(): Promise<string> {
    try {
      return this.getRedirectUrl();
    } catch (err) {
      const payload: ITelemetryData = {
        page: getWindow().location.href,
        action: Constants.Telemetry.Action.Login,
        actionModifier: Constants.Telemetry.ActionModifier.Error,
        details: stringifyError(err),
      };

      SpzaInstrumentService.getProvider().probe<ITelemetryData>(Constants.Telemetry.ProbeName.LogInfo, payload);
      logger.error(payload.details, {
        action: payload.action,
        actionModifier: payload.actionModifier,
      });
    }
  }

  async getSignUpUrl(email: string): Promise<string> {
    const signInRedirectUrl = await this.getSignInRedirectUrl();
    return `${this.signUp}${signInUtils.getRedirectString(this.props.signInModalType)}${encodeURIComponent(
      signInRedirectUrl
    )}&pi=1&email=${email}`;
  }

  async signInUser() {
    // Set the flushLog to true since we are navigating away from the SPZA website
    // and we need to flush all the logs present in the telemetry buffer
    const { signInModalType, ctaType } = this.props;
    const { inputEmail } = this.state;
    const details = {
      signInModal: Constants.SignInType[`${signInModalType}`],
      ctaType: Constants.CTAType[`${ctaType}`],
      message: 'Clicked on signIn. Hand-off to AAD for authentication',
    };

    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.LoginModal,
      details: JSON.stringify(details),
    };

    this.instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });
    const pageAfterRedirect = decodeURIComponent(this.getRedirectUrl());
    loginRedirectUser({ to: pageAfterRedirect, email: inputEmail });
  }

  onChange(event: any) {
    if (event.target.value && event.target.value.length > 0) {
      this.isEmailEmpty = false;
    } else {
      this.isEmailEmpty = true;
    }

    this.validateEmail(event.target.value);
  }

  onKeyDown(event: KeyboardEvent) {
    // When user presses enter key, we should sign-in
    if (event.keyCode === Constants.SystemKey.Enter) {
      this.signInUser();
    }
  }

  handleSignIn() {
    this.signInUser();
  }

  async handleSignUp() {
    // Set the flushLog to true since we are navigating away from the SPZA website
    // and we need to flush all the logs present in the telemetry buffer
    const details = {
      signInModal: Constants.SignInType[this.props.signInModalType],
      ctaType: Constants.CTAType[this.props.ctaType],
      message: 'Clicked on sign-up. Navigating to office portal',
    };

    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.Signup,
      details: JSON.stringify(details),
    };

    this.instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });
    // Send the sign-in based URL so that when user lands back on SPZA post Sign-up we get him/her signed in as well.
    const { inputEmail } = this.state;
    const signUpUrl = await this.getSignUpUrl(inputEmail);

    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', signUpUrl);

    const emailField = document.createElement('input');
    emailField.setAttribute('type', 'hidden');
    emailField.setAttribute('name', 'StepsData.Email');
    emailField.setAttribute('value', inputEmail);

    form.appendChild(emailField);

    document.body.appendChild(form);
    form.submit();
  }

  // TODO : Remove this once Office onboarding is done
  renderOldSignInModal() {
    return (
      <div className="prompContainer">
        <div className="toolBar">
          <button id="cancelButton" aria-label="close" className="cancel" onClick={this.props.dismissModal}>
            <span className="c-glyph"></span>
          </button>
        </div>
        <div className="signInModal">
          <div className="contentHeader">{signInUtils.getSignInHeader(this.context)}</div>
          <div className="formCell signInFormCell">
            <div className="cellHeader">
              {signInUtils.getTitle(this.context)}
              <span className="required"></span>
            </div>
            <input
              className="c-text-field f-flex signInInput"
              onChange={this.onChange.bind(this)}
              onKeyDown={this.onKeyDown.bind(this)}
              autoFocus={true}
              type="text"
              placeholder="someone@example.com"
              name="default"
            ></input>
          </div>
          <div className="signInButton">
            <button
              name="button"
              className="c-button requestButton"
              type="submit"
              disabled={this.isEmailEmpty}
              data-bi-id={Constants.JsllCTAId.SignIn}
              data-bi-area="SignIn Dialog"
              onClick={this.handleSignIn.bind(this)}
            >
              {this.context.loc('SI_SignIn')}
            </button>
          </div>
          <div className="signupFooter">
            {this.context.loc('SI_For')}
            <button
              data-bi-id={Constants.JsllCTAId.SignUp}
              data-bi-area="SignIn Dialog"
              onClick={() => {
                this.handleSignUp();
              }}
            >
              {this.context.loc('SI_Signup')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderTheCancelButton() {
    const { disableDismissModal } = this.props;
    return (
      !disableDismissModal && (
        <div className="toolBar">
          <button
            data-bi-id="cancelButton"
            aria-label="close"
            className="cancel"
            data-bi-name={Constants.JsllSaas.SignIn}
            onClick={this.props.dismissModal}
          >
            <span className="c-glyph"></span>
          </button>
        </div>
      )
    );
  }

  renderSignInContent(title: string, subTitle: string, disclaimer: string, accountType: string) {
    return (
      <div className="prompContainer newSignInModal" aria-label={title}>
        {this.renderTheCancelButton()}
        <div className="signInModal">
          <h1 className="title">{title}</h1>
          <div className="subTitle">{subTitle}</div>
          <div className="disclaimer">
            <span className="c-glyph" />
            {disclaimer}
          </div>
          <div className="formCell signInFormCell">
            <div className="cellHeader">
              {accountType}
              <span className="required"></span>
            </div>
            <input
              className="c-text-field f-flex signInInput"
              onChange={this.onChange.bind(this)}
              onKeyDown={this.onKeyDown.bind(this)}
              autoFocus={true}
              type="text"
              placeholder="someone@example.com"
              aria-label="sign in modal is now open. Use your work or school account"
              name="Use your work or school account"
            ></input>
          </div>
          <div className="signInButton">
            <button
              name="button"
              className="c-button requestButton"
              data-bi-id={Constants.JsllCTAId.SignIn}
              data-bi-name={Constants.JsllSaas.SignIn}
              type="submit"
              disabled={this.isEmailEmpty}
              onClick={this.handleSignIn.bind(this)}
            >
              {this.context.loc('SI_SignIn')}
            </button>
          </div>
          <div className="signupFooter">
            {this.context.loc('SI_For')}
            <button
              onClick={() => {
                this.handleSignUp();
              }}
            >
              {this.context.loc('SignInModal_SignUp')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderDualPurposeLoginModal() {
    return this.renderSignInContent(
      this.context.loc('SignInModal_Title1'),
      this.context.loc('SignInModal_SubTitle1'),
      this.context.loc('SignInModal_Disclaimer1'),
      this.context.loc('SignInModal_AccountType1')
    );
  }

  renderWorkOnlyModal() {
    if (this.props.entityType && this.props.entityType === Constants.EntityType.Service) {
      return this.renderSignInContent(
        this.context.loc('SignInModal_Title1'),
        this.context.loc('SignInModal_SubTitle1'),
        this.context.locParams('SignInModal_ServiceDisclaimer2', [this.props.serviceName]),
        this.context.loc('SignInModal_AccountType2')
      );
    } else {
      return this.renderSignInContent(
        this.context.loc('SignInModal_Title1'),
        this.context.loc('SignInModal_SubTitle1'),
        this.context.locParams('SignInModal_Disclaimer2', [this.props.appName]),
        this.context.loc('SignInModal_AccountType2')
      );
    }
  }

  renderSwitchAccountModal() {
    return this.renderSignInContent(
      this.context.loc('SignInModal_Title2'),
      this.context.locParams('SignInModal_SubTitle2', [this.props.appName]),
      this.context.locParams('SignInModal_Disclaimer3', [this.props.email]),
      this.context.loc('SignInModal_AccountType2')
    );
  }

  renderMSAOnlySignInModal() {
    return this.renderSignInContent(
      this.context.loc('SignInModal_Title1'),
      this.context.loc('SignInModal_SubTitle1'),
      this.context.locParams('SignInModal_AppRequiresMSADisclaimer', [this.props.appName]),
      this.context.loc('SignInModal_PersonalAccount')
    );
  }

  renderSwitchToMSAModal() {
    return this.renderSignInContent(
      this.context.loc('SignInModal_SwitchToPersonalAccount'),
      this.context.locParams('SignInModal_AppRequiresMSADisclaimer', [this.props.appName]),
      this.context.locParams('SignInModal_GenericAutoLogout', [this.props.email]),
      this.context.loc('SignInModal_PersonalAccount')
    );
  }

  renderModalContent(modalType: number) {
    let modalContent: JSX.Element = null;

    switch (modalType) {
      // This case is added to handle legacy sign-in modal
      case Constants.SignInType.OldSignInModal:
        modalContent = this.renderOldSignInModal();
        break;
      // The new dual purpose login modal
      case Constants.SignInType.SignInWith_MSA_AAD:
        modalContent = this.renderDualPurposeLoginModal();
        break;
      // Work only login modal
      case Constants.SignInType.SignInWith_AAD:
        modalContent = this.renderWorkOnlyModal();
        break;
      // Sign-in modal for MSA only
      case Constants.SignInType.SignInWith_MSA:
        modalContent = this.renderMSAOnlySignInModal();
        break;
      // Sign-in modal to switch from MSA to AAD
      case Constants.SignInType.SwitchTo_AAD:
        modalContent = this.renderSwitchAccountModal();
        break;
      case Constants.SignInType.SwitchTo_MSA:
        modalContent = this.renderSwitchToMSAModal();
        break;

      default:
        modalContent = this.renderOldSignInModal();
    }

    return modalContent;
  }

  renderImpl() {
    let ariaLabel = 'sign in is now open, ';
    if (
      this.props.signInModalType === Constants.SignInType.SignInWith_MSA_AAD ||
      this.props.signInModalType === Constants.SignInType.SignInWith_AAD
    ) {
      ariaLabel += this.context.loc('SignInModal_Title1');
    } else if (this.props.signInModalType === Constants.SignInType.SwitchTo_AAD) {
      ariaLabel += this.context.loc('SignInModal_Title2');
    } else {
      ariaLabel += this.context.loc('SI_SignInAppSource');
    }

    return (
      <div role="dialog" aria-label={ariaLabel} className="signInModalClass">
        {this.renderModalContent(this.props.signInModalType)}
      </div>
    );
  }
}

(SignInModal as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  renderErrorModal: PropTypes.func,
  buildHref: PropTypes.func,
};
