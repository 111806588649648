import { ServerCollection, ServerLogger } from '@server/serverLogger';
import { ClientLoggerTelemetry } from '@shared/logger/client';

export interface ILoggerPayload {
  action?: string;
  actionModifier?: string;
  page?: string;
  appName?: string;
  collection?: ServerCollection;
}

type IErrorLoggerPayload = ILoggerPayload & { error?: any };

export class Logger {
  // eslint-disable-next-line no-useless-constructor
  constructor(public clientLogger: ClientLoggerTelemetry, public serverLogger: ServerLogger) {}

  public info(message: string, { action, actionModifier, appName, page, collection }: ILoggerPayload = {}) {
    if (__CLIENT__) this.clientLogger.info({ details: message, actionModifier, action, appName, page });
    if (__SERVER__)
      this.serverLogger.info(message, {
        collection: collection || ServerCollection.ServerLogs,
        operation: action,
        operationModifier: actionModifier,
        page,
      });
  }

  public warning(message: string, { action, actionModifier, appName, page, collection }: ILoggerPayload = {}) {
    if (__CLIENT__) this.clientLogger.warning({ details: message, actionModifier, action, appName, page });
    if (__SERVER__)
      this.serverLogger.warning(message, {
        collection: collection || ServerCollection.ServerLogs,
        operation: action,
        operationModifier: actionModifier,
        page,
      });
  }

  public debug(message: string, { action, actionModifier, appName, page, collection }: ILoggerPayload = {}) {
    if (__CLIENT__) this.clientLogger.debug({ details: message, actionModifier, action, appName, page });
    if (__SERVER__)
      this.serverLogger.debug(message, {
        collection: collection || ServerCollection.ServerLogs,
        operation: action,
        operationModifier: actionModifier,
        page,
      });
  }

  public error(message: string, { action, actionModifier, appName, error, page, collection }: IErrorLoggerPayload = {}) {
    if (__CLIENT__) this.clientLogger.error({ details: message, actionModifier, action, appName, error, page });
    if (__SERVER__)
      this.serverLogger.error(message, {
        collection: collection || ServerCollection.ServerLogs,
        operation: action,
        operationModifier: actionModifier,
        page,
        error,
      });
  }

  public flush() {
    if (__CLIENT__) this.clientLogger.flush();
  }
}
