import React, { FunctionComponent } from 'react';
import * as PropTypes from 'prop-types';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { ILinkedInEvent } from '@src/State';
import { DefaultButton, FontIcon, Stack, Image, Text, mergeStyleSets, ImageFit } from '@fluentui/react';
import { numberToLocaleString, timeToLocalLocaleString, timeToLocalString } from '@shared/utils/localeUtils';
import { NeutralColors } from '@fluentui/theme';

export interface ILinkedinUpcomingEventProps {
  event: ILinkedInEvent;
  locale: string;
  countryCode: string;
  onExternalLinkClick: () => void;
}

const contentStyles = mergeStyleSets({
  upcomingEvent: {
    width: 512,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: NeutralColors.gray30,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  upcomingEventLogo: {
    width: 60,
    height: 60,
    position: 'relative',
    overflow: 'hidden',
  },
  upcomingEventLargeIcon: {
    width: 60,
    height: 60,
    position: 'relative',
    overflow: 'hidden',
    color: '#69AFE5',
  },
  upcomingEventLargeIconImg: {
    width: '100%',
    height: '100%',
  },
  upcomingEventNameText: {
    marginTop: 8,
    fontSize: 18,
    lineHeight: 24,
    fontWeight: 600,
    color: NeutralColors.gray160,
  },
  upcomingEventDefaultIcon: {
    fontSize: 40,
    width: 40,
    height: 50,
    color: '#69AFE5',
  },
  upcomingEventDefaultPanel: {
    padding: 10,
    width: 60,
    height: 60,
    backgroundColor: '#EFF6FC',
  },
  upcomingEventNormalText: {
    marginTop: 8,
    fontSize: 12,
    lineHeight: 16,
    fontWeight: 600,
    color: NeutralColors.gray130,
  },
  upcomingEventLinkButton: {
    width: 212,
    marginTop: 24,
    fontSize: 14,
    lineHeight: 20,
    fontWeight: 600,
    color: NeutralColors.gray190,
  },
  externallinkIcon: {
    marginLeft: 8,
    height: 24,
    fontSize: 14,
    color: NeutralColors.gray190,
  },
});

export const LinkedinUpcomingEvent: FunctionComponent<ILinkedinUpcomingEventProps> = (
  { event, locale, countryCode, onExternalLinkClick }: ILinkedinUpcomingEventProps,
  context: ILocContext & ILocParamsContext
) => {
  const { startTime, endTime, name, logoUrl, eventType, attendeeCount, detailsUrl } = event;

  const startTimeStr = timeToLocalString({ timeUTCSeconds: startTime / 1000, locale, countryCode });
  const endTimeStr = timeToLocalLocaleString({ timeUTCSeconds: endTime / 1000, locale, countryCode });
  return (
    <div className={contentStyles.upcomingEvent}>
      {logoUrl ? (
        <div className={contentStyles.upcomingEventLogo}>
          <div className={contentStyles.upcomingEventLargeIcon}>
            <Image imageFit={ImageFit.contain} className={contentStyles.upcomingEventLargeIconImg} src={logoUrl} />
          </div>
        </div>
      ) : (
        <div className={contentStyles.upcomingEventDefaultPanel}>
          <FontIcon aria-label="Calendar" iconName="Calendar" className={contentStyles.upcomingEventDefaultIcon} />
        </div>
      )}
      <Text className={contentStyles.upcomingEventNameText}>{name}</Text>
      <Text className={contentStyles.upcomingEventNormalText}>{eventType}</Text>
      <Text className={contentStyles.upcomingEventNormalText}>
        {context.locParams('Linkedin_Event_Time', [startTimeStr, endTimeStr])}
      </Text>
      <Text className={contentStyles.upcomingEventNormalText}>
        {context.locParams('Linkedin_Attendees_Count', [numberToLocaleString({ number: attendeeCount, locale, countryCode })])}
      </Text>
      <DefaultButton
        className={contentStyles.upcomingEventLinkButton}
        href={detailsUrl}
        target="_blank"
        onClick={onExternalLinkClick}
      >
        <Stack horizontal={true}>
          {context.loc('Linkedin_Event_Link', 'View event on LinkedIn')}
          <FontIcon iconName="OpenInNewWindow" className={contentStyles.externallinkIcon} />
        </Stack>
      </DefaultButton>
    </div>
  );
};

(LinkedinUpcomingEvent as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
};
