import { DataMap, IProductValue } from '@shared/utils/dataMapping';
import { Constants } from '@shared/utils/constants';
import { IAppDataItem } from '@shared/Models';
import { bitMasksMatchBitMask } from '@shared/utils/datamappingHelpers';
import { getProdcutBitMaskInfo } from '@shared/utils/appUtils';

export function shouldShowPopUp() {
  return true;
}

/**
 * detemine if app consent modal is a contact form
 *
 * @param appInfo: application info
 * @param ctaType: click-to-action type
 *
 * @return:
 *  true, if application click-to-action is Contact Me, and not a test drive
 *  false, otherwise
 */
export const isAppContactForm = (appInfo: IAppDataItem, ctaType: Constants.CTAType): boolean => {
  if (!appInfo) {
    return false;
  }

  if (appInfo.licenseManagement && appInfo.licenseManagement.isMicrosoftManaged) {
    return ctaType === Constants.CTAType.RequestTrial;
  }

  return appInfo.actionString === Constants.ActionStrings.RequestTrial && ctaType !== Constants.CTAType.TestDrive;
};

export function isLeadgenEnabled() {
  // All apps should be considered as sending leads
  return true;
}

export function getContactSubmitText(context: any) {
  return context.loc('CTA_ContactMe');
}

export function isETERunning(query: { [key: string]: string }): boolean {
  return query && !!query[Constants.QueryStrings.ete];
}

// Localization

export function getServiceDisclaimerLeadConsentLocalizationName() {
  return 'Service_Disclaimer_LeadConsent';
}

export function getAppDisclaimerLeadConsentLocalizationName(usesEnterpriseContract: boolean, productType: IProductValue) {
  if (
    bitMasksMatchBitMask(productType, getProdcutBitMaskInfo(DataMap.products.Dynamics365)) ||
    bitMasksMatchBitMask(productType, getProdcutBitMaskInfo(DataMap.products.Dynamics365forBusinessCentral))
  ) {
    return 'TRY_Disclaimer_LeadConsent_Dynamics365';
  }
  return 'TRY_Disclaimer_LeadConsent';
}

export function getContactMeConsentTermsLocalizationName() {
  return 'RT_Disclaimer';
}

export function getLegalTermsText(context: any) {
  return context.loc('RT_Terms');
}
