import { IURLQuery } from './../Models';
import { getWindow } from './../services/window';
import { getTrimmedString } from './stringUtils';

/**
 * get query from window.location.search
 *
 * @param search: window.location.search e.g. ?product=dynamics-365%3Bsearch=hmmm
 */
export function getQueryFromWindowLocationSearch(search: string): IURLQuery {
  if (!search || !search.trim()) {
    return {};
  }

  const queryArray: string[] = search
    .replace(/\?/g, ' ')
    .replace(/\&/g, ' ')
    .replace(/%3B/g, ';')
    .split(' ')
    .map((querySegment: string) => querySegment.trim())
    .filter((querySegment: string) => !!querySegment);

  const query: IURLQuery = {};

  queryArray.forEach((querySegment: string) => {
    const querySegmentKeyValuePair: string[] = querySegment.split('=');

    if (querySegmentKeyValuePair.length === 2) {
      query[querySegmentKeyValuePair[0]] = querySegmentKeyValuePair[1];
    }
  });

  return query;
}

export function getQueryFromBrowserUrl(): IURLQuery {
  const location = getLocationFromBrowserUrl();
  const search = (location && location.search) || '';

  return getQueryFromWindowLocationSearch(search);
}

export function getPathnameFromBrowserUrl(): string {
  const location = getLocationFromBrowserUrl();

  return (location && location.pathname) || '';
}

export function getOriginFromBrowserUrl(): string {
  const location = getLocationFromBrowserUrl();

  return (location && location.origin) || '';
}

export function getHostFromBrowserUrl(): string {
  const location = getLocationFromBrowserUrl();

  return (location && location.host) || '';
}

export function getProtocolFromBrowserUrl(): string {
  const location = getLocationFromBrowserUrl();

  return (location && location.protocol) || '';
}

export function getLocationFromBrowserUrl(): Location {
  return getWindow() && getWindow().location;
}

export function getLocationFromString(href: string): Location {
  const regex = new RegExp(
    [
      // protocol
      '^(https?:)//',

      // host (hostname and port)
      '(([^:/?#]*)(?::([0-9]+))?)',

      // pathname
      '(/{0,1}[^?#]*)',

      // search
      '(\\?[^#]*|)',

      // hash
      '(#.*|)$',
    ].join('')
  );

  const match = href.match(regex);

  if (!match) {
    return {
      href: href,
      origin: '',
      protocol: '',
      host: '',
      hostname: '',
      port: '',
      pathname: '',
      search: '',
      hash: '',
      assign: () => {},
      reload: () => {},
      replace: () => {},
      toString: () => href,
      ancestorOrigins: null,
    } as Location;
  }

  return {
    href: href,
    origin: `${match[1]}//${match[2]}`,
    protocol: match[1],
    host: match[2],
    hostname: match[3],
    port: match[4],
    pathname: match[5],
    search: match[6],
    hash: match[7],
    assign: () => {},
    reload: () => {},
    replace: () => {},
    toString: () => href,
    ancestorOrigins: null,
  } as Location;
}

/**
 * get pathname from a partial url string
 * @param href well-formed input href could be origin + pathname, host + pathname, pathname
 *  Note: when input is pathname only, to conform to well-formed, it must start with character '/'
 */
export function getPathnameFromStringUrl(href: string): string {
  const trimmedHref = getTrimmedString(href);

  const protocolRegExp = new RegExp('^https?://');
  const originRegExp = new RegExp('^https?://([^:/?#]*)(?::([0-9]+))?');

  // complete href origin if not complete
  let completeHref = '';

  // check first if input has complete origin (protocol + host)
  if (!trimmedHref.match(originRegExp)) {
    // preprend with protocol first
    completeHref = `${getProtocolFromBrowserUrl()}//${trimmedHref}`;

    // if match still fails, means input doesn't have origin at all
    // prepend with origin
    if (!completeHref.match(protocolRegExp)) {
      completeHref = `${getOriginFromBrowserUrl()}${trimmedHref}`;
    }
  }

  return getLocationFromString(completeHref).pathname;
}
