import * as React from 'react';
import { FieldHubModal as FieldHubModalComponent } from '../../components/modals/fieldHubModal';
import { getReportEmbedToken } from '../../actions/thunkActions';
import { IState } from '../../../State';

let { connect } = require('react-redux');

export const mapStateToProps = (state: IState, ownProps: any) => {
  return ownProps;
};

export const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    getReportEmbedToken: (reportData: any) => dispatch(getReportEmbedToken(reportData)),
  };
};

export const FieldHubModal = connect(mapStateToProps, mapDispatchToProps)(FieldHubModalComponent) as React.StatelessComponent<
  any
>;
