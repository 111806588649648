import { Environment, LocalizationSettings } from './constants';

export interface Locale {
  language: string;
  locale: string;
  languageCode: string;
}

export const allLocales: string[] = [
  'fr-dz',
  'es-ar',
  'en-au',
  'nl-be',
  'fr-be',
  'es-bo',
  'bs-ba',
  'pt-br',
  'en-ca',
  'fr-ca',
  'cs-cz',
  'es-cl',
  'es-co',
  'es-cr',
  'sr-latn-me',
  'en-cy',
  'da-dk',
  'de-de',
  'es-ec',
  'et-ee',
  'en-eg',
  'es-sv',
  'es-es',
  'fr-fr',
  'es-gt',
  'en-ae',
  'es-hn',
  'en-hk',
  'hr-hr',
  'en-in',
  'id-id',
  'en-ie',
  'is-is',
  'en-il',
  'it-it',
  'en-jo',
  'lv-lv',
  'en-lb',
  'lt-lt',
  'de-lu',
  'fr-lu',
  'hu-hu',
  'en-my',
  'en-mt',
  'es-mx',
  'fr-ma',
  'nl-nl',
  'en-nz',
  'es-ni',
  'en-ng',
  'nb-no',
  'de-at',
  'en-pk',
  'es-pa',
  'es-py',
  'es-pe',
  'en-ph',
  'pl-pl',
  'pt-pt',
  'es-pr',
  'es-do',
  'ro-md',
  'ro-ro',
  'en-sa',
  'de-ch',
  'en-sg',
  'sl-si',
  'sk-sk',
  'en-za',
  'sr-latn-rs',
  'en-lk',
  'fr-ch',
  'fi-fi',
  'sv-se',
  'fr-tn',
  'tr-tr',
  'en-gb',
  'en-us',
  'es-uy',
  'es-ve',
  'vi-vn',
  'el-gr',
  'ru-by',
  'bg-bg',
  'ru-kz',
  'ru-ru',
  'uk-ua',
  'he-il',
  'ar-iq',
  'ar-sa',
  'ar-ly',
  'ar-eg',
  'ar-ae',
  'th-th',
  'ko-kr',
  'zh-cn',
  'zh-tw',
  'ja-jp',
  'zh-hk',
];

export const i18nLocales = [
  'en-US',
  'ar-SA',
  'bg-BG',
  'ca-ES',
  'cs-CZ',
  'da-DK',
  'de-DE',
  'el-GR',
  'es-ES',
  'et-EE',
  'eu-ES',
  'fi-FI',
  'fr-FR',
  'gl-ES',
  'he-IL',
  'hi-IN',
  'hr-HR',
  'hu-HU',
  'id-ID',
  'it-IT',
  'ja-JP',
  'kk-KZ',
  'ko-KR',
  'lt-LT',
  'lv-LV',
  'ms-MY',
  'nb-NO',
  'nl-NL',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'ro-RO',
  'ru-RU',
  'sk-SK',
  'sl-SI',
  'sr-Cyrl-RS',
  'sr-Latn-RS',
  'sv-SE',
  'th-TH',
  'tr-TR',
  'uk-UA',
  'vi-VN',
  'zh-CN',
  'zh-TW',
];

export const languageToRegion = {
  ar: 'sa',
  bg: 'bg',
  cs: 'cz',
  da: 'dk',
  de: 'de',
  el: 'gr',
  en: 'us',
  es: 'es',
  et: 'ee',
  fi: 'fi',
  fr: 'fr',
  he: 'il',
  hr: 'hr',
  hu: 'hu',
  id: 'id',
  it: 'it',
  ja: 'jp',
  ko: 'kr',
  lt: 'lt',
  lv: 'lv',
  no: 'no',
  nl: 'nl',
  pl: 'pl',
  pt: 'br',
  ro: 'ro',
  ru: 'ru',
  sk: 'sk',
  sl: 'si',
  sv: 'se',
  th: 'th',
  tr: 'tr',
  uk: 'ua',
  vi: 'vn',
  zh: 'cn',
  ca: 'es',
  eu: 'es',
  gl: 'es',
  kk: 'kz',
  ms: 'my',
  hi: 'in',
  'sr-cyrl': 'rs',
  'sr-latn': 'rs',
};

export const i18nLocalesLowerCase = i18nLocales.map((locale) => locale.toLowerCase());
export const scriptLangauges = ['sr-cyrl', 'sr-latn'];
export const languagesOnlyLocales = ['zh-hant', 'zh-hans'];

export function GetLanguageCode(locale: string) {
  // languageCode must only be defined if locale was valid and we could extract the right value
  let languageCode = '';
  const lowerCaseLocale = locale.toLowerCase();

  // the special cases:
  // todo: norwegian is not mapped
  if (lowerCaseLocale === 'zh-cn' || lowerCaseLocale === 'zh-hans') {
    languageCode = 'zh-Hans';
  } else if (lowerCaseLocale === 'zh-tw' || lowerCaseLocale === 'zh-hk' || lowerCaseLocale === 'zh-hant') {
    languageCode = 'zh-Hant';
  } else if (lowerCaseLocale === 'sr-cyrl') {
    languageCode = 'sr-Cyrl';
  } else if (lowerCaseLocale === 'sr-latn') {
    languageCode = 'sr-Latn';
  } else if (lowerCaseLocale === 'pt-pt') {
    languageCode = 'pt-PT';
  } else if (lowerCaseLocale === 'nb-no') {
    languageCode = 'no'; // todo: Joey to make sure this is correct mapping
  } else if (lowerCaseLocale.lastIndexOf('-') > 0) {
    languageCode = lowerCaseLocale.substring(0, lowerCaseLocale.lastIndexOf('-'));
  } else {
    // Assume that the input is the languageCode.
    // If it is not valid, it should be filtered out by the caller as appropriate.
    languageCode = lowerCaseLocale;
  }

  // GetLanguageCode will be used both by the static list of locales. And, on each request.
  return languageCode;
}

export function extractLanguageFromLocale(locale = ''): string {
  const loweredLocale = locale.toLowerCase();

  if (scriptLangauges.includes(loweredLocale)) {
    return loweredLocale;
  }

  const splitedLocale = loweredLocale.split('-');

  if (splitedLocale.length === 1) {
    return loweredLocale;
  }

  const languages = splitedLocale.slice(0, splitedLocale.length - 1);

  return languages.join('-');
}

export function extractRegionFromLocale(locale = ''): string {
  const splitedLocale = locale.split('-');

  if (splitedLocale.length === 1) {
    return '';
  }

  return splitedLocale[splitedLocale.length - 1];
}

export function getSupportedI18nLocale(locale: string): string {
  if (i18nLocalesLowerCase.includes(locale)) {
    return locale;
  }

  const language = extractLanguageFromLocale(locale);

  const region = languageToRegion[`${language as keyof typeof languageToRegion}`];

  if (region) {
    return `${language}-${region}`;
  }

  return LocalizationSettings.defaultLng;
}

function formatLanguageCode(language: string): string {
  return language
    .split('-')
    .map((lang, index) => {
      if (index === 0) {
        return lang.toLowerCase();
      }

      return lang.charAt(0).toUpperCase() + lang.slice(1).toLowerCase();
    })
    .join('-');
}

export function getI18NLocale(requestLocale = '') {
  const loweredLocale = requestLocale.toLowerCase();

  if (languagesOnlyLocales.includes(loweredLocale)) {
    return formatLanguageCode(loweredLocale);
  }

  const locale = getSupportedI18nLocale(loweredLocale);

  const langauge = formatLanguageCode(extractLanguageFromLocale(locale));

  const region = extractRegionFromLocale(locale).toUpperCase();

  return [langauge, region].join('-');
}
