import { Constants } from './../utils/constants';
import { SpzaInstrumentService } from '../services/telemetry/spza/spzaInstrument';
import { ITelemetryData, INpsData } from './../Models';
import { getWindow } from './../services/window';
import { getAppConfig } from './../services/init/appConfig';
import { getLocalStorageItem, saveLocalStorageItem } from './../utils/browserStorageUtils';
import { isEmptyNullorUndefinedString } from './../utils/stringUtils';
import { logger } from '@src/logger';

export module NpsModule {
  let showModal: () => void;
  let npsHelper: Nps = null;
  let idleCounter = 0;
  let blocked = false;
  let idleTimer: any = null;
  let isNotSameCorrelationId = false;
  let sessionStartTime = 0;

  export function setTimer(callback: any, interval?: number) {
    // check every 1 second
    idleTimer = window.setInterval(callback, interval || 1000);
  }

  export function reset() {
    blocked = false;
    idleTimer = null;
  }

  function dLog(debugMsg?: string) {
    const instrument = SpzaInstrumentService.getProvider();
    const data = {
      npsFrom: debugMsg,
      npsStore: npsHelper.getStore(),
    };
    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.NPS,
      actionModifier: Constants.Telemetry.ActionModifier.Debug,
      details: JSON.stringify(data),
    };
    instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });
  }

  export function checkForNPS(viewedAppsCount?: number, acquiredAppsCount?: number, interval?: number, debugMsg?: string) {
    if (npsHelper) {
      const waitTime = 30 * 1000; // If all conditions are met, we wait for 30 seconds since the start-up to show the NPS popup.
      const currentTime = Date.now();

      if (sessionStartTime && currentTime - sessionStartTime > waitTime) {
        const oneHourInMS = 1000 * 60 * 60;
        const savedSessionEndTime = npsHelper.getItem('sessionEndTime');
        const viewedApps = viewedAppsCount || npsHelper.getItem('viewedApps');
        const acquiredApps = acquiredAppsCount || npsHelper.getItem('acquiredApps');
        const isAppsCriteriaValid =
          (viewedApps && viewedApps >= npsHelper.viewedAppsTriggerNumber) ||
          (acquiredApps && acquiredApps >= npsHelper.acquiredAppsTriggerNumber);
        const isTimeCriteriaValid = savedSessionEndTime && sessionStartTime - savedSessionEndTime > oneHourInMS;

        if (isTimeCriteriaValid && isAppsCriteriaValid && isNotSameCorrelationId && npsHelper.due() && idleTimer == null) {
          // turn on the timer
          setTimer(() => {
            if (!blocked) {
              idleCounter++;
              if (idleCounter >= 3) {
                window.clearInterval(idleTimer);
                idleTimer = null;
                dLog(debugMsg);
                ResetNPSCount();
                showModal();
              }
            }
          }, interval);
        }
      }
    }
  }

  export function IncreaseAppDetail(interval?: number) {
    if (npsHelper) {
      let viewedApps = npsHelper.getItem('viewedApps');

      if (!viewedApps) {
        viewedApps = 1;
      } else {
        viewedApps += 1;
      }
      npsHelper.setItem('viewedApps', viewedApps);

      checkForNPS(viewedApps, npsHelper.getItem('acquiredApps'), interval, 'detailsPage');
    }
  }

  export function IncreaseAppAcquisition(interval?: number) {
    if (npsHelper) {
      let acquiredApps = npsHelper.getItem('acquiredApps') || 0;

      acquiredApps++;
      npsHelper.setItem('acquiredApps', acquiredApps);

      checkForNPS(npsHelper.getItem('viewedApps'), acquiredApps, interval, 'detailsPage');
    }
  }

  export function ResetNPSIdle() {
    if (npsHelper) {
      idleCounter = 0;
    }
  }

  export function ResetNPSCount() {
    if (npsHelper) {
      npsHelper.setItem('viewedApps', 0);
      npsHelper.setItem('acquiredApps', 0);
      npsHelper.setItem('sessionEndTime', null);
    }
  }

  export function SetShortInterval() {
    if (npsHelper) {
      npsHelper.setNext(npsHelper.shortInterval);
    }
  }

  export function SetInterval(interval: number, offset?: boolean) {
    if (npsHelper) {
      npsHelper.setNext(interval, offset);
    }
  }

  export function Declined() {
    if (npsHelper) {
      if (npsHelper.getItem('secondTime')) {
        npsHelper.setItem('secondTime', false);
        npsHelper.setNext(npsHelper.longInterval);
      } else {
        npsHelper.setItem('secondTime', true);
        npsHelper.setNext(npsHelper.shortInterval);
      }
    }
  }

  export function Submitted() {
    if (npsHelper) {
      // succeeded
      npsHelper.setItem('secondTime', false);
      npsHelper.setNext(npsHelper.longInterval);
    }
  }

  export function BlockShowingNPS() {
    if (npsHelper) {
      blocked = true;
    }
  }

  export function AllowShowingNPS() {
    if (npsHelper) {
      blocked = false;
      ResetNPSIdle();
    }
  }

  export function SaveSessionEndTime() {
    if (npsHelper) {
      const savedSessionEndTime = npsHelper.getItem('sessionEndTime');

      // save if it is set to null or doesn't exist
      // opening the NPS model will set it to null (inside ResetNPS)
      if (!savedSessionEndTime) {
        npsHelper.setItem('sessionEndTime', Date.now());
      }
    }
  }

  export function Initialize(showModalFunction: () => void, timerInterval?: number) {
    // only init from client
    sessionStartTime = Date.now();
    showModal = showModalFunction;
    npsHelper = new Nps();

    const currentCorrelationId = getAppConfig('correlationId');

    const savedCorrelationId = npsHelper.getItem('correlationId');
    if (savedCorrelationId && currentCorrelationId !== savedCorrelationId) {
      isNotSameCorrelationId = true;
    } else {
      isNotSameCorrelationId = false;
      npsHelper.setItem('correlationId', currentCorrelationId);
    }

    let viewedApps = npsHelper.getItem('viewedApps');
    if (!viewedApps) {
      viewedApps = 0;
    }

    let acquiredApps = npsHelper.getItem('acquiredApps');
    if (!acquiredApps) {
      acquiredApps = 0;
    }

    checkForNPS(viewedApps, acquiredApps, timerInterval, 'landingPage');
  }

  class Nps {
    public longInterval: number;
    public shortInterval: number;
    public timeTriggerInterval: number;
    public viewedAppsTriggerNumber: number;
    public acquiredAppsTriggerNumber: number;

    private storeTitle: string;

    constructor() {
      this.storeTitle = Constants.LocalStorage.nps;
      this.longInterval = 1000 * 3600 * 24 * 30 * 3; // delay after accepting
      this.shortInterval = 1000 * 3600 * 24 * 30 * 1; // delay after denying
      this.timeTriggerInterval = 1000 * 3600 * 1; // amount of delay the user needs to wait to see an nps dialog after the first visit
      this.viewedAppsTriggerNumber = 2; // amount of apps viewed has to be greater than this number in order to trigger nps
      this.acquiredAppsTriggerNumber = 1; // amount of apps that need to be acquired before triggering nps
    }

    reset(next?: number) {
      let initNext = next || Date.now();
      initNext += this.timeTriggerInterval; // This is added to find the 'time' at which we need to show the nps for the first time

      const date = new Date(initNext);
      const store: INpsData = {
        next: initNext,
        nextText: date.toString(),
        secondTime: false,
        viewedApps: 0,
        acquiredApps: 0,
        sessionEndTime: null,
      };
      this.setStore(store);
      return store;
    }

    getStore() {
      try {
        const storeString: string = getLocalStorageItem(this.storeTitle);
        if (!isEmptyNullorUndefinedString(storeString)) {
          return JSON.parse(storeString);
        } else {
          return this.reset();
        }
      } catch (e) {
        return this.reset();
      }
    }

    setStore(store: INpsData) {
      const storeString: string = JSON.stringify(store);
      try {
        saveLocalStorageItem(this.storeTitle, storeString);
      } catch (e) {
        // In case of quota exceed exception we dont want to crash rather not let NPS show up.
      }
    }

    getItem(section: string) {
      const store = this.getStore();
      if (store && section in store) {
        return store[`${section}`];
      } else {
        return null;
      }
    }

    setItem(section: string, data: any) {
      const store = this.getStore();
      if (store) {
        store[`${section}`] = data;
      }
      this.setStore(store);
    }

    getNext() {
      const next: number = this.getItem(Constants.LocalStorage.npsNext);
      if (next) {
        return next;
      } else {
        return 0;
      }
    }

    setNext(next: number, offset = true) {
      if (offset) {
        next = Date.now() + next;
      }
      const nextText = new Date(next);
      this.setItem(Constants.LocalStorage.npsNext, next);
      this.setItem(Constants.LocalStorage.npsNextText, nextText.toString());
    }

    due(next?: number): boolean {
      const by = next || this.getNext();
      const now = Date.now();
      return now >= by;
    }
  }
}
