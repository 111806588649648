import * as React from 'react';
import * as PropTypes from 'prop-types';
import { ScreenWidthMinLarge, ScreenWidthMinXLarge, ScreenWidthMinXXLarge, Text, mergeStyleSets } from '@fluentui/react';
import Slider, { CustomArrowProps, Settings } from 'react-slick';
import SpzaComponent from './spzaComponent';
import { urlPush } from 'routerHistory';
import { InternalLink } from './internalLink';
import { ICommonContext } from '@shared/interfaces/context';
import { getNpsModule } from '@appsource/utils/nps';
import classNames from 'classnames';
import { TelemetryImage } from '@shared/components/telemetryImage';

export interface IRibbonProps {
  title?: string;
  seeMoreUrl?: string;
  seeMoreText?: string;
  isSeeMoreButton?: boolean;
  subHeader?: string;
  onClick?: () => void;
  carousel?: boolean;
  key?: string;
  imgSource?: string;
}
const contentStyles = mergeStyleSets({
  ribbonHeaderImage: {
    verticalAlign: 'top',
    display: 'inline-block',
    marginLeft: '8px',
  },
});

function CrouselNextArrow(props: CustomArrowProps): JSX.Element {
  const { onClick } = props;

  return (
    <button
      className={classNames({ crouselArrow: true, crouselArrowRight: true, 'icon-arrow-right-32': true })}
      onClick={onClick}
      aria-label="Next page"
    />
  );
}

function CrouselPrevArrow(props: CustomArrowProps): JSX.Element {
  const { onClick } = props;

  return (
    <button
      className={classNames({ crouselArrow: true, crouselArrowLeft: true, 'icon-arrow-left-32': true })}
      onClick={onClick}
      aria-label="Previous page"
    />
  );
}

export default class Ribbon extends SpzaComponent<IRibbonProps, any> {
  context: ICommonContext;

  componentDidMount() {
    window.addEventListener('resize', this.hideAriaHiddenTiles);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.hideAriaHiddenTiles);
  }

  componentDidUpdate() {
    this.hideAriaHiddenTiles();
  }

  hideAriaHiddenTiles() {
    // Fix https://github.com/akiran/react-slick/issues/1535
    Array.from(document.querySelectorAll('.slick-slide')).forEach((slide: HTMLElement) => {
      if (slide.classList?.contains('slick-active')) {
        slide.style.visibility = 'visible';
      } else {
        slide.style.visibility = 'hidden';
      }
    });
  }

  visibleAriaHiddenTiles() {
    Array.from(document.querySelectorAll('.slick-slide')).forEach((slide: HTMLElement) => {
      slide.style.visibility = 'visible';
    });
  }

  onRibbonButtonClick() {
    if (this.props.onClick) {
      this.props.onClick();
    } else {
      urlPush(this.props.seeMoreUrl, true);
    }
    getNpsModule()?.increaseActionsCounter('seeAllActionsCount');
  }

  renderRibbonButton(): JSX.Element {
    return (
      this.props.seeMoreUrl && (
        <InternalLink
          onClick={() => this.onRibbonButtonClick()}
          className="ribbonHeaderButton"
          href={this.props.seeMoreUrl}
          accEnabled
          aria-label={`see all for ${this.props.title}`}
        >
          {this.props.seeMoreText}
          {this.props.isSeeMoreButton && <Text variant="medium" className="c-glyph" />}
        </InternalLink>
      )
    );
  }

  renderCarouselTiles(): JSX.Element {
    const { children } = this.props;
    const childrenCount = React.Children.count(children);
    const settings: Settings = {
      speed: 500,
      slidesToShow: 4,
      // Critical for dealing with a bug in react-slick which caused to double elements when infinite is true and slidesToShow is less than children count - https://github.com/akiran/react-slick/issues/1171
      infinite: childrenCount > 4,
      slidesToScroll: 1,
      swipeToSlide: false,
      responsive: [
        {
          breakpoint: ScreenWidthMinXXLarge,
          settings: {
            slidesToShow: 3,
            infinite: childrenCount > 3,
          },
        },
        {
          breakpoint: ScreenWidthMinXLarge,
          settings: {
            slidesToShow: 2,
            infinite: childrenCount > 2,
          },
        },
        {
          breakpoint: ScreenWidthMinLarge,
          settings: {
            slidesToShow: 1,
            infinite: childrenCount > 1,
          },
        },
      ],
      nextArrow: <CrouselNextArrow />,
      prevArrow: <CrouselPrevArrow />,
      beforeChange: () => this.visibleAriaHiddenTiles(),
      afterChange: () => this.hideAriaHiddenTiles(),
    };

    return (
      <div className="ribbonSlider">
        <Slider {...settings}>{this.props.children}</Slider>
      </div>
    );
  }

  renderTiles(): JSX.Element {
    return <div className="ribbonTiles">{this.props.carousel ? this.renderCarouselTiles() : this.props.children}</div>;
  }

  renderHeader(): JSX.Element {
    const { imgSource, title, subHeader } = this.props;
    return title ? (
      <div className="ribbonHeaderContainer">
        <div className="ribbonHeader">
          <Text variant="large" className="ribbonHeaderText" as={'h2'}>
            {title}
          </Text>
          {this.renderRibbonButton()}
          {imgSource && <TelemetryImage className={contentStyles.ribbonHeaderImage} src={imgSource} />}
        </div>
        {subHeader ? <div className="ribbonSubHeader">{subHeader}</div> : null}
      </div>
    ) : null;
  }

  renderImpl() {
    return (
      <div className={'ribbon'} aria-label={`${this.props.title} swimlane`} role="banner">
        {this.renderHeader()}
        {this.renderTiles()}
      </div>
    );
  }
}

(Ribbon as any).contextTypes = {
  renderErrorModal: PropTypes.func,
};
