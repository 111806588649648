import {
  Action,
  isType,
  SearchboxInputChangedAction,
  LiveSearchboxFilterAction,
  SearchSortingOptionChangedAction,
  FilteredSearchResultsReceivedAction,
  PartnerSearchResultsReceivedAction,
  ShowingResultsForReceviedAction,
  SearchSuggestionsRequestId,
} from '@shared/actions/actions';
import { ISearchState, initialSearchState, copyState } from '@src/State';

export default function searchReducer(state: ISearchState = initialSearchState, action: Action<any>): ISearchState {
  const newState = copyState(state);
  if (isType(action, SearchboxInputChangedAction)) {
    // search box is being searched
    newState.searchText = action.payload.searchString;

    // leave the search results be, since they will be updated when new search results come in
    // however, when the dropdown is emptied out, clear the results.
    if (newState.searchText.length === 0) {
      newState.appSearchResults = [];
      newState.servicesSearchResults = [];
      newState.ecaSearchResults = [];
    }

    // make sure we only react to the latest request
    newState.searchIdCurrentlyOngoing = state.searchIdCurrentlyOngoing + 1;
  } else if (isType(action, LiveSearchboxFilterAction)) {
    newState.searchText = action.payload.searchText;
  } else if (isType(action, FilteredSearchResultsReceivedAction)) {
    if (newState.searchIdCurrentlyOngoing !== action.payload.searchid) {
      return state;
    } else {
      const {
        appSearchResults,
        servicesSearchResults,
        cloudsIndustrySearchResults,
        appsCount,
        servicesCount,
        cloudsIndustryCount,
        requestId,
      } = action.payload;

      return {
        ...newState,
        filteredAppSearchResults: appSearchResults,
        filteredServicesSearchResults: servicesSearchResults,
        filteredCloudsIndustrySearchResults: cloudsIndustrySearchResults,
        appsCount,
        servicesCount,
        cloudsIndustryCount,
        requestId,
      };
    }
  } else if (isType(action, PartnerSearchResultsReceivedAction)) {
    newState.partnerSearchResults = action.payload.partnerSearchResults;
  } else if (isType(action, ShowingResultsForReceviedAction)) {
    const { previousSearchText = '', showingResultsFor = '' } = action.payload;

    return {
      ...newState,
      showingResultsFor,
      previousSearchText,
      searchText: previousSearchText,
    };
  } else if (isType(action, SearchSortingOptionChangedAction)) {
    newState.searchSortingOption = action.payload.searchSortingOption;
  } else if (isType(action, SearchSuggestionsRequestId)) {
    newState.suggestionsRequestId = action.payload.suggestionsRequestId;
  } else {
    return state;
  }

  return newState;
}
