import { Constants } from '@shared/utils/constants';
import {
  IUserFavouriteEntity,
  IRawFavoriteEntityData,
  IUserFavouritePostResponse,
  IDeleteUserFavouriteItemActionPayload,
  IUserFavouriteState,
  IUserFavouriteApp,
  IUserFavouriteService,
  IUserFavouriteItem,
} from '@shared/interfaces/userFavouriteModels';
import { routes } from '@shared/routerHistory';
import { IBuildHrefFn } from '@shared/interfaces/context';
import { getUserFavouriteEntityServiceType, getUserFavouriteEntityAppType } from '@shared/utils/userFavouriteDedicatedUtils';

// general utils
export function isApplication(applicationType: Constants.UserFavourite.ApplicationTypes) {
  return applicationType === getUserFavouriteEntityAppType();
}

export function isConsultingService(applicationType: Constants.UserFavourite.ApplicationTypes) {
  return applicationType === getUserFavouriteEntityServiceType();
}

export function getFavouriteEntityState(
  favourites: IRawFavoriteEntityData[],
  applicationType: Constants.UserFavourite.ApplicationTypes
): IUserFavouriteEntity[] {
  return favourites.map<IUserFavouriteEntity>((favourite: IRawFavoriteEntityData) => {
    return {
      entityGuid: favourite.id,
      applicationId: favourite.applicationId,
      planId: favourite.planId,
      applicationType: applicationType,
      item: favourite.item,
    };
  });
}

// redux store related
// upsert, either add, or update, a favourite based on entityId
export function upsertsUserFavouriteEntityState(entityList: IUserFavouriteEntity[], payload: IUserFavouritePostResponse) {
  const newEntity: IUserFavouriteEntity = {
    entityGuid: payload.id,
    applicationId: payload.applicationId,
    planId: payload.planId,
    item: payload.item,
    applicationType: payload.applicationType,
  };

  // find update first
  const shouldUpdate: boolean = entityList.some((entity: IUserFavouriteEntity, index: number) => {
    const foundMatch: boolean = entity.entityGuid === payload.id;
    if (foundMatch) {
      entityList[`${index}`] = newEntity;
    }
    return foundMatch;
  });

  if (shouldUpdate) {
    return;
  }

  // if it's not an update, it's an add
  entityList.push(newEntity);
}

// delete a favourite based on entityId
export function deleteUserFavouriteEntityState(
  entityList: IUserFavouriteEntity[],
  payload: IDeleteUserFavouriteItemActionPayload
) {
  let indexToDelete = -1;
  entityList.some((entity: IUserFavouriteEntity, index: number) => {
    const foundMatch: boolean = entity.entityGuid === payload.entityGuid;
    if (foundMatch) {
      indexToDelete = index;
    }
    return foundMatch;
  });

  const numberOfEntityToDelete = indexToDelete === -1 ? 0 : 1;
  entityList.splice(indexToDelete, numberOfEntityToDelete);
}

// component related utils
export function getAllUserFavouriteCount(userFavourite: IUserFavouriteState): number {
  const userFavouriteCount: number =
    userFavourite.fetchDataStatus === Constants.UserFavourite.FetchDataStatus.Valid
      ? userFavourite.apps.length + userFavourite.services.length
      : 0;

  return userFavouriteCount;
}

export function getUserFavouriteAppCount(userFavourite: IUserFavouriteState): number {
  const userFavouriteCount: number =
    userFavourite.fetchDataStatus === Constants.UserFavourite.FetchDataStatus.Valid ? userFavourite.apps.length : 0;

  return userFavouriteCount;
}

export function getUserFavouriteServiceCount(userFavourite: IUserFavouriteState): number {
  const userFavouriteCount: number =
    userFavourite.fetchDataStatus === Constants.UserFavourite.FetchDataStatus.Valid ? userFavourite.services.length : 0;

  return userFavouriteCount;
}

export function getUserFavouriteDetailPageRedirectUrl(buildHref: IBuildHrefFn): string {
  return buildHref(routes.userFavouriteDetail, null, {
    category: null,
    industry: null,
    product: null,
    search: null,
    serviceType: null,
  });
}

export function getUserFavouriteEntity(userFavourite: IUserFavouriteState, entityId: string): IUserFavouriteEntity {
  let userFavouriteEntity: IUserFavouriteEntity;

  const hasFoundEntity = userFavourite.apps.some((app: IUserFavouriteApp) => {
    const hasFound = app.applicationId === entityId;

    if (hasFound) {
      userFavouriteEntity = {
        ...app,
      };
    }

    return hasFound;
  });

  if (hasFoundEntity) {
    return userFavouriteEntity;
  }

  userFavourite.services.some((service: IUserFavouriteService) => {
    const hasFound = service.applicationId === entityId;

    if (hasFound) {
      userFavouriteEntity = {
        ...service,
      };
    }

    return hasFound;
  });

  return userFavouriteEntity;
}

export function getUserFavouriteViewEntityList(userFavouriteEntityList: IUserFavouriteEntity[]): IUserFavouriteItem[] {
  if (!userFavouriteEntityList) {
    return [];
  }

  return userFavouriteEntityList.map((userFavouriteEntity) => userFavouriteEntity.item);
}
