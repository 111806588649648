import { ActionType, actionCreator } from './../actions/actions';
import {
  ICartItem,
  ICustomer,
  IPublisherOfferResponse,
  ICustomerAddress,
  ISetSeatsAction,
  ISetProductAction,
  ISetTermDurationAction,
  ISetTermIdAction,
  ISetRecurringBilling,
  IBillingAddress,
  CheckoutSourcePayload,
  ICheckoutId,
} from '@shared/Models';
// eslint-disable-next-line import/named
import type { IReceiptView } from '@microsoft-commerce/purchase-blends-component-library';

// todo: type the any payloads
export const SetReceiptAction: ActionType<IReceiptView> = 'SetReceiptAction';
export const createSetReceiptAction = actionCreator(SetReceiptAction);

export const SetBillingAddressAction: ActionType<IBillingAddress> = 'SetBillingAddressAction';
export const createSetBillingAddressAction = actionCreator(SetBillingAddressAction);

export const SetCheckoutId: ActionType<ICheckoutId> = 'SetCheckoutId';
export const createSetCheckoutId = actionCreator(SetCheckoutId);

export const SetActiveStep: ActionType<number> = 'SetActiveStep';
export const createSetActiveStep = actionCreator(SetActiveStep);

export const SetProduct: ActionType<ISetProductAction> = 'setProduct';
export const createProduct = actionCreator(SetProduct);

export const SetBillingTerm: ActionType<ISetTermDurationAction> = 'SetBillingTerm';
export const createSetBillingTerm = actionCreator(SetBillingTerm);

export const SetTermId: ActionType<ISetTermIdAction> = 'SetTermId';
export const createSetTermId = actionCreator(SetTermId);

export const SetSeatNumber: ActionType<ISetSeatsAction> = 'SetSeatNumber';
export const createSetSeatNumber = actionCreator(SetSeatNumber);

export const SetRecurringBilling: ActionType<ISetRecurringBilling> = 'SetRecurringBilling';
export const createSetRecurringBilling = actionCreator(SetRecurringBilling);

export const SetItemInCartAction: ActionType<ICartItem> = 'SetItemInCartAction';
export const createSetItemInCartAction = actionCreator(SetItemInCartAction);

export const SetCheckoutSource: ActionType<CheckoutSourcePayload> = 'SetCheckoutSource';
export const createSetCheckoutSource = actionCreator(SetCheckoutSource);

export const UpdateCustomerAction: ActionType<ICustomerAddress> = 'UpdateCustomerAction';
export const createUpdateCustomerAction = actionCreator(UpdateCustomerAction);

export const SetCustomersAction: ActionType<ICustomer[]> = 'SetCustomersAction';
export const createSetCustomersAction = actionCreator(SetCustomersAction);

export const SetActiveCustomerAction: ActionType<ICustomer> = 'SetActiveCustomerAction';
export const createSetActiveCustomerAction = actionCreator(SetActiveCustomerAction);

export const SetPublisherTokenResponse: ActionType<IPublisherOfferResponse> = 'SetPublisherTokenResponse';
export const createSetPublisherTokenResponse = actionCreator(SetPublisherTokenResponse);

// eslint-disable-next-line @typescript-eslint/ban-types
export const ClearCheckoutState: ActionType<{}> = 'ClearCheckoutState';
export const createClearCheckoutState = actionCreator(ClearCheckoutState);

export const ClearErrorAction: ActionType<void> = 'ClearErrorAction';
export const createClearErrorAction = actionCreator(ClearErrorAction);

export const ErrorAction: ActionType<void> = 'ErrorAction';
export const createErrorAction = actionCreator(ErrorAction);

export const FetchingProductAction: ActionType<void> = 'FetchingProductAction';
export const createFetchingProductAction = actionCreator(FetchingProductAction);
