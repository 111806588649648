import React, { useEffect, useRef } from 'react';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { NpsModal } from '@appsource/components/modals';
import { getNpsModule } from '@appsource/utils/nps';
import { getWindow } from '@shared/services/window';
import { Constants } from '@shared/utils/constants';
import { ITelemetryData, INpsSurveyAnswer } from '@shared/Models';
import { useSpzaInstrumentService } from '@shared/hooks';
import { storeFeedback } from '@shared/services/feedback';
import { logger } from '@src/logger';

interface INpsProps {
  email: string;
  correlationId: string;
  context: ILocContext & ILocParamsContext;
}

export const Nps: React.FunctionComponent<INpsProps> = ({ email, correlationId, context }: INpsProps) => {
  const [instrument] = useSpzaInstrumentService();
  const showPreviewModalRef = useRef(false);

  useEffect(() => {
    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.NPS,
      actionModifier: Constants.Telemetry.ActionModifier.NpsPresented,
      details: JSON.stringify({ ...(getNpsModule()?.metadata || {}), previewModal: showPreviewModalRef.current }),
    };
    instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });
  }, [instrument]);

  const handleSubmit = (answer: INpsSurveyAnswer) => {
    getNpsModule()?.npsSubmitted();

    const userTelemetryPayload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.NPS,
      actionModifier: Constants.Telemetry.ActionModifier.NpsSurveySubmitted,
      details: JSON.stringify({ previewModal: showPreviewModalRef.current }),
    };
    const userFeedbackPayload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.NPS,
      actionModifier: Constants.Telemetry.ActionModifier.Info,
      details: JSON.stringify({ ...answer, rating: `${answer.rating}`, previewModal: showPreviewModalRef.current }),
      isFeedback: true,
    };
    instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', userTelemetryPayload);
    instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', userFeedbackPayload);

    logger.info(userTelemetryPayload.details, {
      action: userTelemetryPayload.action,
      actionModifier: userTelemetryPayload.actionModifier,
    });

    storeFeedback({
      action: userFeedbackPayload.action,
      actionModifier: userFeedbackPayload.actionModifier,
      details: userFeedbackPayload.details,
    });
  };

  const handleDismissPreview = () => {
    getNpsModule()?.npsDismissed();
    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.NPS,
      actionModifier: Constants.Telemetry.ActionModifier.NpsPreviewDismissed,
      details: '',
    };
    instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });
  };

  const handleDismissSurvey = () => {
    getNpsModule()?.npsDismissed();
    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.NPS,
      actionModifier: Constants.Telemetry.ActionModifier.NpsSurveyDismissed,
      details: '',
    };
    instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });
  };

  return (
    <NpsModal
      email={email}
      showPreviewModal={showPreviewModalRef.current}
      onDismissPreview={handleDismissPreview}
      onDismissSurvey={handleDismissSurvey}
      onSubmitted={handleSubmit}
      correlationId={correlationId}
      context={context}
    />
  );
};
