/* eslint-disable no-useless-escape */
import { Constants } from '@shared/utils/constants';
import { IURLQuery } from '@shared/Models';
import { countryCodeToNameMap } from '@shared/utils/countryCodeMap';
import { serviceOffersSupportedStatesCodes } from '@shared/utils/serviceOffersSupportedStatesCodes';
import { urlKeys } from '@shared/utils/datamappingHelpers';

interface ParamToDataMapKey {
  [urlKey: string]: string;
}

const paramToDataMapKey: ParamToDataMapKey = {
  [Constants.QueryStrings.product]: Constants.DataMapCollectionKeys.products,
  [Constants.QueryStrings.category]: Constants.DataMapCollectionKeys.categories,
  [Constants.QueryStrings.industry]: Constants.DataMapCollectionKeys.industries,
  [Constants.QueryStrings.serviceType]: Constants.DataMapCollectionKeys.serviceTypes,
};

function parseQuery(queryString: string) {
  const query = {};

  const pairs = queryString.split('&');

  for (const pair of pairs) {
    const matches = pair.match(/([^\=]+)(\=?)(.*)/);

    if (matches) {
      const name = matches[1];
      const value = matches[3];

      query[decodeURIComponent(name)] = value || '';
    }
  }
  return query;
}

export function optimizeQueryParameters({ queryParams }: { queryParams: IURLQuery }) {
  const optimizeQueryParameters: IURLQuery = {};

  for (const name in queryParams) {
    const rawValue = queryParams[`${name}`] || '';
    // TODO (technical debt): although rawValue has a string type, the value might be different (an array for example). The toString call is necessary to prevent crashes until the IUrlQuery casting will work as expected.
    const valueUpper = rawValue.toString().toUpperCase();
    const loweredName = name.toLowerCase();
    const dataMappingName = paramToDataMapKey[`${loweredName}`];

    if (rawValue) {
      if (loweredName === Constants.QueryStrings.country) {
        if (countryCodeToNameMap[`${valueUpper}`]) {
          optimizeQueryParameters[Constants.QueryStrings.country] = valueUpper;
        }
      } else if (loweredName === Constants.QueryStrings.region) {
        if (serviceOffersSupportedStatesCodes[`${valueUpper}`]) {
          optimizeQueryParameters[Constants.QueryStrings.region] = valueUpper;
        }
      } else if (dataMappingName && typeof rawValue === 'string') {
        const value = rawValue
          .split(';')
          .sort()
          .filter((value: string) => urlKeys[`${dataMappingName}`][`${value}`])
          .join(';');

        if (value) {
          optimizeQueryParameters[`${name}`] = value;
        }
      } else {
        optimizeQueryParameters[`${name}`] = rawValue;
      }
    }
  }

  return optimizeQueryParameters;
}

/**
 * This function will remove any non-existing URL values which will not found in the DataMapping file.
 * Hence we will optimize the cache keys and increase cache hits
 * @param originalUrl Original URL from client, composite from path and query params
 * @param paramToDataMapKey Helper function which convert URL query parameter name to DataMapping.UrlKeys collection
 */
export function optimizeUrl(originalUrl: string) {
  const decodedUrl = decodeURIComponent(originalUrl);
  // Helper regex to extract the queryy params
  const matchResult = decodedUrl.match(/([^\?]+)\?(.*)/);

  if (!matchResult) {
    return decodedUrl;
  }

  const [fullMatch, path, queryParamsString] = matchResult;

  if (!fullMatch || !path || !queryParamsString) {
    return decodedUrl;
  }

  const queryParams = parseQuery(queryParamsString);

  const optimizedQueryParameters = optimizeQueryParameters({ queryParams });

  const newQueryString = Object.keys(optimizedQueryParameters)
    .map((name) => `${name}=${optimizedQueryParameters[`${name}`]}`)
    .join('&');

  // Helper regex to return the original url path with the optimized query params
  return decodedUrl.replace(/\?(.*)/, `?${newQueryString}`);
}
