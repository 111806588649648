import * as React from 'react';
import * as PropTypes from 'prop-types';
import SpzaComponent from './spzaComponent';
import { ILocContext, ICommonContext } from '../interfaces/context';
import { getElement } from '../utils/appUtils';
import { Constants } from '../utils/constants';
import { SpzaInstrumentService } from '../services/telemetry/spza/spzaInstrument';
import { getWindow } from '../services/window';
import { ITelemetryData } from '../Models';
import { logger } from '@src/logger';

export interface ILongContentProps {
  // This is prefixed with 'longContent' to give a unique ID for the div
  entityId: string;
  // The content to be displayed
  content: string | JSX.Element;
  // The height which the content should exceed to see a 'More' button
  clipHeight: number;
}

export interface ILongContentState {
  isExpanded: boolean;
  showExpansionButton: boolean;
  // This flag is to avoid an infinite loop on componentDidUpdate.
  // We are introducing the less/more button based on the height of the div.
  // The height of the div is available only when the component successfully mounts/updates.
  // So we MUST set the state in componentDidUpdate.
  // But if we set the state in componentDidUpdate, we are actually updating the component. :)
  // To avoid this infinite loop, we are setting this variable now.
  processed: boolean;
}

// Displays long content with more and less switches
export class LongContent extends SpzaComponent<ILongContentProps, ILongContentState> {
  context: ILocContext & ICommonContext;
  longContentIdPrefix = 'longContent';

  constructor(props: ILongContentProps, context: ILocContext & ICommonContext) {
    super(props, context);
    this.state = {
      // By default every content is expanded
      isExpanded: true,
      // By default we should not show the expansion button
      showExpansionButton: false,
      processed: false,
    };
  }

  changeExpandedState() {
    this.setState((prevState: ILongContentState) => {
      return {
        isExpanded: !prevState.isExpanded,
        showExpansionButton: prevState.showExpansionButton,
        processed: prevState.processed,
      };
    });

    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: this.state.isExpanded ? Constants.Telemetry.Action.Collapse : Constants.Telemetry.Action.Expand,

      // if this is used somewhere else than my reviews need to update it by passing in with props
      // to determine where is 'more' clicked
      actionModifier: Constants.Telemetry.ActionModifier.ReviewItemMoreButton,
    };
    SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
    logger.info('', {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });
  }

  isLongContent() {
    const longContent = getElement(Constants.SearchBy.Id, this.longContentIdPrefix + this.props.entityId);
    return longContent && longContent.offsetHeight > this.props.clipHeight;
  }

  setStateBasedOnContentLength() {
    if (this.isLongContent()) {
      this.setState({
        isExpanded: false,
        showExpansionButton: true,
        processed: true,
      });
    } else {
      // Just updating the processed flag.
      this.setState((prevState: ILongContentState) => {
        return {
          isExpanded: prevState.isExpanded,
          showExpansionButton: prevState.showExpansionButton,
          processed: true,
        };
      });
    }
  }

  componentDidMount() {
    this.setStateBasedOnContentLength();
  }

  componentDidUpdate() {
    if (!this.state.processed) {
      this.setStateBasedOnContentLength();
    }
  }

  UNSAFE_componentWillReceiveProps() {
    // Reset to the original state so that more/less can be recalculated.
    this.state = {
      // By default every content is expanded
      isExpanded: true,
      // By default we should not show the expansion button
      showExpansionButton: false,
      // When new props are received, we have to reprocess this component.
      processed: false,
    };
  }

  renderImpl() {
    if (!this.props.content) {
      return null;
    }

    return (
      <div className="c-content-toggle">
        <p
          // eslint-disable-next-line react/forbid-dom-props
          id={this.longContentIdPrefix + this.props.entityId}
          className={this.state.isExpanded ? 'longContentExpanded' : 'longContentContracted'}
        >
          {<span itemProp="description">{this.props.content}</span>}
        </p>
        {!this.state.showExpansionButton ? null : (
          <button className="expansionButton" onClick={this.changeExpandedState.bind(this)}>
            {this.state.isExpanded ? this.context.loc('LongContent_Less_Text') : this.context.loc('LongContent_More_Text')}
          </button>
        )}
      </div>
    );
  }
}

(LongContent as any).contextTypes = {
  loc: PropTypes.func,
};
