import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Stack, Text } from '@fluentui/react';
import noSearchResultsImg from '@shared/images/noSearchResultsImg.png';
import { Constants } from '@shared/utils/constants';
import { IBuildHrefContext, ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { ITelemetryData } from '@shared/Models';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { TelemetryImage } from './telemetryImage';
import { NoSearchResultsButtons } from './noSearchResultsButtons';
import { logger } from '@src/logger';
import { IState } from '@src/State';

export interface INoSearchResults {
  context: ILocContext & IBuildHrefContext & ILocParamsContext;
  isSearch: boolean;
  isFilter: boolean;
  entityType: Constants.EntityType;
  currentPage: string;
  changeSearchText: (searchvalue: string) => void;
}

export interface INoSearchResultsTelemetry {
  message: string;
  entityType: Constants.EntityType;
  isSearch?: boolean;
  isFilter?: boolean;
}

export const logTelemetry = (currentPage: string, actionModifier: string, telemetryDetails: INoSearchResultsTelemetry): void => {
  const payload: ITelemetryData = {
    page: currentPage,
    action: Constants.Telemetry.Action.Search,
    actionModifier: actionModifier,
    details: JSON.stringify(telemetryDetails),
  };

  SpzaInstrumentService.getProvider().probe<ITelemetryData>(Constants.Telemetry.ProbeName.LogInfo, payload);
  logger.info(payload.details, {
    action: payload.action,
    actionModifier: payload.actionModifier,
    page: payload.page,
  });
};

export const NoSearchResults = ({
  context,
  isSearch = false,
  isFilter = false,
  entityType = Constants.EntityType.App,
  currentPage = '',
  changeSearchText,
}: INoSearchResults) => {
  const { previousSearchText } = useSelector(({ search: { previousSearchText } }: IState) => ({ previousSearchText }));

  useEffect(() => {
    const telemetryDetails: INoSearchResultsTelemetry = {
      message: 'NoSearchResults component is shown to the user',
      entityType,
      isSearch,
      isFilter,
    };
    logTelemetry(currentPage, Constants.Telemetry.ActionModifier.NoSearchResultsPage, telemetryDetails);
  }, [entityType, isFilter, isSearch, currentPage]);

  return (
    <Stack className="NoSearchResultsContainer" tokens={{ childrenGap: 'l1' }}>
      <Stack.Item align="center">
        <TelemetryImage className="NoSearchResultsImg" src={noSearchResultsImg} />
      </Stack.Item>
      <Stack.Item className="NoSearchResultsTitle" align="center">
        <Text
          className="NoSearchResultsTitleText"
          variant="large"
          styles={{
            root: {
              color: '#323130',
              fontWeight: '600',
              lineHeight: '24px',
            },
          }}
        >
          {context.loc('NoSearchResults_NoResultsFound', 'No results found')}
        </Text>
      </Stack.Item>
      <Stack.Item className="NoSearchResultsDescription" align="center">
        <Text
          className="NoSearchResultsDescriptionText"
          variant="medium"
          styles={{
            root: {
              color: '#605e5c',
              lineHeight: '20px',
              whiteSpace: 'pre-wrap',
              textAlign: 'center',
              display: 'flex',
            },
          }}
        >
          {previousSearchText
            ? context.locParams(
                'NoSearchResults_NoResultsFoundDescription',
                [previousSearchText],
                `We couldn't find any results for ${previousSearchText}.\nChange selected filters or try a different search term.`
              )
            : context.loc(
                'NoSearchResults_NoResultsFoundDescription_Without_SearchTerm',
                `We couldn't find any results.\nChange selected filters or try a different search term.`
              )}
        </Text>
      </Stack.Item>
      <Stack.Item align="center">
        <NoSearchResultsButtons
          context={context}
          isSearch={isSearch}
          isFilter={isFilter}
          entityType={entityType}
          currentPage={currentPage}
          changeSearchText={changeSearchText}
        />
      </Stack.Item>
    </Stack>
  );
};
