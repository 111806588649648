import * as favouriteRestClient from '@shared/services/http/userFavouriteRestClient';
import {
  createReceiveUserFavouriteDataAction,
  createUpdateUserFavouriteFetchDataStatusAction,
  createDeleteUserFavouriteItemAction,
  createUpsertUserFavouriteItemAction,
  createFavouriteAppsPricingDataReceivedAction,
} from '@shared/actions/userFavouriteActions';
import { IState } from '../../State';
import { Constants } from '@shared/utils/constants';
import {
  shouldPerformFetchFavourite,
  invalidateFavourites,
  validateFavourites,
  failedDataStatus,
  fetchPricing,
} from '@shared/utils/userFavouriteThunkActionUtils';
import {
  IUserFavouritePostPayload,
  IRawUserFavourites,
  IUserFavouritePostResponse,
  IUserFavouritePostResponseNonCriticalError,
  IUserFavouriteItem,
} from '@shared/interfaces/userFavouriteModels';
import { SharedInstrumentService } from '@shared/services/telemetry/shared/sharedInstrument';
import { getWindow } from '@shared/services/window';
import { ITelemetryData } from '@shared/Models';
import { stringifyError } from '@shared/utils/errorUtils';
import { logger } from '@src/logger';

/**
 * fetch favourite if needed, i.e. favourite entity are invalid
 */
export function fetchUserFavourites() {
  return (dispatch: Function, getState: () => IState) => {
    const favourite = getState().userFavourite;
    const user = getState().users;

    // if user is not signed in, we will
    // 1) skip fetching favourite
    // 2) update fetch status to invalid
    if (!user.signedIn) {
      // dispatch invalidate action only if needed
      if (favourite.fetchDataStatus !== Constants.UserFavourite.FetchDataStatus.Invalid) {
        dispatch(invalidateFavourites());
      }
      return Promise.resolve();
    }

    // if user is signed in, we will
    // 1) skip fetching favourite, if favourite in redux store is already valid, or is performing a fetch in another thread
    // 3) will fetch favourite, if favourite is invalid
    if (!shouldPerformFetchFavourite(favourite.fetchDataStatus)) {
      return Promise.resolve();
    }

    dispatch(
      createUpdateUserFavouriteFetchDataStatusAction({
        fetchDataStatus: Constants.UserFavourite.FetchDataStatus.IsFetching,
      })
    );

    return favouriteRestClient
      .getUserFavouriteItems()
      .then((rawUserFavourites: IRawUserFavourites) => {
        dispatch(
          createReceiveUserFavouriteDataAction({
            ...rawUserFavourites,
          })
        );

        if (getState().apps.pricingPayload) {
          dispatch(createFavouriteAppsPricingDataReceivedAction(getState().apps.pricingPayload));
        }

        dispatch(validateFavourites());

        return Promise.resolve();
      })
      .catch((error) => {
        const payload = {
          page: getWindow()?.location.href,
          action: Constants.Telemetry.Action.FetchUserFavourites,
          actionModifier: Constants.Telemetry.ActionModifier.Error,
          details: JSON.stringify({ error: stringifyError(error) }),
        };
        SharedInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
        logger.error(payload.details, {
          action: payload.action,
          actionModifier: payload.actionModifier,
        });
        dispatch(failedDataStatus());
        return Promise.resolve();
      });
  };
}

/**
 * upsert a favourite given an applicationType, i.e. add or update
 *
 * @param payload consists as same with marketplace api payload, which are
 *      applicationId: application ID of a favourite entity
 *      planId: plan ID of a favourite entity
 *      applicationType: application type of a favourite entity
 *
 * @param entityId if given, it will try to fetch pricing details to obtain its 1st plan Id, if any
 */
export function upsertUserFavourite(payload: IUserFavouritePostPayload, item: IUserFavouriteItem, entityId: string = undefined) {
  return (dispatch: Function, getState: () => IState) => {
    return dispatch(fetchUserFavourites())
      .then(() => {
        const favourite = getState().userFavourite;

        // skip upsertting favourite item, if favourite data is invalid
        if (favourite.fetchDataStatus === Constants.UserFavourite.FetchDataStatus.Invalid) {
          return Promise.resolve();
        }

        // upsert favourite item, if favourite data is valid
        return dispatch(fetchPricing(entityId, payload.applicationType))
          .then((planId: string) => {
            const updatedPayload: IUserFavouritePostPayload = {
              ...payload,
              planId: planId,
            };
            return favouriteRestClient.addUserFavouriteItem(updatedPayload);
          })
          .then((response: IUserFavouritePostResponse | IUserFavouritePostResponseNonCriticalError) => {
            const errorResponse = response as IUserFavouritePostResponseNonCriticalError;

            if (
              errorResponse.nonCriticalError !== Constants.UserFavourite.NotCriticalFailure.ItemExist &&
              errorResponse.nonCriticalError !== Constants.UserFavourite.NotCriticalFailure.MaximumQuotaReached
            ) {
              const postResponse = response as IUserFavouritePostResponse;

              dispatch(
                createUpsertUserFavouriteItemAction({
                  ...postResponse,
                  item,
                })
              );

              return Promise.resolve();
            }
          })
          .catch((error: any) => {
            const payload = {
              page: getWindow()?.location.href,
              action: Constants.Telemetry.Action.UpsertUserFavourite,
              actionModifier: Constants.Telemetry.ActionModifier.Error,
              details: JSON.stringify({ error: stringifyError(error) }),
            };
            SharedInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
            logger.error(payload.details, {
              action: payload.action,
              actionModifier: payload.actionModifier,
            });
            return Promise.resolve();
          });
      })
      .catch((error: any) => {
        const payload = {
          page: getWindow()?.location.href,
          action: Constants.Telemetry.Action.UpsertUserFavourite,
          actionModifier: Constants.Telemetry.ActionModifier.Error,
          details: JSON.stringify({ error: stringifyError(error) }),
        };
        SharedInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
        logger.error(payload.details, {
          action: payload.action,
          actionModifier: payload.actionModifier,
        });

        return Promise.resolve();
      });
  };
}

/**
 * delete favourite
 *
 * @param entityGuid: guid generated to identify a favourite entity
 * @param applicationType: application type of a favourite entity
 */
export function deleteUserFavourite(entityGuid: string, applicationType: Constants.UserFavourite.ApplicationTypes) {
  return (dispatch: Function, getState: () => IState) => {
    return dispatch(fetchUserFavourites())
      .then(() => {
        const favourite = getState().userFavourite;

        // skip deleting favourite item, if favourite data is invalid
        if (favourite.fetchDataStatus === Constants.UserFavourite.FetchDataStatus.Invalid) {
          return Promise.resolve();
        }

        // delete favourite item, if favourite data is valid
        return favouriteRestClient
          .deleteUserFavouriteItem(entityGuid, applicationType)
          .then((response: Constants.UserFavourite.NotCriticalFailure) => {
            if (response !== Constants.UserFavourite.NotCriticalFailure.ItemNotExist) {
              dispatch(
                createDeleteUserFavouriteItemAction({
                  entityGuid: entityGuid,
                  applicationType: applicationType,
                })
              );
            }
            return Promise.resolve();
          })
          .catch((error) => {
            const payload = {
              page: getWindow()?.location.href,
              action: Constants.Telemetry.Action.DeleteUserFavourite,
              actionModifier: Constants.Telemetry.ActionModifier.Error,
              details: JSON.stringify({ error: stringifyError(error) }),
            };
            SharedInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
            logger.error(payload.details, {
              action: payload.action,
              actionModifier: payload.actionModifier,
            });

            return Promise.resolve();
          });
      })
      .catch((error: any) => {
        const payload = {
          page: getWindow()?.location.href,
          action: Constants.Telemetry.Action.DeleteUserFavourite,
          actionModifier: Constants.Telemetry.ActionModifier.Error,
          details: JSON.stringify({ error: stringifyError(error) }),
        };
        SharedInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
        logger.error(payload.details, {
          action: payload.action,
          actionModifier: payload.actionModifier,
        });

        return Promise.resolve();
      });
  };
}
