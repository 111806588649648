import * as React from 'react';
import SpzaComponent from '../spzaComponent';

export const SortOrder = {
  None: 'none',
  Ascending: 'ascending',
  Descending: 'descending',
} as const;

export const SortOrderStyles = {
  NoneStyle: 'c-glyph',
  AscendingStyle: 'c-glyph f-ascending',
  DescendingStyle: 'c-glyph f-descending',
};

export interface ISortedTHProps {
  // This is the name of the property in td which the header is bound to.
  boundProperty: string;
  handleSortChange: any;
  currentlySelected: string;
}

interface ISortedTHState {
  ariaSort: 'ascending' | 'descending' | 'none' | 'other';
  buttonClass: string;
}

export class SortedTH extends SpzaComponent<ISortedTHProps, ISortedTHState> {
  constructor(props: ISortedTHProps) {
    super(props);
    this.state = {
      ariaSort: SortOrder.None,
      buttonClass: SortOrderStyles.NoneStyle,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: ISortedTHProps) {
    if (this.props.boundProperty !== nextProps.currentlySelected) {
      this.setState({
        ariaSort: SortOrder.None,
        buttonClass: SortOrderStyles.NoneStyle,
      });
    }
  }

  handleHeaderClick() {
    let newState: ISortedTHState = {
      ariaSort: SortOrder.None,
      buttonClass: SortOrderStyles.NoneStyle,
    };

    if (this.state.ariaSort === SortOrder.None || this.state.ariaSort === SortOrder.Descending) {
      newState = {
        ariaSort: SortOrder.Ascending,
        buttonClass: SortOrderStyles.AscendingStyle,
      };
    } else {
      newState = {
        ariaSort: SortOrder.Descending,
        buttonClass: SortOrderStyles.DescendingStyle,
      };
    }

    this.setState(newState);
    this.props.handleSortChange(this.props.boundProperty, newState.ariaSort);
  }

  renderImpl() {
    return (
      <th scope="col" className="f-sortable" aria-sort={this.state.ariaSort}>
        <button
          className={this.state.buttonClass}
          aria-pressed="mixed"
          aria-label={`${this.props.children} current sorting mode is ${this.state.ariaSort}`}
          onClick={() => this.handleHeaderClick()}
        >
          {this.props.children}
        </button>
      </th>
    );
  }
}
