import React, { useEffect } from 'react';
// eslint-disable-next-line import/named
import {
  mergeStyleSets,
  Stack,
  Text,
  FontSizes,
  ScreenWidthMinXLarge,
  Spinner,
  ISpinnerStyleProps,
  ISpinnerStyles,
  IStyleFunctionOrObject,
} from '@fluentui/react';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';

const contentStyles = mergeStyleSets({
  itemName: { fontSize: FontSizes.size28, fontWeight: 600, marginTop: '20px', textAlign: 'center' },
  progressContainer: {
    width: '560px',
    height: '280px',
    zIndex: '101',
    backgroundColor: '#FFFFFF',
    position: 'absolute',
    margin: 'auto',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    padding: '120px 80px',
    [`@media (max-width: ${ScreenWidthMinXLarge}px)`]: {
      width: '90%',
      height: '280px',
    },
  },
});

const spinnerStyles: IStyleFunctionOrObject<ISpinnerStyleProps, ISpinnerStyles> = {
  circle: {
    width: '56px',
    height: '56px',
  },
};

interface IBusyLoader {
  context: ILocContext & ILocParamsContext;
}

export const BusyLoader: React.FunctionComponent<IBusyLoader> = ({ context }: IBusyLoader) => {
  const progressMessages = ['Loading_Selection', 'Working', 'Updating'];
  const intervalDelay = 2500;
  const intervalIncrement = 0.1;
  const [progressMessage, setProgressMessage] = React.useState(progressMessages[0]);
  const [percentComplete, setPercentComplete] = React.useState(0);

  useEffect(() => {
    if (percentComplete <= 0.9) {
      const id = setInterval(() => {
        setPercentComplete((intervalIncrement + percentComplete) % 1);
        switch (Math.round((percentComplete * 10) / 2)) {
          case 0:
          case 1:
            setProgressMessage(progressMessages[0]);
            break;
          case 2:
            setProgressMessage(progressMessages[1]);
            break;
          case 3:
            setProgressMessage(progressMessages[2]);
            break;
        }
      }, intervalDelay);
      return () => {
        clearInterval(id);
      };
    }
  }, [percentComplete, progressMessages]);

  return (
    <Stack className={contentStyles.progressContainer} tokens={{ childrenGap: 20 }} verticalAlign="center">
      <Spinner styles={spinnerStyles}></Spinner>
      <Text title={context.loc(progressMessage)} className={contentStyles.itemName}>
        {context.loc(progressMessage)}
      </Text>
    </Stack>
  );
};
