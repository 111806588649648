import * as React from 'react';
let { connect } = require('react-redux');
import { IState } from '../../../State';
import { ErrorModal as ErrorModalComponent } from './../../components/modals/errorModal';

export const mapStateToProps = (state: IState, ownProps: any) => {
  return {
    localizedStrings: state.config.locStrings,
  };
};

const ErrorModal = connect(mapStateToProps)(ErrorModalComponent);

export default ErrorModal as React.StatelessComponent<any>;
