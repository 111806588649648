import * as httpProtocol from './httpProtocol';
import { getAppConfig } from '@shared/services/init/appConfig';
import { logError } from '@shared/utils/httpClientUtil';
import { Constants } from '@shared/utils/constants';

const option: httpProtocol.IHttpOption = {
  clientType: 'webBrowser',
  requireCorrelationId: true,
  stringifyPostData: false,
  allowOrigin: true,
  authenticationType: httpProtocol.AuthenticationTypes.Graph,
  contentType: 'image/jpeg',
  responseType: 'blob',
  returnRawResponse: true,
};

export interface ICheckMembershipResponse {
  value: string[];
}

/**
 * Checks if user is billing admin or global admin
 * Returns an array of RoleDefinitions user is a member of
 */
export function checkAdminMemberShips(): Promise<string[]> {
  const membershipOptions: httpProtocol.IHttpOption = {
    clientType: 'webBrowser',
    requireCorrelationId: true,
    stringifyPostData: true,
    allowOrigin: true,
    authenticationType: httpProtocol.AuthenticationTypes.Graph,
    contentType: 'application/json',
    returnRawResponse: false,
  };

  const endpoint = getAppConfig('graphApi') + `/v1.0/me/checkMemberObjects`;
  const body = {
    ids: Constants.RoleDefinitions.rolesToCheck,
  };
  const post = httpProtocol.post(endpoint, membershipOptions);

  return post
    .setData(JSON.stringify(body))
    .request()
    .then((result: ICheckMembershipResponse) => result.value)
    .catch((error) => {
      logError('graphRestClient.get', error);
      return [];
    });
}
