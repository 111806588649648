import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import { Dropdown, mergeStyleSets } from '@fluentui/react';
import type { IDropdownOption } from '@fluentui/react';
import { ITelemetryData } from '@shared/Models';
import { Constants } from '@shared/utils/constants';
import { ILocContext, IBuildHrefContext } from '@shared/interfaces/context';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { IRouteConfig } from '@shared/routerHistory';
import { windowScrollToTop } from '@shared/utils/filterHelpers';
import { logger } from '@src/logger';
import { BusinessTelemetry } from '@shared/utils/businessConstants';
import sortByImage from '@shared/images/sortByIcon.svg';

const DataBi = BusinessTelemetry.dataBi;

export interface ISortingDropdownProps {
  defaultKey: Constants.SearchSortingOption;
  route: IRouteConfig<any>;
  hide: boolean;
}

const $backgroundColor = '#ffffff';
const $primaryText = '#323130';

const contentStyles = mergeStyleSets({
  dropdownWidth: { minWidth: '150px' },
  SearchSortBy: {
    display: 'flex',
    fontSize: '14px',
    lineHeight: '20px',
    marginTop: 'auto',
    paddingBottom: '4px',

    '& .spza-sub-filter-dropdown': {
      '& .valueBox': {
        outline: 'none',
        width: 'max-content',
        border: 'none',

        '& .item': {
          padding: '3px 7px 3px 3px',
        },

        '.item > span': {
          width: 'max-content',
        },

        '.toggle': {
          width: '24px',
        },
      },
    },
  },
  sortByIconAndText: {
    color: { $primaryText },
    margin: '0px 5px 0px 0px',
    backgroundSize: '16px 16px',
    padding: '5px 0px 0px 24px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left',
    backgroundColor: { $backgroundColor },
    backgroundImage: `url(${sortByImage})`,
    '& .ms-Dropdown:not(.is-open)': {
      '& .ms-Dropdown-title': {
        borderWidth: '0px',
      },
    },
  },
});

export const SortingDropdown: React.FunctionComponent<ISortingDropdownProps> = (
  { defaultKey, route, hide }: ISortingDropdownProps,
  context: ILocContext & IBuildHrefContext
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchByKey, setSearchByKey] = useState(defaultKey);

  const isSortingSupported = route.name !== Constants.Views.cloudsIndustryGallery;
  const location = useLocation();
  const navigate = useNavigate();
  const getSortingOptions = () => {
    let options: IDropdownOption[] = [
      {
        text: context.loc('AppsGlobalFilters_SearchSortingOptions_BestMatch', 'Best match'),
        key: Constants.SearchSortingOption.BestMatch,
      },
      {
        text: context.loc('AppsGlobalFilters_SearchSortingOptions_Popularity', 'Most viewed'),
        key: Constants.SearchSortingOption.Popularity,
      },
    ];

    const areNewSortsSupported = route.name === Constants.Views.appGallery;
    if (areNewSortsSupported) {
      options = [
        ...options,
        {
          text: context.loc('AppsGlobalFilters_SearchSortingOptions_Rating', 'Average rating'),
          key: Constants.SearchSortingOption.RatingAverage,
        },
        {
          text: context.loc('AppsGlobalFilters_SearchSortingOptions_LastUpdated', 'Recent version update '),
          key: Constants.SearchSortingOption.RecentlyUpdated,
        },
      ];
    }
    return options;
  };
  const options: IDropdownOption[] = getSortingOptions();

  const onSortingChangeHandle = (option: IDropdownOption) => {
    const payload: ITelemetryData = {
      page: location.pathname,
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.SortSearchResults,
      details: option.key as string,
    };

    SpzaInstrumentService.getProvider().probe<ITelemetryData>(Constants.Telemetry.ProbeName.LogInfo, payload);
    logger.info(payload.details, payload);

    const sortingLink: string = context.buildHref(route, null, {
      sortBy: option.key === Constants.SearchSortingOption.BestMatch ? null : option.key,
    });

    // Set the state for display to update immediately (testability)
    setSearchByKey(option.key as Constants.SearchSortingOption);
    // Navigate to the URL for functionality
    navigate(sortingLink);
  };

  const onSortingClickHandle = () => {
    const payload: ITelemetryData = {
      page: location.pathname,
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.SortSearchOpened,
    };

    SpzaInstrumentService.getProvider().probe<ITelemetryData>(Constants.Telemetry.ProbeName.LogInfo, payload);
    logger.info('', payload);
    if (!isOpen) {
      setIsOpen((isOpen) => !isOpen);
    }
  };

  const detectKeyboardClick = ({ event: { key } }: { event: React.KeyboardEvent<Element> }) => {
    const currentOptionsIndex = options.findIndex((option) => option.key === searchByKey);
    if (key === Constants.SystemKeyAsString.Enter || key === Constants.SystemKeyAsString.Space) {
      onSortingChangeHandle(options[`${currentOptionsIndex}`]);
    }

    if (key === Constants.SystemKeyAsString.ArrowUp) {
      const nextIdx = currentOptionsIndex - 1 <= 0 ? 0 : currentOptionsIndex - 1;
      setSearchByKey(options[`${nextIdx}`].key as Constants.SearchSortingOption);
    }

    if (key === Constants.SystemKeyAsString.ArrowDown) {
      const nextIdx = currentOptionsIndex + 1 >= options.length ? options.length : currentOptionsIndex + 1;
      setSearchByKey(options[`${nextIdx}`].key as Constants.SearchSortingOption);
    }
  };

  const onDropdownChange = ({ option }: { option: IDropdownOption }) => {
    onSortingChangeHandle(option);
    windowScrollToTop();
  };

  const activeSortByOption: IDropdownOption = options.filter((option) => option.key === searchByKey)[0] || options[0];
  const sortingDropdownClass = classNames({ [contentStyles.dropdownWidth]: isOpen });

  return (
    <>
      {!hide && isSortingSupported && (
        <div className={contentStyles.SearchSortBy}>
          <div className={contentStyles.sortByIconAndText}>
            {context.loc('AppsGlobalFilters_SearchSortingOptions_Title', 'Sort By:')}
          </div>
          <Dropdown
            data-bi-id={DataBi.sortingDropdownId}
            data-bi-name={DataBi.sortingDropdownName}
            selectedKey={activeSortByOption.key}
            options={options}
            onKeyDown={(event: React.KeyboardEvent<Element>) => detectKeyboardClick({ event })}
            ariaLabel={context.loc('AppsGlobalFilters_SearchSortingOptions_AriaLabel', 'Sort by')}
            className={sortingDropdownClass}
            onChange={(_: React.FormEvent<HTMLDivElement>, option: IDropdownOption) => onDropdownChange({ option })}
            onClick={onSortingClickHandle}
            onDismiss={() => {
              setIsOpen((isOpen) => !isOpen);
            }}
            selectedKeys={[activeSortByOption.key.toString()]}
          />
        </div>
      )}
    </>
  );
};

(SortingDropdown as any).contextTypes = {
  loc: PropTypes.func,
  buildHref: PropTypes.func,
};
