import React, { FunctionComponent, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { ILinkedInProductGroup, ILinkedInCustomer, ILinkedInEvent } from '@src/State';
import { Link, Text, mergeStyleSets, TeachingBubble, Stack, DirectionalHint, FontIcon, DefaultButton } from '@fluentui/react';
import type { IButtonProps } from '@fluentui/react';
import Ribbon from '@shared/components/ribbon';
import { LinkedinUpcomingEvent } from '@appsource/components/linkedin/linkedinUpcomingEvent';
import { LinkedinFeaturedCustomer } from '@appsource/components/linkedin/linkedinFeaturedCustomer';
import { CommunicationColors, NeutralColors } from '@fluentui/theme';
import { FeatureFeedback, FeatureFeedbackSubmittedModal, IFeatureFeedbackContent } from '@shared/components/featureFeedback';
import { Constants } from '@shared/utils/constants';
import { getNpsModule } from '@appsource/utils/nps';
import { popupDisplayed, PopupType, shouldDisplayPopup } from '@shared/utils/popupDisplayManager';
import { withRouter, WithRouterProps } from '@shared/routerHistory';
import { SpzaInstrumentService } from 'services/telemetry/spza/spzaInstrument';
import { ITelemetryData } from '@shared/Models';
import { logger } from '@src/logger';

const smallTextSize = 12;
const normalTextSize = 14;
const largeTextSize = 18;

const contentStyles = mergeStyleSets({
  linkedin: {
    minHeight: 200,
    paddingBottom: 20,
    paddingTop: 32,
  },
  featuredCustomersPanel: {
    marginTop: 44,
  },
  featuredCustomersTitle: {
    fontSize: largeTextSize,
    fontWight: 400,
    lineHeight: 24,
  },
  featuredCustomersList: {
    marginTop: 16,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 240px)',
    gridGap: 24,
  },
  featuredCustomersRibbon: {
    marginTop: 16,
    display: 'grid',
    '.ribbon': {
      marginTop: 0,
      '.ribbonTiles': {
        marginLeft: 0,
        '.ribbonSlider': {
          height: 'auto',
        },
        '.slick-list': {
          marginLeft: 16,
        },
      },
    },
  },
  upcomingEventLargeIconImg: {
    position: 'absolute',
    display: 'block',
    width: '100%',
    height: '100%',
  },
  upcomingEventNameText: {
    marginTop: 8,
    fontSize: largeTextSize,
    lineHeight: 24,
    fontWeight: 600,
    color: NeutralColors.gray160,
  },
  upcomingEventNormalText: {
    marginTop: 8,
    fontSize: smallTextSize,
    lineHeight: 16,
    fontWeight: 600,
    color: NeutralColors.gray130,
  },
  upcomingEventLinkButton: {
    width: 212,
    marginTop: 24,
    fontSize: normalTextSize,
    lineHeight: 20,
    fontWeight: 600,
    color: NeutralColors.gray190,
  },
  externallinkIcon: {
    marginLeft: 8,
    height: 24,
    fontSize: normalTextSize,
    color: NeutralColors.gray190,
  },
  upcomingEventsTitlePanel: {
    marginTop: 44,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  emptyEventsPanel: {
    paddingTop: 8,
  },
  emptyEventsIcon: {
    fontSize: 22,
    color: '#69AFE5',
  },
  emptyEventsText: {
    lineHeight: 24,
    fontWeight: 400,
    padding: 8,
    fontSize: normalTextSize,
    color: NeutralColors.gray130,
  },
  upcomingEventsTitle: {
    fontSize: largeTextSize,
    fontWeight: 400,
    lineHeight: 24,
  },
  upcomingEventsLink: {
    marginLeft: 8,
    fontSize: normalTextSize,
    fontWeight: 400,
    lineHeight: 24,
    verticalAlignment: 'center',
    textDecoration: 'underline',
  },
  upcomingEventsList: {
    marginTop: 16,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 512px)',
    gridGap: 24,
  },
  feedbackTitle: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: 24,
  },
  feedbackItem: {
    marginTop: 16,
    fontSize: normalTextSize,
    fontWeight: 400,
    lineHeight: 20,
  },
  feedbackItemBold: {
    marginLeft: 4,
    fontSize: normalTextSize,
    fontWeight: 600,
    lineHeight: 20,
  },
  linkedinLogoIcon: {
    fontSize: 20,
    lineHeight: 20,
    verticalAlignment: 'center',
    color: CommunicationColors.primary,
  },
});

const MAX_CUSTOMERS_SLIDES = 4;

export interface ILinkedinProductGroupProps {
  linkedInProductGroup: ILinkedInProductGroup;
  offerName: string;
  locale: string;
  countryCode: string;
  userEmail: string;
  feedbackScoreRequired: boolean;
  parentTabQuery: string;
}

export interface IFeaturedCustomersListProps {
  linkedInProductGroup: ILinkedInProductGroup;
  offerName: string;
  locale: string;
  countryCode: string;
}

export function FeaturedCustomersList(
  { linkedInProductGroup, offerName, locale, countryCode }: IFeaturedCustomersListProps,
  context: ILocContext & ILocParamsContext
) {
  if (linkedInProductGroup.featuredCustomers?.length > 0) {
    const customerElements = linkedInProductGroup.featuredCustomers.map((customer: ILinkedInCustomer, index: number) => {
      return (
        <LinkedinFeaturedCustomer
          key={'featuredCustomer_' + index.toString()}
          locale={locale}
          countryCode={countryCode}
          customer={customer}
        />
      );
    });

    return (
      <div className={contentStyles.featuredCustomersPanel}>
        <Text className={contentStyles.featuredCustomersTitle}>
          {context.locParams('Linked_Featured_Customers_Title', [offerName])}
        </Text>
        <div>
          {customerElements.length < MAX_CUSTOMERS_SLIDES ? (
            <div className={contentStyles.featuredCustomersList}>{customerElements}</div>
          ) : (
            <div className={contentStyles.featuredCustomersRibbon}>
              <Ribbon carousel>{customerElements}</Ribbon>
            </div>
          )}
        </div>
      </div>
    );
  }
  return null;
}

export interface IUpcomingEventsListProps {
  linkedInProductGroup: ILinkedInProductGroup;
  offerName: string;
  locale: string;
  countryCode: string;
  onExternalLinkClick: () => void;
}

export function UpcomingEventsList(
  { linkedInProductGroup, offerName, locale, countryCode, onExternalLinkClick }: IUpcomingEventsListProps,
  context: ILocContext & ILocParamsContext
) {
  if (linkedInProductGroup.upcomingEvents?.length > 0) {
    return (
      <div>
        <Stack className={contentStyles.upcomingEventsTitlePanel}>
          <Text className={contentStyles.upcomingEventsTitle}> {context.locParams('Linkedin_Events_Title', [offerName])}</Text>
          {linkedInProductGroup.eventsUrl && (
            <Link
              className={contentStyles.upcomingEventsLink}
              target="_blank"
              href={linkedInProductGroup.eventsUrl}
              onClick={onExternalLinkClick}
            >
              {context.loc('Linkedin_Events_Link')}
            </Link>
          )}
        </Stack>
        <div className={contentStyles.upcomingEventsList}>
          {linkedInProductGroup.upcomingEvents.map((event: ILinkedInEvent, index: number) => {
            return (
              <LinkedinUpcomingEvent
                key={'upcomingEvent_' + index.toString()}
                locale={locale}
                countryCode={countryCode}
                event={event}
                onExternalLinkClick={onExternalLinkClick}
              />
            );
          })}
        </div>
      </div>
    );
  } else
    return (
      <Stack>
        <Stack className={contentStyles.upcomingEventsTitlePanel}>
          <Text className={contentStyles.upcomingEventsTitle}> {context.locParams('Linkedin_Events_Title', [offerName])}</Text>
        </Stack>
        <Stack horizontal verticalAlign="center" className={contentStyles.emptyEventsPanel}>
          <FontIcon iconName="Calendar" className={contentStyles.emptyEventsIcon} />
          <Text className={contentStyles.emptyEventsText}>{context.locParams('Linkedin_Events_Empty_Message', [offerName])}</Text>
        </Stack>
      </Stack>
    );
}

export interface IFeedbackSectionProps {
  onFeedbackClick: () => void;
}

export function FeedbackSection({ onFeedbackClick }: IFeedbackSectionProps, context: ILocContext & ILocParamsContext) {
  return (
    <Stack tokens={{ childrenGap: 16 }} horizontalAlign="start">
      <Stack tokens={{ childrenGap: 12 }} horizontal verticalAlign="center">
        <FontIcon iconName="LinkedInLogo" className={contentStyles.linkedinLogoIcon} />
        <Text className={contentStyles.feedbackTitle}>
          {context.loc('Linkedin_Connected_Title', 'Stay connected to the community')}
        </Text>
      </Stack>
      <Text className={contentStyles.feedbackItem}>
        {context.loc(
          'Linkedin_Connected_Content_1',
          'Explore and connect with other companies interested in this topic. The following information is provided directly from LinkedIn.'
        )}
        <span className={contentStyles.feedbackItemBold}>
          {context.loc('Linkedin_Connected_Content_2', 'Let us know if this is useful to you!')}
        </span>
      </Text>
      <DefaultButton
        className={contentStyles.feedbackItem}
        text={context.loc('Linkedin_Feedback_Section_Action', 'Give feedback')}
        onClick={onFeedbackClick}
      />
    </Stack>
  );
}

const LinkedinProductGroupFunction: FunctionComponent<ILinkedinProductGroupProps & WithRouterProps> = (
  {
    linkedInProductGroup,
    offerName,
    locale,
    countryCode,
    userEmail,
    feedbackScoreRequired,
    location,
    parentTabQuery,
  }: ILinkedinProductGroupProps & WithRouterProps,
  context: ILocContext & ILocParamsContext
) => {
  const [feedbackMode, setFeedbackMode] = useState(false);
  const [teachingBubbleMode, setTeachingBubbleMode] = useState(false);
  const [feedbackSentMode, setFeedbackSentMode] = useState(false);
  const featureFeedbackContent: IFeatureFeedbackContent = {
    title: context.loc('Linkedin_Feedback_Dialog_Title', 'We’d love your feedback'),
    scoreTitle: context.loc('Linkedin_Feedback_Score_Title', 'How valuable do you find the information provided by LinkedIn?'),
    scoreMinLabel: context.loc('Linkedin_Feedback_Score_Min_Title', 'Not valuable'),
    scoreMaxLabel: context.loc('Linkedin_Feedback_Score_Max_Title', 'Very valuable'),
    scoreRequired: feedbackScoreRequired,
    checksTitle: context.loc('Linkedin_Feedback_Checks_Title', 'Which of the following contributed to your experience?'),
    checks: [
      {
        key: 'Featured customers',
        text: context.loc('Linkedin_Feedback_Featured_Customers', 'Featured customers'),
      },
      {
        key: 'Upcoming events',
        text: context.loc('Linkedin_Feedback_Upcoming_Events', 'Upcoming events'),
      },
      {
        key: 'Direct link to the LinkedIn community',
        text: context.loc('Linkedin_Feedback_Direct_Link', 'Direct link to the LinkedIn community'),
      },
    ],
    openQuestionFieldTitle: context.loc(
      'Linkedin_Feedback_Open_Question_Title',
      'Which additional information from LinkedIn would you like to see?'
    ),
    openQuestionFieldPlaceholder: context.loc(
      'Linkedin_Feedback_Open_Question_Placeholder',
      'For example, “I’d like to see other members from my community who use this product”.'
    ),
  };

  const logTelemetry = (action: string, actionModifier: string) => {
    const payload: ITelemetryData = {
      page: location?.pathname,
      action,
      actionModifier,
      details: JSON.stringify({ offerId: linkedInProductGroup.offerId }),
    };
    SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
    logger.info(payload.details, {
      page: payload.page,
      action: payload.action,
      actionModifier: payload.actionModifier,
    });
  };

  useEffect(() => {
    if (shouldDisplayPopup(PopupType.LinkedinTeachingBubble)) {
      setTeachingBubbleMode(true);
      popupDisplayed(PopupType.LinkedinTeachingBubble);
      logTelemetry(Constants.Telemetry.Action.TeachingBubble, Constants.Telemetry.ActionModifier.Linkedin);
    }
  }, []);

  const onFeedbackClick = () => {
    setTeachingBubbleMode(false);
    setFeedbackMode(true);
    logTelemetry(Constants.Telemetry.Action.Click, Constants.Telemetry.ActionModifier.LinkedinFeedbackButton);
  };

  const onExternalEventLinkClick = () => {
    logTelemetry(Constants.Telemetry.Action.Click, Constants.Telemetry.ActionModifier.LinkedinEventLink);
  };

  const onFeedbackDismiss = () => {
    setFeedbackMode(false);
    logTelemetry(Constants.Telemetry.Action.Click, Constants.Telemetry.ActionModifier.LinkedinFeedbackDismiss);
    getNpsModule().npsUpdateLastDismissTime();
  };

  const onFeedbackSubmitted = () => {
    setFeedbackMode(false);
    getNpsModule().npsUpdateLastDismissTime();
    setFeedbackSentMode(true);
    logTelemetry(Constants.Telemetry.Action.Click, Constants.Telemetry.ActionModifier.LinkedinFeedbackSubmit);
  };

  const onTeachingBubbleCloseClick = () => {
    setTeachingBubbleMode(false);
  };

  const primaryButtonProps: IButtonProps = {
    children: context.loc('Linkedin_Feedback_Action', 'Give feedback'),
    onClick: onFeedbackClick,
  };
  const secondaryButtonProps: IButtonProps = {
    children: context.loc('Linkedin_Teaching_Bubble_Close_Action', 'Close'),
    onClick: onTeachingBubbleCloseClick,
  };

  const onTeachingBubblePreventDismiss = (event: Event): boolean => {
    return event.type === 'scroll';
  };

  return (
    <div className={contentStyles.linkedin}>
      <FeedbackSection onFeedbackClick={onFeedbackClick} />
      <FeaturedCustomersList
        countryCode={countryCode}
        linkedInProductGroup={linkedInProductGroup}
        offerName={offerName}
        locale={locale}
      />
      <UpcomingEventsList
        countryCode={countryCode}
        linkedInProductGroup={linkedInProductGroup}
        offerName={offerName}
        locale={locale}
        onExternalLinkClick={onExternalEventLinkClick}
      />
      {feedbackMode && (
        <FeatureFeedback
          featureName={Constants.Telemetry.ActionModifier.Linkedin}
          viewContent={featureFeedbackContent}
          onDialogDismissed={onFeedbackDismiss}
          onFeedbackSubmitted={onFeedbackSubmitted}
          email={userEmail}
        />
      )}
      {teachingBubbleMode && (
        <TeachingBubble
          target={parentTabQuery}
          calloutProps={{ directionalHint: DirectionalHint.bottomCenter, preventDismissOnEvent: onTeachingBubblePreventDismiss }}
          primaryButtonProps={primaryButtonProps}
          secondaryButtonProps={secondaryButtonProps}
          onDismiss={() => {
            setTeachingBubbleMode(false);
          }}
          headline={context.loc('Linkedin_Teaching_Bubble_title', 'Stay connected to the community')}
        >
          {context.loc(
            'Linkedin_Teaching_Bubble_text',
            'Explore and connect with other companies interested in this topic. Let us know if this is useful to you!'
          )}
        </TeachingBubble>
      )}
      {feedbackSentMode && (
        <FeatureFeedbackSubmittedModal
          hidden={!feedbackSentMode}
          onDismiss={() => {
            setFeedbackSentMode(false);
          }}
          context={context}
        />
      )}
    </div>
  );
};

export const LinkedinProductGroup = withRouter(LinkedinProductGroupFunction);

(LinkedinProductGroupFunction as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
};

(FeaturedCustomersList as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
};

(UpcomingEventsList as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
};

(FeedbackSection as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
};
