import { useEffect } from 'react';
import { getWindow } from '@fluentui/react';
import { IClarityFunctionNames } from '@shared/components';

export const useMsClarityIdentify = ({ correlationId, spzaId }: { spzaId: string; correlationId: string }) => {
  useEffect(() => {
    if (correlationId && spzaId && getWindow()) {
      getWindow()?.clarity(IClarityFunctionNames.IDENTIFY, spzaId, correlationId);
    }
  }, [spzaId, correlationId]);
};

export const useMsClarityTracking = ({ allowTracking }: { allowTracking: boolean }) => {
  useEffect(() => {
    const currentWindow = getWindow();
    if (allowTracking && currentWindow?.clarity) {
      currentWindow?.clarity(IClarityFunctionNames.CONSENT);
    }
  }, [allowTracking]);
};
