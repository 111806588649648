import qs from 'qs';
import urljoin from 'url-join';
import queryStringUtils from 'query-string';
import { IURLQuery } from '@shared/Models';
import { getAppConfig } from '@shared/services/init/appConfig';
import { getHttpPrefix } from '@src/server/utils/func';

export class Url {
  public host: string;
  public query: IURLQuery;
  public endpoint: string;

  constructor(endpoint: string, query?: IURLQuery, host?: string) {
    this.host = host || getAppConfig('hostname');
    this.endpoint = endpoint;
    this.query = query || {};
  }

  getUrl(stringifyWithQs = false): string {
    if (!this.host) {
      return '';
    }

    let url = this.host.startsWith('http') ? this.host : urljoin(getHttpPrefix(), this.host);
    url += this.endpoint;
    if (this.query) {
      url += '?' + (stringifyWithQs ? qs.stringify(this.query) : queryStringUtils.stringify(this.query));
    }
    return url;
  }
}
