import { DataMap } from '@shared/utils/dataMapping';
import { Service } from '@shared/serviceViewModel';

export function getGalleryPageTitle() {
  return 'servicesGallery_pageTitle';
}

export function getDetailsPageTitle() {
  return 'detail_pageTitle';
}
export function getFilterItemRoute({
  filterItem,
  filterValue,
  filterTileType,
}: {
  filterItem: string;
  filterValue: string;
  filterTileType: string;
}) {
  const filter = DataMap[`${filterItem}`][`${filterValue}`];
  const routeArgs: Record<string, string> = null;
  const params: Record<string, string> = {
    industry: null,
    category: null,
    product: null,
    search: null,
    serviceType: null,
  };
  params[`${filterTileType}`] = filter.UrlKey;

  return {
    routeArgs,
    params,
  };
}

export function isFilterItemMatch({
  service,
  filterItem,
  filterValue,
}: {
  service: Service;
  filterItem: string;
  filterValue: string;
}) {
  return service[`${filterItem}`] & DataMap[`${filterItem}`][`${filterValue}`].FilterID;
}
