import { ApplicationInsights } from '@microsoft/1ds-analytics-web-js';
import type { ITelemetryItem } from '@microsoft/1ds-analytics-web-js';
import sha256 from 'crypto-js/sha256';
import { getWindow } from '@shared/services/window';
import { getSpzaUserIdAndNewUserModifier, getTrailIdAndData } from '@shared/utils/appUtils';
import { Constants } from '@shared/utils/constants';
import type { Store } from 'redux';
import { IState } from '@src/State';

export type LogLevel = 'Info' | 'Debug' | 'Error' | 'Warn';

const OneDSEventNames = {
  ClientError: 'Ms.Web.ClientError',
} as const;

interface LogEventPayload {
  page?: string;
  appName?: string;
  duration?: number;
  [key: string]: unknown;
}

interface LogEvent {
  level: LogLevel;
  collection: string;
  payload: LogEventPayload;
}

type LogMetadata = Record<
  | 'environment'
  | 'appVersion'
  | 'region'
  | 'spzaId'
  | 'hostType'
  | 'locale'
  | 'correlationId'
  | 'mshash'
  | 'userAgent'
  | 'host'
  | 'landingView'
  | 'connectionEffectiveType',
  string
>;

const getCommonData = (): LogMetadata => {
  const initialConfiguration = getWindow().__INITIAL_STATE__.config;

  return {
    environment: initialConfiguration.siteEnvironment?.toLowerCase() || '',
    appVersion: initialConfiguration.appVersion || '',
    region: initialConfiguration.region || '',
    spzaId: getSpzaUserIdAndNewUserModifier().spzaUserId || '',
    hostType: !initialConfiguration.isEmbedded ? Constants.appSource : Constants.appSourceEmbed,
    locale: initialConfiguration.query?.locale || '',
    correlationId: initialConfiguration.correlationId || Constants.ReservedCorrelationIds.EmptyId,
    mshash: getTrailIdAndData()?.trailId || '',
    userAgent: getWindow().navigator?.userAgent || '',
    host: getWindow().location?.host || '',
    landingView: initialConfiguration.landingView || '',
    connectionEffectiveType: (getWindow().navigator as any)?.connection?.effectiveType || '',
  };
};

export abstract class Logger {
  private _1dsLogger: ApplicationInsights;
  private logMetadata: Partial<LogMetadata>;
  private store: Store<IState>;
  private preLoadEvents: LogEvent[] = [];
  private modifyClientError(item: ITelemetryItem) {
    if (item.name !== OneDSEventNames.ClientError) {
      return;
    }

    if (Array.isArray(item.baseData.exceptions)) {
      item.baseData.exceptions = JSON.stringify(item.baseData.exceptions);
    }

    if (Array.isArray(item.tags)) {
      delete item.tags;
    }
  }

  public get logger() {
    return this._1dsLogger;
  }

  public initialize(_1dsLogger: ApplicationInsights, store: Store<IState>) {
    this._1dsLogger = _1dsLogger;
    this.store = store;
    this.logMetadata = getCommonData();
    this._1dsLogger.addTelemetryInitializer(this.modifyClientError);

    Object.entries(this.logMetadata).forEach(([key, value]) => {
      this._1dsLogger.getPropertyManager().setProperty(key, value);
    });

    // Flush preload events - if any
    Promise.resolve().then(() =>
      this.preLoadEvents.forEach(({ level, collection, payload }) => this.log(level, collection, payload))
    );
  }

  protected log(level: LogLevel, collection: string, loggerPayload: LogEventPayload) {
    if (this._1dsLogger && this.logMetadata) {
      try {
        const state = this.store?.getState();
        const { oid, tid, signedIn, isMSAUser, userSegment } = state?.users || {};
        const {
          config: { featureFlags },
        } = state;
        const hashedOid = oid ? sha256(oid).toString().toLowerCase() : '';
        const appName = loggerPayload.appName
          ? loggerPayload.appName
          : state?.modal?.entityId || state.checkout?.items?.[0]?.entityId || '';

        const data = {
          ...loggerPayload,
          browserLanguage: getWindow()?.navigator?.language || getWindow()?.navigator?.userLanguage || '',
          page: loggerPayload.page || getWindow()?.location?.href || '',
          embed: `${getWindow()?.AppsourceMode === 'Embed'}`,
          duration: loggerPayload.duration || -1,
          clientTimestamp: new Date().toISOString(),
          hashedOid,
          tid: tid || '',
          isLoggedIn: `${!!signedIn}`,
          isMSA: `${!!isMSAUser}`,
          accountType: userSegment || '',
          collection,
          level,
          appName,
        };
        this._1dsLogger?.trackEvent(
          {
            name: `${this.logMetadata.environment.toUpperCase()}.${collection}`,
          },
          data
        );

        if (featureFlags.logToConsole) {
          console.log(JSON.stringify(data));
        }
      } catch {}
    } else {
      this.preLoadEvents.push({
        level,
        collection,
        payload: loggerPayload,
      });
    }
  }

  public flush() {
    this._1dsLogger.getPostChannel().flush(false);
  }

  abstract info(loggerPayload: any): void;
  abstract error(loggerPayload: any): void;
  abstract warning(loggerPayload: any): void;
  abstract debug(loggerPayload: any): void;
}
