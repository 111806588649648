import React, { FunctionComponent } from 'react';
import * as PropTypes from 'prop-types';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { ILinkedInCustomer } from '@src/State';
import { numberToLocaleString } from '@shared/utils/localeUtils';
import { Image, ImageFit, mergeStyleSets, Text } from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';

export interface ILinkedinFeaturedCustomerProps {
  customer: ILinkedInCustomer;
  locale: string;
  countryCode: string;
}

const contentStyles = mergeStyleSets({
  featuredCustomer: {
    width: 240,
    height: 190,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: NeutralColors.gray30,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  featuredCustomerLogo: {
    width: 60,
    height: 60,
    position: 'relative',
    overflow: 'hidden',
  },
  featuredCustomerLargeIcon: {
    width: 60,
    height: 60,
    position: 'relative',
    overflow: 'hidden',
  },
  featuredCustomerLargeIconImg: {
    width: '100%',
    height: '100%',
  },
  featuredCustomerNameText: {
    marginTop: 8,
    fontSize: 18,
    lineHeight: 24,
    fontWeight: 600,
    color: NeutralColors.gray160,
  },
  featuredCustomerNormalText: {
    marginTop: 8,
    fontSize: 12,
    lineHeight: 16,
    fontWeight: 600,
    color: NeutralColors.gray130,
  },
});

export const LinkedinFeaturedCustomer: FunctionComponent<ILinkedinFeaturedCustomerProps> = (
  { customer, locale, countryCode }: ILinkedinFeaturedCustomerProps,
  context: ILocContext & ILocParamsContext
) => {
  const { name, logoUrl, businessCategory, followersCount } = customer;

  return (
    <div className="featuredCustomerBox">
      <div className={contentStyles.featuredCustomer}>
        <div className={contentStyles.featuredCustomerLogo}>
          <div className={contentStyles.featuredCustomerLargeIcon}>
            <Image imageFit={ImageFit.contain} className={contentStyles.featuredCustomerLargeIconImg} src={logoUrl} />
          </div>
        </div>
        <Text className={contentStyles.featuredCustomerNameText}>{name}</Text>
        <Text className={contentStyles.featuredCustomerNormalText}>{businessCategory}</Text>
        <Text className={contentStyles.featuredCustomerNormalText}>
          {context.locParams('Linkedin_Followers_Count', [numberToLocaleString({ number: followersCount, locale, countryCode })])}
        </Text>
      </div>
    </div>
  );
};

(LinkedinFeaturedCustomer as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
};
