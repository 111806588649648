import React, { useRef, useState } from 'react';
import { IAppDataItem, IBillingCountry } from '@shared/Models';
import FuturePriceWarning from '@shared/components/futurePriceWarning';
import { SaasPricingTableBase } from '@shared/components';
import { IConfigState, IState } from '@src/State';
import { connect } from 'react-redux';
import { mergeStyleSets } from '@fluentui/react';
import classNames from 'classnames';

interface OwnProps {
  app: IAppDataItem;
  billingCountry: IBillingCountry;
  onGetInNowClicked?: (planId?: string) => void;
  shouldBlockPrices?: boolean;
}

interface StateProps {
  config: IConfigState;
}

type SaasPricingTableProps = OwnProps & StateProps;

const classes = mergeStyleSets({
  limitHeight: {
    height: '70vh',
  },
});


export const SaasPricingTableComponent = ({
  app,
  billingCountry,
  onGetInNowClicked,
  shouldBlockPrices,
  config: { locale },
}: SaasPricingTableProps) => {
  const [isHorizontalScrollbarPresent, setIsHorizontalScrollbarPresent] = useState(false);
  const containerRef = useRef<HTMLDivElement>();
  const { futurePricesInformation: futurePrices } = app;
  // In SSR context there's no body
  const bodyElement = globalThis.document?.body;
  const isDocumentScrollableVertically = bodyElement?.scrollHeight > bodyElement?.clientHeight;

  return (
    <div
      ref={containerRef}
      className={classNames({ [classes.limitHeight]: isDocumentScrollableVertically && isHorizontalScrollbarPresent })}
    >
      <FuturePriceWarning futurePrices={futurePrices} />
      <SaasPricingTableBase
        app={app}
        billingCountry={billingCountry}
        locale={locale}
        onGetInNowClicked={onGetInNowClicked}
        shouldBlockPrices={shouldBlockPrices}
      />
    </div>
  );
};

const mapStateToProps = ({ config }: IState): StateProps => ({ config });

export const SaasPricingTable = connect<StateProps, OwnProps>(mapStateToProps)(SaasPricingTableComponent);
