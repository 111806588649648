import { post, IHttpOption, AuthenticationTypes } from '@shared/services/http/httpProtocol';
import { IItemReport } from '@shared/Models';

const commonHttpOptions: IHttpOption = {
  requireCorrelationId: true,
  stringifyPostData: false,
  authenticationType: AuthenticationTypes.Spza,
  setBearerHeader: true,
};

export const ReportReviewQueryStrings = {
  ApiVersion: 'api-version',
};

export async function createReportReview({
  itemReport,
  communityAPI,
  communityAPIVersion,
}: {
  itemReport: IItemReport;
  communityAPI: string;
  communityAPIVersion: string;
}): Promise<boolean> {
  try {
    await post(`${communityAPI}/report`, commonHttpOptions)
      .addQueryEntry(ReportReviewQueryStrings.ApiVersion, communityAPIVersion)
      .setData(itemReport)
      .request();
    return true;
  } catch (error) {
    return false;
  }
}
