import * as React from 'react';
import { Dialog, DialogFooter, IDialogContentProps, IDialogContentStyles, PrimaryButton } from '@fluentui/react';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';

const dialogContentStyles: Partial<IDialogContentStyles> = {
  subText: {
    whiteSpace: 'pre-line',
  },
};

const dialogContentProps = (context: ILocContext & ILocParamsContext): IDialogContentProps => {
  return {
    title: context.loc('PowerBIVisual_DownloadSampleModal_Header'),
    subText: [
      context.loc('PowerBIVisual_DownloadSampleModal_Body_1'),
      context.loc('PowerBIVisual_DownloadSampleModal_Body_2'),
    ].join('\n'),
    showCloseButton: true,
    styles: dialogContentStyles,
  };
};

export interface IDownloadSampleModalProps {
  context: ILocContext & ILocParamsContext;
  dismissModal: () => void;
}

export const DownloadSampleModal = ({ context, dismissModal }: IDownloadSampleModalProps) => {
  return (
    <Dialog hidden={false} dialogContentProps={dialogContentProps(context)} onDismiss={dismissModal} maxWidth={560}>
      <DialogFooter>
        <PrimaryButton text={context.loc('Common_Ok')} name="button" type="submit" onClick={dismissModal} />
      </DialogFooter>
    </Dialog>
  );
};
