import * as React from 'react';
import * as PropTypes from 'prop-types';
import SpzaComponent from '../../shared/components/spzaComponent';
import { ILocContext, ILocParamsContext, ICommonContext } from '../../shared/interfaces/context';
import { Constants } from '../../shared/utils/constants';
import { IFilterElement } from '../../shared/Models';
import { InternalLink } from '../../shared/components/internalLink';
import * as DetailUtils from '../../shared/utils/detailUtils';
import { urlPush } from '../../shared/routerHistory';

export interface IUsefulInfoProps {
  metadata: UsefulInfoMetadataColumns[];
  ownerType: DetailUtils.OwnerType;
}

export interface UsefulInfoMetadataColumns {
  id: string;
  title: string;
  cells: UsefulInfoMetadataCell[];
  titleFallback?: string;
}
export interface UsefulInfoMetadataCell {
  id: string;
  title?: string;
  cellItems: UsefulInfoMetadataCellItemBase[];
  titleFallback?: string;
}
export interface UsefulInfoMetadataCellItemBase {
  id: string;
  type: UsefulInfoMetadataCellItemType;
  text: string;
  props?: { [key: string]: any };
}

// modal
export interface UsefulInfoMetadataCellItemModal extends UsefulInfoMetadataCellItemBase {
  type: UsefulInfoMetadataCellItemType.Modal;
  getContent: () => JSX.Element;
}

// label
export interface UsefulInfoMetadataCellItemLabel extends UsefulInfoMetadataCellItemBase {
  type: UsefulInfoMetadataCellItemType.Label;
}

// link
export interface UsefulInfoMetadataCellItemLink extends UsefulInfoMetadataCellItemBase {
  type: UsefulInfoMetadataCellItemType.Link;
  entityId: string;
  information: any;
}

// internal link
export interface UsefulInfoMetadataCellItemInternalLink extends UsefulInfoMetadataCellItemBase {
  type: UsefulInfoMetadataCellItemType.InternalLink;
  information: any;
  onClick?: () => void;
}

// filter
export interface UsefulInfoMetadataCellItemFilter extends UsefulInfoMetadataCellItemBase {
  type: UsefulInfoMetadataCellItemType.Filter;
  filter: IFilterElement;
  entityId: string;
  isEmbedded?: boolean;
  nationalCloud?: string;
}

export enum UsefulInfoMetadataCellItemType { // ask Gil where to put it
  Filter,
  Label,
  Link,
  Modal,
  InternalLink,
}

const isUsefulInfoMetadataCellItemFilter = (
  object: UsefulInfoMetadataCellItemBase
): object is UsefulInfoMetadataCellItemFilter => {
  return object.type === UsefulInfoMetadataCellItemType.Filter;
};

const isUsefulInfoMetadataCellItemInternalLink = (
  object: UsefulInfoMetadataCellItemBase
): object is UsefulInfoMetadataCellItemInternalLink => {
  return object.type === UsefulInfoMetadataCellItemType.InternalLink;
};

const isUsefulInfoMetadataCellItemLink = (object: UsefulInfoMetadataCellItemBase): object is UsefulInfoMetadataCellItemLink => {
  return object.type === UsefulInfoMetadataCellItemType.Link;
};

const isUsefulInfoMetadataCellItemModal = (object: UsefulInfoMetadataCellItemBase): object is UsefulInfoMetadataCellItemModal => {
  return object.type === UsefulInfoMetadataCellItemType.Modal;
};

export class UsefulInfo extends SpzaComponent<IUsefulInfoProps, never> {
  context: ILocContext & ILocParamsContext & ICommonContext;

  getLinkElement(item: UsefulInfoMetadataCellItemLink) {
    if (item.information) {
      return (
        <a
          className="c-hyperlink"
          rel="noreferrer"
          target="_blank"
          href={item.information}
          title={this.context.loc(item.text)}
          onClick={() => {
            DetailUtils.generateLinkPayloadAndLogTelemetry(
              this.props.ownerType,
              item.entityId,
              item.id,
              item.information,
              'Default'
            );
          }}
          {...item.props}
        >
          {this.context.loc(item.text)}
        </a>
      );
    } else {
      return null;
    }
  }

  getInternalLinkElement(item: UsefulInfoMetadataCellItemInternalLink) {
    return (
      <InternalLink className="c-hyperlink" href={item.information} onClick={item.onClick} {...item.props}>
        {this.context.loc(item.text)}
      </InternalLink>
    );
  }

  getFilterElement(item: UsefulInfoMetadataCellItemFilter) {
    const filter: IFilterElement = item.filter;
    if (item.nationalCloud) {
      return <div key={filter.title}>{this.context.loc(filter.locKey, filter.title)}</div>;
    } else {
      return (
        <InternalLink
          className="c-hyperlink detailsCategories"
          href={filter.newPath}
          title={this.context.loc(filter.locKey, filter.title)}
          onClick={(e: any) => {
            DetailUtils.generateLinkPayloadAndLogTelemetry(
              DetailUtils.OwnerType.App,
              item.entityId,
              filter.filterItem,
              filter.newPath,
              'Default'
            );
            // The embedded app needs to open these links in a new window
            if (item.isEmbedded) {
              // eslint-disable-next-line security/detect-non-literal-fs-filename
              window.open(window.location.origin + filter.newPath, '_blank');
            } else {
              urlPush(filter.newPath);
              window.scrollTo(0, 0);
            }

            // The preventDefault prevents the route from transitioning and thus refreshing the page.
            e.preventDefault();
          }}
          key={filter.title}
          accEnabled={true}
          {...item.props}
        >
          <span>{this.context.loc(filter.locKey, filter.title)}</span>
          {filter.filterItem === Constants.filterMaps.categories ? (
            <meta itemProp="applicationCategory" content={this.context.loc(filter.locKey, filter.title)} />
          ) : null}
        </InternalLink>
      );
    }
  }

  renderImpl() {
    return (
      <div className="usefulInfo ">
        {this.props.metadata.map((col: UsefulInfoMetadataColumns) => {
          return (
            <div className="usefulInfoColumn" key={col.id}>
              <h3 className="columnTitle">{this.context.loc(col.title, col.titleFallback || col.title)}</h3>
              <div className="columnCells">
                {col.cells
                  .map((cell: UsefulInfoMetadataCell) => {
                    return (
                      <div className="cell" itemProp={cell.id} key={cell.id}>
                        {cell.title && (
                          <header aria-label={cell.title}>
                            {this.context.loc(cell.title, cell.titleFallback || cell.title)}
                          </header>
                        )}
                        {cell.cellItems.map((item: UsefulInfoMetadataCellItemBase) => {
                          if (isUsefulInfoMetadataCellItemFilter(item)) {
                            return this.getFilterElement(item);
                          } else if (isUsefulInfoMetadataCellItemLink(item)) {
                            return this.getLinkElement(item);
                          } else if (isUsefulInfoMetadataCellItemModal(item)) {
                            return item.getContent();
                          } else if (isUsefulInfoMetadataCellItemInternalLink(item)) {
                            return this.getInternalLinkElement(item);
                          } else {
                            return (
                              <span className="cellLabel" key={item.text} {...item.props}>
                                {this.context.loc(item.text)}
                              </span>
                            );
                          }
                        })}
                      </div>
                    );
                  })
                  .filter(Boolean)}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

(UsefulInfo as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  renderErrorModal: PropTypes.func,
};
