import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import type { ThunkDispatch } from 'redux-thunk';
import type { AnyAction } from 'redux';
import { InteractionRequiredAuthError, InteractionStatus, InteractionType } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { logger } from '@src/logger';
import { updateUser } from '@shared/actions/thunkActions';
import { createAuthenticatedUser, createGuestUser } from '@shared/msal/user';
import { Constants } from '@shared/utils/constants';
import { IState } from '@src/State';
import { getExtraScopesToConsent } from '@shared/msal/scopes';
import { fetchAccessTokens } from '@shared/msal/tokens';
import { stringifyError } from '@shared/utils/errorUtils';
import { getSilentRedirectUri } from '@shared/msal/config';
import { getLocalStorageItem, saveLocalStorageItem } from '@shared/utils/browserStorageUtils';

const SilentClientLogin = () => {
  const dispatch = useDispatch<ThunkDispatch<IState, object, AnyAction>>();
  const [hasActiveUser, setHasActiveUser] = useState(false);
  const ssoStartTime = useRef<number>(null);
  const { error, result } = useMsalAuthentication(InteractionType.Silent, {
    redirectUri: getSilentRedirectUri(),
    extraScopesToConsent: getExtraScopesToConsent(),
    loginHint: getLocalStorageItem(Constants.LocalStorage.LoginHint),
  });
  const { accounts, instance, inProgress } = useMsal();
  const activeAccount = accounts?.[0];

  useEffect(() => {
    const setActiveUser = async () => {
      instance.setActiveAccount(activeAccount);
      saveLocalStorageItem(Constants.LocalStorage.LoginHint, activeAccount.idTokenClaims.login_hint);
      const accessTokens = await fetchAccessTokens({
        resources: ['spza', 'arm', 'graph', 'commerce', 'jarvis', 'marketplaceLeads', 'pifd', 'agreement'],
      });
      const user = createAuthenticatedUser({ accessTokens, account: activeAccount, ssoStartTime: ssoStartTime.current });
      dispatch(updateUser({ userDetails: user }));
    };

    if (activeAccount && !hasActiveUser && inProgress === InteractionStatus.None) {
      setHasActiveUser(true);
      setActiveUser();
    }
  }, [activeAccount, hasActiveUser, inProgress, dispatch, instance]);

  useEffect(() => {
    if (error) {
      dispatch(updateUser({ userDetails: createGuestUser() }));
      const stringifiedError = stringifyError(error);
      if (error instanceof InteractionRequiredAuthError) {
        logger.warning(stringifiedError, {
          action: Constants.Telemetry.Action.Login,
          actionModifier: Constants.Telemetry.ActionModifier.SSO_LOGIN_ERROR,
        });
      } else {
        logger.error(stringifiedError, {
          action: Constants.Telemetry.Action.Login,
          actionModifier: Constants.Telemetry.ActionModifier.SSO_LOGIN_ERROR,
          error,
        });
      }
    }
  }, [error]);

  useEffect(() => {
    if (result) {
      logger.info(JSON.stringify({ fromCache: result.fromCache }), {
        action: Constants.Telemetry.Action.Login,
        actionModifier: Constants.Telemetry.ActionModifier.SSO_LOGIN_SUCCEEDED,
      });
    }
  }, [result]);

  useEffect(() => {
    ssoStartTime.current = Date.now();
    logger.info('', {
      action: Constants.Telemetry.Action.Login,
      actionModifier: Constants.Telemetry.ActionModifier.Start,
    });
  }, []);

  return <></>;
};

export default SilentClientLogin;
