import { connect } from 'react-redux';
import { RatingModal as RatingModalComponent } from '@shared/components/modals/ratingModal';
import { submitReview, updateReview, deleteReview } from '@shared/actions/thunkActions';
import { IState } from '@src/State';
import { UserReviewFormData } from '@shared/interfaces/reviews/models';

export const mapStateToProps = (state: IState) => {
  return {
    userInfo: state.users,
    reviewsData: state.apps.appReviewsData,
  };
};

export const mapDispatchToProps = (dispatch: any) => {
  return {
    submitReview: (reviewFormData: UserReviewFormData) => dispatch(submitReview(reviewFormData)),
    deleteReview: (reviewFormData: UserReviewFormData) => dispatch(deleteReview(reviewFormData)),
    updateReview: (reviewFormData: UserReviewFormData) => dispatch(updateReview(reviewFormData)),
  };
};

export const RatingModal = connect(mapStateToProps, mapDispatchToProps)(RatingModalComponent);
