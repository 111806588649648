import configDf from './partnersManifest_df.json';
import configProd from './partnersManifest_prod.json';
import { Environment } from '@shared/utils/constants';
import { getWindow } from '@shared/services/window';

const configsMap = {
  [Environment.Production]: configProd,
  [Environment.Canary]: configProd,
  [Environment.Dogfood]: configDf,
  [Environment.Test]: configDf,
  [Environment.Development]: configDf,
};

export const loadPartnerManifest = () =>
  configsMap[getWindow()?.__INITIAL_STATE__?.config?.siteEnvironment || process.env.siteEnvironment];
