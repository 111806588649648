import { Constants } from './constants';
import { IDataValues, DataMap, IDataCollection, ICategoryList, IDataMap } from './dataMapping';

export type IDataMapArray = IDataValues[];

function dataValuesSubCategoryToMap(dataValues: IDataValues): IDataMapArray {
  const dataMapArray: IDataMapArray = [];

  Object.keys(dataValues.SubCategoryDataMapping).forEach((dataValueKey) => {
    const subDataValues = dataValues.SubCategoryDataMapping[`${dataValueKey}`];

    if (!subDataValues.IsReference) {
      dataMapArray.push(subDataValues);
    }
  });

  return dataMapArray;
}

function dataCollectionToMap(dataCollection: IDataCollection | ICategoryList): IDataMapArray {
  const dataMapArray: IDataMapArray = [];

  Object.keys(dataCollection).forEach((categoryKey) => {
    const dataValues = dataCollection[`${categoryKey}`];

    if (!dataValues.IsReference) {
      dataMapArray.push(dataValues);
    }

    if (dataValues.SubCategoryDataMapping) {
      dataMapArray.push(...dataValuesSubCategoryToMap(dataValues));
    }
  });

  return dataMapArray;
}

export function dataMapToArray(dataMap: IDataMap): IDataMapArray {
  const dataMapArray: IDataMapArray = [];

  Object.keys(dataMap).forEach((dataCollectionKey) => {
    if (dataCollectionKey === Constants.DataMapCollectionKeys.categories) {
      Object.keys(dataMap.categories).forEach((categoryDataKey: string) => {
        const categoryDataMap = dataCollectionToMap(dataMap.categories[`${categoryDataKey}`]);

        dataMapArray.push(...categoryDataMap);
      });
    } else {
      const dataCollectionMap = dataCollectionToMap(dataMap[`${dataCollectionKey}`]);

      dataMapArray.push(...dataCollectionMap);
    }
  });

  return dataMapArray;
}

function getDataCollectionDataValuesFromUrlKey(
  dataCollection: IDataCollection | ICategoryList,
  curatedUrlValue: string
): IDataValues {
  let dataValues: IDataValues;

  Object.keys(dataCollection).forEach((dataKey: string) => {
    if (dataCollection[`${dataKey}`].UrlKey === curatedUrlValue) {
      dataValues = dataCollection[`${dataKey}`];
    }
  });

  return dataValues;
}

export function getDataValuesFromUrlKey(curatedUrlKey: string, curatedUrlValue: string): IDataValues {
  const dataCollectionKey: Constants.DataMapCollectionKeys =
    Constants.CuratedQueryParamsToDataMapCollectionKeyMap[`${curatedUrlKey}`];

  if (!DataMap[`${dataCollectionKey}`]) {
    return null;
  }

  let dataValues: IDataValues;
  if (dataCollectionKey === Constants.DataMapCollectionKeys.categories) {
    Object.keys(DataMap.categories).forEach((categoryDataKey: string) => {
      if (!dataValues) {
        dataValues = getDataCollectionDataValuesFromUrlKey(DataMap.categories[`${categoryDataKey}`], curatedUrlValue);
      }
    });
  } else {
    dataValues = getDataCollectionDataValuesFromUrlKey(DataMap[`${dataCollectionKey}`], curatedUrlValue);
  }

  return dataValues;
}

export function isRequiredPricings() {
  return false;
}
