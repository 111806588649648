import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Nav, Text, SearchBox, ScreenWidthMinXLarge, Stack } from '@fluentui/react';
import type {
  INavLinkGroup,
  INavLink,
  INavStyles,
  INavButtonProps,
  ISearchBoxStyleProps,
  IStyleFunctionOrObject,
  ISearchBoxStyles,
} from '@fluentui/react';
import { IURLQuery, ITelemetryData } from '@shared/Models';
import { ILocContext, ILocParamsContext, ICommonContext, IBuildHrefContext } from '@shared/interfaces/context';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { urlPush } from '@shared/routerHistory';
import SpzaComponent from '@shared/components/spzaComponent';
import { FilterPane as SharedFilterPane } from '@shared/components/filterPane';
import { InternalLink } from '@shared/components/internalLink';
import {
  IDataMap,
  IDataCollection,
  IDataValues,
  ICategoryList,
  IProductValue,
  CategoriesInnerKeys,
} from '@shared/utils/dataMapping';
import { getCategoryListByProductId } from '@shared/utils/datamappingHelpers';
import { Constants, SupportedRegion } from '@shared/utils/constants';
import { getWindow } from '@shared/services/window';
import {
  shouldShowSubFilter,
  sortFilters,
  serviceOffersSupportedCountriesMap,
  sort,
  getResetFiltersHref,
} from '@shared/utils/filterHelpers';
import { serviceOffersSupportedCAStates, serviceOffersSupportedUSStates } from '@shared/utils/serviceOffersSupportedStatesCodes';
import { FilterLink } from '@appsource/components/filterLink';
import { dataMapToArray } from '@shared/utils/dataMappingUtils';
import { getEntityRegistration } from '@shared/utils/entityRegistration';
import { getFilterLink } from '@shared/utils/filterUtils';
import { ServiceCountries } from '../../State';
import { useTelemetry } from '@shared/hooks/useTelemetry';
import { getNpsModule } from '@appsource/utils/nps';
import { logger } from '@src/logger';
import { BusinessTelemetry } from '@shared/utils/businessConstants';
import { listOfUrlKeysToExcludeInAppsource } from '@shared/oneTaxonomy';
import { ExternalLink } from '@shared/components/externalLink';
import { getProductByUrlKey } from '@shared/utils/appUtils';
import { filterHideList, isOneTaxonomyEnabled } from '@shared/oneTaxonomy/utils';
import { isEmptyObject } from '@shared/utils/objectUtils';

// eslint-disable-next-line react-hooks/rules-of-hooks
const [{ pageAction }] = useTelemetry();
export interface IFilterPaneProps {
  dataMap: IDataMap;
  activeFilters: IDataValues[];
  isEmbedded: boolean;
  embedHost: string;
  getFilterLink: (filter: IDataValues | IDataValues, query?: any) => string;
  stateCountryFilterHandler: (countryCode: string, regionCode: string) => Promise<any>;
  entityType: Constants.EntityType;
  countryCode: string;
  regionCode: string;
  params: IURLQuery;
  query: IURLQuery;
  currentView: string;
  countriesList: ServiceCountries;
  isMaccUser: boolean;
}

interface IFilterLink {
  isChild: boolean;
  isActive: boolean;
  localizedName?: string;
  tooltip?: string;
}

interface IFiltersMap {
  [key: string]: IFilterLink;
}

export interface IFilterPaneState {
  filtersExpandedState: { [key: string]: boolean };
  filtersMap: IFiltersMap;
  filterSearchValue: string;
  windowSize: { width?: number };
  hasInit: boolean;
}

const rootGroupName = 'FilterType_Root';
const countryGroupName = 'FilterType_Countries';
const regionGroupName = 'FilterType_States';

export class FilterPane extends SpzaComponent<IFilterPaneProps, IFilterPaneState> {
  context: ILocContext & ILocParamsContext & ICommonContext & IBuildHrefContext;
  navStyles: Partial<INavStyles>;
  instrument = SpzaInstrumentService.getProvider();
  searchStyles: IStyleFunctionOrObject<ISearchBoxStyleProps, ISearchBoxStyles>;

  constructor(props: IFilterPaneProps, context: ILocContext & ILocParamsContext & ICommonContext & IBuildHrefContext) {
    super(props, context);

    this.state = {
      filterSearchValue: '',
      filtersMap: this.getFiltersMap(),
      filtersExpandedState: {},
      windowSize: {},
      hasInit: false,
    };
    this.navStyles = {
      groupContent: {
        '&': {
          marginBottom: 0,
        },
      },
      navItems: {
        [`[name='${countryGroupName}'] + &, [name='${regionGroupName}'] + &`]: {
          maxHeight: 150,
          overflowY: 'auto',
          overflowX: 'hidden',
        },
      },
      link: {
        '&': {
          backgroundColor: 'transparent',
        },
        [`[name='${rootGroupName}'] &`]: {
          fontSize: 18,
          padding: '0 0 0 16px',
        },
      },
      chevronButton: {
        '&': {
          backgroundColor: 'transparent',
        },
        [`[name='${rootGroupName}'] &`]: {
          left: 'auto',
          right: 16,
        },
      },
      chevronIcon: {
        color: 'rgb(50, 49, 48)',
      },
      root: {
        width: 'auto',
      },
    };
    this.searchStyles = {
      root: {
        backgroundColor: 'transparent',
      },
    };
  }

  onMount() {
    this.telemetryComponentDidMount();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.setState({ hasInit: true });
  }

  componentDidUpdate(prevProps: IFilterPaneProps) {
    if (!this.state.hasInit) {
      this.onMount();
    }
    if (this.props.dataMap !== prevProps.dataMap) {
      this.setState({
        filtersMap: this.getFiltersMap(),
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  telemetryComponentDidMount() {
    const window = getWindow();
    const { navigationStart = 0, requestStart = 0 } = getWindow()?.performance?.timing || {};

    if (window) {
      window.loadTimeGalleryFilters = performance.now() - (requestStart - navigationStart);
    }
  }

  updateWindowDimensions = () => {
    this.setState({ windowSize: { width: getWindow()?.innerWidth } });
  };

  getCountryKey(countryCode: string): string {
    return `COUNTRY_${countryCode}`;
  }

  getRegionKey(regionCode: string): string {
    return `REGION_${regionCode}`;
  }

  getFiltersMap(): IFiltersMap {
    const dataMapArray = dataMapToArray(this.props.dataMap);

    const dataMapFilters: IFiltersMap = dataMapArray.reduce(
      (dataMapFilters, dataValues) => ({
        ...dataMapFilters,
        [dataValues.UrlKey]: {
          isChild: dataValues.IsChildFilter,
          isActive: dataValues.isActive,
          localizedName: dataValues.Title,
          tooltip: dataValues.Tooltip,
        },
      }),
      {} as IFiltersMap
    );

    const countriesFilters: IFiltersMap = Object.keys(this.props.countriesList).reduce(
      (dataMapFilters, countryCode) => ({
        ...dataMapFilters,
        [this.getCountryKey(countryCode)]: {
          isChild: true,
          isActive: countryCode === this.props.countryCode,
        },
      }),
      {} as IFiltersMap
    );

    const regionsFilters: IFiltersMap = (this.getSelectedCountryRegions() || []).reduce(
      (dataMapFilters, region) => ({
        ...dataMapFilters,
        [this.getRegionKey(region.code)]: {
          isChild: true,
          isActive: region.code === this.props.regionCode,
        },
      }),
      {} as IFiltersMap
    );

    return {
      ...dataMapFilters,
      ...countriesFilters,
      ...regionsFilters,
    };
  }

  getCurrentProduct(query: IURLQuery): IProductValue {
    if (query && typeof query.product === 'string') {
      const products = query.product.split(';');

      if (products && products.length > 0) {
        const product = products[0].trim();

        const productFilter = getProductByUrlKey({ urlKey: product });

        if (productFilter) {
          const productBitMask: IProductValue = {
            [productFilter.FilterGroup]: productFilter.FilterID,
          };

          return productBitMask;
        }
      }
    }
  }

  parseFiltersMapToFilters<T extends IDataValues>(filtersMap: IDataCollection | ICategoryList, keys?: string[]): T[] {
    return this.parseFilters<T>((keys || Object.keys(filtersMap)).map((filterKey) => filtersMap[`${filterKey}`] as T));
  }

  parseFilters<T extends IDataValues>(filters: T[]): T[] {
    return filters
      .filter((filter) => filter && !listOfUrlKeysToExcludeInAppsource.includes(filter.UrlKey))
      .sort((filterA, filterB) => sortFilters(filterA, filterB));
  }

  getCategoryList(): ICategoryList {
    const currentProduct = this.getCurrentProduct(this.props.query);

    if (currentProduct) {
      const dynamicCategories = getCategoryListByProductId(currentProduct, this.props.dataMap);

      if (dynamicCategories) {
        return dynamicCategories;
      }
    }

    return this.props.dataMap.categories.default;
  }

  filterToLink(filter: IDataValues, links?: INavLink[], parent?: IDataValues): INavLink {
    const key = filter.UrlKey;

    return {
      name: filter.LocKey || filter.Title,
      key,
      url: getFilterLink(this.context, this.props.entityType, filter, parent),
      isExpanded: !!this.state.filtersExpandedState[`${key}`],
      links,
    };
  }

  groupToLink(name: string, key: string, links: INavLink[], tooltip = ''): INavLink {
    return {
      name,
      key,
      url: '',
      isExpanded: !!this.state.filtersExpandedState[`${key}`],
      links,
      tooltip,
    };
  }

  getFilterChildrenItems<T extends IDataValues>(filter: T): INavLink[] {
    if (!(filter && filter.SubCategoryDataMapping)) {
      return [];
    }

    const filterChildren: T[] = this.parseFiltersMapToFilters<T>(filter.SubCategoryDataMapping).filter(
      (categoryChild) => shouldShowSubFilter(categoryChild) && !filterHideList.includes(categoryChild.BackendKey)
    );

    return filterChildren.map((filterChild) => this.filterToLink(filterChild, this.getFilterChildrenItems(filterChild), filter));
  }

  getProductChildrenItems(product: IDataValues): INavLink[] {
    if (!(product && product.ShortcutFilters)) {
      return [];
    }

    const productsMap = this.props.dataMap.products;

    const productChildrenKeys = Object.keys(product.ShortcutFilters).map((key: string) => product.ShortcutFilters[`${key}`]);

    const productChildren: IDataValues[] = this.parseFiltersMapToFilters<IDataValues>(productsMap, productChildrenKeys).filter(
      (productChild) => productChild.Level === product.Level + 1
    );

    return productChildren.map((productChild) =>
      this.filterToLink(productChild, this.getProductChildrenItems(productChild), product)
    );
  }

  getProductsFiltersItems(): INavLink {
    const productsMap = this.props.dataMap.products;

    const products: IDataValues[] = this.parseFiltersMapToFilters<IDataValues>(productsMap).filter(
      (product) => product.Level === 1
    );

    const links = products.map((product) => this.filterToLink(product, this.getProductChildrenItems(product)));

    return this.groupToLink('FilterType_Products', 'Products', links);
  }

  createCategoriesLinks(dataValues: IDataValues[]): INavLink[] {
    return dataValues
      .filter(({ BackendKey }) => !filterHideList.includes(BackendKey))
      .map((datavalue) => this.filterToLink(datavalue, this.getFilterChildrenItems(datavalue)));
  }

  getIndustriesFiltersItems(): INavLink {
    const industriesMap: IDataCollection = this.props.dataMap.industries;

    const industries: IDataValues[] = this.parseFiltersMapToFilters<IDataValues>(industriesMap);

    const links = this.createCategoriesLinks(industries);

    return this.groupToLink('FilterType_Industries', 'Industries', links);
  }

  getCloudIndustriesFiltersItems(): INavLink {
    const { cloudIndustries } = this.props.dataMap;

    const filteredCloudIndustries: IDataValues[] = this.parseFiltersMapToFilters<IDataValues>(cloudIndustries);

    const links = this.createCategoriesLinks(filteredCloudIndustries);

    return this.groupToLink('FilterType_Industries', 'Industries', links);
  }

  getCategoriesFiltersItems(): INavLink {
    const categoryMap: ICategoryList = this.getCategoryList();

    const categories: IDataValues[] = this.parseFilters(
      Object.entries(categoryMap).map(([categoryKey, categoryValue]: [CategoriesInnerKeys, IDataValues]) =>
        categoryValue.IsReference ? this.props.dataMap.categories.default[`${categoryKey}`] : categoryValue
      )
    );

    const links = this.createCategoriesLinks(categories);

    return this.groupToLink('FilterType_Categories', 'Categories', links);
  }

  getTrialsFiltersItems(): INavLink {
    const trialsMap: IDataCollection = this.props.dataMap.trials;

    const trials: IDataValues[] = this.parseFiltersMapToFilters<IDataValues>(trialsMap);

    const links = trials.map((trial) => this.filterToLink(trial));

    return this.groupToLink('Global_Loc_trials', 'Trials', links);
  }

  getPricingModelsFiltersItems(): INavLink {
    const pricingModelMap: IDataCollection = this.props.dataMap.pricingModel;

    const pricingModels: IDataValues[] = this.parseFiltersMapToFilters<IDataValues>(pricingModelMap);

    const links = pricingModels.map((pricingModel) => this.filterToLink(pricingModel));

    return this.groupToLink('Global_Loc_pricing_model', 'PricingModels', links);
  }

  getRatingsFiltersItems(): INavLink {
    const ratingsMap: IDataCollection = this.props.dataMap.ratings;

    const ratings: IDataValues[] = this.parseFiltersMapToFilters<IDataValues>(ratingsMap);

    const links = ratings.map((rating) => this.filterToLink(rating));

    return this.groupToLink('Global_Loc_ratings', 'Ratings', links);
  }

  getAppComplianceFiltersItems(): INavLink {
    const compianceMap: IDataCollection = this.props.dataMap.appCompliance;

    const comlianceTypes: IDataValues[] = this.parseFiltersMapToFilters<IDataValues>(compianceMap);

    const links = comlianceTypes.map((comlianceType) => this.filterToLink(comlianceType));

    return this.groupToLink('Global_Loc_app_compliance', 'Compliance', links, 'Global_Loc_compliance_tooltip');
  }

  getAzureBenefitEligibleItem(): INavLink {
    const azureBenefitEligibleMap: IDataCollection = this.props.dataMap.AzureBenefitEligible;

    const azureBenefitEligibleTypes: IDataValues[] = this.parseFiltersMapToFilters<IDataValues>(azureBenefitEligibleMap);

    const links = (azureBenefitEligibleTypes || []).map((azureBenefitEligibleType) =>
      this.filterToLink(azureBenefitEligibleType)
    );

    return { ...links[0], checkboxTooltip: this.getTooltipContent() };
  }

  getServiceTypesFiltersItems(): INavLink {
    const serviceTypesMap: IDataCollection = this.props.dataMap.serviceTypes;

    const serviceTypes: IDataValues[] = this.parseFiltersMapToFilters<IDataValues>(serviceTypesMap);

    const links = serviceTypes.map((serviceType) => this.filterToLink(serviceType));

    return this.groupToLink('FilterType_ServiceType', 'ServiceTypes', links);
  }

  getCountryFiltersItems(): INavLink[] {
    const route = getEntityRegistration(this.props.entityType).route;

    return Object.keys(this.props.countriesList)
      .filter((countryCode: string) => !!serviceOffersSupportedCountriesMap[`${countryCode}`])
      .map<INavLink>((countryCode: string) => ({
        key: this.getCountryKey(countryCode),
        name: serviceOffersSupportedCountriesMap[`${countryCode}`].name,
        url: this.context.buildHref(route, null, {
          [Constants.FilterQuery.country]: countryCode,
          [Constants.FilterQuery.region]: null,
        }),
      }))
      .sort((a, b) => sort(a.name, b.name));
  }

  getRegionFiltersItems(regions: SupportedRegion[]): INavLink[] {
    const route = getEntityRegistration(this.props.entityType).route;

    return regions.map<INavLink>((region: SupportedRegion) => ({
      key: this.getRegionKey(region.code),
      name: region.name,
      url: this.context.buildHref(route, null, {
        [Constants.FilterQuery.country]: this.props.countryCode,
        [Constants.FilterQuery.region]: region.code,
      }),
    }));
  }

  getLocationFiltersItems(): INavLink {
    const links = [this.groupToLink(countryGroupName, 'country', this.getCountryFiltersItems())];

    const regions = this.getSelectedCountryRegions();

    if (regions) {
      links.push(this.groupToLink(regionGroupName, 'region', this.getRegionFiltersItems(regions)));
    }

    return this.groupToLink('FilterType_Location', 'location', links);
  }

  getSelectedCountryRegions(): SupportedRegion[] {
    let regions: SupportedRegion[];

    const countryCode = this.props.countryCode && this.props.countryCode.toLowerCase();

    if (countryCode === Constants.caCountryCode) {
      return serviceOffersSupportedCAStates;
    } else if (countryCode === Constants.usCountryCode) {
      return serviceOffersSupportedUSStates;
    }

    return regions;
  }

  getFiltersGroups(): INavLinkGroup[] {
    const filters = {
      [Constants.EntityType.Service]: this.getServicesFiltersGroups(),
      [Constants.EntityType.CloudsIndustry]: this.getCloudsIndustryFiltersGroups(),
    };

    let links: INavLink[] = filters[this.props.entityType] || this.getAppsFiltersGroups();

    if (this.state.filterSearchValue.length > 0) {
      links = this.searchInFilterLinks(links);
    }

    links = links.filter(({ links = [], checkboxTooltip }) => {
      return links?.length || checkboxTooltip;
    });

    if (this.isSmallScreen()) {
      links = [this.groupToLink(rootGroupName, rootGroupName, links)];
    }

    return [
      {
        links,
      },
    ];
  }

  getCopilotExtensionsTooltip() {
    return (
      <Stack role={'contentinfo'}>
        <Text variant="small">
          {this.context.loc(
            'Global_Loc_Copilot_extension_tooltip',
            'Discover apps that work with Microsoft Copilot.Currently only showing Teams apps that work with Copilot, but more to come soon.'
          )}
        </Text>
      </Stack>
    );
  }

  getCopilotExtensionsItem(): INavLink {
    const copilotExtensionMap: IDataCollection = this.props.dataMap.CopilotExtension;

    const copilotExtensionTypes: IDataValues[] = this.parseFiltersMapToFilters<IDataValues>(copilotExtensionMap);

    const links = (copilotExtensionTypes || []).map((copilotExtensionType) => this.filterToLink(copilotExtensionType));

    return { ...links[0], checkboxTooltip: this.getCopilotExtensionsTooltip() };
  }

  getAppsFiltersGroups(): INavLink[] {
    const { isMaccUser } = this.props;

    const appsFiltersGroup = [
      this.getProductsFiltersItems(),
      this.getCategoriesFiltersItems(),
      this.getIndustriesFiltersItems(),
      this.getTrialsFiltersItems(),
      this.getPricingModelsFiltersItems(),
      this.getRatingsFiltersItems(),
      this.getAppComplianceFiltersItems(),
      !isEmptyObject(this.props.dataMap.CopilotExtension) && this.getCopilotExtensionsItem(),
    ];
    if (isMaccUser) {
      appsFiltersGroup.push(this.getAzureBenefitEligibleItem());
    }
    return appsFiltersGroup;
  }

  getServicesFiltersGroups(): INavLink[] {
    return [
      this.getLocationFiltersItems(),
      this.getProductsFiltersItems(),
      this.getIndustriesFiltersItems(),
      this.getServiceTypesFiltersItems(),
    ];
  }

  getCloudsIndustryFiltersGroups(): INavLink[] {
    return [
      this.getLocationFiltersItems(),
      this.getProductsFiltersItems(),
      isOneTaxonomyEnabled() ? this.getCloudIndustriesFiltersItems() : this.getIndustriesFiltersItems(),
      this.getCategoriesFiltersItems(),
      this.getTrialsFiltersItems(),
      this.getRatingsFiltersItems(),
    ];
  }

  searchInFilterLinks(links: INavLink[]): INavLink[] {
    const searchWord = this.state.filterSearchValue;

    const canonicalSearch = (link: INavLink): boolean => {
      const isWordFound = link.name && link.name.toLowerCase().indexOf(searchWord.toLowerCase()) >= 0;

      if (!link.links || link.links.length === 0) {
        return isWordFound;
      } else {
        link.links = link.links.filter((subLink) => canonicalSearch(subLink));
        if (link.links.length > 0) {
          link.isExpanded = true;
        }

        if (isWordFound || link.links.length > 0) {
          return true;
        }
      }
    };

    links.forEach((link) => {
      if (!Array.isArray(link.links)) {
        return;
      }

      link.links = link.links.filter(canonicalSearch);
    });

    return links;
  }

  toggleLinkExpanded(item: INavLink) {
    this.setState({
      filtersExpandedState: {
        ...this.state.filtersExpandedState,
        [item.key]: !this.state.filtersExpandedState[item.key],
      },
    });
  }

  toggleLinkIfNeeded(item: INavLink) {
    const filter = this.state.filtersMap[item.key];

    if (!filter) {
      return this.toggleLinkExpanded(item);
    }

    this.setState({
      filtersExpandedState: {
        ...this.state.filtersExpandedState,
        [item.key]: !filter.isActive,
      },
    });
  }

  onNavLinkClick(event: React.MouseEvent<HTMLElement> | React.FormEvent<HTMLElement>, item: INavLink) {
    const payload = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.FilterClick,
      actionModifier: Constants.Telemetry.ActionModifier.FilterPane,
      details: JSON.stringify({
        key: item.key,
        name: item.name,
        url: item.url,
        filterState: !item.url?.includes?.(item.key)
          ? BusinessTelemetry.Filters.Deactivating
          : BusinessTelemetry.Filters.Activating,
      }),
    };

    this.instrument.probe<ITelemetryData>('logTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });

    pageAction(null, {
      content: {
        contentType: Constants.Telemetry.ContentType.LinkClicked,
        areaName: Constants.Telemetry.AreaName.FilterPane,
        contentName: item.key,
      },
    });

    if (event) {
      event.nativeEvent.preventDefault();
    }

    this.toggleLinkIfNeeded(item);

    if (item && item.url) {
      urlPush(item.url);
    }

    getNpsModule()?.increaseActionsCounter('filterActionsCount');
  }

  onLinkExpandClick(event: React.MouseEvent<HTMLElement>, item: INavLink) {
    const payload = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.FilterExpand,
      actionModifier: Constants.Telemetry.ActionModifier.FilterPane,
      details: JSON.stringify({
        key: item.key,
        name: item.name,
        url: item.url,
        filterState: !item.url?.includes?.(item.key)
          ? BusinessTelemetry.Filters.Deactivating
          : BusinessTelemetry.Filters.Activating,
      }),
    };

    this.instrument.probe<ITelemetryData>('logTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });

    pageAction(null, {
      content: {
        contentType: Constants.Telemetry.ContentType.LinkExpanded,
        areaName: Constants.Telemetry.AreaName.FilterPane,
        contentName: item.key,
      },
    });

    this.toggleLinkExpanded(item);
  }

  getResetFilter(): JSX.Element {
    const { activeFilters, entityType } = this.props;
    if (!(activeFilters && activeFilters.length)) {
      return null;
    }

    const redirectHref = getResetFiltersHref(this.context, entityType);

    return (
      <InternalLink
        href={redirectHref}
        className="c-hyperlink resetButton"
        role="button"
        telemetryDetails={BusinessTelemetry.Filters.FiltersCleared}
        accEnabled
      >
        {this.context.loc('Gallery_FilterPane_ClearButton', 'Clear all')}
      </InternalLink>
    );
  }

  filterSearchChange(filterSearchValue: string): void {
    const payload = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.FilterSearchChange,
      actionModifier: Constants.Telemetry.ActionModifier.FilterPane,
      details: JSON.stringify({
        filterSearchValue,
      }),
    };

    this.instrument.probe<ITelemetryData>('logTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });

    this.setState({
      filterSearchValue: filterSearchValue && filterSearchValue.toLowerCase(),
    });
  }

  getActiveChildrenCount(link: INavLink): number {
    if (!link.links) {
      return 0;
    }

    return link.links.reduce((activesCount, currentSubLink: INavLink) => {
      const subFilter = this.state.filtersMap[currentSubLink.key];
      const isSubActive = subFilter && subFilter.isActive;

      return activesCount + (isSubActive ? 1 : 0);
    }, 0);
  }

  isSmallScreen(): boolean {
    return this.state.windowSize?.width <= ScreenWidthMinXLarge;
  }

  renderLink(props: INavButtonProps): JSX.Element {
    const { link } = props;

    const isGroup = !link.url;
    const activeChildren = this.getActiveChildrenCount(link);

    let isChild = false;
    let isActive = false;
    let isRoot = false;
    let localizedName = '';
    let tooltip = '';

    if (isGroup) {
      isActive = activeChildren > 0;
      if (link.tooltip) {
        tooltip = link.tooltip;
      }
    } else {
      const filter = this.state.filtersMap[link.key];

      if (filter) {
        isChild = filter.isChild;
        isActive = filter.isActive;
        localizedName = filter.localizedName;
        tooltip = filter.tooltip;
      }
    }

    let clearAll: JSX.Element = null;
    if (link.key === rootGroupName) {
      isRoot = true;
      clearAll = this.getResetFilter();
    }

    return (
      <FilterLink
        locKey={link.name}
        localizedName={localizedName}
        isRoot={isRoot}
        isGroup={isGroup}
        isChild={isChild}
        isActive={isActive}
        activeChildren={activeChildren}
        searchValue={this.state.filterSearchValue}
        tooltip={tooltip}
        checkboxTooltip={link.checkboxTooltip}
        {...props}
      >
        {clearAll}
      </FilterLink>
    );
  }

  getTooltipContent() {
    return (
      <Stack role={'contentinfo'}>
        <Text variant="small">
          {this.context.loc(
            'Global_Loc_Azure_benefit_tooltip',
            'Purchasing these offers through the marketplace benefits your organization by reducing your existing Azure commitment.'
          )}
        </Text>
        <ExternalLink className="c-hyperlink" target="_blank" href={Constants.AzureBenefitURL} accessibilityEnabled>
          {this.context.loc('Global_Loc_Azure_benefit_tooltip_link', 'Learn more about your benefits')}
        </ExternalLink>
      </Stack>
    );
  }

  renderImpl() {
    const { filterSearchValue } = this.state;
    const filterGroups = this.getFiltersGroups();
    const filterGroupLinksLength = filterGroups[0].links.length;
    const {
      dataMap,
      isEmbedded,
      countryCode,
      countriesList,
      currentView,
      query,
      params,
      regionCode,
      entityType,
      getFilterLink,
      embedHost,
      stateCountryFilterHandler,
    } = this.props;

    if (isEmbedded) {
      return (
        <SharedFilterPane
          dataMap={dataMap}
          getFilterLink={getFilterLink}
          embedHost={embedHost}
          isEmbedded={isEmbedded}
          entityType={entityType}
          stateCountryFilterHandler={stateCountryFilterHandler}
          countryCode={countryCode}
          regionCode={regionCode}
          params={params}
          query={query}
          currentView={currentView}
          countriesList={countriesList}
        />
      );
    }
    return (
      <div className="filterPaneContainer">
        <div className="filterPane">
          <div className="filterPaneHeader">
            <div className="filterPaneTitle">
              <Text variant="large" className="galleryTitle">
                {this.context.loc('Gallery_FilterPane_MainTitle', 'Filters')}
              </Text>
              {this.getResetFilter()}
            </div>
            <div className="filterPaneSearch">
              <SearchBox
                data-bi-id={Constants.Telemetry.Action.FilterSearchChange}
                data-bi-value={filterSearchValue}
                styles={this.searchStyles}
                placeholder={this.context.loc('FilterType_Placeholder', 'Search filters')}
                underlined
                onChange={(event: React.ChangeEvent<HTMLInputElement>, newValue: string) => this.filterSearchChange(newValue)}
                ariaLabel={filterSearchValue}
              />
            </div>
          </div>
          <>
            <div
              role="status"
              aria-label={this.context.locParams(
                'Filter_Pane_Search_Results_Aria_Label',
                [`${filterGroupLinksLength}`],
                `${filterGroupLinksLength} Results found`
              )}
            ></div>
            <Nav
              groups={filterGroups}
              styles={this.navStyles}
              linkAs={(props) => this.renderLink(props)}
              onLinkClick={(event, item) => this.onNavLinkClick(event, item)}
              onLinkExpandClick={(event, item) => this.onLinkExpandClick(event, item)}
              selectedKey=""
            />
          </>
        </div>
      </div>
    );
  }
}

(FilterPane as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  renderErrorModal: PropTypes.func,
  buildHref: PropTypes.func,
};
