import React, { useState } from 'react';
import ProductsComponent from '@shared/components/products';
import { IBuildHrefContext, ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import * as PropTypes from 'prop-types';
import { IAppDataItem, IRatingData, ITelemetryData } from '@shared/Models';
import { IProductValue } from '@shared/utils/dataMapping';
import { getProductsList } from '@shared/utils/detailUtils';
import { TelemetryImage } from '@shared/components/telemetryImage';
import { Stack, Text, mergeStyleSets, DefaultButton, ScreenWidthMinXLarge, BaseButton, Button } from '@fluentui/react';
import type { IStackStyles, IButtonStyles } from '@fluentui/react';
import classNames from 'classnames';
import { NeutralColors } from '@fluentui/theme';
import { bulidStartingPriceElement, getStartingPriceElement, shouldBlockPricing } from '@shared/utils/pricing';
import { BaseCtaButton } from '@shared/components/baseCtaButton';
import { Constants } from '@shared/utils/constants';
import { Link, useLocation } from 'react-router-dom';
import type History from 'history';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { logger } from '@src/logger';
import { RatingsInfo } from '@shared/components/ratings/ratingsInfo';

export interface ILinkedItemProps {
  linkedItem: IAppDataItem;
  productId?: string;
  isInAppDetail?: boolean;
  billingCountryCode?: string;
  locale?: string;
  isSaaSRendering?: boolean;
  ctaButtonClick?: ({
    linkedItem,
    event,
  }: {
    linkedItem: IAppDataItem;
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement>;
  }) => void;
  isLoadingUserData?: boolean;
  getLinkedItemLink?: ({
    linkedItem,
    productId,
    context,
  }: {
    linkedItem: IAppDataItem;
    productId: string;
    context: IBuildHrefContext;
  }) => string;
}

const containerTokens = { childrenGap: 16, padding: 16 };
const verticalItemsTokens = { childrenGap: 8, paddingTop: 16, paddingBottom: 16 };

const linkedItemStyles = mergeStyleSets({
  itemImage: {
    width: 60,
    height: 60,
    display: 'flex',
    backgroundColor: NeutralColors.white,
  },
  linkItemTitle: {
    height: 48,
    fontWeight: 600,
    color: NeutralColors.gray160,
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
  },
  linkItemContainer: {
    justifyContent: 'space-around',
  },
  moreProductsStyle: {
    paddingTop: 3,
  },
  imageStackItemStyle: {
    '& .appTileHoverImage': {
      width: 50,
      height: 50,
      margin: 'auto',
    },
  },
  productsAndStarsStackItemStyle: {
    marginBottom: 8,
  },
  startingPriceElementContainer: {
    maxWidth: '237px',
    display: 'block',
    '& .button-link': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      textAlign: 'start',
    },
    '& .buttonLinkContainer': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      textAlign: 'start',
    },
    '& .dark': {
      marginTop: 0,
    },
    [`@media (max-width: ${ScreenWidthMinXLarge}px)`]: {
      display: 'none',
    },
  },
  starsAndRattingContainer: {
    height: 20,
    '& .ratingInfoSection .detailsRatingStar': {
      marginBottom: 0,
    },
  },
  rattingText: {
    color: NeutralColors.gray130,
  },
  rattingStarText: {
    marginRight: 4,
    padding: 0,
    fontSize: 12,
  },
  pricingAndCtaToken: { marginTop: 12 },
  ctaButton: { maxWidth: 115 },
});

const tileStyle: IStackStyles = {
  root: {
    selectors: {
      '&:hover': {
        backgroundColor: NeutralColors.gray10,
      },
    },
  },
};

const ctaButtonStyle: IButtonStyles = {
  textContainer: {
    overflow: 'hidden',
  },
  label: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};

const getProducts = (products: IProductValue) => {
  return products && Object.keys(products).length > 0 && getProductsList(products);
};

const getRatingsData = (context: ILocContext, ratingsAverage?: number, ratingsNum?: number): IRatingData => {
  return (
    ratingsNum >= 1 && {
      avgRating: ratingsAverage,
      ratingsCount: ratingsNum,
      key: 'internal',
      text: context.loc('Tile_ASRatings', 'ratings'),
    }
  );
};

const logTelemetryInfo = ({ linkedItem, location }: { linkedItem: IAppDataItem; location: History.Location }) => {
  const instrument = SpzaInstrumentService.getProvider();
  const { title, entityId } = linkedItem;

  const payload: ITelemetryData = {
    page: location.pathname,
    action: Constants.Telemetry.Action.Click,
    actionModifier: Constants.Telemetry.ActionModifier.SaaSLinkingTile,
    details: JSON.stringify({ title, entityId }),
  };
  instrument.probe<ITelemetryData>(Constants.Telemetry.ProbeName.LogInfo, payload);
  logger.info('', payload);
};

export const LinkedItem: React.FunctionComponent<ILinkedItemProps> = (
  {
    linkedItem,
    isInAppDetail,
    billingCountryCode,
    isSaaSRendering,
    ctaButtonClick,
    isLoadingUserData,
    getLinkedItemLink,
    productId,
    locale,
  }: ILinkedItemProps,
  context: ILocContext & ILocParamsContext & IBuildHrefContext
) => {
  const [isCardHover, setIsCardHover] = useState(false);
  const location = useLocation();

  const itemProducts = getProducts(linkedItem.products);
  const ratingsData = getRatingsData(context, linkedItem.AverageRating, linkedItem.NumberOfRatings);

  const startingPrice = shouldBlockPricing(linkedItem) ? null : linkedItem.startingPrice;
  const pricingElement = getStartingPriceElement({
    app: linkedItem,
    billingCountryCode,
    startingPrice,
    context,
    locale,
  });

  return (
    <Link
      to={getLinkedItemLink({ linkedItem, productId, context })}
      onClick={() => {
        logTelemetryInfo({ linkedItem, location });
      }}
    >
      <Stack
        tokens={containerTokens}
        horizontal
        className={classNames({ 'ms-depth-8': isInAppDetail })}
        onMouseEnter={() => setIsCardHover(true)}
        onMouseLeave={() => setIsCardHover(false)}
        styles={tileStyle}
      >
        <Stack.Item className={classNames([linkedItemStyles.imageStackItemStyle])}>
          <TelemetryImage
            src={linkedItem.iconURL}
            alt="linked item icon"
            className={classNames([linkedItemStyles.itemImage], 'ms-depth-8')}
            imgClassName="appTileHoverImage"
          />
        </Stack.Item>
        <Stack.Item grow={1}>
          <Stack tokens={verticalItemsTokens} className={linkedItemStyles.linkItemContainer}>
            <Stack.Item>
              <Text variant="large" className={classNames([linkedItemStyles.linkItemTitle])}>
                {linkedItem.title}
              </Text>
            </Stack.Item>
            {itemProducts?.length && (
              <Stack.Item grow={1} className={classNames([linkedItemStyles.moreProductsStyle])}>
                <ProductsComponent withTooltip textSize="smallPlus" withText productsItems={itemProducts} />
              </Stack.Item>
            )}
            <Stack.Item className={classNames([linkedItemStyles.starsAndRattingContainer])}>
              {ratingsData && (
                <RatingsInfo
                  avgRating={ratingsData.avgRating}
                  ratingsCount={ratingsData.ratingsCount}
                  key={ratingsData.key}
                  title={ratingsData.text}
                  context={context}
                  textSize="smallPlus"
                  starClass={classNames([linkedItemStyles.rattingStarText])}
                  ratingClass={classNames([linkedItemStyles.rattingText])}
                />
              )}
            </Stack.Item>
            <Stack.Item>
              <Stack horizontal className={classNames([linkedItemStyles.pricingAndCtaToken])}>
                <Stack.Item className={linkedItemStyles.startingPriceElementContainer} title={pricingElement?.value}>
                  {bulidStartingPriceElement({
                    app: linkedItem,
                    pricingLinkClassName: 'dark',
                    billingCountryCode,
                    context,
                    locale,
                  })}
                </Stack.Item>
                <Stack.Item disableShrink>
                  {isSaaSRendering ? (
                    <DefaultButton
                      primary={isCardHover}
                      text={context.loc('Button_LearnMore', 'Learn more')}
                      onClick={(event) => {
                        ctaButtonClick({ linkedItem, event });
                      }}
                      className={classNames([linkedItemStyles.ctaButton])}
                      styles={ctaButtonStyle}
                      title={context.loc('Button_LearnMore', 'Learn more')}
                    />
                  ) : (
                    <BaseCtaButton
                      app={linkedItem}
                      billingCountryCode={billingCountryCode}
                      startingPrice={linkedItem.startingPrice}
                      isLoadingUserData={isLoadingUserData}
                      CtaComponent={
                        <DefaultButton
                          primary={isCardHover}
                          text={context.loc('CTA_Get', 'Get it now')}
                          onClick={(event) => {
                            ctaButtonClick({ linkedItem, event });
                          }}
                          className={classNames([linkedItemStyles.ctaButton])}
                          styles={ctaButtonStyle}
                          title={context.loc('CTA_Get', 'Get it now')}
                        />
                      }
                      ctaType={Constants.CTAType.Get}
                    />
                  )}
                </Stack.Item>
              </Stack>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </Link>
  );
};

(LinkedItem as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  buildHref: PropTypes.func,
};
