import { ILeadPayload, ILeadCustomerInfo, ICampaigns } from '@shared/Models';
import { Constants } from '@shared/utils/constants';
import { DataMap } from '@shared/utils/dataMapping';
import deepCopy from 'deepcopy';
import { logger } from '@src/logger';
import sha256 from 'crypto-js/sha256';

const getLeadSourceAndSubSource = (
  isEmbedded: boolean,
  embedHost: string
): {
  leadSource: Constants.LeadSourceType;
  leadSubSource?: Constants.LeadSubSourceType;
} => {
  let leadSource: Constants.LeadSourceType = Constants.LeadSource.SPZA;
  let leadSubSource: Constants.LeadSubSourceType;

  if (isEmbedded) {
    if (embedHost === DataMap.products.Dynamics365forSales.UrlKey) {
      leadSource = Constants.LeadSource.D365Sales;
    } else if (embedHost === DataMap.products.Dynamics365forBusinessCentral.UrlKey) {
      leadSource = Constants.LeadSource.D365BusinessCentral;
    } else if (embedHost === DataMap.products.AdminPortal.UrlKey) {
      leadSource = Constants.LeadSource.Office;
      leadSubSource = Constants.LeadSubSource.Admin;
    } else if (embedHost === DataMap.products.PowerBI.UrlKey) {
      leadSource = Constants.LeadSource.PowerBI;
    }
  }

  return {
    leadSource,
    leadSubSource,
  };
};

export function createLeadPayload({
  entityId,
  privateApp,
  ctaType,
  actionCode,
  campaigns,
  customerInfo,
  notes,
  uniquePlanId,
  isEmbedded,
  embedHost,
}: {
  entityId: string;
  privateApp: boolean;
  ctaType?: Constants.CTAType;
  actionCode?: string;
  campaigns: ICampaigns;
  customerInfo?: ILeadCustomerInfo;
  notes?: string;
  uniquePlanId?: string;
  isEmbedded?: boolean;
  embedHost?: string;
}): ILeadPayload {
  const { leadSource, leadSubSource } = getLeadSourceAndSubSource(isEmbedded, embedHost);

  const leadPayload: ILeadPayload = {
    uniqueProductId: entityId ?? '',
    uniquePlanId,
    callToAction: ctaType ? Constants.ActionStrings[Constants.CTAType[`${ctaType}`]] : undefined,
    actionCode: actionCode ?? undefined,
    category: privateApp ? Constants.LeadCategory.Private : Constants.LeadCategory.Public,
    notes,
    customerInfo,
    leadSource,
    leadSubSource,
  };

  if (campaigns) {
    leadPayload.campaigns = campaigns;
  }

  return leadPayload;
}

function generateHashCode(input: string) {
  if (!input) {
    return input;
  }
  const hashBytes = sha256(input).toString().toUpperCase();
  const hashCode = hashBytes.replace('-', '');
  return hashCode.substring(0, 8);
}

function scrubPiiField(piiField = '') {
  return piiField?.length ? `length: ${piiField.length}, Hash: ${generateHashCode(piiField)}` : piiField;
}

function scrubPii(user: ILeadCustomerInfo) {
  Constants.PIIFields.forEach((piiField) => {
    if (user[`${piiField}`]) {
      user[`${piiField}`] = scrubPiiField(user[`${piiField}`]);
    }
  });
}

export function scrubLeadPayload(payload: ILeadPayload) {
  try {
    const payloadCopy = deepCopy(payload);
    if (payloadCopy.customerInfo) {
      scrubPii(payloadCopy.customerInfo);
    }

    payloadCopy.notes = scrubPiiField(payloadCopy.notes);
    return payloadCopy;
  } catch (error) {
    logger.error(`Failed to scrubLeadPayload with error ${error.error.message}`);
    return {};
  }
}
