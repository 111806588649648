import { IState } from '../../State';
import * as httpProtocol from '@shared/services/http/httpProtocol';
import { createPowerBIDataReceivedAction, createPartnerAppDataReceivedAction } from '@shared/actions/actions';
import { logPageLoadFinished } from '../embedMessaging';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { ITelemetryData } from '@shared/Models';
import { Constants } from '@shared/utils/constants';
import { DataMap } from '@shared/utils/dataMapping';
import { logger } from '@src/logger';
import type { Dispatch } from 'redux';
import { getProductByUrlKey } from '@shared/utils/appUtils';

const PUBLISH_RETRY_COUNT = 2;

interface IEmbedHostData {
  context: any;
  accessToken: string;
  getAppsEndpoint: string;
}

export function finishProcessingAppData(dispatch: Dispatch, embedHost: string, apps: any[]): any {
  const payload: ITelemetryData = {
    page: `In App Gallery(${getProductByUrlKey({ urlKey: embedHost })?.UrlKey})`,
    action: Constants.Telemetry.Action.NetworkCall,
    actionModifier: Constants.Telemetry.ActionModifier.End,
    details: '[End] Partner App data is received. Data dispatched for parsing',
  };
  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
  logger.info(payload.details, payload);

  dispatch(
    createPartnerAppDataReceivedAction({
      appData: apps,
      embedHost,
    })
  );

  logPageLoadFinished(embedHost);

  return apps;
}

export function fetchEmbedAppData(embedHost: string, hostData: IEmbedHostData) {
  return function (dispatch: Dispatch) {
    const getAppsEndpoint = hostData.getAppsEndpoint;
    if (!getAppsEndpoint) {
      throw new Error('No getAppsEndpoint specified');
    }

    const options: httpProtocol.IHttpOption = {
      authenticationType: httpProtocol.AuthenticationTypes.Unauthenticated,
      parseResult: true,
      retry: PUBLISH_RETRY_COUNT,
    };

    return new Promise((resolve, reject) => {
      httpProtocol
        .get(getAppsEndpoint, options)
        .setAuthHeader(hostData.accessToken)
        .setHeader('Accept', 'application/json')
        .setHeader('Context', JSON.stringify(hostData.context))
        .request()
        .then(
          (result: any) => {
            const appData = finishProcessingAppData(dispatch, embedHost, result.applications);
            resolve(appData);
          },
          (error: any) => {
            reject(error);
          }
        );
    });
  };
}

// TODO: Add error handling for PowerBI failure
export function fetchPowerBIData(hostData: any) {
  return function (dispatch: Dispatch, getState: () => IState) {
    const state = getState();
    // TODO: this should probably be api.powerbi.com for the default
    let pbiApi = 'https://api.powerbi.com/v1.0/spza/applications';
    if (hostData.backendUrlOverride && hostData.backendUrlOverride.length > 1) {
      pbiApi = hostData.backendUrlOverride + '/v1.0/spza/applications';
    }

    const options: httpProtocol.IHttpOption = {
      authenticationType: httpProtocol.AuthenticationTypes.Unauthenticated,
      parseResult: true,
      allowOrigin: true,
      retry: PUBLISH_RETRY_COUNT,
    };

    return new Promise((resolve, reject) => {
      httpProtocol
        .get(pbiApi, options)
        .setAuthHeader(hostData.accessToken)
        .setHeader('Accept', 'application/json')
        .setHeader('ActivityId', state.config.correlationId)
        .setHeader('X-PowerBI-User-GroupId', hostData.context.groupId)
        .request()
        .then(
          (appData: any) => {
            // This event is used to indicate that the Power BI data is fetched. Parsing and rendering starts after this step
            const payload: ITelemetryData = {
              page: 'In App Gallery(PowerBI)',
              action: Constants.Telemetry.Action.NetworkCall,
              actionModifier: Constants.Telemetry.ActionModifier.End,
              details: '[End] PowerBI data is received. Data dispatched for parsing',
            };
            SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
            logger.info(payload.details, {
              action: payload.action,
              actionModifier: payload.actionModifier,
              page: payload.page,
            });
            dispatch(
              createPowerBIDataReceivedAction({
                appData: appData.applications,
              })
            );

            logPageLoadFinished(DataMap.products.PowerBI.UrlKey);
            resolve(appData);
          },
          (error: any) => {
            reject(error);
          }
        );
    });
  };
}
