import { inspect } from 'util';
import { pick } from 'lodash-es';

export function parseError(error: any): Record<string, string> {
  if (error instanceof Error) {
    return pick(error, Object.getOwnPropertyNames(error));
  } else {
    return { message: inspect(error) };
  }
}

export function stringifyError(error: any) {
  return JSON.stringify(parseError(error));
}
