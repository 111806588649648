import * as React from 'react';
import SpzaComponent from './spzaComponent';
import Animation from './animation';

export default class Loader extends SpzaComponent<any, any> {
  renderImpl() {
    return (
      <div className="animation">
        <Animation isCircular />
      </div>
    );
  }
}
