import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router';
import classNames from 'classnames';
import { Stack } from '@fluentui/react';

import SpzaComponent from '@shared/components/spzaComponent';
import { IAppDataItem, ITelemetryData, IURLQuery } from '@shared/Models';
import { IUserDataState } from '@src/State';

import Modal from '@shared/containers/modals/modal';
import { IBuildHrefFn } from '@shared/interfaces/context';
import {
  isJsonString,
  logTenantInfo,
  getSpzaUserIdAndNewUserModifier,
  isEmbedded as serverIsEmbedded,
  getTrailIdAndData,
  getHostType,
  getAppTelemetryDetailContentText,
} from '@shared/utils/appUtils';
import { handleCTACallback } from '@shared/handlers/appViewHandler';
import { Footer } from '@shared/containers/footer';
import { WithRouterProps, removeUserUniqueQueryParams } from '@shared/routerHistory';
import { initializeNpsModule } from '@appsource/utils/nps';
import { closeAzureHeaderNavDropdown, forceAzureHeaderNavStatic } from '@shared/utils/appViewUtils';
import { Nps } from '@appsource/components/nps';
import { logger } from '@src/logger';
import { getWindow } from '@shared/services/window';
import { Constants } from '@shared/utils/constants';
import { shouldShowCookieBanner } from '@shared/utils/cookieBannerUtils';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { SharedInstrumentService } from '@shared/services/telemetry/shared/sharedInstrument';
import { NpsModule } from '@shared/utils/npsUtils';
import { CookieBanner } from '@shared/containers/cookieBanner';
import { handleSignUp } from '@shared/utils/signin';
import Loader from './loader';
import { stringifyError } from '@shared/utils/errorUtils';
import SilentClientLogin from './SilentClientLogin';
import { MsalProvider } from '@azure/msal-react';
import AuthClient from '@shared/msal/client';
import { logoutUser } from '@shared/msal/logout';

const RemoteHeader = React.lazy(() => import('./FederatedModules/RemoteHeader'));

const EmbedHeader = React.lazy(() =>
  import('@embed/containers/header').then(({ default: EmbedHeader }) => ({
    default: React.memo(({ props }: { props: Record<string, unknown> }) => <EmbedHeader {...props} />),
  }))
);

export interface IAppViewProps extends WithRouterProps {
  isConverged: boolean;
  initLoading: boolean;
  localizedStrings: any;
  locale: string;
  isEmbedded: boolean;
  embedHost: string;
  buildHref: IBuildHrefFn;
  showModal: boolean;
  performedSubsetQueryString: string;
  searchSortingOption: Constants.SearchSortingOption;
  locationQuery: IURLQuery;
  userInfo: IUserDataState;
  storefrontName: string;
  telemetryLoggedCount: number;
  entityType: any;
  isInErrorDialog: boolean;
  nationalCloud: string;
  isMobile: boolean;
  currentView: string;
  tileDataRequestId: string;
  enableNewNps: boolean;
  npsLogicVerified: boolean;
  allApps: IAppDataItem[];
  correlationId: string;
  getUserAuthStatus: () => Promise<any>;
  showCTAModal: (params: {
    entityId: string;
    entityType: Constants.EntityType;
    ctaType: Constants.CTAType;
    skuId?: string;
    planId?: string;
    isOpenedFromPDP?: boolean;
    ribbonKey?: string;
  }) => void;
  showContactModal: (entityId: string, crossListingAppcontext?: IAppDataItem, callback?: any) => void;
  showErrorModal: () => void;
  showNPSModal: () => void;
  setUserLogin: () => void;
  showAnimationModal: () => void;
  showRaingsModal: (app: IAppDataItem, accessKey: string, ctaType: string, callback: any) => void;
  showReportAbuseModal: (appId: string, reviewId: string, reviewTitle: string, reviewSource: string) => void;
  openTile: (detailUrl: string) => void;
  updateTelemetryLoggedCount: () => void;
  uiRole: Constants.UiRole;
  searchSortingOptionChanged(searchSortingOption: Constants.SearchSortingOption): void;
  changeSearchText(searchValue: string): void;
  updateQuery(query: IURLQuery): void;
  performSearchAll(seachText: string, searchSortingOption: Constants.SearchSortingOption): Promise<any>;
  clearSearch(): void;
  openSignInModal(): void;
  setIsNpsLogicVerified(verified: boolean): void;
  onMarketChange: (regionCode: string) => Promise<void>;
  onLocaleChange: (locale: string) => Promise<void>;
  dispatch: any;
  regionCode: string;
  regionDisabled: boolean;
  favoritesCount: number;
  homeAccountId: string;
  pcaInstanceInitialized: boolean;
}

interface AppViewState {
  isMounted: boolean;
}

export class AppView extends SpzaComponent<IAppViewProps, AppViewState> {
  private showCookieBanner = false;
  private instrument: ReturnType<typeof SpzaInstrumentService.getProvider>;

  private headerRef: React.RefObject<HTMLDivElement> = null;

  constructor(props: IAppViewProps) {
    super(props);

    this.instrument = SpzaInstrumentService.getProvider();
    // retrieve the query parameters from URI.
    props.updateQuery(props.locationQuery);
    this.headerRef = React.createRef();
    this.state = {
      isMounted: false,
    };
  }

  performSearchIfNeeded(props: IAppViewProps) {
    // The embedded app doesn't use Azure search, just live filtering
    // But, for embedded OCP we are using the Azure search
    if (props.isEmbedded) {
      return;
    }

    // you might wonder why we are executing the search here, instead of on the server or in the
    // search component. This is because we also want to make sure we execute the search if someone
    // uses the back button, which does not result in the search component searching, nor in the
    // server to be hit for a new request.

    // this route can work with the following params:
    // 1. filtering
    // 2. search

    // in the case of filtering: the filtering pane takes care of everything
    // in the case of searching: we should make sure that the right data is fetched

    // we need to perform the search if:
    // 1. we have a search query in the params
    // 2. that search query is different from the search query used to build the subset data with
    const urlQuery = props.locationQuery;
    const search = urlQuery?.search;
    const subscetQueryParamChanged = search !== props.performedSubsetQueryString;
    const sortingQueryParam: Constants.SearchSortingOption =
      urlQuery && urlQuery[Constants.FilterQuery.sortBy]
        ? (urlQuery[Constants.FilterQuery.sortBy] as Constants.SearchSortingOption)
        : Constants.SearchSortingOption.BestMatch;

    if (search) {
      const sortingQueryParamChanged = sortingQueryParam !== props.searchSortingOption;
      let shouldPerformSearchAll = false;

      shouldPerformSearchAll = search.length >= 2 && (subscetQueryParamChanged || sortingQueryParamChanged);

      if (sortingQueryParamChanged) {
        props.searchSortingOptionChanged(sortingQueryParam);
      }

      if (shouldPerformSearchAll) {
        // we are rendering this page while we do not have the correct subset of data
        props.performSearchAll(search, sortingQueryParam);
      }
    } else if (props.performedSubsetQueryString && props.performedSubsetQueryString !== '') {
      props.clearSearch();
    }
  }

  errorHandling(error: Event) {
    try {
      // The error object here vs the one that is used in the main client hook up are different adding this logging
      // to see what are the diff types of error we are showing to later decide on when not to show the error modal for errors not raised by our components
      const payload: ITelemetryData = {
        page: getWindow().location.href,
        action: Constants.Telemetry.Action.ErrorModal,
        actionModifier: Constants.Telemetry.ActionModifier.Info,
        appName: 'N.A',
        details: error ? stringifyError(error) : 'Error object null',
      };
      this.instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', payload);
      logger.info(payload.details, {
        action: payload.action,
        actionModifier: payload.actionModifier,
        appName: payload.appName,
      });
      // TODO : Remove this hack after the Power BI null images issue is sorted out
      let showError = true;
      if (
        error.target[`${'nodeName'}`] &&
        error.srcElement[`${'nodeName'}`] &&
        (error.target[`${'nodeName'}`].toString() === 'image' ||
          error.srcElement[`${'nodeName'}`].toString() === 'image' ||
          error.target[`${'nodeName'}`].toString() === 'IMG' ||
          error.srcElement[`${'nodeName'}`].toString() === 'IMG')
      ) {
        showError = false;
      }
      // We dont wanna show errors for an empty or isTrusted error object as the site is still expected to be functional
      if ((error && Object.getOwnPropertyNames(error).length === 0) || (error && error.isTrusted && error.isTrusted === true)) {
        showError = false;
      }
      if (showError) {
        this.props.showErrorModal();
      }
    } catch (err) {
      console.log('something went wrong while showing error modal' + err);
    }
  }

  // We need to remove all the event listeners once the component is unmounted.
  componentWillUnmount() {
    window.onbeforeunload = null;
    window.onpopstate = null;
    // getWindow().removeEventListener('error', this.errorHandling.bind(this), false);
  }

  // We have 3 steps in the auto sign-in behavior.
  // Step 1: Make a call to the AAD and get the user info into the sub iframe.
  // Step 2: Make a post message from the sub iframe to the parent appsource site with the user info.[This receiveMessage api will be called when we receive the message]
  // Step 3 : Using this user data, we make a auto signin action which will update the State using userDataReducer
  receiveMessage(event: Event) {
    // We should listen only to the messages from the iframes with the same origin.
    if (
      !event ||
      !event[`${'data'}`] ||
      !event[`${'origin'}`] ||
      !window.location.hostname ||
      event[`${'origin'}`]?.indexOf(window.location.hostname) === -1
    ) {
      return;
    }

    const data = event[`${'data'}`]; // Extract the payload data from the event
    const payload = data ? isJsonString(data) : '';

    // If the user is already signed-in, we should not trigger the sign in action
    // Also check for the null values in the mandatory fields.
    if (
      payload &&
      payload[`${'msgType'}`] === 'authIframe' &&
      payload[`${'signedIn'}`] &&
      payload[`${'group'}`] &&
      payload[`${'accessToken'}`] &&
      payload[`${'refreshToken'}`] &&
      payload[`${'firstName'}`] &&
      payload[`${'lastName'}`] &&
      payload[`${'email'}`]
    ) {
      const userStore: IUserDataState = this.props.userInfo;

      SpzaInstrumentService.registerTenantInfo(userStore.oid, userStore.tid);

      const userIdInfo = getSpzaUserIdAndNewUserModifier();
      SpzaInstrumentService.registerSpzaId(userIdInfo.spzaUserId);

      const userTrailIdInfo = getTrailIdAndData();
      SpzaInstrumentService.registerTrailId(userTrailIdInfo.trailId);

      SpzaInstrumentService.registerHostType(getHostType(this.props.isEmbedded));

      logTenantInfo(this.props.userInfo, true, userIdInfo.spzaUserId);
    }
  }

  onWindowBeforeUnload() {
    // attach to the onbeforeunload window event
    if (!window.onbeforeunload) {
      window.onbeforeunload = function () {
        // flush the buffer if we have anything in it
        SpzaInstrumentService.getProvider().probe<ITelemetryData>('flushTelemetryInfo');
        SharedInstrumentService.getProvider().probe<ITelemetryData>('flushTelemetryInfo');
        // save session end time for NPS
        NpsModule.SaveSessionEndTime();
      };
    }
  }

  onBrowserBackButton() {
    if (!window.onpopstate) {
      window.onpopstate = function () {
        const payload: ITelemetryData = {
          page: window.location.href,
          action: Constants.Telemetry.Action.PageLoad,
          actionModifier: Constants.Telemetry.ActionModifier.StartBack,
        };
        SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
        logger.info('', {
          action: payload.action,
          actionModifier: payload.actionModifier,
        });
      };
    }
  }

  attachEventListener() {
    // Auto-SignIn listener : Parent window is listening for the post message from the child iframe.
    if (window.addEventListener) {
      window.addEventListener('message', this.receiveMessage.bind(this), false);
    } else {
      (window as any).attachEvent('onmessage', this.receiveMessage.bind(this)); // Added this for old versions of IE
    }
  }

  getParameter(params: any, parameterName: string) {
    if (params[`${parameterName}`]) {
      if (typeof params[`${parameterName}`] === 'string') {
        return params[`${parameterName}`];
      }

      return params[`${parameterName}`][0];
    }

    return undefined;
  }

  handleCookieBanner() {
    // We should not show the cookie banner for Embed experience since the parent site handles the cookie banner experience.
    // we should not show the banner for OCP as well as that is part of the appsource domain
    if (!this.props.isEmbedded) {
      this.showCookieBanner = shouldShowCookieBanner();
    }
  }

  componentDidMount() {
    this.setState({ isMounted: true });

    // onerror event handler is added in the mainClientHookup. Client error logs are rendered from there. We use this event listener for just showing the error dialog.
    // getWindow().addEventListener('error', this.errorHandling.bind(this), true);
    this.performSearchIfNeeded(this.props);

    // UHF fixups
    // NOTE: these need to be orchestrated with UHF changes in compass that I've made

    // Skip UHF fixups for the embedded app
    if (this.props.isEmbedded) {
      this.attachEventListener();
      return;
    }

    // attach to window onbeforeunload
    this.onWindowBeforeUnload();

    // attach to browser back button
    this.onBrowserBackButton();

    // Check for the cookie banner flag and set the CSS for the cookie banner.
    this.handleCookieBanner();

    if (!this.props.enableNewNps) {
      NpsModule.Initialize(this.props.showNPSModal);
    } else {
      initializeNpsModule(() => this.props.setIsNpsLogicVerified(true));
    }

    const params = this.props.locationQuery;

    // This is added here for the sign-in/sign-up redirect scenarios which are triggered by CTA.
    // Once we get redirected to SPZA after signing in, we need to show the CTA modal dialog.
    if (params && params.modalAppId !== undefined) {
      // Added this as a fallback case when there will be duplicate modalAppIds in the queryParams
      // in case of uauth scenario the query params comes as an array after the round trip.
      if (typeof params.modalAppId === 'string' || (params.modalAppId && (params.modalAppId as []).length > 0)) {
        this.props.showCTAModal({
          entityId: this.getParameter(params, 'modalAppId'),
          entityType: Constants.EntityType.App,
          ctaType: this.getParameter(params, 'ctaType'),
        });
      }
    }

    if (params && params.modalServiceId !== undefined) {
      // Added this as a fallback case when there will be duplicate modalAppIds in the queryParams
      // in case of uauth scenario the query params comes as an array after the round trip.
      if (typeof params.modalServiceId === 'string' || (params.modalServiceId && (params.modalServiceId as []).length > 0)) {
        this.props.showCTAModal({
          entityId: this.getParameter(params, 'modalServiceId'),
          entityType: Constants.EntityType.Service,
          ctaType: this.getParameter(params, 'ctaType'),
        });
      }
    }

    if (params && params.modalPartnerId !== undefined) {
      const modalPartnerId = this.getParameter(params, 'modalPartnerId');

      if (params.entityId !== undefined && params.productId !== undefined) {
        const crossListingAppcontext: any = {
          entityId: this.getParameter(params, 'entityId'),
          primaryProduct: this.getParameter(params, 'productId'),
        };

        this.props.showContactModal(modalPartnerId, crossListingAppcontext);
      } else {
        this.props.showContactModal(modalPartnerId);
      }
    }

    if (params && params.modalReviewId) {
      // Added this as a fallback case when there will be duplicate modalReviewIds in the queryParams
      // in case of uauth scenario the query params comes as an array after the round trip.
      if (typeof params.modalReviewId === 'string' || (params.modalReviewId && (params.modalReviewId as []).length > 0)) {
        this.props.showReportAbuseModal(
          this.getParameter(params, 'modalReviewAppId'),
          this.getParameter(params, 'modalReviewId'),
          this.getParameter(params, 'modalReviewTitle'),
          this.getParameter(params, 'modalReviewSource')
        );
      }
    }

    if (params && params.modalRatingId) {
      if (typeof params.modalRatingId === 'string' || (params.modalRatingId && (params.modalRatingId as []).length > 0)) {
        const appId = this.getParameter(params, 'modalRatingId');
        const appData = this.props.allApps.filter((x) => {
          return x.entityId === appId;
        })[0];
        if (appData) {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          this.props.showRaingsModal(appData, null, appData.actionString, () => {});
        }
      }
    }

    forceAzureHeaderNavStatic();
    removeUserUniqueQueryParams();
  }

  // For every page navigation, we need to check for the cookie change.
  componentDidUpdate(prevProps: IAppViewProps) {
    // first we save the current query parameters.
    if (JSON.stringify(this.props.locationQuery) !== JSON.stringify(prevProps.locationQuery)) {
      this.props.updateQuery(this.props.locationQuery);
    }
    this.performSearchIfNeeded(this.props);
    const { userInfo } = this.props;
    this.handleCookieBanner();
    forceAzureHeaderNavStatic();
    if (userInfo?.signedIn) {
      const { oid, tid } = userInfo;
      SpzaInstrumentService.registerTenantInfo(oid, tid);
      // Consider querying TAS once (also save state )
    }
  }

  getChildContext() {
    return {
      loc: (textid: string, fallback?: string) => {
        let translated = this.props.localizedStrings[`${textid}`];
        // todo: remove these underscores for production
        translated = translated || fallback || textid;
        return translated;
      },
      locParams: (textid: string, params: string[], fallback?: string) => {
        let translated = this.props.localizedStrings[`${textid}`];
        for (let i = 0; i < params.length; i++) {
          // eslint-disable-next-line security/detect-non-literal-regexp
          const regEx = new RegExp('\\{' + i + '\\}', 'gm');
          translated = translated || fallback || textid;
          let currentParam = params[`${i}`];
          if (currentParam && currentParam.length > 0 && currentParam[0] === '$') {
            currentParam = '$' + currentParam;
          }
          translated = translated.replace(regEx, currentParam);
        }
        return translated;
      },
      locDateString: (date: string | number, options?: Intl.DateTimeFormatOptions) => {
        return date ? new Date(date).toLocaleDateString(this.props.locale, options) : null;
      },
      buildHref: this.props.buildHref,
      ctaCallback: ({
        entity,
        entityType,
        ctaType,
        skuId,
        actionModifier,
        hasLicense,
        planId,
        isOpenedFromPDP,
        ribbonKey,
      }: {
        entity: any;
        entityType: Constants.EntityType;
        ctaType: Constants.CTAType;
        skuId: string;
        actionModifier: string;
        tileDataRequestId?: string;
        hasLicense?: boolean;
        planId?: string;
        isOpenedFromPDP?: boolean;
        ribbonKey?: string;
      }) => {
        const { currentView, uiRole, nationalCloud, tileDataRequestId: requestId, isEmbedded } = this.props;
        const payload: ITelemetryData = {
          page: getWindow().location.href,
          action: Constants.Telemetry.Action.Click,
          actionModifier,
          appName: entity.entityId,
          details: getAppTelemetryDetailContentText({
            appInfo: entity,
            isNationalCloud: !!nationalCloud,
            isEmbedded,
            ctaTypes: [ctaType],
            currentView,
            tileDataRequestId: requestId,
            uiRole,
            hasLicense,
            planId,
            ribbonKey,
          }),
        };

        if (entity.linkedAddIns && entity.linkedAddIns.length > 0) {
          const payloadDetails = JSON.parse(payload.details);
          payloadDetails.isSaaSBundle = true;
          payload.details = JSON.stringify(payloadDetails);
        }

        this.instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', payload);
        logger.info(payload.details, {
          action: payload.action,
          actionModifier: payload.actionModifier,
          appName: payload.appName,
        });

        // getUserAuthStatus will validate if the user has the right refreh token/cookie combo and if not
        // will enfore a sign in.
        if (this.props.getUserAuthStatus) {
          handleCTACallback({
            props: this.props,
            entity,
            entityType,
            ctaType,
            skuId,
            planId,
            isOpenedFromPDP,
            ribbonKey,
          });
        }
      },
      openTileCallback: (detailUrl: string) => {
        this.props.openTile(detailUrl);
      },
      renderErrorModal: () => {
        this.props.showErrorModal();
      },
      reportAbuseCallback: (appId: string, reviewId: string, reviewTitle: string, reviewSource: string) => {
        // getUserAuthStatus will validate if the user has the right refreh token/cookie combo and if not
        // will enfore a sign in.
        if (this.props.getUserAuthStatus) {
          return this.props.getUserAuthStatus().then(() => {
            return this.props.showReportAbuseModal(appId, reviewId, reviewTitle, reviewSource);
          });
        }
      },
      getAppHeaderHeight: (): number => {
        return this.headerRef?.current?.clientHeight;
      },
    };
  }

  renderFailImpl(): React.ReactElement {
    // rendering is never allowed to throw an exception
    return this.props.showModal ? <Modal /> : null;
  }

  onClickAppViewHandle(): void {
    closeAzureHeaderNavDropdown();
  }

  logout = () => {
    return logoutUser();
  };

  renderImpl() {
    const {
      isEmbedded,
      enableNewNps,
      npsLogicVerified,
      isMobile,
      userInfo,
      correlationId,
      favoritesCount,
      openSignInModal,
      isInErrorDialog,
      initLoading,
      regionDisabled,
      onMarketChange,
      onLocaleChange,
      showModal,
      locationQuery,
      regionCode,
      locale,
      pcaInstanceInitialized,
      isConverged,
    } = this.props;
    const isUserSignedIn = userInfo ? userInfo.signedIn : false;
    const renderNewNps = enableNewNps && isUserSignedIn && npsLogicVerified && !isMobile;
    const { signedIn, loading } = userInfo;

    NpsModule.ResetNPSIdle();
    if (isInErrorDialog) {
      // some component failed rendering, so we can't trust to render components again
      return <Modal />;
    } else if (isEmbedded && initLoading) {
      return <Loader />;
    } else {
      const params = locationQuery || null;

      return (
        <div
          className={classNames({ spza_root: !serverIsEmbedded(), spza_rootEmbedded: serverIsEmbedded() })}
          onClick={() => this.onClickAppViewHandle()}
        >
          {this.state.isMounted && pcaInstanceInitialized && (
            <MsalProvider instance={AuthClient.getInstance()}>
              <SilentClientLogin />
            </MsalProvider>
          )}
          {isEmbedded ? (
            <EmbedHeader props={{ locale, query: params }} />
          ) : (
            <Stack styles={{ root: { position: 'sticky', top: 0, zIndex: 5 } }}>
              <CookieBanner />
              <RemoteHeader
                userProps={{
                  signedIn,
                  loading,
                  favoritesCount,
                  openSignInModal,
                  onSignUp: handleSignUp,
                  onLogout: this.logout,
                }}
                market={{ code: regionCode, disabled: regionDisabled }}
                locale={locale}
                onMarketChange={onMarketChange}
                onLocaleChange={onLocaleChange}
                isConverged={isConverged}
              />
            </Stack>
          )}
          <Outlet />

          {showModal ? <Modal query={params} /> : null}
          {renderNewNps && <Nps email={userInfo.email} correlationId={correlationId} context={this.getChildContext()} />}
          <Footer locale={locale} />
        </div>
      );
    }
  }
}

(AppView as any).childContextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  locDateString: PropTypes.func,
  locale: PropTypes.string,
  buildHref: PropTypes.func,
  ctaCallback: PropTypes.func,
  contactCallback: PropTypes.func,
  openTileCallback: PropTypes.func,
  renderErrorModal: PropTypes.func,
  reportAbuseCallback: PropTypes.func,
  getAppHeaderHeight: PropTypes.func,
};
