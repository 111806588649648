/* eslint-disable react/forbid-dom-props */
import * as React from 'react';
import { IUserFavouriteItem } from '../interfaces/userFavouriteModels';
import { Constants } from './../utils/constants';
import { generateTileClickPayloadAndLogTelemetry } from './../utils/detailUtils';
import { getComponentXYCoordinate, getTileComponentRank } from './../utils/reactUtils';
import UserFavouriteTileButton from './../containers/userFavouriteTileButton';
import { ICtaData, IBoldString, IRatingData, IFilterIcon } from '@shared/Models';
import { DefaultButton, Stack, Text, mergeStyleSets } from '@fluentui/react';
import { useEffect, useRef } from 'react';
import { TelemetryImage } from './telemetryImage';
import { getNpsModule } from '@appsource/utils/nps';
import ProductsComponent from './products';
import { NeutralColors } from '@fluentui/theme';
import {
  IBuildHrefContext,
  ICommonContext,
  ICTACallbackContext,
  ILocContext,
  ILocParamsContext,
  IOpenTileCallbackContext,
} from 'interfaces/context';
import { getTooltipSilentLogIn } from '@shared/utils/silentLogInUtils';
import classNames from 'classnames';
import { isOneTimePaymentOffer } from '@shared/utils/onetimepaymentoffers';
import { InternalLink } from '@shared/components/internalLink';
import { RatingsInfo } from './ratings/ratingsInfo';
import { useHydration } from '@shared/hooks/useHydration';

export interface IBaseTileProps {
  isLoadingUserData: boolean;
  id: string;
  item: IUserFavouriteItem;
  iconURL: string;
  title: string;
  tileDataRequestId?: string;
  publisher: string;
  description?: string;
  ctaData: ICtaData;
  detailUrl: string;
  tileIndex: number;
  componentInstance?: string;
  userFavouriteEntityType?: string;
  currentView?: string;
  linkedAddIns?: JSX.Element;
  linkedWebApps?: JSX.Element;
  price?: IBoldString;
  rating?: IRatingData;
  badge?: JSX.Element;
  onOpenTile?: (e: any) => void;
  products: IFilterIcon[];
  totalTiles: number;
  context: IBuildHrefContext & ILocContext & ILocParamsContext & ICTACallbackContext & ICommonContext & IOpenTileCallbackContext;
  popularity?: number;
  activeFilters: any;
  count: number;
  searchSortingBy: string;
  searchReqId: string;
  shouldShowLinkedAddIn?: boolean;
  shouldShowLinkedWebApps?: boolean;
  isEmbedded: boolean;
  badgeTypes?: IBadgeTypes;
  ribbonKey?: string;
}

const contentStyle = mergeStyleSets({
  subText: [{ color: NeutralColors.gray130, marginBottom: '5px' }],
  headerText: [{ color: NeutralColors.gray160 }],
  lightText: [{ marginRight: '4px' }],
  ctaButton: [{ marginTop: '12px' }],
  publisher: [{ height: '48px' }],
  linkesButton: [{ background: 'none', border: 'none', padding: 0 }],
});

interface IBadgeTypes {
  m365Certified: boolean;
  preferredSolution: boolean;
  officeAppAwards: boolean;
  pbi: boolean;
  azureExpertMsp: boolean;
  isPrivate: boolean;
}
export interface ITileFooterProps {
  item: IUserFavouriteItem;
  userFavouriteEntityType: string;
  ctaData: ICtaData;
  id: string;
  price?: IBoldString;
  isLoadingUserData: boolean;
  popularity?: number;
  isEmbedded: boolean;
  onKeyDown(event: React.KeyboardEvent): void;
  onClick?(event: React.MouseEvent): void;
}

export interface ICtaProps {
  isLoadingUserData: boolean;
  ctaData: ICtaData;
  isEmbedded: boolean;
  onKeyDown: (event: React.KeyboardEvent) => void;
}

export const TileCTAbutton: React.FunctionComponent<ICtaProps> = ({ ctaData, isLoadingUserData, isEmbedded }: ICtaProps) => {
  const [isCTAHover, setIsCTAHover] = React.useState(false);

  const { title, disabled, id, entityId, CtaType, area, view, onClick } = ctaData;
  const button = (
    <DefaultButton
      primary={isCTAHover}
      className={contentStyle.ctaButton}
      name="button"
      type="submit"
      text={title}
      disabled={disabled || (isLoadingUserData && !isEmbedded)}
      data-bi-id={id}
      data-bi-name={entityId}
      data-bi-type={CtaType}
      data-bi-area={area}
      data-bi-view={view}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        return onClick(e);
      }}
      onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => {
        if (e.charCode === Constants.SystemKey.Enter) {
          onClick();
        }
      }}
      onMouseEnter={() => setIsCTAHover(true)}
      onMouseLeave={() => setIsCTAHover(false)}
    />
  );
  return getTooltipSilentLogIn(button, isLoadingUserData);
};

const TileFooter: React.FunctionComponent<ITileFooterProps> = ({
  item,
  userFavouriteEntityType,
  ctaData,
  id,
  price,
  isLoadingUserData,
  popularity,
  isEmbedded,
  onKeyDown,
  onClick,
}: ITileFooterProps) => {
  return (
    <Stack verticalAlign="end" className="tileFooterBox" onClick={onClick}>
      {price && (
        <div className="price">
          {price.light && (
            <Text variant={'small'} className={`${contentStyle.lightText} ${contentStyle.subText}`}>
              {price.light}
            </Text>
          )}
          {price.bold && (
            <Text
              variant={'small'}
              className={`${contentStyle.headerText} ms-fontWeight-semibold`}
              nowrap
              block
              title={price.bold}
            >
              {price.bold}
            </Text>
          )}
        </div>
      )}
      <Stack horizontal verticalAlign="baseline" tokens={{ childrenGap: 5 }}>
        {ctaData && (
          <TileCTAbutton onKeyDown={onKeyDown} isLoadingUserData={isLoadingUserData} ctaData={ctaData} isEmbedded={isEmbedded} />
        )}
        {userFavouriteEntityType && <UserFavouriteTileButton entityId={id} entityType={userFavouriteEntityType} item={item} />}
      </Stack>
      {popularity && <Text>P#: {popularity * 1000 || 'none'}</Text>}
    </Stack>
  );
};

export const BaseTile: React.FunctionComponent<IBaseTileProps> = ({
  id,
  item,
  iconURL,
  title,
  tileDataRequestId,
  publisher,
  description,
  ctaData,
  detailUrl,
  tileIndex,
  componentInstance,
  userFavouriteEntityType,
  currentView,
  linkedAddIns,
  linkedWebApps,
  price,
  rating,
  badge,
  onOpenTile,
  products,
  totalTiles,
  context,
  isLoadingUserData,
  popularity,
  count,
  activeFilters,
  searchSortingBy,
  searchReqId,
  shouldShowLinkedAddIn,
  shouldShowLinkedWebApps,
  badgeTypes,
  ribbonKey = '',
  isEmbedded,
}: IBaseTileProps) => {
  const index = tileIndex + 1;
  const tileId = `${componentInstance}-${id.replace('.', '-')}_${index}`;
  const [imgUrlError, setImgUrlError] = React.useState(false);
  const isHydrated = useHydration();
  useEffect(() => {
    setImgUrlError(true);
  }, []);
  const ref = useRef(null);

  const logOnClick = () => {
    const reference = ref.current;
    const coordinate = getComponentXYCoordinate(reference);
    const tileRank = getTileComponentRank(reference);

    const details: any = {
      id,
      rank: JSON.stringify(tileRank),
      xOffset: coordinate.x,
      yOffset: coordinate.y,
      currentView,
      tileDataRequestId,
      discoveryData: {
        totalNumOfResults: count, // correlative to display of apps or services not both
        activeFilters,
        sortingBy: searchSortingBy,
        searchRequestId: searchReqId,
      },
      badges: JSON.stringify(badgeTypes),
      ribbonKey,
      publisherId: item.publisherId,
      appName: item.entityId,
    };

    // Add Legacy Telemetry Properties for Bundle Tile Click
    if (shouldShowLinkedAddIn || shouldShowLinkedWebApps) {
      details.isSaaSBundle = true;
    }

    generateTileClickPayloadAndLogTelemetry(Constants.Telemetry.ActionModifier.Tile, details);
  };

  const handleTileClick = (event: React.MouseEvent | React.KeyboardEvent) => {
    onOpenTile?.(event);
    logOnClick();
  };

  const handleTileKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === Constants.SystemKeyString.Enter) {
      handleTileClick(event);
    }
  };

  const preventNavigation = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const descriptionclassNames = classNames({
    multineEllipsis: true,
    description: !linkedAddIns,
    mobileDescription: true,
  });

  const oneTimePaymentOffer = isOneTimePaymentOffer(id);

  return (
    <InternalLink
      data-testid={`tile-link-container`}
      title={title}
      className="tileContainer"
      href={detailUrl}
      ref={ref}
      role="link"
      aria-labelledby={`label-${tileId} labelBy-${tileId}`}
      aria-describedby={`desc-${tileId} descPrice-${tileId} descCompetency-${tileId}`}
      data-bi-name={id}
      data-bi-area={
        linkedAddIns && currentView === Constants.currentView.AppDetails
          ? Constants.Telemetry.AreaName.ParentSaaSTile
          : Constants.Telemetry.AreaName.AppTile
      }
      data-bi-view={Constants.Telemetry.TemplateName.SaaSBundle}
      accEnabled={true}
      onKeyDown={handleTileKeyDown}
    >
      <Stack
        verticalAlign="space-between"
        className="tileContent ms-depth-8"
        onClick={(e: React.MouseEvent) => {
          handleTileClick(e);
          getNpsModule()?.increaseActionsCounter('pdpViewsCount');
        }}
      >
        {badge}
        <Stack tokens={{ childrenGap: 8 }}>
          {
            <div className={'tileIcon ms-depth-8'}>
              {iconURL && imgUrlError && (
                <TelemetryImage
                  src={iconURL}
                  alt={`${title} icon`}
                  onError={() => setImgUrlError(false)}
                  itemPropName={'tileImage'}
                />
              )}
            </div>
          }
          <Stack className={'tileHeaderBox'} tokens={{ childrenGap: 8 }}>
            {
              <Text
                variant={'large'}
                nowrap
                block
                className={`${contentStyle.publisher} title multineEllipsis ms-fontWeight-semibold`}
              >
                {title}
              </Text>
            }
            {
              <Text variant={'small'} className={`${contentStyle.subText} publisher`}>
                {publisher}
              </Text>
            }
            {!oneTimePaymentOffer && !!products?.length && (
              <ProductsComponent
                textSize={'smallPlus'}
                withTooltip
                tooltipTitle={'Applicable to:'}
                withText
                productsItems={products}
              />
            )}
            {linkedAddIns && (
              <button className={contentStyle.linkesButton} tabIndex={0}>
                {linkedAddIns}
              </button>
            )}
            {linkedWebApps && (
              <button className={contentStyle.linkesButton} tabIndex={0}>
                {linkedWebApps}
              </button>
            )}
            {
              <Text variant={'small'} className={`${contentStyle.subText} ${descriptionclassNames} `}>
                {description}
              </Text>
            }
            {rating && (
              <RatingsInfo
                avgRating={rating.avgRating}
                ratingsCount={rating.ratingsCount}
                key={rating.key}
                title={rating.text}
                context={context}
                textSize={'smallPlus'}
              />
            )}
            <div id={`labelIndexString-${tileId}`} className="totalTiles">
              {`${index} out of ${totalTiles}`}
            </div>
          </Stack>
        </Stack>
        {isHydrated && (
          <TileFooter
            onClick={preventNavigation}
            onKeyDown={handleTileKeyDown}
            isEmbedded={isEmbedded}
            isLoadingUserData={isLoadingUserData}
            item={item}
            userFavouriteEntityType={userFavouriteEntityType}
            ctaData={ctaData}
            id={id}
            price={price}
            popularity={popularity}
          />
        )}
        <div className="mobileUserFavourite" onClick={preventNavigation}>
          {userFavouriteEntityType && <UserFavouriteTileButton entityId={id} entityType={userFavouriteEntityType} item={item} />}
        </div>
      </Stack>
    </InternalLink>
  );
};
