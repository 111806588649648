import React from 'react';
import { DefaultPalette, IStyle, mergeStyleSets, Text } from '@fluentui/react';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import * as PropTypes from 'prop-types';

interface Props {}

interface StylingClasses {
  freeTrialText: IStyle;
}

const classes = mergeStyleSets<StylingClasses>({
  freeTrialText: {
    color: DefaultPalette.green, // #107C10
    background: '#DFF6DD',
    padding: '5.25px 8px 7px 8px',
    borderRadius: 2,
    fontSize: 13,
    height: 28,
    display: 'inline-block',
  },
});

export const FreeTrialBadge = (_: Props, context: ILocParamsContext & ILocContext) => {
  return (
    <Text className={classes.freeTrialText} nowrap variant="smallPlus">
      {context.loc('CTA_Try')}
    </Text>
  );
};

(FreeTrialBadge as any).contextTypes = {
  loc: PropTypes.func,
};
