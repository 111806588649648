import * as React from 'react';
import * as PropTypes from 'prop-types';
import SpzaComponent from '@shared/components/spzaComponent';
import { isValidInput, serializeUserProfileEmbedInfo, readUserProfileEmbedCookie } from '@shared/utils/appUtils';
import { getCookieItem, saveCookieItem } from '@shared/utils/browserStorageUtils';
import { IUserLeadGenProfile, ITelemetryData, IUserLeadProfileAgreement, ILicense } from '@shared/Models';
import { ILocContext, ICommonContext } from '@shared/interfaces/context';
import { Constants, allBillingCountries } from '@shared/utils/constants';
import Animation from '@shared/components/animation';
import { getWindow } from '@shared/services/window';
import { SpzaInstrumentProvider, SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { logger } from '@src/logger';
import { Text, Stack, mergeStyleSets, TextField, Dropdown, Link } from '@fluentui/react';

import type { IStackTokens, IDropdownOption, IDropdownStyles } from '@fluentui/react';

export interface IUserProfileProps {
  renderProfile?: boolean;
  userLeadgenProfile: IUserLeadGenProfile;
}

export interface IUserProfileMethods {
  getUserProfile: () => Promise<void>;
  updateUserProfile: (profile: IUserLeadProfileAgreement) => Promise<void>;
}

export interface IUserProfileModal extends IUserProfileMethods, IUserProfileProps {
  isEmbedded?: boolean;
  userSubmittedForm?: boolean;
  licenseType?: number;
  currentLicense?: ILicense;
  compactUserProfile?: boolean;
  profileCompleted?: (profileFilledOut: boolean) => void;
  callBackHandler?: () => void;
  shouldRenderHeader?: boolean;
  getExpandHeaderText?: string;
  shouldHideAccountInfo?: () => void;
  accountInfoUpdate?: (id: string, value: string) => void;
  isContactForm?: boolean;
}

interface IUserProfileField {
  id: string;
  defaultValue: string;
  title: string;
  placeholder: string;
  isRequired?: boolean;
}

export class UserProfileModalData {
  user: IUserLeadProfileAgreement;
  firstTimeEmbed: boolean;
  licenseType: number;

  constructor(user: IUserLeadGenProfile, isEmbedHost: boolean, licenseType: number) {
    this.updateUserProfile(user, isEmbedHost, licenseType);
  }

  updateUserProfile(user: IUserLeadGenProfile, isEmbedHost: boolean, licenseType: number): void {
    this.licenseType = licenseType;
    if (isEmbedHost && user?.firstName === '' && user?.lastName === '' && user?.email === '' && user?.isLatestProfile === false) {
      this.user = {
        ...user,
        phone: '',
        company: '',
        country: '',
        title: '',
        accepted: true,
      };
      const userProfileInCookie = getCookieItem(Constants.userProfileEmbedCookieName, false);
      if (userProfileInCookie) {
        readUserProfileEmbedCookie(userProfileInCookie, this.user);
      } else {
        this.firstTimeEmbed = true;
      }
    } else {
      this.user = {
        ...user,
        accepted: true,
        licenses: this.setLicensesToLocalUserProfile(user.licenses),
      };
    }
  }

  setLicensesToLocalUserProfile(newLicenses: ILicense[]) {
    if (
      this.licenseType !== Constants.Dynamics365LicenseType.noLicense &&
      newLicenses?.length === Constants.dynamicsLicense.currentD365LicenseNumber
    ) {
      const localLicenses: ILicense[] = [];
      for (let i = 0; i < newLicenses.length; i++) {
        const licenseInState = newLicenses[`${i}`];
        const licenseNewObj: ILicense = {
          type: licenseInState.type,
          hasLicense: licenseInState.hasLicense,
          isDisputed: licenseInState.isDisputed,
        };
        localLicenses.push(licenseNewObj);
      }
      return localLicenses;
    }
    return [];
  }

  isDirty(originalUser: IUserLeadGenProfile): boolean {
    for (const i in this.user) {
      if (i !== 'accepted' && i !== 'licenses') {
        if (originalUser[`${i}`] !== this.user[`${i}`]) {
          return true;
        }
      }
    }

    return false;
  }

  licenseChange(newCurrentLicense: ILicense, isEmbeddedHost: boolean) {
    if (isEmbeddedHost) {
      return false;
    }
    if (
      newCurrentLicense &&
      this.licenseType === Constants.Dynamics365LicenseType.dynamics365License &&
      this.user.licenses[Constants.Dynamics365LicenseIndex.dynamics365LicenseIndex].isDisputed !== newCurrentLicense.isDisputed
    ) {
      return true;
    }

    if (
      newCurrentLicense &&
      this.licenseType === Constants.Dynamics365LicenseType.dynamics365forBusinessLicense &&
      this.user.licenses[Constants.Dynamics365LicenseIndex.dynamics365ForBusinessLicenseIndex].isDisputed !==
        newCurrentLicense.isDisputed
    ) {
      return true;
    }

    return false;
  }
}

const userProfileModalClassNames = mergeStyleSets({
  editButton: {
    paddingLeft: 3,
  },
  boldText: {
    fontWeight: 600,
  },
  doubleCellContainer: {
    [`@media (max-width: 425px)`]: {
      flexWrap: 'wrap',
    },
  },
  secondCell: {
    [`@media (min-width: 425px)`]: {
      marginLeft: 12,
    },
    [`@media (max-width: 425px)`]: {
      marginTop: 15,
    },
  },
});

const userProfileFieldsContainer: IStackTokens = { childrenGap: 8 };
const expandedUserProfileTokens: IStackTokens = { childrenGap: 21 };

const dropDownStyles: Partial<IDropdownStyles> = {
  callout: {
    maxHeight: 300,
    display: 'flex',
    flexDirection: 'column',
  },
};

export interface IUserProfileModalState {
  showProfile: boolean;
  userProfileUpdated: boolean;
}

export class UserProfileModal extends SpzaComponent<IUserProfileModal, IUserProfileModalState> {
  public context: ILocContext & ICommonContext;
  private instrument: SpzaInstrumentProvider;
  private countryList = this.getCountries();
  private localUserInfo: UserProfileModalData = new UserProfileModalData(
    this.props.userLeadgenProfile,
    this.props.isEmbedded,
    this.props.licenseType
  );

  constructor(props: IUserProfileModal, context: ILocContext & ICommonContext) {
    super(props, context);
    this.instrument = SpzaInstrumentService.getProvider();

    this.state = {
      showProfile: !!this.localUserInfo.firstTimeEmbed || !props.compactUserProfile,
      userProfileUpdated: false,
    };
    this.onChange = this.onChange.bind(this);
  }

  shouldProfileBeRendered(): boolean {
    const { renderProfile } = this.props;
    return renderProfile;
  }

  UNSAFE_componentWillMount(): void {
    const { profileCompleted, userLeadgenProfile, isEmbedded, licenseType } = this.props;

    if (!this.shouldProfileBeRendered()) {
      profileCompleted(true);
    } else {
      this.localUserInfo.updateUserProfile(userLeadgenProfile, isEmbedded, licenseType);
      this.checkMandatoryFields();
    }
  }

  onChange(id: string, value: string): void {
    const { accountInfoUpdate } = this.props;
    this.localUserInfo.user[`${id}`] = value;
    accountInfoUpdate(id, value);
    this.checkMandatoryFields();
  }

  checkMandatoryFields(): void {
    const { profileCompleted } = this.props;
    profileCompleted(this.mandatoryFieldsDidUpdate());
  }

  mandatoryFieldsDidUpdate(): boolean {
    // TODO: Add validation for international phone numbers +52 353 333 7777
    // TODO: Validate phone number with code number.
    const { firstName, lastName, country } = this.localUserInfo.user;
    if (
      isValidInput(firstName) &&
      isValidInput(lastName) &&
      country &&
      allBillingCountries.some((c) => c.countryCode === this.localUserInfo.user.country)
    ) {
      return true;
    }
    return false;
  }

  onClickEditButton(eventKeyCode?: number) {
    const { shouldHideAccountInfo } = this.props;
    if (!eventKeyCode || eventKeyCode === Constants.SystemKey.Space || eventKeyCode === Constants.SystemKey.Enter) {
      this.setState((prevState: IUserProfileModalState) => ({
        showProfile: true,
        userProfileUpdated: prevState.userProfileUpdated,
      }));
      shouldHideAccountInfo();
    }
  }

  renderCompactedUserProfile(): JSX.Element {
    return (
      <Stack horizontal>
        <Link
          className={userProfileModalClassNames.editButton}
          tabIndex={0}
          role="button"
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          onClick={(_) => this.onClickEditButton()}
          onKeyDown={(event) => this.onClickEditButton(event.keyCode)}
          underline
        >
          {this.context.loc('UserProfileCompacted_EditButton', 'Edit your details')}
        </Link>
      </Stack>
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps: IUserProfileModal): void {
    const {
      userLeadgenProfile: currentUserLeadgenProfile,
      isEmbedded,
      licenseType,
      userSubmittedForm: currenttUserSubmittedForm,
      updateUserProfile,
      callBackHandler,
    } = this.props;
    const { userLeadgenProfile: nextUserLeadgenProfile, userSubmittedForm: nextUserSubmittedForm, currentLicense } = nextProps;

    if (!this.areEqual(currentUserLeadgenProfile, nextUserLeadgenProfile)) {
      this.localUserInfo.updateUserProfile(nextUserLeadgenProfile, isEmbedded, licenseType);
    }
    if (
      licenseType &&
      licenseType !== Constants.Dynamics365LicenseType.noLicense &&
      !this.licenseInfoEqual(this.localUserInfo.user.licenses, nextUserLeadgenProfile.licenses)
    ) {
      this.localUserInfo.setLicensesToLocalUserProfile(nextUserLeadgenProfile.licenses);
    }
    if (nextUserSubmittedForm && nextUserSubmittedForm !== currenttUserSubmittedForm) {
      if (
        this.localUserInfo.isDirty(nextUserLeadgenProfile) ||
        this.localUserInfo.licenseChange(currentLicense, isEmbedded) ||
        nextUserLeadgenProfile.updateRequired
      ) {
        if (
          !isEmbedded &&
          licenseType &&
          licenseType !== Constants.Dynamics365LicenseType.noLicense &&
          this.localUserInfo.user.licenses &&
          this.localUserInfo.user.licenses.length === Constants.dynamicsLicense.currentD365LicenseNumber
        ) {
          this.localUserInfo.user.licenses[licenseType - 1].isDisputed = currentLicense.isDisputed;
        }
        updateUserProfile(this.localUserInfo.user).then(() => {
          const payload: ITelemetryData = {
            page: getWindow().location.href,
            action: Constants.Telemetry.Action.Click,
            actionModifier: Constants.Telemetry.ActionModifier.Info,
            details: 'User Updated profile, Will run acquisition.',
          };
          this.instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', payload);
          logger.info(payload.details, {
            action: payload.action,
            actionModifier: payload.actionModifier,
          });
          if (isEmbedded) {
            saveCookieItem(Constants.userProfileEmbedCookieName, serializeUserProfileEmbedInfo(this.localUserInfo.user));
          }
          callBackHandler();
        });
      } else {
        const payload: ITelemetryData = {
          page: getWindow().location.href,
          action: Constants.Telemetry.Action.Click,
          actionModifier: Constants.Telemetry.ActionModifier.Info,
          details: 'User did not updated profile, Will run acquisition.',
        };
        this.instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', payload);
        logger.info(payload.details, {
          action: payload.action,
          actionModifier: payload.actionModifier,
        });
        callBackHandler();
      }
    }
  }

  shouldComponentUpdate(nextProps: IUserProfileModal, nextState: IUserProfileModalState): boolean {
    const { compactUserProfile: currentCompactUserProfile, userLeadgenProfile: currentUserLeadgenProfile } = this.props;
    const { showProfile: currentShowProfile } = this.state;
    const { showProfile: nextShowProfile } = nextState;
    const { compactUserProfile: nextCompactUserProfile, userLeadgenProfile: nextUserLeadgenProfile } = nextProps;

    return (
      currentShowProfile !== nextShowProfile ||
      currentCompactUserProfile !== nextCompactUserProfile ||
      !this.areEqual(nextUserLeadgenProfile, currentUserLeadgenProfile)
    );
  }

  getDefaultCountry(defaultCountry: string): IDropdownOption {
    const country = this.countryList.find((c) => c.key === defaultCountry.toUpperCase());
    return country || { key: '', text: '' };
  }

  getCountries(): IDropdownOption[] {
    return allBillingCountries.map((c) => ({ key: c.countryCode, text: c.name }));
  }

  dropDownCountry(defaultValue?: string): JSX.Element {
    const id = Constants.Country;
    const selectedCountry = defaultValue ? this.getDefaultCountry(defaultValue) : { key: '', text: '' };
    const { key: selectedCountryKey } = selectedCountry;

    return (
      <Stack>
        <Dropdown
          options={this.countryList}
          label={this.context.loc('RT_Country', 'Country / region')}
          defaultSelectedKey={selectedCountryKey}
          onChange={(_, option) => {
            this.onChange(id, option.key.toString());
          }}
          placeholder={this.context.loc('Leads_Modal_Country_Placeholder', 'Select country')}
          styles={dropDownStyles}
          disabled={!!selectedCountryKey}
          required={!selectedCountryKey}
        />
      </Stack>
    );
  }

  inputCell(field: IUserProfileField, className?: string): JSX.Element {
    const { id, title, placeholder, defaultValue, isRequired } = field;
    return (
      <Stack.Item grow>
        <TextField
          label={title}
          required={isRequired}
          onChange={(_, value) => this.onChange(id, value)}
          key={id + defaultValue}
          defaultValue={defaultValue || ''}
          aria-required={isRequired}
          ariaLabel={title}
          placeholder={placeholder}
          className={className}
        />
      </Stack.Item>
    );
  }

  doubleInputCells(isRequired: boolean, firstCell: IUserProfileField, secondCell: IUserProfileField, title: string): JSX.Element {
    const {
      id: firstCellId,
      defaultValue: firstCellDefaultValue,
      title: firstCellTitle,
      placeholder: firstCellPlaceholder,
    } = firstCell;

    const {
      id: secondCellId,
      defaultValue: secondCellDefaultValue,
      title: secondCellTitle,
      placeholder: secondCellPlaceholder,
    } = secondCell;

    return (
      <Stack horizontal className={userProfileModalClassNames.doubleCellContainer}>
        <Stack.Item grow>
          <TextField
            required={isRequired}
            label={title}
            onChange={(_, value) => this.onChange(firstCellId, value)}
            key={firstCellId + firstCellDefaultValue}
            defaultValue={firstCellDefaultValue || ''}
            ariaLabel={firstCellTitle}
            aria-required={isRequired}
            placeholder={firstCellPlaceholder}
          />
        </Stack.Item>
        <Stack.Item grow align="end">
          <TextField
            onChange={(_, value) => this.onChange(secondCellId, value)}
            key={secondCellId + secondCellDefaultValue}
            defaultValue={secondCellDefaultValue || ''}
            ariaLabel={secondCellTitle}
            aria-required={isRequired}
            placeholder={secondCellPlaceholder}
            className={userProfileModalClassNames.secondCell}
          />
        </Stack.Item>
      </Stack>
    );
  }

  renderExpandedHeader(): JSX.Element {
    const { shouldRenderHeader, isContactForm, getExpandHeaderText } = this.props;
    const text: string =
      shouldRenderHeader && isContactForm
        ? this.context.loc(
            'CTA_UserProfile_Title',
            'To continue, we need some basic profile information. We’ve pulled your Microsoft Account data to help you get started.'
          )
        : '';

    return <Text variant="medium">{getExpandHeaderText || text}</Text>;
  }

  renderExpandedUserProfile(): JSX.Element {
    const { isEmbedded, userLeadgenProfile } = this.props;
    const firstName: IUserProfileField = {
      id: 'firstName',
      defaultValue:
        isEmbedded && !this.localUserInfo.user.firstName ? userLeadgenProfile.firstName : this.localUserInfo.user.firstName,
      title: this.context.loc('RT_FirstName', 'First name'),
      placeholder: this.context.loc('RT_FirstName', 'First name'),
    };

    const lastName: IUserProfileField = {
      id: 'lastName',
      defaultValue:
        isEmbedded && !this.localUserInfo.user.lastName ? userLeadgenProfile.lastName : this.localUserInfo.user.lastName,
      title: this.context.loc('RT_LastName', 'Last name'),
      placeholder: this.context.loc('RT_LastName', 'Last name'),
    };

    const title: IUserProfileField = {
      id: 'title',
      defaultValue: isEmbedded && !this.localUserInfo.user.title ? userLeadgenProfile.title : this.localUserInfo.user.title,
      title: this.context.loc('RT_Title', 'Job title'),
      placeholder: this.context.loc('Leads_Modal_Title_Placeholder', 'Enter your job title'),
    };

    const company: IUserProfileField = {
      id: 'company',
      defaultValue: isEmbedded && !this.localUserInfo.user.company ? userLeadgenProfile.company : this.localUserInfo.user.company,
      title: this.context.loc('RT_Company', 'Company'),
      placeholder: this.context.loc('Leads_Modal_Company_Placeholder', 'Enter your company name'),
    };

    const phone: IUserProfileField = {
      id: 'phone',
      defaultValue: isEmbedded && !this.localUserInfo.user.phone ? userLeadgenProfile.phone : this.localUserInfo.user.phone,
      title: this.context.loc('RT_Phone', 'Phone number'),
      placeholder: this.context.loc('Leads_Modal_Phone_Number_Placeholder', 'Write your phone number'),
    };

    return (
      <Stack tokens={expandedUserProfileTokens}>
        <Stack.Item>{this.renderExpandedHeader()}</Stack.Item>
        <Stack tokens={userProfileFieldsContainer}>
          {this.doubleInputCells(true, firstName, lastName, this.context.loc('RT_Name', 'Name'))}
          <Stack horizontal className={userProfileModalClassNames.doubleCellContainer}>
            {this.inputCell(title)}
            {this.inputCell(company, userProfileModalClassNames.secondCell)}
          </Stack>
          {this.dropDownCountry(
            isEmbedded && !this.localUserInfo.user.country ? userLeadgenProfile.country : this.localUserInfo.user.country
          )}
          {this.inputCell(phone)}
        </Stack>
      </Stack>
    );
  }

  renderAnimation(): JSX.Element {
    return (
      <div className="leadGen">
        {' '}
        <Animation />{' '}
      </div>
    );
  }

  componentDidMount() {
    const { getUserProfile } = this.props;
    if (this.shouldGetUserProfile()) {
      getUserProfile();
    } else {
      this.checkMandatoryFields();
    }
  }

  shouldGetUserProfile(): boolean {
    const { userLeadgenProfile, isEmbedded } = this.props;
    return !userLeadgenProfile.isLatestProfile && this.shouldProfileBeRendered() && !isEmbedded;
  }

  shouldRenderExpandedProfile() {
    // if update required or one of the user props are not filled out.
    const { showProfile } = this.state;
    return showProfile || !this.mandatoryFieldsDidUpdate() || this.updateRequiredFromAPI();
  }

  updateRequiredFromAPI(): boolean {
    const { userLeadgenProfile } = this.props;
    return userLeadgenProfile.updateRequired === true;
  }

  renderImpl(): JSX.Element {
    const { compactUserProfile } = this.props;
    if (compactUserProfile) {
      return this.renderCompactedUserProfile();
    } else if (!this.shouldProfileBeRendered()) {
      return null;
    } else if (this.shouldGetUserProfile()) {
      return this.renderAnimation();
    } else if (this.shouldRenderExpandedProfile()) {
      return this.renderExpandedUserProfile();
    } else {
      return this.renderCompactedUserProfile();
    }
  }

  componentDidUpdate(prevProps: IUserProfileModal) {
    const { userLeadgenProfile: currentUserLeadgenProfile } = this.props;
    const { userLeadgenProfile: prevUserLeadgenProfile } = prevProps;
    if (!this.areEqual(prevUserLeadgenProfile, currentUserLeadgenProfile)) {
      this.checkMandatoryFields();
    }
  }

  areEqual(profile: IUserLeadGenProfile, newProfile: IUserLeadGenProfile): boolean {
    return (
      profile.isLatestProfile === newProfile.isLatestProfile &&
      profile.updateRequired === newProfile.updateRequired &&
      profile.firstName === newProfile.firstName &&
      profile.lastName === newProfile.lastName &&
      profile.company === newProfile.company &&
      profile.country === newProfile.country &&
      profile.phone === newProfile.phone &&
      profile.title === newProfile.title &&
      profile.email === newProfile.email
    );
  }

  licenseInfoEqual(license: ILicense[], newLicense: ILicense[]) {
    if (!newLicense || !license) {
      return false;
    }

    if (license.length !== newLicense.length) {
      return false;
    }

    for (let i = 0; i < license.length; i++) {
      if (license[`${i}`].isDisputed !== newLicense[`${i}`].isDisputed) {
        return false;
      }
    }
    return true;
  }
}

(UserProfileModal as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  renderErrorModal: PropTypes.func,
};
