/* istanbul ignore file */

/* eslint-disable @typescript-eslint/no-namespace */
export namespace BusinessTelemetry {
  export enum dataBi {
    sortingDropdownId = 'sortByDropdown',
    sortingDropdownName = 'Sorting dropdown',
  }

  export enum Filters {
    Activating = 'Activating',
    Deactivating = 'Deactivating',
    FiltersCleared = 'Filters cleared',
  }

  export enum ProductAvailabilityType {
    Private = 'private',
    Public = 'public',
  }
}
