/* eslint-disable react/no-danger */
import * as React from 'react';
import { Text, mergeStyleSets } from '@fluentui/react';
import * as PropTypes from 'prop-types';

import { ServiceTile } from '@shared/containers/serviceTile';

import SpzaComponent from '@shared/components/spzaComponent';
import { IAppDataItem, IDemoVideos, IImages, IMedia, IMediaModalPayload, MediaType } from '@shared/Models';
import { ICommonContext, ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { Constants } from '@shared/utils/constants';
import { Capabilities } from '@shared/utils/capabilities';
import * as DetailUtils from '@shared/utils/detailUtils';
import Ribbon from '@shared/components/ribbon';
import { TelemetryImage } from '@shared/components/telemetryImage';
import { Service } from '@shared/serviceViewModel';
import { AppTile } from '@shared/containers/appTile';
import { IProductValue, DataMap } from '@shared/utils/dataMapping';
import { ExternalLink } from '@shared/components/externalLink';
import { bitMasksMatch } from '@shared/utils/datamappingHelpers';
import { useTelemetry } from '@shared/hooks/useTelemetry';
import videoOverlay from '@shared/images/videoOverlay.png';
import classnames from 'classnames';
import { AutoRuns } from '@shared/utils/autoruns';
import { getProdcutBitMaskInfo } from '@shared/utils/appUtils';
import { SafeHtmlWrapper } from '@shared/components/safeHtmlWrapper';

// eslint-disable-next-line react-hooks/rules-of-hooks
const [{ pageAction }] = useTelemetry();

export interface IOverviewDispatchProps {
  openMediaModal?: (mediaModalPayload: IMediaModalPayload) => void;
}

export interface IOverviewProps extends IOverviewDispatchProps {
  ownerType: DetailUtils.OwnerType;
  ownerId: string;
  ownerTitle: string;
  shortDescription: string;
  description: string;
  images: IImages[];
  videos: IDemoVideos[];
  capabilities: string | string[];
  autoRunLaunchEvents?: string[];
  TileType: typeof ServiceTile;
  crossListings: (IAppDataItem | Service)[];
  products?: IProductValue;
  isPowerBICertified?: boolean;
  isSaaSTransactableApp?: string;
  countryCode?: string;
  isOneTimePayment?: boolean;
}

interface IOverviewState {
  failures: { [thumbnail: string]: boolean };
}

const contentStyles = mergeStyleSets({
  ulAutorunStyle: {
    'list-style': 'unset',
    'padding-left': 40,
    'margin-top': 8,
  },
  autorunTitleStyle: {
    'margin-top': 8,
  },
  ilTextAutorunStyle: {
    'font-size': 13,
  },
});

export class Overview extends SpzaComponent<IOverviewProps, IOverviewState> {
  context: ILocContext & ILocParamsContext & ICommonContext;

  constructor(props: IOverviewProps, context: ILocContext & ILocParamsContext & ICommonContext) {
    super(props, context);

    this.state = { failures: {} };
  }

  getLocalizationKey = (tileType: typeof AppTile | typeof ServiceTile): string => {
    const { isOneTimePayment } = this.props;
    if (tileType === AppTile) {
      return isOneTimePayment ? 'AppDetails_RibbonHeader_TeamOneTimePayment' : 'AppDetails_RibbonHeader';
    }
    if (tileType === ServiceTile) {
      return 'ServiceDetails_RibbonHeader';
    }
    return ''; // Shouldn't reach this case.
  };

  getMedia(): IMedia[] {
    const images: IImages[] = this.props.images || [];
    const videos: IDemoVideos[] = this.props.videos || [];

    const mediaImages: IMedia[] = images.map(
      (image: IImages): IMedia => ({
        name: image.ImageName,
        link: image.ImageUri,
        thumbnail: image.ImageUri,
        type: MediaType.Image,
      })
    );

    const mediaVideos: IMedia[] = videos.map(
      (video: IDemoVideos): IMedia => ({
        name: video.VideoName,
        link: video.VideoLink,
        thumbnail: video.ThumbnailURL,
        type: MediaType.Video,
      })
    );

    return [...mediaVideos, ...mediaImages];
  }

  mediaRendering() {
    const mediaRender: JSX.Element[] = [];
    const list: IMedia[] = this.getMedia();

    if (!list.length) {
      return null;
    }

    for (let i = 0; i < list.length; i++) {
      const item: IMedia = list[`${i}`];

      if (!this.state.failures[item.thumbnail]) {
        const isVideo = item.type === MediaType.Video;

        mediaRender.push(
          <div
            className="imgContent"
            key={i}
            tabIndex={0}
            onKeyPress={(event: React.KeyboardEvent) => {
              if (event.charCode === Constants.SystemKey.Enter) {
                this.onMediaItemSelect(item, i, list);
              }
            }}
            onClick={() => this.onMediaItemSelect(item, i, list)}
          >
            <TelemetryImage
              src={item.thumbnail}
              isVideo={true}
              alt={`Snapshot ${i + 1} of ${this.props.ownerTitle}`}
              ariaLabel={`At a glance ${isVideo ? 'video' : 'image'}`}
              onError={() => {
                this.setState({
                  failures: {
                    ...this.state.failures,
                    [item.thumbnail]: true,
                  },
                });
              }}
            />
            {isVideo ? <TelemetryImage className="overlay" src={videoOverlay} alt="detail page video overlay image" /> : null}
          </div>
        );
      }
    }

    if (!mediaRender.length) {
      return null;
    }

    return (
      <div className="appDetailMedia">
        <h3 className="thumnailsTitle">{this.context.loc('Overview_Thumbnails')}</h3>
        <div className="thumbnails">{mediaRender}</div>
      </div>
    );
  }

  isPowerBiVisual() {
    const { property, mask } = getProdcutBitMaskInfo(DataMap.products.PowerBICustomVisual);
    return !!(this.props.products && bitMasksMatch(this.props.products, property, mask));
  }

  createDisclaimer(): JSX.Element {
    if (!this.isPowerBiVisual()) {
      return <div>{this.context.loc('CP_Disclaimer')}</div>;
    }

    if (this.isPowerBiVisual() && !this.props.isPowerBICertified) {
      return <div>{this.context.loc('CP_Disclaimer_PowerBI')}</div>;
    }

    return (
      <>
        <div>{this.context.loc('CP_Disclaimer_Certified_PowerBI')}</div>
        <div>
          <ExternalLink className="c-hyperlink" href={Constants.learnMoreCertifiedPBI} accessibilityEnabled={true}>
            <span>{this.context.loc('PBI_LearnMore')}.</span>
          </ExternalLink>
        </div>
      </>
    );
  }

  capabilitiesRendering() {
    const capabilitiesPane: JSX.Element[] = [];

    if (this.isPowerBiVisual() && this.props.isPowerBICertified) {
      return null;
    }

    if (this.isPowerBiVisual()) {
      return (
        <ul className="capabilitiesList">
          <li className="capabilitiesListItem" key={0}>
            {this.context.loc('CP_10')}
          </li>
        </ul>
      );
    }

    for (let i = 0; i < this.props.capabilities.length; i++) {
      const capability = this.props.capabilities[`${i}`];
      const subCapabilities = Capabilities[`${capability}`];
      const subCapabilitiesCount = subCapabilities && subCapabilities.length ? subCapabilities.length : 0;

      for (let j = 0; j < subCapabilitiesCount; j++) {
        capabilitiesPane.push(
          <li key={j} className="capabilitiesListItem">
            {this.context.loc(subCapabilities[`${j}`])}
          </li>
        );
      }
    }

    return capabilitiesPane.length ? <ul className="capabilitiesList">{capabilitiesPane}</ul> : null;
  }

  autorunRendering() {
    const { autoRunLaunchEvents } = this.props;
    const autorunsPane: JSX.Element[] = autoRunLaunchEvents
      .filter((autorun) => AutoRuns[`${autorun}`])
      .map((autorun) => {
        const autorunInfo = AutoRuns[`${autorun}`];
        return (
          <li key={autorun}>
            <Text className={contentStyles.ilTextAutorunStyle}>{this.context.loc(autorunInfo.loc, autorunInfo.fallback)}</Text>
          </li>
        );
      });

    return autorunsPane?.length ? (
      <div className={contentStyles.autorunTitleStyle}>
        <Text variant="smallPlus">{this.context.loc('Autorun_Title', 'This add-in can launch itself when:')}</Text>
        <ul className={contentStyles.ulAutorunStyle}>{autorunsPane}</ul>
      </div>
    ) : null;
  }

  showMediaDialog(index: number, list: IMedia[]) {
    DetailUtils.generateLinkPayloadAndLogTelemetry(this.props.ownerType, this.props.ownerId, 'OpenMediaModal', '', 'Overview');
    const jsllMediaType = list[`${index}`]
      ? list[`${index}`].type === MediaType.Image
        ? Constants.JsllSaas.ViewImage
        : Constants.JsllSaas.WatchVideo
      : '';
    pageAction(document.getElementById(this.props.ownerId), {
      contentTags: {
        contentId: jsllMediaType,
        contentName: this.props.ownerId,
        contentType: this.props.isSaaSTransactableApp,
      },
    });

    this.props.openMediaModal({
      defaultIndex: index,
      media: list,
    });
  }

  onMediaItemSelect(selected: IMedia, index: number, list: IMedia[]) {
    DetailUtils.generateLinkPayloadAndLogTelemetry(
      this.props.ownerType,
      this.props.ownerId,
      `Select${selected.type}`,
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      selected.link,
      'Overview'
    );

    this.showMediaDialog(index, list);
  }

  renderDescription(): JSX.Element {
    return <SafeHtmlWrapper className="description" html={this.props.description} />;
  }

  renderShortDescription() {
    return (
      <Text as={'h2'} className={this.props.description ? 'shortDescription' : 'description'}>
        <div dangerouslySetInnerHTML={{ __html: this.props.shortDescription }}></div>
      </Text>
    );
  }

  renderImpl() {
    const { TileType, crossListings } = this.props;
    const localizationTexts: string[] = [];
    let ribbonUrl = 'openTab';
    let ribbonSeeAllText = this.context.loc('Link_SeeAll', 'See all');
    let ribbonTitleParam = '';
    let ribbonKey = '';

    if (TileType === AppTile || TileType === ServiceTile) {
      const localizedTitleKey = this.getLocalizationKey(TileType);
      localizationTexts.push(localizedTitleKey);
      localizationTexts.push('');
      localizationTexts.push('');
      ribbonSeeAllText = '';
      ribbonUrl = null;
      ribbonTitleParam = crossListings?.[0]?.publisher || '';
      ribbonKey = `${localizedTitleKey} ${ribbonTitleParam}`;
    }

    const appDetailContentMaxWidthClasses = classnames({
      appDetailContent: true,
    });

    return (
      <div className="overview overviewContent">
        <div className="detailsContentWrapper">
          <div className={appDetailContentMaxWidthClasses}>
            <div>
              {this.renderShortDescription()}
              {this.renderDescription()}
            </div>
            {this.props.capabilities || this.props.autoRunLaunchEvents?.length ? (
              <div className="capabilities">
                <h3 className="c-heading-6 capabilitiesTitle">
                  {this.context.loc(this.isPowerBiVisual() ? 'CP_Title_PowerBI' : 'CP_Title')}
                </h3>
                {this.props.capabilities && (
                  <>
                    <div className="disclaimer">{this.createDisclaimer()}</div>
                    {this.capabilitiesRendering()}
                  </>
                )}
                {!!this.props.autoRunLaunchEvents?.length && <>{this.autorunRendering()}</>}
              </div>
            ) : null}
          </div>
          {this.mediaRendering()}
        </div>
        {crossListings?.length ? (
          <Ribbon
            title={this.context.locParams(localizationTexts[0], [ribbonTitleParam], localizationTexts[1])}
            seeMoreUrl={ribbonUrl}
            seeMoreText={ribbonSeeAllText}
            subHeader={this.context.locParams(localizationTexts[2], [this.props.ownerTitle], localizationTexts[3])}
          >
            {crossListings
              .slice(0, Constants.crossListingRibbonItemCount)
              .map((value: any, index: number, tiles: (IAppDataItem | Service)[]) => {
                return (
                  <this.props.TileType
                    {...value}
                    item={value}
                    key={index}
                    customCSSClass={'tile' + index}
                    totalTiles={tiles.length}
                    countryContext={this.props.countryCode}
                    ribbonKey={ribbonKey}
                  />
                );
              })}
          </Ribbon>
        ) : null}
      </div>
    );
  }
}

(Overview as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  renderErrorModal: PropTypes.func,
};
