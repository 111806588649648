/* istanbul ignore file */

import { IURLQuery, ICuratedSections, ICuratedSectionItem, ICuratedSection } from '@shared/Models';
import { DataMap, IDataValues } from '@shared/utils/dataMapping';
import { Constants } from '@shared/utils/constants';
import { getProductByUrlKey } from '@shared/utils/appUtils';

const adminPortal = DataMap.products.AdminPortal;
const powerBIHost = DataMap.products.PowerBI;
const powerBIVisualsHost = DataMap.products.PowerBICustomVisual;
const dynamics365financials = DataMap.products.Dynamics365forBusinessCentral;
const dynamics365 = DataMap.products.Dynamics365;
const dynamicsCRMLegacy = DataMap.products.Dynamics365forSales;
const fullScreenMode = 'fullScreenMode';

export function shouldShowCloseButton(embedHost: string) {
  return embedHost !== dynamics365financials.UrlKey;
}

export function shouldHideFilterPane(embedHost: string) {
  return embedHost === powerBIHost.UrlKey || embedHost === dynamics365financials.UrlKey;
}

export function shouldHideIndustriesAndProducts(embedHost: string) {
  return embedHost !== dynamics365.UrlKey && embedHost !== adminPortal.UrlKey;
}

export function shouldFlatProducts(embedHost: string) {
  return embedHost === adminPortal.UrlKey;
}

export function hasDynamicData(embedHost: string) {
  return embedHost === powerBIHost.UrlKey;
}

export function hasPrivateApps(embedHost: string) {
  return embedHost === powerBIHost.UrlKey || embedHost === dynamics365.UrlKey;
}

export function shouldShowCuratedData(embedHost: string) {
  return embedHost === dynamics365.UrlKey || embedHost === adminPortal.UrlKey;
}

export function shouldShowPowerAppsNoContent(embedHost: string) {
  return embedHost === powerBIHost.UrlKey || embedHost === dynamics365.UrlKey;
}

export function shouldShowLegacyStyle(embedHost: string) {
  return embedHost === dynamics365financials.UrlKey || embedHost === dynamicsCRMLegacy.UrlKey;
}

export function shouldShowVideos(embedHost: string) {
  return embedHost !== powerBIVisualsHost.UrlKey;
}

export function getPowerAppsTitle() {
  return DataMap.products.PowerApps.Title;
}

export function isFullScreenMode(query: IURLQuery) {
  const isFullScreen = query[`${fullScreenMode}`];
  return isFullScreen === 'true';
}

export function shouldShowFilter(embedHost: string, product: IDataValues) {
  if (embedHost === adminPortal.UrlKey) {
    if (
      (product.FilterID === DataMap.products.OneNote.FilterID && product.FilterGroup === DataMap.products.OneNote.FilterGroup) ||
      (product.FilterID === DataMap.products.Project.FilterID && product.FilterGroup === DataMap.products.Project.FilterGroup)
    ) {
      return false;
    }
  }

  return true;
}

export function isEmbed(embedHost: string) {
  return embedHost === adminPortal.UrlKey;
}

export function isAppSource(embedHost: string) {
  return embedHost === null || embedHost === undefined;
}

export function getEmbedHost(queryParams: IURLQuery): string {
  const embedHost = decodeURIComponent(queryParams.embedHost);
  const productQuery = decodeURIComponent(queryParams.product);

  if (getProductByUrlKey({ urlKey: embedHost })) {
    return embedHost;
  }

  if (getProductByUrlKey({ urlKey: productQuery })) {
    return productQuery;
  }

  return Constants.dynamics365.salesED;
}

function filterPowerBICuratedData(curatedData: ICuratedSections<ICuratedSectionItem>): ICuratedSections<ICuratedSectionItem> {
  const filteredCuratedData: ICuratedSections<ICuratedSectionItem> = {
    [Constants.Curated.everything]: (curatedData[Constants.Curated.powerPlatform] || []).filter(
      (currentCuratedData: ICuratedSection<ICuratedSectionItem>) => currentCuratedData.titleId === Constants.Curated.powerBi
    ),
  };

  return filteredCuratedData;
}

function filterAdminPortalCuratedData(curatedData: ICuratedSections<ICuratedSectionItem>): ICuratedSections<ICuratedSectionItem> {
  return {
    [Constants.Curated.everything]: curatedData[Constants.Curated.office365],
  };
}

function filterDynamicsCuratedData(curatedData: ICuratedSections<ICuratedSectionItem>): ICuratedSections<ICuratedSectionItem> {
  const validKeys = [
    DataMap.products.Dynamics365,
    DataMap.products.Dynamics365forFinancials,
    DataMap.products.Dynamics365forSales,
    DataMap.products.Dynamics365CustomerInsights,
    DataMap.products.Dynamics365forBusinessCentral,
    DataMap.products.Dynamics365forCustomerServices,
    DataMap.products.Dynamics365forSales,
    DataMap.products.Dynamics365forProjectServicesAutomation,
  ]
    .filter((dataValues) => !!dataValues?.BackendKey)
    .map((dataValues) => dataValues.BackendKey.toLowerCase());

  const filteredCuratedData: ICuratedSections<ICuratedSectionItem> = {
    [Constants.Curated.everything]: (curatedData[Constants.Curated.dynamics365] || []).filter(
      (currentCuratedData: ICuratedSection<ICuratedSectionItem>) =>
        currentCuratedData.titleId && validKeys.indexOf(currentCuratedData.titleId.toLowerCase()) > -1
    ),
  };

  const salesED = DataMap.products.Dynamics365forSales.BackendKey;

  if (salesED && curatedData[`${salesED}`]) {
    filteredCuratedData[`${salesED}`] = curatedData[`${salesED}`];
  }

  return filteredCuratedData;
}

export function filterEmbedCuratedData(
  embedHost: string,
  curatedData: ICuratedSections<ICuratedSectionItem>
): ICuratedSections<ICuratedSectionItem> {
  if (embedHost === Constants.dynamics365.dynamics365) {
    return filterDynamicsCuratedData(curatedData);
  } else if (embedHost === Constants.adminPortal) {
    return filterAdminPortalCuratedData(curatedData);
  } else if (embedHost === Constants.PowerBI.powerBi) {
    return filterPowerBICuratedData(curatedData);
  }

  return {};
}
