import { jwtDecode } from 'jwt-decode';
import { IAccessToken } from '../../../../src/State';

function isTokenExpiring(token: string, minTimeToExpirationSec: number): boolean {
  try {
    const decodedToken = token ? jwtDecode(token) : null;
    if (decodedToken) {
      const currentTimeSec = Math.round(Date.now() / 1000);
      const timeToExpirationSec = currentTimeSec - decodedToken.exp;
      return timeToExpirationSec < minTimeToExpirationSec;
    }
  } catch (err) {
    return false;
  }
  return false;
}

export function shouldRefreshAccessToken(accessToken: IAccessToken, minTimeToExpirationSec: number): boolean {
  return (
    isTokenExpiring(accessToken.spza, minTimeToExpirationSec) ||
    isTokenExpiring(accessToken.graph, minTimeToExpirationSec) ||
    isTokenExpiring(accessToken.arm, minTimeToExpirationSec) ||
    isTokenExpiring(accessToken.pifd, minTimeToExpirationSec) ||
    isTokenExpiring(accessToken.jarvis, minTimeToExpirationSec) ||
    isTokenExpiring(accessToken.commerce, minTimeToExpirationSec) ||
    isTokenExpiring(accessToken.marketplaceLeads, minTimeToExpirationSec)
  );
}
