import React, { useEffect, useState } from 'react';
import { Link, mergeStyleSets, MessageBar } from '@fluentui/react';
import { IAppReview } from '@shared/Models';
import { AppReviewItemToolbar } from '@shared/components/appReviewItemToolbar';
import { ReviewIsvReply } from '@shared/components/reviewIsvReply';
import { AppReviewCommentCollection } from '@shared/containers/appReviewCommentCollection';
import { launchTelemetry } from '@shared/utils/reviewsUtils';
import { Constants } from '@shared/utils/constants';
import { logger } from '@src/logger';
import { ILocContext } from '@shared/interfaces/context';
import { useBoolean } from '@fluentui/react-hooks';
import * as PropTypes from 'prop-types';

export interface IappReviewSocialContentProps {
  isEmbed: boolean;
  review: IAppReview;
  onReportClick: () => void;
  locale: string;
  iconUrl: string;
  publisherName: string;
  isSignedIn: boolean;
  isMarkedAsHelpful: boolean;
  onToggleMarkAsHelpful: (args: { isHelpful: boolean }) => void;
  openSignInModal: () => void;
}

const contentStyles = mergeStyleSets({
  markAsHelpfulAuthMsg: {
    marginTop: 24,
  },
});

const MARK_AS_HELPFUL_MESSAGE_HIDE_MS = 2000;

export const AppReviewSocialContent: React.FunctionComponent<IappReviewSocialContentProps> = (
  {
    isEmbed,
    review,
    onReportClick,
    locale,
    iconUrl,
    publisherName,
    isSignedIn,
    isMarkedAsHelpful,
    onToggleMarkAsHelpful,
    openSignInModal,
  }: IappReviewSocialContentProps,
  context: ILocContext
) => {
  const [commentsExpanded, setCommentsExpanded] = useState(false);
  const [markAsHelpfulAuthMsgExpanded, setMarkAsHelpfulAuthMsgExpanded] = useState(false);
  const [showMarkedAsHelpfulThanks, { setFalse: hideMarkAsHelpfulMessage, setTrue: showMarkAsHelpfulMessage }] =
    useBoolean(false);

  const triggerMarkAsHelpfulThanksMessage = () => {
    showMarkAsHelpfulMessage();

    // Hide the "Thank you" callout after the configured amount of milliseconds
    const timeout = setTimeout(() => {
      hideMarkAsHelpfulMessage();
      clearTimeout(timeout);
    }, MARK_AS_HELPFUL_MESSAGE_HIDE_MS);
  };

  useEffect(() => {
    if (markAsHelpfulAuthMsgExpanded && isSignedIn) {
      setMarkAsHelpfulAuthMsgExpanded(false);
    }
  }, [isSignedIn]);

  const toggleComments = () => {
    const payload = {
      action: commentsExpanded ? Constants.Telemetry.Action.Collapse : Constants.Telemetry.Action.Expand,
      actionModifier: Constants.Telemetry.ActionModifier.ReviewItemCommentsToggleButton,
      details: {
        reviewId: review?.id,
        appId: review?.offer_id,
      },
    };
    launchTelemetry(payload.action, payload.actionModifier, payload.details);
    logger.info(JSON.stringify(payload.details), {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });

    setMarkAsHelpfulAuthMsgExpanded(false);
    setCommentsExpanded((prevCommentsExpanded) => !prevCommentsExpanded);
  };

  const toggleMarkAsHelpfulAuthMsg = () => {
    const payload = {
      action: markAsHelpfulAuthMsgExpanded ? Constants.Telemetry.Action.Collapse : Constants.Telemetry.Action.Expand,
      actionModifier: Constants.Telemetry.ActionModifier.ReviewItemMarkAsHelpfulUnauthorizedMessage,
      details: {
        reviewId: review?.id,
        appId: review?.offer_id,
      },
    };

    launchTelemetry(payload.action, payload.actionModifier, payload.details);

    logger.info(JSON.stringify(payload.details), {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });

    setCommentsExpanded(false);
    setMarkAsHelpfulAuthMsgExpanded((prevMarkAsHelpfulAuthMsgExpanded) => !prevMarkAsHelpfulAuthMsgExpanded);
  };

  const onMarkAsHelpfulClick = () => {
    const { id: reviewId, offer_id: offerId } = review;
    const telemetryPayload = {
      reviewId,
      offerId,
      prevIsMarkedAsHelpful: isMarkedAsHelpful,
    };

    launchTelemetry(
      Constants.Telemetry.Action.Click,
      Constants.Telemetry.ActionModifier.ReviewItemMarkAsHelpfulButton,
      telemetryPayload
    );

    logger.info(JSON.stringify(telemetryPayload), {
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.ReviewItemMarkAsHelpfulButton,
    });

    if (isSignedIn) {
      const isHelpful = !isMarkedAsHelpful;
      onToggleMarkAsHelpful({ isHelpful });
      if (isHelpful) {
        triggerMarkAsHelpfulThanksMessage();
      }
    } else {
      toggleMarkAsHelpfulAuthMsg();
    }
  };

  const onMarkAsHelpfulSignInClick = () => {
    openSignInModal();
    launchTelemetry(Constants.Telemetry.Action.Click, Constants.Telemetry.ActionModifier.ReviewItemMarkAsHelpfulSignIn, {
      reviewId: review?.id,
      offerId: review?.offer_id,
    });
    logger.info(
      JSON.stringify({
        reviewId: review?.id,
        offerId: review?.offer_id,
      }),
      {
        action: Constants.Telemetry.Action.Click,
        actionModifier: Constants.Telemetry.ActionModifier.ReviewItemMarkAsHelpfulSignIn,
      }
    );
  };

  return (
    <>
      {isEmbed || (!review.title && !review.content) ? null : (
        <AppReviewItemToolbar
          onReportAbuse={onReportClick}
          onToggleComments={toggleComments}
          onMarkAsHelpful={onMarkAsHelpfulClick}
          review={review}
          isMarkedAsHelpful={isMarkedAsHelpful}
          showMarkAsHelpfulThanks={showMarkedAsHelpfulThanks}
        />
      )}
      {review.isv_reply && (
        <ReviewIsvReply isvReply={review.isv_reply} locale={locale} iconUrl={iconUrl} publisherName={publisherName} />
      )}
      <AppReviewCommentCollection expanded={commentsExpanded} review={review} locale={locale} />
      {markAsHelpfulAuthMsgExpanded && (
        <MessageBar styles={{ root: contentStyles.markAsHelpfulAuthMsg }}>
          {context.loc('ReviewItem_MarkAsHelpful_Unauthenticated_SignIn_Description', 'To mark as helpful please ')}
          {
            <Link onClick={onMarkAsHelpfulSignInClick}>
              {context.loc('ReviewItem_MarkAsHelpful_Unauthenticated_SignIn_Link', 'sign in.')}
            </Link>
          }
        </MessageBar>
      )}
    </>
  );
};

(AppReviewSocialContent as any).contextTypes = {
  loc: PropTypes.func,
};
