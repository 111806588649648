import React, { FunctionComponent } from 'react';
import * as PropTypes from 'prop-types';
import { ILocContext } from '@shared/interfaces/context';
import { Constants } from '@shared/utils/constants';
import { launchTelemetry } from '@shared/utils/reviewsUtils';
import { logger } from '@src/logger';
import { IAppEntityReviewMarkAsHelpfulModalActionPayload } from '@shared/interfaces/reviews/markAsHelpful/stateModels';
import { ReviewMarkAsHelpfulModalErrorDialog } from './reviewMarkAsHelpfulModalErrorDialog';

export interface IReviewMarkAsHelpfulModalProps {
  payload: IAppEntityReviewMarkAsHelpfulModalActionPayload;
  dismissModal: () => void;
}

export interface ReviewMarkAsHelpfulModalBaseDialogProps {
  context: ILocContext;
  dismissModal: () => void;
  payload: IAppEntityReviewMarkAsHelpfulModalActionPayload;
}

export function ReviewMarkAsHelpfulModal({ payload, dismissModal }: IReviewMarkAsHelpfulModalProps, context: ILocContext) {
  const dismissModalWithTelemetry = () => {
    launchTelemetry(Constants.Telemetry.Action.Close, Constants.Telemetry.ActionModifier.ReviewItemMarkAsHelpfulDialog, {
      payload,
    });
    logger.info(
      JSON.stringify({
        payload,
      }),
      {
        action: Constants.Telemetry.Action.Close,
        actionModifier: Constants.Telemetry.ActionModifier.ReviewItemMarkAsHelpfulDialog,
      }
    );
    dismissModal();
  };

  const innerDialogProps = {
    dismissModal: dismissModalWithTelemetry,
    context,
    payload,
  };

  return <ReviewMarkAsHelpfulModalErrorDialog {...innerDialogProps} />;
}

(ReviewMarkAsHelpfulModal as FunctionComponent).contextTypes = {
  loc: PropTypes.func,
};
