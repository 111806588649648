import { IAppViewProps } from '@shared/components/appView';
import { Constants } from '@shared/utils/constants';

export function handleCTACallback({
  props,
  entity,
  entityType,
  ctaType,
  skuId,
  planId,
  isOpenedFromPDP,
  ribbonKey,
}: {
  props: IAppViewProps;
  entity: any;
  entityType: Constants.EntityType;
  ctaType: Constants.CTAType;
  skuId?: string;
  planId?: string;
  isOpenedFromPDP?: boolean;
  ribbonKey?: string;
}) {
  // getUserAuthStatus will set the state based on the users sign in state, then let the cta show up based on the state.
  props.getUserAuthStatus().then(() => {
    props.showCTAModal({
      entityId: entity.entityId,
      entityType,
      ctaType,
      skuId,
      planId,
      isOpenedFromPDP,
      ribbonKey,
    });
  });
}
