import { IURLQuery } from '../Models';
import { IState } from '../../State';
import { createSetQueryAction } from './queryActions';
import { loadTileData } from './tileDataThunkActions';

export function queryChanged(query: IURLQuery) {
  return function (dispatch: any, getState: () => IState) {
    return Promise.resolve()
      .then(() => {
        dispatch(createSetQueryAction({ query }));

        return dispatch(loadTileData());
      })
      .catch((err) => Promise.reject(err));
  };
}
