/* eslint-disable react/forbid-dom-props */
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Stack, Text } from '@fluentui/react';
import { v4 } from 'uuid';

import SpzaComponent from '@shared/components/spzaComponent';
import {
  IAppDataItem,
  PricingStates,
  AppTag,
  ITileCtaBaseData,
  ITileCtaExtraData,
  OdataRawObjectAppType,
  IBoldString,
  ICtaData,
  IRatingData,
} from '@shared/Models';
import { DataMap } from '@shared/utils/dataMapping';
import {
  IBuildHrefContext,
  ILocContext,
  ILocParamsContext,
  ICTACallbackContext,
  ICommonContext,
  IOpenTileCallbackContext,
} from '@shared/interfaces/context';
import { IUserFavouriteItem } from '@shared/interfaces/userFavouriteModels';
import { BaseTile } from '@shared/containers/baseTile';
import { Constants, OfferType } from '@shared/utils/constants';
import {
  isOfficeNonSaasApp,
  isOfficeApp,
  isWebApp,
  getParentProductTitle,
  getCTATypeForActionString,
  isTransactApp,
  isAvailableInThisRegion,
  isPrivateOffer,
  getPDPRoute,
  isBagTransactable,
  isPowerBIVisuals,
  getCtaBagTransactable,
  getBadgeTypes,
  getProductByUrlKey,
} from '@shared/utils/appUtils';
import { generateTileClickPayloadAndLogTelemetry, getProductsList, hideCertificateBadges } from '@shared/utils/detailUtils';
import { getStartingFromPriceText } from '@shared/utils/pricing';
import { getComponentXYCoordinate, getTileComponentRank } from '@shared/utils/reactUtils';
import { isAppSource, isEmbed } from '@embed/embedHostUtils';
import { getPrimaryProductUrl, bitMasksMatchFilter } from '@shared/utils/datamappingHelpers';
import LinkedAddInsComponent from '@shared/components/linkedAddins';
import { SharedColors } from '@fluentui/theme';
import PBICertified from '@shared/images/badges/PBICertified.svg';
import AzureExpertsMSP from '@shared/images/badges/AzureExpertsMSP.svg';
import PrivateOffer from '@shared/images/badges/PrivateOffer.svg';
import OfficeAwards from '@shared/images/badges/OfficeAwards.svg';
import SolutionMap from '@shared/images/badges/SolutionMap.svg';
import MicrosoftCertified from '@shared/images/badges/MicrosoftCertified.svg';
import AzureBenefitEligibleIMG from '@shared/images/badges/AzureBenefitEligible.svg';
import CertifiedSoftware from '@shared/images/badges/badge_CertifiedSoftware.svg';
import { ItemsTooltip } from '@shared/components/ItemsTooltip';
import webApps from '@shared/images/ProductIcons/web-apps.svg';
import { TelemetryImage } from '@shared/components/telemetryImage';
import { isOneTimePaymentOffer } from '@shared/utils/onetimepaymentoffers';
import { getRatingSummaryByType } from '@shared/utils/reviewsUtils';

const {
  CTAType: {
    Install: CTAInstall,
    BuyLicense: CTABuyLicense,
    Get: CTAGet,
    RequestTrial: CTARequestTrial,
    Try: CTATry,
    Purchase: CTAPurchase,
  },
  ActionStrings: {
    Install: ActionInstall,
    BuyLicense: ActionBuyLicense,
    Get: ActionGet,
    RequestTrial: ActionRequestTrial,
    Try: ActionTry,
  },
  JsllCTAId: { Install: JsllCTAIdInstall, Try: JsllCTAIdTry, BuyLicense: JsllCTAIdBuyLicense },
} = Constants;

export interface IAppTileProps extends IAppDataItem {
  item: IUserFavouriteItem;
  customCSSClass?: string;
  billingCountryCode: string;
  tileIndex: number;
  totalTiles: number;
  correlationId: string;
  isEmbedded: boolean;
  embedHost: string;
  currentView?: string;
  isMobile: boolean;
  signedIn?: boolean;
  shouldShowLinkedAddIn: boolean;
  shouldShowLinkedWebApps: boolean;
  tileDataRequestId: string;
  uiRole: Constants.UiRole;
  doesTenantHasLicenseForApp: boolean;
  isPrivateOffer: boolean;
  locale?: string;
  ribbonKey?: string;
  isMaccUser: boolean;
}

export class AppTile extends SpzaComponent<IAppTileProps, any> {
  context: IBuildHrefContext & ILocContext & ILocParamsContext & ICTACallbackContext & ICommonContext & IOpenTileCallbackContext;
  componentInstance: string;

  constructor(
    props: IAppTileProps,
    context: IBuildHrefContext & ILocContext & ILocParamsContext & ICTACallbackContext & ICommonContext & IOpenTileCallbackContext
  ) {
    super(props, context);
    this.componentInstance = v4();
  }

  handleKeyPress(event: React.KeyboardEvent<HTMLButtonElement>) {
    // only enter key should open the corresponding pop up
    if (event.charCode === Constants.SystemKey.Enter) {
      if (event.preventDefault) {
        event.preventDefault();
      }
      this.getApp(event);
    }
  }

  isPBIVApp = isPowerBIVisuals(getPrimaryProductUrl(this.props.primaryProduct));

  getApp(e: React.SyntheticEvent) {
    e.stopPropagation();

    const {
      closeTile,
      currentView,
      actionString,
      ctaTypes,
      licenseManagement,
      entityId,
      AverageRating,
      NumberOfRatings,
      doesTenantHasLicenseForApp,
      linkedAddIns,
      uiRole,
      activeFilters,
      searchSortingOption,
      count,
      searchReqId,
      setTileCheckoutSource,
      ribbonKey,
    } = this.props;

    if (closeTile && currentView === Constants.currentView.AppDetails) {
      this.props.closeTile();
    }

    let ctaType = getCTATypeForActionString(actionString);

    // Since Get and Purchase have the same action strings, fix the CTA type for purchase
    if (ctaTypes.length && ctaTypes[0] === CTAPurchase) {
      ctaType = CTAPurchase;
    }

    if (this.isPBIVApp) {
      ctaType = CTAGet;
    } else if (isBagTransactable(this.props)) {
      ctaType = getCtaBagTransactable(uiRole, doesTenantHasLicenseForApp, licenseManagement?.isFreemium);
    } else if (licenseManagement?.isMicrosoftManaged) {
      ctaType = this.getCtaMicrosoftManaged()?.type;
    }

    const reference = this.refs[`appTile ${entityId}`];
    const coordinate = getComponentXYCoordinate(reference);
    const tileRank = getTileComponentRank(reference);
    const badgeTypes = getBadgeTypes(this.props);

    setTileCheckoutSource();

    const details = {
      id: entityId,
      rank: JSON.stringify(tileRank),
      xOffset: coordinate.x,
      yOffset: coordinate.y,
      CTAType: ctaType,
      currentView,
      appAverageRating: AverageRating,
      appNumOfRatings: NumberOfRatings,
      isMicrosoftManaged: licenseManagement && licenseManagement.isMicrosoftManaged,
      isFreemium: licenseManagement && licenseManagement.isFreemium,
      hasLicense: doesTenantHasLicenseForApp,
      isSaaSBundle: false,
      discoveryData: {
        totalNumOfResults: count, // correlative to display of apps or services not both
        activeFilters,
        sortingBy: searchSortingOption,
        searchRequestId: searchReqId,
      },
      badge: JSON.stringify(badgeTypes),
    };

    // Add Legacy Telemetry Properties for Bundle App Tile CTA Click
    if (linkedAddIns && linkedAddIns.length > 0) {
      details.isSaaSBundle = true;
    }

    generateTileClickPayloadAndLogTelemetry(Constants.Telemetry.ActionModifier.AppTileCTAButton, details);
    this.context.ctaCallback({
      entity: this.props,
      entityType: Constants.EntityType.App,
      ctaType,
      actionModifier: Constants.Telemetry.ActionModifier.AppTileCTAButton,
      hasLicense: doesTenantHasLicenseForApp,
      ribbonKey,
    });
  }

  renderStartingPrice(): IBoldString {
    const { startingPrice, billingCountryCode, offerType, entityId, locale } = this.props;

    const isOneTimePayment = isOneTimePaymentOffer(entityId);
    if (startingPrice && startingPrice.pricingData && startingPrice.pricingData !== PricingStates.NoPricingData) {
      if (startingPrice.pricingData === PricingStates.FreeApp) {
        return { bold: this.context.loc('Pricing_Free') };
      }

      if (startingPrice.pricingData === PricingStates.StartFromFree) {
        return { light: this.context.loc('Pricing_StartsAt'), bold: this.context.loc('Pricing_Free') };
      }

      if (startingPrice.pricingData === PricingStates.AdditionalPurchasesRequired) {
        return { bold: this.context.loc('AdditionalPurchaseMayBeRequired') };
      }

      if (startingPrice.pricingData === PricingStates.BYOL) {
        return { bold: this.context.loc('Bring_Your_Own_License_Shortcut', 'BYOL') };
      }

      if (typeof startingPrice.pricingData === 'object') {
        return {
          light: this.context.loc('Pricing_StartsAt'),
          bold: getStartingFromPriceText({
            startingPrice,
            offerType,
            context: this.context,
            countryCode: billingCountryCode,
            locale,
            isOneTimePaymentOffer: isOneTimePayment,
          }),
        };
      }

      if (!isAvailableInThisRegion(this.props)) {
        return { light: this.context.loc('NotAvailableInBillingRegion', 'Not available in selected billing region') };
      }
    }

    return null;
  }

  renderPopularity() {
    if (this.props.showPopularity && this.props.popularity) {
      return <div className="pricingText">P#: {this.props.popularity * 1000 || 'none'}</div>;
    }
    return null;
  }

  getTag(keys: string[]) {
    const res: number[] = [];
    for (let i = 0; i < keys.length; i++) {
      const tag = AppTag[`${keys[`${i}`]}`];
      if (tag) {
        res.push(tag);
      }
    }
    return res;
  }

  // Returns array of products an app tile is built for
  getBuiltFor(): string[] {
    const builtForList = [];
    const productTitle = getPrimaryProductUrl(this.props.primaryProduct);
    // Temporary fix for PowerApps to have their builtfor displayed as Apps
    // until we come up with the complete design
    if (
      this.props.products[DataMap.products.PowerApps.FilterGroup] &&
      (this.props.products[DataMap.products.PowerApps.FilterGroup] & DataMap.products.PowerApps.FilterID) > 0
    ) {
      builtForList.push('Apps');
    } else if (this.props.primaryProduct && productTitle && isOfficeNonSaasApp(productTitle, this.props.products)) {
      for (const key in DataMap.products) {
        if (
          isOfficeNonSaasApp(DataMap.products[`${key}`].UrlKey) &&
          bitMasksMatchFilter(this.props.products, DataMap.products[`${key}`])
        ) {
          if (!DataMap.products[`${key}`].ShortcutFilters) {
            builtForList.push(DataMap.products[`${key}`].Title);
          }
        }
      }
    } else {
      const parentProductTitle = getParentProductTitle(productTitle, 'dynamics-365');
      builtForList.push(parentProductTitle || this.props.builtFor);
    }

    return builtForList;
  }

  renderLinkedAddIns() {
    const { linkedAddIns, shouldShowLinkedAddIn, item } = this.props;
    const stackTokens = { childrenGap: 8 };
    const textStyles = { root: { color: SharedColors.cyanBlue10, textDecoration: 'underline', whiteSpace: 'nowrap' } };

    if (linkedAddIns?.length && shouldShowLinkedAddIn) {
      return (
        <Stack horizontal tokens={stackTokens}>
          <Stack.Item>
            <ItemsTooltip
              linkedItemsIds={linkedAddIns.map(({ entityId }) => entityId)}
              title={this.context.loc(
                'AppTileText_RelatedAddInsOnHoverText',
                'This app includes the full version of these add-ins:'
              )}
              parentItem={item}
            >
              <Text variant="smallPlus" styles={textStyles}>
                {this.context.loc('AppTileText_RelatedAddIns', '+included add-ins')}
              </Text>
            </ItemsTooltip>
          </Stack.Item>
          <Stack.Item grow={1}>
            <LinkedAddInsComponent linkedItems={linkedAddIns} />
          </Stack.Item>
        </Stack>
      );
    }
  }

  renderLinkedWebApps() {
    const { linkedSaaS, shouldShowLinkedWebApps, item } = this.props;
    const stackTokens = { childrenGap: 4 };
    const textStyles = { root: { color: SharedColors.cyanBlue10, textDecoration: 'underline', whiteSpace: 'nowrap' } };

    if (linkedSaaS?.length && shouldShowLinkedWebApps) {
      return (
        <Stack horizontal reversed horizontalAlign="end" tokens={stackTokens}>
          <ItemsTooltip
            linkedItemsIds={linkedSaaS.map(({ entityId }) => entityId)}
            title={this.context.loc(
              'AppTileText_RelatedWebAppsOnHoverText',
              'Available SaaS package (includes a full version of this add-in):'
            )}
            parentItem={item}
          >
            <Text variant="smallPlus" styles={textStyles}>
              {this.context.loc('AppTileText_RelatedWebApps', 'View package options')}
            </Text>
          </ItemsTooltip>
          <TelemetryImage src={webApps} />
        </Stack>
      );
    }
  }

  // Returns brand icon for a product
  getBrandIconFromProduct(title: string) {
    return title === 'Web app' ? (
      <div className={'icon-webapp-12'} />
    ) : (
      <div className={'ms-BrandIcon-svg--icon16 product-icon-12 ms-BrandIcon--' + title.toLowerCase()} />
    );
  }

  // Returns component in the form icon + product name
  getBuiltForRenderItem(title: string) {
    return (
      <div className="built-for-product-container">
        <div className="built-for-product-icon-wrapper">
          <div className="built-for-icon">{this.getBrandIconFromProduct(title)}</div>
        </div>
        <span className="built-for-product-title">{title}</span>
      </div>
    );
  }

  // Renders built for prduct type information for app (product type can be one or more)
  renderBuiltForProductType() {
    const builtForProducts = this.getBuiltFor();
    let builtForItems;
    const productTitle = getPrimaryProductUrl(this.props.primaryProduct);
    if (
      (isAppSource(this.props.embedHost) || isEmbed(this.props.embedHost)) &&
      (isOfficeApp(productTitle) || isWebApp(productTitle))
    ) {
      if (
        builtForProducts.length === 1 &&
        (builtForProducts[0] === DataMap.products.Office365.Title ||
          builtForProducts[0] === DataMap.products.AzureforWebApps.Title)
      ) {
        builtForItems = (
          <div className="built-for-outer-wrapper">
            <div className="built-for-container">{this.getBuiltForRenderItem('Web app')}</div>
          </div>
        );
      } else {
        const builtForItemsFirstRow = [];
        const builtForItemsSecondRow = [];
        for (let i = 0; i < builtForProducts.length; i++) {
          if (i < 2) {
            builtForItemsFirstRow.push(this.getBuiltForRenderItem(builtForProducts[`${i}`]));
          } else if (i < 4) {
            builtForItemsSecondRow.push(this.getBuiltForRenderItem(builtForProducts[`${i}`]));
          } else if (i >= 4) {
            const moreHintStr = '...';
            builtForItemsSecondRow.push(
              <div className="built-for-product-container">
                <span className="product-tip">{moreHintStr}</span>
              </div>
            );
            break;
          }
        }

        builtForItems = (
          <div className="built-for-outer-wrapper">
            {builtForItemsFirstRow.length > 0 && <div className="built-for-container">{builtForItemsFirstRow}</div>}
            {builtForItemsSecondRow.length > 0 && <div className="built-for-container">{builtForItemsSecondRow}</div>}
          </div>
        );
      }
    } else {
      builtForItems = null;
    }

    return builtForItems;
  }

  renderDescription(id: string, shortDescription: string) {
    let maxNumberOfLines = 3;
    if (this.props.AverageRating) maxNumberOfLines--;

    const builtFor = this.getBuiltFor();
    if (builtFor && builtFor.length > 2) maxNumberOfLines--;

    const style = {
      '-webkit-line-clamp': maxNumberOfLines.toString(),
      maxHeight: `${maxNumberOfLines * 17}px`,
    } as React.CSSProperties;

    return (
      // eslint-disable-next-line react/forbid-dom-props
      <h5 style={style} id={'desc-' + id} className="c-paragraph-4 description">
        {shortDescription}
      </h5>
    );
  }

  renderRatings(): IRatingData {
    const { ratingSummaries } = this.props;
    const allRatingSummaryObj = getRatingSummaryByType(ratingSummaries, Constants.Reviews.ReviewsSource.All);
    const avgRating = allRatingSummaryObj?.AverageRating || this.props.AverageRating;
    const ratingsCount = allRatingSummaryObj?.TotalRatings || this.props.NumberOfRatings;

    return (
      ratingsCount >= 1 && {
        avgRating,
        ratingsCount,
        key: 'internal',
        text: this.context.loc('Tile_ASRatings', 'ratings'),
      }
    );
  }

  private getCTAData(): ICtaData {
    const { isEmbedded, ctaTypes, billingCountryCode } = this.props;
    const ctaBaseData = this.getCtaBaseData();
    const { area, view } = this.getCtaExtraData();

    return (
      ctaBaseData?.text && {
        id: ctaBaseData.id || Constants.JsllCTAId[Constants.CTAType[ctaBaseData.type]],
        title: ctaBaseData.text,
        area,
        view,
        onClick: this.getApp.bind(this),
        entityId: this.props.entityId,
        CtaType:
          this.props.offerType === OfferType.SaaSApp
            ? isTransactApp({ isEmbedded, ctaTypes, appData: this.props, billingRegion: billingCountryCode })
              ? Constants.JsllSaas.SaasTransactable
              : Constants.JsllSaas.SaasNonTransactable
            : Constants.JsllCTAId[Constants.CTAType[ctaBaseData.type]],
        disabled: !isAvailableInThisRegion(this.props),
      }
    );
  }

  private getCtaBaseData(): ITileCtaBaseData {
    const { licenseManagement, startingPrice, trials, uiRole, doesTenantHasLicenseForApp } = this.props;

    if (this.isPBIVApp) {
      return this.getCtaInstall();
    } else if (isBagTransactable(this.props)) {
      return getCtaBagTransactable(uiRole, doesTenantHasLicenseForApp, licenseManagement?.isFreemium) === CTAInstall
        ? this.getCtaInstall()
        : this.getCtaBuyLicense();
    } else if (licenseManagement?.isMicrosoftManaged) {
      return this.getCtaMicrosoftManaged();
    } else if (startingPrice?.hasFreeTrial || trials === DataMap.trials['free-trial'].FilterID) {
      return this.getCtaTry();
    }
    return this.getCtaByActionString();
  }

  private getCtaMicrosoftManaged(): ITileCtaBaseData {
    const { uiRole, licenseManagement, doesTenantHasLicenseForApp: doesTenantHaveAppLicense, signedIn } = this.props;

    if (!isAvailableInThisRegion(this.props)) {
      return null; // This means the button would dissappear
    }

    if (!signedIn) {
      return this.getCtaRequestTrial();
    }

    const adminCanInstall = licenseManagement?.isFreemium || doesTenantHaveAppLicense;
    if (uiRole === Constants.UiRole.Admin && adminCanInstall) {
      return this.getCtaGet();
    } else {
      return this.getCtaRequestTrial();
    }
  }

  private getCtaGet(): ITileCtaBaseData {
    return {
      id: Constants.JsllCTAId[`${ActionGet}`],
      type: CTAGet,
      text: this.context.loc(ActionGet, 'Get'),
    };
  }

  private getCtaRequestTrial(): ITileCtaBaseData {
    return {
      id: Constants.JsllCTAId[`${ActionRequestTrial}`],
      type: CTARequestTrial,
      text: this.context.loc(ActionRequestTrial, 'Contact Me'),
    };
  }

  private getCtaTry(): ITileCtaBaseData {
    return {
      id: JsllCTAIdTry,
      type: CTATry,
      text: this.context.loc(ActionTry, 'Free Trial'),
    };
  }

  private getCtaInstall(): ITileCtaBaseData {
    return {
      id: JsllCTAIdInstall,
      type: CTAInstall,
      text: this.context.loc(ActionInstall, 'Install'),
    };
  }

  private getCtaBuyLicense(): ITileCtaBaseData {
    return {
      id: JsllCTAIdBuyLicense,
      type: CTABuyLicense,
      text: this.context.loc(ActionBuyLicense, 'Buy License'),
    };
  }

  private getCtaByActionString(): ITileCtaBaseData {
    return {
      id: Constants.JsllCTAId[this.props.actionString] || this.props.actionString,
      text: this.context.loc(this.props.actionString),
    };
  }

  private getCtaExtraData(): ITileCtaExtraData {
    let area;
    let view;
    const linkedAddIns = this.props.linkedAddIns;
    if (linkedAddIns && linkedAddIns.length > 0) {
      if (this.props.currentView === Constants.currentView.AppDetails) {
        area = Constants.Telemetry.AreaName.ParentSaaSTile;
      } else if (
        this.props.currentView === Constants.currentView.AppGallery ||
        this.props.currentView === Constants.currentView.SearchAppGallery
      ) {
        area = Constants.Telemetry.AreaName.AppTile;
      }
      view = Constants.Telemetry.TemplateName.SaaSBundle;
    } else if (this.props.linkedSaaS) {
      view = Constants.Telemetry.AreaName.BundleAddIn;
      area = Constants.Telemetry.AreaName.AppTile;
    } else {
      area = Constants.Telemetry.AreaName.AppTile;
    }

    return { area, view };
  }

  getAppDetailsUrl(productType: string, appType: OdataRawObjectAppType) {
    return this.context.buildHref(
      getPDPRoute(appType),
      { productId: productType, entityId: this.props.entityId },
      { tab: 'Overview' }
    );
  }

  getProducts() {
    return this.props.products && Object.keys(this.props.products).length > 0 && getProductsList(this.props.products);
  }

  renderBadge(): JSX.Element {
    const {
      azureExpertMsp,
      isPrivate,
      m365Certified,
      officeAppAwards,
      pbi,
      preferredSolution,
      AzureBenefitEligible,
      isCertifiedSoftware,
    } = getBadgeTypes(this.props);
    const { isMaccUser } = this.props;
    const isAzureBenefitEligible = AzureBenefitEligible !== 0;

    if (
      m365Certified ||
      preferredSolution ||
      officeAppAwards ||
      pbi ||
      isPrivate ||
      azureExpertMsp ||
      isAzureBenefitEligible ||
      isCertifiedSoftware
    ) {
      return (
        <div className="tileBadgeContainer">
          {m365Certified && (
            <img
              className="tileBadge"
              hidden={hideCertificateBadges()}
              src={MicrosoftCertified}
              title={this.context.loc('Microsoft365Certified_Badge', 'Microsoft 365 Certified')}
              alt={this.context.loc('Microsoft365Certified_Badge', 'Microsoft 365 Certified')}
            />
          )}
          {preferredSolution && (
            <img
              className="tileBadge"
              src={SolutionMap}
              title={this.context.loc('MicrosoftPreferredSolution_Badge', 'Microsoft preferred solution')}
              alt={this.context.loc('MicrosoftPreferredSolution_Badge', 'Microsoft preferred solution')}
            />
          )}
          {officeAppAwards && (
            <img
              className="tileBadge"
              src={OfficeAwards}
              title={this.context.loc('Microsoft365AppAward_Badge', 'Microsoft 365 App Award')}
              alt={this.context.loc('Microsoft365AppAward_Badge', 'Microsoft 365 App Award')}
            />
          )}
          {isPrivate && (
            <img
              className="tileBadge size-14"
              src={PrivateOffer}
              title={this.context.loc('UserPrivatePlan_Badge')}
              alt={this.context.loc('UserPrivatePlan_Badge')}
            />
          )}
          {pbi && (
            <img
              className="tileBadge"
              src={PBICertified}
              title={this.context.loc('PowerBICertified_Badge', 'Power BI Certified')}
              alt={this.context.loc('PowerBICertified_Badge', 'Power BI Certified')}
            />
          )}
          {azureExpertMsp && (
            <img
              className="tileBadge"
              src={AzureExpertsMSP}
              title={this.context.loc('AzureExpertsMSP_Badge', 'Azure Experts MSP')}
              alt={this.context.loc('AzureExpertsMSP_Badge', 'Azure Experts MSP')}
            />
          )}
          {isAzureBenefitEligible && isMaccUser && (
            <img
              className="tileBadge"
              src={AzureBenefitEligibleIMG}
              title={this.context.loc('Global_Loc_Azure_benefit', 'Azure benefit eligible')}
              alt={this.context.loc('Global_Loc_Azure_benefit', 'Azure benefit eligible')}
            />
          )}
          {isCertifiedSoftware && (
            <img
              className="tileBadge"
              src={CertifiedSoftware}
              title={this.context.loc('CertifiedSoftware', 'Certified software')}
              alt={this.context.loc('CertifiedSoftware', 'Certified software')}
            />
          )}
        </div>
      );
    }
    return null;
  }

  renderImpl() {
    const productString = getPrimaryProductUrl(this.props.primaryProduct);
    const productData = getProductByUrlKey({ urlKey: productString });
    const product = productData ? productData.UrlKey : productString;

    const isPrivate = isPrivateOffer(this.props.appType);
    const appDetailUrl = this.getAppDetailsUrl(product, this.props.appType);
    const { showPopularity, popularity, shouldShowLinkedAddIn, shouldShowLinkedWebApps, ribbonKey = '' } = this.props;

    return (
      <BaseTile
        totalTiles={this.props.totalTiles}
        badge={this.renderBadge()}
        id={this.props.entityId}
        componentInstance={this.componentInstance}
        item={this.props.item}
        iconURL={this.props.iconURL}
        title={this.props.title}
        tileDataRequestId={this.props.tileDataRequestId}
        publisher={this.props.publisher}
        description={this.props.shortDescription}
        ctaData={this.getCTAData()}
        detailUrl={appDetailUrl}
        tileIndex={this.props.tileIndex}
        currentView={this.props.currentView}
        userFavouriteEntityType={isPrivate ? null : Constants.UserFavourite.ApplicationTypes.ITApplications}
        linkedAddIns={this.renderLinkedAddIns()}
        linkedWebApps={this.renderLinkedWebApps()}
        price={this.renderStartingPrice()}
        rating={this.renderRatings()}
        openTileCallback={this.context.openTileCallback}
        products={this.getProducts()}
        context={this.context}
        popularity={showPopularity && popularity}
        shouldShowLinkedAddIn={shouldShowLinkedAddIn}
        shouldShowLinkedWebApps={shouldShowLinkedWebApps}
        badgeTypes={getBadgeTypes(this.props)}
        ribbonKey={ribbonKey}
      />
    );
  }
}

(AppTile as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  buildHref: PropTypes.func,
  ctaCallback: PropTypes.func,
  renderErrorModal: PropTypes.func,
  openTileCallback: PropTypes.func,
};
