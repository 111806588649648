import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import {
  Text,
  mergeStyleSets,
  TextField,
  Separator,
  Checkbox,
  Dialog,
  PrimaryButton,
  Stack,
  IconButton,
  ButtonType,
  DefaultButton,
  Link,
  FontIcon,
} from '@fluentui/react';

import type { IButtonStyles, ITextStyles } from '@fluentui/react';
import { NeutralColors, SharedColors } from '@fluentui/theme';
import { storeFeedback } from '@shared/services/feedback';
import { Constants } from '@shared/utils/constants';
import { useSpzaInstrumentService } from '@shared/hooks';
import { ITelemetryData } from '@shared/Models';
import { getWindow } from '@shared/services/window';
import { SubmittedModal } from '@shared/components/modals/submittedModal';
import { useLocation } from 'react-router-dom';

interface IFeatureFeedbackSubmittedModalProps {
  hidden: boolean;
  onDismiss: () => void;
  context: ILocContext & ILocParamsContext;
}

const subTextStyles: Partial<ITextStyles> = { root: { color: NeutralColors.gray130, lineHeight: 20 } };

export const FeatureFeedbackSubmittedModal: React.FunctionComponent<IFeatureFeedbackSubmittedModalProps> = ({
  hidden,
  onDismiss,
  context,
}: IFeatureFeedbackSubmittedModalProps) => {
  return (
    <SubmittedModal
      hidden={hidden}
      onDismiss={onDismiss}
      title={context.loc('Nps_SubmittedModal_Title', 'Feedback received')}
      context={context}
    >
      <Text block styles={subTextStyles}>
        {context.loc('Nps_SubmittedModal_Summary_Text', 'Thanks for taking the time to share your thoughts!')}
      </Text>
    </SubmittedModal>
  );
};

const DIALOG_MAX_WIDTH = 560;
const DIALOG_MIN_WIDTH = 300;
const DIALOG_MINI_MODE_WIDTH = 480;
const NO_SCORE = -1;

const buttonStyles: Partial<IButtonStyles> = {
  root: { minWidth: 40, height: 48, maxWidth: 40, border: '1px solid #E1E3E5' },
  rootChecked: { color: NeutralColors.white, backgroundColor: SharedColors.cyanBlue10 },
  rootCheckedHovered: { color: NeutralColors.white, backgroundColor: SharedColors.cyanBlue10 },
};

const contentStyles = mergeStyleSets({
  feedback: {
    width: 'auto',
    height: 'auto',
  },
  defaultText: [{ color: NeutralColors.gray130 }],
  stackdiv: {
    display: 'flex',
    flexDirection: 'column',
  },
  openQuestionDiv: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 40,
  },
  checksDiv: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 40,
  },
  title: {
    fontSize: 20,
    lineHeight: 28,
    fontWeight: 600,
  },
  scoreDiv: {
    marginTop: 48,
  },
  checkBox: {
    marginTop: 16,
    lineHeight: 16,
  },
  scorePanel: {
    marginTop: 18,
  },
  scoreSubtitle: {
    lineHeight: 20,
    fontSize: 14,
    fontWeight: 600,
  },
  scoreMiniSecondLine: {
    marginTop: 8,
  },
  checksSubtitle: {
    lineHeight: 20,
    fontSize: 14,
    fontWeight: 600,
  },
  openQuestionTextField: {
    height: 96,
  },
  inputSectionSeparator: {
    marginTop: 32,
  },
  emailConsentCheck: {
    marginTop: 9,
  },
  footer: {
    marginTop: 5,
    height: 96,
  },
  privacyStatement: {
    marginTop: 7,
  },
  submitButton: {
    alignSelf: 'start',
    width: 86,
    lineHeight: 24,
    marginTop: 9,
  },
  faceButtonSelected: {
    backgroundColor: NeutralColors.gray20,
  },
  faceButtonNotSelected: {
    backgroundColor: 'transparent',
  },
  requiredIcon: {
    fontSize: 7,
    padding: 2,
    color: '#A80000',
  },
  closeButton: {
    alignSelf: 'end',
    float: 'right',
  },
  closeIcon: {
    fontSize: 16,
    color: NeutralColors.gray160,
  },
  closeIconRoot: {
    width: 24,
    height: 24,
  },
});

export interface IFeatureCheckDefinition {
  key: string;
  text: string;
}

export interface IFeatureFeedbackContent {
  title?: string;
  scoreTitle?: string;
  scoreMinLabel?: string;
  scoreMaxLabel?: string;
  scoreRequired?: boolean;
  checksTitle?: string;
  checks?: IFeatureCheckDefinition[];
  openQuestionFieldTitle?: string;
  openQuestionFieldPlaceholder?: string;
}

export interface IFeatureFeedbackProps {
  featureName: string; // Use as action in feedback log/data table.
  viewContent?: IFeatureFeedbackContent; // allow to custom feedback dialog content.
  email: string; // current user email
  onFeedbackSubmitted: () => void; // Called after user click submit and feedback info logged.
  onDialogDismissed: () => void; // Called after user performed some sort of dismiss request.
}
export interface IFeatureFeedbackScoreSectionProps {
  viewContent?: IFeatureFeedbackContent;
  score: number;
  onScoreChange: (score: number) => void;
}

export const scoreValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] as const;
export const scoreValuesMiniLine1 = [0, 1, 2, 3, 4, 5] as const;
export const scoreValuesMiniLine2 = [6, 7, 8, 9, 10] as const;

export function FeatureFeedbackScoreSection(
  { viewContent, score, onScoreChange }: IFeatureFeedbackScoreSectionProps,
  context: ILocContext & ILocParamsContext
) {
  const miniMode = getWindow()?.innerWidth <= DIALOG_MINI_MODE_WIDTH;
  return (
    <div className={contentStyles.scoreDiv}>
      <Stack verticalAlign="center" horizontal>
        <Text className={contentStyles.scoreSubtitle}>
          {viewContent?.scoreTitle ||
            context.loc('Feature_Feedback_Score_Title', 'How valuable do you find the information provided by LinkedIn?')}
        </Text>
        <FontIcon iconName="Asterisk" className={contentStyles.requiredIcon} />
      </Stack>
      <Stack className={contentStyles.scorePanel} tokens={{ childrenGap: 8 }}>
        <Stack horizontal horizontalAlign="space-between">
          <Text variant="smallPlus" className={contentStyles.defaultText}>
            {viewContent?.scoreMinLabel || context.loc('Feature_Feedback_Score_Min_Title', 'Min')}
          </Text>
          <Text variant="smallPlus" className={contentStyles.defaultText}>
            {viewContent.scoreMaxLabel || context.loc('Feature_Feedback_Score_Max_Title', 'Max')}
          </Text>
        </Stack>
        {miniMode ? (
          <div>
            <Stack horizontal tokens={{ childrenGap: 8 }}>
              {scoreValuesMiniLine1.map((ratingValue) => (
                <DefaultButton
                  toggle
                  buttonType={score === ratingValue ? ButtonType.primary : ButtonType.default}
                  checked={score === ratingValue}
                  styles={buttonStyles}
                  key={ratingValue}
                  text={`${ratingValue}`}
                  onClick={() => onScoreChange(ratingValue)}
                />
              ))}
            </Stack>
            <Stack className={contentStyles.scoreMiniSecondLine} horizontal tokens={{ childrenGap: 8 }}>
              {scoreValuesMiniLine2.map((ratingValue) => (
                <DefaultButton
                  toggle
                  buttonType={score === ratingValue ? ButtonType.primary : ButtonType.default}
                  checked={score === ratingValue}
                  styles={buttonStyles}
                  key={ratingValue}
                  text={`${ratingValue}`}
                  onClick={() => onScoreChange(ratingValue)}
                />
              ))}
            </Stack>
          </div>
        ) : (
          <Stack horizontal tokens={{ childrenGap: 8 }}>
            {scoreValues.map((ratingValue) => (
              <DefaultButton
                toggle
                buttonType={score === ratingValue ? ButtonType.primary : ButtonType.default}
                checked={score === ratingValue}
                styles={buttonStyles}
                key={ratingValue}
                text={`${ratingValue}`}
                onClick={() => onScoreChange(ratingValue)}
              />
            ))}
          </Stack>
        )}
      </Stack>
    </div>
  );
}

export interface IFeatureFeedbackTitleSectionProps {
  viewContent?: IFeatureFeedbackContent;
  onDialogDismissed: () => void;
}

export function FeatureFeedbackTitleSection(
  { viewContent, onDialogDismissed }: IFeatureFeedbackTitleSectionProps,
  context: ILocContext & ILocParamsContext
) {
  return (
    <div className={contentStyles.stackdiv}>
      <div>
        <Text className={contentStyles.title}>
          {viewContent?.title || context.loc('Feature_Feedback_Dialog_Title', 'We’d love your feedback')}
        </Text>
        <IconButton
          className={contentStyles.closeButton}
          styles={{
            icon: contentStyles.closeIcon,
            root: contentStyles.closeIconRoot,
          }}
          iconProps={{ iconName: 'ChromeClose' }}
          aria-label={context.loc('Dialog_Close', 'Close')}
          onClick={onDialogDismissed}
        />
      </div>
    </div>
  );
}

export interface IFeatureFeedbackChecksSectionProps {
  viewContent?: IFeatureFeedbackContent;
  checks: { [key: string]: boolean }[];
  setCheckChange: (key: string, checked: boolean) => void;
}

export function FeatureFeedbackChecksSection(
  { viewContent, checks, setCheckChange }: IFeatureFeedbackChecksSectionProps,
  context: ILocContext & ILocParamsContext
) {
  return (
    <div className={contentStyles.checksDiv}>
      <Text className={contentStyles.checksSubtitle}>
        {viewContent?.checksTitle ||
          context.loc('Feature_Feedback_Checks_Title', 'Which of the following contributed to your experience?')}
      </Text>
      {viewContent?.checks?.map((featureCheckDef: IFeatureCheckDefinition) => {
        return (
          <Checkbox
            className={contentStyles.checkBox}
            key={featureCheckDef.key}
            label={featureCheckDef.text}
            checked={checks[featureCheckDef.key] && checks[featureCheckDef.key]}
            onChange={(element, checked) => setCheckChange(featureCheckDef.key, checked)}
          />
        );
      })}
    </div>
  );
}

export interface IFeatureFeedbackInputSection {
  viewContent?: IFeatureFeedbackContent;
  userComment: string;
  setUserComment: (userComment: string) => void;
}

export function FeatureFeedbackInputSection(
  { viewContent, userComment, setUserComment }: IFeatureFeedbackInputSection,
  context: ILocContext & ILocParamsContext
) {
  return (
    <div className={contentStyles.openQuestionDiv}>
      <TextField
        value={userComment}
        label={
          viewContent?.openQuestionFieldTitle ||
          context.loc('Feature_Feedback_Open_Question_Title', 'What can we do to improve your experience?')
        }
        placeholder={
          viewContent?.openQuestionFieldPlaceholder ||
          context.loc('Feature_Feedback_Open_Question_Placeholder', 'Tell us about your experience...')
        }
        className={contentStyles.openQuestionTextField}
        multiline
        rows={4}
        onChange={(ev, newValue) => setUserComment(newValue)}
      />
    </div>
  );
}

export interface IFeatureFeedbackEmailSection {
  email: string;
  emailConsent: boolean;
  setEmailConsent: (emailConsent: boolean) => void;
}

export function FeatureFeedbackEmailSection(
  { email, emailConsent, setEmailConsent }: IFeatureFeedbackEmailSection,
  context: ILocContext & ILocParamsContext
) {
  return (
    <div>
      <Separator className={contentStyles.inputSectionSeparator} />
      {email && (
        <Checkbox
          label={context.locParams(
            'Feature_Feedback_Email_Concent',
            [email],
            `Microsoft can email you to ${email} about your feedback`
          )}
          className={contentStyles.emailConsentCheck}
          checked={emailConsent}
          onChange={(ev, checked) => {
            setEmailConsent(checked);
          }}
        />
      )}
    </div>
  );
}

export interface IFeatureFeedbackFooterSection {
  onSubmitClick: () => void;
  submitDisabled: boolean;
}

export function FeatureFeedbackFooterSection(
  { onSubmitClick, submitDisabled }: IFeatureFeedbackFooterSection,
  context: ILocContext & ILocParamsContext
) {
  return (
    <div className={contentStyles.stackdiv}>
      <Link
        underline
        target="_blank"
        rel="noreferrer"
        href="https://aka.ms/AppSourcePrivacyStatement"
        className={contentStyles.privacyStatement}
      >
        {context.loc('Feedback_privacy', 'Privacy statement')}
      </Link>
      <PrimaryButton
        disabled={submitDisabled}
        onClick={onSubmitClick}
        className={contentStyles.submitButton}
        text={context.loc('Feature_FeedbackSubmit_Button', 'Submit')}
      />
    </div>
  );
}

/* Feature feedback dialog.
   This component can be customize to allow getting feedback for diffrent features.
   To cistomize its content use 'viewContent' property.
   The component handle feedback submission (to log/data table) and trigger event after feedback has been submitted.
   When dialod in react component tree, it is always visible.
   User should handle dialog visibility externaly by using 'onFeedbackSubmitted' and 'onDialogDismissed' callbacks.
*/
function FeatureFeedbackInternal({
  featureName,
  viewContent,
  email,
  onFeedbackSubmitted,
  onDialogDismissed,
}: IFeatureFeedbackProps) {
  const location = useLocation();
  const [score, setScore] = useState(NO_SCORE);
  const [emailConsent, setEmailConsent] = useState(false);
  const [userComment, setUserComment] = useState('');
  const [instrument] = useSpzaInstrumentService();
  const [checks, setChecks] = React.useState(
    viewContent?.checks
      ? viewContent.checks.map((featureCheck) => {
          return { [featureCheck.key]: false };
        })
      : []
  );

  const setCheckChange = (key: string, checked: boolean) => setChecks({ ...checks, [key]: checked });

  const getEmail = () => {
    return emailConsent ? email : '';
  };

  const onSubmitClick = () => {
    const userFeedbackContent = {
      score,
      checks,
      comments: userComment,
      email: getEmail(),
    };

    // New logger
    storeFeedback({
      action: Constants.Telemetry.Action.CVA,
      actionModifier: featureName,
      details: JSON.stringify(userFeedbackContent),
    });

    // Old logger
    const payload: ITelemetryData = {
      page: location?.pathname,
      action: Constants.Telemetry.Action.CVA,
      actionModifier: featureName,
      details: JSON.stringify(userFeedbackContent),
      isFeedback: true,
    };
    instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', payload);

    onFeedbackSubmitted?.();
  };

  return (
    <Dialog maxWidth={DIALOG_MAX_WIDTH} minWidth={DIALOG_MIN_WIDTH} hidden={false} onDismiss={onDialogDismissed}>
      <div className={contentStyles.feedback}>
        <FeatureFeedbackTitleSection viewContent={viewContent} onDialogDismissed={onDialogDismissed} />
        <FeatureFeedbackScoreSection viewContent={viewContent} onScoreChange={setScore} score={score} />
        <FeatureFeedbackChecksSection viewContent={viewContent} checks={checks} setCheckChange={setCheckChange} />
        <FeatureFeedbackInputSection viewContent={viewContent} userComment={userComment} setUserComment={setUserComment} />
        <FeatureFeedbackEmailSection email={email} emailConsent={emailConsent} setEmailConsent={setEmailConsent} />
        <FeatureFeedbackFooterSection
          onSubmitClick={onSubmitClick}
          submitDisabled={viewContent?.scoreRequired && score === NO_SCORE}
        />
      </div>
    </Dialog>
  );
}

export const FeatureFeedback = FeatureFeedbackInternal;

(FeatureFeedbackTitleSection as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
};

(FeatureFeedbackScoreSection as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
};

(FeatureFeedbackChecksSection as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
};

(FeatureFeedbackInputSection as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
};

(FeatureFeedbackEmailSection as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
};

(FeatureFeedbackFooterSection as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
};
