import * as React from 'react';
import { Label, mergeStyleSets } from '@fluentui/react';
import { ICommonContext, ILocContext, ILocParamsContext } from '@shared/interfaces/context';

export interface IRatingPercentages {
  context: ILocContext & ILocParamsContext & ICommonContext;
  numberOfStars: number;
  percentagesOfTotal: number;
}

export interface IRatingPercentagesClassNames {
  fillWidth: string;
}

export const getClassNames = (fill: number): IRatingPercentagesClassNames => {
  return mergeStyleSets({
    fillWidth: {
      width: `${fill}%`,
    },
  });
};

export const RatingPercentages = ({ context, numberOfStars = 0, percentagesOfTotal = 0 }: IRatingPercentages) => {
  const classNames = require('classnames');
  const { fillWidth } = getClassNames(percentagesOfTotal);
  const chartClasses = classNames('ratingsPercentagesChartFill', fillWidth);
  return (
    <div className="ratingPercentagesContainer">
      <div className="ratingPercentagesDetails">
        <Label className="ratingPercentagesNumOfStars">
          {numberOfStars} {context?.loc('Global_Loc_starsLower', 'stars')}
        </Label>
        <text className="percentagesOfTotal">({percentagesOfTotal}%)</text>
      </div>
      <div className="ratingsPercentagesChart">
        <div className={chartClasses} />
      </div>
    </div>
  );
};
