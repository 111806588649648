import * as httpProtocol from './httpProtocol';
import { Constants } from '../../utils/constants';
import { getAppConfig } from '../../services/init/appConfig';
import { logError } from '../../utils/httpClientUtil';
import { IUserProfile } from '@shared/Models';

export function getUserProfile(): Promise<IUserProfile> {
  const endpoint = '/view/userProfile';
  const options = {
    requireCorrelationId: true,
    stringifyPostData: false,
    authenticationType: httpProtocol.AuthenticationTypes.MarketplaceLeads,
    setBearerHeader: true,
  };

  const get = httpProtocol.get(endpoint, options);

  return get.request().catch((error: any) => {
    logError('getUserProfile', error);
    return Promise.reject(error);
  });
}

export function getUserTenantsDetails() {
  const endpoint = `${getAppConfig('armApiHost')}/tenants?api-version=2020-01-01`;
  const options = {
    requireCorrelationId: true,
    stringifyPostData: false,
    authenticationType: httpProtocol.AuthenticationTypes.Arm,
    setBearerHeader: false,
  };
  const get = httpProtocol.get(endpoint, options);

  return get.request().catch((error: any) => {
    logError('getUserTenantsDetails', error);
    return Promise.reject(error);
  });
}

export function getUserBillingAccounts(): Promise<any> {
  const endpoint = `${getAppConfig('armApiHost')}/providers/Microsoft.Billing/billingAccounts`;
  const options = {
    requireCorrelationId: true,
    stringifyPostData: false,
    allowOrigin: true,
    authenticationType: httpProtocol.AuthenticationTypes.Arm,
    contentType: 'application/json',
    returnRawResponse: false,
  };

  const get = httpProtocol.get(endpoint, options);

  return get
    .addQueryEntry(Constants.QueryStrings.apiVersion, Constants.armBillingAccountsApiVersion)
    .addQueryEntry(Constants.QueryStrings.expand, 'properties/enrollmentDetails')
    .request()
    .catch((error: any) => {
      logError('getUserBillingAccounts', error);
      return Promise.reject(error);
    });
}
