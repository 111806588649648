import * as httpProtocol from '@shared/services/http/httpProtocol';
import { ILeadPayload } from '@shared/Models';
import { Constants } from '@shared/utils/constants';
import { getAppConfig } from '@shared/services/init/appConfig';
import { logError } from '@shared/utils/httpClientUtil';
import { marketplaceLeadsApiVersion } from '@shared/specifics';

const marketplaceLeadsTimeoutInMs = 30000;

export interface SendLeadOptions {
  requestId?: string;
}


export async function sendLead(payload: ILeadPayload, options?: SendLeadOptions) {
  try {
    const { requestId } = options || {};
    const endpoint = `${getAppConfig('marketplaceLeadsHost')}/api/leads`;
    const httpOptions: httpProtocol.IHttpOption = {
      requireCorrelationId: true,
      stringifyPostData: false,
      allowOrigin: true,
      authenticationType: httpProtocol.AuthenticationTypes.MarketplaceLeads,
      setBearerHeader: true,
      contentType: 'application/json',
      requestId,
      timeout: marketplaceLeadsTimeoutInMs,
    };

    const post = httpProtocol.post(endpoint, httpOptions);
    return post.addQueryEntry(Constants.QueryStrings.apiVersion, marketplaceLeadsApiVersion).setData(payload).request();
  } catch (error) {
    logError('sendLead', error);
    throw error;
  }
}
