import { Constants } from '@shared/utils/constants';
import { get, IHttpOption, AuthenticationTypes } from '@shared/services/http/httpProtocol';
import { IAppReview } from '@shared/Models';

interface BaseReviewsArgs {
  reviewsAPI: string; // The endpoint for the Enrich reviews API
  reviewsAPIVersion: string; // The version of the Enrich reviews API
}

interface BaseProductReviewsArgs extends BaseReviewsArgs {
  offerId: string;
}

interface GetReviewsByOfferIdArgs extends BaseProductReviewsArgs {
  correlationId: string;
  requestId: string;
  userId?: string;
}

interface GetUserReviewByOfferIdArgs extends BaseProductReviewsArgs {
  token: string;
  source: string | string[];
  correlationId: string;
  requestId: string;
}

interface ReviewsMetadata {
  myReviewId: string;
  markAsHelpfulReviewIds: string[];
  commentReviewIds: string[];
}

const commonHttpOptions: IHttpOption = {
  requireCorrelationId: true,
  stringifyPostData: false,
  authenticationType: AuthenticationTypes.Spza,
  setBearerHeader: true,
  timeout: 120000,
};

export const ReviewsQueryStrings = {
  OfferId: 'offer_id',
  ApiVersion: 'api-version',
  Source: 'source',
  UserId: 'user_id',
  From: 'from',
};

export function getReviewsByOfferId({
  offerId,
  reviewsAPI,
  reviewsAPIVersion,
  correlationId,
  requestId,
  userId,
}: GetReviewsByOfferIdArgs): Promise<IAppReview[]> {
  const req = get(`${reviewsAPI}`, commonHttpOptions);

  if (userId) {
    req.addQueryEntry(ReviewsQueryStrings.UserId, userId);
  }

  return req
    .addQueryEntry(ReviewsQueryStrings.ApiVersion, reviewsAPIVersion)
    .addQueryEntry(ReviewsQueryStrings.OfferId, offerId)
    .setHeader(Constants.Headers.RequestId, requestId)
    .setHeader(Constants.Headers.CorrelationId, correlationId)
    .request();
}

export function getUserReviewByOfferId({
  token,
  offerId,
  source,
  correlationId,
  requestId,
  reviewsAPI,
  reviewsAPIVersion,
}: GetUserReviewByOfferIdArgs): Promise<IAppReview[]> {
  const req = get(`${reviewsAPI}/private/user`, commonHttpOptions);
  req.setQuery({
    source,
  });
  return req
    .addQueryEntry(ReviewsQueryStrings.ApiVersion, reviewsAPIVersion)
    .addQueryEntry(ReviewsQueryStrings.OfferId, offerId)
    .setHeader(Constants.Headers.RequestId, requestId)
    .setHeader(Constants.Headers.CorrelationId, correlationId)
    .setAuthHeader(token)
    .request();
}

export async function getReviewsMetadata({
  reviewsAPI,
  reviewsAPIVersion,
  legacyId,
}: {
  legacyId: string;
  reviewsAPI: string;
  reviewsAPIVersion: string;
}): Promise<ReviewsMetadata> {
  return await get(`${reviewsAPI}/private/metadata`, commonHttpOptions)
    .addQueryEntry(ReviewsQueryStrings.ApiVersion, reviewsAPIVersion)
    .addQueryEntry(ReviewsQueryStrings.OfferId, legacyId)
    .request();
}

interface GetAllReviewsByTimestampArgs extends BaseReviewsArgs {
  timestamp: number;
  correlationId: string;
  requestId: string;
  source: string[];
}

/**
 * @deprecated - This API is deprecated and should not be used, this is currently in use in order to retain legacy behaviour.
 */
export async function getAllReviewsByTimestamp({
  reviewsAPI,
  reviewsAPIVersion,
  timestamp,
  correlationId,
  requestId,
  source,
}: GetAllReviewsByTimestampArgs): Promise<IAppReview[]> {
  return get(`${reviewsAPI}/deprecated`, commonHttpOptions)
    .setQuery({
      source,
    })
    .setHeader(Constants.Headers.RequestId, requestId)
    .setHeader(Constants.Headers.CorrelationId, correlationId)
    .addQueryEntry(ReviewsQueryStrings.ApiVersion, reviewsAPIVersion)
    .addQueryEntry(ReviewsQueryStrings.From, new Date(timestamp * 1000).toJSON())
    .request();
}
