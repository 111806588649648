import { connect } from 'react-redux';

import { IState } from '@src/State';
import { CookieBanner as CookieBannerComponent, ICookieBannerProps, WCPconsent } from '@shared/components/cookieBanner';
import {
  createIsManageModalOpenAction,
  createSetWCPConsentAction,
  createSetIsConsentRequiredAction,
} from '@shared/actions/actions';
import { Constants } from '@shared/utils/constants';

const mapStateToProps = (state: IState): ICookieBannerProps => {
  const {
    config: { locale = Constants.Cookies.WCPDefaultLocale, cookiesConsent, siteEnvironment },
  } = state;

  return {
    locale,
    isManagementModalOpen: cookiesConsent.isManageModalOpen,
    siteEnvironment,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    resetCookieManagementModal: () => dispatch(createIsManageModalOpenAction(false)),
    updateSiteConsent: (consent: WCPconsent) => dispatch(createSetWCPConsentAction(consent)),
    updateIsConsentRequired: (consent: boolean) => dispatch(createSetIsConsentRequiredAction(consent)),
  };
};

export const CookieBanner = connect(mapStateToProps, mapDispatchToProps)(CookieBannerComponent);
