import { IState } from '@src/State';
import { BaseTile as BaseTileComponent, IBaseTileProps } from '@shared/components/baseTile';

import { connect } from 'react-redux';

const mapStateToProps = (state: IState, ownProps: IBaseTileProps): IBaseTileProps => {
  const { activeFilters: appsActiveFilters, count: appsCount } = state.apps;
  const { activeFilters: servicesActiveFilters, count: servicesCount } = state.services;
  const { currentView, query, requestId: searchReqId, isEmbedded } = state.config;
  return {
    ...ownProps,
    isLoadingUserData: state.users.loading,
    activeFilters: currentView === 'appGallery' ? appsActiveFilters : servicesActiveFilters,
    count: currentView === 'appGallery' ? appsCount : servicesCount,
    searchReqId,
    searchSortingBy: query.sortBy,
    isEmbedded,
  };
};

export const BaseTile = connect(mapStateToProps, null)(BaseTileComponent);
