import React, { ReactNode } from 'react';
import { Dialog, DialogType, DialogFooter, PrimaryButton, Stack, FontIcon, mergeStyleSets } from '@fluentui/react';
import type { IDialogContentStyles, IDialogContentProps, IModalProps, IStackTokens, IDialogStyles } from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import type { ICSSPixelUnitRule } from '@fluentui/merge-styles';

import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';

interface ISubmittedModalProps {
  hidden: boolean;
  onDismiss: () => void;
  title: string;
  children: ReactNode;
  context: ILocContext & ILocParamsContext;
}

const closeButtonText = 'Done';
const iconName = 'Completed';
const modalMinWidth: ICSSRule | ICSSPixelUnitRule = '560px';
const modalProps: IModalProps = { isDarkOverlay: false };
const stackTokens: IStackTokens = { childrenGap: 8 };
const classNames = mergeStyleSets({
  icon: [
    {
      fontSize: 16,
      height: 16,
      width: 16,
      color: '#107C10',
      marginTop: 2,
    },
  ],
});

const dialogStyles: Partial<IDialogStyles> = {
  main: {
    minHeight: 100,
  },
};

const dialogContentStyles: Partial<IDialogContentStyles> = {
  header: { color: NeutralColors.gray160 },
};

const dialogContentProps = (context: ILocContext & ILocParamsContext): IDialogContentProps => {
  return {
    type: DialogType.close,
    closeButtonAriaLabel: context.loc('SubmittedModal_CloseButton_AriaText', 'Close'),
    styles: dialogContentStyles,
  };
};

export const SubmittedModal: React.FunctionComponent<ISubmittedModalProps> = ({
  hidden,
  onDismiss,
  title,
  children,
  context,
}: ISubmittedModalProps) => {
  return (
    <Dialog
      hidden={hidden}
      dialogContentProps={{ ...dialogContentProps(context), title }}
      minWidth={modalMinWidth}
      modalProps={modalProps}
      styles={dialogStyles}
      onDismiss={onDismiss}
    >
      <Stack horizontal tokens={stackTokens}>
        <FontIcon iconName={iconName} className={classNames.icon} />
        <Stack.Item>{children}</Stack.Item>
      </Stack>
      <DialogFooter>
        <PrimaryButton text={context.loc('SubmittedModal_CloseButton_Text', closeButtonText)} onClick={onDismiss} />
      </DialogFooter>
    </Dialog>
  );
};
