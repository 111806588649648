import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FontSizes, FontWeights, mergeStyleSets, PrimaryButton, ScreenWidthMinXLarge, Stack, Text } from '@fluentui/react';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { Constants } from '@shared/utils/constants';
import { logger } from '@src/logger';
import { ITelemetryData } from '@shared/Models';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
/* eslint-disable import/named */
import { UserType } from '@microsoft-commerce/purchase-blends-component-library/dist/lib/providers/app-dependencies/user-info';
import { IState } from '@src/State';

interface IRedirectModalProps {
  onClick: () => void;
  context: ILocContext & ILocParamsContext;
  userType: UserType;
}

const contentStyles = mergeStyleSets({
  title: { fontSize: FontSizes.size20, fontWeight: FontWeights.semibold },
  container: {
    width: '560px',
    height: '216px',
    zIndex: '101',
    backgroundColor: '#FFFFFF',
    position: 'absolute',
    margin: 'auto',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    padding: '24px',
    [`@media (max-width: ${ScreenWidthMinXLarge}px)`]: {
      width: '90%',
    },
  },
});
const logClickEvent = ({ userType, checkoutId }: { userType: UserType; checkoutId: string }) => {
  const payload: ITelemetryData = {
    page: location.pathname,
    action: Constants.Telemetry.Action.Checkout,
    actionModifier: Constants.Telemetry.ActionModifier.RedirectButton,
    details: JSON.stringify({
      userType,
      checkoutId,
    }),
  };
  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
  logger.info(payload.details, {
    action: payload.action,
    actionModifier: payload.actionModifier,
    appName: payload.appName,
  });
};

export const RedirectModal: React.FunctionComponent<IRedirectModalProps> = ({
  onClick,
  context,
  userType,
}: IRedirectModalProps) => {
  const checkoutId = useSelector(({ checkout }: IState) => checkout.checkoutId);
  useEffect(() => {
    const payload: ITelemetryData = {
      page: location.pathname,
      action: Constants.Telemetry.Action.Checkout,
      actionModifier: Constants.Telemetry.ActionModifier.RedirectModal,
      details: JSON.stringify({
        userType,
        checkoutId,
      }),
    };
    SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
      page: payload.page,
    });
  }, []);

  return (
    <Stack className={contentStyles.container} verticalAlign="space-between">
      <Stack.Item align="start">
        <Text className={contentStyles.title}>{context.loc('Checkout_Error_Title')}</Text>
      </Stack.Item>
      <Stack.Item align="start">
        <Text>{context.loc('Checkout_Error_Description')}</Text>
      </Stack.Item>
      <Stack.Item align="end">
        <PrimaryButton
          data-bi-id={Constants.JsllCheckout.RedirectButton}
          onClick={() => {
            onClick();
            logClickEvent(userType);
          }}
        >
          {context.loc('Checkout_Error_Button')}
        </PrimaryButton>
      </Stack.Item>
    </Stack>
  );
};
