import React from 'react';
import * as PropTypes from 'prop-types';

import { Tab, Tabs, TabsTheme } from '@shared/components/tabs';
import { SuccessStory } from '@shared/components/successStory';
import { urlReplace, routes } from '@shared/routerHistory';
import { IBuildHrefContext, ICommonContext, ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { TestimonialId, testimonials } from './Testimonials.data';
import { OwnerType } from '@shared/utils/detailUtils';

interface TestimonialsProps {
  defaultTestimonialTabName: TestimonialId;
}

const biArea = 'homePage';

export const Testimonials: React.FunctionComponent<TestimonialsProps> = (
  { defaultTestimonialTabName }: TestimonialsProps,
  context: IBuildHrefContext & ICommonContext & ILocContext & ILocParamsContext
) => {
  const getSuccessStoryTabHref = (tabId: string) => {
    const params: { [key: string]: string } = {
      filterTab: null,
      successStoryTab: tabId,
      industry: null,
    };

    return context?.buildHref(routes.home, null, params);
  };

  const changeSuccessStoryTabCallback = (tabId: string) => {
    const newPath = getSuccessStoryTabHref(tabId) || '';

    urlReplace(newPath);
  };

  return (
    <Tabs
      defaultTab={defaultTestimonialTabName}
      ownerType={OwnerType.App}
      theme={TabsTheme.gray}
      ownerId={'homeSuccessStory'}
      centerize
      changeTabCallback={(tabId: string) => {
        changeSuccessStoryTabCallback(tabId);
      }}
      getTabHref={(tabId: any) => getSuccessStoryTabHref(tabId)}
    >
      {testimonials.map((story) => {
        const { title, id, author, description, descriptionFallback, image, recommendations } = story;

        return (
          <Tab
            title={context.loc(title, title)}
            key={id}
            name={id}
            otherProps={{
              'data-bi-id': id,
              'data-bi-type': 'successStory',
              'data-bi-area': biArea,
            }}
          >
            <SuccessStory
              author={author}
              description={description}
              descriptionFallback={descriptionFallback}
              id={id}
              image={image}
              recommendations={recommendations}
              title={title}
            />
          </Tab>
        );
      })}
    </Tabs>
  );
};

Testimonials.contextTypes = {
  buildHref: PropTypes.func,
  loc: PropTypes.func,
};
