import { get, IHttpOption, AuthenticationTypes } from '@shared/services/http/httpProtocol';
import { IAppReview } from '@shared/Models';
import { logError } from '@shared/utils/httpClientUtil';

const commonHttpOptions: IHttpOption = {
  requireCorrelationId: true,
  stringifyPostData: false,
  authenticationType: AuthenticationTypes.Unauthenticated,
  setBearerHeader: false,
};

export const AppReviewsQueryStrings = {
  OfferId: 'offer_Id',
  ApiVersion: 'api-version',
  Source: 'source',
  FilterEmptyTitles: 'filter_empty_titles',
};

/*
 * Gets reviews for a given offer Id
 * @param Object containing named parameters for the call
 * @returns Promise with the response, containing the reviews for the offer
 */
export async function getAppReviews({
  entityId,
  reviewsAPI,
  reviewsAPIVersion,
}: {
  entityId: string;
  reviewsAPI: string;
  reviewsAPIVersion: string;
}): Promise<IAppReview[]> {
  try {
    if (!entityId) {
      logError('reviewAppReviewsRestClient.getAppReviews - AppId is empty');
      return Promise.reject(new Error('AppId is required'));
    }

    return await get(`${reviewsAPI}`, commonHttpOptions)
      .addQueryEntry(AppReviewsQueryStrings.ApiVersion, reviewsAPIVersion)
      .addQueryEntry(AppReviewsQueryStrings.OfferId, entityId)
      .addQueryEntry(AppReviewsQueryStrings.FilterEmptyTitles, 'true')
      .request();
  } catch (error) {
    logError('reviewAppReviewsRestClient.getAppReviews', error);
    return Promise.reject(error);
  }
}
