import { Constants } from './constants';

/**
 * get trimmed words speparated by delimiter from string
 *
 * @param text input text
 * @param delimiter text delimiter for text split
 */
export function getTrimmedWords(text: string, delimiter: string = ' '): string[] {
  if (!text) {
    return [];
  }

  return (
    text
      // split
      .split(delimiter)
      // trim words
      .map((word: string) => getTrimmedString(word))
      // filter out empty words
      .filter((word: string) => !!word)
  );
}

/**
 * check whether the string is empty, or consists of spaces only, or 'null', or 'undefined', or null, or undefined, case insensitive
 *
 * @param text input text
 * @returns true if input string is null, undefined, '', '   ', 'null', 'undefined'
 */
export function isEmptyNullorUndefinedString(text: string): boolean {
  if (!text) {
    return true;
  }

  const lowerCaseText: string = text.toLowerCase().trim();

  return lowerCaseText === Constants.TypeOf.undefinedName || lowerCaseText === Constants.TypeOf.nullName || lowerCaseText === '';
}

/**
 * process and return the string with word break enabled
 * @param text raw text input
 */
export function getWorkBreakEnabledString(text: string): string {
  if (text) {
    return text.replace(/\u00a0|&nbsp;|\s/g, ' ');
  }
  return '';
}

/**
 * get trimmed string
 * @param text input text
 */
export function getTrimmedString(text: string): string {
  if (text) {
    return text.trim();
  }
  return '';
}

export function getCapitalizedString(text: string): string {
  const trimmedText = getTrimmedString(text);

  const capitalizedString = trimmedText.charAt(0).toUpperCase() + trimmedText.slice(1);

  return capitalizedString;
}

export function getStringWithoutSpecialCharacters(text: string, regexToFilterOutSpecialCharacters: RegExp): string {
  const trimmedText = getTrimmedString(text);

  const outputText = trimmedText.split(regexToFilterOutSpecialCharacters).join('');

  return outputText;
}
