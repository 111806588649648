import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import SpzaComponent from './../spzaComponent';
import { IMediaModalPayload, MediaType, IMedia } from '../../Models';
import { convertTimeToSeconds } from '../../utils/appUtils';
let url = require('url');

export interface IMediaModal {
  payload: IMediaModalPayload;
  dismissModal: () => void;
}

interface IParsedUrl {
  hostname: string;
  pathname: string;
  query: any;
}

interface VideoSupplierConfig {
  host: string;
  query: string;
  pauseCommand: Object;
}

enum VideoSupplier {
  YouTube = 'YouTube',
  Vimeo = 'Vimeo',
}

interface ParsedVideo {
  supplier: VideoSupplier;
  sourceUrl: string;
}

interface VideoRef {
  element: HTMLIFrameElement;
  supplier: VideoSupplier;
}

type VideoSupliersConfigs = { [videoSupplier in VideoSupplier]: VideoSupplierConfig };

export default class MediaModal extends SpzaComponent<IMediaModal, any> {
  videoSuppliers: VideoSupliersConfigs = {
    [VideoSupplier.YouTube]: {
      host: 'https://www.youtube.com/embed/',
      query: 'showinfo=0&rel=0&modestbranding=1&cc_load_policy=1&enablejsapi=1',
      pauseCommand: {
        event: 'command',
        func: 'pauseVideo',
      },
    },
    [VideoSupplier.Vimeo]: {
      host: 'https://player.vimeo.com/video/',
      query: 'api=1',
      pauseCommand: {
        method: 'pause',
        value: 'true',
      },
    },
  };
  videosRefs: VideoRef[] = [];

  getQueryString(query: any, exclude?: string[]): string {
    let result = '';
    const keys = query ? Object.keys(query) : [];
    const keysLength = keys.length;

    for (let i = 0; i < keysLength; i++) {
      if (exclude && exclude.indexOf(keys[i]) < 0) {
        if (keys[i] && keys[i].toLowerCase() === 't') {
          // When using youtube embed, the parameter "t" should be replaced with "start", and the value should be
          // numeric only (according to youtube api - https://developers.google.com/youtube/player_parameters)
          const startTimeInSeconds: string = isNaN(query[keys[i]])
            ? convertTimeToSeconds(query[keys[i]]).toString()
            : query[keys[i]];
          result += '&' + 'start' + '=' + startTimeInSeconds;
        } else {
          result += '&' + keys[i] + '=' + query[keys[i]];
        }
      }
    }

    return result;
  }

  parseUrl(videoUrl: string): IParsedUrl {
    let parsedUrl = url.parse(videoUrl, true);

    let hostName = '';
    let pathName = '';

    if (parsedUrl) {
      hostName = parsedUrl.hostname ? parsedUrl.hostname.toLowerCase() : hostName;
      // If the pathname doesn't start with "/", add "/" since in IE the pathname doesn't start with "/" which breaks the video url generating logic.
      pathName =
        (parsedUrl.pathname && parsedUrl.pathname.length > 0 && parsedUrl.pathname[0] !== '/' ? '/' : '') + parsedUrl.pathname;
    }

    return {
      hostname: hostName,
      pathname: pathName,
      query: parsedUrl ? parsedUrl.query : '',
    };
  }

  generateYouTubeVideoSource(parsedUrl: IParsedUrl, videoUrl: string): string {
    const youtubeConfigs = this.videoSuppliers[VideoSupplier.YouTube];

    let sourceUrl = '';

    if (
      (parsedUrl.hostname === 'www.youtube.com' || parsedUrl.hostname === 'youtube.com') &&
      parsedUrl.query !== null &&
      parsedUrl.query.v
    ) {
      sourceUrl = `${youtubeConfigs.host}${parsedUrl.query.v}?${this.getQueryString(parsedUrl.query, ['v'])}`;
    } else if (parsedUrl.hostname === 'youtu.be') {
      let pathValues = parsedUrl.pathname.split('/');
      if (pathValues.length > 1) {
        sourceUrl = `${youtubeConfigs.host}${pathValues[1]}?${this.getQueryString(parsedUrl.query)}`;
      }
    } else if (
      (parsedUrl.hostname === 'www.youtube.com' || parsedUrl.hostname === 'youtube.com') &&
      parsedUrl.pathname.indexOf('embed') >= 0
    ) {
      sourceUrl = videoUrl;

      if (sourceUrl.indexOf('?') < 0) {
        sourceUrl = sourceUrl + '?';
      }
    }

    return `${sourceUrl}&${youtubeConfigs.query}`;
  }

  generateVimeoVideoSource(parsedUrl: IParsedUrl, videoUrl: string): string {
    const vimeoConfigs = this.videoSuppliers[VideoSupplier.Vimeo];

    const vimeoParsingRegex = /vimeo\.com\/(video\/)?(external\/)?(\d+)/;

    const matches = videoUrl.match(vimeoParsingRegex);

    const vimeoId = (matches && matches.length && matches[matches.length - 1]) || 'vimeoId';

    return `${vimeoConfigs.host}${vimeoId}?${this.getQueryString(parsedUrl.query)}&${vimeoConfigs.query}`;
  }

  parseVideo(item: IMedia): ParsedVideo {
    const parsedUrl: IParsedUrl = this.parseUrl(item.link);
    let supplier: VideoSupplier;
    let sourceUrl = '';

    if (parsedUrl.hostname.indexOf('youtube') >= 0 || parsedUrl.hostname.indexOf('youtu.be') >= 0) {
      supplier = VideoSupplier.YouTube;
      sourceUrl = this.generateYouTubeVideoSource(parsedUrl, item.link);
    } else if (parsedUrl.hostname.indexOf('vimeo') >= 0) {
      supplier = VideoSupplier.Vimeo;
      sourceUrl = this.generateVimeoVideoSource(parsedUrl, item.link);
    }

    if (parsedUrl.hostname === 'aka.ms') {
      sourceUrl = item.link;
    }

    return {
      sourceUrl,
      supplier,
    };
  }

  pauseVideoRefs(): void {
    if (this.videosRefs && this.videosRefs.length) {
      for (const videoRef of this.videosRefs) {
        if (
          videoRef.supplier &&
          this.videoSuppliers[videoRef.supplier] &&
          this.videoSuppliers[videoRef.supplier].pauseCommand &&
          videoRef.element &&
          videoRef.element.contentWindow &&
          videoRef.element.contentWindow.postMessage
        ) {
          videoRef.element.contentWindow.postMessage(JSON.stringify(this.videoSuppliers[videoRef.supplier].pauseCommand), '*');
        }
      }
    }
  }

  renderImpl() {
    this.videosRefs = [];

    return (
      <div role="dialog" aria-label="media modal is now open" className="mediaModal">
        <div className="dialogContent">
          <div className="toolBar">
            <button
              className="cancel"
              aria-label="close"
              onClick={this.props.dismissModal}
              data-bi-id="cancelButton"
              data-bi-name={
                this.props.payload.media &&
                this.props.payload.media[this.props.payload.defaultIndex || 0] &&
                this.props.payload.media[this.props.payload.defaultIndex || 0].type
              }
              autoFocus={true}
            >
              <span className="c-glyph"></span>
            </button>
          </div>
          <div className="mediaContainer">
            <Carousel
              showThumbs={false}
              autoPlay={false}
              showStatus={false}
              useKeyboardArrows={true}
              infiniteLoop={false}
              selectedItem={this.props.payload.defaultIndex || 0}
              onChange={() => this.pauseVideoRefs()}
            >
              {this.props.payload.media.map((item) => {
                if (item.type === MediaType.Image) {
                  return <img src={item.link} />;
                } else if (item.type === MediaType.Video) {
                  const parsedVideo = this.parseVideo(item);

                  return (
                    <iframe
                      ref={(element) =>
                        this.videosRefs.push({
                          element,
                          supplier: parsedVideo.supplier,
                        })
                      }
                      className="embedded-video"
                      title={item.name || 'Video'}
                      width="100%"
                      height="100%"
                      src={parsedVideo.sourceUrl}
                      frameBorder={0}
                    ></iframe>
                  );
                } else {
                  return <></>;
                }
              })}
            </Carousel>
          </div>
        </div>
      </div>
    );
  }
}

(MediaModal as any).contextTypes = {
  loc: PropTypes.func,
  renderErrorModal: PropTypes.func,
};
