import React from 'react';
import { IDataItem } from '@shared/Models';
import Ribbon from '@shared/components/ribbon';
import { AppTile } from '@shared/containers/appTile';
import { ServiceTile } from '@shared/containers/serviceTile';
import CommonTile from '@shared/components/commonTile';
import NewBadge from '@shared/images/NewBadge.svg';

const maxRibbonItems = 5;

export interface IRecommendationsRibbonProps {
  recommendedItems: IDataItem[];
  title: string;
  ribbonKey: string;
  selectedLocale: string;
  selectedCountry: string;
  tileType: typeof AppTile | typeof ServiceTile | typeof CommonTile;
}

export const RecommendationsRibbon: React.FunctionComponent<IRecommendationsRibbonProps> = ({
  recommendedItems = [],
  title,
  ribbonKey,
  selectedCountry,
  selectedLocale,
  tileType: TileType,
}: IRecommendationsRibbonProps) => {
  return (
    <>
      {recommendedItems.length >= maxRibbonItems && (
        <Ribbon title={title} imgSource={NewBadge}>
          {recommendedItems.map((item: IDataItem, index: number) => {
            return (
              <TileType
                {...item}
                item={item}
                key={item.entityId}
                customCSSClass={'tile' + index}
                tileIndex={index}
                totalTiles={recommendedItems.length}
                selectedLocale={selectedLocale}
                countryContext={selectedCountry}
                ribbonKey={`Recommended_${ribbonKey}`}
              />
            );
          })}
        </Ribbon>
      )}
    </>
  );
};
