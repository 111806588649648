import * as React from 'react';
import * as PropTypes from 'prop-types';
import SpzaComponent from './spzaComponent';
import * as DetailUtils from '@shared/utils/detailUtils';
import { Constants } from '@shared/utils/constants';
import { mergeStyles } from '@fluentui/react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

export interface ITabProps {
  title: string;
  name: string;
  otherProps?: any;
  textBadge?: string;
}

export const TAB_SELECTED = 'tabSelected';
export const DEFAULT_TAB = 'defaultTab';
export const SELECTED_TAB_QUERY = `.${DEFAULT_TAB}.${TAB_SELECTED}`;

export class Tab extends SpzaComponent<ITabProps, any> {
  renderImpl() {
    return this.props.children;
  }
}

export interface ITabsProps {
  defaultTab: string;
  ownerType: DetailUtils.OwnerType;
  ownerId: string;
  changeTabCallback: (tabTitle: string) => void;
  getTabHref: (tabTitle: string) => string;
  theme?: TabsTheme;
  centerize?: boolean;
}

const tabsClass = mergeStyles({ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' });
export enum TabsTheme {
  'gray',
}
export class Tabs extends SpzaComponent<ITabsProps, any> {
  changeTabWithName(e: any, name: string) {
    e.preventDefault();

    const detailsObject = {
      tab: name,
    };
    DetailUtils.generateClickPayloadAndLogTelemetry(
      this.props.ownerType,
      this.props.ownerId,
      Constants.Telemetry.ActionModifier.Tab,
      detailsObject
    );

    this.props.changeTabCallback(name);
  }

  getCurrentTabIndex() {
    const tabsList = this.getNonNullChildren();
    const length = tabsList.length;

    for (let index = 0; index < length; index++) {
      const tab = tabsList[`${index}`].valueOf() as Tab;

      if (tab.props.name === this.props.defaultTab) {
        return index;
      }
    }

    return 0;
  }

  getHeaderElement(currentTabIndex: number) {
    const { getTabHref } = this.props;
    return function (child: Tab, index: number) {
      const classes = classNames({
        [TAB_SELECTED]: currentTabIndex === index,
        [DEFAULT_TAB]: true,
      });

      if (child?.props?.children) {
        const otherProps = child.props.otherProps || {};
        const { name, title, textBadge } = child.props;
        return (
          <Link
            to={getTabHref(name)}
            className={classes}
            key={'tab' + index}
            role="tab"
            aria-selected={currentTabIndex === index}
            onClick={(e: any) => {
              this.changeTabWithName(e, name);
            }}
            {...otherProps}
          >
            {textBadge ? (
              <label>
                {title} <span className="tabTextBadge">{textBadge}</span>
              </label>
            ) : (
              <label>{title}</label>
            )}
          </Link>
        );
      } else {
        return null;
      }
    };
  }

  getNonNullChildren(): React.ReactNode[] {
    return React.Children.toArray(this.props.children);
  }

  // Returns the number of non-null children.
  getNonNullChildrenCount(): number {
    return this.getNonNullChildren().length;
  }

  shouldRenderHeader(): boolean {
    return this.getNonNullChildrenCount() > 1;
  }

  renderHeaders(currentTabIndex: number) {
    // we do not render a header if there's only one tab
    if (!this.shouldRenderHeader()) {
      return null;
    } else {
      const createHeaderElement = this.getHeaderElement(currentTabIndex);
      const tabClass = classNames({
        [tabsClass]: this.props.centerize,
      });
      return <div className={tabClass}>{this.getNonNullChildren().map(createHeaderElement.bind(this))}</div>;
    }
  }

  renderImpl() {
    const currentTabIndex = this.getCurrentTabIndex();
    const tabContainerClass = 'tabContainer ' + (this.props.theme === TabsTheme.gray ? 'gray' : '');
    return (
      <>
        <div className={tabContainerClass} role="tablist" aria-busy="true">
          {this.renderHeaders(currentTabIndex)}
        </div>
        <div className="tabContent">{this.getNonNullChildren()[`${currentTabIndex}`]}</div>
      </>
    );
  }
}

(Tab as any).contextTypes = {
  renderErrorModal: PropTypes.func,
};

(Tabs as any).contextTypes = {
  renderErrorModal: PropTypes.func,
};
