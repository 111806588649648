import { Constants } from './constants';

// NOTE:
//  DO NOT access directly to, or from browser, for following items
//      1) cookie data, or
//      2) local storage data
//
//  instead, please use methods in browserStorageUtils util to access
//  e.g.
//      get cookie data
//          1) BrowserStorage.getBrowserStorage().getCookieItem(...)
//          2) getCookieItem(...)
//      set cookie data
//          1) BrowserStorage.getBrowserStorage().setCookieItem(...)
//          2) saveCookieItem(...)
//      get local storage data
//          1) BrowserStorage.getBrowserStorage().getLocalStorageItem(...)
//          2) getLocalStorageItem(...)
//      set local storage data
//          1) BrowserStorage.getBrowserStorage().setLocalStorageItem(...)
//          2) saveLocalStorageItem(...)
//

/**
 * get cookie expires option
 *
 * @param expiry: cookie expiry
 *
 * @returns expiration date which is 13 months afterwards in milli-seconds.
 */
export function getCookieOptionExpires(expiry: number = Constants.CookieExpiry.Default): string {
  const date: Date = new Date();

  date.setTime(date.getTime() + expiry);

  const expiresOptionText = `expires=${date.toUTCString()}`;

  return expiresOptionText;
}
