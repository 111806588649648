/* eslint-disable react/prop-types */
import * as React from 'react';
import { TelemetryImage } from './telemetryImage';

interface CardProps {
  title: string;
  subtitle: string;
  logoURL: string;
}

export const Card: React.FC<React.PropsWithChildren<CardProps>> = ({ logoURL, title, subtitle, children }) => {
  return (
    <div className="card ms-depth-8">
      {logoURL && (
        <div className="iconHost ms-depth-8">
          <TelemetryImage src={logoURL} className="appLargeIcon" itemPropName="image" title={title} />
        </div>
      )}
      <div className="header">
        <div className="titleBlock">
          <h1 className="ms-fontSize-28 ms-fontWeight-semibold title">{title}</h1>
          <h2 className="ms-fontSize-14 subTitle">{subtitle}</h2>
          <div className="mainContent">{children}</div>
        </div>
      </div>
    </div>
  );
};
