import { getAppConfig } from '@shared/services/init/appConfig';
import AuthClient from './client';
import { getExtraScopesToConsent } from './scopes';
import { getImplicitRedirectUri } from './config';
import { getWindow } from '@shared/services/window';
import { logger } from '@src/logger';
import { stringifyError } from '@shared/utils/errorUtils';
import { Constants } from '@shared/utils/constants';
import { saveLocalStorageItem } from '@shared/utils/browserStorageUtils';

const getRedirectStartPage = ({ to }: { to: string }) => {
  const redirectStartPage = new URL(to);
  redirectStartPage.searchParams.set(Constants.QueryStrings.Referer, getWindow().location.href);
  redirectStartPage.searchParams.set(Constants.QueryStrings.CorrelationId, getAppConfig('correlationId'));
  return redirectStartPage.href;
};

export const loginRedirectUser = async ({ to, email }: { to: string; email: string }) => {
  try {
    saveLocalStorageItem(Constants.LocalStorage.LoginHint, '');
    const authClient = AuthClient.getInstance();
    await authClient.clearCache();
    await authClient.loginRedirect({
      scopes: [],
      extraScopesToConsent: getExtraScopesToConsent(),
      loginHint: email,
      redirectUri: getImplicitRedirectUri(),
      redirectStartPage: getRedirectStartPage({ to }),
      correlationId: getAppConfig('correlationId'),
    });
  } catch (error) {
    logger.error(stringifyError(error), {
      action: Constants.Telemetry.Action.Login,
      actionModifier: Constants.Telemetry.ActionModifier.PreAuthRedirectFailed,
      error,
    });
  }
};
