import { IURLQuery } from 'Models';
import { Constants } from '@shared/utils/constants';
import { getEntityRegistration } from '@shared/utils/entityRegistration';
import { IDataValues, IProductValue } from '@shared/utils/dataMapping';
import { IBuildHrefContext } from '@shared/interfaces/context';
import { removeUnmatchedCategories } from '@shared/utils/datamappingHelpers';

export function getFilterLink(
  context: IBuildHrefContext,
  entityType: Constants.EntityType,
  filter: IDataValues,
  parent: IDataValues
): string {
  const queryParams: IURLQuery = {
    page: '1',
  };

  const isProduct = filter.UrlGroup === Constants.FilterQuery.product;
  const isCategory = filter.UrlGroup === Constants.FilterQuery.category;
  const isIndustry = filter.UrlGroup === Constants.FilterQuery.industry;

  const query: string = filter.UrlGroup || filter.FilterGroup;

  let filterValue = filter.UrlKey;
  if (filter.IsChildFilter) {
    let prefix: string;
    if (parent && !parent.isActive) {
      prefix = '';
    } else if (filter.isActive) {
      prefix = '!';
    } else {
      prefix = ';';
    }

    filterValue = prefix + filterValue;
  } else {
    if (filter.isActive) {
      filterValue = null;
    }
  }

  queryParams[`${query}`] = filterValue;

  if (filter.IsChildFilter && parent?.SubCategoryDataMapping) {
    if (parent.UrlGroup && parent.UrlKey) {
      queryParams[parent.UrlGroup] = parent.UrlKey;
    }
  } else {
    if (isCategory) {
      queryParams[Constants.FilterQuery.subcategories] = null;
    }
    if (isIndustry) {
      queryParams[Constants.FilterQuery.subindustries] = null;
    }
  }

  const route = getEntityRegistration(entityType).route;

  if (isProduct && !filter.IsChildFilter) {
    const urlKey = filter.isActive ? null : filter.UrlKey;
    const productMask: IProductValue = filter.isActive ? null : { [filter.FilterGroup]: filter.FilterID };

    return context.buildHref(route, null, removeUnmatchedCategories(queryParams, urlKey, productMask));
  }

  return context.buildHref(route, null, queryParams);
}
