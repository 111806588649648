import * as httpProtocol from './httpProtocol';
import { logError } from '@shared/utils/httpClientUtil';
import { IUserReview, IAppReview } from '@shared/Models';
import { getAppConfig } from '@shared/services/init/appConfig';
import { Constants } from '@shared/utils/constants';

const option: httpProtocol.IHttpOption = {
  requireCorrelationId: true,
  stringifyPostData: false,
  authenticationType: httpProtocol.AuthenticationTypes.Spza,
};

export function post(authMethod: string, authKey: string, review: IUserReview) {
  const endpoint = '/api/userreview';

  if (authMethod === 'accessKey') {
    option.authenticationType = httpProtocol.AuthenticationTypes.Unauthenticated;
  } else {
    option.authenticationType = httpProtocol.AuthenticationTypes.Spza;
  }

  const post = httpProtocol.post(endpoint, option);
  if (option.authenticationType === httpProtocol.AuthenticationTypes.Unauthenticated) {
    post.setHeader(authMethod, authKey);
  }

  return post
    .setData(review)
    .request()
    .catch((error: any) => {
      logError('reviewRestClient.post', error);
      return Promise.reject(error);
    });
}

export function get(authMethod: string, authKey: string, appId: string): Promise<IUserReview[]> {
  if (!appId || appId === '*') {
    appId = '';
  }

  const endpoint = '/api/userreview/' + appId;

  if (authMethod === 'accessKey') {
    option.authenticationType = httpProtocol.AuthenticationTypes.Unauthenticated;
  } else {
    option.authenticationType = httpProtocol.AuthenticationTypes.Spza;
  }

  const get = httpProtocol.get(endpoint, option);
  if (option.authenticationType === httpProtocol.AuthenticationTypes.Unauthenticated) {
    get.setHeader(authMethod, authKey);
  }
  return get.request().catch((error: any) => {
    logError('reviewRestClient.get', error);
    return Promise.reject(error);
  });
}

export function del(authMethod: string, authKey: string, appId: string, review: IUserReview): Promise<any> {
  const endpoint = '/api/userreview/' + appId;

  if (!appId) {
    logError('reviewRestClient.del', 'missing appId');
    return null;
  }

  if (authMethod === 'accessKey') {
    option.authenticationType = httpProtocol.AuthenticationTypes.Unauthenticated;
  } else {
    option.authenticationType = httpProtocol.AuthenticationTypes.Spza;
  }

  const del = httpProtocol.del(endpoint, option);
  del.option.parseResult = false;
  if (option.authenticationType === httpProtocol.AuthenticationTypes.Unauthenticated) {
    del.setHeader(authMethod, authKey);
  }

  return del
    .setData(review)
    .request()
    .catch((error: any) => {
      logError('reviewRestClient.del', error);
      return Promise.reject(error);
    });
}

export function getPurchaseStatus(appId: string): Promise<boolean> {
  if (!appId) {
    return Promise.reject(new Error('AppId is required. Operation: reviewRestClient.getPurchaseStatus'));
  }

  const endpoint = '/view/app/' + appId + '/purchase';
  option.authenticationType = httpProtocol.AuthenticationTypes.Spza;

  const get = httpProtocol.get(endpoint, option);
  get.addQueryEntry(Constants.QueryStrings.ApplicationId, appId);

  return get
    .addQueryEntry(Constants.QueryStrings.version, getAppConfig(Constants.apiVersion))
    .request()
    .catch((error: any) => {
      logError('reviewRestClient.getPurchaseStatus', error);
      return Promise.resolve(false);
    });
}
