import { ILinksList, IURLQuery } from '@shared/Models';
import { Gallery } from '@shared/components/galleryPage';
import { getFilteredServiceData } from '@shared/actions/thunkActions';
import { createSearchboxInputChangedAction, createUpdateCurrentGalleryViewIsCurated } from '@shared/actions/actions';
import { getEntityRegistrationByView } from '@shared/utils/entityRegistration';
import { getInternetOfThingsLink } from '@shared/utils/filterHelpers';
import { extractCountryCodeFromQueryParam } from '@shared/utils/consultingServicesUtils';
import { Constants } from '@shared/utils/constants';
import { IState, IAppDataState } from '@src/State';
import { withRouter } from '@shared/routerHistory';
import { connect } from 'react-redux';
import { isOneTimePaymentOffer } from '@shared/utils/onetimepaymentoffers';
import { isMaccUser } from '@shared/utils/userUtils';

function tryParseInt(val: string) {
  let parsedVal = parseInt(val, 10);

  if (isNaN(parsedVal)) {
    parsedVal = null;
  }

  return parsedVal;
}

export const mapStateToProps = (state: IState, ownProps: any) => {
  const {
    config: {
      requestFilteredQuery,
      query: queryProp,
      featureFlags,
      locale,
      isEmbedded,
      isMobile,
      isAppSource,
      embedHost,
      requestFilteredLoading,
      currentView,
      billingCountryCode,
    },
    apps,
    services,
    cloudsIndustry,
  } = state;

  const pageSize = tryParseInt(ownProps.location.query.pageSize);
  const galleryPage = tryParseInt(ownProps.location.query.page);
  const showPrivateApps = ownProps.location.query.showPrivateApps?.toLowerCase() === 'true';
  const linksList: ILinksList[] = getInternetOfThingsLink(ownProps.params?.category);
  const query: IURLQuery = { ...requestFilteredQuery, ...ownProps.location.query };
  const subsetSearchQuery: string = requestFilteredQuery[Constants.TileDataFilteredQueryParams.search];
  const countryCode = extractCountryCodeFromQueryParam(queryProp) || billingCountryCode || state.services.selectedCountry;
  const regionCode = query?.region ?? state.services.selectedRegion;

  const dataList = state.apps.dataList.filter(({ entityId }) => !isOneTimePaymentOffer(entityId));
  const entityType = getEntityRegistrationByView(currentView);
  const showRecommendations = state.config.featureFlags.recommendations;

  const { whatsNewApps, trendingApps } = state.apps.recommendedApps;
  const { whatsNewService, trendingService } = state.services.recommendedServices;

  return {
    galleryPage,
    pageSize,
    requestFilteredLoading,
    entityData: {
      [Constants.EntityType.App]: {
        allData: dataList,
        subsetData: state.apps.subsetData,
        dataMap: state.apps.dataMap,
        idMap: state.apps.idMap,
        subsetSearchQuery,
        curatedData: state.apps.curatedData,
        activeFilters: state.apps.activeFilters,
        count: state.apps.count,
        countriesList: {},
      },
      [Constants.EntityType.Service]: {
        allData: state.services.dataList,
        subsetData: state.services.subsetData,
        dataMap: state.services.dataMap,
        idMap: state.services.idMap,
        subsetSearchQuery,
        curatedData: state.services.curatedData,
        activeFilters: state.services.activeFilters,
        count: state.services.count,
        countriesList: state.services.countriesList,
      },
      [Constants.EntityType.CloudsIndustry]: {
        allData: state.cloudsIndustry.dataList,
        subsetData: state.cloudsIndustry.subsetData,
        dataMap: state.cloudsIndustry.dataMap,
        idMap: state.cloudsIndustry.idMap,
        subsetSearchQuery,
        curatedData: state.cloudsIndustry.curatedData,
        activeFilters: state.cloudsIndustry.activeFilters,
        count: state.cloudsIndustry.count,
        countriesList: state.services.countriesList,
      },
    },
    showPrivateApps,
    isEmbedded,
    partnerAppDataLoaded: (apps as IAppDataState).partnerAppDataLoaded,
    partnerAppDataLoadedError: (apps as IAppDataState).partnerAppDataLoadedError,
    embedHost,
    entityType,
    query,
    params: ownProps.params,
    countryCode,
    regionCode,
    isAppSource,
    isMobile,
    currentView,
    selectedLocale: locale,
    linksList,
    appsActiveFilters: apps.activeFilters,
    servicesActiveFilters: services.activeFilters,
    cloudsIndustryActiveFilters: cloudsIndustry.activeFilters,
    featureFlags,
    selectedCountry: state.services ? state.services.selectedCountry : null,
    recoMicrosoft365WhatsNewItems: showRecommendations ? apps.recommendedApps.microsoft365WhatsNewApps : [],
    recoMicrosoft365TrendingItems: showRecommendations ? apps.recommendedApps.microsoft365TrendingApps : [],
    recoWhatsNewItems:
      showRecommendations &&
      (entityType === Constants.EntityType.App
        ? whatsNewApps
        : entityType === Constants.EntityType.Service
        ? whatsNewService
        : []),
    recoTrendingItems:
      showRecommendations &&
      (entityType === Constants.EntityType.App
        ? trendingApps
        : entityType === Constants.EntityType.Service
        ? trendingService
        : []),

    isMaccUser: isMaccUser(state.users),
  };
};

export const mapDispatchToProps = (dispatch: any) => {
  return {
    stateCountryFilterHandler: () => {
      dispatch(getFilteredServiceData());
    },
    updateCurrentGalleryViewIsCurated: (isCuratedGalleryView: boolean) => {
      dispatch(
        createUpdateCurrentGalleryViewIsCurated({
          isCuratedGalleryView: isCuratedGalleryView,
        })
      );
    },
    changeSearchText: (searchvalue: string) => dispatch(createSearchboxInputChangedAction({ searchString: searchvalue })),
  };
};

const GalleryPageContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(Gallery));

export default GalleryPageContainer;
