import * as React from 'react';
import { DefaultButton, PrimaryButton, Stack } from '@fluentui/react';
import { Constants } from '@shared/utils/constants';
import { IBuildHrefContext, ILocContext } from '@shared/interfaces/context';
import { getResetFiltersAndSearchHref, getResetFiltersHref } from '@shared/utils/filterHelpers';
import { urlPush } from '@shared/routerHistory';
import { INoSearchResults, INoSearchResultsTelemetry, logTelemetry } from './noSearchResults';

export const getViewAllButtonText = (entityType: Constants.EntityType, context: ILocContext): string => {
  const viewTextMapping = {
    [Constants.EntityType.CloudsIndustry]: context.loc('NoSearchResults_ViewAllIndustryClouds', 'View all industry clouds'),
    [Constants.EntityType.Service]: context.loc('NoSearchResults_ViewAllServices', 'View all consulting services'),
  };

  return viewTextMapping[`${entityType}`] || context.loc('NoSearchResults_ViewAllApps', 'View all apps');
};

export const clearSearchAndFilters = (changeSearchText: (searchvalue: string) => void, viewAllAppsUrl: string): void => {
  changeSearchText('');
  urlPush(viewAllAppsUrl);
};

export const renderViewAllAppsButton = (
  context: ILocContext & IBuildHrefContext,
  entityType: Constants.EntityType,
  isPrimaryButton: boolean,
  currentPage: string,
  changeSearchText: (searchvalue: string) => void
): JSX.Element => {
  const viewAllAppsClassName = 'ViewAllAppsCTA';
  const viewAllAppsText = getViewAllButtonText(entityType, context);
  const viewAllAppsUrl = getResetFiltersAndSearchHref(context, entityType);
  const telemetryDetails: INoSearchResultsTelemetry = {
    message: 'View all apps button is pressed after the user got an empty search results page.',
    entityType,
  };
  if (isPrimaryButton) {
    return (
      <PrimaryButton
        className={viewAllAppsClassName}
        text={viewAllAppsText}
        onClick={() => {
          logTelemetry(currentPage, Constants.Telemetry.ActionModifier.ViewAllButton, telemetryDetails);
          clearSearchAndFilters(changeSearchText, viewAllAppsUrl);
        }}
        data-bi-id={Constants.JsllCTAId.ViewAllButton}
        data-bi-area="Search"
      />
    );
  }
  return (
    <DefaultButton
      className={viewAllAppsClassName}
      text={viewAllAppsText}
      onClick={() => {
        logTelemetry(currentPage, Constants.Telemetry.ActionModifier.ViewAllButton, telemetryDetails);
        clearSearchAndFilters(changeSearchText, viewAllAppsUrl);
      }}
      data-bi-id={Constants.JsllCTAId.ViewAllButton}
      data-bi-area="Search"
    />
  );
};

export const renderClearFiltersButton = (
  context: ILocContext & IBuildHrefContext,
  entityType: Constants.EntityType,
  currentPage: string
): JSX.Element => {
  const clearFiltersClassName = 'ClearFiltersCTA';
  const clearFiltersText = context.loc('NoSearchResults_ClearFilters', 'Clear filters');
  const resetFiltersUrl = getResetFiltersHref(context, entityType);
  const telemetryDetails: INoSearchResultsTelemetry = {
    message: 'Clear filters button is pressed after the user got an empty search results page.',
    entityType,
  };
  return (
    <PrimaryButton
      className={clearFiltersClassName}
      text={clearFiltersText}
      onClick={() => {
        logTelemetry(currentPage, Constants.Telemetry.ActionModifier.ClearFilters, telemetryDetails);
        urlPush(resetFiltersUrl);
      }}
      data-bi-id={Constants.JsllCTAId.ClearFilters}
      data-bi-area="Search"
    />
  );
};

export const NoSearchResultsButtons = ({
  context,
  isSearch = false,
  isFilter = false,
  entityType = Constants.EntityType.App,
  currentPage = '',
  changeSearchText,
}: INoSearchResults): JSX.Element => {
  if (isSearch && isFilter) {
    return (
      <Stack horizontal className="NoSearchResultsCTAStack" tokens={{ childrenGap: 's1' }}>
        <Stack.Item align="center">{renderClearFiltersButton(context, entityType, currentPage)}</Stack.Item>
        <Stack.Item align="center">
          {renderViewAllAppsButton(context, entityType, false, currentPage, changeSearchText)}
        </Stack.Item>
      </Stack>
    );
  } else if (isSearch) {
    return renderViewAllAppsButton(context, entityType, true, currentPage, changeSearchText);
  }

  return renderClearFiltersButton(context, entityType, currentPage);
};
