import type { Store } from 'redux';
import { windowOnErrorHandler } from '@shared/utils/appUtils';
import { renderErrorModal } from '@shared/utils/errorHandlerUtil';
import { handleInterruptedHydration } from './recoverableHydrationError';

export const onError = (window: Window, store: Store) => {
  const eventListener = (msg: string, file: string, line: number, col: number, error: unknown) => {
    if (handleInterruptedHydration(msg)) {
      return;
    }

    windowOnErrorHandler({ msg, file, line, col, error });

    if (file?.indexOf(window.location.origin) > -1) {
      renderErrorModal(store);
    }
  };

  window.onerror = eventListener;
};
