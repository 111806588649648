import { Constants } from '@shared/utils/constants';
import { EnrichGetReviewCommentsResponse } from 'interfaces/reviews/comments/serverModels';
import { get, post, patch, del, IHttpOption, AuthenticationTypes } from '@shared/services/http/httpProtocol';
import { mapEnrichReviewCommentToIAppReviewComment } from '@shared/utils/reviewsUtils';
import { IAppGetReviewCommentCollectionResponse, IReviewCommentOperationResponse } from '@shared/interfaces/reviews/comments';

interface BaseAppReviewCommentsRestArgs {
  offerId: string;
  reviewId: string;
  reviewsAPI: string; // The endpoint for the Enrich reviews API
  reviewsAPIVersion: string; // The version of the Enrich reviews API
}

interface GetAppReviewCommentsArgs extends BaseAppReviewCommentsRestArgs {
  page: number;
  itemsPerPage: number;
}

interface CreateReviewCommentArgs extends BaseAppReviewCommentsRestArgs {
  commentContent: string;
  source: string;
}

interface DeleteReviewCommentArgs extends BaseAppReviewCommentsRestArgs {
  commentId: string;
}

interface UpdateReviewCommentArgs extends BaseAppReviewCommentsRestArgs {
  commentId: string;
  commentContent: string;
}

const commonHttpOptions: IHttpOption = {
  requireCorrelationId: true,
  stringifyPostData: false,
  authenticationType: AuthenticationTypes.Spza,
  setBearerHeader: true,
};

export const ReviewCommentsQueryStrings = {
  OfferId: 'offerId',
  ApiVersion: 'api-version',
  PageNumber: 'PageNum',
  PageSize: 'PageSize',
  Sorting: 'Sorting',
};

/**
 * Creates a review comment
 * @param Object containing named parameters for the call
 * @returns Promise with the response, both for success and failure. in case of success, will contain the created comment
 */
export async function createReviewComment({
  commentContent,
  offerId,
  reviewId,
  reviewsAPI,
  reviewsAPIVersion,
  source,
}: CreateReviewCommentArgs): Promise<IReviewCommentOperationResponse> {
  try {
    const reviewComment = await post(`${reviewsAPI}/${reviewId}/comments/`, commonHttpOptions)
      .addQueryEntry(ReviewCommentsQueryStrings.ApiVersion, reviewsAPIVersion)
      .addQueryEntry(ReviewCommentsQueryStrings.OfferId, offerId)
      .setData({ content: commentContent.trim(), source })
      .request();
    return {
      reviewComment: mapEnrichReviewCommentToIAppReviewComment(reviewComment),
    };
  } catch (error) {
    return {
      error: error.response?.text || error,
    };
  }
}

/**
 * Get a review's comment list
 * @param Object containing named parameters for the call
 * @returns Promise with the response, containing a list of comments for the given review
 */
export async function getAppReviewComments({
  offerId,
  reviewId,
  page,
  itemsPerPage = Constants.Reviews.CommentsPerPage,
  reviewsAPI,
  reviewsAPIVersion,
}: GetAppReviewCommentsArgs): Promise<IAppGetReviewCommentCollectionResponse> {
  try {
    const {
      comments,
      pagination_info: paginationInfo,
      total_comments: totalComments,
    }: EnrichGetReviewCommentsResponse = await get(`${reviewsAPI}/${reviewId}/comments/`, commonHttpOptions)
      .addQueryEntry(ReviewCommentsQueryStrings.ApiVersion, reviewsAPIVersion)
      .addQueryEntry(ReviewCommentsQueryStrings.OfferId, offerId)
      .addQueryEntry(ReviewCommentsQueryStrings.PageNumber, page.toString())
      .addQueryEntry(ReviewCommentsQueryStrings.PageSize, itemsPerPage.toString())
      .addQueryEntry(ReviewCommentsQueryStrings.Sorting, 'asc')
      .request();
    return {
      comments: comments.map((c) => mapEnrichReviewCommentToIAppReviewComment(c)),
      paginationInfo: {
        pageNumber: paginationInfo.page_number,
        pageSize: paginationInfo.page_size,
      },
      totalComments,
    };
  } catch (error) {
    return {
      error,
      comments: [],
      totalComments: 0,
      paginationInfo: {
        pageNumber: 0,
        pageSize: 0,
      },
    };
  }
}

/**
 * Updates a review comment's content
 * @param Object containing named parameters for the call
 * @returns Promise with the response, both for success and failure. in case of success, will contain the modified comment
 */
export async function updateReviewComment({
  commentId,
  commentContent,
  offerId,
  reviewId,
  reviewsAPI,
  reviewsAPIVersion,
}: UpdateReviewCommentArgs): Promise<IReviewCommentOperationResponse> {
  try {
    const reviewComment = await patch(`${reviewsAPI}/${reviewId}/comments/${commentId}`, commonHttpOptions)
      .addQueryEntry(ReviewCommentsQueryStrings.ApiVersion, reviewsAPIVersion)
      .addQueryEntry(ReviewCommentsQueryStrings.OfferId, offerId)
      .setData({ content: commentContent.trim() })
      .request();
    return {
      reviewComment: mapEnrichReviewCommentToIAppReviewComment(reviewComment),
    };
  } catch (error) {
    return { error };
  }
}

/**
 * Deletes a review comment (this is a soft deletion, meaning that the comment will still be in the database and will show in the list as deleted)
 * @param Object containing named parameters for the call
 * @returns Promise with the response, both for success and failure. in case of success, will contain the comment after the deletion
 */
export async function deleteReviewComment({
  commentId,
  offerId,
  reviewId,
  reviewsAPI,
  reviewsAPIVersion,
}: DeleteReviewCommentArgs): Promise<IReviewCommentOperationResponse> {
  try {
    const reviewComment = await del(`${reviewsAPI}/${reviewId}/comments/${commentId}`, commonHttpOptions)
      .addQueryEntry(ReviewCommentsQueryStrings.ApiVersion, reviewsAPIVersion)
      .addQueryEntry(ReviewCommentsQueryStrings.OfferId, offerId)
      .request();
    return {
      reviewComment: mapEnrichReviewCommentToIAppReviewComment(reviewComment),
    };
  } catch (error) {
    return {
      error,
    };
  }
}
