import * as React from 'react';
import * as PropTypes from 'prop-types';

import SpzaComponent from './spzaComponent';
import { IDataValues, DataMap } from '@shared/utils/dataMapping';
import { isOneTaxonomyEnabled } from '@shared/oneTaxonomy/utils';
import { ICommonContext, ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { ILink, ITelemetryData } from '@shared/Models';
import { getWindow } from '@shared/services/window';
import { Constants } from '@shared/utils/constants';
import { shouldRenderPromotionBanner } from '@shared/utils/promotionBannerUtils';
import { Banner } from './banner';
import Automotive from '@shared/images/banners/automotive.jpg';
import Distribution from '@shared/images/banners/distribution.jpg';
import Education from '@shared/images/banners/education.jpg';
import FinancialServices from '@shared/images/banners/financial-services.jpg';
import Government from '@shared/images/banners/government.jpg';
import HospitalityAndTravel from '@shared/images/banners/hospitality-and-travel.jpg';
import Healthcare from '@shared/images/banners/healthcare.jpg';
import ManufacturingResources from '@shared/images/banners/manufacturing-resources.jpg';
import RetailAndConsumerGoods from '@shared/images/banners/retail-and-consumer-goods.jpg';
import MediaCommunications from '@shared/images/banners/media-communications.jpg';
import Nonprofit from '@shared/images/banners/nonprofit.jpg';
import ProfessionalServices from '@shared/images/banners/professional-services.jpg';
import DefenseIntelligence from '@shared/images/banners/defenseIntelligence.jpg';
import Energy from '@shared/images/banners/energy.jpg';
import CloudsIndustryNonprofit from '@shared/images/banners/cloudsIndustry/nonprofit.png';
import CloudsIndustryHealthcare from '@shared/images/banners/cloudsIndustry/healthcare.png';
import CloudsIndustryFinancialServices from '@shared/images/banners/cloudsIndustry/financial-services.png';
import CloudsIndustryRetail from '@shared/images/banners/cloudsIndustry/retail.png';
import D365 from '@shared/images/banners/D365.jpg';
import M365 from '@shared/images/banners/Office365_1600x200.jpg';
import WebApps from '@shared/images/banners/Web_Apps.jpg';
import PowerPlatform from '@shared/images/banners/Power_Platform.jpg';
import PowerBI from '@shared/images/banners/Power_BI_apps.jpg';
import Word from '@shared/images/banners/Word.jpg';
import Excel from '@shared/images/banners/Excel.jpg';
import PowerPoint from '@shared/images/banners/PowerPoint.jpg';
import Office from '@shared/images/banners/Office.jpg';
import OneNote from '@shared/images/banners/OneNote.jpg';
import Visio from '@shared/images/banners/Visio_1600x200.jpg';
import Access from '@shared/images/banners/Access_1600x200.jpg';
import Publisher from '@shared/images/banners/Publisher.jpg';
import Skype from '@shared/images/banners/Skype.jpg';
import Project from '@shared/images/banners/Project.jpg';
import Outlook from '@shared/images/banners/Outlook.jpg';
import Sharepoint from '@shared/images/banners/SharePoint.jpg';
import OneDrive from '@shared/images/banners/OneDrive_1600x200.jpg';
import Dynamics365ForSales from '@shared/images/banners/Dynamics_365_for_Sales_1600x200.jpg';
import Dynamics365ForFieldHub from '@shared/images/banners/Dynamics_365_for_Field_Service_1600x200.jpg';
import Dynamics365ForServiceAutomation from '@shared/images/banners/Dynamics_365_for_Project_Service_Automation_1600x200.jpg';
import Dynamics365ForCustomerService from '@shared/images/banners/Dynamics_365_For_Customer_Service_1600x200.jpg';
import Dynamics365ForFinanceAndOperations from '@shared/images/banners/Dynamics_365_for_Finance_and_Operations_1600x200.jpg';
import Dynamics365ForBusinessCentral from '@shared/images/banners/Dynamics_365_Business_Central_1600x200.jpg';
import NAVImage from '@shared/images/banners/Dynamics_NAV_1600x200.jpg';
import Teams from '@shared/images/banners/Teams.jpg';
import Azure from '@shared/images/banners/Azure.jpg';
import Minecraft from '@shared/images/banners/Minecraft.jpg';
import PowerApps from '@shared/images/banners/Power_Apps.jpg';
import PowerAutomate from '@shared/images/banners/Power_Automate.jpg';
import PowerBiVisuals from '@shared/images/banners/Power_BI_visuals.jpg';
import PowerVirtualAgents from '@shared/images/banners/Power_Virtual_Agents.jpg';
import Viva from '@shared/images/banners/Viva.jpg';
import Windows from '@shared/images/banners/Windows.jpg';
import Sustainability from '@shared/images/banners/Sustainability.jpg';

import { logger } from '@src/logger';

// exported for testing
export interface IPromotionBanner {
  name: string;
  dataValue?: IDataValues;
  storeLink?: string;
  keywords: string[];
  title?: string;
  description?: string;
  descriptionFallback?: string;
  links?: ILink[];
  imageUrl?: string;
  titleColor?: string;
  signUpNames?: string[];
}

const defaultPromotionsBanners: IPromotionBanner[] = [
  {
    name: 'Microsoft Word 2016',
    dataValue: DataMap.products.Word,
    keywords: ['word', 'microsoft word', 'ms word'],
    title: 'Microsoft Word',
    description: 'Banner_MicrosoftWord',
    descriptionFallback:
      'Microsoft Word is better than ever. Better ways to read, write, collaborate and present ideas. From anywhere.',
    links: [{ text: 'AppSource_banner_get', params: ['Microsoft Word'], url: 'https://aka.ms/officestoreword' }],
    imageUrl: Word,
    titleColor: '#2a579a',
  },
  {
    name: 'Power Virtual Agents',
    dataValue: DataMap.products.PowerVirtualAgents,
    keywords: ['pvirtual agents', 'power virtual agents'],
    title: 'Power Virtual Agents',
    description: 'Banner_PowerVirtualAgents',
    descriptionFallback:
      "Easily create intelligent, secure, and scalable virtual agents with a single low-code bot building platform that's designed for everyone.",
    links: [{ text: 'AppSource_Banner_LearnMore', url: 'https://powervirtualagents.microsoft.com' }],
    imageUrl: PowerVirtualAgents,
    titleColor: '#2a579a',
  },
  {
    name: 'Power BI visuals',
    dataValue: DataMap.products.PowerBICustomVisual,
    keywords: ['pbi visuals', 'power bi visuals'],
    title: 'Power BI Visuals',
    description: 'Banner_PowerBiVisuals',
    descriptionFallback:
      'Bring your data to life with stunning, interactive data visualizations tailored to your organization and industry.',
    links: [{ text: 'AppSource_Banner_LearnMore', url: 'https://aka.ms/appsourcepbivisual' }],
    imageUrl: PowerBiVisuals,
    titleColor: '#2a579a',
  },
  {
    name: 'Power Automate',
    dataValue: DataMap.products.PowerAutomate,
    keywords: ['power automate'],
    title: 'Power Automate',
    description: 'Banner_PowerAutomate',
    descriptionFallback: 'Empower everyone to automate workflows with a single low-code, user-friendly platform.',
    links: [{ text: 'AppSource_Banner_LearnMore', url: 'https://aka.ms/appsourcepowerautomate' }],
    imageUrl: PowerAutomate,
    titleColor: '#2a579a',
  },
  {
    name: 'Microsoft Excel 2016',
    dataValue: DataMap.products.Excel,
    keywords: ['Excel', 'microsoft excel'],
    title: 'Microsoft Excel',
    description: 'Banner_MicrosoftExcel2016',
    descriptionFallback: 'Create order. Unlock insights and tell the story in your data.',
    links: [{ text: 'AppSource_banner_get', params: ['Microsoft Excel'], url: 'https://aka.ms/officestoreexcel' }],
    imageUrl: Excel,
    titleColor: '#227446',
  },
  {
    name: 'Microsoft PowerPoint 2016',
    dataValue: DataMap.products.PowerPoint,
    keywords: ['powerpoint', 'microsoft powerpoint', 'power point'],
    title: 'Microsoft PowerPoint',
    description: 'Banner_MicrosoftPowerPoint2016',
    descriptionFallback: 'Build the story, present with clarity and conviction, and move forward faster',
    links: [{ text: 'AppSource_banner_get', params: ['Microsoft PowerPoint'], url: 'https://aka.ms/officestorepowerpoint' }],
    imageUrl: PowerPoint,
    titleColor: '#b84729',
  },
  {
    name: 'Microsoft Office',
    dataValue: DataMap.products.Office365,
    keywords: ['officemetaos', 'OfficeMetaOS', 'MetaOS'],
    title: 'Office app',
    description: 'Banner_MicrosoftOfficeApp',
    descriptionFallback:
      'A hub for all your content - get to files and documents, understand what needs attention, and easily start creating.',
    links: [{ text: 'AppSource_banner_getOfficeApp', params: ['Microsoft Office'], url: 'https://aka.ms/office.com/' }],
    imageUrl: Office,
    titleColor: '#b84729',
  },
  {
    name: 'Microsoft Office 365 (Home/Personal)',
    dataValue: DataMap.products.OneNote,
    keywords: ['onenote', 'microsoft onenote', 'one note'],
    title: 'Microsoft OneNote',
    description: 'Banner_MicrosoftOffice365_HomeOrPersonal',
    descriptionFallback: 'OneNote is the digital note-taking app for all your devices. Get OneNote with office 365',
    links: [{ text: 'AppSource_banner_get', params: ['Microsoft OneNote'], url: 'https://aka.ms/appsourceoffice' }],
    imageUrl: OneNote,
    titleColor: '#7719AA',
  },
  {
    name: 'Microsoft Visio 2013',
    keywords: ['visio', 'microsoft visio'],
    title: 'Microsoft Visio',
    description: 'Banner_MicrosoftVisio2013',
    descriptionFallback: 'Easily design versatile diagrams with a rich set of new and updated shapes and stencils',
    links: [{ text: 'AppSource_banner_get', params: ['Microsoft Visio'], url: 'https://aka.ms/appsourcevisio' }],
    imageUrl: Visio,
    titleColor: '#3a55a4',
  },
  {
    name: 'Minecraft',
    keywords: ['minecraft'],
    title: 'Minecraft',
    description: 'Banner_Minecraft',
    descriptionFallback:
      'Immerse yourself in a Minecraft world of building amazing things from the simplest of homes to the grandest of castles to develop and improve your coding skills with assessments hosted on Microsoft Azure',
    links: [{ text: 'AppSource_Banner_LearnMore', url: 'https://aka.ms/appsourceminecraft' }],
    imageUrl: Minecraft,
    titleColor: '#3a55a4',
  },
  {
    name: 'Windows',
    keywords: ['windows', 'microsoft windows', 'ms windows'],
    title: 'Microsoft Windows',
    description: 'Banner_Windows',
    descriptionFallback:
      'Help people and teams do their best work with the apps and experiences they rely on every day to connect, collaborate, and get work done from anywhere',
    links: [{ text: 'AppSource_Banner_LearnMore', url: 'https://aka.ms/appsourcewindows' }],
    imageUrl: Windows,
    titleColor: '#3a55a4',
  },
  {
    name: 'Viva',
    keywords: ['viva'],
    title: 'Microsoft Viva',
    description: 'Banner_Viva',
    descriptionFallback:
      'Grow your customer reach and engagement with an integrated employee experience platform that empowers people and teams to be their best, from anywhere',
    links: [{ text: 'AppSource_Banner_LearnMore', url: 'https://aka.ms/appsourceviva' }],
    imageUrl: Viva,
    titleColor: '#3a55a4',
  },
  {
    name: 'Azure',
    keywords: ['azure', 'microsoft azure', 'microsoft cloud', 'ms cloud'],
    title: 'Microsoft Azure',
    description: 'Banner_Azure',
    descriptionFallback: 'On-premises, hybrid, multicloud, or at the edge—create secure, future-ready cloud solutions on Azure',
    links: [{ text: 'AppSource_Banner_LearnMore', url: 'https://aka.ms/appsourceazure' }],
    imageUrl: Azure,
    titleColor: '#3a55a4',
  },
  {
    name: 'Publisher',
    keywords: ['publisher', 'microsoft publisher'],
    title: 'Microsoft Publisher',
    description: 'Banner_Publisher',
    descriptionFallback:
      'Precisely layout and style text, pictures, borders, calendars, and more. In Publisher everything you add stays exactly the way you’ve designed it, in print, online, or in an email',
    links: [{ text: 'AppSource_Banner_LearnMore', url: 'https://aka.ms/appsourcepublisher' }],
    imageUrl: Publisher,
    titleColor: '#3a55a4',
  },
  {
    name: 'Microsoft Publisher 2016',
    keywords: ['Publisher'],
    title: 'Microsoft Publisher',
    description: 'Banner_MicrosoftPublisher2016',
    descriptionFallback:
      'Simple tools make it easy to grab attention. Personalize your publications and publish in the way that suits your audience best',
    links: [{ text: 'AppSource_banner_get', params: ['Microsoft Publisher'], url: 'https://aka.ms/appsource-publisher' }],
    imageUrl: Publisher,
    titleColor: '#077568',
  },
  {
    name: 'Microsoft Skype for Business',
    keywords: ['skype', 'microsoft skype', 'skype for business'],
    title: 'Skype',
    description: 'Banner_MicrosoftSkypeForBusiness',
    descriptionFallback:
      'Discover Skype calls, video, messaging, screen sharing and more. Skype keeps the world talking, for free',
    links: [
      { text: 'AppSource_banner_get', params: ['Skype'], url: 'https://aka.ms/appsourceskypeinstall' },
      { text: 'AppSource_banner_get', params: ['Skype for business'], url: 'https://aka.ms/appsourceskypeforbusinessinstall' },
    ],
    imageUrl: Skype,
    titleColor: '#2bace3',
  },
  {
    name: 'Microsoft Office 365 (Business)',
    keywords: ['Office 365, office365', 'office', 'microsoft office', 'ms office'],
    title: 'Office 365',
    description: 'Banner_MicrosoftOffice365_Business',
    descriptionFallback:
      'From home to business, from desktop to web and the devices in between, Office delivers the tools you need to get the work done',
    links: [{ text: 'AppSource_banner_get', params: ['Office 365'], url: 'https://aka.ms/appsourceoffice' }],
    imageUrl: PowerPoint,
    titleColor: '#DA3B01',
  },
  {
    name: 'Microsoft Access 2013',
    keywords: ['Access', 'microsoft access'],
    title: 'Microsoft Access',
    description: 'Banner_MicrosoftAccess2013',
    descriptionFallback: 'Elevate data. Create your own database apps easily in formats that serve your business best',
    links: [{ text: 'AppSource_banner_get', params: ['Microsoft Access'], url: 'https://aka.ms/appsource-access' }],
    imageUrl: Access,
    titleColor: '#a4373a',
  },
  {
    name: 'Microsoft Project',
    dataValue: DataMap.products.Project,
    keywords: ['project', 'microsoft project'],
    title: 'Microsoft Project',
    description: 'Banner_MicrosoftProject',
    descriptionFallback:
      'Deliver winning projects. Integrated planning tools help you keep track of projects and stay organized.',
    links: [{ text: 'AppSource_banner_get', params: ['Microsoft Project'], url: 'https://aka.ms/officestoreproject' }],
    imageUrl: Project,
    titleColor: '#32752e',
  },
  {
    name: 'Microsoft Outlook 2016',
    dataValue: DataMap.products.Outlook,
    keywords: ['outlook', 'microsoft outlook', 'outl', 'email', 'mail'],
    title: 'Microsoft Outlook',
    description: 'Banner_MicrosoftOutlook2016',
    descriptionFallback:
      'Work smarter with tools that help you communicate, manage your schedule, and find what you need—simply and fast.',
    links: [{ text: 'AppSource_banner_get', params: ['Microsoft Outlook'], url: 'https://aka.ms/officestoreoutlook' }],
    imageUrl: Outlook,
    titleColor: '#0174c7',
  },
  {
    name: 'Microsoft SharePoint',
    dataValue: DataMap.products.SharePoint,
    keywords: ['sharepoint', 'microsoft sharepoint', 'sharepo'],
    title: 'Microsoft SharePoint',
    description: 'Banner_MicrosoftSharePoint',
    descriptionFallback:
      'Your mobile, intelligent intranet. Share and manage content, knowledge, and applications to empower teamwork.',
    links: [{ text: 'AppSource_banner_get', params: ['Microsoft SharePoint'], url: 'https://aka.ms/officestoresharepoint' }],
    imageUrl: Sharepoint,
    titleColor: '#0c3e97',
  },
  {
    name: 'Microsoft Viva',
    dataValue: DataMap.products.Viva,
    keywords: ['viva', 'microsoft viva', 'viva topics', 'viva connections', 'viva insights'],
    title: 'Microsoft Viva',
    description: 'Banner_MicrosoftViva',
    descriptionFallback:
      'Grow your customer reach and engagement with an integrated employee experience platform that empowers people and teams to be their best, from anywhere',
    links: [{ text: 'AppSource_banner_get', params: ['Microsoft Viva'], url: 'https://aka.ms/appsourceviva' }],
    imageUrl: Viva,
    titleColor: '#0c3e97',
  },
  {
    name: 'Microsoft OneDrive',
    keywords: ['OneDrive', 'onedr', 'one dri', 'one driv', 'onedri', 'skydrive'],
    title: 'Microsoft OneDrive',
    description: 'Banner_MicrosoftOneDrive',
    descriptionFallback:
      'OneDrive on any device, anytime. With OneDrive you can access your favorite things on your favorite devices, including PCs, Macs, tablets, and mobile phones.',
    links: [{ text: 'AppSource_banner_get', params: ['Microsoft OneDrive'], url: 'https://aka.ms/appsource-onedrive' }],
    imageUrl: OneDrive,
    titleColor: '#0b4ab3',
  },
  {
    name: 'Microsoft Dynamics NAV',
    description: 'Banner_DynamicsNav',
    descriptionFallback:
      'Connect your financials, sales, service, and operations with an all-in-one business management solution. Easily tailor and extend the application to meet your business or industry-specific needs.',
    keywords: ['Dynamics NAV', 'NAV'],
    title: 'Microsoft Dynamics NAV',
    imageUrl: NAVImage,
    links: [{ text: 'AppSource_banner_get', params: ['Microsoft Dynamics NAV'], url: 'https://aka.ms/appsourcenav' }],
  },
  {
    name: 'Dynamics 365 for Sales',
    dataValue: DataMap.products.Dynamics365forSales,
    keywords: ['Dynamics 365 for Sales'],
    title: 'Dynamics 365 Sales',
    description: 'Banner_Dynamics365Sales',
    descriptionFallback:
      'Go beyond sales force automation with Dynamics 365 for Sales, enabling you to better understand customer needs, engage more effectively, and win more deals.',
    links: [{ text: 'AppSource_banner_get', params: ['Dynamics 365 Sales'], url: 'https://aka.ms/appsourced365sales' }],
    imageUrl: Dynamics365ForSales,
    titleColor: '#002050',
  },
  {
    name: 'Dynamics 365 for Field Service',
    dataValue: DataMap.products.Dynamics365forFieldServices,
    keywords: ['Dynamics 365 for Field Service'],
    title: 'Dynamics 365 Field Service',
    description: 'Banner_Dynamics365FieldService',
    descriptionFallback:
      'Deliver a seamless, end-to-end service experience. Built-in intelligence helps you resolve service issues before they occur, reduce operational costs, and deliver positive onsite experiences.',
    links: [
      { text: 'AppSource_banner_get', params: ['Dynamics 365 Field Service'], url: 'https://aka.ms/appsrcd365fieldservice' },
    ],
    imageUrl: Dynamics365ForFieldHub,
    titleColor: '#002050',
  },
  {
    name: 'Dynamics 365 for Project Service Automation',
    dataValue: DataMap.products.Dynamics365forProjectServicesAutomation,
    keywords: ['Project Service Automation', 'Dynamics 365 for Project Service Automation'],
    title: 'Dynamics 365 Project Service Automation',
    description: 'Banner_Dynamics365ProjectServiceAutomation',
    descriptionFallback:
      'Build trusted customer relationships by delivering outstanding project experiences. Increase profitability through predictable project outcomes while increasing employee productivity.',
    links: [{ text: 'AppSource_banner_get', params: ['Microsoft Project'], url: 'https://aka.ms/appsrcd365psa' }],
    imageUrl: Dynamics365ForServiceAutomation,
    titleColor: '#002050',
  },
  {
    name: 'Dynamics 365 for Customer Service',
    dataValue: DataMap.products.Dynamics365forCustomerServices,
    keywords: ['Dynamics 365 for Customer Service'],
    title: 'Dynamics 365 Customer Service',
    description: 'Banner_Dynamics365CustomerService',
    descriptionFallback:
      'Effortless customer service: Empower your customers and your agents with the tools they need to ensure quick and accurate resolution, every time.',
    links: [{ text: 'AppSource_banner_get', params: ['Dynamics 365 Customer Service'], url: 'https://aka.ms/appsrcd365cs' }],
    imageUrl: Dynamics365ForCustomerService,
    titleColor: '#002050',
  },
  {
    name: 'Dynamics 365 for Finance and Operations',
    dataValue: DataMap.products.Dynamics365forFinancials,
    keywords: ['Dynamics 365 for Financials', 'Dynamics 365 for Finance and Operations'],
    title: 'Dynamics 365 Finance and Operations',
    description: 'Banner_Dynamics365FinanceAndOperations',
    descriptionFallback:
      'Unify global financials and operations to empower people to make fast, informed decisions. Help businesses adapt quickly to changing market demands and drive business growth.',
    links: [
      {
        text: 'AppSource_banner_get',
        params: ['Dynamics 365 Finance and Operations'],
        url: 'https://aka.ms/appsrcd365operations',
      },
    ],
    imageUrl: Dynamics365ForFinanceAndOperations,
    titleColor: '#002050',
  },
  {
    name: 'Dynamics 365 Business Central',
    dataValue: DataMap.products.Dynamics365forBusinessCentral,
    keywords: ['Dynamics 365 for Business Central', 'Dynamics 365 Business Central'],
    title: 'Dynamics 365 Business Central',
    description: 'Banner_Dynamics365BusinessCentral',
    descriptionFallback:
      'Connect your financials, sales, service, and operations with an all-in-one business management solution. Easily tailor and extend the application to meet your business or industry-specific needs.',
    links: [
      { text: 'AppSource_banner_get', params: ['Dynamics 365 Business Central'], url: 'https://aka.ms/appsrcd365financials' },
    ],
    imageUrl: Dynamics365ForBusinessCentral,
    titleColor: '#002050',
  },
  {
    name: 'Cortana Intelligence Suite',
    storeLink: 'https://aka.ms/appsrccortana',
    keywords: ['Cortana Intelligence', 'Cortana', 'azure ai'],
  },
  {
    name: 'Microsoft Teams',
    dataValue: DataMap.products.Teams,
    keywords: ['teams', 'microsoft teams', 'teas', 'teans', 'tems'],
    title: 'Microsoft Teams',
    description: 'Banner_MicrosoftTeams',
    descriptionFallback:
      'One place for all your team needs. Bring everything together including chats, meetings, calls, files, and tools to enable more productive teamwork.',
    links: [{ text: 'AppSource_banner_get', params: ['Microsoft Teams'], url: 'https://go.microsoft.com/fwlink/?linkid=874513' }],
    imageUrl: Teams,
    titleColor: '#5558af',
  },
  {
    name: 'Automotive',
    dataValue: isOneTaxonomyEnabled() ? DataMap.industries.automotive : DataMap.industries.Automotive_AS,
    keywords: ['Automotive'],
    title: 'Automotive',
    description: 'Banner_Automotive_Description',
    descriptionFallback:
      'Accelerate innovation, monetize data, and provide new services. Explore automotive offerings that help you redefine mobility as an industry while staying focused on trust, innovation, security, and compliance.',
    links: [{ text: 'AppSource_Banner_LearnMore', url: 'https://www.microsoft.com/en-us/industry/automotive' }],
    imageUrl: Automotive,
    titleColor: '#0067B8',
  },
  {
    name: 'Education',
    dataValue: isOneTaxonomyEnabled() ? DataMap.industries.education : DataMap.industries.Education,
    keywords: ['Education'],
    title: 'Education',
    description: 'Banner_Education_Description',
    descriptionFallback:
      'Help your students achieve their potential with a student-centered educational approach. Explore education solutions to help you develop safe and secure in-person and online environments, optimize student outcomes, and maximize classroom time.',
    links: [
      {
        text: 'AppSource_Banner_LearnMore',
        url: 'https://www.microsoft.com/en-us/education/school-leaders/k-12-microsoft-education-transformation-framework',
      },
    ],
    imageUrl: Education,
    titleColor: '#0067B8',
  },
  {
    name: 'Defense & Intelligence',
    dataValue: isOneTaxonomyEnabled()
      ? DataMap.industries['defense-and-intelligence']
      : DataMap.industries.DefenseIntelligence_AS_L1,
    links: [
      {
        text: 'AppSource_Banner_LearnMore',
        url: 'https://www.microsoft.com/en-us/industry/government/defense-and-intelligence',
      },
    ],
    keywords: ['Defense & Intelligence'],
    title: 'Defense & Intelligence',
    description: 'Banner_DefenseIntelligence_Description',
    descriptionFallback:
      'Advance your missions to promote stability and security. Discover defense and intelligence solutions that help you modernize your workforce, deliver trusted and secure services, protect the information domain, and optimize operations.',
    imageUrl: DefenseIntelligence,
    titleColor: '#0067B8',
  },
  {
    name: 'Distribution',
    dataValue: isOneTaxonomyEnabled() ? DataMap.industries.distribution : DataMap.industries.Distribution,
    keywords: ['Distribution'],
    title: 'Distribution',
    description: 'Banner_Distribution_Description',
    descriptionFallback:
      'Reimagine logistics, delivery, and distribution networks. Discover solutions that help you transform your processes, optimize product availability, and better manage operations.',
    imageUrl: Distribution,
    titleColor: '#0067B8',
  },
  {
    name: 'Energy',
    dataValue: isOneTaxonomyEnabled() ? DataMap.industries.energy : DataMap.industries.Energy_AS_L1,
    links: [{ text: 'AppSource_Banner_LearnMore', url: 'https://www.microsoft.com/en-us/industry/energy' }],
    keywords: ['Energy'],
    title: 'Energy',
    description: 'Banner_Energy_Description',
    descriptionFallback:
      'Accelerate innovation, transion to clean energy, and achieve growth objectives. Find digital technology solutions that help you enhance exploration, production, and distribution operations.',
    imageUrl: Energy,
    titleColor: '#0067B8',
  },
  {
    name: 'Financial Services',
    dataValue: isOneTaxonomyEnabled() ? DataMap.industries['financial-services'] : DataMap.industries.FinancialServices,
    keywords: ['FinancialServices'],
    title: 'Financial Services',
    description: 'Banner_FinancialServices_Description',
    descriptionFallback:
      'Become a trusted partner to your customers and differentiate your financial institution from competitors. Explore financial services offerings that help you create new value with innovative technology.',
    links: [
      {
        text: 'AppSource_Banner_LearnMore',
        url: 'https://www.microsoft.com/en-us/industry/financial-services/microsoft-cloud-for-financial-services',
      },
    ],
    imageUrl: FinancialServices,
    titleColor: '#0067B8',
  },
  {
    name: 'Government',
    dataValue: isOneTaxonomyEnabled() ? DataMap.industries.government : DataMap.industries.Government,
    keywords: ['Government'],
    title: 'Government',
    description: 'Banner_Government_Description',
    descriptionFallback:
      'Enhance government services and influence positive societal change. Explore government solutions that help you promote the well-being of people and communities on a platform that focuses on trust, innovation, security, and compliance.',
    links: [{ text: 'AppSource_Banner_LearnMore', url: 'https://www.microsoft.com/en-us/industry/government' }],
    imageUrl: Government,
    titleColor: '#0067B8',
  },
  {
    name: 'Healthcare',
    dataValue: isOneTaxonomyEnabled() ? DataMap.industries.healthcare : DataMap.industries.HealthCareandLifeSciences,
    keywords: ['Health'],
    title: 'Health',
    description: 'Banner_Healthcare_Description',
    descriptionFallback:
      'Personalize care, empower care teams, and improve operational outcomes. Explore healthcare solutions that help you reimage the ways you bring together people, data, and processes to meet your organizational mission.',
    links: [
      {
        text: 'AppSource_Banner_LearnMore',
        url: 'https://www.microsoft.com/en-us/industry/health/microsoft-cloud-for-healthcare',
      },
    ],
    imageUrl: Healthcare,
    titleColor: '#0067B8',
  },
  {
    name: 'Manufacturing & Resources',
    dataValue: isOneTaxonomyEnabled()
      ? DataMap.industries['manufacturing-and-resources']
      : DataMap.industries['manufacturing-resources'],
    keywords: ['Manufacturing'],
    title: 'Microsoft Cloud for Manufacturing',
    description: 'Banner_ManufacturingResources_Description',
    descriptionFallback:
      'Microsoft Cloud for Manufacturing addresses your most challenging digital transformation initiatives through a powerful combination of trusted Microsoft products and industry solutions from our partner ecosystem.',
    links: [
      {
        text: 'AppSource_banner_explore_more',
        url: 'https://aka.ms/cloudformanufacturing',
      },
      {
        text: 'AppSource_banner_get_started',
        url: 'https://aka.ms/MCFMdocs',
      },
    ],
    imageUrl: ManufacturingResources,
    titleColor: '#0067B8',
  },
  {
    name: 'ProfessionalServices',
    dataValue: isOneTaxonomyEnabled() ? DataMap.industries['professional-services'] : DataMap.industries.ProfessionalServices,
    keywords: ['ProfessionalServices'],
    title: 'Professional Services',
    description: 'Banner_ProfessionalServices_Description',
    descriptionFallback:
      'Create a more agile and transparent business model.Find professional services solutions that help you manage projects and resources, create business value, and deliver results for clients.',
    imageUrl: ProfessionalServices,
    titleColor: '#0067B8',
  },
  {
    name: 'Retail',
    dataValue: isOneTaxonomyEnabled()
      ? DataMap.industries['retail-and-consumer-goods']
      : DataMap.industries.RetailandConsumerGoods,
    keywords: ['RetailandConsumerGoods'],
    title: 'Retail',
    description: 'Banner_RetailAndConsumerGoods_Description',
    descriptionFallback:
      'Reinvent your business to meet evolving customer expectations. Discover intelligent retail solutions that help you turn data into actionable insights, create more resilient supply chains, enable employee collaboration, and deliver better business outcomes',
    links: [
      {
        text: 'AppSource_Banner_LearnMore',
        url: 'https://www.microsoft.com/en-us/industry/retail/microsoft-cloud-for-retail',
      },
    ],
    imageUrl: RetailAndConsumerGoods,
    titleColor: '#0067B8',
  },
  {
    name: 'Hospitality & Travel',
    dataValue: isOneTaxonomyEnabled() ? DataMap.industries['hospitality-and-travel'] : DataMap.industries.HospitalityTravel_AS,
    keywords: ['Hospitality & Travel'],
    title: 'Hospitality & Travel',
    description: 'Banner_HospitalityAndTravel_Description',
    descriptionFallback:
      'Manage assets and maintenance, automatically configure prices, and increase customer acquisition. Discover hospitality and travel solutions that help you map your inventory, reduce churn, and increase customer loyalty.',
    imageUrl: HospitalityAndTravel,
  },
  {
    name: 'Nonprofit & IGO',
    dataValue: isOneTaxonomyEnabled()
      ? DataMap.industries['other-public-sector-industries']
      : DataMap.industries.NonprofitIGO_AS_L1,
    keywords: ['Nonprofit & IGO'],
    title: 'Nonprofit & IGO',
    description: 'Banner_OtherPublicSectorIndustries_Description',
    descriptionFallback:
      'Accelerate your mission and drive greater impact. Turn data into insights, run fundraising campaigns, manage volunteers, and design and deliver programs.',
    links: [
      {
        text: 'AppSource_Banner_LearnMore',
        url: 'https://www.microsoft.com/en-us/nonprofits/?activetab=pivot1%3aprimaryr3',
      },
    ],
    imageUrl: Nonprofit,
  },
  {
    name: 'Media & Communications',
    dataValue: isOneTaxonomyEnabled()
      ? DataMap.industries['media-and-communications']
      : DataMap.industries.MediaCommunications_AS,
    keywords: ['Media & Communications'],
    title: 'Media & Communications',
    description: 'Banner_MediaCommunications_Description',
    descriptionFallback:
      'Deepen audience engagement, maximize creativity, and streamline production workflows. Explore industry-leading solutions that help you produce and deliver content, encourage collaboration, harness audience insights, and personalize experiences.',
    links: [
      {
        text: 'AppSource_Banner_LearnMore',
        url: 'https://www.microsoft.com/en-us/industry/media-entertainment',
      },
    ],
    imageUrl: MediaCommunications,
  },
  {
    name: 'PowerApps',
    dataValue: DataMap.products.PowerApps,
    keywords: ['power apps', 'microsoft power apps'],
    title: 'Power Apps',
    description: 'Banner_PowerApps',
    descriptionFallback: 'Easily build the business apps you need and extend or customize the apps you already use.',
    links: [{ text: 'AppSource_banner_get', params: ['Power Apps'], url: 'https://powerapps.microsoft.com/en-us/' }],
    imageUrl: PowerApps,
    titleColor: '#5558af',
  },
  {
    name: 'Office365',
    dataValue: DataMap.products.Office365,
    keywords: ['Office365', 'microsoft365', 'microsoft 365'],
    title: 'Trust Microsoft 365 Certified apps',
    description: 'Banner_Office365',
    descriptionFallback:
      'Ensure that an app has undergone rigorous security vetting before you download it. Microsoft 365 Certification validates apps and publishers against industry-best security standards. Use the “App Compliance” filter to find all apps that have earned the certification badge.',
    links: [{ text: 'AppSource_Banner_LearnMore', url: 'https://aka.ms/appsourcem365' }],
    imageUrl: M365,
    titleColor: '#5558af',
  },
  {
    name: 'Azure for Web Apps',
    dataValue: DataMap.products.AzureforWebApps,
    keywords: ['Azure for Web Apps', 'saas'],
    title: 'Discover industry-leading cloud-based apps',
    description: 'Banner_AzureForWebApps',
    descriptionFallback:
      'Discover applications that run securely from any internet-connected computer or mobile device without installing and maintaining hardware or software infrastructure—and pay by subscription or your level of use.',
    links: [],
    imageUrl: WebApps,
    titleColor: '#5558af',
  },
  {
    name: 'Power BI',
    dataValue: DataMap.products.PowerBI,
    keywords: ['pbi', 'pbi apps', 'power bi apps', 'powerbi', 'powerbi apps'],
    title: 'Microsoft Power BI',
    description: 'Banner_PowerBi',
    descriptionFallback:
      'Create a data culture by connecting your big data investments with Power BI and empowering everyone in your organization to make data-driven, insights-based decisions.',
    links: [
      {
        text: 'AppSource_banner_get',
        params: ['Power BI'],
        url: 'https://aka.ms/appsourcepbi',
      },
    ],
    imageUrl: PowerBI,
    titleColor: '#5558af',
  },
  {
    name: 'Dynamics 365',
    dataValue: DataMap.products.Dynamics365,
    keywords: ['dynamics', 'dynamics365', 'dynamics 365'],
    title: 'Microsoft Dynamics 365',
    description: 'Banner_Dynamics365',
    descriptionFallback:
      'Be more agile with the only portfolio of business applications that empowers everyone in your organization to deliver operational excellence and delight every customer.',
    links: [{ text: 'AppSource_Banner_LearnMore', url: 'https://aka.ms/appsourcedynamics' }],
    imageUrl: D365,
    titleColor: '#5558af',
  },
  {
    name: 'PowerPlatform',
    dataValue: DataMap.products.PowerPlatform,
    keywords: ['power platform'],
    title: 'Microsoft Power Platform',
    description: 'Banner_PowerPlatform',
    descriptionFallback:
      'Analyze and visualize data, automate workflows, create chatbots, and build transformational end-to-end business applications with an extensible, scalable, and collaborative low-code platform.',
    links: [{ text: 'AppSource_Banner_LearnMore', url: 'https://aka.ms/appsourcepowerplat' }],
    imageUrl: PowerPlatform,
    titleColor: '#5558af',
  },
];

const promotionsBanners: { [entityType: string]: IPromotionBanner[] } = {
  [Constants.EntityType.App]: [],
  [Constants.EntityType.Service]: [],
  [Constants.EntityType.CloudsIndustry]: [
    {
      name: 'Financial Services',
      dataValue: isOneTaxonomyEnabled() ? DataMap.industries['financial-services'] : DataMap.industries.FinancialServices,
      keywords: ['FinancialServices'],
      title: 'Microsoft Cloud for Financial Services',
      description: 'Banner_CloudsIndustry_FinancialServices_Description',
      descriptionFallback:
        'Microsoft Cloud for Financial Services provides capabilities to manage data to deliver differentiated experiences, empower employees, and combat financial crime while facilitating security, compliance, and interoperability.',
      links: [
        {
          text: 'AppSource_banner_explore',
          params: ['Microsoft Cloud for Financial Services'],
          url: 'https://www.microsoft.com/industry/financial-services/microsoft-cloud-for-financial-services',
        },
        {
          text: 'AppSource_banner_get_started',
          url: 'https://aka.ms/solutioncenter-financialservices',
        },
      ],
      imageUrl: CloudsIndustryFinancialServices,
      titleColor: '#0067B8',
    },
    {
      name: 'Nonprofit & IGO',
      dataValue: isOneTaxonomyEnabled()
        ? DataMap.industries['other-public-sector-industries']
        : DataMap.industries.NonprofitIGO_AS_L1,
      keywords: ['Nonprofit & IGO'],
      title: 'Microsoft Cloud for Nonprofit',
      description: 'Banner_CloudsIndustry_OtherPublicSectorIndustries_Description',
      descriptionFallback:
        'Microsoft Cloud for Nonprofit uniquely brings together trusted and intelligent cloud capabilities to provide nonprofits with a holistic, connected platform to help accelerate mission outcomes.',
      links: [
        {
          text: 'AppSource_banner_explore',
          params: ['Microsoft Cloud for Nonprofit'],
          url: 'https://www.microsoft.com/nonprofit',
        },
        {
          text: 'AppSource_banner_get_started',
          url: 'https://aka.ms/solutioncenter-nonprofit',
        },
      ],
      imageUrl: CloudsIndustryNonprofit,
    },
    {
      name: 'Healthcare',
      dataValue: isOneTaxonomyEnabled() ? DataMap.industries.healthcare : DataMap.industries.HealthCareandLifeSciences,
      keywords: ['Health'],
      title: 'Microsoft Cloud for Healthcare',
      description: 'Banner_CloudsIndustry_Healthcare_Description',
      descriptionFallback:
        'Microsoft Cloud for Healthcare provides trusted and integrated cloud capabilities to deliver better experiences, better insights, and better care.',
      links: [
        {
          text: 'AppSource_banner_explore',
          params: ['Microsoft Cloud for Healthcare'],
          url: 'https://www.microsoft.com/industry/health/microsoft-cloud-for-healthcare',
        },
        {
          text: 'AppSource_banner_get_started',
          url: 'http://aka.ms/solutioncenter-healthcare',
        },
      ],
      imageUrl: CloudsIndustryHealthcare,
      titleColor: '#0067B8',
    },
    {
      name: 'Retail',
      dataValue: isOneTaxonomyEnabled()
        ? DataMap.industries['retail-and-consumer-goods']
        : DataMap.industries.RetailandConsumerGoods,
      keywords: ['RetailandConsumerGoods'],
      title: 'Microsoft Cloud for Retail',
      description: 'Banner_CloudsIndustry_RetailAndConsumerGoods_Description',
      descriptionFallback:
        "Microsoft Cloud for Retail accelerates business growth by providing trusted retail industry solutions that integrate with retailer's existing systems to seamlessly connect your customers, your people, and your data.",
      links: [
        {
          text: 'AppSource_banner_explore',
          params: ['Microsoft Cloud for Retail'],
          url: 'https://aka.ms/cloudforretail',
        },
        {
          text: 'AppSource_banner_get_started',
          url: 'https://aka.ms/solutioncenter-retail',
        },
      ],
      imageUrl: CloudsIndustryRetail,
      titleColor: '#0067B8',
    },
    {
      name: 'Sustainability',
      dataValue: isOneTaxonomyEnabled() ? DataMap.industries.SustainabilityIndustry : DataMap.industries.Sustainability_AS,
      keywords: ['Sustainability'],
      title: 'Microsoft Cloud for Sustainability',
      description: 'Banner_CloudsIndustry_Sustainability_Description',
      descriptionFallback:
        'Microsoft Cloud for Sustainability helps organizations accelerate sustainability progress and business growth with a growing set of ESG capabilities from Microsoft and our global ecosystem of partners.',
      links: [
        {
          text: 'AppSource_banner_explore',
          params: ['Microsoft Cloud for Sustainability'],
          url: 'https://aka.ms/cloudforsustainability',
        },
        {
          text: 'AppSource_banner_get_started',
          url: 'https://aka.ms/solutioncenter-sustainability',
        },
      ],
      imageUrl: Sustainability,
      titleColor: '#0067B8',
    },
  ],
};

export interface IAppPromotionPaneProps {
  entityType: Constants.EntityType;
  activeFilters: IDataValues[];
  searchText: string;
  isEmbedded: boolean;
}

export default class AppPromotionPane extends SpzaComponent<IAppPromotionPaneProps, any> {
  context: ICommonContext & ILocContext & ILocParamsContext;

  getPromotionBanner(): IPromotionBanner {
    const searchText = this.props.searchText;
    const activeFilters: IDataValues[] = this.props.activeFilters;

    // show a banner only in
    // a) app source
    // b) search mode and the search term as a matching banner
    // c) when only filter from a group (product, category, industry) is selected
    // and it has a matching banner
    if (!shouldRenderPromotionBanner() || (searchText && activeFilters && activeFilters.length > 0)) {
      return undefined;
    }

    const searchPromotionBanner = this.getPromotionBannerForSearchText(searchText);
    if (searchPromotionBanner) {
      return searchPromotionBanner;
    }

    if (activeFilters) {
      if (
        activeFilters.filter((filter: IDataValues) => filter.UrlGroup === Constants.FilterQuery.product).length ===
        activeFilters.length
      ) {
        const lastLevelFilters = activeFilters.filter((filter: IDataValues) => !filter.ShortcutFilters && !!filter.ProductCode);
        // get product corresponding to current active filters
        if (lastLevelFilters && lastLevelFilters.length === 1) {
          return this.getPromotionBannerForFilter(lastLevelFilters[0]);
        }
      }

      if (activeFilters.length === 1) {
        if (
          activeFilters.filter((filter: IDataValues) => filter.FilterGroup === Constants.FilterQuery.category).length ===
          activeFilters.length
        ) {
          // check banner for selected category
          return this.getPromotionBannerForFilter(activeFilters[0]);
        }

        if (
          activeFilters.filter((filter: IDataValues) => filter.FilterGroup === Constants.FilterQuery.industry).length ===
          activeFilters.length
        ) {
          // check banner for selected industry
          return this.getPromotionBannerForFilter(activeFilters[0]);
        }
        if (
          activeFilters.filter((activeFilter: IDataValues) => activeFilter.UrlGroup === Constants.FilterQuery.product).length ===
          activeFilters.length
        ) {
          // check banner for selected product
          return this.getPromotionBannerForFilter(activeFilters[0]);
        }
      }
    }
    return null;
  }

  getCurrentPromotionsBanner() {
    return [...promotionsBanners[this.props.entityType], ...defaultPromotionsBanners];
  }

  getPromotionBannerForSearchText(searchText: string) {
    if (typeof searchText === 'string' && searchText.length) {
      const searchTextLowerCase = searchText.toLowerCase();
      const bannerForSearchText = this.getCurrentPromotionsBanner().filter((banner: IPromotionBanner) => {
        return (
          banner.keywords.filter((keyword: string) => {
            return searchTextLowerCase.indexOf(keyword.toLowerCase()) >= 0;
          }).length > 0
        );
      });
      if (bannerForSearchText.length > 0) {
        return bannerForSearchText[0];
      }
    }
    return null;
  }

  getPromotionBannerForFilter(activeFilters: IDataValues) {
    const promotedBannerForActiveFilters = this.getCurrentPromotionsBanner().filter((banner: IPromotionBanner) => {
      return activeFilters.UrlKey === banner.dataValue?.UrlKey && banner.dataValue.FilterGroup === activeFilters.FilterGroup;
    });
    if (promotedBannerForActiveFilters.length > 0) {
      return promotedBannerForActiveFilters[0];
    }
    return null;
  }

  handleClick(telemetryLink: string, event: any) {
    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.FirstPartyAppPromotion,
      details: JSON.stringify({
        linkType: Constants.Telemetry.ActionModifier.FirstPartyAppPromotion,
        link: telemetryLink || (event && event.target && event.target.href),
      }),
    };

    SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });
  }

  renderStoreLink(storeLink: string) {
    return (
      <a
        href={storeLink}
        className="c-hyperlink"
        target="_blank"
        onClick={this.handleClick.bind(this, storeLink)}
        rel="noreferrer"
      >
        {this.context.loc('AppPromotionPane_SeeYourOptions')}
      </a>
    );
  }

  renderImpl() {
    if (this.props.isEmbedded) {
      return null;
    }

    const bannerInfo = this.getPromotionBanner();

    if (bannerInfo) {
      const { title, imageUrl: imgURL, links, description, descriptionFallback, name, storeLink } = bannerInfo;
      return (
        <>
          {title ? (
            <Banner
              data={{
                description: this.context.loc(description, descriptionFallback),
                imgURL,
                title,
                links,
              }}
            />
          ) : (
            <div className="appPromotionPane_shorter">
              <p className="c-subheading-5">
                {this.context.locParams('AppPromotionPane_Content', [name, ' '])}
                {this.renderStoreLink(storeLink)}
              </p>
            </div>
          )}
        </>
      );
    }

    return null;
  }
}

(AppPromotionPane as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
};
