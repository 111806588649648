import DOMPurify from 'dompurify';
import React, { useMemo } from 'react';
import { JSDOM } from 'jsdom';

interface Props {
  html: string;
  className?: string;
}

let DOMPurifyServer: DOMPurify.DOMPurify;
if (!__CLIENT__) {
  const { window } = new JSDOM('');
  DOMPurifyServer = DOMPurify(window);
}

export const SafeHtmlWrapper = ({ html, className }: Props) => {
  const sanitize = __CLIENT__ ? DOMPurify.sanitize : DOMPurifyServer.sanitize;
  const sanitizedHtml = useMemo(() => sanitize(html, { ADD_ATTR: ['target'] }), [html]);
  // eslint-disable-next-line react/no-danger
  return <div className={className} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
};
