import * as React from 'react';
import * as PropTypes from 'prop-types';
import SpzaComponent from './spzaComponent';
import { ICuratedSection, IDataItem, ITelemetryData } from '@shared/Models';
import Ribbon from '@shared/components/ribbon';
import { DataMap } from '@shared/utils/dataMapping';
import { IBuildHrefContext, ILocContext, ILocParamsContext, ICommonContext } from '@shared/interfaces/context';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { getWindow } from '@shared/services/window';
import { Constants } from '@shared/utils/constants';
import { getEntityRegistration } from '@shared/utils/entityRegistration';
import { logger } from '@src/logger';
import { isOneTimePaymentOffer } from '@shared/utils/onetimepaymentoffers';
import { RecommendationsRibbon } from '@appsource/components/RecommendationsRibbonWrapper';
import { getProductByUrlKey } from '@shared/utils/appUtils';
import { urlKeys } from '@shared/utils/datamappingHelpers';

export interface ICuratedGalleryProps<T> {
  sections: ICuratedSection<T>[];
  entityType: Constants.EntityType;
  isMobile: boolean;
  selectedLocale: string;
  renderL3CurationStyle?: boolean;
  embedHost: string;
  getSeeMoreLink?: (sectionId: any, context: any) => string;
  getSectionTitle?: (titleId: any) => string;
  selectedCountry: string;
  recoWhatsNewItems: IDataItem[];
  recoTrendingItems: IDataItem[];
}

export class CuratedGallery<T> extends SpzaComponent<ICuratedGalleryProps<T>, any> {
  context: IBuildHrefContext & ILocContext & ILocParamsContext & ICommonContext;

  private maxTileDisplay: number = this.props.embedHost ? 4 : 5;

  componentDidMount() {
    const sectionsTelemetry = (this.props.sections || []).map((section) => {
      return {
        titleId: section.titleId,
        sectionItemsLength: section.items ? section.items.length : 0,
      };
    });
    const detailsObject = {
      message: 'main page swim lanes: ',
      sections: sectionsTelemetry,
    };
    const perfPayload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.PageLoad,
      actionModifier: Constants.Telemetry.ActionModifier.End,
      details: JSON.stringify(detailsObject),
    };
    SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', perfPayload);
    logger.info(perfPayload.details, {
      action: perfPayload.action,
      actionModifier: perfPayload.actionModifier,
    });
  }

  getDetailsPageUrl(titleId: string): string {
    let url = '';
    let isUrlKeyExists = false;
    let filterName = '';

    const urlFilters: any = {
      category: null,
      industry: null,
      product: null,
      search: null,
      sortBy: null,
    };

    Object.keys(urlKeys).forEach((urlKey) => {
      if (urlKeys[`${urlKey}`][`${titleId}`]) {
        isUrlKeyExists = true;
        filterName = urlKey;
      }
    });

    if (isUrlKeyExists) {
      if (filterName === Constants.DataMapCollectionKeys.products) {
        const productInfo = getProductByUrlKey({ urlKey: titleId });
        const shortcutFilters = productInfo?.ShortcutFilters;
        if (shortcutFilters) {
          urlFilters.product = shortcutFilters.join(';');
        } else {
          urlFilters.product = titleId;
        }
      } else {
        if (filterName === Constants.DataMapCollectionKeys.categories) {
          urlFilters.category = titleId;
        } else if (filterName === Constants.DataMapCollectionKeys.industries) {
          urlFilters.industry = titleId;
        }
      }
      url = this.context.buildHref(getEntityRegistration(this.props.entityType).route, null, urlFilters);
    }
    return url;
  }

  getTitle(section: ICuratedSection<T>): string {
    let titleId = section.titleId;
    if (titleId === Constants.FeaturedAppsId) {
      return this.context.loc('CuratedType_FeaturedApps');
    }

    let isUrlKeyExists = false;
    let filterName = '';
    Object.keys(urlKeys).forEach((urlKey) => {
      if (urlKeys[`${urlKey}`][`${titleId}`]) {
        isUrlKeyExists = true;
        filterName = urlKey;
      }
    });

    if (
      isUrlKeyExists &&
      filterName !== Constants.DataMapCollectionKeys.categories &&
      DataMap[`${filterName}`][`${titleId}`]?.LocKey
    ) {
      titleId = this.context.loc(DataMap[`${filterName}`][`${titleId}`].LocKey, DataMap[`${filterName}`][`${titleId}`].Title);
    } else if (isUrlKeyExists && filterName === Constants.DataMapCollectionKeys.categories) {
      const isCategoryExist = Object.keys(DataMap.categories).filter(
        (catagorySection: any) => DataMap.categories[`${catagorySection}`][`${titleId}`]?.LocKey
      );

      if (isCategoryExist && isCategoryExist.length) {
        titleId = this.context.loc(
          DataMap.categories[`${isCategoryExist[0]}`][`${titleId}`].LocKey,
          DataMap.categories[`${isCategoryExist[0]}`][`${titleId}`].Title
        );
      }
    }

    if (section.titleLocId) {
      titleId = this.context.loc(section.titleLocId, titleId);
    }
    return titleId;
  }

  renderImpl() {
    const TileType = getEntityRegistration(this.props.entityType).tileType;
    const { sections, recoWhatsNewItems = [], recoTrendingItems = [], selectedCountry, selectedLocale } = this.props;

    sections?.map(
      (section) => (section.items = section?.items?.filter((item: any) => !isOneTimePaymentOffer(item.entityId)) || [])
    );
    return (
      <>
        <div className="spza_controls"></div>

        <RecommendationsRibbon
          recommendedItems={recoTrendingItems}
          title={this.context.loc('CuratedType_Trending_Now', 'Trending Now')}
          ribbonKey={Constants.Recommendations.Trending}
          selectedCountry={selectedCountry}
          selectedLocale={selectedLocale}
          tileType={TileType}
        />

        <RecommendationsRibbon
          recommendedItems={recoWhatsNewItems}
          title={this.context.loc('CuratedType_Whats_New', 'Whats New')}
          ribbonKey={Constants.Recommendations.WhatsNew}
          selectedCountry={selectedCountry}
          selectedLocale={selectedLocale}
          tileType={TileType}
        />

        {sections
          ? sections.map((section, key) => {
              const ribbonItems =
                section.items.length > this.maxTileDisplay ? section.items.slice(0, this.maxTileDisplay) : section.items;
              return (
                <Ribbon
                  title={this.props.getSectionTitle ? this.props.getSectionTitle(section.titleId) : this.getTitle(section)}
                  key={key}
                  seeMoreUrl={
                    this.props.getSeeMoreLink
                      ? this.props.getSeeMoreLink(section.titleId, this.context)
                      : this.getDetailsPageUrl(section.titleId)
                  }
                  seeMoreText={this.props.isMobile ? this.context.loc('PartnerDetail_More') : this.context.loc('Link_SeeAll')}
                >
                  {ribbonItems.map((item: any, index: number) => {
                    return (
                      <TileType
                        {...item}
                        item={item}
                        key={item.entityId || item.solutionId}
                        customCSSClass={'tile' + index}
                        tileIndex={index}
                        totalTiles={ribbonItems.length}
                        selectedLocale={selectedLocale}
                        embedHost={this.props.embedHost}
                        countryContext={selectedCountry}
                        ribbonKey={section.titleId}
                      />
                    );
                  })}
                </Ribbon>
              );
            })
          : null}
      </>
    );
  }
}

(CuratedGallery as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  buildHref: PropTypes.func,
  renderErrorModal: PropTypes.func,
};
