import React from 'react';
import * as PropTypes from 'prop-types';

import { IAppDataItem, IStartingPrice } from '@shared/Models';
import { Constants, OfferType } from '@shared/utils/constants';
import { isTransactApp, isAvailableInThisRegion } from '@shared/utils/appUtils';

export interface IBaseCtaButtonsProps {
  app: IAppDataItem;
  startingPrice: IStartingPrice;
  isLoadingUserData: boolean;
  billingCountryCode: string;
  ctaType: Constants.CTAType;
  CtaComponent: JSX.Element;
}

export const getDataBiView = ({ linkedAddIns, linkedSaaS }: IAppDataItem): string => {
  let dataBiView = '';
  if (linkedAddIns?.length > 0) {
    dataBiView = Constants.Telemetry.TemplateName.SaaSBundle;
  } else if (linkedSaaS) {
    dataBiView = Constants.Telemetry.AreaName.BundleAddIn;
  }
  return dataBiView;
};

export const getDataBiType = ({ app, billingCountryCode }: Partial<IBaseCtaButtonsProps>): string => {
  const { ctaTypes = [] } = app;
  return app.offerType === OfferType.SaaSApp
    ? isTransactApp({ isEmbedded: false, ctaTypes, appData: app, billingRegion: billingCountryCode })
      ? Constants.JsllSaas.SaasTransactable
      : Constants.JsllSaas.SaasNonTransactable
    : '';
};

export const BaseCtaButton: React.FunctionComponent<IBaseCtaButtonsProps> = ({
  app,
  startingPrice,
  isLoadingUserData,
  billingCountryCode,
  CtaComponent,
  ctaType,
}: IBaseCtaButtonsProps) => {
  const newProp = {
    disabled: !isAvailableInThisRegion({ startingPrice }) || isLoadingUserData,
    'data-bi-id': Constants.JsllCTAId[Constants.CTAType[`${ctaType}`]],
    'data-bi-name': app.entityId,
    'data-bi-type': getDataBiType({ app, billingCountryCode }),
    'data-bi-area': Constants.Telemetry.AreaName.AppDetails,
    'data-bi-view': getDataBiView(app),
  };

  const ClonedElementWithMoreProps = React.cloneElement(CtaComponent, { ...newProp });
  return ClonedElementWithMoreProps;
};

(BaseCtaButton as any).contextTypes = {
  locParams: PropTypes.func,
  loc: PropTypes.func,
};
