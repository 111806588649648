import React from 'react';
import { ILocContext, ILocParamsContext, ICommonContext, IBuildHrefContext } from '@shared/interfaces/context';
import { Stack, Text, IconButton, Modal, ScreenWidthMinXLarge, ScreenWidthMinLarge } from '@fluentui/react';
import type { IStackTokens, IButtonStyles, IIconProps, IModalStyles } from '@fluentui/react';

import PropTypes from 'prop-types';
import { NeutralColors } from '@fluentui/theme';
import { useSelector } from 'react-redux';
import { IState } from '@src/State';

interface IConsentModalContentProps {
  showAnimation: boolean;
  handoffTitle: string;
  isTransactApp: boolean;
  isContactForm: boolean;
  publisher: string;
  shouldSkipModal: boolean;
  dismissModal: () => void;
  children: React.ReactNode;
}

const modalContentItemsTokens: IStackTokens = { childrenGap: 20 };
const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: NeutralColors.gray160,
  },
};

const modalStyles: Partial<IModalStyles> = {
  main: {
    [`@media (min-width: ${ScreenWidthMinXLarge}px)`]: {
      width: '35%',
    },
    [`@media (min-width: ${ScreenWidthMinLarge}px) and (max-width: ${ScreenWidthMinXLarge}px)`]: {
      width: '60%',
    },
    [`@media (max-width: ${ScreenWidthMinLarge}px)`]: {
      width: '90%',
    },
    display: 'flex',
  },
  scrollableContent: {
    padding: '16px 27px 24px 24px',
    [`@media (max-width: ${ScreenWidthMinLarge}px)`]: {
      padding: '16px 20px 24px 20px',
    },
  },
};

export const ConsentModalContent: React.FunctionComponent<IConsentModalContentProps> = (
  {
    showAnimation,
    handoffTitle,
    isTransactApp,
    isContactForm,
    publisher,
    shouldSkipModal,
    dismissModal,
    children,
  }: IConsentModalContentProps,
  context: IBuildHrefContext & ICommonContext & ILocContext & ILocParamsContext
) => {
  const isEmbedded = useSelector<IState, boolean>((state) => state.config.isEmbedded);
  const withAnimationHeaderText = isContactForm
    ? context.locParams(
        'TRY_ContactMe',
        [publisher || 'the publisher'],
        `Sending your request to ${[publisher || 'the publisher']}`
      )
    : context.locParams(
        'TRY_Redirect',
        [handoffTitle || 'Azure Portal'],
        `Taking you to ${[handoffTitle || 'Azure Portal']} to complete this process`
      );

  const headerText =
    isEmbedded && !isContactForm
      ? context.loc('Confirm_to_continue_Modal_Title', 'Confirm to continue')
      : !isTransactApp && !isContactForm
      ? context.loc('Confirm_Details_Modal_Title', 'Confirm your details to continue')
      : isContactForm
      ? context.locParams('Contact_Me_Modal_Title', [publisher], `Ask someone from ${publisher} to contact you`)
      : null;

  return (
    <Modal isOpen={!shouldSkipModal} onDismiss={dismissModal} styles={modalStyles}>
      <Stack tokens={modalContentItemsTokens}>
        <Stack.Item>
          <Stack horizontal>
            <Stack.Item grow>
              {showAnimation ? (
                <Text variant="xLarge">{withAnimationHeaderText}</Text>
              ) : (
                <Text variant="xLarge">{headerText}</Text>
              )}
            </Stack.Item>
            <Stack.Item>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel={context.loc('Dialog_Close', 'Close')}
                onClick={dismissModal}
              />
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>{children}</Stack.Item>
      </Stack>
    </Modal>
  );
};

(ConsentModalContent as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  renderErrorModal: PropTypes.func,
  buildHref: PropTypes.func,
};
