import * as React from 'react';
import SpzaComponent from '@shared/components/spzaComponent';
import { IDataItem } from '@shared/Models';
import Ribbon from '@shared/components/ribbon';
import CommonTile from './commonTile';

export interface IPopularApps {
  popularAppsTitle: string;
  ribbonPopularApps: IDataItem[];
  ribbonKey: string;
}

export class PopularApps extends SpzaComponent<IPopularApps, any> {
  renderImpl() {
    const { popularAppsTitle, ribbonPopularApps, ribbonKey } = this.props;
    return (
      <div className="indFeaturedContainer">
        <Ribbon title={popularAppsTitle}>
          {ribbonPopularApps.map((offer: IDataItem, index: number) => (
            <CommonTile
              {...offer}
              item={offer}
              tileIndex={index}
              totalTiles={popularAppsTitle.length}
              key={index}
              ribbonKey={ribbonKey}
            />
          ))}
        </Ribbon>
      </div>
    );
  }
}
