import type { Store } from 'redux';
import { Request } from 'express';
import { parse } from 'stack-trace';
import isEmail from 'validator/lib/isEmail';

import { getDataValuesFromUrlKey } from '@shared/utils/dataMappingUtils';
import { optimizeQueryParameters } from '@shared/utils/optimizeUrlHelper';
import { Constants, OfferType, PlanPricingType } from '@shared/utils/constants';
import {
  IAppDataItem,
  IAppDataItemBasicData,
  ILicense,
  IStartingPrice,
  ITelemetryConsentDialogInfo,
  ITelemetryData,
  IURLQuery,
  PricingStates,
  UserSegment,
  OdataRawObjectAppType,
  ISearchFilter,
  ISearchFilterCategory,
  ITrailData,
  ISpzaUserIdAndModifier,
  IUserLeadProfileAgreement,
  IDataItem,
  ISimpleSKU,
  TermsStrings,
  ICreauteUiDefinition,
  ISKU,
  AppTag,
  IBitmask,
  Badges,
} from '@shared/Models';
import { getWindow } from '@shared/services/window';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { SharedInstrumentService } from '@shared/services/telemetry/shared/sharedInstrument';
import { DataMap, IDataCollection, IDataValues, IProductValue } from '@shared/utils/dataMapping';
import { getAppConfig } from '@shared/services/init/appConfig';
import { Url } from '@shared/services/http/urlUtils';
import * as embedHostUtils from '@embed/embedHostUtils';
import { createAppViewTelemetryLoggedAction, createFeatureFlagsReceivedAction } from '@shared/actions/actions';
import { IFeatureFlags, IUserDataState, IState } from '@src/State';
import { extractCampaignsFromUrl, getEntityIdFromUrl, updateHandOffUrlWithCampaignParams } from '@shared/utils/routeUtils';
import {
  getLocalStorageItem,
  getSessionStorageItem,
  hasUserAgreedPrivacyConsent,
  saveLocalStorageItem,
  saveSessionStorageItem,
} from '@shared/utils/browserStorageUtils';
import { isArray, isNullorUndefined } from '@shared/utils/objectUtils';
import { Service } from '@shared/serviceViewModel';
import { buildHref, routes } from '@shared/routerHistory';
import { getQueryFromBrowserUrl } from '@shared/utils/urlUtils';
import { getTrimmedWords, isEmptyNullorUndefinedString } from '@shared/utils/stringUtils';
import {
  getPrimaryProductUrl,
  bitMasksContainSomeBitMask,
  bitMasksMatch,
  bitMasksMatchFilter,
  bitMasksMatchBitMask,
  productKeyByUrlKey,
  productKeyByBackendKey,
  getShortCutBitmask,
} from '@shared/utils/datamappingHelpers';
import { loadPartnerManifest } from '@shared/utils/partnerManifestLoader';
import { logger } from '@src/logger';
import { isString } from 'lodash-es';
import { useTelemetry } from '@shared/hooks/useTelemetry';
import sha256 from 'crypto-js/sha256';
import { BusinessTelemetry } from '@shared/utils/businessConstants';
import { isAppContactForm } from '@shared/utils/modals';
import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line react-hooks/rules-of-hooks
const [{ flushTelemetryBuffer }] = useTelemetry();

declare let __COMMIT_HASH__: string;

export function generateGuid() {
  return uuidv4(); // random string, no time sequenced.
}

export function attachClickHandlerToElement(id: string, handler: any) {
  const el = document.getElementById(id);
  if (el) {
    el.onclick = handler;
  }
}

export const getProductByUrlKey = ({ urlKey }: { urlKey: string }) => {
  const productKey = productKeyByUrlKey[`${urlKey}`];
  return DataMap.products[`${productKey}`];
};

export const getProductByBackendKey = ({ backendKey }: { backendKey: string }) => {
  const productKey = productKeyByBackendKey[`${backendKey}`];
  return DataMap.products[`${productKey}`];
};

export function categoryInfoToBitmask(categoryInfo?: IDataValues): IBitmask {
  const { FilterGroup, FilterID } = categoryInfo || {};
  return categoryInfo ? { property: FilterGroup, mask: FilterID } : null;
}

function getCategoryBitmaskInfo({
  collection,
  dataValueKey,
  subCollection,
  dataValueChildKey,
}: {
  collection: Constants.DataMapCollectionKeys;
  dataValueKey: string;
  subCollection: string;
  dataValueChildKey: string;
}): IBitmask {
  const categoryInfo =
    DataMap[`${collection}`][`${subCollection}`][`${dataValueKey}`]?.SubCategoryDataMapping?.[`${dataValueChildKey}`];
  return categoryInfoToBitmask(categoryInfo);
}

function getGeneralBitmaskInfo({
  collection,
  dataValueKey,
}: {
  collection: Constants.DataMapCollectionKeys;
  dataValueKey: string;
}): IBitmask {
  const categoryInfo = DataMap[`${collection}`][`${dataValueKey}`] as IDataValues;
  return categoryInfoToBitmask(categoryInfo);
}

function getIndustriesBitmaskInfo({
  collection,
  dataValueKey,
  dataValueChildKey,
}: {
  collection: Constants.DataMapCollectionKeys;
  dataValueKey: string;
  dataValueChildKey: string;
}): IBitmask {
  const categoryInfo = DataMap[`${collection}`][`${dataValueKey}`]?.SubCategoryDataMapping?.[`${dataValueChildKey}`];
  return categoryInfoToBitmask(categoryInfo);
}

const bitmaskInfoType: {
  [collection in Constants.DataMapCollectionKeys]: (params: {
    collection: Constants.DataMapCollectionKeys;
    dataValueKey: string;
    subCollection?: string;
    dataValueChildKey?: string;
  }) => IBitmask;
} = {
  [Constants.DataMapCollectionKeys.categories]: getCategoryBitmaskInfo,
  [Constants.DataMapCollectionKeys.products]: getGeneralBitmaskInfo,
  [Constants.DataMapCollectionKeys.industries]: getIndustriesBitmaskInfo,
  [Constants.DataMapCollectionKeys.cloudIndustries]: getIndustriesBitmaskInfo,
  [Constants.DataMapCollectionKeys.serviceTypes]: getGeneralBitmaskInfo,
  [Constants.DataMapCollectionKeys.trials]: getGeneralBitmaskInfo,
  [Constants.DataMapCollectionKeys.pricingModel]: getGeneralBitmaskInfo,
  [Constants.DataMapCollectionKeys.ratings]: getGeneralBitmaskInfo,
  [Constants.DataMapCollectionKeys.appCompliance]: getGeneralBitmaskInfo,
  [Constants.DataMapCollectionKeys.azureBenefitEligible]: getGeneralBitmaskInfo,
  [Constants.DataMapCollectionKeys.copilotExtension]: getGeneralBitmaskInfo,
  [Constants.DataMapCollectionKeys.productType]: getGeneralBitmaskInfo,
};

export const getBitMaskInfo = ({
  collection,
  dataValueKey,
  subCollection,
  dataValueChildKey,
}: {
  collection: Constants.DataMapCollectionKeys;
  dataValueKey: string;
  subCollection?: string;
  dataValueChildKey?: string;
}) => {
  const bitmaskType = bitmaskInfoType[`${collection}`];
  return bitmaskType({ collection, dataValueKey, subCollection, dataValueChildKey });
};

export const getProdcutBitMaskInfo = (categoryInfo: IDataValues) => {
  const { FilterGroup, FilterID } = categoryInfo || {};
  return categoryInfo ? { property: FilterGroup, mask: FilterID } : null;
};

export const isEmbedded = () => {
  const AppsourceMode: string = getWindow().AppsourceMode;
  return AppsourceMode === 'Embed';
};
// Does a reverse look up on the data map from the product display name to get the product key
// Reverse lookup as dont want to create a new map to maintain
export function getProductLongTitleFromDisplayName(displayName: string): string {
  for (const item in DataMap.products) {
    // When you iterate over an Enum it gives you both the indexes and the string values
    // The regex makes sure you dont compare the indexes.
    // eslint-disable-next-line no-prototype-builtins
    if (DataMap.products.hasOwnProperty(item) && !/^\d+$/.test(item)) {
      if (displayName === DataMap.products[`${item}`].BackendKey) {
        return DataMap.products[`${item}`].LongTitle;
      }
    }
  }
  // Came here means none the expected products matched,
  // Some one handcrafted the Url with a non existant prod.
  // return an empty string as you cannot show details anyways
  return displayName;
}

// format the string which has {0}, {1} arguments
export function getFormattedString(baseString: string, args: string[]) {
  for (let i = 0; i < args.length; i++) {
    // eslint-disable-next-line security/detect-non-literal-regexp
    const regEx = new RegExp('\\{' + i + '\\}', 'gm');
    baseString = baseString.replace(regEx, args[`${i}`]);
  }

  return baseString;
}

export function getTelemetryResponseUrl(appId: string) {
  const query = {};
  query[Constants.QueryStrings.ApplicationId] = appId;
  query[Constants.QueryStrings.CorrelationId] = getAppConfig('correlationId');

  const url = new Url('/api/notifyresult', query);

  return url.getUrl();
}

export function getPaymentRedirectUrl(entityId: string, referredBy: string) {
  const url = new Url(buildHref(routes.checkout, null, { entityId }, null, null), { referredBy });

  return url.getUrl();
}

function bitmaskMatch(product: string, bitmaskValue: IProductValue, products?: IProductValue) {
  let match = false;

  if (products) {
    match = bitMasksContainSomeBitMask(bitmaskValue, products);
  } else {
    const productInfo = getProductByUrlKey({ urlKey: product });
    if (product && productInfo) {
      match = bitMasksMatchFilter(bitmaskValue, productInfo);
    }
  }
  return match;
}

// This API returns if a product belongs to office Add-ins
// ShortcutBitmask contains the bitmask value of all the office child products.
// DataMap.products['office365'].ShortcutBitmask = 8355840 which is equal to 'OR' of all the child product filterIds
// we are sending office365 as the primary product for both Saas apps and Saas add-ins. So, added the products param as well
export function isOfficeNonSaasApp(product: string, products?: IProductValue) {
  const productMask: IProductValue = {
    [DataMap.products.AzureforWebApps.FilterGroup]: DataMap.products.AzureforWebApps.FilterID,
  };
  return (
    bitmaskMatch(product, getShortCutBitmask(DataMap.products.Office365), products) &&
    !bitmaskMatch(product, productMask, products)
  );
}

// returns the title of the parent product if product belongs to parent product
// checks whether product belongs to parent Product
export function getParentProductTitle(product: string, parentProduct?: string) {
  const productInfo = getProductByUrlKey({ urlKey: parentProduct });
  if (bitmaskMatch(product, getShortCutBitmask(productInfo))) {
    return productInfo.LongTitle;
  }
}

// This API returns if a product belongs to office Saas apps
// we are sending office365 as the primary product for both Saas apps and Saas add-ins. So, added the products param as well
export function isOfficeSaasApp(product: string, products: IProductValue) {
  const productOfficeMask: IProductValue = {
    [DataMap.products.Office365.FilterGroup]: DataMap.products.Office365.FilterID,
  };
  const productWebAppsMask: IProductValue = {
    [DataMap.products.AzureforWebApps.FilterGroup]: DataMap.products.AzureforWebApps.FilterID,
  };
  return bitmaskMatch(product, productOfficeMask, products) && bitmaskMatch(product, productWebAppsMask, products);
}

// This API returns if a product belongs to office category (Add-ins + Saas apps)
// we are sending office365 as the primary product for both Saas apps and Saas add-ins. So, added the products param as well
export function isOfficeApp(product: string, products?: IProductValue) {
  const bitmaskVal = getShortCutBitmask(DataMap.products.Office365);
  return bitmaskMatch(product, bitmaskVal, products);
}

export function isWebApp(product: string, products?: IProductValue) {
  const bitmaskVal = getShortCutBitmask(DataMap.products.AzureforWebApps);
  return bitmaskMatch(product, bitmaskVal, products);
}

export function isD365App(product: string, products?: IProductValue) {
  const bitmaskVal = getShortCutBitmask(DataMap.products.Dynamics365);
  return bitmaskMatch(product, bitmaskVal, products);
}

export function isD365BCApp(product: string, products?: IProductValue) {
  const bitmaskVal = getShortCutBitmask(DataMap.products.Dynamics365forBusinessCentral);
  return bitmaskMatch(product, bitmaskVal, products);
}

export function isPowerBIVisuals(product: string, products?: IProductValue) {
  const bitmaskVal = getShortCutBitmask(DataMap.products.PowerBICustomVisual);
  return bitmaskMatch(product, bitmaskVal, products);
}

export function isPowerBI(product: string, products?: IProductValue) {
  const bitmaskVal = getShortCutBitmask(DataMap.products.PowerBI);
  return bitmaskMatch(product, bitmaskVal, products);
}

/** This is a temporary function.
 * AppSource has to support offers from Office and from BigCat during the migration.
 * Once all offers have been migrated, this function and all references to it can be deleted. */
export function isMigratedPowerBIVisual(appInfo: IAppDataItem) {
  return appInfo && appInfo.downloadLink && appInfo.downloadSampleLink && appInfo.bigId;
}

export function shouldDownloadPowerBIVisual(appInfo: IAppDataItem, isEmbedded: boolean) {
  return (
    appInfo && isPowerBIVisuals(getPrimaryProductUrl(appInfo.primaryProduct)) && isMigratedPowerBIVisual(appInfo) && !isEmbedded
  );
}

/** Current transactable apps are SaaS, Dynamics 365 CE, Dynamics 365 BC, and Power BI Visuals */
export function isTransactableOfferType(appData: IAppDataItem) {
  if (!appData) {
    return false;
  }

  const isSaaSApp = bitmaskMatch('azure', appData.products) && bitmaskMatch('web-apps', appData.primaryProduct);
  return (
    isSaaSApp ||
    isD365App(getPrimaryProductUrl(appData.primaryProduct)) ||
    isD365BCApp(getPrimaryProductUrl(appData.primaryProduct)) ||
    isPowerBIVisuals(getPrimaryProductUrl(appData.primaryProduct))
  );
}

export function isLTS(app: IAppDataItem) {
  return (app.pricingInformation?.skus as ISimpleSKU[])?.find((sku) => {
    return sku.termPrices?.find(
      (termPrice) =>
        termPrice.unit === TermsStrings.Term2YString || termPrice.unit === TermsStrings.Term3YString || termPrice.billingPlan
    );
  });
}

export function isTransactApp({
  isEmbedded,
  ctaTypes,
  appData,
  billingRegion,
}: {
  isEmbedded: boolean;
  ctaTypes: Constants.CTAType[];
  appData: IAppDataItem;
  billingRegion: string;
}) {
  return (
    !isEmbedded &&
    appData &&
    ctaTypes?.some((ctaType) => [Constants.CTAType.Purchase, Constants.CTAType.BuyLicense].includes(ctaType)) &&
    billingRegion &&
    isTransactableOfferType(appData)
  );
}

/** An offer is considered BAG transactable if it:
 *  - Is of offer type Dynamics 365 CE, Dynamics 365 BC or Power BI Visuals
 *  - Has prices associated with the offer
 */
export function isBagTransactable(appData: IAppDataItem) {
  const primaryProductUrl = getPrimaryProductUrl(appData?.primaryProduct);

  const isBagEnabledD365BC = isD365BCApp(primaryProductUrl);

  const isBagEnabledD365 = isD365App(primaryProductUrl) && primaryProductUrl !== 'dynamics-365-business-central';
  const isBagEnabledPowerBIVisual = isPowerBIVisuals(primaryProductUrl);

  const isBagOfferType = isBagEnabledD365 || isBagEnabledD365BC || isBagEnabledPowerBIVisual;
  return isBagOfferType && appData.hasPrices;
}

export function getTransactCTATypes(
  targetApp: IAppDataItem,
  uiRole: Constants.UiRole,
  doesTenantHasLicenseForApp: boolean,
  isEmbedded: boolean,
  billingRegion: string
) {
  const ctaTypes: Constants.CTAType[] = [];
  const isAdmin = uiRole === Constants.UiRole.Admin;

  if (isBagTransactable(targetApp)) {
    /* Only administrator can install D365CE apps for users in tenant.
      Administrator and Non-Administrator can install D365BC apps for users in tenant if it has licenses.
      Any user can install Power BI Visuals, since they are all Freemium.
      If the offer is Freemium, Install must be the primary action.
    */
    const showInstallFirst =
      (isD365App(getPrimaryProductUrl(targetApp?.primaryProduct)) && isAdmin && targetApp?.licenseManagement?.isFreemium) ||
      isPowerBIVisuals(getPrimaryProductUrl(targetApp?.primaryProduct));

    const isD365BC = isD365BCApp(getPrimaryProductUrl(targetApp?.primaryProduct));

    if (!isD365BC && showInstallFirst) {
      ctaTypes.push(Constants.CTAType.Install);
      ctaTypes.push(Constants.CTAType.BuyLicense);
    } else if ((!isD365BC && isAdmin && doesTenantHasLicenseForApp) || (isD365BC && doesTenantHasLicenseForApp)) {
      ctaTypes.push(Constants.CTAType.BuyLicense);
      ctaTypes.push(Constants.CTAType.Install);
    } else {
      ctaTypes.push(Constants.CTAType.BuyLicense);
    }
  } else if (isPowerBIVisuals(getPrimaryProductUrl(targetApp?.primaryProduct))) {
    ctaTypes.push(Constants.CTAType.Get);
  } else if (targetApp?.licenseManagement?.isMicrosoftManaged) {
    const showGetCta = targetApp.licenseManagement.isFreemium || doesTenantHasLicenseForApp;
    if (isAdmin && showGetCta) {
      ctaTypes.push(Constants.CTAType.Get);
      ctaTypes.push(Constants.CTAType.RequestTrial);
    } else {
      ctaTypes.push(Constants.CTAType.RequestTrial);
    }
  }

  const isTransactableD365App =
    isD365App(getPrimaryProductUrl(targetApp?.primaryProduct)) &&
    isTransactApp({ isEmbedded, ctaTypes: targetApp.ctaTypes, appData: targetApp, billingRegion });

  if (isTransactableD365App) {
    ctaTypes.push(Constants.CTAType.RequestTrial);
  }

  return ctaTypes.length ? ctaTypes : null;
}

export function getCtaBagTransactable(uiRole: Constants.UiRole, doesTenantHasLicenseForApp: boolean, isFreemium: boolean) {
  const isAdmin = uiRole === Constants.UiRole.Admin;
  const {
    CTAType: { Install, BuyLicense },
  } = Constants;

  if (isAdmin && (doesTenantHasLicenseForApp || isFreemium)) {
    return Install;
  } else {
    return BuyLicense;
  }
}

export function isSharepointApp(products: IProductValue) {
  return bitMasksMatchBitMask(products, getProdcutBitMaskInfo(DataMap.products.SharePoint));
}

export function isLeadGenEnabled(appData: IAppDataItem) {
  return appData.leadgenEnabled !== undefined ? appData.leadgenEnabled : null;
}

function getOfficeHandoffParams(appId: string, correlationId: string, locale: string) {
  const urlArgs: string[] = [];
  // These values for auth type are given by Office Store team
  const storeConfig = getAppConfig('config');

  // default values
  let ui = 'en-us';

  // 'locale' also denote the content market applicable for the app.
  // This can be removed once we build support for localization.
  let contentMarket = locale || 'en-us';

  if (storeConfig?.locale) {
    contentMarket = ui = storeConfig.locale;
  }

  // This is the URL which we are trying to fill the parameters for: https://pages.store.office.com/addininstaller.aspx?assetid={0}&ui={1}&rs={2}&sourcecorrid={3}
  urlArgs.push(appId);
  urlArgs.push(ui); // ui => UI language
  urlArgs.push(contentMarket); // rs => Content Market
  urlArgs.push(correlationId);

  return urlArgs;
}

export function getHandoffUrlForProduct(productUrlKey: string, appId: string, products: IProductValue, locale: string) {
  const config = loadPartnerManifest();
  const handOffUrl: string = config.handoff[`${productUrlKey}`];

  // For office Saas apps, we use the default handoff url which we receive from the backend
  // For Power BI Visuals, we no longer route externally to Office
  if (!handOffUrl || isOfficeSaasApp(productUrlKey, products)) {
    return null;
  }

  const correlationId: string = getAppConfig('correlationId');
  let urlArgs: string[] = [];

  if (isOfficeApp(productUrlKey)) {
    urlArgs = getOfficeHandoffParams(appId, correlationId, locale);
  } else {
    urlArgs = [encodeURIComponent(appId), encodeURIComponent(getTelemetryResponseUrl(appId)), correlationId];
  }

  const handOffURL = getFormattedString(handOffUrl, urlArgs);

  return handOffURL;
}

export function processHandoffURLAndTitle(appData?: IAppDataItem): { url?: string; handoffTitle?: string } {
  if (!appData) {
    return { url: null, handoffTitle: null };
  }
  const { primaryProduct, entityId, products, locale, title, handoffURL } = appData;
  // We get the Handoff URL from the Partners Manifest file. If the url is null,
  // we need to read the url from the app meta data.
  let handoffTitle;
  let url = getHandoffUrlForProduct(getPrimaryProductUrl(primaryProduct), entityId, products, locale);
  if (!url) {
    if (handoffURL) {
      url = handoffURL;
      const notifyURL = getTelemetryResponseUrl(entityId);
      const queryParams = url.indexOf('?') > -1 ? '&responseUrl=' + notifyURL : '?responseUrl=' + notifyURL;
      url = url + queryParams;

      // Taking you to Azure/Office doesn't make sense for ISV SaaS apps during transition animation popup.
      // So, for only this case, we update the builtFor field.
      // Also, there is a special case for Power BI. For all the content packs, we use the title as Power BI
      const { property, mask } = getProdcutBitMaskInfo(DataMap.products.PowerBI);
      if (!bitMasksMatch(primaryProduct, property, mask)) {
        handoffTitle = title;
      }
    }
  }

  url = updateHandOffUrlWithCampaignParams(url, getWindow()?.location?.href);

  return { url, handoffTitle };
}

export function isPrivateOffer(appType: OdataRawObjectAppType) {
  if (appType === OdataRawObjectAppType.private) {
    return true;
  }
  return false;
}

export function isOpenInNewWindowButton({
  isEmbedded,
  ctaType,
  appData,
  billingRegion,
  url,
}: {
  isEmbedded: boolean;
  ctaType: Constants.CTAType;
  appData: IAppDataItem;
  billingRegion: string;
  url?: string;
}) {
  const isTransactable =
    isTransactApp({ isEmbedded, ctaTypes: [ctaType], appData, billingRegion }) || isPrivateOffer(appData?.appType);
  return (
    // Product decision that for test drive we always show the "open in new tab" icon.
    // We couldn't know if the test drive will send the user outside before the button is created
    ctaType === Constants.CTAType.TestDrive ||
    (!!url &&
      !isAppContactForm(appData, ctaType) &&
      !isTransactable &&
      !shouldDownloadPowerBIVisual(appData, isEmbedded) &&
      !isEmbedded)
  );
}

export function getDataMapValues(collection: IDataCollection, bitmask: IProductValue) {
  return Object.keys(collection).reduce((acc, value) => {
    if (bitMasksMatchFilter(bitmask, collection[`${value}`])) {
      acc.push(collection[`${value}`]);
    }
    return acc;
  }, []);
}

export function getProductFieldFromDataMap(collection: IDataCollection, bitmask: IProductValue, field: string) {
  const dataMapValues = getDataMapValues(collection, bitmask);
  return dataMapValues ? dataMapValues[0][`${field}`] : '';
}

export function getActionStringForCTAType(ctaType: Constants.CTAType): string {
  // Falling back to Get if we ctaType does not match.
  return Constants.ActionStrings[Constants.CTAType[`${ctaType}`]];
}

// Utility function to determine if an email has 'onmicrosoft.com' or 'ccsctp.net' [email sufixes for PROD/DF and INT respectively]
export function isOnMicrosoftEmail(email: string) {
  return !!(
    email &&
    (email.substr(-'onmicrosoft.com'.length) === 'onmicrosoft.com' || email.substr(-'ccsctp.net'.length) === 'ccsctp.net')
  );
}

export function getLeadGenEmailId(userInfo: any): string {
  return userInfo.email &&
    userInfo.alternateEmail &&
    isOnMicrosoftEmail(userInfo.email) &&
    userInfo.alternateEmail !== 'undefined'
    ? userInfo.alternateEmail
    : userInfo.email;
}

const urlList = [
  'https://powerbi-df.analysis-df.windows.net', // PowerBI Dogfood
  'https://dxt.powerbi.com', // PowerBI DXT
  'https://msit.powerbi.com', // PowerBI MSIT
  'https://app.powerbi.com', // PowerBI PROD
  'https://app.powerbi.cn',
  'https://app.powerbigov.us',
  'https://app.powerbi.de',
  'https://app.analysis.windows-int.net', // PowerBI Dev env
  'https://home.dynamics.com', // D365 PROD?
  'https://tip1.home.dynamics.com', // D365 Test?
  'https://tip1.web.powerapps.com', // D365 production
  'https://powerapps.cloudapp.net', // PowerApps dev env
  'https://portal.analysis.windows-int.net', // Appsource Basil Environment?
  'https://appgallery.spza-internal.net', // Appsource staging
  'https://appgallery.spza-staging.net', // Appsource Dogfood
  'http://localhost:3000', // Appsource Dev env
  'https://service.powerapps.com', // PowerApps
  'https://dxtcurrent.powerbi.com', // PowerBI DXT current
  'https://dxtprevious.powerbi.com', // PowerBI DXT previous
  'https://dxtnext.powerbi.com', // PowerBI DXT next
  'https://cosell.dev.microsoft.com', // MSX dev
  'https://cosell.ppe.microsoft.com', // MSX ppe
  'https://cosell.microsoft.com', // MSX prod
  'https://partnersalespreview.microsoft.com',
  'https://partnersales.microsoft.com',
];

const httpsSubString = 'https://';

export function checkOriginSource(endpoint: string) {
  if (!endpoint) {
    return false;
  }

  let origin = endpoint;
  let originStartIndex = 0;
  if (origin.indexOf(httpsSubString) === 0) {
    originStartIndex = httpsSubString.length;
  }
  const firstSlashIndex = origin.indexOf('/', originStartIndex);
  if (firstSlashIndex >= 0) {
    origin = origin.substr(0, firstSlashIndex);
  }

  for (let i = 0; i < urlList.length; i++) {
    if (origin.toLocaleLowerCase() === urlList[`${i}`]) {
      return true;
    }
  }

  if (
    /^https:\/\/.*analysis.windows(-int)?.net/.test(origin) || // PowerBI (daily) env
    /^https:\/\/.*.dynamics.com/.test(origin) || // Dynamics CRM
    /^https:\/\/.*.dynamics.de/.test(origin) || // Dinamics CRM
    /^https:\/\/.*.financials.dynamics-tie.com/.test(origin) ||
    /^https:\/\/.*.financials.dynamics-ppe.com/.test(origin) ||
    /^https:\/\/.*.projectmadeira.com/.test(origin) || // Madeira PROD
    /^https:\/\/.*.projectmadeira-ppe.com/.test(origin) || // Madeira INT
    /^https:\/\/.*.projectmadeira-test.com/.test(origin)
  ) {
    // Madeira test
    return true;
  } else {
    return false;
  }
}

export function removeURLParameter(url: string, parameter: string): string {
  // Split the url into 2 parts. The right side of the '?' has all the query parameters.
  const urlparts = url.split('?');

  if (urlparts.length >= 2) {
    const prefix = encodeURIComponent(parameter) + '=';
    // We use either '&' or ';' as the parameter seperaters. Use the second part of the urlParts which has queryParams
    const queryParams = urlparts[1].split(/[&;]/g);

    for (let i = queryParams.length; i-- > 0; ) {
      // Search the string for the last occurance of the prefix : Equivalent of string starts with
      if (queryParams[`${i}`].lastIndexOf(prefix, 0) !== -1) {
        queryParams.splice(i, 1); // Removes that element from the queryParams array
      }
    }

    const newUrl = urlparts[0] + (queryParams.length > 0 ? '?' + queryParams.join('&') : '');
    return newUrl;
  } else {
    return url;
  }
}

// Telemetry Helper Functions
export function getDataMapString(collection: IDataCollection, bitmask: IProductValue, field: string) {
  const dataMapValues = getDataMapValues(collection, bitmask);
  const result = dataMapValues
    .map((value) => {
      return value[`${field}`];
    })
    .join(', ');

  return result;
}

export function getProductStringArray(productBitmask: IProductValue) {
  const productUrlKeyString = getDataMapString(DataMap.products, productBitmask, 'UrlKey');
  return getTrimmedWords(productUrlKeyString, ',');
}

/**
 * get consent dialog info for telemetry detail content
 *
 * @param hasCheckBox consent Dialog has consent checkbox: e.x. By click this ...
 * @param hasUserProfile consent Dialog has user profile: e.x. name, email, phone, job, company, ...
 *
 * @return
 *     an object which has following properties
 *         hasPhone: boolean determine whether dialog has phone or not
 *         hasCheckbox: boolean determine whether dialog has checkbox or not
 */
export function getTelemetryDetailContentConsentDialogInfo(
  hasCheckbox = false,
  hasUserProfile = false
): ITelemetryConsentDialogInfo {
  const consentDialogInfo = {
    hasContactInfo: isNullorUndefined(hasUserProfile) ? undefined : hasUserProfile,
    hasCheckbox: isNullorUndefined(hasCheckbox) ? undefined : hasCheckbox,
  };

  return consentDialogInfo;
}

function getTag(keys: string[]): number[] {
  return keys.map((key) => AppTag[`${key}`]);
}

export function isCertifiedSoftware(app: IAppDataItem): boolean {
  return !!app?.badges?.includes(Badges.MAICPPCertifiedSolution);
}

export function getBadgeTypes(appData: IAppDataItem) {
  const {
    CertificationState = Constants.CertificationType.None,
    isSolutionMap = false,
    tags = [],
    entityId,
    appType = OdataRawObjectAppType.public,
    AzureBenefitEligible = 0,
  } = appData;

  const isPrivate = isPrivateOffer(appType);
  const m365Certified = CertificationState === Constants.CertificationType.MicrosoftCertified;
  const officeAppAwards =
    (tags?.length && getTag(tags)[0] === AppTag.OfficeAward) ||
    entityId?.toUpperCase() === Constants.HyperfishEntityId.toUpperCase();
  const pbi = !!tags?.length && getTag(tags)[0] === AppTag.PowerBICertified;
  const azureExpertMsp = tags?.includes(Constants.BadgeType.AzureExpertsMSP);
  return {
    m365Certified,
    preferredSolution: isSolutionMap,
    officeAppAwards,
    pbi,
    azureExpertMsp,
    isPrivate,
    AzureBenefitEligible,
    isCertifiedSoftware: isCertifiedSoftware(appData),
  };
}

export interface IAppTelemetryPayload {
  appInfo: IAppDataItem;
  isNationalCloud: boolean;
  isEmbedded?: boolean;
  ctaTypes?: Constants.CTAType[];
  consentDialogHasUserProfile?: boolean;
  consentDialogHasCheckbox?: boolean;
  currentView?: string;
  tileDataRequestId?: string;
  uiRole?: Constants.UiRole;
  hasLicense?: boolean;
  shouldShowUserTerms?: boolean;
  planId?: string;
  ribbonKey?: string;
  trailId: string;
}

/**
 * get application telemetry detail content as string
 *
 * @param appInfo: application information
 * @param isNationalCloud: detetmine this application is Microsoft National Clouds: e.g. Azure, Dynamics 365, and Office 365
 * @param ctaTypes: click to action types
 * @param consentDialogHasUserProfile: if current view is a consent modal dialog, determine it has user contact information
 * @param consentDialogHasCheckbox: if current view is a consent modal dialog, determine it has checkbox
 *
 * @return:
 *  a string serialized for telemetry property details
 */
export function getAppTelemetryDetailContentText({
  appInfo,
  isNationalCloud,
  isEmbedded,
  ctaTypes,
  consentDialogHasUserProfile,
  consentDialogHasCheckbox,
  currentView,
  tileDataRequestId,
  uiRole,
  hasLicense,
  shouldShowUserTerms,
  planId,
  ribbonKey,
  trailId,
}: IAppTelemetryPayload): string {
  if (!appInfo) {
    return '';
  }

  const {
    privateApp,
    leadgenEnabled: leadGenEnabled,
    title,
    builtFor,
    actionString,
    licenseManagement,
    entityId,
    offerType,
    startingPrice,
    products,
    publisher,
    publisherId,
  } = appInfo;

  // data map, e.g. filter, property 'Title'
  const dataMapField = 'Title';

  const appPublisher: string = publisher && isNationalCloud ? sha256(publisher).toString() : publisher;
  const appPublisherId: string = publisherId && isNationalCloud ? sha256(publisherId).toString() : publisherId;

  const ctaTypeString: string =
    (isArray(ctaTypes) && ctaTypes.map((ctaType: Constants.CTAType) => Constants.CTAType[`${ctaType}`]).join(', ')) || undefined;

  const badgeTypes = getBadgeTypes(appInfo);

  const productAvailabilityType = privateApp
    ? BusinessTelemetry.ProductAvailabilityType.Private
    : BusinessTelemetry.ProductAvailabilityType.Public;
  const isSaasTransactApp =
    offerType === OfferType.SaaSApp ? (startingPrice && typeof startingPrice.pricingData === 'object' ? 'true' : 'false') : '';
  const isTransactable = startingPrice && typeof startingPrice.pricingData === 'object' ? 'true' : 'false';

  const appTelemetryDetailContent = {
    title,
    publisher: appPublisher,
    builtFor,
    actionString,
    products: getDataMapString(DataMap.products, products, dataMapField),
    leadGenEnabled,
    ctaType: ctaTypeString,
    type: productAvailabilityType,
    currentView,
    tileDataRequestId: tileDataRequestId || '',
    isSaasTransactApp,
    isEmbedded,
    isTransactable,
    isMicrosoftManaged: licenseManagement?.isMicrosoftManaged,
    isFreemium: licenseManagement?.isFreemium,
    uiRole,
    hasLicense,
    showTerms: shouldShowUserTerms,
    badgeTypes,
    publisherId: appPublisherId,
    entityId,
    planId,
    ribbonKey,
    trailId,
  };

  // if any of consentDialogHasUserProfile and consentDialogHasCheckbox has value
  // add consent dialog info into telemetry detail content
  if (!isNullorUndefined(consentDialogHasUserProfile) || !isNullorUndefined(consentDialogHasCheckbox)) {
    const consentDialogInfoKey = 'consentDialogInfo';

    appTelemetryDetailContent[`${consentDialogInfoKey}`] = getTelemetryDetailContentConsentDialogInfo(
      consentDialogHasCheckbox,
      consentDialogHasUserProfile
    );
  }

  return JSON.stringify(appTelemetryDetailContent);
}

export interface IServiceTelemetryPayload {
  serviceInfo: Service;
  isNationalCloud: boolean;
  ctaTypes?: Constants.CTAType[];
  consentDialogHasUserProfile?: boolean;
  consentDialogHasCheckbox?: boolean;
  currentView?: string;
  ribbonKey?: string;
  trailId: string;
}

/**
 * get consulting service telemetry detail content as string
 *
 * @param serviceInfo: consulting service information
 * @param isNationalCloud: detetmine this application is Microsoft National Clouds: e.g. Azure, Dynamics 365, and Office 365
 * @param ctaTypes: click to action types
 * @param consentDialogHasUserProfile: if current view is a consent modal dialog, determine it has user contact information
 * @param consentDialogHasCheckbox: if current view is a consent modal dialog, determine it has checkbox
 * @param currentView: name of main container component rendered in current page, e.x. 'galleryPage'
 *
 * @return:
 *  a string serialized for telemetry property details
 */
export function getServiceTelemetryDetailContentText({
  serviceInfo,
  isNationalCloud,
  ctaTypes,
  consentDialogHasUserProfile,
  consentDialogHasCheckbox,
  currentView,
  ribbonKey = '',
  trailId,
}: IServiceTelemetryPayload): string {
  if (!serviceInfo) {
    return '';
  }
  const { serviceTypes, title, industries, products, publisher, entityId, publisherId } = serviceInfo;
  // data map, e.g. filter, property 'Title'
  const dataMapField = 'Title';
  const ctaTypeString: string =
    (isArray(ctaTypes) && ctaTypes.map((ctaType: Constants.CTAType) => Constants.CTAType[`${ctaType}`]).join(', ')) || undefined;
  const appPublisher = publisher && isNationalCloud ? sha256(publisher).toString() : publisher;
  const appPublisherId: string = publisherId && isNationalCloud ? sha256(publisherId).toString() : publisherId;

  const serviceTelemetryDetailContent = {
    serviceName: title,
    publisher: appPublisher,
    actionString: Constants.ActionStrings.Service,
    products: getDataMapString(DataMap.products, products, dataMapField),
    industries: getDataMapString(DataMap.industries, industries, dataMapField),
    serviceTypes: Constants.ServiceTypes[`${serviceTypes}`],
    ctaType: ctaTypeString,
    currentView,
    entityId,
    publisherId: appPublisherId,
    ribbonKey,
    trailId,
  };

  // if any of consentDialogHasUserProfile and consentDialogHasCheckbox has value
  // add consent dialog info into telemetry detail content
  if (!isNullorUndefined(consentDialogHasUserProfile) || !isNullorUndefined(consentDialogHasCheckbox)) {
    const consentDialogInfoKey = 'consentDialogInfo';

    serviceTelemetryDetailContent[`${consentDialogInfoKey}`] = getTelemetryDetailContentConsentDialogInfo(
      consentDialogHasCheckbox,
      consentDialogHasUserProfile
    );
  }

  return JSON.stringify(serviceTelemetryDetailContent);
}

export function isEmbedHostDataDynamic(embedHost?: string) {
  if (embedHost === DataMap.products.PowerBI.UrlKey) {
    return true;
  }

  return false;
}

export function getEmbedHostName(embedHost?: string) {
  return embedHost != null ? getProductByUrlKey({ urlKey: embedHost })?.UrlKey : 'portal';
}

export function compareApiVersion(sourceVersion: string, targetVersion: string) {
  return sourceVersion < targetVersion ? -1 : sourceVersion > targetVersion ? 1 : 0;
}

export function IsMoonCakeNationalCloud(nationalCloud: string) {
  if (nationalCloud) {
    if (nationalCloud.toLowerCase() === Constants.mooncakeNationalCloud) {
      return true;
    }
  }
  return false;
}

function getProductPropertyByProductKey(productKey: string, value: string, propertyName: string) {
  for (const key in DataMap.products) {
    if (!!DataMap.products[`${key}`] && DataMap.products[`${key}`][`${productKey}`] === value) {
      return DataMap.products[`${key}`][`${propertyName}`];
    }
  }

  return null;
}

export function getProductBackendKeyByLongTitle(productTitle: string) {
  return getProductPropertyByProductKey('LongTitle', productTitle, 'BackendKey');
}

export function serializeUserProfileEmbedInfo(userInfo: IUserLeadProfileAgreement) {
  let profileToSave = '';
  for (const key in userInfo) {
    if (key === Constants.userAcceptField) {
      continue;
    }
    profileToSave += key + ':' + userInfo[`${key}`] + '|';
  }
  return profileToSave;
}

export function readUserProfileEmbedCookie(userProfileInCookie: string, user: IUserLeadProfileAgreement) {
  const userProfileInfo = userProfileInCookie.split('|');
  userProfileInfo.forEach((pair: string) => {
    const colenIndex = pair.indexOf(':');
    if (colenIndex !== -1) {
      const key = pair.substring(0, colenIndex);
      const value = pair.substring(colenIndex + 1, pair.length);
      user[`${key}`] = value;
    }
  });
}

export function logClientError(err: any, additionalDetails: string, file = '', isWarning = false) {
  try {
    const value = err ? parse(err) : [];
    const stringifiedStack = value.map((sf: any) => sf.toString()).join('\n');

    let errorMessage = '';
    if (err && err.message) {
      errorMessage = `Error Message: ${err.message}\n `;
    }

    additionalDetails = errorMessage + additionalDetails + '\n Stack: ' + stringifiedStack;

    // adding the file to query errors based on the ones that have a valid file path hence raised from our code base vs ones raised by external components
    const details = {
      file,
      additionalDetails,
    };

    console.error(err);

    const payload: ITelemetryData = {
      page: getWindow()?.location?.href,
      action: Constants.Telemetry.Action.PageLoad,
      actionModifier: isWarning ? Constants.Telemetry.ActionModifier.Warning : Constants.Telemetry.ActionModifier.Error,
      details: JSON.stringify(details),
      flushLog: true,
    };
    SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
    const log = isWarning ? logger.warning.bind(logger) : logger.error.bind(logger);
    log(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });
  } catch (error: any) {
    console.error(error);
  }
}

// Utility function which determines if a string is a JSON or not.
// Added this to avoid crash in few cases where we receive an object instead of a json string.
export function isJsonString(str: string) {
  let parsedObj: any = null;
  try {
    parsedObj = JSON.parse(str);
  } catch (e) {
    return null;
  }
  return parsedObj;
}

// function to loop through feature flags
// and check if at least one of them is present in the queries or in the app settings
export function queryConfigContainsFeatureFlags(queryParams: IURLQuery) {
  const featureFlags: IFeatureFlags = new IFeatureFlags();
  const keys = Object.getOwnPropertyNames(featureFlags);
  const keysLength = keys.length;

  for (let i = 0; i < keysLength; i++) {
    if (queryParams && queryParams[keys[`${i}`]]) {
      return true;
    } else if (process.env[keys[`${i}`]]) {
      return true;
    }
  }
  return false;
}

// function to loop through feature flags
// and return an object with featureFlag values that are present in the query or in the app settings
// while ignoring other params in the query
export function getParsedFeatureFlags(queryParams: IURLQuery) {
  const result: IFeatureFlags = new IFeatureFlags();

  if (queryParams) {
    const keys = Object.getOwnPropertyNames(result);
    const keysLength = keys.length;

    for (let i = 0; i < keysLength; i++) {
      const ff = keys[`${i}`];
      if (queryParams[`${ff}`]) {
        if (typeof result[`${ff}`] === 'boolean') {
          result[`${ff}`] = queryParams[`${ff}`] === 'true';
        } else {
          result[`${ff}`] = queryParams[`${ff}`];
        }
      } else if (process.env[`${ff}`]) {
        result[`${ff}`] = process.env[`${ff}`];
      }
    }
  }
  result.activeCloudMarketplaceFeatures();
  return result;
}

// returns true or false whether the regular expression matches pattern
export function matchesExpression(spzaUserId: string, pattern: RegExp): boolean {
  const match = spzaUserId.match(pattern);
  if (match) {
    return match.index === 0;
  }
  return false;
}

export function getSpzaUserIdAndNewUserModifier(): ISpzaUserIdAndModifier {
  const spzaUserData: ISpzaUserIdAndModifier = {
    spzaUserId: '',
    newUserModifier: Constants.Telemetry.ActionModifier.SpzaUserIdNew,
  };

  // get spza user id
  spzaUserData.spzaUserId = getLocalStorageItem(Constants.LocalStorage.spzaUserId);

  // create spza user id
  if (isEmptyNullorUndefinedString(spzaUserData.spzaUserId)) {
    // NOTE: if local storage is not enabled in browser, we always create a new one everytime we access it
    spzaUserData.spzaUserId = generateGuid();

    // store spza user id
    saveLocalStorageItem(Constants.LocalStorage.spzaUserId, spzaUserData.spzaUserId);
  } else if (hasUserAgreedPrivacyConsent()) {
    // a returned user must meet following criteria
    //  1) user has agreed privacy agreement <=> spzaUserId is in browser local storage, and
    //  2) spzaUserId is found
    spzaUserData.newUserModifier = Constants.Telemetry.ActionModifier.SpzaUserIdReturn;
  }

  return spzaUserData;
}

export function getTrailIdAndData(forceUpdate = false, action?: string): ITrailData {
  // get spza user trail id
  const data: ITrailData = JSON.parse(getSessionStorageItem(Constants.LocalStorage.trailId) || JSON.stringify({}));

  const trailData: ITrailData = {
    trailId: '',
    trailIdModifier: Constants.Telemetry.ActionModifier.TrailIdUpdate,
    time: Date.now().toString(),
    ...data,
  };

  const sessionTimeOut = 1000 * 60 * 60 * 2; // 2h
  const isValid = new Date(parseInt(trailData.time)).getTime() + sessionTimeOut > Date.now();
  // create user trail id
  if (isEmptyNullorUndefinedString(trailData.trailId) || !isValid || forceUpdate) {
    trailData.trailId = `${generateGuid()}_${trailData.time}`;
    // store trail id
    saveSessionStorageItem(Constants.LocalStorage.trailId, JSON.stringify(trailData));

    if (forceUpdate) {
      trailData.trailIdModifier = `${Constants.Telemetry.ActionModifier.TrailIdUpdate} by force duo to ${action}`;
      SpzaInstrumentService.registerTrailId(trailData.trailId);
    }

    console.log('[TrailID]', trailData.trailId, trailData.trailIdModifier);
  } else if (hasUserAgreedPrivacyConsent()) {
    trailData.trailIdModifier = Constants.Telemetry.ActionModifier.TrailIdValid;
  }

  const payload = {
    page: getWindow().location.href,
    action: Constants.Telemetry.Action.TrailData,
    actionModifier: trailData.trailIdModifier,
    details: JSON.stringify(trailData),
  };
  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
  logger.info(payload.details, {
    action: payload.action,
    actionModifier: payload.actionModifier,
  });

  return trailData;
}

export function getLocaleDate(date: string, locale: string): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  if (date) {
    const parsedDate: Date = new Date(date);
    return locale ? parsedDate.toLocaleDateString(locale, options) : parsedDate.toLocaleDateString();
  }

  return '';
}

export function getNetworkStats() {
  const { effectiveType, rtt, downlink } = navigator?.connection || {};

  return {
    downlink,
    rtt,
    effectiveType,
  };
}

export function logAssetsTelemetryEvents(store: Store<IState>) {
  const state = store.getState();

  const instrument = SpzaInstrumentService.getProvider();

  const page = getWindow().location.href;
  const landingView = state?.config?.currentView;
  const isEmbedded = state.config?.isEmbedded || false;
  const connection = getNetworkStats();
  const userAgent = navigator.userAgent;

  const additionalTelemetry = {
    landingView,
    isEmbedded,
    connection,
    userAgent,
  };

  const { navigationStart = 0, requestStart = 0 } = getWindow()?.performance?.timing || {};

  const perfEntries = (getWindow()?.performance?.getEntriesByType('resource') || []) as PerformanceResourceTiming[];

  perfEntries
    .filter(({ initiatorType }) => initiatorType === 'script' || initiatorType === 'link')
    .forEach((perfEntry) => {
      const { name: src, initiatorType, responseEnd: performanceNow, duration } = perfEntry;

      const telemetryData: ITelemetryData = {
        page,
        action: Constants.Telemetry.Action.PerfResources,
        actionModifier: Constants.Telemetry.ActionModifier.Complete,
        details: JSON.stringify({
          src,
          initiatorType,
          performanceNow,
          durationSinceRequestStart: performanceNow - (requestStart - navigationStart),
          duration,
          ...additionalTelemetry,
        }),
      };
      instrument.probe<ITelemetryData>('logTelemetryInfo', telemetryData);
      logger.info(telemetryData.details, {
        action: telemetryData.action,
        actionModifier: telemetryData.actionModifier,
      });
    });
}

export function getHostType(isEmbed: boolean) {
  const hostType = !isEmbed ? Constants.appSource : Constants.appSourceEmbed;
  return hostType;
}
export function getCTATypeForActionString(actionString: string): Constants.CTAType {
  if (!actionString) {
    return undefined;
  }
  const actionStrings = Object.keys(Constants.ActionStrings);
  for (const key of actionStrings) {
    if (Constants.ActionStrings[`${key}`] === actionString) {
      return Constants.CTAType[`${key}`];
    }
  }

  return undefined;
}

export function logTenantInfo(users: IUserDataState, isAutoSign: boolean, spzaUserId: string) {
  const instrument = SpzaInstrumentService.getProvider();
  if (users && users.signedIn && users.email) {
    let tenant = '';
    const emailParts = users.email.split('@');
    if (emailParts.length > 1) {
      tenant = emailParts[1];
    }

    // When the user is signed in, we need to correlate the spzaUserId with the signed-in information.
    // We extract the tid and oid and then map it to the spzaUserId
    const userDetails = {
      'tenant name': tenant,
      'tenand id': users.tid ? users.tid : '',
      oid: users.oid ? users.oid : '',
      spzaUserId,
      isAutoSign,
      isMSAUser: users.isMSAUser,
      userSegment: (users.userSegment && UserSegment[users.userSegment]) || '',
      fieldHubUserType: Constants.FieldHubUserType.None,
      emailHash: sha256(users.email).toString(),
      alternateEmailHash:
        users.alternateEmail && users.alternateEmail !== 'undefined' ? sha256(users.alternateEmail).toString() : '',
      tenantType: users.tenantType,
    };

    const tenantPayload = {
      eventName: Constants.Telemetry.Action.UserTenantInfo,
      data: JSON.stringify(userDetails),
    };

    instrument.probe<any>('logOneTimeInfo', tenantPayload);
    logger.info(tenantPayload.data, { action: tenantPayload.eventName, actionModifier: Constants.Telemetry.ActionModifier.Info });
  }
}

// This method logs the landing page details, user settings, perf
export function logInitialTelemetryEvents(store: Store<IState>) {
  const timeAtTelemetryMethodCall = new Date().getTime();
  const state = store.getState();

  const instrument = SpzaInstrumentService.getProvider();
  // This sets the correlationId which will be passed to the telemetry request header
  instrument.probe<string>('initSystemInfo');

  const sharedInstrument = SharedInstrumentService.getProvider();
  // This sets the correlationId which will be passed to the telemetry request header
  sharedInstrument.probe<string>('initSystemInfo');

  // These are one time telemetry events
  instrument.probe<string>('logUserSystemInfo', state.config.locale);

  const { spzaUserId, newUserModifier } = getSpzaUserIdAndNewUserModifier();

  SpzaInstrumentService.registerSpzaId(spzaUserId);

  const trailIdData = getTrailIdAndData();
  SpzaInstrumentService.registerTrailId(trailIdData.trailId);

  SpzaInstrumentService.registerHostType(getHostType(state.config.isEmbedded));

  const spzaUserIDPayload = {
    eventName: Constants.Telemetry.Action.SpzaUserId,
    actionModifier: newUserModifier,
    data: spzaUserId,
    ...trailIdData,
  };

  instrument.probe<any>('logOneTimeInfo', spzaUserIDPayload);

  logger.info(spzaUserIDPayload.data, {
    action: spzaUserIDPayload.eventName,
    actionModifier: spzaUserIDPayload.actionModifier,
  });

  // Logs user tenant info if logged in
  logTenantInfo(state.users, false, spzaUserId);

  const embedHostName = getEmbedHostName(state.config.embedHost);

  const { landingView } = state.config;
  const landingDetails = {
    landingPageURL: getWindow().location.href,
    landingView,
    referrer: getWindow().document.referrer,
    isEmbedded: state.config.isEmbedded,
    embedHost: embedHostName,
    isAuthenticated: state.users ? state.users.signedIn : '',
    cloudType: state.config.nationalCloud,
    campaign: extractCampaignsFromUrl(getWindow().location.href),
    commitHash: __COMMIT_HASH__,
    userSegment: (state.users && state.users.userSegment && UserSegment[state.users.userSegment]) || '',
  };

  const appName = getEntityIdFromUrl({ pathName: getWindow()?.location?.pathname });

  getWindow().telemetry({
    action: Constants.Telemetry.Action.Landing,
    actionModifier: Constants.Telemetry.ActionModifier.End,
    details: landingDetails,
    spzaId: spzaUserId,
    appName,
  });

  // if app data is fetched after bundle load (like power bi), the page will not be rendered on
  // component mount. There will be an additional network call to fetch app data after the
  // initial window/frame load. So defer perf event to post app data retrieval
  if (!embedHostUtils.hasDynamicData(state.config.embedHost)) {
    const timing = performance.timing;
    // Telemetry for Network Latency
    const networkLatency = timing.responseEnd - timing.fetchStart;

    // The time taken for page load once the page is received from the server
    // we may find timing.loadEventEnd as 0 if called before load event fires.
    // If so, use current time as approximation till we fix it properly
    const loadEventEnd = timing.loadEventEnd !== 0 ? timing.loadEventEnd : timeAtTelemetryMethodCall;
    // The time taken for page load once the page is received from the server
    const pageLoadTime = loadEventEnd - timing.responseEnd;
    // The whole process of navigation and page load
    // notice the inconsistent usage of time (loadEventEnd), which makes the below not too useful for me
    const navigationToPageLoad = loadEventEnd - timing.navigationStart;

    // the data we are getting here is valuable, but seems very browser specific (some browsers do not properly support)
    // so introducing a new number here that will always be consistent:
    const timePassedSinceNavigationStart = timeAtTelemetryMethodCall - timing.navigationStart;

    const perfTimings = {
      networkLatency,
      pageLoadTime,
      navigationToPageLoad,
      timePassedSinceNavigationStart,
      allTimings: timing, // Dumping raw timings for perf telemetry analysis
      isEmbedded: state.config.isEmbedded,
      embedHost: embedHostName,
      isAuthenticated: state.users ? state.users.signedIn : '',
      // The server created state will have the count set as 0, in case we are seeing any issue,
      // wherein without going to server we see app view being loaded this well let us know.
      TelemetryLoggedCount: state.config.appViewTelemetryLoggedCount,
      landingPageURL: getWindow().location.href,
    };

    const perfPayload = {
      eventName: Constants.Telemetry.Action.PerfEvents,
      data: JSON.stringify(perfTimings),
      flushLog: true,
    };

    // This would dispatch an action to update the incremented count in state
    store.dispatch(createAppViewTelemetryLoggedAction(null));

    instrument.probe<any>('logOneTimeInfo', perfPayload);
    logger.info(perfPayload.data, { action: perfPayload.eventName, actionModifier: Constants.Telemetry.ActionModifier.Info });
  }
}

/**
 * Sets the Profile feature flag in the state tree to true
 * @param store application state tree
 */
export function setProfileFeatureFlag(store: Store<any>): void {
  const state = store.getState();
  // need feature flags from config
  if (!state || !state.config) {
    return;
  }
  const data: ITelemetryData = {
    page: getWindow().location.href,
    action: Constants.Telemetry.Action.EnableFeature,
    actionModifier: Constants.Telemetry.ActionModifier.Info,
    details: JSON.stringify('User Profile phone number feature enabled.'),
  };
  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', data);
  logger.info(data.details, {
    action: data.action,
    actionModifier: data.actionModifier,
  });

  const featureFlags: IFeatureFlags = { ...state.config.featureFlags, ProfileExp: 'true' };
  store.dispatch(createFeatureFlagsReceivedAction(featureFlags));
}

/**
 * Checks and sets whether we need to set the profileExp flag to true in the state depending on the pattern
 * @param store application state tree
 * @param pattern regular expression to apply to spza user id.
 */
export function setProfileFeatureFlagWithPattern(store: Store<any>, pattern: string): void {
  const userIdInfo = getSpzaUserIdAndNewUserModifier();
  // eslint-disable-next-line security/detect-non-literal-regexp
  const profileRegex = new RegExp(pattern);
  if (matchesExpression(userIdInfo.spzaUserId, profileRegex)) {
    setProfileFeatureFlag(store);
  }
}

export function encodeVulnerableDataInInitalState(initialState: any) {
  const userState = initialState.users as IUserDataState;
  userState.firstName = encodeURI(userState.firstName);
  userState.lastName = encodeURI(userState.lastName);
  userState.displayName = encodeURI(userState.displayName);
  userState.email = encodeURI(userState.email);
  userState.alternateEmail = encodeURI(userState.alternateEmail);
}

export function decodeVulnerableDataInInitalState(initialState: any) {
  const userState = initialState.users as IUserDataState;
  userState.firstName = decodeURI(userState.firstName);
  userState.lastName = decodeURI(userState.lastName);
  userState.displayName = decodeURI(userState.displayName);
  userState.email = decodeURI(userState.email);
  userState.alternateEmail = decodeURI(userState.alternateEmail);
}

// Algorithm to find if a product supports MSA or not :
// By default AAD is supported by all the products
// App has products field which has list of products it supports.
// We need to iterate over all the products.
// We need to show MSA + AAD signin dialog when all the products which we show support MSA
// else default to AAD signin.
export function isMSASupported(products: IProductValue) {
  let isMSAProduct = true;
  let isBitmaskMatch = false; // We need to have at least one product supporting MSA

  for (const key in DataMap.products) {
    if (isOfficeNonSaasApp(DataMap.products[`${key}`].UrlKey) && bitMasksMatchFilter(products, DataMap.products[`${key}`])) {
      if (!DataMap.products[`${key}`].ShortcutFilters) {
        isBitmaskMatch = true;
        isMSAProduct = isMSAProduct && DataMap.products[`${key}`].AllowMSA;
      }
    }
  }

  return isBitmaskMatch && isMSAProduct;
}

export function isAppForMSAAccountOnly(primaryProduct: IProductValue, products: IProductValue, ctaType: Constants.CTAType) {
  return (
    isOfficeApp(getPrimaryProductUrl(primaryProduct)) && !isSharepointApp(products) && ctaType === Constants.CTAType.Purchase
  );
}

export function getSignInModalTypeHelper(
  primaryProduct: IProductValue,
  products: IProductValue,
  userInfo: IUserDataState,
  ctaType: Constants.CTAType,
  isService: boolean,
  isTransactApp = false
): Constants.SignInType {
  // If the user is in a consulting service
  if (isService) {
    // And already signed in, then we show the AAD sign in modal
    if (!userInfo.signedIn) {
      return Constants.SignInType.SignInWith_AAD;
    }

    // Otherwise, the user is already authorized.
    return Constants.SignInType.Authorized;
  }

  const isMSAUser = userInfo.isMSAUser;
  const isMSASupportedProduct = isMSASupported(products) && !isTransactApp;
  // To buy any office product other than sharepoint, the user should be signed in as a MSA user.
  const shouldForceMSA = isAppForMSAAccountOnly(primaryProduct, products, ctaType);

  if (!userInfo.signedIn) {
    if (shouldForceMSA) {
      return Constants.SignInType.SignInWith_MSA;
    } else if (isMSASupportedProduct) {
      return Constants.SignInType.SignInWith_MSA_AAD; // For WXPO products which support both
    } else {
      return Constants.SignInType.SignInWith_AAD;
    }
  }

  // already signed in as MSA and it is a transact app switch to AAD.
  if (userInfo.signedIn && isMSAUser && isTransactApp) {
    return Constants.SignInType.SwitchTo_AAD;
  }

  // If the user is not an MSA User and we have to force the user to be MSA, then we do so.
  if (userInfo.signedIn && !isMSAUser && shouldForceMSA) {
    return Constants.SignInType.SwitchTo_MSA;
  }

  // We need to switch to AAD signin dialog when :
  // 1) User is already signed in
  // 2) The product doesn't support MSA
  // 3) The user is an MSA user
  if (userInfo.signedIn && !isMSASupportedProduct && isMSAUser) {
    return Constants.SignInType.SwitchTo_AAD;
  }

  // If all the above conditions failed, it means the user is already signed in with proper authentication and can continue to Consent modal.
  return Constants.SignInType.Authorized;
}

export function getSignInModalType(
  primaryProduct: IProductValue,
  products: IProductValue,
  userInfo: IUserDataState,
  ctaType: Constants.CTAType,
  isService: boolean,
  isTransactApp = false
): Constants.SignInType {
  const isServiceEntity = !!isService;

  return isServiceEntity
    ? getSignInModalTypeHelper(null, null, userInfo, ctaType, isServiceEntity, isTransactApp)
    : getSignInModalTypeHelper(primaryProduct, products, userInfo, ctaType, isServiceEntity, isTransactApp);
}

export function isAppForAdminCenter(app: IAppDataItemBasicData) {
  return (
    !app.hideFromAdminPortal &&
    bitMasksContainSomeBitMask(app.products, getShortCutBitmask(DataMap.products.AdminPortal)) &&
    !(
      bitmaskMatch('web-apps', app.primaryProduct) &&
      app.ctaTypes.length === 1 &&
      app.ctaTypes[0] === Constants.CTAType.RequestTrial
    )
  );
}

export function embeddedAppDataFiltering(appData: IAppDataItem[], embedHost: string) {
  let filteredApps = appData;

  if (embedHost) {
    let appFilter = (app: IAppDataItemBasicData) => {
      const productInfo = getProductByUrlKey({ urlKey: embedHost });
      const bitmaskInfo = getProdcutBitMaskInfo(productInfo);
      return bitMasksMatchBitMask(app.primaryProduct, bitmaskInfo);
    };

    // HACK: remove after Legacy CRM EmbedHost path is fixed
    if (embedHost === Constants.dynamics365.salesED) {
      appFilter = (app: IAppDataItemBasicData) => {
        const bitmaskInfo = getProdcutBitMaskInfo(DataMap.products.Dynamics365);
        return bitMasksMatchBitMask(app.primaryProduct, bitmaskInfo);
      };
    }

    if (embedHost === Constants.dynamics365.dynamics365) {
      appFilter = (app: IAppDataItemBasicData) => {
        const bitmaskOperationsED = getProdcutBitMaskInfo(DataMap.products.Dynamics365forOperations);
        const bitmaskDynamics365 = getProdcutBitMaskInfo(DataMap.products.Dynamics365);
        const bitmaskFinanceAndOperationsBD = getProdcutBitMaskInfo(DataMap.products.Dynamics365forBusinessCentral);
        return (
          bitMasksMatchBitMask(app.primaryProduct, bitmaskOperationsED) ||
          bitMasksMatchBitMask(app.primaryProduct, bitmaskDynamics365) ||
          bitMasksMatchBitMask(app.primaryProduct, bitmaskFinanceAndOperationsBD)
        );
      };
    }

    if (embedHost === Constants.adminPortal) {
      appFilter = (app: IAppDataItemBasicData) => {
        return isAppForAdminCenter(app);
      };
    }

    filteredApps = appData.filter(appFilter);
  }

  return filteredApps;
}

export function isEmailValid(email: string) {
  if (!email) {
    return false;
  }

  return isEmail(email);
}

// Validates whether the input is empty or not empty by trimming white spaces
// validate callback is optional for extra validation
export function isValidInput(input: string, validateCallBack?: (input: string) => boolean): boolean {
  if (input) {
    const inputNoSpaces = input.trim();
    if (inputNoSpaces.length > 0) {
      if (validateCallBack) {
        return validateCallBack(input);
      } else {
        return true;
      }
    }
  }
  return false;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function getElement(search: Constants.SearchBy, key: string, selectorFunc?: (r: any) => {}): HTMLElement {
  let element: any = null;
  switch (search) {
    case Constants.SearchBy.Id:
      element = document.getElementById(key);
      break;
    case Constants.SearchBy.Class:
      element = document.getElementsByClassName(key)[0] as HTMLElement;
      break;
    case Constants.SearchBy.Tag:
      element = document.getElementsByTagName(key)[0] as HTMLElement;
      break;
    case Constants.SearchBy.QuerySelector: {
      const elements = document.querySelectorAll(key);
      if (selectorFunc) {
        element = selectorFunc(elements) as HTMLElement;
      } else {
        element = elements.length > 0 ? elements[0] : null;
      }
      break;
    }
  }

  return element as HTMLElement;
}

export function focusElementByObject(elementToFocus: any) {
  const element: HTMLElement = getElement(elementToFocus.search, elementToFocus.key, elementToFocus.selectorFunc);
  if (element) {
    element.focus();
  }
  return element;
}

export function addClassToElement(search: Constants.SearchBy, key: string, className: string) {
  const element: HTMLElement = getElement(search, key);
  if (element && element.classList) {
    element.classList.add(className);
  }
}

export function removeClassToFirstMatchedElement(search: Constants.SearchBy, key: string, className: string) {
  const element: HTMLElement = getElement(search, key);
  if (element && element.classList) {
    element.classList.remove(className);
  }
}

export function removeClassFromElements(elementsClassName: string) {
  const elements = document.getElementsByClassName(elementsClassName);
  for (const clearElement in elements) {
    if (elements[`${clearElement}`].classList && elements[`${clearElement}`].classList.contains(elementsClassName)) {
      elements[`${clearElement}`].classList.remove(elementsClassName);
    }
  }
}

export function focusElement(search: Constants.SearchBy, key: string) {
  const element: HTMLElement = getElement(search, key);
  if (element) {
    element.focus();
  }
}

export function setTabindex(search: Constants.SearchBy, key: string, index: number) {
  const element: HTMLElement = getElement(search, key);
  if (element) {
    element.tabIndex = index;
  }
}

export function removeTabindex(search: Constants.SearchBy, key: string) {
  const element: HTMLElement = getElement(search, key);
  if (element) {
    element.removeAttribute(Constants.HTMLAttribute.tabIndex);
  }
}

export function addAttributes(search: Constants.SearchBy, key: string, attributes: Array<any>) {
  const element: HTMLElement = getElement(search, key);
  if (element) {
    attributes.forEach((attribute) => {
      element.setAttribute(attribute.key, attribute.value);
    });
  }
}
const postMessageLogTemplate = (msg: string) => `Embed: ${msg}`;
export function postMessageToHost(payload: any) {
  console.log(postMessageLogTemplate('emitting telemetry before post message'));
  flushTelemetryBuffer();
  console.log(postMessageLogTemplate(`Message posted with msgType ${payload.msgType}`));
  // Added a lag of 150ms here so that when we do a handoff, the telemetry call is not failed.
  // We use this 150ms buffer to construct the telemetry call and make it before we leave SPZA portal.
  getWindow().setTimeout(() => {
    getWindow().parent.postMessage(payload, '*');
  }, 150);
}

export function getQueryParam(req: Request, param: string) {
  const value = req && req.query && req.query[`${param}`];
  return value;
}

export function getListOfDynamics365Products(productsBitmask: IProductValue) {
  const productsList: string[] = [];

  DataMap.products.Dynamics365.ShortcutFilters.forEach((filter: string) => {
    if (bitMasksMatch(productsBitmask, DataMap.products[`${filter}`].FilterGroup, DataMap.products[`${filter}`].FilterID)) {
      productsList.push(DataMap.products[`${filter}`].Title);
    }
  });

  return productsList;
}

export function licenseDisplayTextSet(licenseType: number, license: ILicense) {
  if (licenseType === Constants.Dynamics365LicenseType.dynamics365License) {
    if ((license.hasLicense ? 1 : 0) ^ (license.isDisputed ? 1 : 0)) {
      return Constants.dynamicsLicense.dynamics365License;
    } else {
      return Constants.dynamicsLicense.noLicense;
    }
  } else if (licenseType === Constants.Dynamics365LicenseType.dynamics365forBusinessLicense) {
    if ((license.hasLicense ? 1 : 0) ^ (license.isDisputed ? 1 : 0)) {
      return Constants.dynamicsLicense.dynamics365FinancialLicense;
    } else {
      return Constants.dynamicsLicense.noLicense;
    }
  } else {
    return '';
  }
}

export function dynamicsLicenseTypeForEmbed(productBitmask: IProductValue) {
  const productList = getListOfDynamics365Products(productBitmask);
  if (productList && productList.length > 0) {
    for (let i = 0; i < productList.length; i++) {
      if (
        productList[`${i}`] === DataMap.products.Dynamics365forSales.Title ||
        productList[`${i}`] === DataMap.products.Dynamics365forFieldServices.Title ||
        productList[`${i}`] === DataMap.products.Dynamics365forProjectServicesAutomation.Title ||
        productList[`${i}`] === DataMap.products.Dynamics365forCustomerServices.Title
      ) {
        return Constants.Dynamics365LicenseType.dynamics365License;
      }
      if (productList[`${i}`] === DataMap.products.Dynamics365forBusinessCentral.Title) {
        return Constants.Dynamics365LicenseType.dynamics365forBusinessLicense;
      }
    }
  }
}

export function dynamicsLicenseType(userProfile: IUserLeadProfileAgreement, productsBitMask: IProductValue) {
  if (userProfile && userProfile.licenses && userProfile.licenses.length === Constants.dynamicsLicense.currentD365LicenseNumber) {
    return dynamicsLicenseTypeForEmbed(productsBitMask);
  } else {
    return Constants.Dynamics365LicenseType.noLicense;
  }
}

export function setInitialLocalLicenseStore(licenses: ILicense[], licenseType: number) {
  if (licenseType === Constants.Dynamics365LicenseType.dynamics365License) {
    return licenses[Constants.Dynamics365LicenseIndex.dynamics365LicenseIndex];
  } else {
    return licenses[Constants.Dynamics365LicenseIndex.dynamics365ForBusinessLicenseIndex];
  }
}

export function setInitialLicenseDisplayText(licenses: ILicense[], licenseType: number) {
  if (licenseType === Constants.Dynamics365LicenseType.dynamics365License) {
    return licenseDisplayTextSet(licenseType, licenses[Constants.Dynamics365LicenseIndex.dynamics365LicenseIndex]);
  } else {
    return licenseDisplayTextSet(licenseType, licenses[Constants.Dynamics365LicenseIndex.dynamics365ForBusinessLicenseIndex]);
  }
}

export function shouldSendUserProfile(state: IState, isService = false) {
  const isProfileExp: boolean =
    state && state.config && state.config.featureFlags && state.config.featureFlags.ProfileExp === 'true';
  const userState: IUserDataState = state && state.users;
  const isUpdateRequiredByUserBeforeSend: boolean = userState && userState.profile && userState.profile.updateRequired === false;
  return (isProfileExp || isService) && isUpdateRequiredByUserBeforeSend;
}

export function getLocaleFromState(state: IState): string {
  return !!state && !!state.config && !!state.config.locale ? state.config.locale : null;
}

/**
 * get currentView which contains curated data for Telemetry detail content
 *
 *  helper for getTelemetryDetailContentCurrentView
 *
 * @param query broser url queries
 * @param filterQueryName browser url query name
 * @param isAppGallery is application gallery page
 *
 * @return
 *  1) detailed product curated, e.g. dynamics 365 curated, office 365 curated
 *  2) detailed industry e.g. education, distribution
 *  3) empty string, if filtered view is not a industry view which shows both curated and filtered
 */
export function getTelemetryDetailContentCurrentCuratedViewPrefix(
  query: IURLQuery,
  filterQueryName: string,
  isAppGallery: boolean
) {
  interface IFilterQueryToFilterCollectionName {
    [filterCollectionName: string]: string;
  }

  const filterQueryToFilterCollectionName: IFilterQueryToFilterCollectionName = {
    product: Constants.filterMaps.products,
    industry: Constants.filterMaps.industries,
    category: Constants.filterMaps.categories,
    serviceType: Constants.filterMaps.serviceTypes,
  };

  const isProcessingAppGalleryIndustryFilters: boolean = isAppGallery && filterQueryName === Constants.FilterQuery.industry;

  // industy view, shows both curated and filtered, so skip adding specific keyword 'curated'
  let curatedViewPrefix = isProcessingAppGalleryIndustryFilters ? '' : 'curated';

  if (!query) {
    return curatedViewPrefix;
  }

  // get first query value of query filterQueryName
  const filterQueryValues: string[] =
    (typeof query[`${filterQueryName}`] === 'string' &&
      query[`${filterQueryName}`].split(';').filter((queryValue: string) => !!queryValue.trim())) ||
    [];

  const filterQueryValue: string = filterQueryValues[0] || '';

  if (!filterQueryValue) {
    return curatedViewPrefix;
  }

  // for industry filterd view, it also shows curated data,
  // but only when one and only one active filter is a industry filter
  // check if it is not the case, return an empty string
  if (isProcessingAppGalleryIndustryFilters) {
    const areMultipleFilterCollectionsActive: boolean =
      Object.keys(query).filter((queryName: string) => !!DataMap[filterQueryToFilterCollectionName[`${queryName}`]]).length >= 2;

    const areMultipleIndustryFiltersActive: boolean = filterQueryValues.length >= 2;

    const querySearch = (query && query[Constants.filterTileTypes.search]) || '';
    const isSearchMode = !!querySearch.trim();

    if (areMultipleFilterCollectionsActive || areMultipleIndustryFiltersActive || isSearchMode) {
      // it is not a industry view which shows curated or filtered, return empty string
      return curatedViewPrefix;
    }
  }

  const filterCollection: IDataCollection = DataMap[filterQueryToFilterCollectionName[`${filterQueryName}`]];

  if (!filterCollection) {
    return curatedViewPrefix;
  }

  Object.keys(filterCollection).some((filterName: string) => {
    const filter: IDataValues = filterCollection[`${filterName}`];
    const match: boolean = filter.UrlKey === filterQueryValue;

    // query matches with filter url key
    if (match) {
      const filterTitle = (filter.Title && filter.Title.toLowerCase()) || '';

      // note using 'join', which takes care of empty filterTitle, which avoids adding a space
      curatedViewPrefix = [filterTitle.trim(), curatedViewPrefix].filter((word: string) => word.trim()).join(' ');
    }

    return match;
  });

  return curatedViewPrefix;
}

/**
 * get currentView for Telemetry detail content
 *
 *   additionally for gallery page, need to determine further whether it is
 *        a) curated view
 *        b) detailed product curated view (e.g. office 365 curated appGallery)
 *        c) filtered view
 *        d) industry view (e.g. education appGallery)
 *        e) search filtered view
 *
 * @param currentView current rendered view name, one of routes property `name` in routerHistory.tsx
 * @param currentGalleryViewIsCurated only applies when current view is a gallery view, determine whether it is curated
 */
export function getTelemetryDetailContentCurrentView(currentView: string, currentGalleryViewIsCurated: boolean): string {
  if (!currentView) {
    return undefined;
  }

  // get all gallery views from routes in routerHistory.tsx
  // e.g. appGallery, serviceGallery
  const galleryViews = [routes.marketplace.name, routes.marketplaceServices.name];

  // if new current view is not a gallery view
  if (galleryViews.indexOf(currentView) < 0) {
    // simply return current view
    return currentView;
  }

  // get browser url query, needed to determine curated view, also to determine whether gallery page is showing search results
  // NOTE:
  //  since this function is called in components may or may in coupled in routes
  //  which means container may or may not get props.location.query, props.params
  //  use function below to process queries
  const query: IURLQuery = getQueryFromBrowserUrl();

  const isAppGallery: boolean = currentView === routes.marketplace.name;
  let galleryViewPrefix: string;

  // if gallery shows curated data, need to determine further whether it is
  //  1) product curated view, or
  //  2) curated view
  if (currentGalleryViewIsCurated) {
    galleryViewPrefix = getTelemetryDetailContentCurrentCuratedViewPrefix(query, Constants.FilterQuery.product, isAppGallery);
  } else {
    // if gallery shows filtered data, need to determine further whether it is
    //  1) filtered
    //  2) detailed industry view (shows both curated and filtered, if one and only one active filter is a industry filter)

    const querySearch = (query && query[Constants.filterTileTypes.search]) || '';

    galleryViewPrefix =
      // detailed industry view, or
      getTelemetryDetailContentCurrentCuratedViewPrefix(query, Constants.FilterQuery.industry, isAppGallery) ||
      // simply filtered view
      (querySearch.trim() ? 'search filtered' : 'filtered');
  }

  const galleryView: string = [galleryViewPrefix, currentView].filter((word: string) => word.trim()).join(' ');

  return galleryView;
}

export function getTileDataCuratedQueryParams(queryParams: IURLQuery): IURLQuery {
  if (!queryParams) {
    return {};
  }

  const tileDataCuratedQueryParams: IURLQuery = {};

  for (const urlKey in queryParams) {
    if (Constants.TileDataCuratedQueryParams[`${urlKey}`]) {
      tileDataCuratedQueryParams[`${urlKey}`] = queryParams[`${urlKey}`];
    }
  }

  return tileDataCuratedQueryParams;
}

export function getTileDataFilteredQueryParams(queryParams: IURLQuery): IURLQuery {
  if (!queryParams) {
    return {};
  }

  const tileDataFilteredQueryParams: IURLQuery = {};

  for (const urlKey in queryParams) {
    if (Constants.TileDataFilteredQueryParams[`${urlKey}`]) {
      if (urlKey === Constants.TileDataFilteredQueryParams.search) {
        // Consider search when query length is properly
        if (queryParams[`${urlKey}`] && queryParams[`${urlKey}`].length > 1) {
          tileDataFilteredQueryParams[`${urlKey}`] = queryParams[`${urlKey}`];
        }
      } else {
        tileDataFilteredQueryParams[`${urlKey}`] = queryParams[`${urlKey}`];
      }
    }
  }

  return tileDataFilteredQueryParams;
}

export function getTileDataAdditionalQueryParams(queryParams: IURLQuery): IURLQuery {
  if (!queryParams) {
    return {};
  }

  const tileDataAdditionalQueryParams: IURLQuery = {};

  for (const urlKey in queryParams) {
    if (Constants.TileDataAdditionalQueryParams[`${urlKey}`]) {
      tileDataAdditionalQueryParams[`${urlKey}`] = queryParams[`${urlKey}`];
    }
  }

  // Initialize page
  tileDataAdditionalQueryParams[Constants.TileDataAdditionalQueryParams.page] =
    tileDataAdditionalQueryParams[Constants.TileDataAdditionalQueryParams.page] || '1';

  // Initialize country
  tileDataAdditionalQueryParams[Constants.TileDataAdditionalQueryParams.country] = (
    tileDataAdditionalQueryParams[Constants.TileDataAdditionalQueryParams.country] || Constants.usCountryCode
  ).toUpperCase();

  // Initialize region
  tileDataAdditionalQueryParams[Constants.TileDataAdditionalQueryParams.region] = (
    tileDataAdditionalQueryParams[Constants.TileDataAdditionalQueryParams.region] || Constants.allStatesCode
  ).toUpperCase();

  return tileDataAdditionalQueryParams;
}

export function getTileDataQueryParams(queryParams: IURLQuery): IURLQuery {
  if (!queryParams) {
    return {};
  }

  const optimizedTileDataQueryParams = optimizeQueryParameters({ queryParams });

  const tileDataQueryParams: IURLQuery = {
    ...getTileDataCuratedQueryParams(optimizedTileDataQueryParams),
    ...getTileDataFilteredQueryParams(optimizedTileDataQueryParams),
    ...getTileDataAdditionalQueryParams(optimizedTileDataQueryParams),
  };

  return tileDataQueryParams;
}

export function getAppsTileDataQueryParams(queryParams: IURLQuery): IURLQuery {
  const tileDataQueryParams = getTileDataQueryParams(queryParams);

  delete tileDataQueryParams[Constants.TileDataAdditionalQueryParams.country];
  delete tileDataQueryParams[Constants.TileDataAdditionalQueryParams.region];

  return tileDataQueryParams;
}

export function validateTileDataAdditionalQueryParams(queryParams: IURLQuery) {
  const country = queryParams[Constants.TileDataAdditionalQueryParams.country];
  const region = queryParams[Constants.TileDataAdditionalQueryParams.region];

  if ((country && !isString(country)) || (region && !isString(region))) {
    throw new Error('Country or Region query params that are not strings are not supported');
  }
}

const multipleAllowList: Partial<Record<Constants.TileDataCuratedQueryParams, true>> = {
  [Constants.TileDataCuratedQueryParams.product]: true,
};

export function isQueryParamsPersist(queryParams: IURLQuery): boolean {
  const tileDataQueryParams = getTileDataAdditionalQueryParams(queryParams);
  const tileDataCuratedQueryParams = getTileDataCuratedQueryParams(queryParams);
  const tileDataFilteredQueryParams = getTileDataFilteredQueryParams(queryParams);

  let isMultiple = false;
  if (Object.keys(tileDataCuratedQueryParams).length === 1) {
    const key = Object.keys(tileDataCuratedQueryParams)[0];

    if (!(key in multipleAllowList)) {
      const value = tileDataCuratedQueryParams[`${key}`];
      isMultiple = !!(value?.indexOf(';') > -1);
    }
  }

  let isValidated = true;
  if (Object.keys(tileDataCuratedQueryParams).length === 1) {
    const curatedUrlKey = Object.keys(tileDataCuratedQueryParams)[0];
    const curatedUrlValue =
      typeof tileDataCuratedQueryParams[`${curatedUrlKey}`] === 'string'
        ? tileDataCuratedQueryParams[`${curatedUrlKey}`].split(';')
        : [];
    isValidated = curatedUrlValue.every((value) => !!getDataValuesFromUrlKey(curatedUrlKey, value));
  }

  return (
    tileDataQueryParams[Constants.TileDataAdditionalQueryParams.page] === '1' &&
    tileDataQueryParams[Constants.TileDataAdditionalQueryParams.country]?.toLowerCase() === Constants.usCountryCode &&
    tileDataQueryParams[Constants.TileDataAdditionalQueryParams.region]?.toLowerCase() === Constants.allStatesCode &&
    Object.keys(tileDataFilteredQueryParams).length === 0 &&
    Object.keys(tileDataCuratedQueryParams).length <= 1 &&
    !isMultiple &&
    isValidated
  );
}

export function filterOutArrayFromIds<T>(items: T[], idField: keyof T): T[] {
  const map: {
    [id: string]: boolean;
  } = {};

  return items.filter((item) => {
    const id: string = item[`${idField}`] && item[`${idField}`].toString();
    return !map[`${id}`];
  });
}

export function filterInArrayFromIds<T, K>(items: T[], idField: keyof T, sections: K[], sectionIdField: keyof K): T[] {
  const map: {
    [id: string]: T;
  } = {};

  for (const item of items) {
    const id: string = item[`${idField}`] && item[`${idField}`].toString();
    if (id) {
      map[`${id}`] = item;
    }
  }

  const filteredItems: T[] = [];
  for (const section of sections) {
    const sectionId: string = section[`${sectionIdField}`] && section[`${sectionIdField}`].toString();
    if (map[`${sectionId}`]) {
      filteredItems.push(map[`${sectionId}`]);
    }
  }

  return filteredItems;
}

// Convert time stamp of the form "_h_m_s" for example "1h30m10s"
export function convertTimeToSeconds(timeStamp: string): number {
  let totalTimeInSeconds = 0;
  if (timeStamp) {
    // eslint-disable-next-line security/detect-unsafe-regex
    const hours: RegExpMatchArray = timeStamp.match(/(\d+)+h/);
    // eslint-disable-next-line security/detect-unsafe-regex
    const minutes: RegExpMatchArray = timeStamp.match(/(\d+)+m/);
    // eslint-disable-next-line security/detect-unsafe-regex
    const seconds: RegExpMatchArray = timeStamp.match(/(\d+)+s/);

    if (hours && hours[1]) {
      totalTimeInSeconds += parseInt(hours[1]) * 60 * 60;
    }

    if (minutes && minutes[1]) {
      totalTimeInSeconds += parseInt(minutes[1]) * 60;
    }

    if (seconds && seconds[1]) {
      totalTimeInSeconds += parseInt(seconds[1]);
    }
  }

  return isNaN(totalTimeInSeconds) ? 0 : totalTimeInSeconds;
}

export function shouldEnsureAppDetails(entityId: string, state: IState): boolean {
  const appIndex = state.apps.idMap[`${entityId}`];

  if (isNaN(appIndex)) {
    return true;
  }

  const app = state.apps.dataList[`${appIndex}`];

  if (!app) {
    return true;
  }

  if (!app.detailInformation) {
    return true;
  }

  return false;
}

export function shouldEnsureServiceDetails(entityId: string, state: IState): boolean {
  const appIndex = state.services.idMap[`${entityId}`];

  if (isNaN(appIndex)) {
    return true;
  }

  const service = state.services.dataList[`${appIndex}`];

  if (!service) {
    return true;
  }

  if (!service.detailInformation) {
    return true;
  }

  return false;
}

export function shouldEnsureAppPricings(entityId: string, state: IState): boolean {
  if (__SERVER__) {
    return false;
  }

  const appIndex = state.apps.idMap[`${entityId}`];

  if (isNaN(appIndex)) {
    return true;
  }

  const app = state.apps.dataList[`${appIndex}`] as IAppDataItem;

  if (!app) {
    return true;
  }

  if (app?.pricingInformation?.billingRegion?.toLowerCase() === state.config.billingCountryCode?.toLowerCase()) {
    return false;
  }

  return true;
}

export function shouldEnsureAppReviews(entityId: string, state: IState): boolean {
  if (state.apps.appReviewsData && state.apps.appReviewsData.entityId === entityId) {
    return false;
  }

  return true;
}

export function shouldEnsureAppRelatedApps(entityId: string, state: IState): boolean {
  if (entityId === state.apps.relatedAppsItems.entityId) {
    return false;
  }

  if (state.config.embedHost === DataMap.products.PowerBICustomVisual.UrlKey) {
    return false;
  }

  return true;
}

export function shouldEnsureServiceRelatedApps(entityId: string, state: IState): boolean {
  if (entityId === state.services.relatedAppsItems.entityId) {
    return false;
  }

  return true;
}

export function isAvailableInThisRegion({ startingPrice }: { startingPrice?: IStartingPrice }): boolean {
  return !!(startingPrice && startingPrice.pricingData !== PricingStates.NotAvailableInThisRegion);
}

export function isExtensionFile(file: string): boolean {
  const browsersExtensions = ['chrome-extension', 'moz-extension'];

  return browsersExtensions.some((browsersExtension) => file.indexOf(browsersExtension) > -1);
}

export interface IWindowError {
  msg: string;
  file: string;
  line: number;
  col: number;
  error: any;
}

export function windowOnErrorHandler({ msg, file, line, error }: IWindowError) {
  try {
    const isWarning = isExtensionFile(file);

    logClientError(error, `client side error: [From mainClientHookup] ${msg} in file ${file} on line: ${line}`, file, isWarning);
  } catch (e) {
    console.error('something went wrong during logging' + e);
  }
}

const allowedListUrlValuesCache: { [urlKey: string]: string } = {
  [Constants.QueryStrings.country]: Constants.usCountryCode,
  [Constants.QueryStrings.region]: Constants.allStatesCode,
};

function isAllowedListUrlValuesCache({ query = {} }: { query: IURLQuery }): boolean {
  return Object.entries(query).every(([key, value]) => {
    const validator = allowedListUrlValuesCache[key?.toLowerCase()];

    if (validator) {
      return value?.toLowerCase() === validator;
    }

    return true;
  });
}

export function generateUrlCacheKey({
  isEmbedded,
  landingView,
  locale,
  query,
  isMobile = false,
}: {
  isEmbedded: boolean;
  landingView: string;
  locale: string;
  query: IURLQuery;
  isMobile?: boolean;
}): string {
  const urlCacheKeys: string[] = [];

  const isMainRoute =
    [Constants.Views.home, Constants.Views.appGallery, Constants.Views.serviceGallery].indexOf(landingView) > -1;

  if (isMainRoute) {
    const urlParamCacheKeyBlackList = isEmbedded ? Constants.EmbedUrlParamCacheKeyBlackList : Constants.UrlParamCacheKeyBlackList;

    const isBlackListUrlCache = Object.keys(query).some((urlKey) => urlParamCacheKeyBlackList[urlKey?.toLowerCase()]);

    if (!isBlackListUrlCache && isAllowedListUrlValuesCache({ query })) {
      const cacheParameters: IURLQuery = {
        ...query,
      };

      cacheParameters[Constants.QueryStrings.page] = cacheParameters[Constants.QueryStrings.page] || '1';

      if (cacheParameters[Constants.QueryStrings.page] === '1') {
        urlCacheKeys.push(landingView);

        urlCacheKeys.push(`locale:${locale?.toLowerCase()}`);

        urlCacheKeys.push(`isEmbedded:${isEmbedded}`);

        urlCacheKeys.push(`isMobile:${isMobile}`);

        urlCacheKeys.push(
          `cacheParameters:[${Object.keys(cacheParameters)
            .filter(Boolean)
            .filter((key) => Constants.UrlParamCacheKeyWhiteList[key.toLowerCase()])
            .sort()
            .map((key) => `${key.toLowerCase()}:${cacheParameters[`${key}`]}`)
            .join(',')}]`
        );
      }
    }
  }

  return urlCacheKeys.join('|');
}

export function getXMSClientName(appName: Constants.BrowserConfigs): Constants.ClientNames {
  return Constants.browserConfigsToClientNames[`${appName}`] || Constants.ClientNames.Default;
}

export function getPDPRoute(appType: OdataRawObjectAppType) {
  return isPrivateOffer(appType) ? routes.privateOfferDetails : routes.appDetails;
}

export function getGalleryRoute(appType: OdataRawObjectAppType) {
  const isPrivate = isPrivateOffer(appType);
  return {
    locKey: isPrivate ? 'UserPrivateOffers_Detail_AppTitle' : 'Embedded_Apps',
    href: isPrivate ? routes.privateOffers : routes.marketplace,
  };
}

export function productsUrlValuesToSearchFilter(applicationTarget: string, products: string[]): ISearchFilter {
  const searchFilter: ISearchFilter = {
    category: ISearchFilterCategory.Products,
    values: [],
  };

  if (applicationTarget === Constants.appSourceApplicationTargetName && products) {
    for (const productUrlKey of products) {
      const dataValues = getDataValuesFromUrlKey(Constants.TileDataCuratedQueryParams.product, productUrlKey);

      if (typeof dataValues?.ShortcutUrlKey === 'string') {
        const shortcutUrlKeys = dataValues.ShortcutUrlKey.split(';').filter(
          (shortcutUrlKey: string) => shortcutUrlKey !== dataValues?.UrlKey
        );

        for (const shortcutUrlKey of shortcutUrlKeys) {
          const shortcutDataValues = getDataValuesFromUrlKey(Constants.TileDataCuratedQueryParams.product, shortcutUrlKey);

          if (shortcutDataValues?.Title) {
            searchFilter.values.push(shortcutDataValues.Title);
          }
        }
      }

      if (dataValues?.Title) {
        searchFilter.values.push(dataValues.Title);
      }
    }
  }

  return searchFilter;
}

export function getTileDataEntityType(currentView: string): Constants.TileDataEntityType {
  const mapping = {
    [routes.marketplaceServices.name]: Constants.TileDataEntityType.Service,
    [routes.marketplaceCloudsIndustry.name]: Constants.TileDataEntityType.CloudsIndustry,
  };

  return mapping[`${currentView}`] || Constants.TileDataEntityType.App;
}

export function getMaxPageSize(req: Request) {
  return req.headers.searchactiontype === Constants.Search.ActionType.Suggestions
    ? Constants.Search.maxNumOfSuggestions
    : Constants.FilteredGallery.pageSize;
}

export function isService(offer: IDataItem): offer is Service {
  return offer.entityType === Constants.EntityType.Service;
}

export function isApp(offer: IDataItem): offer is IAppDataItem {
  return offer.entityType === Constants.EntityType.App;
}

export function stripHTML(html: string): string {
  return html.replace(Constants.removeHtmlRegex, '').trim();
}

export function isEncoded({ txt = '' } = {}): boolean {
  return txt !== decodeURIComponent(txt);
}

export function isSignedInChanged(currentSignedIn: boolean, prevSignedIn: boolean) {
  return currentSignedIn && !prevSignedIn;
}

export function getActiveFeaturedFlags(featureFlags: IFeatureFlags): IURLQuery {
  return Object.keys(featureFlags || {})
    .filter((flag) => featureFlags[`${flag}`] === 'true' && flag !== 'ProfileExp')
    .reduce(
      (acc, flag) => ({
        ...acc,
        [flag]: featureFlags[`${flag}`],
      }),
      {}
    );
}

export function canRenderPricing({
  app,
  isEmbedded,
  billingRegion,
}: {
  app: IAppDataItem;
  isEmbedded: boolean;
  billingRegion: string;
}) {
  const { ctaTypes, licenseManagement, planPricingType, primaryProduct } = app;

  const isSimplePlanPricing = planPricingType === PlanPricingType.SimplePlanPricing;
  const transactApp = isTransactApp({ isEmbedded, ctaTypes, appData: app, billingRegion });
  const bagTransactable = isBagTransactable(app);
  const isMicrosoftManaged = !!licenseManagement?.isMicrosoftManaged;
  const powerBIVisuals = isPowerBIVisuals(getPrimaryProductUrl(primaryProduct));

  if (isEmbedded) {
    return (!powerBIVisuals && isSimplePlanPricing && (transactApp || isMicrosoftManaged)) || (bagTransactable && powerBIVisuals);
  }

  // Render plans and pricing only for Transactable Apps and MicrosoftManaged offers
  return isSimplePlanPricing && (transactApp || isMicrosoftManaged);
}

export function shouldRenderPlanAndPricing(planPricingType: PlanPricingType) {
  return planPricingType !== PlanPricingType.None;
}

export function parseCatalogRecommendedSizes(
  { parameters: { osPlatform, recommendedSizes } }: ICreauteUiDefinition = {
    parameters: { osPlatform: '', recommendedSizes: [] },
  }
): string[] {
  return recommendedSizes
    .map((recommendedSize) => {
      const parts = (recommendedSize || '').split('_');

      if (parts.length < 2) {
        return null;
      }

      const [tier, instance, version = '', version2 = ''] = parts;

      return [
        `${osPlatform}-${instance}${version}-${tier}`.toLowerCase(),
        `${osPlatform}-${instance}${version}${version2}-${tier}`.toLowerCase(),
      ];
    })
    .reduce((accumulator, value) => accumulator.concat(value), [])
    .filter(Boolean);
}

export function sortingSKUs({ a, b }: { a: ISKU; b: ISKU }): number {
  return a.displayRank - b.displayRank || b.title?.toUpperCase().localeCompare(a.title?.toUpperCase());
}

export function isProfessionalService({ service }: { service: Service }): boolean {
  return service?.entityType === Constants.EntityType.Service && service?.isHiddenPrivateOffer;
}

export function openInNewWindow(url: string): void {
  const newWindow = window.open(url, '_blank');

  if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
    const payload: ITelemetryData = {
      page: getWindow()?.location?.href,
      action: Constants.Telemetry.Action.openInNewWindow,
      actionModifier: Constants.Telemetry.ActionModifier.Error,
      details: `Failed to open the URL: ${url} in new window`,
    };
    logger.error('Failed to open in new window', {
      action: payload.action,
      actionModifier: payload.actionModifier,
      page: payload.page,
      error: payload.details,
    });
    SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
  }
}

export const getServiceTypeFromBackEndKey = (serviceTypeBackendKey: string) => {
  return Object.values(DataMap.serviceTypes).find((serviceType) => serviceType.BackendKey === serviceTypeBackendKey);
};
