import { Constants } from './constants';
import { addAttributes, getElement } from './appUtils';
import { getTrimmedWords } from './stringUtils';

/**
 * This function is to change azure header navigation bar to be static instead of sticky at the top
 */
export function forceAzureHeaderNavStatic() {
  // tag name of dom element to append new classname to
  const htmlTagName = 'html';

  // new classname
  const azureHeaderStaticClassValue = 'azure-header-static';

  // get dom element of tag name
  const htmlTagDomElement: HTMLElement = getElement(Constants.SearchBy.Tag, htmlTagName);

  if (htmlTagDomElement) {
    // get classname value of html tag element
    const htmlTagClassValue = htmlTagDomElement.className || '';
    const htmlTagClassValueList = getTrimmedWords(htmlTagClassValue, ' ');

    // check if html tag element already has azure-header-static
    if (htmlTagClassValueList.indexOf(azureHeaderStaticClassValue) >= 0) {
      return;
    }

    // append new classname to existing attribute classname value if exist
    addAttributes(Constants.SearchBy.Tag, htmlTagName, [
      {
        key: Constants.HTMLAttribute.className,
        value: htmlTagClassValueList.concat(azureHeaderStaticClassValue).join(' '),
      },
    ]);
  }
}

/**
 * Fix for clicking elsewhere, azure header navigation bar dropdown should be closed
 *
 *  NOTE: since azure header team refuses to fix this bug, adding a workaround for now
 */
export function closeAzureHeaderNavDropdown() {
  // query selector of active azure header navigation bar dropdown button
  const azureHeaderNavDropdownQuerySelector = '.azure-header li.active button';

  // get active azure header navigation bar dropdown button
  const azureHeaderNavDropdownDOMElement: HTMLElement = getElement(
    Constants.SearchBy.QuerySelector,
    azureHeaderNavDropdownQuerySelector
  );

  // click it again to close
  if (azureHeaderNavDropdownDOMElement) {
    azureHeaderNavDropdownDOMElement.click();
  }
}
