import React, { FunctionComponent } from 'react';
import { mergeStyleSets } from '@fluentui/react';
import classNames from 'classnames';

export interface IHiddenComponentWrapperProps {
  hide: boolean;
  className?: string;
  children?: React.ReactChild;
}

const contentStyles = mergeStyleSets({
  hiddenClass: {
    display: 'none',
  },
});

export const HiddenComponentWrapper: FunctionComponent<IHiddenComponentWrapperProps> = ({
  className,
  hide,
  children,
}: IHiddenComponentWrapperProps) => {
  const containerClass = classNames({
    [className]: className?.length > 0,
    [contentStyles.hiddenClass]: hide,
  });

  return <div className={containerClass}>{children}</div>;
};
