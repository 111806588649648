import React, { useRef, useEffect, useState } from 'react';
import { NeutralColors, SharedColors } from '@fluentui/theme';
import { Text, FontIcon, mergeStyleSets } from '@fluentui/react';
import type { IFontStyles } from '@fluentui/react';
import { IItemData, LinkedAddInsType, ProductsType } from '@shared/Models';
import { getWindow } from '@shared/services/window';
import { Constants } from '@shared/utils/constants';
import { ItemsTooltip } from '@shared/components/ItemsTooltip';
import { InternalLink } from '@shared/components/internalLink';
import word from '@shared/images/ProductIcons/Word.svg';
import outlook from '@shared/images/ProductIcons/Outlook.svg';
import onenote from '@shared/images/ProductIcons/OneNote.svg';
import officemetaos from '@shared/images/ProductIcons/OfficeMetaOS.svg';
import teams from '@shared/images/ProductIcons/Teams.svg';
import excel from '@shared/images/ProductIcons/Excel.svg';
import sharepoint from '@shared/images/ProductIcons/SharePoint.svg';
import viva from '@shared/images/ProductIcons/Viva.svg';
import powerpoint from '@shared/images/ProductIcons/PowerPoint.svg';
import project from '@shared/images/ProductIcons/Project.svg';
import azure from '@shared/images/ProductIcons/azure.png';
import webApps from '@shared/images/ProductIcons/web-apps.svg';
import dynamics365Marketing from '@shared/images/ProductIcons/dynamics-365-marketing.svg';
import dynamics365Commerce from '@shared/images/ProductIcons/dynamics-365-commerce.svg';
import dynamics365SupplyChainManagment from '@shared/images/ProductIcons/dynamics-365-supply-chain-management.svg';
import dynamics365ForFinanceAndOperations from '@shared/images/ProductIcons/dynamics-365-for-finance-and-operations.svg';
import dynamics365ForSales from '@shared/images/ProductIcons/dynamics-365-for-sales.svg';
import dynamics365BusinessCentral from '@shared/images/ProductIcons/dynamics-365-business-central.svg';
import dynamics365ForCustomerServices from '@shared/images/ProductIcons/dynamics-365-for-customer-services.svg';
import dynamics365ForFieldServices from '@shared/images/ProductIcons/dynamics-365-for-field-services.svg';
import dynamics365ForProjectServiceAutomation from '@shared/images/ProductIcons/dynamics-365-for-project-service-automation.svg';
import dynamics365CustomerInsights from '@shared/images/ProductIcons/dynamics-365-customer-insights.svg';
import dynamics365CustomerVoice from '@shared/images/ProductIcons/dynamics-365-customer-voice.svg';
import dynamics365ProjectOperations from '@shared/images/ProductIcons/dynamics-365-project-operations.svg';
import dynamics365MixedReality from '@shared/images/ProductIcons/dynamics-365-mixed-reality.svg';
import dynamics365HumanResources from '@shared/images/ProductIcons/dynamics-365-human-resources.svg';
import m365AdoptionAndChangeManagement from '@shared/images/ProductIcons/m365-sa-adoption-and-change-management.svg';
import m365CallingForMicrosoftTeams from '@shared/images/ProductIcons/m365-sa-calling-for-microsoft-teams.svg';
import m365CloudSecurity from '@shared/images/ProductIcons/m365-sa-cloud-security.svg';
import m365ComplianceAdvisoryServices from '@shared/images/ProductIcons/m365-sa-compliance-advisory-services.svg';
import m365DeviceDeploymentAndManagement from '@shared/images/ProductIcons/m365-sa-device-deployment-and-management.svg';
import m365FirstlineWorkers from '@shared/images/ProductIcons/m365-sa-firstline-workers.svg';
import m365IdentityAndAccessManagement from '@shared/images/ProductIcons/m365-sa-identity-and-access-management.svg';
import m365InformationProtectionAndGovernance from '@shared/images/ProductIcons/m365-sa-information-protection-and-governance.svg';
import m365InsiderRisk from '@shared/images/ProductIcons/m365-sa-insider-risk.svg';
import m365KnowledgeAndInsights from '@shared/images/ProductIcons/m365-sa-knowledge-and-insights.svg';
import m365MeetingRoomsForMicrosoftTeams from '@shared/images/ProductIcons/m365-sa-meeting-rooms-for-microsoft-teams.svg';
import m365MeetingsForMicrosoftTeams from '@shared/images/ProductIcons/m365-sa-meetings-for-microsoft-teams.svg';
import m365LiveEvents from '@shared/images/ProductIcons/m365-sa-microsoft-365-live-events.svg';
import m365MobileDeviceManagement from '@shared/images/ProductIcons/m365-sa-mobile-device-management.svg';
import m365PowerPlatformForTeams from '@shared/images/ProductIcons/m365-sa-power-platform-for-teams.svg';
import m365TeamsCustomSolutions from '@shared/images/ProductIcons/m365-sa-teams-custom-solutions.svg';
import m365TeamworkDeployment from '@shared/images/ProductIcons/m365-sa-teamwork-deployment.svg';
import m365ThreatProtection from '@shared/images/ProductIcons/m365-sa-threat-protection.svg';
import m365WorkspaceAnalytics from '@shared/images/ProductIcons/m365-sa-workplace-analytics.svg';
import powerBI from '@shared/images/ProductIcons/power-bi.svg';
import powerBIVisuals from '@shared/images/ProductIcons/power-bi-visuals.svg';
import powerAutomate from '@shared/images/ProductIcons/power-automate.svg';
import powerVirtualAgents from '@shared/images/ProductIcons/power-virtual-agents.svg';
import powerapps from '@shared/images/ProductIcons/PowerApps.svg';
import powerpages from '@shared/images/ProductIcons/powerpages.svg';
import classNames from 'classnames';

const iconsMap = {
  word,
  outlook,
  onenote,
  officemetaos,
  teams,
  excel,
  sharepoint,
  viva,
  powerpoint,
  project,
  azure,
  'web-apps': webApps,
  // 'power-platform': true,
  // 'dynamics-365': true,
  'dynamics-365-marketing': dynamics365Marketing,
  'dynamics-365-commerce': dynamics365Commerce,
  'dynamics-365-supply-chain-management': dynamics365SupplyChainManagment,
  // 'dynamics-365-for-operations': true,
  'dynamics-365-for-finance-and-operations': dynamics365ForFinanceAndOperations,
  'dynamics-365-for-sales': dynamics365ForSales,
  'dynamics-365-business-central': dynamics365BusinessCentral,
  'dynamics-365-for-customer-services': dynamics365ForCustomerServices,
  'dynamics-365-for-field-services': dynamics365ForFieldServices,
  'dynamics-365-for-project-service-automation': dynamics365ForProjectServiceAutomation,
  'dynamics-365-customer-insights': dynamics365CustomerInsights,
  'dynamics-365-customer-voice': dynamics365CustomerVoice,
  'dynamics-365-project-operations': dynamics365ProjectOperations,
  // 'dynamics-365-project-services': true,
  'dynamics-365-mixed-reality': dynamics365MixedReality,
  'dynamics-365-human-resources': dynamics365HumanResources,
  'm365-sa-adoption-and-change-management': m365AdoptionAndChangeManagement,
  'm365-sa-calling-for-microsoft-teams': m365CallingForMicrosoftTeams,
  'm365-sa-cloud-security': m365CloudSecurity,
  'm365-sa-compliance-advisory-services': m365ComplianceAdvisoryServices,
  'm365-sa-device-deployment-and-management': m365DeviceDeploymentAndManagement,
  'm365-sa-firstline-workers': m365FirstlineWorkers,
  'm365-sa-identity-and-access-management': m365IdentityAndAccessManagement,
  'm365-sa-information-protection-and-governance': m365InformationProtectionAndGovernance,
  'm365-sa-insider-risk': m365InsiderRisk,
  'm365-sa-knowledge-and-insights': m365KnowledgeAndInsights,
  'm365-sa-meeting-rooms-for-microsoft-teams': m365MeetingRoomsForMicrosoftTeams,
  'm365-sa-meetings-for-microsoft-teams': m365MeetingsForMicrosoftTeams,
  'm365-sa-microsoft-365-live-events': m365LiveEvents,
  'm365-sa-mobile-device-management': m365MobileDeviceManagement,
  'm365-sa-power-platform-for-teams': m365PowerPlatformForTeams,
  'm365-sa-teams-custom-solutions': m365TeamsCustomSolutions,
  'm365-sa-teamwork-deployment': m365TeamworkDeployment,
  'm365-sa-threat-protection': m365ThreatProtection,
  'm365-sa-workplace-analytics': m365WorkspaceAnalytics,
  'power-bi': powerBI,
  'power-bi-visuals': powerBIVisuals,
  'power-automate': powerAutomate,
  'power-virtual-agents': powerVirtualAgents,
  powerapps,
  powerpages,
  // 'admin-portal': true
};

const pixelsPerChar = {
  medium: 9,
  smallPlus: 6,
};
const firstIconMargin = 2;
const iconSize = 16;
const totalIconMargin = 8;
const totalTextMargin = totalIconMargin;
const dividerSize = iconSize;
const optionalLeftNumberSize = (textSize: keyof Pick<IFontStyles, 'smallPlus' | 'medium'>) => 2 * pixelsPerChar[`${textSize}`];
const minTextSize = (textSize: keyof Pick<IFontStyles, 'smallPlus' | 'medium'>) => 3 * pixelsPerChar[`${textSize}`];

const classStyles = mergeStyleSets({
  icon: [{ display: 'inline-block', margin: `0 ${totalIconMargin / 2}px` }],
  firstIcon: [{ margin: `0 ${totalIconMargin / 2}px 0 0` }],
  divider: [{ fontSize: iconSize, color: NeutralColors.gray40 }],
  hoveredText: [{ margin: `0px ${totalTextMargin / 2}px`, color: SharedColors.cyanBlue10, textDecoration: 'underline' }],
  link: [{ margin: `2px ${totalTextMargin / 2}px` }],
  linkText: [{ color: SharedColors.cyanBlue10 }],
  text: [{ margin: `0px ${totalTextMargin / 2}px` }, { color: NeutralColors.gray130 }],
});

const containerClassName = (wrap: boolean) =>
  mergeStyleSets({
    container: ['itemsContainer', { flexWrap: wrap ? 'wrap' : 'nowrap' }],
  });

const sizes = {
  icon: iconSize + totalIconMargin,
  text: (length: number, textSize: keyof Pick<IFontStyles, 'smallPlus' | 'medium'>) =>
    length * pixelsPerChar[`${textSize}`] + totalTextMargin,
};

export interface IItemsContainerComponentProps {
  items: IItemData[];
  logOnClick?: (type: LinkedAddInsType) => void;
  hrefCallback?: (entityId: string) => string;
  wrap?: boolean;
  withText?: boolean;
  textSize?: keyof Pick<IFontStyles, 'smallPlus' | 'medium'>;
  withTooltip?: boolean;
  tooltipTitle?: string;
  dataBiId?: string;
  withDivider?: boolean;
}

interface IItemsContainerTextProps {
  text: string;
  hovered?: boolean;
  textSize?: keyof IFontStyles;
}

interface IItemsContainerLinkTextProps {
  entityId?: string;
  type?: string;
  text: string;
  hovered?: boolean;
  textSize?: keyof IFontStyles;
  logOnClick?: (type: LinkedAddInsType) => void;
  hrefCallback?: (entityId: string) => string;
  dataBiId: string;
}

interface IItemsContainerIconProps {
  itemType: LinkedAddInsType | ProductsType;
  className?: string;
}

export const ItemsContainerIcon: React.FunctionComponent<IItemsContainerIconProps> = ({
  itemType,
  className = classStyles.icon,
}: IItemsContainerIconProps) => (
  <img src={iconsMap[`${itemType}`]} width={iconSize} height={iconSize} alt={`${itemType} product icon`} className={className} />
);

export const ItemsContainerDivider: React.FunctionComponent = () => (
  <FontIcon iconName="Separator" className={classStyles.divider} />
);

export const ItemsContainerText: React.FunctionComponent<IItemsContainerTextProps> = ({
  text,
  hovered = false,
  textSize = 'medium',
}: IItemsContainerTextProps) => {
  const classes = classNames({ [classStyles.hoveredText]: hovered });

  return (
    <Text variant={textSize} className={hovered ? classes : classStyles.text}>
      {text}
    </Text>
  );
};

export const ItemsContainerLinkText: React.FunctionComponent<IItemsContainerLinkTextProps> = ({
  entityId,
  type,
  text,
  hovered,
  hrefCallback,
  logOnClick,
  dataBiId,
  textSize = 'medium',
}: IItemsContainerLinkTextProps) => {
  return hrefCallback ? (
    <InternalLink
      href={hrefCallback(entityId)}
      additionalCommand={logOnClick ? () => logOnClick(type as LinkedAddInsType) : null}
      data-bi-id={dataBiId}
      data-bi-name={entityId}
      data-bi-view={Constants.Telemetry.TemplateName.SaaSBundle}
      data-bi-area={Constants.Telemetry.AreaName.AppDetails}
      accEnabled
      className={classStyles.link}
    >
      <Text className={classStyles.linkText} variant={textSize}>
        {text}
      </Text>
    </InternalLink>
  ) : (
    <ItemsContainerText text={text} textSize={textSize} hovered={hovered} />
  );
};

export const ItemsContainerComponent: React.FunctionComponent<IItemsContainerComponentProps> = ({
  items,
  logOnClick,
  hrefCallback,
  tooltipTitle,
  textSize = 'medium',
  wrap = false,
  withText = false,
  withTooltip = false,
  dataBiId = 'View AddIn',
  withDivider = true,
}: IItemsContainerComponentProps) => {
  const ref = useRef(null);
  const [elementWidth, setElementWidth] = useState(ref?.current?.clientWidth || 0);
  const onResize = () => {
    if (ref.current) {
      setElementWidth(ref.current.clientWidth);
    }
  };
  useEffect(() => setElementWidth(ref.current.clientWidth), []);
  useEffect(() => {
    getWindow()?.addEventListener('resize', onResize);

    return () => {
      getWindow()?.removeEventListener('resize', onResize);
    };
  }, []);

  const insertEllipsis = (itemIndex: number, productIndex: number, elements: React.ReactElement[], productsNumber: number) => {
    let leftNumber = productsNumber - productIndex;
    if (itemIndex > 0) {
      elements.push(<ItemsContainerText key={`${itemIndex}_ellipsis`} text="..." textSize={textSize} hovered={withTooltip} />);
      leftNumber -= 1;
    }
    if (leftNumber > 0)
      elements.push(
        <ItemsContainerText key={`${itemIndex}_ellipsis2`} text={`+${leftNumber}`} textSize={textSize} hovered={withTooltip} />
      );
    return elements;
  };

  const renderElements = () => {
    const productsCount = items.length;
    const shouldAddLeftNumber = productsCount > 1;
    const additionalLeftNumberSize = shouldAddLeftNumber ? optionalLeftNumberSize(textSize) : 0;
    const maxWidth = wrap ? Infinity : elementWidth - additionalLeftNumberSize;
    const elementsToRender = [];
    let currentWidth = 0;

    for (let productIndex = 0; productIndex < productsCount; productIndex++) {
      const icons = items[`${productIndex}`].icons;
      const lastProduct: boolean = productIndex >= productsCount - 1;
      for (let itemIndex = 0; itemIndex < icons.length; itemIndex++) {
        const lastType: boolean = items[`${productIndex}`].urlKey ? itemIndex === icons.length - 1 : true;
        const shouldAddDivider = itemIndex === 0 && !lastProduct && withDivider;
        let additionalSize = shouldAddDivider ? dividerSize : 0;
        if (currentWidth + sizes.icon + additionalSize < maxWidth) {
          currentWidth += sizes.icon + additionalSize;
          if (!withDivider && productIndex === 0) {
            elementsToRender.push(
              <ItemsContainerIcon
                key={`${productIndex}_${itemIndex}`}
                itemType={icons[`${itemIndex}`]}
                className={classStyles.firstIcon}
              />
            );
            additionalSize -= firstIconMargin;
          } else {
            elementsToRender.push(<ItemsContainerIcon key={`${productIndex}_${itemIndex}`} itemType={icons[`${itemIndex}`]} />);
          }
          if (lastType) {
            const productName: string = items[`${productIndex}`].title;
            const productNameLength = sizes.text(productName.length, textSize);
            const totalMaxWidth = lastProduct ? maxWidth + additionalLeftNumberSize : maxWidth;
            const totalProductNameLength = withDivider ? productNameLength : productNameLength + totalTextMargin / 2;
            if (withText) {
              if (currentWidth + minTextSize(textSize) <= totalMaxWidth) {
                if (currentWidth + productNameLength > totalMaxWidth) {
                  const leftNumber = productsCount - productIndex;
                  // if we cant push the whole product name, then remove the icon and add +1
                  elementsToRender.pop();
                  elementsToRender.push(
                    <ItemsContainerText
                      text={`+${leftNumber}`}
                      textSize={textSize}
                      hovered={withTooltip}
                      key={`${items[`${productIndex}`].urlKey}_linkText3`}
                    />
                  );
                  return elementsToRender;
                } else {
                  elementsToRender.push(
                    <ItemsContainerLinkText
                      entityId={items[`${productIndex}`].urlKey}
                      type={icons[0]}
                      key={`${items[`${productIndex}`].urlKey}_${productIndex}_linkText`}
                      text={productName}
                      textSize={textSize}
                      hrefCallback={hrefCallback}
                      logOnClick={logOnClick}
                      dataBiId={dataBiId}
                    />
                  );
                }
                currentWidth += totalProductNameLength;
              } else {
                elementsToRender.push(
                  <ItemsContainerText text="..." textSize={textSize} hovered={withTooltip} key={`${productIndex}_linkText2`} />
                );
                if (lastProduct) return elementsToRender;

                const leftNumber = productsCount - productIndex - 1;
                if (leftNumber > 0)
                  elementsToRender.push(
                    <ItemsContainerText
                      text={`+${leftNumber}`}
                      textSize={textSize}
                      hovered={withTooltip}
                      key={`${items[`${productIndex}`].urlKey}_linkText3`}
                    />
                  );

                return elementsToRender;
              }
            }
            if (!lastProduct && withDivider)
              elementsToRender.push(<ItemsContainerDivider key={`${items[`${productIndex}`].urlKey}_linkDivider`} />);
          }
        } else {
          return insertEllipsis(itemIndex, productIndex, elementsToRender, productsCount);
        }
      }
    }

    return elementsToRender.map((element, index) => <React.Fragment key={index}>{element}</React.Fragment>);
  };

  return (
    <div ref={ref} className="itemsRootContainer">
      {withTooltip ? (
        <ItemsTooltip title={tooltipTitle} items={items}>
          <div className={containerClassName(wrap).container}>{renderElements()}</div>
        </ItemsTooltip>
      ) : (
        <div className={containerClassName(wrap).container}>{renderElements()}</div>
      )}
    </div>
  );
};
