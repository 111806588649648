import * as React from 'react';
import { IReviewIsvReply } from 'Models';
import { getLocaleDate } from '../../shared/utils/appUtils';

export interface IReviewIsvReplyComponent {
  isvReply: IReviewIsvReply;
  publisherName: string;
  iconUrl: string;
  locale: string;
  classes?: string;
}

export const ReviewIsvReply = (props: IReviewIsvReplyComponent) => {
  const classNames = require('classnames-minimal');
  const [isImageLoadingError, setIsImageLoadingError] = React.useState(false);
  const imageClassName = classNames({ isvIcon: !isImageLoadingError });
  const containerClasses = classNames({ isvReplyContainer: true, [props.classes]: props.classes });

  const replyDate = getLocaleDate(props.isvReply.replied_at, props.locale);

  const handleLoadError = () => {
    setIsImageLoadingError(true);
  };

  return (
    <div className={containerClasses}>
      <div className="isvReplyTitle">
        <img className={imageClassName} alt="product-icon" src={props.iconUrl} onError={handleLoadError} />
        <div className="isvName">{props.publisherName ? props.publisherName : ''}</div>
        <div className="replyDate">replied on {replyDate}</div>
      </div>
      <div className="isvReplyContent">{props.isvReply.content}</div>
    </div>
  );
};
