import React from 'react';
import PropTypes from 'prop-types';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import {
  IconButton,
  Stack,
  Text,
  Modal,
  ScreenWidthMinXLarge,
  ScreenWidthMinLarge,
  mergeStyleSets,
  PrimaryButton,
} from '@fluentui/react';

import type { IButtonStyles, IIconProps, IModalStyles, IStackTokens } from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import consentSuccessModalIcon from '@shared/images/consentSuccessModalIcon.png';
import { Constants } from '@shared/utils/constants';
import { getNpsModule } from '@appsource/utils/nps';

export interface ISuccessConsentModalProps {
  publisher: string;
  dismissModal: () => void;
  shouldSkipModal?: boolean;
}

const successConsentModalClassNames = mergeStyleSets({
  description: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
  },
  centeredText: {
    textAlign: 'center',
  },
  imgSize: {
    width: 100,
    height: 100,
  },
});

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: NeutralColors.gray160,
  },
};

const modalStyles: Partial<IModalStyles> = {
  main: {
    [`@media (min-width: ${ScreenWidthMinXLarge}px)`]: {
      width: '28%',
    },
    [`@media (min-width: ${ScreenWidthMinLarge}px) and (max-width: ${ScreenWidthMinXLarge}px)`]: {
      width: '50%',
    },
    [`@media (max-width: ${ScreenWidthMinLarge}px)`]: {
      width: '80%',
    },
    display: 'flex',
    maxWidth: 560,
  },
  scrollableContent: {
    padding: '16px 27px 24px 24px',
    [`@media (max-width: ${ScreenWidthMinLarge}px)`]: {
      padding: '16px 20px 24px 20px',
    },
  },
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };
const titleAndContentTokens: IStackTokens = { childrenGap: 60 };
const descriptionAndIconTokens: IStackTokens = { childrenGap: 20 };
const descriptionTokens: IStackTokens = { maxWidth: 280 };
const contentAndBottomTokens: IStackTokens = { childrenGap: 66 };

export const SuccessConsentModal: React.FunctionComponent<ISuccessConsentModalProps> = (
  { publisher, dismissModal, shouldSkipModal }: ISuccessConsentModalProps,
  context: ILocContext & ILocParamsContext
) => {
  return (
    <Modal isOpen={!shouldSkipModal} onDismiss={dismissModal} styles={modalStyles}>
      <Stack tokens={titleAndContentTokens}>
        <Stack.Item>
          <Stack horizontal>
            <Stack.Item grow className={successConsentModalClassNames.centeredText}>
              <Text variant="xLarge">{context.loc('Consent_Modal_Success_Title', 'Thanks for getting in touch!')}</Text>
            </Stack.Item>
            <Stack.Item>
              <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="close" onClick={dismissModal} />
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Stack tokens={contentAndBottomTokens}>
            <Stack.Item>
              <Stack horizontalAlign="center" tokens={descriptionAndIconTokens}>
                <Stack.Item>
                  <img
                    src={consentSuccessModalIcon}
                    alt={context.loc('Success_Consent_Modal_Icon_Name')}
                    className={successConsentModalClassNames.imgSize}
                  />
                </Stack.Item>
                <Stack.Item tokens={descriptionTokens} className={successConsentModalClassNames.description}>
                  <Text variant="large">
                    {context.loc('Success_Consent_Modal_Someone_From', `Someone from`)}&nbsp;
                    <Text variant="large" className="ms-fontWeight-bold">
                      {publisher}
                    </Text>
                  </Text>
                  <Text variant="large" className="ms-fontWeight-semibold">
                    {context.loc('Success_Consent_Modal_Will_Contact_You', 'will contact you soon.')}
                  </Text>
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item align="end">
              <PrimaryButton
                text={context.loc('Dialog_Close')}
                onClick={() => {
                  dismissModal();
                  getNpsModule()?.ctaClicked();
                }}
                data-bi-id={Constants.JsllCTAId.Close}
                data-bi-area="Consent Dialog"
              />
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </Modal>
  );
};

(SuccessConsentModal as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
};
