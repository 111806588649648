import {
  MPRPAppData,
  MPRPPlansEntity,
  TermsStrings,
  PricingAudience,
  MPRPAvailabilities,
  TermsEntity,
  IMeter,
  AdvancedTerm,
  IAvailabilityActions,
  CatalogOfferType,
} from '@shared/Models';
import { getTermPriorityForSorting } from '@shared/utils/pricing';

export class Product {
  /** List of term duration  */
  TermsPerPlanMapping: { [planId: string]: TermsStrings[] } = {};
  /** Equivalent to entityId <PublisherId.OfferId> */
  legacyId: string;
  bigId: string;
  plans: MPRPPlansEntity[];
  isProfessionalService: boolean;
  offerType: CatalogOfferType;

  private termsByPlanId: { [planId: string]: AdvancedTerm[] } = {};
  /** This is unused yet, but is preparation for the future when AppSource would contain apps with custom meters */
  private metersByPlanId: { [planId: string]: IMeter[] } = {};

  constructor({ legacyId, bigId, plans, offerType }: MPRPAppData) {
    this.legacyId = legacyId;
    this.bigId = bigId;
    this.plans = plans;
    this.offerType = offerType;
    this.isProfessionalService = plans.some(({ isHiddenPrivateOffer }) => isHiddenPrivateOffer);

    this.populateTermsByPlanIdMap();
    this.populateUniqueTermsPerPlan();
  }

  /**
   * Extract a specific availability from the plan's availabilities
   * The termId depends on the plan, and availability properties: termUnits (P1Y, month, ...), free trial and billing plan;
   */
  getPlanAvailabilityByTermId = (planId: string, termId: string): MPRPAvailabilities => {
    // Get the selected plan
    const plan = this.plans.find((plan) => plan.planId === planId);

    // AppsSource only has direct commercial customers (no CSP or special type pricingAudience).
    // There should *always* be availabilities at this point since we filter by billing region prior to fetching the item
    const directAvailabilities = (plan.availabilities || []).filter(
      (availability) =>
        availability.pricingAudience === PricingAudience.DirectCommercial &&
        availability.actions.includes(IAvailabilityActions.Browse)
    );

    // Filter by term, there could be multiple billingPlans with the same duration (termUnits) so this could still be an array
    return (
      directAvailabilities.find((availability) => availability.terms.some((term) => term.termId === termId)) ||
      ({} as MPRPAvailabilities)
    );
  };

  /** Extract default availability by planId */
  getFirstTermFromPlan = (planId: string): AdvancedTerm => {
    return this.termsByPlanId[`${planId}`]?.[0];
  };

  private populateTermsByPlanIdMap() {
    // Populates terms by planId
    this.plans.forEach((plan: MPRPPlansEntity) => {
      const tempPlanTerms: TermsEntity[] = [];
      this.termsByPlanId[`${plan.planId}`] = [];
      /** Meters are unused yet, but is preparation for the future when AppSource would contain apps with custom meters */
      this.metersByPlanId[`${plan.planId}`] = [];

      plan.availabilities.forEach((a: MPRPAvailabilities) => {
        tempPlanTerms.push(...a.terms);

        /** Meters are unused yet, but is preparation for the future when AppSource would contain apps with custom meters */
        this.metersByPlanId[`${plan.planId}`].push(a.meter);
      });

      const unsortedTerms = tempPlanTerms.reduce((accumulatedArray: AdvancedTerm[], curr: TermsEntity) => {
        // in Professional Service offer type the renewTermUnit can be null
        if (this.isProfessionalService && !curr.renewTermId) {
          curr.renewTermId = curr.termId;
        }
        // Iterate over all non-free trial terms (termId===renewTermId)
        if (curr.renewTermId === curr.termId) {
          let freeTrialTermId = '';
          let freeTrialTermUnit: TermsStrings;

          // Find relevant free trial term for specific termId
          const freeTrialTerm = tempPlanTerms.find(
            (term) => term.renewTermId === curr.termId && term.renewTermId !== term.termId
          );

          // Populate free trial info for term
          if (freeTrialTerm) {
            freeTrialTermId = freeTrialTerm.termId;
            freeTrialTermUnit = freeTrialTerm.termUnits;
          }

          // Only added if non-free trial. Meaning we have a merged term with both freeTrial termId as well as non-free trial termId
          accumulatedArray.push({ ...curr, freeTrialTermId, freeTrialTermUnit });
        }
        return accumulatedArray;
      }, []);
      this.termsByPlanId[`${plan.planId}`] = this.sortTermPrices(unsortedTerms);
    });
  }

  /** Extract all unique terms durations of product. Relies on renewTermId rather than termId */
  private populateUniqueTermsPerPlan(): void {
    this.plans.forEach((plan) => {
      const uniqueTerms: TermsStrings[] = [];

      const termsOfAllAvailabilitiesByPlan = plan.availabilities.map((avail) => avail.terms);
      termsOfAllAvailabilitiesByPlan.forEach((terms: TermsEntity[]) => {
        terms.forEach((term) => {
          // in Professional Service offer type the renewTermUnit can be null
          if (this.isProfessionalService && !term.renewTermUnits) {
            term.renewTermUnits = term.termUnits;
          }
          if (!uniqueTerms.includes(term.renewTermUnits)) {
            uniqueTerms.push(term.renewTermUnits);
          }
        });
      });

      this.TermsPerPlanMapping[plan.planId] = uniqueTerms;
    });
  }

  /** Returns the distinct of possible term durations by planId */
  getTermDurationsByPlanId = (planId: string) => {
    return this.TermsPerPlanMapping[`${planId}`];
  };

  /** Helper method to get selected plan by planId rather than access plans array by index */
  getPlanByPlanId = (targetPlanId: string): MPRPPlansEntity => {
    const plan = this.plans.find((plan: MPRPPlansEntity) => plan.planId === targetPlanId);
    return plan;
  };

  /** Used to get terms entity by termId. TermId should be the renew termId  */
  getFirstTermByPlanId = (planId: string): AdvancedTerm => {
    return this.termsByPlanId[`${planId}`]?.[0];
  };

  /** Used to get terms entity by termId. TermId should be the renew termId  */
  getTermByTermId = ({ planId, termId }: { planId: string; termId: string }): AdvancedTerm => {
    return this.termsByPlanId[`${planId}`].find((term) => {
      // Compare the given termId with either the free trial termId or non free trial termId
      const foundTerm = termId === term.termId || termId === term.freeTrialTermId;
      return foundTerm;
    });
  };

  /** Returns a new array of ascending sorted term prices. First by billing term and if the billing terms are equal then by the billing period frequency */
  sortTermPrices = (termPrices?: AdvancedTerm[]): AdvancedTerm[] => {
    if (!termPrices?.length) {
      return [];
    }

    // Cloning before calling ".sort" is a must because ".sort" sorts the array in-place.
    return [...termPrices].sort((a, b) => {
      if (a.renewTermUnits === b.renewTermUnits) {
        return getTermPriorityForSorting(a.billingPlan?.billingPeriod) - getTermPriorityForSorting(b.billingPlan?.billingPeriod);
      }

      return getTermPriorityForSorting(a.renewTermUnits) - getTermPriorityForSorting(b.renewTermUnits);
    });
  };

  getTermByPlanAndBillingTermDuration = ({
    planId,
    billingTermDuration,
  }: {
    planId: string;
    billingTermDuration: TermsStrings;
  }): AdvancedTerm => {
    return this.termsByPlanId[`${planId}`].filter((term) => term.renewTermUnits === billingTermDuration)?.[0];
  };
}
