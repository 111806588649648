import { Logger } from './base';
import { stringifyError } from '@shared/utils/errorUtils';

export enum ClientLogsCollection {
  Telemetry = 'Telemetry',
  Feedback = 'Feedback',
}

interface ClientLogPayload {
  collection?: ClientLogsCollection;
  action?: string;
  actionModifier?: string;
  details?: string;
  appName?: string;
  page?: string;
  product?: string;
  error?: any;
}

export class ClientLoggerTelemetry extends Logger {
  public info({
    error,
    collection = ClientLogsCollection.Telemetry,
    action = '',
    actionModifier = '',
    details = '',
    appName = '',
    page = '',
    product = '-1',
  }: ClientLogPayload) {
    this.log('Info', collection, {
      details,
      appName,
      product,
      page,
      error: error ? stringifyError(error) : '',
      action,
      actionModifier,
    });
  }

  public debug({
    error,
    collection = ClientLogsCollection.Telemetry,
    action = '',
    actionModifier = '',
    details = '',
    appName = '',
    page = '',
    product = '-1',
  }: ClientLogPayload) {
    this.log('Debug', collection, {
      details,
      appName,
      product,
      page,
      error: error ? stringifyError(error) : '',
      action,
      actionModifier,
    });
  }

  public warning({
    error,
    collection = ClientLogsCollection.Telemetry,
    action = '',
    actionModifier = '',
    details = '',
    appName = '',
    page = '',
    product = '-1',
  }: ClientLogPayload) {
    this.log('Warn', collection, {
      details,
      appName,
      product,
      page,
      error: error ? stringifyError(error) : '',
      action,
      actionModifier,
    });
  }

  public error({
    error,
    collection = ClientLogsCollection.Telemetry,
    action = '',
    actionModifier = '',
    details = '',
    appName = '',
    page = '',
    product = '-1',
  }: ClientLogPayload) {
    this.log('Error', collection, {
      details,
      appName,
      product,
      page,
      error: error ? stringifyError(error) : '',
      action,
      actionModifier,
    });
  }
}
