import { IAccessToken } from '@src/State';

export const mergeAccessToken = ({
  currentAccessToken,
  newAccessToken,
}: {
  currentAccessToken: IAccessToken;
  newAccessToken: IAccessToken;
}) => {
  return Object.keys(newAccessToken).reduce((previousObject, currentKey) => {
    return { ...previousObject, [currentKey]: newAccessToken[`${currentKey}`] || currentAccessToken[`${currentKey}`] };
  }, currentAccessToken);
};
