import React from 'react';
import { mergeStyleSets } from '@fluentui/react';
import { ILocContext } from '@shared/interfaces/context';
import * as PropTypes from 'prop-types';
import { NeutralColors } from '@fluentui/theme';
import { AppReviewCommentCollectionPaginationButton } from '@shared/components/appReviewCommentCollection';

const contentStyles = mergeStyleSets({
  commentsContainer: {
    marginBottom: '20px',
  },
  paginationContainer: {
    marginTop: '10px',
  },
  emptyMessage: {
    margin: '15px 0',
    color: NeutralColors.gray130,
  },
  loader: {
    margin: '25px 0',
  },
});

interface AppReviewItemCommentPaginationProps {
  currentPage: number;
  totalPageCount: number;
  onSeeMoreClick: () => void;
  onSeeLessClick: () => void;
}

export function AppReviewItemCommentPagination(
  { currentPage, totalPageCount, onSeeMoreClick, onSeeLessClick }: AppReviewItemCommentPaginationProps,
  context: ILocContext
) {
  return (
    <div className={contentStyles.paginationContainer}>
      {currentPage < totalPageCount && (
        <AppReviewCommentCollectionPaginationButton
          data-bi-id="reviewCommentsPaginationSeeMore"
          data-bi-name="Review Comments Section See More Button"
          onClick={() => onSeeMoreClick()}
          text={context.loc('ReviewItem_CommentSection_LoadMore_Button_Label', 'See more')}
          iconName="ChevronDown"
        />
      )}
      {totalPageCount > 1 && currentPage >= totalPageCount && (
        <AppReviewCommentCollectionPaginationButton
          data-bi-id="reviewCommentsPaginationSeeLess"
          data-bi-name="Review Comments Section See Less Button"
          onClick={() => onSeeLessClick()}
          text={context.loc('ReviewItem_CommentSection_Collapse_Button_Label', 'See less')}
          iconName="ChevronUp"
        />
      )}
    </div>
  );
}
(AppReviewItemCommentPagination as any).contextTypes = {
  loc: PropTypes.func,
};
