import AuthClient from './client';
import { getWindow } from '@shared/services/window';
import { Constants } from '@shared/utils/constants';
import { routes } from '@shared/routerHistory';
import { logger } from '@src/logger';
import { stringifyError } from '@shared/utils/errorUtils';

export const postRedirect = async () => {
  try {
    const tokenResponse = await AuthClient.getInstance().handleRedirectPromise();
    if (tokenResponse) {
      const { state } = tokenResponse;
      logger.info(JSON.stringify({ state }), {
        action: Constants.Telemetry.Action.Login,
        actionModifier: Constants.Telemetry.ActionModifier.AuthRedirectSucceed,
      });
    } else if (getWindow().location.pathname === routes.redirect.getPath()) {
      // User navigated to redirect route and didn't come from AAD, so will be redirected to homepage as a fallback
      const fallbackRoute = routes.home.getPath();
      logger.warning(JSON.stringify({ to: fallbackRoute }), {
        action: Constants.Telemetry.Action.Login,
        actionModifier: Constants.Telemetry.ActionModifier.AuthRedirectNotFromAAD,
      });
      getWindow().location.href = fallbackRoute;
    }
  } catch (error) {
    logger.error(stringifyError(error), {
      action: Constants.Telemetry.Action.Login,
      actionModifier: Constants.Telemetry.ActionModifier.AuthRedirectFailed,
      error,
    });
  }
};
