/* istanbul ignore file */
import React, { Suspense, useMemo, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IState } from '@src/State';
import LoadingSpinner from '@shared/components/LoadingSpinner';

const withSuspense = (Component: React.ReactNode) => {
  return <Suspense fallback={<LoadingSpinner />}>{Component}</Suspense>;
};

interface UseClientRoutesOptions {
  routes: RouteObject[];
}

export function useClientRoutes(options: UseClientRoutesOptions): RouteObject[] {
  const { routes } = options;
  const [localeRoutes, ...otherRoutes] = routes;
  const location = useLocation();

  const [ssrEntry] = useState(() => location.pathname);
  const isEmbedded = useSelector<IState, boolean>((state) => state.config.isEmbedded);

  return useMemo(() => {
    const children = localeRoutes.children.map((route) => {
      const path = `/:locale/${route.path ?? ''}`;
      const matchingPath = matchPath(path, ssrEntry);

      const shouldWrapWithSuspense = isEmbedded || !(route.index || matchingPath?.pattern?.path === path);

      return {
        ...route,
        element: shouldWrapWithSuspense ? withSuspense(route.element) : route.element,
      };
    });

    return [{ ...localeRoutes, children }, ...otherRoutes];
  }, [ssrEntry, routes, isEmbedded]);
}
