/* eslint-disable react/forbid-dom-props */
import { IDataItem, ITelemetryData, IURLQuery } from '@shared/Models';
import * as React from 'react';
import * as PropTypes from 'prop-types';
import SpzaComponent from '@shared/components/spzaComponent';
import { Constants } from '@shared/utils/constants';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { ILocContext, ILocParamsContext, IBuildHrefContext, ICommonContext } from '@shared/interfaces/context';
import PaginationControl from '@shared/components/paginationControl';
import { getWindow } from '@shared/services/window';
import { shouldShowPowerAppsNoContent, getPowerAppsTitle } from '../../embed/embedHostUtils';
import { getFilteredAppPageTitle } from '@shared/utils/localization';
import { DataMap } from '@shared/utils/dataMapping';
import { urlPush, routes } from '@shared/routerHistory';
import Animation from '@shared/components/animation';
import { getEntityRegistration } from '@shared/utils/entityRegistration';
import { NoSearchResults } from '@shared/components/noSearchResults';
import { logger } from '@src/logger';
import powerApps from '@shared/images/powerApps.png';
import { getProductByUrlKey } from '@shared/utils/appUtils';

export interface IFilteredGalleryProps {
  query: IURLQuery;
  galleryPage: number;
  pageSize: number;
  filteredData: IDataItem[];
  count: number;
  publicAppsCount?: number;
  privateAppsCount?: number;
  searchText?: string;
  showPrivateApps: boolean;
  isEmbedded: boolean;
  partnerAppDataLoaded: boolean;
  partnerAppDataLoadedError: boolean;
  embedHost: string;
  entityType: Constants.EntityType;
  isMobile: boolean;
  selectedLocale: string;
  selectedCountry: string;
  isSelectedFilters: boolean;
  changeSearchText: (searchvalue: string) => void;
  microsoft365WhatsNewItems: IDataItem[];
  microsoft365TrendingItems: IDataItem[];
}

export default class AppSourceFilteredGallery extends SpzaComponent<IFilteredGalleryProps, any> {
  context: ILocParamsContext & ILocContext & IBuildHrefContext & ICommonContext;
  filteredData: IDataItem[] = [];
  galleryPage = 0;
  maxPages = 0;
  defaultPageSize = !this.props.isMobile ? Constants.FilteredGallery.pageSize : 10;

  componentDidMount() {
    const perfPayload: ITelemetryData = {
      page: getWindow()?.location?.href,
      action: Constants.Telemetry.Action.PageLoad,
      actionModifier: Constants.Telemetry.ActionModifier.End,
    };
    SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', perfPayload);
    logger.info('', {
      action: perfPayload.action,
      actionModifier: perfPayload.actionModifier,
    });
    document.getElementsByTagName('title')[0].innerHTML = getFilteredAppPageTitle(this.context);
  }

  setPageSize() {
    this.filteredData = [];

    this.galleryPage = this.props.galleryPage ? this.props.galleryPage : 1;
    // todo: validate rendering second page of tiles and validate props being passed into pagination control
    const pageSize = this.props.pageSize ? this.props.pageSize : this.defaultPageSize;

    this.maxPages = Math.ceil(this.props.count / pageSize);
    this.filteredData = this.props.filteredData.slice();
  }

  setMaxPages(itemCount: number, pageSize: number) {
    this.maxPages = Math.ceil(itemCount / pageSize);
    if (this.galleryPage > this.maxPages) {
      this.galleryPage = this.maxPages;
    }
  }

  getTiles() {
    const entityRegistration = getEntityRegistration(this.props.entityType);
    const TileType = entityRegistration.tileType;

    return (
      <div className="spza_filteredTileContainer">
        {this.filteredData.map((entity: IDataItem, index: number) => {
          return (
            <TileType
              {...entity}
              item={entity}
              key={entity.entityId}
              tileIndex={index}
              totalTiles={this.filteredData.length}
              selectedLocale={this.props.selectedLocale}
              embedHost={this.props.embedHost}
              countryContext={this.props.selectedCountry}
              ribbonKey={'FilteredGallery_AllResults'}
            />
          );
        })}
      </div>
    );
  }

  logNoContentTelemetry(link: string, linkType: string) {
    const details = {
      linkType,
      link,
      tab: 'Default',
    };
    const payload: ITelemetryData = {
      page: 'In App Gallery(' + getProductByUrlKey({ urlKey: this.props.embedHost })?.UrlKey + ')',
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.Link,
      details: JSON.stringify(details),
    };
    SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });
  }

  logAndNavigate(link: string, linkType: string) {
    this.logNoContentTelemetry(link, linkType);
    urlPush(link);
  }

  getNoContentUI() {
    // We have 3 cases for 'No Content' UIs
    // 1) Apps tab for all products
    // 2) My Org tab for only power-bi
    // 3) My Org tab for dynamics-365
    const publicGalleryUri = this.context.buildHref(routes.marketplace, null, {
      showPrivateApps: 'false',
      page: '1',
    });

    const privateGalleryUri = this.context.buildHref(routes.marketplace, null, {
      showPrivateApps: 'true',
      page: '1',
    });

    // This is No Content UI for Apps tab
    if (!this.props.showPrivateApps) {
      return (
        <div className="noOrgContent">
          <div>{this.props.searchText ? this.context.loc('Search_NoApps') : this.context.loc('FilteredGallery_NoApps')}</div>
          <div>
            {this.props.searchText ? (
              this.props.privateAppsCount ? (
                <span
                  className="switchPage"
                  onClick={() => {
                    this.logAndNavigate(privateGalleryUri, 'SwitchTo_MyOrg');
                  }}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: this.context.locParams('Search_SwitchTab', [
                      '<span>' + this.context.loc('Embedded_MyOrganization') + '</span>',
                      this.props.privateAppsCount.toString(),
                    ]),
                  }}
                ></span>
              ) : (
                <span
                  className="switchPage"
                  onClick={() => {
                    this.logNoContentTelemetry(
                      '"https://appsource.microsoft.com/en-us/marketplace/apps?search=' +
                        encodeURIComponent(this.props.searchText) +
                        '&page=1"',
                      'AppsTab_SwitchTo_Appsource'
                    );
                  }}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: this.context.locParams('Search_SimilarApps', [
                      '<a href="https://appsource.microsoft.com/en-us/marketplace/apps?search=' +
                        encodeURIComponent(this.props.searchText) +
                        '&page=1" target="_blank">Appsource.com</a>',
                    ]),
                  }}
                ></span>
              )
            ) : null}
          </div>
        </div>
      );
    }

    // This is No Content UI for Power BI My Org tab
    if (this.props.embedHost === DataMap.products.PowerBI.UrlKey) {
      return (
        <div className="noOrgContent">
          <div>
            {this.props.searchText ? (
              this.props.publicAppsCount ? (
                <div>
                  <span>{this.context.loc('Search_NoApps')}</span>
                  <span
                    className="switchPage shiftLeft"
                    onClick={() => {
                      this.logAndNavigate(publicGalleryUri, 'SwitchTo_Apps');
                    }}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: this.context.locParams('Search_SwitchTab', [
                        '<span>' + this.context.loc('Embedded_Apps') + '</span>',
                        this.props.publicAppsCount.toString(),
                      ]),
                    }}
                  ></span>
                </div>
              ) : (
                <div>
                  <span>{this.context.loc('Search_NoApps')}</span>
                  <span
                    className="switchPage shiftLeft"
                    onClick={() => {
                      this.logNoContentTelemetry(
                        '"https://appsource.microsoft.com/en-us/marketplace/apps?search=' +
                          encodeURIComponent(this.props.searchText) +
                          '&page=1"',
                        'MyOrgTab_SwitchTo_Appsource'
                      );
                    }}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: this.context.locParams('Search_SimilarApps', [
                        '<a href="https://appsource.microsoft.com/en-us/marketplace/apps?search=' +
                          encodeURIComponent(this.props.searchText) +
                          '&page=1" target="_blank">Appsource.com</a>',
                      ]),
                    }}
                  ></span>
                </div>
              )
            ) : (
              <div>{this.context.loc('Embedded_No_MyOrg_Content_PowerBI')}</div>
            )}
          </div>
          <div
            className="powerAppsCreate"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: this.context.locParams('Embedded_PowerBI_Create_Link', [
                '<a href="https://powerbi.microsoft.com/en-us/documentation/powerbi-service-organizational-content-pack-tutorial-create-and-publish/" target="_blank">' +
                  this.context.loc('Embedded_PowerBI_Create_Link_Learn_Link_Text') +
                  '</a>',
              ]),
            }}
            onClick={() => {
              this.logNoContentTelemetry(
                'https://powerbi.microsoft.com/en-us/documentation/powerbi-service-organizational-content-pack-tutorial-create-and-publish/',
                'pbi_CreateContentPacks'
              );
            }}
          ></div>
        </div>
      );
    } else {
      // This is No Content UI for Dynamics-365 My Org tab
      return (
        <div className="noOrgContent">
          <div>{this.props.searchText ? this.context.loc('Search_NoApps') : null}</div>
          <div>
            {this.props.searchText ? (
              this.props.publicAppsCount ? (
                <span
                  className="switchPage"
                  onClick={() => {
                    this.logAndNavigate(publicGalleryUri, 'SwitchTo_Apps');
                  }}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: this.context.locParams('Search_SwitchTab', [
                      '<span>' + this.context.loc('Embedded_Apps') + '</span>',
                      this.props.publicAppsCount.toString(),
                    ]),
                  }}
                ></span>
              ) : (
                <span
                  className="switchPage"
                  onClick={() => {
                    this.logNoContentTelemetry(
                      '"https://appsource.microsoft.com/en-us/marketplace/apps?search=' +
                        encodeURIComponent(this.props.searchText) +
                        '&page=1"',
                      'MyOrgTab_SwitchTo_Appsource'
                    );
                  }}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: this.context.locParams('Search_SimilarApps', [
                      '<a href="https://appsource.microsoft.com/en-us/marketplace/apps?search=' +
                        encodeURIComponent(this.props.searchText) +
                        '&page=1" target="_blank">Appsource.com</a>',
                    ]),
                  }}
                ></span>
              )
            ) : (
              <div>
                {this.props.partnerAppDataLoadedError
                  ? this.context.loc('Embedded_No_MyOrg_Content_Error')
                  : this.context.loc('Embedded_No_MyOrg_Content_PowerBI')}
              </div>
            )}
          </div>
          {this.props.partnerAppDataLoadedError ? null : (
            <div className="actions">
              <a
                className="action powerApps"
                href="https://web.powerapps.com/"
                rel="noreferrer"
                target="_blank"
                onClick={() => {
                  this.logNoContentTelemetry('https://web.powerapps.com/', 'powerApps_Create');
                }}
              >
                <div className="title">{this.context.loc('Embedded_No_MyOrg_Content_Action2_Title')}</div>
                <img src={powerApps} />
                <div className="bottom c-glyph">{getPowerAppsTitle()}</div>
              </a>
            </div>
          )}
        </div>
      );
    }
  }

  renderImpl() {
    const { query, entityType, partnerAppDataLoaded, showPrivateApps, isEmbedded, embedHost } = this.props;
    this.setPageSize();

    if (isEmbedded && shouldShowPowerAppsNoContent(embedHost) && showPrivateApps && !this.filteredData.length) {
      const context = this.context;

      if (partnerAppDataLoaded) {
        return <div className="filteredGalleryContainer">{this.getNoContentUI()}</div>;
      } else {
        return (
          <div className="filteredGalleryContainer">
            <div className="loader">
              <Animation />
              <div className="text">{context.loc('Loading')}</div>
            </div>
          </div>
        );
      }
    }

    if (isEmbedded && this.filteredData.length === 0) {
      return <div className="filteredGalleryContainer">{this.getNoContentUI()}</div>;
    }

    if (this.filteredData?.length === 0) {
      const { searchText, isSelectedFilters, entityType, changeSearchText } = this.props;
      return (
        <NoSearchResults
          context={this.context}
          isSearch={!!searchText}
          isFilter={isSelectedFilters}
          entityType={entityType}
          currentPage={getWindow()?.location?.href}
          changeSearchText={changeSearchText}
        />
      );
    }

    // DOES SPZA_CONTROLS DIV NEED TO BE HERE?
    return (
      <div className="filteredGalleryContainer">
        <div id="pagechange_id"></div>
        {this.getTiles()}
        <PaginationControl query={query} galleryPage={this.galleryPage} maxPages={this.maxPages} entityType={entityType} />
      </div>
    );
  }
}

(AppSourceFilteredGallery as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  buildHref: PropTypes.func,
  renderErrorModal: PropTypes.func,
};
