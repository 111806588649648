import { Constants } from '@shared/utils/constants';
import { addClassToElement, getElement, removeClassToFirstMatchedElement } from '@shared/utils/appUtils';
import { IUserDataState } from './../../State';

export function shouldHideUserFavouriteNavBarButton(currentView: string) {
  return false;
}

function geQueryByDropdownItemClassName(className: string, isActive: boolean) {
  const activeClassNameSuffix = isActive ? `.${Constants.UserComponent.activeClassName}` : '';

  const queryToPrevActiveItem =
    `.${Constants.UserComponent.dropdownActiveOptionContainerClassName} ` + `.${className}${activeClassNameSuffix}`;
  return queryToPrevActiveItem;
}

export function getActiveItemIndexOfUserMobileDropdown() {
  let activeIndex = -1;

  Constants.UserComponent.dropdownItemClassNameArray.some((className, arrayIndex) => {
    const query = geQueryByDropdownItemClassName(className, true);
    const match = !!getElement(Constants.SearchBy.QuerySelector, query);

    if (match) {
      activeIndex = arrayIndex;
    }
    return match;
  });

  return activeIndex;
}

export function addActiveClassNameToMobileUserDropdownItem(
  dropdownItemName: Constants.UserComponent.DropdownItemName,
  isMobileDropdown: boolean
) {
  if (!isMobileDropdown) {
    return;
  }

  const newActiveItemIndexClassName = Constants.UserComponent.dropdownItemNameToClassName[`${dropdownItemName}`];
  const newActiveItemIndex = Constants.UserComponent.dropdownItemClassNameArray.indexOf(newActiveItemIndexClassName);

  const prevActiveItemIndex: number = getActiveItemIndexOfUserMobileDropdown();

  // if active dropdown item has not changed, simply return
  if (newActiveItemIndex === prevActiveItemIndex) {
    return;
  }

  // remove active classname to previous active dropdown item
  if (prevActiveItemIndex >= 0) {
    const queryToPrevActiveItem = geQueryByDropdownItemClassName(
      Constants.UserComponent.dropdownItemClassNameArray[`${prevActiveItemIndex}`],
      true
    );
    removeClassToFirstMatchedElement(
      Constants.SearchBy.QuerySelector,
      queryToPrevActiveItem,
      Constants.UserComponent.activeClassName
    );
  }

  // set active classname to new active dropdown item
  const newActiveItemClassName = Constants.UserComponent.dropdownItemClassNameArray[`${newActiveItemIndex}`];
  const queryToNewActiveItem = geQueryByDropdownItemClassName(newActiveItemClassName, false);
  addClassToElement(Constants.SearchBy.QuerySelector, queryToNewActiveItem, Constants.UserComponent.activeClassName);
}

export const isMaccUser = (userInfo: IUserDataState): boolean => {
  const { isMaccTenant, accounts: { isEAAccount } = {} } = userInfo;
  return isMaccTenant || isEAAccount;
};
