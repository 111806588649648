import type { IPageViewOverrideValues, IPageActionOverrideValues } from '@microsoft/1ds-wa-js';
import { ApplicationInsights } from '@microsoft/1ds-analytics-web-js';
import initBusinessTelemetry from '@shared/services/telemetry/1DSClient';
import { logger } from '@src/logger';

const telemetryClient = () => {
  let telemetryClient: ApplicationInsights = null;
  return () => {
    if (!telemetryClient) {
      telemetryClient = initBusinessTelemetry();
    }
    return telemetryClient;
  };
};

const getTelemetryClient = telemetryClient();

export const initBusinessTelemetryClient = getTelemetryClient;

export const useTelemetry = () => {
  const pageView = async (
    overrideValues?: IPageViewOverrideValues,
    customProperties?: {
      [name: string]: string | number | boolean | string[] | number[] | boolean[] | object;
    }
  ): Promise<void> => {
    const telemetryClient = getTelemetryClient();
    telemetryClient.capturePageView(overrideValues, customProperties);
    logger.clientLogger.logger.capturePageView(overrideValues, customProperties);
  };
  const pageAction = async (
    element: Element,
    overrideValues?: IPageActionOverrideValues,
    customProperties?: {
      [name: string]: string | number | boolean | string[] | number[] | boolean[] | object;
    },
    isRightClick?: boolean
  ) => {
    const telemetryClient = getTelemetryClient();
    telemetryClient.capturePageAction(element, overrideValues, customProperties, isRightClick);
    logger.clientLogger.logger.capturePageAction(element, overrideValues, customProperties, isRightClick);
  };
  const flushTelemetryBuffer = () => {
    const telemetryClient = getTelemetryClient();
    telemetryClient.getPostChannel().flush(false);
    logger.flush();
  };

  return [
    { pageAction, pageView, flushTelemetryBuffer },
    {
      telemetryClient,
    },
  ];
};
