import * as PropTypes from 'prop-types';
import * as React from 'react';

import { ActionButton, Checkbox, TooltipHost, FontIcon, Stack, Text, ScreenWidthMinXLarge, mergeStyles } from '@fluentui/react';
import type { ICheckboxStyles, ITooltipHostStyles, IButtonStyles, INavButtonProps } from '@fluentui/react';
import { IBuildHrefContext, ICommonContext, ILocContext } from '@shared/interfaces/context';

import SpzaComponent from '@shared/components/spzaComponent';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const classNames = require('classnames-minimal');

const checkboxFilterPadding = mergeStyles({
  padding: '15px 5px',
  [`@media (max-width: ${ScreenWidthMinXLarge}px)`]: {
    padding: '15px 5px 20px 20px',
  },
});

export interface IFilterLinkProps extends INavButtonProps {
  isRoot: boolean;
  isGroup: boolean;
  isChild: boolean;
  isActive: boolean;
  activeChildren: number;
  locKey: string;
  localizedName: string;
  searchValue: string;
  tooltip: string;
  checkboxTooltip?: JSX.Element;
}

export class FilterLink extends SpzaComponent<IFilterLinkProps, unknown> {
  context: ILocContext & ICommonContext & IBuildHrefContext;
  styles: Partial<IButtonStyles> = {
    flexContainer: {
      alignItems: this.props.isRoot ? 'baseline' : 'center',
    },
  };

  getFilterName(): string {
    return `${this.context.loc(this.props.locKey, this.props.localizedName)}${
      this.props.activeChildren > 0 ? ` (${this.props.activeChildren})` : ''
    }`;
  }

  renderCheckBoxFilter = () => {
    const { isActive, checkboxTooltip, onClick } = this.props;
    return (
      <Stack className={checkboxFilterPadding} horizontal tokens={{ childrenGap: 5 }} onClick={onClick}>
        <Checkbox checked={isActive} ariaLabel={this.getFilterName()} />
        <Text>{this.getFilterLabel()}</Text>
        <TooltipHost content={checkboxTooltip} id="tooltipContent">
          <FontIcon iconName="Info" />
        </TooltipHost>
      </Stack>
    );
  };

  getFilterLabel(): JSX.Element {
    const name = this.getFilterName();
    const start = name.toLowerCase().indexOf(this.props.searchValue);

    if (!this.props.searchValue || start === -1) {
      return <span title={name}>{name}</span>;
    }

    const end = start + this.props.searchValue.length - 1;

    return (
      <span>
        {name.substr(0, start)}
        <b>{name.substr(start, end - start + 1)}</b>
        {name.substr(end + 1)}{' '}
      </span>
    );
  }

  renderImpl() {
    const hostStyles: Partial<ITooltipHostStyles> = { root: { marginLeft: 5 } };
    const checkboxStyles: Partial<ICheckboxStyles> = { checkbox: { marginRight: 0 } };
    const buttonFocused = !this.props.isGroup && !this.props.isChild;

    return this.props.checkboxTooltip ? (
      this.renderCheckBoxFilter()
    ) : (
      <Stack
        horizontal
        verticalAlign="center"
        className={`${this.props.className} filterLinkButton ${classNames({
          isGroup: this.props.isGroup,
          isChild: this.props.isChild,
          isActive: this.props.isActive,
        })}`}
      >
        {this.props.isChild ? (
          <Stack.Item className="filterCheckbox" onClick={this.props.onClick}>
            <Checkbox styles={checkboxStyles} checked={this.props.isActive} ariaLabel={this.getFilterName()} />
          </Stack.Item>
        ) : null}
        <Stack.Item>
          <ActionButton
            onClick={this.props.onClick}
            styles={this.styles}
            data-is-focusable={buttonFocused}
            aria-expanded={this.props.link?.links?.length ? this.props.isActive : undefined}
            aria-current={this.props.isActive ? 'page' : undefined}
          >
            <div className="filterLabel">{this.getFilterLabel()}</div>
            {this.props.tooltip && (
              <div tabIndex={0}>
                <TooltipHost
                  styles={hostStyles}
                  content={this.context.loc(this.props.tooltip, this.props.tooltip)}
                  id="tooltipContent"
                >
                  <FontIcon iconName="Info" />
                </TooltipHost>
              </div>
            )}

            {this.props.children && <>{this.props.children}</>}
          </ActionButton>
        </Stack.Item>
      </Stack>
    );
  }
}

(FilterLink as any).contextTypes = {
  loc: PropTypes.func,
  renderErrorModal: PropTypes.func,
  buildHref: PropTypes.func,
};
