import { IBillingCountry, IPrice, ISimpleSKU } from '@shared/Models';
import React from 'react';
import * as PropTypes from 'prop-types';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { Text, mergeStyleSets } from '@fluentui/react';
import type { IStyle, IFontStyles } from '@fluentui/react';
import { getPriceWithFrequencyText } from '@shared/utils/pricing';
import { CustomMeterPricing } from '@shared/components';
import FuturePriceTooltip from '@shared/components/futurePriceTooltip';

interface SaasPricingCellProps {
  sku: ISimpleSKU;
  futurePriceSku?: ISimpleSKU;
  price: IPrice;
  billingCountry: IBillingCountry;
  shouldBlockPrices?: boolean;
  locale?: string;
}

interface SaasPricingContentProps {
  sku: ISimpleSKU;
  price: IPrice;
  billingCountry: IBillingCountry;
  variant?: keyof IFontStyles;
  locale?: string;
}

interface Styles {
  pricingCellRoot: IStyle;
}

const classes = mergeStyleSets<Styles>({
  pricingCellRoot: {
    whiteSpace: 'break-spaces',
  },
});

const SaasPricingContent = (
  { sku, price, billingCountry, variant, locale }: SaasPricingContentProps,
  context: ILocContext & ILocParamsContext
) => {
  const { countryCode } = billingCountry;

  const priceText = getPriceWithFrequencyText({
    context,
    priceObject: price,
    isQuantifiable: sku.isQuantifiable,
    countryCode,
    currency: sku.currencyCode,
    maximumFractionDigits: 2,
    onetimePaymentTranslation: 'Pricing_Onetime_Payment',
    locale,
  });

  const freeTrialText = price.freeDurationDays ? context.loc('First_Month_Free_Trial', 'First month free, then ') : ''; // SaaS offers can only have 30 days(1 month) trial
  const fullPricingText = `${freeTrialText}${priceText}`;

  return (
    <>
      <Text variant={variant}>{fullPricingText}</Text>
      <CustomMeterPricing sku={sku} billingCountry={billingCountry} price={price} variant={variant} locale={locale} />
    </>
  );
};

export const SaasPricingCell = (
  { sku, futurePriceSku, price, billingCountry, shouldBlockPrices, locale }: SaasPricingCellProps,
  context: ILocContext & ILocParamsContext
) => {
  if (shouldBlockPrices) {
    return (
      <Text>
        {context.loc('Pricing_ContactPublisher', 'Contact the publisher for pricing information and to purchase the license')}
      </Text>
    );
  }

  const futurePriceTerm = futurePriceSku?.termPrices?.find((futurePriceTerm) => futurePriceTerm.termId === price.termId);

  return (
    <div className={classes.pricingCellRoot}>
      <SaasPricingContent sku={sku} price={price} billingCountry={billingCountry} locale={locale} />
      {futurePriceTerm && (
        <FuturePriceTooltip
          futurePrice={{
            priceComponent: (
              <div>
                <SaasPricingContent
                  sku={futurePriceSku}
                  price={futurePriceTerm}
                  billingCountry={billingCountry}
                  variant="small"
                  locale={locale}
                />
              </div>
            ),
            startDate: futurePriceSku.startDate,
          }}
        />
      )}
    </div>
  );
};

(SaasPricingCell as any).contextTypes = {
  locParams: PropTypes.func,
  loc: PropTypes.func,
};

(SaasPricingContent as any).contextTypes = {
  locParams: PropTypes.func,
  loc: PropTypes.func,
};
