// eslint-disable-next-line spaced-comment
///<reference types="@wcp/wcp-consent" />
import React, { useEffect, useState } from 'react';
import { Constants } from '@shared/utils/constants';
import { ITelemetryData } from '@shared/Models';
import { getWindow, getDocument } from '@shared/services/window';
import { stringifyError } from '@shared/utils/errorUtils';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { logger } from '@src/logger';
import { MsClarity, MsClarityEnabledEnvironments } from '@shared/components/msClarity';
import { COMSCORE_URL } from '@appsource/utils/comscore';
import * as appConfig from '@shared/services/init/appConfig';

const shouldTrack = (consent: Record<WcpConsent.consentCategories, boolean>) => {
  return (consent.Required && consent.Analytics) || !consent.Required;
};

export interface ICookieBannerDispatchProps {
  resetCookieManagementModal?: () => void;
  updateSiteConsent?: (consent: Record<WcpConsent.consentCategories, boolean>) => void;
  updateIsConsentRequired?: (isConsentRequired: boolean) => void;
}

export interface ICookieBannerProps extends ICookieBannerDispatchProps {
  locale: string;
  isManagementModalOpen: boolean;
  siteEnvironment: string;
}

let siteConsent: WcpConsent.SiteConsent = null;

const CookieBannerComponent: React.FC = () => (
  <div id={Constants.Cookies.WCPBannerName} className={Constants.Cookies.WCPBannerName}></div>
);

// wrapper to WCP
export const CookieBanner: React.FC<ICookieBannerProps> = ({
  resetCookieManagementModal,
  locale,
  updateSiteConsent,
  updateIsConsentRequired,
  isManagementModalOpen,
  siteEnvironment,
}: ICookieBannerProps) => {
  const [allowTracking, setAllowTracking] = useState(false);
  const [isComScorePresent, setIsComScorePresent] = useState(false);
  const isMsClarityEnabled = MsClarityEnabledEnvironments.some((environment) => siteEnvironment === environment);

  const handleNewConsent = (newConsent: Record<WcpConsent.consentCategories, boolean>) => {
    const telemetryData: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.ConsentBanner,
      actionModifier: Constants.Telemetry.ActionModifier.HandleNewConsent,
      details: 'onConsentChanged: ' + JSON.stringify(newConsent),
    };
    SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', telemetryData);
    logger.info(telemetryData.details, {
      action: telemetryData.action,
      actionModifier: telemetryData.actionModifier,
    });

    setAllowTracking(shouldTrack(newConsent));
  };

  const manageCookies = () => {
    if (siteConsent) {
      siteConsent.manageConsent();
      resetCookieManagementModal();
    }
  };

  const getConsent = () => {
    getWindow()?.WcpConsent?.init(
      locale,
      Constants.Cookies.WCPBannerName,
      (err: any, _siteConsent: WcpConsent.SiteConsent) => {
        const telemetryData: ITelemetryData = {
          page: getWindow().location.href,
          action: Constants.Telemetry.Action.ConsentBanner,
          actionModifier: Constants.Telemetry.ActionModifier.GetConsent,
          details: 'initConsentBanner: start',
        };
        if (err) {
          telemetryData.actionModifier = Constants.Telemetry.ActionModifier.Error;
          telemetryData.details = 'initConsentBanner Error: ' + stringifyError(err);
        } else {
          siteConsent = _siteConsent!;
          const wcpConsent = _siteConsent.getConsent();
          appConfig.set('siteConsent', wcpConsent);
          updateSiteConsent(wcpConsent);
          updateIsConsentRequired(_siteConsent.isConsentRequired);
          telemetryData.details = 'initConsentBanner: ' + JSON.stringify(_siteConsent.getConsent());

          setAllowTracking(shouldTrack(siteConsent.getConsent()));

          if (!getDocument().getElementById(Constants.Cookies.WCPManageCookieBtnId) && _siteConsent.isConsentRequired) {
            telemetryData.actionModifier = Constants.Telemetry.ActionModifier.Error;
            telemetryData.details = 'error - no manage btn for local: ' + locale;
            SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', telemetryData);
            logger.error(telemetryData.details, {
              action: telemetryData.action,
              actionModifier: telemetryData.actionModifier,
            });
          }
        }
        SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', telemetryData);
        logger.info(telemetryData.details, {
          action: telemetryData.action,
          actionModifier: telemetryData.actionModifier,
        });
      },
      (newConsent: Record<WcpConsent.consentCategories, boolean>) => {
        updateSiteConsent(newConsent);
        appConfig.set('siteConsent', newConsent);
        handleNewConsent(newConsent);
      }
    );
  };

  const wcpConsentCallBack = () => {
    logger.info(`wcpConsentCallBack: get WcpConsent :${!!getWindow()?.WcpConsent}`, {
      action: Constants.Telemetry.Action.ConsentBanner,
      actionModifier: Constants.Telemetry.ActionModifier.WcpConsentCallBack,
    });
    getConsent();
  };

  const wcpConsentListener = () => {
    getWindow()?.WcpConsent ? getConsent() : getWindow().addEventListener(Constants.Cookies.WCPEventName, wcpConsentCallBack);
    return () => {
      try {
        getWindow().removeEventListener(Constants.Cookies.WCPEventName, wcpConsentCallBack);
        logger.info('removeEventListener', {
          action: Constants.Telemetry.Action.ConsentBanner,
          actionModifier: Constants.Telemetry.ActionModifier.RemoveEventListener,
        });
      } catch (error) {
        logger.error(`Error removing event listener: ${error}`, {
          action: Constants.Telemetry.Action.ConsentBanner,
          actionModifier: Constants.Telemetry.ActionModifier.Error,
        });
      }
    };
  };

  const loadComScoreScript = () => {
    setIsComScorePresent(true);
    const scriptElement = getDocument().createElement('script');
    scriptElement.src = COMSCORE_URL;
    scriptElement.async = false;
    document.body.appendChild(scriptElement);
  };

  useEffect(() => {
    if (isManagementModalOpen) {
      manageCookies();
    }
  }, [isManagementModalOpen]);

  useEffect(() => {
    wcpConsentListener();
  }, []);

  useEffect(() => {
    if (allowTracking && !isComScorePresent) {
      loadComScoreScript();
    }
  }, [allowTracking]);

  return (
    <>
      {isMsClarityEnabled && <MsClarity allowTracking={allowTracking} />}
      <CookieBannerComponent />
    </>
  );
};
