import * as React from 'react';
import { Link, Image, Text, mergeStyleSets, Stack } from '@fluentui/react';
import { getEmptyRatingSummary } from './ratingSummary';
import redirectLogo from '@shared/images/Redirect.svg';
import { launchTelemetry } from '@shared/utils/reviewsUtils';
import { Constants } from '@shared/utils/constants';
import { logger } from '@src/logger';
import { IRatingSummary } from '@shared/Models';
import * as PropTypes from 'prop-types';
import { ILocContext } from '@shared/interfaces/context';

export function getExternalProductLink(productName: string): string {
  return productName ? `https://www.g2.com/products/${productName}/reviews` : '';
}

interface IExternalRatingSummaryProps {
  ratingSummary: IRatingSummary;
  image: string;
  showImage?: boolean;
  source: string;
}
const contentStyles = mergeStyleSets({
  externalRatingSummariesTitle: {
    fontSize: 16,
    lineHeight: 22,
    fontWeight: 600,
  },
  externalRatingSourceImage: {
    width: 16,
    height: 16,
    marginTop: 3,
    marginRight: 10,
  },
  externalSummaryUrlImage: {
    height: 13,
    width: 13,
    marginBottom: 1,
  },
});

export const ExternalRatingSummary = (
  { ratingSummary = getEmptyRatingSummary(), image = '', showImage = true, source }: IExternalRatingSummaryProps,
  context: ILocContext
) => {
  ratingSummary = ratingSummary || getEmptyRatingSummary();
  return (
    <div className="externalRatingSummaryContainer" key={`externalRatingSummaryContainer${ratingSummary.Source}`}>
      {showImage && (
        <div className={contentStyles.externalRatingSourceImage}>
          <Image
            src={image}
            className="externalRatingSummaryImage"
            alt={context.loc('External_Rating_Summary_Image_Alt', 'Rating summary image')}
          />
        </div>
      )}
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <Text className={contentStyles.externalRatingSummariesTitle}>
          {context.loc(`ExternalRatingSummaries_${source}_Title`, `${source} ratings`)}
        </Text>
        <div className="externalRatingSummaryRightContainer">
          <Link
            className="externalSummaryUrl"
            href={getExternalProductLink(ratingSummary.ExternalOfferReference)}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              launchTelemetry(Constants.Telemetry.Action.Click, Constants.Telemetry.ActionModifier.ExternalRatingSummaryLink, {
                source: ratingSummary.Source,
              });
              logger.info(
                JSON.stringify({
                  source: ratingSummary.Source,
                }),
                {
                  action: Constants.Telemetry.Action.Click,
                  actionModifier: Constants.Telemetry.ActionModifier.ExternalRatingSummaryLink,
                }
              );
            }}
          >
            <Image
              src={redirectLogo}
              className={contentStyles.externalSummaryUrlImage}
              alt={context.loc('ExternalRatingSummaries_External_Summary_Logo_Alt', 'External rating provider logo')}
            />
          </Link>
        </div>
      </Stack>
    </div>
  );
};

(ExternalRatingSummary as any).contextTypes = {
  loc: PropTypes.func,
};
