import {
  IDataMap,
  ICategoryList,
  DataMap,
  IDataValues,
  IProductValue,
  IDataCollection,
  ICategoryInnerCollection,
  CategoriesInnerKeys,
  industriesType,
  cloudIndustriesType,
} from './dataMapping';
import { getTrimmedWords } from './../utils/stringUtils';
import { IBitmask } from '@shared/Models';
import { Constants } from './constants';

type AllChildMapType = Record<string, string>;

const getAllCategoriesReferences = () => {
  return Object.values(DataMap.categories)
    .map((category) => Object.values(category))
    .flat();
};

const createBackendKeysFromSubCategories = (subCategories: ICategoryList) => {
  return Object.values(subCategories).reduce(
    (acc, curr) => ({
      ...acc,
      [curr.BackendKey]: curr,
    }),
    {}
  );
};

const getAllChildKeys = ({ SubCategoryDataMapping, BackendKey }: IDataValues) => {
  return SubCategoryDataMapping
    ? Object.values(SubCategoryDataMapping)
        .filter(({ UrlKey }) => UrlKey !== 'all' && BackendKey && SubCategoryDataMapping.all)
        .reduce(
          (acc, { BackendKey: currentBackendKey }) => ({
            ...acc,
            [currentBackendKey]: SubCategoryDataMapping.all.BackendKey,
          }),
          {}
        )
    : {};
};

const createAllChildMap = (categories: IDataValues[]) => {
  return categories
    .filter(({ BackendKey, SubCategoryDataMapping }) => BackendKey && SubCategoryDataMapping.all)
    .reduce(
      (acc, { BackendKey, SubCategoryDataMapping }) => ({
        ...acc,
        [BackendKey]: SubCategoryDataMapping.all.BackendKey,
      }),
      {}
    );
};

export const categoryKeyByBackendKey: Record<CategoriesInnerKeys, IDataValues> = Object.assign(
  {},
  ...getAllCategoriesReferences().map(({ SubCategoryDataMapping }) => {
    return SubCategoryDataMapping ? createBackendKeysFromSubCategories(SubCategoryDataMapping) : {};
  })
);

export const industryKeyByBackendKey: Record<industriesType, IDataValues> = Object.assign(
  {},
  ...Object.values(DataMap.industries).map(({ SubCategoryDataMapping }) =>
    createBackendKeysFromSubCategories(SubCategoryDataMapping)
  )
);

export const cloudIndustryKeyByBackendKey: Record<cloudIndustriesType, IDataValues> = Object.assign(
  {},
  ...Object.values(DataMap.cloudIndustries).map(({ SubCategoryDataMapping }) =>
    createBackendKeysFromSubCategories(SubCategoryDataMapping)
  )
);

export const productKeyByUrlKey = Object.keys(DataMap.products).reduce(
  (acc, curr) => ({
    ...acc,
    [DataMap.products[`${curr}`].UrlKey]: curr,
  }),
  {}
);

export const productKeyByBackendKey = Object.keys(DataMap.products).reduce(
  (acc, curr) => ({
    ...acc,
    [DataMap.products[`${curr}`].BackendKey]: curr,
  }),
  {}
);

const productKeyByBitmaskFilter = Object.keys(DataMap.products).reduce((acc, curr) => {
  const { FilterGroup, FilterID } = DataMap.products[`${curr}`];
  return {
    ...acc,
    [`${FilterGroup}_${FilterID}`]: curr,
  };
}, {});

const calculateShortCutBitmask = (productInfo?: IDataValues) => {
  const { ShortcutFilters, FilterGroup, FilterID } = productInfo || {};
  return ShortcutFilters?.length
    ? ShortcutFilters.map((productKey) => DataMap.products[`${productKey}`]).reduce(
        (currMask: IProductValue, shortcutProductInfo: IDataValues) => {
          const { FilterGroup: currentFilterGroup, FilterID } = shortcutProductInfo;
          return {
            ...currMask,
            [currentFilterGroup]: currMask[`${currentFilterGroup}`] ? currMask[`${currentFilterGroup}`] | FilterID : FilterID,
          };
        },
        { [FilterGroup]: FilterID }
      )
    : { [FilterGroup]: FilterID };
};

const productShortcutBitmask = Object.keys(DataMap.products).reduce(
  (acc, curr) => ({
    ...acc,
    [DataMap.products[`${curr}`].UrlKey]: calculateShortCutBitmask(DataMap.products[`${curr}`]),
  }),
  {}
);

export const categoryAllChildMap: AllChildMapType = Object.assign(
  {},
  ...getAllCategoriesReferences()
    .map((category) => getAllChildKeys(category))
    .concat(createAllChildMap(getAllCategoriesReferences()))
);

export const industryAllChildMap: AllChildMapType = Object.assign(
  {},
  ...Object.values(DataMap.industries)
    .map((industry) => getAllChildKeys(industry))
    .concat(createAllChildMap(Object.values(DataMap.industries)))
);

export const cloudIndustryAllChildMap: AllChildMapType = Object.assign(
  {},
  ...Object.values(DataMap.cloudIndustries)
    .map((cloudIndustry) => getAllChildKeys(cloudIndustry))
    .concat(createAllChildMap(Object.values(DataMap.cloudIndustries)))
);

export const allChildMaps: Record<Constants.DataMapCollectionKeys, AllChildMapType> = {
  [Constants.DataMapCollectionKeys.categories]: categoryAllChildMap,
  [Constants.DataMapCollectionKeys.products]: {},
  [Constants.DataMapCollectionKeys.industries]: industryAllChildMap,
  [Constants.DataMapCollectionKeys.cloudIndustries]: cloudIndustryAllChildMap,
  [Constants.DataMapCollectionKeys.serviceTypes]: {},
  [Constants.DataMapCollectionKeys.trials]: {},
  [Constants.DataMapCollectionKeys.pricingModel]: {},
  [Constants.DataMapCollectionKeys.ratings]: {},
  [Constants.DataMapCollectionKeys.appCompliance]: {},
  [Constants.DataMapCollectionKeys.azureBenefitEligible]: {},
  [Constants.DataMapCollectionKeys.copilotExtension]: {},
  [Constants.DataMapCollectionKeys.productType]: {},
};

export const addAllIdToChildCategory = ({
  ids,
  collection,
}: {
  ids: string[];
  collection: Constants.DataMapCollectionKeys;
}): string[] => {
  return ids?.concat(
    ids.filter((id) => allChildMaps[`${collection}`][`${id}`]).map((id) => allChildMaps[`${collection}`][`${id}`])
  );
};

export const getShortCutBitmask = (productInfo?: IDataValues) => {
  return productShortcutBitmask[`${productInfo.UrlKey}`];
};

type UrlKeys = Record<string, true>;

const getUrlKeys = ({ collection }: { collection: IDataCollection | ICategoryList }): UrlKeys => {
  return Object.keys(collection).reduce<UrlKeys>((urlKeys, currentDataValue) => {
    return { ...urlKeys, [collection[`${currentDataValue}`].UrlKey]: true };
  }, {});
};

const getCategoryUrlKeys = (): UrlKeys => {
  return {
    ...getUrlKeys({
      collection: DataMap.categories.default,
    }),
    ...getUrlKeys({
      collection: DataMap.categories.office,
    }),
    ...getUrlKeys({
      collection: DataMap.categories.addIns,
    }),
    ...getUrlKeys({
      collection: DataMap.categories.powerBiVisuals,
    }),
  };
};

type UrlKeysCollection = Record<Constants.DataMapCollectionKeys, UrlKeys>;

export const urlKeys: UrlKeysCollection = {
  [Constants.DataMapCollectionKeys.categories]: getCategoryUrlKeys(),
  [Constants.DataMapCollectionKeys.products]: getUrlKeys({ collection: DataMap.products }),
  [Constants.DataMapCollectionKeys.industries]: getUrlKeys({ collection: DataMap.industries }),
  [Constants.DataMapCollectionKeys.serviceTypes]: getUrlKeys({ collection: DataMap.serviceTypes }),
  [Constants.DataMapCollectionKeys.trials]: getUrlKeys({ collection: DataMap.trials }),
  [Constants.DataMapCollectionKeys.pricingModel]: getUrlKeys({ collection: DataMap.pricingModel }),
  [Constants.DataMapCollectionKeys.ratings]: getUrlKeys({ collection: DataMap.ratings }),
  [Constants.DataMapCollectionKeys.appCompliance]: getUrlKeys({ collection: DataMap.appCompliance }),
  [Constants.DataMapCollectionKeys.azureBenefitEligible]: getUrlKeys({ collection: DataMap.AzureBenefitEligible }),
  [Constants.DataMapCollectionKeys.copilotExtension]: getUrlKeys({ collection: DataMap.CopilotExtension }),
  [Constants.DataMapCollectionKeys.productType]: getUrlKeys({ collection: DataMap.productType }),
  [Constants.DataMapCollectionKeys.cloudIndustries]: getUrlKeys({ collection: DataMap.cloudIndustries }),
};

// TODO, make a type for string.
type SubUrlKeys = Record<string, true>;

const getSubUrlKeys = ({ collection }: { collection: IDataCollection | ICategoryList }): SubUrlKeys => {
  return Object.values(collection)
    .filter((collection) => collection.SubCategoryDataMapping)
    .reduce<UrlKeys>((urlKeys, { SubCategoryDataMapping: currentSubCategoryKey }) => {
      const subUrlKeys: SubUrlKeys = Object.fromEntries(
        Object.keys(currentSubCategoryKey).map((subCategoryKey) => [currentSubCategoryKey[`${subCategoryKey}`].UrlKey, true])
      );
      return { ...urlKeys, ...subUrlKeys };
    }, {});
};

const getSubCategoryUrlKeys = (): SubUrlKeys => {
  return Object.values(DataMap.categories).reduce((acc, collection) => {
    return {
      ...acc,
      ...getSubUrlKeys({
        collection,
      }),
    };
  }, {});
};

export const subUrlKeys: UrlKeysCollection = {
  [Constants.DataMapCollectionKeys.categories]: getSubCategoryUrlKeys(),
  [Constants.DataMapCollectionKeys.products]: getSubUrlKeys({ collection: DataMap.products }),
  [Constants.DataMapCollectionKeys.industries]: getSubUrlKeys({ collection: DataMap.industries }),
  [Constants.DataMapCollectionKeys.serviceTypes]: getSubUrlKeys({ collection: DataMap.serviceTypes }),
  [Constants.DataMapCollectionKeys.trials]: getSubUrlKeys({ collection: DataMap.trials }),
  [Constants.DataMapCollectionKeys.pricingModel]: getSubUrlKeys({ collection: DataMap.pricingModel }),
  [Constants.DataMapCollectionKeys.ratings]: getSubUrlKeys({ collection: DataMap.ratings }),
  [Constants.DataMapCollectionKeys.appCompliance]: getSubUrlKeys({ collection: DataMap.appCompliance }),
  [Constants.DataMapCollectionKeys.azureBenefitEligible]: getSubUrlKeys({ collection: DataMap.AzureBenefitEligible }),
  [Constants.DataMapCollectionKeys.copilotExtension]: getSubUrlKeys({ collection: DataMap.CopilotExtension }),
  [Constants.DataMapCollectionKeys.productType]: getSubUrlKeys({ collection: DataMap.productType }),
};

export function bitMasksMatch(bitMasks: IProductValue, property: string, mask: number): boolean {
  return !!(bitMasks && bitMasks[`${property}`] && (bitMasks[`${property}`] & mask) > 0);
}

export function bitMasksMatchBitMask(bitMasksA: IProductValue, bitMasksB: IBitmask): boolean {
  return bitMasksMatch(bitMasksA, bitMasksB.property, bitMasksB.mask);
}

export function bitMasksMatchFilter(bitMasksA: IProductValue, filter: IDataValues): boolean {
  return bitMasksMatch(bitMasksA, filter.FilterGroup, filter.FilterID);
}

export function bitMasksContainSomeBitMask(bitMasksA: IProductValue, bitMasksB: IProductValue): boolean {
  for (const key of Object.keys(bitMasksA)) {
    if (bitMasksB[`${key}`] && (bitMasksB[`${key}`] & bitMasksA[`${key}`]) > 0) {
      return true;
    }
  }
  return false;
}

export function getPrimaryProductUrl(primaryProduct: IProductValue): string {
  if (primaryProduct) {
    const property = Object.keys(primaryProduct)[0];
    const mask = primaryProduct[`${property}`];
    const maskKey = `${property}_${mask}`;
    const productKey = productKeyByBitmaskFilter[`${maskKey}`];
    return DataMap.products[`${productKey}`]?.UrlKey;
  }
}

export function getProductFilter(primaryProduct: IProductValue): IDataValues {
  for (const key of Object.keys(primaryProduct)) {
    for (const product in DataMap.products) {
      if (
        DataMap.products[`${product}`].FilterID === primaryProduct[`${key}`] &&
        DataMap.products[`${product}`].FilterGroup === key
      ) {
        return DataMap.products[`${product}`];
      }
    }
  }
  return null;
}

export function getCategoryListByProductId(productBitMaskFilter: IProductValue, datamap?: IDataMap): ICategoryList {
  if (!productBitMaskFilter || Object.keys(productBitMaskFilter).length === 0) {
    return DataMap.categories.default;
  }

  const productFilter = getProductFilter(productBitMaskFilter);
  if (productFilter !== null) {
    if (datamap) {
      return datamap.categories[productFilter.categoryList];
    }
    return DataMap.categories[productFilter.categoryList];
  }
  return null;
}

export function getCategoryListByListName(name: string, datamap?: any): ICategoryList {
  if (datamap) {
    return datamap.categories[`${name}`];
  }
  return DataMap.categories[`${name}`];
}

const { default: defaultCateogires, ...specificCategories } = DataMap.categories;

export const overidedCategories: ICategoryInnerCollection = {
  ...Object.values(specificCategories).reduce((acc, curr) => ({ ...curr, ...acc }), {}),
  ...defaultCateogires,
};

export function removeUnmatchedCategories(query: any, newProductUrlKey: string, newProductFilterData: IProductValue) {
  const newQuery = { product: newProductUrlKey };

  if (!query) {
    return newQuery;
  }

  const L1Product = newProductFilterData;
  const categoryListL1Product = getCategoryListByProductId(L1Product);

  const categories = query.category ? getTrimmedWords(query.category, ';') : [];

  let newCategory = '';
  for (const key in query) {
    if (key === 'product') {
      continue;
    }
    if (key !== 'category') {
      newQuery[`${key}`] = query[`${key}`];
    } else {
      for (const queryCategory in categories) {
        for (const category in categoryListL1Product) {
          if (category === categories[`${queryCategory}`]) {
            newCategory += category + ';';
            break;
          }
        }
      }
    }
  }
  newCategory = newCategory !== '' ? newCategory.slice(0, -1) : newCategory;

  newQuery.category = newCategory !== '' ? newCategory : null;
  return newQuery;
}
