import { reduce, isEmpty, isEqualWith, isArray, differenceWith, isEqual } from 'lodash-es';
import xss from 'xss';
import { Request } from 'express';
import { stringifyError } from '@shared/utils/errorUtils';
import { logger } from '@src/logger';

const objCompare = (obj1: Record<string, unknown>, obj2: Record<string, unknown>) => {
  return isEqualWith(obj1, obj2, function (v1, v2, key) {
    if (key === undefined) {
      return undefined;
    }
    if (v1 === v2) {
      return true;
    }
    if (isArray(v1) && isArray(v2)) {
      if (isEmpty(differenceWith(v1, v2, isEqual))) {
        return true;
      }
    }

    return false;
  });
};

const recursiveDecode = ({ value, run = 3 }: { value: string; run?: number }): string => {
  if (run <= 0) {
    return value;
  }

  const decodedValue = decodeURIComponent(value);

  return decodedValue === value ? value : recursiveDecode({ value: decodedValue, run: --run });
};

const parseQuery = ({ query = {}, parser }: { query: Record<string, unknown>; parser: (value: string) => string }) =>
  reduce(
    query,
    (acc, value: string, key: string) => {
      const decodedValue = (isArray(value) && value.map((arrayValue) => parser(arrayValue))) || parser(value);

      return {
        ...acc,
        [parser(key)]: decodedValue,
      };
    },
    {}
  );

const decodeString = (value: string) => recursiveDecode({ value });

const xssDecodeString = (value: string): string => {
  return xss(decodeString(value));
};

const decodeQuery = (query: Record<string, unknown>) => {
  return parseQuery({ query, parser: (value) => decodeString(value) });
};

const xssDecodeQuery = (query: Record<string, unknown>) => {
  return parseQuery({ query, parser: (value) => xssDecodeString(value) });
};

export const getXssDiff = ({ req: { url, query } }: { req: Request }): boolean => {
  let hasDiff = false;
  try {
    const decodedUrl = decodeString(JSON.parse(`"${url}"`));
    const xssDecodedUrl = xssDecodeString(decodedUrl);
    const hasUrlDiff = decodedUrl !== xssDecodedUrl;

    const decodedQuery = decodeQuery(query);
    const xssDecodedQuery = xssDecodeQuery(query);
    const hasQueryDiff = !objCompare(decodedQuery, xssDecodedQuery);

    hasDiff = hasQueryDiff || hasUrlDiff;
  } catch (e) {
    hasDiff = true;

    logger.debug(stringifyError({ msg: 'failed to parse query diff', err: e }), { action: 'xssMiddleware' });
  }
  return hasDiff;
};

export const getXssDiffString = ({ url }: { url: string }): boolean => {
  const urlObject = new URL(url);
  return getXssDiff({ req: { url: urlObject.pathname, query: Object.fromEntries(urlObject.searchParams) } });
};
