import { getWindow } from '@shared/services/window';

const redirectLoginSuffix = (): string => {
  const isHttps = getWindow()?.location.port === '443' || getWindow()?.location.protocol === 'https:';
  const retVal = (!isHttps && `:${getWindow()?.location.port}`) || '';
  return retVal;
};

export const getRedirectLoginUrl = () =>
  `${getWindow()?.location.protocol}//${getWindow()?.location.hostname}${redirectLoginSuffix()}`;
