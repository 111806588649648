import { Action, ActionType, isType } from '@shared/actions/actions';
import { IAppCheckoutState, initialCheckoutState, copyState } from '@src/State';
import {
  SetItemInCartAction,
  UpdateCustomerAction,
  SetActiveCustomerAction,
  SetPublisherTokenResponse,
  ClearCheckoutState,
  SetCustomersAction,
  ErrorAction,
  ClearErrorAction,
  SetActiveStep,
  SetReceiptAction,
  SetBillingAddressAction,
  SetBillingTerm,
  SetRecurringBilling,
  SetSeatNumber,
  SetProduct,
  SetTermId,
  FetchingProductAction,
  SetCheckoutSource,
  SetCheckoutId,
} from '@shared/actions/checkoutActions';
import { ICartItem, CreateNewCartActionType } from '@shared/Models';

const newCartActions = [SetItemInCartAction, SetBillingTerm, SetRecurringBilling, SetSeatNumber, SetTermId];

export default function checkoutReducer(state: IAppCheckoutState = initialCheckoutState, action: Action<any>): IAppCheckoutState {
  const newState = copyState(state);

  if (isType(action, SetItemInCartAction)) {
    // currently we only allow 1 item in cart, so override the existing item
    newState.items = newState.items.slice();
    if (newState.items.length > 0) {
      newState.items.splice(0, 1, action.payload);
    } else {
      newState.items.splice(0, 0, action.payload);
    }
  } else if (isType(action, SetCheckoutSource)) {
    return { ...newState, source: action.payload.source };
  } else if (isType(action, SetProduct)) {
    const indexToUpdate = newState.items.findIndex((cartItem: ICartItem) => cartItem.entityId === action.payload.id);
    if (indexToUpdate >= 0) {
      newState.items[`${indexToUpdate}`].product = action.payload.product;
    }
    newState.productFetched = true;
  } else if (isType(action, FetchingProductAction)) {
    newState.productFetched = false;
  } else if (isType(action, SetBillingTerm)) {
    const indexToUpdate = newState.items.findIndex((cartItem: ICartItem) => cartItem.id === action.payload.id);
    if (indexToUpdate >= 0) {
      newState.items = state.items.map((item) => ({ ...item, termDuration: action.payload.termDuration }));
    }
  } else if (isType(action, SetTermId)) {
    const indexToUpdate = newState.items.findIndex((cartItem: ICartItem) => cartItem.id === action.payload.id);
    if (indexToUpdate >= 0) {
      newState.items = state.items.map((item) => ({ ...item, termId: action.payload.termId }));
    }
  } else if (isType(action, SetSeatNumber)) {
    const indexToUpdate = newState.items.findIndex((cartItem: ICartItem) => cartItem.id === action.payload.id);
    if (indexToUpdate >= 0) {
      newState.items[`${indexToUpdate}`].quantity = action.payload.numSeats;
      newState.items[`${indexToUpdate}`].quantityString = action.payload.numSeats.toString();
    }
  } else if (isType(action, SetRecurringBilling)) {
    const indexToUpdate = newState.items.findIndex((cartItem: ICartItem) => cartItem.id === action.payload.id);
    if (indexToUpdate >= 0) {
      newState.items[`${indexToUpdate}`].recurringBilling = action.payload.recurringBilling;
    }
  } else if (isType(action, UpdateCustomerAction)) {
    newState.activeCustomer = {
      ...newState.activeCustomer,
      address: action.payload,
    };
  } else if (isType(action, SetActiveStep)) {
    newState.currentStep = action.payload;
  } else if (isType(action, SetCustomersAction)) {
    newState.customers = action.payload;
  } else if (isType(action, SetActiveCustomerAction)) {
    // Implies customer identity has been changed to another customer identity
    newState.activeCustomer = action.payload;
  } else if (isType(action, SetPublisherTokenResponse)) {
    newState.publisherToken = action.payload;
  } else if (isType(action, ClearCheckoutState)) {
    // clear everything except the activeCustomer and selectedPi information
    newState.items = [];
    newState.retryAfter = null;
    newState.publisherToken = null;
  } else if (isType(action, SetReceiptAction)) {
    newState.receipt = action.payload;
  } else if (isType(action, SetBillingAddressAction)) {
    newState.billingAddress = action.payload;
  } else if (isType(action, SetCheckoutId)) {
    newState.checkoutId = action.payload.checkoutId;
  } else if (isType(action, ClearErrorAction)) {
    newState.error = false;
  } else if (isType(action, ErrorAction)) {
    newState.error = true;
  }
  // Upon incrementing purchaseAttempt, a new cart will generate and checkout will reload
  if (newCartActions.some((newCartAction: ActionType<CreateNewCartActionType>) => isType(action, newCartAction))) {
    newState.purchaseAttempt++;
  }

  return newState;
}
