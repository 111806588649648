import { getWindow } from '@shared/services/window';
import { Constants } from '@shared/utils/constants';
import { getSpzaUserIdAndNewUserModifier } from '@shared/utils/appUtils';

export interface PageLoadTime {
  hostType: string;
  landingView: string;
  responseStart: number;
  responseEnd: number;
  scriptStart: number;
  hydrationStart: number;
  performanceNow: number;
  duration: number;
  durationSinceRequestStart: number;
  slowestAssetDuration: number;
  slowestAssetURL: string;
}

interface AssetsLoadTime {
  src: string;
  requestStart: number;
  responseEnd: number;
  duration: number;
}

const predefinedPLT = {
  scriptStart: -1,
  hydrationStart: -1,
};

function currentSinceRequestStart() {
  const { navigationStart = 0, requestStart = 0 } = getWindow()?.performance?.timing || {};

  return getWindow()?.performance?.now() - (requestStart - navigationStart);
}

export function setScriptStart() {
  predefinedPLT.scriptStart = currentSinceRequestStart();
}

export function setHydrationStart() {
  predefinedPLT.hydrationStart = currentSinceRequestStart();
}

function getAssetsLoadTime(): AssetsLoadTime[] {
  const { navigationStart = 0, requestStart = 0 } = getWindow()?.performance?.timing || {};

  const perfEntries = (getWindow()?.performance?.getEntriesByType('resource') || []) as PerformanceResourceTiming[];

  return perfEntries
    .filter(({ initiatorType }) => initiatorType === 'script' || initiatorType === 'link')
    .map(({ name: src, responseEnd }) => ({
      src,
      requestStart,
      responseEnd,
      duration: responseEnd - (requestStart - navigationStart),
    }));
}

const whiteListedAssets = ['staticstorage', 'agorasstatic'];

function getSlowestAssetDuration({ assets }: { assets: AssetsLoadTime[] }): AssetsLoadTime {
  return assets
    .filter(({ src }) => whiteListedAssets.some((whiteListedAsset) => src.includes(whiteListedAsset)))
    .sort((a, b) => b.duration - a.duration)[0];
}

export function telemetryPageLoadTime({ isEmbedded, landingView }: { isEmbedded: boolean; landingView: string }): void {
  if (!getWindow()) {
    return;
  }

  const performanceNow = getWindow().performance.now();
  const { navigationStart = 0, requestStart = 0, responseStart = 0, responseEnd = 0 } = getWindow().performance.timing;
  const duration = performanceNow - (requestStart - navigationStart);

  const { scriptStart, hydrationStart } = predefinedPLT;

  const assets = getAssetsLoadTime();

  const { duration: slowestAssetDuration, src: slowestAssetURL } = getSlowestAssetDuration({ assets }) || {};

  const { spzaUserId: spzaId } = getSpzaUserIdAndNewUserModifier() || {};

  const hostType = isEmbedded ? Constants.appSourceEmbed : Constants.appSource;

  const pageLoadTime: PageLoadTime = {
    hostType,
    landingView,
    responseStart: Math.max(responseStart - requestStart, 0),
    responseEnd: Math.max(responseEnd - requestStart, 0),
    scriptStart,
    hydrationStart,
    performanceNow,
    duration,
    durationSinceRequestStart: duration,
    slowestAssetDuration,
    slowestAssetURL,
  };

  getWindow().pageLoadTime = pageLoadTime;

  getWindow().telemetry({
    action: Constants.Telemetry.Action.RootHandler,
    actionModifier: Constants.Telemetry.ActionModifier.End,
    details: pageLoadTime,
    spzaId,
  });

  getWindow().telemetry({
    action: Constants.Telemetry.Action.PerfResources,
    actionModifier: Constants.Telemetry.ActionModifier.Complete,
    details: { length: assets.length, assets },
    spzaId,
  });

  getWindow().flushTelemetry();
}
