import { DataMap, IProductValue } from '@shared/utils/dataMapping';
import { BackendKeyToURLKey } from '@shared/utils/dataMappingConstants';
import { Constants } from '@shared/utils/constants';
import {
  IServiceDetails,
  IMultiGeoCountryPricing,
  IMultiGeoCountryAndRegions,
  IServicePricingView,
} from '@shared/serviceViewModel';
import { IURLParam, IURLQuery } from '@shared/Models';
import { bitMasksMatchFilter } from '@shared/utils/datamappingHelpers';
import { ILocContext } from '@shared/interfaces/context';
import { getAppConfig } from '@shared/services/init/appConfig';

export function getProductTitleForTile(serviceProducts: IProductValue) {
  let products = '';
  // eslint-disable-next-line array-callback-return
  Object.keys(DataMap.products).map((value) => {
    if (serviceProducts && bitMasksMatchFilter(serviceProducts, DataMap.products[`${value}`])) {
      // If the product is a d365 sub prod just insert d365 once for all the sub products, else insert the product
      if (
        DataMap.products.Dynamics365.ShortcutFilters.indexOf(value) > -1 &&
        products.indexOf(DataMap.products.Dynamics365.LongTitle) < 0
      ) {
        products += DataMap.products.Dynamics365.LongTitle + ' | ';
      } else if (DataMap.products.Dynamics365.ShortcutFilters.indexOf(value) < 0) {
        // !!! Temporary HACK: If the product is 'Power BI apps' we want to display it as 'Power BI'
        // in Consulting services tab alone.
        let longTitle = DataMap.products[`${value}`].LongTitle;
        if (longTitle === 'Power BI apps') {
          longTitle = 'Power BI';
        }

        products += longTitle + ' | ';
      }
    }
  });
  return products.substring(0, products.lastIndexOf('|'));
}

function getServicePricingByCountry(multiGeoCountryPricings: IMultiGeoCountryPricing[], country: string): IServicePricingView {
  return multiGeoCountryPricings?.find(
    (multiGeoCountryPricing) => multiGeoCountryPricing.countryRegion?.toLowerCase() === country?.toLowerCase()
  )?.pricing;
}

export function getServicePricingByCountryWithFallback(
  multiGeoCountryPricings: IMultiGeoCountryPricing[],
  country: string
): IServicePricingView {
  return (
    getServicePricingByCountry(multiGeoCountryPricings, country) ||
    getServicePricingByCountry(multiGeoCountryPricings, Constants.usCountryCode) ||
    multiGeoCountryPricings?.[0]?.pricing
  );
}

export function getCountryRegionByCountry(detailInformation: IServiceDetails, country: string): IMultiGeoCountryAndRegions {
  if (country && detailInformation && detailInformation.MultiCountryRegions && detailInformation.MultiCountryRegions.length > 0) {
    for (let i = 0; i < detailInformation.MultiCountryRegions.length; i++) {
      if (
        detailInformation.MultiCountryRegions[`${i}`].countryRegion &&
        detailInformation.MultiCountryRegions[`${i}`].countryRegion.toLowerCase() === country.toLowerCase()
      ) {
        return detailInformation.MultiCountryRegions[`${i}`];
      }
    }
  } else if (detailInformation && detailInformation.countryRegion) {
    return <IMultiGeoCountryAndRegions>{ countryRegion: detailInformation.countryRegion, regions: detailInformation.regions };
  }
  return null;
}

export function findBestMatchCountryCode(multiCountryServicePricing: IMultiGeoCountryPricing[], country: string): string {
  if (country && multiCountryServicePricing && multiCountryServicePricing.length > 0) {
    for (let i = 0; i < multiCountryServicePricing.length; i++) {
      if (
        multiCountryServicePricing[`${i}`].countryRegion &&
        multiCountryServicePricing[`${i}`].countryRegion.toLowerCase() === country.toLowerCase()
      ) {
        return multiCountryServicePricing[`${i}`].countryRegion;
      }
    }
    // Input country not found - return the first country we can find.
    return multiCountryServicePricing[0].countryRegion;
  }
  return null;
}

export function extractCountryCodeFromFilterQueryParam(query: IURLQuery): string {
  // Try to get (AMP) filter-country query param.
  if (typeof query?.filters === 'string') {
    const subFilters: string[] = query.filters.split(';');
    if (subFilters && subFilters.length > 0) {
      for (let i = 0; i < subFilters.length; i++) {
        if (subFilters[`${i}`] && subFilters[`${i}`].toLowerCase().indexOf('country-') !== -1) {
          for (const key in BackendKeyToURLKey) {
            if (BackendKeyToURLKey[`${key}`].toLowerCase() === subFilters[`${i}`].toLowerCase()) {
              return key;
            }
          }
        }
      }
    }
  }
  return null;
}

export function extractCountryCodeFromCountryQueryParam(query: IURLQuery): string {
  return query && query.country ? query.country : null;
}

export function extractCountryCodeFromQueryParam(query: IURLQuery): string {
  // Try to get country code from country(AppSource) query param.
  let countryCode = extractCountryCodeFromCountryQueryParam(query);
  if (countryCode) {
    return countryCode;
  }
  // Try to get country code from filter-country(AMP) query param.  }
  countryCode = extractCountryCodeFromFilterQueryParam(query);
  if (countryCode) {
    return countryCode;
  }
  return null;
}

export function createCountryFilterQueryParam(countryCode: string, isAppSource: boolean): IURLParam {
  if (isAppSource) {
    return { [Constants.countryCodeQueryParam]: countryCode };
  } else {
    return { [Constants.FilterQuery.filters]: BackendKeyToURLKey[`${countryCode}`] };
  }
}

export function getReplacedCountryQueryParam(query: IURLQuery, newCountryCode: string): IURLParam {
  if (!query || !newCountryCode) {
    return null;
  }
  // AppSource
  if (query.country) {
    return { [Constants.countryCodeQueryParam]: newCountryCode };
  }
  // AMP
  if (query.filters) {
    const countryCodeToReplace = extractCountryCodeFromFilterQueryParam(query);
    // No country code to replace.
    let newFilter: string;
    if (!countryCodeToReplace) {
      if (query.filters.length > 0) {
        newFilter = query.filters += ';' + BackendKeyToURLKey[`${newCountryCode}`];
      } else {
        newFilter = BackendKeyToURLKey[`${newCountryCode}`];
      }
    }
    // Replace country code
    else {
      newFilter = query.filters
        .toLowerCase()
        .replace(BackendKeyToURLKey[`${countryCodeToReplace}`], BackendKeyToURLKey[`${newCountryCode}`]);
    }
    return { [Constants.FilterQuery.filters]: newFilter };
  }
  return null;
}

export function getStartingPriceCurrency(multiCountryPricing: IMultiGeoCountryPricing[], country: string) {
  const pricing = getServicePricingByCountryWithFallback(multiCountryPricing, country);

  return pricing?.currencyCode || Constants.usdCurrency;
}

export function getStartingPriceHeader(
  multiCountryPricing: IMultiGeoCountryPricing[],
  country: string,
  locale: string,
  context: ILocContext
) {
  const pricing = getServicePricingByCountryWithFallback(multiCountryPricing, country);

  const currency = pricing?.currencyCode;

  const planPrice = pricing?.planPrices?.[0];

  const header = planPrice?.price
    ? planPrice.price.toLocaleString(locale, {
        style: 'currency',
        currency,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      })
    : context.loc('Pricing_Free');

  return header;
}
