import * as React from 'react';
import * as PropTypes from 'prop-types';
import SpzaComponent from './../spzaComponent';
import { ILocContext, ICommonContext } from '../../interfaces/context';
import { ITelemetryData, IAvertAbuseCategory, IItemReport, CommunityItemType } from '@shared/Models';
import { Constants } from '../../utils/constants';
import { RichTextDropDown, IRichTextDropDownItem } from '../richTextDropDown';
import { SpzaInstrumentProvider, SpzaInstrumentService } from '../../services/telemetry/spza/spzaInstrument';
import { getWindow } from '../../services/window';
import { isEmailValid, focusElement } from '../../utils/appUtils';
import classNames from 'classnames';
import { logger } from '@src/logger';
import checkLarge from '@shared/images/checkLarge.png';

export interface IReportAbuseModalProps {
  appId: string;
  reviewId: string;
  reviewTitle: string;
  reviewSource: string;
  dismissModal: () => void;
  reportReview: (report: IItemReport) => Promise<boolean>;
  storefrontName: string;
}

export interface IReportAbuseModalState {
  category: IRichTextDropDownItem<string>;
  additionalComments: string;
  reviewContent: string;
  successMode: boolean;
  validEmail?: boolean;
  email?: string;
}

export default class ReportAbuseModal extends SpzaComponent<IReportAbuseModalProps, IReportAbuseModalState> {
  context: ILocContext & ICommonContext;
  textDropDownItems: IRichTextDropDownItem<string>[];
  private instrument: SpzaInstrumentProvider;
  constructor(props: IReportAbuseModalProps, context: ILocContext & ICommonContext) {
    super(props, context);
    this.context = context;
    this.instrument = SpzaInstrumentService.getProvider();
    this.state = {
      category: this.getDropDownOptions()[0],
      successMode: false,
      reviewContent: '',
      additionalComments: '',
      validEmail: true,
      email: '',
    };
  }

  componentDidMount() {
    focusElement(Constants.SearchBy.Id, Constants.ReportAbuseDialog.dropdownHTMLIdName);
  }

  getDropDownOptions(): IRichTextDropDownItem<string>[] {
    if (this.textDropDownItems) {
      return this.textDropDownItems;
    }

    this.textDropDownItems = Constants.Avert.ReportAbuseCategories.map((abuseCategory: IAvertAbuseCategory) => {
      return {
        text: this.context.loc(abuseCategory.locKey),
        value: abuseCategory.dropDownValue,
      } as IRichTextDropDownItem<string>;
    });

    return this.textDropDownItems;
  }

  onCommentsChange(event: any) {
    const comment = event.target.value;
    this.setState((prevState: IReportAbuseModalState) => {
      return {
        category: prevState.category,
        additionalComments: comment,
        successMode: prevState.successMode,
        reviewContent: prevState.reviewContent,
        email: prevState.email,
        validEmail: prevState.validEmail,
      };
    });
  }

  onEmailChange(event: any) {
    const email = event.target.value;
    this.setState({ email: email });
    if (!email || isEmailValid(this.state.email)) {
      this.setState({ validEmail: true });
    } else {
      this.setState({ validEmail: false });
    }
  }

  setSuccessState() {
    this.setState((prevState: IReportAbuseModalState) => {
      return {
        category: prevState.category,
        additionalComments: prevState.additionalComments,
        reviewContent: prevState.reviewContent,
        successMode: true,
        email: prevState.email,
        validEmail: prevState.validEmail,
      };
    });
  }

  getReportCategory(selectedValue: string) {
    const abuseCategory = Constants.Avert.ReportAbuseCategories.filter((category: IAvertAbuseCategory) => {
      return category.dropDownValue === selectedValue;
    });

    return abuseCategory && abuseCategory[0]
      ? abuseCategory[0].avertIdentifier
      : Constants.Avert.ReportAbuseCategories[0].avertIdentifier;
  }

  handleSubmit() {
    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.ReportModal,
      appName: this.props.appId,
      details: JSON.stringify({
        category: this.state.category.value,
        comments: this.state.additionalComments,
        reviewId: this.props.reviewId,
        reviewSource: this.props.reviewSource,
        reviewContent: this.state.reviewContent,
        email: this.state.email,
      }),
    };

    this.instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
      appName: payload.appName,
    });

    const { storefrontName, appId, reviewId } = this.props;
    const postItemReport: IItemReport = {
      category: this.getReportCategory(this.state.category.value),
      source: storefrontName,
      offerId: appId,
      itemId: reviewId,
      comments: this.state.additionalComments,
      itemType: CommunityItemType.Review,
    };

    this.props.reportReview(postItemReport);
    this.setSuccessState();
  }

  setFocusInsideDialog(e: React.KeyboardEvent<any>) {
    if (e.shiftKey && e.keyCode === Constants.SystemKey.Tab) {
      const submitButton = document.getElementById('reportAbuseModalSubmitButton');
      window.setTimeout(function () {
        submitButton.focus();
      }, 0);
    }
  }

  getCancelButton() {
    return (
      <div className="toolBar">
        <button
          // eslint-disable-next-line react/forbid-dom-props
          id="cancelButton"
          aria-label="close"
          className="cancel"
          onClick={this.props.dismissModal.bind(this)}
          onKeyDown={(event: React.KeyboardEvent<any>) => {
            this.setFocusInsideDialog(event);
          }}
        >
          <span className="c-glyph"></span>
        </button>
      </div>
    );
  }

  getRatingCategoryDropDown() {
    return (
      <RichTextDropDown
        className="categoryDropDown"
        id={Constants.ReportAbuseDialog.dropdownHTMLIdName}
        options={this.getDropDownOptions()}
        value={this.state.category}
        dropdownLabel={this.context.loc('ReportModal_Category_Label')}
        onChangeHandle={(option: any) =>
          this.setState((prevState: IReportAbuseModalState) => {
            return {
              category: option,
              additionalComments: prevState.additionalComments,
              reviewContent: prevState.reviewContent,
              successMode: prevState.successMode,
              email: prevState.email,
              validEmail: prevState.validEmail,
            };
          })
        }
      />
    );
  }

  getReviewTitle() {
    return (
      <div className="reviewTitleSection">
        <div className="itemLabel">{this.context.loc('ReportModal_ReviewTitle_Label') + ': ' + this.props.reviewTitle}</div>
      </div>
    );
  }

  renderImpl() {
    if (this.state.successMode) {
      return (
        <div role="dialog" aria-label="rating and review dialog is now open" className="reportAbuseModalClass">
          {this.getCancelButton()}
          <div className="successDialog">
            <div className="successHeader">{this.context.loc('ReportModal_Success_Done_Text')}</div>
            <div className="successCheck">
              <img src={checkLarge} />
            </div>
            <div className="successFooter">
              <button
                name="button"
                className="c-button requestButton"
                type="submit"
                data-bi-id={Constants.JsllCTAId.Done}
                data-bi-area="Report Abuse Dialog"
                onClick={this.props.dismissModal.bind(this)}
              >
                {this.context.loc('ReportModal_Success_Done_Label')}
              </button>
            </div>
          </div>
        </div>
      );
    }

    const reportModalClassName = classNames({
      reportAbuseModalClass: true,
      invalidReviewMode: this.state.category.value === Constants.Avert.ReportAbuseCategories[3].dropDownValue,
      invalidEmailText:
        this.state.category.value === Constants.Avert.ReportAbuseCategories[3].dropDownValue && !this.state.validEmail,
    });
    return (
      <div role="dialog" aria-label="report abuse modal is now open" className={reportModalClassName}>
        {this.getCancelButton()}
        <div className="title">{this.context.loc('ReportModal_Title_Text')}</div>
        {this.getReviewTitle()}
        <div className="categorySection">
          <div>
            <div className="itemLabel">{this.context.loc('ReportModal_Category_Label')}</div>
          </div>
          {this.getRatingCategoryDropDown()}
        </div>
        <div className="commentsSection">
          <div className="itemLabel">{this.context.loc('ReportModal_AdditionalComments_Label')}</div>
          <div className="c-textarea">
            <textarea
              // eslint-disable-next-line react/forbid-dom-props
              id="additionalComments"
              aria-label={this.context.loc('ReportModal_AdditionalComments_Label')}
              className="f-no-resize"
              rows={3}
              maxLength={400}
              onChange={this.onCommentsChange.bind(this)}
              role="textbox"
            ></textarea>
          </div>
        </div>
        {this.state.category.value === Constants.Avert.ReportAbuseCategories[3].dropDownValue ? (
          <div className="emailSection">
            <div className="itemLabel">{this.context.loc('ReportModal_Email_Label')}</div>
            <div className="emailInputContainer">
              <input
                // eslint-disable-next-line react/forbid-dom-props
                id="emailSection"
                aria-label={this.context.loc('ReportModal_Email_Label')}
                className={this.state.validEmail ? '' : 'invalidEmailAddress'}
                onChange={this.onEmailChange.bind(this)}
              ></input>
            </div>
            {this.state.validEmail ? null : <span className="invalidEmailText">{this.context.loc('Feedback_invalidEmail')}</span>}
          </div>
        ) : null}
        <div className="submitSection">
          <button
            name="submitButton"
            // eslint-disable-next-line react/forbid-dom-props
            id="reportAbuseModalSubmitButton"
            className="c-button"
            disabled={
              (this.state.category.value === Constants.Avert.ReportAbuseCategories[3].dropDownValue &&
                !this.state.additionalComments) ||
              (this.state.category.value === Constants.Avert.ReportAbuseCategories[3].dropDownValue && !this.state.validEmail)
            }
            role="button"
            type="submit"
            aria-label={this.context.loc('NPS_SubmitButton')}
            onClick={() => this.handleSubmit()}
          >
            {this.context.loc('ReportModal_SubmitButton_Label')}
          </button>
        </div>
      </div>
    );
  }
}

(ReportAbuseModal as any).contextTypes = {
  loc: PropTypes.func,
};
