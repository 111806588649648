import React from 'react';
import * as PropTypes from 'prop-types';
import { Text, mergeStyleSets, Stack, Link } from '@fluentui/react';
import { useSelector } from 'react-redux';

import { IState } from '@src/State';
import { ShowingResultsFor } from '@appsource/components/ShowingResultsFor';
import { SortingDropdown } from '@shared/components/sortingDropdown';
import { Constants } from '@shared/utils/constants';
import { routes, IRouteConfig } from '@shared/routerHistory';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ThumbsUpDown } from '@appsource/components/thumbsUpDown';
import { IBuildHrefContext, ILocContext } from '@shared/interfaces/context';

export interface IFilteredGalleryHeaderProps {
  numResults: number;
  localizedTitle: string;
  subTitle: string;
  otherGalleriesLinks: JSX.Element[];
  hideSortingDropdown: boolean;
}

export type TitleComponentProps = {
  localizedTitle: string;
  showingResultsForText: string;
  previousSearchText: string;
};

export const TitleComponent: React.FC<TitleComponentProps> = ({
  localizedTitle,
  previousSearchText = '',
  showingResultsForText = '',
}: TitleComponentProps) => {
  return showingResultsForText.length > 0 ? (
    <ShowingResultsFor showingResultsForText={showingResultsForText} previousSearchText={previousSearchText} />
  ) : (
    <Text as={'h1'} variant="xLarge" className="galleryTitle">
      {localizedTitle}
    </Text>
  );
};

const contentStyles = mergeStyleSets({
  subTitle: { marginTop: 8 },
  container: { marginBottom: 20 },
  leftSpace: { paddingLeft: 6 },
});

const { Popularity, RecentlyUpdated, BestMatch, RatingAverage } = Constants.SearchSortingOption;
const nonDefaultSortingOptions = [Popularity, RecentlyUpdated, RatingAverage];
const { marketplaceServices, marketplace, marketplaceCloudsIndustry } = routes;
const viewToRouteMap: Record<string, IRouteConfig<any>> = {
  [Constants.Views.appGallery]: marketplace,
  [Constants.Views.serviceGallery]: marketplaceServices,
  [Constants.Views.cloudsIndustryGallery]: marketplaceCloudsIndustry,
};

export const FilteredGalleryHeader: React.FunctionComponent<IFilteredGalleryHeaderProps> = (
  { otherGalleriesLinks, subTitle, numResults, localizedTitle, hideSortingDropdown }: IFilteredGalleryHeaderProps,
  context: ILocContext & IBuildHrefContext
) => {
  const {
    showingResultsFor: showingResultsForText = '',
    previousSearchText = '',
    currentView,
  } = useSelector(
    ({
      search: { showingResultsFor, previousSearchText },
      config: {
        currentView,
        featureFlags: { sortByRatingEnabled, allNewSortsEnabled, sortByRecentlyUpdatedEnabled },
      },
    }: IState) => ({
      showingResultsFor,
      previousSearchText,
      currentView,
      sortByRatingEnabled,
      allNewSortsEnabled,
      sortByRecentlyUpdatedEnabled,
    })
  );

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const sortByQueryParam = searchParams.get(Constants.FilterQuery.sortBy);

  let defaultSortValue = BestMatch;
  if (sortByQueryParam && nonDefaultSortingOptions.includes(sortByQueryParam as Constants.SearchSortingOption)) {
    defaultSortValue = sortByQueryParam as Constants.SearchSortingOption;
  }

  const baseRoute = viewToRouteMap[`${currentView}`] || marketplace;

  const { container, leftSpace, subTitle: subTitleClass } = contentStyles;

  const handleClearSearch = () => {
    const url = context.buildHref(baseRoute, {}, { [Constants.FilterQuery.search]: '' }, false);

    navigate(url);
  };

  const shouldDisplayClearSearch = previousSearchText || searchParams.get('search')?.length > 0;

  return (
    <>
      <div role="status" aria-label={`${numResults} items found`} />
      <Stack horizontal horizontalAlign="space-between" className={container} wrap tokens={{ childrenGap: 6 }}>
        <Stack.Item>
          <TitleComponent
            localizedTitle={localizedTitle}
            previousSearchText={previousSearchText}
            showingResultsForText={showingResultsForText}
          />
          <Stack tokens={{ childrenGap: 4 }} horizontal className={subTitleClass} wrap>
            <Stack.Item>
              <Text block>{subTitle}</Text>
            </Stack.Item>
            {!shouldDisplayClearSearch && otherGalleriesLinks && (
              <Stack.Item className={leftSpace}>{otherGalleriesLinks}</Stack.Item>
            )}
            <Stack.Item>
              {shouldDisplayClearSearch && (
                <Link onClick={() => handleClearSearch()} underline as="button">
                  {context.loc('ClearSearch', 'Clear search')}
                </Link>
              )}
            </Stack.Item>
          </Stack>
          {shouldDisplayClearSearch && otherGalleriesLinks && (
            <Stack.Item tokens={{ margin: '8px 0' }}>{otherGalleriesLinks}</Stack.Item>
          )}
          {!!showingResultsForText.length && (
            <Stack.Item>
              <ThumbsUpDown
                localizedQuestion={context.loc('Feedback_ShowingResultsFor', "Are these results what you're looking for?")}
              />
            </Stack.Item>
          )}
        </Stack.Item>
        <Stack.Item>
          <Stack verticalAlign="start" horizontalAlign="end">
            {numResults > 0 && (
              <Stack.Item>
                <SortingDropdown defaultKey={defaultSortValue} route={baseRoute} hide={hideSortingDropdown} />
              </Stack.Item>
            )}
          </Stack>
        </Stack.Item>
      </Stack>
    </>
  );
};

(FilteredGalleryHeader as React.FC).contextTypes = {
  loc: PropTypes.func,
  buildHref: PropTypes.func,
};
