import { get, IHttpOption, AuthenticationTypes } from '@shared/services/http/httpProtocol';
import { ILinkedInProductGroup } from '@src/State';

const commonHttpOptions: IHttpOption = {
  requireCorrelationId: true,
  stringifyPostData: false,
  authenticationType: AuthenticationTypes.Spza,
  setBearerHeader: true,
};

export const LinkedInQueryStrings = {
  LegacyOfferId: 'offer_id',
  ApiVersion: 'api-version',
};

export interface GetLinkedInProductGroupResult {
  linkedInProductGroup: ILinkedInProductGroup;
}

/**
 * Get Linkedin product group information.
 * @param legacyOfferId legacy offer identifier.
 * @param linkedInAPI Linkedin API route.
 * @param legacyOfferId Linkedin API version.
 * @returns Promise with the response, containing Linkedin product group information or null if information doesn't exist.
 */
export async function getLinkedInProductgroupInfo({
  legacyOfferId,
  linkedinAPI,
  linkedinAPIVersion,
}: {
  legacyOfferId: string;
  linkedinAPI: string;
  linkedinAPIVersion: string;
}): Promise<GetLinkedInProductGroupResult> {
  const linkedInProductGroup = await get(`${linkedinAPI}/product_groups`, commonHttpOptions)
    .addQueryEntry(LinkedInQueryStrings.ApiVersion, linkedinAPIVersion)
    .addQueryEntry(LinkedInQueryStrings.LegacyOfferId, legacyOfferId)
    .request();
  return {
    linkedInProductGroup,
  };
}
