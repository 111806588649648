import * as React from 'react';
import SpzaComponent from './spzaComponent';
import { SpzaInstrumentService } from '../services/telemetry/spza/spzaInstrument';
import { ITelemetryData } from '../Models';
import { Constants } from '../utils/constants';
import { logger } from '@src/logger';
import classNames from 'classnames';

export interface ITelemetryImageProps {
  title?: string;
  src: string;
  alt?: string;
  className?: string;
  imgClassName?: string;
  isVideo?: boolean;
  itemPropName?: string;
  ariaLabel?: string;
  onError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
}

const minimumLoatimeForTelemetry = 10000;
const processedImageLoadedSrcList: { [src: string]: boolean } = {};
const processedImageErrorSrcList: { [src: string]: boolean } = {};

export function onImageLoaded(src: string, startTime: number) {
  const timeTaken = Date.now() - startTime;

  if (!processedImageLoadedSrcList[`${src}`] && timeTaken > minimumLoatimeForTelemetry) {
    processedImageLoadedSrcList[`${src}`] = true;
    const payload: ITelemetryData = {
      page: src,
      action: Constants.Telemetry.Action.ImageLoad,
      actionModifier: Constants.Telemetry.ActionModifier.End,
      details: `Elapsed time: ${timeTaken} ms`,
    };

    SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
      page: payload.page,
    });
  }
}

export function onImageErrored(src: string, startTime: number) {
  if (!processedImageErrorSrcList[`${src}`]) {
    processedImageErrorSrcList[`${src}`] = true;
    const payload: ITelemetryData = {
      page: src,
      action: Constants.Telemetry.Action.ImageLoad,
      actionModifier: Constants.Telemetry.ActionModifier.Error,
      details: `Elapsed time: ${Date.now() - startTime} ms`,
    };

    SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
    logger.error(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
      page: payload.page,
    });
  }
}

interface ITelemetryImageState {
  readyForNonInitialRendering: boolean;
  startTime: number;
}

export class TelemetryImage extends SpzaComponent<ITelemetryImageProps, ITelemetryImageState> {
  microTaggingItemPropName = '';

  constructor(props: ITelemetryImageProps) {
    super(props);

    const { itemPropName } = this.props;

    this.state = {
      readyForNonInitialRendering: false,
      startTime: 0,
    };

    this.microTaggingItemPropName = itemPropName || 'thumbnailUrl';
  }

  getAlt(alt: string, title: string, isVideo: boolean) {
    if (alt) {
      return alt;
    } else if (title) {
      return title + ' image';
    } else if (isVideo) {
      return 'play video';
    } else {
      return 'image';
    }
  }

  componentDidMount() {
    // since componentDidMount is only called at client side after the initial client side rendering
    // is finished which would be consistent with the server side rendering result.
    // then readyForNonInitialRendering is set to true here which would trigger another round of client side rendering.
    this.setState({
      readyForNonInitialRendering: true,
      startTime: Date.now(),
    });
  }

  renderImpl() {
    const { title, alt, isVideo, className, src, onError, imgClassName } = this.props;
    const { startTime } = this.state;

    return (
      <div className={classNames('delayLoadImage', 'telemetry', className)}>
        {this.state.readyForNonInitialRendering ? (
          <img
            itemProp={this.microTaggingItemPropName}
            src={src}
            alt={this.getAlt(alt, title, isVideo)}
            onLoad={() => onImageLoaded(src, startTime)}
            onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
              onImageErrored(src, startTime);
              onError?.(event);
            }}
            aria-label={this.getAlt(alt, title, isVideo)}
            className={imgClassName || null}
          />
        ) : (
          src
        )}
      </div>
    );
  }
}
