import React from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { NpsPreviewModal, NpsSubmittedModal, NpsSurveyModal } from '@appsource/components/modals';
import { INpsSurveyAnswer } from '@shared/Models';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';

interface INpsModalProps {
  email: string;
  showPreviewModal: boolean;
  onDismissPreview: () => void;
  onDismissSurvey: () => void;
  onSubmitted: (answer: INpsSurveyAnswer) => void;
  correlationId: string;
  context: ILocContext & ILocParamsContext;
}

export const NpsModal: React.FunctionComponent<INpsModalProps> = ({
  email,
  showPreviewModal,
  onDismissPreview,
  onDismissSurvey,
  onSubmitted,
  correlationId,
  context,
}: INpsModalProps) => {
  const [hidePreviewDialog, { toggle: toggleHidePreviewDialog }] = useBoolean(!showPreviewModal);
  const [hideSurveyDialog, { toggle: toggleHideSurveyDialog }] = useBoolean(showPreviewModal);
  const [hideSubmittedDialog, { toggle: toggleHideSubmittedDialog }] = useBoolean(true);

  const handleDismissPreview = () => {
    toggleHidePreviewDialog();
    onDismissPreview();
  };

  const handleSubmitPreview = () => {
    toggleHidePreviewDialog();
    toggleHideSurveyDialog();
  };

  const handleDismissSurvey = () => {
    toggleHideSurveyDialog();
    onDismissSurvey();
  };

  const handleSubmitSurvey = (answer: INpsSurveyAnswer) => {
    toggleHideSurveyDialog();
    onSubmitted(answer);
    toggleHideSubmittedDialog();
  };

  return (
    <>
      <NpsPreviewModal
        hidden={hidePreviewDialog}
        onDismiss={handleDismissPreview}
        onSubmit={handleSubmitPreview}
        context={context}
      />
      <NpsSurveyModal
        hidden={hideSurveyDialog}
        email={email}
        onDismiss={handleDismissSurvey}
        onSubmit={handleSubmitSurvey}
        context={context}
      />
      <NpsSubmittedModal
        hidden={hideSubmittedDialog}
        onDismiss={toggleHideSubmittedDialog}
        context={context}
        correlationId={correlationId}
      />
    </>
  );
};
