import { stringifyError } from '@shared/utils/errorUtils';
import winston from 'winston';

export enum ServerCollection {
  ServerLogs = 'Logs',
  IncomingRequests = 'IncomingRequests',
  ServerOutgoingRequests = 'OutgoingRequests',
  DBResourceOverUsage = 'DBResourceOverUsage',
  UserTelemetry = 'UserTelemetry',
  UserFeedback = 'UserFeedback',
}

export interface IServerLogPayload {
  operation?: string;
  operationModifier?: string;
  collection?: string;
  page?: string;
}

interface IServerLoggerContext {
  requestId: string;
  correlationId: string;
}

export class ServerLogger {
  private _logger: winston.Logger;
  public context: IServerLoggerContext;

  constructor() {
    this.context = {
      correlationId: '',
      requestId: '',
    };
  }

  public get logger() {
    return this._logger;
  }

  public init(winstonLogger: winston.Logger) {
    this._logger = winstonLogger;
  }

  public debug(
    message: string,
    {
      operation = '',
      operationModifier = '',
      page = '',
      collection = ServerCollection.ServerLogs,
      ...loggerMetadata
    }: IServerLogPayload
  ) {
    const { correlationId, requestId } = this.context;
    if (this._logger) {
      this._logger.debug(message, {
        correlationId,
        requestId,
        operation,
        operationModifier,
        page,
        collection,
        ...loggerMetadata,
      });
    }
  }

  public info(
    message: string,
    {
      operation = '',
      operationModifier = '',
      page = '',
      collection = ServerCollection.ServerLogs,
      ...loggerMetadata
    }: IServerLogPayload
  ) {
    const { correlationId, requestId } = this.context;
    if (this._logger) {
      this._logger.info(message, {
        correlationId,
        requestId,
        operation,
        operationModifier,
        page,
        collection,
        ...loggerMetadata,
      });
    }
  }

  public warning(
    message: string,
    {
      operation = '',
      operationModifier = '',
      page = '',
      collection = ServerCollection.ServerLogs,
      ...loggerMetadata
    }: IServerLogPayload
  ) {
    const { correlationId, requestId } = this.context;
    if (this._logger) {
      this._logger.warn(message, {
        correlationId,
        requestId,
        operation,
        operationModifier,
        page,
        collection,
        ...loggerMetadata,
      });
    }
  }

  public error(
    message: string,
    {
      operation = '',
      operationModifier = '',
      page = '',
      collection = ServerCollection.ServerLogs,
      error,
      ...loggerMetadata
    }: IServerLogPayload & { error?: any }
  ) {
    const { correlationId, requestId } = this.context;

    // The payload of the error might be changed!
    if (this._logger) {
      this._logger.error(message, {
        correlationId,
        requestId,
        operation,
        operationModifier,
        page,
        collection,
        error: stringifyError(error || ''),
        ...loggerMetadata,
      });
    }
  }
}
