import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Dropdown, getTheme } from '@fluentui/react';
import type { IDropdownStyles } from '@fluentui/react';

import { NeutralColors } from '@fluentui/theme';

import { routes, urlPush } from '@shared/routerHistory';
import { IBuildHrefContext, ICommonContext, ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { IDataValues } from '@shared/utils/dataMapping';
import SpzaComponent from './spzaComponent';
import classNames from 'classnames';

export enum FiltersDropDownCollections {
  category = 'category',
  industry = 'industry',
  product = 'product',
}

export interface IFiltersDropDownOptionData {
  icon?: string;
  link: string;
}

export interface IFiltersDropDownOption {
  key: string;
  text: string;
  data: IFiltersDropDownOptionData;
}

export interface IFiltersDropDownProps {
  collection: FiltersDropDownCollections;
  filters: IDataValues[];
  placeholder: string;
  showIcons: boolean;
}

const theme = getTheme();

const height = 55;
const paddingLeft = 20;

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: '100%', height },
  dropdownItemSelected: { height, paddingLeft },
  dropdownItem: { height, paddingLeft },
  title: {
    height,
    lineHeight: height,
    paddingLeft: 20,
    borderRadius: 6,
    borderColor: NeutralColors.gray30,
    boxShadow: theme.effects.elevation8,
  },
  caretDownWrapper: { lineHeight: height, right: 16 },
  subComponentStyles: {
    panel: {
      main: {
        inset: '0px auto 0px 0px',
      },
    },
    label: {},
    multiSelectItem: {},
  },
};

export class FiltersDropDown extends SpzaComponent<IFiltersDropDownProps, unknown> {
  context: IBuildHrefContext & ICommonContext & ILocContext & ILocParamsContext;

  getDefaultSelectedKey(): string {
    // cast to any[] since https://github.com/microsoft/TypeScript/issues/36390
    const activeFilter: IDataValues = (this.props.filters as any[])?.find((filter: IDataValues) => filter.isActive);

    return activeFilter?.UrlKey;
  }

  getAllFilterOption(): IFiltersDropDownOption {
    return {
      key: 'ALL',
      text: this.context.loc('FiltersDropDown_All', 'ALL'),
      data: {
        icon: 'icon-all-16',
        link: this.context.buildHref(routes.home, null, {
          [this.props.collection]: '',
        }),
      },
    };
  }

  getFiltersOptions(): IFiltersDropDownOption[] {
    if (!(this.props.filters && this.props.collection)) {
      return [];
    }

    return [
      this.getAllFilterOption(),
      ...this.props.filters.map((filter: IDataValues) => {
        const icon = this.props.showIcons ? `icon-${filter.UrlKey}-16` : '';
        const link = this.context.buildHref(routes.home, null, {
          [this.props.collection]: (filter.isActive ? '!' : '') + filter.ShortcutUrlKey,
        });

        return {
          key: filter.UrlKey,
          text: filter.Title,
          data: {
            icon,
            link,
          },
        };
      }),
    ];
  }

  onRenderOption(option: IFiltersDropDownOption): JSX.Element {
    return (
      option && (
        <div className="filtersDropDownOption">
          {option.data.icon && (
            <div
              className={classNames({
                [option.data.icon]: true,
                filtersDropDownOptionIcon: true,
              })}
            ></div>
          )}
          <span>{option.text}</span>
        </div>
      )
    );
  }

  onChange(option?: IFiltersDropDownOption) {
    if (option?.data.link) {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      urlPush(option.data.link);
    }
  }

  renderImpl() {
    const { placeholder } = this.props;

    return (
      <Dropdown
        placeholder={placeholder}
        defaultSelectedKey={this.getDefaultSelectedKey()}
        options={this.getFiltersOptions()}
        onRenderTitle={(options: IFiltersDropDownOption[]) => this.onRenderOption(options[0])}
        onRenderOption={(option: IFiltersDropDownOption) => this.onRenderOption(option)}
        onChange={(_, option?: IFiltersDropDownOption) => this.onChange(option)}
        styles={dropdownStyles}
        ariaLabel={placeholder}
      />
    );
  }
}

(FiltersDropDown as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  buildHref: PropTypes.func,
  renderErrorModal: PropTypes.func,
};
