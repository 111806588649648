import { DynamicCampaign as DynamicCampaignComponent } from './../components/dynamicCampaign';
import { IState } from '../../State';
import { getDynamicCampaign } from '../actions/thunkActions';
import { EnsureAsyncDataSSR } from '@shared/hooks/useLocationChange';
import { connect } from 'react-redux';

export const mapStateToProps = (state: IState) => {
  return {
    dynamicCampaign: state.dynamicCampaign,
  };
};

const Campaign = connect(mapStateToProps)(DynamicCampaignComponent);

export default Campaign;

export const ensureAsyncDataSSR: EnsureAsyncDataSSR = (dispatch: any, getState: () => IState, params: any, request: any) => {
  request.params.id = params.id;
  request.params.publisher = params.publisher;
  return dispatch(getDynamicCampaign(request));
};
