import { ICuratedSection, IHashMap } from '../Models';

// hashmap that maps the passed identifier value to the index in the array
// i.e. if we pass an array of apps with appid as the identifier
// the returned map will be h[appid] = index in array
export function generateHashMap(data: any[], identifiers: string[]) {
  let hash: IHashMap = {};
  const dataLength = data.length;
  const idsLength = identifiers.length;

  for (let i = 0; i < dataLength; i++) {
    // data is allowed to be null
    if (data[i]) {
      for (let j = 0; j < idsLength; j++) {
        if (data[i][identifiers[j]]) {
          hash[data[i][identifiers[j]].toString().toLowerCase()] = i;
        }
      }
    }
  }

  return hash;
}

export function parseCuratedSections<T>(sections: ICuratedSection<T>[], data: T[], hashMap: IHashMap, dataMapSubCats?: any) {
  let result: ICuratedSection<T>[] = [];

  if (sections) {
    sections.forEach((section: ICuratedSection<T>) => {
      //dataMapSubCats is only supplied when filling mac app swimlanes
      //when it's given, use it to make sure each subcategory is in datamapping
      //otherwise, continue on without checking
      if (!dataMapSubCats || dataMapSubCats.includes(section.titleId)) {
        let newItems: T[] = [];

        let items: any[] = section.items;
        if (items) {
          items.forEach((item: any) => {
            const id = item.id;
            const index = hashMap[id];

            if (index >= 0) {
              newItems.push(data[index]);
            }
          });
        }

        // if we can't find any data for any item in the curated section
        // let's not show the section at all
        if (newItems.length > 0) {
          let newSection: ICuratedSection<T> = {
            titleId: section.titleId,
            items: newItems,
          };
          if (section.titleLocId) {
            newSection.titleLocId = section.titleLocId;
          }
          result.push(newSection);
        }
      }
    });
  }
  return result;
}
