import { LinkedAddIns, LinkedAddInsType, LinkedSaaS, ProductsType } from '@shared/Models';

interface EntityIdToLinkedItems<T extends (LinkedAddIns | LinkedSaaS)[]> {
  [entityId: string]: T;
}

export function mapEntityIdToLinkedItems<T extends (LinkedAddIns | LinkedSaaS)[]>(linkedItems: T): EntityIdToLinkedItems<T> {
  return linkedItems.reduce((prev, current) => {
    return {
      ...prev,
      [current.entityId]: prev[current.entityId] ? prev[current.entityId].concat(current) : [current],
    };
  }, {});
}

export function getEntityName(linkedItems: LinkedAddIns[]): string {
  return linkedItems.map((item) => `${item.type.charAt(0).toLocaleUpperCase()}${item.type.slice(1)}`).join(', ');
}
