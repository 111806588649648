import * as PropTypes from 'prop-types';
import * as React from 'react';

import { IBuildHrefContext, ICommonContext, ILocContext } from '../interfaces/context';
import { IFilterItemProps, getFilterText, handleFilterToggle, keyDownHandler } from '../utils/filterItemUtils';
// eslint-disable-next-line import/named
import { Icon, ITooltipHostStyles, TooltipHost } from '@fluentui/react';

import { Constants } from '../utils/constants';
import { InternalLink } from './internalLink';
import SpzaComponent from './spzaComponent';

export default class FilterItem<T> extends SpzaComponent<IFilterItemProps<T>, any> {
  context: ILocContext & IBuildHrefContext & ICommonContext;

  getClassName() {
    let className: string = this.props.className || 'spza_filterItem';
    className += ' filterItemName' + (this.props.isActive ? ' spza_activeFilter' : '');
    className += this.props.shouldShowFilterBoldStyle ? '' : ' spza_parent-filter-bold-disable';
    return className;
  }

  getFilterItemInner() {
    const filter = this.props.filter;
    const filterText = getFilterText(this.context, filter, this.props.showExpandedIcon, this.props.href);
    const className: string = this.getClassName();
    const filterId = (filter as any).FilterID || null;
    const filterClassname = `filterText ${this.props.isEmbed ? 'embedFilterButton' : ''} ${this.props.filterTextClass}`;
    const hostStyles: Partial<ITooltipHostStyles> = { root: { marginLeft: 5 } };
    const role: string = this.props.role || 'checkbox';
    const isActive = !!this.props.isActive;

    const clickHandler =
      this.props.onClickHandle ||
      handleFilterToggle(
        this.props.category,
        this.props.queryValue,
        isActive,
        this.props.href,
        Constants.Telemetry.ActionModifier.FilterPane,
        this.props.onClick
      );

    const otherProps = { ...this.props.otherProps };
    if (role === 'checkbox') {
      otherProps['aria-checked'] = isActive;
    }

    return (
      <label className={`${className} filterItemName`}>
        {!this.props.hideCheckbox && (
          <input
            type="checkbox"
            aria-label={filterText}
            checked={isActive}
            onChange={clickHandler}
            onKeyDown={(event: React.KeyboardEvent<any>) => {
              keyDownHandler(event, clickHandler);
            }}
          />
        )}
        <span>
          <InternalLink
            role="checkbox"
            className={filterClassname}
            aria-checked={isActive}
            accEnabled={this.props.accEnabled}
            href={this.props.href}
            onClick={clickHandler}
            key={filterId}
            {...otherProps}
          >
            {this.props.iconClassName ? (
              <div className="productIcon">
                <div className={this.props.iconClassName}></div>
              </div>
            ) : null}
            {filterText}
          </InternalLink>
          {this.props.tooltip && (
            <TooltipHost styles={hostStyles} content={this.context.loc(this.props.tooltip)}>
              <Icon className="ms-Icon ms-Icon--Info" />
            </TooltipHost>
          )}
        </span>
        <span className="resultCount">{this.props.resultCount ? this.props.resultCount : null}</span>
      </label>
    );
  }

  renderImpl() {
    return this.props.role === 'button' ? (
      <div className="c-checkbox filterItem">{this.getFilterItemInner()}</div>
    ) : (
      <li className="c-checkbox filterItem">{this.getFilterItemInner()}</li>
    );
  }
}

(FilterItem as any).contextTypes = {
  loc: PropTypes.func,
  buildHref: PropTypes.func,
  renderErrorModal: PropTypes.func,
};
