export interface ILoc {
  [loc: string]: string;
}

export interface IAutoRun {
  [loc: string]: ILoc;
}

export const AutoRuns: IAutoRun = {
  OnNewMessageCompose: { loc: 'AutoRun_OnNewMessageCompose', fallback: 'The user composes a new mail.' },
  OnNewAppointmentOrganizer: { loc: 'AutoRun_OnNewAppointmentOrganizer', fallback: 'The user creates a new event.' },
  OnMessageAttachmentsChanged: {
    loc: 'AutoRun_OnMessageAttachmentsChanged',
    fallback: 'The user adds or removes attachments from an email.',
  },
  OnAppointmentAttachmentsChanged: {
    loc: 'AutoRun_OnAppointmentAttachmentsChanged',
    fallback: 'The user adds or removes attachments from an event.',
  },
  OnMessageRecipientsChanged: {
    loc: 'AutoRun_OnMessageRecipientsChanged',
    fallback: 'The user adds or removes recipients from an email.',
  },
  OnAppointmentAttendeesChanged: {
    loc: 'AutoRun_OnAppointmentAttendeesChanged',
    fallback: 'The user adds or removes attendees from an event.',
  },
  OnAppointmentTimeChanged: {
    loc: 'AutoRun_OnAppointmentTimeChanged',
    fallback: 'The user changes the date or time of an event.',
  },
  OnAppointmentRecurrenceChanged: {
    loc: 'AutoRun_OnAppointmentRecurrenceChanged',
    fallback: 'The user changes the recurrence of an event.',
  },
  OnInfoBarDismissClicked: {
    loc: 'AutoRun_OnInfoBarDismissClicked',
    fallback: 'The user dismisses a notification while composing an email or creating an event.',
  },
  OnMessageSend: {
    loc: 'AutoRun_OnMessageSend',
    fallback: 'The user sends an email.',
  },
  OnAppointmentSend: {
    loc: 'AutoRun_OnAppointmentSend',
    fallback: 'The user sends an event invite.',
  },
};
