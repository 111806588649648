import React from 'react';
import * as PropTypes from 'prop-types';
import { Text, mergeStyleSets, Stack } from '@fluentui/react';

import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';

export interface IShowingResultsForProps {
  previousSearchText: string;
  showingResultsForText: string;
}

const contentStyles = mergeStyleSets({
  italicFont: { fontStyle: 'italic' },
  title: { marginTop: 4 },
  subTitle: { marginTop: 8 },
  leftSpace: { paddingLeft: 6 },
});

export const ShowingResultsFor: React.FunctionComponent<IShowingResultsForProps> = (
  { showingResultsForText, previousSearchText }: IShowingResultsForProps,
  context: ILocContext & ILocParamsContext
) => {
  const { title, leftSpace, italicFont } = contentStyles;

  return (
    <>
      <Stack horizontal>
        <Stack.Item>
          <Text variant="medium">{`${context.loc('Showing_Results_For_NoResults_Prefix', 'No results found for')} `}</Text>
        </Stack.Item>
        <Stack.Item className={leftSpace}>
          <Text className="ms-fontSize-14 ms-fontWeight-semibold">{`‘${previousSearchText}’`}</Text>
        </Stack.Item>
      </Stack>
      <Stack horizontal className={title}>
        <Stack.Item>
          <Text className="ms-fontSize-20">{`${context.loc('Showing_Results_For_Prefix', 'Showing results for')} `}</Text>
        </Stack.Item>
        <Stack.Item className={leftSpace}>
          <Text as={'h1'} variant="xLarge" className={italicFont}>{`‘${showingResultsForText}’`}</Text>
        </Stack.Item>
      </Stack>
    </>
  );
};

(ShowingResultsFor as React.FC).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
};
