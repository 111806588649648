import { getCookieItem } from '@shared/utils/browserStorageUtils';
import { Constants } from '@shared/utils/constants';

export const getGPCValue = () => {
  const globalPrivacyControlEnabled = (navigator as Navigator & { globalPrivacyControl?: string }).globalPrivacyControl;
  if (globalPrivacyControlEnabled) {
    return false;
  }

  try {
    const cookieValue = getCookieItem(Constants.Cookies.AMC3PAdsOptOut) || '';
    return String(cookieValue) !== '1';
  } catch {
    return true;
  }
};
