import * as React from 'react';
import { PrimaryButton, Stack } from '@fluentui/react';
import noReviewsImg from '@shared/images/noReviewsImg.svg';
import { Constants } from '@shared/utils/constants';
import { ILocContext } from '@shared/interfaces/context';

export interface INoReviewsToShow {
  context: ILocContext;
  isOfferPurchased: boolean;
  openReviewModal: () => void;
}

export const getDescriptionText = (context: ILocContext, isOfferPurchased: boolean): string => {
  if (isOfferPurchased) {
    return context.loc(
      'NoReviewsToShow_addReviewDescription_WriteReviewText',
      "This offer doesn't have any reviews yet. \nBe the first to add a review."
    );
  }

  return context.loc(
    'NoReviewsToShow_addReviewDescription_PurchaseNeededText',
    "This offer doesn't have any reviews yet. \nTo add the first review, get the offer and write your review."
  );
};

export const NoReviewsToShow = ({ context, isOfferPurchased = false, openReviewModal }: INoReviewsToShow) => {
  return (
    <Stack className="NoReviewsToShowContainer">
      <Stack.Item align="center">
        <img
          className="NoReviewsToShowImg"
          src={noReviewsImg}
          alt={context.loc('NoReviewsToShow_addReviewTitle_Text', 'No reviews yet')}
        />
      </Stack.Item>
      <Stack.Item className="NoReviewsToShowTitle" align="center">
        <span className="NoReviewsToShowTitleText">{context.loc('NoReviewsToShow_addReviewTitle_Text', 'No reviews yet')}</span>
      </Stack.Item>
      <Stack.Item className="NoReviewsToShowDescription" align="center">
        <span className="NoReviewsToShowDescriptionText">{getDescriptionText(context, isOfferPurchased)}</span>
      </Stack.Item>
      <Stack.Item align="center">
        <PrimaryButton
          className="NoReviewsToShowCTA"
          text={context.loc('AppReviewCollection_Button_Text_Write', 'Write a review')}
          onClick={() => openReviewModal()}
          data-bi-id={Constants.JsllCTAId.WriteReview}
          data-bi-area="Review"
          disabled={!isOfferPurchased}
        />
      </Stack.Item>
    </Stack>
  );
};
