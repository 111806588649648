import * as React from 'react';
import * as PropTypes from 'prop-types';
import {
  IDataMap,
  IDataCollection,
  IDataValues,
  ICategoryList,
  IProductValue,
  CategoriesInnerKeys,
} from '@shared/utils/dataMapping';
import { getCategoryListByProductId, productKeyByUrlKey } from '../utils/datamappingHelpers';
import FilterItem from './filterItem';
import SpzaComponent from './spzaComponent';
import { Constants } from '../utils/constants';
import { shouldFlatProducts, shouldHideIndustriesAndProducts, shouldShowFilter } from '../../embed/embedHostUtils';
import { ILocContext, ICommonContext, IBuildHrefContext } from '../interfaces/context';
import { RichTextDropDown, IRichTextDropDownItem } from '../../shared/components/richTextDropDown';
import {
  shouldShowSubFilter,
  sortFiltersKeys,
  serviceOffersSupportedCountries,
  getCountryRegionfilterLink,
} from '../utils/filterHelpers';
import { serviceOffersSupportedCAStates, serviceOffersSupportedUSStates } from '@shared/utils/serviceOffersSupportedStatesCodes';
import { isEmbedded, getProductByUrlKey } from '../utils/appUtils';
import { SpzaInstrumentProvider, SpzaInstrumentService } from '../services/telemetry/spza/spzaInstrument';
import { ITelemetryData } from './../Models';
import { getWindow } from '../services/window';
import { getEntityRegistration } from '../utils/entityRegistration';
import { FontIcon, TooltipHost } from '@fluentui/react';
import type { ITooltipHostStyles } from '@fluentui/react';
import { logger } from '@src/logger';
import { listOfUrlKeysToExcludeInAppsource } from '@shared/oneTaxonomy';
import { filterHideList } from '@shared/oneTaxonomy/utils';
import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const classNames = require('classnames-minimal');

const FilterQuery = Constants.FilterQuery;

export interface IFilterPaneProps {
  dataMap: IDataMap;
  isEmbedded: boolean;
  embedHost: string;
  getFilterLink: (filter: IDataValues, query?: any) => string;
  stateCountryFilterHandler?: (countryCode: string, regionCode: string) => Promise<any>;
  entityType?: Constants.EntityType;
  countryCode?: string;
  regionCode?: string;
  params?: { [key: string]: string };
  query?: { [key: string]: string };
  currentView: string;
  countriesList: { [country: string]: string };
}

interface ISubTitles {
  name: string;
  products: string[] | { [index: number]: string };
}

// Add a new object if need subtitles for existing products.
const subTitle = {
  2: {
    editions: [
      {
        name: 'Loc_BusinessEdition',
        products: [Constants.dynamics365.financeAndOperationsBD],
      },
      {
        name: 'Loc_EnterpriseEdition',
        products: [
          Constants.dynamics365.customerInsightsED,
          Constants.dynamics365.customerServiceED,
          Constants.dynamics365.fieldServicesED,
          Constants.dynamics365.financeED,
          Constants.dynamics365.serviceAutomationED,
          Constants.dynamics365.salesED,
          Constants.dynamics365.marketingED,
          Constants.dynamics365.commerceED,
          Constants.dynamics365.supplyChainManagementED,
        ],
      },
    ],
  },
};

// Add the id of a parent filter if we need the check box filters indented.

export interface IFilterPaneState {
  stateFilterItems: IRichTextDropDownItem<string>[];
  countryFilterItems: IRichTextDropDownItem<string>[];
  selectedRegion: IRichTextDropDownItem<string>;
  selectedCountry: IRichTextDropDownItem<string>;
  productData: IProductValue;
}

export class FilterPane extends SpzaComponent<IFilterPaneProps, IFilterPaneState> {
  context: ILocContext & ICommonContext & IBuildHrefContext;
  private instrument: SpzaInstrumentProvider;

  constructor(props: IFilterPaneProps, context: ILocContext & ICommonContext & IBuildHrefContext) {
    super(props, context);
    this.instrument = SpzaInstrumentService.getProvider();
    this.state = this.getInitialState();
  }

  getInitialState() {
    const defaultState: IFilterPaneState = {
      selectedCountry: {
        text: Constants.usFilterText,
        value: Constants.usCountryCode.toUpperCase(),
      },
      selectedRegion: {
        text: Constants.allRegionsFilterText,
        value: Constants.allStatesCode.toUpperCase(),
      },
      countryFilterItems: this.getCountriesFilterItems(),
      stateFilterItems: this.getStatesFilterItems(Constants.usCountryCode),
      productData: this.getCategoryID(this.props),
    };
    return defaultState;
  }

  UNSAFE_componentWillMount() {
    if (this.props.entityType && this.props.entityType === Constants.EntityType.Service) {
      this.setSelectRegionFilters(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: IFilterPaneProps) {
    if (
      nextProps.entityType &&
      nextProps.entityType === Constants.EntityType.Service &&
      (this.props.countryCode !== nextProps.countryCode || this.props.regionCode !== nextProps.regionCode)
    ) {
      this.setSelectRegionFilters(nextProps);
    }
    if (nextProps.currentView === 'appGallery') {
      const currentProductData = this.getCategoryID(nextProps);
      this.setState({ productData: currentProductData });
    }
    this.setState({ countryFilterItems: this.getCountriesFilterItems() });
  }

  getCategoryID(props: IFilterPaneProps) {
    const productData = {};
    if (typeof props.query?.product === 'string') {
      const products = props.query.product.split(';');
      if (products && products.length > 0) {
        const productFilter = products[0].trim();

        const product = getProductByUrlKey({ urlKey: productFilter });

        if (product) {
          productData[product.FilterGroup] = product.FilterID;
        }
      }
    }
    return productData;
  }

  setSelectRegionFilters(props: IFilterPaneProps) {
    let selectedCountry: any;
    let selectedRegion: any;
    if (props.countryCode) {
      selectedCountry = serviceOffersSupportedCountries.filter((item: any) => {
        return item.countryCode === props.countryCode;
      });
    }
    if (props.regionCode) {
      if (props.countryCode && props.countryCode.toLowerCase() === Constants.caCountryCode) {
        selectedRegion = serviceOffersSupportedCAStates.filter((item: any) => {
          return item.code === props.regionCode;
        });
      } else {
        selectedRegion = serviceOffersSupportedUSStates.filter((item: any) => {
          return item.code === props.regionCode;
        });
      }
    }
    const firstSupportedCountryIndex = this.getFirstSupportedCountryIndex();
    this.setState({
      selectedCountry:
        selectedCountry && selectedCountry.length > 0
          ? {
              text: selectedCountry[0].name,
              value: selectedCountry[0].countryCode,
            }
          : {
              text: serviceOffersSupportedCountries[`${firstSupportedCountryIndex}`].name,
              value: serviceOffersSupportedCountries[`${firstSupportedCountryIndex}`].countryCode,
            },
      selectedRegion:
        selectedRegion && selectedRegion.length > 0
          ? { text: selectedRegion[0].name, value: selectedRegion[0].code }
          : {
              text: serviceOffersSupportedUSStates[0].name,
              value: serviceOffersSupportedUSStates[0].code,
            },
      countryFilterItems: this.getCountriesFilterItems(),
      stateFilterItems: this.getStatesFilterItems(props.countryCode),
    });
    getCountryRegionfilterLink(this.context, props.countryCode, props.regionCode);
    this.props.stateCountryFilterHandler(props.countryCode, props.regionCode);
  }

  setProductOrComplianceCategory(filter: any, isProductFilter: boolean) {
    if (isProductFilter) {
      if (!filter.isActive) {
        // check product filter
        const productData = {};
        productData[filter.FilterGroup] = filter.FilterID;
        this.setState({ productData });
      } else {
        // uncheck product filter
        this.setState({ productData: null });
      }
    }
  }

  getRoute() {
    return getEntityRegistration(this.props.entityType).route;
  }

  buildFilterElement(filters: any, filter: string, category: string, key: React.Key, flat = false) {
    const isCategoryFilter = category === FilterQuery.category;
    const isIndustryFilter = category === FilterQuery.industry;

    if ((isCategoryFilter || isIndustryFilter) && !filter) {
      return;
    }
    let currentFilter =
      category === FilterQuery.product ? this.props.dataMap.products[productKeyByUrlKey[`${filter}`]] : filters[`${filter}`];
    if (currentFilter.IsReference) {
      currentFilter = this.props.dataMap.categories.default[`${filter as CategoriesInnerKeys}`];
    }

    const queryValue = currentFilter.UrlKey;

    const isProductFilter = category === FilterQuery.product && !flat;
    const isAppComplianceFilter = category === FilterQuery.product && !flat;
    let activeParentFilter = (currentFilter.isActive || this.props.isEmbedded) && currentFilter.ShortcutFilters != null;

    // for Office on services page, for now, we have no children
    // so we should hide the expanded icon
    if (this.props.entityType === Constants.EntityType.Service && currentFilter.BackendKey === 'Office365') {
      activeParentFilter = false;
    }

    if (currentFilter.isActive && currentFilter.SubCategoryDataMapping) {
      const visibleSubFilters = Object.keys(currentFilter.SubCategoryDataMapping).filter(
        (filterKey) => !listOfUrlKeysToExcludeInAppsource.includes(currentFilter.SubCategoryDataMapping[`${filterKey}`].UrlKey)
      );
      activeParentFilter = visibleSubFilters.length > 0;
    }

    const otherProps: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> = {};
    const children = this.props.dataMap.products[`${filter}`]?.ShortcutFilters;
    if (children) {
      otherProps['aria-expanded'] = currentFilter.isActive;
    }
    if (isProductFilter) {
      otherProps['aria-pressed'] = currentFilter.isActive;
    }
    if (currentFilter.FilterID) {
      otherProps.id = category + '-' + currentFilter.FilterID;
    }
    if (isEmbedded) {
      const generatedId = uuidv4();
      otherProps.id = `${category} - ${generatedId}`;
    }

    const classNameFilterPaneRoot = 'filterPaneItemRoot' + (currentFilter.isActive ? ' taxonomy' : '');
    const hideCheckbox = isProductFilter || isAppComplianceFilter || isCategoryFilter || isIndustryFilter;

    return (
      <li className={classNameFilterPaneRoot} key={key}>
        <FilterItem
          filter={currentFilter}
          isActive={currentFilter.isActive}
          category={category}
          queryValue={queryValue}
          hideCheckbox={hideCheckbox}
          showExpandedIcon={activeParentFilter}
          href={this.props.getFilterLink(currentFilter, this.props.query)}
          onClick={() => this.setProductOrComplianceCategory(currentFilter, isProductFilter)}
          key={currentFilter.FilterID}
          role={hideCheckbox ? 'button' : 'checkbox'}
          otherProps={otherProps}
          accEnabled={true}
          isEmbed={this.props.isEmbedded}
          tooltip={currentFilter.Tooltip}
        />
        {activeParentFilter && !flat ? (
          <ul>
            {currentFilter.SubCategoryDataMapping
              ? this.renderSubCategories(currentFilter.SubCategoryDataMapping, FilterQuery.category)
              : this.renderSubFilters(currentFilter, category, filters)}
          </ul>
        ) : null}
      </li>
    );
  }

  renderSubCategories(filters: IDataCollection, category: string) {
    const filterKeys: string[] = Object.keys(filters);

    return filterKeys
      .filter(
        (filterKey) =>
          shouldShowSubFilter(filters[`${filterKey}`]) && !filterHideList.includes(filters[`${filterKey}`].BackendKey)
      )
      .sort(function (filterKeyA, filterKeyB) {
        return sortFiltersKeys(filterKeyA, filterKeyB, filters);
      })
      .map((key: string) => {
        const childFilter: IDataValues = filters[`${key}`];
        const childProps = {
          'aria-selected': childFilter.isActive,
        };

        return (
          <FilterItem
            filter={childFilter}
            isActive={childFilter.isActive}
            category={category}
            queryValue={childFilter.UrlKey}
            hideCheckbox={false}
            href={this.props.getFilterLink(childFilter)}
            key={childFilter.Title}
            otherProps={childProps}
          />
        );
      });
  }

  renderSubFilters(currentFilter: IDataValues, category: string, filterCollection: IDataCollection) {
    if (subTitle[`${currentFilter.FilterID}`]) {
      const subTitles: ISubTitles[] = subTitle[currentFilter.FilterID].editions;

      return subTitles.map((subTitle: ISubTitles) => {
        return (
          <div className="subtitle" key={subTitle.name}>
            {this.renderChildrenFilters(subTitle.products, category, filterCollection)}
          </div>
        );
      });
    }
    const indentFilters = [this.props.dataMap.products.Dynamics365.FilterID, this.props.dataMap.products.PowerPlatform.FilterID];

    const office = this.props.dataMap.products.Office365;
    if (office) {
      indentFilters.push(office.FilterID);
    }
    const shouldIndent: boolean = indentFilters.indexOf(currentFilter.FilterID) >= 0;
    return this.renderChildrenFilters(currentFilter.ShortcutFilters, category, filterCollection, shouldIndent);
  }

  renderChildrenFilters(ShortcutFilters: any, category: any, filters: any, indent?: boolean) {
    return (
      <div className={indent ? 'indentCheckbox' : ''}>
        {Object.keys(ShortcutFilters).map((key: string, index: number) => {
          const childFilter = filters[ShortcutFilters[`${key}`]];

          // a child filter might be null when no apps/services with that filter are present
          if (childFilter) {
            const childProps = {};
            if (childFilter.isActive) {
              childProps['aria-checked'] = childFilter.isActive;
            }

            return (
              <FilterItem
                filter={childFilter}
                isActive={childFilter.isActive}
                category={category}
                queryValue={childFilter.UrlKey}
                href={this.props.getFilterLink(childFilter)}
                key={index}
                otherProps={childProps}
                accEnabled={true}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  }

  renderCategoryBlock(title: string, filters: ICategoryList) {
    const groupClass = 'spza_filterGroup category';
    return (
      <div className={groupClass}>
        <h2 className="c-heading5 spza_filterTitle">{this.context.loc(title)}</h2>
        <ul className="spza_filterItems" role="list" aria-label={`${this.context.loc(title)} filter`}>
          {Object.entries(filters)
            .sort(([filterKeyA], [filterKeyB]) => {
              return sortFiltersKeys(filterKeyA, filterKeyB, filters);
            })
            .map(([categoryKey, categoryValue]: [CategoriesInnerKeys, IDataValues]) => {
              const filter = categoryValue.IsReference ? this.props.dataMap.categories.default[`${categoryKey}`] : categoryValue;
              return this.buildFilterElement(
                filters,
                categoryKey,
                FilterQuery.category,
                categoryKey,
                filter.HideReferenceSubCategories || false
              );
            })}
        </ul>
      </div>
    );
  }

  renderSearchBlock(title: string, filters: IDataCollection, category: string) {
    const groupClass = 'spza_filterGroup ' + category;
    return (
      <div className={groupClass}>
        <h2 className="c-heading5 spza_filterTitle">{this.context.loc(title)}</h2>
        <div className="spza_filterItems" role="listbox" aria-label={this.context.loc(title) + ' filter'}>
          {Object.keys(filters)
            .sort(function (filterKeyA, filterKeyB) {
              return sortFiltersKeys(filterKeyA, filterKeyB, filters);
            })
            .filter((filterKey) => !filterHideList.includes(filters[`${filterKey}`].BackendKey))
            .map((filter, key) => this.buildFilterElement(filters, filter, category, key))}
        </div>
      </div>
    );
  }

  renderProductBlock(title: string) {
    const flat = this.props.isEmbedded && shouldFlatProducts(this.props.embedHost); // embeded admin portal
    const embedHost = this.props.isEmbedded && this.props.embedHost;
    const products = this.props.dataMap.products;
    const productGroupings = Object.keys(products).reduce(
      function (acc, productKey) {
        const product = products[`${productKey}`];
        if (product.IsChildFilter && !flat) {
          return acc;
        }
        if (product.BackendKey === Constants.officeBackendKey) {
          return acc;
        }
        if (product.BackendKey === Constants.adminPortalBackendKey) {
          return acc;
        }
        if (product.BackendKey === Constants.officeFlatBackendKey && flat) {
          return acc;
        }
        if (embedHost && !shouldShowFilter(embedHost, product)) {
          return acc;
        }
        acc.apps.push(product);

        return acc;
      },
      { apps: [] as IDataValues[], plugins: [] as IDataValues[] }
    );

    const key = this.state.productData && Object.keys(this.state.productData)[0];
    const value = this.state.productData && this.state.productData[`${key}`];
    const activeDescendant = key + '_' + value;
    return (
      <div
        className="spza_filterGroup embeded-product"
        role="listbox"
        aria-activedescendant={!this.state.productData ? '' : activeDescendant}
        aria-label="Products filter"
      >
        <h2 className="c-heading5 spza_filterTitle">{this.context.loc(title)}</h2>
        <div className={'spza_filterItemsNoIndent'}>
          {productGroupings.apps
            .filter((app) => app)
            .map((app, key) => this.buildFilterElement(products, app.UrlKey, FilterQuery.product, key, flat))}
        </div>
      </div>
    );
  }

  renderDropDownFilterBlock(filterType: string) {
    let defaultItem: IRichTextDropDownItem<string> = {
      text: '',
      value: '',
    };
    defaultItem = filterType === 'States' ? this.state.selectedRegion : this.state.selectedCountry;

    return (
      <div className="spza_dropDownFilterSection">
        <div className="spza_filterSubTitle">{this.context.loc('FilterType_' + filterType)}</div>
        <RichTextDropDown
          className="regionList"
          options={filterType === 'States' ? this.state.stateFilterItems : this.state.countryFilterItems}
          value={defaultItem}
          searchEnabled={filterType !== 'States'}
          onChangeHandle={(option: any) => {
            if (filterType === 'States') {
              this.handleStateFilterSelectionChange(option);
            } else {
              this.handleCountryFilterSelectionChange(option);
            }
          }}
          dropdownLabel={filterType === 'States' ? 'state/province' : 'country/region'}
        />
      </div>
    );
  }

  renderAppComplianceBlock(title: string) {
    const flat = this.props.isEmbedded && shouldFlatProducts(this.props.embedHost); // embeded admin portal
    const complianceTypes = this.props.dataMap.appCompliance;
    const hostStyles: Partial<ITooltipHostStyles> = { root: { marginLeft: 5 } };
    const complianceTypeGroupings = Object.keys(complianceTypes).reduce(
      (acc, complianceType) => {
        const compliance = complianceTypes[`${complianceType}`];
        acc.complianceType.push(compliance);

        return acc;
      },
      { complianceType: [] as IDataValues[], plugins: [] as IDataValues[] }
    );

    return (
      <div className="spza_filterGroup embeded-product" role="listbox" aria-label="AppCompliance filter">
        <h2 className="c-heading5 spza_filterTitle">
          {this.context.loc(title)}{' '}
          <TooltipHost styles={hostStyles} content={this.context.loc('Global_Loc_compliance_tooltip')}>
            <FontIcon iconName="Info" />
          </TooltipHost>
        </h2>
        <div className={'spza_filterItemsNoIndent'}>
          {complianceTypeGroupings.complianceType.map((type, key) =>
            this.buildFilterElement(complianceTypes, type.UrlKey, FilterQuery.appCompliance, key, flat)
          )}
        </div>
      </div>
    );
  }

  handleStateFilterSelectionChange(regionCode: any) {
    this.logfilterChangeTelemetry(this.state.selectedRegion.value, regionCode, 'state');
    this.setState({
      countryFilterItems: this.state.countryFilterItems,
      stateFilterItems: this.state.stateFilterItems,
      selectedCountry: this.state.selectedCountry,
      selectedRegion: regionCode,
    });
    getCountryRegionfilterLink(this.context, this.state.selectedCountry.value, regionCode.value);
    this.props.stateCountryFilterHandler(this.state.selectedCountry.value, regionCode.value);
  }

  handleCountryFilterSelectionChange(countryCode: any) {
    const states = this.getStatesFilterItems(countryCode.value);
    const allStateCode = Constants.allStatesCode;
    const region = states.filter((item) => item.value.toLowerCase() === allStateCode)[0];
    this.logfilterChangeTelemetry(this.state.selectedCountry.value, countryCode, 'country');
    this.setState({
      countryFilterItems: this.state.countryFilterItems,
      stateFilterItems: states,
      selectedCountry: countryCode,
      selectedRegion: region,
    });
    getCountryRegionfilterLink(this.context, countryCode.value, region.value);
    this.props.stateCountryFilterHandler(countryCode.value, region.value);
  }

  logfilterChangeTelemetry(oldValue: string, newValue: string, type: string) {
    const detailsObj = {
      oldValue,
      newValue,
      filter: type,
    };
    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.FilterClick,
      actionModifier: Constants.Telemetry.ActionModifier.FilterPane,
      details: JSON.stringify(detailsObj),
    };
    this.instrument.probe<ITelemetryData>('logTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });
  }

  renderLocationFilters() {
    const groupClass = 'spza_filterGroup locations';
    return (
      <div className={groupClass}>
        <h2 className="c-heading5 spza_filterTitle">{this.context.loc('FilterType_Location')}</h2>
        {this.renderDropDownFilterBlock('Countries')}
        {this.state.selectedCountry.value.toLowerCase() === Constants.usCountryCode ||
        this.state.selectedCountry.value.toLowerCase() === Constants.caCountryCode
          ? this.renderDropDownFilterBlock('States')
          : null}
      </div>
    );
  }

  setCategoryList() {
    const defaultCategories = this.props.dataMap.categories.default;
    if (this.state.productData && Object.keys(this.state.productData).length > 0) {
      const dynamicCategories = getCategoryListByProductId(this.state.productData, this.props.dataMap);
      if (dynamicCategories) {
        return dynamicCategories;
      }
    }
    return defaultCategories;
  }

  renderImpl() {
    const hideIndustriesAndProducts = this.props.isEmbedded && shouldHideIndustriesAndProducts(this.props.embedHost);
    const showServiceTypeFilters = this.props.entityType === Constants.EntityType.Service;
    const categoryList = this.setCategoryList();

    return (
      <div
        className={classNames({ spza_filterContainer: !isEmbedded(), spza_filterContainerEmbedded: isEmbedded() })}
        role="navigation"
        aria-label="Product Category Filter"
      >
        {showServiceTypeFilters ? this.renderLocationFilters() : null}
        {!hideIndustriesAndProducts && showServiceTypeFilters ? this.renderProductBlock('FilterType_Products') : null}
        {!showServiceTypeFilters && Object.getOwnPropertyNames(categoryList).length > 0
          ? this.renderCategoryBlock('FilterType_Categories', categoryList)
          : null}
        {hideIndustriesAndProducts
          ? null
          : this.renderSearchBlock('FilterType_Industries', this.props.dataMap.industries, FilterQuery.industry)}
        {!hideIndustriesAndProducts && !showServiceTypeFilters ? this.renderProductBlock('FilterType_Products') : null}
        {!hideIndustriesAndProducts && !showServiceTypeFilters ? this.renderAppComplianceBlock('FilterType_AppCompliance') : null}
        {showServiceTypeFilters
          ? this.renderSearchBlock('FilterType_ServiceType', this.props.dataMap.serviceTypes, FilterQuery.serviceType)
          : null}
      </div>
    );
  }

  getStatesFilterItems(countryCode: string) {
    const items =
      countryCode && countryCode.toLowerCase() === Constants.caCountryCode
        ? serviceOffersSupportedCAStates
        : serviceOffersSupportedUSStates;
    const filterList: IRichTextDropDownItem<string>[] = [];
    items.forEach((item) => filterList.push({ text: item.name, value: item.code }));
    return filterList;
  }

  getFirstSupportedCountryIndex(): number {
    return 1;
  }

  getCountriesFilterItems() {
    const filterCountries = Object.keys(this.props.countriesList).length > 0;
    const filterList: IRichTextDropDownItem<string>[] = [];
    const firstSupportedCountryIndex = this.getFirstSupportedCountryIndex();
    for (let i = firstSupportedCountryIndex; i < serviceOffersSupportedCountries.length; i++) {
      if (
        !filterCountries ||
        (filterCountries && this.props.countriesList[serviceOffersSupportedCountries[`${i}`].countryCode]) ||
        serviceOffersSupportedCountries[`${i}`].countryCode === 'ALL'
      ) {
        filterList.push({
          text: serviceOffersSupportedCountries[`${i}`].name,
          value: serviceOffersSupportedCountries[`${i}`].countryCode,
        });
      }
    }
    return filterList;
  }

  getDefaultState() {
    let selectedRegion: any;
    if (
      (this.props.countryCode && this.props.countryCode.toLowerCase() === Constants.caCountryCode) ||
      (this.state.selectedCountry && this.state.selectedCountry.value.toLowerCase() === Constants.caCountryCode)
    ) {
      // there is some region code present in the query param.
      if (this.props.regionCode) {
        selectedRegion = serviceOffersSupportedCAStates.filter((item: any) => {
          return item.code === this.props.regionCode;
        });
        // the one found in the query param is valid return that
        if (selectedRegion && selectedRegion.length > 0) {
          return selectedRegion;
        }
      }
    }
  }
}

(FilterPane as any).contextTypes = {
  loc: PropTypes.func,
  renderErrorModal: PropTypes.func,
  buildHref: PropTypes.func,
};
