import React, { useMemo } from 'react';
import { IPrice, ISimpleSKU } from '@shared/Models';
import { NeutralColors } from '@fluentui/theme';
import classNames from 'classnames';
import { sortTermPrices } from '@shared/utils/pricing';
import { SaasRowData } from '../saasPricingTableBase';
import { subtotalColumnKey } from '../saasPricingTableColumns';
import { mergeStyleSets, Stack } from '@fluentui/react';
import type { IColumn, IDetailsRowProps } from '@fluentui/react';

export interface MultilineRenderItem {
  sku: ISimpleSKU;
  futurePriceSku?: ISimpleSKU;
  price: IPrice;
}

const classes = mergeStyleSets({
  cell: {
    borderBottom: `1px solid ${NeutralColors.gray30}`,
  },
  rightBorder: {
    borderRight: `1px solid ${NeutralColors.gray20}`,
  },
  topBorder: {
    borderTop: `1px solid ${NeutralColors.gray20}`,
  },
  padding: {
    padding: '16px',
  },
  subtotalCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export const SaasPricingRow = ({ item, columns, rowWidth }: IDetailsRowProps) => {
  const { sku, futurePriceSku } = item as SaasRowData;

  const sortedPrices = useMemo(() => sortTermPrices(sku.termPrices), [sku.termPrices]);
  const multilineColumns = useMemo(() => columns.filter((column) => column.isMultiline), [columns]);

  const numberOfPrices = sortedPrices.length;
  const cellHeightPercentage = `${100 / numberOfPrices}%`;

  const getRowsForColumn = (column: IColumn) => (multilineColumns.includes(column) ? sortedPrices : [sortedPrices[0]]);

  const getCellStyle = (column: IColumn) => ({
    width: column.calculatedWidth,
    maxWidth: column.calculatedWidth,
  });

  return (
    <Stack role="row" horizontal horizontalAlign="start" style={{ width: rowWidth }}>
      {columns.map((column: IColumn, columnIndex: number) => {
        const rowsForColumn = getRowsForColumn(column);

        return (
          <Stack
            role="cell"
            style={getCellStyle(column)}
            className={classNames(classes.cell, {
              [classes.rightBorder]: columnIndex !== columns.length - 1,
              [classes.subtotalCell]: column.key === subtotalColumnKey,
            })}
            key={column.key}
          >
            {rowsForColumn.map((price, index) => {
              const isTopBorder = index !== 0;
              const stackHeight = rowsForColumn.length > 1 ? cellHeightPercentage : 'auto';
              const className = classNames(classes.padding, { [classes.topBorder]: isTopBorder });

              return (
                <Stack key={index} className={className} style={{ height: stackHeight }}>
                  {column.onRender({ sku, futurePriceSku, price })}
                </Stack>
              );
            })}
          </Stack>
        );
      })}
    </Stack>
  );
};
