import * as React from 'react';
import * as PropTypes from 'prop-types';
import SpzaComponent from '@shared/components/spzaComponent';
import { ICommonContext, ILocContext } from '@shared/interfaces/context';
import { IPricingInformation } from '@shared/Models';
import { IStyle, mergeStyleSets, MessageBar } from '@fluentui/react';
import { Text } from '@fluentui/react/lib/Text';

export interface IFuturePriceWarning {
  futurePrices: IPricingInformation;
}

interface Styles {
  warningText: IStyle;
}

const classes = mergeStyleSets<Styles>({
  warningText: {
    lineHeight: 'normal',
  },
});

export default class FuturePriceWarning extends SpzaComponent<IFuturePriceWarning, any> {
  context: ILocContext & ICommonContext;

  renderImpl() {
    return (
      !!this.props.futurePrices?.skus?.length && (
        <MessageBar className="futurePriceMessageBar" isMultiline={false}>
          <Text className={classes.warningText}>
            {this.context.loc('FuturePrices_warning', 'Please note some prices will be changing. View details below.')}
          </Text>
        </MessageBar>
      )
    );
  }
}

(FuturePriceWarning as any).contextTypes = {
  renderErrorModal: PropTypes.func,
  loc: PropTypes.func,
};
