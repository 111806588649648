import React from 'react';
import { IFontStyles } from '@fluentui/react';
import { IItemData, LinkedAddIns, LinkedAddInsType } from '@shared/Models';
import { mapEntityIdToLinkedItems, getEntityName } from '@shared/utils/linkedItems';
import { ItemsContainerComponent } from '@shared/components/itemsContainer';

export interface ILinkedAddInsComponentProps {
  linkedItems: LinkedAddIns[];
  logOnClick?: (type: LinkedAddInsType) => void;
  hrefCallback?: (entityId: string) => string;
  wrap?: boolean;
  withText?: boolean;
  textSize?: keyof Pick<IFontStyles, 'smallPlus' | 'medium'>;
  withTooltip?: boolean;
  tooltipTitle?: string;
  dataBiId?: string;
}
const LinkedAddInsComponent: React.FunctionComponent<ILinkedAddInsComponentProps> = ({
  linkedItems,
  logOnClick,
  hrefCallback,
  tooltipTitle,
  textSize = 'medium',
  wrap = false,
  withText = false,
  withTooltip = false,
  dataBiId = 'View AddIn',
}: ILinkedAddInsComponentProps) => {
  const items: IItemData[] = React.useMemo(() => {
    const mapEntityToItems = mapEntityIdToLinkedItems(linkedItems);
    const mapEntityToItemsTuple = Object.entries(mapEntityToItems);
    return mapEntityToItemsTuple.map((item) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, addins] = item;
      return {
        icons: addins.map((addinsItem) => addinsItem.type),
        title: getEntityName(addins),
        urlKey: addins[0].entityId,
      };
    });
  }, [linkedItems]);

  return (
    <ItemsContainerComponent
      items={items}
      textSize={textSize}
      hrefCallback={hrefCallback}
      logOnClick={logOnClick}
      dataBiId={dataBiId}
      tooltipTitle={tooltipTitle}
      withText={withText}
      withTooltip={withTooltip}
      wrap={wrap}
      withDivider={false}
    />
  );
};

export default LinkedAddInsComponent;
