import * as models from '@server/ApiModels';
import { Constants } from '@shared/utils/constants';
import { IRequestContext, UserSegment, IAcquistionUserInfo, IAcquistionPayload, IUserProfile } from '@shared/Models';
import { ODataQuery, RequestQuery } from '@server/api/RequestQuery';
import { Logger } from '@server/LogHelper';
import { isValidAPIVersion, errorMessages } from '@server/api/ApiVersion';
import { updateServerError } from '@server/apiUtils/ODataError';
import { getMaskedIpFromRawIpAddress } from '@shared/utils/locationUtils';
import { storeAcquisitionInfo } from '@server/services';
import { logger } from '@src/logger';
import * as jwt from 'jsonwebtoken';
import crypto from 'crypto';
import deepCopy from 'deepcopy';

const emptyId = '00000000-0000-0000-0000-000000000000';

export function generateUid(len: number) {
  return crypto
    .randomBytes(Math.ceil((len * 3) / 4))
    .toString('base64')
    .slice(0, len);
}

export class RequestHeaders {
  requestId: string;
  correlationId: string;
  authorization?: string;

  constructor(requestId: string, correlationId: string, authorization?: string) {
    this.requestId = requestId;
    this.correlationId = correlationId;
    this.authorization = authorization;
  }
}

export class RequestCookies {
  appSourceCookie: string;
  appSourceLeadCookie: string;

  constructor(appSourceCookie?: string, appSourceLeadCookie?: string) {
    this.appSourceCookie = appSourceCookie;
    this.appSourceLeadCookie = appSourceLeadCookie;
  }
}

export function extractRequestCookies(req: any): RequestCookies {
  let appSourceCookie: string = null;
  let appSourceLeadCookie: string = null;

  if (req && req.cookies) {
    appSourceCookie = req.cookies[Constants.Cookies.AppSourceCookie] || null;
    appSourceLeadCookie = req.cookies[Constants.Cookies.AppSourceLeadCookie] || null;
  }

  return new RequestCookies(appSourceCookie, appSourceLeadCookie);
}

// Recommended to use this method to get the requestId and correlationId as a single typed object from a request object
export function extractRequestHeaders(req: any): RequestHeaders {
  let requestId = Constants.ReservedCorrelationIds.EmptyId;
  let correlationId = Constants.ReservedCorrelationIds.EmptyId;
  let authorization: string = null;

  if (req) {
    requestId = req.headers[Constants.Headers.RequestId] || Constants.ReservedCorrelationIds.EmptyId;
    correlationId = req.headers[Constants.Headers.CorrelationId] || Constants.ReservedCorrelationIds.EmptyId;
    authorization = req.headers[Constants.Headers.Authorization] || null;
  }

  return new RequestHeaders(requestId, correlationId, authorization);
}

// Helper method to create an Outgoing Request
export function createOutgoingHttpContext(
  incomingRequest: any,
  operation: string,
  httpMethod: string,
  hostname: string,
  targetUri: string,
  apiVersion: string,
  contentLength: number,
  headers: string
): models.IHttpRequestContext {
  let requestId = emptyId;
  let correlationId = emptyId;
  if (incomingRequest) {
    if (incomingRequest.headers) {
      requestId = incomingRequest.headers[Constants.Headers.RequestId] || emptyId;
      correlationId = incomingRequest.headers[Constants.Headers.CorrelationId] || emptyId;
    }
  }
  const requestContext: models.IHttpRequestContext = {
    requestId,
    correlationId,
    operation: operation || '',
    httpMethod: httpMethod || '',
    hostName: hostname || '',
    targetUri: targetUri || '',
    userAgent: '',
    clientIpAddress: '',
    apiVersion: apiVersion || '',
    contentLength: contentLength || 0,
    headers: JSON.stringify(headers) || '',
  };
  return requestContext;
}

/**
 * get masked ip address from http request forwared address
 *
 *  supports ipv6 and ipv6
 *
 *  NOTE: this function doesn't invalidate ipaddresses, whose any of segment values exceeds its maximum
 *
 * @param request http request
 *
 * @return
 *  undefined, if valid ipv6 or valid ipv4 cannot be found, or
 *  a string with last segment of ip adress masked with 'xxx'
 */
export function getMaskedIP(request: any): string {
  const rawIpAddress: string = request.headers?.[Constants.Headers.XForwardedFor] || request.connection?.remoteAddress;

  return getMaskedIpFromRawIpAddress(rawIpAddress);
}

export function getStringQueryParam(req: any, queryParamKey: string): string {
  if (!req || !req.query || !req.query[`${queryParamKey}`]) {
    // Handle it as a 400 in the future
    return null;
  }

  let queryParamValue = req.query[`${queryParamKey}`];
  if (Array.isArray(queryParamValue)) {
    if (queryParamValue.length === 0) {
      return null;
    }

    // When we receive retries from the front end view API's we currently receive duplicate
    // query string parameters and we reject the request. We are temporarily allowing the
    // multiple query string parameters to be used with the first value to be used. This will
    // be removed once the front end retry logic no longer sends multiple query string parameters
    // on retries.
    queryParamValue = queryParamValue[0];

    // TODO: Re-enable this and remove the line above once the retry logic sends single query string parameters.
    // If multiple values are provided for the same query param, throw Error
    // throw Error(queryParamKey + errorMessages.multipleValuesForQueryParam);
  }

  try {
    return decodeURIComponent(queryParamValue);
  } catch (e) {
    const { requestId, correlationId } = extractRequestHeaders(req);
    Logger.logDebugMessage(requestId, correlationId, 'getStringQueryParam', `URI malformed ${queryParamValue}`);
    logger.debug(`URI malformed ${queryParamValue}`, {
      action: 'getStringQueryParam',
    });

    return null;
  }
}

export function throwIfQueryParamPresent(req: any, queryParamKey: any) {
  const value = getStringQueryParam(req, queryParamKey);
  if (value) {
    throw Error(queryParamKey + ' is not supported');
  }
}

export function getRequestContext(req: any) {
  let requestId = Constants.ReservedCorrelationIds.EmptyId;
  let correlationId = Constants.ReservedCorrelationIds.EmptyId;
  let apiVersion = Constants.appsourceApiVersion;
  let requestUrl = '';
  let headers: any[] = [];
  let cookies: any = {};
  let remoteIpAddress = null;

  if (req) {
    requestId = req.headers[Constants.Headers.RequestId] || Constants.ReservedCorrelationIds.EmptyId;
    correlationId = req.headers[Constants.Headers.CorrelationId] || Constants.ReservedCorrelationIds.EmptyId;
    apiVersion = getStringQueryParam(req, 'api-version') || getStringQueryParam(req, 'version');
    requestUrl = req.url || '';
    headers = req.headers;
    cookies = extractRequestCookies(req);
    remoteIpAddress = getMaskedIP(req);

    if (remoteIpAddress === Constants.TypeOf.undefinedName) {
      remoteIpAddress = null;
    }
  }

  const requestContext: IRequestContext = {
    correlationId,
    requestId,
    operation: requestUrl,
    apiVersion,
    continuation: null,
    headers,
    cookies,
    remoteIpAddress,
  };

  return requestContext;
}

export function rotateArray(array: any[], numElements: number) {
  while (numElements-- > 0) {
    const tmp = array.shift();
    array.push(tmp);
  }

  return array;
}

export function logDebug(requestContext: IRequestContext, message: string) {
  Logger.logDebugMessage(requestContext.requestId, requestContext.correlationId, requestContext.operation, message);
}

function checkAndLogDuplicateQueryStringParameters(req: any, requestContext: IRequestContext) {
  if (!req || !req.query) {
    return;
  }

  const keys = Object.keys(req.query);
  for (let i = 0; i < keys.length; i++) {
    if (Array.isArray(req.query[keys[`${i}`]])) {
      logDebug(requestContext, 'Duplicate query string parameters found:' + req.originalUrl);
      break;
    }
  }
}

// TODO: Move this method and getRequestContext methods out of Utils.tsx
//       to remove the cyclic dependency on Utils.tsx from these classes
export function getRequestQuery(req: any) {
  const query = new RequestQuery();
  const requestHeaders = extractRequestHeaders(req);
  const requestContext = getRequestContext(req);

  checkAndLogDuplicateQueryStringParameters(req, requestContext);

  query.requestContext = requestContext;
  query.apiVersion = getStringQueryParam(req, 'api-version');
  query.flightCode = getStringQueryParam(req, 'flightCodes');
  query.billingRegion = getStringQueryParam(req, 'billingregion');
  query.appId = getStringQueryParam(req, 'appId');
  query.planId = getStringQueryParam(req, 'planId');
  query.partnerId = getStringQueryParam(req, 'partnerId');
  query.id = getStringQueryParam(req, 'id');
  query.userSegment = getStringQueryParam(req, 'usersegment');
  query.headers = requestHeaders;
  query.odataQuery = ODataQuery.createODataQuery(req);
  query.cookies = extractRequestCookies(req);
  query.publisherid = getStringQueryParam(req, 'publisherid');
  query.requestContext.continuation = query.odataQuery.$skiptoken;
  query.country = getStringQueryParam(req, 'country');

  if (!query.apiVersion) {
    throw Error(errorMessages.missingApiVersion);
  } else if (!isValidAPIVersion(query.apiVersion)) {
    throw Error(errorMessages.invalidApiVersion);
  }

  return query;
}

export function logError(requestContext: IRequestContext, message: string, error: string) {
  Logger.logError(requestContext.operation, message, error, {
    requestId: requestContext.requestId,
    correlationId: requestContext.correlationId,
  });
}

export function logAcquisitionInfo(requestContext: IRequestContext, body: any) {
  const decodedAuthToken = jwt.decode(this.authToken);
  Logger.LogAcquisitionInfo(requestContext.requestId, requestContext.correlationId, body, decodedAuthToken);
  storeAcquisitionInfo({ payload: body }, this.authToken);
}

/**
 * Helper function for logging the outbound request end.
 *
 * @param {models.IHttpRequestContext} request : The HttpRequestContext.
 * @param {number} duration : The duration of the request.
 * @param {number} httpStatus : The Http Status response code.
 * @param {string} errorMessage : The error message if available.
 */
export function logOutboundRequestEnd(
  request: models.IHttpRequestContext,
  duration: number,
  httpStatus: number,
  errorMessage: string
) {
  Logger.LogOutboundRequestEnd(request, duration, httpStatus, errorMessage);
}

export function IsAppAndPartnerDataMerged(): boolean {
  const settingValue = process.env.mergeDataCollections || 'false';
  return settingValue === 'true';
}

export function isGuid(guid: string): boolean {
  const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return pattern.test(guid);
}

export function isServerId(serverId: string): boolean {
  const serverPattern = /^[0-9]{8}-[0-9]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9]{12}$/i;
  return serverPattern.test(serverId);
}

export function checkReqHeaders(req: any) {
  // cacheStatus is called from antares we dont have an option to add these headers
  if (req && req.headers && req.baseUrl !== '/view/cacheStatus') {
    const requestId = req.headers[Constants.Headers.RequestId];
    const correlationId = req.headers[Constants.Headers.CorrelationId];

    if (!requestId || !correlationId) {
      return 'No requestId or correlationId found in the header of the request';
    }

    if (!isGuid(requestId)) {
      return 'Invalid requestId format in the header of the request';
    }

    if (!isGuid(correlationId) && !isServerId(correlationId)) {
      return 'Invalid correlationId format in header';
    }
  }
  return '';
}

export function clone(obj: any): any {
  if (!obj) {
    return obj;
  }

  return JSON.parse(JSON.stringify(obj));
}

function scrubPiiField(piiField = '') {
  return piiField?.length ? `length: ${piiField.length}` : piiField;
}

function scrubPii(user: IAcquistionUserInfo | IUserProfile) {
  Constants.PIIFields.forEach((piiField) => {
    user[`${piiField}`] = scrubPiiField(user[`${piiField}`]);
  });
}

export function scrubAcquisitionPayload(payload: IAcquistionPayload): IAcquistionPayload | Record<string, undefined> {
  try {
    const payloadCopy = deepCopy(payload);
    if (payloadCopy.userInfo) {
      scrubPii(payloadCopy.userInfo);
    }

    payloadCopy.notes = payloadCopy.notes?.length?.toString();
    return payloadCopy;
  } catch (error) {
    logger.error(`Failed to scrubAcquisitionPayload with error ${error.error.message}`);
    return {};
  }
}

export function scrubUserProfile(user: IUserProfile) {
  try {
    const userCopy = deepCopy(user);
    scrubPii(userCopy);

    return userCopy;
  } catch (error) {
    logger.error(`Failed to scrubUserProfile with error ${error.error.message}`);
    return {};
  }
}

export function removeDuplicateQueryParams(queryParams: string) {
  let urlQueryParams = queryParams;
  if (queryParams.indexOf('?') > -1) {
    const splitValues = queryParams.split('?');
    urlQueryParams = splitValues && splitValues.length > 1 ? splitValues[1] : urlQueryParams;
  }

  if (!urlQueryParams || urlQueryParams === '' || queryParams.indexOf('=') === -1) {
    return '';
  }

  const obj = JSON.parse('{"' + urlQueryParams.replace(/&/g, '","').replace(/=/g, '":"') + '"}');
  return `?${new URLSearchParams(obj).toString()}`;
}

// Logs the payload provided as Json into debug table
export function logPayload(requestId: string, correlationId: string, payload: any, operationName: string) {
  try {
    Logger.logDebugMessage(requestId, correlationId, operationName, JSON.stringify(scrubAcquisitionPayload(payload)));
    logger.debug(JSON.stringify(scrubAcquisitionPayload(payload)), { action: operationName });
  } catch (e) {
    const error = updateServerError(e);
    Logger.logErrorMessage(requestId, correlationId, operationName, 'Failed to log payload with error ' + error.error.message);
    logger.error(`Failed to log payload with error ${error.error.message}`, { action: operationName, error: e });
  }
}

// List of all the crawlers which we need to exclude from the user traffic
