// eslint-disable-next-line import/named
import { Store } from 'redux';
import { IState } from '../State';
import { shouldRefreshAccessToken } from '@shared/services/http/tokenRestClient';
import { ITelemetryData } from '@shared/Models';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { stringifyError } from '@shared/utils/errorUtils';
import { createSetAccessTokenAction } from '@shared/actions/actions';
import { Constants } from '@shared/utils/constants';
import { logger } from '@src/logger';
import { HttpModule } from '@shared/services/http/httpProtocol';
import { mergeAccessToken } from '@shared/utils/accessToken';
import { fetchAccessTokens } from '@shared/msal/tokens';

// If token expiration time is less than minimum, token should be refreshed.
const MIN_TIME_UNTIL_EXPIRATION_SEC = 60 * 30; // 30 minutes in seconds.

const logErrorTelemetry = (message: string, error?: Error) => {
  const payload: ITelemetryData = {
    page: window.location.href || '',
    action: Constants.Telemetry.Action.RefreshToken,
    actionModifier: Constants.Telemetry.ActionModifier.Error,
    details: JSON.stringify({ message, error: error ? stringifyError(error) : '' }),
  };
  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
  logger.error(payload.details, { action: payload.action, actionModifier: payload.actionModifier });
};

export const refreshTokenIfNeeded = async (store: Store<IState>) => {
  const { accessToken } = store.getState().users;
  if (accessToken && shouldRefreshAccessToken(accessToken, MIN_TIME_UNTIL_EXPIRATION_SEC)) {
    try {
      const refreshedAccessToken = await fetchAccessTokens({
        resources: ['spza', 'arm', 'graph', 'commerce', 'jarvis', 'marketplaceLeads', 'pifd', 'agreement'],
      });
      if (refreshedAccessToken) {
        const storedToken = mergeAccessToken({ currentAccessToken: accessToken, newAccessToken: refreshedAccessToken });
        store.dispatch(createSetAccessTokenAction(storedToken));
        HttpModule.updateAccessToken(storedToken);
      } else {
        logErrorTelemetry('AppSourceClientHookup fail to refresh token. Null token created.');
      }
    } catch (error) {
      logErrorTelemetry('AppSourceClientHookup fail to refresh token with error', error);
    }
  }
};
