/* eslint-disable @typescript-eslint/no-namespace */
import * as Redux from 'redux';
import { IState } from '@src/State';
import { AwaMetaTags, Constants } from '@shared/utils/constants';
import { routes } from '@shared/routerHistory';
import { IMetaTags } from '@shared/Models';
import { Service } from '@shared/serviceViewModel';
import { getDocument } from '@shared/services/window';
import { RenderProps } from '@src/server/matchClientRoutes';

export namespace metaTagConstants {
  export const viewportName = 'viewport';
  export const viewportValue = 'width=device-width, initial-scale=1';
  export const typeName = 'type';
  export const typeValue = 'Website';
  export const keywordsName = 'keywords';
  export const spzaGalleryWordKeywordsValue =
    'word plugin, word apps, word add ons, word extension, word add-ins, grammar, grammer, chatgpt word, word cloud, citation';
  export const spzaGalleryExcelKeywordsValue =
    'chatgpt excel, excel chatgpt, excel plugins, excel apps, excel add ons, excel add-ins, excel extensions, ai excel, web viewer';
  export const spzaGalleryPptKeywordsValue =
    'ppt plugin, ppt add-ins, ppt apps, ppt add ons, ppt extension, powerpoint add-ins, powerpoint apps, powerpoint plugin, powerpoint add ons, powerpoint extension, power bi visual';
  export const spzaGalleryOutlookKeywordsValue =
    'outlook plugin, outlook add-ins, outlook apps, outlook add ons, outlook extension, email recovery';
  export const spzaGalleryOfficeKeywordsValue =
    'outlook apps, office store, outlook plugin, word plugin, gpt excel, chatgpt excel, excel plugin, microsoft office store, Business apps, microsoft 365 apps, education apps, word apps, excel apps, sharepoint apps, powerpoint apps, grammar, power bi visual';
  export const spzaGalleryKeywordsValue =
    'Business apps, 365 apps, office store, dynamics apps, outlook apps, word apps, government apps, education apps, microsoft office store, excel apps, ppt apps, powerpoint apps, office plugin, excel plugin';
  export const spzaHomeKeywordsValue =
    'Business apps, 365 apps, office store apps, dynamics apps, outlook apps, word apps, government apps, education apps, consulting services';
  export const csGalleryKeywordsValue =
    'PowerBI consulting services, dynamics consulting services, assessment, briefing, consulting services implementation';
  export const desName = 'description';
  export const csGalleryDesValue =
    'Find the consulting services for your platforms: PowerBI, Dynamics, Web Apps. Get solutions tailored to your industry: Agriculture, Education, Distribution, Financial services, Government, Healthcare, Manufacturing, Professional services, Retail and consumer goods.';
  export const spzaHomeDesValue =
    'Discover the app for your business needs and explore tailored solutions for various industries. Whether you are seeking Microsoft 365 apps for Word, Excel, PowerPoint, or Outlook, or apps for Power Platform, Dynamics 365, SaaS, we have you covered. Enhance your productivity and streamline your work with our diverse range of plugins, including grammar and citation tools, GPT-powered Word and Excel enhancements, word clouds, and more. Find the right tools to excel in your field and make the most of your software experience, right here.';
  export const spzaWordGalleryDesValue =
    'Discover the Word app for your business needs. Explore a diverse range of Word plugins. Whether you are in professional writing, content creation, or any other domain, Word apps are designed to enhance your productivity and simplify your tasks. Unleash the full potential of your documents with tools such as GPT-powered enhancements, grammar and citation plugins, and more.';
  export const spzaExcelGalleryDesValue =
    'Discover the Excel app for your business needs. Explore a diverse range of Excel plugins. Whether you are in finance, data analysis, project management, or any other field, our Excel apps are designed to boost your productivity and streamline your work. Unlock the potential of your spreadsheets with tools including GPT-powered enhancements, web viewer, data analysis plugins, and more.';
  export const spzaPptGalleryDesValue =
    'Discover the PowerPoint app for your business needs. Explore a diverse range of PowerPoint plugins. Whether you are in sales, education, marketing, or any other sector, our PowerPoint apps are designed to boost your creativity and streamline your presentations. Unleash the full potential of your slides with tools like GPT-powered enhancements, interactive plugins, and more.';
  export const spzaOutlookGalleryDesValue =
    'Discover the Outlook app for your business needs. Explore a diverse range of Outlook plugins. Whether you are in sales, education, marketing, or any other sector, our PowerPoint apps are designed to boost your creativity and streamline your presentations. Unleash the full potential of your slides with tools like GPT-powered enhancements, interactive plugins, and more.';
  export const spzaOfficeGalleryDesValue =
    'Discover the Office app for your business needs. Explore a diverse range of Word , Excel, PowerPoint, Outlook, Teams, and SharePoint plugins. Whether you are in sales, education, marketing, or any other sector, our apps are designed to boost your creativity and enhance your productivity. Unleash the full potential of your Office suite with tools like GPT-powered plugins, grammar checker, interactive add-ins, and more.';
  export const sitenameName = 'site_name';
  export const sitenameValue = 'Appsource – Business Apps';
  export const titleName = 'title';
  export const csTitleValue = 'Appsource –  Consulting services';
  export const urlName = 'url';
  export const localeName = 'locale';
  export const imageName = 'image';
  export const videoName = 'video';

  export const spzaEnvironment = 'portal';
  export const spzaUrl = 'https://appsource.microsoft.com';

  export const twitterCard = 'twitter:card';
  export const twitterSite = 'twitter:site';
  export const twitterTitle = 'twitter:title';
  export const twitterDes = 'twitter:description';
  export const twitterImage = 'twitter:image';
  export const twitterCardValue = 'summary';
  export const twitterSiteValue = '@appsource';
}

export function getPreventIndexMetatag(siteEnv: string): HTMLMetaElement | null {
  if (siteEnv !== 'prod') {
    const preventIndexMetatag = getDocument().createElement('meta');
    preventIndexMetatag.setAttribute('name', 'robots');
    preventIndexMetatag.setAttribute('content', 'noindex');
    return preventIndexMetatag;
  }
}
const isGallery = (path: string, galleryType: string) => {
  const pathArray = path.split('/');
  return pathArray[pathArray.length - 1].indexOf(galleryType) > -1 || path.indexOf(Constants.category) > -1;
};

export function getMetaTag(key: string, value: string): HTMLMetaElement {
  if (value) {
    const metaTag = getDocument().createElement('meta');
    metaTag.setAttribute('name', key);
    metaTag.setAttribute('content', value);
    return metaTag;
  }
}

export function getOGtag(key: string, value: string): HTMLMetaElement {
  if (value) {
    const metaTag = getDocument().createElement('meta');
    metaTag.setAttribute('property', `og:${key}`);
    metaTag.setAttribute('content', value);
    return metaTag;
  }
}

export function getViewPortTag(): HTMLMetaElement {
  return getMetaTag(metaTagConstants.viewportName, metaTagConstants.viewportValue);
}

export function getWebtypeOGTag(): HTMLMetaElement {
  return getOGtag(metaTagConstants.typeName, metaTagConstants.typeValue);
}

export function getAppSourceWordGalleryKeywordsTag(): HTMLMetaElement {
  return getMetaTag(metaTagConstants.keywordsName, metaTagConstants.spzaGalleryWordKeywordsValue);
}

export function getAppSourceExcelGalleryKeywordsTag(): HTMLMetaElement {
  return getMetaTag(metaTagConstants.keywordsName, metaTagConstants.spzaGalleryExcelKeywordsValue);
}

export function getAppSourcePptGalleryKeywordsTag(): HTMLMetaElement {
  return getMetaTag(metaTagConstants.keywordsName, metaTagConstants.spzaGalleryPptKeywordsValue);
}

export function getAppSourceOfficeGalleryKeywordsTag(): HTMLMetaElement {
  return getMetaTag(metaTagConstants.keywordsName, metaTagConstants.spzaGalleryOfficeKeywordsValue);
}

export function getAppSourceOutlookGalleryKeywordsTag(): HTMLMetaElement {
  return getMetaTag(metaTagConstants.keywordsName, metaTagConstants.spzaGalleryOutlookKeywordsValue);
}

export function getAppSourceGalleryKeywordsTag(): HTMLMetaElement {
  return getMetaTag(metaTagConstants.keywordsName, metaTagConstants.spzaGalleryKeywordsValue);
}

export function getAppSourceHomeKeywordsTag(): HTMLMetaElement {
  return getMetaTag(metaTagConstants.keywordsName, metaTagConstants.spzaHomeKeywordsValue);
}

export function getAppSourceCSGalleryKeywordsTag(): HTMLMetaElement {
  return getMetaTag(metaTagConstants.keywordsName, metaTagConstants.csGalleryKeywordsValue);
}

export function getAppSourceCSGalleryDescriptionTag(): HTMLMetaElement {
  return getMetaTag(metaTagConstants.desName, metaTagConstants.csGalleryDesValue);
}

export function getAppSourceWordDescriptionTag(): HTMLMetaElement {
  return getMetaTag(metaTagConstants.desName, metaTagConstants.spzaWordGalleryDesValue);
}

export function getAppSourceExcelDescriptionTag(): HTMLMetaElement {
  return getMetaTag(metaTagConstants.desName, metaTagConstants.spzaExcelGalleryDesValue);
}

export function getAppSourcePptDescriptionTag(): HTMLMetaElement {
  return getMetaTag(metaTagConstants.desName, metaTagConstants.spzaPptGalleryDesValue);
}

export function getAppSourceOutlookDescriptionTag(): HTMLMetaElement {
  return getMetaTag(metaTagConstants.desName, metaTagConstants.spzaOutlookGalleryDesValue);
}

export function getAppSourceOfficeDescriptionTag(): HTMLMetaElement {
  return getMetaTag(metaTagConstants.desName, metaTagConstants.spzaOfficeGalleryDesValue);
}

export function getAppSourceHomeDescriptionTag(): HTMLMetaElement {
  return getMetaTag(metaTagConstants.desName, metaTagConstants.spzaHomeDesValue);
}

export function getAppSourcePageOGSitename(): HTMLMetaElement {
  return getOGtag(metaTagConstants.sitenameName, metaTagConstants.sitenameValue);
}

export function getAppSourcePageOGTitleTag(): HTMLMetaElement {
  return getOGtag(metaTagConstants.titleName, metaTagConstants.sitenameValue);
}

export function getAppSourceCSGalleryOGTitleTag(): HTMLMetaElement {
  return getOGtag(metaTagConstants.titleName, metaTagConstants.csTitleValue);
}

export function getAppSourceGalleryOGDescriptionTag(): HTMLMetaElement {
  return getOGtag(metaTagConstants.desName, metaTagConstants.spzaGalleryKeywordsValue);
}

export function getAppSourceWordGalleryOGDescriptionTag(): HTMLMetaElement {
  return getOGtag(metaTagConstants.desName, metaTagConstants.spzaWordGalleryDesValue);
}

export function getAppSourcePptGalleryOGDescriptionTag(): HTMLMetaElement {
  return getOGtag(metaTagConstants.desName, metaTagConstants.spzaPptGalleryDesValue);
}

export function getAppSourceExcelGalleryOGDescriptionTag(): HTMLMetaElement {
  return getOGtag(metaTagConstants.desName, metaTagConstants.spzaExcelGalleryDesValue);
}

export function getAppSourceOutlookGalleryOGDescriptionTag(): HTMLMetaElement {
  return getOGtag(metaTagConstants.desName, metaTagConstants.spzaOutlookGalleryDesValue);
}

export function getAppSourceOfficeGalleryOGDescriptionTag(): HTMLMetaElement {
  return getOGtag(metaTagConstants.desName, metaTagConstants.spzaOfficeGalleryDesValue);
}

export function getAppSourceHomeOGDescriptionTag(): HTMLMetaElement {
  return getOGtag(metaTagConstants.desName, metaTagConstants.spzaHomeKeywordsValue);
}

export function getAppSourceCSGalleryOGDescriptionTag(): HTMLMetaElement {
  return getOGtag(metaTagConstants.desName, metaTagConstants.csGalleryKeywordsValue);
}

export function getAwaMetaTags(state: IState): HTMLMetaElement[] {
  const awaMetaTags: HTMLMetaElement[] = [];
  if (state?.config) {
    awaMetaTags.push(getMetaTag(AwaMetaTags.locale, state.config.locale));
    awaMetaTags.push(getMetaTag(AwaMetaTags.signedIn, `${state.users?.signedIn ?? false}`));
  }
  return awaMetaTags;
}

function isCanonicalQueryKey(queryKey: string) {
  return Constants.KeepInCanonicalFilters.indexOf(queryKey) > -1;
}
const parseFirstFilter = (filterstring: string) => {
  return filterstring.split(';')[0].split(Constants.semicolon)[0];
};

function getGalleryCanonicalSuffix(currentUrl: string) {
  if (currentUrl.split('?').length < 2) {
    return '';
  }
  const queryParamsString = currentUrl.split('?')[1];
  const queryParamsArray = queryParamsString.split('&');
  for (const queryParam of queryParamsArray) {
    const queryTuple = queryParam.split('=');
    if (queryTuple.length > 1 && isCanonicalQueryKey(queryTuple[0])) {
      return '?' + parseFirstFilter(queryParam);
    }
  }
  return '';
}

const parseLocale = (path: string) => {
  const match = path.match(Constants.SeoLocaleRegex);
  return match ? match[0].substr(1) : Constants.DefaultLocale;
};

export function getCanonicalLink(currentUrl: string, isMacEnv: boolean) {
  const path = (currentUrl && currentUrl.split('?')[0]) || '';
  let canonicalLink: string = path;
  const locale = parseLocale(path);
  if (isMacEnv) {
    if (path.indexOf(Constants.appsGallery) > -1 && !isGallery(path, Constants.GalleryTypes.apps)) {
      canonicalLink = path + Constants.appDetailsCanonicalTabSuffix;
    } else if (path.includes('/sitemap')) {
      canonicalLink = `/${locale}/sitemap`;
    } else if (path.split('/').length <= 3) {
      canonicalLink = `/${locale}/`;
    }
  } else {
    if (path.indexOf(Constants.csGallery) > -1) {
      canonicalLink = isGallery(path, Constants.GalleryTypes.consultingServices)
        ? path + getGalleryCanonicalSuffix(currentUrl)
        : path;
    } else if (path.indexOf(Constants.appsGallery) > -1) {
      canonicalLink = path + getGalleryCanonicalSuffix(currentUrl);
    } else if (path.indexOf(Constants.product) > -1) {
      canonicalLink = path + Constants.appDetailsCanonicalTabSuffix;
    } else if (path.includes('/sitemap')) {
      canonicalLink = `/${locale}/sitemap`;
    } else {
      canonicalLink = `/${locale}/`;
    }
  }
  return canonicalLink.toLowerCase();
}

export function getCanonicalLinkTag({ path, protocol, host }: { path: string; protocol: string; host: string }) {
  const runningEnv = process.env.webAppName;
  const runningOnMac = runningEnv === Constants.canonicalAMPEnvWebAppName;
  const canonicalLink = `${protocol}://${host}${getCanonicalLink(path, runningOnMac)}`;
  const canonicalLinkTag = getDocument().createElement('link');
  canonicalLinkTag.rel = 'canonical';
  canonicalLinkTag.href = canonicalLink;
  return canonicalLinkTag;
}

export function getPageName(renderProps: any, env: string) {
  // get cuurent page from pathname and pass to getComponentHTMLData for meta tag
  let page: number = Constants.PageToGiveMetaTag.PageNotReady;
  if (env !== metaTagConstants.spzaEnvironment) {
    page = Constants.PageToGiveMetaTag.PageNotReady;
  } else if (
    renderProps.location.pathname === '/' + renderProps.params.locale + routes.home.getPath(null) ||
    renderProps.location.pathname === '/' + renderProps.params.locale ||
    renderProps.location.pathname === '/' + renderProps.params.locale + '/'
  ) {
    page = Constants.PageToGiveMetaTag.SpzaHomePage;
  } else if (renderProps.location.pathname.startsWith('/' + renderProps.params.locale + routes.marketplace.getPath(null))) {
    if (renderProps.query?.product === 'word') {
      page = Constants.PageToGiveMetaTag.SpzaWordGalleryPage;
    } else if (renderProps.query?.product === 'excel') {
      page = Constants.PageToGiveMetaTag.SpzaExcelGalleryPage;
    } else if (renderProps.query?.product === 'powerpoint') {
      page = Constants.PageToGiveMetaTag.SpzaPowerpointGalleryPage;
    } else if (renderProps.query?.product === 'outlook') {
      page = Constants.PageToGiveMetaTag.SpzaOutlookGalleryPage;
    } else if (renderProps.query?.product === 'office') {
      page = Constants.PageToGiveMetaTag.SpzaOfficeGalleryPage;
    } else {
      page = Constants.PageToGiveMetaTag.SpzaGalleryPage;
    }
  } else if (renderProps.location.pathname === '/' + renderProps.params.locale + routes.marketplaceServices.getPath(null)) {
    page = Constants.PageToGiveMetaTag.SpzaCSGalleryPage;
  } else if (
    renderProps.params.productId &&
    renderProps.location.pathname.startsWith(
      '/' +
        renderProps.params.locale +
        routes.appDetails.getPath({
          productId: renderProps.params.productId,
          entityId: renderProps.params.entityId,
        })
    )
  ) {
    page = Constants.PageToGiveMetaTag.SpzaDetailPage;
  } else if (
    renderProps.location.pathname.startsWith(
      '/' + renderProps.params.locale + routes.serviceDetail.getPath({ entityId: renderProps.params.entityId })
    )
  ) {
    page = Constants.PageToGiveMetaTag.SpzaCSDetailPage;
  } else {
    page = Constants.PageToGiveMetaTag.PageNotReady;
  }
  return page;
}

export function formatMetaTags(metaObj: any) {
  let metaTags = '';
  if (metaObj) {
    for (const key in metaObj) {
      if (metaObj[`${key}`] !== '') {
        metaTags += metaObj[`${key}`];
      }
    }
  }
  return metaTags;
}

export function createMetaTags(store: Redux.Store<IState>, renderProps: RenderProps, page: number, url: string) {
  let metaObj: IMetaTags;

  if (page === Constants.PageToGiveMetaTag.SpzaHomePage) {
    metaObj = {
      metaDescription: getAppSourceHomeDescriptionTag(),
      metaKeywords: getAppSourceHomeKeywordsTag(),
      metaOGTitle: getAppSourcePageOGTitleTag(),
      metaOGUrl: getOGtag(metaTagConstants.urlName, metaTagConstants.spzaUrl + url),
      metaOGDescription: getAppSourceHomeOGDescriptionTag(),
      metaOGLocale: getOGtag(metaTagConstants.localeName, renderProps.params.locale ? renderProps.params.locale : 'en-us'),
      metaOGSiteName: getAppSourcePageOGSitename(),
    };
  } else if (page === Constants.PageToGiveMetaTag.SpzaWordGalleryPage) {
    metaObj = {
      metaDescription: getAppSourceWordDescriptionTag(),
      metaKeywords: getAppSourceWordGalleryKeywordsTag(),
      metaOGTitle: getAppSourcePageOGTitleTag(),
      metaOGUrl: getOGtag(metaTagConstants.urlName, metaTagConstants.spzaUrl + url),
      metaOGDescription: getAppSourceWordGalleryOGDescriptionTag(),
      metaOGLocale: getOGtag(metaTagConstants.localeName, renderProps.params.locale ? renderProps.params.locale : 'en-us'),
      metaOGSiteName: getAppSourcePageOGSitename(),
    };
  } else if (page === Constants.PageToGiveMetaTag.SpzaExcelGalleryPage) {
    metaObj = {
      metaDescription: getAppSourceExcelDescriptionTag(),
      metaKeywords: getAppSourceExcelGalleryKeywordsTag(),
      metaOGTitle: getAppSourcePageOGTitleTag(),
      metaOGUrl: getOGtag(metaTagConstants.urlName, metaTagConstants.spzaUrl + url),
      metaOGDescription: getAppSourceExcelGalleryOGDescriptionTag(),
      metaOGLocale: getOGtag(metaTagConstants.localeName, renderProps.params.locale ? renderProps.params.locale : 'en-us'),
      metaOGSiteName: getAppSourcePageOGSitename(),
    };
  } else if (page === Constants.PageToGiveMetaTag.SpzaPowerpointGalleryPage) {
    metaObj = {
      metaDescription: getAppSourcePptDescriptionTag(),
      metaKeywords: getAppSourcePptGalleryKeywordsTag(),
      metaOGTitle: getAppSourcePageOGTitleTag(),
      metaOGUrl: getOGtag(metaTagConstants.urlName, metaTagConstants.spzaUrl + url),
      metaOGDescription: getAppSourcePptGalleryOGDescriptionTag(),
      metaOGLocale: getOGtag(metaTagConstants.localeName, renderProps.params.locale ? renderProps.params.locale : 'en-us'),
      metaOGSiteName: getAppSourcePageOGSitename(),
    };
  } else if (page === Constants.PageToGiveMetaTag.SpzaOutlookGalleryPage) {
    metaObj = {
      metaDescription: getAppSourceOutlookDescriptionTag(),
      metaKeywords: getAppSourceOutlookGalleryKeywordsTag(),
      metaOGTitle: getAppSourcePageOGTitleTag(),
      metaOGUrl: getOGtag(metaTagConstants.urlName, metaTagConstants.spzaUrl + url),
      metaOGDescription: getAppSourceOutlookGalleryOGDescriptionTag(),
      metaOGLocale: getOGtag(metaTagConstants.localeName, renderProps.params.locale ? renderProps.params.locale : 'en-us'),
      metaOGSiteName: getAppSourcePageOGSitename(),
    };
  } else if (page === Constants.PageToGiveMetaTag.SpzaOfficeGalleryPage) {
    metaObj = {
      metaDescription: getAppSourceOfficeDescriptionTag(),
      metaKeywords: getAppSourceOfficeGalleryKeywordsTag(),
      metaOGTitle: getAppSourcePageOGTitleTag(),
      metaOGUrl: getOGtag(metaTagConstants.urlName, metaTagConstants.spzaUrl + url),
      metaOGDescription: getAppSourceOfficeGalleryOGDescriptionTag(),
      metaOGLocale: getOGtag(metaTagConstants.localeName, renderProps.params.locale ? renderProps.params.locale : 'en-us'),
      metaOGSiteName: getAppSourcePageOGSitename(),
    };
  } else if (page === Constants.PageToGiveMetaTag.SpzaGalleryPage) {
    metaObj = {
      metaDescription: getAppSourceHomeDescriptionTag(),
      metaKeywords: getAppSourceGalleryKeywordsTag(),
      metaOGTitle: getAppSourcePageOGTitleTag(),
      metaOGUrl: getOGtag(metaTagConstants.urlName, metaTagConstants.spzaUrl + url),
      metaOGDescription: getAppSourceGalleryOGDescriptionTag(),
      metaOGLocale: getOGtag(metaTagConstants.localeName, renderProps.params.locale ? renderProps.params.locale : 'en-us'),
      metaOGSiteName: getAppSourcePageOGSitename(),
    };
  } else if (page === Constants.PageToGiveMetaTag.SpzaCSGalleryPage) {
    metaObj = {
      metaDescription: getAppSourceCSGalleryDescriptionTag(),
      metaKeywords: getAppSourceCSGalleryKeywordsTag(),
      metaOGTitle: getAppSourceCSGalleryOGTitleTag(),
      metaOGUrl: getOGtag(metaTagConstants.urlName, metaTagConstants.spzaUrl + url),
      metaOGDescription: getAppSourceCSGalleryOGDescriptionTag(),
      metaOGLocale: getOGtag(metaTagConstants.localeName, renderProps.params.locale ? renderProps.params.locale : 'en-us'),
      metaOGSiteName: getAppSourcePageOGSitename(),
    };
  } else if (page === Constants.PageToGiveMetaTag.SpzaDetailPage) {
    const app = store
      .getState()
      .apps.dataList.find((app) => app?.entityId?.toLowerCase() === renderProps?.params?.entityId?.toLowerCase());

    if (app) {
      let keywords = '';
      if (app.detailInformation && app.detailInformation.Keywords && app.detailInformation.Keywords.length > 0) {
        for (let i = 0; i < app.detailInformation.Keywords.length; i++) {
          if (i !== 0) {
            keywords += ', ';
          }
          keywords += app.detailInformation.Keywords[`${i}`];
        }
      }
      let videoUrl = '';
      if (app.detailInformation && app.detailInformation.DemoVideos && app.detailInformation.DemoVideos.length > 0) {
        videoUrl = app.detailInformation.DemoVideos[0].VideoLink;
      }
      metaObj = {
        metaDescription: getMetaTag(metaTagConstants.desName, app.shortDescription),
        metaKeywords: getMetaTag(metaTagConstants.keywordsName, keywords),
        metaOGTitle: getOGtag(metaTagConstants.titleName, app.title),
        metaOGUrl: getOGtag(metaTagConstants.urlName, metaTagConstants.spzaUrl + url),
        metaOGImage: getOGtag(metaTagConstants.imageName, app.iconURL),
        metaOGDescription: getOGtag(metaTagConstants.desName, app.shortDescription),
        metaOGLocale: getOGtag(metaTagConstants.localeName, renderProps.params.locale ? renderProps.params.locale : 'en-us'),
        metaOGSiteName: getOGtag(metaTagConstants.sitenameName, app.title),
        metaOGVideo: getOGtag(metaTagConstants.videoName, videoUrl),
        metaTwitterCard: getMetaTag(metaTagConstants.twitterCard, metaTagConstants.twitterCardValue),
        metaTwitterSite: getMetaTag(metaTagConstants.twitterSite, metaTagConstants.twitterSiteValue),
        metaTwitterTitle: getMetaTag(metaTagConstants.twitterTitle, app.title),
        metaTwitterDescription: getMetaTag(metaTagConstants.twitterDes, app.shortDescription),
        metaTwitterImage: getMetaTag(metaTagConstants.twitterImage, app.iconURL),
      };
    }
  } else if (page === Constants.PageToGiveMetaTag.SpzaCSDetailPage) {
    const csApp: Service = store
      .getState()
      .services.dataList.find((app) => app?.entityId?.toLowerCase() === renderProps?.params?.entityId?.toLowerCase());

    if (csApp) {
      let videoUrl = '';
      if (csApp.detailInformation && csApp.detailInformation.DemoVideos && csApp.detailInformation.DemoVideos.length > 0) {
        videoUrl = csApp.detailInformation.DemoVideos[0].VideoLink;
      }

      metaObj = {
        metaDescription: getMetaTag(metaTagConstants.desName, csApp.extraData?.shortDescription),
        metaKeywords: getMetaTag(metaTagConstants.keywordsName, csApp.title + ', consulting service'),
        metaOGTitle: getOGtag(metaTagConstants.titleName, csApp.title),
        metaOGUrl: getOGtag(metaTagConstants.urlName, metaTagConstants.spzaUrl + url),
        metaOGImage: getOGtag(metaTagConstants.imageName, csApp.detailInformation.LargeIconUri),
        metaOGDescription: getOGtag(metaTagConstants.desName, csApp.extraData?.shortDescription),
        metaOGLocale: getOGtag(metaTagConstants.localeName, renderProps.params.locale ? renderProps.params.locale : 'en-us'),
        metaOGSiteName: getOGtag(metaTagConstants.sitenameName, csApp.title),
        metaOGVideo: getOGtag(metaTagConstants.videoName, videoUrl),
        metaTwitterCard: getMetaTag(metaTagConstants.twitterCard, metaTagConstants.twitterCardValue),
        metaTwitterSite: getMetaTag(metaTagConstants.twitterSite, metaTagConstants.twitterSiteValue),
        metaTwitterTitle: getMetaTag(metaTagConstants.twitterTitle, csApp.title),
        metaTwitterDescription: getMetaTag(metaTagConstants.twitterDes, csApp.extraData?.shortDescription),
        metaTwitterImage: getMetaTag(metaTagConstants.twitterImage, csApp.detailInformation.LargeIconUri),
      };
    }
  }
  return metaObj;
}
