import { IURLQuery } from '@shared/Models';
import { Constants } from '@shared/utils/constants';
import { ActionType, actionCreator } from './actions';

export const SetQueryAction: ActionType<{ query: IURLQuery }> = 'SetQueryAction';
export const createSetQueryAction = actionCreator(SetQueryAction);

export const SetRequestFilteredQueryAction: ActionType<{ requestFilteredQuery: IURLQuery }> = 'SetRequestFilteredQueryAction';
export const createSetRequestFilteredQueryAction = actionCreator(SetRequestFilteredQueryAction);

export const SetResponseFilteredQueryAction: ActionType<{ responseFilteredQuery: IURLQuery }> = 'SetResponseFilteredQueryAction';
export const createSetResponseFilteredQueryAction = actionCreator(SetResponseFilteredQueryAction);

export const SetRequestFilteredEntityTypeAction: ActionType<{
  requestFilteredEntityType: Constants.TileDataEntityType;
}> = 'SetRequestFilteredEntityTypeAction';
export const createSetRequestFilteredEntityTypeAction = actionCreator(SetRequestFilteredEntityTypeAction);

export const SetRequestFilteredLoadingAction: ActionType<void> = 'SetRequestFilteredLoadingAction';
export const createSetRequestFilteredLoadingAction = actionCreator(SetRequestFilteredLoadingAction);

export const SetRequestFilteredLoadedAction: ActionType<void> = 'SetRequestFilteredLoadedAction';
export const createSetRequestFilteredLoadedAction = actionCreator(SetRequestFilteredLoadedAction);
