import * as React from 'react';
import SpzaComponent from './spzaComponent';
import { InternalLink } from './internalLink';
import { ICommonContext } from '../interfaces/context';
import { Constants } from './../utils/constants';

export interface IButtonLink {
  label: string;
  value: string;
  accEnabled: boolean;
  className: string;
  href?: string;
  type: string;
}

export class ButtonLink extends SpzaComponent<IButtonLink, any> {
  context: ICommonContext;

  renderImpl() {
    let button = null;
    switch (this.props.type) {
      case Constants.ButtonType.Link:
        button = (
          <InternalLink accEnabled={this.props.accEnabled} href={this.props.href} role="link">
            <span className="ms-fontSize-14">{this.props.label + ' '}</span>
            <span className="ms-fontSize-16 ms-fontWeight-semibold">{this.props.value}</span>
          </InternalLink>
        );
        break;
      case Constants.ButtonType.Label:
        button = (
          <div className="buttonLinkContainer">
            <span className="ms-fontSize-14 label">{this.props.label + ' '}</span>
            <span className="ms-fontSize-16 ms-fontWeight-semibold">{this.props.value}</span>
          </div>
        );
        break;
      default:
        button = <div></div>;
    }
    return <div className={'button-link ' + this.props.className}>{button}</div>;
  }
}

(ButtonLink as any).contextTypes = {};
