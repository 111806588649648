import { getWindow } from '../services/window';

export const listOfUrlKeysToExcludeInAppsource = [
  'all',
  'admin-portal',
  'other-unsegmented',
  'all-office',
  'azure',
  'dynamics-365-for-operations',
];

export const listOfUrlKeysTopLevelInAmp = [
  'apps',
  'featured',
  'trending',
  'top-test-drives',
  'operating-systems',
  'devops',
  'onprem-to-cloud-migration',
  'consulting-services',
];

export const listOfUrlKeysToShowInHomePageAppsource = [
  'web-apps',
  'power-platform',
  'dynamics-365',
  'office',
  'analytics',
  'ai-machine-learning',
  'ai-for-business',
  'bot-apps',
  'collaboration',
  'project-management',
  'compliance-legals',
  'customer-service',
  'finance',
  'geolocation',
  'human-resources',
  'internet-of-things',
  'it-management-tools',
  'commerce',
  'marketing',
  'operations',
  'productivity',
  'sales',
  'automotive',
  'defense-and-intelligence',
  'distribution',
  'education',
  'financial-services',
  'government',
  'healthcare',
  'hospitality-and-travel',
  'energy',
  'manufacturing-resources',
  'retail-and-consumer-goods',
  'media-communications',
  'professional-services',
  'nonprofit',
  'sustainability',
];

export const oldToNewUrlKeys: Record<string, string> = {
  'dashboard-data-visualization': 'visualization-reporting',
  'ai-machine-learning': 'ai-plus-machine-learning',
  'contact-management': 'contact-and-people',
  'meeting-calendar-management': 'meeting-management',
  'site-design-management': 'site-design-and-management',
  'voice-video-conferencing': 'voice-and-video-conferencing',
  'project-management': 'task-and-project-management',
  'compliance-legals': 'compliance-and-legal',
  'tax-audit': 'tax-and-audit',
  'data-governance-privacy': 'data-governance-and-privacy',
  'health-safety': 'health-and-safety',
  'back-office-employee-service': 'back-office-and-employee-service',
  'knowledge-case-management': 'knowledge-and-case-management',
  'social-media-omnichannel-engagement': 'social-media-and-omnichannel-engagement',
  'analytics-consolidation-reporting': 'analytics-consolidation-and-reporting',
  'payments-credit-collections': 'credit-and-collections',
  'compliance-risk-management': 'compliance-and-risk-management',
  'geolocation-maps': 'maps',
  'people-analytics-and-Insights': 'workforce-planning-and-analytics',
  'asset-management-operations': 'asset-management-and-operations',
  'safety-security': 'safety-and-security',
  'smart-infrastructure-resources': 'smart-infrastructure-and-resources',
  'vehicles-mobility': 'vehicles-and-mobility',
  'it-management-tools': 'it-and-management-tools',
  commerce: 'Commerce',
  'e-commerce-social-commerce-and-marketplaces': 'e-commerce',
  'marketing-analytics': 'analytics-marketing',
  'campaign-management-automation': 'campaign-management-and-automation',
  'events-resource-management': 'events-and-resource-management',
  'research-analysist': 'research-and-analysis',
  operations: 'operations-and-supply-chain',
  'asset-production-management': 'asset-and-production-management',
  'information-management-connectivity': 'information-management-and-connectivity',
  'planning-purchasing-reporting': 'planning-purchasing-and-reporting',
  'quality-service-management': 'quality-and-service-management',
  'sales-order-management': 'sales-and-order-management',
  'transportation-warehouse-management': 'transportation-and-warehouse-management',
  'content-creation-management': 'content-creation-and-management',
  'language-translation': 'language-and-translation',
  'document-and-file-management': 'document-management',
  'search-reference': 'search-and-reference',
  sales: 'Sales',
  'business-data-management': 'business-data-enrichment',
  'primary-secondary-education': 'primary-and-secondary-education',
  banking: 'banking-and-capital-markets',
  'life-sciences': 'pharmaceuticals',
  'manufacturing-resources': 'manufacturing-and-resources',
  'process-manufacturing': 'chemical-and-agrochemical',
  retailL2: 'retailers',
  'media-communications': 'media-and-communications',
  'media-entertainment': 'media-and-entertainment',
  nonprofit: 'other-public-sector-industries',
  sustainability: 'SustainabilityIndustry',
};

export const filterHideList = [
  'automated-ml',
  'bot-services',
  'business-robotic-process-automation',
  'cognitive-services',
  'data-labelling',
  'data-preparation',
  'knowledge-mining',
  'ml-operations',
  'ml-service',
  'bigData',
  'data-analytics',
  'data-insights',
  'predictive-analytics',
  'real-time-streaming-analytics',
  'blockchain',
  'compute',
  'containers',
  'databases',
  'developer-tools',
  'devops',
  'identity',
  'integration',
  'data-analytics-and-visualization',
  'iot-connectivity',
  'iot-core-services',
  'iot-edge-modules',
  'iot-iiot-platforms',
  'iot-security',
  'iot-solutions',
  'media',
  'migration',
  'mixed-reality',
  'monitoring-and-diagnostics',
  'networking',
  'security',
  'storage',
  'web',
  'marketing-analytics',
  'agriculture',
  'architecture-and-construction',
  'real-estate',
];

// The key is the old, and the value is the oneTaxonomy
export const legacyKeyMapping: Record<string, string> = {
  Analytics: 'analytics',
  ArtificialIntelligence: 'ai-plus-machine-learning',
  Collaboration: 'collaboration',
  Commerce_AS_L1: 'Commerce',
  ComplianceLegal_AS: 'compliance-and-legal',
  CustomerService: 'customer-service',
  Finance: 'finance',
  Geolocation_AS: 'geolocation',
  HumanResources: 'human-resources',
  IoT: 'internet-of-things',
  ITandAdministration: 'it-and-management-tools',
  Marketing: 'marketing',
  OperationsSupplyChain: 'operations-and-supply-chain',
  Productivity: 'productivity',
  ProjectManagement: 'task-and-project-management',
  Automotive_AS: 'automotive',
  DefenseIntelligence_AS_L1: 'defense-and-intelligence',
  Distribution: 'distribution',
  Education: 'education',
  FinancialServices: 'financial-services',
  Government: 'government',
  HealthCareandLifeSciences: 'healthcare',
  HospitalityTravel_AS: 'hospitality-and-travel',
  Energy_AS_L1: 'energy',
  'manufacturing-resources': 'manufacturing-and-resources',
  RetailandConsumerGoods: 'retail-and-consumer-goods',
  MediaCommunications_AS: 'media-and-communications',
  ProfessionalServices: 'professional-services',
  NonprofitIGO_AS_L1: 'other-public-sector-industries',
  Sustainability_AS: 'SustainabilityIndustry',
};

export const legacySubKeyMapping = {
  AdvancedAnalytics: 'advanced-analytics',
  DashboardDataVisualization_AS: 'visualization-reporting',
  AIBusiness_AS: 'ai-for-business',
  BotApps_AS: 'bot-apps',
  ContactManagement_AS: 'contact-and-people',
  Chat_AS: 'chat',
  MeetingCalendarManagement_AS: 'meeting-management',
  SiteManagement_AS: 'site-design-and-management',
  VoiceVidedoConferencing_AS: 'voice-and-video-conferencing',
  ProjectSalesProposalsBids_AS_L2: 'project-sales-proposals-bids',
  ProjectPlanningTracking_AS_L2: 'project-planning-tracking',
  ProjectResourcePlanningUtilizationMetrics_AS_L2: 'project-resource-planning-utilization-metrics',
  ProjectTimeExpenseReporting_AS_L2: 'project-time-expense-reporting',
  ProjectInvoicing_AS_L2: 'project-invoicing',
  ProjectAccountingRevenueRecognition_AS_L2: 'project-accounting-revenue-recognition',
  TaxAudit_AS: 'tax-and-audit',
  Legal_AS: 'legal',
  DataGovernancePrivacy_AS: 'data-governance-and-privacy',
  HealthSafety_AS: 'health-and-safety',
  ContactCenter_AS: 'contact-center',
  FaceToFaceService_AS: 'face-to-face-service',
  BackOfficeEmployeeService_AS: 'back-office-and-employee-service',
  KnowledgeManagement_AS: 'knowledge-and-case-management',
  SocialOmnichannelEngagement_AS: 'social-media-and-omnichannel-engagement',
  Accounting_AS: 'accounting',
  AssetManagement_AS: 'asset-management',
  AnalyticsReporting_AS: 'analytics-consolidation-and-reporting',
  PaymentsCreditCollections_AS: 'credit-and-collections',
  ComplianceRiskManagement_AS: 'compliance-and-risk-management',
  Maps_AS: 'maps',
  NewsWeather_AS: 'news-and-weather',
  AddressValidation_AS: 'address-validation',
  TalentAcquisition_AS: 'talent-acquisition',
  TalentManagement_AS: 'talent-management',
  HROperations_AS: 'hr-operations',
  PeopleAnalyticsInsights_AS: 'workforce-planning-and-analytics',
  AssetManagementOperations_AS: 'asset-management-and-operations',
  ConnectedProducts_AS: 'connected-products',
  IntelligentSupplyChain_AS: 'intelligent-supply-chain',
  PredictiveMaintenance_AS: 'predictive-maintenance',
  RemoteMonitoring_AS: 'remote-monitoring',
  SafetySecurity_AS: 'safety-and-security',
  SmartInfraResources_AS: 'smart-infrastructure-and-resources',
  VehiclesMobility_AS: 'vehicles-and-mobility',
  ManagementSolutions_AS: 'management-solutions',
  BusinessApplications_AS: 'business-applications',
  StoreManagement_AS_L2: 'store-management',
  LoyaltyGifting_AS_L2: 'loyalty-gifting',
  ProductInformationContentManagement_AS_L2: 'product-information-content-management',
  PersonalizationRatingsReviews_AS_L2: 'personalization-ratings-reviews',
  SubscriptionPostPurchaseServices_AS_L2: 'subscription-post-purchase-services',
  Advertisement_AS: 'advertisement',
  Analytics_AS: 'analytics-marketing',
  CampaignMgMAutomation_AS: 'campaign-management-and-automation',
  EmailMarketing_AS: 'email-marketing',
  EventsResourceManagement_AS: 'events-and-resource-management',
  ResearchAnalysis_AS: 'research-and-analysis',
  SocialMedia_AS: 'social-media',
  PricingOptimization_AS: 'pricing-optimization',
  AssetProdManagement_AS: 'asset-and-production-management',
  DemandForecasting_AS: 'demand-forecasting',
  InfoMgMConnectivity_AS: 'information-management-and-connectivity',
  PlanPurchasingReporting_AS: 'planning-purchasing-and-reporting',
  QualityServiceManagement_AS: 'quality-and-service-management',
  SalesOrderManagement_AS: 'sales-and-order-management',
  TransportManagement_AS: 'transportation-and-warehouse-management',
  ContentCreationMgmt_AS: 'content-creation-and-management',
  LanguageTranslation_AS: 'language-and-translation',
  DocumentFileManagement_AS: 'document-management',
  WorkflowAutomation_AS: 'workflow-automation',
  Blogs_AS: 'blogs',
  EmailManagement_AS: 'email-management',
  SearchReference_AS: 'search-and-reference',
  Gamification_AS: 'gamification',
  Telesales_AS: 'telesales',
  ConfigurePriceQuote_AS: 'configure-price-quote',
  ContractManagement_AS: 'contract-management',
  CRM_AS: 'crm',
  BusinessDataManagement_AS: 'business-data-enrichment',
  SalesEnablement_AS: 'sales-enablement',
  ParcelPackageShipping_AS: 'parcel-and-package-shipping',
  Wholesale_AS: 'wholesale',
  HigherEducation_AS: 'higher-education',
  PrimarySecondaryEducation_AS: 'primary-and-secondary-education',
  LibrariesMuseums_AS: 'libraries-and-museums',
  Banking_AS: 'banking-and-capital-markets',
  Insurance_AS: 'insurance',
  CapitalMarkets_AS: 'capital-markets',
  PublicFinance_AS: 'public-finance',
  CriticalInfrastructure_AS: 'critical-infrastructure',
  CivilianGovernment_AS: 'civilian-government',
  PublicSafetyJustice_AS: 'public-safety-and-justice',
  HealthPayor_AS: 'health-payor',
  HealthProvider_AS: 'health-provider',
  Life_Sciences_AS: 'pharmaceuticals',
  HotelsLeisure_AS: 'hotels-and-leisure',
  TravelTransportation_AS: 'travel-and-transportation',
  RestaurantsFoodServices_AS: 'restaurants-and-food-services',
  ProcessManufacturing_AS_L2: 'chemical-and-agrochemical',
  DiscreteManufacturing_AS_L2: 'discrete-manufacturing',
  ConsumerGoods_AS_L2: 'consumer-goods',
  Retail_AS_L2: 'retailers',
  MediaEntertainment_AS: 'media-and-entertainment',
  Telecommunications_AS: 'telecommunications',
  PartnerProfessionalServices_AS: 'partner-professional-services',
  ArchitectureConstruction_AS_L2: 'architecture-and-construction',
  RealEstate_AS_L2: 'real-estate',
};

export const urlKeysMapping = {
  'dashboard-data-visualization': 'visualization-reporting',
  'ai-machine-learning': 'ai-plus-machine-learning',
  'contact-management': 'contact-and-people',
  'meeting-calendar-management': 'meeting-management',
  'site-design-management': 'site-design-and-management',
  'voice-video-conferencing': 'voice-and-video-conferencing',
  'project-management': 'task-and-project-management',
  'compliance-legals': 'compliance-and-legal',
  'tax-audit': 'tax-and-audit',
  'data-governance-privacy': 'data-governance-and-privacy',
  'health-safety': 'health-and-safety',
  'back-office-employee-service': 'back-office-and-employee-service',
  'knowledge-case-management': 'knowledge-and-case-management',
  'social-media-omnichannel-engagement': 'social-media-and-omnichannel-engagement',
  'analytics-consolidation-reporting': 'analytics-consolidation-and-reporting',
  'payments-credit-collections': 'credit-and-collections',
  'compliance-risk-management': 'compliance-and-risk-management',
  'geolocation-maps': 'maps',
  'people-analytics-and-Insights': 'workforce-planning-and-analytics',
  'asset-management-operations': 'asset-management-and-operations',
  'safety-security': 'safety-and-security',
  'smart-infrastructure-resources': 'smart-infrastructure-and-resources',
  'vehicles-mobility': 'vehicles-and-mobility',
  'it-management-tools': 'it-and-management-tools',
  commerce: 'Commerce',
  'e-commerce-social-commerce-and-marketplaces': 'undefined',
  'marketing-analytics': 'analytics-marketing',
  'campaign-management-automation': 'campaign-management-and-automation',
  'events-resource-management': 'events-and-resource-management',
  'research-analysist': 'research-and-analysis',
  operations: 'operations-and-supply-chain',
  'asset-production-management': 'asset-and-production-management',
  'information-management-connectivity': 'information-management-and-connectivity',
  'planning-purchasing-reporting': 'planning-purchasing-and-reporting',
  'quality-service-management': 'quality-and-service-management',
  'sales-order-management': 'sales-and-order-management',
  'transportation-warehouse-management': 'transportation-and-warehouse-management',
  'content-creation-management': 'content-creation-and-management',
  'language-translation': 'language-and-translation',
  'document-and-file-management': 'document-management',
  'search-reference': 'search-and-reference',
  sales: 'Sales',
  'business-data-management': 'business-data-enrichment',
  'primary-secondary-education': 'primary-and-secondary-education',
  banking: 'banking-and-capital-markets',
  'life-sciences': 'pharmaceuticals',
  'manufacturing-resources': 'manufacturing-and-resources',
  'process-manufacturing': 'chemical-and-agrochemical',
  retailL2: 'retailers',
  'media-communications': 'media-and-communications',
  'media-entertainment': 'media-and-entertainment',
  nonprofit: 'other-public-sector-industries',
  sustainability: 'SustainabilityIndustry',
};

export const isOneTaxonomyEnabled = () => {
  return process?.env.enableOneTaxonomy === 'true' || getWindow()?.__INITIAL_STATE__?.config?.enableOneTaxonomy;
};

export const newToOldUrlKeys = Object.fromEntries(Object.entries(oldToNewUrlKeys).map(([newKey, oldKey]) => [oldKey, newKey]));
