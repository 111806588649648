import type { IExtendedConfiguration, IWebAnalyticsConfiguration } from '@microsoft/1ds-analytics-web-js';
import { AuthPlugin, AuthType } from '@microsoft/1ds-auth-js';
import type { IAuthenticationConfiguration } from '@microsoft/1ds-auth-js';
import { PropertiesPlugin } from '@microsoft/1ds-properties-js';
import type { IPropertyConfiguration } from '@microsoft/1ds-properties-js';
import { CorrelationVectorPlugin } from '@microsoft/1ds-cv-js';
import { QosPlugin } from '@microsoft/1ds-qos-js';
import type { IQosConfiguration } from '@microsoft/1ds-qos-js';
import { getWindow } from '@shared/services/window';
import { Constants, Environment, OneDSEnvs } from '@shared/utils/constants';
import * as appConfig from '@shared/services/init/appConfig';
import { getGPCValue } from '@shared/utils/privacyUtils';

type IOneDsEnvMapping = {
  [key in Environment]: OneDSEnvs;
};

const envsMap: IOneDsEnvMapping = {
  [Environment.Production]: OneDSEnvs.Production,
  [Environment.Canary]: OneDSEnvs.Canary,
  [Environment.Development]: OneDSEnvs.Internal,
  [Environment.Dogfood]: OneDSEnvs.Dogfood,
  [Environment.Test]: OneDSEnvs.Test,
};

const getEnvName = () => {
  const siteEnvironment = getWindow()?.__INITIAL_STATE__?.config?.siteEnvironment?.toLowerCase() ?? process?.env?.siteEnvironment;
  const env = envsMap[`${siteEnvironment}`];
  if (!env) {
    throw new Error('failed to load env name for telemetry');
  }
  return env;
};

const authPlugin: AuthPlugin = new AuthPlugin();
const propertiesPlugin: PropertiesPlugin = new PropertiesPlugin();
const cvPlugin: CorrelationVectorPlugin = new CorrelationVectorPlugin();
const qosPlugin: QosPlugin = new QosPlugin();

const webAnalyticsConfig: IWebAnalyticsConfiguration = {
  urlCollectQuery: true,
  urlCollectHash: true,
  autoCapture: {
    scroll: true,
    pageView: true,
    onLoad: true,
    onUnload: true,
    click: true,
    resize: true,
    lineage: true,
    jsError: true,
  },
  shareAuthStatus: true,
  callback: {
    signedinStatus: () => getWindow()?.__INITIAL_STATE__?.users?.signedIn ?? false,
  },
};

const authConfig: IAuthenticationConfiguration = {
  authType: AuthType.AAD,
  loggedInStatusCallback: () => {
    return getWindow()?.__INITIAL_STATE__?.users?.signedIn ?? false;
  },
};

const propertiesPluginConfig = (): IPropertyConfiguration => {
  return {
    populateBrowserInfo: true,
    populateOperatingSystemInfo: true,
    enableApplicationInsightsTrace: true,
    enableApplicationInsightsUser: true,
    userConsentCookieName: Constants.Cookies.privacyConsentAcceptedDate,
    env: getEnvName(),
    // eslint-disable-next-line camelcase
    gpcDataSharingOptIn: getGPCValue() ?? true,
    callback: {
      userConsentDetails: () => appConfig.get('siteConsent'),
    },
  };
};

const qosConfig = (): IQosConfiguration => {
  return {
    disableAjaxTracking: false,
    disableFetchTracking: false,
    maxAjaxCallsPerView: -1,
    excludeRequestFromAutoTrackingPatterns: [getWindow()?.__INITIAL_STATE__.config.telemetryEndpoint.toLowerCase()],
  };
};

export const config = (): IExtendedConfiguration => {
  return {
    propertyConfiguration: propertiesPluginConfig(),
    instrumentationKey: getWindow()?.__INITIAL_STATE__.config.loggingInstrumentationKey,
    endpointUrl: getWindow()?.__INITIAL_STATE__.config.telemetryEndpoint,
    webAnalyticsConfiguration: webAnalyticsConfig,
    extensions: [authPlugin, propertiesPlugin, cvPlugin, qosPlugin],
    extensionConfig: {
      [authPlugin.identifier]: authConfig,
      [propertiesPlugin.identifier]: propertiesPluginConfig(),
      [qosPlugin.identifier]: qosConfig(),
    },
    perfEvtsSendAll: true,
  };
};
