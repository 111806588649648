import * as React from 'react';
import * as PropTypes from 'prop-types';
import SpzaComponent from '@shared/components/spzaComponent';
import { Icon, ITooltipProps, TooltipHost } from '@fluentui/react';
import { ICommonContext, ILocContext, ILocDateStringContext, ILocParamsContext } from '@shared/interfaces/context';
import { getUTCDateString } from '@shared/utils/futurePricesUtils';
import { uniqueId } from 'lodash-es';
import classNames from 'classnames';
import { Text } from '@fluentui/react/lib/Text';

export interface IFuturePriceTooltipProps {
  price?: string;
  priceComponent?: React.ReactNode;
  startDate: number;
  className?: string;
}

export default class FuturePriceTooltip extends SpzaComponent<{ futurePrice: IFuturePriceTooltipProps }, any> {
  context: ILocParamsContext & ICommonContext & ILocDateStringContext & ILocContext;

  tooltipContent = () => {
    const { startDate, price, priceComponent, className = '' } = this.props.futurePrice;
    const startDateText: string = getUTCDateString(startDate, this.context);
    const futurePriceTooltipClass = classNames('futurePriceTooltipContent', className);
    return (
      <div className={futurePriceTooltipClass}>
        <span>{this.context.locParams('FuturePrices_message', [startDateText])}</span>
        <span
          className="price"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: price,
          }}
        />
        {priceComponent}
      </div>
    );
  };

  tooltipProps: ITooltipProps = {
    onRenderContent: this.tooltipContent,
  };

  renderImpl() {
    const iconId = 'futurePriceTooltipIcon' + uniqueId();
    const { className = '' } = this.props.futurePrice;
    const futurePriceTooltipClass = classNames('futurePrice', className);
    const textClass = classNames('futurePriceLabel', className);

    return (
      <div className={futurePriceTooltipClass}>
        <TooltipHost
          tooltipProps={this.tooltipProps}
          id={'future-price-tooltip'}
          calloutProps={{ target: `#${iconId}`, gapSpace: 5 }}
        >
          <div className={'futurePriceTooltip'}>
            <Icon aria-describedby={'future-price-tooltip'} iconName="Info" className="futurePriceIcon" id={iconId} />
            <Text className={textClass}>{this.context.loc('FuturePrices_upcomingPriceChange', 'Upcoming price change')}</Text>
          </div>
        </TooltipHost>
      </div>
    );
  }
}

(FuturePriceTooltip as any).contextTypes = {
  renderErrorModal: PropTypes.func,
  locParams: PropTypes.func,
  loc: PropTypes.func,
  locDateString: PropTypes.func,
};
