import AuthClient from './client';
import { getRedirectLoginUrl } from './windowUtils';
import { routes } from '@shared/routerHistory';
import { logger } from '@src/logger';
import { stringifyError } from '@shared/utils/errorUtils';
import { Constants } from '@shared/utils/constants';
import { saveLocalStorageItem } from '@shared/utils/browserStorageUtils';

export const logoutUser = async () => {
  try {
    const { idTokenClaims } = AuthClient.getInstance().getActiveAccount();
    saveLocalStorageItem(Constants.LocalStorage.LoginHint, '');
    return await AuthClient.getInstance().logoutRedirect({
      postLogoutRedirectUri: `${getRedirectLoginUrl()}${routes.home.getPath()}`,
      logoutHint: idTokenClaims?.login_hint,
    });
  } catch (error) {
    logger.error(stringifyError(error), {
      action: Constants.Telemetry.Action.Logout,
      actionModifier: Constants.Telemetry.ActionModifier.LogoutFailed,
      error,
    });
  }
};
