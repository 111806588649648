import * as React from 'react';
import {
  AppReviewCollection as AppReviewCollectionComponent,
  IAppReviewCollectionProps,
} from '@appsource/components/appReviewCollection';
import {
  ensureAppReviewsData,
  loadUserMarkedAsHelpfulReviews,
  setReviewMarkedAsHelpful,
  loadUserProductReviewMetadata,
} from '@shared/actions/thunkActions';
import { createModalAction, createRatingAction } from '@shared/actions/actions';
import { IState } from '../../State';
import { Constants } from '@shared/utils/constants';
import { IAppDataItem } from '@shared/Models';
import {
  IAppEntityMarkAsHelpfulBaseThunkActionParams,
  IAppEntitySetReviewMarkAsHelpfulParams,
} from '@shared/interfaces/reviews/markAsHelpful';
import { connect } from 'react-redux';
import { withRouter } from '@shared/routerHistory';

export const mapStateToProps = (state: IState, ownProps: IAppReviewCollectionProps) => {
  return {
    reviewsData: state.apps.appReviewsData,
    appId: ownProps.appId,
    locale: state.config.locale,
    appData: ownProps.appData,
    location: ownProps.location,
    reviewId: state.config.query?.reviewId,
    userInfo: state.users,
    markedAsHelpful: state.apps.appReviewsData.markedAsHelpful,
    userCommentedReviewIds: state.apps.appReviewsData.userCommentedReviewIds,
    billingCountryCode: state.config.billingCountryCode,
    showMyCommentsFilter: state.config.featureFlags.ReviewsMyCommentsFilter === 'true',
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    ensureAppReviewsData: (entityId: string, forceUpdate = false) => dispatch(ensureAppReviewsData(entityId, forceUpdate)),
    openRatingModal: (app: IAppDataItem, accessKey: string, ctaType: string, callback: any) =>
      dispatch(
        createRatingAction({
          showModal: true,
          app: app,
          accessKey: accessKey,
          ctaType: ctaType,
          callback: callback,
        })
      ),
    openSignInModal: () =>
      dispatch(
        createModalAction({
          showModal: true,
          modalId: Constants.ModalType.SignIn,
        })
      ),
    toggleMarkAsHelpful: (args: IAppEntitySetReviewMarkAsHelpfulParams) => dispatch(setReviewMarkedAsHelpful(args)),
    loadReviewIdsMarkedAsHelpful: (args: IAppEntityMarkAsHelpfulBaseThunkActionParams) =>
      dispatch(loadUserMarkedAsHelpfulReviews(args)),
    loadUserProductReviewMetadata: (args: { legacyId: string }) => dispatch(loadUserProductReviewMetadata(args)),
  };
};

export const AppReviewCollection = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppReviewCollectionComponent)
) as React.StatelessComponent<any>;
