import React from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

import { IAppDataItem, IStartingPrice } from '@shared/Models';
import { ICommonContext, ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { getWindow } from '@shared/services/window';
import { Constants } from '@shared/utils/constants';
import {
  getActionStringForCTAType,
  isAvailableInThisRegion,
  isOpenInNewWindowButton,
  processHandoffURLAndTitle,
} from '@shared/utils/appUtils';
import { getTooltipSilentLogIn } from '@shared/utils/silentLogInUtils';
import SpzaComponent from '@shared/components/spzaComponent';
import { BaseCtaButton } from '@shared/components/baseCtaButton';
import { PrimaryButton, mergeStyleSets, DefaultButton } from '@fluentui/react';
import { openInNewWindowButtonStyles } from '@shared/components/contentStyles';
import { hidePowerBiVisualPricing } from '@shared/utils/pricing';

export interface ICtaButtonsProps {
  app: IAppDataItem;
  startingPrice: IStartingPrice;
  isLoadingUserData: boolean;
  billingCountryCode: string;
  onCTAClick: (ctaType: Constants.CTAType) => void;
  isEmbedded: boolean;
  openInNewWindowButtonFlag: boolean;
}

const ctaButtonsClassNames = mergeStyleSets({
  ctaButton: {
    padding: '5px 25px',
    marginTop: 4,
    marginRight: 8,
  },
});

// eslint-disable-next-line @typescript-eslint/ban-types
export class CtaButtons extends SpzaComponent<ICtaButtonsProps, {}> {
  context: ICommonContext & ILocContext & ILocParamsContext;

  componentDidMount(): void {
    const window = getWindow();
    const { navigationStart = 0, requestStart = 0 } = getWindow()?.performance?.timing || {};

    if (window) {
      window.loadTimePDPCTA = performance.now() - (requestStart - navigationStart);
    }
  }

  ctaDefaultButton(ctaType: Constants.CTAType, isOpenInNewWindow: boolean) {
    const { startingPrice, isLoadingUserData, onCTAClick } = this.props;
    const { loc } = this.context;

    return (
      <DefaultButton
        text={loc(getActionStringForCTAType(ctaType))}
        className={classNames([
          ctaButtonsClassNames.ctaButton,
          'pdpButton',
          { [openInNewWindowButtonStyles.openInNewWindowButton]: isOpenInNewWindow },
        ])}
        disabled={!isAvailableInThisRegion({ startingPrice }) || isLoadingUserData}
        key={ctaType}
        onClick={() => onCTAClick(ctaType)}
        iconProps={isOpenInNewWindow ? { iconName: 'OpenInNewWindow' } : {}}
      />
    );
  }

  ctaPrimaryButton(ctaType: Constants.CTAType, isOpenInNewWindow: boolean) {
    const { startingPrice, isLoadingUserData, onCTAClick, isEmbedded, app } = this.props;
    const { loc } = this.context;
    const isPBIVisualPriceHidden = hidePowerBiVisualPricing({ app, isEmbedded });

    return (
      <PrimaryButton
        text={
          isPBIVisualPriceHidden && ctaType === Constants.CTAType.Install
            ? loc('CTA_Install_free', 'Install free')
            : loc(getActionStringForCTAType(ctaType))
        }
        className={classNames([
          ctaButtonsClassNames.ctaButton,
          'requestButton',
          { [openInNewWindowButtonStyles.openInNewWindowButton]: isOpenInNewWindow },
        ])}
        disabled={!isAvailableInThisRegion({ startingPrice }) || isLoadingUserData}
        key={ctaType}
        onClick={() => onCTAClick(ctaType)}
        iconProps={isOpenInNewWindow ? { iconName: 'OpenInNewWindow' } : {}}
      />
    );
  }

  renderImpl() {
    const { app, startingPrice, isLoadingUserData, billingCountryCode, isEmbedded, openInNewWindowButtonFlag } = this.props;
    const { url } = processHandoffURLAndTitle(app);

    const buttons: JSX.Element[] = app.ctaTypes.map<JSX.Element>((ctaType: Constants.CTAType, index) => {
      const isOpenInNewWindow =
        openInNewWindowButtonFlag &&
        isOpenInNewWindowButton({
          isEmbedded,
          ctaType,
          appData: app,
          billingRegion: billingCountryCode,
          url,
        });

      const button = index
        ? this.ctaDefaultButton(ctaType, isOpenInNewWindow)
        : this.ctaPrimaryButton(ctaType, isOpenInNewWindow);
      const baseCtaCompoent = (
        <BaseCtaButton
          app={app}
          billingCountryCode={billingCountryCode}
          startingPrice={startingPrice}
          isLoadingUserData={isLoadingUserData}
          CtaComponent={button}
          ctaType={ctaType}
        />
      );
      return getTooltipSilentLogIn(baseCtaCompoent, isLoadingUserData);
    });

    return <>{buttons}</>;
  }
}

(CtaButtons as any).contextTypes = {
  locParams: PropTypes.func,
  loc: PropTypes.func,
};
