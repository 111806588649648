import React from 'react';
import { TooltipDelay, TooltipHost } from '@fluentui/react';
import type { ITooltipProps, ITooltipStyles, ICalloutProps, ITooltipHostProps } from '@fluentui/react';

import { useId } from '@fluentui/react-hooks';
import { noop } from 'lodash-es';

interface ITooltipComponentProps {
  children: React.ReactNode;
  tooltipHostProps?: ITooltipHostProps;
  onTooltipToggle?: (isTooltipVisible: boolean) => void;
}

const Tooltip: React.FunctionComponent<ITooltipComponentProps> = ({
  children,
  tooltipHostProps = {},
  onTooltipToggle = noop,
}: ITooltipComponentProps) => {
  const tooltipId = useId('tooltip');
  const tooltipStyles: Partial<ITooltipStyles> = { content: { cursor: 'default' } };
  const tooltipProps: ITooltipProps = { styles: tooltipStyles };
  const calloutProps: ICalloutProps = {
    gapSpace: 5,
    isBeakVisible: false,
    setInitialFocus: false,
    dismissOnTargetClick: true,
  };

  const onTooltipToggleHandle = (isTooltipVisible: boolean): void => {
    onTooltipToggle?.(isTooltipVisible);
  };

  return (
    <TooltipHost
      id={tooltipId}
      onTooltipToggle={onTooltipToggleHandle}
      delay={TooltipDelay.long}
      closeDelay={0}
      tooltipProps={tooltipProps}
      calloutProps={calloutProps}
      {...tooltipHostProps}
    >
      {children}
    </TooltipHost>
  );
};

export default Tooltip;
