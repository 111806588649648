import * as React from 'react';
import { IState } from '../../../State';
import { createEntityFullDataReceivedAction, createModalAction, createUserReviewUpdatedAction } from '../../actions/actions';
import { Modal as ModalComponent } from './../../components/modals/modal';
import { getWindow } from '../../services/window';
import { isOnMicrosoftEmail } from '../../utils/appUtils';
import { SpzaInstrumentService } from '../../services/telemetry/spza/spzaInstrument';
import { Constants } from './../../utils/constants';
import { ITelemetryData, IAppDataItem } from './../../Models';
import { getCookieItem } from './../../utils/browserStorageUtils';
import { logger } from '@src/logger';
import { connect } from 'react-redux';
import { getAppDetail } from '@shared/actions/thunkActions';

export const mapStateToProps = (state: IState, ownProps: any) => {
  const userInfo = state.users;
  if (state.config.isEmbedded) {
    const cookie = getCookieItem('appsourcelead');
    let isAlternateEmailFromCookie = false;

    if (cookie && cookie.alternateEmail && cookie.email) {
      const userEmail = userInfo && userInfo.email ? userInfo.email.toLowerCase() : ' ';

      // If userInfo already has alternateEmail (from graph call on server), do NOT overwrite it here
      // also ensure that cookie's email is matching useremail to avoid scenario where user signs in into other sites that updated the cookie to some MSA
      if (!userInfo.alternateEmail && userEmail === cookie.email.toLowerCase()) {
        userInfo.alternateEmail = cookie.alternateEmail;
        isAlternateEmailFromCookie = true;
      }
    }

    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.PageLoad,
      actionModifier: Constants.Telemetry.ActionModifier.CookieData,
      details: JSON.stringify({
        alternateEmailExists: !!(cookie && cookie.alternateEmail),
        emailHasOnMicrosoft: userInfo && userInfo.email ? isOnMicrosoftEmail(userInfo.email) : false,
        alternateEmailFromCookie: isAlternateEmailFromCookie,
      }),
    };
    SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });
  }
  const recommendedServices = state.services.recommendedServices;

  return {
    entityId: state.modal.entityId,
    modalId: state.modal.modalId,
    onModalDismiss: state.modal.onModalDismiss,
    disableDismissModal: state.modal.disableDismissModal,
    userInfo,
    isSignedIn: state.users.signedIn,
    allApps: state.apps.dataList,
    allCloudsIndustry: state.cloudsIndustry.dataList,
    allServices: state.services.dataList.concat(recommendedServices.whatsNewService).concat(recommendedServices.trendingService),
    allPrivateOffers: state.users.privateOffers?.dataList || [],
    allFavouriteApps: state.userFavourite.apps,
    isEmbedded: state.config.isEmbedded,
    featureFlags: state.config.featureFlags,
    entityType: state.modal.entityType,
    payload: state.modal.payload,
    embedHost: state.config.embedHost,
    options: state.modal.options,
    query: ownProps && ownProps.query,
    billingCountryCode: state.config.billingCountryCode,
  };
};

export const mapDispatchToProps = (dispatch: any): any => {
  return {
    fetchAppDetails: (entityId: string) => dispatch(getAppDetail(entityId, false)),
    updateAppEntity: (entityDetails: IAppDataItem) =>
      dispatch(
        createEntityFullDataReceivedAction({
          entityType: Constants.EntityType.App,
          entityDetails,
        })
      ),
    dismissModal: () => {
      return dispatch(
        createModalAction({
          showModal: false,
        })
      );
    },
    setUserReviewStatus: (hasReview: boolean) => {
      return dispatch(createUserReviewUpdatedAction(hasReview));
    },
    dismissModalWithRedirect: (href: string) => {
      getWindow().location.assign(href);
    },
  };
};

const Modal = connect(mapStateToProps, mapDispatchToProps)(ModalComponent);

export default Modal as React.StatelessComponent<any>;
