import React, { useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import type {
  ICellStyleProps,
  IDetailsHeaderProps,
  IDetailsHeaderStyles,
  IDetailsListProps,
  IDetailsRowProps,
} from '@fluentui/react';
import { CheckboxVisibility, ConstrainMode, DetailsHeader, DetailsList, DetailsListLayoutMode } from '@fluentui/react';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { IAppDataItem, IBillingCountry, ISimpleSKU, ITelemetryData } from '@shared/Models';
import { Constants } from '@shared/utils/constants';
import { renderNotAvailablePriceUI } from '@shared/utils/pricing';
import { getColumns } from '@shared/components/saasPricingTableBase/saasPricingTableColumns';
import { SaasPricingRow } from '@shared/components/saasPricingTableBase/saasPricingRow';
import { logger } from '@src/logger';
import { getWindow } from '@shared/services/window';
import { useSpzaInstrumentService } from '@shared/hooks';
import { sortingSKUs } from '@shared/utils/appUtils';
import { isOneTimePaymentOffer } from '@shared/utils/onetimepaymentoffers';

export interface SaasRowData {
  sku: ISimpleSKU;
  futurePriceSku?: ISimpleSKU;
}

export interface SaasPricingTableBaseProps {
  app: IAppDataItem;
  billingCountry: IBillingCountry;
  locale: string;
  detailsListPropsOverride?: Partial<IDetailsListProps>;
  onGetInNowClicked?(planId?: string): void;
  shouldBlockPrices?: boolean;
}

const renderRow = (rowProps: IDetailsRowProps) => <SaasPricingRow {...rowProps} />;

const headerStyles: Partial<IDetailsHeaderStyles> = {
  root: {
    height: 20,
    lineHeight: 20,
    paddingBottom: 8,
  },
};

const cellStyles: ICellStyleProps = {
  cellExtraRightPadding: 0,
  cellLeftPadding: 0,
  cellRightPadding: 0,
};

const sortRows = (rows: SaasRowData[]): SaasRowData[] => rows.sort(({ sku: a }, { sku: b }) => sortingSKUs({ a, b }));

export const defaultRenderHeader = (headerProps: IDetailsHeaderProps) => {
  return <DetailsHeader {...headerProps} styles={headerStyles} />;
};

export const SaasPricingTableBase = (
  { app, billingCountry, locale, shouldBlockPrices, onGetInNowClicked, detailsListPropsOverride }: SaasPricingTableBaseProps,
  context: ILocContext & ILocParamsContext
) => {
  const { pricingInformation: pricing, futurePricesInformation: futurePrices, entityId } = app;
  const isOneTimePayment = isOneTimePaymentOffer(entityId);
  const skus = (pricing?.skus || []) as ISimpleSKU[];
  const futurePricesSkus = (futurePrices?.skus || []) as ISimpleSKU[];
  const [instrument] = useSpzaInstrumentService();
  const columns = useMemo(
    () => getColumns({ context, skus, billingCountry, onGetInNowClicked, shouldBlockPrices, locale, isOneTimePayment }),
    [context, skus, billingCountry, onGetInNowClicked, shouldBlockPrices, locale]
  );
  const items: SaasRowData[] = useMemo(
    () =>
      sortRows(
        skus.map((sku) => {
          const futurePriceSku = futurePricesSkus.find((futureSku) => futureSku.id === sku.id);
          return { sku, futurePriceSku };
        })
      ),
    [skus, futurePricesSkus]
  );

  useEffect(() => {
    const { bigId, entityId } = app;
    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.LongTermSaaS,
      actionModifier: Constants.Telemetry.ActionModifier.LongTermSaasTablePresented,
      details: JSON.stringify({ bigId, entityId }),
    };
    logger.info(`LongTermSaas PDP table was loaded. bigId: ${bigId}, entityId: ${entityId}`, payload);
    // Using old logger for the transition period
    instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', payload);
  }, []);

  if (!pricing) {
    return <div>{context.loc('Loading')}</div>;
  }

  if (!skus?.length) {
    return renderNotAvailablePriceUI(context);
  }

  return (
    <DetailsList
      items={items}
      columns={columns}
      onRenderRow={renderRow}
      onRenderDetailsHeader={defaultRenderHeader}
      layoutMode={DetailsListLayoutMode.justified}
      checkboxVisibility={CheckboxVisibility.hidden}
      disableSelectionZone
      cellStyleProps={cellStyles}
      constrainMode={ConstrainMode.unconstrained}
      {...detailsListPropsOverride}
    />
  );
};

(SaasPricingTableBase as any).contextTypes = {
  locParams: PropTypes.func,
  loc: PropTypes.func,
};
