/* eslint-disable react/prop-types */
import * as React from 'react';
import classNames from 'classnames';
import { InternalLink } from './internalLink';

interface IconLinkProps {
  accEnabled: boolean;
  className: string;
  iconClassName?: string;
  href: string;
  onClick: (event: Event) => void;
  title: string;
}

export const IconLink: React.FC<React.PropsWithChildren<IconLinkProps>> = ({
  iconClassName,
  accEnabled,
  href,
  className,
  onClick,
  title,
  children,
}) => {
  return (
    <div className="icon-link">
      <div className={classNames(iconClassName, 'icon')}></div>
      <InternalLink accEnabled={accEnabled} href={href} role="link" className={className} onClick={onClick} title={title}>
        {children}
      </InternalLink>
    </div>
  );
};
