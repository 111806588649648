import React from 'react';
import { connect } from 'react-redux';
import type { AnyAction, Dispatch } from 'redux';
import { withRouter } from '@shared/routerHistory';
import { IState } from '@src/State';
import {
  AppDetails as AppDetailsComponent,
  IAppDetailsDispatchProps,
  IAppDetailsOwnProps,
  IAppDetailsRouterProps,
} from '@appsource/components/appDetails';
import { IAppDataItem } from '@shared/Models';
import type { CheckoutSourcePayload } from '@shared/Models';
import { ensureAppDetailsData, ensureAppReviewsData, loadLinkedInProductGroup } from '@shared/actions/thunkActions';
import { createInstructionsModalAction, createRatingAction } from '@shared/actions/actions';
import { getTelemetryDetailContentCurrentView, getTransactCTATypes } from '@shared/utils/appUtils';
import { isOneTimePaymentOffer } from '@shared/utils/onetimepaymentoffers';
import { IAppEntityLinkedInProductGroupThunkActionParams } from '@shared/interfaces/linkedIn/models';
import { withHydration } from '@shared/hooks/useHydration';
import { EnsureAsyncDataSSR } from '@shared/hooks/useLocationChange';
import { createSetCheckoutSource } from '@shared/actions/checkoutActions';
import { getUserPrivateOffers } from '@shared/actions/privateOffersThunkActions';

export const mapStateToProps = (
  state: IState,
  ownProps: IAppDetailsOwnProps & { routeParams: Record<string, unknown> }
): IAppDetailsOwnProps => {
  const entityId = ownProps.routeParams.entityId.toString().toLowerCase();
  const isPrivate = !!ownProps.routeParams.privateOffer;

  const {
    billingCountryCode,
    breadcrumbUrl: stateBreadcrumbUrl,
    correlationId,
    currentGalleryViewIsCurated,
    currentView,
    featureFlags,
    locale,
    nationalCloud,
    isEmbedded,
  } = state.config;

  const { openInNewWindowButton } = featureFlags;
  const appList = state.apps.dataList as IAppDataItem[];
  const hashMap = state.apps.idMap;
  const query = ownProps.location ? ownProps.location.query : null;
  const appIndex = hashMap[`${entityId}`];

  let targetApp: IAppDataItem = null;
  if (appIndex >= 0) {
    targetApp = appList[`${appIndex}`];
  }

  const uiRole = state.users.profile && state.users.profile.uiRole;
  const managedLicenses = state.users.profile?.managedLicenses || [];
  const doesTenantHasLicenseForApp = Boolean(targetApp?.bigId) && managedLicenses.some((x) => x.productId === targetApp.bigId);

  const ctaTypes = getTransactCTATypes(targetApp, uiRole, doesTenantHasLicenseForApp, isEmbedded, billingCountryCode);

  if (ctaTypes) {
    targetApp.ctaTypes = ctaTypes;
  }
  const breadcrumbUrl = stateBreadcrumbUrl || '/' + locale + '/marketplace/apps';
  const defaultTab = query?.tab ? query.tab : null;

  const isOneTimePayment = isOneTimePaymentOffer(entityId);
  const hideBreadcrumbUrl = isOneTimePayment;

  return {
    ...ownProps,
    entityId,
    app: targetApp,
    startingPrice: targetApp ? targetApp.startingPrice : null,
    breadcrumbUrl,
    defaultTab,
    allApps: appList,
    nationalCloud,
    billingCountryCode,
    correlationId,
    currentView: getTelemetryDetailContentCurrentView(currentView, currentGalleryViewIsCurated),
    userInfo: state.users,
    isLoadingUserData: state.users.loading,
    isLoadingUserProfile: state.users.isLoadingUserProfile,
    parentApps: state.apps.relatedAppsItems?.items?.saasLinkingItems ?? null,
    linkedAddIns: state.apps.relatedAppsItems?.items?.linkedAddIns ?? null,
    locale,
    suggestedItems: state.apps.relatedAppsItems?.items?.suggestedItems || [],
    uiRole,
    isPrivate,
    isEmbedded,
    doesTenantHasLicenseForApp,
    hideBreadcrumbUrl,
    linkedInProductGroup: state.apps.linkedInProductGroup,
    openInNewWindowButtonFlag: openInNewWindowButton,
  };
};

function ensureAsyncData(dispatch: Dispatch, entityId: string, isPrivate: boolean) {
  return dispatch(ensureAppDetailsData(entityId, isPrivate));
}

export const mapDispatchToProps = (dispatch: Dispatch): IAppDetailsDispatchProps => {
  return {
    setCheckoutSourcePDP: ({ source }: CheckoutSourcePayload) => dispatch(createSetCheckoutSource({ source })),
    ensureAsyncData: ({ entityId, isPrivate }: { entityId: string; isPrivate?: boolean }) =>
      ensureAsyncData(dispatch, entityId, isPrivate),
    ensureAppReviewsData: (entityId: string, forceUpdate = false) => dispatch(ensureAppReviewsData(entityId, forceUpdate)),
    openInstructionsModal: () =>
      dispatch(
        createInstructionsModalAction({
          showModal: true,
          isFromDownload: false,
        })
      ),
    openRatingModal: (app: IAppDataItem, accessKey: string, ctaType: string, callback: any) =>
      dispatch(
        createRatingAction({
          showModal: true,
          app: app,
          accessKey: accessKey,
          ctaType: ctaType,
          callback: callback,
        })
      ),
    fetchUserPrivateOffers: () => dispatch(getUserPrivateOffers()),
    loadLinkedInProductGroup: (args: IAppEntityLinkedInProductGroupThunkActionParams) => dispatch(loadLinkedInProductGroup(args)),
  };
};

const AppDetails = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withHydration(AppDetailsComponent) as React.ComponentClass)
);

export const ensureAsyncDataSSR: EnsureAsyncDataSSR = async (
  dispatch: Dispatch<AnyAction>,
  getState: () => IState,
  params: IAppDetailsRouterProps
) => {
  const isPrivate = !!params.privateOffer;
  if (isPrivate) {
    return;
  }
  await ensureAsyncData(dispatch, params.entityId, false);
};

export default AppDetails;
