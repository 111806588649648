import * as React from 'react';
import * as PropTypes from 'prop-types';
import { TagItem } from '@fluentui/react';
import type { ITagItemStyles } from '@fluentui/react';

import { getFilterType } from 'utils/filterHelpers';

import { IURLQuery } from '@shared/Models';
import { IDataValues } from '@shared/utils/dataMapping';
import SpzaComponent from './spzaComponent';
import { Constants } from '@shared/utils/constants';
import { IBuildHrefContext, ILocContext, ICommonContext } from '@shared/interfaces/context';
import { urlPush } from '@shared/routerHistory';
import { handleFilterToggle } from '@shared/utils/filterItemUtils';
import { getEntityRegistration } from '@shared/utils/entityRegistration';
import { getNpsModule } from '@appsource/utils/nps';
import { listOfUrlKeysToExcludeInAppsource } from '@shared/oneTaxonomy';

export interface IFilterTile {
  filterType: string;
  filterValue: string;
  filterTileName: any;
  firstLevelProductFilter: boolean;
  onClick: () => void;
}

export interface IGalleryHeaderProps {
  activeFilters: any;
  searchText: string;
  getFilterLink: (filter: IDataValues, query?: IURLQuery) => string;
  isEmbedded: boolean;
  entityType: Constants.EntityType;
  currentView: string;
  urlQuery?: any;
}

const tagItemStyles: Partial<ITagItemStyles> = {
  close: {
    '&:focus': {
      border: '2px solid',
    },
  },
};

export default class GalleryHeader extends SpzaComponent<IGalleryHeaderProps, { isMounted: boolean }> {
  context: IBuildHrefContext & ILocContext & ICommonContext;
  state = {
    isMounted: false,
  };

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  getRoute() {
    return getEntityRegistration(this.props.entityType).route;
  }

  removeSearchFilter() {
    if (!this.props.isEmbedded) {
      const newPath = this.context.buildHref(this.getRoute(), null, {
        search: null,
        page: null,
        sortBy: null,
      });

      urlPush(newPath);
    }
  }

  renderImpl() {
    if (!this.state.isMounted) {
      return null;
    }

    const headerFilters: IFilterTile[] = [];

    let isConsultingSevices = false;
    const route = this.getRoute();
    if (route) {
      isConsultingSevices = (route.getPath(null) && route.getPath(null).indexOf('consulting-services') > -1) || false;
    }

    this.props.activeFilters.forEach((filter: any) => {
      let filterTitleName = this.context.loc(filter.LocKey || (filter as any).locKey, filter.Title || (filter as any).title);

      // !!! Temporary HACK: If filterTitleName is 'Power BI apps' and we are in consulting services tab,
      // then we make the text 'Power BI'.
      if (isConsultingSevices && filterTitleName === 'Power BI apps') {
        filterTitleName = 'Power BI';
      }

      if (filter.UrlKey && filter.UrlKey !== 'azure' && !listOfUrlKeysToExcludeInAppsource.includes(filter.UrlKey)) {
        const filterType = getFilterType(filter);

        headerFilters.push({
          filterType: filterType,
          filterValue: filter.UrlKey || (filter as any).urlKey,
          filterTileName: filterTitleName,
          firstLevelProductFilter: filterType === 'product' && !filter.IsChildFilter,
          onClick: handleFilterToggle(
            filterType,
            filter.UrlKey || (filter as any).urlKey,
            true,
            this.props.getFilterLink(filter, filterType === 'product' && !filter.IsChildFilter ? this.props.urlQuery : null),
            Constants.Telemetry.ActionModifier.FilterTag,
            () => {
              window.scrollTo(0, 0);
            }
          ),
        });
      }
    });

    // sort function to put the subcategories after the category
    function sortFunction(a: IFilterTile) {
      if (a.filterType === 'subcategories') {
        return 1;
      } else {
        return -1;
      }
    }

    headerFilters.sort(sortFunction);

    if (this.props.isEmbedded) {
      return null;
    }

    // TODO: the close icon should be using a sprite instead of an img
    if (headerFilters && headerFilters.length > 0) {
      return (
        <div className="galleryHeader">
          <div className="galleryHeader_tileFilters" role="list">
            {headerFilters.map((filter, key) => {
              return (
                <TagItem
                  className="tileFilter"
                  index={key}
                  key={key}
                  item={{ name: `remove ${filter.filterValue}`, key }}
                  styles={tagItemStyles}
                  tabIndex={0}
                  onRemoveItem={() => {
                    filter.onClick();
                    getNpsModule()?.increaseActionsCounter('filterActionsCount');
                  }}
                  removeButtonAriaLabel={`Remove ${filter.filterTileName}`}
                >
                  {filter.filterTileName}
                </TagItem>
              );
            })}
          </div>
        </div>
      );
    } else {
      // In case of no headers the tile header should align with the filters hence the div with just margin.
      return <div className="emptyGalleryHeader"></div>;
    }
  }
}

(GalleryHeader as any).contextTypes = {
  loc: PropTypes.func,
  buildHref: PropTypes.func,
  renderErrorModal: PropTypes.func,
};
