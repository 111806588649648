import classNames from 'classnames';
import * as React from 'react';
import SpzaComponent from '../spzaComponent';
import { SortedTableColumn } from './sortedTableColumn';

const SortOrder = {
  None: 'none',
  Ascending: 'ascending',
  Descending: 'descending',
} as const;

function SortArrayByProperty<T>(inputArray: Array<T>, propertyName: string, order: string): T[] {
  const comparer: any = function (arg0: any, arg1: any) {
    if (typeof arg0[`${propertyName}`] !== 'number' || typeof arg1[`${propertyName}`] !== 'number') {
      return 0;
    }

    return order === SortOrder.Ascending
      ? arg0[`${propertyName}`] - arg1[`${propertyName}`]
      : arg1[`${propertyName}`] - arg0[`${propertyName}`];
  };

  return inputArray.sort(comparer);
}

interface IMultiLineRowConfiguration {
  multilineColumns: string[];
  multilineColumnClass?: string;
  emptyColumnClassName?: string;
  lastRowClassName?: string;
}

export interface ISortConfig {
  sortColumnName?: string;
  sortOrder?: string;
}

export interface ISortedTableProps {
  thead: Array<any>;
  tbody: Array<any>;
  sortConfig: ISortConfig;
  // Columns specified in this array get a <span /> rendered around its content.
  spanColumns?: Array<string>;
  // Columns specified in this array are not rendered.
  ignore?: Array<string>;
  // Columns specified in this area have html in their values so use dangerouslySetInnerHTML
  setInnerHTMLColumns?: Array<string>;

  // Columns specified in this array that shows an item per line/
  multiLineColumns?: Array<string>;

  /** Use this to order the columns instead of using Object.keys result */
  columns?: string[];

  columnsClasses?: { [key: string]: string };
  multiLineRowsConfiguration?: IMultiLineRowConfiguration;
  defaultSortingDisabled?: boolean;
}

export class SortedTable extends SpzaComponent<ISortedTableProps, any> {
  // This is the old implementation of the component and new implemnantion is under multiyear feature flag
  renderOldSingleLineRow = (row: any) => {
    const columns: Array<any> = [];

    Object.keys(row).forEach((propertyName) => {
      // If this flag is set the property is not rendered. Default - property is rendered.
      const shouldIgnore = this.props.ignore ? this.props.ignore.indexOf(propertyName) >= 0 : false;

      // If this flag is set a span tag is added around the content of table data. Default - no span tag added.
      const shouldAddSpanTag = this.props.spanColumns ? this.props.spanColumns.indexOf(propertyName) >= 0 : false;

      // If this flag is set, we will use dangerouslySetInnerHTML
      const shouldUseSetInnerHTML = this.props.setInnerHTMLColumns
        ? this.props.setInnerHTMLColumns.indexOf(propertyName) >= 0
        : false;

      // If this flag is set the content of table data can have breaks within it. Default - A single line.
      const shouldShowMultiLine = this.props.multiLineColumns ? this.props.multiLineColumns.indexOf(propertyName) >= 0 : false;

      if (!shouldIgnore && row[`${propertyName}`]) {
        if (row[`${propertyName}`] instanceof Array && shouldShowMultiLine) {
          if (row[`${propertyName}`]) {
            const lines = row[`${propertyName}`].map(function (item: any, key: any) {
              if (shouldUseSetInnerHTML) {
                return (
                  // eslint-disable-next-line react/no-danger
                  <span key={key} dangerouslySetInnerHTML={{ __html: item }}>
                    <br />
                  </span>
                );
              }
              return (
                <span key={key}>
                  {item}
                  <br />
                </span>
              );
            });
            columns.push(
              // eslint-disable-next-line react/forbid-dom-props
              <td id={propertyName} className={propertyName}>
                {lines}
              </td>
            );
          }
        } else if (
          typeof row[`${propertyName}`] === 'string' ||
          typeof row[`${propertyName}`] === 'number' ||
          typeof row[`${propertyName}`] === 'object'
        ) {
          if (shouldAddSpanTag) {
            columns.push(
              // eslint-disable-next-line react/forbid-dom-props
              <td id={propertyName} className={propertyName}>
                <span>{row[`${propertyName}`]}</span>
              </td>
            );
          } else if (shouldUseSetInnerHTML) {
            columns.push(
              <td
                // eslint-disable-next-line react/forbid-dom-props
                id={propertyName}
                className={propertyName}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: row[`${propertyName}`],
                }}
              />
            );
          } else {
            columns.push(
              // eslint-disable-next-line react/forbid-dom-props
              <td id={propertyName} className={propertyName}>
                {row[`${propertyName}`]}
              </td>
            );
          }
        }
      }
    });

    return (
      <tr className={row.rowClassName} key={row.rowKey}>
        {columns}
      </tr>
    );
  };

  getColumnKeysToRender = (row: any): string[] => {
    if (!row) {
      return [];
    }

    const { columns } = this.props;
    if (columns) {
      return columns;
    }

    return Object.keys(row).filter((columnKey) => {
      const columnValue = row[columnKey.toString()];
      const shouldIgnore = this.props.ignore?.some((ignoreKey) => ignoreKey === columnKey);
      return columnValue && !shouldIgnore;
    });
  };

  renderSingleLineRow = (row: any) => {
    const { columnsClasses } = this.props;

    const columns = this.getColumnKeysToRender(row).map((columnKey) => (
      <SortedTableColumn
        key={columnKey}
        columnKey={columnKey}
        className={columnsClasses?.[`${columnKey}`]}
        columnValue={row[`${columnKey}`]}
        tableProps={this.props}
      />
    ));

    return (
      <tr className={row.rowClassName} key={row.rowKey}>
        {columns}
      </tr>
    );
  };

  renderMultilineRow = (multilineConfig: IMultiLineRowConfiguration) => (rowData: any) => {
    const { columnsClasses } = this.props;
    const { multilineColumns, emptyColumnClassName, lastRowClassName, multilineColumnClass } = multilineConfig;

    if (!multilineColumns.length) {
      return null;
    }

    const numberOfMultilineRows = rowData[multilineColumns[0]]?.length || 0;
    const rows = [];
    for (let rowIndex = 0; rowIndex < numberOfMultilineRows; rowIndex++) {
      const columns = this.getColumnKeysToRender(rowData).map((columnKey) => {
        const columnValue = rowData[columnKey.toString()];
        const isMultilineColumn = multilineColumns.find((x) => x === columnKey);

        if (rowIndex === 0) {
          return (
            <SortedTableColumn
              columnKey={columnKey}
              key={columnKey}
              columnValue={isMultilineColumn ? columnValue[`${rowIndex}`] : columnValue}
              tableProps={this.props}
              className={classNames(columnsClasses?.[`${columnKey}`], { [multilineColumnClass]: isMultilineColumn })}
            />
          );
        } else {
          if (isMultilineColumn) {
            return (
              <SortedTableColumn
                columnKey={columnKey}
                key={columnKey}
                columnValue={columnValue[`${rowIndex}`]}
                tableProps={this.props}
                className={classNames(multilineColumnClass, columnsClasses?.[`${columnKey}`])}
              />
            );
          } else {
            return (
              <td key={columnKey} className={classNames(columnKey, emptyColumnClassName, columnsClasses?.[`${columnKey}`])}></td>
            );
          }
        }
      });
      rows.push(columns);
    }

    return rows.map((row, index) => (
      <tr className={`${rowData.rowClassName} ${index === rows.length - 1 ? lastRowClassName : ''}`} key={rowData.rowKey}>
        {row}
      </tr>
    ));
  };

  renderImpl() {
    const { multiLineRowsConfiguration, tbody, defaultSortingDisabled } = this.props;

    let tableData = defaultSortingDisabled
      ? tbody
      : tbody.sort((a: any, b: any) => {
          const titleA = a.title ? a.title.toUpperCase() : a.category.toUpperCase();
          const titleB = b.title ? b.title.toUpperCase() : b.category.toUpperCase();
          return titleA < titleB ? 1 : titleA > titleB ? -1 : 0;
        });
    tableData = this.props.sortConfig.sortColumnName
      ? SortArrayByProperty(tableData, this.props.sortConfig.sortColumnName, this.props.sortConfig.sortOrder)
      : tableData;

    const hasMultilineConfig = !!multiLineRowsConfiguration?.multilineColumns?.length;
    const rowRenderingFunction = hasMultilineConfig
      ? this.renderMultilineRow(multiLineRowsConfiguration)
      : this.renderOldSingleLineRow;

    return (
      <table data-f-sort="true">
        <thead>{this.props.thead}</thead>
        <tbody>{tableData.map((row) => rowRenderingFunction(row))}</tbody>
      </table>
    );
  }
}
