import * as httpProtocol from './httpProtocol';
import { logError } from '../../utils/httpClientUtil';
import { getAppConfig } from '../../services/init/appConfig';

const option: httpProtocol.IHttpOption = {
  requireCorrelationId: true,
  stringifyPostData: false,
  parseResult: false,
  authenticationType: httpProtocol.AuthenticationTypes.Graph,
};

export function generateReportEmbedToken(reportData: any) {
  const endpoint = '/view/generateReportEmbedToken';
  const post = httpProtocol.post(endpoint, option);

  const body = {
    groupId: reportData.groupId,
    reportId: reportData.reportId,
    datasetId: reportData.datasetId,
  };

  return post
    .addQueryEntry('version', getAppConfig('apiVersion'))
    .setData(body)
    .request()
    .catch((error: any) => {
      logError('generateReportEmbedToken', error);
      return Promise.resolve(null);
    });
}
