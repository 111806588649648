import * as React from 'react';
import { IModalProps } from '@shared/components/modals/modal';
import { IReviewPayload, IAppDataItem } from '@shared/models';
import { Constants } from '@shared/utils/constants';
import { SignInModal } from '@shared/components/modals/signInModal';
import { getSignInModalType, getCTATypeForActionString } from '@shared/utils/appUtils';
import { Service } from '@shared/serviceViewModel';
import { RatingModal } from '@shared/containers/modals/ratingModal';

export function handleRatingModal(props: IModalProps, appData: IAppDataItem, dismissModal: () => void, service: Service) {
  let modalContent: any = null;
  const ctaType = getCTATypeForActionString(props.payload.ctaType);
  const signinType: Constants.SignInType = getSignInModalType(
    appData && appData.primaryProduct,
    appData && appData.products,
    props.userInfo,
    ctaType,
    !!service
  );
  const shouldShowSignInModal: boolean = signinType !== Constants.SignInType.Authorized && !props.isEmbedded;
  const signinRedirect = 'review';
  const entityType: Constants.EntityType = !!service && !appData ? Constants.EntityType.Service : Constants.EntityType.App;

  if (shouldShowSignInModal) {
    const appTitle = appData && appData.title ? appData.title : '';
    const userEmail = props.userInfo && props.userInfo.email ? props.userInfo.email : '';

    modalContent = (
      <SignInModal
        redirect={signinRedirect}
        entityId={appData.entityId}
        dismissModal={dismissModal}
        signInModalType={signinType}
        appName={appTitle}
        email={userEmail}
        entityType={entityType}
        ctaType={ctaType}
        reviewAppId={appData.entityId}
      />
    );
  } else {
    const payload: IReviewPayload = props.payload;
    modalContent = <RatingModal payload={payload} dismissModal={dismissModal} setUserReviewStatus={props.setUserReviewStatus} />;
  }

  return modalContent;
}
