import * as React from 'react';
import * as PropTypes from 'prop-types';
import { IBillingCountry, IPricingInformation, ISimpleSKU } from '@shared/Models';
import { IBuildHrefContext, ILocContext, ILocParamsContext, ICTACallbackContext, ICommonContext } from '../interfaces/context';
import { renderNotAvailablePriceUI, getVariablePriceStringWithDefault } from '@shared/utils/pricing';
import { SortedTable, ISortConfig, SortedTH, SortOrder } from './sortedTable';
import { Plan } from '@shared/utils/constants';
import { SimplePriceCell } from './simplePriceCell';
import FuturePriceWarning from '@shared/components/futurePriceWarning';
import PureSpzaComponent from '@shared/components/pureSpzaComponent';
import pdpPrivateBadge from '@shared/images/pdp-privateBadge.svg';

export interface ISimplePlanPricingProps {
  pricing: IPricingInformation;
  futurePrices?: IPricingInformation;
  billingCountry: IBillingCountry;
  isMicrosoftManaged?: boolean;
  shouldBlockPrices?: boolean;
  isOneTimePaymentOffer?: boolean;
  locale?: string;
}

interface ISimplePlanPricingState {
  sortConfig?: ISortConfig;
}

export class SimplePlanPricing extends PureSpzaComponent<ISimplePlanPricingProps, ISimplePlanPricingState> {
  context: IBuildHrefContext & ILocContext & ILocParamsContext & ICTACallbackContext & ICommonContext;

  constructor(
    props: ISimplePlanPricingProps,
    context: IBuildHrefContext & ILocContext & ILocParamsContext & ICTACallbackContext & ICommonContext
  ) {
    super(props, context);

    this.state = {
      sortConfig: {
        sortColumnName: Plan.displayRank,
        sortOrder: SortOrder.Ascending,
      },
    };
    this.handleSortChange = this.handleSortChange.bind(this);
  }

  handleSortChange(sortColumnName: string, order: string) {
    this.setState({
      sortConfig: {
        sortColumnName,
        sortOrder: order,
      },
    });
  }

  showColumnByUnit(skus: ISimpleSKU[], unit: string) {
    return skus.some((sku) => sku.termPrices && sku.termPrices.some((termPrice) => termPrice.unit.toLowerCase() === unit));
  }

  getBadge(sku: ISimpleSKU) {
    if (sku.isPrivate) {
      return <img className="banner" src={pdpPrivateBadge} />;
    }
  }

  getPriceColumnsData(skus: ISimpleSKU[], renderBadges: boolean) {
    const { futurePrices, isOneTimePaymentOffer } = this.props;
    const showPriceColumn = skus.some((sku) => !!sku.startingPrice);
    const showYearlyPrice = this.showColumnByUnit(skus, 'year');
    let showMonthlyPrice = this.showColumnByUnit(skus, 'month');

    // If the offer is Microsoft managed and without prices.
    if (this.props.isMicrosoftManaged && skus.length && !showYearlyPrice && !showMonthlyPrice) {
      showMonthlyPrice = true;
    }

    const formattedSkus = skus.map((sku) => {
      const futureSku = futurePrices?.skus.find((futurePriceSku: ISimpleSKU) => futurePriceSku.id === sku.id) as ISimpleSKU;
      const { monthly, yearly, price, futurePriceMonthly, futurePriceYearly } = getVariablePriceStringWithDefault({
        sku,
        showPriceColumn,
        showYearlyPrice,
        showMonthlyPrice,
        isMicrosoftManaged: this.props.isMicrosoftManaged,
        countryCode: this.props.billingCountry.countryCode,
        currency: this.props.billingCountry.currency,
        context: this.context,
        futureSku,
        isOneTimePaymentOffer,
        locale: this.props.locale,
        shouldBlockPrices: this.props.shouldBlockPrices,
      });
      let row: { [key: string]: any } = {
        title: sku.title,
        description: sku.description,
        displayRank: sku.displayRank,
        monthly: monthly && <SimplePriceCell price={monthly} futurePrice={futurePriceMonthly} />,
        yearly: yearly && <SimplePriceCell price={yearly} futurePrice={futurePriceYearly} />,
        price: sku.startingPrice?.meterId ? `${price}/${sku.startingPrice.meterId}` : price,
        priceInternal: showPriceColumn && sku.startingPrice ? sku.startingPrice.value : 0,
        rowClassName: 'pricingListItem',
        rowKey: sku.title,
      };

      if (renderBadges) {
        row = {
          badge: (renderBadges && this.getBadge(sku)) || ' ',
          ...row,
        };
      }
      return row;
    });

    const showGeneralPrice = showPriceColumn && !showMonthlyPrice && !showYearlyPrice;

    return { showMonthlyPrice, showYearlyPrice, formattedSkus, showGeneralPrice };
  }

  renderImpl() {
    const { pricing, isOneTimePaymentOffer } = this.props;

    if (!pricing) {
      return <div>{this.context.loc('Loading', 'Loading...')}</div>;
    }

    const skus = pricing.skus as ISimpleSKU[];
    const hasBadges = skus.some((sku) => sku.isPrivate === true);
    const { showMonthlyPrice, showYearlyPrice, showGeneralPrice, formattedSkus } = this.getPriceColumnsData(skus, hasBadges);
    return skus.length === 0 ? (
      renderNotAvailablePriceUI(this.context)
    ) : (
      <div className="pricing simplePlanPricing">
        <div className="c-table f-divided">
          <FuturePriceWarning futurePrices={this.props.futurePrices} />
          <SortedTable
            thead={[
              <tr className="pricingListItem" key="0">
                {hasBadges && <th className="f-sortable" colSpan={1} aria-sort="none" scope="col"></th>}
                <th className="f-sortable" colSpan={1} aria-sort="none" scope="col">
                  {this.context.loc('Plan', 'Plan')}
                </th>
                <th className="f-sortable" colSpan={1} aria-sort="none" scope="col">
                  {this.context.loc('Description', 'Description')}
                </th>
                {showMonthlyPrice ? (
                  <SortedTH
                    boundProperty="priceInternal"
                    handleSortChange={this.handleSortChange}
                    currentlySelected={this.state.sortConfig.sortColumnName}
                  >
                    {isOneTimePaymentOffer
                      ? this.context.loc('Price', 'Price')
                      : this.context.loc('Monthly_Price', 'Monthly Price')}
                  </SortedTH>
                ) : null}
                {showYearlyPrice ? (
                  <SortedTH
                    boundProperty="priceInternal"
                    handleSortChange={this.handleSortChange}
                    currentlySelected={this.state.sortConfig.sortColumnName}
                  >
                    {this.context.loc('Annual_Price', 'Annual Price')}
                  </SortedTH>
                ) : null}
                {showGeneralPrice ? (
                  <SortedTH
                    boundProperty="priceInternal"
                    handleSortChange={this.handleSortChange}
                    currentlySelected={this.state.sortConfig.sortColumnName}
                  >
                    {this.context.loc('Price', 'Price')}
                  </SortedTH>
                ) : null}
              </tr>,
            ]}
            tbody={formattedSkus}
            sortConfig={this.state.sortConfig}
            ignore={['rowKey', 'rowClassName', 'priceInternal', Plan.displayRank]}
            setInnerHTMLColumns={['description']}
            multiLineColumns={['monthly', 'yearly', 'title']}
          />
        </div>
      </div>
    );
  }
}

(SimplePlanPricing as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  buildHref: PropTypes.func,
  ctaCallback: PropTypes.func,
  renderErrorModal: PropTypes.func,
};
