import React from 'react';
import { IState } from '@src/State';
import {
  UserProfileModal as UserProfileModalComponent,
  IUserProfileMethods,
  IUserProfileProps,
} from '@appsource/components/modals/userProfileModal';
import { getUserProfile, updateUserProfile } from '@shared/actions/thunkActions';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IUserLeadProfileAgreement } from '@shared/Models';

const mapStateToProps = (state: IState): IUserProfileProps => {
  if (state.config.featureFlags.ProfileExp === 'true') {
    return {
      renderProfile: true,
      userLeadgenProfile: state.users.profile,
    };
  }
  return {
    userLeadgenProfile: state.users.profile,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): IUserProfileMethods => {
  return {
    getUserProfile: () => dispatch(getUserProfile()),
    updateUserProfile: (profile: IUserLeadProfileAgreement) => dispatch(updateUserProfile(profile)),
  };
};

export const UserProfileModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileModalComponent) as React.StatelessComponent<any>;
