import { logger } from '@src/logger';
import { ITelemetryData } from '@shared/Models';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { getWindow } from '@shared/services/window';
import { Constants } from './constants';
import { stringifyError } from './errorUtils';

export function getSignUpHost(modalType: number) {
  if (modalType === Constants.SignInType.SignInWith_MSA || modalType === Constants.SignInType.SwitchTo_MSA) {
    return 'https://signup.live.com/signup?wa=wsignin1.0&rpsnv=13&rver=6.7.6643.0&wp=MBI_SSL';
  }

  return 'https://signup.microsoft.com/signUp?sku=appsource';
}

export function getSignInHeader(context: any) {
  return context.loc('SI_SignInAppSource');
}

export function getTitle(context: any) {
  return context.loc('SI_WorkAccount');
}

export function getRedirectString(modalType: number) {
  if (modalType === Constants.SignInType.SignInWith_MSA || modalType === Constants.SignInType.SwitchTo_MSA) {
    return '&wreply=';
  }

  return '&ru=';
}

export async function handleSignUp() {
  const payload: ITelemetryData = {
    page: getWindow().location.href,
    action: Constants.Telemetry.Action.Click,
    actionModifier: Constants.Telemetry.ActionModifier.Signup,
    details: JSON.stringify({
      message: 'Clicked on sign-up. Navigating to office portal',
    }),
  };

  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logAndFlushTelemetryInfo', payload);
  logger.info(payload.details, {
    action: payload.action,
    actionModifier: payload.actionModifier,
  });

  try {
    const redirectUrl = `https://signup.microsoft.com/signUp?sku=appsource&ru=${encodeURIComponent(
      getWindow().location.href
    )}&pi=1`;
    getWindow().location.href = redirectUrl;
  } catch (err) {
    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.Login,
      actionModifier: Constants.Telemetry.ActionModifier.Error,
      details: stringifyError(err),
    };

    SpzaInstrumentService.getProvider().probe<ITelemetryData>(Constants.Telemetry.ProbeName.LogInfo, payload);
    logger.error(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });
  }
}
