let apiVersions: string[] = [
  '2016-12-07',
  '2017-01-24',
  // this is the version for marketplace API
  '2017-04-15',
  '2017-04-19',
  '2017-04-24',
  // catrp api version
  '2018-08-01-beta',
  // multi geo services
  '2020-09-22',
];

export let errorMessages = {
  invalidApiVersion: 'Invalid value for query string \'api-version\'. Allowed values are ' + JSON.stringify(apiVersions),
  missingApiVersion: 'Query string \'api-version\' is missing. Allowed values are ' + JSON.stringify(apiVersions),
  multipleValuesForQueryParam: ' query string does not support multiple values. Please provide a single value',
};

export let minVersionForOData = process.env.minVersionForOData || '2016-12-07';

export function isValidAPIVersion(apiVersion: string): boolean {
  return apiVersions.indexOf(apiVersion) !== -1;
}
