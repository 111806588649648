import * as React from 'react';
import { Constants } from '@shared/utils/constants';
import { getWindow } from '@shared/services/window';
import { ITelemetryData, IURLQuery, IAppDataItem, IFilterIcon, ProductsType } from '@shared/Models';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { DataMap, IProductValue } from '@shared/utils/dataMapping';
import { bitMasksContainSomeBitMask, getShortCutBitmask } from '@shared/utils/datamappingHelpers';
import { logger } from '@src/logger';
import { isTransactApp } from '@shared/utils/appUtils';

export enum OwnerType {
  App,
  Service,
}

// temp method to hide badge certificates.
export function hideCertificateBadges() {
  return false;
}

export function getDetailInformation(item: any, id: string): any {
  if (item.detailInformation && item.detailInformation[`${id}`]) {
    return item.detailInformation[`${id}`];
  } else {
    return '';
  }
}

// Use this method only for the Array items in the details fields
export function getDetailArrayItem(item: any, id: string): string[] | string {
  if (item.detailInformation && item.detailInformation[`${id}`] && item.detailInformation[`${id}`].length > 0) {
    return item.detailInformation[`${id}`];
  } else {
    return '';
  }
}

export function getDetailInformationAsDivElement(item: any, id: string): JSX.Element {
  const information = this.getDetailInformation(item, id);

  if (information) {
    return <div>{information}</div>;
  } else {
    return null;
  }
}

export function getListElements(list: string[], maxItems = -1): JSX.Element[] {
  const listRender: JSX.Element[] = [];
  if (list) {
    for (let i = 0; i < list.length; i++) {
      if (i === maxItems) {
        break;
      }
      listRender.push(<div className="leftSideItem">{list[`${i}`]}</div>);
    }
  }
  return listRender;
}

export function generateClickPayloadAndLogTelemetry(
  ownerType: OwnerType,
  ownerId: string,
  actionModifier: string,
  detailsObject: any
): void {
  const payload: ITelemetryData = {
    page: getWindow().location.href,
    action: Constants.Telemetry.Action.Click,
    actionModifier,
    details: JSON.stringify(detailsObject),
  };

  if (ownerType === OwnerType.App) {
    payload.appName = ownerId;
  }

  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
  logger.info(payload.details, {
    action: payload.action,
    actionModifier: payload.actionModifier,
  });
}

export function generateLinkPayloadAndLogTelemetry(
  ownerType: OwnerType,
  ownerId: string,
  linkType: string,
  link: string,
  tabOwner?: string
): void {
  const detailsObject = {
    linkType,
    link,
    tab: tabOwner || '',
  };

  generateClickPayloadAndLogTelemetry(ownerType, ownerId, Constants.Telemetry.ActionModifier.Link, detailsObject);
}

export function generateFilterClickPayloadAndLogTelemetry(
  ownerType: OwnerType,
  ownerId: string,
  actionModifier: string,
  detailsObject: any
): void {
  const payload: ITelemetryData = {
    page: getWindow().location.href,
    action: Constants.Telemetry.Action.FilterClick,
    actionModifier,
    details: JSON.stringify(detailsObject),
  };

  if (ownerType === OwnerType.App) {
    payload.appName = ownerId;
  }

  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
  logger.info(payload.details, {
    action: payload.action,
    actionModifier: payload.actionModifier,
  });
}

export function generateTileClickPayloadAndLogTelemetry(actionModifier: string, detailsObject: any): void {
  const payload: ITelemetryData = {
    page: getWindow().location.href,
    action: Constants.Telemetry.Action.Click,
    actionModifier,
    details: JSON.stringify(detailsObject),
    appName: detailsObject.appName,
  };

  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
  logger.info(payload.details, {
    action: payload.action,
    actionModifier: payload.actionModifier,
    appName: payload.appName,
  });
}

export function generateBreadcrumbPayloadAndLogTelemetry(linkType: string): void {
  const detailsObject = {
    linkType,
    link: getWindow().location.href,
  };

  const payload: ITelemetryData = {
    page: getWindow().location.href,
    action: Constants.Telemetry.Action.Click,
    actionModifier: Constants.Telemetry.ActionModifier.Breadcrumb,
    details: JSON.stringify(detailsObject),
  };

  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
  logger.info(payload.details, {
    action: payload.action,
    actionModifier: payload.actionModifier,
  });
}

// Each version of appDetails.tsx (SMP/AMP/Mobile) seem to have a copy of this method
// They should try and use this common method. It could be marked export at that point
function getDetailHyperlinkElement(ownerId: any, information: any, id: string, locString: string, parentLocString?: string) {
  if (!information) {
    return null;
  } else {
    return (
      <a
        className="c-hyperlink"
        rel="noreferrer"
        target="_blank"
        href={information}
        onClick={() => {
          generateLinkPayloadAndLogTelemetry(OwnerType.App, ownerId, id, information, 'Default');
        }}
        aria-label={`${parentLocString} ${locString}`}
      >
        {locString}
      </a>
    );
  }
}

/**
 * Gets the elements of the Support section
 * on the App Details page for AMP/Mobile
 * @export
 * @param {*} item (this.props.App passed down from any of the appDetails.tsx)
 * @param {*} context (this.context passed down from any of the appDetails.tsx)
 * @returns Div containing Support link and/or Help Link if available
 */
export function getSupportElements(item: any, context: any) {
  // Both SupportLink and HelpLink are optional and could be null
  // If both are null, don't show 'Support' section. Otherwise, do
  if (!getDetailInformation(item, 'SupportLink') && !getDetailInformation(item, 'HelpLink')) {
    return null;
  } else {
    return (
      <div className="cell">
        <header>{context.loc('App_Support')}</header>
        {getDetailHyperlinkElement(
          item.entityId,
          getDetailInformation(item, 'SupportLink'),
          'SupportLink',
          context.loc('App_Support'),
          context.loc('App_Support')
        )}
        {getDetailHyperlinkElement(
          item.entityId,
          getDetailInformation(item, 'HelpLink'),
          'HelpLink',
          context.loc('App_Help'),
          context.loc('App_Support')
        )}
      </div>
    );
  }
}

export function addSaaSBundleProperties(payloadDetails: any, app: IAppDataItem): IFilterIcon[] | null {
  if (payloadDetails && app.linkedAddIns && app.linkedAddIns.length > 0) {
    payloadDetails.isSaaSBundle = true;
  }

  return payloadDetails;
}

export function getProductsList(products: IProductValue): IFilterIcon[] {
  const filterDatamapBlocks = DataMap[`${Constants.filterMaps.products}`];
  const filteritems: IFilterIcon[] = [];

  Object.keys(filterDatamapBlocks).map((value) => {
    const currentDatamapBlock = filterDatamapBlocks[`${value}`];
    if (
      products &&
      !currentDatamapBlock.ShortcutFilters &&
      bitMasksContainSomeBitMask(getShortCutBitmask(currentDatamapBlock), products)
    ) {
      const filter = currentDatamapBlock;
      if (!filter) {
        return null;
      }

      filteritems.push({
        title: filter.Title,
        urlKey: filter.UrlKey as ProductsType,
      });
    }
    return null;
  });
  return filteritems;
}

export const shouldShowUserTerms = ({ app, billingCountryCode }: { app: IAppDataItem; billingCountryCode: string }) => {
  const { ctaTypes } = app;
  const CtaRequiringTerms = [Constants.CTAType.Contact, Constants.CTAType.RequestTrial];

  return (
    !isTransactApp({ isEmbedded: false, ctaTypes, appData: app, billingRegion: billingCountryCode }) &&
    !CtaRequiringTerms.includes(ctaTypes[0])
  );
};
