import * as React from 'react';
import * as PropTypes from 'prop-types';
import SpzaComponent from './../spzaComponent';
import { IFieldHub } from '../../Models';
import { getPowerBIEmbedParams, getPowerBIEmbedReportURL } from '../../utils/fieldHubUtils';

// import * as models from 'powerbi-models';

export interface IFieldHubModalProps {
  iframeProps: IFieldHub;
  dismissModal: () => void;
  getReportEmbedToken: (reportData: any) => Promise<any>;
}

export class FieldHubModal extends SpzaComponent<IFieldHubModalProps, any> {
  componentDidMount() {
    const reportData = getPowerBIEmbedParams(this.props.iframeProps.reportName);
    const groupId = reportData.groupId;
    const reportId = reportData.reportId;
    const embedUrl = getPowerBIEmbedReportURL(groupId, reportId);

    // Refresh the access token if it has expired or about to expire
    this.props.getReportEmbedToken(reportData).then((token: string) => {
      if (token) {
        const sectionId = reportData.sectionId;
        let config = {
          type: 'report',
          accessToken: token,
          embedUrl: embedUrl,
          id: reportId,
          settings: {
            filterPaneEnabled: false,
          },
          pageName: sectionId,
        };

        let reportContainer = document.getElementById('reportContainer');
        if (reportContainer && window && window['powerbi']) {
          window['powerbi'].embed(reportContainer, config);
        }
      }
    });
  }

  renderImpl() {
    return (
      <div role="dialog" aria-label="field hub is now open" className="fieldHubModalClass">
        <div className="dialogContent">
          <div className="toolBar">
            <button id="cancelButton" aria-label="close" className="cancel" onClick={this.props.dismissModal}>
              <span className="c-glyph"></span>
            </button>
          </div>
          <div className="iframeContainer" id="reportContainer"></div>
        </div>
      </div>
    );
  }
}

(FieldHubModal as any).contextTypes = {
  loc: PropTypes.func,
  renderErrorModal: PropTypes.func,
};
