/* eslint-disable react/forbid-dom-props */
import React from 'react';
import * as PropTypes from 'prop-types';
import SpzaComponent from './spzaComponent';
import { IBuildHrefContext, ICommonContext, ILocContext } from '@shared/interfaces/context';
import { routes } from '@shared/routerHistory';
import { TestimonialProps, TestimonialRecommendation } from './Testimonials';
import { Link } from 'react-router-dom';

export class SuccessStory extends SpzaComponent<TestimonialProps, null> {
  context: IBuildHrefContext & ICommonContext & ILocContext;

  getAppTile(app: TestimonialRecommendation, tileKey: number) {
    const { iconURL, shortDescription, shortDescriptionFallback, entityId, productFamily, title } = app;

    const route = this.context.buildHref(
      routes.appDetails,
      {
        productId: productFamily,
        entityId,
      },
      { tab: 'Overview' }
    );
    const description = this.context.loc(shortDescription, shortDescriptionFallback);

    return (
      <div className="tileWrapper" key={tileKey}>
        <img className="icon" src={iconURL} alt={description} />
        <h2 className="title">{title}</h2>
        <span className="description">{description}</span>
        <Link className="link" data-bi-id={entityId} data-bi-area="SuccessStory learn more link" to={route}>
          {this.context.loc('AppSource_Banner_LearnMore', 'Learn more >')}
        </Link>
      </div>
    );
  }

  renderImpl() {
    const {
      author: { name, title },
      description,
      descriptionFallback,
      recommendations,
      image,
    } = this.props;
    const backgroundImage = {
      backgroundImage: `linear-gradient(256.63deg, rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0.55)), url(${image})`,
    } as React.CSSProperties;

    return (
      <div className="storyContainer">
        <div className="image" style={backgroundImage}>
          <div className="contentContainer">
            <div className="testimonial">
              <span className="title">{this.context.loc(description, descriptionFallback)}</span>
              <div className="author">
                <span className="authorName">{name}</span>
                <span className="authorTitle">{title}</span>
              </div>
            </div>
            <div className="apps appsDesktop">
              {recommendations.map((app: TestimonialRecommendation, index: number) => this.getAppTile(app, index))}
            </div>
          </div>
        </div>
        <div className="apps appsMobile">
          {recommendations.map((app: TestimonialRecommendation, index: number) => this.getAppTile(app, index))}
        </div>
      </div>
    );
  }
}

(SuccessStory as React.ComponentClass<TestimonialProps>).contextTypes = {
  buildHref: PropTypes.func,
  renderErrorModal: PropTypes.func,
  loc: PropTypes.func,
};
