import { IState } from './../../State';
import { Constants } from './../utils/constants';
import { createUpdateUserFavouriteFetchDataStatusAction } from './../actions/userFavouriteActions';

export function shouldPerformFetchFavourite(fetchDataStatus: Constants.UserFavourite.FetchDataStatus): boolean {
  // fetch favourite data only if favourite entity state is invalid
  return fetchDataStatus === Constants.UserFavourite.FetchDataStatus.Invalid;
}

/**
 * invalidate redux favourite store by flagging fetch data status as invalid
 */
export function invalidateFavourites() {
  return (dispatch: Function, getState: () => IState) => {
    const fetchDataStatus = Constants.UserFavourite.FetchDataStatus.Invalid;

    dispatch(
      createUpdateUserFavouriteFetchDataStatusAction({
        fetchDataStatus: fetchDataStatus,
      })
    );
  };
}

/**
 * validate redux favourite store by flagging fetch data status as valid
 */
export function validateFavourites() {
  return (dispatch: Function, getState: () => IState) => {
    const fetchDataStatus = Constants.UserFavourite.FetchDataStatus.Valid;

    dispatch(
      createUpdateUserFavouriteFetchDataStatusAction({
        fetchDataStatus: fetchDataStatus,
      })
    );
  };
}

export function fetchPricing(entityId: string, applicationType: Constants.UserFavourite.ApplicationTypes) {
  return (dispatch: Function, getState: () => IState) => {
    return Promise.resolve(undefined);
  };
}

/**
 * mark call failure to redux favourite store by flagging fetch data status as failure to avoid infinite loop
 */
export function failedDataStatus() {
  return (dispatch: Function, getState: () => IState) => {
    const fetchDataStatus = Constants.UserFavourite.FetchDataStatus.Failure;

    dispatch(
      createUpdateUserFavouriteFetchDataStatusAction({
        fetchDataStatus: fetchDataStatus,
      })
    );
  };
}
