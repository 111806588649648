import * as React from 'react';
import * as PropTypes from 'prop-types';
import SpzaComponent from './../spzaComponent';
import { IVideo, MediaType } from '../../Models';
import { convertTimeToSeconds, focusElement } from '../../utils/appUtils';
import { Constants } from '../../utils/constants';

let classNames = require('classnames-minimal');
let url = require('url');

export interface IVideoModal {
  video: IVideo;
  dismissModal: () => void;
}

interface IParsedUrl {
  hostname: string;
  pathname: string;
  query: any;
}

export default class VideoModal extends SpzaComponent<IVideoModal, any> {
  youtubeEmbedTemplate: string = 'https://www.youtube.com/embed/';
  vimeoEmbedTemplate: string = 'https://player.vimeo.com/video/';
  initialFocusElementId: string = 'cancelButton';

  componentDidMount() {
    // move focus to the cancel button
    // sets the document.activeElement to be the button
    focusElement(Constants.SearchBy.Id, this.initialFocusElementId);
  }

  getQueryString(query: any, exclude?: string[]): string {
    let result = '';
    const keys = query ? Object.keys(query) : [];
    const keysLength = keys.length;

    for (let i = 0; i < keysLength; i++) {
      if (exclude && exclude.indexOf(keys[i]) < 0) {
        if (keys[i] && keys[i].toLowerCase() === 't') {
          // When using youtube embed, the parameter "t" should be replaced with "start", and the value should be
          // numeric only (according to youtube api - https://developers.google.com/youtube/player_parameters)
          const startTimeInSeconds: string = isNaN(query[keys[i]])
            ? convertTimeToSeconds(query[keys[i]]).toString()
            : query[keys[i]];
          result += '&' + 'start' + '=' + startTimeInSeconds;
        } else {
          result += '&' + keys[i] + '=' + query[keys[i]];
        }
      }
    }

    return result;
  }

  parseUrl(videoUrl: string): IParsedUrl {
    let parsedUrl = url.parse(videoUrl, true);

    let hostName = '';
    let pathName = '';

    if (parsedUrl) {
      hostName = parsedUrl.hostname ? parsedUrl.hostname.toLowerCase() : hostName;
      // If the pathname doesn't start with "/", add "/" since in IE the pathname doesn't start with "/" which breaks the video url generating logic.
      pathName =
        (parsedUrl.pathname && parsedUrl.pathname.length > 0 && parsedUrl.pathname[0] !== '/' ? '/' : '') + parsedUrl.pathname;
    }

    return {
      hostname: hostName,
      pathname: pathName,
      query: parsedUrl ? parsedUrl.query : '',
    };
  }

  generateVideoSource(): string {
    let videoUrl = this.props.video.videoLink;
    let parsedUrl: IParsedUrl = this.parseUrl(videoUrl);
    let sourceUrl = '';
    if (parsedUrl.hostname.indexOf('youtube') >= 0 || parsedUrl.hostname.indexOf('youtu.be') >= 0) {
      if (
        (parsedUrl.hostname === 'www.youtube.com' || parsedUrl.hostname === 'youtube.com') &&
        parsedUrl.query !== null &&
        parsedUrl.query.v
      ) {
        sourceUrl = this.youtubeEmbedTemplate + parsedUrl.query.v + '?' + this.getQueryString(parsedUrl.query, ['v']);
      } else if (parsedUrl.hostname === 'youtu.be') {
        let pathValues = parsedUrl.pathname.split('/');
        if (pathValues.length > 1) {
          sourceUrl = this.youtubeEmbedTemplate + pathValues[1] + '?' + this.getQueryString(parsedUrl.query);
        }
      } else if (
        (parsedUrl.hostname === 'www.youtube.com' || parsedUrl.hostname === 'youtube.com') &&
        parsedUrl.pathname.indexOf('embed') >= 0
      ) {
        sourceUrl = videoUrl;

        if (sourceUrl.indexOf('?') < 0) {
          sourceUrl = sourceUrl + '?';
        }
      }

      // regardless, add the modesty params
      sourceUrl = sourceUrl + '&autoplay=1&showinfo=0&rel=0&modestbranding=1&cc_load_policy=1';
    } else if (parsedUrl.hostname.indexOf('vimeo') >= 0) {
      // we are doing a naive parse here

      const vimeoParsingRegex = /vimeo\.com\/(video\/)?(external\/)?(\d+)/;

      const matches = videoUrl.match(vimeoParsingRegex);

      const vimeoId = (matches && matches.length && matches[matches.length - 1]) || 'vimeoId';

      sourceUrl = this.vimeoEmbedTemplate + vimeoId + '?' + this.getQueryString(parsedUrl.query) + '&autoplay=1';
    }

    // our redirect
    if (parsedUrl.hostname === 'aka.ms') {
      sourceUrl = videoUrl;
    }

    return sourceUrl;
  }

  renderImpl() {
    let sourceUrl = this.generateVideoSource();
    const videoDialogClasses = classNames({
      spza_videoClass: true,
      videoDisplay: sourceUrl !== '',
    });
    return (
      <div role="dialog" aria-label="video is now open" className={videoDialogClasses}>
        <div className="dialogContent">
          <div className="toolBar">
            <button
              className="cancel"
              aria-label="close"
              onClick={this.props.dismissModal}
              data-bi-id="cancelButton"
              data-bi-name={MediaType.Video}
              autoFocus={true}
            >
              <span className="c-glyph"></span>
            </button>
          </div>
          <div className="videoContainer">
            {sourceUrl !== '' ? (
              <iframe
                title={this.props.video.videoName ? this.props.video.videoName : 'Video'}
                width="100%"
                height="100%"
                src={sourceUrl}
                frameBorder={0}
              ></iframe>
            ) : (
              <img src={this.props.video.thumbnailURL} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

(VideoModal as any).contextTypes = {
  loc: PropTypes.func,
  renderErrorModal: PropTypes.func,
};
