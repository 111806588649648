import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Spinner } from '@fluentui/react';
// #region "Integrated imports, don't add @shared"
import { getGalleryPageTitle } from '@appsource/utils/consultingServicesUtils';
import { getAppGalleryPageTitle, getFeaturedAppsTitle } from '@shared/utils/localization';
import CuratedGallery from '@shared/containers/curatedGallery';
import { FilterPane } from '@appsource/components/filterPane';
import {
  getFilterLink,
  shouldShowCuratedDataForActiveFilters,
  getPopularItems,
  getFeaturedItemsByFilter,
  isFilterChosen,
} from '@shared/utils/filterHelpers';
// #endregion
import {
  ICuratedSectionItem,
  ICuratedSections,
  IHashMap,
  IAppDataItem,
  IDataItem,
  IURLParam,
  IURLQuery,
  ILinksList,
  IBannerData,
} from '@shared/Models';
import { Constants } from '@shared/utils/constants';
import { DataMap, IDataMap, IDataValues } from '@shared/utils/dataMapping';
import { urlPush, routes } from '@shared/routerHistory';
import { removeDuplicateAddIns } from '@shared/utils/search';
import { NpsModule } from '@shared/utils/npsUtils';
import { addClassToElement, removeClassFromElements } from '@shared/utils/appUtils';
import { IBuildHrefContext, ILocContext, ICommonContext, ILocParamsContext } from '@shared/interfaces/context';
import CommonTile from '@shared/components/commonTile';

import AppsbannerImg from '@shared/images/banners/Appsbanner.png';
import CloudsIndustrybannerImg from '@shared/images/banners/CloudsIndustrybanner.png';
import ConsultingServicesImage from '@shared/images/banners/ConsultingServices.png';

import { IFeatureFlags, ServiceCountries } from '@src/State';
import * as embedHostUtils from '@src/embed/embedHostUtils';

import PureSpzaComponent from './pureSpzaComponent';
import AppSourceFilteredGallery from '@appsource/components/filteredGallery';
import GalleryHeader from './galleryHeader';
import Ribbon from './ribbon';
import AppPromotionPane from './appPromotionPane';
import { InternalLink } from './internalLink';
import { Banner } from './banner';
import { PopularApps } from './popularApps';
import GalleryContent from './GalleryContent';
import { FilteredGalleryHeader } from '@shared/components/FilteredGalleryHeader';
import { getEntityRegistration } from '@shared/utils/entityRegistration';
import { RecommendationsRibbon } from '@appsource/components/RecommendationsRibbonWrapper';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const classNames = require('classnames-minimal');

export interface IGalleryProps {
  requestFilteredLoading: boolean;
  isEmbedded: boolean;
  embedHost: string;
  showPrivateApps: boolean;
  entityData: IEntityData;
  partnerAppDataLoaded: boolean;
  partnerAppDataLoadedError: boolean;
  stateCountryFilterHandler: (countryCode: string, regionCode: string) => Promise<any>;
  updateCurrentGalleryViewIsCurated: (isCuratedGalleryView: boolean) => void;
  changeSearchText: (searchvalue: string) => void;
  galleryPage: number;
  pageSize: number;
  entityType: Constants.EntityType;
  query: IURLQuery;
  params: IURLParam;
  countryCode: string;
  featureFlags: IFeatureFlags;
  regionCode: string;
  isAppSource: boolean;
  isMobile: boolean;
  currentView: string;
  selectedLocale: string;
  linksList: ILinksList[];
  appsActiveFilters: IDataValues[];
  servicesActiveFilters: IDataValues[];
  cloudsIndustryActiveFilters: IDataValues[];
  selectedCountry: string;
  recoMicrosoft365WhatsNewItems?: IDataItem[];
  recoMicrosoft365TrendingItems?: IDataItem[];
  recoTrendingItems?: IDataItem[];
  recoWhatsNewItems?: IDataItem[];
  isMaccUser: boolean;
}

interface IGalleryTitle {
  title: string;
  mainTitle: string;
  subTitle: string;
  linkHref: string;
}

interface IEntityTypeData {
  allData: IDataItem[];
  subsetData: IDataItem[];
  dataMap: IDataMap;
  idMap: IHashMap;
  subsetSearchQuery: string;
  curatedData: ICuratedSections<ICuratedSectionItem>;
  activeFilters: IDataValues[];
  countriesList: ServiceCountries;
  count: number;
}
interface IEntityData {
  [entityType: number]: IEntityTypeData;
}

interface IGalleryEntitiesData {
  [entityType: number]: IGalleryEntityData;
}

enum ShowPopular {
  Always = 'Always',
  Never = 'Never',
  MainOnly = 'MainOnly',
  FilteredOnly = 'FilteredOnly',
}

interface IGalleryEntityData extends IEntityTypeData {
  galleryTitle: IGalleryTitle;
  bannerData: IBannerData;
  showPopoular: ShowPopular;
  showRibbon: boolean;
}

interface ITelemetryRelatedGalleryPayload {
  relatedGallery: string;
  relatedGalleryResultsCount: number;
  relatedGalleryTargetUrl: string;
}

export class Gallery extends PureSpzaComponent<IGalleryProps, any> {
  context: IBuildHrefContext & ILocContext & ICommonContext & ILocParamsContext;

  constructor(props: IGalleryProps | Readonly<IGalleryProps>) {
    super(props);
    this.state = { shouldDisplayNumricValues: false };
  }

  filterLinkCallback(filter: IDataValues, query?: IURLQuery) {
    return getFilterLink(
      this.context,
      filter,
      this.props.entityType,
      this.props.embedHost,
      false,
      !this.props.isAppSource,
      query
    );
  }

  getCurrentEntityData(): IGalleryEntityData {
    return this.getEntityData()[this.props.entityType];
  }

  getOthersEntitiesData(): IGalleryEntitiesData {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [this.props.entityType]: currentEntity, ...othersEntities } = this.getEntityData();

    return othersEntities;
  }

  getEntityData(): IGalleryEntitiesData {
    const entitiyes: IGalleryEntitiesData = {
      [Constants.EntityType.App]: {
        ...this.props.entityData[Constants.EntityType.App],
        showPopoular: ShowPopular.Always,
        showRibbon: true,
        bannerData: {
          title: this.context.loc('Banner_Apps_Title', 'Find the right app, right now'),
          imgURL: AppsbannerImg,
          description: this.context.loc(
            'Banner_Apps_Description',
            'Solve challenges with apps tailored for a wide range of industries and business needs—and begin propelling innovation and productivity today.'
          ),
        },
        galleryTitle: {
          title: this.context.loc('GalleryTitle_Apps', 'Apps'),
          mainTitle: this.context.loc('GalleryTitle_MainTitle_Default_Apps', 'Browse apps'),
          subTitle: this.context.loc('GalleryTitle_SubTitle_Default_Apps', 'Filter and sort offers to refine the results.'),
          linkHref: this.isFullscreenEmbedded() ? '' : this.context.buildHref(routes.marketplace, null, { page: '1' }),
        },
      },
      [Constants.EntityType.Service]: {
        ...this.props.entityData[Constants.EntityType.Service],
        showPopoular: ShowPopular.Always,
        showRibbon: false,
        bannerData: {
          title: this.context.loc('Banner_Services_Title', 'Find consulting services'),
          imgURL: ConsultingServicesImage,
          description: this.context.loc(
            'Banner_Services_Description',
            'Connect with experts who can help you digitally transform your organization.'
          ),
        },
        galleryTitle: {
          title: this.context.loc('GalleryTitle_CS', 'Consulting services'),
          mainTitle: this.context.loc('GalleryTitle_MainTitle_Default_CS', 'Browse consulting services'),
          subTitle: this.context.loc(
            'GalleryTitle_SubTitle_Default_CS',
            'Refine offers by selecting filters and sorting options.'
          ),
          linkHref: this.context.buildHref(routes.marketplaceServices, null, { page: '1' }),
        },
      },
      [Constants.EntityType.CloudsIndustry]: {
        ...this.props.entityData[Constants.EntityType.CloudsIndustry],
        showPopoular: ShowPopular.MainOnly,
        showRibbon: true,
        bannerData: {
          title: this.context.loc('Banner_CloudsIndustry_Title', 'Microsoft Industry Clouds'),
          imgURL: CloudsIndustrybannerImg,
          description: this.context.loc(
            'Banner_CloudsIndustry_Description',
            'Microsoft Industry Clouds connect robust industry-specific capabilities across Azure, Power Platform, Microsoft 365 & Teams, Dynamics 365, and Security, underpinned by our common data model.  Helping businesses be more agile, make intelligent decisions, and provide rich customer, patient, and employee experiences.'
          ),
          links: [
            {
              text: this.context.loc('AppSource_banner_explore', 'Explore Microsoft Industry Clouds'),
              params: ['Microsoft Industry Clouds'],
              url: 'https://www.microsoft.com/industry',
            },
          ],
        },
        galleryTitle: {
          title: this.context.loc('GalleryTitle_CloudsIndustry', 'Industry Clouds'),
          mainTitle: this.context.loc('GalleryTitle_MainTitle_Default_CloudsIndustry', 'Browse Industry Clouds'),
          subTitle: this.context.loc(
            'GalleryTitle_SubTitle_Default_CloudsIndustry',
            'Filter and sort apps to refine the results.'
          ),
          linkHref: this.context.buildHref(routes.marketplaceCloudsIndustry, null, { page: '1' }),
        },
      },
    };

    return entitiyes;
  }

  isSearchMode(entityType: Constants.EntityType, entityData: IEntityData): boolean {
    if (!entityType) {
      return undefined;
    }

    const searchQuery = entityData[`${entityType}`].subsetSearchQuery;

    return !!searchQuery && searchQuery.length > 0;
  }

  shouldShowCuratedView(entityType: Constants.EntityType, entityData: IEntityData, query: IURLQuery): boolean {
    return (
      entityType &&
      !this.isSearchMode(entityType, entityData) &&
      !query[Constants.QueryStrings.showAllApps] &&
      shouldShowCuratedDataForActiveFilters(entityData[`${entityType}`].activeFilters) &&
      !this.props.showPrivateApps &&
      (!this.props.isEmbedded || embedHostUtils.shouldShowCuratedData(this.props.embedHost)) &&
      (entityType !== Constants.EntityType.Service || this.shouldShowCuratededViewServices())
    );
  }

  shouldShowCuratededViewServices(): boolean {
    if (
      this.getCurrentEntityData()?.activeFilters.filter(
        (activeFilter) => activeFilter.BackendKey === DataMap.products.Office365.BackendKey
      ).length
    ) {
      return false;
    }

    if (this.props.countryCode?.toLowerCase() !== Constants.usCountryCode) {
      return false;
    }

    if (this.props.regionCode?.toLowerCase() !== Constants.allStatesCode) {
      return false;
    }

    return true;
  }

  startUp() {
    // accessibility
    switch (this.props.entityType) {
      case Constants.EntityType.Service:
        document.getElementsByTagName('title')[0].innerHTML = this.context.loc(getGalleryPageTitle());
        removeClassFromElements('spza-accessibility-selected');

        // the bold on the UHF wont work till the new UHF header for services is added, thus highlighting the services
        addClassToElement(Constants.SearchBy.Id, 'uhf_servicesGalleryLink', 'spza-accessibility-selected');
        // Ensure country selection if needed.
        break;
      default:
        document.getElementsByTagName('title')[0].innerHTML = getAppGalleryPageTitle(this.context);
        removeClassFromElements('spza-accessibility-selected');
        addClassToElement(Constants.SearchBy.Id, 'uhf_galleryLink', 'spza-accessibility-selected');
        break;
    }
  }

  isAppliedFiltersEmpty() {
    return !this.getCurrentEntityData()?.activeFilters.length && !this.getCurrentEntityData()?.subsetSearchQuery;
  }

  manageShouldDisplayNumricValues() {
    if (this.isAppliedFiltersEmpty()) {
      this.setState({
        shouldDisplayNumricValues: false,
      });
    } else if (!this.props.requestFilteredLoading) {
      this.setState({
        shouldDisplayNumricValues: true,
      });
    }
  }

  componentDidMount() {
    // step 4: determine whether gallery view should show curated view, on the very first render
    const shouldShowCuratedView = this.shouldShowCuratedView(this.props.entityType, this.props.entityData, this.props.query);
    this.props.updateCurrentGalleryViewIsCurated(shouldShowCuratedView);
    NpsModule.checkForNPS();
    this.startUp();
  }

  componentDidUpdate() {
    this.startUp();
    this.manageShouldDisplayNumricValues();
  }

  UNSAFE_componentWillReceiveProps(nextProps: IGalleryProps) {
    // get shouldShowCuratedView consists of current props
    const shouldShowCuratedView = this.shouldShowCuratedView(this.props.entityType, this.props.entityData, this.props.query);

    // get shouldShowCuratedView consists of next props
    const nextShouldShowCuratedView = this.shouldShowCuratedView(nextProps.entityType, nextProps.entityData, nextProps.query);

    // update only if shouldShowCuratedView changes
    // without this check, it will in an infinite loop of re-rendering
    if (shouldShowCuratedView !== nextShouldShowCuratedView) {
      this.props.updateCurrentGalleryViewIsCurated(nextShouldShowCuratedView);
    }
  }

  getGalleryMainTitle(): string {
    if (this.getCurrentEntityData().subsetSearchQuery) {
      return this.context.locParams(
        'GalleryTitle_MainTitle_Search',
        [this.getCurrentEntityData().subsetSearchQuery],
        'Search results for “{0}”'
      );
    }

    const activeFiltersWithoutReference = this.getActiveFiltersWithoutReference();

    if (activeFiltersWithoutReference.length >= 1) {
      const firstFilterName = this.context.loc(activeFiltersWithoutReference[0].LocKey, activeFiltersWithoutReference[0].Title);

      return activeFiltersWithoutReference.length === 1
        ? this.context.locParams(
            'GalleryTitle_MainTitle_OneFilter',
            [this.getCurrentEntityData().galleryTitle.title, firstFilterName],
            '{0} results for {1}'
          )
        : this.context.locParams(
            'GalleryTitle_MainTitle_MultipleFilter',
            [this.getCurrentEntityData().galleryTitle.title],
            '{0} results'
          );
    }

    return this.getCurrentEntityData().galleryTitle.mainTitle;
  }

  getGallerySubTitle(): string {
    const curEntityData = this.getCurrentEntityData();
    const curEntityCount = curEntityData.count;
    const { shouldDisplayNumricValues } = this.state;

    if (curEntityCount <= 0) {
      return this.context.locParams(
        'GalleryTitle_No_Results',
        [curEntityCount?.toString() || '', curEntityData.galleryTitle.title.toLocaleLowerCase()],
        'Showing 0 results.'
      );
    }
    if ((this.getActiveFiltersWithoutReference().length || curEntityData.subsetSearchQuery) && curEntityCount) {
      return this.context.locParams(
        'GalleryTitle_Results',
        [shouldDisplayNumricValues ? curEntityCount.toString() : '', curEntityData.galleryTitle.title.toLocaleLowerCase()],
        'Showing {0} results in {1}.'
      );
    }

    return curEntityData.galleryTitle.subTitle;
  }

  getActiveFiltersWithoutReference() {
    return this.getCurrentEntityData().activeFilters.filter((f: IDataValues) => !f.IsReference);
  }

  renderOthersGalleriesLinks(): JSX.Element[] {
    if (!this.getActiveFiltersWithoutReference().length) {
      return null;
    }

    const { shouldDisplayNumricValues } = this.state;

    const entitiesData = this.getOthersEntitiesData();
    const results: JSX.Element[] = [];

    Object.keys(entitiesData)
      .filter((entityType) => (entitiesData[`${entityType}`] as IGalleryEntityData).count)
      .forEach((entityType, index, entities) => {
        const entityData: IGalleryEntityData = entitiesData[`${entityType}`];
        const href = entityData.galleryTitle.linkHref;
        const galleryTitle = entityData.galleryTitle.title.toLocaleLowerCase();
        const galleryResultsCount = entityData.count;
        const telemetryPayload: ITelemetryRelatedGalleryPayload = {
          relatedGallery: galleryTitle,
          relatedGalleryResultsCount: galleryResultsCount,
          relatedGalleryTargetUrl: href,
        };

        if (index === 0) {
          results.push(<span key={`span-${href}`}> {this.context.loc('GalleryTitle_Link_Pre', 'View')} </span>);
        } else {
          results.push(<span key={`span-${href}`}> {this.context.loc('GalleryTitle_Link_Seperator', 'or')} </span>);
        }

        const numResultsText = shouldDisplayNumricValues ? galleryResultsCount?.toString() || '' : '';
        results.push(
          <InternalLink
            key={`internal-link-${href}`}
            href={href}
            className="galleryTitleLink"
            accEnabled
            data-bi-id={'gallery Title link'}
            data-bi-area={`entity: ${this.props.entityType}`}
            onClick={() => {
              urlPush(entityData.galleryTitle.linkHref, true);
            }}
            telemetryDetails={JSON.stringify(telemetryPayload)}
          >
            {this.context.locParams(
              'GalleryTitle_Link',
              [numResultsText, galleryTitle],
              `${numResultsText} related results in ${galleryTitle}`
            )}
          </InternalLink>
        );

        if (index === entities.length - 1) {
          results.push(<span key="dot">.</span>);
        }
      });

    return results;
  }

  isShowPopularItems() {
    const activeFilters = this.getActiveFiltersWithoutReference();

    const showPopular = this.getCurrentEntityData().showPopoular;

    if (
      showPopular === ShowPopular.Never ||
      (showPopular === ShowPopular.MainOnly && activeFilters?.length !== 0) ||
      (showPopular === ShowPopular.FilteredOnly && activeFilters?.length !== 1)
    ) {
      return false;
    }

    if (this.isSearchMode(this.props.entityType, this.props.entityData)) {
      return false;
    }

    if (!this.props.isAppSource) {
      return false;
    }

    if (this.props.isEmbedded) {
      return false;
    }

    if (activeFilters?.length > 1) {
      return false;
    }

    if (
      activeFilters.length === 1 &&
      [Constants.FilterQuery.product, Constants.FilterQuery.category, Constants.FilterQuery.industry].indexOf(
        activeFilters[0].UrlGroup
      ) === -1
    ) {
      return false;
    }

    return true;
  }

  getRibbonKey = (): string => {
    const activeFilters = this.getActiveFiltersWithoutReference();
    return activeFilters.length ? `CuratedType_Most_Viewed ${activeFilters[0].UrlKey}` : 'CuratedType_Most_Viewed';
  };

  getPopularItemsTitle(): string {
    const activeFilters = this.getActiveFiltersWithoutReference();

    return activeFilters.length
      ? this.context.locParams(
          'CuratedType_Most_Viewed_Title_In',
          [activeFilters[0].Title],
          `Most viewed in ${[activeFilters[0].Title]}`
        )
      : this.context.loc('CuratedType_Most_Viewed', 'Most Viewed');
  }

  shouldRenderBanner(): boolean {
    return (
      this.props.isAppSource &&
      !this.props.isEmbedded &&
      !this.getCurrentEntityData().subsetSearchQuery &&
      this.getCurrentEntityData().activeFilters.length === 0
    );
  }

  renderBannner(): JSX.Element {
    return this.shouldRenderBanner() ? <Banner data={this.getCurrentEntityData().bannerData} /> : null;
  }

  isFullscreenEmbedded(): boolean {
    return embedHostUtils.shouldShowCuratedData(this.props.embedHost) && embedHostUtils.isFullScreenMode(this.props.query);
  }

  getRibbonItems(): IDataItem[] {
    return getFeaturedItemsByFilter(
      this.getCurrentEntityData().activeFilters,
      this.getCurrentEntityData().curatedData,
      this.getCurrentEntityData().idMap,
      this.getCurrentEntityData().allData
    ).slice(0, 5);
  }

  getFilteredItems(): IDataItem[] {
    const ribbonItems = this.getRibbonItems();

    const filteredItems = removeDuplicateAddIns<IDataItem>(this.getCurrentEntityData().allData, 'entityId').apps.filter((app) =>
      ribbonItems.every((ribbonItem) => app.entityId !== ribbonItem.entityId)
    );

    return filteredItems;
  }

  getPopularItems(): IDataItem[] {
    if (!this.isShowPopularItems()) {
      return [];
    }

    return getPopularItems(this.getFilteredItems(), this.getRibbonItems()).slice(0, 5);
  }

  renderPopularRibbon(): JSX.Element {
    const popularItems = this.getPopularItems();

    return popularItems.length ? (
      <PopularApps
        popularAppsTitle={this.getPopularItemsTitle()}
        ribbonPopularApps={popularItems}
        ribbonKey={this.getRibbonKey()}
      ></PopularApps>
    ) : null;
  }

  renderRecommendationsRibbons(): JSX.Element {
    const {
      isAppSource,
      entityType,
      selectedLocale,
      countryCode,
      recoMicrosoft365WhatsNewItems,
      recoMicrosoft365TrendingItems,
      entityData,
      query,
      recoTrendingItems,
      recoWhatsNewItems,
    } = this.props;
    const TileType = getEntityRegistration(entityType).tileType;

    const isOfficeFilter = query?.product === Constants.FilteredGallery.officeFilter;
    const hasNoActiveFilters = entityData[`${entityType}`].activeFilters.length === 0;
    const isFirstPage = !query.page || query.page === '1';
    const hasSearch = query?.search;

    return (
      ((isOfficeFilter && entityType === Constants.EntityType.App) || (hasNoActiveFilters && isFirstPage)) &&
      (entityType === Constants.EntityType.App || entityType === Constants.EntityType.Service) &&
      isAppSource &&
      !hasSearch && (
        <>
          <RecommendationsRibbon
            recommendedItems={isOfficeFilter ? recoMicrosoft365TrendingItems : recoTrendingItems}
            title={this.context.loc('CuratedType_Trending_Now', 'Trending Now')}
            ribbonKey={Constants.Recommendations.TrendingMicrosoft365}
            selectedCountry={countryCode}
            selectedLocale={selectedLocale}
            tileType={TileType}
          />

          <RecommendationsRibbon
            recommendedItems={isOfficeFilter ? recoMicrosoft365WhatsNewItems : recoWhatsNewItems}
            title={this.context.loc('CuratedType_Whats_New', 'Whats New')}
            ribbonKey={Constants.Recommendations.WhatsNewMicrosoft365}
            selectedCountry={countryCode}
            selectedLocale={selectedLocale}
            tileType={TileType}
          />
        </>
      )
    );
  }

  renderCuratedGallery(): JSX.Element {
    return (
      <div>
        {this.renderBannner()}
        <div
          className={classNames({
            curatedGalleryWrapper:
              this.getCurrentEntityData().activeFilters.length === 0 && !this.getCurrentEntityData().subsetSearchQuery,
          })}
        >
          <CuratedGallery
            entityType={this.props.entityType}
            dataMap={this.getCurrentEntityData().dataMap}
            category={this.props.params.category || this.props.query}
            activeFilters={this.getCurrentEntityData().activeFilters}
            searchText={this.getCurrentEntityData().subsetSearchQuery}
            embedHost={this.props.embedHost}
          />
        </div>
      </div>
    );
  }

  shouldRenderRibbonItems(): boolean {
    return this.getCurrentEntityData().showRibbon && !this.props.isEmbedded;
  }

  renderFeaturedRibbon(): JSX.Element {
    const ribbonItems = this.getRibbonItems();

    const featuredRibbonKey = getFeaturedAppsTitle();
    const ribbonTitle = this.context.locParams(featuredRibbonKey, [this.getCurrentEntityData().activeFilters[0]?.Title]);
    const ribbonKey = `${featuredRibbonKey} ${this.getCurrentEntityData().activeFilters[0]?.BackendKey}`;

    return this.shouldRenderRibbonItems() && ribbonItems.length ? (
      <div className="indFeaturedContainer">
        <Ribbon title={ribbonTitle}>
          {ribbonItems.map((item: IAppDataItem, index: number) => (
            <CommonTile
              {...item}
              item={item}
              tileIndex={index}
              totalTiles={ribbonItems.length}
              key={index}
              ribbonKey={ribbonKey}
            />
          ))}
        </Ribbon>
      </div>
    ) : null;
  }

  renderFilteredGallery(): JSX.Element {
    const filteredItems = this.getFilteredItems();
    const {
      query,
      galleryPage,
      pageSize,
      embedHost,
      partnerAppDataLoaded,
      partnerAppDataLoadedError,
      entityType,
      isMobile,
      countryCode,
      changeSearchText,
      isEmbedded,
      selectedLocale,
      showPrivateApps,
      currentView,
      appsActiveFilters,
      servicesActiveFilters,
      cloudsIndustryActiveFilters,
      recoMicrosoft365WhatsNewItems,
      recoMicrosoft365TrendingItems,
    } = this.props;

    return (
      <>
        {this.renderBannner()}
        {this.renderRecommendationsRibbons()}
        {this.getCurrentEntityData().count ? (
          <div className="allResults">{this.context.loc('FilteredGallery_AllResults', 'All results')}</div>
        ) : null}
        <AppSourceFilteredGallery
          query={query}
          galleryPage={galleryPage}
          pageSize={pageSize}
          filteredData={filteredItems}
          count={this.getCurrentEntityData().count}
          searchText={this.getCurrentEntityData().subsetSearchQuery}
          showPrivateApps={showPrivateApps}
          isEmbedded={isEmbedded}
          embedHost={embedHost}
          partnerAppDataLoaded={partnerAppDataLoaded}
          partnerAppDataLoadedError={partnerAppDataLoadedError}
          entityType={entityType}
          isMobile={isMobile}
          selectedLocale={selectedLocale}
          selectedCountry={countryCode}
          isSelectedFilters={isFilterChosen(currentView, appsActiveFilters, servicesActiveFilters, cloudsIndustryActiveFilters)}
          changeSearchText={changeSearchText}
          microsoft365WhatsNewItems={recoMicrosoft365WhatsNewItems}
          microsoft365TrendingItems={recoMicrosoft365TrendingItems}
        />
      </>
    );
  }

  renderLoading(): JSX.Element {
    return (
      <div className="loading">
        <Spinner label={this.context.loc('Gallery_Loading', 'Loading...')} ariaLive="assertive" labelPosition="left" />
      </div>
    );
  }

  renderPromotionPane(): JSX.Element {
    return (
      <AppPromotionPane
        entityType={this.props.entityType}
        activeFilters={this.getCurrentEntityData().activeFilters}
        searchText={this.getCurrentEntityData().subsetSearchQuery}
        isEmbedded={this.props.isEmbedded}
      />
    );
  }

  renderHeader(): JSX.Element {
    const { isAppSource, isEmbedded, entityType, entityData, currentView, query } = this.props;

    const shouldHideSortingDropdown = !this.isSearchMode(entityType, entityData);

    return isAppSource ? (
      <>
        <FilteredGalleryHeader
          localizedTitle={this.getGalleryMainTitle()}
          subTitle={this.getGallerySubTitle()}
          otherGalleriesLinks={this.renderOthersGalleriesLinks()}
          numResults={this.getCurrentEntityData().count}
          hideSortingDropdown={shouldHideSortingDropdown}
        />
        <GalleryHeader
          activeFilters={this.getCurrentEntityData().activeFilters}
          searchText={this.getCurrentEntityData().subsetSearchQuery}
          getFilterLink={this.filterLinkCallback.bind(this)}
          isEmbedded={isEmbedded}
          entityType={entityType}
          currentView={currentView}
          urlQuery={query}
        />
      </>
    ) : null;
  }

  renderImpl() {
    const {
      showPrivateApps,
      isEmbedded,
      embedHost,
      entityType,
      stateCountryFilterHandler,
      countryCode,
      regionCode,
      params,
      query,
      currentView,
      requestFilteredLoading,
      entityData,
      isMaccUser,
    } = this.props;

    if (!entityType) {
      return <div></div>;
    }

    const hideFilterPane = isEmbedded && (embedHostUtils.shouldHideFilterPane(embedHost) || showPrivateApps);
    const galleryContainerClass = classNames({
      spza_content: true,
      spza_content_hideFilterPane: hideFilterPane,
    });

    return (
      <div>
        <div className="spza_galleryContainer" role="main">
          <div className={galleryContainerClass}>
            <div className="paneAndGalleryWrapper">
              {hideFilterPane ? null : (
                <FilterPane
                  dataMap={this.getCurrentEntityData().dataMap}
                  activeFilters={this.getCurrentEntityData().activeFilters}
                  getFilterLink={this.filterLinkCallback.bind(this)}
                  embedHost={embedHost}
                  isEmbedded={isEmbedded}
                  entityType={entityType}
                  stateCountryFilterHandler={stateCountryFilterHandler}
                  countryCode={countryCode.toUpperCase()}
                  regionCode={regionCode}
                  params={params}
                  query={query}
                  currentView={currentView}
                  countriesList={this.getCurrentEntityData().countriesList}
                  isMaccUser={isMaccUser}
                />
              )}
              <div
                className="gallery"
                // eslint-disable-next-line react/forbid-dom-props
                id="maincontent"
                tabIndex={-1}
              >
                <GalleryContent loadingComponent={this.renderLoading()} requestFilteredLoading={requestFilteredLoading}>
                  {this.renderPromotionPane()}
                  {this.renderHeader()}
                  {this.shouldShowCuratedView(entityType, entityData, query)
                    ? this.renderCuratedGallery()
                    : this.renderFilteredGallery()}
                </GalleryContent>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

(Gallery as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  buildHref: PropTypes.func,
  renderErrorModal: PropTypes.func,
};
