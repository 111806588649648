import * as React from 'react';
import { DefaultButton, Dialog, DialogFooter, PrimaryButton, Spinner, SpinnerSize, Text } from '@fluentui/react';
import * as PropTypes from 'prop-types';
import { ILocContext, ICommonContext, ILocParamsContext } from '@shared/interfaces/context';
import { ITelemetryData, IReviewPayload } from '@shared/Models';
import { IReviewsState, IUserDataState } from '../../../State';
import { Constants } from '@shared/utils/constants';
import SpzaComponent from '../spzaComponent';
import { SpzaInstrumentService } from '../../services/telemetry/spza/spzaInstrument';
import { getWindow } from '@shared/services/window';
import { ReviewActionModal } from './reviewActionModal';
import successGreenV from '@shared/images/successGreenV.svg';
import redExclamationMark from '@shared/images/redExclamationMark.svg';
import { SubmitReviewResponseModal } from './submitReviewResponseModal';
import { getReviewModalDialogProps } from '@shared/utils/reviewsUtils';
import { logger } from '@src/logger';
import { UserReviewFormData } from '@shared/interfaces/reviews/models';

export interface IRatingModalProps {
  payload: IReviewPayload;
  userInfo: IUserDataState;
  reviewsData: IReviewsState;
  dismissModal: () => void;
  setUserReviewStatus: (hasReview: boolean) => void;
  submitReview: (review: UserReviewFormData) => Promise<void>;
  updateReview: (review: UserReviewFormData) => Promise<void>;
  deleteReview: (review: UserReviewFormData) => Promise<void>;
}

export interface IRatingState {
  showMode?: Constants.RatingShowMode;
  rating?: number;
  description?: string;
  isEdit?: boolean;
  title?: string;
  submitted?: string;
  consent?: boolean;
  showAnonymous?: boolean;
}

export class RatingModal extends SpzaComponent<IRatingModalProps, IRatingState> {
  context: ILocContext & ICommonContext & ILocParamsContext;
  auth: string;
  iconURL: string;
  accessKey: string;
  instrument = SpzaInstrumentService.getProvider();

  constructor(props: IRatingModalProps, context: ILocContext & ICommonContext & ILocParamsContext) {
    super(props, context);
    this.context = context;

    this.initMode();

    this.state = {
      showMode: Constants.RatingShowMode.Loading,
      rating: null,
      description: '',
      isEdit: false,
      title: '',
      submitted: '',
      consent: false,
      showAnonymous: false,
    };
  }

  initMode() {
    if (this.props.payload.accessKey) {
      this.auth = Constants.Headers.AccessKey;
      this.accessKey = this.props.payload.accessKey;
    } else {
      if (this.props.userInfo.signedIn) {
        this.auth = Constants.Headers.Authorization;
        this.accessKey = this.props.userInfo.accessToken.spza;
      } else {
        this.closeModal();
      }
    }

    if (this.props.payload.app.detailInformation?.LargeIconUri) {
      this.iconURL = this.props.payload.app.detailInformation.LargeIconUri;
    } else {
      this.iconURL = this.props.payload.app.iconURL;
    }
  }

  componentDidMount() {
    const reviewsData = this.props.reviewsData;
    const userReview = reviewsData.userReview;

    if (userReview) {
      this.setState({
        showMode: Constants.RatingShowMode.EditReview,
        rating: userReview.rating,
        title: userReview.title || '',
        description: userReview.content || '',
        submitted: userReview.updated_at,
        isEdit: true,
        consent: false,
        showAnonymous: userReview.customer_info?.is_anonymous ?? false,
      });
    } else {
      this.setState({
        showMode: Constants.RatingShowMode.WriteNewReview,
        isEdit: false,
      });
    }
  }

  getReviewFormData(): UserReviewFormData {
    const { userReview } = this.props.reviewsData;
    return {
      offerId: this.props.payload.app.entityId,
      rating: this.state.rating,
      title: this.state.title,
      content: this.state.description,
      sendEmailConsent: this.state.consent,
      isAnonymous: this.state.showAnonymous,
      reviewId: userReview?.id,
    };
  }

  handleSubmit() {
    this.setState({
      showMode: Constants.RatingShowMode.Loading,
    });
    const reviewData = this.getReviewFormData();
    const { isEdit } = this.state;
    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.RRSubmit,
      appName: this.props.payload.app.entityId,
      details: `${
        isEdit
          ? `A review of the app ${this.props.payload.app.entityId} was updated`
          : `A new review was inserted to the app ${this.props.payload.app.entityId}`
      }`,
    };
    this.instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
      appName: payload.appName,
    });

    const submitOperation$ = isEdit ? this.props.updateReview(reviewData) : this.props.submitReview(reviewData);

    submitOperation$
      .then(() => {
        this.setState({
          showMode: Constants.RatingShowMode.Success,
        });
        if (this.auth === Constants.Headers.Authorization) {
          this.props.setUserReviewStatus(true);
        }
        this.props.payload.callback('success');
      })
      .catch((err) => {
        this.handleError(err);
      });
  }

  handleDelete() {
    this.setState({
      showMode: Constants.RatingShowMode.Loading,
    });
    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.RRDelete,
      appName: this.props.payload.app.entityId,
    };
    this.instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', payload);
    logger.info('', {
      action: payload.action,
      actionModifier: payload.actionModifier,
      appName: payload.appName,
    });

    this.props
      .deleteReview(this.getReviewFormData())
      .then(() => {
        this.setState({
          showMode: Constants.RatingShowMode.DeleteSuccess,
        });
        this.props.payload.callback('success');
      })
      .catch((err) => {
        this.handleError(err);
      });
  }

  handleError(err: any) {
    if (err.response?.statusCode === 400 && err.response?.text === Constants.ReviewsEnrichApiResponse.ContentViolation) {
      this.setState({
        showMode: Constants.RatingShowMode.ContentViolation,
      });
    } else {
      this.setState({
        showMode: Constants.RatingShowMode.Error,
      });
    }

    const errorStatusCode = err.response?.statusCode?.toString();
    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.RRSubmit,
      details: `RatingModal got an error from the server. status code: ${errorStatusCode}, message: ${err.response?.text}.`,
    };

    this.instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });
  }

  closeModal() {
    this.props.dismissModal();
  }

  handleRatingChange(ratingNumber: number) {
    this.setState({
      rating: ratingNumber,
    });
  }

  onChangeReviewTitle(titleText: string) {
    this.setState({
      title: titleText,
    });
  }

  onChangeReviewContent(contentText: string) {
    this.setState({
      description: contentText,
    });
  }

  setEnsureDeleteShowMode() {
    this.setState({
      showMode: Constants.RatingShowMode.EnsureDelete,
    });
  }

  onChangeConsent() {
    this.setState({
      consent: !this.state.consent,
    });
  }

  onChangeDisplayName() {
    this.setState({
      showAnonymous: !this.state.showAnonymous,
    });
  }

  renderImpl() {
    if (this.state.showMode === Constants.RatingShowMode.Error) {
      return (
        <SubmitReviewResponseModal
          context={this.context}
          title={this.context.loc('ReviewActionModal_ErrorDialogTitle', "Your review wasn't published")}
          content={this.context.loc(
            'ReviewActionModal_ErrorDialogText',
            'There was a problem publishing the review because of technical issue.\nTry posting your review again.'
          )}
          dialogIcon={redExclamationMark}
          shouldRenderLink={false}
          closeModal={this.closeModal.bind(this)}
        />
      );
    }
    if (this.state.showMode === Constants.RatingShowMode.ContentViolation) {
      return (
        <SubmitReviewResponseModal
          context={this.context}
          title={this.context.loc('ReviewActionModal_ErrorContentViolationDialogTitle', "Your review couldn't be published")}
          content={this.context.loc(
            'ReviewActionModal_ErrorContentViolationDialogText',
            "We couldn't publish your review because we detected some offensive content\nwhich violates Microsoft terms."
          )}
          dialogIcon={redExclamationMark}
          shouldRenderLink={true}
          closeModal={this.closeModal.bind(this)}
        />
      );
    }
    if (this.state.showMode === Constants.RatingShowMode.Success) {
      return (
        <SubmitReviewResponseModal
          context={this.context}
          title={this.context.loc('ReviewActionModal_SuccessDialogTitle', 'Thanks for your review')}
          content={this.context.loc(
            'ReviewActionModal_SuccessDialogText',
            'Thanks for taking the time to give your feedback.\nYour rating will be added to the average app rating shortly.'
          )}
          dialogIcon={successGreenV}
          shouldRenderLink={false}
          closeModal={this.closeModal.bind(this)}
        />
      );
    }
    if (this.state.showMode === Constants.RatingShowMode.DeleteSuccess) {
      return (
        <SubmitReviewResponseModal
          context={this.context}
          title={this.context.loc('ReviewActionModal_DeleteSuccessDialogTitle', 'Your review has been deleted')}
          content={this.context.loc('ReviewActionModal_DeleteSuccessDialogText', 'We removed your review from the feed.')}
          dialogIcon={successGreenV}
          shouldRenderLink={false}
          closeModal={this.closeModal.bind(this)}
        />
      );
    }
    if (this.state.showMode === Constants.RatingShowMode.WriteNewReview) {
      return (
        <ReviewActionModal
          context={this.context}
          title={this.context.loc('ReviewActionModal_WriteBoxTitle', 'Review this app')}
          appTitle={this.props.payload.app.title}
          appPublisher={this.props.payload.app.publisher}
          userEmail={this.props.userInfo.email}
          userDisplayName={this.props.userInfo.displayName}
          appIcon={this.iconURL}
          curRating={this.state.rating}
          curReviewTitle={this.state.title}
          curReviewContent={this.state.description}
          isAnonymous={this.state.showAnonymous}
          userReview={this.props.reviewsData.userReview}
          closeModal={this.closeModal.bind(this)}
          handleRatingChange={this.handleRatingChange.bind(this)}
          handleReviewTitleChange={this.onChangeReviewTitle.bind(this)}
          handleReviewContentChange={this.onChangeReviewContent.bind(this)}
          handleSendEmailCheckbox={this.onChangeConsent.bind(this)}
          handleDisplayUserNameCheckbox={this.onChangeDisplayName.bind(this)}
          handleSubmitReview={this.handleSubmit.bind(this)}
          handleDeleteReview={this.setEnsureDeleteShowMode.bind(this)}
        />
      );
    }
    if (this.state.showMode === Constants.RatingShowMode.EditReview) {
      return (
        <ReviewActionModal
          context={this.context}
          title={this.context.loc('ReviewActionModal_EditBoxTitle', 'Edit your review')}
          appTitle={this.props.payload.app.title}
          appPublisher={this.props.payload.app.publisher}
          userEmail={this.props.userInfo.email}
          userDisplayName={this.props.userInfo.displayName}
          appIcon={this.iconURL}
          curRating={this.state.rating}
          curReviewTitle={this.state.title}
          curReviewContent={this.state.description}
          isAnonymous={this.state.showAnonymous}
          userReview={this.props.reviewsData.userReview}
          closeModal={this.closeModal.bind(this)}
          handleRatingChange={this.handleRatingChange.bind(this)}
          handleReviewTitleChange={this.onChangeReviewTitle.bind(this)}
          handleReviewContentChange={this.onChangeReviewContent.bind(this)}
          handleSendEmailCheckbox={this.onChangeConsent.bind(this)}
          handleDisplayUserNameCheckbox={this.onChangeDisplayName.bind(this)}
          handleSubmitReview={this.handleSubmit.bind(this)}
          handleDeleteReview={this.setEnsureDeleteShowMode.bind(this)}
        />
      );
    }
    if (this.state.showMode === Constants.RatingShowMode.EnsureDelete) {
      const dialogProps = getReviewModalDialogProps(
        this.context.loc('ReviewActionModal_EnsureDeleteTitle', 'Delete your review?')
      );
      dialogProps.modalProps.styles.main.minHeight = 150;
      return (
        <Dialog
          hidden={false}
          onDismiss={() => this.closeModal()}
          dialogContentProps={dialogProps.dialogContentProps}
          maxWidth={dialogProps.maxWidth}
          modalProps={dialogProps.modalProps}
        >
          <Text className="ResponeDialogContent">
            {this.context.loc(
              'ReviewActionModal_EnsureDeleteDialogText',
              "It won't appear in the reviews feed and won't be available any longer."
            )}
          </Text>
          <DialogFooter>
            <PrimaryButton onClick={() => this.handleDelete()} text={this.context.loc('Rating_DeleteButton', 'Delete')} />
            <DefaultButton onClick={() => this.closeModal()} text={this.context.loc('Cancel', 'Cancel')} />
          </DialogFooter>
        </Dialog>
      );
    }

    return (
      <Spinner
        className="ratingModalLoading"
        size={SpinnerSize.large}
        label={this.context.loc('AppDetail_LoadingText', 'Loading...')}
        ariaLive="assertive"
      />
    );
  }
}

(RatingModal as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
};
