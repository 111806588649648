import React from 'react';
import * as PropTypes from 'prop-types';
import Animation from '@shared/components/animation';
import SpzaComponent from '@shared/components/spzaComponent';
import {
  dynamicsLicenseTypeForEmbed,
  setInitialLocalLicenseStore,
  setInitialLicenseDisplayText,
  isTransactApp,
  getTrailIdAndData,
  dynamicsLicenseType,
  readUserProfileEmbedCookie,
  getAppTelemetryDetailContentText,
  getServiceTelemetryDetailContentText,
  shouldDownloadPowerBIVisual,
  getActionStringForCTAType,
  isPowerBIVisuals,
  processHandoffURLAndTitle,
  isOpenInNewWindowButton,
  openInNewWindow,
  getProductByUrlKey,
  generateGuid,
} from '@shared/utils/appUtils';
import {
  ILicense,
  IAppDataItem,
  ITelemetryData,
  IAppDetailInformation,
  IUserLeadProfileAgreement,
  SendLeadInfoOptions,
} from '@shared/Models';
import { Constants, OfferType } from '@shared/utils/constants';
import { getLocalStorageItem, getCookieItem } from '@shared/utils/browserStorageUtils';
import { postEmbedAcquisitionMessage } from '@embed/embedMessaging';
import { SpzaInstrumentProvider, SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { NpsModule } from '@shared/utils/npsUtils';
import { Service } from '@shared/serviceViewModel';
import { UserProfileModal } from '@appsource/containers/modals/userProfileModal';
import { IFeatureFlags, IModalState, IUserDataState } from '@src/State';
import { isLeadgenEnabled, isETERunning } from '@shared/utils/modals';
import { urlPush, routes, WithRouterProps } from '@shared/routerHistory';
import { IBuildHrefContext, ICommonContext, ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { getPrimaryProductUrl } from '@shared/utils/datamappingHelpers';
import { getNpsModule } from '@appsource/utils/nps';
import { logger } from '@src/logger';
import {
  Stack,
  Text,
  mergeStyleSets,
  PrimaryButton,
  Icon,
  ScreenWidthMinXLarge,
  ScreenWidthMinLarge,
  TextField,
} from '@fluentui/react';
import type { IModalStyles, IStackTokens, IIconStyles } from '@fluentui/react';
import { NeutralColors, CommunicationColors } from '@fluentui/theme';
import { TelemetryImage } from '@shared/components/telemetryImage';
import classNames from 'classnames';
import { SuccessConsentModal } from '@appsource/components/modals/successConsentModal';
import { FailureConsentModal } from '@appsource/components/modals/failureConsentModal';
import { SafeHtmlWrapper } from '@shared/components/safeHtmlWrapper';
import { ConsentModalContent } from './consentModalContent';
import { getTooltipSilentLogIn } from '@shared/utils/silentLogInUtils';
import { useTelemetry } from '@shared/hooks/useTelemetry';
import { containsURLOrEmail } from '@shared/utils/InputValidationUtils';
import { openInNewWindowButtonStyles } from '@shared/components/contentStyles';
import { hidePowerBiVisualPricing } from '@shared/utils/pricing';
import { stringifyError } from '@shared/utils/errorUtils';

// eslint-disable-next-line react-hooks/rules-of-hooks
const [{ pageAction }] = useTelemetry();

export interface IConsentModal {
  userInfo: IUserDataState;
  userProfile?: IUserLeadProfileAgreement;
  embedHost?: string;
  appInfo?: IAppDataItem;
  serviceInfo?: Service;
  isContactForm: boolean;
  isEmbedded: boolean;
  dismissModal: () => void;
  isNationalCloud: boolean;
  openInstructionsModal: () => void;
  openDriveModal?: (driveUrl: string) => void;
  ctaType: Constants.CTAType;
  billingCountryCode: string;
  fetchAppDetails?: (targetApp: IAppDataItem) => void;
  fetchServiceDetails?: (targetService: Service) => void;
  sendLeadInfo: (options: SendLeadInfoOptions) => Promise<unknown>;
  getUserAuthStatus: () => Promise<unknown>;
  updatePurchaseStatus: () => void;
  updateLicense?: (licenseType: number, hasLicense: boolean) => void;
  featureFlags?: IFeatureFlags;
  signOutUser?: () => void;
  query?: { [key: string]: string };
  currentView?: string;
  isTransactApp?: boolean;
  modal: IModalState;
  isLoadingUserProfile: boolean;
  isOpenedFromPDP?: boolean;
  ribbonKey?: string;
  flightCodes: string;
}

const modalStyles: Partial<IModalStyles> = {
  main: {
    [`@media (min-width: ${ScreenWidthMinXLarge}px)`]: {
      width: '35%',
    },
    [`@media (min-width: ${ScreenWidthMinLarge}px) and (max-width: ${ScreenWidthMinXLarge}px)`]: {
      width: '60%',
    },
    [`@media (max-width: ${ScreenWidthMinLarge}px)`]: {
      width: '90%',
    },
    display: 'flex',
  },
  scrollableContent: {
    padding: '16px 27px 24px 24px',
    [`@media (max-width: ${ScreenWidthMinLarge}px)`]: {
      padding: '16px 20px 24px 20px',
    },
  },
};

const infoIconStyles: Partial<IIconStyles> = {
  root: {
    fontSize: 16,
    marginRight: 8,
  },
};

const consentModalClassNames = mergeStyleSets({
  appImg: {
    width: 44,
    height: 44,
    padding: 4,
    border: `1px solid ${NeutralColors.gray20}`,
  },
  appImgContainer: {
    marginRight: 8,
    width: 44,
    height: 44,
  },
  boldText: {
    fontWeight: 600,
  },
  desclaimerContainer: {
    backgroundColor: NeutralColors.gray20,
    marginBottom: 20,
    marginTop: 12,
    '& a:link': {
      color: CommunicationColors.primary,
      textDecoration: 'underline',
    },
    '& a:visited': {
      color: CommunicationColors.primary,
    },
    '& a:hover': {
      color: CommunicationColors.shade20,
    },
    '& a:active': {
      color: CommunicationColors.shade30,
    },
  },
  userProfileContainer: {
    marginBottom: 8,
  },
  notes: {
    marginBottom: 8,
  },
  animationImg: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const loginUserInfoTokens: IStackTokens = { padding: '20px 0px 4px' };
const desclaimerTokens: IStackTokens = { padding: 8 };
const animationStackTokens: IStackTokens = { childrenGap: 25 };

/** These offer types should not trigger sending leads */
const offerTypesWithoutLeads = [Constants.PowerBI.visuals];

export class ConsentModal extends SpzaComponent<IConsentModal & WithRouterProps, any> {
  context: IBuildHrefContext & ICommonContext & ILocContext & ILocParamsContext;
  protected handoffTitle = '';
  private instrument: SpzaInstrumentProvider;
  private licenseType = -1;

  constructor(props: IConsentModal & WithRouterProps) {
    super(props);
    this.instrument = SpzaInstrumentService.getProvider();

    const { userProfile, isContactForm, serviceInfo, isEmbedded, appInfo } = this.props;

    // 1 is D365 license, 2 is D365 for financial license, -1 is don't display account info
    this.licenseType = isEmbedded
      ? dynamicsLicenseTypeForEmbed(appInfo.products)
      : appInfo
      ? dynamicsLicenseType(userProfile, appInfo.products)
      : -1;

    this.state = {
      isProfileCompleted: false,
      showAnimation: false,
      showUserSignInError: false,
      showContactMeError: false,
      // To get the privacy URL for an app, we need to fetch the App Details. This is lazy loaded.
      // Hence it is in the state.
      appPrivacyPolicyUrl: '',
      showSuccessDialog: false,
      formSubmitted: false,
      showAccountInfo: false,
      forceUserProfileCompacted: !isContactForm && !serviceInfo && this.isMandatoryFieldsFilled(userProfile),
      accountInfo: {
        firstName: userProfile?.firstName ? userProfile.firstName : '',
        lastName: userProfile?.lastName ? userProfile.lastName : '',
        email: userProfile?.email ? userProfile.email : '',
        title: userProfile?.title ? userProfile.title : '',
        company: userProfile?.company ? userProfile.company : '',
        country: userProfile?.country ? userProfile.country : '',
        phone: userProfile?.phone ? userProfile.phone : '',
      },
      notes: '',
      isNotesInvalid: false,
      localLicenseStore:
        (this.licenseType === Constants.Dynamics365LicenseType.dynamics365License ||
          this.licenseType === Constants.Dynamics365LicenseType.dynamics365forBusinessLicense) &&
        !isEmbedded
          ? setInitialLocalLicenseStore(userProfile.licenses, this.licenseType)
          : null,
      licenseDisplayText:
        (this.licenseType === Constants.Dynamics365LicenseType.dynamics365License ||
          this.licenseType === Constants.Dynamics365LicenseType.dynamics365forBusinessLicense) &&
        !isEmbedded
          ? setInitialLicenseDisplayText(userProfile.licenses, this.licenseType)
          : '',
      shouldSkipModal: this.shouldSkipModal(userProfile),
      trailId: generateGuid(),
    };

    const { accountInfo } = this.state;
    if (
      isEmbedded &&
      accountInfo.firstName === '' &&
      accountInfo.lastName === '' &&
      accountInfo.email === '' &&
      accountInfo.phone === ''
    ) {
      const userProfileInCookie = getCookieItem(Constants.userProfileEmbedCookieName, false);
      if (userProfileInCookie) {
        const userInfoInCache: IUserLeadProfileAgreement = {
          firstName: '',
          lastName: '',
          phone: '',
          company: '',
          country: '',
          email: '',
          title: '',
          accepted: true,
          managedLicenses: [],
        };
        readUserProfileEmbedCookie(userProfileInCookie, userInfoInCache);
        const accountInfoFromCache = {
          firstName: userInfoInCache.firstName ? userInfoInCache.firstName : '',
          lastName: userInfoInCache.lastName ? userInfoInCache.lastName : '',
          email: userInfoInCache.email ? userInfoInCache.email : '',
          title: userInfoInCache.title ? userInfoInCache.title : '',
          company: userInfoInCache.company ? userInfoInCache.company : '',
          country: userInfoInCache.country ? userInfoInCache.country : '',
          phone: userInfoInCache.phone ? userInfoInCache.phone : '',
        };
        this.state = {
          accountInfo: accountInfoFromCache,
        };
      }
    }
  }

  isMandatoryFieldsFilled({ firstName, lastName, email, country }: IUserLeadProfileAgreement) {
    return !!(firstName && lastName && email && country);
  }

  telemetry(action: string, actionModifier: string, details: any) {
    const { trailId } = this.state;
    const { location } = this.props;
    const payload = {
      page: location?.pathname,
      action,
      actionModifier,
      details: JSON.stringify({ trailId, ...details }),
    };
    SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
      page: payload.page,
    });
  }

  logConsentDialogEvents(action: string, actionModifier: string, details: string = this.getTelemetryDetailsContent()) {
    const { appInfo, serviceInfo, location } = this.props;
    const entityInfo = appInfo || serviceInfo;

    const payload: ITelemetryData = {
      page: location?.pathname,
      action,
      actionModifier,
      appName: entityInfo && entityInfo.entityId,
      details,
    };
    this.instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
      appName: payload.appName,
    });
  }

  setAccountInfo(consentProps: IConsentModal) {
    const { userProfile, isEmbedded, appInfo } = consentProps;

    this.licenseType = isEmbedded
      ? dynamicsLicenseTypeForEmbed(appInfo.products)
      : appInfo
      ? dynamicsLicenseType(userProfile, appInfo.products)
      : -1;

    this.setState({
      accountInfo: {
        firstName: userProfile?.firstName ? userProfile.firstName : '',
        lastName: userProfile?.lastName ? userProfile.lastName : '',
        email: userProfile?.email ? userProfile.email : '',
        title: userProfile?.title ? userProfile.title : '',
        company: userProfile?.company ? userProfile.company : '',
        country: userProfile?.country ? userProfile.country : '',
        phone: userProfile?.phone ? userProfile.phone : '',
      },
      localLicenseStore:
        (this.licenseType === Constants.Dynamics365LicenseType.dynamics365License ||
          this.licenseType === Constants.Dynamics365LicenseType.dynamics365forBusinessLicense) &&
        !isEmbedded
          ? setInitialLocalLicenseStore(userProfile.licenses, this.licenseType)
          : null,
      licenseDisplayText:
        (this.licenseType === Constants.Dynamics365LicenseType.dynamics365License ||
          this.licenseType === Constants.Dynamics365LicenseType.dynamics365forBusinessLicense) &&
        !isEmbedded
          ? setInitialLicenseDisplayText(userProfile.licenses, this.licenseType)
          : '',
    });
  }

  isCTAShouldSkipModal() {
    const { ctaType, isEmbedded, modal } = this.props;
    const isOpenedFromPDP = modal?.isOpenedFromPDP;
    const shouldCTATypeBeSkip = [Constants.CTAType.Get, Constants.CTAType.Try].includes(ctaType);

    return isOpenedFromPDP && !isEmbedded && shouldCTATypeBeSkip;
  }

  /** Determines whether should skip the consent modal */
  shouldSkipModal(userProfile: IUserLeadProfileAgreement): boolean {
    const { isContactForm, isEmbedded, appInfo } = this.props;

    if (isContactForm) {
      return false;
    }

    if (isEmbedded && isPowerBIVisuals(getPrimaryProductUrl(appInfo.primaryProduct))) {
      return true;
    }

    return this.isCTAShouldSkipModal() && this.isMandatoryFieldsFilled(userProfile);
  }

  componentDidMount() {
    const { isTransactApp, appInfo, fetchAppDetails, userProfile, userInfo, isContactForm, dismissModal, isEmbedded } =
      this.props;

    this.handoffTitle = appInfo && appInfo.builtFor;

    this.setState({ isEmbeddedApp: isEmbedded && !isContactForm });

    if (this.shouldSkipModal(userProfile)) {
      this.logConsentDialogEvents(
        Constants.Telemetry.ActionModifier.ConsentModal,
        Constants.Telemetry.Action.Skip,
        JSON.stringify({ AutomaticallyAccepted: true })
      );
      this.setState({ shouldSkipModal: true });
      // Embed doesn't dismiss the modal when skipping it, so dismiss manually
      if (!userInfo?.signedIn && isEmbedded) {
        dismissModal();
      }
      this.handleContinue(userProfile);
    } else if (this.isCTAShouldSkipModal() && !this.isMandatoryFieldsFilled(userProfile) && !userInfo?.loading) {
      this.logConsentDialogEvents(
        Constants.Telemetry.ActionModifier.ConsentModal,
        Constants.Telemetry.Action.Open,
        JSON.stringify({ AutomaticallyAccepted: false })
      );
    } else {
      this.logConsentDialogEvents(Constants.Telemetry.ActionModifier.ConsentModal, Constants.Telemetry.Action.Open);
    }

    if (isTransactApp) {
      this.handleTransactCheckout(this.props);
    }

    // For the privacy policy URL to show up, we need the app details.
    if (appInfo && appInfo.detailInformation == null) {
      fetchAppDetails(appInfo);
    } else if (appInfo) {
      this.setState({
        appPrivacyPolicyUrl: (appInfo.detailInformation as IAppDetailInformation).PrivacyPolicyUrl,
      });
    }

    if (userInfo.loading && !isContactForm && this.isCTAShouldSkipModal()) {
      this.setState({ showAnimation: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: IConsentModal) {
    const {
      userProfile: currentUserProfile,
      appInfo: currentAppInfo,
      isLoadingUserProfile: currentIsLoadingUserProfile,
    } = this.props;
    const {
      userProfile: nextUserProfile,
      appInfo: nextAppInfo,
      userInfo: nextUserInfo,
      isLoadingUserProfile: nextIsLoadingUserProfile,
      isContactForm: nextIsContactForm,
      serviceInfo: nextServiceInfo,
    } = nextProps;

    if (nextProps.isTransactApp) {
      this.handleTransactCheckout(nextProps);
    }
    if (
      nextAppInfo &&
      currentAppInfo &&
      nextAppInfo.detailInformation &&
      currentAppInfo.detailInformation !== nextAppInfo.detailInformation
    ) {
      this.setState({
        appPrivacyPolicyUrl: (nextAppInfo.detailInformation as IAppDetailInformation).PrivacyPolicyUrl,
      });
    }

    if (
      (nextUserProfile.firstName !== '' && currentUserProfile.firstName === '') ||
      (nextUserProfile.lastName !== '' && currentUserProfile.lastName === '') ||
      (nextUserProfile.company !== '' && currentUserProfile.company === '') ||
      (nextUserProfile.country !== '' && currentUserProfile.country === '') ||
      (nextUserProfile.email !== '' && currentUserProfile.email === '') ||
      (nextUserProfile.phone !== '' && currentUserProfile.phone === '') ||
      (nextUserProfile.title !== '' && currentUserProfile.title === '')
    ) {
      this.setAccountInfo(nextProps);
    }

    if (!nextUserInfo.loading) {
      if (this.shouldSkipModal(nextUserProfile) && !this.shouldSkipModal(currentUserProfile)) {
        this.logConsentDialogEvents(
          Constants.Telemetry.ActionModifier.ConsentModal,
          Constants.Telemetry.Action.Skip,
          JSON.stringify({ AutomaticallyAccepted: true })
        );
        this.setState({ shouldSkipModal: true });
        this.handleContinue(nextUserProfile);
      } else if (this.isCTAShouldSkipModal() && !this.isMandatoryFieldsFilled(nextUserProfile)) {
        this.logConsentDialogEvents(
          Constants.Telemetry.ActionModifier.ConsentModal,
          Constants.Telemetry.Action.Open,
          JSON.stringify({ AutomaticallyAccepted: false })
        );
      }
    }

    if (!nextIsLoadingUserProfile && currentIsLoadingUserProfile !== nextIsLoadingUserProfile) {
      this.userProfilePostLoadingActions(nextUserProfile, nextIsContactForm, nextServiceInfo);
    }
  }

  userProfilePostLoadingActions(userProfile: IUserLeadProfileAgreement, isContactForm: boolean, serviceInfo?: Service) {
    this.setState({
      forceUserProfileCompacted: !isContactForm && !serviceInfo && this.isMandatoryFieldsFilled(userProfile),
    });
    if (!this.isCTAShouldSkipModal()) {
      this.setState({ showAnimation: false });
    }
  }

  handleTransactCheckout(props: IConsentModal) {
    const { userInfo, modal, dismissModal, appInfo: { entityId } = {} } = props;

    this.logConsentDialogEvents(
      Constants.Telemetry.Action.Open,
      Constants.Telemetry.ActionModifier.HandleTransactCheckout,
      JSON.stringify({ tenantType: userInfo.tenantType })
    );

    if (userInfo && userInfo.signedIn && !userInfo.isMSAUser && userInfo.tenantType !== Constants.TenantType.Msa) {
      this.logConsentDialogEvents(
        Constants.Telemetry.Action.Redirecting,
        Constants.Telemetry.ActionModifier.HandleTransactCheckout,
        'Redirecting to checkout page'
      );
      urlPush(
        this.context.buildHref(
          routes.checkout,
          { entityId },
          { [Constants.Checkout.DefaultPlanIdQueryParam]: modal?.options?.planId },
          true,
          false,
          true
        )
      );
      dismissModal();
    } else {
      this.logConsentDialogEvents(
        Constants.Telemetry.Action.Error,
        Constants.Telemetry.ActionModifier.HandleTransactCheckout,
        JSON.stringify({
          tenantType: userInfo.tenantType,
          isMSAUser: userInfo.isMSAUser,
          isSignedIn: userInfo.signedIn,
        })
      );
      this.setState({ showUserSignInError: true });
    }
  }

  updateUserLicenseInState() {
    const { updateLicense } = this.props;
    const { localLicenseStore } = this.state;
    updateLicense(this.licenseType, localLicenseStore);
  }

  shouldProcessLead(): boolean {
    const { isEmbedded, embedHost } = this.props;

    const productShouldntGenerateLead = offerTypesWithoutLeads.includes(getProductByUrlKey({ urlKey: embedHost })?.UrlKey);

    if (isEmbedded && productShouldntGenerateLead) {
      return false;
    }

    return true;
  }

  shouldProcessLeadInfo(): boolean {
    const { appInfo, serviceInfo, flightCodes, embedHost, userInfo, isEmbedded } = this.props;
    const isService = !!serviceInfo;
    const uniqueProductId = isService ? serviceInfo.entityId : appInfo?.entityId;

    if (!userInfo?.signedIn) {
      this.telemetry(Constants.Telemetry.Action.ProcessLeadInfo, Constants.Telemetry.ActionModifier.Skip, {
        reason: Constants.Telemetry.LeadsDropReason.UserNotSignedIn,
        uniqueProductId,
        isEmbedded,
        embedHost,
      });
      return false;
    }

    if (flightCodes) {
      this.telemetry(Constants.Telemetry.Action.ProcessLeadInfo, Constants.Telemetry.ActionModifier.Skip, {
        reason: Constants.Telemetry.LeadsDropReason.PreviewOffer,
        uniqueProductId,
        isEmbedded,
        embedHost,
      });
      return false;
    }

    if (!isService) {
      if (!appInfo || appInfo.privateApp === true) {
        this.telemetry(Constants.Telemetry.Action.ProcessLeadInfo, Constants.Telemetry.ActionModifier.Skip, {
          reason: Constants.Telemetry.LeadsDropReason.AppNotAvailableOrPrivate,
          uniqueProductId,
          isEmbedded,
          embedHost,
        });
        return false;
      }
    }

    if (!this.shouldProcessLead()) {
      this.telemetry(Constants.Telemetry.Action.ProcessLeadInfo, Constants.Telemetry.ActionModifier.Skip, {
        reason: Constants.Telemetry.LeadsDropReason.EmbedWithoutLead,
        uniqueProductId,
        isEmbedded,
        embedHost,
      });
      return false;
    }

    return true;
  }

  async processLeadInfo() {
    const { ctaType, appInfo, sendLeadInfo, isContactForm, serviceInfo, isEmbedded, embedHost } = this.props;
    const { notes } = this.state;
    const isService = !!serviceInfo;
    const item = isService ? serviceInfo : appInfo;

    try {
      this.telemetry(Constants.Telemetry.Action.ProcessLeadInfo, Constants.Telemetry.ActionModifier.Start, {
        data: `Processing the lead. isService is ${isService}.`,
        uniqueProductId: item.entityId,
        isEmbedded,
        embedHost,
      });

      if (this.shouldProcessLeadInfo()) {
        await sendLeadInfo({
          item,
          ctaType,
          notes,
        });
        this.telemetry(Constants.Telemetry.Action.ProcessLeadInfo, Constants.Telemetry.ActionModifier.End, {
          uniqueProductId: item.entityId,
          isEmbedded,
          embedHost,
        });
      }
    } catch (error) {
      const shouldStopFlow = isContactForm || isService;

      this.telemetry(Constants.Telemetry.Action.ProcessLeadInfo, Constants.Telemetry.ActionModifier.Error, {
        error,
        shouldStopFlow,
        uniqueProductId: item.entityId,
        isEmbedded,
        embedHost,
      });

      if (shouldStopFlow) {
        throw error;
      }
    }
  }

  handleContinueHelper(showAnimation = true, shouldGenerateLead = true) {
    const {
      getUserAuthStatus,
      serviceInfo,
      isContactForm,
      ctaType,
      isEmbedded,
      appInfo,
      openDriveModal,
      embedHost,
      dismissModal,
      signOutUser,
      featureFlags,
      billingCountryCode,
    } = this.props;
    const { isEmbeddedApp } = this.state;

    if (this.licenseType && this.licenseType !== -1) {
      this.updateUserLicenseInState();
    }
    getUserAuthStatus().then(async (result: any) => {
      if (isEmbeddedApp || result.status !== Constants.SigninStatus.ExpiredTokenInvalidCookie) {
        try {
          this.setState({ showAnimation: true });

          const { url, handoffTitle } = processHandoffURLAndTitle(appInfo);
          const isOpenInNewWindow =
            featureFlags?.openInNewWindowButton &&
            isOpenInNewWindowButton({
              isEmbedded,
              ctaType,
              appData: appInfo,
              billingRegion: billingCountryCode,
              url,
            });

          const actions = [this.processLeadInfo()];

          await Promise.all(actions);

          this.setState({ showAnimation: false });

          if (serviceInfo || isContactForm) {
            this.setState({ showSuccessDialog: true });
          }

          if (!shouldGenerateLead) {
            this.logConsentDialogEvents(
              Constants.Telemetry.Action.Click,
              Constants.Telemetry.ActionModifier.CTAInfo,
              'User choose not to generate lead'
            );
          }

          if (isEmbedded && !isContactForm) {
            // TODO : Publish lead in embed experience using the host data
            // We need to fetch lastName, firstName and email. If firstName/lastName are null, use FNU/LNU
            postEmbedAcquisitionMessage(appInfo, ctaType, embedHost);
            setTimeout(() => {
              dismissModal();
            }, 1000);

            return;
          }

          if (!isContactForm && !serviceInfo) {
            if (shouldDownloadPowerBIVisual(appInfo, isEmbedded)) {
              this.handlePowerBIVisualDownload();
              return;
            }

            if (handoffTitle) {
              this.handoffTitle = handoffTitle;
            }
            this.logOutgoingHandoffRedirect(url);
            if (!url) {
              dismissModal();
              return;
            }
            NpsModule.IncreaseAppAcquisition();
            getNpsModule()?.ctaClicked(false);

            if (featureFlags?.openInNewWindowButton) {
              if (showAnimation) {
                this.setState({ showAnimation: true, handoffUrl: url });
                dismissModal();
              }
              openInNewWindow(url);
            } else {
              if (showAnimation) {
                this.setState({ showAnimation: true, handoffUrl: url });
                setTimeout(function () {
                  window.open(url, '_self');
                }, 2500);
              } else {
                window.open(url, '_self');
              }
            }
          }
        } catch (error) {
          const errorString = stringifyError(error);

          const actionModifier: string = isContactForm
            ? Constants.Telemetry.ActionModifier.SubmitLeadGen
            : Constants.Telemetry.ActionModifier.ConsentModal;

          const action: string =
            errorString === Constants.Telemetry.Action.RefreshToken
              ? Constants.Telemetry.Action.RefreshToken
              : Constants.Telemetry.Action.Error;

          this.logConsentDialogEvents(action, actionModifier, errorString);

          if (errorString === Constants.Telemetry.Action.RefreshToken) {
            signOutUser();
          }

          if (isContactForm || serviceInfo) {
            this.setState({ showContactMeError: true, showAnimation: false });
          } else {
            this.setState({ showAnimation: false });
          }
        }
      }
    });
  }

  hideAccountInfoUI() {
    const { forceUserProfileCompacted } = this.state;

    if (forceUserProfileCompacted) {
      this.setState({ forceUserProfileCompacted: false });
    }
    this.setState({ showAccountInfo: true });
  }

  onUpdateAccountInfo(id: string, value: string) {
    this.setState({ accountInfo: this.updateAccountInfo(id, value) });
  }

  updateAccountInfo(id: string, value: string) {
    const { accountInfo } = this.state;

    const newAccountInfo = {
      firstName: accountInfo.firstName,
      lastName: accountInfo.lastName,
      email: accountInfo.email,
      title: accountInfo.title,
      company: accountInfo.company,
      country: accountInfo.country,
      phone: accountInfo.phone,
    };
    newAccountInfo[`${id}`] = value;
    return newAccountInfo;
  }

  handlePowerBIVisualDownload() {
    const { appInfo, location, openInstructionsModal, dismissModal } = this.props;

    if (appInfo?.downloadLink) {
      const payload: ITelemetryData = {
        page: location.pathname,
        action: Constants.Telemetry.Action.Click,
        actionModifier: Constants.Telemetry.ActionModifier.DownloadPBIVisual,
        appName: appInfo.entityId,
      };
      this.instrument.probe<ITelemetryData>(Constants.Telemetry.ProbeName.LogInfo, payload);
      logger.info('', {
        action: payload.action,
        actionModifier: payload.actionModifier,
        appName: payload.appName,
      });

      window.location.href = appInfo.downloadLink;
      if (!getLocalStorageItem(Constants.LocalStorage.dontShowInstructions)) {
        openInstructionsModal();
      } else {
        dismissModal();
      }
    }
  }

  handleContinue(userProfile: IUserLeadProfileAgreement, showAnimation = true, shouldGenerateLead = true) {
    const { isEmbeddedApp } = this.state;
    const { query, updatePurchaseStatus, isContactForm, appInfo, serviceInfo } = this.props;
    const { entityId } = appInfo || serviceInfo;

    const shouldGenerateLeadGen: boolean = shouldGenerateLead && !isETERunning(query);
    getTrailIdAndData(true, 'consent form - continue btn');

    if (!isEmbeddedApp && !this.shouldSkipModal(userProfile)) {
      const actionModifier: string = isContactForm
        ? Constants.Telemetry.ActionModifier.SubmitLeadGen
        : Constants.Telemetry.ActionModifier.ConsentModal;

      this.logConsentDialogEvents(Constants.Telemetry.Action.Click, actionModifier);
      this.logConsentTelemetry({
        contentId: Constants.JsllCTAId.Continue,
        areaName: Constants.Telemetry.AreaName.ConsentModal,
        entityId,
      });

      this.setState({ formSubmitted: true });
    } else {
      this.logConsentTelemetry({
        contentId: Constants.Telemetry.ContentType.SkipConsentModal,
        areaName: Constants.Telemetry.AreaName.ConsentModal,
        entityId,
      });
      this.handleContinueHelper(showAnimation, shouldGenerateLeadGen);
    }

    updatePurchaseStatus();
  }

  logConsentTelemetry({ contentId, areaName, entityId }: { contentId: string; areaName: string; entityId: string }) {
    pageAction(null, {
      content: {
        contentId,
        areaName,
        contentName: entityId,
      },
    });
  }

  logOutgoingHandoffRedirect = (url: string) => {
    const { appInfo, serviceInfo, location } = this.props;
    const entityInfo = appInfo || serviceInfo;

    const payload: ITelemetryData = {
      page: location.pathname,
      action: Constants.Telemetry.Action.Redirecting,
      actionModifier: Constants.Telemetry.ActionModifier.OfficeOffersPurchase,
      appName: entityInfo && entityInfo.entityId,
      details: url,
    };
    this.instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
      appName: payload.appName,
    });
  };

  getTelemetryDetailsContent() {
    const { trailId } = this.state;
    const { appInfo, serviceInfo, isContactForm, isNationalCloud, ctaType, currentView } = this.props;
    const { shouldSkipModal } = this.state;
    const entityInfo: IAppDataItem | Service = appInfo || serviceInfo;
    const hasCheckbox = !isContactForm;
    const hasUserProfile = !shouldSkipModal || !!serviceInfo;

    // application
    if (appInfo) {
      return getAppTelemetryDetailContentText({
        appInfo: entityInfo as IAppDataItem,
        isNationalCloud,
        ctaTypes: [ctaType],
        consentDialogHasUserProfile: hasUserProfile,
        consentDialogHasCheckbox: hasCheckbox,
        currentView,
        trailId,
      });
    }

    // consulting services
    return getServiceTelemetryDetailContentText({
      serviceInfo: entityInfo as Service,
      isNationalCloud,
      ctaTypes: [ctaType],
      consentDialogHasUserProfile: hasUserProfile,
      consentDialogHasCheckbox: hasCheckbox,
      currentView,
      trailId,
    });
  }

  profileCompleted(profileFilledOut: boolean) {
    const { isProfileCompleted } = this.state;

    if (isProfileCompleted !== profileFilledOut) {
      this.setState({ isProfileCompleted: profileFilledOut });
    }
  }

  disableSubmitButton() {
    const { showUserSignInError, shouldSkipModal, isProfileCompleted, isNotesInvalid } = this.state;
    const { isContactForm } = this.props;

    if (showUserSignInError) {
      return false;
    }

    if (!shouldSkipModal) {
      return !isProfileCompleted || (isContactForm && isNotesInvalid);
    }

    return isLeadgenEnabled();
  }

  getTermsLink() {
    const { appInfo } = this.props;
    const terms: any = {};
    if (appInfo?.UsesEnterpriseContract) {
      terms.link = Constants.enterpriseContractLink;
      terms.locKey = 'ECA_Link';
      return terms;
    }
    terms.link = appInfo.licenseTermsUrl;
    terms.locKey = 'TRY_Terms2';
    return terms;
  }

  renderConsentDisclaimer() {
    const { ctaType, isContactForm, appInfo } = this.props;
    const terms = appInfo && this.getTermsLink();
    const { appPrivacyPolicyUrl } = this.state;

    return (
      <div>
        <Stack horizontal tokens={desclaimerTokens} className={consentModalClassNames.desclaimerContainer}>
          <Icon iconName="info" styles={infoIconStyles} />
          {isContactForm ? (
            <SafeHtmlWrapper
              html={this.context.locParams('Contact_Me_Consent_Disclaimer', [
                `<a href='${Constants.MicrosoftTermsURL}' rel='noreferrer' target='_blank'>${this.context.loc(
                  'RT_Terms',
                  'terms'
                )}</a>`,
              ])}
            />
          ) : appInfo ? (
            <SafeHtmlWrapper
              html={this.context.locParams('App_Consent_Disclaimer', [
                `<b>${this.context.loc(getActionStringForCTAType(ctaType))}</b>`,
                `<a href='${terms.link}' rel='noreferrer' target='_blank'>${this.context.loc(
                  'Provider_Terms',
                  'terms of use'
                )}</a>`,
                `<a href='${appPrivacyPolicyUrl}' rel='noreferrer' target='_blank'>${this.context.loc(
                  'TRY_PrivacyPolicy',
                  'privacy policy'
                )}</a>`,
                `<a href='${Constants.MicrosoftTermsURL}' rel='noreferrer' target='_blank'>${this.context.loc(
                  'TRY_Terms',
                  'terms'
                )}</a>`,
                `<a href='${Constants.MicrosoftPrivacyStatementURL}' rel='noreferrer' target='_blank'>${this.context.loc(
                  'TRY_Privacy',
                  'privacy'
                )}</a>`,
              ])}
            />
          ) : (
            <SafeHtmlWrapper
              html={this.context.locParams('CS_Consent_Disclaimer', [
                `<a href='${Constants.MicrosoftTermsURL}' rel='noreferrer' target='_blank'>${this.context.loc(
                  'TRY_Terms',
                  'terms'
                )}</a>`,
                `<a href='${Constants.MicrosoftPrivacyStatementURL}' rel='noreferrer' target='_blank'>${this.context.loc(
                  'TRY_Privacy',
                  'privacy'
                )}</a>`,
              ])}
            />
          )}
        </Stack>
      </div>
    );
  }

  renderConsentBottomBar() {
    const {
      appInfo,
      isContactForm,
      isEmbedded,
      billingCountryCode,
      ctaType,
      serviceInfo,
      dismissModal,
      userProfile,
      isLoadingUserProfile,
    } = this.props;
    const { showUserSignInError, isEmbeddedApp } = this.state;
    let ctaButton: JSX.Element;

    const isCTADisabled = !isEmbeddedApp && (this.disableSubmitButton() || isLoadingUserProfile);

    if (isContactForm || !!serviceInfo) {
      ctaButton = (
        <PrimaryButton
          name="button"
          text={this.context.loc('CTA_ContactMe', 'Contact me')}
          type="submit"
          data-bi-id={Constants.JsllCTAId.Continue}
          data-bi-name={appInfo?.entityId}
          data-bi-type={
            appInfo?.offerType === OfferType.SaaSApp
              ? isTransactApp({ isEmbedded, ctaTypes: appInfo.ctaTypes, appData: appInfo, billingRegion: billingCountryCode })
                ? Constants.JsllSaas.SaasTransactable
                : Constants.JsllSaas.SaasNonTransactable
              : ''
          }
          data-bi-area="Consent Dialog"
          onClick={() => this.handleContinue(userProfile)}
          disabled={isCTADisabled}
        />
      );
    } else {
      const { url } = processHandoffURLAndTitle(appInfo);
      const isOpenInNewWindow =
        this.props.featureFlags?.openInNewWindowButton &&
        isOpenInNewWindowButton({
          isEmbedded,
          ctaType,
          appData: appInfo,
          billingRegion: billingCountryCode,
          url,
        });

      const isPBIVisualPriceHidden = hidePowerBiVisualPricing({ app: appInfo, isEmbedded });

      ctaButton = (
        <PrimaryButton
          className={classNames({ [openInNewWindowButtonStyles.openInNewWindowButton]: isOpenInNewWindow })}
          text={
            isPBIVisualPriceHidden && ctaType === Constants.CTAType.Install
              ? this.context.loc('CTA_Install_free', 'Install free')
              : this.context.loc(getActionStringForCTAType(ctaType))
          }
          name="button"
          type="submit"
          data-bi-id={Constants.JsllCTAId.Continue}
          data-bi-name={appInfo?.entityId}
          data-bi-type={
            appInfo?.offerType === OfferType.SaaSApp
              ? isTransactApp({ isEmbedded, ctaTypes: appInfo.ctaTypes, appData: appInfo, billingRegion: billingCountryCode })
                ? Constants.JsllSaas.SaasTransactable
                : Constants.JsllSaas.SaasNonTransactable
              : ''
          }
          data-bi-area="Consent Dialog"
          onClick={() => (showUserSignInError ? dismissModal() : this.handleContinue(userProfile))}
          disabled={isCTADisabled}
          iconProps={isOpenInNewWindow ? { iconName: 'OpenInNewWindow' } : {}}
        />
      );
    }

    return <Stack.Item align="end">{getTooltipSilentLogIn(ctaButton, isLoadingUserProfile)}</Stack.Item>;
  }

  renderUserProfileModal() {
    const { isEmbedded, isContactForm } = this.props;
    const { formSubmitted, localLicenseStore, forceUserProfileCompacted, shouldSkipModal } = this.state;

    return (
      !shouldSkipModal && (
        <Stack className={consentModalClassNames.userProfileContainer}>
          <UserProfileModal
            isEmbedded={isEmbedded}
            userSubmittedForm={formSubmitted}
            profileCompleted={this.profileCompleted.bind(this)}
            callBackHandler={this.handleContinueHelper.bind(this)}
            shouldHideAccountInfo={this.hideAccountInfoUI.bind(this)}
            licenseType={this.licenseType}
            currentLicense={localLicenseStore}
            compactUserProfile={forceUserProfileCompacted}
            accountInfoUpdate={this.onUpdateAccountInfo.bind(this)}
            shouldRenderHeader
            isContactForm={isContactForm}
          />
        </Stack>
      )
    );
  }

  renderNotes() {
    const { notes, isNotesInvalid } = this.state;
    const { isContactForm } = this.props;

    return (
      isContactForm && (
        <TextField
          className={consentModalClassNames.notes}
          multiline
          rows={4}
          maxLength={1500}
          deferredValidationTime={2000}
          errorMessage={
            isNotesInvalid
              ? this.context.loc(
                  'ConsentModal_Notes_ContainEmailOrUrlErrorMsg',
                  "This field can't contain URLs or email addresses."
                )
              : ''
          }
          label={this.context.loc('ConsentModal_Notes_Label', 'Describe your use case')}
          placeholder={this.context.loc(
            'ConsentModal_Notes_Placeholder',
            'Tell us about your team’s business needs.\nFor example, are you working on a current project? What is your time frame?'
          )}
          value={notes}
          onChange={this.onNotesChange}
        />
      )
    );
  }

  onNotesChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    this.setState({ notes: newValue, isNotesInvalid: containsURLOrEmail(newValue) });
  };

  renderLoginInfo() {
    const { userInfo } = this.props;
    const { displayName, email } = userInfo;

    return (
      userInfo?.signedIn && (
        <Stack tokens={loginUserInfoTokens}>
          <Text variant="medium">
            {this.context.loc('Leads_Logged_User_Info', "You're signed in as")}{' '}
            <Text variant="medium" className={consentModalClassNames.boldText}>
              {displayName} ({email}).
            </Text>
          </Text>
        </Stack>
      )
    );
  }

  renderConsentElement() {
    const { appInfo, serviceInfo } = this.props;
    const { isEmbeddedApp } = this.state;
    const iconSrc = appInfo ? appInfo.iconURL : serviceInfo.extraData.smallIconUri;
    const title = appInfo ? appInfo.title : serviceInfo.title;
    const publisher = appInfo ? appInfo.publisher : serviceInfo.publisher;

    return (
      <Stack>
        <Stack horizontal>
          <Stack.Item align="center">
            <TelemetryImage
              imgClassName={consentModalClassNames.appImg}
              className={classNames(consentModalClassNames.appImgContainer, { 'ms-depth-8': true })}
              src={iconSrc}
              aria-label={this.context.loc('Telemetry_Image_Aria_Label', 'Application Icon')}
              alt={title}
            />
          </Stack.Item>
          <Stack>
            <Text variant="mediumPlus" className={consentModalClassNames.boldText}>
              {title}
            </Text>
            <Text variant="medium">{this.context.locParams('Tile_By', [publisher])}</Text>
          </Stack>
        </Stack>
        {!isEmbeddedApp && (
          <>
            {this.renderLoginInfo()}
            {this.renderUserProfileModal()}
            {this.renderNotes()}
          </>
        )}
        {this.renderConsentDisclaimer()}
        {this.renderConsentBottomBar()}
      </Stack>
    );
  }

  switchToleadGen() {
    this.setState({ showAnimation: false });
    return this.renderConsentElement();
  }

  renderImpl() {
    const { dismissModal, isTransactApp, isContactForm, appInfo, serviceInfo } = this.props;
    const { shouldSkipModal, showSuccessDialog, showUserSignInError, showAnimation, showContactMeError } = this.state;
    const publisher = appInfo ? appInfo.publisher : serviceInfo.publisher;

    if (shouldSkipModal && this.isCTAShouldSkipModal()) {
      this.setState({ shouldSkipModal: false });
    }

    return showSuccessDialog && (isContactForm || serviceInfo) ? (
      <SuccessConsentModal dismissModal={dismissModal} publisher={publisher} shouldSkipModal={shouldSkipModal} />
    ) : (showUserSignInError || showContactMeError) && (isContactForm || serviceInfo) ? (
      <FailureConsentModal
        dismissModal={dismissModal}
        shouldSkipModal={shouldSkipModal}
        showContactMeError={showContactMeError}
      />
    ) : (
      <ConsentModalContent
        showAnimation={showAnimation}
        handoffTitle={this.handoffTitle}
        isTransactApp={isTransactApp}
        isContactForm={isContactForm || !!serviceInfo}
        shouldSkipModal={shouldSkipModal}
        publisher={appInfo ? appInfo.publisher : serviceInfo.publisher}
        dismissModal={dismissModal}
      >
        {this.renderPromptContent()}
      </ConsentModalContent>
    );
  }

  renderPromptContent() {
    const { showAnimation } = this.state;
    const { appInfo, serviceInfo } = this.props;

    if (showAnimation) {
      return this.renderAnimation();
    }

    if (appInfo || serviceInfo) {
      return this.renderApp();
    }
  }

  renderAnimation() {
    const { appInfo, serviceInfo } = this.props;
    const iconSrc = appInfo ? appInfo.iconURL : serviceInfo.extraData.smallIconUri;
    const title = appInfo ? appInfo.title : serviceInfo.title;
    const { animationImg, appImg, appImgContainer } = consentModalClassNames;

    return (
      <Stack tokens={animationStackTokens}>
        <Stack.Item>
          <Animation />
        </Stack.Item>
        <Stack.Item className={animationImg}>
          <TelemetryImage
            imgClassName={appImg}
            className={classNames(appImgContainer, { 'ms-depth-8': true })}
            src={iconSrc}
            aria-label={this.context.loc('Telemetry_Image_Aria_Label', 'Application Icon')}
            alt={title}
          />
        </Stack.Item>
      </Stack>
    );
  }

  renderApp() {
    const { isTransactApp, userInfo } = this.props;

    const leadGen =
      isTransactApp && (userInfo.isMSAUser || (userInfo.tenantType && userInfo.tenantType !== Constants.TenantType.Managed));
    return leadGen ? this.switchToleadGen() : this.renderConsentElement();
  }
}

(ConsentModal as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  renderErrorModal: PropTypes.func,
  buildHref: PropTypes.func,
};
