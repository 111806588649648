import { updateMatchFunctions } from 'utils/filterHelpers';
import { getEntityRegistration } from 'utils/entityRegistration';
import { DataMap } from '@shared/utils/dataMapping';

import {
  Action,
  isType,
  EntityDataReceivedAction,
  CuratedDataReceivedAction,
  SearchDataReceivedAction,
  EntityDetailsReceivedAction,
  RehydrateClientStateAction,
  SearchQueryChangedAction,
  DataMapReceivedAction,
  SuggestedChangeAction,
  LoadFavouriteDataStateAction,
  RelatedAppsItemsReceivedAction,
  RelatedAppsItemsLoadingAction,
  SetEntityIdLoadingAction,
  SetEntityIdFailedAction,
  GetAppReviewsAction,
  EntityFullDataReceivedAction,
} from '@shared/actions/actions';
import { TileDataReceivedAction, TileDataLoadingAction } from '@shared/actions/tileDataActions';
import {
  initialEntityDataState,
  IEntityDataState,
  copyState,
  deepCopyDataMap,
  IAppDataState,
  IServiceDataState,
} from '@src/State';
import { Constants } from '@shared/utils/constants';
import { IDataItem, PricingStates } from '@shared/Models';
import { IUserFavouriteApp, IUserFavouriteService } from '@shared/interfaces/userFavouriteModels';
import { generateHashMap } from '@shared/utils/hashMapUtils';
import { performFilter } from '@shared/utils/filterModule';
import { AzureEnvironmentSettings } from '@shared/AzureEnvironmentSettings';
import { IDataMap } from '@shared/utils/dataMapping';

function preserveData(oldEntity: IDataItem, newEntity: IDataItem) {
  if (!oldEntity || !newEntity) {
    return;
  }

  function preserveDataHelper(data: string) {
    if (oldEntity[`${data}`] || (Object.prototype.hasOwnProperty.call(oldEntity, data) && !newEntity[`${data}`])) {
      newEntity[`${data}`] = oldEntity[`${data}`];
    }
  }

  // preserve all the data that was already present
  // that way, we do not get flicker when loading data
  const dataList = Object.keys(oldEntity);
  dataList.forEach((data: string) => {
    preserveDataHelper(data);
  });
}

export function buildFullSearchData<Source>(
  ids: any[],
  sourceData: Source[],
  hashMap: { [id: string]: number },
  identifier: string
) {
  const tempArray: Source[] = [];

  if (ids.length > 0) {
    // we care about showing the top 5 percent, which means that we will take the first result (highest score) and
    // ignore everyone that is lower that 5% of that
    const cutoff: number = ids[0]['@search.score'] * 0.05;

    ids.forEach(function (id) {
      if (id['@search.score'] > cutoff) {
        const index = hashMap[id[`${identifier}`].toString().toLowerCase()];
        // make sure we actually understand the app
        if (index >= 0) {
          tempArray.push(sourceData[`${index}`]);
        }
      }
    });
  }

  return tempArray;
}

function isServiceDataState(state: IEntityDataState<IDataItem>): string {
  return state.selectedCountry;
}

function isSelectedCountryValidDataMap(dataMap: IDataMap, selectedCountry: string): boolean {
  // If countries not defined in data map yet, assume it is valid country selection.
  if (
    !dataMap?.serviceGlobalFilter1?.country?.subCategoryDataMapping ||
    Object.keys(dataMap.serviceGlobalFilter1.country.subCategoryDataMapping).length === 0
  ) {
    return true;
  }
  // Make sure country in data map.
  const countries = dataMap.serviceGlobalFilter1.country.subCategoryDataMapping;

  return Object.keys(countries).some((countryKey) => countries[`${countryKey}`].BackendKey === selectedCountry);
}

export function commonEntityDataReducer(
  entityType: Constants.EntityType,
  state: IEntityDataState<IDataItem> = initialEntityDataState,
  action: Action<any>
): IEntityDataState<IDataItem> {
  if (action.payload && action.payload.entityType && action.payload.entityType !== entityType) {
    return state;
  }

  let newState = copyState(state);
  if (isType(action, EntityDataReceivedAction)) {
    // we have received filter data from the backend
    if (!state.dataLoaded[action.payload.userSegment]) {
      updateMatchFunctions(DataMap);

      newState.dataList = action.payload.dataList;
      newState.count = action.payload.count;

      const dataMap = deepCopyDataMap(state.dataMap);
      const results = performFilter(null, action.payload.urlQuery, [], dataMap, false, false);

      newState.activeFilters = results.activeFilters;
      newState.dataMap = dataMap;

      if (state.dataList && state.dataList.length > 0) {
        state.dataList.forEach((entity) => {
          const index = newState.idMap[entity.entityId];
          if (index >= 0) {
            // preserve already fetched data that are not part of the tile data
            // i.e. detail information and pricing
            preserveData(entity, newState.dataList[`${index}`]);
          }
        });
      }

      newState.idMap = generateHashMap(newState.dataList, ['entityId']);
      newState.dataLoaded = copyState(state.dataLoaded);
      newState.dataLoaded[action.payload.userSegment] = true;
    }
  } else if (isType(action, RehydrateClientStateAction)) {
    updateMatchFunctions(DataMap);

    const dataMap = deepCopyDataMap(state.dataMap);
    updateMatchFunctions(dataMap);
    newState.dataMap = dataMap;

    newState.idMap = generateHashMap(state.dataList, ['entityId']);
  } else if (isType(action, LoadFavouriteDataStateAction)) {
    let favouriteItems: IUserFavouriteApp[] | IUserFavouriteService[];
    if (entityType === Constants.EntityType.Service) {
      favouriteItems = action.payload.userFavouriteState.services;
    } else if (entityType === Constants.EntityType.App) {
      favouriteItems = action.payload.userFavouriteState.apps;
    }

    if (favouriteItems) {
      const items = favouriteItems
        .map((favouriteItem) => favouriteItem.item)
        .filter((item) => !!item && !!item.entityId && !(item.entityId.toLowerCase() in newState.idMap));

      newState.dataList = [...newState.dataList, ...items];
      newState.idMap = generateHashMap(newState.dataList, ['entityId']);
    }
  } else if (isType(action, SuggestedChangeAction)) {
    const entityId = action.payload.item.entityId && action.payload.item.entityId.toString().toLowerCase();
    const appIndex = state.idMap[`${entityId}`];
    if (!(appIndex >= 0)) {
      newState.dataList = [...newState.dataList, action.payload.item];
      newState.idMap = generateHashMap(newState.dataList, ['entityId']);
    }
  } else if (isType(action, DataMapReceivedAction)) {
    const dataMap = deepCopyDataMap(action.payload.dataMap);
    updateMatchFunctions(dataMap);
    newState.dataMap = dataMap;
    newState.dataMapLoaded = true;
  } else if (isType(action, TileDataLoadingAction)) {
    const dataMap = deepCopyDataMap(state.dataMap);
    const results = performFilter(null, action.payload.urlQuery, [], dataMap, false, false);

    newState.dataMap = dataMap;
    newState.activeFilters = results.activeFilters;
  } else if (isType(action, TileDataReceivedAction)) {
    if (state.dataList) {
      newState = {
        ...newState,
        ...action.payload.entityData,
      };

      newState.tileDataRequestId = action.payload.requestId;

      let currentEntityDetail: IDataItem = null;
      // If there is only one item in appData, it means the app detail is loaded at server side.
      // So when appData is loaded with this lazy loaded tile data, we need to make sure the original appData item
      // with detail data is still preserved.
      if (state.dataList.length === 1) {
        currentEntityDetail = state.dataList[0];
      }

      for (let i = 0, len = newState.dataList.length; i < len; i++) {
        const entityId =
          newState.dataList[`${i}`] && newState.dataList[`${i}`].entityId && newState.dataList[`${i}`].entityId.toLowerCase();

        if (
          currentEntityDetail &&
          newState.dataList[`${i}`] &&
          newState.dataList[`${i}`].entityId === currentEntityDetail.entityId
        ) {
          newState.dataList[`${i}`] = currentEntityDetail;
        }
        // preserve already fetched data that are not part of the tile data
        // i.e. detail information and pricing
        const indexInOldState = state.dataMap?.[`${entityId}`];
        // this is not a guaranteed 1to1 list
        // => so make sure that we have the new app in the old list
        if (indexInOldState >= 0) {
          preserveData(state.dataList[`${indexInOldState}`], newState.dataList[`${i}`]);
        }
      }

      // Services can have completely diff filters than apps hence
      // update the map again based on the services data list to dynamically light up services filters
      if (action.payload.entityType === Constants.EntityType.Service) {
        updateMatchFunctions(DataMap);
      }

      const dataMap = deepCopyDataMap(state.dataMap);
      const results = performFilter(null, action.payload.urlQuery, [], dataMap, false, false);

      newState.activeFilters = results.activeFilters;
      newState.dataMap = dataMap;
      newState.idMap = generateHashMap(newState.dataList, ['entityId']);

      if (action.payload.entityType === Constants.EntityType.App) {
        (newState as IAppDataState).pricingPayload = (state as IAppDataState).pricingPayload;
      }

      if (isServiceDataState(state) && isServiceDataState(newState)) {
        if (isSelectedCountryValidDataMap(newState.dataMap, state.selectedCountry)) {
          newState.selectedCountry = state.selectedCountry;
        } else {
          newState.selectedCountry = AzureEnvironmentSettings.defaultCountryCode.toUpperCase();
        }
        newState.selectedRegion = state.selectedRegion;
      }
    }
  } else if (isType(action, SetEntityIdLoadingAction)) {
    newState.entityIdLoading = action.payload.entityId;
  } else if (isType(action, SetEntityIdFailedAction)) {
    newState.entityIdFailed = action.payload.entityId;
  } else if (isType(action, RelatedAppsItemsLoadingAction)) {
    newState.relatedAppsItems = {
      entityId: action.payload.entityId,
      items: { saasLinkingItems: [], suggestedItems: [], linkedAddIns: [] },
    };
  } else if (isType(action, RelatedAppsItemsReceivedAction)) {
    newState.relatedAppsItems = {
      entityId: action.payload.entityId,
      items: action.payload.relatedAppsItems,
    };
  } else if (isType(action, GetAppReviewsAction)) {
    const { markedAsHelpful: prevMarkAsHelpful } = newState.appReviewsData;
    newState.appReviewsData = {
      ...newState.appReviewsData,
      entityId: action.payload.entityId,
      reviews: action.payload.reviews,
      isPurchased: action.payload.isPurchased,
      userReview: action.payload.userReview,
      markedAsHelpful: {
        ...prevMarkAsHelpful,
      },
    };
  } else if (isType(action, EntityDetailsReceivedAction)) {
    let index = -1;

    // valid error situation: we got back no appdetails
    if (action.payload.entityDetails) {
      index = state.idMap[action.payload.entityDetails.entityId.toString().toLowerCase()];
      const startingPrice = action.payload.entityDetails.startingPrice;
      if (startingPrice) {
        if (!startingPrice.pricingData) {
          startingPrice.pricingData = PricingStates.NoPricingData;
        }
      } else {
        action.payload.entityDetails.startingPrice = {
          pricingData: PricingStates.Loading, // Loading is the default state at server side.
        };
      }
    }

    if (index >= 0) {
      let newData: IDataItem = null;

      // we will now deal with app detail information not loading
      if (action.payload.entityDetails === null) {
        // todo: Object.assign({}, state.appData[`${index}`]); cannot work or we need to polyfill it
        newData = JSON.parse(JSON.stringify(state.dataList[`${index}`]));
        newData.detailLoadFailed = true;
      } else {
        // find the app to replace
        newData = action.payload.entityDetails;

        // Here we need to preserve the starting price and pricing information since we may get app data again from raw data cache which doesn't contain price data.
        // If we don't reserve starting price and pricing information retreived earlier at client side, the they will be lost in the new data.
        preserveData(state.dataList[`${index}`], newData);

        if (newData.startingPrice && newData.startingPrice.pricingBitmask) {
          // make sure we create/append the global filters bitmask to the app data
          Object.keys(newData.startingPrice.pricingBitmask).forEach((key) => {
            // if the bitmask has not been created yet
            // let's create it and set it to 0
            if (!newData[`${key}`]) {
              newData[`${key}`] = 0;
            }
            newData[`${key}`] |= newData.startingPrice.pricingBitmask[`${key}`];
          });
        }
      }
      const newDataList = state.dataList
        .slice(0, index)
        .concat(newData)
        .concat(state.dataList.slice(index + 1));
      newState.dataList = newDataList;
    } else {
      // This will be called only once when there is no appdata in the server cache.
      // This called when app details page is the landing page and it is the first page to be rendered by the server.
      // If we open home page before the app details page is opened, then the appdata is populated into the cache.
      newState.dataList = [...newState.dataList, action.payload.entityDetails];
      newState.idMap = generateHashMap(newState.dataList, ['entityId']);

      if (
        Object.prototype.hasOwnProperty.call(newState, 'selectedCountry') &&
        action.payload.entityDetails?.detailInformation?.countryRegion
      ) {
        (newState as IServiceDataState).selectedCountry = action.payload.entityDetails.detailInformation.countryRegion;
      }
    }
  } else if (isType(action, CuratedDataReceivedAction)) {
    // we have received curated data from the backend
    if (!state.curatedDataLoaded[action.payload.userSegment]) {
      newState.curatedData = action.payload.curatedData as any;

      const newCuratedDataLoadedState = copyState(state.curatedDataLoaded);
      newCuratedDataLoadedState[action.payload.userSegment] = true;
      newState.curatedDataLoaded = newCuratedDataLoadedState;
    }
  } else if (isType(action, EntityFullDataReceivedAction)) {
    let index = -1;

    if (action.payload.entityDetails) {
      index = state.idMap[action.payload.entityDetails.entityId.toString().toLowerCase()];
    }

    if (index >= 0) {
      const newData: IDataItem = action.payload.entityDetails;
      const newDataList = [...state.dataList];
      newDataList.splice(index, 1, newData);
      newState.dataList = newDataList;
    } else {
      newState.dataList = [...newState.dataList, action.payload.entityDetails];
      newState.idMap = generateHashMap(newState.dataList, ['entityId']);
    }
  } else if (isType(action, SearchQueryChangedAction)) {
    newState.subsetSearchQuery = action.payload.performedQuery;
  } else if (isType(action, SearchDataReceivedAction)) {
    newState.subsetData = [];
    newState.subsetSearchQuery = action.payload.performedQuery;
    const dataList = action.payload.entityIdData;
    if (dataList.length <= 0 || !state.dataLoaded) {
      return newState;
    }
    newState.subsetData = buildFullSearchData(
      dataList,
      state.dataList,
      state.idMap,
      getEntityRegistration(entityType).identifier
    );
  } else {
    return state;
  }
  return newState;
}
