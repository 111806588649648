import React from 'react';
import { mergeStyleSets, Spinner } from '@fluentui/react';
import propTypes from 'prop-types';
import classNames from 'classnames';

const styles = mergeStyleSets({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '50vh',
  },
});

const LoadingSpinner: React.FC = (_: never, context: { loc: (key: string, fallback: string) => string }) => (
  <div className={classNames(['loading', styles.container])}>
    <Spinner label={context.loc('Gallery_Loading', 'Loading...')} ariaLive="assertive" labelPosition="left" />
  </div>
);

LoadingSpinner.contextTypes = {
  loc: propTypes.func,
};

export default LoadingSpinner;
