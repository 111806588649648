import * as React from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { IState } from '@src/State';
import { IServiceTileProps, ServiceTile as ServiceTileComponent } from 'components/serviceTile';
import { getTelemetryDetailContentCurrentView } from '@shared/utils/appUtils';
import { createSetCheckoutSource } from '@shared/actions/checkoutActions';
import { CheckoutSource } from '@shared/Models';
import { ITileDispatchProps } from '@shared/components/commonTile';

const mapStateToProps = (state: IState, ownProps: IServiceTileProps) => {
  return {
    currentView: getTelemetryDetailContentCurrentView(state.config?.currentView, state.config?.currentGalleryViewIsCurated),
    query: state.config?.query,
    isAppSource: state.config?.isAppSource,
    isLoadingUserData: state.users?.loading,
    ribbonKey: ownProps.ribbonKey,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch): ITileDispatchProps => {
  return { setTileCheckoutSource: () => dispatch(createSetCheckoutSource({ source: CheckoutSource.Tile })) };
};

export const ServiceTile = connect(mapStateToProps, mapDispatchToProps)(ServiceTileComponent) as React.StatelessComponent<any>;
