import React from 'react';
import { Dialog, DialogFooter, Icon, mergeStyleSets, PrimaryButton, Stack, Text } from '@fluentui/react';
import { getReviewCommentsModalDialogProps } from '@shared/utils/reviewsUtils';
import { ReviewMarkAsHelpfulModalBaseDialogProps } from './reviewMarkAsHelpfulModal';
import { SharedColors } from '@fluentui/theme';

const ReviewMarkAsHelpfulModalStyles = mergeStyleSets({
  infoIcon: {
    color: SharedColors.red20,
    fontSize: '16px',
  },
  loader: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2200,
  },
});

export function ReviewMarkAsHelpfulModalErrorDialog({ context, dismissModal, payload }: ReviewMarkAsHelpfulModalBaseDialogProps) {
  const { modalProps, dialogContentProps, maxWidth } = getReviewCommentsModalDialogProps(
    context.loc('ReviewMarkAsHelpfulModal_UpdateError_Title', 'Something went wrong')
  );

  return (
    <Dialog
      hidden={!payload.showModal}
      onDismiss={dismissModal}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
      maxWidth={maxWidth}
    >
      <Stack role="dialog" horizontal tokens={{ childrenGap: 10 }}>
        <Icon iconName="info" className={ReviewMarkAsHelpfulModalStyles.infoIcon} />
        <Text>
          {context.loc(
            'ReviewMarkAsHelpfulModal_UpdateError_Description',
            'There was a problem marking the review as helpful. Please try again.'
          )}
        </Text>
      </Stack>
      <DialogFooter>
        <PrimaryButton
          onClick={dismissModal}
          text={context.loc('ReviewMarkAsHelpfulModal_UpdateError_Close_Button_Label', 'Close')}
        />
      </DialogFooter>
    </Dialog>
  );
}
