import { Action, isType, DynamicCampaignReceivedAction } from './../actions/actions';
import { IDynamicCampaignState, initialDynamicCampaignState, copyState } from './../../State';

export default function campaignReducer(
  state: IDynamicCampaignState = initialDynamicCampaignState,
  action: Action<any>
): IDynamicCampaignState {
  let newState = copyState(state);
  if (isType(action, DynamicCampaignReceivedAction)) {
    newState = action.payload.campaignInfo;
  } else {
    return state;
  }
  return newState;
}
