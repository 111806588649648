import * as React from 'react';
import * as PropTypes from 'prop-types';
import SpzaComponent from './../spzaComponent';
import { ILocContext, ICommonContext } from '../../interfaces/context';
import { IDisclaimer } from './../../Models';
import { Constants } from '../../utils/constants';

interface IDisclaimerModalProps {
  payload: IDisclaimer;
  dismissModal: () => void;
}

export default class DisclaimerModal extends SpzaComponent<IDisclaimerModalProps, any> {
  public context: ILocContext & ICommonContext;

  constructor(props: IDisclaimerModalProps, context: ILocContext & ICommonContext) {
    super(props, context);
  }

  renderImpl() {
    return (
      <div role="dialog" aria-label="disclaimer is now open" className="disclaimerModalClass">
        <div className="prompContainer">
          <div className="title">{this.props.payload.title}</div>
          <div className="description">{this.props.payload.description}</div>
          <div className="footer">
            <button
              name="button"
              className="c-button requestButton"
              type="submit"
              data-bi-id={Constants.JsllCTAId.Continue}
              data-bi-area="Disclaimer Dialog"
              onClick={this.props.dismissModal}
            >
              {this.context.loc('Dialog_Continue')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

(DisclaimerModal as any).contextTypes = {
  loc: PropTypes.func,
  renderErrorModal: PropTypes.func,
};
