import React from 'react';
import { IFontStyles } from '@fluentui/react';
import { IFilterIcon, IItemData } from '@shared/Models';
import { ItemsContainerComponent } from '@shared/components/itemsContainer';

interface IProductsComponentProps {
  productsItems: IFilterIcon[];
  wrap?: boolean;
  withText?: boolean;
  textSize?: keyof Pick<IFontStyles, 'smallPlus' | 'medium'>;
  withTooltip?: boolean;
  tooltipTitle?: string;
}

const ProductsComponent: React.FunctionComponent<IProductsComponentProps> = ({
  productsItems,
  tooltipTitle,
  textSize = 'medium',
  wrap = false,
  withText = false,
  withTooltip = false,
}: IProductsComponentProps) => {
  const items: IItemData[] = React.useMemo(
    () =>
      productsItems.map((item) => {
        return { icons: [item.urlKey], title: item.title };
      }),
    [productsItems]
  );

  return (
    <ItemsContainerComponent
      items={items}
      textSize={textSize}
      tooltipTitle={tooltipTitle}
      withText={withText}
      withTooltip={withTooltip}
      wrap={wrap}
      withDivider={false}
    />
  );
};

export default ProductsComponent;
