/* eslint-disable react/forbid-dom-props */
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { TooltipHost } from '@fluentui/react';
import { v4 } from 'uuid';

import { getUserFavouriteEntityServiceType } from '@shared/utils/userFavouriteDedicatedUtils';
import SpzaComponent from '@shared/components/spzaComponent';
import { IURLParam, IURLQuery, IBoldString } from '@shared/Models';
import {
  IBuildHrefContext,
  ILocContext,
  ILocParamsContext,
  ICTACallbackContext,
  ICommonContext,
  IOpenTileCallbackContext,
} from '@shared/interfaces/context';
import { IUserFavouriteItem } from '@shared/interfaces/userFavouriteModels';
import { BaseTile } from '@shared/containers/baseTile';
import { Constants } from '@shared/utils/constants';
import { generateTileClickPayloadAndLogTelemetry, getProductsList } from '@shared/utils/detailUtils';
import { getComponentXYCoordinate, getTileComponentRank } from '@shared/utils/reactUtils';
import { routes } from '@shared/routerHistory';
import {
  findBestMatchCountryCode,
  getReplacedCountryQueryParam,
  createCountryFilterQueryParam,
  getProductTitleForTile,
  getStartingPriceHeader,
} from '@shared/utils/consultingServicesUtils';
import { Service } from '@shared/serviceViewModel';
import { stripHTML } from '@shared/utils/appUtils';
import IntegratedSolution from '@shared/images/badges/IntegratedSolution.svg';
import AzureExpertsMSPBadge from '@shared/images/badges/AzureExpertsMSP.svg';
import { ITileDispatchProps } from '@shared/components/commonTile';

export interface IServiceTileProps extends Service, ITileDispatchProps {
  item: IUserFavouriteItem;
  serviceTypes: number;
  customCSSClass?: string;
  tileIndex: number;
  totalTiles: number;
  publisher: string;
  extraData: any;
  selectedLocale: any;
  currentView?: string;
  countryContext: string;
  query: IURLQuery;
  isAppSource?: boolean;
  ribbonKey?: string;
}

export class ServiceTile extends SpzaComponent<IServiceTileProps, any> {
  context: IBuildHrefContext & ILocContext & ILocParamsContext & ICTACallbackContext & ICommonContext & IOpenTileCallbackContext;

  componentInstance = v4();

  constructor(
    props: IServiceTileProps,
    context: IBuildHrefContext & ILocContext & ILocParamsContext & ICTACallbackContext & ICommonContext & IOpenTileCallbackContext
  ) {
    super(props, context);
    this.getReplacedCountryQueryParamIfNeeded = this.getReplacedCountryQueryParamIfNeeded.bind(this);
  }

  getService(e: Event) {
    e.stopPropagation();
    const { setTileCheckoutSource } = this.props;

    const reference = this.refs['appTile' + this.props.entityId];
    const coordinate = getComponentXYCoordinate(reference);
    const tileRank = getTileComponentRank(reference);
    const { ribbonKey = '' } = this.props;

    setTileCheckoutSource();

    const details = {
      id: this.props.entityId,
      rank: JSON.stringify(tileRank),
      xOffset: coordinate.x,
      yOffset: coordinate.y,
      CTAType: this.props.extraData?.serviceActionType || '',
      currentView: this.props.currentView,
    };

    generateTileClickPayloadAndLogTelemetry(Constants.Telemetry.ActionModifier.ServiceTileCTAButton, details);
    this.context.ctaCallback({
      entity: this.props,
      entityType: Constants.EntityType.Service,
      ctaType: Constants.CTAType.Service,
      actionModifier: Constants.Telemetry.ActionModifier.ServiceTileCTAButton,
      ribbonKey,
    });
  }

  shouldComponentUpdate(nextProps: IServiceTileProps) {
    return (
      this.props.entityId !== nextProps.entityId ||
      this.props.currentView !== nextProps.currentView ||
      this.props.countryContext !== nextProps.countryContext
    );
  }

  renderPrice(): IBoldString {
    const { extraData, countryContext, selectedLocale } = this.props;
    const bold = getStartingPriceHeader(extraData?.multiCountryPricing, countryContext, selectedLocale, this.context);

    return { bold };
  }

  renderBuiltForItem(): JSX.Element {
    return <div>{getProductTitleForTile(this.props.products)}</div>;
  }

  getReplacedCountryQueryParamIfNeeded(): IURLParam {
    // Play defense - return alternative country only if we can find one.
    if (this.props.extraData && this.props.extraData.multiCountryPricing && this.props.extraData.multiCountryPricing[0]) {
      // No country context - use first country as default.
      if (!this.props.countryContext) {
        const firstCountry = this.props.extraData.multiCountryPricing[0].countryRegion;
        let replaceCountryParam: IURLParam = getReplacedCountryQueryParam(this.props.query, firstCountry);
        // No country query param to replace - create new one.
        if (!replaceCountryParam) {
          replaceCountryParam = createCountryFilterQueryParam(firstCountry, this.props.isAppSource);
        }
        return replaceCountryParam;
      }
      const foundCountryCode = findBestMatchCountryCode(this.props.extraData.multiCountryPricing, this.props.countryContext);
      // If there is mismatch between country context and what we found in the service. replace the country param.
      if (foundCountryCode !== this.props.countryContext && this.props.query) {
        return getReplacedCountryQueryParam(this.props.query, foundCountryCode);
      }
    }
    return null;
  }

  renderBadge(): JSX.Element {
    const AzureExpertsMSP = this.props.tags?.length > 0 && this.props.tags.indexOf(Constants.BadgeType.AzureExpertsMSP) >= 0;
    const hasIntSolutionBadge = Object.prototype.hasOwnProperty.call(Constants.ServicesIdWithBadge, this.props.entityId);
    const tooltipText = this.context.loc('MicrosoftIntegratedSolution_badge', 'Microsoft Integrated Solution badge');
    if (!hasIntSolutionBadge && !AzureExpertsMSP) {
      return null;
    }
    return (
      <div className={'tileBadgeContainer'}>
        {hasIntSolutionBadge && (
          <div className="serviceTileBadge" tabIndex={0}>
            <TooltipHost content={tooltipText}>
              <img src={IntegratedSolution} alt={tooltipText} />
            </TooltipHost>
          </div>
        )}
        {AzureExpertsMSP && (
          <div className="expertBadge">
            <img
              className="tileBadge"
              src={AzureExpertsMSPBadge}
              title={this.context.loc('AzureExpertsMSP_Badge', 'Azure Experts MSP')}
              alt={this.context.loc('AzureExpertsMSP_Badge', 'Azure Experts MSP')}
            />
          </div>
        )}
      </div>
    );
  }

  getCTAData() {
    const ctaText = this.props.extraData?.serviceActionType || '';
    return (
      ctaText && {
        id: Constants.JsllCTAId.ContactMe,
        title: this.context.loc('CTA_Services'),
        area: 'Services Tile',
        onClick: this.getService.bind(this),
        entityId: this.props.entityId,
      }
    );
  }

  getProducts() {
    return this.props?.products && Object.keys(this.props.products).length > 0 && getProductsList(this.props.products);
  }

  renderImpl() {
    const replaceCountryParams: IURLParam = this.getReplacedCountryQueryParamIfNeeded();
    const serviceDetailUrl = this.context.buildHref(
      routes.serviceDetail,
      { entityId: this.props.entityId },
      replaceCountryParams,
      true
    );
    const { ribbonKey = '' } = this.props;

    return (
      <BaseTile
        totalTiles={this.props.totalTiles}
        id={this.props.entityId}
        componentInstance={this.componentInstance}
        item={this.props.item}
        iconURL={this.props.extraData && this.props.extraData.smallIconUri}
        title={this.props.title}
        description={stripHTML(this.props.extraData?.shortDescription ?? '')}
        publisher={this.props.publisher}
        ctaData={this.getCTAData()}
        detailUrl={serviceDetailUrl}
        tileIndex={this.props.tileIndex}
        currentView={this.props.currentView}
        userFavouriteEntityType={getUserFavouriteEntityServiceType()}
        price={this.renderPrice()}
        badge={this.renderBadge()}
        openTileCallback={this.context.openTileCallback}
        products={this.getProducts()}
        context={this.context}
        ribbonKey={ribbonKey}
      />
    );
  }
}

(ServiceTile as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  buildHref: PropTypes.func,
  ctaCallback: PropTypes.func,
  renderErrorModal: PropTypes.func,
  openTileCallback: PropTypes.func,
};
