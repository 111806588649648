import React, { FunctionComponent, useEffect, useState } from 'react';
import { IAppReview } from '@shared/Models';
import { Constants } from '@shared/utils/constants';
import { launchTelemetry } from '@shared/utils/reviewsUtils';
import { AppReviewItemCommentComposer } from '@shared/components/appReviewItemCommentComposer';
import { IAppReviewCommentsState, IReviewCommentOperationResponse } from '@shared/interfaces/reviews/comments';
import { logger } from '@src/logger';
import { AppReviewItemCommentCollectionListSection } from '@shared/components/appReviewCommentCollection/appReviewItemCommentCollectionListSection';

export interface IAppReviewCommentCollectionBaseArgs {
  offerId: string;
  reviewId: string;
}

export interface IAppReviewCommentCollectionLoadDataArgs extends IAppReviewCommentCollectionBaseArgs {
  page: number;
  itemsPerPage: number;
}

export interface IAppReviewCommentCollectionCreateCommentArgs extends IAppReviewCommentCollectionBaseArgs {
  content: string;
  addToNewlyAdded: boolean;
}

export interface IAppReviewCommentCollectionEditCommentArgs extends IAppReviewCommentCollectionBaseArgs {
  commentId: string;
  updatedContent: string;
  isNewlyAddedComment: boolean;
}

export interface IAppReviewCommentCollectionDeleteCommentArgs extends IAppReviewCommentCollectionBaseArgs {
  commentId: string;
  isNewlyAddedComment: boolean;
}

export interface IAppReviewCommentCollectionOpenModalArgs {
  modalId: number;
}

export interface IAppReviewCommentCollectionEnsureStateInitializedArgs extends IAppReviewCommentCollectionBaseArgs {
  totalComments: number;
}

export interface IAppReviewCommentCollectionProps {
  comments?: IAppReviewCommentsState;
  expanded: boolean;
  review: IAppReview;
  loadData?: (args: IAppReviewCommentCollectionLoadDataArgs) => Promise<void>;
  createComment?: (args: IAppReviewCommentCollectionCreateCommentArgs) => Promise<IReviewCommentOperationResponse>;
  editComment?: (args: IAppReviewCommentCollectionEditCommentArgs) => Promise<IReviewCommentOperationResponse>;
  deleteComment?: (args: IAppReviewCommentCollectionDeleteCommentArgs) => Promise<IReviewCommentOperationResponse>;
  clearData?: (args: IAppReviewCommentCollectionBaseArgs) => Promise<void>;
  locale?: string;
  signedIn?: boolean;
  openModal?: (args: IAppReviewCommentCollectionOpenModalArgs) => void;
  ensureStateInitialized?: (args: IAppReviewCommentCollectionEnsureStateInitializedArgs) => Promise<void>;
  unloadPages?: (args: IAppReviewCommentCollectionBaseArgs) => void; // Action for going back to the first page
  isStateInitialized?: boolean;
}

export const AppReviewCommentCollection: FunctionComponent<IAppReviewCommentCollectionProps> = ({
  comments = null,
  expanded,
  review,
  loadData,
  clearData,
  locale,
  signedIn,
  openModal,
  createComment,
  deleteComment,
  editComment,
  ensureStateInitialized,
  unloadPages,
  isStateInitialized = false,
}: IAppReviewCommentCollectionProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [composeText, setComposeText] = useState('');

  /**
   * Triggers loading of comments for the current page, if necessary,
   * e.g. during an initial expansion of the component, page navigation.
   */
  const loadCurrentPageDataIfNeeded = () => {
    if (expanded && loadData && comments) {
      // Load the current page if there are no comments, if the initial loading was never triggered,
      // or if the user navigated to the next page which hasn't yet bee loaded.
      if (!comments.commentIds.length || currentPage > comments.currentLoadedPage) {
        loadData({
          offerId: review.offer_id,
          reviewId: review.id,
          page: currentPage,
          itemsPerPage: Constants.Reviews.CommentsPerPage,
        });
      }
    }
  };

  const loadNextPage = () => {
    // If we already loaded enough data, simply append the visible page - otherwise, append both
    setCurrentPage((prev) => prev + 1);
  };

  const resetPagination = () => {
    unloadPages({ offerId: review.offer_id, reviewId: review.id });
    setCurrentPage(1);
  };

  const onSeeLessClick = () => {
    resetPagination();
    launchTelemetry(Constants.Telemetry.Action.Click, Constants.Telemetry.ActionModifier.ReviewItemCommentsSeeLess, {
      reviewId: review?.id,
      offerId: review?.offer_id,
    });
    logger.info(
      JSON.stringify({
        reviewId: review?.id,
        offerId: review?.offer_id,
      }),
      {
        action: Constants.Telemetry.Action.Click,
        actionModifier: Constants.Telemetry.ActionModifier.ReviewItemCommentsSeeLess,
      }
    );
  };

  const onSeeMoreClick = () => {
    loadNextPage();
    launchTelemetry(Constants.Telemetry.Action.Click, Constants.Telemetry.ActionModifier.ReviewItemCommentsSeeMore, {
      reviewId: review?.id,
      offerId: review?.offer_id,
    });
    logger.info(
      JSON.stringify({
        reviewId: review?.id,
        offerId: review?.offer_id,
      }),
      {
        action: Constants.Telemetry.Action.Click,
        actionModifier: Constants.Telemetry.ActionModifier.ReviewItemCommentsSeeMore,
      }
    );
  };

  const openSignInModal = () => {
    openModal({ modalId: Constants.ModalType.SignIn });
    launchTelemetry(Constants.Telemetry.Action.Click, Constants.Telemetry.ActionModifier.ReviewItemCommentsSignIn, {
      reviewId: review?.id,
      offerId: review?.offer_id,
    });
    logger.info(
      JSON.stringify({
        reviewId: review?.id,
        offerId: review?.offer_id,
      }),
      {
        action: Constants.Telemetry.Action.Click,
        actionModifier: Constants.Telemetry.ActionModifier.ReviewItemCommentsSignIn,
      }
    );
  };

  const shouldNewCommentEnterNewlyAddedList = () => {
    return currentPage < comments.totalPages;
  };

  const onSubmitComment = async (content: string) => {
    if (createComment) {
      try {
        const res = await createComment({
          offerId: review.offer_id,
          reviewId: review.id,
          addToNewlyAdded: shouldNewCommentEnterNewlyAddedList(),
          content,
        });
        if (!res?.error) {
          setComposeText('');
          launchTelemetry(
            Constants.Telemetry.Action.Submitted,
            Constants.Telemetry.ActionModifier.ReviewItemCommentCreationSubmit,
            {
              reviewId: review?.id,
              offerId: review?.offer_id,
              content,
            }
          );
          logger.info(
            JSON.stringify({
              reviewId: review?.id,
              offerId: review?.offer_id,
              content,
            }),
            {
              action: Constants.Telemetry.Action.Submitted,
              actionModifier: Constants.Telemetry.ActionModifier.ReviewItemCommentCreationSubmit,
            }
          );
        }
      } catch (e) {
        launchTelemetry(
          Constants.Telemetry.Action.SubmitError,
          Constants.Telemetry.ActionModifier.ReviewItemCommentCreationSubmit,
          {
            reviewId: review?.id,
            offerId: review?.offer_id,
            error: `Encountered an error while attempting to create a comment, error: ${e}`,
          }
        );
        logger.error(
          JSON.stringify({
            reviewId: review?.id,
            offerId: review?.offer_id,
            error: `Encountered an error while attempting to create a comment, error: ${e}`,
          }),
          {
            action: Constants.Telemetry.Action.SubmitError,
            actionModifier: Constants.Telemetry.ActionModifier.ReviewItemCommentCreationSubmit,
          }
        );
      }
    }
  };

  const onComposerTextChange = (newContent: string) => {
    setComposeText(newContent);
  };

  useEffect(() => {
    if (expanded) {
      ensureStateInitialized({ offerId: review.offer_id, reviewId: review.id, totalComments: review.comments_count });
    }
    loadCurrentPageDataIfNeeded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded, currentPage, isStateInitialized]);

  useEffect(() => {
    if (isStateInitialized && comments.currentLoadedPage) {
      clearData({ offerId: review.offer_id, reviewId: review.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedIn]);

  useEffect(() => {
    return () => {
      clearData({ offerId: review.offer_id, reviewId: review.id });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    expanded &&
    comments !== null && (
      <div>
        <AppReviewItemCommentCollectionListSection
          comments={comments}
          currentPage={currentPage}
          locale={locale}
          totalPageCount={comments.totalPages}
          onSeeMoreClick={() => onSeeMoreClick()}
          onSeeLessClick={() => onSeeLessClick()}
          onCommentDelete={(commentId, isNewlyAddedComment) => {
            deleteComment({ commentId, offerId: review.offer_id, reviewId: review.id, isNewlyAddedComment });
          }}
          onCommentEdit={(commentId, updatedContent, isNewlyAddedComment) => {
            editComment({ offerId: review.offer_id, reviewId: review.id, commentId, isNewlyAddedComment, updatedContent });
          }}
        />
        <AppReviewItemCommentComposer
          onChange={(content) => onComposerTextChange(content)}
          signedIn={signedIn}
          openSignInModal={() => openSignInModal()}
          onSubmit={() => {
            onSubmitComment(composeText);
          }}
          initialContent={composeText}
        />
      </div>
    )
  );
};
