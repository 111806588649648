import React from 'react';
import type { FC } from 'react';
import { useSelector } from 'react-redux';

import { Constants, Environment } from '@shared/utils/constants';
import { IState } from '@src/State';
import { getLocalStorageItem } from '@shared/utils/browserStorageUtils';
import { useMsClarityIdentify, useMsClarityTracking } from '@shared/hooks';

// Learn more about clarity API here: https://learn.microsoft.com/en-us/clarity/setup-and-installation/clarity-api
export const IClarityFunctionNames = {
  IDENTIFY: 'identify',
  SET: 'set',
  CONSENT: 'consent',
  UPGRADE: 'upgrade',
} as const;

interface IMsClarityProps {
  allowTracking: boolean;
}

export const MsClarityEnabledEnvironments: Environment[] = [Environment.Canary, Environment.Production, Environment.Development];

export const MsClarity: FC<IMsClarityProps> = ({ allowTracking }) => {
  const { correlationId } = useSelector((state: IState) => state.config);
  const spzaId = getLocalStorageItem(Constants.LocalStorage.spzaUserId);

  useMsClarityTracking({ allowTracking });
  useMsClarityIdentify({ spzaId, correlationId });

  return <></>;
};
