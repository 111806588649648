import {
  getSessionStorageItem,
  getLocalStorageItem,
  saveSessionStorageItem,
  saveLocalStorageItem,
} from '@shared/utils/browserStorageUtils';

export const POPUP_DISPLAYED = 'PopupDisplayed';

// The different types of popups - add new popup here.
export enum PopupType {
  LinkedinTeachingBubble = 'LinkedinTeachingBubble',
}

export interface IPopupDisplayLogic {
  maxDisplayCount: number; // Maximum number popup displays to user.
  DisplayOnceInSession: boolean; // If true should popup once in session.
}

// Map used to define the display logic for each popup.
const popups = new Map<PopupType, IPopupDisplayLogic>([
  [PopupType.LinkedinTeachingBubble, { maxDisplayCount: 5, DisplayOnceInSession: true }],
]);

function getTotalDisplayCount(popupType: PopupType): number {
  const storageDisplayCount: string = getLocalStorageItem(popupType);
  return storageDisplayCount ? parseInt(storageDisplayCount) : 0;
}

function didPopupDisplayedInThisSession(popupType: PopupType): boolean {
  const displayedInSession: string = getSessionStorageItem(popupType);
  return POPUP_DISPLAYED === displayedInSession;
}

function didPopupExceededMaxCount(popupType: PopupType, displayLogic: IPopupDisplayLogic): boolean {
  return displayLogic.maxDisplayCount <= getTotalDisplayCount(popupType);
}

// True if popup should be displayed according to its display history and display logic.
export function shouldDisplayPopup(popupType: PopupType): boolean {
  const displayLogic = popups.get(popupType);
  if (displayLogic.DisplayOnceInSession) {
    return !didPopupDisplayedInThisSession(popupType) && !didPopupExceededMaxCount(popupType, displayLogic);
  } else {
    return !didPopupExceededMaxCount(popupType, displayLogic);
  }
}

// Call when popup displayed. This call update the popup's display history.
export function popupDisplayed(popupType: PopupType): void {
  const displayLogic = popups.get(popupType);
  const updatedTotalDisplayCount = getTotalDisplayCount(popupType) + 1;
  if (displayLogic.DisplayOnceInSession) {
    saveSessionStorageItem(popupType, POPUP_DISPLAYED);
  }
  saveLocalStorageItem(popupType, updatedTotalDisplayCount.toString());
}
