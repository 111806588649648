import * as httpProtocol from '@shared/services/http/httpProtocol';
import { IAppReview } from '@shared/Models';
import { logError } from '@shared/utils/httpClientUtil';
import { UserReviewFormData } from '@shared/interfaces/reviews/models';

const commonHttpOptions: httpProtocol.IHttpOption = {
  requireCorrelationId: true,
  stringifyPostData: false,
  authenticationType: httpProtocol.AuthenticationTypes.Spza,
  setBearerHeader: true,
};

interface UserReviewCommonArgs {
  reviewsAPI: string;
  reviewsAPIVersion: string;
}

interface UserReviewSourceSensitiveArgs {
  source: string;
}

export const UserReviewQueryStrings = {
  OfferId: 'offer_id',
  ApiVersion: 'api-version',
  Source: 'source',
  ReviewId: 'review_id',
};

export async function get({
  offerId,
  reviewsAPI,
  reviewsAPIVersion,
  source,
}: UserReviewCommonArgs & { source: string; offerId: string }): Promise<IAppReview[]> {
  try {
    return await httpProtocol
      .get(`${reviewsAPI}/private/user`, commonHttpOptions)
      .addQueryEntry(UserReviewQueryStrings.OfferId, offerId)
      .addQueryEntry(UserReviewQueryStrings.Source, source)
      .addQueryEntry(UserReviewQueryStrings.ApiVersion, reviewsAPIVersion)
      .request();
  } catch (e) {
    logError('userReviewRestClient.getUserReview', e);
    throw e;
  }
}

export function submit({
  reviewsAPI,
  reviewsAPIVersion,
  content,
  isAnonymous,
  rating,
  reviewId,
  sendEmailConsent,
  title,
  source,
  offerId,
}: UserReviewCommonArgs & UserReviewFormData & UserReviewSourceSensitiveArgs) {
  try {
    return httpProtocol
      .post(`${reviewsAPI}/private`, commonHttpOptions)
      .addQueryEntry(UserReviewQueryStrings.ApiVersion, reviewsAPIVersion)
      .setData({
        content: content,
        is_anonymous: isAnonymous,
        rating,
        id: reviewId,
        title,
        send_email_consent: sendEmailConsent,
        source,
        offer_id: offerId,
        offer_type: 'Application',
      })
      .request();
  } catch (e) {
    logError('userReviewRestClient.updateReview', e);
    throw e;
  }
}

export async function update({
  reviewsAPI,
  reviewsAPIVersion,
  content,
  isAnonymous,
  rating,
  reviewId,
  sendEmailConsent,
  title,
}: UserReviewCommonArgs & UserReviewFormData) {
  try {
    return await httpProtocol
      .patch(`${reviewsAPI}/private/update`, commonHttpOptions)
      .addQueryEntry(UserReviewQueryStrings.ApiVersion, reviewsAPIVersion)
      .setData({
        content: content,
        is_anonymous: isAnonymous,
        rating,
        id: reviewId,
        title,
        send_email_consent: sendEmailConsent,
      })
      .request();
  } catch (e) {
    logError('userReviewRestClient.updateReview', e);
    throw e;
  }
}

export async function remove({ reviewsAPI, reviewsAPIVersion, reviewId }: UserReviewCommonArgs & { reviewId: string }) {
  try {
    return await httpProtocol
      .del(`${reviewsAPI}/private/delete_my_review`, commonHttpOptions)
      .addQueryEntry(UserReviewQueryStrings.ApiVersion, reviewsAPIVersion)
      .addQueryEntry(UserReviewQueryStrings.ReviewId, reviewId)
      .request();
  } catch (e) {
    logError('userReviewRestClient.updateReview', e);
    throw e;
  }
}
