import * as React from 'react';
import * as PropTypes from 'prop-types';
import SpzaComponent from './spzaComponent';
import { InternalLink } from './internalLink';
import { ILocContext, IBuildHrefContext, ICommonContext } from '../interfaces/context';
import { Constants } from '../utils/constants';
import { focusElement } from '../utils/appUtils';
import { getEntityRegistration } from '../utils/entityRegistration';
import { IURLQuery } from 'Models';
import { getNpsModule } from '@appsource/utils/nps';

const classNames = require('classnames-minimal');

export interface IPaginationControl {
  query: IURLQuery;
  maxPages: number;
  galleryPage: number;
  entityType: Constants.EntityType;
}

export default class PaginationControl extends SpzaComponent<IPaginationControl, any> {
  context: IBuildHrefContext & ILocContext & ICommonContext;

  getPageNavigationArray() {
    const pageArray: number[] = [];
    const pageSpan = 4;
    if (this.props.maxPages > 1) {
      let min = this.props.galleryPage - 2;
      if (min < 1) {
        min = 1;
      }
      let max = min + pageSpan; // there should only be a total of pageSpan + 1 'pages' visible
      if (max > this.props.maxPages) {
        max = this.props.maxPages;
      }

      if (max - min < pageSpan) {
        min = max - pageSpan;
        if (min < 1) {
          min = 1;
        }
      }

      for (let i = min; i <= max; i++) {
        pageArray.push(i);
      }
    }

    return pageArray;
  }

  handlePageChange() {
    focusElement(Constants.SearchBy.Id, 'pagechange_id');
    getNpsModule()?.increaseActionsCounter('paginationActionsCount');
  }

  getPageAriaLabel(page: number): string {
    if (this.props.query && this.props.query.page === page.toString()) {
      return 'Page ' + page + ' current page';
    }
    return 'Page ' + page;
  }

  renderImpl() {
    const { galleryPage, maxPages, entityType } = this.props;
    const pageArray = this.getPageNavigationArray();
    const route = getEntityRegistration(entityType).route;
    const getPageLink = (n: number) => this.context.buildHref(route, null, { page: n.toString() }, true);

    return (
      <div className="paginationContainer">
        {pageArray.length > 1 ? (
          <ul
            className="m-pagination"
            tabIndex={0}
            aria-label={this.context.loc('PAGINATION_Navigation', 'Pagination navigation')}
          >
            {galleryPage === 1 ? null : (
              <li>
                <InternalLink
                  className="c-glyph paginationPrevious"
                  aria-label="Previous Page"
                  href={getPageLink(galleryPage - 1)}
                  scrollToTop
                  accEnabled
                  additionalCommand={this.handlePageChange.bind(this)}
                >
                  {this.context.loc('PAGINATION_Previous', 'Previous')}
                </InternalLink>
              </li>
            )}
            {pageArray.map((e, i) => (
              <li
                key={i}
                className={classNames({
                  'f-active': e === galleryPage,
                })}
              >
                <InternalLink
                  href={getPageLink(e)}
                  aria-label={this.getPageAriaLabel(e)}
                  scrollToTop
                  accEnabled
                  additionalCommand={this.handlePageChange.bind(this)}
                >
                  {e}
                </InternalLink>
              </li>
            ))}
            {galleryPage === maxPages ? null : (
              <li>
                <InternalLink
                  className="c-glyph paginationNext"
                  aria-label="Next Page"
                  href={getPageLink(galleryPage + 1)}
                  accEnabled
                  scrollToTop
                  additionalCommand={this.handlePageChange.bind(this)}
                >
                  {this.context.loc('PAGINATION_Next', 'Next')}
                </InternalLink>
              </li>
            )}
          </ul>
        ) : null}
      </div>
    );
  }
}

(PaginationControl as any).contextTypes = {
  buildHref: PropTypes.func,
  loc: PropTypes.func,
  renderErrorModal: PropTypes.func,
};
