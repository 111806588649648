import {
  Action,
  isType,
  UserSignInAction,
  UserLoginDetailsAction,
  EmbedUserSignInAction,
  UserReviewUpdateAction,
  UserSegmentInitializeAction,
  UserSignOutAction,
  UserFieldHubTypeAction,
  UserProfileAction,
  UpdateUserProfileLicenseAction,
  UpdateUserProfileAction,
  ClearUserPropertiesAction,
  UserOrdersAction,
  SetUserOrdersLoadingAction,
  UserPrivateOffersAction,
  SetUserPrivateOffersLoadingAction,
  UserTenantsDetailsAction,
  UserTenantsDetailsLoadingAction,
  SetAccessTokenAction,
  UserProfileLoadingAction,
  UpdateUserRoleDefinitions,
  AddSubscriptionsInTenant,
  UserSignInFetchStatusAction,
  UserSignInStatusDataAction,
  UserSignInStatusAction,
  UserAccesstokensAction,
  SetAccountAction,
  SetAccountIsLoadingAction,
  SetIsMaccTenantAction,
} from './../actions/actions';
import { IUserDataState, initialUserDataState, copyState } from './../../State';
import { Constants } from '../utils/constants';
import { IAppDataItem } from './../Models';
import { pick } from 'lodash-es';

export default function userDataReducer(state: IUserDataState = initialUserDataState, action: Action<any>): IUserDataState {
  const newState = copyState(state);
  newState.profile = copyState(state.profile);
  if (isType(action, UserSignInAction) || isType(action, EmbedUserSignInAction)) {
    newState.id = action.payload.id || '';
    newState.group = action.payload.group;
    newState.email = action.payload.email || '';
    newState.signedIn = action.payload.signedIn;
    newState.idToken = action.payload.idToken || '';
    newState.firstName = action.payload.firstName;
    newState.lastName = action.payload.lastName;
    newState.displayName = action.payload.displayName;
    newState.oid = action.payload.oid;
    newState.tid = action.payload.tid;
    newState.alternateEmail = action.payload.alternateEmail;
    newState.isMSAUser = action.payload.isMSAUser;
    newState.userSegment = action.payload.userSegment;
    newState.fieldHubUserType = action.payload.fieldHubUserType;
    newState.graphApi = action.payload.graphApi;

    if (!newState.displayName) {
      newState.displayName = (newState.firstName + ' ' + newState.lastName).trim();
      newState.email = newState.email.substr(newState.email.indexOf('#') + 1);
    }

    // Embedded scenario does not send access token in the required format. So we call a different action
    // so that it is formatted the right way.
    if (isType(action, EmbedUserSignInAction)) {
      newState.accessToken = {
        spza: action.payload.accessToken,
      };
    }
    if (isType(action, UserSignInAction)) {
      newState.accessToken = action.payload.accessToken;
    }
  } else if (isType(action, UserSignInStatusAction)) {
    return {
      ...state,
      ...pick(action.payload, ['signedIn', 'refreshToken', 'homeAccountId']),
    };
  } else if (isType(action, UserSignInStatusDataAction)) {
    return {
      ...state,
      ...pick(action.payload, ['signedIn', 'refreshToken', 'homeAccountId']),
    };
  } else if (isType(action, UserSignInFetchStatusAction)) {
    return {
      ...state,
      ...pick(action.payload, ['loading']),
    };
  } else if (isType(action, UserAccesstokensAction)) {
    return {
      ...state,
      accessToken: action.payload,
    };
  } else if (isType(action, UserLoginDetailsAction)) {
    return {
      ...state,
      ...action.payload,
    };
  } else if (isType(action, UpdateUserRoleDefinitions)) {
    newState.roleDefinitions = action.payload;
  } else if (isType(action, UserReviewUpdateAction)) {
    newState.hasReview = action.payload;
  } else if (isType(action, SetAccessTokenAction)) {
    newState.accessToken = action.payload;
  } else if (isType(action, AddSubscriptionsInTenant)) {
    newState.tenantsDetails.details.azureSubscriptions = action.payload;
  } else if (isType(action, UserSegmentInitializeAction)) {
    newState.userSegment = action.payload.userSegment;
  } else if (isType(action, UserSignOutAction)) {
    newState.id = '';
    newState.group = [''];
    newState.email = '';
    newState.signedIn = false;
    newState.idToken = '';
    newState.refreshToken = '';
    newState.firstName = '';
    newState.lastName = '';
    newState.displayName = '';
    newState.oid = '';
    newState.tid = '';
    newState.alternateEmail = '';
    newState.isMSAUser = false;
    newState.fieldHubUserType = Constants.FieldHubUserType.None;
    newState.phone = '';
    newState.company = '';
    newState.country = '';
    newState.profile.firstName = '';
    newState.profile.lastName = '';
    newState.profile.updateRequired = true;
    newState.profile.isLatestProfile = false;
    newState.profile.phone = '';
    newState.profile.email = '';
    newState.profile.title = '';
    newState.profile.company = '';
    newState.profile.country = '';
    newState.profile.uiRole = null;
    newState.profile.managedLicenses = [];

    if (!newState.displayName) {
      newState.displayName = newState.firstName + ' ' + newState.lastName;
      newState.email = newState.email.substr(newState.email.indexOf('#') + 1);
    }
  } else if (isType(action, UserFieldHubTypeAction)) {
    newState.fieldHubUserType = action.payload;
  } else if (isType(action, UserProfileLoadingAction)) {
    newState.isLoadingUserProfile = action.payload.isLoading;
  } else if (isType(action, UserProfileAction)) {
    newState.isLoadingUserProfile = false;
    newState.profile.firstName = action.payload.firstName;
    newState.profile.lastName = action.payload.lastName;
    newState.profile.updateRequired = action.payload.updateRequired === true;
    newState.profile.isLatestProfile = true;
    newState.profile.phone = action.payload.phone;
    newState.profile.email = action.payload.email;
    newState.profile.title = action.payload.title;
    newState.profile.company = action.payload.company;
    newState.profile.country = action.payload.country;
    newState.profile.uiRole = action.payload.uiRole;
    newState.profile.managedLicenses = action.payload.managedLicenses;
    if (action.payload.licenses && action.payload.licenses.length > 0) {
      newState.profile.licenses = [];
      for (let i = 0; i < action.payload.licenses.length; i++) {
        newState.profile.licenses.push(action.payload.licenses[`${i}`]);
      }
    }
  } else if (isType(action, UserTenantsDetailsAction)) {
    newState.tenantsDetails.details = { ...action.payload };
  } else if (isType(action, UserTenantsDetailsLoadingAction)) {
    newState.tenantsDetails.status = action.payload;
  } else if (isType(action, UpdateUserProfileAction)) {
    newState.profile.firstName = action.payload.firstName;
    newState.profile.lastName = action.payload.lastName;
    newState.profile.updateRequired = false;
    newState.profile.isLatestProfile = true;
    newState.profile.phone = action.payload.phone;
    newState.profile.title = action.payload.title;
    newState.profile.company = action.payload.company;
    newState.profile.country = action.payload.country;
    newState.profile.email = action.payload.email;
    newState.profile.uiRole = action.payload.uiRole;
    newState.profile.managedLicenses = action.payload.managedLicenses;
    // todo: post me update with license info (both of them)
  } else if (isType(action, UpdateUserProfileLicenseAction)) {
    if (action.payload.license) {
      if (newState.profile.licenses.length === Constants.dynamicsLicense.currentD365LicenseNumber) {
        if (action.payload.licenseType === Constants.Dynamics365LicenseType.dynamics365License) {
          newState.profile.licenses[Constants.Dynamics365LicenseIndex.dynamics365LicenseIndex].isDisputed =
            action.payload.license.isDisputed;
        }
        if (action.payload.licenseType === Constants.Dynamics365LicenseType.dynamics365forBusinessLicense) {
          newState.profile.licenses[Constants.Dynamics365LicenseIndex.dynamics365ForBusinessLicenseIndex].isDisputed =
            action.payload.license.isDisputed;
        }
      }
    }
  } else if (isType(action, ClearUserPropertiesAction)) {
    newState.phone = '';
  } else if (isType(action, UserOrdersAction)) {
    newState.purchases.hasPurchases = action.payload;
  } else if (isType(action, SetUserOrdersLoadingAction)) {
    newState.purchases.loading = action.payload;
  } else if (isType(action, UserPrivateOffersAction)) {
    const offers: IAppDataItem[] = action.payload.apps || [];
    newState.privateOffers.dataList = offers;
  } else if (isType(action, SetUserPrivateOffersLoadingAction)) {
    newState.privateOffers.loading = action.payload;
  } else if (isType(action, SetAccountAction)) {
    newState.accounts = action.payload;
  } else if (isType(action, SetAccountIsLoadingAction)) {
    newState.accounts.isLoading = action.payload.isLoading;
  } else if (isType(action, SetIsMaccTenantAction)) {
    newState.isMaccTenant = action.payload.isMacc;
  } else {
    return state;
  }

  return newState;
}
