/* eslint-disable react/forbid-dom-props */
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Pivot, PivotItem, mergeStyles } from '@fluentui/react';
import type { IPivotStyles } from '@fluentui/react';
import SpzaComponent from './spzaComponent';
import Ribbon, { IRibbonProps } from './ribbon';
import { AppTile } from '@shared/containers/appTile';
import { ICuratedSection, IAppDataItem, ITelemetryData, ICuratedSectionItem, IDataItem } from '@shared/Models';
import { IDataValues, IDataMap } from '@shared/utils/dataMapping';
import * as DetailUtils from '@shared/utils/detailUtils';
import { IBuildHrefContext, ICommonContext, ILocContext, ILocParamsContext } from '../interfaces/context';
import { urlReplace, routes, IRouteConfig, WithRouterProps } from '@shared/routerHistory';
import { Constants } from '@shared/utils/constants';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { getWindow } from '@shared/services/window';
import { IMatchingItemsAgainstActiveFilters } from '@shared/utils/filterModule';
import { NpsModule } from '@shared/utils/npsUtils';
import { addClassToElement, removeClassFromElements } from '@shared/utils/appUtils';
import { Service } from '@shared/serviceViewModel';
import { getFeaturedItemsByFilter, getPopularItems } from '@shared/utils/filterHelpers';
import FilterPillItem from '@shared/components/filterPillItem';
import { InternalLink } from '@shared/components/internalLink';
import { FiltersDropDown, FiltersDropDownCollections } from '@shared/components/filtersDropDown';
import { ExternalLink } from '@shared/components/externalLink';
import { getNpsModule } from '@appsource/utils/nps';
import { logger } from '@src/logger';
import { Testimonials } from './Testimonials';
import classNames from 'classnames';
import { listOfUrlKeysToShowInHomePageAppsource } from '@src/shared/oneTaxonomy';

export interface IHomeProps extends WithRouterProps {
  appList: IDataItem[];
  hashMap: any;
  featuredApps: ICuratedSection<IDataItem>;
  searchID: number;
  appSearchResults: IAppDataItem[];
  servicesSeachResults: Service[];
  searchQuery: string;
  allApps: IAppDataItem[];
  allServices: Service[];
  selectedLocale: string;
  urlQuery: any;
  appsCuratedData: { [id: string]: ICuratedSection<ICuratedSectionItem>[] };
  currentView?: string;
  showPopularity: boolean;
  servicesActiveFilters: IDataValues[];
  appsActiveFilters: IDataValues[];
  appsCount: number;
  servicesCount: number;
  dataMap: IDataMap;
  valueProposition: valueProposition;
  defaultTab: string;
  defaultSuccessStoryTab: string;
}

interface IFilterTabs {
  [filterTab: string]: {
    collection: IDataValues[];
    type: FiltersDropDownCollections;
    defaultDisplayName: string;
  };
}

interface valueProposition {
  imageUrl: string;
  title: string;
  description: string;
}

export interface IHomeState {
  resultSearchID: number; // the searchid that we are looking for
}
class Home extends SpzaComponent<IHomeProps, IHomeState> {
  context: IBuildHrefContext & ICommonContext & ILocContext & ILocParamsContext;
  private timeoutID = -1;
  private instrument: ReturnType<typeof SpzaInstrumentService.getProvider>;
  private activeProducts = 0;
  private biArea = 'homePage';

  constructor(props: IHomeProps, context: IBuildHrefContext & ICommonContext & ILocContext & ILocParamsContext) {
    super(props, context);
    this.instrument = SpzaInstrumentService.getProvider();
    this.state = {
      resultSearchID: 0,
    };
  }

  buildNavigateUrl<T>(route: IRouteConfig<T>, search?: string) {
    return this.context.buildHref(
      route,
      null,
      {
        category: this.props.urlQuery.category ? this.props.urlQuery.category : null,
        industry: this.props.urlQuery.industry ? this.props.urlQuery.industry : null,
        product: this.props.urlQuery.product ? this.props.urlQuery.product : null,
        search: search || null,
      },
      true
    );
  }

  telemetryComponentDidMount() {
    const window = getWindow();
    const { navigationStart = 0, requestStart = 0 } = getWindow()?.performance?.timing || {};

    if (window) {
      window.loadTimeHomeFilters = performance.now() - (requestStart - navigationStart);
    }
  }

  componentDidMount() {
    this.telemetryComponentDidMount();
    const perfPayload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.PageLoad,
      actionModifier: Constants.Telemetry.ActionModifier.End,
    };
    this.instrument.probe<ITelemetryData>('logTelemetryInfo', perfPayload);
    logger.info('', {
      action: perfPayload.action,
      actionModifier: perfPayload.actionModifier,
    });
    NpsModule.checkForNPS();
    if (document.getElementsByTagName('title')[0]) {
      document.getElementsByTagName('title')[0].innerHTML = this.context.loc('home_pageTitle');
    }
    removeClassFromElements('spza-accessibility-selected');
    addClassToElement(Constants.SearchBy.Id, 'uhfCatLogo', 'spza-accessibility-selected');
  }

  renderFilter(filter: IDataValues, category: string, showIcon: boolean) {
    const { isActive, UrlKey, ShortcutUrlKey, Title } = filter;
    const filterValue = (isActive ? '!' : '') + UrlKey;

    const otherProps = {
      'data-bi-id': UrlKey,
      'data-bi-name': Title,
      'data-bi-type': category,
      'data-bi-area': this.biArea,
    };

    return (
      <FilterPillItem
        filter={filter}
        isActive={isActive}
        category={category}
        queryValue={ShortcutUrlKey}
        accEnabled={true}
        role={'option'}
        href={this.context.buildHref(routes.home, null, {
          [category]: filterValue,
        })}
        className={'filterPanelItem'}
        filterTextClass={'noFocus'}
        iconClassName={showIcon ? 'icon-' + UrlKey + '-16' : ''}
        key={UrlKey}
        otherProps={otherProps}
        onClick={() => getNpsModule()?.increaseActionsCounter('filterActionsCount')}
      />
    );
  }

  sortFilters(filters: IDataValues[]) {
    return filters.sort((filterKeyA: IDataValues, filterKeyB: IDataValues) => {
      const titleA = filterKeyA.Title ? filterKeyA.Title.toUpperCase() : '';
      const titleB = filterKeyB.Title ? filterKeyB.Title.toUpperCase() : '';
      return titleA < titleB ? -1 : titleA > titleB ? 1 : 0;
    });
  }

  filterPanelRendering(filters: IDataValues[], category: string, productGroup: boolean) {
    this.sortFilters(filters);
    const document: JSX.Element[] = [];
    filters.forEach((filter: IDataValues) => {
      const showFilter = this.isShowFilter(productGroup, filter);

      if (showFilter) {
        document.push(this.renderFilter(filter, category, category !== 'product'));
      }
    });
    return document;
  }

  isShowFilter(productGroup: boolean, filter: IDataValues): boolean {
    return !(productGroup && filter.BackendKey === 'Office 365');
  }

  updateGalleryURL(products: IDataValues[], galleryURL: string) {
    this.activeProducts = 0;

    if (this.props.urlQuery.product && products) {
      const length = products.length;

      for (let i = 0; i < length; i++) {
        if (this.props.urlQuery.product.indexOf(products[`${i}`].ShortcutUrlKey) >= 0) {
          this.activeProducts++;

          if (this.activeProducts === 1) {
            const urlKey = products[`${i}`].UrlKey;

            galleryURL = this.context.buildHref(routes.marketplace, null, {
              product: urlKey,
            });
          } else if (this.activeProducts > 1) {
            galleryURL = this.context.buildHref(routes.marketplace, null, {
              product: null,
            });

            break;
          }
        }
      }
    }

    return galleryURL;
  }

  updateServiceGalleryURL(count: number, galleryURL: string) {
    if (this.activeProducts > 1) {
      return this.context.buildHref(routes.home, null, {
        product: null,
      });
    }
    return galleryURL;
  }

  /// /////////////////////////////// Filters Tabs ///////////////////////////////////////

  getTabHref(tabTitle: string) {
    return this.context.buildHref(routes.home, null, {
      filterTab: tabTitle,
      product: null,
      category: null,
      industry: null,
    });
  }

  changeTabCallback(tabTitle: string) {
    const newPath = this.getTabHref(tabTitle);

    urlReplace(newPath);

    DetailUtils.generateClickPayloadAndLogTelemetry(0, 'home', Constants.Telemetry.ActionModifier.Tab, { tab: tabTitle });
  }

  /// /////////////////////////////// Customer success story Tabs ///////////////////////////////////////

  viewMoreRecommendations() {
    const marginClass = mergeStyles({ marginTop: '32px' });
    const linkClass = classNames({ actionBannerButton: true, [marginClass]: true });
    return (
      <>
        <ExternalLink
          href="https://aka.ms/appsourcecustomerrecommendations"
          className={linkClass}
          accessibilityEnabled
          target="_blank"
          rel="noreferrer"
        >
          {this.context.loc('Testimonials_View_More', 'View more recommendations >')}
        </ExternalLink>
      </>
    );
  }

  getAllAppsBtn() {
    const seeAllUrl = this.context.buildHref(routes.marketplace, null, {
      category: null,
      industry: null,
      product: null,
      search: null,
    });
    return (
      <InternalLink
        className="actionBannerButton"
        href={seeAllUrl}
        accEnabled
        aria-label={this.context.loc('ValueProposition_ActionBanner_Button_AriaText')}
        data-bi-name={'actionBanner See all apps button'}
        data-bi-area={this.biArea}
        additionalCommand={() => getNpsModule()?.increaseActionsCounter('seeAllActionsCount')}
      >
        {this.context.loc('ValueProposition_ActionBanner_Button', 'See all apps')}
      </InternalLink>
    );
  }

  tilesTelemetry(details: string) {
    const perfPayload: ITelemetryData = {
      page: getWindow() && getWindow().location.href,
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.HomeApps,
      details,
    };
    this.instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', perfPayload);
    logger.info(perfPayload.details, {
      action: perfPayload.action,
      actionModifier: perfPayload.actionModifier,
    });
  }

  renderRibbon(apps: IDataItem[], ribbonData: IRibbonProps) {
    const { title, key } = ribbonData;
    if (apps.length > 0) {
      return (
        <Ribbon {...ribbonData} carousel>
          {apps.map((item: IDataItem, index: number) => (
            <AppTile
              {...item}
              item={item}
              key={item.entityId}
              tileIndex={index}
              totalTiles={apps.length}
              onOpenTile={() => this.tilesTelemetry(`${title} clicked: ${item.entityId}  of: ${apps.length}, with key: ${key}`)}
              ribbonKey={key}
            />
          ))}
        </Ribbon>
      );
    }
    return null;
  }

  renderImpl() {
    const context = this.context as any;
    const { defaultTab } = this.props;

    // home page needs both curated data and app data to exist, if it doesn't let's render a progress bar
    if (!this.props.allApps) {
      return null;
    }

    const dataMap: IDataMap = this.props.dataMap;

    const products = Object.keys(dataMap.products)
      .map((k) => dataMap.products[`${k}`])
      .filter((f) => listOfUrlKeysToShowInHomePageAppsource.includes(f.UrlKey));
    const categories = Object.keys(dataMap.categories.default)
      .map((k) => dataMap.categories.default[`${k}`])
      .filter((f) => listOfUrlKeysToShowInHomePageAppsource.includes(f.UrlKey));
    const industries = Object.keys(dataMap.industries)
      .map((k) => dataMap.industries[`${k}`])
      .filter((f) => listOfUrlKeysToShowInHomePageAppsource.includes(f.UrlKey));

    const galleryUrl = this.updateGalleryURL(products, this.buildNavigateUrl(routes.marketplace, this.props.searchQuery));
    const seeAllUrl = this.buildNavigateUrl(routes.marketplace);

    // featured apps swim lane
    const appRibbonData: IRibbonProps = {
      title: context.loc('CuratedType_FeaturedApps'),
      seeMoreUrl: seeAllUrl,
      seeMoreText: context.loc('Link_AllApps'),
      isSeeMoreButton: true,
      subHeader: '',
      key: 'CuratedType_FeaturedApps',
    };

    // popular apps swim lane
    const popularAppRibbonData: IRibbonProps = {
      title: context.loc('CuratedType_Most_Viewed_Apps'),
      seeMoreUrl: seeAllUrl,
      seeMoreText: context.loc('Link_AllApps'),
      isSeeMoreButton: true,
      subHeader: '',
      key: 'CuratedType_Most_Viewed_Apps',
    };

    let ribbonApps = (this.props.featuredApps && this.props.featuredApps.items) || [];
    const appResults: IMatchingItemsAgainstActiveFilters<IAppDataItem> = {
      activeFilters: this.props.appsActiveFilters,
      items: this.props.allApps,
    };

    // if filter active
    if (this.props.urlQuery.product || this.props.urlQuery.industry || this.props.urlQuery.category) {
      const activeFilters = this.props.appsActiveFilters.filter((f) => listOfUrlKeysToShowInHomePageAppsource.includes(f.UrlKey));
      const activeFiltersTitle = activeFilters.map((f) => f.Title)[0];
      const activeFiltersKey = activeFilters.map((f) => f.UrlKey)[0];

      // featured apps swim lane
      appRibbonData.seeMoreUrl = galleryUrl;
      appRibbonData.seeMoreText = context.locParams(
        'CuratedType_Apps_In_Link',
        [activeFiltersTitle],
        `See all ${activeFiltersTitle} apps`
      );
      appRibbonData.isSeeMoreButton = false;

      const featuredApps: IDataItem[] = getFeaturedItemsByFilter(
        appResults.activeFilters,
        this.props.appsCuratedData,
        this.props.hashMap,
        this.props.appList
      );

      appRibbonData.title = context.locParams(
        'CuratedType_FeaturedApps_Title_In',
        [activeFiltersTitle],
        `Featured apps in ${activeFiltersTitle}`
      );
      appRibbonData.key = `CuratedType_FeaturedApps_Title_In ${activeFiltersKey}`;
      ribbonApps = featuredApps;

      // popular apps swim lane
      popularAppRibbonData.seeMoreUrl = galleryUrl;
      popularAppRibbonData.seeMoreText = context.locParams(
        'CuratedType_Apps_In_Link',
        [activeFiltersTitle],
        `See all ${activeFiltersTitle} apps`
      );
      popularAppRibbonData.isSeeMoreButton = false;
      popularAppRibbonData.title = context.locParams(
        'CuratedType_Most_Viewed_Apps_Title_In',
        [activeFiltersTitle],
        `Most Viewed apps in ${activeFiltersTitle}`
      );
      popularAppRibbonData.key = `CuratedType_Most_Viewed_Apps_Title_In ${activeFiltersKey}`;
    }
    const ribbonPopularApps = getPopularItems(this.props.appList, ribbonApps);

    const valuePropositionImage = {
      backgroundImage: `url(${this.props.valueProposition.imageUrl})`,
    } as React.CSSProperties;

    const filterItems: IFilterTabs = {
      categories: { collection: categories, type: FiltersDropDownCollections.category, defaultDisplayName: 'Categories' },
      industries: { collection: industries, type: FiltersDropDownCollections.industry, defaultDisplayName: 'Industries' },
      products: { collection: products, type: FiltersDropDownCollections.product, defaultDisplayName: 'Products' },
    };

    const selectedTab = defaultTab && filterItems[`${defaultTab}`] ? defaultTab : Object.keys(filterItems)[0];
    const { collection: selectedFilterCollection, type: selectedFilterType } = filterItems[`${selectedTab}`];
    const tabItemDataProps = { 'data-bi-type': 'homeFilters', 'data-bi-area': this.biArea };
    const pivotStyles: Partial<IPivotStyles> = { linkIsSelected: { '&::before': { transition: 'none', left: 0, right: 0 } } };
    const { defaultSuccessStoryTab } = this.props;

    return (
      <div className="spza_homePage" role="main">
        <div id="maincontent" className="section homeTop" style={valuePropositionImage}>
          <div className="landingBackground"></div>
          <div className="valuePropositionDynamicContent">
            <div className="dynamicText">
              <h1 className="landingHeader">{this.context.loc(this.props.valueProposition.title)}</h1>
              <p className="landingSubHeader">{this.context.loc(this.props.valueProposition.description)}</p>
              <div className="mobileButton">{this.getAllAppsBtn()}</div>
            </div>
          </div>
        </div>
        <div className="homeValuePropositionActionBanner">
          <span className="homeHeader">{context.loc('ValueProposition_ActionBanner', 'Explore business solutions now')}</span>
          {this.getAllAppsBtn()}
        </div>
        <div className="section homeFiltersPanel">
          <div className="tabContainer">
            <Pivot
              headersOnly
              selectedKey={selectedTab}
              onLinkClick={(item: PivotItem) => {
                this.changeTabCallback(item.props.itemKey);
                getNpsModule()?.increaseActionsCounter('categoryChangesCount');
              }}
              styles={pivotStyles}
            >
              {Object.entries(filterItems).map(([key, { defaultDisplayName }]) => {
                return (
                  <PivotItem
                    key={key}
                    itemKey={key}
                    headerText={this.context.loc(`FilterType_${defaultDisplayName}`, defaultDisplayName)}
                    headerButtonProps={{
                      'data-bi-id': defaultDisplayName,
                      ...tabItemDataProps,
                    }}
                  />
                );
              })}
            </Pivot>
            <div className="tabContent">
              <div
                className="filterPanelItemContainer"
                role="listbox"
                aria-label={this.context.loc('Home_Filter_Panel', 'home filter panel')}
              >
                {this.filterPanelRendering(selectedFilterCollection, selectedFilterType, false)}
              </div>
              <div className="filtersDropDownForSmallScreen">
                {
                  <FiltersDropDown
                    filters={selectedFilterCollection}
                    placeholder={this.context.loc(`Select_${selectedFilterType}`)}
                    collection={selectedFilterType}
                    showIcons={selectedFilterType !== FiltersDropDownCollections.product}
                  />
                }
              </div>
            </div>
          </div>
        </div>
        <div className="section homeResult">
          {this.renderRibbon(ribbonPopularApps, popularAppRibbonData)}
          {this.renderRibbon(ribbonApps, appRibbonData)}
        </div>
        <div className="section homeSuccessStory">
          <h1 className="successStoryHeader">{context.loc('Home_SuccessStory_Title', 'Recommendations from customers')}</h1>
          <Testimonials defaultTestimonialTabName={defaultSuccessStoryTab} />
          {this.viewMoreRecommendations()}
        </div>
        <div className="section homeMarketing">
          <span className="helpSectionTitle">{this.context.loc('Help_Section_Title')}</span>
          <div className="marketContent">
            <div className="cardContent">
              <div className="marketIcon icon-shop-50" />
              <h5 className="marketHeader">{context.loc('Home_Marketing1Header')}</h5>
              <p className="marketParagraph">{context.loc('Home_Marketing1Content')}</p>
              <ExternalLink
                href="https://aka.ms/AppSourceDocOverview"
                className="c-hyperlink supportLink"
                accessibilityEnabled={true}
              >
                {this.context.loc('App_LearnMore')}
              </ExternalLink>
            </div>
            <div className="cardContent">
              <div className="marketIcon icon-shopping-cart-50" />
              <h5 className="marketHeader">{context.loc('Home_Marketing2Header')}</h5>
              <p className="marketParagraph">{context.loc('Home_Marketing2Content')}</p>
              <ExternalLink
                className={'c-hyperlink supportLink'}
                href={this.context.buildHref(routes.partners, {}, {})}
                accessibilityEnabled={true}
              >
                {this.context.loc('Sell_With_Us')}
              </ExternalLink>
            </div>
            <div className="cardContent">
              <div className="marketIcon icon-what-this-50" />
              <h5 className="marketHeader">{context.loc('Home_Marketing3Header')}</h5>
              <p className="marketParagraph">{context.loc('Home_Marketing3Content')}</p>
              <ExternalLink
                href="https://aka.ms/AppSourceContactUs"
                className="c-hyperlink supportLink"
                accessibilityEnabled={true}
              >
                {this.context.loc('ContactUs')}
              </ExternalLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

(Home as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
  buildHref: PropTypes.func,
  renderErrorModal: PropTypes.func,
};

export default Home;
