/* eslint-disable camelcase */
import * as ASDataMapping from '@shared/utils/dataMapping';
import { IProductValue } from '@shared/utils/dataMapping';
import { Constants, OfferType, PlanPricingType } from '@shared/utils/constants';
import { Service } from '@shared/serviceViewModel';
import { Product } from 'services/product/product';
import { IBillingAccount } from '@src/interfaces/IBilling';

import { ICatalogServiceEnrichedData } from '@src/server/viewModels/serviceApiModel';
// eslint-disable-next-line import/named
import type { ICartAddress } from '@microsoft-commerce/purchase-blends-component-library/dist/lib/models/wire/cart/cart-address';
import { urlKeys } from '@shared/utils/datamappingHelpers';

export type ICommonDataMap = ASDataMapping.IDataMap;

export interface IRestStatus {
  isLoading: boolean;
  hasError: boolean;
  error?: string;
}

export interface IFilterElement {
  filterItem: string;
  newPath: string;
  locKey: string;
  title: string;
  urlKey?: string;
}

export interface IFilterIcon {
  title: string;
  urlKey: ProductsType;
}

export interface IPricingElement {
  type: Constants.ButtonType;
  value: string;
  label: string;
  href?: string;
}

export enum PartnerSearchResultType {
  Industry,
  Product,
  Service,
  Category,
  AdvSpec,
  Partner,
  FreeText,
}

export interface IPartnerSearchResultValue {
  val: string;
  type: PartnerSearchResultType;
  text: string;
  entityId?: string;
}

export interface IPartnerSearchResponse {
  suggestions: IPartnerSearchResultValue[];
}

export interface IShowingResultsForPayload {
  showingResultsFor: string;
  previousSearchText: string;
}

export interface ISearchResultsPayload {
  appSearchResults: IAppDataItem[];
  servicesSearchResults: Service[];
  cloudsIndustrySearchResults: IAppDataItem[];
  appsCount: number;
  servicesCount: number;
  cloudsIndustryCount: number;
  searchid: number;
  requestId: string;
  searchResponseId: string;
}

export interface IEntityDataReceived {
  entityType: Constants.EntityType;
  dataList: ICloudsIndustry[];
  isEmbedded: boolean;
  userSegment: string;
  count: number;
  urlQuery: IURLQuery;
}

export interface ISearchItem {
  type: string;
  text: string;
  original: IAppDataItem | Service | IPartnerSearchResultValue;
}

export interface ISearchResult extends ISearchItem {
  id: string;
  logo: string;
}

export interface IAppSearchResult extends ISearchResult {
  type: 'AppSearchResult';
  product: string;
  linkedAddInsTypes: LinkedAddIns[];
}

export interface IServiceSearchResult extends ISearchResult {
  type: 'ServicesSearchResult';
}

export interface IPartnerSearchResult extends ISearchResult {
  type: 'PartnerSearchResult';
}

export interface IFilterAdditionalData {
  iconURL: string;
  learnMoreURL: string;
}

export interface IImages {
  ImageName: string;
  ImageUri: string;
}

export interface ICollateralDocuments {
  DocumentName: string;
  DocumentUri: string;
}

export interface IDemoVideos {
  VideoLink: string;
  VideoName: string;
  ThumbnailURL: string;
}

export interface IDetailInformation {
  Description: string;
  LargeIconUri: string;
  Images?: IImages[];
  DemoVideos?: IDemoVideos[];
  CollateralDocuments?: ICollateralDocuments[];
  LanguagesSupported?: string[];
  IconBackgroundColor?: string;
  PrivacyPolicyUrl?: string;
}

export interface IAppDetailInformation extends IDetailInformation {
  Subcategories?: string[];
  Keywords?: string[];
  HelpLink: string;
  SupportLink: string;
  PrivacyPolicyUrl?: string;
  AdditionalPurchasesRequired?: boolean;
  Countries?: string[];
  AppVersion?: string;
  PlatformVersion?: string;
  ReleaseDate?: string;
  // The below properties are added for Office Onboarding. These will be valid for other products also in the future
  Capabilities?: string[];
  WorksWith?: string[];
  SiteLicenceAvailable?: boolean;
  UsesEnterpriseContract?: boolean;
  // TODO: add pricing meta data
}

export interface IFilterItemDetail {
  longTitle: string;
  locKey: string;
  urlKey: string;
}

export interface IDataItem {
  popularity?: number;
  entityId: string;
  publisherId: string;
  entityIdLoweredCased: string;
  entityType: Constants.EntityType;
  title: string;
  products?: IProductValue;
  industries?: IViewDataBitmask;
  categories?: number;
  cloudIndustriesCategories?: string[];
  trials?: number;
  pricingModel?: number;
  ratings?: number;
  appCompliance?: number;
  AzureBenefitEligible?: number;
  filtermatch?: boolean;
  friendlyURL?: string; // An alternative match for the 'appid' in the detail page URL
  detailInformation?: IDetailInformation; // todo: with TS2.0 we should set this to non-nullable so that our appdetail page can be written more succinct
  detailLoadFailed?: boolean;
  startingPrice?: IStartingPrice;
  tags?: string[];
  origin?: string | null;
  searchResponseId?: string;
  isCloudIndustry?: boolean;
  industriesDetails?: IFilterItemDetail[];
  categoriesDetails?: IFilterItemDetail[];
  productType: string;
  isTestProduct?: boolean;
}

export interface IAppDataItemBasicData extends IDataItem {
  bigId: string;
  publisher: string;
  builtFor: string;
  iconBackgroundColor?: string;
  actionString: string;
  ctaTypes: Constants.CTAType[];
  primaryProduct?: IProductValue;
  handoffURL?: string;
  showcaseLink?: string;
  privateApp?: boolean;
  ranking?: number; // We are using it to order the apps based on ranking. Currently only embed Power BI is using this feature.
  leadgenEnabled?: boolean;
  awards?: string[];
  CertificationState?: Constants.CertificationType;
  CertificationLink?: string;
  tags?: string[];
  locale?: string;
  AverageRating?: number;
  NumberOfRatings?: number;
  ratingSummary?: IRatingSummary;
  ratingSummaries?: IRatingSummary[];
  UsesEnterpriseContract?: boolean;
  planPricingType?: PlanPricingType;
  pricingInformation?: IPricingInformation;
  futurePricesInformation?: IPricingInformation;
  offerType?: OfferType;
  universalAmendmentUrl?: string;
  linkedAddIns?: LinkedAddIns[];
  linkedSaaS?: LinkedSaaS[];
  hideFromAdminPortal?: boolean;
  licenseManagement?: ILicenseManagement;
  hasPrices?: boolean;
  detailInformation?: IAppDetailInformation;
  autoRunLaunchEvents?: string[];
  badges: Badges[];
}

type CosmosId = string;

export interface ICustomHashMap {
  [ViewId: string]: CosmosId;
}

type Index = number;

export interface IHashMap {
  [ViewId: string]: Index;
}

export interface ITileDataApps {
  dataList: IDataItem[];
  count: number;
}

export interface ITileDataCloudsIndustry {
  dataList: IDataItem[];
  count: number;
}

export interface ITileDataServices {
  dataList: IDataItem[];
  count: number;
  selectedCountry: string;
  selectedRegion: string;
}

export interface ITileData {
  apps: ITileDataApps;
  services: ITileDataServices;
  cloudsIndustry: ITileDataCloudsIndustry;
  origin: string | null;
  showingResultsFor?: string;
}

export interface IAppsPricingsPayload {
  pricingData: IStartingPrices;
  countryCode: string;
}

export interface IStartingPrices {
  [entityId: string]: IStartingPrice;
}

export interface ILegacyIdToCatalogStartingPrice {
  [legacyId: string]: ICatalogStartingPrice;
}

export interface ICatalogStartingPriceData {
  termUnits: TermsStrings;
  meterUnits: TermsStrings;
  minTermPrice: number;
  minMeterPrice: number;
  currency: string;
}

export interface ICatalogStartingPrice {
  legacyId: string;
  isQuantifiable: boolean;
  hasFreeTrials: boolean;
  isByol: boolean;
  startingPrice: ICatalogStartingPriceData;
}

export interface IAppsPricings {
  billingRegion: string;
  startingPrices: IStartingPrices;
}

export interface FilterDataResults<T> {
  items: T[];
  count: number;
  showingResultsFor?: string;
  didYouMean?: string;
  requestId: string;
  searchResponseId: string;
}

export interface LinkedAddIns {
  entityId: string;
  type: LinkedAddInsType;
}

export interface LinkedSaaS {
  entityId: string;
  entityIdLoweredCased: string;
  icon: string;
}

export const LinkedAddInsTypes = {
  outlook: true,
  onenote: true,
  officemetaos: true,
  teams: true,
  word: true,
  excel: true,
  sharepoint: true,
  viva: true,
  powerpoint: true,
  project: true,
};

export type LinkedAddInsType = keyof typeof LinkedAddInsTypes;

export enum AppTag {
  'Other' = 0,
  'OfficeAward' = 1,
  'New' = 2,
  'Trending' = 3,
  'PowerBICertified' = 4,
  'AzureExpertsMSP' = 5,
}

export interface IFuturePriceFormat {
  price: string;
  startDate: number;
}

export interface IPricingInformation {
  billingRegion: string;
  skus?: ISKU[];
}

export interface ISKU {
  /** Also known as the planId */
  id: string;
  bigId?: string;
  skuId?: string;
  title: string;
  offerType?: OfferType;
  handoffURL?: string;
  summary?: string;
  freeTrialDurationDays?: number;
  description: string;
  leadgenEnabled?: boolean;
  azureInfraCostsExtra?: boolean;
  priceVaries?: boolean;
  isQuantifiable?: boolean;
  minQuantity?: number;
  maxQuantity?: number;
  isPrivate?: boolean;
  startDate?: number;
  displayRank?: number;
  createUiDefinitionEndpoint?: string;
}

export interface IFuturePrices {
  plans: IFuturePlan[];
}

export interface IFuturePlan {
  id: string;
  planId: string;
  skuId: string;
  availabilities: IFutureMPRPAvailability[];
}

export interface IFutureMPRPAvailability extends MPRPAvailabilities {
  currentAvailabilityId: string;
}

export interface ISimpleSKU extends ISKU {
  startingPrice: IPrice;
  termPrices?: IPrice[];
  currencyCode?: string;
  customMeter?: ICustomMeter;
}

export interface IAppPricing {
  billingRegion: string;
  skus: ISimpleSKU[];
}

export interface IAppDataItemExtraData {
  entityId: string;
  shortDescription?: string;
  licenseTermsUrl?: string;
  iconURL?: string;
  isSolutionMap?: boolean;
  downloadLink?: string;
  downloadSampleLink?: string;
}

export type ICloudsIndustry = IAppDataItem | Service;

export interface IAppDataItem extends IAppDataItemBasicData, IAppDataItemExtraData {
  [dynamicCategory: string]: any;
  appType?: OdataRawObjectAppType;
}

export interface AzureSearchParsedResult {
  entityId: string;
  entityIdLoweredCased: string;
  origin: Constants.SearchSources;
  showingResultsFor?: string;
  didYouMean?: string;
}

export interface AzureSearchAppsResult {
  ApplicationId: string;
}

export interface AzureSearchServicesResult {
  ServiceId: string;
}

export interface IRawCuratedSection {
  id: string;
  parentId: string;
  sectionId?: string;
  order: number;
  items: IRawCuratedSectionItem[];
}

export interface IRawCuratedSectionItem {
  id: string;
  order: number;
}

export interface ICuratedSections<T> {
  [section: string]: ICuratedSection<T>[];
}

export interface ICuratedSection<T> {
  titleId: string;
  items: T[];
  titleLocId?: string;
}

export interface ICuratedSectionItem {
  id: string;
  order: number;
}

export interface IUserInfo {
  firstName: string;
  lastName: string;
  email: string;
  displayName?: string;
  uniqueName?: string;
  phone?: string;
  country?: string;
  company?: string;
  title?: string;
  oid?: string;
  tid?: string;
  puid: string;
  givenName?: string;
  familyName?: string;
  thumbphoto?: string;
  alternateEmail?: string;
  isMSAUser?: boolean;
  userSegment?: UserSegment;
  fieldHubUserType?: Constants.FieldHubUserType;
  licenses?: string[];
  tenantType?: Constants.TenantType;
  uiRole?: Constants.UiRole;
}

export interface IUserLeadGenProfile {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  country?: string;
  company?: string;
  title?: string;
  updateRequired?: boolean;
  isLatestProfile?: boolean;
  licenses?: ILicense[];
  uiRole?: Constants.UiRole;
  managedLicenses?: IBigCatProductSkuContract[];
}

// Please do not change this model. This is used for Leadgen payload
export interface ILeadGenInfo {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  country?: string;
  company?: string;
  title?: string;
  oid?: string;
  tid?: string;
  appId: string;
  leadgenEnabled: boolean;
  category: string;
}

export interface ILeadConfigInfo {
  serverId?: string;
  munchkinId?: string;
  formId?: string;
  url?: string;
  authenticationType?: string;
  userName?: string;
  password?: string;
  connectionString?: string;
  objectIdentifier?: string;
  containerName?: string;
}

export interface ILeadgenPayload {
  appId?: string;
  customerInfo: ILeadGenInfo;
  acquisitionType?: string;
  customerLicense?: ILicense;
  campaigns?: ICampaigns;
  notes?: string;
}

export interface IVideo {
  videoLink: string;
  thumbnailURL: string;
  videoName: string;
}

export enum MediaType {
  Image = 'Image',
  Video = 'Video',
}

export interface IMedia {
  link: string;
  thumbnail: string;
  name: string;
  type: MediaType;
}

export interface IMediaModalPayload {
  media: IMedia[];
  defaultIndex: number;
}

export interface IDrive {
  urlLink: string;
}

export interface IFieldHub {
  reportName: string;
}

export interface IDisclaimer {
  title: string;
  description: string;
}

export interface IReviewPayload {
  showModal: boolean;
  app: IAppDataItem;
  accessKey: string;
  ctaType: string;
  callback: any;
}

export interface IInstructions {
  isFromDownload: boolean;
}

export interface SendLeadInfoOptions {
  item: IAppDataItem | Service;
  ctaType: Constants.CTAType;
  notes?: string;
  planId?: string;
}

export interface ILeadCustomerInfo {
  firstName: string;
  lastName: string;
  phone: string;
  country: string;
  company: string;
  title: string;
  email?: string;
}

export interface ILeadPayload {
  uniqueProductId: string;
  uniquePlanId?: string;
  numberOfUnitsPurchased?: number;
  category: string;
  callToAction?: string;
  actionCode?: string;
  notes: string;
  leadSource: string;
  leadSubSource?: string;
  campaigns?: ICampaigns;
  customerInfo: ILeadCustomerInfo;
}

export interface IAcquistionUserInfo {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  country?: string;
  company?: string;
  title?: string;
  oid?: string;
  tid?: string;
  alternateEmail?: string;
  licenses?: string[];
  effectiveLocale?: string;
}

export interface IAcquistionAppInfo {
  appid: string;
  appName: string;
  builtFor: string;
  publisher: string;
  category?: string;
  product?: string;
  leadgenEnabled?: boolean;
}

export interface IAcquistionPayload {
  acquisitionId?: string;
  acquisitionTime: string;
  ctaType: string;
  appData?: IAcquistionAppInfo;
  userInfo: IAcquistionUserInfo;
  notes?: string;
}

export interface IAcquisitionPayloadV2 {
  ctaType?: string;
  acquisitionId?: string;
  acquisitionType: string;
  campaigns?: ICampaigns;
  appData?: IAcquistionAppInfo;
  servicesData?: IAcquistionServiceInfo;
  userInfo: IAcquistionUserInfo;
  planId?: string;
  customerLicense?: ILicense;
  notes?: string;
}

// Telemetry Models. This is the common model between front-end and WFE backend which talks with the telemetry service
// This Telemetry event will be constructed from the ITelemetryData
export interface ITelemetryEvent {
  page: string;
  action: string;
  actionModifier: string;
  clientTimestamp: string;
  appName?: string;
  product?: number;
  featureFlag?: string;
  details?: string;
  hostType?: string;
  oid?: string;
  tid?: string;
  spzaId?: string;
  mshash?: string;
  isFeedback?: boolean;
}

// This is the telemetry model which we use for logging.
// Notice that this doesn't have timestamp. That will be added while constructing the debug event
export interface ITelemetryData {
  page: string;
  action: string;
  actionModifier: string;
  appName?: string;
  serviceId?: string;
  product?: number;
  featureFlag?: string;
  details?: string;
  flushLog?: boolean;
  isFeedback?: boolean;
}

export interface ISharedTelemetryEvent {
  operation: string;
  message: string;
}

export type ITelemetryOutRequest = ITelemetryOutRequestStart | ITelemetryOutRequestEnd;

export interface ITelemetryOutRequestStart {
  taskName: string;
  correlationId: string;
  requestId: string;
  operation: string;
  httpMethod: string;
  hostName: string;
  targetUri: string;
  requestHeaders: string;
}

export interface ITelemetryOutRequestEnd extends ITelemetryOutRequestStart {
  contentLength: number;
  responseHeaders: string;
  durationInMilliseconds: number;
  httpStatusCode: number;
  errorMessage: string;
}

export interface ITelemetryOutRequests {
  TelemetryEvents?: ITelemetryOutRequest[];
}

export interface ITelemetryEvents {
  TelemetryEvents?: ITelemetryEvent[];
}

export interface IUser {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  country?: string;
  company?: string;
  title?: string;
  licenses?: ILicense[];
  managedLicenses: IBigCatProductSkuContract[];
  uiRole?: Constants.UiRole;
}

export enum CustomerType {
  Organization = 'Organization',
  Individual = 'Individual',
}

export interface IPublisherOfferResponse {
  publisherOfferBaseUri?: string;
  token?: string;
  offerUriWithToken?: string;
  expiryTime?: Date;
}

export interface ICustomerAddress {
  firstName: string;
  lastName: string;
  line1?: string;
  line2?: string;
  line3?: string;
  city?: string;
  state?: string;
  country: string;
  postalCode?: string;
}

export interface IBillingAddress extends ICartAddress {
  companyName: string;
}

export interface IBillableAccount {
  id: string;
}

export interface ICustomer {
  id?: string;
  billableAccountId?: string;
  type?: CustomerType;
  address?: ICustomerAddress;
}

/**
 * This subscription is an Azure subscription, not to be confused with a monthly subscription like the ISubscription
 */
export interface IAzureSubscription {
  authorizationSource: string;
  displayName: string;
  managedByTenants: string[];
  // Enabled/disabled/etc.
  state: string;
  // Format of: /subscriptions/id(guid)
  id: string;
  // Format of: //id(guid)
  subscriptionId: string;
  tenantId: string;
}

export interface IAzureSubscriptionCountResponse {
  type: string;
  value: number;
}

export interface IAzureSubscriptionsResponse {
  count: IAzureSubscriptionCountResponse;
  subscriptions: IAzureSubscription[];
}

export interface IUserProfile extends IUser {
  id?: string;
  lastLogin?: string;
  updateRequired?: boolean;
  alternateEmail?: string;
}

export interface IBigCatProductSkuContract {
  productId: string;
  skuId: string;
}

export interface TenantsDetails {
  id: string;
  tenantId: string;
  countryCode: string;
  displayName: string;
  domains: string[];
  tenantCategory: string;
  defaultDomain: string;
  tenantType: string;
  azureSubscriptions: IAzureSubscriptionsResponse;
}

export interface IUserTenantsDetails {
  details: TenantsDetails;
  status: IRestStatus;
}

export interface ILicense {
  type: string;
  hasLicense: boolean;
  isDisputed: boolean;
}

export interface IUserLeadProfileAgreement extends IUser {
  accepted: boolean;
}

export interface IUserAcquisitionInfo {
  id: string;
  userid: string;
  email: string;
  fullName: string;
  appid: string;
  appName: string;
  publisher: string;
  product: string;
  acquisitionTime: string;
  ctaType: string;
  accessKey?: string;
  useridHash?: string;
  category?: string;
  acquisitionType?: string;
  acquisitionInstanceId?: string;
  acquisitionInstanceType?: string;
  isAcquisitionSuccess?: boolean;
  partitionKey?: string;
  doctype?: string;
  isAuthenticated?: boolean;
  sourceAppId?: string;
  ipAddress?: string;
  hasUserConsented?: boolean;
  effectiveLocale?: string;
}

export interface IUserReview {
  // These are mandatory while pushing to Db
  // TODO: Have a different interface for client Model
  id?: string;
  userId?: string;
  appid: string;
  product: string;
  rating: number;
  title?: string;
  description: string;
  isPublic?: boolean;
  submitted?: string;
  lastUpdated?: string;
  ctaType?: string;
  responseRate?: string;
  doctype?: string;
  email?: string;
  isContentScanned?: boolean;
  isActive?: boolean;
  disableRemark?: string;
  partitionKey?: string;
  firstName?: string;
  lastName?: string;
  isConsent?: boolean;
  isAnonymous?: boolean;
  isUpdateExistingReview?: boolean;
}

export interface ISharedAccessKey {
  appid: string;
  product: string;
  userId: string;
  expiry: number;
  acquisitionId?: string;
}

export interface IRequestContext {
  correlationId: string;
  requestId: string;
  operation: string;
  apiVersion: string;
  continuation?: string;
  headers?: any;
  cookies?: any;
  remoteIpAddress?: string;
}

/* The name of the following claims from two sources:

1) JWT standard https://tools.ietf.org/html/rfc7519
   aud, iat, iss, nbf, sub,

2) first party doc:
   https://identitydocs.azurewebsites.net/static/overview/best_practices.html

*/

export interface IMSAIdTokenClaim {
  aio: string;
  altesecid: string;
  amr: any;
  aud: string;
  email: string;
  exp: number;
  family_name: string;
  given_name: string;
  iat: number;
  idp: string;
  ipaddr: string;
  iss: string;
  nbf: number;
  nonce: string;
  sub: string;
  tid: string;
  unique_name: string;
  ver: string;
}

export interface IAADIdTokenClaim {
  aio: string;
  amr: any;
  aud: string;
  exp: number;
  family_name: string;
  given_name: string;
  iat: number;
  in_corp?: string;
  ipaddr: string;
  iss: string;
  name: string;
  nbf: number;
  nouce: string;
  oid: string;
  onprem_sid?: string;
  puid: string;
  sub: string;
  tid: string;
  unique_name: string;
  upn: string;
  ver: string;
}

// Test Drive status
export enum SolutionInstanceStatus {
  Provisioning = 'Provisioning',
  Ready = 'Ready',
  Deprovisioned = 'Deprovisioned',
  Expired = 'Expired',
  Error = 'Error',
  Unavailable = 'Unavailable',
}

// Auto generated shared/maintained by BE and FE
// Represents the test drive status response from the BE API
export interface ISolutionInstanceStatusResponse {
  id: string;
  appId: string;
  solutionVersion: number;
  location: string;
  provisioningType: string;
  status: string;
  expirationDate?: string;
  outputs: ISolutionInstanceOutput[];
}

// Represents the test drive type and cta action
export interface ISolutionInstanceOutput {
  key: string;
  value: string;
  type: string;
  index: number;
}

export interface IComponentHTMLData {
  html: string;
  css: string[];
  title: string;
  metaObj: IMetaTags;
  webExtractor: ChunkExtractor;
  nodeExtractor: ChunkExtractor;
}

export interface IMetaTags {
  metaDescription: HTMLMetaElement;
  metaKeywords: HTMLMetaElement;
  metaViewport?: HTMLMetaElement;
  metaOGTitle: HTMLMetaElement;
  metaOGUrl: HTMLMetaElement;
  metaOGDescription: HTMLMetaElement;
  metaOGLocale: HTMLMetaElement;
  metaOGSiteName: HTMLMetaElement;
  metaOGType?: string;
  metaOGImage?: HTMLMetaElement;
  metaOGVideo?: HTMLMetaElement;
  metaTwitterCard?: HTMLMetaElement;
  metaTwitterSite?: HTMLMetaElement;
  metaTwitterTitle?: HTMLMetaElement;
  metaTwitterDescription?: HTMLMetaElement;
  metaTwitterImage?: HTMLMetaElement;
}

export enum PricingStates {
  Loading = 1,
  BYOL,
  AlwaysAvailable,
  NotAvailableInThisRegion,
  NoPricingData,
  FreeApp,
  AdditionalPurchasesRequired,
  SolutionTemplate,
  // NOTE: where is the following used?? Can it be removed?
  WebApp,
  PriceVaries,
  StartFromFree,
}

export interface IPrice {
  // The price value
  value: number;
  // Billing term
  unit: string;
  // Number of free trial days
  freeDurationDays?: number;
  customMeter?: ICustomMeter[];
  // Indicator if term has an option of seats/users
  isQuantifiable?: boolean;
  // The availability id !== bigId. i.e. {"DZH318Z0BMZ5"}
  availabilityId?: string;
  // The term id. i.e. {"hjdtn7tfnxcy"}
  termId?: string;
  // Billing plan for multiyear saas offers(If no billing plan then it's a onetime payment)
  billingPlan?: IBillingPlan;
  meterId?: string;
}

export interface ICustomMeter {
  rate: number;
  unit: string;
  title: string;
  includedQuantity: string;
  availabilityId?: string;
}

export interface ILocalPrice extends IPrice {
  currency: string;
}

export type IStartingPrice = {
  pricingData: ILocalPrice | PricingStates;
  hasFreeTrial?: boolean;
  isQuantifiable?: boolean;
  pricingBitmask?: { [property: string]: number };
  hasExtraAzureInfraCost?: boolean;
};

export interface IBillingCountry {
  countryCode: string;
  currency: string;
  name: string;
}

export interface IIdBasedActionType {
  // The ID of the cartItem to update
  id: string;
}

// Used to update the number of users aka seats
export interface ISetSeatsAction extends IIdBasedActionType {
  // Number (quantity) of users, also known as seats
  numSeats: number;
  numSeatsString: string;
}

/** Used for updating the (mprp)product in the each cart item */
export interface ISetProductAction extends IIdBasedActionType {
  product: Product;
}

/** Used for updating recurring billing */
export interface ISetRecurringBilling extends IIdBasedActionType {
  recurringBilling: boolean;
}

/** Used for updating the billing term for each product in the cart */
export interface ISetTermDurationAction extends IIdBasedActionType {
  termDuration: TermsStrings;
}

/** Used for updating the termId for each product in the cart */
export interface ISetTermIdAction extends IIdBasedActionType {
  termId: string;
}

/** Used to track a single checkout journey within a session,
 * since each session may start and complete multiple checkouts */
export interface ICheckoutId {
  checkoutId?: string;
}

/** Used to identity the types which requires generating a new cart */
export type CreateNewCartActionType = ISetTermIdAction | ISetTermDurationAction | ISetRecurringBilling | ISetSeatsAction;

export interface ICartItem {
  /** PublisherId.OfferId (productId) */
  entityId?: string;
  /** Display name of app (product) */
  appTitle?: string;
  /** Logo URL */
  appImageUri?: string;
  /** PlanId */
  id?: string;
  /** The commitment duration of the purchase */
  termDuration?: TermsStrings;
  /** The billing plan, defined by the termId, includes how the price will get paid over the billing term (Say Term is P3Y, then billing plan could be P1Y) */
  termId?: string;
  /** Should renew billing after billing duration ends */
  recurringBilling?: boolean;
  /** Plan display name */
  title?: string;
  /** Plan description */
  description?: string;
  /** Pricing info (soon to be deprecated) */
  unitPrice?: ILocalPrice;
  /** Number of users / seats to purchase, only applicable if app (product) is quantifiable */
  quantity?: number;
  quantityString?: string;
  /** Determines if app has users / seats customer can buy for or is a single app (or product) */
  isQuantifiable?: boolean;
  /** Minimal number of seats defined for specific selected plan  */
  minQuantity?: number;
  /** Maximal number of seats defined for specific selected plan  */
  maxQuantity?: number;
  /** Add-ins related to the purchase (usually free of charge) that customer will get if purchasing the app (product) */
  linkedAddIns?: LinkedAddIns[];
  /** The product Id saved in Big (display) Catalog i.e. {DZH318Z0BNRC} */
  bigId?: string;
  /** Big catalog format of planIds i.e. {0001, 000A} */
  skuId?: string;
  /** Indicates if plan has free trial */
  hasFreeTrial?: boolean;
  /** The matching app with all the info */
  app?: IAppDataItem;

  /**
   * This is a product (private or public) that is fetched from MPRP.
   * The reason for this is that in dakota there is an
   *  incorrect parameter so we have to fetch from MPRP apps where the termId is correct
   */
  product?: Product;
}

export interface IEmailInfo {
  to: string[];
  cc?: string[];
  substitutionData: { [key: string]: any };
}

export interface ICampaignEmailPayload {
  emailServiceId: string;
  source: string;
  emailInfoList: IEmailInfo[];
}

// one and only one key value pair
export interface ICampaign {
  [source: string]: string;
}

// one or more key value pair
export type ICampaigns = ICampaign;

export enum UserSegment {
  'unauthenticated',
  'business',
  'consumer',
  'edu',
  'tenantadmin',
}

export interface IAcquistionServiceInfo {
  serviceId: string;
}

export interface IAppReview {
  id: string;
  offer_id: string;
  rating: number;
  title: string;
  source: string;
  content?: string;
  review_content?: IAppReviewContent;
  customer_info?: IReviewCustomerInfo;
  created_at?: string;
  updated_at?: string;
  isv_reply?: IReviewIsvReply;
  third_party_review_url?: string;
  comments_count?: number;
  mark_as_helpful_count?: number;
}

export interface IAppReviewContent {
  flat_content?: string;
  question_answer_content?: IAppReviewQAContent[];
}

export interface IAppReviewQAContent {
  question: string;
  answer: string;
}

export interface IReviewCustomerInfo {
  user_id?: string;
  name: string;
  is_anonymous: boolean;
  email: string;
}

export interface IReviewIsvReply {
  content: string;
  replied_at: string;
}

export interface IRatingSummary {
  TotalRatings: number;
  AverageRating: number;
  StarsDistribution: IRatingDistribution;
  Source: string;
  ExternalOfferReference?: string;
  OfferId: string;
  CreatedAt: string;
}

export interface IRatingDistribution {
  OneStars: number;
  TwoStars: number;
  ThreeStars: number;
  FourStars: number;
  FiveStars: number;
}

export interface IUserAcquisition {
  exists: boolean;
}

export enum CommunityItemType {
  Review = 'Review',
}

export interface IItemReport {
  category: string;
  source: string;
  offerId: string;
  itemId: string;
  comments: string;
  itemType: CommunityItemType;
}

export interface IAvertAbuseCategory {
  locKey: string;
  dropDownValue: string;
  avertIdentifier: string;
}

export interface IGlobalSettings {
  marketplaceApiHost: string;
  saasRPHost: string;
  armApiHost: string;
  MPRPHost: string;
  partnersIframeUrl?: string;
  partnersIframeUrlTest?: string;
  partnersApiHost: string;
  telemetryInstrumentationKey: string;
  loggingInstrumentationKey: string;
  appVersion: string;
  region: string;
  correlationId: string;
  requestId: string;
  reviewsAPI: string;
  linkedinAPI: string;
  linkedinAPIVersion: string;
  communityAPI: string;
  communityAPIVersion: string;
  siteEnvironment: string;
  commerceApiHost: string;
  oneCatalogApiHost: string;
  telemetryEndpoint: string;
  msClarityId: string;
  searchApiEndpoint: string;
  expHeaderDisabled: boolean;
  marketplaceLeadsHost: string;
  marketplaceLeadsAudienceId: string;
  enableOneTaxonomy: boolean;
  aadApplicationId: string;
  aadLoginUrl: string;
  jarvisCM: string;
  mprpAadApplicationId: string;
  pifdEndpoint: string;
  commerceApi: string;
  graphApi: string;
  agreementAppId: string;
  isConverged: boolean;
}

export interface ICheckoutSettings {
  pidlEnv: string;
}

export enum CheckoutSource {
  PdpTable = 'PDP Table',
  PdpTop = 'PDP Top',
  Tile = 'Tile',
  Deeplink = 'Deeplink',
}

export type CheckoutSourcePayload = { source: CheckoutSource };

export interface ICountry {
  country: string;
  ip: string;
  message: string;
}

export type ILocation = ICountry;

export interface IBrowserStorageData {
  localStorage: ILocalStorageData;
  cookies: ICookieData;
}

export interface ICookieData {
  [cookieName: string]: any;
}

export interface ILocalStorageData {
  [localStorageConfigName: string]: any;

  // for typing purpose only
  getItem?: (item: string) => string;
  setItem?: (item: string) => string;
}

// URL Params parsed from browser URL, , which are values before '?', and after base url
// key: Param Name
// value: Param Value
export interface IURLParam {
  [paramName: string]: string;
}

export interface ITelemetryConsentDialogInfo {
  hasContactInfo: boolean;
  hasCheckbox: boolean;
}

// URL Queries parsed from browser URL, which are values after '?'
// key: Query Name
// value: Query Value(s), joined by ';' or its equivalence '%3B'
export interface IURLQuery {
  [queryName: string]: string;
}

export interface ISpzaUserIdAndModifier {
  spzaUserId: string;
  newUserModifier: string;
}

export interface ITrailData {
  trailId: string;
  trailIdModifier: string;
  time: string;
}

export interface INpsData {
  next: number;
  nextText: string;
  secondTime: boolean;
  viewedApps: number;
  acquiredApps: number;
  sessionEndTime: number;
}

export interface IExternalLinkProps extends IExternalLinkAttribute {
  href: string;
  accessibilityEnabled: boolean;

  shouldOpenInTab?: boolean;
  // for onClick event, use either onClickHandle or, onClick
  onClickHandle?: (event: any) => void;
  telemetryDetails?: string;
}

// external link attribute consists of properties and methods
export interface IExternalLinkAttribute {
  [attribute: string]: any;
}

export interface IExternalLinkOverrideAttribute {
  tabIndex: number;
  target: string;
  onClick: (event: any) => void;
}

export interface IRequestHeader {
  requestId: string;
  correlationId: string;
}

export interface IRawDataDetailedCategory {
  SubCategories: string[];
  Category: string;
}

export interface IBitmask {
  property: string;
  mask: number;
}

export interface IViewDataBitmask {
  [bitmaskTargetProperty: string]: number;
}

export interface ILinksList {
  href?: string;
  className?: string[];
  locKey: string;
  accessibilityEnabled?: boolean;
}

export interface TabChild {
  title: string;
  name: string;
  textBadge?: string;
  getContent: () => JSX.Element;
}

export interface ILicenseManagement {
  isMicrosoftManaged: boolean;
  isFreemium: boolean;
}

export interface ITileCtaBaseData {
  id: string;
  text: string;
  type?: Constants.CTAType;
}

export interface ITileCtaExtraData {
  area: string;
  view: string;
}

export enum PricingOfferType {
  AppService = 'AppService',
  VirtualMachine = 'VirtualMachine',
}

export enum CatalogOfferType {
  None = 'None',
  DevService = 'DevService',
  ManagedApplication = 'ManagedApplication',
  VirtualMachine = 'VirtualMachine',
  AzureApplication = 'AzureApplication',
  Container = 'Container',
  SaaS = 'SaaS',
  SolutionTemplate = 'SolutionTemplate',
  IotEdgeModules = 'IotEdgeModules',
  ManagedServices = 'ManagedServices',
  ContainerApps = 'ContainerApps',
  DynamicsOps = 'DynamicsOps',
  DynamicsCE = 'DynamicsCE',
  DynamicsBC = 'DynamicsBC',
  PowerBI = 'PowerBI',
  ConsultingServices = 'ConsultingServices',
  CoreVirtualMachine = 'CoreVirtualMachine',
  PowerBIVisuals = 'PowerBIVisuals',
  Office365 = 'Office365',
  AADApps = 'AADApps',
  AzureServices = 'AzureServices',
  AppService = 'AppService',
  LogAnalytics = 'LogAnalytics',
}

export enum CatalogApplicableProducts {
  'Sales' = 'Sales',
  'FieldService' = 'Field Service',
  'ProjectServiceAutomation' = 'Project Service Automation',
  'CustomerService' = 'Customer Service',
  'CustomerVoice' = 'Customer Voice',
  'Marketing' = 'Marketing',
  'MixedReality' = 'Mixed Reality',
  'Power_Apps' = 'Power Apps',
  'PowerApps' = 'PowerApps',
  'PowerAutomate' = 'Power Automate',
  'PowerVirtualAgents' = 'Power Virtual Agents',
  'PowerPages' = 'Power Pages',
  'ProjectOperations' = 'Project Operations',
  'ProjectServices' = 'Project Services',
  'Commerce' = 'Commerce',
  'Finance' = 'Finance',
  'HumanResources' = 'Human Resources',
  'SupplyChainManagement' = 'Supply Chain Management',
  'BusinessCentral' = 'Business Central',
}

export interface MPRPAppData {
  id: string;
  /** Format of DZH318Z0BMTW */
  bigId: string;
  language: string;
  displayName: string;
  hasStandardContractAmendments: boolean;
  publisherId: string;
  publisherDisplayName: string;
  offerId: string;
  /** Format of publisherId.offerId */
  legacyId: string;
  standardContractAmendmentsRevisionId: string;
  longSummary: string;
  description: string;
  offerType: CatalogOfferType;
  isPrivate: boolean;
  isPreview: boolean;
  isStopSell: boolean;
  fulfillBeforeChargeEligible: boolean;
  legalTermsUri: string;
  legalTermsType: string;
  privacyPolicyUri: string;
  version: string;
  iconFileUris: IconFileUris;
  metadata: Metadata;
  isThirdParty: boolean;
  popularity: number;
  hasFreeTrials: boolean;
  isByol: boolean;
  isMacc: boolean;
  hasFreePlans: boolean;
  isQuantifiable: boolean;
  hasPaygPlans: boolean;
  isReseller: boolean;
  isExcludedFromSearch: boolean;
  applicableStoreFronts: number;
  offerEnvironment: number;
  excludeFromBootstrap: boolean;
  disableSendEmailOnPurchase: boolean;
  hideFromSaasBlade: boolean;
  hideFromAdminPortal: boolean;
  integratedWithMicrosoftGraphApi: boolean;
  _ts: number;
  licenseModel?: string;
  licenseManagementType?: string;
  helpLink?: string;
  publisherMpnId?: string;
  isvContactDetails?: IsvContactDetails;
  supportUri?: string;
  categoryIds?: string[];
  industryIds?: string[];
  cloudIndustryCategories?: string[];
  images?: ImagesMPRPEntity[];
  videos?: VideosEntity[];
  plans?: MPRPPlansEntity[];
  keywords?: string[];
  isMicrosoftProduct?: boolean;
  productOwnershipSellingMotion?: string;
  documentLinks?: DocumentLinksEntityOrLinksEntity[];
  linkedAddIns?: null[];
  hydrationNotificationRecivedAt?: string;
  partitionKey?: null;
  downloadLink?: string;
  downloadSampleLink?: string;
  _etag?: null;
  offerVersion?: string;
  sellerId?: string;
  links?: DocumentLinksEntityOrLinksEntity[];
  cspLegalTermsUri?: string;
  summary?: string;
  notAvailableForMarket?: string;
  storeFrontPricings: CatalogStoreFrontPricings;
  enrichedData: ICatalogServiceEnrichedData;
  pricingTypes: CatalogPricingTypes[];
  applicableProducts: string[];
  m365CertificationInfo: ICatalogM365CertificationInfo;
  appFreeType: CatalogAppFreeType;
  bigCatLastModifiedDate: string;
  awards: string[];
  supportedProducts: string[];
  capabilities: string[];
  universalAmendmentUrl: string;
  linkedAddInsTypes: ICatalogLinkedAddInsTypes;
  autoRunLaunchEvents?: string[];
  isTestProduct?: boolean;
  badges: Badges[];
}

export interface ICatalogLinkedAddInsTypes {
  [legacyId: string]: string;
}

export enum CatalogAppFreeType {
  AppFree = 'AppFree',
  AppFreeTrial = 'AppFreeTrial',
  ExternalPurchase = 'ExternalPurchase',
}

export enum CatalogM365CertificationType {
  Undefined = 0,
  None = 1,
  SelfAttested = 2,
  MicrosoftCertified = 3,
}

interface ICatalogM365CertificationInfo {
  m365CertificationId: string;
  m365CertificationDetailsUrl: string;
  m365CertificationType: CatalogM365CertificationType;
}

export enum CatalogPricingTypes {
  Payg = 'Payg',
  Free = 'Free',
  FreeTrial = 'FreeTrial',
  Byol = 'Byol',
}

export interface CatalogStoreFrontPricings {
  amp: CatalogStoreFrontPricing;
  appsource: CatalogStoreFrontPricing;
}

export interface CatalogStoreFrontPricing {
  pricingOption: number;
  hasPrices: boolean;
}

export interface IsvContactDetails {
  us?: string;
}
export interface IconFileUris {
  medium: string;
  small: string;
  large: string;
  wide?: string;
  hero?: string;
}
export interface Metadata {
  leadGeneration: LeadGeneration;
}

export enum OrchestrationType {
  PowerBi = 'PowerBi',
  LabManager = 'LabManager',
  LogicApp = 'LogicApp',
}

export interface LeadGeneration {
  leadGenEnabled?: boolean;
  productId?: string;
}
export interface ImagesMPRPEntity {
  context: string;
  items?: ItemsMPRPEntity[];
}
export interface ItemsMPRPEntity {
  id: string;
  uri: string;
  type: string;
}
export interface VideosEntity {
  caption: string;
  uri: string;
  videoPurpose: string;
  previewImage: PreviewImage;
}
export interface PreviewImage {
  caption: string;
  uri?: string;
  imagePurpose: string;
}

export interface MPRPPlansEntityOperationSystem {
  family: string;
  type: string;
  name: string;
}

export interface MPRPPlansEntity {
  id: string;
  displayName: string;
  description: string;
  restrictedAudience: RestrictedAudienceOrMetadataOrIsvContactDetails;
  skuId: string;
  planId: string;
  legacyPlanId: string;
  type: CatalogOfferType;
  leadGeneration: LeadGeneration1;
  metadata: RestrictedAudienceOrMetadataOrIsvContactDetails;
  isPrivate: boolean;
  isHidden: boolean;
  hasFreeTrials: boolean;
  isByol: boolean;
  isFree: boolean;
  isPayg: boolean;
  isStopSell: boolean;
  cspState: string;
  isQuantifiable: boolean;
  vmSecurityType?: string;
  availabilities?: MPRPAvailabilities[];
  categoryIds?: string[];
  conversionPaths?: string[];
  keywords?: null[];
  artifacts?: ArtifactsEntity[];
  uiDefinitionUri?: string;
  minQuantity?: number;
  maxQuantity?: number;
  summary?: string;
  callToAction?: string;
  redirectUrl?: string;
  operatingSystem: MPRPPlansEntityOperationSystem;
  displayRank: string;
  isHiddenPrivateOffer: boolean;
}

export enum PricingAudience {
  DirectCommercial = 'DirectCommercial',
  PartnerCommercial = 'PartnerCommercial',
}

export enum IAvailabilityActions {
  Browse = 'Browse',
  Curate = 'Curate',
  Consume = 'Consume',
  Details = 'Details',
  Purchase = 'Purchase',
  Renew = 'Renew',
}

export type ActionsType = IAvailabilityActions[];
export type PricingAudienceType = PricingAudience | string;

export interface IncludedQuantityProperties {
  termId: string;
  quantity: string;
}

export interface MPRPAvailabilities {
  id: string;
  market: string;
  isPIRequired: boolean;
  appId: string;
  planID: string;
  pricingAudience: PricingAudienceType;
  piFilter: PiFilter;
  isStopSell: boolean;
  hasFreeTrials: boolean;
  consumptionUnitType: string;
  displayRank: number;
  partitionKey: string;
  remediationRequired: boolean;
  startDate: number;
  endDate: number;
  _ts?: number;
  _etag?: string;
  actions?: ActionsType | null;
  meterId?: null;
  meter?: IMeter;
  terms?: TermsEntity[];
  assetBehaviors?: string[] | null;
  remediations?: null;
}

export interface IMeter {
  meterId: string;
  partNumber: string;
  consumptionResourceId: string;
  price: Price;
  type: string;
  includedQuantityProperties: IncludedQuantityProperties[];
}

export enum TermsStrings {
  Term30DString = 'P30D',
  Term90DString = 'P90D',
  Term1HString = 'P1H',
  Term1MString = 'P1M',
  Term3MString = 'P3M',
  Term1YString = 'P1Y',
  Term2YString = 'P2Y',
  Term3YString = 'P3Y',
  HourString = 'HR',
  MonthString = 'Month',
  YearString = 'Year',
  FreeTrial = 'FreeTrial',
  OneTime = 'OneTime',
}

export interface TermsEntity {
  termId: string;
  /**  The commitment duration of the purchase */
  termUnits: TermsStrings;
  prorationPolicy: ProrationPolicy;
  termDescription: string;
  price: Price;
  renewTermId: string;
  renewTermUnits: TermsStrings;
  billingPlan?: IBillingPlan;
  termDescriptionParameters?: null;
}

export interface AdvancedTerm extends TermsEntity {
  freeTrialTermId: string;
  freeTrialTermUnit: TermsStrings;
}

export interface IBillingPlan {
  /** The term duration the billing plan is relevant for, in the format of TermsStrings (P1Y, P1M, ....) */
  billingPeriod: TermsStrings;
  description: string;
  title: string;
  price: Price;
}

export interface IBillingPlanODataRawObject {
  BillingPeriod: TermsStrings;
  Description: string;
  Title: string;
  Price: IPriceODataRawObject;
}

export interface IPriceODataRawObject {
  CurrencyCode: string;
  IsPIRequired: boolean;
  ListPrice: number;
  MSRP: number;
}

export interface ProrationPolicy {
  minimumProratedUnits: string;
}
export interface Price {
  currencyCode: string;
  isPIRequired: boolean;
  listPrice: number;
  /** MicroSoft Retail Price (MSRP) */
  msrp: number;
}
export interface PiFilter {
  exclusionProperties?: string[] | null;
  inclusionProperties?: null[] | null;
}

export type RestrictedAudienceOrMetadataOrIsvContactDetails = Record<string, unknown>;

export interface LeadGeneration1 {
  productId?: string;
}
export interface ArtifactsEntity {
  name: string;
  uri: string;
  type: string;
}
export interface DocumentLinksEntityOrLinksEntity {
  id: string;
  displayName: string;
  uri: string;
}

export interface ResponseWithHeaders<T> {
  data: T;
  headers: Record<string, unknown>;
}

export interface ODataPricing {
  id: string;
  AppId: string;
  bigId: string;
  pricingFromIngest: boolean;
}

export interface LinkedAddInsTypes {
  Key: string;
  Value: string;
}

export enum Badges {
  MAICPPCertifiedSolution = 'MAICPPCertifiedSolution',
  CopilotExtension = 'CopilotExtension',
}

export interface OdataRawObject {
  Id?: string;
  DocType?: string;
  ApplicationId: string;
  LastModifiedTime?: number;
  PartitionKey?: string;
  Title: string;
  ShortDescription: string;
  SmallIconUri: string;
  Publisher: string;
  PublisherId: string;
  PublisherMpnId?: string;
  Product: string;
  universalAmendmentUrl?: string;
  Industries?: string[];
  Products?: string[];
  WorksWith?: string[];
  AppActionType: string;
  HandOffURL?: string;
  ItemId?: string;
  ShowcaseLink?: null;
  PrivacyPolicyUrl: string;
  EnableLeadSharing: boolean;
  Description: string;
  LargeIconUri: string;
  Keywords?: string[];
  HelpLink?: string;
  SupportLink: string;
  LicenseTermsUrl: string;
  AdditionalPurchasesRequired: boolean;
  Countries?: string[];
  LanguagesSupported?: string[];
  AppVersion?: string;
  PlatformVersion?: null;
  OfferVersion?: number;
  ReleaseDate?: string;
  FlightCodes?: null[];
  Categories?: string[];
  CloudIndustriesCategories?: string[];
  Capabilities?: string[];
  HomePageUrl?: null;
  IconBackgroundColor?: null;
  NumberOfRatings: number;
  AverageRating: number;
  RatingSummary?: IRatingSummary;
  RatingSummaries?: IRatingSummary[];
  SiteLicenseAvailable?: boolean;
  Popularity: number;
  HasPrices: boolean;
  PricingOptions: number;
  UsesEnterpriseContract?: boolean;
  Tags?: string[];
  Awards?: string[];
  Locale?: string;
  IsSolutionMap: boolean;
  BigId?: string;
  OmexProductId?: string;
  BigCatSubmissionVersion?: string;
  UniversalAmendmentUrl?: string;
  LinkedAddIns?: null[];
  AddInParents?: string[];
  HideFromAdminPortal: boolean;
  HydrationNotificationReceivedAt?: string;
  IntegratedWithMicrosoftGraphApi?: boolean;
  MultiTenantAadAppId?: string;
  DisableSendEmailOnPurchase?: boolean;
  HideFromSaasBlade?: boolean;
  BigCatSubmissionVersionBeforePricing?: string;
  LicenseManagementType?: string;
  LicenseModel?: string;
  PBIServicePrincipalIds?: null[];
  Images?: ImagesEntity[];
  DemoVideos?: DemoVideosEntityOrVideosEntity[];
  CollateralDocuments?: CollateralDocumentsEntityOrInstructionsDocument[];
  DetailedCategories?: null[];
  DetailedCategoriesV2?: DetailedCategoriesV2[];
  DetailedIndustriesV2?: DetailedCategoriesV2[];
  CloudIndustryCategories?: CloudIndustryCategories[];
  UserSegmentPopularities?: UserSegmentPopularitiesEntity[];
  Plans?: PlansEntity[];
  LinkedAddInsTypes?: LinkedAddInsTypes[];
  Pricing?: PricingData;
  '@odata.type'?: string;
  CertificationState?: Constants.CertificationType;
  CertificationId?: string;
  CertificationLink?: string;
  locale?: any;
  hideFromAdminPortal?: any;
  isSolutionMap?: any;
  appType?: OdataRawObjectAppType;
  DownloadLink?: string;
  DownloadSampleLink?: string;
  AutoRunLaunchEvents?: string[];
  isAzureBenefitEligible?: boolean;
  offerType: string;
  isTestProduct?: boolean;
  badges: Badges[];
}

export interface StorefrontsRawItem extends OdataRawObject {
  ApplicationIdLoweredCased: string;
}

export enum OdataRawObjectAppType {
  'public',
  'private',
}

export interface ImagesEntity {
  ImageName: string;
  ImageUri: string;
}
export interface DemoVideosEntityOrVideosEntity {
  VideoLink: string;
  VideoName: string;
  ThumbnailURL: string;
}
export interface CollateralDocumentsEntityOrInstructionsDocument {
  DocumentName: string;
  DocumentUri: string;
}
export interface DetailedCategoriesV2 {
  Category: string;
  SubCategories?: string[];
}

export interface CloudIndustryCategories {
  Category: string;
  SubCategories?: string[];
}

export interface UserSegmentPopularitiesEntity {
  Key: string;
  Value: number;
}

export enum PlansEntityOS {
  windows = 'windows',
  linux = 'linux',
}

export enum DakotaOfferType {
  AzureApplication = 'AzureApplication',
  Container = 'Container',
  ContainerApp = 'ContainerApp',
  DevService = 'DevService',
  IoTEdgeModule = 'IoTEdgeModule',
  ManagedApp = 'ManagedApp',
  ManagedServices = 'ManagedServices',
  SaaSApp = 'SaaSApp',
  SolutionTemplate = 'SolutionTemplate',
  VirtualMachine = 'VirtualMachine',
  PowerBI = 'PowerBI',
  PowerBIVisuals = 'PowerBIVisuals',
  DynamicsCE = 'DynamicsCE',
  None = 'None',
  DynamicsOps = 'DynamicsOps',
  DynamicsBC = 'DynamicsBC',
  ConsultingServices = 'ConsultingServices',
  Office365 = 'Office365',
  AAD = 'AAD',
}

export interface PlansEntity {
  OS?: string;
  RecommendedSlugs?: null[];
  CreateUiDefinitionEndpoint: string;
  PlanId: string;
  SkuId: string;
  OfferType?: DakotaOfferType;
  Title: string;
  OfferVersion?: null;
  Summary?: string;
  Description: string;
  EnableLeadSharing: boolean;
  ItemId?: null;
  HandOffUrl?: string;
  FlightCodes?: null[];
  MinQuantity?: number;
  MaxQuantity?: number;
  IsQuantifiable: boolean;
  ServiceIdentifier?: null;
  DetailedCategories?: null[];
  DetailedCategoriesV2?: null[];
  IsPrivate?: boolean;
  DisplayRank: string;
}

export interface PricingData {
  Id: string;
  OfferType: string;
  BillingRegionPrice: BillingRegionPrice;
}
export interface BillingRegionPrice {
  DocType: string;
  AppId: string;
  BigId: string;
  PartitionKey: string;
  OfferType: string;
  RegionCode: string;
  CurrencyCode: string;
  FlightCodes?: null[];
  PlanPrices?: PlanPricesEntity[];
}
export interface PlanPricesEntity {
  PlanId: string;
  SkuId: string;
  HasFreeTrial: boolean;
  BringYourOwnLicense: boolean;
  IsFree: boolean;
  FreeTrialDurationDays: number;
  AzureInfraCostsExtra?: boolean;
  PriceVaries?: boolean;
  BasePrice: BasePrice;
  VariablePrices?: VariablePrices[];
  TermPrices?: TermPricesEntity[];
  IsPrivate: boolean;
  DisplayRank: string;
}
export interface BasePrice {
  Term: string;
  Price: number;
  BillingPlan?: IBillingPlanODataRawObject;
}

export interface VariablePrices {
  MeterID: string;
  MeterUnit: string;
  MeterTitle: string;
  Price: number;
  IncludedQuantity: string;
  IncludedQuantities: IncludedQuantityProperties[];
  AvailabilityId: string;
  StartDate: number;
  EndDate: number;
}

export interface TermPricesEntity {
  Term: string;
  TermId?: string;
  AvailabilityId: string;
  Price: number;
  IncludedQuantity?: string;
  FreeTrialDurationDays?: number;
  HasFreeTrial?: boolean;
  VariablePrice?: VariablePrices;
  VariablePrices?: VariablePrices[];
  BasePrice?: BasePrice;
  StartDate?: number;
  EndDate?: number;
  BillingPlan?: IBillingPlanODataRawObject;
}

export interface ILink {
  text: string;
  url: string;
  params?: string[];
}

export interface IBannerData {
  description: string;
  imgURL: string;
  title: string;
  links?: ILink[];
}

export enum ISearchFilterCategory {
  Products = 'Products',
}

export interface ISearchFilter {
  category: ISearchFilterCategory;
  values: string[];
}

export interface ICtaData {
  id: string;
  entityId: string;
  title: string;
  CtaType?: string;
  onClick: any;
  disabled?: boolean;
  area: string;
  view?: string;
}

export interface IBoldString {
  bold?: string;
  light?: string;
}

export interface IRatingData {
  key: string;
  text: string;
  ratingsCount: number;
  avgRating: number;
}

export interface INpsPersistentMetadata {
  lastSubmittedTime?: Date;
  lastDismissedTime?: Date;
  lastCtaTime?: Date;
}

export interface INpsCounterMetadata {
  pdpViewsCount: number;
  searchActionsCount: number;
  filterActionsCount: number;
  galleryChangesCount: number;
  categoryChangesCount: number;
  paginationActionsCount: number;
  seeAllActionsCount: number;
}

export type NpsPersistentJsonMetadata = Record<keyof INpsPersistentMetadata, string>;
export type NpsEvent = keyof INpsCounterMetadata;

export type INpsMetadata = INpsPersistentMetadata & INpsCounterMetadata;

export const ratingValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] as const;
export type ratingValue = typeof ratingValues[number];

export interface INpsSurveyAnswer {
  rating: ratingValue;
  description: string;
  email: string;
}

export interface IItemData {
  icons: LinkedAddInsType[] | ProductsType[];
  title: string;
  urlKey?: string;
}

export type ProductsType = keyof typeof urlKeys.products;

export interface ICreauteUiDefinitionParameters {
  osPlatform: string;
  recommendedSizes: string[];
}

export interface ICreauteUiDefinition {
  parameters: ICreauteUiDefinitionParameters;
}

export interface ITermsLink {
  link: string;
  locKey: string;
}

export interface RawUHFData {
  cssIncludes: string;
  footerHtml: string;
}

export interface UHFData {
  css: string;
  footerHtml: string;
  javascriptSrcs: string[];
}

export interface IAccountsDetails {
  isLoading: boolean;
  isEAAccount: boolean;
  accounts: IBillingAccount[];
  hasError: boolean;
}

export interface Token {
  iss: string;
  aud: string;
  appid: string;
  exp: number;
  iat: number;
  tid: string;
  appidacr: string;
}
