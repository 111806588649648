import * as React from 'react';
import { Dialog, DialogFooter, PrimaryButton, Link, Text } from '@fluentui/react';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { getReviewModalDialogProps } from '@shared/utils/reviewsUtils';
import { Constants } from '@shared/utils/constants';

export interface ISubmitReviewResponseModal {
  context: ILocContext & ILocParamsContext;
  title: string;
  content: string;
  dialogIcon: string;
  shouldRenderLink: boolean;
  closeModal: () => void;
}

export const SubmitReviewResponseModal = ({
  context,
  title,
  content,
  dialogIcon,
  shouldRenderLink,
  closeModal,
}: ISubmitReviewResponseModal) => {
  const dialogProps = getReviewModalDialogProps(title);
  return (
    <Dialog
      hidden={false}
      onDismiss={() => closeModal()}
      dialogContentProps={dialogProps.dialogContentProps}
      maxWidth={dialogProps.maxWidth}
      modalProps={dialogProps.modalProps}
    >
      <div className="ResponeDialogContent">
        <img className="ResponeDialogImg" src={dialogIcon} />
        <Text className="ResponeDialogText">
          {content}{' '}
          {shouldRenderLink && (
            <Link className="ResponeDialogLink" target="_blank" href={Constants.MicrosoftRatingReviewPolicy}>
              {context.loc('SubmitReviewResponseModal_Policy', 'View review policies')}
            </Link>
          )}
        </Text>
      </div>
      <DialogFooter>
        <PrimaryButton onClick={() => closeModal()} text={context.loc('Rating_SuccessButton', 'Done')} />
      </DialogFooter>
    </Dialog>
  );
};
