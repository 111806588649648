import * as React from 'react';
import ReportAbuseModalComp from '../../components/modals/reportAbuseModal';
import { IState } from '../../../State';
import { connect } from 'react-redux';
import { IItemReport } from '@shared/Models';
import { reportItem } from '@shared/actions/thunkActions';
import type { Dispatch } from 'redux';

export const mapStateToProps = (state: IState, ownProps: any) => {
  const { appId, reviewId, reviewTitle, reviewSource, dismissModal } = ownProps;
  return {
    appId,
    reviewId,
    reviewTitle,
    reviewSource,
    dismissModal,
    storefrontName: state.config.storefrontName,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    reportReview: (report: IItemReport) => dispatch(reportItem(report)),
  };
};

export const ReportAbuseModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportAbuseModalComp) as React.StatelessComponent<any>;
