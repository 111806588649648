import React from 'react';
import { Text } from '@fluentui/react';
import type { IColumn } from '@fluentui/react';
import { SafeHtmlWrapper } from '@shared/components';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { IBillingCountry, ISimpleSKU } from '@shared/Models';
import { Constants } from '@shared/utils/constants';
import { getBillingTermTranslated } from '@shared/utils/pricing';
import { SaasPlanNameCell } from '../saasPlanNameCell';
import { SaasPricingCell } from '../saasPricingCell';
import { MultilineRenderItem } from '../saasPricingRow';
import { SaasSubtotalCell } from '../saasSubtotalCell';

const planNameWidthsByNumberOfTags = [
  {
    // 0 Tags
    minWidth: 176,
    maxWidth: 422,
  },
  {
    // 1 Tag
    minWidth: 284,
    maxWidth: 530,
  },
  {
    // 2 Tags
    minWidth: 366,
    maxWidth: 612,
  },
];

const baseColumn: Partial<IColumn> = {
  styles: {
    cellTitle: { paddingLeft: 16 },
    root: { height: 'fit-content' },
  },
};

const getPlanNameColumn = (
  context: ILocContext & ILocParamsContext,
  skus: ISimpleSKU[],
  onGetInNowClicked?: (planId?: string) => void
): IColumn => {
  const hasPrivatePlans = skus.some((sku) => sku.isPrivate);
  const hasFreeTrial = skus.some((sku) => sku.freeTrialDurationDays);
  const planNameWidths = planNameWidthsByNumberOfTags[Number(hasPrivatePlans) + Number(hasFreeTrial)];
  return {
    ...baseColumn,
    key: 'plan-name',
    name: context.loc('SimplePlanPricing_ColumnHeader_Plan', 'Plan'),
    minWidth: planNameWidths.minWidth,
    maxWidth: planNameWidths.maxWidth,
    onRender: ({ sku }: MultilineRenderItem) => (
      <SaasPlanNameCell
        sku={sku}
        offerHasFreeTrialPlans={skus.some((sku) => sku.freeTrialDurationDays)}
        offerHasPrivatePlans={skus.some((sku) => sku.isPrivate)}
        onGetInNowClicked={() => onGetInNowClicked(sku.id)}
      />
    ),
  };
};

const getDescriptionColumn = (context: ILocContext & ILocParamsContext): IColumn => ({
  ...baseColumn,
  key: 'description',
  name: context.loc('SimplePlanPricing_ColumnHeader_Description', 'Description'),
  minWidth: 320,
  maxWidth: 696,
  onRender: ({ sku }: MultilineRenderItem) => <SafeHtmlWrapper className="plan-description-wrapper" html={sku.description} />,
});

const getPriceAndPaymentOptionsColumn = (
  context: ILocContext & ILocParamsContext,
  billingCountry: IBillingCountry,
  shouldBlockPrices?: boolean,
  locale?: string
): IColumn => ({
  ...baseColumn,
  key: 'price-and-payment',
  name: context.loc('SimplePlanPricing_ColumnHeader_PriceAndPaymentOptions', 'Price/payment options'),
  minWidth: 206,
  isMultiline: true,
  onRender: ({ price, sku, futurePriceSku }: MultilineRenderItem) => (
    <SaasPricingCell
      price={price}
      sku={sku}
      futurePriceSku={futurePriceSku}
      billingCountry={billingCountry}
      shouldBlockPrices={shouldBlockPrices}
      locale={locale}
    />
  ),
});

const getBillingTermColumn = (context: ILocContext & ILocParamsContext): IColumn => ({
  ...baseColumn,
  key: 'billing-term',
  name: context.loc('SimplePlanPricing_ColumnHeader_BillingTerm', 'Billing term'),
  minWidth: 136,
  isMultiline: true,
  onRender: ({ price }: MultilineRenderItem) => (
    <Text>{`${getBillingTermTranslated({ context, term: price.unit, useHyphen: true, isSubscription: true })}`}</Text>
  ),
});

export const subtotalColumnKey = 'subtotal';
const getSubtotalColumn = (
  context: ILocContext & ILocParamsContext,
  billingCountry: IBillingCountry,
  locale: string
): IColumn => ({
  ...baseColumn,
  key: subtotalColumnKey,
  name: context.loc('SimplePlanPricing_ColumnHeader_Subtotal', 'Subtotal'),
  minWidth: 136,
  isMultiline: true,
  onRender: ({ price, sku }: MultilineRenderItem) => (
    <SaasSubtotalCell sku={sku} price={price} billingCountry={billingCountry} locale={locale} />
  ),
});

const getNumberOfUsersColumn = (context: ILocContext & ILocParamsContext, locale: string): IColumn => ({
  ...baseColumn,
  key: 'number-of-users',
  name: context.loc('SimplePlanPricing_ColumnHeader_NumberOfUsers', 'No. of users'),
  minWidth: 136,
  onRender: ({ sku: { minQuantity, maxQuantity } }: MultilineRenderItem) => {
    const minQuantityText = minQuantity.toLocaleString(locale);
    const maxQuantityText = Math.min(Constants.Checkout.MaxQuantity, maxQuantity).toLocaleString(locale);

    return <Text>{`${minQuantityText} - ${maxQuantityText}`}</Text>;
  },
});

export interface GetColumnsParameters {
  context: ILocContext & ILocParamsContext;
  skus: ISimpleSKU[];
  billingCountry: IBillingCountry;
  locale: string;
  onGetInNowClicked?: (planId?: string) => void;
  shouldBlockPrices?: boolean;
  isOneTimePayment?: boolean;
}

export const getColumns = ({
  context,
  skus,
  billingCountry,
  locale,
  onGetInNowClicked,
  shouldBlockPrices,
  isOneTimePayment,
}: GetColumnsParameters): IColumn[] => {
  const planNameColumn = getPlanNameColumn(context, skus, onGetInNowClicked);
  const descriptionColumn = getDescriptionColumn(context);
  const priceAndPaymentOptionsColumn: IColumn = getPriceAndPaymentOptionsColumn(
    context,
    billingCountry,
    shouldBlockPrices,
    locale
  );
  const billingTermColumn = getBillingTermColumn(context);
  const subtotalColumn = getSubtotalColumn(context, billingCountry, locale);

  const columns = isOneTimePayment
    ? [planNameColumn, descriptionColumn, priceAndPaymentOptionsColumn, subtotalColumn]
    : [planNameColumn, descriptionColumn, priceAndPaymentOptionsColumn, billingTermColumn, subtotalColumn];

  const isSeatBased = skus.some((sku) => sku.minQuantity && sku.maxQuantity);
  if (isSeatBased) {
    const numberOfUsersColumn: IColumn = getNumberOfUsersColumn(context, locale);
    const descriptionColumnIndex = 1;
    columns.splice(descriptionColumnIndex + 1, 0, numberOfUsersColumn);
  }

  return columns;
};
