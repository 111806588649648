import * as React from 'react';
import { ConsentModal } from '@appsource/containers/modals/consentModal';
import { IModalProps } from '@shared/components/modals/modal';
import { IAppDataItem } from '@shared/Models';
import { Constants } from '@shared/utils/constants';
import { SignInModal } from '@shared/components/modals/signInModal';
import { getSignInModalType, isTransactApp, isPrivateOffer, isPowerBIVisuals } from '@shared/utils/appUtils';
import { Service } from './../serviceViewModel';
import { isAppContactForm } from '@shared/utils/modals';
import AnimationModal from '@shared/components/modals/animationModal';
import { getPrimaryProductUrl } from '@shared/utils/datamappingHelpers';

export function handleConsentModalCall(props: IModalProps, appData: IAppDataItem, dismissModal: () => void, service: Service) {
  let modalContent: any = null;

  const { options, isEmbedded, billingCountryCode, userInfo, entityId, query } = props;

  const ctaType = parseInt(options.ctaType, 10);
  const isTransactable =
    isTransactApp({ isEmbedded, ctaTypes: [ctaType], appData, billingRegion: billingCountryCode }) ||
    (isPrivateOffer(appData?.appType) && !isPowerBIVisuals(getPrimaryProductUrl(appData.primaryProduct)));

  const signinType: Constants.SignInType = getSignInModalType(
    appData && appData.primaryProduct,
    appData && appData.products,
    userInfo,
    ctaType,
    !!service,
    isTransactable
  );
  const { signedIn, loading } = props.userInfo;
  const shouldShowAnimationModal = !!(!signedIn && loading);
  const shouldShowSignInModal: boolean = signinType !== Constants.SignInType.Authorized && !props.isEmbedded;
  const signinRedirect: string = !!service && !appData ? 'service' : 'app';
  const entityType: Constants.EntityType = !!service && !appData ? Constants.EntityType.Service : Constants.EntityType.App;

  if (shouldShowAnimationModal) {
    modalContent = <AnimationModal />;
  }

  if (shouldShowSignInModal && !shouldShowAnimationModal) {
    const appTitle = appData && appData.title ? appData.title : '';
    const userEmail = userInfo && userInfo.email ? userInfo.email : '';
    const serviceTitle = service && service.title ? service.title : '';

    modalContent = (
      <SignInModal
        redirect={signinRedirect}
        entityId={entityId}
        dismissModal={dismissModal}
        signInModalType={signinType}
        appName={appTitle}
        email={userEmail}
        entityType={entityType}
        serviceName={serviceTitle}
        ctaType={ctaType}
      />
    );
  } else if (appData || service) {
    modalContent = (
      <ConsentModal
        appInfo={appData}
        isEmbedded={isEmbedded}
        isContactForm={isAppContactForm(appData, ctaType)}
        dismissModal={dismissModal}
        ctaType={ctaType}
        serviceInfo={service}
        query={query}
        isTransactApp={isTransactable}
      />
    );
  }

  return modalContent;
}
