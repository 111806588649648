import { Constants } from './constants';
import datamapJson from '@appsource/datamapping/datamappingTaxonomy.json';
import oneTaxonomyDatamapJson from '@src/shared/oneTaxonomy/oneTaxonomyDatamapping.json';
import { isOneTaxonomyEnabled } from '@shared/oneTaxonomy/utils';

const MAX_BITMASK_INDEX = Math.pow(2, 30);
const CATEGORY_WITH_SUB_PREFIX = 'sub';
const URLGROUP_SEPERATOR_NAME = '_mask_';

const getDatamappingJsonFile = () => {
  return isOneTaxonomyEnabled() ? oneTaxonomyDatamapJson.dataMappings : datamapJson.dataMappings;
};

enum FilterType {
  product = 'product',
  category = 'category',
  industry = 'industry',
  cloudIndustries = 'cloudIndustries',
  serviceType = 'serviceType',
  filters = 'filters',
}

const filterTypeToFilterGroup: Record<FilterType, string> = {
  [FilterType.product]: FilterType.product,
  [FilterType.category]: FilterType.category,
  [FilterType.industry]: FilterType.industry,
  [FilterType.cloudIndustries]: Constants.CloudsIndustry.bitmaskPrefix + FilterType.industry,
  [FilterType.serviceType]: FilterType.serviceType,
  [FilterType.filters]: FilterType.filters,
};

const categoryFilterGroup: Record<keyof IDataMap, FilterType> = {
  products: FilterType.product,
  categories: FilterType.category,
  industries: FilterType.industry,
  cloudIndustries: FilterType.cloudIndustries,
  serviceTypes: FilterType.serviceType,
  pricingModel: FilterType.filters,
  ratings: FilterType.filters,
  trials: FilterType.filters,
  appCompliance: FilterType.filters,
  AzureBenefitEligible: FilterType.filters,
  productType: FilterType.filters,
  CopilotExtension: FilterType.filters,
};

const categoryUrlGroup: Record<keyof IDataMap, FilterType> = { ...categoryFilterGroup, cloudIndustries: FilterType.industry };

const subCategoryUrlGroup: Record<keyof IDataMap, keyof IDataMap> = {
  ...Object.fromEntries(Object.keys(categoryFilterGroup).map((key) => [key, key])),
  cloudIndustries: Constants.DataMapCollectionKeys.industries,
} as Record<keyof IDataMap, keyof IDataMap>;

const productsToCategoriesReferences: Record<
  Exclude<Constants.CategoriesDataMapCollectionKeys, Constants.CategoriesDataMapCollectionKeys.default>,
  CategoriesInnerKeys[]
> = {
  [Constants.CategoriesDataMapCollectionKeys.addIns]: [
    'analytics',
    'ai-plus-machine-learning',
    'collaboration',
    'Commerce',
    'compliance-and-legal',
    'customer-service',
    'finance',
    'geolocation',
    'human-resources',
    'internet-of-things',
    'it-and-management-tools',
    'marketing',
    'operations-and-supply-chain',
    'productivity',
    'task-and-project-management',
    'Sales',
  ],
  [Constants.CategoriesDataMapCollectionKeys.powerBiVisuals]: [
    'pbiv-comparison',
    'pbiv-correlation',
    'pbiv-distribution',
    'pbiv-flow',
    'Filters',
    'Infographics',
    'KPI',
    'pbiv-narratives',
    'Maps',
    'pbiv-other',
    'pbiv-part-to-whole',
    'pbiv-r-visuals',
    'Time',
  ],
  [Constants.CategoriesDataMapCollectionKeys.office]: [
    'analytics',
    'collaboration',
    'task-and-project-management',
    'Connectors',
    'customer-service',
    'finance',
    'human-resources',
    'it-and-management-tools',
    'marketing',
    'operations-and-supply-chain',
    'productivity',
    'Sales',
    'Utilities',
    'ContentManagement',
    'FileManagers',
    'CRM',
    'NewsWeather',
    'Reference',
    'SiteDesign',
    'Social',
    'TrainingAndTutorial',
  ],
};

export interface IDataValues {
  FilterID?: number;
  LocKey?: string;
  Title?: string;
  LongTitle?: string;
  UrlKey?: string;
  ShortcutFilters?: string[];
  IsChildFilter?: boolean;
  AllowMSA?: boolean;
  BackendKey?: string;
  ProductCode?: string;
  categoryList?: string;
  ShouldExpandChildFilters?: boolean;
  Level?: number;
  FilterGroup?: string;
  UrlGroup?: string;
  ShortcutUrlKey?: string;
  ShortcutBitmask?: IProductValue;
  isActive?: boolean;
  count?: number;
  match?: (item: any, matchCollection?: boolean) => boolean;
  checkFilter?: boolean;
  FeaturedIndustryApps?: string[];
  EmbedContainerTitle?: string;
  Tooltip?: string;
  SectionTitleKey?: string;
  IsReference?: boolean;
  SubCategoryDataMapping?: ICategoryList;
  HideReferenceSubCategories?: boolean;
}

export interface IProductValue {
  [dataKey: string]: number;
}

export interface ICategoryList {
  [dataKey: string]: IDataValues;
}

type IntersectProps<T> = { [K in keyof T]: (x: T[K]) => void }[keyof T] extends (x: infer I) => void ? Record<keyof T, I> : never; // Thank you stackoverflow: https://stackoverflow.com/a/72554577

const { categories: categoriesMapping, ...restMappings } = getDatamappingJsonFile();

type DataMapPaths = IntersectProps<typeof restMappings>;
type DataMapKeys = keyof DataMapPaths;

type DataMapInnerKeys = keyof DataMapPaths[DataMapKeys];

export type IDataCollection = {
  [K in DataMapInnerKeys]: DataMapPaths[DataMapKeys][K] & IDataValues;
};

export type CategoriesPath = IntersectProps<typeof categoriesMapping>;
export type CategoriesKeys = keyof CategoriesPath;
export type CategoriesInnerKeys = keyof CategoriesPath[CategoriesKeys];

type DataMapEntries = Record<DataMapKeys, IDataCollection>;

export type ICategoryInnerCollection = { [K in CategoriesInnerKeys]: CategoriesPath[CategoriesKeys][K] & IDataValues };
export type ICategoryCollection = {
  [Constants.DataMapCollectionKeys.categories]: {
    [K in CategoriesKeys]: ICategoryInnerCollection;
  };
};

type DataCollections = IDataCollection | ICategoryInnerCollection;
export type IDataMap = DataMapEntries & ICategoryCollection;
export type industriesType = keyof typeof DataMap.industries;
export type cloudIndustriesType = keyof typeof DataMap.cloudIndustries;
export type ProductsType = keyof typeof DataMap.products;

export const DataMap = getDatamappingJsonFile() as IDataMap;

interface IMaskCounterCategory {
  index: number;
  mask: number;
}

interface IMaskCounter {
  [dataKey: string]: IMaskCounterCategory;
}
const maskCounters: IMaskCounter = {};
const increaseMaskIndex = (currentMask: IMaskCounterCategory): void => {
  currentMask.index *= 2;
  if (currentMask.index > MAX_BITMASK_INDEX) {
    currentMask.mask++;
    currentMask.index = 1;
  }
};

const calculateShortcutUrlKey = ({ ShortcutFilters, UrlKey }: IDataValues): string => {
  if (ShortcutFilters) {
    return ShortcutFilters.map((currentShortcutUrl: ProductsType) => DataMap.products[`${currentShortcutUrl}`]?.UrlKey).join(';');
  }
  return UrlKey;
};

const getBaseFilterGroup = ({ mask, filterType }: { mask: number; filterType: FilterType }) => {
  return `${filterTypeToFilterGroup[filterType]}${URLGROUP_SEPERATOR_NAME}${mask}`;
};

const getFilterGroup = ({
  dataCollectionKey,
  mask,
  filterType,
  isBaseFilterGroup,
}: {
  dataCollectionKey: keyof IDataMap;
  mask: number;
  filterType: FilterType;
  isBaseFilterGroup?: boolean;
}): string => {
  const filterGroup = getBaseFilterGroup({ mask, filterType });
  return isBaseFilterGroup ? filterGroup : categoryFilterGroup[`${dataCollectionKey}`];
};

const getDataValueBitMask = ({
  dataCollectionKey,
  isBaseFilterGroup,
}: {
  dataCollectionKey: keyof IDataMap;
  isBaseFilterGroup: boolean;
}) => {
  const currentMask = maskCounters[`${dataCollectionKey}`];
  const filterGroup = getFilterGroup({
    dataCollectionKey,
    filterType: categoryFilterGroup[`${dataCollectionKey}`],
    mask: currentMask.mask,
    isBaseFilterGroup,
  });
  const filterInfo = {
    FilterGroup: filterGroup,
    FilterID: currentMask.index,
  };
  return filterInfo;
};

const initDataValue = ({
  dataValueKey,
  dataCollectionKey,
  dataValue,
  isBaseFilterGroup,
  isSubDataValue,
}: {
  dataValueKey: string;
  dataCollectionKey: keyof IDataMap;
  dataValue: IDataValues;
  isBaseFilterGroup: boolean;
  isSubDataValue: boolean;
}) => {
  const currentMask = maskCounters[`${dataCollectionKey}`];
  const filterInfo = getDataValueBitMask({ dataCollectionKey, isBaseFilterGroup });

  const { IsReference, SubCategoryDataMapping, IsChildFilter } = dataValue;
  if (!SubCategoryDataMapping && !IsReference) {
    increaseMaskIndex(currentMask);
  }

  Object.assign(dataValue, {
    ...filterInfo,
    UrlGroup: isSubDataValue
      ? `${CATEGORY_WITH_SUB_PREFIX}${subCategoryUrlGroup[dataCollectionKey]}`
      : categoryUrlGroup[`${dataCollectionKey}`],
    ShortcutUrlKey: calculateShortcutUrlKey(dataValue),
    IsChildFilter: IsChildFilter || isSubDataValue,
  });
};

const initMaskCounters = (dataCollectionKey: string) => {
  if (!maskCounters[`${dataCollectionKey}`]) {
    maskCounters[`${dataCollectionKey}`] = {
      mask: 1,
      index: 1,
    };
  }
};

const initCollection = ({
  dataCollectionKey,
  dataCollection,
}: {
  dataCollectionKey: keyof IDataMap;
  dataCollection: DataCollections;
}): void => {
  initMaskCounters(dataCollectionKey);

  Object.entries(dataCollection).forEach(([dataValueKey, dataValue]) => {
    initDataValue({
      dataValueKey,
      dataCollectionKey,
      dataValue,
      isBaseFilterGroup: dataCollectionKey === Constants.DataMapCollectionKeys.products,
      isSubDataValue: false,
    });

    Object.values(dataValue.SubCategoryDataMapping || {}).forEach((subCategoryCollectionValue) => {
      initDataValue({
        dataValueKey,
        dataCollectionKey,
        dataValue: subCategoryCollectionValue,
        isBaseFilterGroup: true,
        isSubDataValue: true,
      });
    });
  });
};

const createCategoryReference = (id: string) => {
  return { [`${id}`]: { IsReference: true, HideReferenceSubCategories: true } };
};

const updateCategoriesReferences = () => {
  const categoriesReference = Object.fromEntries(
    Object.entries(productsToCategoriesReferences).map(([productCategoryReference, productCategoryTaxonomy]) => {
      return [
        productCategoryReference,
        productCategoryTaxonomy
          .filter((uniqueFilter: CategoriesInnerKeys) => DataMap.categories.default[`${uniqueFilter}`])
          .reduce(
            (acc, productCategoryTaxonomy) => ({
              ...acc,
              ...createCategoryReference(productCategoryTaxonomy),
            }),
            {}
          ),
      ];
    })
  );

  DataMap.categories = { ...DataMap.categories, ...categoriesReference };
};

const initDataMap = (): void => {
  const { categories, ...collections } = DataMap;
  [
    ...Object.entries(collections),
    ...Object.values(categories).map<[string, ICategoryInnerCollection]>((categoryCollection) => [
      Constants.DataMapCollectionKeys.categories,
      categoryCollection,
    ]),
  ].forEach(([dataCollectionKey, dataCollection]: [keyof IDataMap, ICategoryInnerCollection]) => {
    initCollection({ dataCollectionKey, dataCollection });
  });
  if (isOneTaxonomyEnabled()) {
    updateCategoriesReferences();
  }
};

initDataMap();
