import * as httpProtocol from './httpProtocol';
import { logError } from '../../utils/httpClientUtil';
import { getAppConfig } from '../../services/init/appConfig';

const option: httpProtocol.IHttpOption = {
  requireCorrelationId: true,
  stringifyPostData: false,
  authenticationType: httpProtocol.AuthenticationTypes.Spza,
};

export function getUserAuthStatus() {
  const isEmbed = getAppConfig('embedded');

  if (!isEmbed) {
    const endpoint = '/view/signinStatus';
    const get = httpProtocol.get(endpoint, option);

    return get
      .addQueryEntry('version', getAppConfig('apiVersion'))
      .request()
      .then((result: any) => {
        return result;
      })
      .catch((error: any) => {
        logError('getSigninStatus', error);
        return Promise.resolve(null);
      });
  }
}
