import jsdom from 'jsdom';

export const getWindow = (): Window => {
  return typeof window !== 'undefined' && window;
};

export const getDocument = (): Document => {
  let doc;
  if (__SERVER__) {
    const vDOM = new jsdom.JSDOM('');
    doc = vDOM.window.document;
  }
  return (typeof document !== 'undefined' && document) || doc;
};

export function getActiveElement() {
  return getDocument().activeElement;
}

export function isActiveElement(element: Element) {
  return element === getActiveElement();
}
