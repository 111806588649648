import * as React from 'react';
import * as PropTypes from 'prop-types';
import SpzaComponent from './spzaComponent';
import FuturePriceTooltip, { IFuturePriceTooltipProps } from 'components/futurePriceTooltip';

interface ISimplePriceCell {
  price: string;
  futurePrice?: IFuturePriceTooltipProps;
  className?: string;
}

export class SimplePriceCell extends SpzaComponent<ISimplePriceCell, any> {
  renderImpl() {
    const { price, futurePrice, className } = this.props;
    return (
      <div className={`simplePriceCell ${className}`}>
        <span
          className="price"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: price,
          }}
        />
        {futurePrice && <FuturePriceTooltip futurePrice={futurePrice} />}
      </div>
    );
  }
}

(SimplePriceCell as any).contextTypes = {
  renderErrorModal: PropTypes.func,
};
