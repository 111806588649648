import * as React from 'react';
import * as PropTypes from 'prop-types';
import SpzaComponent from './../spzaComponent';
import { IDrive } from '../../Models';
// let classNames = require('classnames-minimal');

export interface IDriveModal {
  drive: IDrive;
  dismissModal: () => void;
}

export default class DriveModal extends SpzaComponent<IDriveModal, any> {
  renderImpl() {
    let sourceUrl = this.props.drive.urlLink;
    return (
      <div role="dialog" aria-label="test drive is now open" className="driveModalClass">
        <div className="dialogContent">
          <div className="toolBar">
            <button id="cancelButton" aria-label="close" className="cancel" onClick={this.props.dismissModal}>
              <span className="c-glyph"></span>
            </button>
          </div>
          <div className="driveContainer">
            <iframe frameBorder="0" width="1200" height="675" src={sourceUrl}></iframe>
          </div>
        </div>
      </div>
    );
  }
}

(DriveModal as any).contextTypes = {
  loc: PropTypes.func,
  renderErrorModal: PropTypes.func,
};
