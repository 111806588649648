/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/prefer-namespace-keyword */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { IBrowserStorageData, ILocalStorageData, ICookieData, ITelemetryData } from './../Models';
import { Constants } from './constants';
import { getCookieOptionExpires } from './cookieUtils';
import { getWindow, getDocument } from './../services/window';
import { isNullorUndefined } from './objectUtils';
import { isEmptyNullorUndefinedString } from './stringUtils';
import { SpzaInstrumentService } from '../services/telemetry/spza/spzaInstrument';
import { stringifyError } from './errorUtils';
import { logger } from '@src/logger';

// NOTE:
//  DO NOT access directly to, or from browser, for following items
//      1) cookie data, or
//      2) local storage data
//
//  instead, please use methods in this util to access
//  e.g.
//      get cookie data
//          1) BrowserStorage.getBrowserStorage().getCookieItem(...)
//          2) getCookieItem(...)
//      set cookie data
//          1) BrowserStorage.getBrowserStorage().setCookieItem(...)
//          2) saveCookieItem(...)
//      get local storage data
//          1) BrowserStorage.getBrowserStorage().getLocalStorageItem(...)
//          2) getLocalStorageItem(...)
//      set local storage data
//          1) BrowserStorage.getBrowserStorage().setLocalStorageItem(...)
//          2) saveLocalStorageItem(...)
//

// shortcut methods below are for ease of access

export function hasUserAgreedPrivacyConsent(): boolean {
  return BrowserStorage.getBrowserStorage().hasUserAgreedPrivacyConsent();
}

export function saveDataToBrowserAfterUserAgreedPrivacyConsent() {
  BrowserStorage.getBrowserStorage().saveDataToBrowserAfterUserAgreedPrivacyConsent();
}

export function saveCookieItem(itemName: string, itemValue: any, optionText: string = getCookieOptionExpires()) {
  BrowserStorage.getBrowserStorage().setCookieItem(itemName, itemValue, optionText);
}

export function getCookieItem(itemName: string, shouldDecode = true) {
  return BrowserStorage.getBrowserStorage().getCookieItem(itemName, shouldDecode);
}

export function saveLocalStorageItem(itemName: string, itemValue: any) {
  BrowserStorage.getBrowserStorage().setLocalStorageItem(itemName, itemValue);
}

export function getLocalStorageItem(itemName: string): any {
  return BrowserStorage.getBrowserStorage().getLocalStorageItem(itemName);
}

export function saveSessionStorageItem(itemName: string, itemValue: string) {
  BrowserStorage.getBrowserStorage().setSessionStorageItem(itemName, itemValue);
}

export function getSessionStorageItem(itemName: string): string {
  return BrowserStorage.getBrowserStorage().getSessionStorageItem(itemName);
}

// Since privacy consent is introduced, access browser data depends on user agreement
//
// This module purpose is to get browser storage data,
//  1) cookie item,
//  2) local storage item
//
// automatically from either
//  1) from browser storage data buffer, if user hasn't agreed upon privacy consent (banner at the top), or
//  2) from browser storage
//
// wrapper module
// intentionally makes factory class within and its constructor non-public to callers outside of module
// eslint-disable-next-line no-use-before-define
export module BrowserStorage {
  // singleton
  // eslint-disable-next-line no-undef-init
  let browserStorage: BrowserStorage = undefined;

  export function getBrowserStorage(): BrowserStorage {
    if (!browserStorage) {
      browserStorage = new BrowserStorage();
    }

    return browserStorage;
  }

  // DO NOT export class
  class BrowserStorage {
    // browser storage buffer consists of local storage data and cookie data
    private store: IBrowserStorageData;

    constructor(store?: IBrowserStorageData) {
      this.store = store;

      if (!this.store) {
        this.store = this.getDefaultBrowserStorageData();
      }
    }

    private getDefaultBrowserStorageData(): IBrowserStorageData {
      const browserStorageData: IBrowserStorageData = {
        localStorage: {},
        cookies: {},
      };

      return browserStorageData;
    }

    // since we have a flushStore method, store may be undefined when called after privacy consent expired, or
    private getStore(): IBrowserStorageData {
      if (!this.store) {
        this.store = this.getDefaultBrowserStorageData();
      }

      return this.store;
    }

    /**
     * get cookie item value
     *
     *  get cookie item value
     *      1) directly from browser, if user accepted privacy consent, and privacy consent has not expired, or
     *      2) from store buffer, if user hasn't accepted privacy consent, or privacy consent has expired
     *
     * @param itemName cookie item name
     * @param shouldDecode cookie should be decoded
     *
     * @returns value of cookie item, as primitive or an object
     */
    getCookieItem(itemName: string, shouldDecode = true): any {
      if (this.hasUserAgreedPrivacyConsent() || Constants.Cookies.Essential[`${itemName}`]) {
        return cookieUtils.getCookieItemFromBrowser(itemName, shouldDecode);
      }

      return this.getStore().cookies[`${itemName}`];
    }

    /**
     * set cookie item value
     *
     *  set cookie item value
     *      1) directly to browser, if user accepted privacy consent, and privacy consent has not expired, or
     *      2) to store buffer, if user hasn't accepted privacy consent, or privacy consent has expired
     *
     * @param itemName cookie item name
     * @param itemName cookie item value
     * @param optionText cookie options as a string
     */
    setCookieItem(itemName: string, itemValue: any, optionText = getCookieOptionExpires()) {
      if (this.hasUserAgreedPrivacyConsent() || Constants.Cookies.Essential[`${itemName}`]) {
        cookieUtils.setCookieItemToBrowser(itemName, itemValue, optionText);
        return;
      }

      const cookieData: ICookieData = this.getStore().cookies;

      cookieData[`${itemName}`] = itemValue;
    }

    /**
     * get local storage item value
     *
     *  get local storage item value
     *      1) directly from browser, if user accepted privacy consent, and privacy consent has not expired, or
     *      2) from store buffer, if user hasn't accepted privacy consent, or privacy consent has expired
     *
     * @param itemName local storage item name
     *
     * @returns value of local storage item, as primitive or an object
     */
    getLocalStorageItem(itemName: string): any {
      const userAgreedPrivacyConsent = this.hasUserAgreedPrivacyConsent();
      logger.info(
        JSON.stringify({
          userAgreedPrivacyConsent,
          itemName,
        }),
        {
          action: Constants.Telemetry.Action.LocalStorage,
          actionModifier: Constants.Telemetry.ActionModifier.GetItem,
        }
      );
      if (userAgreedPrivacyConsent || Constants.LocalStorage.Essentials.includes(itemName)) {
        return localStorageUtils.getLocalStorageItemFromBrowser(itemName);
      }

      return this.getStore().localStorage[`${itemName}`];
    }

    getSessionStorageItem(itemName: string): any {
      if (this.hasUserAgreedPrivacyConsent()) {
        return localStorageUtils.getSessionStorageItemFromBrowser(itemName);
      }

      return this.getStore().localStorage[`${itemName}`];
    }

    /**
     * set local storage item value
     *
     *  set local storage item value
     *      1) directly to browser, if user accepted privacy consent, and privacy consent has not expired, or
     *      2) to store buffer, if user hasn't accepted privacy consent, or privacy consent has expired
     *
     * @param itemName local storage item name
     * @param itemValue local storage item value
     */
    setLocalStorageItem(itemName: string, itemValue: any) {
      const userAgreedPrivacyConsent = this.hasUserAgreedPrivacyConsent();
      logger.info(
        JSON.stringify({
          userAgreedPrivacyConsent,
          itemName,
        }),
        {
          action: Constants.Telemetry.Action.LocalStorage,
          actionModifier: Constants.Telemetry.ActionModifier.SaveItem,
        }
      );
      if (userAgreedPrivacyConsent || Constants.LocalStorage.Essentials.includes(itemName)) {
        localStorageUtils.setLocalStorageItemToBrowser(itemName, itemValue);

        return;
      }

      const localStorageData: ILocalStorageData = this.getStore().localStorage;

      localStorageData[`${itemName}`] = itemValue;
    }

    setSessionStorageItem(itemName: string, itemValue: any) {
      if (this.hasUserAgreedPrivacyConsent()) {
        localStorageUtils.setSessionStorageItemToBrowser(itemName, itemValue);

        return;
      }

      const sessionStorageData: ILocalStorageData = this.getStore().localStorage;

      sessionStorageData[`${itemName}`] = itemValue;
    }

    /**
     * save browser storage buffer data to browser, then flush buffer
     */
    saveDataToBrowserAfterUserAgreedPrivacyConsent() {
      if (!this.hasUserAgreedPrivacyConsent()) {
        // indicate user has agreed our privacy consent
        this.setUserHasAgreedPrivacyConsent();
      }

      // dump and flush
      this.dumpAndFlushStore();
    }

    /**
     * save browser storage buffer data to browser, then flush buffer
     */
    private dumpAndFlushStore() {
      // dump
      this.copyStoreToBrowser();

      // flush
      this.flushStore();
    }

    /**
     * copy browser storage buffer data to brower storage, i.e. cookie, and local storage
     */
    private copyStoreToBrowser() {
      // dump cookie
      this.copyStoreCookiesToBrowser();

      // dump local storage
      this.copyStoreLocalStorageToBrowser();
    }

    /**
     * copy browser storage cookie buffer data to brower storage cookie
     */
    private copyStoreCookiesToBrowser() {
      try {
        const cookieData: ICookieData = this.getStore().cookies;

        Object.keys(cookieData).forEach((cookieName: string) => {
          const cookieValue = cookieData[`${cookieName}`];

          if (isEmptyNullorUndefinedString(cookieValue)) {
            return;
          }

          this.setCookieItem(cookieName, cookieValue);
        });
      } catch (error) {
        const errorText: string = isNullorUndefined(error) ? undefined : stringifyError(error);

        const errorMessage: string = `Failed: Dump browser storage cookie buffer data to browser storage cookie, Error: ${errorText}`;

        TelemetryUtils.logTelemetryForAccessError(
          Constants.Telemetry.Action.Copy,
          Constants.Telemetry.ActionModifier.BrowserStorageCookieDataBuffer,
          errorMessage
        );
      }
    }

    /**
     * copy browser storage local storage buffer data to brower storage local storage
     */
    private copyStoreLocalStorageToBrowser() {
      try {
        const localStorageData: ILocalStorageData = this.getStore().localStorage;

        Object.keys(localStorageData).forEach((localStorageItemName: string) => {
          const localStorageItemValue = localStorageData[`${localStorageItemName}`];

          if (isEmptyNullorUndefinedString(localStorageItemValue)) {
            return;
          }

          this.setLocalStorageItem(localStorageItemName, localStorageItemValue);
        });
      } catch (error) {
        const errorText: string = isNullorUndefined(error) ? undefined : stringifyError(error);

        const errorMessage: string = `Failed: Dump browser storage local storage buffer data to brower storage local storage, Error: ${errorText}`;

        TelemetryUtils.logTelemetryForAccessError(
          Constants.Telemetry.Action.Copy,
          Constants.Telemetry.ActionModifier.BrowserStorageLocalStorageDataBuffer,
          errorMessage
        );
      }
    }

    // flush browser storage buffer data
    flushStore() {
      this.store = this.getDefaultBrowserStorageData();
    }

    hasUserAgreedPrivacyConsent(): boolean {
      return cookieUtils.isValidForUserAgreedPrivacyConsent();
    }

    private setUserHasAgreedPrivacyConsent() {
      cookieUtils.setUserHasAgreedPrivacyConsent();
    }
  }

  // NOTE: This module should only be used in class BrowserStorage
  // DO NOT export module
  module cookieUtils {
    /**
     * read cookie directly from browser
     *
     *  NOTE: this method should not be directly called elsewhere but only in class BrowserStorage
     *
     * @param itemName cookie item name
     * @param shouldDecode cookie should be decoded
     *
     * @returns value of cookie item, as primitive or an object
     */
    export function getCookieItemFromBrowser(itemName: string, shouldDecode = true): any {
      try {
        const cookiePrefix: string = `${itemName}=`;
        const cookieArray: string[] = getDocument()
          .cookie.split(';')
          .map((cookie: string) => cookie.trim());

        // eslint-disable-next-line no-undef-init
        let cookieValue: string = undefined;

        // find the cookie
        // break the loop immediately once found
        cookieArray.some((cookie: string) => {
          if (cookie.indexOf(cookiePrefix) === 0) {
            const rawValue: string = cookie.substring(cookiePrefix.length);

            cookieValue = shouldDecode ? JSON.parse(decodeURIComponent(rawValue)) : rawValue;

            return true;
          }

          return false;
        });

        return cookieValue;
      } catch (error) {
        const errorText: string = isNullorUndefined(error) ? undefined : stringifyError(error);

        const errorMessage: string = `Failed: read access of cookie item ${itemName} shouldDecode ${shouldDecode}, Error: ${errorText}`;

        TelemetryUtils.logTelemetryForAccessError(
          Constants.Telemetry.Action.ReadAccess,
          Constants.Telemetry.ActionModifier.CookieItem,
          errorMessage
        );

        return undefined;
      }
    }

    /**
     * save cookie directly to browser
     *
     *  NOTE: this method should not be directly called elsewhere but only in class BrowserStorage
     *
     * @param itemName cookie item name
     * @param itemValue cookie item value
     * @param optionText cookie options as a string
     */
    export function setCookieItemToBrowser(itemName: string, itemValue: any, optionText = getCookieOptionExpires()) {
      try {
        getDocument().cookie = `${itemName}=${itemValue};path=/;${optionText}`;
      } catch (error) {
        const errorText: string = isNullorUndefined(error) ? undefined : stringifyError(error);

        const errorMessage: string = `Failed: write access of cookie item ${itemName} value ${itemValue}, Error: ${errorText}`;

        TelemetryUtils.logTelemetryForAccessError(
          Constants.Telemetry.Action.WriteAccess,
          Constants.Telemetry.ActionModifier.CookieItem,
          errorMessage
        );

        return errorMessage;
      }
    }

    /**
     * check whether user has agreed privacy consent, which should also be valid
     *
     *  NOTE: this method is an exception which directly access the browser storage
     *
     * @returns true if all following apllies:
     *      1) user has agreed to privacy consent
     *      2) agreed privacy consent has not expired
     */
    export function isValidForUserAgreedPrivacyConsent(): boolean {
      const cookie = getCookieItemFromBrowser(Constants.Cookies.privacyConsentAcceptedDate, false);
      return !isCookieExpired(cookie);
    }

    /**
     * Check if user accepted privacy consent is expired.
     *
     *  NOTE: currently is only capable of checking if cookie value consists only of a time string
     *  TO DO: update to check other cookie expiry, whose cookie value has a substring of `;path=\;${expiry}`
     *
     *  @param cookieValue: cookie item value
     *  @param expiry: valid duration, default is 13 months after saved in browser
     *
     *  @returns
     *      true, if cookie is empty, or has no expiration date, or expired
     *      false, otherwise
     */
    function isCookieExpired(cookieValue: string, expiry = Constants.CookieExpiry.Default): boolean {
      if (!cookieValue) {
        return true;
      }

      const date: Date = new Date();
      const currentTime: number = date.getTime();

      const cookieInitialTime: number = parseInt(cookieValue, 10);
      const cookieEndTime: number = cookieInitialTime + expiry;

      return currentTime > cookieEndTime;
    }

    /**
     * set a flag indicating user has agreed to privacy consent, banner at the top
     *
     *  this is achieved by simply setting a privacy consent accepted date as a cookie in browser
     *  NOTE: this method is an exception which directly access the browser storage
     */
    export function setUserHasAgreedPrivacyConsent() {
      setCookieItemToBrowserForUserAgreedPrivacyConsentDate();
    }

    /**
     * save expiration date of privacy consent cookie (banner at the top)
     *
     *  cookie banner won't show to user for next 13 months.
     *  NOTE: this method is an exception which directly access the browser storage
     */
    function setCookieItemToBrowserForUserAgreedPrivacyConsentDate() {
      // skip if user-agreed privacy consent is valid
      if (isValidForUserAgreedPrivacyConsent()) {
        return;
      }

      // update cookie accepted date to current
      const cookieAcceptedDate: string = Date.now().toString();

      // a cookie serves as
      //  a) a value when checking expiry, also serves as
      //  b) a flag indicating user has agreed privacy consent
      setCookieItemToBrowser(Constants.Cookies.privacyConsentAcceptedDate, cookieAcceptedDate);
    }
  }

  // NOTE: This module should only be used in class BrowserStorage
  // DO NOT export module
  module localStorageUtils {
    /**
     * read local storage item directly from browser
     *
     *  NOTE: this method should not be directly called elsewhere but only in class BrowserStorage
     *
     * @param itemName local storage item name
     *
     * @returns local storage item value, as primitive or an object
     */
    export function getLocalStorageItemFromBrowser(itemName: string): any {
      try {
        const localStorage: Storage = getWindow().localStorage;

        return localStorage.getItem(itemName);
      } catch (error) {
        const errorText: string = isNullorUndefined(error) ? undefined : stringifyError(error);

        const errorMessage: string = `Failed: read access of local storage item ${itemName}, Error: ${errorText}`;

        TelemetryUtils.logTelemetryForAccessError(
          Constants.Telemetry.Action.ReadAccess,
          Constants.Telemetry.ActionModifier.LocalStorageItem,
          errorMessage
        );

        return errorMessage;
      }
    }

    export function getSessionStorageItemFromBrowser(itemName: string): string {
      try {
        const sessionStorage: Storage = getWindow().sessionStorage;

        return sessionStorage.getItem(itemName);
      } catch (error) {
        const errorText: string = isNullorUndefined(error) ? undefined : stringifyError(error);

        const errorMessage: string = `Failed: read access of session storage item ${itemName}, Error: ${errorText}`;

        TelemetryUtils.logTelemetryForAccessError(
          Constants.Telemetry.Action.ReadAccess,
          Constants.Telemetry.ActionModifier.SessionStorageItem,
          errorMessage
        );

        throw errorMessage;
      }
    }

    /**
     * save local storage directly to browser
     *
     *  NOTE: this method should not be directly called elsewhere but only in class BrowserStorage
     *
     * @param itemName local storage item name
     * @param itemValue local storage item value
     */
    export function setLocalStorageItemToBrowser(itemName: string, itemValue: any) {
      try {
        const localStorage: Storage = getWindow().localStorage;

        localStorage.setItem(itemName, itemValue);
      } catch (error) {
        const errorText: string = isNullorUndefined(error) ? undefined : stringifyError(error);

        const errorMessage: string = `Failed: write access of local storage item ${itemName} value ${itemValue}, Error: ${errorText}`;

        TelemetryUtils.logTelemetryForAccessError(
          Constants.Telemetry.Action.WriteAccess,
          Constants.Telemetry.ActionModifier.LocalStorageItem,
          errorMessage
        );

        return errorMessage;
      }
    }

    export function setSessionStorageItemToBrowser(itemName: string, itemValue: string) {
      try {
        const sessionStorage: Storage = getWindow().sessionStorage;

        sessionStorage.setItem(itemName, itemValue);
      } catch (error) {
        const errorText: string = isNullorUndefined(error) ? undefined : stringifyError(error);

        const errorMessage: string = `Failed: write access of session storage item ${itemName} value ${itemValue}, Error: ${errorText}`;

        TelemetryUtils.logTelemetryForAccessError(
          Constants.Telemetry.Action.WriteAccess,
          Constants.Telemetry.ActionModifier.SessionStorageItem,
          errorMessage
        );

        throw errorMessage;
      }
    }
  }

  export module TelemetryUtils {
    /**
     * log telemetry read, or write access error on browser storage, cookie, and local storage
     *
     * @param action telemetry action
     * @param actionModifier telemetry action modifier
     * @param errorMessage telemetry details message
     */
    export function logTelemetryForAccessError(action: string, actionModifier: string, errorMessage: string = undefined) {
      const page = getWindow() && getWindow().location && getWindow().location.href;

      const payload: ITelemetryData = {
        page: page,
        action: action,
        actionModifier: actionModifier,
        details: errorMessage,
      };

      SpzaInstrumentService.getProvider().probe<ITelemetryData>(Constants.Telemetry.ProbeName.LogInfo, payload);
      logger.info(payload.details, {
        action: payload.action,
        actionModifier: payload.actionModifier,
      });
    }
  }
}
