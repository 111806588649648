import { IAccessToken } from '@src/State';
import { getScopeByAADResource } from './scopes';
import { Constants } from '@shared/utils/constants';
import { logger } from '@src/logger';
import AuthClient from './client';
import { pick } from 'lodash-es';

type Resource = keyof IAccessToken;

export const fetchAccessTokens = async ({ resources }: { resources: Resource[] }): Promise<IAccessToken> => {
  const scopesByResource = getScopeByAADResource();
  const filteredScopesByResource = pick(scopesByResource, resources);

  const filteredResources = Object.keys(filteredScopesByResource) as Resource[];
  const results = await Promise.allSettled(
    filteredResources.map((resource: Resource) => {
      return AuthClient.getInstance().acquireTokenSilent({
        scopes: [filteredScopesByResource[`${resource}`]],
        forceRefresh: true,
      });
    })
  );

  return results.reduce((acc, result, index) => {
    const resource = filteredResources[`${index}`];

    if (result.status === 'rejected') {
      logger.error(
        JSON.stringify({
          resource,
          error: result.reason,
        }),
        {
          action: Constants.Telemetry.Action.Login,
          actionModifier: Constants.Telemetry.ActionModifier.AccessTokenSilentFetchingError,
          error: result.reason,
        }
      );
    } else {
      logger.info(JSON.stringify({ resource, fromCache: result.value.fromCache }), {
        action: Constants.Telemetry.Action.Login,
        actionModifier: Constants.Telemetry.ActionModifier.AccessTokenSilentFetchingSuccess,
      });
    }

    return {
      ...acc,
      [`${resource}`]: result.status === 'fulfilled' ? result.value.accessToken : '',
    };
  }, {} as IAccessToken);
};
