import { Constants } from '@shared/utils/constants';

// dedicated utils, one for spza, one for mac
export function getUserFavouriteEntityServiceType() {
  return Constants.UserFavourite.ApplicationTypes.ITConsultingServices;
}

export function getUserFavouriteEntityAppType() {
  return Constants.UserFavourite.ApplicationTypes.ITApplications;
}
