import React, { FunctionComponent } from 'react';
import * as PropTypes from 'prop-types';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { ILinkedInProductGroup } from '@src/State';
import { FontIcon, Link, Text, mergeStyleSets, Stack } from '@fluentui/react';
import { numberToLocaleString } from '@shared/utils/localeUtils';
import { CommunicationColors } from '@fluentui/theme';
import { Constants } from '@shared/utils/constants';
import { SpzaInstrumentService } from 'services/telemetry/spza/spzaInstrument';
import { ITelemetryData } from '@shared/Models';
import { withRouter, WithRouterProps } from '@shared/routerHistory';
import { logger } from '@src/logger';

const contentStyles = mergeStyleSets({
  linkedinSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
  },
  linkedinLogoIcon: {
    width: 18,
    height: 18,
    padding: 0,
    lineHeight: 20,
    verticalAlignment: 'center',
    color: CommunicationColors.primary,
  },
  linkedinMembersText: {
    marginLeft: 6,
    fontSize: 14,
    lineHeight: 20,
    fontWeight: 400,
  },
  linkedinLink: {
    marginLeft: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textDecoration: 'none',
  },
  linkedinLinkText: {
    fontSize: 14,
    fontWeight: 400,
    textDecoration: 'underline',
  },
  externallinkIcon: {
    paddingLeft: 4,
    width: 13,
    height: 13,
    color: CommunicationColors.primary,
    lineHeight: 20,
  },
});

export interface ILinkedinProductGroupBarProps {
  linkedInProductGroup: ILinkedInProductGroup;
  locale: string;
  countryCode: string;
}

const LinkedinProductGroupBarFunction: FunctionComponent<ILinkedinProductGroupBarProps & WithRouterProps> = (
  { linkedInProductGroup, locale, countryCode, location }: ILinkedinProductGroupBarProps & WithRouterProps,
  context: ILocContext & ILocParamsContext
) => {
  const onLinkClick = () => {
    const payload: ITelemetryData = {
      page: location?.pathname,
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.LinkedinLink,
      details: JSON.stringify({ offerId: linkedInProductGroup.offerId }),
    };
    SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
    logger.info(payload.details, {
      page: payload.page,
      action: payload.action,
      actionModifier: payload.actionModifier,
    });
  };

  return (
    <Stack className={contentStyles.linkedinSection}>
      <FontIcon iconName="LinkedInLogo" className={contentStyles.linkedinLogoIcon} />
      <Text className={contentStyles.linkedinMembersText}>
        {context.locParams('Linkedin_Members', [
          numberToLocaleString({ number: linkedInProductGroup.membersCount, locale, countryCode }),
        ])}
      </Text>
      <Stack horizontal={true}>
        <Link className={contentStyles.linkedinLink} href={linkedInProductGroup.detailsUrl} target="_blank" onClick={onLinkClick}>
          <Text className={contentStyles.linkedinLinkText}> {context.loc('Explore_Group_On_Linkedin')}</Text>
        </Link>
        <FontIcon iconName="OpenInNewWindow" className={contentStyles.externallinkIcon} />
      </Stack>
    </Stack>
  );
};

export const LinkedinProductGroupBar = withRouter(LinkedinProductGroupBarFunction);

(LinkedinProductGroupBarFunction as any).contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
};
