import {
  ExternalRatingSummaries as ExternalRatingSummariesComponent,
  IExternalRatingSummariesProps,
} from '@shared/components/externalRatingSummaries';
import { IState } from '../../State';
import { connect } from 'react-redux';

export const mapStateToProps = (state: IState, ownProps: IExternalRatingSummariesProps) => {
  return {
    ...ownProps,
    storefrontName: state.config.storefrontName,
  };
};

export const ExternalRatingSummaries = connect(mapStateToProps)(ExternalRatingSummariesComponent);
