import * as React from 'react';
import * as PropTypes from 'prop-types';
import { IBannerData, ITelemetryData } from '@shared/Models';
import { ILocContext, ILocParamsContext, ICommonContext } from '@shared/interfaces/context';
import { BannerLinks } from '@shared/components/bannerLinks';
import SpzaComponent from '@shared/components/spzaComponent';
import { getWindow } from '@shared/services/window';
import { Constants } from '@shared/utils/constants';
import { IProvider } from 'services/telemetry/core/provider';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { logger } from '@src/logger';
import { Link, Text } from '@fluentui/react';
import { useHydration } from '@shared/hooks/useHydration';

export interface BannerProps {
  data: IBannerData;
  linkClickHandle?: () => void;
}

interface WrappedBannerProps extends BannerProps {
  bannerClickHandle?: () => void;
}

const bannerLinkClicked = ({
  event,
  linkClickHandle,
}: {
  event: React.MouseEvent<HTMLAnchorElement>;
  linkClickHandle: () => void;
}) => {
  event.stopPropagation();
  linkClickHandle();
};

const BannerContent: React.FunctionComponent<WrappedBannerProps> = ({ data, linkClickHandle }) => {
  const { imgURL, title, description, links } = data;
  return (
    <div
      className={'bannerImgWrapper'}
      // eslint-disable-next-line react/forbid-dom-props
      style={{ backgroundImage: `url(${imgURL})` }}
    >
      <div className="bannerText">
        <div className="textContinar">
          <Text variant="xxLarge" className="bannerTitle">
            {title}
          </Text>
          <Text variant="medium" className="bannerDescription">
            {description}
          </Text>
          <div className="bannerLinks">
            <BannerLinks links={links} onLinkClick={({ event }) => bannerLinkClicked({ event, linkClickHandle })} />
          </div>
        </div>
      </div>
    </div>
  );
};

const WrappedBanner: React.FC<React.PropsWithChildren<WrappedBannerProps>> = ({ data, bannerClickHandle, children }) => {
  const { links } = data;
  const url = links?.[links.length - 1]?.url;
  const isHydrated = useHydration();

  const shouldWrapWithLink = React.useMemo(() => data.links?.length > 0, [data.links]);

  return isHydrated && shouldWrapWithLink ? (
    <Link href={url} target="_blank" onClick={bannerClickHandle}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
};

export class Banner extends SpzaComponent<BannerProps, any> {
  context: ICommonContext & ILocContext & ILocParamsContext;
  private provider: IProvider;

  constructor(props: BannerProps, context: any) {
    super(props, context);
    this.provider = SpzaInstrumentService.getProvider();
  }

  handleBannerHyperLinkClicked = (): void => {
    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.BannerHyperLink,
      details: this.props.data.title,
    };
    this.provider.probe<ITelemetryData>(Constants.Telemetry.ProbeName.LogInfo, payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });
  };

  handleBannerClicked = (): void => {
    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.BannerClick,
      details: this.props.data.title,
    };
    this.provider.probe<ITelemetryData>(Constants.Telemetry.ProbeName.LogInfo, payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });
  };

  renderImpl() {
    return (
      <WrappedBanner data={this.props.data} bannerClickHandle={this.handleBannerClicked}>
        <BannerContent data={this.props.data} linkClickHandle={this.handleBannerHyperLinkClicked} />
      </WrappedBanner>
    );
  }
}

(Banner as any).contextTypes = {
  loc: PropTypes.func,
  locDateString: PropTypes.func,
  locParams: PropTypes.func,
};
