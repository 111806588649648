/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-interface */
import {
  IUserDataState,
  IFeatureFlags,
  IDynamicCampaignState,
  IRelatedItems,
  IAccessToken,
  IUserLoginStatus,
  IUserLoginFetchStatus,
  ILinkedInProductGroup,
  RecommendedWhatsNewApps,
  RecommendedTrendingApps,
  RecommendedWhatsNewService,
  RecommendedTrendingService,
  ServiceCountries,
  RecommendedMicrosoft365WhatsNewApps,
  RecommendedMicrosoft365TrendingApps,
  IExperiment,
} from '@src/State';
import { IDataMap } from '@shared/utils/dataMapping';
import { Constants } from '@shared/utils/constants';
import {
  IReviewPayload,
  IAppDataItem,
  UserSegment,
  IUserProfile,
  IGlobalSettings,
  ICheckoutSettings,
  IPricingInformation,
  IMediaModalPayload,
  IAppsPricingsPayload,
  ICuratedSections,
  ICuratedSectionItem,
  IAppReview,
  TenantsDetails,
  IRestStatus,
  IFuturePrices,
  IPartnerSearchResult,
  IUserLeadProfileAgreement,
  IAzureSubscriptionsResponse,
  ILicense,
  ISearchResultsPayload,
  IEntityDataReceived,
  IShowingResultsForPayload,
  IAccountsDetails,
} from '@shared/Models';
import { WCPconsent } from '@shared/components/cookieBanner';
import { Service } from '@shared/serviceViewModel';
import { IUserFavouriteState } from '@shared/interfaces/userFavouriteModels';
import {
  IAppEntityReviewCommentModalActionPayload,
  IAppsReviewCommentIsLoadingPayload,
  IAppsReviewCommentsReceivedPayload,
  IAppsReviewCommentOperationBaseResultPayload,
  IAppEntityReviewCommentsStateUpdateActionPayload,
  IAppsReviewCommentBaseActionPayload,
} from '@shared/interfaces/reviews/comments';
import type { i18n } from 'i18next';
// simple actions that change state in our app

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface ActionType<TPayload> extends String {}

export type Action<TPayload> = {
  type: ActionType<TPayload>;
  payload: TPayload;
};

interface ActionCreator<P> {
  (payload: P): Action<P>;
}

export function actionCreator<TPayload>(type: ActionType<TPayload>): ActionCreator<TPayload> {
  return (payload) => ({
    type,
    payload,
  });
}

export function isType<TPayload>(action: Action<any>, type: ActionType<TPayload>): action is Action<TPayload> {
  return action.type === type;
}

export const RehydrateClientStateAction: ActionType<void> = 'RehydrateClientStateAction';
export const createRehydrateClientStateAction = actionCreator(RehydrateClientStateAction);

export const SearchboxInputChangedAction: ActionType<{ searchString: string }> = 'SearchboxInputChangedAction';
export const createSearchboxInputChangedAction = actionCreator(SearchboxInputChangedAction);

export const SuggestedChangeAction: ActionType<{ item: IAppDataItem | Service; entityType: Constants.EntityType }> =
  'SuggestedChangeAction';
export const createSuggestedChangeAction = actionCreator(SuggestedChangeAction);

export const SearchSortingOptionChangedAction: ActionType<{ searchSortingOption: Constants.SearchSortingOption }> =
  'SearchSortingOptionChangedAction';
export const createSearchSortingOptionChangedAction = actionCreator(SearchSortingOptionChangedAction);

export const LiveSearchboxFilterAction: ActionType<{ searchText: string }> = 'BasicSearchboxChangedAction';
export const createLiveSearchboxFilterAction = actionCreator(LiveSearchboxFilterAction);

export const ModalAction: ActionType<{
  showModal: boolean;
  modalId?: number;
  entityId?: string;
  options?: any;
  entityType?: Constants.EntityType;
  onModalDismiss?: () => void;
  disableDismissModal?: boolean;
  isOpenedFromPDP?: boolean;
}> = 'ModalAction';
export const createModalAction = actionCreator(ModalAction);

export const VideoModalAction: ActionType<{
  showModal: boolean;
  videoUrl: string;
  videoThumbnail: string;
  videoName: string;
}> = 'VideoModalAction';
export const createVideoModalAction = actionCreator(VideoModalAction);

export const MediaModalAction: ActionType<{
  showModal: boolean;
  payload: IMediaModalPayload;
}> = 'MediaModalAction';
export const createMediaModalAction = actionCreator(MediaModalAction);

export const DriveModalAction: ActionType<{
  showModal: boolean;
  driveUrl: string;
}> = 'DriveModalAction';
export const createDriveModalAction = actionCreator(DriveModalAction);

export const IFieldHubModalAction: ActionType<{
  showModal: boolean;
  reportName: string;
}> = 'IFieldHubModalAction';
export const createIFieldHubModalAction = actionCreator(IFieldHubModalAction);

export const IDisclaimerModalAction: ActionType<{
  showModal: boolean;
  title: string;
  description: string;
}> = 'IDisclaimerModalAction';
export const createIDisclaimerModalAction = actionCreator(IDisclaimerModalAction);

export const InstructionsModalAction: ActionType<{
  showModal: boolean;
  isFromDownload: boolean;
}> = 'InstructionsModalAction';
export const createInstructionsModalAction = actionCreator(InstructionsModalAction);

export const RatingAction: ActionType<IReviewPayload> = 'RatingAction';
export const createRatingAction = actionCreator(RatingAction);

export const BreadcrumbAction: ActionType<{ breadcrumbUrl: string }> = 'BreadcrumbAction';
export const createBreadcrumbAction = actionCreator(BreadcrumbAction);

export const SetCurrentViewAction: ActionType<{ currentView: string }> = 'SetCurrentView';
export const createSetCurrentViewAction = actionCreator(SetCurrentViewAction);

// determine current rendered gallery page is curated view or filtered view
// it should be undefined when currentView is not a gallery page
export const UpdateCurrentGalleryViewIsCuratedAction: ActionType<{
  isCuratedGalleryView: boolean;
}> = 'UpdateCurrentGalleryViewIsCuratedAction';
export const createUpdateCurrentGalleryViewIsCurated = actionCreator(UpdateCurrentGalleryViewIsCuratedAction);

export const SetLandingViewAction: ActionType<{ landingView: string }> = 'SetLandingView';
export const createSetLandingViewAction = actionCreator(SetLandingViewAction);

export const EnableNewNpsAction: ActionType<{ enable: boolean }> = 'EnableNewNps';
export const createEnableNewNpsAction = actionCreator(EnableNewNpsAction);

export const SetInitializedPCAInstanceAction: ActionType<{ initialized: boolean }> = 'SetInitializedPCA';
export const createSetInitializedPCAInstanceAction = actionCreator(SetInitializedPCAInstanceAction);

export const SetIsNpsLogicVerifiedAction: ActionType<{ verified: boolean }> = 'SetIsNpsLogicVerified';
export const createSetIsNpsLogicVerifiedAction = actionCreator(SetIsNpsLogicVerifiedAction);

export const ShowingResultsForReceviedAction: ActionType<IShowingResultsForPayload> = 'ShowingResultsForReceviedAction';
export const createShowingResultsForReceivedAction = actionCreator(ShowingResultsForReceviedAction);

export const FilteredSearchResultsReceivedAction: ActionType<ISearchResultsPayload> = 'FilteredSearchResultsReceivedAction';
export const createFilteredSearchResultsReceivedAction = actionCreator(FilteredSearchResultsReceivedAction);

export const PartnerSearchResultsReceivedAction: ActionType<{
  partnerSearchResults: IPartnerSearchResult[];
}> = 'PartnerSearchResultsReceivedAction';
export const createPartnerSearchResultsReceivedAction = actionCreator(PartnerSearchResultsReceivedAction);

export const EntityDataReceivedAction: ActionType<IEntityDataReceived> = 'EntityDataReceivedAction';
export const createEntityDataReceivedAction = actionCreator(EntityDataReceivedAction);

export const UserProfileLoadingAction: ActionType<{ isLoading: boolean }> = 'UserProfileLoadingAction';
export const createUserProfileLoadingAction = actionCreator(UserProfileLoadingAction);

export const UserProfileAction: ActionType<IUserProfile> = 'UserProfileAction';
export const createUserProfileAction = actionCreator(UserProfileAction);

export const UserTenantsDetailsAction: ActionType<TenantsDetails> = 'UserTenantsDetailsAction';
export const createUserTenantsDetailsAction = actionCreator(UserTenantsDetailsAction);
export const UserTenantsDetailsLoadingAction: ActionType<IRestStatus> = 'UserTenantsDetailsLoadingAction';
export const createUserTenantsDetailsLoadingAction = actionCreator(UserTenantsDetailsLoadingAction);

export const UpdateUserProfileAction: ActionType<IUserLeadProfileAgreement> = 'UpdateUserProfileAction';
export const createUpdateUserProfileAction = actionCreator(UpdateUserProfileAction);

export const SetAccountAction: ActionType<IAccountsDetails> = 'SetAccountAction';
export const createSetAccountAction = actionCreator(SetAccountAction);

export const SetAccountIsLoadingAction: ActionType<{ isLoading: boolean }> = 'SetAccountIsLoadingAction';
export const createSetAccountIsLoadingAction = actionCreator(SetAccountIsLoadingAction);

export const SetIsMaccTenantAction: ActionType<{ isMacc: boolean }> = 'SetIsMaccTenantAction';
export const createSetIsMaccTenantAction = actionCreator(SetIsMaccTenantAction);

export const UpdateUserProfileLicenseAction: ActionType<{
  licenseType: number;
  license: ILicense;
}> = 'UpdateUserProfileLicenseAction';
export const createUpdateUserProfileLicenseAction = actionCreator(UpdateUserProfileLicenseAction);

/** Updates IUserDataState only clears the phone number to string empty */
export const ClearUserPropertiesAction: ActionType<null> = 'ClearUserPropertiesAction';
export const createClearUserPropertiesAction = actionCreator(ClearUserPropertiesAction);

// Actions for SI Partners - End

export const PowerBIDataReceivedAction: ActionType<{ appData: any[] }> = 'PowerBIDataReceivedAction';
export const createPowerBIDataReceivedAction = actionCreator(PowerBIDataReceivedAction);

export const PartnerAppDataReceivedAction: ActionType<{
  appData: any[];
  embedHost: string;
}> = 'PartnerAppDataReceivedAction';
export const createPartnerAppDataReceivedAction = actionCreator(PartnerAppDataReceivedAction);

export const SetEntityIdLoadingAction: ActionType<{
  entityType: Constants.EntityType;
  entityId: string;
}> = 'SetEntityIdLoadingAction';
export const createSetEntityIdLoadingAction = actionCreator(SetEntityIdLoadingAction);

export const SetEntityIdFailedAction: ActionType<{
  entityType: Constants.EntityType;
  entityId: string;
}> = 'SetEntityIdFailedAction';
export const createSetEntityIdFailedAction = actionCreator(SetEntityIdFailedAction);

export const EntityDetailsReceivedAction: ActionType<{
  entityType: Constants.EntityType;
  entityDetails: any;
}> = 'EntityDetailsReceivedAction';
export const createEntityDetailsReceivedAction = actionCreator(EntityDetailsReceivedAction);

export const EntityFullDataReceivedAction: ActionType<{
  entityType: Constants.EntityType;
  entityDetails: any;
}> = 'EntityFullDataReceivedAction';
export const createEntityFullDataReceivedAction = actionCreator(EntityFullDataReceivedAction);

export const EntityReviewCommentsReceivedAction: ActionType<IAppsReviewCommentsReceivedPayload> =
  'EntityReviewCommentsReceivedAction';
export const createEntityReviewCommentsReceivedAction = actionCreator(EntityReviewCommentsReceivedAction);

export const EntityReviewCommentsLoadingAction: ActionType<IAppsReviewCommentIsLoadingPayload> =
  'EntityReviewCommentsLoadingAction';
export const createEntityReviewCommentsLoadingAction = actionCreator(EntityReviewCommentsLoadingAction);

export const EntityReviewCommentsCleanupAction: ActionType<IAppsReviewCommentBaseActionPayload> =
  'EntityReviewCommentsCleanupAction';
export const createEntityReviewCommentsCleanupAction = actionCreator(EntityReviewCommentsCleanupAction);

export const EntityReviewCommentCreateResult: ActionType<IAppsReviewCommentOperationBaseResultPayload> =
  'EntityReviewCommentCreateResult';
export const createEntityReviewCommentCreateResult = actionCreator(EntityReviewCommentCreateResult);

export const EntityReviewCommentDeleteResult: ActionType<IAppsReviewCommentOperationBaseResultPayload> =
  'EntityReviewCommentDeleteResult';
export const createEntityReviewCommentDeleteResult = actionCreator(EntityReviewCommentDeleteResult);

export const EntityReviewCommentUpdateResult: ActionType<IAppsReviewCommentOperationBaseResultPayload> =
  'EntityReviewCommentUpdateResult';
export const createEntityReviewCommentUpdateResult = actionCreator(EntityReviewCommentUpdateResult);

export const EntityReviewCommentModalAction: ActionType<IAppEntityReviewCommentModalActionPayload> =
  'EntityReviewCommentModalAction';
export const createEntityReviewCommentModalAction = actionCreator(EntityReviewCommentModalAction);

export const EntityReviewCommentsUpdateTotalCommentCountAction: ActionType<IAppEntityReviewCommentsStateUpdateActionPayload> =
  'EntityReviewCommentsUpdateTotalCommentCountAction';
export const createEntityReviewCommentsUpdateTotalCommentCountAction = actionCreator(
  EntityReviewCommentsUpdateTotalCommentCountAction
);

export const EntityReviewCommentsUnloadPagesAction: ActionType<IAppsReviewCommentBaseActionPayload> =
  'EntityReviewCommentsUnloadPagesAction';
export const createEntityReviewCommentsUnloadPagesAction = actionCreator(EntityReviewCommentsUnloadPagesAction);

export const EntityReviewCommentsInitializeStateAction: ActionType<IAppEntityReviewCommentsStateUpdateActionPayload> =
  'EntityReviewCommentsInitializeStateAction';
export const createEntityReviewCommentsInitializeStateAction = actionCreator(EntityReviewCommentsInitializeStateAction);

export const EntityMarkedAsHelpfulReviewUpdateListAction: ActionType<{ offerId: string; reviewIds: string[] }> =
  'EntityMarkedAsHelpfulReviewUpdateListAction';
export const createEntityMarkedAsHelpfulReviewUpdateListAction = actionCreator(EntityMarkedAsHelpfulReviewUpdateListAction);

export const EntityUserCommentedReviewIdsUpdateListAction: ActionType<{ commentReviewIds: string[] }> =
  'EntityUserCommentedReviewIdsUpdateListAction';
export const createEntityUserCommentedReviewIdsUpdateListAction = actionCreator(EntityUserCommentedReviewIdsUpdateListAction);
export const EntityLinkedInProductGroupUpdateAction: ActionType<{
  legacyOfferId: string;
  linkedInProductGroup: ILinkedInProductGroup;
}> = 'EntityLinkedInProductGroupUpdateAction';
export const createEntityLinkedInProductGroupUpdateAction = actionCreator(EntityLinkedInProductGroupUpdateAction);

export const EntityReviewSetIsHelpfulLoadingAction: ActionType<{ reviewId: string; isLoading: boolean }> =
  'EntityReviewSetIsHelpfulLoadingAction';
export const createEntityReviewSetIsHelpfulLoadingAction = actionCreator(EntityReviewSetIsHelpfulLoadingAction);

export const EntityReviewSetIsHelpfulAction: ActionType<{ reviewId: string; isHelpful: boolean }> =
  'EntityReviewSetIsHelpfulAction';
export const createEntityReviewSetIsHelpfulAction = actionCreator(EntityReviewSetIsHelpfulAction);

export const EntityReviewMarkAsHelpfulModalAction: ActionType<{
  showModal: boolean;
}> = 'EntityReviewMarkAsHelpfulModalAction';
export const createEntityReviewMarkAsHelpfulModalAction = actionCreator(EntityReviewMarkAsHelpfulModalAction);

export const EntityReviewSetMarkAsHelpfulCount: ActionType<{
  count: number;
  reviewId: string;
}> = 'EntityReviewSetMarkAsHelpfulCount';
export const createEntityReviewSetMarkAsHelpfulCount = actionCreator(EntityReviewSetMarkAsHelpfulCount);

export const RelatedAppsItemsLoadingAction: ActionType<{
  entityType: Constants.EntityType;
  entityId: string;
}> = 'RelatedAppsItemsLoadingAction';
export const createRelatedAppsItemsLoadingAction = actionCreator(RelatedAppsItemsLoadingAction);

export const GetAppReviewsAction: ActionType<{
  entityId: string;
  reviews: IAppReview[];
  isPurchased: boolean;
  userReview?: IAppReview;
}> = 'GetAppReviewsAction';
export const createGetAppReviewsAction = actionCreator(GetAppReviewsAction);

export const SetStorefrontNameAction: ActionType<string> = 'SetStorefrontNameAction';
export const createSetStorefrontNameAction = actionCreator(SetStorefrontNameAction);

export const RelatedAppsItemsReceivedAction: ActionType<{
  entityType: Constants.EntityType;
  entityId: string;
  relatedAppsItems: IRelatedItems;
}> = 'RelatedAppsItemsReceivedAction';
export const createRelatedAppsItemsReceivedAction = actionCreator(RelatedAppsItemsReceivedAction);

export const RelatedAppsItemsPricingDataReceivedAction: ActionType<IAppsPricingsPayload> =
  'RelatedAppsItemsPricingDataReceivedAction';
export const createRelatedAppsItemsPricingDataReceivedAction = actionCreator(RelatedAppsItemsPricingDataReceivedAction);

export const RecommendedAppsItemsPricingDataReceivedAction: ActionType<IAppsPricingsPayload> =
  'RecommendedAppsItemsPricingDataReceivedAction';
export const createRecommendedAppsItemsPricingDataReceivedAction = actionCreator(RecommendedAppsItemsPricingDataReceivedAction);

export const RecommendedWhatsNewAppsReceivedAction: ActionType<RecommendedWhatsNewApps> = 'RecommendedWhatsNewAppsReceivedAction';
export const createRecommendedWhatsNewAppsReceivedAction = actionCreator(RecommendedWhatsNewAppsReceivedAction);

export const RecommendedTrendingAppsReceivedAction: ActionType<RecommendedTrendingApps> = 'RecommendedTrendingAppsReceivedAction';
export const createRecommendedTrendingAppsReceivedAction = actionCreator(RecommendedTrendingAppsReceivedAction);

export const RecommendedWhatsNewServiceReceivedAction: ActionType<RecommendedWhatsNewService> =
  'RecommendedWhatsNewServiceReceivedAction';
export const createRecommendedWhatsNewServiceReceivedAction = actionCreator(RecommendedWhatsNewServiceReceivedAction);

export const RecommendedTrendingServiceReceivedAction: ActionType<RecommendedTrendingService> =
  'RecommendedTrendingServiceReceivedAction';
export const createRecommendedTrendingServiceReceivedAction = actionCreator(RecommendedTrendingServiceReceivedAction);

export const RecommendedMicrosoft365WhatsNewAppsReceivedAction: ActionType<RecommendedMicrosoft365WhatsNewApps> =
  'RecommendedMicrosoft365WhatsNewAppsReceivedAction';
export const createRecommendedMicrosoft365WhatsNewAppsReceivedAction = actionCreator(
  RecommendedMicrosoft365WhatsNewAppsReceivedAction
);

export const RecommendedMicrosoft365TrendingAppsReceivedAction: ActionType<RecommendedMicrosoft365TrendingApps> =
  'RecommendedMicrosoft365TrendingAppsReceivedAction';
export const createRecommendedMicrosoft365TrendingAppsReceivedAction = actionCreator(
  RecommendedMicrosoft365TrendingAppsReceivedAction
);

export const BillingRegionReadFromCookieAction: ActionType<{
  billingRegion: string;
}> = 'BillingRegionReadFromCookieAction';
export const createBillingRegionReadFromCookieAction = actionCreator(BillingRegionReadFromCookieAction);

export const AppDetailPricingReceivedAction: ActionType<{
  entityId: string;
  pricing: IPricingInformation;
  futurePrices: IFuturePrices;
}> = 'AppDetailPricingReceivedAction';
export const createAppDetailPricingReceivedAction = actionCreator(AppDetailPricingReceivedAction);

export const SearchQueryChangedAction: ActionType<{
  performedQuery: string;
  entityType: Constants.EntityType;
}> = 'SearchQueryChangedAction';
export const createSearchQueryChangedAction = actionCreator(SearchQueryChangedAction);

export const SearchDataReceivedAction: ActionType<{
  entityIdData: any[];
  performedQuery: string;
  entityType: Constants.EntityType;
}> = 'SearchDataReceivedAction';
export const createSearchDataReceivedAction = actionCreator(SearchDataReceivedAction);

export const SearchSuggestionsRequestId: ActionType<{
  suggestionsRequestId: string;
}> = 'SearchSuggestionsRequestId';
export const setSuggestionsRequestId = actionCreator(SearchSuggestionsRequestId);

export const CuratedDataReceivedAction: ActionType<{
  entityType: Constants.EntityType;
  curatedData: ICuratedSections<ICuratedSectionItem>;
  userSegment: string;
}> = 'CuratedDataReceivedAction';
export const createCuratedDataReceivedAction = actionCreator(CuratedDataReceivedAction);

export const DataMapReceivedAction: ActionType<{
  entityType: Constants.EntityType;
  dataMap: IDataMap;
}> = 'DataMapReceivedAction';
export const createDataMapReceivedAction = actionCreator(DataMapReceivedAction);

export const UserSignInAction: ActionType<IUserDataState> = 'UserSignInAction';
export const createUserSignInAction = actionCreator(UserSignInAction);

export const UserSignInStatusAction: ActionType<IUserLoginStatus> = 'UserSignInStatusAction';
export const UserSignInFetchStatusAction: ActionType<IUserLoginFetchStatus> = 'UserSignInFetchStatusAction';

export const UserSignInStatusDataAction: ActionType<IUserLoginStatus & IUserLoginFetchStatus> = 'UserSignInStatusDataAction';
export const UserAccesstokensAction: ActionType<IAccessToken> = 'UserAccesstokensAction';
export const UserLoginDetailsAction: ActionType<Partial<IUserDataState>> = 'UserLoginDetailsAction';

export const createUserSignInStatusAction = actionCreator(UserSignInStatusAction);
export const createUserSignInStatusDataAction = actionCreator(UserSignInStatusDataAction);
export const createUserSignInFetchStatusAction = actionCreator(UserSignInFetchStatusAction);
export const createUserAccesstokensAction = actionCreator(UserAccesstokensAction);
export const createUserLoginDetailsAction = actionCreator(UserLoginDetailsAction);

export const ABTestingEnableAction: ActionType<IExperiment> = 'ABTestingEnableAction';
export const createABTestingAction = actionCreator(ABTestingEnableAction);

export const EmbedUserSignInAction: ActionType<IUserDataState> = 'EmbedUserSignInAction';
export const createEmbedUserSignInAction = actionCreator(EmbedUserSignInAction);

export const NpsAction: ActionType<IUserDataState> = 'NpsAction';
export const createNpsAction = actionCreator(NpsAction);

export const UserReviewUpdateAction: ActionType<boolean> = 'UserReviewUpdateAction';
export const createUserReviewUpdatedAction = actionCreator(UserReviewUpdateAction);

export const EmbeddedAppSettingAction: ActionType<{ isEmbedded: boolean }> = 'EmbeddedAppSettingAction';
export const createEmbeddedAppSettingAction = actionCreator(EmbeddedAppSettingAction);

export const SetInitLoadingAction: ActionType<{ initLoading: boolean }> = 'SetInitLoadingAction';
export const createSetInitLoadingAction = actionCreator(SetInitLoadingAction);

export const NationalCloudSettingAction: ActionType<{ nationalCloud: string }> = 'NationalCloudSettingAction';
export const createNationalCloudSettingAction = actionCreator(NationalCloudSettingAction);

export const EmbeddedHostAction: ActionType<{ embedHost: string }> = 'EmbeddedHostAction';
export const createEmbeddedHostAction = actionCreator(EmbeddedHostAction);

export const LocStringsReceivedAction: ActionType<{
  localizedstrings: any;
  locale: string;
}> = 'LocStringsReceivedAction';
export const createLocStringsReceivedAction = actionCreator(LocStringsReceivedAction);

export const I18nextReceivedAction: ActionType<{
  i18nInstance: i18n;
}> = 'I18nextReceivedAction';
export const createI18nextReceivedAction = actionCreator(I18nextReceivedAction);

export const FlightCodesReceivedAction: ActionType<{ flightCodes: string }> = 'FlightCodesReceivedAction';
export const createFlightCodesReceivedAction = actionCreator(FlightCodesReceivedAction);

export const IncludeTestProductsReceivedAction: ActionType<{ includeTestProducts: boolean }> =
  'IncludeTestProductsReceivedAction';
export const createIncludeTestProductsReceivedAction = actionCreator(IncludeTestProductsReceivedAction);

export const IsConverged: ActionType<{ isConverged: boolean }> = 'IsConverged';
export const createIsConverged = actionCreator(IsConverged);

export const CorrelationIdReceivedAction: ActionType<{
  correlationId: string;
}> = 'CorrelationIdReceivedAction';
export const createCorrelationIdAction = actionCreator(CorrelationIdReceivedAction);

export const RequestIdReceivedAction: ActionType<{
  requestId: string;
}> = 'RequestIdReceivedAction';
export const createRequestIdAction = actionCreator(RequestIdReceivedAction);

export const TelemetryLoggedAction: ActionType<void> = 'TelemetryLoggedAction';
export const createAppViewTelemetryLoggedAction = actionCreator(TelemetryLoggedAction);

export const AppsPricingDataReceivedAction: ActionType<IAppsPricingsPayload> = 'AppsPricingAction';
export const createAppsPricingDataReceivedAction = actionCreator(AppsPricingDataReceivedAction);

export const AppPricingDataReceivedAction: ActionType<{
  entityId: string;
  pricingPayload: IAppsPricingsPayload;
}> = 'AppPricingAction';
export const createAppPricingDataReceivedAction = actionCreator(AppPricingDataReceivedAction);

export const AppPricingDataRequestedAction: ActionType<void> = 'AppPricingRequestedAction';
export const createAppPricingDataRequestedAction = actionCreator(AppPricingDataRequestedAction);

export const FeatureFlagsReceivedAction: ActionType<IFeatureFlags> = 'FeatureFlagsReceivedAction';
export const createFeatureFlagsReceivedAction = actionCreator(FeatureFlagsReceivedAction);

export const AddSubscriptionsInTenant: ActionType<IAzureSubscriptionsResponse> = 'AddSubscriptionsInTenant';
export const createAddSubscriptionsInTenant = actionCreator(AddSubscriptionsInTenant);

export const UpdateUserRoleDefinitions: ActionType<string[]> = 'UpdateUserRoleDefinitions';
export const createUpdateUserRoleDefinitions = actionCreator(UpdateUserRoleDefinitions);

export const UserSegmentInitializeAction: ActionType<{
  userSegment: UserSegment;
}> = 'UserSegmentInitializeAction';
export const createUserSegmentInitializeAction = actionCreator(UserSegmentInitializeAction);

export const UserSignOutAction: ActionType<void> = 'UserSignOutAction';
export const createUserSignOutAction = actionCreator(UserSignOutAction);

export const UserFieldHubTypeAction: ActionType<Constants.FieldHubUserType> = 'UserFieldHubTypeAction';
export const createUserFieldHubTypeAction = actionCreator(UserFieldHubTypeAction);
export const CountriesDataReceivedAction: ActionType<{
  countries: ServiceCountries;
}> = 'CountriesDataReceivedAction';
export const createServicesCountriesDataReceivedAction = actionCreator(CountriesDataReceivedAction);

export const SetEntityDataRegionFiltersAction: ActionType<{
  entityType: Constants.EntityType;
  countryCode: string;
  regionCode: string;
}> = 'SetEntityDataRegionFiltersAction';
export const createSetEntityDataRegionFiltersAction = actionCreator(SetEntityDataRegionFiltersAction);

export const GlobalSettingsReceivedAction: ActionType<{
  globalSettings: IGlobalSettings;
}> = 'GlobalSettingsReceivedAction';
export const createGlobalSettingsReceivedAction = actionCreator(GlobalSettingsReceivedAction);

export const IsMobileReceivedAction: ActionType<{ isMobile: boolean }> = 'IsMobileReceivedAction';
export const createIsMobileReceivedAction = actionCreator(IsMobileReceivedAction);

export const DynamicCampaignReceivedAction: ActionType<{
  campaignInfo: IDynamicCampaignState;
}> = 'CampaignReceivedAction';
export const createDynamicCampaignReceivedAction = actionCreator(DynamicCampaignReceivedAction);

export const CheckoutConfigAction: ActionType<{
  checkoutSettings: ICheckoutSettings;
}> = 'CheckoutConfigAction';
export const createCheckoutConfigAction = actionCreator(CheckoutConfigAction);

export const SetTenantTypeAction: ActionType<Constants.TenantType> = 'SetTenantTypeAction';
export const createSetTenantTypeAction = actionCreator(SetTenantTypeAction);

export const UserOrdersAction: ActionType<boolean> = 'UserOrdersAction';
export const createUserOrdersAction = actionCreator(UserOrdersAction);

export const SetUserOrdersLoadingAction: ActionType<boolean> = 'SetUserOrdersLoadingAction';
export const createSetUserOrdersLoadingAction = actionCreator(SetUserOrdersLoadingAction);

export const IsManageModalOpenAction: ActionType<boolean> = 'IsManageModalOpenAction';
export const createIsManageModalOpenAction = actionCreator(IsManageModalOpenAction);

export const SetWCPConsentAction: ActionType<WCPconsent> = 'SetWCPConsentAction';
export const createSetWCPConsentAction = actionCreator(SetWCPConsentAction);

export const SetIsConsentRequired: ActionType<boolean> = 'SetIsConsentRequired';
export const createSetIsConsentRequiredAction = actionCreator(SetIsConsentRequired);

export const LoadFavouriteDataStateAction: ActionType<{
  userFavouriteState: IUserFavouriteState;
}> = 'LoadFavouriteDataStateAction';
export const createLoadFavouriteDataStateAction = actionCreator(LoadFavouriteDataStateAction);

export const UserPrivateOffersAction: ActionType<{
  apps: IAppDataItem[];
  billingRegion: string;
}> = 'UserPrivateOffersAction';
export const createUserPrivateOffersAction = actionCreator(UserPrivateOffersAction);

export const ToggleShowContextPanel: ActionType<void> = 'ToggleShowContextPanel';
export const createToggleShowContextPanel = actionCreator(ToggleShowContextPanel);

export const SetUserPrivateOffersLoadingAction: ActionType<boolean> = 'SetPrivateOffersLoadingAction';
export const createSetUserPrivateOffersLoadingAction = actionCreator(SetUserPrivateOffersLoadingAction);

export const SetAccessTokenAction: ActionType<IAccessToken> = 'SetAccessTokenAction';
export const createSetAccessTokenAction = actionCreator(SetAccessTokenAction);

export const ChangeLocaleLanguage: ActionType<{
  locale: string;
}> = 'ChangeLocaleLanguage';
export const createChangeLocaleLanguage = actionCreator(ChangeLocaleLanguage);

export const SetIsDisabledBilling: ActionType<boolean> = 'createIsDisabledBilling';
export const createSetIsDisabledBilling = actionCreator(SetIsDisabledBilling);

export const LoadingRecommenededSizes: ActionType<{ entityId: string; planId: string }> = 'LoadingRecommenededSizes';
export const createLoadingRecommenededSizes = actionCreator(LoadingRecommenededSizes);

export const SetRecommenededSizes: ActionType<{ entityId: string; planId: string; recommendedSizes: string[] }> =
  'SetRecommenededSizes';
export const createSetRecommenededSizes = actionCreator(SetRecommenededSizes);
