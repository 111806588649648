import React from 'react';
import { mergeStyleSets, Text } from '@fluentui/react';
import type { ITextStyles } from '@fluentui/react';

import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { SubmittedModal } from '@shared/components/modals/submittedModal';
import { NeutralColors, SharedColors } from '@fluentui/theme';
import { ExternalLink } from '@shared/components/externalLink';

interface INpsSubmittedModalProps {
  hidden: boolean;
  onDismiss: () => void;
  correlationId: string;
  context: ILocContext & ILocParamsContext;
}

const externalUrl = (correlationId: string) => `https://aka.ms/appsource/comscore?correlationID=${correlationId}`;

const subTextStyles: Partial<ITextStyles> = { root: { color: NeutralColors.gray130, lineHeight: 20 } };
const classNames = mergeStyleSets({
  externalLink: [{ display: 'inline-block', color: `${SharedColors.cyanBlue10} !important` }],
});

export const NpsSubmittedModal: React.FunctionComponent<INpsSubmittedModalProps> = ({
  hidden,
  onDismiss,
  correlationId,
  context,
}: INpsSubmittedModalProps) => {
  return (
    <SubmittedModal
      hidden={hidden}
      onDismiss={onDismiss}
      title={context.loc('Nps_SubmittedModal_Title', 'Feedback received')}
      context={context}
    >
      <Text block styles={subTextStyles}>
        {context.loc('Nps_SubmittedModal_Summary_Text', 'Thanks for taking the time to share your thoughts!')}
      </Text>
      <Text styles={subTextStyles}>{`${context.loc(
        'Nps_SubmittedModal_Link_Invitation_Text',
        'If you’d like to help us even more'
      )}, `}</Text>
      <ExternalLink
        className={classNames.externalLink}
        accessibilityEnabled
        href={externalUrl(correlationId)}
        target="_blank"
        rel="noreferrer"
        onClickHandle={onDismiss}
      >
        {context.loc('Nps_SubmittedModal_External_Link_Text', 'take our online survey')}
      </ExternalLink>
      <Text styles={subTextStyles}>.</Text>
    </SubmittedModal>
  );
};
