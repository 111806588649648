import { IAppDataItem } from '@shared/Models';
import { Action, isType } from './../actions/actions';
import {
  ReceiveUserFavouriteDataAction,
  UpdateUserFavouriteFetchDataStatusAction,
  UpsertUserFavouriteItemAction,
  DeleteUserFavouriteItemAction,
  FavouriteAppsPricingDataReceivedAction,
} from './../actions/userFavouriteActions';
import { initialUserFavouriteState, copyState } from '@src/State';
import {
  IUserFavouriteState,
  IReceiveUserFavouriteDataActionPayload,
  IUpdateUserFavouriteFetchDataStatusActionPayload,
  IUpsertUserFavouriteItemActionPayload,
  IDeleteUserFavouriteItemActionPayload,
  IRawUserFavourite,
  IUserFavouriteApp,
} from './../interfaces/userFavouriteModels';
import {
  isApplication,
  isConsultingService,
  getFavouriteEntityState,
  upsertsUserFavouriteEntityState,
  deleteUserFavouriteEntityState,
} from './../utils/userFavouriteUtils';
import { Constants } from './../utils/constants';
import { loadAppPricing } from './../utils/pricing';

export default function userFavouriteReducer(
  state: IUserFavouriteState = initialUserFavouriteState,
  action: Action<
    | IReceiveUserFavouriteDataActionPayload
    | IUpdateUserFavouriteFetchDataStatusActionPayload
    | IUpsertUserFavouriteItemActionPayload
    | IDeleteUserFavouriteItemActionPayload
  >
): IUserFavouriteState {
  const newState = copyState(state);

  if (isType(action, ReceiveUserFavouriteDataAction)) {
    const payload = action.payload as IReceiveUserFavouriteDataActionPayload;
    const userFavourites = payload.userFavorites || [];

    userFavourites.forEach((userFavourite: IRawUserFavourite) => {
      if (isApplication(userFavourite.applicationType)) {
        newState.apps = getFavouriteEntityState(userFavourite.favorites, userFavourite.applicationType);
      } else if (isConsultingService(userFavourite.applicationType)) {
        newState.services = getFavouriteEntityState(userFavourite.favorites, userFavourite.applicationType);
      }
    });
  } else if (isType(action, UpdateUserFavouriteFetchDataStatusAction)) {
    const payload = action.payload as IUpdateUserFavouriteFetchDataStatusActionPayload;

    if (newState.fetchDataStatus === payload.fetchDataStatus) {
      return state;
    }

    newState.fetchDataStatus = payload.fetchDataStatus;
  } else if (isType(action, UpsertUserFavouriteItemAction)) {
    if (newState.fetchDataStatus === Constants.UserFavourite.FetchDataStatus.Valid) {
      const payload = action.payload as IUpsertUserFavouriteItemActionPayload;

      if (isApplication(payload.applicationType)) {
        upsertsUserFavouriteEntityState(newState.apps, payload);
      } else if (isConsultingService(payload.applicationType)) {
        upsertsUserFavouriteEntityState(newState.services, payload);
      }
    }
  } else if (isType(action, DeleteUserFavouriteItemAction)) {
    if (newState.fetchDataStatus === Constants.UserFavourite.FetchDataStatus.Valid) {
      const payload = action.payload as IDeleteUserFavouriteItemActionPayload;

      if (isApplication(payload.applicationType)) {
        deleteUserFavouriteEntityState(newState.apps, payload);
      } else if (isConsultingService(payload.applicationType)) {
        deleteUserFavouriteEntityState(newState.services, payload);
      }
    }
  } else if (isType(action, FavouriteAppsPricingDataReceivedAction)) {
    if (!state.apps || !action.payload.pricingData || Object.keys(action.payload.pricingData).length === 0) {
      return state;
    }

    newState.apps = state.apps.map((favouriteApp: IUserFavouriteApp) => {
      const app = favouriteApp.item as IAppDataItem;

      const item = loadAppPricing(app, action.payload.pricingData);

      return {
        ...favouriteApp,
        item,
      };
    });
  } else {
    return state;
  }

  return newState;
}
