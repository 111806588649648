import * as React from 'react';
import { Stack, Label, Text, mergeStyleSets, Icon, Image } from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';

import type { IIconStyles } from '@fluentui/react';
import { ICommonContext, ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { IRatingSummary } from '@shared/Models';
import { RatingPercentages } from './ratingPercentages';
import microsoftLogo from '@shared/images/microsoft-icon.svg';

const contentStyles = mergeStyleSets({
  ratingSummaryContainer: ['ratingSummaryContainer', { minWidth: 280 }],
  singleInternalRatingSourceImage: {
    width: 16,
    height: 16,
    marginRight: 10,
    marginTop: 3,
  },
  ratingSummaryAverageSection: [
    'ratingSummaryAverageSection',
    {
      margin: '16px 0',
    },
  ],
});

export interface IRatingSummaryComponent {
  ratingSummary: IRatingSummary;
  title: string;
  context: ILocContext & ILocParamsContext & ICommonContext;
}

export function getPercentagesOfTotal(params: { timesRated: number; totalRatings: number }): number {
  const percentages: number = params.totalRatings ? (params.timesRated * 100) / params.totalRatings : 0;
  return Math.round(percentages);
}

export function getEmptyRatingSummary(): IRatingSummary {
  return {
    AverageRating: 0,
    TotalRatings: 0,
    Source: '',
    OfferId: null,
    CreatedAt: null,
    StarsDistribution: {
      OneStars: 0,
      TwoStars: 0,
      ThreeStars: 0,
      FourStars: 0,
      FiveStars: 0,
    },
  };
}

export function getAverageRatingToDisplay(ratingSummary: IRatingSummary): string {
  return ratingSummary.AverageRating && !isNaN(ratingSummary.AverageRating) ? ratingSummary.AverageRating.toFixed(1) : '0';
}

export const renderRatingPercentagesByNumOfStars = (
  numOfStars: number,
  timesRated: number,
  totalRatings: number,
  context: ILocContext & ILocParamsContext & ICommonContext
): JSX.Element => {
  return (
    <RatingPercentages
      context={context}
      numberOfStars={numOfStars}
      percentagesOfTotal={getPercentagesOfTotal({
        timesRated,
        totalRatings,
      })}
    />
  );
};

export const RatingSummary = ({
  ratingSummary = getEmptyRatingSummary(),
  context = null,
  title = 'Rating Summary',
}: IRatingSummaryComponent) => {
  const totalRatingSummary = ratingSummary || getEmptyRatingSummary();
  const averageRating = getAverageRatingToDisplay(totalRatingSummary);
  const ratingSummaryAriaText = context?.locParams('Reviews_Summary_AriaText', [
    averageRating,
    '5',
    `${totalRatingSummary.TotalRatings}`,
  ]);

  const iconStyles: IIconStyles = {
    root: [
      'ratingSummaryStar',
      {
        fontSize: 17,
      },
      !totalRatingSummary.TotalRatings && { color: NeutralColors.gray90 },
    ],
  };

  return (
    <div className={contentStyles.ratingSummaryContainer}>
      <Stack horizontal>
        <Image src={microsoftLogo} className={contentStyles.singleInternalRatingSourceImage} alt="Microsoft Logo" />
        <text className="ratingSummaryTitle">{title}</text>
      </Stack>

      <div className={contentStyles.ratingSummaryAverageSection} aria-label={ratingSummaryAriaText}>
        <Icon
          iconName={totalRatingSummary.TotalRatings ? 'FavoriteStarFill' : 'FavoriteStar'}
          styles={iconStyles}
          aria-label={context?.loc('Ratings_Review_Star', 'Ratings review star')}
        />
        <div className="ratingSummaryAverageSectionText">
          <Label className="ratingSummaryNumOfStars">{averageRating}</Label>
          <Text className="numOfRatingText" variant="mediumPlus">
            ({totalRatingSummary.TotalRatings} {context?.loc('Global_Loc_ratingsLower', 'ratings')})
          </Text>
        </div>
      </div>
      {totalRatingSummary.TotalRatings >= 5 && (
        <Stack tokens={{ childrenGap: 2, padding: 1 }}>
          {renderRatingPercentagesByNumOfStars(
            5,
            totalRatingSummary.StarsDistribution?.FiveStars ?? 0,
            totalRatingSummary.TotalRatings,
            context
          )}
          {renderRatingPercentagesByNumOfStars(
            4,
            totalRatingSummary.StarsDistribution?.FourStars ?? 0,
            totalRatingSummary.TotalRatings,
            context
          )}
          {renderRatingPercentagesByNumOfStars(
            3,
            totalRatingSummary.StarsDistribution?.ThreeStars ?? 0,
            totalRatingSummary.TotalRatings,
            context
          )}
          {renderRatingPercentagesByNumOfStars(
            2,
            totalRatingSummary.StarsDistribution?.TwoStars ?? 0,
            totalRatingSummary.TotalRatings,
            context
          )}
          {renderRatingPercentagesByNumOfStars(
            1,
            totalRatingSummary.StarsDistribution?.OneStars ?? 0,
            totalRatingSummary.TotalRatings,
            context
          )}
        </Stack>
      )}
    </div>
  );
};
