/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/prefer-namespace-keyword */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable security/detect-non-literal-fs-filename */
/* eslint-disable node/no-deprecated-api */
import { Request } from 'express';
import agentParser from 'ua-parser-js';

import paths from '@webpackConfig/paths';
import {
  ITelemetryEvent,
  ITelemetryData,
  ITelemetryEvents,
  IAcquistionPayload,
  ITelemetryOutRequestStart,
  ITelemetryOutRequestEnd,
  ITelemetryOutRequests,
  ITelemetryOutRequest,
} from '@shared/Models';
import { Constants } from '@shared/utils/constants';
import { generateGuid } from '@shared/utils/appUtils';
import { getAppConfig } from '@shared/services/init/appConfig';
import { stringifyError } from '@shared/utils/errorUtils';
import { IHttpRequest, IHttpRequestContext } from '@server/ApiModels';
import { getStringQueryParam, scrubAcquisitionPayload } from '@server/Utils';
import { scrubHeaders } from '@shared/utils/httpClientUtil';
import stackTrace from 'stack-trace';
import { v4 as uuid } from 'uuid';
import { logger } from '@src/logger';

const fs = require('fs');

const sha256 = require('crypto-js/sha256');

const serverInstanceCorrelationId = process.env.serverInstanceCorrelationId || Constants.ReservedCorrelationIds.EmptyId;

/**
 * Legacy log methods that are still used in the codebase.
 * Following types are based on https://dev.azure.com/CEPlanning/Commercial%20Marketplace%20eXperiences/_git/CPX-Marketplace-Catalog?path=/src/Microsoft.Marketplace.Hydrate.Storefronts/Source/Common/Logging/EventSources/MarketPlaceEventSourceEvents.cs
 */
type LogHttpMethods =
  | 'LogHttpInboundRequestStart'
  | 'LogHttpInboundRequestEndWithSuccess'
  | 'LogHttpInboundRequestEndWithClientFailure'
  | 'LogHttpInboundRequestEndWithServerFailure'
  | 'LogHttpOutboundRequestStart'
  | 'LogHttpOutboundRequestEndWithSuccess'
  | 'LogHttpOutboundRequestEndWithServerFailure'
  | 'LogHttpOutboundRequestEndWithClientFailure';

type LogMethod =
  | 'Debug'
  | 'Error'
  | 'LogUserTelemetry'
  | 'LogAcquisitionInfo'
  | 'LogDbResourceOverUsage'
  | 'LogUserFeedback'
  | LogHttpMethods;

type KustoTable =
  | 'Logs'
  | 'IncomingRequests'
  | 'OutgoingRequests'
  | 'UserTelemetry'
  | 'AcquisitionInfo'
  | 'DbResourceOverUsage'
  | 'UserFeedback';

const MethodTableMap = new Map<LogMethod, KustoTable>([
  ['Debug', 'Logs'],
  ['Error', 'Logs'],
  ['LogHttpInboundRequestStart', 'IncomingRequests'],
  ['LogHttpInboundRequestEndWithSuccess', 'IncomingRequests'],
  ['LogHttpInboundRequestEndWithClientFailure', 'IncomingRequests'],
  ['LogHttpInboundRequestEndWithServerFailure', 'IncomingRequests'],
  ['LogHttpOutboundRequestStart', 'OutgoingRequests'],
  ['LogHttpOutboundRequestEndWithSuccess', 'OutgoingRequests'],
  ['LogHttpOutboundRequestEndWithClientFailure', 'OutgoingRequests'],
  ['LogHttpOutboundRequestEndWithServerFailure', 'OutgoingRequests'],
  ['LogAcquisitionInfo', 'AcquisitionInfo'],
  ['LogDbResourceOverUsage', 'DbResourceOverUsage'],
  ['LogUserFeedback', 'UserFeedback'],
  ['LogUserTelemetry', 'UserTelemetry'],
]);

const RequestsLogMethodToTaskName: Record<LogHttpMethods, string> = {
  LogHttpInboundRequestStart: 'HttpInboundRequest',
  LogHttpInboundRequestEndWithSuccess: 'HttpInboundRequestEndWithSuccess',
  LogHttpInboundRequestEndWithClientFailure: 'HttpInboundRequestEndWithClientFailure',
  LogHttpInboundRequestEndWithServerFailure: 'HttpInboundRequestEndWithServerFailure',
  LogHttpOutboundRequestStart: 'HttpOutboundRequest',
  LogHttpOutboundRequestEndWithSuccess: 'HttpOutboundRequestEndWithSuccess',
  LogHttpOutboundRequestEndWithServerFailure: 'HttpOutboundRequestEndWithServerFailure',
  LogHttpOutboundRequestEndWithClientFailure: 'HttpOutboundRequestEndWithClientFailure',
};

interface LogMessage {
  requestId: string;
  correlationId: string;
  operationName: string;
  message: string;
  exception: string;
  taskName?: string;
}

export module Logger {
  function getCLRMethod(methodName: LogMethod) {
    return (args: LogMessage) => {
      const { message = '', ...payload } = args;
      const collection = MethodTableMap.get(methodName);

      if (methodName === 'Error') {
        logger.serverLogger.error(message, {
          collection,
          taskName: 'Error',
          ...payload,
        });
        return;
      }

      // Deprecated
      if (collection === 'AcquisitionInfo' || collection === 'DbResourceOverUsage') {
        return;
      }

      if (collection === 'IncomingRequests' || collection === 'OutgoingRequests') {
        payload.taskName = RequestsLogMethodToTaskName[methodName as LogHttpMethods];
      }

      if (methodName === 'Debug') {
        payload.taskName = 'Debug';
      }

      logger.serverLogger.info(message, { collection, ...payload });
    };
  }

  const LoggerDll: string = paths.dllLoggerPath;
  // Series of CLR entry points to be used by edge,
  // since we dont have an overload that takes the function name we have one func for each clr mrthod
  export const clrDebug = getCLRMethod('Debug');
  export const clrError = getCLRMethod('Error');
  export const clrLogUserTelemetry = getCLRMethod('LogUserTelemetry');
  const clrLogHttpInboundRequestStart = getCLRMethod('LogHttpInboundRequestStart');
  const clrLogHttpInboundRequestEndWithSuccess = getCLRMethod('LogHttpInboundRequestEndWithSuccess');
  const clrLogHttpInboundRequestEndWithClientFailure = getCLRMethod('LogHttpInboundRequestEndWithClientFailure');
  const clrLogHttpInboundRequestEndWithServerFailure = getCLRMethod('LogHttpInboundRequestEndWithServerFailure');
  const clrLogHttpOutboundRequestStart = getCLRMethod('LogHttpOutboundRequestStart');
  const clrLogHttpOutboundRequestEndWithSuccess = getCLRMethod('LogHttpOutboundRequestEndWithSuccess');
  const clrLogHttpOutboundRequestEndWithServerFailure = getCLRMethod('LogHttpOutboundRequestEndWithServerFailure');
  const clrLogHttpOutboundRequestEndWithClientFailure = getCLRMethod('LogHttpOutboundRequestEndWithClientFailure');
  const clrLogAcquisitionInfo = getCLRMethod('LogAcquisitionInfo');
  const clrLogDbResourceOverUsage = getCLRMethod('LogDbResourceOverUsage');
  const clrLogUserFeedback = getCLRMethod('LogUserFeedback');

  let logStreamData: ILogStreamItem[] = null;
  let correlationIdToWatch = '';

  // Call this function to start watch the log for the **session** based on the given correlation ID.
  // We use this function to immediately get the server side logs in the console for debugging purpose.
  export function startWatchingLog(correlationId: string) {
    correlationIdToWatch = correlationId;
    logStreamData = [];
    setTimeout(() => {
      stopWatchingLog(correlationId);
    }, 60000); // Stop watching logs in 1 minute automatically in case we fail to stop watching log for any reasons.
  }

  export function stopWatchingLog(correlationId: string) {
    if (correlationId === correlationIdToWatch) {
      correlationIdToWatch = '';
      logStreamData = null;
    }
  }

  export function getLogStream(): ILogStreamItem[] {
    return logStreamData;
  }

  export function isWatchingLog(correlationId: string): boolean {
    return correlationIdToWatch === correlationId;
  }

  export function isLoggingEnabled(callbackFunction: () => void) {
    return fs.exists(LoggerDll, callbackFunction);
  }

  function writeToLogStream(correlationId: string, operation: string, message: string) {
    if (correlationId && logStreamData && correlationId === correlationIdToWatch) {
      const now = new Date();
      const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000); // Gets the UTC time.
      logStreamData.push({
        timeStamp: utc.toISOString(),
        operation: operation,
        message: message,
      });
    }
  }

  // To be used by Node server side code Debug events
  function Debug(operationName: string, message: string, exception: string, additionalData: any) {
    // A JSON object in the exception causes edge to crash with stack overflow hence the check.
    let exceptionString = '';
    if (exception) {
      // Stringify also handles non JSON object such as raw strings, int, bools etc..
      exceptionString = JSON.stringify(exception);
    }
    const debugMessage: LogMessage = {
      requestId: additionalData && additionalData.requestId ? additionalData.requestId : uuid(),
      correlationId: additionalData && additionalData.correlationId ? additionalData.correlationId : serverInstanceCorrelationId,
      operationName: operationName.toString() || '',
      message: message.toString() || '',
      exception: exceptionString,
    };

    clrDebug(debugMessage);
  }

  // Overload to debug without request and correlationId for server debugging
  function Information(operationName: string, message: string, exception?: string, additionalData?: any) {
    // A JSON object in the exception causes edge to crash with stack overflow hence the check.
    let exceptionString = '';
    if (exception) {
      // Stringify handles non JSON object such as raw strings, int, bools etc..
      exceptionString = JSON.stringify(exception);
    }
    let requestId = uuid();
    let correlationId = serverInstanceCorrelationId;
    if (additionalData) {
      if (additionalData.requestId) {
        requestId = additionalData.requestId;
      }
      if (additionalData.correlationId) {
        correlationId = additionalData.correlationId;
      }
    }
    const debugMessage: LogMessage = {
      requestId: requestId,
      correlationId: correlationId,
      operationName: operationName.toString() || '',
      message: message.toString() || '',
      exception: exceptionString,
    };

    clrDebug(debugMessage);
  }

  // To be used for Exception logging
  export function Exception(operationName: string, message: string, exception: string, otherInfo?: any) {
    let requestId = uuid();
    let correlationId = serverInstanceCorrelationId;

    if (otherInfo) {
      requestId = otherInfo.requestId ? otherInfo.requestId : requestId;
      correlationId = otherInfo.correlationId ? otherInfo.correlationId : correlationId;

      if (otherInfo.request) {
        requestId = otherInfo.request.headers[Constants.Headers.RequestId] || requestId;
        correlationId = otherInfo.request.headers[Constants.Headers.CorrelationId] || correlationId;
      }
    }
    // A JSON object in the exception causes edge to crash with stack overflow hence the check.
    let exceptionString = '';
    if (exception) {
      // Stringify handles non JSON object such as raw strings, int, bools etc..
      exceptionString = JSON.stringify(exception, Object.getOwnPropertyNames(exception));
    }
    const errorMessage: LogMessage = {
      requestId: requestId,
      correlationId: correlationId,
      operationName: operationName.toString() || '',
      message: message.toString() || '',
      exception: exceptionString,
    };

    clrError(errorMessage);
  }

  export async function LogFeedback(requestId: string, correlationId: string, event: ITelemetryEvent) {
    const message = {
      requestId: requestId || uuid(),
      correlationId: correlationId || serverInstanceCorrelationId,
      page: event.page ? event.page.toString() : '',
      clientTimestamp: event.clientTimestamp ? event.clientTimestamp.toString() : '',
      action: event.action ? event.action.toString() : '',
      actionModifier: event.actionModifier ? event.actionModifier.toString() : '',
      details: event.details
        ? typeof event.details === 'string'
          ? event.details.toString()
          : JSON.stringify(event.details)
        : '',
      hostType: event.hostType ? event.hostType.toString() : '',
      oid: event.oid ? event.oid.toString() : '',
      tid: event.tid ? event.tid.toString() : '',
      spzaId: event.spzaId ? event.spzaId.toString() : '',
    };

    const detailsAsJson = event.details
      ? typeof event.details === 'string'
        ? JSON.parse(event.details.toString())
        : event.details
      : null;

    if (detailsAsJson) {
      message.details = detailsAsJson;
      sendFeedback(message);
    } else {
      sendFeedback(message);
    }
  }

  function sendFeedback(message: {}) {
    clrLogUserFeedback(message, function (error: any) {
      if (error) {
        Logger.Exception('LogFeedback', error, '');
      }
    });
  }

  // To be used by Browser via API route to log telemetry events
  export function LogTelemetryEvent(requestId: string, correlationId: string, event: ITelemetryEvent) {
    // For all the error events we want to add a hash based on the error stack/message for making the kusto queries easier.
    if (event.actionModifier && event.actionModifier === Constants.Telemetry.ActionModifier.Error) {
      if (event.details) {
        event.details = JSON.stringify(getErrorDetailsWithHash(event.details));
      }
    }
    if (event.isFeedback) {
      LogFeedback(requestId, correlationId, event);
    } else {
      const message = {
        requestId: requestId || uuid(),
        correlationId: correlationId || serverInstanceCorrelationId,
        page: event.page ? event.page.toString() : '',
        clientTimestamp: event.clientTimestamp ? event.clientTimestamp.toString() : '',
        action: event.action ? event.action.toString() : '',
        actionModifier: event.actionModifier ? event.actionModifier.toString() : '',
        details: event.details
          ? typeof event.details === 'string'
            ? event.details.toString()
            : JSON.stringify(event.details)
          : '',
        appName: event.appName ? event.appName.toString() : '',
        product: event.product ? event.product.toString() : '',
        featureFlag: event.featureFlag ? event.featureFlag.toString() : '',
        hostType: event.hostType ? event.hostType.toString() : '',
        oid: event.oid ? 'true' : '',
        tid: event.tid ? event.tid.toString() : '',
        spzaId: event.spzaId ? event.spzaId.toString() : '',
        mshash: event.mshash ? event.mshash.toString() : '',
      };
      clrLogUserTelemetry(message, function (error: any) {
        if (error) {
          Logger.Exception('LogTelemetry', error, '');
        }
      });
    }
  }

  // To be used by Node server side API to log incoming requests start
  export function LogHttpInboundRequestStart(httpRequestContext: IHttpRequestContext) {
    clrLogHttpInboundRequestStart(httpRequestContext, function (error: any) {
      if (error) {
        Logger.Exception(httpRequestContext.operation, error, '', {
          requestId: httpRequestContext.requestId,
          correlationId: httpRequestContext.correlationId,
        });
      }
    });
  }

  // To be used by Node server side API to log incoming requests end with success
  export function LogHttpInboundRequestEndWithSuccess(httpRequest: IHttpRequest) {
    clrLogHttpInboundRequestEndWithSuccess(httpRequest, function (error: any) {
      if (error) {
        Logger.Exception('LogHttpInboundRequestSuccess', error, '', {
          requestId: httpRequest.httpRequestContext.requestId,
          correlationId: httpRequest.httpRequestContext.correlationId,
        });
      }
    });
  }

  // To be used by Node server side API to log incoming requests end with client failure (4xx)
  export function LogHttpInboundRequestEndWithClientFailure(httpRequest: IHttpRequest) {
    clrLogHttpInboundRequestEndWithClientFailure(httpRequest, function (error: any) {
      if (error) {
        Logger.Exception('LogHttpInboundRequestEndWithClientFailure', error, '', {
          requestId: httpRequest.httpRequestContext.requestId,
          correlationId: httpRequest.httpRequestContext.correlationId,
        });
      }
    });
  }

  // To be used by Node server side API to log incoming requests end with client failure (5xx)
  export function LogHttpInboundRequestEndWithServerFailure(httpRequest: IHttpRequest) {
    clrLogHttpInboundRequestEndWithServerFailure(httpRequest, function (error: any) {
      if (error) {
        Logger.Exception('LogHttpInboundRequestEndWithServerFailure', error, '', {
          requestId: httpRequest.httpRequestContext.requestId,
          correlationId: httpRequest.httpRequestContext.correlationId,
        });
      }
    });
  }

  // To be used by Node server side API to log outgoing requests start
  export function LogHttpOutboundRequestStart(httpRequestContext: IHttpRequestContext) {
    const clonedRequest: IHttpRequestContext = cloneRequest({ httpRequestContext });

    clrLogHttpOutboundRequestStart(clonedRequest, function (error: any) {
      if (error) {
        Logger.Exception(httpRequestContext.operation, error, '', {
          requestId: httpRequestContext.requestId,
          correlationid: httpRequestContext.correlationId,
        });
      }
    });
  }

  function cloneRequest({
    httpRequestContext: { requestId, correlationId, headers, operation, httpMethod, hostName, targetUri, apiVersion },
  }: {
    httpRequestContext: IHttpRequestContext;
  }): IHttpRequestContext {
    let parsedHeaders = headers || {};

    let i = 0;
    // in cases where the headers were stringified more than once
    while (typeof parsedHeaders === 'string' && i < 3) {
      try {
        parsedHeaders = JSON.parse(parsedHeaders);
      } catch (error) {
        Logger.logDebugMessage(requestId, correlationId, 'cloneRequest', stringifyError(error));
        break;
      }
      // using i for sanity purposes.
      i += 1;
    }

    parsedHeaders = typeof parsedHeaders === 'string' ? {} : parsedHeaders;

    const clonedRequest: IHttpRequestContext = {
      requestId,
      correlationId,
      operation,
      httpMethod,
      hostName,
      targetUri,
      userAgent: parsedHeaders[Constants.Headers.XMSUserAgent] || '',
      clientIpAddress: '',
      apiVersion: apiVersion || '',
      contentLength: parsedHeaders['Content-Length'] || 0,
      headers: JSON.stringify(scrubHeaders(parsedHeaders)),
    };
    return clonedRequest;
  }

  // To be used by Node server side API to log outgoing requests end with success
  export function LogHttpOutboundRequestEndWithSuccess(httpRequest: IHttpRequest) {
    const requestId = httpRequest.httpRequestContext.requestId;
    const correlationId = httpRequest.httpRequestContext.correlationId;

    clrLogHttpOutboundRequestEndWithSuccess(httpRequest, function (error: any) {
      if (error) {
        try {
          const striginfyRequest = JSON.stringify(httpRequest);
          Logger.Exception('LogHttpOutboundRequestEndWithSuccess', `error: ${error}. httpRequest: ${striginfyRequest}`, '', {
            requestId,
            correlationId,
          });
        } catch (stringifyError) {
          Logger.Exception('LogHttpOutboundRequestEndWithSuccess', `error: ${error}. stringifyError: ${stringifyError}`, '', {
            requestId,
            correlationId,
          });
        }
      }
    });
  }

  // To be used by Node server side API to log outgoing requests end with client failure (4xx)
  export function LogHttpOutboundRequestEndWithServerFailure(httpRequest: IHttpRequest) {
    clrLogHttpOutboundRequestEndWithServerFailure(httpRequest, function (error: any) {
      if (error) {
        Logger.Exception('LogHttpOutboundRequestEndWithServerFailure', error, '', {
          requestId: httpRequest.httpRequestContext.requestId,
          correlationId: httpRequest.httpRequestContext.correlationId,
        });
      }
    });
  }

  // To be used by Node server side API to log outgoing requests end with server failure (5xx)
  export function LogHttpOutboundRequestEndWithClientFailure(httpRequest: IHttpRequest) {
    clrLogHttpOutboundRequestEndWithClientFailure(httpRequest, function (error: any) {
      if (error) {
        Logger.Exception('LogHttpOutboundRequestEndWithClientFailure', error, '', {
          requestId: httpRequest.httpRequestContext.requestId,
          correlationId: httpRequest.httpRequestContext.correlationId,
        });
      }
    });
  }

  // Helper method to log all Inbound request starts
  export function LogInboundRequestStart(request: any) {
    let requestId = uuid();
    let correlationId = serverInstanceCorrelationId;
    // request can be null for direct server calls, thus the empty guids
    if (request) {
      // default to empty guids for missing headers
      requestId = request.headers[Constants.Headers.RequestId] || requestId;
      correlationId =
        request.headers[Constants.Headers.CorrelationId] ||
        getStringQueryParam(request, Constants.QueryStrings.CorrelationId) ||
        correlationId;
    }
    try {
      const requestContext: IHttpRequestContext = {
        requestId: requestId,
        correlationId: correlationId,
        operation: request.url,
        httpMethod: request.method,
        hostName: request.hostname,
        targetUri: request.hostname + request.originalUrl,
        userAgent: request.headers[Constants.Headers.XMSUserAgent] || '',
        clientIpAddress: '', // TODO: Bug node request is missing client ip behind a proxy,
        apiVersion: getStringQueryParam(request, 'version') || '',
        contentLength: request.headers['Content-Length'] || 0,
        headers: JSON.stringify(scrubHeaders(request.headers)),
      };
      LogHttpInboundRequestStart(requestContext);
    } catch (exception) {
      Logger.Exception('LogHelper.LogInboundRequestStart', 'ExceptionLoggingRequest', exception);
    }
  }

  // Helper method to log all request end
  export function LogInboundRequestEnd(request: any, duration: number, httpStatus: number, errorMessage: string) {
    let requestId = uuid();
    let correlationId = serverInstanceCorrelationId;
    if (request) {
      requestId = request.headers[Constants.Headers.RequestId] || requestId;
      correlationId =
        request.headers[Constants.Headers.CorrelationId] ||
        getStringQueryParam(request, Constants.QueryStrings.CorrelationId) ||
        correlationId;
    }
    if (errorMessage) {
      // Stringify handles non JSON object such as raw strings, int, bools etc..
      errorMessage = JSON.stringify(errorMessage);
    }
    try {
      const requestContext: IHttpRequestContext = {
        requestId: requestId,
        correlationId: correlationId,
        operation: request.url,
        httpMethod: request.method,
        hostName: request.hostname,
        targetUri: request.hostname + request.url,
        userAgent: request.headers[Constants.Headers.XMSUserAgent] || '',
        clientIpAddress: '', // TODO: Bug node request is missing client ip behind a proxy,
        apiVersion: getStringQueryParam(request, 'version') || '',
        contentLength: request.headers['Content-Length'] || 0,
        headers: JSON.stringify(scrubHeaders(request.headers)),
      };

      const httpRequest: IHttpRequest = {
        httpRequestContext: requestContext,
        durationInMilliseconds: duration,
        httpStatusCode: httpStatus,
        errorMessage: errorMessage,
      };

      if (httpStatus > 199 && httpStatus < 300) {
        LogHttpInboundRequestEndWithSuccess(httpRequest);
      } else if (httpStatus > 399 && httpStatus < 500) {
        LogHttpInboundRequestEndWithClientFailure(httpRequest);
      } else if (httpStatus > 499) {
        LogHttpInboundRequestEndWithServerFailure(httpRequest);
      }
    } catch (exception) {
      Logger.Exception('LogHelper.LogRequestEnd', 'ExceptionLoggingRequestEnd', exception);
    }
  }

  // Helper for logging outbound request end, since we create the request context we dont need to extract it from the express request
  export function LogOutboundRequestEnd(
    request: IHttpRequestContext,
    duration: number,
    httpStatus: number,
    errorMessage: string
  ) {
    if (errorMessage) {
      // Stringify handles non JSON object such as raw strings, int, bools etc..
      errorMessage = JSON.stringify(errorMessage);
    }
    try {
      const clonedRequest: IHttpRequestContext = cloneRequest({
        httpRequestContext: request,
      });

      const httpRequest: IHttpRequest = {
        httpRequestContext: clonedRequest,
        durationInMilliseconds: duration,
        httpStatusCode: httpStatus,
        errorMessage: errorMessage,
      };
      if (httpStatus > 199 && httpStatus < 300) {
        LogHttpOutboundRequestEndWithSuccess(httpRequest);
      } else if (httpStatus > 399 && httpStatus < 500) {
        LogHttpOutboundRequestEndWithClientFailure(httpRequest);
      } else if (httpStatus > 499) {
        LogHttpOutboundRequestEndWithServerFailure(httpRequest);
      }
    } catch (exception) {
      Logger.Exception('LogHelper.LogOutboundRequestEnd', 'ExceptionLoggingRequestEnd', exception);
    }
  }

  export function LogDbResourceOverUsage(
    requestId: string,
    correlationId: string,
    requestCharge: number,
    latency: number,
    query: string
  ) {
    try {
      const message = {
        requestId: requestId,
        correlationId: correlationId,
        requestCharge: requestCharge ? requestCharge.toString() : '-1',
        latency: latency ? latency.toString() : '-1',
        query: query,
      };
      clrLogDbResourceOverUsage(message, function (error: any) {
        if (error) {
          Logger.Exception('LogHelper.LogDbResourceOverUsage', 'LogDbResourceOverUsage', error);
        }
      });
    } catch (exception) {
      Logger.Exception('LoggerService.LogDbResourceOverUsage', 'Error logging db resource over usage', exception, {
        requestId: requestId,
        correlationId: correlationId,
      });
    }
  }

  // To be used by Browser via API route to log app acquisition info
  export function LogAcquisitionInfo(requestId: string, correlationId: string, info: IAcquistionPayload, token: any) {
    try {
      info.userInfo.firstName = info.userInfo.firstName ? info.userInfo.firstName : token ? token.given_name : '';
      info.userInfo.lastName = info.userInfo.lastName ? info.userInfo.lastName : token ? token.family_name : '';
      info.userInfo.email = info.userInfo.email ? info.userInfo.email : token ? token.upn : '';
      info.userInfo.oid = info.userInfo.oid ? info.userInfo.oid : token ? token.oid : '';
      info.userInfo.tid = info.userInfo.tid ? info.userInfo.tid : token ? token.tid : '';
      Logger.LogAcquisitionInfoPayload(requestId, correlationId, info);
    } catch (exception) {
      Logger.Exception('Logger.LogAcquisitionInfo', 'Error logging acquisition info', exception, {
        requestId: requestId,
        correlationId: correlationId,
      });
    }
  }

  // Separated these methods to call this one from tests to validate the arguments sent to this.
  export function LogAcquisitionInfoPayload(requestId: string, correlationId: string, info: IAcquistionPayload) {
    const message = {
      requestId: requestId,
      correlationId: correlationId,
      acquisitionInfoPayload: JSON.stringify(scrubAcquisitionPayload(info)),
    };
    clrLogAcquisitionInfo(message, function (error: any) {
      if (error) {
        Logger.Exception('Logger.LogAcquisitionInfo', 'LogAcquisitionInfo', error);
      }
    });
  }

  export function LogTelemetryEvents(requestId: string, correlationId: string, telemetrybatch: ITelemetryEvents, req?: any) {
    try {
      for (let i = 0; i < telemetrybatch.TelemetryEvents?.length; i++) {
        const telemetry = telemetrybatch.TelemetryEvents[`${i}`];
        try {
          Logger.LogTelemetryEvent(requestId, correlationId, telemetryHelper(telemetry, req));
        } catch (exception) {
          Logger.Exception(
            'LoggerService.LogTelemetryEvents',
            'Error logging telemetry row. Original telemtry row: ' + JSON.stringify(telemetry),
            exception,
            { requestId, correlationId }
          );
        }
      }
    } catch (exception) {
      Logger.Exception(
        'LoggerService.LogTelemetryEvents',
        'Error logging telemetry batch. Original telemtry batch: ' + JSON.stringify(telemetrybatch),
        exception,
        { requestId, correlationId }
      );
    }
  }

  // Used for client
  export function ClientLogOutRequests(
    requestId: string,
    correlationId: string,
    telemetrybatch: ITelemetryOutRequests,
    request: any
  ) {
    const userAgent =
      request.headers && (request.headers[Constants.Headers.XMSUserAgent] || request.headers[Constants.Headers.UserAgent]);
    const clientIp =
      (request.headers && request.headers[Constants.Headers.XForwardedFor]) ||
      (request.connection && request.connection.remoteAddress);

    try {
      for (let i = 0; i < telemetrybatch.TelemetryEvents?.length; i++) {
        const telemetry = telemetrybatch.TelemetryEvents[`${i}`];
        try {
          logOutRequest(telemetry, userAgent, clientIp);
        } catch (exception) {
          Logger.Exception(
            'LoggerService.ClientLogOutRequests',
            'Error logging telemetry row. Original telemtry row: ' + JSON.stringify(telemetry),
            exception,
            { requestId, correlationId }
          );
        }
      }
    } catch (exception) {
      Logger.Exception(
        'LoggerService.ClientLogOutRequests',
        'Error logging telemetry batch. Original telemtry batch: ' + JSON.stringify(telemetrybatch),
        exception,
        { requestId, correlationId }
      );
    }
  }

  // Used for server
  export function ServerLogOutRequest(telemetry: ITelemetryOutRequest) {
    const userAgent = getAppConfig('httpUserAgent');
    const clientIp = getAppConfig('serverIp');

    try {
      logOutRequest(telemetry, userAgent, clientIp);
    } catch (exception) {
      Logger.Exception(
        'LoggerService.ServerLogOutRequest',
        'Error logging telemetry row. Original telemtry row: ' + JSON.stringify(telemetry),
        exception
      );
    }
  }

  export function logServerTelemetryEvent(payload: ITelemetryData, correlationId: string, requestId?: string) {
    const event: ITelemetryEvent = {
      page: payload.page ? payload.page : '',
      action: payload.action ? payload.action : '',
      actionModifier: payload.actionModifier ? payload.actionModifier : '',
      clientTimestamp: new Date().toISOString(),
      details: payload.details ? payload.details : '',
      isFeedback: payload.isFeedback,
    };

    const outEvents = { TelemetryEvents: [event] };
    requestId = requestId || generateGuid();
    LogTelemetryEvents(requestId, correlationId, outEvents);
  }

  function errorToConsole(message: string) {
    if (process.env.siteEnvironment !== 'prod') {
      console.error(message);
    }
  }

  export function logDebugMessage(requestId: string, correlationId: string, operation: string, message: string) {
    Debug(operation, message, '', {
      requestId: requestId,
      correlationId: correlationId,
    });
    writeToLogStream(correlationId, operation, message);
  }

  export function logErrorMessage(requestId: string, correlationId: string, operation: string, message: string) {
    Exception(operation, message, '', {
      requestId: requestId,
      correlationId: correlationId,
    });
  }

  export function logInfoMessage(operation: string, message: string, exception?: string, additionalData?: any) {
    Information(operation, message, exception, additionalData);
    writeToLogStream(additionalData ? additionalData.correlationId : serverInstanceCorrelationId, operation, message);
  }

  export function logError(operation: string, message: string, err: string, additionalData?: any, correlationId?: string) {
    const trace = stackTrace.parse(err);
    Logger.Exception(operation, message + ':' + stringifyError(err), trace, additionalData);

    const payload: ITelemetryData = {
      page: 'Error Page (Server)',
      action: 'Page Load',
      actionModifier: Constants.Telemetry.ActionModifier.Error,
      details: '[Error Trace] ' + stringifyError(err) + '   Stack: ' + JSON.stringify(trace),
    };

    correlationId =
      correlationId ||
      (additionalData && additionalData.correlationId ? additionalData.correlationId : serverInstanceCorrelationId);
    const requestId = additionalData && additionalData.requestId;

    logServerTelemetryEvent(payload, correlationId, requestId);
    writeToLogStream(correlationId, operation, message);
  }

  // Creates a hash based on the error stack or on the error message and returns the JSON with the hash
  function getErrorDetailsWithHash(errorMessage: string | Object): { message: string; errorHash: string } {
    const stringErrorMessage = typeof errorMessage === 'string' ? errorMessage : stringifyError(errorMessage);
    const errorStack = stringErrorMessage.split('Stack:');
    const hash = errorStack.length > 0 ? sha256(errorStack[1]) : sha256(errorMessage);
    const errorDetails = {
      message: stringErrorMessage,
      errorHash: hash.toString(),
    };
    return errorDetails;
  }

  const parseEventDetails = (event: ITelemetryEvent) => {
    try {
      return JSON.parse(event.details);
    } catch (e) {
      return { details: event.details }; // incase the details is not a valid JSON
    }
  };

  function telemetryHelper(event: ITelemetryEvent, req: Request) {
    if (event.action === Constants.Telemetry.Action.UserSettings) {
      const clientIP = req.headers[Constants.Headers.XForwardedFor] || req.connection.remoteAddress;
      const userAgent = req.headers[Constants.Headers.XMSUserAgent] || req.headers[Constants.Headers.UserAgent];
      const parsedUserAgent = userAgent && agentParser(userAgent as string);

      event.details = JSON.stringify({
        ...parseEventDetails(event),
        ...parsedUserAgent,
        clientIP,
        ...scrubHeaders(req.headers),
      });
    }

    return event;
  }

  function logOutRequest(telemetry: ITelemetryOutRequest, userAgent: string, clientIp: string) {
    if (telemetry.taskName === Constants.Telemetry.OutRequest.Start) {
      Logger.LogHttpOutboundRequestStart(
        outRequestStartsTelemetryHelper(telemetry as ITelemetryOutRequestStart, userAgent, clientIp)
      );
    } else {
      const telemetryEvent = outRequestEndsTelemetryHelper(telemetry as ITelemetryOutRequestEnd, userAgent, clientIp);

      if (telemetry.taskName === Constants.Telemetry.OutRequest.EndWithSuccess) {
        Logger.LogHttpOutboundRequestEndWithSuccess(telemetryEvent);
      } else if (telemetry.taskName === Constants.Telemetry.OutRequest.EndWithClientError) {
        Logger.LogHttpOutboundRequestEndWithClientFailure(telemetryEvent);
      } else if (telemetry.taskName === Constants.Telemetry.OutRequest.EndWithServerError) {
        Logger.LogHttpOutboundRequestEndWithServerFailure(telemetryEvent);
      }
    }
  }

  function outRequestTelemetryHelper(event: ITelemetryOutRequest, userAgent: string, clientIpAddress: string) {
    const requestContext: IHttpRequestContext = {
      requestId: event.requestId,
      correlationId: event.correlationId,
      operation: event.operation,
      httpMethod: event.httpMethod,
      hostName: event.hostName,
      targetUri: event.targetUri,
      userAgent,
      clientIpAddress,
      apiVersion: '', // TODO: Implement
      contentLength: 0,
      headers: '',
    };

    return requestContext;
  }

  function outRequestStartsTelemetryHelper(
    event: ITelemetryOutRequestStart,
    userAgent: string,
    clientIpAddress: string
  ): IHttpRequestContext {
    const httpRequestContext = outRequestTelemetryHelper(event, userAgent, clientIpAddress);

    httpRequestContext.headers = event.requestHeaders;

    return httpRequestContext;
  }

  function outRequestEndsTelemetryHelper(
    event: ITelemetryOutRequestEnd,
    userAgent: string,
    clientIpAddress: string
  ): IHttpRequest {
    const httpRequestContext = outRequestTelemetryHelper(event, userAgent, clientIpAddress);

    httpRequestContext.contentLength = event.contentLength;
    httpRequestContext.headers = event.responseHeaders;

    const request: IHttpRequest = {
      httpRequestContext,
      errorMessage: event.errorMessage,
      durationInMilliseconds: event.durationInMilliseconds,
      httpStatusCode: event.httpStatusCode,
    };

    return request;
  }

  export const LogHttpOutboundRequestEnds = {
    [Constants.Telemetry.OutRequest.EndWithSuccess]: Logger.LogHttpOutboundRequestEndWithSuccess,
    [Constants.Telemetry.OutRequest.EndWithClientError]: Logger.LogHttpOutboundRequestEndWithClientFailure,
    [Constants.Telemetry.OutRequest.EndWithServerError]: Logger.LogHttpOutboundRequestEndWithServerFailure,
  };
}

export interface ILogStreamItem {
  timeStamp: string;
  operation: string;
  message: string;
}
