import { isEmpty } from 'lodash-es';
import * as superagent from 'superagent';

import { IURLQuery } from '@shared/Models';
import { generateGuid } from '@shared/utils/appUtils';
import { Constants } from '@shared/utils/constants';
import { HttpError } from '@server/errors';
import { getAppConfig } from '@shared/services/init/appConfig';
import {
  shouldRefreshToken,
  logOutRequestStart,
  logOutRequestEnd,
  logOutRequestError,
  scrubHeaders,
} from '@shared/utils/httpClientUtil';
import { IAccessToken, IState } from '../../../State';
import type { AnyAction, Store } from 'redux';
import { createSetAccessTokenAction } from '@shared/actions/actions';
import { mergeAccessToken } from '@shared/utils/accessToken';
import { fetchAccessTokens } from '@shared/msal/tokens';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const queryString = require('query-string');

export interface IHttpHeader {
  'Access-Control-Allow-Origin'?: string;
  'Access-Control-Allow-Methods'?: string;
  'Access-Control-Allow-Headers'?: string;
  'Content-Type'?: string;
  'x-ms-test'?: string;
  'x-ms-requestid'?: string;
  'x-ms-client-name'?: string;
  'x-ms-correlationid'?: string;
  'User-Agent'?: string;
  'x-ms-source'?: string;
  'Accept-Encoding'?: string;
  Host?: string;
  Accept?: string;
  Expect?: string;
  Connection?: string;
  SearchActionType?: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace HttpModule {
  let accessToken: IAccessToken = null;
  let store: Store<IState, AnyAction> = null;

  export function updateAccessToken(newAccessToken: IAccessToken) {
    if (!isEmpty(newAccessToken)) {
      accessToken = { ...newAccessToken };
    }
  }

  export function getAccessToken() {
    return accessToken;
  }

  export function updateStore(outerStore: Store<IState, AnyAction>) {
    store = outerStore;
  }

  export function getStore() {
    return store;
  }
}

// bitmasks for the authentication types
export enum AuthenticationTypes {
  Unauthenticated = 0,
  Spza = 1,
  Graph = 4,
  Arm = 16,
  JarvisCM = 64,
  Pifd = 128,
  CommerceApi = 256,
  MarketplaceLeads = 512,
  Agreement = 1024,
}

interface HttpWrapperResponse {
  data: superagent.Response['text'] | superagent.Response['body'];
  headers: superagent.Response['headers'];
}

export type ResponseWithHeader = superagent.Response & { _header: any };
export type ResponseWithRequest = superagent.Response & { request: superagent.Response };

function isResponseWithHeader(response: superagent.Response): response is ResponseWithHeader {
  return !!(response as unknown as ResponseWithHeader)?._header;
}

function isResponseWithRequest(response: superagent.Response): response is ResponseWithRequest {
  return !!(response as ResponseWithRequest)?.request;
}

function isResponseWithReq(response: superagent.Response): response is superagent.Response & { req: superagent.Response } {
  return !!(response as superagent.Response & { req: superagent.Response })?.req;
}

export function scrubResponseHeaders(response: superagent.Response): void {
  if (response?.headers) {
    response.headers = scrubHeaders(response.headers);
  }

  if (response?.header) {
    response.header = scrubHeaders(response.header);
  }

  if (isResponseWithHeader(response)) {
    response._header = scrubHeaders(response._header);
  }
}

function scrubErrorHeaders(error: superagent.ResponseError): superagent.ResponseError {
  scrubResponseHeaders(error?.response);

  if (isResponseWithRequest(error?.response)) {
    scrubResponseHeaders(error.response.request);
  }

  if (isResponseWithReq(error?.response)) {
    scrubResponseHeaders(error.response.req);
  }

  return error;
}

export class HttpWrapper {
  endpoint = '';
  query: IURLQuery = {};
  baseUrl = '';
  header: IHttpHeader = {};
  option: IHttpOption = {};

  method = '';
  data: any;

  constructor(endpoint: string, method: string, option?: IHttpOption) {
    const appConfig = getAppConfig();

    // set default option
    this.addOption({
      clientType: appConfig.runtimeEnvironment,
      contentType: 'application/json',
      requireCorrelationId: true,
      flushTelemetry: false,
      retry: 2,
      stringifyPostData: false,
      parseResult: true,
      allowOrigin: false,
      fullEndpoint: false,
      returnRawResponse: false,
      authenticationType: AuthenticationTypes.Unauthenticated,
      setBearerHeader: false,
      queryParams: {},
    });

    // merge custom option
    if (option) {
      this.addOption(option);
    }

    if (this.option.fullEndpoint) {
      this.baseUrl = appConfig.hostname;
      this.endpoint = `${this.baseUrl}${endpoint}`;
    } else {
      if (__SERVER__ && endpoint.startsWith('/')) {
        endpoint = `${appConfig.hostname}${endpoint}`;
      }
      this.endpoint = endpoint;
    }

    if (appConfig.XMSClientName) {
      this.header[Constants.Headers.XMSClientName] = appConfig.XMSClientName;
    }

    if (this.option.clientType !== 'browser') {
      this.header[Constants.Headers.UserAgent] = appConfig.httpUserAgent;
    }

    // set correlationId
    if (this.option.requireCorrelationId) {
      this.header[Constants.Headers.CorrelationId] = appConfig.correlationId;
    }

    this.header[Constants.Headers.ContentType] = this.option.contentType;
    this.header[Constants.Headers.RequestId] = this.option.requestId || generateGuid();

    this.initQuery(this.option.queryParams);

    this.method = method;
  }

  initQuery(queryParams: IURLQuery) {
    for (const key in queryParams) {
      if (Object.prototype.hasOwnProperty.call(queryParams, key)) {
        const value = queryParams[`${key}`];

        if (value !== null && value !== undefined) {
          this.addQueryEntry(key, value);
        }
      }
    }
    return this;
  }

  addOption(option: IHttpOption) {
    for (const entry in option) {
      // eslint-disable-next-line no-prototype-builtins
      if (option.hasOwnProperty(entry)) {
        this.option[`${entry}`] = option[`${entry}`];
      }
    }
    return this.option;
  }

  copyOption(option?: IHttpOption) {
    option = option || this.option;

    const copy: IHttpOption = {
      authenticationType: AuthenticationTypes.Unauthenticated,
    };

    for (const entry in option) {
      // eslint-disable-next-line no-prototype-builtins
      if (option.hasOwnProperty(entry)) {
        copy[`${entry}`] = option[`${entry}`];
      }
    }

    return copy;
  }

  setQuery(query: any) {
    if (query) {
      this.query = query;
    }
    return this;
  }

  addQuery(query: any) {
    for (const entry in query) {
      // eslint-disable-next-line no-prototype-builtins
      if (query.hasOwnProperty(entry)) {
        this.query[`${entry}`] = query[`${entry}`];
      }
    }
    return this;
  }

  addQueryEntry(entry: string, value: string) {
    if (!this.query) {
      this.setQuery({});
    }
    this.query[`${entry}`] = value;
    return this;
  }

  getQueryString(query?: any): string {
    query = query || this.query;
    if (query) {
      // It uses encodeURIComponent if 'strict' is set to false. Otherwise it strictly encodes URI components with strict-uri-encode.
      return queryString.stringify(query, { strict: false });
    } else {
      return '';
    }
  }

  setData(data: any) {
    this.data = data;
    return this;
  }

  setRetry(retry: number) {
    this.option.retry = retry;
    return this;
  }

  setHeader(entry: string, value: string) {
    this.header[`${entry}`] = value;
    return this;
  }

  setAuthHeader(token: string, authHeaderTitle = Constants.Headers.Authorization) {
    if (token) {
      this.header[`${authHeaderTitle}`] = token.startsWith('Bearer') ? token : 'Bearer ' + token;
    }
    return this;
  }

  addHeader(header: IHttpHeader) {
    for (const entry in header) {
      // eslint-disable-next-line no-prototype-builtins
      if (header.hasOwnProperty(entry)) {
        this.header[`${entry}`] = header[`${entry}`];
      }
    }
    return this;
  }

  emptyQuery() {
    if (!this.query) {
      return true;
    }

    for (const entry in this.query) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.query.hasOwnProperty(entry)) {
        return false;
      }
    }

    return true;
  }

  request(retry?: number): HttpWrapperResponse['data'] {
    return this.requestWithHeaders(retry).then((d) => d.data);
  }

  requestWithHeaders(retry?: number): Promise<HttpWrapperResponse> {
    let url = this.endpoint;

    if (!this.emptyQuery()) {
      url += '?' + this.getQueryString(this.query);
    }

    let request: superagent.SuperAgentRequest = null;

    if (this.method === 'POST') {
      request = superagent.post(url);
    }

    if (this.method === 'PATCH') {
      request = superagent.patch(url);
    }

    if (this.method === 'PUT') {
      request = superagent.put(url);
    }

    if (this.method === 'GET') {
      request = superagent.get(url);
    }

    if (this.method === 'DELETE') {
      request = superagent.delete(url);
    }

    if (retry == null) {
      retry = this.option.retry;
    }

    if (retry > 0) {
      request.retry(retry);
    }

    const timeout: number = this.option.timeout;
    if (timeout && timeout > 0) {
      request.timeout(timeout);
    }

    if (this.option.setBuffer) {
      request.buffer();
    }

    if (this.option.responseType) {
      request.responseType(this.option.responseType);
    }

    if (this.option.type) {
      request.type(this.option.type);
    }

    if (getAppConfig().agent && this.endpoint.startsWith('https')) {
      request.agent(getAppConfig().agent);
    }

    if (this.option.allowOrigin) {
      this.addHeader({
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, PUT, GET, OPTIONS, DELETE',
        'Access-Control-Allow-Headers': 'x-requested-with',
      });
    }

    if (this.option.requireCorrelationId && !this.header[Constants.Headers.CorrelationId]) {
      return Promise.reject(Error('CorrelationId not found in the header'));
    }

    // set headers
    for (const property in this.header) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.header.hasOwnProperty(property)) {
        request.set(property, this.header[`${property}`]);
      }
    }

    // POST & DELETE
    if (this.method === 'POST' || this.method === 'PATCH' || this.method === 'PUT' || this.method === 'DELETE') {
      if (this.option.contentType === 'application/json' && typeof this.data !== 'string' && this.option.stringifyPostData) {
        request.send(JSON.stringify(this.data));
      } else {
        request.send(this.data);
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    // refresh access token
    let shouldRefreshAccessToken = false;
    if (this.option.authenticationType > 0) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const accessToken = HttpModule.getAccessToken();

      if (__CLIENT__ && accessToken) {
        if (this.option.authenticationType & AuthenticationTypes.Spza) {
          shouldRefreshAccessToken = shouldRefreshAccessToken || shouldRefreshToken(accessToken.spza);
        }

        if (this.option.authenticationType & AuthenticationTypes.Graph) {
          shouldRefreshAccessToken = shouldRefreshAccessToken || shouldRefreshToken(accessToken.graph);
        }

        if (this.option.authenticationType & AuthenticationTypes.Arm) {
          shouldRefreshAccessToken = shouldRefreshAccessToken || shouldRefreshToken(accessToken.arm);
        }
        if (this.option.authenticationType & AuthenticationTypes.CommerceApi) {
          shouldRefreshAccessToken = shouldRefreshAccessToken || shouldRefreshToken(accessToken.commerce);
        }
        if (this.option.authenticationType & AuthenticationTypes.JarvisCM) {
          shouldRefreshAccessToken = shouldRefreshAccessToken || shouldRefreshToken(accessToken.jarvis);
        }
        if (this.option.authenticationType & AuthenticationTypes.Pifd) {
          shouldRefreshAccessToken = shouldRefreshAccessToken || shouldRefreshToken(accessToken.pifd);
        }
        if (this.option.authenticationType & AuthenticationTypes.MarketplaceLeads) {
          shouldRefreshAccessToken = shouldRefreshAccessToken || shouldRefreshToken(accessToken.marketplaceLeads);
        }
        if (this.option.authenticationType & AuthenticationTypes.Agreement) {
          shouldRefreshAccessToken = shouldRefreshAccessToken || shouldRefreshToken(accessToken.agreement);
        }
      }
    }

    const setAuthenticationHeaders = (authenticationType: number, accessToken: IAccessToken, setBearerHeader = false) => {
      if (accessToken) {
        if (authenticationType & AuthenticationTypes.Spza && accessToken.spza) {
          if (setBearerHeader) {
            request.set(Constants.Headers.Authorization, Constants.Headers.Bearer + accessToken.spza);
          } else {
            request.set(Constants.Headers.Authorization, accessToken.spza);
          }
        }

        if (authenticationType & AuthenticationTypes.Graph && accessToken.graph) {
          request.set(Constants.Headers.Authorization, Constants.Headers.Bearer + accessToken.graph);
        }

        if (authenticationType & AuthenticationTypes.Arm && accessToken.arm) {
          request.set(Constants.Headers.Authorization, Constants.Headers.Bearer + accessToken.arm);
        }
        if (authenticationType & AuthenticationTypes.CommerceApi && accessToken.commerce) {
          request.set(Constants.Headers.Authorization, Constants.Headers.Bearer + accessToken.commerce);
        }
        if (authenticationType & AuthenticationTypes.JarvisCM && accessToken.jarvis) {
          request.set(Constants.Headers.Authorization, Constants.Headers.Bearer + accessToken.jarvis);
        }
        if (authenticationType & AuthenticationTypes.Pifd && accessToken.pifd) {
          request.set(Constants.Headers.Authorization, Constants.Headers.Bearer + accessToken.pifd);
        }
        if (authenticationType & AuthenticationTypes.MarketplaceLeads && accessToken.marketplaceLeads) {
          request.set(Constants.Headers.Authorization, Constants.Headers.Bearer + accessToken.marketplaceLeads);
        }
        if (authenticationType & AuthenticationTypes.Agreement && accessToken.agreement) {
          request.set(Constants.Headers.Authorization, Constants.Headers.Bearer + accessToken.agreement);
        }
      }
    };

    // the end request promise
    // it will perform the actual POST, PUT, GET or DEL
    const endRequest = (): Promise<HttpWrapperResponse> => {
      // sets the authentication headers depending on authentication type (if needed)
      setAuthenticationHeaders(this.option.authenticationType, HttpModule.getAccessToken(), this.option.setBearerHeader);

      return new Promise((resolve, reject) => {
        setTimeout(() => logOutRequestStart(request));

        if (self.option.requestCallback) {
          self.option.requestCallback({
            abort: () => {
              return request.abort();
            },
          });
        }

        const start = Date.now();

        request.end((err, res) => {
          const duration = Date.now() - start;

          if (self.option.requestCallback) {
            self.option.requestCallback(null);
          }

          if (err) {
            const error = new HttpError(err);
            setTimeout(() => logOutRequestError(request, res, duration, error, false));
            return reject(error);
          }

          const isJSON = res.headers['content-type']?.includes('application/json');
          const isRemoteChunk =
            res.headers['content-type']?.includes('application/javascript') && this.endpoint.includes('agorasstatic');

          setTimeout(() => logOutRequestEnd(request, res, duration));

          if (self.option.returnRawResponse) {
            resolve({ data: res, headers: res.headers });
          } else if (res.ok && isJSON) {
            resolve({ data: res.body, headers: res.headers });
          } else if (isRemoteChunk) {
            resolve({ data: res.body.toString('utf-8'), headers: res.headers });
          } else {
            resolve({ data: res.text, headers: res.headers });
          }
        });
      });
    };

    if (shouldRefreshAccessToken) {
      // refresh access token will never fail
      // it is designed to always resolve even if the refresh itself fails
      const promise = fetchAccessTokens({
        resources: ['spza', 'arm', 'graph', 'commerce', 'jarvis', 'marketplaceLeads', 'pifd', 'agreement'],
      });

      return promise.then((accessToken: IAccessToken) => {
        // update the accesstoken in the state
        if (!accessToken) {
          return Promise.reject(Constants.Telemetry.Action.RefreshToken);
        }

        const storedToken = mergeAccessToken({ currentAccessToken: HttpModule.getAccessToken(), newAccessToken: accessToken });
        HttpModule.getStore()?.dispatch(createSetAccessTokenAction(storedToken));
        HttpModule.updateAccessToken(storedToken);

        // endRequest returns a promise. So we need not catch exceptions from this then(..)
        return endRequest();
      });
    }

    return endRequest();
  }
}

export function post(endpoint: string, option?: IHttpOption) {
  return new HttpWrapper(endpoint, 'POST', option);
}

export function patch(endpoint: string, option?: IHttpOption) {
  return new HttpWrapper(endpoint, 'PATCH', option);
}

export function put(endpoint: string, option?: IHttpOption) {
  return new HttpWrapper(endpoint, 'PUT', option);
}

export function get(endpoint: string, option?: IHttpOption) {
  return new HttpWrapper(endpoint, 'GET', option);
}

export function del(endpoint: string, option?: IHttpOption) {
  return new HttpWrapper(endpoint, 'DELETE', option);
}

export interface IHttpRequest {
  abort: () => void;
}

export interface IHttpOption {
  clientType?: string;
  contentType?: string;
  requireCorrelationId?: boolean;
  flushTelemetry?: boolean;
  retry?: number;
  stringifyPostData?: boolean;
  parseResult?: boolean;
  allowOrigin?: boolean;
  fullEndpoint?: boolean;
  // When set, request(..) returns the actual response from the HTTP call.
  // Otherwise it returns only the response body.
  returnRawResponse?: boolean;
  authenticationType?: AuthenticationTypes;
  setBearerHeader?: boolean;
  setBuffer?: boolean;
  type?: string;
  timeout?: number;
  requestId?: string;
  requestCallback?: (request: IHttpRequest) => void;
  responseType?: 'blob' | string;
  queryParams?: IURLQuery;
}

export const errorMessage = {
  'Failed to post': 'Failed to make a POST request',
  'Failed to get': 'Failed to make a GET request',
  'Failed to delete': 'Failed to make a DELETE request',
  'appId not availble': 'appId is not available to perform this operation',
  'correlationId not availble': 'correlationId is missing to perform this operation',
};
