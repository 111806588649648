import React from 'react';

import { AppTile } from '@shared/containers/appTile';

import { IDataItem } from '@shared/Models';
import { Constants } from '@shared/utils/constants';
import SpzaComponent from '@shared/components/spzaComponent';
import { ServiceTile } from '@shared/containers/serviceTile';

export interface ICommonTileProps extends IDataItem {
  item: IDataItem;
  tileIndex: number;
  totalTiles: number;
  selectedLocale?: string;
  countryContext?: string;
  embedHost?: string;
  ribbonKey?: string;
}

export interface ITileDispatchProps {
  setTileCheckoutSource: () => void;
}

export default class CommonTile extends SpzaComponent<ICommonTileProps, unknown> {
  renderImpl() {
    if (this.props.entityType === Constants.EntityType.Service) {
      return <ServiceTile {...this.props} />;
    }

    return <AppTile {...this.props} />;
  }
}
