import React from 'react';
import * as PropTypes from 'prop-types';
import { IImageStyles, Image, Link, getWindow } from '@fluentui/react';
import { Constants } from '@shared/utils/constants';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import pdpPbiBadge from '@shared/images/pdp_pbiBadge.svg';
import pdpMspBadge from '@shared/images/pdp_mspBadge.svg';
import pdpM365CertificationBadge from '@shared/images/pdp_m365CertificationBadge.svg';
import freeTrial from '@shared/images/freeTrial.svg';
import BadgeMicrosoft365AppAward from '@shared/images/Badge_Microsoft_365_app_award.svg';
import pdpPreferBadge from '@shared/images/pdp_preferBadge.svg';
import pdpPrivateBadge from '@shared/images/pdp-privateBadge.svg';
import pdpAzureBenefitEligible from '@shared/images/badges/AzureBenefitEligiblePDP.svg';
import pdpCertifiedSoftware from '@shared/images/badges/pdp_CertifiedSoftware.svg';
import { logger } from '@src/logger';
import { ITelemetryData } from '@shared/Models';
import { SpzaInstrumentService } from 'services/telemetry/spza/spzaInstrument';

type BadgeProps = {
  name: string;
};

type BadgeListProps = {
  src: string;
  locTitle: string;
  link: string | null;
  title: string;
};

const ImageStyles: Partial<IImageStyles> = {
  image: { height: '28px' },
};

export const badgeLinks = {
  AzureBenefitURL: 'http://aka.ms/appsource/azurebenefit',
  OfficeAwardsURL: 'https://aka.ms/microsoftappawards',
  SolutionMapURL: 'https://go.microsoft.com/fwlink/?LinkId=875247',
  PrivateOfferURL: 'https://go.microsoft.com/fwlink/?linkid=2162844',
  PBICertifiedURL: 'https://aka.ms/pbicertified',
  AzureExpertsMSPURL: 'https://aka.ms/AzureExpertMSP',
  MicrosoftCertifiedURL: 'https://aka.ms/appcertification',
  CertifiedSoftware: 'https://aka.ms/CertifiedsoftwarepartnerFAQ',
};

const badgeProps: Record<string, BadgeListProps> = {
  FreeTrial: {
    src: freeTrial,
    locTitle: 'Global_Loc_Free_trial_badge',
    link: null,
    title: 'Free trial badge',
  },
  OfficeAwards: {
    src: BadgeMicrosoft365AppAward,
    locTitle: 'Global_Loc_Microsoft_365_App_Award',
    link: badgeLinks.OfficeAwardsURL,
    title: 'Microsoft 365 App Award',
  },
  SolutionMap: {
    src: pdpPreferBadge,
    locTitle: 'Global_Loc_Microsoft_preferred_solution',
    link: badgeLinks.SolutionMapURL,
    title: 'Microsoft preferred solution',
  },
  PrivateOffer: {
    src: pdpPrivateBadge,
    locTitle: 'Global_Loc_Private_plans',
    link: badgeLinks.PrivateOfferURL,
    title: 'Private plans',
  },
  PBICertified: {
    src: pdpPbiBadge,
    locTitle: 'Global_Loc_Microsoft_PBI_Certified',
    link: badgeLinks.PBICertifiedURL,
    title: 'Microsoft PBI Certified',
  },
  AzureExpertsMSP: {
    src: pdpMspBadge,
    locTitle: 'Global_Loc_Azure_Experts_MSP',
    link: badgeLinks.AzureExpertsMSPURL,
    title: 'Azure Experts MSP',
  },
  MicrosoftCertified: {
    src: pdpM365CertificationBadge,
    locTitle: 'Global_Loc_Microsoft_365_Certified',
    link: badgeLinks.MicrosoftCertifiedURL,
    title: 'Microsoft 365 Certified',
  },
  SelfAttested: {
    src: pdpM365CertificationBadge,
    locTitle: 'Global_Loc_Microsoft_365_Certified',
    link: badgeLinks.MicrosoftCertifiedURL,
    title: 'Microsoft 365 Certified',
  },
  AzureBenefitEligible: {
    src: pdpAzureBenefitEligible,
    locTitle: 'Global_Loc_Azure_benefit',
    link: badgeLinks.AzureBenefitURL,
    title: 'Azure benefit eligible',
  },
  CertifiedSoftware: {
    src: pdpCertifiedSoftware,
    locTitle: 'Global_Loc_Certified_Software',
    link: badgeLinks.CertifiedSoftware,
    title: 'Certified Software',
  },
};
const Badge: React.FC<BadgeProps> = ({ name }: BadgeProps, context: ILocContext & ILocParamsContext) => {
  const handleBadgeClick = (contentName: string) => {
    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.Info,
      details: JSON.stringify({ contentName }),
    };

    SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });
  };

  const badge = badgeProps[`${name}`];

  if (!badge) {
    return null;
  }

  const locTitle = context.loc(badge.locTitle, badge.title);

  return badge.link ? (
    <Link
      href={badge.link}
      target="_blank"
      rel="noreferrer"
      title={locTitle}
      aria-label={locTitle}
      accessibility={true}
      onClick={() => handleBadgeClick(locTitle)}
    >
      <Image src={badge.src} alt={locTitle} styles={ImageStyles} />
    </Link>
  ) : (
    <Image styles={ImageStyles} src={badge.src} alt={locTitle} />
  );
};

Badge.contextTypes = {
  loc: PropTypes.func,
  locParams: PropTypes.func,
};

export default Badge;
