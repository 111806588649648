import { SuperAgentRequest, Response } from 'superagent';
import { ITelemetryData, ITelemetryOutRequestStart, ITelemetryOutRequestEnd, ITelemetryOutRequest } from '../Models';
import { getWindow } from '../services/window';
import { Constants } from '../utils/constants';
import { SpzaInstrumentService } from '../services/telemetry/spza/spzaInstrument';
import { SharedInstrumentService } from '../services/telemetry/shared/sharedInstrument';
import { getAppConfig } from '../services/init/appConfig';
import { stringifyError } from './errorUtils';
import { logger, systemLogger } from '@src/logger';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const jwtDecode = require('jwt-decode');

export const headersToRedact = {
  [Constants.Headers.Cookie.toLowerCase()]: true,
  [Constants.Headers.Authorization.toLowerCase()]: true,
  [Constants.Headers.InternalAuthorizationHeader.toLowerCase()]: true,
  [Constants.Headers.XForwardedFor.toLowerCase()]: true,
  [Constants.Headers.XIISNodeRemoteAddr.toLowerCase()]: true,
  [Constants.Headers.ApiKey.toLowerCase()]: true,
  [Constants.Headers.SetCookie.toLowerCase()]: true,
  [Constants.Headers.XMSAuthInternalToken.toLowerCase()]: true,
  [Constants.Headers.XAPIKey.toLowerCase()]: true,
  [Constants.Headers.XProofToken.toLowerCase()]: true,
  [Constants.Headers.ProxyAuthorization.toLowerCase()]: true,
};

function generateTelemetryPayload(error: any, operationName: string): ITelemetryData {
  const _details: any = {};
  _details.operation = operationName;
  _details.error = error || '';

  if (typeof error !== 'string') {
    _details.error = stringifyError(error);
  }

  let page = 'server';
  if (getAppConfig('runtimeEnvironment') === 'browser') {
    page = getWindow().location.href;
  }

  return {
    page,
    action: Constants.Telemetry.Action.NetworkCall,
    actionModifier: Constants.Telemetry.ActionModifier.Error,
    details: _details,
  };
}

function parseURL(url: string) {
  // eslint-disable-next-line security/detect-non-literal-regexp
  const reURLInformation = new RegExp(
    [
      '^(https?:)//', // protocol
      '(([^:/?#]*)(?::([0-9]+))?)', // host (hostname and port)
      '(/{0,1}[^?#]*)', // pathname
      '(\\?[^#]*|)', // search
      '(#.*|)$', // hash
    ].join('')
  );

  const match = url.match(reURLInformation);

  if (!match) {
    return {};
  } else {
    return {
      protocol: match[1],
      host: match[2],
      hostname: match[3],
      port: match[4],
      pathname: match[5],
      search: match[6],
      hash: match[7],
    };
  }
}

/**
 * This method removes the values of headers which contains PII or sensitive information.
 * The object returned will maintain the keys, but the senstive header values will be replaced with 'SCRUBBED (Length X)'
 * i.e.
 * {Authorization: SCRUBBED (Length 160), x-ms-correlationId: 04056106-cf0c-40cc-8e68-d657442da7ad\}
 */
export function scrubHeaders<T extends Record<string, string>>(headers: T): T {
  return Object.fromEntries(
    Object.entries(headers).map(([key, value]) => {
      if (headersToRedact[`${key.toLowerCase()}`]) {
        value = `${Constants.scrubbedHeaderValue} (Length ${headers[`${key}`]?.length})`;
      }

      return [key, value];
    })
  ) as T;
}

function logOutRequestEventInfo(telemetry: ITelemetryOutRequest) {
  SharedInstrumentService.getProvider().probe<ITelemetryOutRequest>('logOutRequestEventInfo', telemetry);
}

export function logError(operationName: string, error?: any) {
  const instrument = SpzaInstrumentService.getProvider();

  let code = '';
  if (error?.response?.statusCode) {
    code = error.response.statusCode.toString();
  }

  const payload = generateTelemetryPayload(`${error}. Code: ${code}. Error details: ${error?.response?.text}`, operationName);
  instrument.probe<ITelemetryData>('logAndFlushTelemetryInfo', payload);
  logger.error(payload.details, { action: payload.action, actionModifier: payload.actionModifier, page: payload.page });
}

function parseRequest(request: SuperAgentRequest, taskName: string) {
  let operationName: string;
  if (getAppConfig('runtimeEnvironment') === 'browser') {
    operationName = getWindow().location.href;
  } else {
    operationName = 'server';
  }

  const requestHeaders = (request as any).header;

  const correlationId = (requestHeaders && requestHeaders[Constants.Headers.CorrelationId]) || '';
  const requestId = (requestHeaders && requestHeaders[Constants.Headers.RequestId]) || '';

  const parsedUrl = parseURL(request.url);

  const telemetry: ITelemetryOutRequestStart = {
    taskName,
    correlationId,
    requestId,
    operation: operationName,
    httpMethod: request.method,
    hostName: parsedUrl.host || '',
    targetUri: request.url,
    requestHeaders: JSON.stringify(scrubHeaders(requestHeaders)),
  };

  return telemetry;
}

export function logOutRequestStart(request: SuperAgentRequest) {
  // Prevent recusrive logs
  if (request.url.indexOf('api/log') === -1 && request) {
    const telemetry: ITelemetryOutRequestStart = parseRequest(request, Constants.Telemetry.OutRequest.Start);

    logOutRequestEventInfo(telemetry);
    systemLogger.info({ event: telemetry });
  }
}

// TODO: Merge commong code with logOutRequestError
export function logOutRequestEnd(request: SuperAgentRequest, response: Response, durationInMilliseconds: number) {
  // Prevent recusrive logs
  if (request.url.indexOf('api/log') === -1 && request && response) {
    let taskName: string;
    let errorMessage: string;

    if (response.ok) {
      taskName = Constants.Telemetry.OutRequest.EndWithSuccess;
    } else if (response.clientError) {
      taskName = Constants.Telemetry.OutRequest.EndWithClientError;
      errorMessage = response.text;
    } else {
      taskName = Constants.Telemetry.OutRequest.EndWithServerError;
      errorMessage = response.text;
    }

    const requestStart: ITelemetryOutRequestStart = parseRequest(request, taskName);

    const contentLength =
      (response.header && response.header['content-length'] && Number(response.header['content-length'])) || 0;

    const requestEnd: ITelemetryOutRequestEnd = {
      ...requestStart,
      durationInMilliseconds,
      httpStatusCode: response.status,
      errorMessage,
      contentLength,
      responseHeaders: JSON.stringify(scrubHeaders(response.header)),
    };

    logOutRequestEventInfo(requestEnd);
    systemLogger.info({ event: requestEnd });
  }
}

// TODO: Merge commong code with logOutRequestEnd
export function logOutRequestError(
  request: SuperAgentRequest,
  response: Response,
  duration: number,
  error: any,
  parsingError: boolean
) {
  // Prevent recusrive logs
  if (request.url.indexOf('api/log') === -1 && request && response) {
    const actionModifier =
      parsingError || response.clientError
        ? Constants.Telemetry.OutRequest.EndWithClientError
        : Constants.Telemetry.OutRequest.EndWithServerError;

    const requestStart: ITelemetryOutRequestStart = parseRequest(request, actionModifier);

    const errorMessage = stringifyError(error);

    const contentLength =
      (response.header && response.header['content-length'] && Number(response.header['content-length'])) || 0;

    const requestEnd: ITelemetryOutRequestEnd = {
      ...requestStart,
      durationInMilliseconds: duration,
      httpStatusCode: response.status,
      errorMessage,
      contentLength,
      responseHeaders: JSON.stringify(scrubHeaders(response.header)),
    };

    logOutRequestEventInfo(requestEnd);
    systemLogger.error({ event: requestEnd });
  }
}

export function shouldRefreshToken(token: string): boolean {
  if (__SERVER__) {
    return false;
  }

  const currentTime = Math.round(Date.now() / 1000);
  const tolerance = 5 * 60;

  try {
    const decodedToken = token ? jwtDecode(token) : null;

    if (decodedToken) {
      if (typeof decodedToken.exp === 'number') {
        const expiryTime = decodedToken.exp;

        if (expiryTime - currentTime < tolerance) {
          return true;
        }
      }
    }
  } catch (err) {
    return true;
  }

  return false;
}
