import { Request } from 'express';
import { StatusCodes } from 'http-status-codes';

import {
  IState,
  IDynamicCampaignState,
  ServiceCountries,
  IRelatedItems,
  IUserDataState,
  RecommendedWhatsNewApps,
  RecommendedTrendingApps,
  RecommendedWhatsNewService,
  RecommendedTrendingService,
  RecommendedMicrosoft365TrendingApps,
  RecommendedMicrosoft365WhatsNewApps,
} from '@src/State';
import {
  createEntityDataReceivedAction,
  createSearchboxInputChangedAction,
  createEntityDetailsReceivedAction,
  createAppsPricingDataReceivedAction,
  createAppPricingDataRequestedAction,
  createAppDetailPricingReceivedAction,
  createBillingRegionReadFromCookieAction,
  createUserSignOutAction,
  createUserProfileAction,
  createUserSignInFetchStatusAction,
  createUpdateUserProfileAction,
  createClearUserPropertiesAction,
  createDynamicCampaignReceivedAction,
  createSetTenantTypeAction,
  createServicesCountriesDataReceivedAction,
  createDataMapReceivedAction,
  createFilteredSearchResultsReceivedAction,
  createRelatedAppsItemsReceivedAction,
  createRelatedAppsItemsLoadingAction,
  createRelatedAppsItemsPricingDataReceivedAction,
  createSetEntityIdLoadingAction,
  createSetEntityIdFailedAction,
  createGetAppReviewsAction,
  createUserTenantsDetailsAction,
  createEntityFullDataReceivedAction,
  createUserTenantsDetailsLoadingAction,
  createUserProfileLoadingAction,
  createPartnerSearchResultsReceivedAction,
  createAppPricingDataReceivedAction,
  createEntityReviewCommentsReceivedAction,
  createEntityReviewCommentsCleanupAction,
  createEntityReviewCommentCreateResult,
  createEntityReviewCommentDeleteResult,
  createEntityReviewCommentUpdateResult,
  createEntityReviewCommentModalAction,
  createUserLoginDetailsAction,
  createEntityReviewCommentsLoadingAction,
  createEntityReviewCommentsInitializeStateAction,
  createEntityReviewCommentsUpdateTotalCommentCountAction,
  createEntityMarkedAsHelpfulReviewUpdateListAction,
  createEntityLinkedInProductGroupUpdateAction,
  createEntityReviewMarkAsHelpfulModalAction,
  createEntityReviewSetIsHelpfulAction,
  createEntityReviewSetMarkAsHelpfulCount,
  createEntityReviewSetIsHelpfulLoadingAction,
  createSetStorefrontNameAction,
  createLoadingRecommenededSizes,
  createSetRecommenededSizes,
  createEntityUserCommentedReviewIdsUpdateListAction,
  createRecommendedWhatsNewAppsReceivedAction,
  createRecommendedTrendingAppsReceivedAction,
  createShowingResultsForReceivedAction,
  createRecommendedAppsItemsPricingDataReceivedAction,
  createRecommendedWhatsNewServiceReceivedAction,
  createRecommendedTrendingServiceReceivedAction,
  createRecommendedMicrosoft365WhatsNewAppsReceivedAction,
  createRecommendedMicrosoft365TrendingAppsReceivedAction,
  createUpdateUserRoleDefinitions,
  createSetAccountIsLoadingAction,
  createSetAccountAction,
} from '@shared/actions/actions';

import {
  createUpdateCustomerAction,
  createSetActiveCustomerAction,
  createSetPublisherTokenResponse,
  createSetCustomersAction,
  createErrorAction,
  createClearErrorAction,
  createProduct,
  createFetchingProductAction,
} from '@shared/actions/checkoutActions';
import { IDataMap } from '@shared/utils/dataMapping';
import {
  isOnMicrosoftEmail,
  shouldEnsureAppDetails,
  shouldEnsureAppRelatedApps,
  shouldEnsureAppPricings,
  shouldEnsureServiceDetails,
  shouldEnsureServiceRelatedApps,
  shouldEnsureAppReviews,
  getTileDataQueryParams,
  shouldSendUserProfile,
  getActiveFeaturedFlags,
  parseCatalogRecommendedSizes,
  getProductByUrlKey,
  generateGuid,
} from '@shared/utils/appUtils';
import { createLeadPayload, scrubLeadPayload } from '@shared/utils/leadsUtils';
import { dispatchSearchQueryChangedAction, takeTopResults } from '@shared/utils/thunkActionUtils';

import * as suggestionsRestClient from '@shared/services/http/suggestionsRestClient';
import * as catalogApiRestClient from '@shared/services/http/catalogApiRestClient';
import * as armRestClient from '@shared/services/http/armRestClient';
import * as entityRestClient from '@shared/services/http/entityRestClient';
import * as leadgenRestClient from '@shared/services/http/leadgenRestClient';
import * as reviewRestClient from '@shared/services/http/reviewRestClient';
import * as fieldHubRestClient from '@shared/services/http/fieldHubRestClient';
import * as userRestClient from '@shared/services/http/userRestClient';
import * as dynamicCampaignRestClient from '@shared/services/http/dynamicCampaignRestClient';
import * as purchaseRestClient from '@shared/services/http/purchaseRestClient';
import * as commerceApiRestClient from '@shared/services/http/commerceApiRestClient';
import * as appReviewCommentsRestClient from '@shared/services/http/reviewCommentsRestClient';
import * as appReviewMarkAsHelpfulRestClient from '@shared/services/http/reviewMarkAsHelpfulRestClient';
import * as reviewsCatalogRestClient from '@shared/services/http/reviewsCatalogRestClient';
import * as linkedInRestClient from '@shared/services/http/linkedInRestClient';
import * as graphRestClient from '@shared/services/http/graphRestClient';
import * as reportReviewRestClient from '@shared/services/http/reportReviewRestClient';
import * as reviewAppReviewsRestClient from '@shared/services/http/reviewAppReviewsRestClient';
import * as userReviewRestClient from '@shared/services/http/userReviewRestClient';

// Constant API version that maps to the app schema,
// inflight browser never calls the API directly hence this is the only place the version lives
import { getCookieItem, saveCookieItem } from '@shared/utils/browserStorageUtils';
import { Constants } from '@shared/utils/constants';
import { getWindow } from '@shared/services/window';
import {
  ITelemetryData,
  IAppDataItemBasicData,
  IAppDataItem,
  UserSegment,
  IUserInfo,
  ILicense,
  ICustomer,
  CustomerType,
  ICustomerAddress,
  IPublisherOfferResponse,
  IURLQuery,
  FilterDataResults,
  IAppsPricingsPayload,
  TenantsDetails,
  IPartnerSearchResult,
  IPartnerSearchResponse,
  ICampaigns,
  IUserLeadProfileAgreement,
  IAppPricing,
  IFuturePrices,
  ICloudsIndustry,
  ICommonDataMap,
  IItemReport,
  ILeadPayload,
  ILeadCustomerInfo,
  SendLeadInfoOptions,
} from '@shared/Models';
import { SpzaInstrumentService } from '@shared/services/telemetry/spza/spzaInstrument';
import { SharedInstrumentService } from '@shared/services/telemetry/shared/sharedInstrument';
import { AzureEnvironmentSettings } from '@shared/AzureEnvironmentSettings';
import * as authRestClient from '@shared/services/http/authRestClient';
import { shouldRefreshToken, logError } from '@shared/utils/httpClientUtil';
import { previousPage, routes } from '@shared/routerHistory';
import { getCampaignsForAcquisitionFromUrl } from '@shared/utils/routeUtils';

import { getSearchQuery, removeDuplicateAddIns } from '@shared/utils/search';

import { stringifyError } from '@shared/utils/errorUtils';
import { extractCountryCodeFromQueryParam } from '@shared/utils/consultingServicesUtils';
import { createFavouriteAppsPricingDataReceivedAction } from './userFavouriteActions';
import { ensurePrivateOffer } from './privateOffersThunkActions';
import { Service } from '@shared/serviceViewModel';
import { Product } from 'services/product/product';
/* eslint-disable import/named */
import { Action } from 'redux';
import { Dispatch } from 'react-redux';
import { logger } from '@src/logger';
import {
  AppReviewCommentThunkActionBaseArgs,
  IAppsReviewCommentBaseCrudOperationPayload,
} from '@shared/interfaces/reviews/comments';
import { UserReviewFormData } from '@shared/interfaces/reviews/models';
import { launchTelemetry as launchReviewsTelemetry } from '@shared/utils/reviewsUtils';
import {
  IAppEntityMarkAsHelpfulBaseThunkActionParams,
  IAppEntitySetReviewMarkAsHelpfulParams,
} from '@shared/interfaces/reviews/markAsHelpful';
import { request } from '@shared/utils/requestUtils';
import { HttpModule } from '@shared/services/http/httpProtocol';
import { IAppEntityLinkedInProductGroupThunkActionParams } from '@shared/interfaces/linkedIn/models';

import type { TilesViewService } from '@src/server/viewAPIServices/tilesViewService';
import type { ServiceViewServices } from '@src/server/viewAPIServices/servicesViewService';
import type { RecommendationsViewService } from '@src/server/viewAPIServices/recommendationsViewService';
import type { CloudsIndustryViewService } from '@src/server/viewAPIServices/cloudsIndustryViewService';
import type { DetailsViewService } from '@src/server/viewAPIServices/detailsViewService';
import type { ServicesDetailsViewService } from '@src/server/viewAPIServices/servicesDetailsViewService';
import type { PricingViewService } from '@src/server/viewAPIServices/pricingViewService';
import { fetchUserFavourites } from './userFavouriteThunkActions';
import { BillingAccountType, BillingAgreementType, IBillingAccounts } from '@src/interfaces/IBilling';

let tilesViewService: TilesViewService;
let servicesViewService: ServiceViewServices;
let recommendationsViewService: RecommendationsViewService;
let cloudsIndustryViewService: CloudsIndustryViewService;
let detailsViewService: DetailsViewService;
let servicesDetailsViewService: ServicesDetailsViewService;
let pricingViewService: PricingViewService;

if (__SERVER__) {
  tilesViewService = require('@src/server/viewAPIServices/tilesViewService');
  servicesViewService = require('@src/server/viewAPIServices/servicesViewService');
  recommendationsViewService = require('@src/server/viewAPIServices/recommendationsViewService');
  cloudsIndustryViewService = require('@src/server/viewAPIServices/cloudsIndustryViewService');
  detailsViewService = require('@src/server/viewAPIServices/detailsViewService');
  servicesDetailsViewService = require('@src/server/viewAPIServices/servicesDetailsViewService');
  pricingViewService = require('@src/server/viewAPIServices/pricingViewService');
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function telemtry(action: string, actionModifier: string, details: Object) {
  const payload = {
    page: getWindow() && getWindow().location && getWindow().location.href,
    action,
    actionModifier,
    details: JSON.stringify(details),
  };
  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
  logger.info(payload.details, {
    action: payload.action,
    actionModifier: payload.actionModifier,
  });
}

function mockRequest({ state, query = {}, request: req = {} }: { state: IState; query?: IURLQuery; request?: Request }): Request {
  return request({
    correlationId: state.config.correlationId,
    requestId: state.config.requestId,
    context: { ...req.context },
    query: { ...query, [Constants.QueryStrings.flightCodes]: state.config.flightCodes },
  });
}

export function ensureAppsDataMap() {
  return async function (dispatch: Dispatch, getState: () => IState) {
    if (getState().apps.dataMapLoaded) {
      return Promise.resolve();
    }

    const embedHost = getProductByUrlKey({ urlKey: getState().config.embedHost })?.UrlKey;

    let dataMap$: Promise<ICommonDataMap>;
    if (__CLIENT__) {
      dataMap$ = entityRestClient.getAppsDataMap({ embedHost });
    } else {
      dataMap$ = tilesViewService.getAppsDataMap({
        request: mockRequest({ state: getState() }),
        embedHost,
      });
    }

    const dataMap = await dataMap$;
    dispatch(
      createDataMapReceivedAction({
        entityType: Constants.EntityType.App,
        dataMap,
      })
    );
    return dataMap;
  };
}

export function ensureCloudsIndustryDataMap() {
  return function (dispatch: Dispatch, getState: () => IState) {
    if (getState().cloudsIndustry.dataMapLoaded) {
      return Promise.resolve();
    }

    let dataMap$: Promise<IDataMap>;
    if (__CLIENT__) {
      dataMap$ = entityRestClient.getCloudsIndustryDataMap();
    } else {
      dataMap$ = cloudsIndustryViewService.getCloudsIndustryDataMap({
        request: mockRequest({ state: getState() }),
      });
    }

    return dataMap$.then((dataMap) => {
      dispatch(
        createDataMapReceivedAction({
          entityType: Constants.EntityType.CloudsIndustry,
          dataMap,
        })
      );

      return dataMap;
    });
  };
}

export function ensureServicesDataMap() {
  return function (dispatch: Dispatch, getState: () => IState) {
    if (getState().services.dataMapLoaded) {
      return Promise.resolve();
    }

    let dataMap$: Promise<ICommonDataMap>;
    if (__CLIENT__) {
      dataMap$ = entityRestClient.getServicesDataMap();
    } else {
      dataMap$ = servicesViewService.getServicesDataMap({
        request: mockRequest({ state: getState() }),
      });
    }

    return dataMap$.then((dataMap) => {
      dispatch(
        createDataMapReceivedAction({
          entityType: Constants.EntityType.Service,
          dataMap,
        })
      );

      return dataMap;
    });
  };
}

export function ensureServiceData({ request }: { request: Request }) {
  return function (dispatch: Dispatch, getState: () => IState) {
    const state = getState();
    const {
      search: { previousSearchText },
      config: { includeTestProducts, isConverged },
    } = state;

    const options = { includeTestProducts, isConverged };
    const requestId = (request.headers[Constants.Headers.RequestId] as string) || '';
    const userSegment = Constants.ServicesUserSegment;

    if (state.services.dataLoaded[`${userSegment}`]) {
      return Promise.resolve();
    }

    const urlQuery: IURLQuery = {
      ...getTileDataQueryParams(state.config.query),
      ...getActiveFeaturedFlags(state.config.featureFlags),
    };

    let getFilteredServices$: Promise<FilterDataResults<Service>>;
    if (__CLIENT__) {
      getFilteredServices$ = entityRestClient.getFilteredServices({
        urlQuery,
        requestId,
        options,
      });
    } else {
      getFilteredServices$ = servicesViewService.getFilteredServices({
        request: mockRequest({ state: getState(), query: urlQuery, request }),
        options,
      });
    }

    return getFilteredServices$.then((result) => {
      const { items, count, showingResultsFor = '' } = result;
      dispatch(
        createEntityDataReceivedAction({
          entityType: Constants.EntityType.Service,
          dataList: items,
          isEmbedded: state.config.isEmbedded,
          userSegment: Constants.ServicesUserSegment,
          count,
          urlQuery,
        })
      );
      // This is to ensure we don't accidently reset the showing results for due to the 3 searches we're doing: Apps, Consulting Services, Industry Clouds
      if (urlQuery.search !== previousSearchText && showingResultsFor.length > 0) {
        dispatch(createShowingResultsForReceivedAction({ showingResultsFor, previousSearchText: urlQuery.search }));
      }

      return result;
    });
  };
}

export function ensureServiceCountries() {
  return function (dispatch: Dispatch, getState: () => IState) {
    let countryDate = Promise.resolve();
    if (!Object.keys(getState().services.countriesList).length && getState().config.isAppSource) {
      countryDate = new Promise((resolve, reject) => {
        servicesViewService
          .getAllServicesCountries({ request: mockRequest({ state: getState() }) })
          .then((countries: ServiceCountries) => {
            dispatch(
              createServicesCountriesDataReceivedAction({
                countries,
              })
            );

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
    return countryDate;
  };
}

export function getUserAuthStatus() {
  return function (dispatch: any, getState: () => IState) {
    return new Promise((resolve, reject) => {
      const state = getState();
      if (state.config.embedHost) {
        return resolve(Constants.SigninStatus.ValidationSkipped);
      }

      if (state.users.accessToken && !shouldRefreshToken(state.users.accessToken.spza)) {
        // The token is still valid and not nearing expiry dont make a server call, let the flow continue.
        resolve(Constants.SigninStatus.ValidTokenUnknownCookie);
      } else {
        authRestClient
          .getUserAuthStatus()
          .then((result: any) => {
            // the token has expired and cookie is guest sign the user out from state
            if (result.status === Constants.SigninStatus.ExpiredTokenInvalidCookie) {
              dispatch(createUserSignOutAction(null));
            }
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  };
}

export function getAppReviews(entityId: string) {
  return async function (dispatch: any, getState: () => IState) {
    const {
      config: { reviewsAPI, reviewsAPIVersion, storefrontName: source },
      users: { signedIn },
    } = getState();
    const reviews$ = reviewAppReviewsRestClient.getAppReviews({ entityId, reviewsAPI, reviewsAPIVersion });
    const isPurchased$ = signedIn && reviewRestClient.getPurchaseStatus(entityId);
    const userReview$ =
      (signedIn && userReviewRestClient.get({ offerId: entityId, reviewsAPI, reviewsAPIVersion, source })) || Promise.resolve([]);
    const [reviews, isPurchased, [userReview]] = await Promise.all([reviews$, isPurchased$, userReview$]);
    return dispatch(
      createGetAppReviewsAction({
        entityId,
        reviews,
        isPurchased,
        userReview,
      })
    );
  };
}

export function ensureAppReviewsData(entityId: string, forceUpdate = false) {
  return function (dispatch: any, getState: () => IState): Promise<boolean> {
    if (!entityId) {
      return Promise.resolve(false);
    }

    const start = Date.now();

    if (shouldEnsureAppReviews(entityId, getState()) || forceUpdate) {
      return Promise.resolve(dispatch(getAppReviews(entityId))).catch((error) => {
        const payload = {
          page: getWindow() && getWindow().location.href,
          action: Constants.Telemetry.Action.EnsureAppReviewsData,
          actionModifier: Constants.Telemetry.ActionModifier.Error,
          details: JSON.stringify({ duration: Date.now() - start, error: stringifyError(error) }),
        };
        SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
        logger.error(payload.details, {
          action: payload.action,
          actionModifier: payload.actionModifier,
        });
        return false;
      });
    }

    return Promise.resolve(true);
  };
}

export function ensureAppData({ billingRegion, request }: { billingRegion: string; request: Request }) {
  return function (dispatch: Dispatch, getState: () => IState) {
    const state = getState();
    const {
      search: { previousSearchText },
    } = state;
    const requestId = (request.headers[Constants.Headers.RequestId] as string) || '';
    const userSegment = UserSegment[UserSegment.unauthenticated];

    if (state.apps.dataLoaded[`${userSegment}`]) {
      return Promise.resolve();
    }

    const { flightCodes, includeTestProducts, isConverged } = state.config;
    const options = { includeTestProducts, isConverged };

    const urlQuery: IURLQuery = {
      ...getTileDataQueryParams(state.config.query),
      ...getActiveFeaturedFlags(state.config.featureFlags),
    };

    const embedHost = getProductByUrlKey({ urlKey: state.config.embedHost })?.UrlKey;

    let getFilteredApps$: Promise<FilterDataResults<IAppDataItemBasicData>>;
    if (__CLIENT__) {
      getFilteredApps$ = entityRestClient.getFilteredApps({
        billingRegion,
        flightCodes,
        embedHost,
        urlQuery,
        requestId,
        options,
      });
    } else {
      getFilteredApps$ = tilesViewService.getFilteredApps({
        request: mockRequest({ state: getState(), query: urlQuery, request }),
        billingRegion,
        flightCodes,
        embedHost,
        options,
      });
    }

    return getFilteredApps$.then((result: FilterDataResults<IAppDataItemBasicData>) => {
      const { items, count, showingResultsFor = '' } = result;
      dispatch(
        createEntityDataReceivedAction({
          entityType: Constants.EntityType.App,
          dataList: items,
          isEmbedded: state.config.isEmbedded,
          userSegment,
          count,
          urlQuery,
        })
      );
      // This is to ensure we don't accidently reset the showing results for due to the 3 searches we're doing: Apps, Consulting Services, Industry Clouds
      if (urlQuery.search !== previousSearchText && showingResultsFor.length > 0) {
        dispatch(createShowingResultsForReceivedAction({ showingResultsFor, previousSearchText: urlQuery.search }));
      }

      return result;
    });
  };
}

export function ensureWhatsNewAppsRecommendationsData() {
  return async function (dispatch: Dispatch, getState: () => IState): Promise<RecommendedWhatsNewApps> {
    const action = Constants.Telemetry.Action.GetAppRecommendedItems;
    const start = Date.now();
    telemtry(action, Constants.Telemetry.ActionModifier.Start, {});

    try {
      const recommendedItems: RecommendedWhatsNewApps =
        (await recommendationsViewService.getWhatsNewRecommendedApps({
          request: mockRequest({ state: getState() }),
        })) || [];

      telemtry(action, Constants.Telemetry.ActionModifier.End, {
        duration: Date.now() - start,
        recommendedWhatsNewApps: recommendedItems?.map((item) => item.entityId),
      });

      dispatch(createRecommendedWhatsNewAppsReceivedAction(recommendedItems));

      return recommendedItems;
    } catch (error) {
      telemtry(action, Constants.Telemetry.ActionModifier.Error, {
        duration: Date.now() - start,
        error: stringifyError(error),
      });
    }
  };
}

export function ensureTrendingAppsRecommendationsData() {
  return async function (dispatch: Dispatch, getState: () => IState): Promise<RecommendedTrendingApps> {
    const action = Constants.Telemetry.Action.GetAppRecommendedItems;
    const start = Date.now();
    telemtry(action, Constants.Telemetry.ActionModifier.Start, {});

    try {
      const recommendedItems: RecommendedTrendingApps =
        (await recommendationsViewService.getTrendingRecommendedApps({
          request: mockRequest({ state: getState() }),
        })) || [];

      telemtry(action, Constants.Telemetry.ActionModifier.End, {
        duration: Date.now() - start,
        RecommendedTrendingApps: recommendedItems?.map((item) => item.entityId),
      });

      dispatch(createRecommendedTrendingAppsReceivedAction(recommendedItems));

      return recommendedItems;
    } catch (error) {
      telemtry(action, Constants.Telemetry.ActionModifier.Error, {
        duration: Date.now() - start,
        error: stringifyError(error),
      });
    }
  };
}

export function ensureWhatsNewServiceRecommendationsData() {
  return async function (dispatch: Dispatch, getState: () => IState): Promise<RecommendedWhatsNewService> {
    const action = Constants.Telemetry.Action.GetServiceRecommendedItems;
    const start = Date.now();
    telemtry(action, Constants.Telemetry.ActionModifier.Start, {});

    try {
      const request = mockRequest({ state: getState() });
      const recommendedItems: RecommendedWhatsNewService =
        (await recommendationsViewService.getWhatsNewRecommendedService({
          request,
          country: request.query[Constants.QueryStrings.country] as string,
          region: request.query[Constants.QueryStrings.region] as string,
        })) || [];

      telemtry(action, Constants.Telemetry.ActionModifier.End, {
        duration: Date.now() - start,
        recommendedWhatsNewService: recommendedItems?.map((item) => item.entityId),
      });

      dispatch(createRecommendedWhatsNewServiceReceivedAction(recommendedItems));

      return recommendedItems;
    } catch (error) {
      telemtry(action, Constants.Telemetry.ActionModifier.Error, {
        duration: Date.now() - start,
        error: stringifyError(error),
      });
    }
  };
}

export function ensureTrendingServiceRecommendationsData() {
  return async function (dispatch: Dispatch, getState: () => IState): Promise<RecommendedTrendingService> {
    const action = Constants.Telemetry.Action.GetServiceRecommendedItems;
    const start = Date.now();

    telemtry(action, Constants.Telemetry.ActionModifier.Start, {});

    try {
      const request = mockRequest({ state: getState() });
      const recommendedItems: RecommendedTrendingService =
        (await recommendationsViewService.getTrendingRecommendedService({
          request,
          country: request.query[Constants.QueryStrings.country] as string,
          region: request.query[Constants.QueryStrings.region] as string,
        })) || [];

      telemtry(action, Constants.Telemetry.ActionModifier.End, {
        duration: Date.now() - start,
        RecommendedTrendingService: recommendedItems?.map((item) => item.entityId),
      });

      dispatch(createRecommendedTrendingServiceReceivedAction(recommendedItems));

      return recommendedItems;
    } catch (error) {
      telemtry(action, Constants.Telemetry.ActionModifier.Error, {
        duration: Date.now() - start,
        error: stringifyError(error),
      });
    }
  };
}
export function ensureMicrosoft365WhatsNewAppsRecommendationsData() {
  return async function (dispatch: Dispatch, getState: () => IState): Promise<RecommendedMicrosoft365WhatsNewApps> {
    const action = Constants.Telemetry.Action.GetAppRecommendedItems;
    const start = Date.now();

    telemtry(action, Constants.Telemetry.ActionModifier.Start, {});

    try {
      const recommendedItems: RecommendedMicrosoft365WhatsNewApps =
        (await recommendationsViewService.getMicrosoft365WhatsNewRecommendedApps({
          request: mockRequest({ state: getState() }),
        })) || [];

      telemtry(action, Constants.Telemetry.ActionModifier.End, {
        duration: Date.now() - start,
        recommendedMicrosoft365WhatsNewApps: recommendedItems?.map((item) => item.entityId),
      });

      dispatch(createRecommendedMicrosoft365WhatsNewAppsReceivedAction(recommendedItems));

      return recommendedItems;
    } catch (error) {
      telemtry(action, Constants.Telemetry.ActionModifier.Error, {
        duration: Date.now() - start,
        error: stringifyError(error),
      });
    }
  };
}

export function ensureMicrosoft365TrendingAppsRecommendationsData() {
  return async function (dispatch: Dispatch, getState: () => IState): Promise<RecommendedMicrosoft365TrendingApps> {
    const action = Constants.Telemetry.Action.GetAppRecommendedItems;
    const start = Date.now();

    telemtry(action, Constants.Telemetry.ActionModifier.Start, {});

    try {
      const recommendedItems: RecommendedMicrosoft365TrendingApps =
        (await recommendationsViewService.getMicrosoft365TrendingRecommendedApps({
          request: mockRequest({ state: getState() }),
        })) || [];

      telemtry(action, Constants.Telemetry.ActionModifier.End, {
        duration: Date.now() - start,
        RecommendedTrendingApps: recommendedItems?.map((item) => item.entityId),
      });

      dispatch(createRecommendedMicrosoft365TrendingAppsReceivedAction(recommendedItems));

      return recommendedItems;
    } catch (error) {
      telemtry(action, Constants.Telemetry.ActionModifier.Error, {
        duration: Date.now() - start,
        error: stringifyError(error),
      });
    }
  };
}

function ensureRecommendationsData(dispatch: Dispatch): Promise<void>[] {
  return [
    dispatch(ensureWhatsNewAppsRecommendationsData()),
    dispatch(ensureTrendingAppsRecommendationsData()),
    dispatch(ensureWhatsNewServiceRecommendationsData()),
    dispatch(ensureTrendingServiceRecommendationsData()),
    dispatch(ensureMicrosoft365WhatsNewAppsRecommendationsData()),
    dispatch(ensureMicrosoft365TrendingAppsRecommendationsData()),
  ];
}

export function ensureCloudsIndustryData({ request }: { request: Request }) {
  return function (dispatch: Dispatch, getState: () => IState) {
    const state = getState();
    const {
      search: { previousSearchText },
    } = state;
    const requestId = (request.headers[Constants.Headers.RequestId] as string) || '';

    const userSegment = UserSegment[UserSegment.unauthenticated];

    if (state.apps.dataLoaded[`${userSegment}`]) {
      return Promise.resolve();
    }

    const urlQuery: IURLQuery = {
      ...getTileDataQueryParams(state.config.query),
      ...getActiveFeaturedFlags(state.config.featureFlags),
      [Constants.QueryStrings.UseSecondarySearch]: state.config.featureFlags.UseSecondarySearch,
    };

    let getFilteredCloudsIndustry$: Promise<FilterDataResults<ICloudsIndustry>>;
    if (__CLIENT__) {
      getFilteredCloudsIndustry$ = entityRestClient.getFilteredCloudsIndustry({ urlQuery, requestId });
    } else {
      getFilteredCloudsIndustry$ = cloudsIndustryViewService.getFilteredCloudsIndustry({
        request: mockRequest({ state: getState(), query: urlQuery, request }),
      });
    }

    return getFilteredCloudsIndustry$.then((result: FilterDataResults<ICloudsIndustry>) => {
      const { items, count, showingResultsFor = '' } = result;
      dispatch(
        createEntityDataReceivedAction({
          entityType: Constants.EntityType.CloudsIndustry,
          dataList: items,
          isEmbedded: false,
          userSegment,
          count,
          urlQuery,
        })
      );

      // This is to ensure we don't accidently reset the showing results for due to the 3 searches we're doing: Apps, Consulting Services, Industry Clouds
      if (urlQuery.search !== previousSearchText && showingResultsFor.length > 0) {
        dispatch(
          createShowingResultsForReceivedAction({
            showingResultsFor,
            previousSearchText: urlQuery.search,
          })
        );
      }

      return result;
    });
  };
}

export function fetchStateCacheData({ billingRegion, request }: { billingRegion: string; request: Request }) {
  return async function (dispatch: Dispatch, getState: () => IState) {
    const dataMaps = [dispatch(ensureAppsDataMap()), dispatch(ensureServicesDataMap())];
    if (getState().config.isAppSource && !getState().config.isEmbedded) {
      dataMaps.push(dispatch(ensureCloudsIndustryDataMap()));
    }
    await Promise.all(dataMaps);

    const offers = [dispatch(ensureAppData({ billingRegion, request }))];
    if (!getState().config.isEmbedded) {
      offers.push(dispatch(ensureServiceData({ request })));

      if (getState().config.isAppSource) {
        offers.push(dispatch(ensureCloudsIndustryData({ request })));
      }
    }
    await Promise.all(offers);

    await Promise.all(ensureRecommendationsData(dispatch));

    if (!getState().config.isEmbedded) {
      await dispatch(ensureServiceCountries());
    }
  };
}

function logSearchTelemetry(actionModifier: string, detailsObject: any) {
  const payload: ITelemetryData = {
    page: getWindow().location.href,
    action: Constants.Telemetry.Action.Search,
    actionModifier,
    details: JSON.stringify(detailsObject),
  };
  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
  logger.info(payload.details, {
    action: payload.action,
    actionModifier: payload.actionModifier,
  });
}

interface IFilteredSearchParams {
  entityType: Constants.FilteredSearchType;
  searchString: string;
  searchId: number;
  considerFilters: boolean;
  requestId: string;
}

type SearchPromisesParams = {
  entityType: Constants.FilteredSearchType;
  urlQuery: IURLQuery;
  requestId: string;
  state: IState;
};

const logSearchApiSuggestions = ({ details }: { details: string }) => {
  const payload: ITelemetryData = {
    page: getWindow().location.href,
    action: Constants.Telemetry.Action.NewSearchApi,
    actionModifier: Constants.Telemetry.ActionModifier.SearchApiSuggestions,
    details,
  };
  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
  logger.info(details, {
    action: payload.action,
    actionModifier: payload.actionModifier,
  });
};

const logSearchApiSuggestionsError = ({ details }: { details: string }): void => {
  const payload: ITelemetryData = {
    page: getWindow().location.href,
    action: Constants.Telemetry.Action.NewSearchApi,
    actionModifier: Constants.Telemetry.ActionModifier.SearchApiSuggestionsError,
    details,
  };
  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
  logger.error(details, {
    action: payload.action,
    actionModifier: payload.actionModifier,
  });
};

type SearchApiSuggestionsPayload = {
  requestId: string;
  searchText: string;
  language: string;
};

export const extractSuggestionsResults = ({
  suggestions = { totalCount: 0, value: [] },
}: {
  suggestions: suggestionsRestClient.SuggestionsResponse;
}): { wordSearches: string[]; entityIds: string[] } => {
  let wordSearches: string[];
  let entityIds: string[];

  suggestions.value.forEach((suggestion) => {
    if (suggestion.suggestionType === Constants.Search.SuggestionTypes.Entity) {
      entityIds = [...entityIds, suggestion.id];
    }
    if (suggestion.suggestionType === Constants.Search.SuggestionTypes.WordSearch) {
      wordSearches = [...wordSearches, suggestion.displayText];
    }
  });
  return { wordSearches, entityIds };
};

export function callSearchApiSuggestionsAction({ language, searchText, requestId }: SearchApiSuggestionsPayload) {
  return function (dispatch: Dispatch, getState: () => IState) {
    const {
      config: { searchApiEndpoint, storefrontName, siteEnvironment },
    } = getState();
    const appName = `${storefrontName}-${siteEnvironment}`;

    suggestionsRestClient
      .callSearchApiSuggestions({ searchText, language, endpoint: searchApiEndpoint, requestId, appName })
      .then((suggestions) => {
        const { wordSearches, entityIds } = extractSuggestionsResults({ suggestions });

        const details = JSON.stringify({
          searchText,
          totalCount: suggestions.totalCount,
          totalWordSearch: wordSearches.length,
          totalEntity: entityIds.length,
          wordSearches,
          entityIds,
          language,
          requestId,
        });

        logSearchApiSuggestions({ details });
      })
      .catch((error) => {
        logSearchApiSuggestionsError({
          details: JSON.stringify({
            searchText,
            requestId,
            error,
          }),
        });
      });
  };
}

const extractSearchPromises = ({ entityType, urlQuery, requestId, state }: SearchPromisesParams) => {
  const emptyResults = Promise.resolve({ items: [], count: 0, requestId: Constants.ReservedCorrelationIds.EmptyId });

  const appsResults$: Promise<FilterDataResults<IAppDataItem>> =
    entityType === Constants.FilteredSearchType.All || entityType === Constants.FilteredSearchType.Apps
      ? entityRestClient.getFilteredApps({ urlQuery, searchActionType: Constants.Search.ActionType.Suggestions, requestId })
      : emptyResults;

  const servicesResults$: Promise<FilterDataResults<Service>> =
    entityType === Constants.FilteredSearchType.All || entityType === Constants.FilteredSearchType.Services
      ? entityRestClient.getFilteredServices({ urlQuery, searchActionType: Constants.Search.ActionType.Suggestions, requestId })
      : emptyResults;

  const cloudsIndustryResults$: Promise<FilterDataResults<IAppDataItem>> =
    state.config.isAppSource &&
    (entityType === Constants.FilteredSearchType.All || entityType === Constants.FilteredSearchType.CloudsIndustry)
      ? entityRestClient.getFilteredCloudsIndustry({
          urlQuery,
          searchActionType: Constants.Search.ActionType.Suggestions,
          requestId,
        })
      : emptyResults;
  return { appsResults$, servicesResults$, cloudsIndustryResults$ };
};

export function performFilteredSearch({ entityType, searchString, searchId, considerFilters, requestId }: IFilteredSearchParams) {
  return async function (dispatch: Dispatch, getState: () => IState) {
    const startTime = Date.now();
    let searchPhase: Constants.FilteredSearchPhase = Constants.FilteredSearchPhase.Started;

    const resultsString = Constants.searchTypeString.AppsAndServices;

    searchString = getSearchQuery(searchString);

    dispatch(createSearchboxInputChangedAction({ searchString }));

    const state = getState();
    const urlQuery: IURLQuery = {
      ...(considerFilters ? state.config.requestFilteredQuery : {}),
      [Constants.TileDataAdditionalQueryParams.country]: state.services.selectedCountry,
      [Constants.TileDataAdditionalQueryParams.region]: state.services.selectedRegion,
      [Constants.TileDataAdditionalQueryParams.page]: '1',
      [Constants.TileDataFilteredQueryParams.search]: searchString,
      ...getActiveFeaturedFlags(state.config.featureFlags),
    };

    const {
      appsResults$,
      servicesResults$,
      cloudsIndustryResults$,
    }: {
      appsResults$: Promise<FilterDataResults<IAppDataItem>>;
      servicesResults$: Promise<FilterDataResults<Service>>;
      cloudsIndustryResults$: Promise<FilterDataResults<ICloudsIndustry>>;
    } = extractSearchPromises({ entityType, urlQuery, requestId, state });

    return Promise.all([appsResults$, servicesResults$, cloudsIndustryResults$])
      .then(
        ([appsResults, servicesResults, cloudsIndustryResults]: [
          FilterDataResults<IAppDataItem>,
          FilterDataResults<Service>,
          FilterDataResults<IAppDataItem>
        ]) => {
          searchPhase = Constants.FilteredSearchPhase.SearchResultsReturned;
          const duration = Date.now() - startTime;
          let bundleCount = 0;

          let apps = appsResults.items;
          const services = servicesResults.items;
          const cloudsIndustry = cloudsIndustryResults.items;

          const results = removeDuplicateAddIns<IAppDataItem>(apps, 'entityId');
          apps = results.apps;
          bundleCount = results.bundleCount;
          searchPhase = Constants.FilteredSearchPhase.DuplicateAddInsRemoved;
          const requestId = appsResults.requestId || servicesResults.requestId || cloudsIndustryResults.requestId;
          const searchResponseId = appsResults.searchResponseId;

          dispatch(
            createFilteredSearchResultsReceivedAction({
              appSearchResults: apps,
              servicesSearchResults: services,
              cloudsIndustrySearchResults: cloudsIndustry,
              appsCount: appsResults.count - results.duplicateAddInsCount,
              servicesCount: servicesResults.count,
              cloudsIndustryCount: cloudsIndustryResults.count,
              searchid: searchId + 1,
              requestId,
              searchResponseId,
            })
          );
          searchPhase = Constants.FilteredSearchPhase.SearchActionCreated;

          logSearchTelemetry(Constants.Telemetry.ActionModifier.Suggestions, {
            results: resultsString,
            searchTerm: searchString,
            appResults: apps?.length || 0,
            servicesResults: services?.length || 0,
            cloudsIndustry: cloudsIndustry?.length || 0,
            bundleAppResults: bundleCount,
            duration,
            origin: apps[0]?.origin || services[0]?.origin || cloudsIndustry[0]?.origin || null,
            topResults: takeTopResults({ apps, services, cloudsIndustry }),
            requestId,
            searchResponseId,
          });

          searchPhase = Constants.FilteredSearchPhase.SearchTelemetryLogged;

          return [apps, services, cloudsIndustry];
        }
      )
      .catch((error: any) => {
        logSearchTelemetry(Constants.Telemetry.ActionModifier.Error, {
          results: resultsString,
          searchTerm: searchString,
          duration: Date.now() - startTime,
          error: stringifyError(error),
          responseError: error.response?.body,
          searchPhase,
        });

        return [[], [], []];
      });
  };
}

/** Get product from ARM, for private OR for public. If private or both do cannot be found, this means one of the following:
 * 1. There's an issue with catalog and ingestion (probably ICM)
 * 2. It's a preview offer, in that case there should be flightcode with the request
 * */
export function getProduct(entityId: string, market: string) {
  return async function (dispatch: Dispatch, getState: () => IState) {
    dispatch(createFetchingProductAction());
    let product;
    try {
      const mprpAppData = await armRestClient.getPrivateOrPublicPublicId(entityId, market); // Catches restricted audience
      product = new Product(mprpAppData);
    } catch (ex) {
      // Search for preview offer in CatalogApi with flightCode.
      // Call from armRestClient doesn't accept flight code so we have to call catalogApi directly.
      // This is relevant for publishers who want to test the preview without adding themselves to the restricted audience list for preview
      const flightCodes = getState().config.flightCodes;

      const mprpAppData = await catalogApiRestClient.getProduct(entityId, flightCodes, market);
      product = new Product(mprpAppData);
    } finally {
      dispatch(createProduct({ id: entityId, product }));
    }
  };
}

export function performPartnersSearch(searchString: string) {
  return async function (dispatch: any, getState: () => IState) {
    const startTime = Date.now();
    const searchPhase: Constants.FilteredSearchPhase = Constants.FilteredSearchPhase.Started;

    const resultsString = Constants.searchTypeString.Partners;

    searchString = getSearchQuery(searchString);

    dispatch(createSearchboxInputChangedAction({ searchString }));

    if (searchString.length < 2) {
      return;
    }

    const partnerSearchResponse: IPartnerSearchResponse = await entityRestClient
      .searchPartners(searchString, getState().config.locale || Constants.DefaultLocale, getState().config.partnersApiHost)
      .catch((error: any) => {
        logSearchTelemetry(Constants.Telemetry.ActionModifier.Error, {
          results: resultsString,
          searchTerm: searchString,
          duration: Date.now() - startTime,
          error: stringifyError(error),
          searchPhase,
        });

        return [[], [], []];
      });
    let partnerSearchResults: IPartnerSearchResult[] = [];
    if (partnerSearchResponse && partnerSearchResponse.suggestions) {
      partnerSearchResults = partnerSearchResponse.suggestions.map((suggestion) => {
        return {
          id: suggestion.val,
          logo: undefined,
          text: suggestion.text,
          type: Constants.searchItemType.partner,
          original: {
            type: suggestion.type,
            val: suggestion.val,
            text: suggestion.text,
          },
        };
      });
    }
    dispatch(
      createPartnerSearchResultsReceivedAction({
        partnerSearchResults,
      })
    );
  };
}

export function performSearchAll(
  rawSearchText: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  searchSortingOption: Constants.SearchSortingOption = Constants.SearchSortingOption.BestMatch
) {
  return function (dispatch: any) {
    return dispatchSearchQueryChangedAction(dispatch, rawSearchText);
  };
}

export function getAppPricing(entityId: string) {
  return async function (dispatch: Dispatch, getState: () => IState) {
    const { isEmbedded, billingCountryCode: billingRegion, flightCodes, featureFlags } = getState().config;
    const urlQuery = getActiveFeaturedFlags(featureFlags);

    let appPricing$: Promise<IAppPricing>;
    if (__CLIENT__) {
      appPricing$ = entityRestClient.getAppPricing({ entityId, isEmbedded, flightCodes, billingRegion, urlQuery });
    } else {
      appPricing$ = pricingViewService.getAppDetailPricing({
        request: mockRequest({ state: getState() }),
        entityId,
        isEmbedded,
        billingRegion,
        flightCodes,
        featureFlags,
      });
    }

    let futurePrices$: Promise<IFuturePrices>;
    if (__CLIENT__) {
      futurePrices$ = entityRestClient.getFuturePrices({ entityId, isEmbedded, flightCodes, billingRegion });
    } else {
      futurePrices$ = pricingViewService.getAppDetailFuturePricing({
        request: mockRequest({ state: getState() }),
        entityId,
        isEmbedded,
        billingRegion,
        flightCodes,
        featureFlags,
      });
    }

    const [pricing, futurePrices] = await Promise.all([
      appPricing$.catch((error) => {
        dispatch(
          createAppDetailPricingReceivedAction({
            entityId,
            pricing: {
              billingRegion,
            },
            futurePrices: null,
          })
        );
        throw error;
      }),
      futurePrices$.catch(() => {
        // If the request for future price failed the PDP will be load without future price.
        return null;
      }),
    ]);
    dispatch(
      createAppDetailPricingReceivedAction({
        entityId,
        pricing,
        futurePrices,
      })
    );
    return [pricing, futurePrices];
  };
}

export function getAppDetail(entityId: string, isPrivate: boolean) {
  return async function (dispatch: Dispatch, getState: () => IState) {
    const { billingCountryCode: billingRegion, flightCodes } = getState().config;

    let entityDetails$: Promise<IAppDataItemBasicData>;
    if (isPrivate) {
      entityDetails$ = ensurePrivateOffer(entityId, billingRegion, getState);
    } else {
      if (__CLIENT__) {
        entityDetails$ = entityRestClient.getApp({
          entityId,
          flightCodes,
        });
      } else {
        entityDetails$ = detailsViewService.getAppDetailView({
          entityId,
          flightCodes,
          request: mockRequest({ state: getState() }),
        });
      }
    }

    const entityDetails = await entityDetails$;
    if (isPrivate) {
      dispatch(
        createEntityFullDataReceivedAction({
          entityType: Constants.EntityType.App,
          entityDetails,
        })
      );
    } else {
      dispatch(
        createEntityDetailsReceivedAction({
          entityType: Constants.EntityType.App,
          entityDetails,
        })
      );
      dispatch(
        createEntityDetailsReceivedAction({
          entityType: Constants.EntityType.CloudsIndustry,
          entityDetails,
        })
      );

      if (getState().apps.pricingPayload) {
        dispatch(createAppPricingDataReceivedAction({ entityId, pricingPayload: getState().apps.pricingPayload }));
      }
    }
    return entityDetails;
  };
}

export function getAppRelatedAppsItems(entityId: string) {
  return async function (dispatch: any, getState: () => IState): Promise<IRelatedItems> {
    const action = Constants.Telemetry.Action.GetAppSuggestedItems;

    const start = Date.now();

    const embedHost = getProductByUrlKey({ urlKey: getState().config.embedHost })?.UrlKey;

    telemtry(action, Constants.Telemetry.ActionModifier.Start, { entityId, embedHost });

    dispatch(
      createRelatedAppsItemsLoadingAction({
        entityType: Constants.EntityType.App,
        entityId,
      })
    );

    let relatedAppsItems$: Promise<IRelatedItems>;
    if (__CLIENT__) {
      relatedAppsItems$ = entityRestClient.getAppRelatedAppsItems(entityId, embedHost);
    } else {
      relatedAppsItems$ = tilesViewService.getAppRelatedAppsItemsView({
        entityId,
        embedHost,
        request: mockRequest({ state: getState() }),
        flightCodes: getState().config.flightCodes,
      });
    }

    try {
      const relatedAppsItems = (await relatedAppsItems$) || { saasLinkingItems: [], suggestedItems: [], linkedAddIns: [] };

      telemtry(action, Constants.Telemetry.ActionModifier.End, {
        duration: Date.now() - start,
        entityId,
        suggestedItems: relatedAppsItems.suggestedItems?.map((item) => item.entityId),
        saasLinkingItems: relatedAppsItems.saasLinkingItems?.map((item) => item.entityId),
        linkedAddIns: relatedAppsItems.linkedAddIns?.map((item) => item.entityId),
      });

      dispatch(
        createRelatedAppsItemsReceivedAction({
          entityType: Constants.EntityType.App,
          entityId,
          relatedAppsItems,
        })
      );

      if (getState().apps.pricingPayload) {
        dispatch(createRelatedAppsItemsPricingDataReceivedAction(getState().apps.pricingPayload));
      }
      return relatedAppsItems;
    } catch (error) {
      telemtry(action, Constants.Telemetry.ActionModifier.Error, {
        duration: Date.now() - start,
        entityId,
        error: stringifyError(error),
      });

      throw error;
    }
  };
}

export function getServiceRelatedAppsItems(entityId: string) {
  return async function (dispatch: any, getState: () => IState): Promise<IRelatedItems> {
    const start = Date.now();

    const action = Constants.Telemetry.Action.GetServiceSuggestedItems;

    const query = getState().config.query;

    const country = extractCountryCodeFromQueryParam(query);
    const region = (query && query.region) || getState().services.selectedRegion;

    telemtry(action, Constants.Telemetry.ActionModifier.Start, { entityId });

    dispatch(
      createRelatedAppsItemsLoadingAction({
        entityType: Constants.EntityType.Service,
        entityId,
      })
    );

    try {
      let relatedAppsItems$: Promise<IRelatedItems>;
      if (__CLIENT__) {
        relatedAppsItems$ = entityRestClient.getServiceRelatedAppsItems(entityId, country, region);
      } else {
        relatedAppsItems$ = servicesViewService.getServiceSuggestedItemsView({
          country,
          region,
          entityId,
          request: mockRequest({ state: getState() }),
        });
      }
      const relatedAppsItems = (await relatedAppsItems$) || { saasLinkingItems: [], suggestedItems: [], linkedAddIns: [] };

      telemtry(action, Constants.Telemetry.ActionModifier.End, {
        duration: Date.now() - start,
        entityId,
        suggestedItems: relatedAppsItems.suggestedItems?.map((item) => item.entityId),
        saasLinkingItems: relatedAppsItems.saasLinkingItems?.map((item) => item.entityId),
        linkedAddIns: relatedAppsItems.linkedAddIns?.map((item) => item.entityId),
      });

      dispatch(
        createRelatedAppsItemsReceivedAction({
          entityType: Constants.EntityType.Service,
          entityId,
          relatedAppsItems,
        })
      );
      return relatedAppsItems;
    } catch (error) {
      telemtry(action, Constants.Telemetry.ActionModifier.Error, {
        duration: Date.now() - start,
        entityId,
        error: stringifyError(error),
      });

      throw error;
    }
  };
}

export function changeBillingRegion({
  countryCode,
  shouldSetCountyCodeCookie = true,
}: {
  countryCode?: string;
  shouldSetCountyCodeCookie?: boolean;
}) {
  return async (dispatch: Dispatch, getState: () => IState) => {
    const { billingCountryCode, pricingDataLoaded, featureFlags } = getState().config;
    const urlQuery = getActiveFeaturedFlags(featureFlags);

    if (!countryCode) {
      countryCode = getCookieItem(Constants.Cookies.billingRegionCookie, false) || AzureEnvironmentSettings.defaultCountryCode;
      dispatch(createBillingRegionReadFromCookieAction({ billingRegion: countryCode }));
    }

    if (countryCode === billingCountryCode && pricingDataLoaded) {
      return;
    }

    dispatch(createAppPricingDataRequestedAction(null));
    try {
      const appsPricings = await entityRestClient.getAllAppsPricing({
        billingRegion: countryCode,
        urlQuery,
      });

      const { billingRegion, startingPrices } = appsPricings || {};

      if (billingRegion && shouldSetCountyCodeCookie) {
        saveCookieItem(Constants.Cookies.billingRegionCookie, billingRegion);
      }

      const pricingPayload: IAppsPricingsPayload = {
        pricingData: startingPrices,
        countryCode: billingRegion,
      };

      dispatch(createAppsPricingDataReceivedAction(pricingPayload));
      dispatch(createFavouriteAppsPricingDataReceivedAction(pricingPayload));
      dispatch(createRelatedAppsItemsPricingDataReceivedAction(pricingPayload));
      dispatch(createRecommendedAppsItemsPricingDataReceivedAction(pricingPayload));

      return appsPricings;
    } catch (error) {
      const pricingPayload: IAppsPricingsPayload = {
        pricingData: {},
        countryCode,
      };

      dispatch(createAppsPricingDataReceivedAction(pricingPayload));
      dispatch(createRecommendedAppsItemsPricingDataReceivedAction(pricingPayload));
    }
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function updateUserInfoForEmbed(userInfo: IUserInfo): IUserInfo {
  const cookie = getCookieItem('appsourcelead');
  let isAlternateEmailFromCookie = false;

  if (cookie && cookie.alternateEmail && cookie.email) {
    const userEmail = userInfo && userInfo.email ? userInfo.email.toLowerCase() : ' ';

    // If userInfo already has alternateEmail (from graph call on server), do NOT overwrite it here
    // also ensure that cookie's email is matching useremail to avoid scenario where user signs in into other sites that updated the cookie to some MSA
    if (!userInfo.alternateEmail && userEmail === cookie.email.toLowerCase()) {
      userInfo.alternateEmail = cookie.alternateEmail;
      isAlternateEmailFromCookie = true;
    }
  }

  const payload: ITelemetryData = {
    page: getWindow().location.href,
    action: Constants.Telemetry.Action.PageLoad,
    actionModifier: Constants.Telemetry.ActionModifier.CookieData,
    details: JSON.stringify({
      alternateEmailExists: !!(cookie && cookie.alternateEmail),
      emailHasOnMicrosoft: userInfo && userInfo.email ? isOnMicrosoftEmail(userInfo.email) : false,
      alternateEmailFromCookie: isAlternateEmailFromCookie,
    }),
  };
  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
  logger.info(payload.details, {
    action: payload.action,
    actionModifier: payload.actionModifier,
  });
  return userInfo;
}

export function sendLead(payload: ILeadPayload) {
  return async function () {
    const requestId = generateGuid();
    telemtry(Constants.Telemetry.Action.SendLead, Constants.Telemetry.ActionModifier.InProgress, {
      payload: scrubLeadPayload(payload),
      requestId,
    });
    const startTime = Date.now();

    try {
      await leadgenRestClient.sendLead(payload, { requestId });
      telemtry(Constants.Telemetry.Action.SendLead, Constants.Telemetry.ActionModifier.End, {
        duration: Date.now() - startTime,
        requestId,
      });
    } catch (error) {
      const errorMessage = stringifyError(error);
      telemtry(Constants.Telemetry.Action.SendLead, Constants.Telemetry.ActionModifier.Error, {
        errorMessage,
        duration: Date.now() - startTime,
        requestId,
      });
      throw new Error(errorMessage);
    }
  };
}

export function sendLeadInfo({ item, ctaType, notes, planId }: SendLeadInfoOptions) {
  return function (dispatch: any, getState: () => IState) {
    try {
      telemtry(Constants.Telemetry.Action.SendLeadInfo, Constants.Telemetry.ActionModifier.Start, {});

      if (!item) {
        throw new Error(Constants.Telemetry.LeadsDropReason.ItemNotAvailable);
      }

      const isService = item.entityType === Constants.EntityType.Service;
      const state = getState();
      const userState = state.users;

      const customerInfo: ILeadCustomerInfo = {
        firstName: userState.profile.firstName,
        lastName: userState.profile.lastName,
        phone: userState.profile.phone,
        country: userState.profile.country,
        company: userState.profile.company,
        title: userState.profile.title,
      };

      const { landingView, currentView, isEmbedded, embedHost } = state.config;
      const url: string = getWindow().location.href;
      const campaigns: ICampaigns = getCampaignsForAcquisitionFromUrl({
        url,
        landingView,
        currentView,
        previousPage,
        routes,
        entityId: item.entityId,
      });

      const privateApp = isService ? false : (item as IAppDataItem)?.privateApp;
      const actionCode = isService ? Constants.ActionCodes.ConsultingServices : undefined;

      const payload = createLeadPayload({
        entityId: item.entityId,
        privateApp,
        ctaType,
        actionCode,
        campaigns,
        customerInfo,
        notes,
        uniquePlanId: planId,
        isEmbedded,
        embedHost,
      });

      return Promise.all([dispatch(sendLead(payload)), dispatch(createClearUserPropertiesAction(null))]);
    } catch (error) {
      telemtry(Constants.Telemetry.Action.SendLeadInfo, Constants.Telemetry.ActionModifier.Error, { error });
      throw error;
    }
  };
}

export function loadUserProductReviewMetadata({ legacyId }: { legacyId: string }) {
  return async function (dispatch: any, getState: () => IState) {
    const {
      reviewsAPI,
      reviewsAPIVersion,
      featureFlags: { ReviewsMyCommentsFilter },
    } = getState().config;
    if (ReviewsMyCommentsFilter === 'true') {
      try {
        const { commentReviewIds } = await reviewsCatalogRestClient.getReviewsMetadata({
          reviewsAPI,
          reviewsAPIVersion,
          legacyId,
        });
        dispatch(createEntityUserCommentedReviewIdsUpdateListAction({ commentReviewIds }));
      } catch (error) {
        launchReviewsTelemetry(
          Constants.Telemetry.Action.Error,
          Constants.Telemetry.ActionModifier.ReviewItemCommentedReviewIds,
          {
            error,
          }
        );
        logger.error(stringifyError(error), {
          action: Constants.Telemetry.Action.Error,
          actionModifier: Constants.Telemetry.ActionModifier.ReviewItemCommentedReviewIds,
        });
        dispatch(createEntityUserCommentedReviewIdsUpdateListAction({ commentReviewIds: [] }));
      }
    }
  };
}

export function getReviews(authMethod: string, authKey: string, entityId: string) {
  return function () {
    return reviewRestClient.get(authMethod, authKey, entityId);
  };
}

export function deleteReview(reviewFormData: UserReviewFormData) {
  return function (dispatch: any, getState: () => IState) {
    const { reviewsAPI, reviewsAPIVersion } = getState().config;
    const { reviewId, offerId } = reviewFormData;
    return userReviewRestClient
      .remove({ reviewsAPI, reviewsAPIVersion, reviewId })
      .then(() => dispatch(getAppReviews(offerId)), dispatch(loadUserProductReviewMetadata({ legacyId: offerId })));
  };
}

export function submitReview(reviewFormData: UserReviewFormData) {
  return function (dispatch: any, getState: () => IState) {
    const { reviewsAPI, reviewsAPIVersion, storefrontName: source } = getState().config;
    const { offerId } = reviewFormData;
    return userReviewRestClient
      .submit({ reviewsAPI, reviewsAPIVersion, ...reviewFormData, source })
      .then(() => dispatch(getAppReviews(offerId)));
  };
}

export function updateReview(reviewFormData: UserReviewFormData) {
  return function (dispatch: any, getState: () => IState) {
    const { reviewsAPI, reviewsAPIVersion } = getState().config;
    const { offerId } = reviewFormData;
    return userReviewRestClient
      .update({ reviewsAPI, reviewsAPIVersion, ...reviewFormData })
      .then(() => dispatch(getAppReviews(offerId)));
  };
}

export function getReportEmbedToken(reportData: any) {
  return function () {
    return fieldHubRestClient.generateReportEmbedToken(reportData);
  };
}

// for appsource, we do not support field hub yet
export function updateFieldHubUserType() {
  return function () {
    return Promise.resolve();
  };
}

export function getFilteredServiceData() {
  return function () {
    return Promise.resolve();
  };
}

interface GetAppReviewCommentCollectionArgs {
  offerId: string;
  reviewId: string;
  page: number;
  itemsPerPage: number;
}
export function getAppReviewCommentCollection({
  offerId,
  reviewId,
  page,
  itemsPerPage,
}: GetAppReviewCommentCollectionArgs): (
  dispatch: Dispatch<Action<IAppsReviewCommentBaseCrudOperationPayload>>,
  getState: () => any
) => Promise<void> {
  return async function (
    dispatch: Dispatch<Action<IAppsReviewCommentBaseCrudOperationPayload>>,
    getState: () => IState
  ): Promise<void> {
    const { reviewsAPI, reviewsAPIVersion } = getState().config;

    dispatch(
      createEntityReviewCommentsLoadingAction({
        offerId,
        reviewId,
        isLoading: true,
      })
    );

    const res = await appReviewCommentsRestClient.getAppReviewComments({
      offerId,
      reviewId,
      page,
      itemsPerPage,
      reviewsAPI,
      reviewsAPIVersion,
    });

    dispatch(
      createEntityReviewCommentsUpdateTotalCommentCountAction({
        reviewId,
        offerId,
        totalComments: res.totalComments,
      })
    );

    dispatch(
      createEntityReviewCommentsReceivedAction({
        offerId,
        reviewId,
        comments: res.comments,
        pageNumber: page,
      })
    );

    dispatch(
      createEntityReviewCommentsLoadingAction({
        offerId,
        reviewId,
        isLoading: false,
      })
    );
  };
}

interface ClearAppReviewCommentCollectionArgs {
  offerId: string;
  reviewId: string;
}
export function clearAppReviewCommentCollection({ offerId, reviewId }: ClearAppReviewCommentCollectionArgs) {
  return function (dispatch: Dispatch<Action<IAppsReviewCommentBaseCrudOperationPayload>>) {
    dispatch(
      createEntityReviewCommentsCleanupAction({
        reviewId,
        offerId,
      })
    );
  };
}

interface EnsureAppReviewCommentCollectionDataArgs {
  offerId: string;
  reviewId: string;
  page: number;
  itemsPerPage: number;
}
export function ensureAppReviewCommentCollectionData({
  offerId,
  reviewId,
  page,
  itemsPerPage,
}: EnsureAppReviewCommentCollectionDataArgs) {
  return function (dispatch: any) {
    dispatch(getAppReviewCommentCollection({ offerId, reviewId, page, itemsPerPage }));
  };
}

interface EnsureAppReviewCommentCollectionStateInitialized {
  offerId: string;
  reviewId: string;
  totalComments: number;
}
export function ensureAppReviewCommentCollectionStateInitialized({
  offerId,
  reviewId,
  totalComments,
}: EnsureAppReviewCommentCollectionStateInitialized) {
  return function (dispatch: any) {
    dispatch(
      createEntityReviewCommentsInitializeStateAction({
        offerId,
        reviewId,
        totalComments,
      })
    );
  };
}

interface EnsureClearAppReviewCommentCollectionDataArgs {
  offerId: string;
  reviewId: string;
}
export function ensureClearAppReviewCommentCollectionData({ offerId, reviewId }: EnsureClearAppReviewCommentCollectionDataArgs) {
  return function (dispatch: any) {
    dispatch(clearAppReviewCommentCollection({ offerId, reviewId }));
  };
}

export function createAppReviewComment({
  offerId,
  reviewId,
  commentContent,
  isNewlyAddedComment = false,
}: AppReviewCommentThunkActionBaseArgs) {
  return async function (dispatch: any, getState: () => IState) {
    const { reviewsAPI, reviewsAPIVersion, storefrontName: source } = getState().config;

    dispatch(
      createEntityReviewCommentModalAction({
        showModal: true,
        modalMode: Constants.Reviews.ReviewCommentModalMode.Loading,
      })
    );
    const actionResult = await appReviewCommentsRestClient.createReviewComment({
      offerId,
      reviewId,
      commentContent,
      reviewsAPI,
      reviewsAPIVersion,
      source,
    });
    dispatch(
      createEntityReviewCommentCreateResult({
        reviewId,
        offerId,
        actionResult,
        isNewlyAddedComment,
      })
    );
    if (!actionResult || actionResult.error) {
      dispatch(
        createEntityReviewCommentModalAction({
          showModal: true,
          modalMode:
            actionResult.error === Constants.Reviews.ReviewCommentActionError.ContentViolation
              ? Constants.Reviews.ReviewCommentModalMode.ContentViolation
              : Constants.Reviews.ReviewCommentModalMode.CreateError,
        })
      );
    } else {
      // Hide loader
      dispatch(
        createEntityReviewCommentModalAction({
          showModal: false,
          modalMode: Constants.Reviews.ReviewCommentModalMode.Loading,
        })
      );
      dispatch(loadUserProductReviewMetadata({ legacyId: offerId }));
    }
    return actionResult;
  };
}

export function deleteReviewComment({
  offerId,
  reviewId,
  commentId,
  isNewlyAddedComment = false,
}: AppReviewCommentThunkActionBaseArgs) {
  return async function (dispatch: any, getState: () => IState) {
    const { reviewsAPI, reviewsAPIVersion } = getState().config;
    const actionResult = await appReviewCommentsRestClient.deleteReviewComment({
      offerId,
      reviewId,
      commentId,
      reviewsAPI,
      reviewsAPIVersion,
    });
    dispatch(
      createEntityReviewCommentDeleteResult({
        reviewId,
        offerId,
        actionResult,
        isNewlyAddedComment,
      })
    );
    dispatch(loadUserProductReviewMetadata({ legacyId: offerId }));

    return actionResult;
  };
}

export function updateReviewComment({
  offerId,
  reviewId,
  commentId,
  commentContent,
  isNewlyAddedComment = false,
}: AppReviewCommentThunkActionBaseArgs) {
  return async function (dispatch: any, getState: () => IState) {
    const { reviewsAPI, reviewsAPIVersion } = getState().config;

    const actionResult = await appReviewCommentsRestClient.updateReviewComment({
      offerId,
      reviewId,
      commentId,
      commentContent,
      reviewsAPI,
      reviewsAPIVersion,
    });
    dispatch(
      createEntityReviewCommentUpdateResult({
        reviewId,
        offerId,
        actionResult,
        isNewlyAddedComment,
      })
    );
    return actionResult;
  };
}

export function loadUserMarkedAsHelpfulReviews({ offerId }: IAppEntityMarkAsHelpfulBaseThunkActionParams) {
  return async function (dispatch: any, getState: () => IState) {
    const { reviewsAPI, reviewsAPIVersion } = getState().config;
    const { reviewIds, error } = await appReviewMarkAsHelpfulRestClient.getUserReviewsMarkedAsHelpful({
      reviewsAPI,
      reviewsAPIVersion,
      offerId,
    });
    if (!error) {
      dispatch(
        createEntityMarkedAsHelpfulReviewUpdateListAction({
          offerId,
          reviewIds,
        })
      );
    } else {
      launchReviewsTelemetry(Constants.Telemetry.Action.Error, Constants.Telemetry.ActionModifier.ReviewsGetMarkedAsHelpful, {
        error,
      });
      logger.error(JSON.stringify({ error }), {
        action: Constants.Telemetry.Action.Error,
        actionModifier: Constants.Telemetry.ActionModifier.ReviewsGetMarkedAsHelpful,
      });
    }
  };
}

export function loadLinkedInProductGroup({ legacyOfferId }: IAppEntityLinkedInProductGroupThunkActionParams) {
  return async function (dispatch: Dispatch, getState: () => IState) {
    const { linkedinAPI, linkedinAPIVersion } = getState().config;
    try {
      const { linkedInProductGroup } = await linkedInRestClient.getLinkedInProductgroupInfo({
        legacyOfferId,
        linkedinAPI,
        linkedinAPIVersion,
      });
      dispatch(
        createEntityLinkedInProductGroupUpdateAction({
          legacyOfferId,
          linkedInProductGroup,
        })
      );
    } catch (error) {
      launchReviewsTelemetry(Constants.Telemetry.Action.Error, Constants.Telemetry.ActionModifier.GetLinkedInProductGroup, {
        error,
      });
      logger.error(stringifyError({ error }), {
        action: Constants.Telemetry.Action.Error,
        actionModifier: Constants.Telemetry.ActionModifier.GetLinkedInProductGroup,
      });
    }
  };
}

export function reportItem(itemReport: IItemReport) {
  return async function (dispatch: Dispatch, getState: () => IState) {
    const { communityAPI, communityAPIVersion } = getState().config;
    try {
      await reportReviewRestClient.createReportReview({
        itemReport,
        communityAPI,
        communityAPIVersion,
      });
    } catch (error) {
      launchReviewsTelemetry(Constants.Telemetry.Action.Error, Constants.Telemetry.ActionModifier.ReportReview, {
        error,
      });
      logger.error(stringifyError({ error }), {
        action: Constants.Telemetry.Action.Error,
        actionModifier: Constants.Telemetry.ActionModifier.ReportReview,
      });
    }
  };
}

export function setReviewMarkedAsHelpful({ offerId, review, isHelpful }: IAppEntitySetReviewMarkAsHelpfulParams) {
  return async function (dispatch: any, getState: () => IState) {
    const state = getState();
    const { reviewsAPI, reviewsAPIVersion, storefrontName: source } = state.config;
    const { userReviewIdsLoading } = state.apps.appReviewsData.markedAsHelpful;
    const { id: reviewId, mark_as_helpful_count: prevMarkAsHelpfulCount } = review;
    const telemetryBasePayload = {
      offerId,
      reviewId,
      isHelpful,
    };

    if (!userReviewIdsLoading[`${reviewId}`]) {
      dispatch(createEntityReviewSetIsHelpfulLoadingAction({ reviewId, isLoading: true }));

      // Immediately update both the isHelpful value and the review's marked as helpful count to reflect the user's action
      dispatch(
        createEntityReviewSetIsHelpfulAction({
          reviewId,
          isHelpful,
        })
      );

      dispatch(
        createEntityReviewSetMarkAsHelpfulCount({
          count: prevMarkAsHelpfulCount + (isHelpful ? 1 : -1),
          reviewId,
        })
      );

      launchReviewsTelemetry(
        Constants.Telemetry.Action.Submitting,
        Constants.Telemetry.ActionModifier.ReviewSetMarkedAsHelpful,
        telemetryBasePayload
      );
      logger.info(JSON.stringify(telemetryBasePayload), {
        action: Constants.Telemetry.Action.Submitting,
        actionModifier: Constants.Telemetry.ActionModifier.ReviewSetMarkedAsHelpful,
      });

      const { error } = await appReviewMarkAsHelpfulRestClient.setReviewMarkedAsHelpful({
        offerId,
        reviewId,
        reviewsAPI,
        reviewsAPIVersion,
        isHelpful,
        source: isHelpful ? source : null,
      });

      if (error) {
        launchReviewsTelemetry(
          Constants.Telemetry.Action.SubmitError,
          Constants.Telemetry.ActionModifier.ReviewSetMarkedAsHelpful,
          { ...telemetryBasePayload, error }
        );
        logger.error(JSON.stringify({ ...telemetryBasePayload, error }), {
          action: Constants.Telemetry.Action.SubmitError,
          actionModifier: Constants.Telemetry.ActionModifier.ReviewSetMarkedAsHelpful,
        });

        // Display the error message
        dispatch(
          createEntityReviewMarkAsHelpfulModalAction({
            showModal: true,
          })
        );
        // Set the is helpful and the count values back to what they were
        dispatch(
          createEntityReviewSetMarkAsHelpfulCount({
            count: prevMarkAsHelpfulCount,
            reviewId,
          })
        );
        dispatch(
          createEntityReviewSetIsHelpfulAction({
            reviewId,
            isHelpful: !isHelpful,
          })
        );
      } else {
        launchReviewsTelemetry(
          Constants.Telemetry.Action.Submitted,
          Constants.Telemetry.ActionModifier.ReviewSetMarkedAsHelpful,
          telemetryBasePayload
        );
        logger.info(JSON.stringify(telemetryBasePayload), {
          action: Constants.Telemetry.Action.Submitted,
          actionModifier: Constants.Telemetry.ActionModifier.ReviewSetMarkedAsHelpful,
        });
      }
      dispatch(createEntityReviewSetIsHelpfulLoadingAction({ reviewId, isLoading: false }));
    }
  };
}

export function ensureAppDetailsData(entityId: string, isPrivate: boolean) {
  return function (dispatch: any, getState: () => IState): Promise<boolean> {
    if (!entityId) {
      return Promise.resolve(false);
    }

    entityId = entityId.toLowerCase();

    if (entityId === getState().apps.entityIdLoading) {
      return Promise.resolve(false);
    }

    if (entityId === getState().apps.entityIdFailed) {
      return Promise.resolve(false);
    }

    const promises = [];

    if (shouldEnsureAppDetails(entityId, getState()) || (isPrivate && shouldEnsureAppPricings(entityId, getState()))) {
      promises.push(dispatch(getAppDetail(entityId, isPrivate)));
    }

    if (shouldEnsureAppPricings(entityId, getState()) && !isPrivate) {
      promises.push(dispatch(getAppPricing(entityId)));
    }

    if (shouldEnsureAppRelatedApps(entityId, getState()) && !isPrivate) {
      promises.push(dispatch(getAppRelatedAppsItems(entityId)));
    }
    if (!promises.length) {
      return Promise.resolve(false);
    }

    dispatch(createSetEntityIdLoadingAction({ entityType: Constants.EntityType.App, entityId }));
    dispatch(createSetEntityIdFailedAction({ entityType: Constants.EntityType.App, entityId: '' }));

    const start = Date.now();

    return Promise.all(promises)
      .then(() => {
        const payload = {
          page: getWindow() && getWindow().location.href,
          action: Constants.Telemetry.Action.EnsureAppDetailsData,
          actionModifier: Constants.Telemetry.ActionModifier.End,
          details: JSON.stringify({
            duration: Date.now() - start,
            entityId,
          }),
        };
        SharedInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
        logger.info(payload.details, {
          action: payload.action,
          actionModifier: payload.actionModifier,
        });

        dispatch(createSetEntityIdLoadingAction({ entityType: Constants.EntityType.App, entityId: '' }));

        return true;
      })
      .catch((error) => {
        const payload = {
          page: getWindow() && getWindow().location.href,
          action: Constants.Telemetry.Action.EnsureAppDetailsData,
          actionModifier: Constants.Telemetry.ActionModifier.Error,
          details: JSON.stringify({ duration: Date.now() - start, error: stringifyError(error) }),
        };
        SharedInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
        if (error?.error?.code === StatusCodes.NOT_FOUND) {
          logger.info(payload.details, {
            action: payload.action,
            actionModifier: payload.actionModifier,
          });
        } else {
          logger.error(payload.details, {
            action: payload.action,
            actionModifier: payload.actionModifier,
          });
        }

        dispatch(createSetEntityIdLoadingAction({ entityType: Constants.EntityType.App, entityId: '' }));
        dispatch(createSetEntityIdFailedAction({ entityType: Constants.EntityType.App, entityId }));

        throw error;
      });
  };
}

export function getServiceDetail(entityId: string) {
  return async function (dispatch: any, getState: () => IState) {
    const state = getState();
    const { flightCodes } = state.config;

    try {
      let serviceDetailData$: Promise<Service>;
      if (__CLIENT__) {
        serviceDetailData$ = entityRestClient.getServices(entityId, undefined, undefined, flightCodes);
      } else {
        serviceDetailData$ = servicesDetailsViewService.getServiceDetailView({
          entityId,
          flightCodes,
          request: mockRequest({ state: getState() }),
        });
      }

      const serviceDetailData = await serviceDetailData$;
      const dispatchActionAndResolve = () => {
        dispatch(
          createEntityDetailsReceivedAction({
            entityType: Constants.EntityType.Service,
            entityDetails: serviceDetailData,
          })
        );
        dispatch(
          createEntityDetailsReceivedAction({
            entityType: Constants.EntityType.CloudsIndustry,
            entityDetails: serviceDetailData,
          })
        );

        return serviceDetailData;
      };

      dispatchActionAndResolve();
    } catch (error) {
      // we can recover from not getting app details, but for now I do not want to (no designed experience)
      // the below dispatch is to make sure
      dispatch(
        createEntityDetailsReceivedAction({
          entityType: Constants.EntityType.Service,
          entityDetails: null,
        })
      );
      dispatch(
        createEntityDetailsReceivedAction({
          entityType: Constants.EntityType.CloudsIndustry,
          entityDetails: null,
        })
      );
      throw error;
    }
  };
}

export function ensureServiceDetailsData(entityId: string) {
  return function (dispatch: any, getState: () => IState): Promise<boolean> {
    if (!entityId) {
      return Promise.resolve(false);
    }

    entityId = entityId.toLowerCase();

    if (entityId === getState().services.entityIdLoading) {
      return Promise.resolve(false);
    }

    if (entityId === getState().services.entityIdFailed) {
      return Promise.resolve(false);
    }

    const promises = [];

    if (shouldEnsureServiceDetails(entityId, getState())) {
      promises.push(dispatch(getServiceDetail(entityId)));
    }

    if (shouldEnsureServiceRelatedApps(entityId, getState())) {
      promises.push(dispatch(getServiceRelatedAppsItems(entityId)));
    }

    if (!promises.length) {
      return Promise.resolve(false);
    }

    dispatch(createSetEntityIdFailedAction({ entityType: Constants.EntityType.Service, entityId: '' }));
    dispatch(createSetEntityIdLoadingAction({ entityType: Constants.EntityType.Service, entityId }));

    const start = Date.now();

    return Promise.all(promises)
      .then(() => {
        const payload = {
          page: getWindow() && getWindow().location.href,
          action: Constants.Telemetry.Action.EnsureServiceDetailsData,
          actionModifier: Constants.Telemetry.ActionModifier.End,
          details: JSON.stringify({
            duration: Date.now() - start,
            entityId,
          }),
        };
        SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
        logger.info(payload.details, {
          action: payload.action,
          actionModifier: payload.actionModifier,
        });

        dispatch(createSetEntityIdLoadingAction({ entityType: Constants.EntityType.Service, entityId: '' }));

        return true;
      })
      .catch((error) => {
        const payload = {
          page: getWindow() && getWindow().location.href,
          action: Constants.Telemetry.Action.EnsureServiceDetailsData,
          actionModifier: Constants.Telemetry.ActionModifier.Error,
          details: JSON.stringify({ duration: Date.now() - start, error: stringifyError(error) }),
        };
        SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
        logger.error(payload.details, {
          action: payload.action,
          actionModifier: payload.actionModifier,
        });

        dispatch(createSetEntityIdLoadingAction({ entityType: Constants.EntityType.Service, entityId: '' }));
        dispatch(createSetEntityIdFailedAction({ entityType: Constants.EntityType.Service, entityId }));

        throw error;
      });
  };
}

export function getUserProfile() {
  return async function (dispatch: any, getState: () => IState) {
    // user must be signed to get user profile and flag ProfileExp
    if (!getState().users.signedIn || !(getState().config.featureFlags.ProfileExp === 'true')) {
      return;
    }

    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.getUserProfile,
      actionModifier: Constants.Telemetry.ActionModifier.Start,
      details: '',
    };

    SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });

    dispatch(createUserProfileLoadingAction({ isLoading: true }));
    try {
      const userProfile = await userRestClient.getUserProfile();

      SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', {
        ...payload,
        actionModifier: Constants.Telemetry.ActionModifier.End,
      });
      logger.info(payload.details, {
        action: payload.action,
        actionModifier: Constants.Telemetry.ActionModifier.End,
      });

      dispatch(createUserProfileAction(userProfile));
    } catch (err) {
      const stringifiedError = stringifyError(err);
      SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', {
        ...payload,
        actionModifier: Constants.Telemetry.ActionModifier.Error,
        details: stringifiedError,
      });
      logger.info(stringifiedError, {
        action: payload.action,
        actionModifier: Constants.Telemetry.ActionModifier.Error,
      });
    } finally {
      dispatch(createUserProfileLoadingAction({ isLoading: false }));
    }
  };
}

export function getCustomers() {
  return function (dispatch: any, getState: () => IState) {
    const user = getState().users;
    if (!user || !user.signedIn || user.isMSAUser) {
      return Promise.resolve();
    }
    return new Promise<void>((resolve) => {
      purchaseRestClient
        .getCustomers({ oid: user.oid, tid: user.tid })
        .then((customers: ICustomer[]) => {
          dispatch(createSetCustomersAction(customers));
          if (customers && customers.length > 0) {
            let selectedCustomer = customers.filter((x: ICustomer) => x.type === CustomerType.Organization);
            if (selectedCustomer.length === 0) {
              selectedCustomer = customers.filter((x: ICustomer) => x.type === CustomerType.Individual);
            }
            if (selectedCustomer && selectedCustomer.length > 0) {
              dispatch(createSetActiveCustomerAction(selectedCustomer[0]));
            }
          }
          resolve();
        })
        .catch((error: any) => {
          logError('thunkActions.getCustomers', error);
        });
    });
  };
}

export function getUserTenantsDetails() {
  return function (dispatch: any, getState: () => IState) {
    const state = getState();
    if (!getState().users.signedIn && !state.config.isEmbedded) {
      return Promise.resolve();
    }

    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.GetTenantDetails,
      actionModifier: Constants.Telemetry.ActionModifier.Start,
      details: JSON.stringify({
        isSignedIn: state.users.signedIn,
        tid: state.users.tid,
      }),
    };
    SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });

    dispatch(createUserTenantsDetailsLoadingAction({ isLoading: true, hasError: false }));
    return userRestClient
      .getUserTenantsDetails()
      .then(({ value }: { value: TenantsDetails[] }) => {
        const selectedTenant: TenantsDetails =
          value.find((tenant: TenantsDetails) => state.users.tid === tenant.tenantId) ||
          ({
            tenantId: '',
            displayName: '',
            defaultDomain: '',
            countryCode: '',
            domains: [],
            id: '',
            tenantCategory: '',
            tenantType: '',
            azureSubscriptions: {},
          } as TenantsDetails);
        dispatch(createUserTenantsDetailsAction(selectedTenant));
        dispatch(createUserTenantsDetailsLoadingAction({ isLoading: false, hasError: false }));

        SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', {
          ...payload,
          actionModifier: Constants.Telemetry.ActionModifier.End,
        });
        logger.info(payload.details, {
          action: payload.action,
          actionModifier: Constants.Telemetry.ActionModifier.End,
        });
      })
      .catch((error: any) => {
        dispatch(createUserTenantsDetailsLoadingAction({ isLoading: false, hasError: true }));
        SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', {
          ...payload,
          actionModifier: Constants.Telemetry.ActionModifier.Error,
          details: JSON.stringify({
            isSignedIn: state.users.signedIn,
            tid: state.users.tid,
            error,
          }),
        });
        logger.error(
          JSON.stringify({
            isSignedIn: state.users.signedIn,
            tid: state.users.tid,
            error,
          }),
          {
            action: payload.action,
            actionModifier: Constants.Telemetry.ActionModifier.Error,
          }
        );
      });
  };
}

export function getUserBillingAccounts() {
  return function (dispatch: Dispatch, getState: () => IState) {
    const state = getState();
    if (!state.users.signedIn && !state.config.isEmbedded) {
      return Promise.resolve();
    }

    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.getUserBillingAccounts,
      actionModifier: Constants.Telemetry.ActionModifier.Start,
      details: JSON.stringify({
        isSignedIn: state.users.signedIn,
        tid: state.users.tid,
      }),
    };
    SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });
    dispatch(createSetAccountIsLoadingAction({ isLoading: true }));
    return userRestClient
      .getUserBillingAccounts()
      .then((billingAccounts: IBillingAccounts) => {
        const billingEAAccounts = billingAccounts.value.filter((account) => {
          const {
            properties: { agreementType, accountType },
          } = account;
          return (
            (agreementType === BillingAgreementType.EnterpriseAgreement ||
              agreementType === BillingAgreementType.MicrosoftCustomerAgreement) &&
            accountType === BillingAccountType.Enterprise
          );
        });
        dispatch(
          createSetAccountAction({
            isLoading: false,
            isEAAccount: !!billingEAAccounts.length,
            accounts: billingAccounts.value,
            hasError: false,
          })
        );
        payload.details = JSON.stringify({
          isSignedIn: state.users.signedIn,
          tid: state.users.tid,
          billingEAAccounts,
        });
        SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', {
          ...payload,
          actionModifier: Constants.Telemetry.ActionModifier.End,
        });
        logger.info(payload.details, {
          action: payload.action,
          actionModifier: Constants.Telemetry.ActionModifier.End,
        });
      })
      .catch((error: any) => {
        createSetAccountAction({ isLoading: false, isEAAccount: false, accounts: null, hasError: true });
        SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', {
          ...payload,
          actionModifier: Constants.Telemetry.ActionModifier.Error,
          details: JSON.stringify({
            isSignedIn: state.users.signedIn,
            tid: state.users.tid,
            error,
          }),
        });
        logger.error(
          JSON.stringify({
            isSignedIn: state.users.signedIn,
            tid: state.users.tid,
            error: stringifyError(error),
          }),
          {
            action: payload.action,
            actionModifier: Constants.Telemetry.ActionModifier.Error,
          }
        );
      });
  };
}

export function getTenantType() {
  return function (dispatch: any, getState: () => IState) {
    const user = getState().users;
    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.GetTenantType,
      actionModifier: Constants.Telemetry.ActionModifier.Start,
      details: JSON.stringify({
        isSignedIn: user.signedIn,
        isMSAUser: user.isMSAUser,
      }),
    };
    SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
    logger.info(payload.details, {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });

    if (!user.signedIn) {
      // if user is not signed in we cannot make this call
      return Promise.resolve();
    }
    if (user.isMSAUser) {
      dispatch(createSetTenantTypeAction(Constants.TenantType.Msa));
      return Promise.resolve();
    }
    // Prefetch the customer checkout page performance.
    dispatch(getCustomers());
    return new Promise<void>((resolve) => {
      const email = user.email;
      const payload: ITelemetryData = {
        page: getWindow().location.href,
        action: Constants.Telemetry.Action.GetTenantType,
        actionModifier: Constants.Telemetry.ActionModifier.Debug,
        details: JSON.stringify({
          isSignedIn: user.signedIn,
          isMSAUser: user.isMSAUser,
          hasEmail: !!email,
        }),
      };
      SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
      logger.debug(payload.details, {
        action: payload.action,
        actionModifier: payload.actionModifier,
      });
    });
  };
}

export function checkAdminMemberships() {
  return async function (dispatch: any, getState: () => IState) {
    const state = getState();
    if (!state.users.signedIn || state.config.isEmbedded) {
      return Promise.resolve();
    }
    try {
      const adminMemberships = await graphRestClient.checkAdminMemberShips();
      if (adminMemberships) {
        dispatch(createUpdateUserRoleDefinitions(adminMemberships));
      }

      const payload: ITelemetryData = {
        page: getWindow().location.href,
        action: Constants.Telemetry.Action.GetUserTenantInfo,
        actionModifier: Constants.Telemetry.ActionModifier.Info,
        details: JSON.stringify({ adminType: adminMemberships }),
      };
      SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
      logger.info(payload.details, {
        action: payload.action,
        actionModifier: payload.actionModifier,
      });
    } catch (error) {
      const payload: ITelemetryData = {
        page: getWindow().location.href,
        action: Constants.Telemetry.Action.GetUserTenantInfo,
        actionModifier: Constants.Telemetry.ActionModifier.Error,
        details: stringifyError(error),
      };
      SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
      logger.error(payload.details, {
        action: payload.action,
        actionModifier: payload.actionModifier,
      });
    }
  };
}

export function updateUser({ userDetails }: { userDetails: Partial<IUserDataState> }) {
  return async function (dispatch: any, getState: () => IState) {
    HttpModule.updateAccessToken(userDetails.accessToken);
    dispatch(createUserLoginDetailsAction(userDetails));
    // dispatch finished loading
    dispatch(createUserSignInFetchStatusAction({ loading: false }));
    // update user data
    dispatch(getUserProfile());
    dispatch(fetchUserFavourites());
    dispatch(checkAdminMemberships());

    if (getState().config.isAppSource && !getState().config.isEmbedded) {
      dispatch(getUserTenantsDetails());
      dispatch(getTenantType());
      dispatch(getUserBillingAccounts());
    }
  };
}

export function updateUserProfile(data: IUserLeadProfileAgreement) {
  return function (dispatch: any) {
    return (
      Promise.resolve()
        .then(() => {
          dispatch(createUpdateUserProfileAction(data));
          return Promise.resolve();
        })
        // eslint-disable-next-line node/handle-callback-err
        .catch(() => {
          return Promise.resolve();
        })
    );
  };
}

export function getDynamicCampaign(request: any) {
  return function (dispatch: any) {
    return new Promise<void>((resolve, reject) => {
      const dispatchActionAndResolve = (campaignData: IDynamicCampaignState) => {
        dispatch(
          createDynamicCampaignReceivedAction({
            campaignInfo: campaignData,
          })
        );
        resolve();
      };
      dynamicCampaignRestClient
        .getDynamicCampaign(request)
        .then((campaignHtml: string) => {
          dispatchActionAndResolve({ html: campaignHtml });
        })
        .catch((error: any) => {
          if (error.status !== 404) {
            // dispatch a received campaign action with no campaign info (null)
            dispatchActionAndResolve(null);
          }
          reject(error);
        });
    });
  };
}

export function createCustomer() {
  return function (dispatch: any, getState: () => IState) {
    dispatch(createClearErrorAction());
    const customers = getState().checkout.customers;
    if (customers && customers.length > 0) {
      // check to see if we already have a customer, if so, don't create it
      return Promise.resolve();
    }
    return new Promise<void>((resolve, reject) => {
      // new customer that's not bootstrapped, create the customer
      let firstName = getState().users.firstName;
      let lastName = getState().users.lastName;
      const displayName = getState().users.displayName;

      // firstname/lastname are required, so check if we have them otherwise work around the issue
      if (!firstName && !lastName && displayName) {
        firstName = displayName;
        lastName = '-';
      } else if (!firstName) {
        firstName = '-';
        lastName = '-';
      } else if (!lastName) {
        lastName = '-';
      }
      const newCustomer: ICustomer = {
        type: CustomerType.Individual,
        address: {
          firstName,
          lastName,
          country: getState().config.billingCountryCode.toUpperCase(),
        },
      };
      purchaseRestClient
        .createCustomer(newCustomer)
        .then((response: ICustomer) => {
          // create response does not include the address, so we need to update that ourselves
          const customer = {
            ...response,
            address: newCustomer.address,
          };
          dispatch(createSetActiveCustomerAction(customer));
          resolve();
        })
        .catch((error: any) => {
          dispatch(createErrorAction());
          reject(error);
        });
    });
  };
}

export function updateCustomer(address: ICustomerAddress) {
  return function (dispatch: any) {
    return new Promise<void>((resolve) => {
      purchaseRestClient
        .updateCustomer(address)
        .then(() => {
          dispatch(createUpdateCustomerAction(address));
          resolve();
        })
        .catch((error: any) => {
          logError('thunkActions.updateCustomer', error);
        });
    });
  };
}

export function generatePublisherOffer(subscriptionId: string) {
  return function (dispatch: any) {
    return new Promise<void>((resolve, reject) => {
      purchaseRestClient
        .getPublisherOfferInfo(subscriptionId)
        .then((response: IPublisherOfferResponse) => {
          dispatch(createSetPublisherTokenResponse(response));
          resolve();
        })
        .catch((error: any) => {
          dispatch(createSetPublisherTokenResponse({}));
          reject(error);
        });
    });
  };
}

export function preWarmAccount(addTestHeader: boolean) {
  return function (_: any, getState: () => IState) {
    const {
      config: { commerceApiHost },
    } = getState();
    try {
      commerceApiRestClient.preWarmAccount({ commerceApiHost, addTestHeader });
    } catch (ex) {}
  };
}

export function updatePurchaseStatus() {
  return (dispatch: any, getState: () => IState) => {
    const reviewsData = getState().apps.appReviewsData;
    dispatch(
      createGetAppReviewsAction({
        ...reviewsData,
        isPurchased: true,
      })
    );
  };
}

export function setStorefrontName(storefrontName: string) {
  return (dispatch: any) => {
    dispatch(createSetStorefrontNameAction(storefrontName));
  };
}

export function getRecommenedSizes({
  entityId,
  planId,
  createUiDefinitionEndpoint,
}: {
  entityId: string;
  planId: string;
  createUiDefinitionEndpoint: string;
}) {
  return async function (dispatch: Dispatch, getState: () => IState) {
    const { entityId: prevEntityId, planId: prevPlanId, loading } = getState().apps.recommendedSizesState;

    if (loading || (prevEntityId === entityId && prevPlanId === planId)) {
      return;
    }

    dispatch(createLoadingRecommenededSizes({ entityId, planId }));

    try {
      const createUiDefinition = await entityRestClient.getCreateUiDefinition({ createUiDefinitionEndpoint });

      const recommendedSizes = parseCatalogRecommendedSizes(createUiDefinition);

      dispatch(createSetRecommenededSizes({ entityId, planId, recommendedSizes }));
    } catch (error) {
      dispatch(createSetRecommenededSizes({ entityId, planId, recommendedSizes: [] }));
    }
  };
}

export function ensureCheckoutApp({ entityId }: { entityId: string }) {
  return async function (dispatch: Dispatch, getState: () => IState) {
    try {
      return await Promise.all([dispatch(getAppDetail(entityId, false)), dispatch(getAppPricing(entityId))]);
    } catch (error) {
      const servicePromises = await dispatch(ensureServiceDetailsData(entityId));
      const state = getState();
      const targetApp = state.services.dataList.find(
        (offer) => offer?.entityId.toString().toLowerCase() === entityId.toString().toLowerCase()
      );
      if (targetApp?.isHiddenPrivateOffer) {
        return servicePromises;
      }
      throw error;
    }
  };
}
