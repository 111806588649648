import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { Constants } from '@shared/utils/constants';
import { ILocParamsContext, ILocContext } from '@shared/interfaces/context';
import { Text, Icon, Label, Stack, ChoiceGroup } from '@fluentui/react';
import type { IChoiceGroupOption } from '@fluentui/react';
import Tooltip from '@shared/components/tooltip';
import { ICartItem, ISetTermDurationAction, ISetTermIdAction, TermsStrings } from '@shared/Models';
import { getBillingTermTranslated } from '@shared/utils/pricing';
import { Product } from '@shared/services/product/product';

const pbTelemetry = Constants.Telemetry.PurchaseBlendsTelemetry;

interface MapBillingTermToBillingOptions {
  [key: string]: IChoiceGroupOption;
}

export interface IBillingTermProps {
  setSelectedTerm: (termIdAction: ISetTermIdAction) => void;
  setBillingTerm: (termDurationAction: ISetTermDurationAction) => void;
  items: ICartItem[];
}

export const BillingTermChoiceGroup: React.FunctionComponent<IBillingTermProps> = (
  { setSelectedTerm, setBillingTerm, items }: IBillingTermProps,
  context: ILocParamsContext & ILocContext
) => {
  const { id: planId, product, hasFreeTrial, termDuration } = items[0];

  const handleBillingTermChanges = ({
    setSelectedTerm,
    setBillingTerm,
    planId,
    product,
    hasFreeTrial,
    option,
  }: {
    product: Product;
    planId: string;
    setBillingTerm: (termDurationAction: ISetTermDurationAction) => void;
    setSelectedTerm: (termIdAction: ISetTermIdAction) => void;
    hasFreeTrial: boolean;
    option: IChoiceGroupOption;
  }): void => {
    const firstTerm = product.getTermByPlanAndBillingTermDuration({
      planId,
      billingTermDuration: option.key as TermsStrings,
    });
    setBillingTerm({ id: planId, termDuration: option.key as TermsStrings });
    setSelectedTerm({ id: planId, termId: hasFreeTrial ? firstTerm.freeTrialTermId : firstTerm.termId });
  };

  const generateBillingOption = (term: TermsStrings, context: ILocParamsContext & ILocContext): IChoiceGroupOption => {
    return {
      key: term,
      text: `${getBillingTermTranslated({ context, term, isSubscription: true })}`,
    };
  };

  const getTermMapping = (context: ILocParamsContext & ILocContext): MapBillingTermToBillingOptions => {
    return {
      [TermsStrings.MonthString.toLowerCase()]: generateBillingOption(TermsStrings.Term1MString, context),
      [TermsStrings.Term1MString.toLowerCase()]: generateBillingOption(TermsStrings.Term1MString, context),
      [TermsStrings.YearString.toLowerCase()]: generateBillingOption(TermsStrings.Term1YString, context),
      [TermsStrings.Term1YString.toLowerCase()]: generateBillingOption(TermsStrings.Term1YString, context),
      [TermsStrings.Term2YString.toLowerCase()]: generateBillingOption(TermsStrings.Term2YString, context),
      [TermsStrings.Term3YString.toLowerCase()]: generateBillingOption(TermsStrings.Term3YString, context),
    };
  };

  const getBillingOptions = (items: ICartItem[], context: ILocParamsContext & ILocContext) => {
    // Since sort happens in place, cloning list instead of messing up redux.
    const listOfTerms = [...items[0].product.getTermDurationsByPlanId(items[0].id)];
    return listOfTerms.sort().map((term) => {
      const billingOptionsMap: MapBillingTermToBillingOptions = getTermMapping(context);
      return billingOptionsMap[term.toLowerCase()];
    });
  };

  const options = useMemo(() => getBillingOptions(items, context), [items, context]);

  return (
    <>
      <Label>
        <Stack horizontal verticalAlign="center" wrap={false}>
          <Text className="ms-fontWeight-semibold">
            {`${context.loc('Checkout_PriceAndBilling_BillingTerm', 'Billing term')} `}
            <Tooltip
              tooltipHostProps={{
                content: context.loc('Checkout_PriceAndBilling_Tooltip_BillingTerm', "This is the plan's subscription period."),
                calloutProps: { gapSpace: 0 },
              }}
            >
              <Icon
                iconName="Info"
                ariaLabel={context.loc('Checkout_PriceAndBilling_Tooltip_BillingTerm', "This is the plan's subscription period.")}
              />
            </Tooltip>
            <Text className="requiredColor">{` *`}</Text>
          </Text>
        </Stack>
      </Label>
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Stack.Item>
          {options?.length > 1 ? (
            <ChoiceGroup
              data-bi-id={pbTelemetry.PickBillingTerm}
              data-bi-name={pbTelemetry.BillingTermComponent}
              options={options}
              onChange={(_?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) =>
                handleBillingTermChanges({ product, planId, setBillingTerm, setSelectedTerm, hasFreeTrial, option })
              }
              defaultSelectedKey={(termDuration as TermsStrings) || ''}
            />
          ) : (
            <Text>{options?.[0].text}</Text>
          )}
        </Stack.Item>
      </Stack>
    </>
  );
};

(BillingTermChoiceGroup as any).contextTypes = {
  locParams: PropTypes.func,
  loc: PropTypes.func,
};
