import * as React from 'react';
import * as PropTypes from 'prop-types';
import SpzaComponent from './spzaComponent';
import { IDynamicCampaignState } from '../../State';
import { ICTACallbackContext, ICommonContext } from '../interfaces/context';
import { Constants } from '../utils/constants';
import { ITelemetryData } from './../Models';
import { getWindow } from 'services/window';
import { SpzaInstrumentProvider, SpzaInstrumentService } from '../services/telemetry/spza/spzaInstrument';
import { logger } from '@src/logger';

export interface IDynamicCampaignProps {
  dynamicCampaign: IDynamicCampaignState;
}

interface ICtaCampaignEntity {
  appId: string;
  ctaType: Constants.CTAType;
  entityType: Constants.EntityType;
}

export class DynamicCampaign extends SpzaComponent<IDynamicCampaignProps, any> {
  context: ICommonContext & ICTACallbackContext;
  private instrument: SpzaInstrumentProvider;

  constructor(props: IDynamicCampaignProps, context: ICommonContext & ICTACallbackContext) {
    super(props, context);
    this.instrument = SpzaInstrumentService.getProvider();
    this.showDialog = this.showDialog.bind(this);
  }

  componentDidMount() {
    // add a function to the window object to be able to call a component method from the html
    window.ctaCallback = (ctaEntity: ICtaCampaignEntity) => this.showDialog(ctaEntity);
    this.generateLoadingPageTelemetry();
  }

  generateLoadingPageTelemetry() {
    if (this.props.dynamicCampaign) {
      const payload: ITelemetryData = {
        page: getWindow().location.href,
        action: Constants.Telemetry.Action.PageLoad,
        actionModifier: Constants.Telemetry.ActionModifier.End,
      };
      this.instrument.probe<ITelemetryData>('logTelemetryInfo', payload);
      logger.info('', {
        action: payload.action,
        actionModifier: payload.actionModifier,
      });
    }
  }

  generateCtaClickTelemetry() {
    const payload: ITelemetryData = {
      page: getWindow().location.href,
      action: Constants.Telemetry.Action.Click,
      actionModifier: Constants.Telemetry.ActionModifier.AppDynamicCampaignCTAButton,
    };

    SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
    logger.info('', {
      action: payload.action,
      actionModifier: payload.actionModifier,
    });
  }

  showDialog(ctaEntity: ICtaCampaignEntity) {
    this.generateCtaClickTelemetry();

    const entity = { entityId: ctaEntity.appId };
    this.context.ctaCallback({
      entity,
      entityType: ctaEntity.entityType,
      ctaType: ctaEntity.ctaType,
      actionModifier: Constants.Telemetry.ActionModifier.AppDynamicCampaignCTAButton,
    });
  }

  createHtmlMarkup() {
    const dynamicCampaign = this.props.dynamicCampaign;
    return { __html: dynamicCampaign.html };
  }

  renderDefaultPage(): JSX.Element {
    return <div className="campaignDefaultPage Header c-heading-2">Campaign not found !</div>;
  }

  renderImpl(): any {
    // eslint-disable-next-line react/no-danger
    return this.props.dynamicCampaign ? <div dangerouslySetInnerHTML={this.createHtmlMarkup()} /> : this.renderDefaultPage();
  }
}

(DynamicCampaign as any).contextTypes = {
  loc: PropTypes.func,
  locDateString: PropTypes.func,
  locParams: PropTypes.func,
  buildHref: PropTypes.func,
  ctaCallback: PropTypes.func,
  contactCallback: PropTypes.func,
  renderErrorModal: PropTypes.func,
  openTileCallback: PropTypes.func,
};
