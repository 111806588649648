import React from 'react';
import { mergeStyleSets, MessageBar, MessageBarType } from '@fluentui/react';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { Constants } from '@shared/utils/constants';

interface IMSuserErrorBannerProps {
  context: ILocContext & ILocParamsContext;
  tenantId: string;
}

const contentStyles = mergeStyleSets({
  root: {
    marginBottom: '10px',
  },
});

export const MSuserErrorBanner: React.FunctionComponent<IMSuserErrorBannerProps> = ({
  context,
  tenantId,
}: IMSuserErrorBannerProps) => {
  return (
    tenantId === Constants.MSTenantId && (
      <div className={contentStyles.root}>
        <MessageBar role="status" messageBarType={MessageBarType.error}>
          {context.loc('MSuser_Banner', 'As a Microsoft Employee, you are not allowed to complete a purchase in AppSource')}
        </MessageBar>
      </div>
    )
  );
};
