import React, { FunctionComponent } from 'react';
import { ISortedTableProps } from './sortedTable';

interface MultilineColumnProps {
  columnKey: string;
  columnValue: any[];
  shouldUseSetInnerHTML: boolean;
}

interface SortedTableColumnProps {
  columnKey: string;
  columnValue: any;
  className?: string;
  tableProps: ISortedTableProps;
}

const MultilineColumn: FunctionComponent<MultilineColumnProps> = ({
  columnKey,
  columnValue,
  shouldUseSetInnerHTML,
}: MultilineColumnProps) => {
  const lines = columnValue.map((item: any, key: number) => {
    if (shouldUseSetInnerHTML) {
      return (
        // eslint-disable-next-line react/no-danger
        <span key={key} dangerouslySetInnerHTML={{ __html: item }}>
          <br />
        </span>
      );
    }
    return (
      <span key={key}>
        {item}
        <br />
      </span>
    );
  });

  return (
    // eslint-disable-next-line react/forbid-dom-props
    <td id={columnKey} className={columnKey}>
      {lines}
    </td>
  );
};

export const SortedTableColumn: FunctionComponent<SortedTableColumnProps> = ({
  columnKey,
  columnValue,
  className,
  tableProps,
}) => {
  const { spanColumns, setInnerHTMLColumns, multiLineColumns } = tableProps;
  if (!columnValue) {
    return null;
  }

  const columnClassName = `${columnKey} ${className}`;

  // If this flag is set a span tag is added around the content of table data. Default - no span tag added.
  const shouldAddSpanTag = spanColumns ? spanColumns.indexOf(columnKey) >= 0 : false;

  // If this flag is set, we will use dangerouslySetInnerHTML
  const shouldUseSetInnerHTML = setInnerHTMLColumns ? setInnerHTMLColumns.indexOf(columnKey) >= 0 : false;

  // If this flag is set the content of table data can have breaks within it. Default - A single line.
  const shouldShowMultiLine = multiLineColumns ? multiLineColumns.indexOf(columnKey) >= 0 : false;

  if (columnValue instanceof Array && shouldShowMultiLine) {
    return <MultilineColumn columnKey={columnKey} columnValue={columnValue} shouldUseSetInnerHTML={shouldUseSetInnerHTML} />;
  } else if (typeof columnValue === 'string' || typeof columnValue === 'number' || typeof columnValue === 'object') {
    if (shouldAddSpanTag) {
      return (
        // eslint-disable-next-line react/forbid-dom-props
        <td id={columnKey} className={columnClassName}>
          <span>{columnValue}</span>
        </td>
      );
    } else if (shouldUseSetInnerHTML) {
      return (
        <td
          // eslint-disable-next-line react/forbid-dom-props
          id={columnKey}
          className={columnClassName}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: columnValue,
          }}
        />
      );
    } else {
      return (
        // eslint-disable-next-line react/forbid-dom-props
        <td id={columnKey} className={columnClassName}>
          {columnValue}
        </td>
      );
    }
  }
};
