import { IFilterGroup, match } from './filterModule';
import {
  IDataMap,
  IDataValues,
  IDataCollection,
  ICategoryCollection,
  IProductValue,
  DataMap as constantDataMap,
  CategoriesInnerKeys,
  CategoriesPath,
} from './dataMapping';

import { removeUnmatchedCategories, bitMasksContainSomeBitMask, bitMasksMatch, getShortCutBitmask } from './datamappingHelpers';
import { IBuildHrefContext } from '../interfaces/context';
import { AllCountry, Constants, supportedCountries, SupportedCountry } from './constants';
import { getEntityRegistration } from '../utils/entityRegistration';
import { urlPush, routes } from './../routerHistory';
import { getWindow } from '../services/window';
import {
  ITelemetryData,
  IURLQuery,
  IURLParam,
  IDataItem,
  IAppDataItemBasicData,
  ILinksList,
  IHashMap,
  ICuratedSections,
  ICuratedSectionItem,
} from './../Models';
import { SpzaInstrumentService } from '../services/telemetry/spza/spzaInstrument';
import { isPureObject } from './objectUtils';

import { handleFilterToggleAction } from '../../shared/utils/filterItemUtils';
import * as embedHostUtils from '../../embed/embedHostUtils';
import { logger } from '@src/logger';
import { getProductByUrlKey } from '@shared/utils/appUtils';
import { isOneTaxonomyEnabled } from '@shared/oneTaxonomy/utils';

export interface IFilterCollectionNameToFilterQuery {
  [filterCollectionName: string]: string;
}

export interface IFilterData {
  filters: IDataValues[];
  ignore: boolean;
}

const FilterQuery = Constants.FilterQuery;

// To do: Improvement in DataMapping.tsx
// could we add query to a filter collection in DataMapping.tsx
// instead of hard coding filter collection queries
const filterCollectionNameToFilterQuery: IFilterCollectionNameToFilterQuery = {
  products: FilterQuery.product,
  industries: FilterQuery.industry,
  categories: FilterQuery.category,
  serviceTypes: FilterQuery.serviceType,
  trials: FilterQuery.trials,
  pricingModel: FilterQuery.pricingModel,
  ratings: FilterQuery.ratings,
  appCompliance: FilterQuery.appCompliance,
  AzureBenefitEligible: FilterQuery.azureBenefitEligible,
  productType: FilterQuery.productType,
  CopilotExtension: FilterQuery.copilotExtension,
};

// TODO: merge with the generic function
function getProductsFilterData(
  filterQueries: string[],
  filterCollection: IDataCollection,
  filterData: IFilterGroup,
  shouldResetCounts: boolean
) {
  const bitmask = {};

  filterQueries.forEach((activeQuery: string) => {
    const activeFilter: IDataValues = getProductByUrlKey({ urlKey: activeQuery });

    if (activeFilter) {
      if (bitmask[activeFilter.FilterGroup]) {
        bitmask[activeFilter.FilterGroup] |= activeFilter.FilterID;
      } else {
        bitmask[activeFilter.FilterGroup] = activeFilter.FilterID;
      }
    } else {
      filterData.ignore = false;
    }
  });

  filterData.ignore = filterData.ignore && Object.keys(bitmask).length === 0;

  // push each filter under one filter collection into filterData.filters
  // and determine values of two properties, isActive and checkFilter
  Object.keys(filterCollection).forEach((filterKey: string) => {
    const filter: IDataValues = filterCollection[`${filterKey}`];

    if (shouldResetCounts) {
      filter.count = 0;
    }
    const filterBitmaskMatch = bitMasksContainSomeBitMask(bitmask, getShortCutBitmask(filter));

    // isActive will determine if the checkbox should be toggled
    filter.isActive = filterBitmaskMatch;

    // checkFilter will determine if apps will be filtered against this filter
    filter.checkFilter = filter.isActive;

    const parentFilterQuery = getProductByUrlKey({ urlKey: filterQueries[0] });
    const selectedParentFilter: IDataValues = filterCollection[`${parentFilterQuery}`];
    const shouldExpandChildFilters: boolean = !!selectedParentFilter && selectedParentFilter.ShouldExpandChildFilters;

    // deactivating if current filter is a child filter of an active parent filter (selectedParentFilter, about to expand)
    if (filter !== selectedParentFilter && shouldExpandChildFilters) {
      filter.isActive = false;
      filter.checkFilter = false;
    }

    filterData.filters.push(filter);
  });
}

function isGlobalFilter(filterQueryName: string): boolean {
  return (
    filterQueryName === 'trials' ||
    filterQueryName === 'pricingModel' ||
    filterQueryName === 'ratings' ||
    filterQueryName === 'appCompliance' ||
    filterQueryName === 'azureBenefitEligible' ||
    filterQueryName === 'productType' ||
    filterQueryName === 'copilotExtension'
  );
}

/**
 * get filter data based on filterQueryName for a filter collection <=> a DataMap (DataMapping.tsx) property, and
 * update filter properties of both isActive and checkFilter in each filter, (updated in both returned filter data and in runtime DataMap)
 *
 *  helper function
 *      to get filter data
 *          from filterQueryName and,
 *          from filter collection processed in its wrapper function getFilterDataForQuery, and
 *      to process filter data and determine two properties of each filter, namely isActive, and checkFilter
 *
 * @param query: url queries in browser url
 * @param filterQueryName:
 *  name of url filter query, which corresponds to a DataMap property <=> a filter collection
 *  namely: product, industry, category, serviceType
 *
 *  hence, the value of filter query should be query[filterQueryName],
 *  a string which joins the actual should-be-active filter name under one filter collection with ';'
 * @param filterCollection:
 *  a DataMap property (DataMapping.tsx), except for 'categories' properties, whose each sub filter collection is the input value instead
 *  namely: products, industries, categories, serviceTypes
 * @param shouldResetCounts:
 *  optionally to reset counts of app which applied to each filter,
 *  the purpose of optional is for accumulate counts for different offers (app, and consulting service) in sequence
 *
 * @return:
 *  return filter data for one filter collection
 *      filters: an array of all filters under one filter collection, where its two properties are modified
 *          isActive: determines if the filter should be active based on
 *              filter url query values: namely query[filterQueryName] <=> filter names under one filter collection joined by ';'
 *              mask: Bit-OR result of mask values for all active filters, indicated by filter url query values
 *          checkFilter: a shorcut boolean to notify performFilter function (filterModule) to ignore this filter
 *      ignore:
 *          same concept with checkFilter, a shortcut boolean to notify performFilter function (filterModule) to
 *          completely ignore perform filter against offers (apps and consulting sevices) with this filter data
 */
function getFilterDataForQueryHelper(
  query: IURLQuery,
  filterQueryName: string,
  filterCollection: IDataCollection,
  shouldResetCounts: boolean
): IFilterGroup {
  const filterData: IFilterGroup = {
    filters: [],
    ignore: true,
  };

  if (!filterCollection) {
    return filterData;
  }

  const hasGlobalFilter = isGlobalFilter(filterQueryName);
  let filterQuery = null;
  if (query) {
    filterQuery = hasGlobalFilter ? query.filters : query[`${filterQueryName}`];
  }
  let filterQueries: string[] = [];

  if (hasGlobalFilter) {
    const rawFilterQueries: string[] = typeof filterQuery === 'string' ? filterQuery.split(';') : [];
    for (let i = 0; i < rawFilterQueries.length; i++) {
      if (filterQueryName === 'trials' && constantDataMap.trials[rawFilterQueries[`${i}`]]?.FilterID) {
        filterQueries.push(rawFilterQueries[`${i}`]);
      }
      if (filterQueryName === 'pricingModel' && constantDataMap.pricingModel[rawFilterQueries[`${i}`]]?.FilterID) {
        filterQueries.push(rawFilterQueries[`${i}`]);
      }
      if (filterQueryName === 'ratings' && constantDataMap.ratings[rawFilterQueries[`${i}`]]?.FilterID) {
        filterQueries.push(rawFilterQueries[`${i}`]);
      }
      if (filterQueryName === 'appCompliance' && constantDataMap.appCompliance[rawFilterQueries[`${i}`]]?.FilterID) {
        filterQueries.push(rawFilterQueries[`${i}`]);
      }
      if (
        filterQueryName === Constants.FilterQuery.azureBenefitEligible &&
        constantDataMap.AzureBenefitEligible[rawFilterQueries[`${i}`]]?.FilterID
      ) {
        filterQueries.push(rawFilterQueries[`${i}`]);
      }
      if (
        filterQueryName === Constants.FilterQuery.copilotExtension &&
        constantDataMap.CopilotExtension[rawFilterQueries[`${i}`]]?.FilterID
      ) {
        filterQueries.push(rawFilterQueries[`${i}`]);
      }
      if (
        filterQueryName === Constants.FilterQuery.productType &&
        constantDataMap.productType[rawFilterQueries[`${i}`]]?.FilterID
      ) {
        filterQueries.push(rawFilterQueries[`${i}`]);
      }
    }
  } else {
    filterQueries = typeof filterQuery === 'string' ? filterQuery.split(';') : [];
  }

  // to set which filter should be active <=> bold in UI, tags in gallery header, and also query in browser
  // use only FilterId property of active filters to check against filters' bitmask (FilterID | ShortcutBitmask)
  // this is to avoid child filter checkboxes being checked automatically,
  // when user clicked on a parent filter consists of checkbox-style child filters
  // Since the FilterIDs of those parent filters doesn't include bitmasks of its childfilters'
  // so when use only FilterIDs, the child filters will stay unchecked when parent filter has just been clicked
  //
  // the reason why we want to disable selecting child filters, is because
  // we want to show curated gallery page, instead of filtered result page when user click on a parent filter
  //
  // the gallery page will show with
  // curated data (swim lanes), when parent filter and only parent filter is selected
  // fitlered result data, when parent filter along with its child filters selected
  //
  // TO DO:
  // We should do the same with expandable parent filter whose children is not checkbox-style
  if (filterQueryName === 'product') {
    getProductsFilterData(filterQueries, filterCollection, filterData, shouldResetCounts);
  } else {
    let bitmask = 0;

    filterQueries.forEach((activeQuery: string) => {
      const activeFilter: IDataValues = filterCollection[`${activeQuery}`];

      if (activeFilter) {
        bitmask = bitmask | activeFilter.FilterID;
      } else {
        filterData.ignore = false;
      }
    });

    filterData.ignore = filterData.ignore && bitmask === 0;

    // push each filter under one filter collection into filterData.filters
    // and determine values of two properties, isActive and checkFilter
    Object.keys(filterCollection).forEach((filterKey: string) => {
      const filter: IDataValues = filterCollection[`${filterKey}`];

      if (shouldResetCounts) {
        filter.count = 0;
      }

      const filterBitmaskMatch: number = filter.FilterID & bitmask;

      // isActive will determine if the checkbox should be toggled
      filter.isActive = filterBitmaskMatch > 0;

      // checkFilter will determine if apps will be filtered against this filter
      filter.checkFilter = filter.isActive;

      // to show curated page when parent filter is clicked upon
      // that is, as mention aboved in setting bitmask
      // we should keep child filter inactive
      // we took care of parent filter whose children is checkbox-style filters
      //
      // here, we still need to take care of parent filter whose children is not checkbox-style filters
      // since bitmask which sums up from FilterID, deosn't take care of such case
      //
      // to get the parent filter, we use first element of filterQueries (of current filter collection filterQueryName), query[filterQueryName] seperated by ';',
      // this is kind of hack, but current rule of building href when filters are clicked is that
      // only one (most nested active) parent filter will be shown in filterQueries, for each filter collection, i.e. products, industries, categories, serviceTypes
      //
      // e.g. product filter add-ins has a child filter dynamics-365, which has many child filters, business central, etc.
      //  a. when user clicked filter add-ins, filterQueries will show add-ins (product=add-ins)
      //  b.
      //      when user clicked filter dynmamics-365,
      //      although active filters (when performFilter funtion calls) have both add-ins and dynamics-365,
      //      filterQueries will only show dynamics-365(product=dynamics-365)
      //      which is the only one and also most nested parent active filter for the product filter collection
      //
      // to determine whether first filter is a parent, and whether this parent filter is about to expand (currently callapsed)
      // we could simply check whether it has ShouldExpandChildFilters property
      //
      // TO DO:
      // getting the parent filter is kind of hack, but you could play around with filters in UI, and notice it's only currently happening for
      // product root filters, i.e. web-apps, and add-ins
      // to improve, instead of only taking first one in filterQueries,
      // we should check each of filterQueries (i.e. selectedParentFilters) against current filter (filter)
      // we should set filter as inactive, if below are all met
      // 1) selectedParentFilter has children (ShortcutFilters)
      // 2) selectedParentFilter is about to expand
      // 3) filter is one of its children
      // 4) filter is not in filterQueries (not active)
      const selectedParentFilter: IDataValues = filterCollection[filterQueries[0]];
      const shouldExpandChildFilters: boolean = !!selectedParentFilter && selectedParentFilter.ShouldExpandChildFilters;

      // deactivating if current filter is a child filter of an active parent filter (selectedParentFilter, about to expand)
      if (filter !== selectedParentFilter && shouldExpandChildFilters) {
        filter.isActive = false;
        filter.checkFilter = false;
      }

      filterData.filters.push(filter);
    });
  }
  // clear each active parent filter's checkFilter if any of its children is active,
  // this is to avoid duplicate checking entities (app and consulting service) against filters, (see performFilter function in filterModule.tsx)
  // if entities are checked against more specific filters, i.e. child filters, there's no need to check its parent
  filterData.filters.forEach((filter: IDataValues) => {
    const childFilterNames: string[] = filter.ShortcutFilters;

    if (!childFilterNames) {
      return;
    }

    // unlike Azure Marketplace, the child filters are on the same level with its parent filter, in DataMap (DataMapping.tsx)
    // to indicate relation with parent and child in appsource is through parent filter ShortcutFilters property
    //
    // TO INVESTIGATE:
    // filter ShortcutFilters property, after several initial renders, will change from string[], to {[index: number]: string}
    // someone may have misuse object.keys, or wrongly implemented deep copy, results in turning array into object
    Object.keys(childFilterNames)
      // return immediately if active child filter is found, and clear checkFilter of parent filter property
      .some((key: string) => {
        const childFilterName: string = childFilterNames[`${key}`];
        const childFilter: IDataValues = filterCollection[`${childFilterName}`];

        const childFilterIsActive: boolean = !!childFilter && childFilter.isActive;

        if (childFilterIsActive) {
          filter.checkFilter = false;
        }

        return childFilterIsActive;
      });
  });

  return filterData;
}

function getFilterDataForQueryHelperV2(
  query: IURLQuery,
  filterQueryName: string,
  subgFilterQueryName: string,
  filterCollection: IDataCollection,
  shouldResetCounts: boolean
): IFilterGroup {
  const filterData: IFilterGroup = {
    filters: [],
    ignore: true,
  };

  if (!filterCollection) {
    return filterData;
  }

  const selectedFilter = query && query[`${filterQueryName}`];

  Object.keys(filterCollection).forEach((filterKey) => {
    const filter: IDataValues = filterCollection[`${filterKey}`];

    if (filter) {
      if (shouldResetCounts) {
        filter.count = 0;
      }

      const primaryFilter: IDataValues =
        filterQueryName === FilterQuery.category && filter.IsReference
          ? constantDataMap.categories.default[`${filterKey as CategoriesInnerKeys}`]
          : filter;

      const isActive = primaryFilter.UrlKey === selectedFilter;

      let isContainsSubActive = false;

      if (isActive) {
        filterData.ignore = false;
      }

      if (primaryFilter.SubCategoryDataMapping) {
        Object.keys(primaryFilter.SubCategoryDataMapping).forEach((subKey) => {
          const subFilter = primaryFilter.SubCategoryDataMapping[`${subKey}`];

          const selectedSubFilters = query[`${subgFilterQueryName}`];

          const isSubActive = !!(selectedSubFilters && selectedSubFilters.indexOf(subFilter.UrlKey) > -1);

          subFilter.isActive = isSubActive;
          subFilter.checkFilter = isSubActive;

          if (isSubActive) {
            isContainsSubActive = true;

            if (!filter.IsReference) {
              filterData.filters.push(subFilter as IDataValues);
            }
          }
        });
      }

      filter.isActive = isActive;
      filter.checkFilter = isActive && !isContainsSubActive;

      filterData.filters.push(filter as IDataValues);
    }
  });

  return filterData;
}

/**
 * get filter data based on filterQueryName for a filter collection <=> a DataMap (DataMapping.tsx) property, and
 * update filter properties of both isActive and checkFilter in each filter, (updated in both returned filter data and in runtime DataMap)
 *
 *  a wrapper function to determine how to process DataMap (DataMapping) to get the actual filter collection
 *  and with the actual filter collection, call getFilterDataForQueryHelper function to get filter data
 *
 * @param query: url queries in browser url
 * @param filterQueryName:
 *  name of url filter query, which corresponds to a DataMap property <=> a filter collection
 *  namely: product, industry, category, serviceType
 *
 *  hence, the value of filter query should be query[filterQueryName],
 *  a string which joins the actual should-be-active filter name under one filter collection with ';'
 * @param filterCollection:
 *  a DataMap property (DataMapping.tsx)
 *  namely: products, industries, categories, serviceTypes
 * @param shouldResetCounts:
 *  optionally to reset counts of app which applied to each filter,
 *  the purpose of optional is for accumulate counts for different offers (app, and consulting service) in sequence
 *
 * @return: one filter collection
 *      filters: an array of all filters under one filter collection, where its two properties are modified
 *          isActive: determines if the filter should be active based on
 *              filter url query values: namely query[filterQueryName] <=> filter names under one filter collection joined by ';'
 *              mask: Bit-OR result of mask values for all active filters, indicated by filter url query values
 *          checkFilter: a shorcut boolean to notify performFilter function (filterModule) to ignore this filter
 *      ignore:
 *          same concept with checkFilter, a shortcut boolean to notify performFilter function (filterModule) to
 *          completely ignore perform filter against offers (apps and consulting sevices) with this filter data
 */
function getFilterDataForQuery(
  query: IURLQuery,
  filterQueryName: string,
  filterCollection: IDataCollection | ICategoryCollection,
  shouldResetCounts: boolean
): IFilterData {
  // Special Case:
  // To get filter data in 'categories' filter collection of DataMap (DataMapping.tsx)
  // need to go one level deeper from its wrapper filter group
  if (filterQueryName === FilterQuery.category) {
    const filterData: IFilterData = {
      filters: [],
      ignore: true,
    };

    if (!filterCollection) {
      return filterData;
    }

    const categoryFilterCollection: ICategoryCollection = filterCollection as ICategoryCollection;

    // access each sub collection to get its actual filter data
    Object.keys(categoryFilterCollection).forEach((subCollectionKey: string) => {
      // sub collection is the actual filter collection which contains filter in 'categories' filter collection
      const categoryFilterSubCollection: IDataCollection = categoryFilterCollection[`${subCollectionKey}`] as IDataCollection;

      // get actual filter sub collection data
      const filterSubCollectionData = getFilterDataForQueryHelperV2(
        query,
        filterQueryName,
        FilterQuery.subcategories,
        categoryFilterSubCollection,
        shouldResetCounts
      );

      filterData.filters = filterData.filters.concat(filterSubCollectionData.filters);
      filterData.ignore = filterData.ignore && filterSubCollectionData.ignore;
    });

    return filterData;
  } else if (filterQueryName === FilterQuery.industry) {
    return getFilterDataForQueryHelperV2(
      query,
      filterQueryName,
      FilterQuery.subindustries,
      filterCollection as IDataCollection,
      shouldResetCounts
    );
  }
  return getFilterDataForQueryHelper(query, filterQueryName, filterCollection as IDataCollection, shouldResetCounts);
}

/**
 * get all filter data based on query for a filter collection <=> a DataMap (DataMapping.tsx) property, and
 * update filter properties of both isActive and checkFilter in each filter, (updated in both returned filter data and in runtime DataMap)
 *
 *  a wrapper function to determine how to process DataMap (DataMapping) to get the actual filter collection
 *  and with the actual filter collection, call getFilterDataForQueryHelper function to get filter data
 *
 * @param query: url queries in browser url
 * @param DataMap:
 *  could be DataMap (DataMapping.tsx) or runtime dataMap,
 *  runtime dataMap same as DataMap (DataMapping.tsx),
 *  which is a collection of all filter collections, namely products, industries, categories, serviceTypes
 *  but where each filter in each filter collection is a valid filter which applies to at least one offer (app and consulting service),
 *  this is one of the differences from DataMap (DataMapping.tsx), other differences are that additional properties are added in runtime dataMap
 * @param shouldResetCounts:
 *  optionally to reset counts of app which applied to each filter,
 *  the purpose of optional is for accumulate counts for different offers (app, and consulting service) in sequence
 *
 * @return:
 *  return filter data for one filter collection
 *      filters: an array of all filters under one filter collection, where its two properties are modified
 *          isActive: determines if the filter should be active based on
 *              filter url query values: namely query[filterQueryName] <=> filter names under one filter collection joined by ';'
 *              mask: Bit-OR result of mask values for all active filters, indicated by filter url query values
 *          checkFilter: a shorcut boolean to notify performFilter function (filterModule) to ignore this filter
 *      ignore:
 *          same concept with checkFilter, a shortcut boolean to notify performFilter function (filterModule) to
 *          completely ignore perform filter against offers (apps and consulting sevices) with this filter data
 */
export function getFilterData(
  params: IURLParam,
  query: IURLQuery,
  DataMap: IDataMap,
  shouldResetCounts: boolean
): IFilterGroup[] {
  // get filter data by checking each filter collection in DataMap (DataMapping.tsx, here constantDataMap) against input paremeter DataMap,
  // which could be either runtime dataMap or constantDataMap
  return Object.keys(constantDataMap).map((filterCollectionName: string) => {
    const filterCollection: IDataCollection | ICategoryCollection = DataMap ? DataMap[`${filterCollectionName}`] : {};

    // this is one of possible properties in query, which is a filter query key,
    // namely product, industry, category, serviceType
    const filterCollectionQueryName: string = filterCollectionNameToFilterQuery[`${filterCollectionName}`];

    return getFilterDataForQuery(query, filterCollectionQueryName, filterCollection as IDataCollection, shouldResetCounts);
  });
}

/**
 * add match function in each filter under each filter collection <=> a DataMap (DataMapping.tsx) property
 *
 * @param DataMap:
 *  could be DataMap (DataMapping.tsx) or runtime dataMap,
 *  runtime dataMap same as DataMap (DataMapping.tsx),
 *  which is a collection of all filter collections, namely products, industries, categories, serviceTypes
 *  but where each filter in each filter collection is a valid filter which applies to at least one offer (app and consulting service),
 *  this is one of the differences from DataMap (DataMapping.tsx), other differences are that additional properties are added in runtime dataMap
 *
 * @return:
 *  return modified DataMap with updated match function in each filter
 */
export function updateMatchFunctions(DataMap: IDataMap): IDataMap {
  // add match function in each filter
  // by checking each filter collection in DataMap (DataMapping.tsx, here constantDataMap) against input paremeter DataMap,
  // which could be either runtime dataMap or constantDataMap
  Object.keys(constantDataMap)
    // update each filter in each filter collection
    .forEach((filterCollectionName: string) => {
      const filterCollection: IDataCollection | CategoriesPath = DataMap[`${filterCollectionName}`];

      if (filterCollection) {
        // if filter collection is 'categories', need to go one level deeper to get the actual filter
        if (filterCollectionName === Constants.DataMapCollectionKeys.categories) {
          const categoryFilterCollection: CategoriesPath = filterCollection as CategoriesPath;

          // access each sub collection to get its actual filter data
          Object.keys(categoryFilterCollection).forEach((subCategoryCollectionKey: string) => {
            // sub collection is the actual filter collection which contains filter in 'categories' filter collection
            const subCategoryFilterCollection: IDataCollection = categoryFilterCollection[
              `${subCategoryCollectionKey}`
            ] as IDataCollection;

            // access each filter in a sub collection
            Object.keys(subCategoryFilterCollection)
              // update each filter match function in a sub collection
              .forEach((filterKey: string) => {
                const category: IDataValues = subCategoryFilterCollection[`${filterKey}`];

                if (!isPureObject(category)) {
                  return;
                }
                const subCategoryKeys = category.SubCategoryDataMapping ? Object.keys(category.SubCategoryDataMapping) : [];

                const childMatchFns: ((app: IAppDataItemBasicData) => boolean)[] = [];

                for (const subCategoryKey of subCategoryKeys) {
                  const subCategory = category.SubCategoryDataMapping[`${subCategoryKey}`];

                  const matchFn = (app: IAppDataItemBasicData) => {
                    return app ? (app[subCategory.FilterGroup] & subCategory.FilterID) > 0 : false;
                  };

                  childMatchFns.push(matchFn);

                  subCategory[`${match}`] = matchFn;
                }

                category[`${match}`] = (app: IAppDataItemBasicData): boolean => {
                  if (!app) {
                    return false;
                  }

                  let primaryProductKey = '';

                  const productFilterCollection: IDataCollection = DataMap.products || {};

                  // find app primary product name
                  Object.keys(productFilterCollection)
                    // return immediately after primary product name found
                    .some((productFilterKey: string) => {
                      const productFilter = productFilterCollection[`${productFilterKey}`];
                      const isPrimaryProduct: boolean = app.primaryProduct
                        ? bitMasksMatch(app.primaryProduct, productFilter.FilterGroup, productFilter.FilterID)
                        : null;

                      if (isPrimaryProduct) {
                        primaryProductKey = productFilterKey;
                      }

                      return isPrimaryProduct;
                    });

                  if (!primaryProductKey) {
                    return false;
                  }

                  // const primaryProduct: IDataValues = productFilterCollection[primaryProductKey];
                  // TODO is matchCollection good solution?
                  // if (matchCollection && primaryProduct.categoryList !== subCategoryCollectionKey) {
                  //     return false;
                  // }

                  if (category.IsReference) {
                    const primaryFilter = categoryFilterCollection.default[`${filterKey as CategoriesInnerKeys}`] as IDataValues;
                    return primaryFilter.match(app);
                  }

                  const matchCategories = (app: IAppDataItemBasicData) => {
                    return app && (app[category.FilterGroup] & category.FilterID) > 0;
                  };

                  const matchSubCategories = (app: IAppDataItemBasicData) => {
                    for (let i = 0; i < childMatchFns.length; i++) {
                      if (childMatchFns[`${i}`](app)) {
                        return true;
                      }
                    }

                    return false;
                  };

                  // TODO: Why did we used ShortcutBitmask?
                  return childMatchFns.length > 0 ? matchSubCategories(app) : matchCategories(app);
                };
              });
          });
        } else if (
          filterCollectionName === Constants.DataMapCollectionKeys.industries ||
          (isOneTaxonomyEnabled() && filterCollectionName === Constants.DataMapCollectionKeys.cloudIndustries)
        ) {
          Object.keys(filterCollection)
            // update each filter with match function
            .forEach((filterKey: string) => {
              const filter: IDataValues = (filterCollection as IDataCollection)[`${filterKey}`];

              const subFilterKeys = filter.SubCategoryDataMapping ? Object.keys(filter.SubCategoryDataMapping) : [];

              const childMatchFns: ((app: IAppDataItemBasicData) => boolean)[] = [];

              for (const subFilterKey of subFilterKeys) {
                const subFilter = filter.SubCategoryDataMapping[`${subFilterKey}`];

                const matchFn = (app: IAppDataItemBasicData) => {
                  return app ? (app[subFilter.FilterGroup] & subFilter.FilterID) > 0 : false;
                };

                childMatchFns.push(matchFn);

                subFilter[`${match}`] = matchFn;
              }

              filter[`${match}`] = (app: IAppDataItemBasicData): boolean => {
                if (!app) {
                  return false;
                }

                const matchCategories = (app: IAppDataItemBasicData) => {
                  return app && (app[filter.FilterGroup] & filter.FilterID) > 0;
                };

                const matchSubCategories = (app: IAppDataItemBasicData) => {
                  for (let i = 0; i < childMatchFns.length; i++) {
                    if (childMatchFns[`${i}`](app)) {
                      return true;
                    }
                  }

                  return false;
                };

                // TODO: Why did we used ShortcutBitmask?
                return childMatchFns.length > 0 ? matchSubCategories(app) : matchCategories(app);
              };
            });
        } else if (filterCollectionName === Constants.DataMapCollectionKeys.products) {
          Object.keys(filterCollection)
            // update each filter with match function
            .forEach((filterKey: string) => {
              const filter: IDataValues = (filterCollection as IDataCollection)[`${filterKey}`];

              filter[`${match}`] = (app: any): boolean => {
                if (!app) {
                  return false;
                }
                return bitMasksContainSomeBitMask(getShortCutBitmask(filter), app[`${filterCollectionName}`]);
              };
            });
        }
        // filter collection other than 'categories', simply access each its property, which is a filter, and update match function
        else {
          Object.keys(filterCollection)
            // update each filter with match function
            .forEach((filterKey: string) => {
              const filter: IDataValues = (filterCollection as IDataCollection)[`${filterKey}`];

              filter[`${match}`] = (app: any): boolean => {
                if (!app) {
                  return false;
                }
                return (app[`${filterCollectionName}`] & filter.FilterID) > 0;
              };
            });
        }
      }
    });

  return DataMap;
}

// TODO Support L2 categories
export function getFilterLink(
  context: IBuildHrefContext,
  filter: IDataValues,
  entityType: Constants.EntityType,
  embedHost: string,
  allowShortcutFilter: boolean,
  isTreatmentNewFilterAMPEnabled: boolean,
  urlQuery: IURLQuery
) {
  let query = filter.FilterGroup;

  const isProduct = filter.UrlGroup === FilterQuery.product;
  const isCategory = filter.UrlGroup === FilterQuery.category;
  const isIndustry = filter.UrlGroup === FilterQuery.industry;
  const isSubFilter = (isCategory || isIndustry) && filter.IsChildFilter;

  if (filter.UrlGroup) {
    query = filter.UrlGroup;
  }
  if (isSubFilter) {
    if (isCategory) {
      query = FilterQuery.subcategories;
    }
    if (isIndustry) {
      query = FilterQuery.subindustries;
    }
  }

  // Use the ShortctUrlKey if it exists and is allowed - the home page sets that argument to true
  // because it wants to apply all the child filters instead of a parent filter
  let filterValue = allowShortcutFilter ? filter.ShortcutUrlKey : filter.UrlKey;

  const flattedEmbedHost = embedHost && embedHostUtils.shouldFlatProducts(embedHost);

  // For top level product or category filters, only allow one to be selected at a time, except specific embed host
  if ((isProduct && !filter.IsChildFilter && !flattedEmbedHost) || ((isCategory || isIndustry) && !isSubFilter)) {
    if (filter.isActive) {
      filterValue = null;
    }
  } else {
    // For all other filters, append or remove the specific value
    filterValue = (filter.isActive ? '!' : ';') + filterValue;
  }

  const route = getEntityRegistration(entityType).route;

  const newProps = {
    [query]: filterValue,
    page: '1',
  };

  // Don't preseve sub filters for top level filter
  if (!isSubFilter) {
    if (isCategory) {
      newProps[FilterQuery.subcategories] = null;
    }
    if (isIndustry) {
      newProps[FilterQuery.subindustries] = null;
    }
  }

  if (urlQuery && isProduct && !filter.IsChildFilter) {
    if (!filter.isActive) {
      // render link when the L1 product filter is not active
      // link is used when it's being checked next time
      const productMask: IProductValue = { [filter.FilterGroup]: filter.FilterID };
      const newQuery = removeUnmatchedCategories(urlQuery, filter.UrlKey, productMask);
      return context.buildHref(route, null, newQuery);
    } else {
      // render link when the L1 product filter is active
      // link is used when it's being unchecked next time
      const newQuery = removeUnmatchedCategories(urlQuery, null, null);
      return context.buildHref(route, null, newQuery);
    }
  }

  return context.buildHref(route, null, newProps);
}

export function getCountryRegionfilterLink(context: IBuildHrefContext, countryCode: string, regionCode: string) {
  const route = getEntityRegistration(Constants.EntityType.Service).route;

  const newProps = {
    country: countryCode,
    region: regionCode,
    page: '1',
  };
  const url = context.buildHref(route, null, newProps);
  urlPush(url);
}

export function getActiveFiltersByFilterGroup(activeFilters: IDataValues[]) {
  const filtersByFilterGroup: {
    [id: string]: IDataValues[];
  } = activeFilters.reduce((grouped, item) => {
    grouped[item.UrlGroup] = grouped[item.UrlGroup] || [];
    grouped[item.UrlGroup].push(item);
    return grouped;
  }, {});
  return filtersByFilterGroup;
}

// Checks to see if the active filter has a curated section with more than one swim lane
export function shouldShowCurationForFilter(
  activeFilter: IDataValues,
  curatedData: ICuratedSections<ICuratedSectionItem>
): boolean {
  const curatedSection =
    activeFilter.BackendKey && (curatedData?.[activeFilter.BackendKey] || curatedData?.[activeFilter.BackendKey.toLowerCase()]);
  return !!curatedSection && curatedSection.length > 1;
}

export function shouldShowCurationForProductFilters(
  activeProductsFilters: IDataValues[],
  curatedData: ICuratedSections<ICuratedSectionItem>
): boolean {
  const L3ProdFilters = activeProductsFilters.filter((filter: IDataValues) => !filter.ShortcutFilters);
  const activeProdFilters = activeProductsFilters.filter((filter: IDataValues) => !!filter.ShortcutFilters);

  if (L3ProdFilters.length === 1) {
    return shouldShowCurationForFilter(L3ProdFilters[0], curatedData);
  }

  if (L3ProdFilters.length === 0 && activeProdFilters.length === 1) {
    return shouldShowCurationForFilter(activeProdFilters[0], curatedData);
  }

  return false;
}

/**
 * check should provide curated data, based on active filters
 *
 * @param activeFilters: active filters, which are chosen left pane filters, and also shows in gallery header tags
 * @param curatedData: response from the featured api
 * @return
 *  return true, if below conditions are met
 *      1) active filters consists none or only of parent product filters, which have children filters
 *       2) only one industry or category filter is selected and it has curated data.
 *  return false, otherwise
 */
export function shouldShowCuratedDataForActiveFilters(
  activeFilters: IDataValues[],
  curatedData: ICuratedSections<ICuratedSectionItem>
) {
  // no filters selected show curation
  if (activeFilters.length === 0) {
    return false;
  }
  const filtersByGroup = getActiveFiltersByFilterGroup(activeFilters);

  // filters from more than one group is selected dont show curated view
  if (Object.keys(filtersByGroup).length > 1) {
    return false;
  }

  // only product filters selected show curation if the product has it
  if (
    filtersByGroup[FilterQuery.product] &&
    filtersByGroup[FilterQuery.product].length > 0 &&
    shouldShowCurationForProductFilters(filtersByGroup[FilterQuery.product], curatedData)
  ) {
    return true;
  }

  // only category filters selected show curation if the category has it
  if (
    filtersByGroup[FilterQuery.category] &&
    filtersByGroup[FilterQuery.category].length === 1 &&
    shouldShowCurationForFilter(filtersByGroup[FilterQuery.category][0], curatedData)
  ) {
    return true;
  }

  // only industry filters selected show curation if the industry has it
  if (
    filtersByGroup[FilterQuery.industry] &&
    filtersByGroup[FilterQuery.industry].length === 1 &&
    shouldShowCurationForFilter(filtersByGroup[FilterQuery.industry][0], curatedData)
  ) {
    return true;
  }

  return false;
}

export function getFilterType(filter: IDataValues) {
  return filter.FilterGroup ? filter.FilterGroup : '';
}

export function shouldShowFilterForView(currentView: string, supportedViews: string[]) {
  return (
    supportedViews &&
    Object.values(supportedViews).some(
      (supportedView) => supportedView === Constants.everywhereFilterView || supportedView === currentView
    )
  );
}

export function shouldShowSubFilter(filter: IDataValues) {
  if (filter.count) {
    return true;
  }
  return false;
}

export function sort(textA: string, textB: string): number {
  textA = textA.toUpperCase();
  textB = textB.toUpperCase();

  if (textA < textB) {
    return -1;
  } else {
    return 1;
  }
}
export function sortFilters(filterA: IDataValues, filterB: IDataValues): number {
  if (filterA && filterB && filterA.Title && filterB.Title) {
    return sort(filterA.Title, filterB.Title);
  }
  return 0;
}

export function sortFiltersKeys(filterKeyA: string, filterKeyB: string, filters: IDataCollection): number {
  if (filters) {
    return sortFilters(filters[`${filterKeyA}`], filters[`${filterKeyB}`]);
  }
  return 0;
}

export function onClickTrackHitCountsForTreatmentNewFilter(filterUrlKey: string) {
  const eventDetails = {
    urlKey: filterUrlKey || '',
  };

  const payload: ITelemetryData = {
    page: getWindow().location.href,
    action: Constants.Telemetry.Action.Click,
    actionModifier: Constants.Telemetry.Action.FilterClick,
    details: JSON.stringify(eventDetails),
  };

  SpzaInstrumentService.getProvider().probe<ITelemetryData>('logTelemetryInfo', payload);
  logger.info(payload.details, {
    action: payload.action,
    actionModifier: payload.actionModifier,
  });
}

export const isFilterChosen = (
  currentView: string,
  appsActiveFilters: IDataValues[],
  servicesActiveFilters: IDataValues[],
  cloudsIndustryActiveFilters: IDataValues[]
): boolean => {
  if (currentView === Constants.Views.appGallery && appsActiveFilters.length > 0) {
    return true;
  }
  if (currentView === Constants.Views.serviceGallery && servicesActiveFilters.length > 0) {
    return true;
  }
  if (currentView === Constants.Views.cloudsIndustryGallery && cloudsIndustryActiveFilters.length > 0) {
    return true;
  }
  return false;
};
export const getResetFiltersHref = (context: IBuildHrefContext, entityType: Constants.EntityType): string => {
  const route = getEntityRegistration(entityType).route;

  return context.buildHref(route, null, {
    [Constants.FilterQuery.page]: '1',
    [Constants.FilterQuery.sortBy]: null,
    [Constants.FilterQuery.filters]: null,
    [Constants.FilterQuery.category]: null,
    [Constants.FilterQuery.industry]: null,
    [Constants.FilterQuery.subcategories]: null,
    [Constants.FilterQuery.subindustries]: null,
    [Constants.FilterQuery.product]: null,
    [Constants.FilterQuery.serviceType]: null,
  });
};

export const getResetFiltersAndSearchHref = (context: IBuildHrefContext, entityType: Constants.EntityType): string => {
  const route = getEntityRegistration(entityType).route;

  return context.buildHref(route, null, {
    [Constants.FilterQuery.page]: '1',
    [Constants.FilterQuery.sortBy]: null,
    [Constants.FilterQuery.filters]: null,
    [Constants.FilterQuery.category]: null,
    [Constants.FilterQuery.industry]: null,
    [Constants.FilterQuery.subcategories]: null,
    [Constants.FilterQuery.subindustries]: null,
    [Constants.FilterQuery.product]: null,
    [Constants.FilterQuery.serviceType]: null,
    [Constants.FilterQuery.search]: null,
  });
};

export function increaseCountOfReferenceCategoryInDefaultCategoryList(filterData: any, filter: any) {
  for (let i = 0; i < filterData.length; i++) {
    const category = filterData[`${i}`];
    if (category.IsReference) {
      continue;
    }
    if (category.FilterID === filter.FilterID) {
      category.count++;
      return;
    }
  }
}

export function getTopCuratedLane(filterKey: string, curatedData: ICuratedSections<ICuratedSectionItem>): ICuratedSectionItem[] {
  if (filterKey && curatedData) {
    const curatedSection = curatedData[`${filterKey}`] || curatedData[filterKey.toLowerCase()];

    if (curatedSection && curatedSection.length === 1) {
      return curatedSection[0].items;
    }
  }

  return [];
}

export function getPopularItems(allItems: IDataItem[], featuredItems: IDataItem[]) {
  return allItems
    .slice()
    .filter((item) => featuredItems.every((featuredItem) => featuredItem.entityId !== item.entityId))
    .sort((a, b) => b.popularity - a.popularity)
    .slice(0, 10);
}

export function getFeaturedItemsFilter(activeFilters: IDataValues[]): IDataValues {
  const filters = activeFilters?.filter((filter) => filter?.BackendKey);

  if (filters.length !== 1) {
    return null;
  }

  const filter = filters[0];

  if (filter.FilterGroup !== Constants.filterTileTypes.industry && filter.FilterGroup !== Constants.filterTileTypes.category) {
    return null;
  }

  return filter;
}

export function getFeaturedItemsByFilter(
  activeFilters: IDataValues[],
  curatedData: ICuratedSections<ICuratedSectionItem>,
  itemsHashMap: IHashMap,
  allItems: IDataItem[]
): IDataItem[] {
  const filter = getFeaturedItemsFilter(activeFilters);

  if (!filter) {
    return [];
  }

  const topCuratedLane = getTopCuratedLane(filter.BackendKey, curatedData);

  const itemsIndexes = topCuratedLane
    .map((curatedItem) => itemsHashMap[curatedItem?.id?.toLowerCase()])
    .filter((appIndex) => appIndex >= 0);

  return itemsIndexes.map((appIndex) => allItems[`${appIndex}`]);
}

export function windowScrollToTop(): void {
  const window = getWindow();
  if (window) {
    getWindow().scrollTo(0, 0);
  }
}

export function getInternetOfThingsLink(category: string): ILinksList[] {
  if (category === Constants.InternetOfThings.urlKey) {
    return [
      {
        className: ['secondary_link', 'secondary_link_left'],
        href: 'https://aka.ms/devicecatalog',
        accessibilityEnabled: true,
        locKey: 'IoT_Devices_Catalog',
      },
    ];
  }

  return [];
}

export const serviceOffersSupportedCountries: (SupportedCountry | AllCountry)[] = [
  {
    countryCode: Constants.allCountriesCode,
    code: Constants.allCountriesCode,
    name: 'All Countries',
  },
  ...supportedCountries,
];

export const serviceOffersSupportedCountriesMap: {
  [countryCode: string]: SupportedCountry | AllCountry;
} = serviceOffersSupportedCountries.reduce(
  (acc, curr) => ({
    ...acc,
    [curr.countryCode]: curr,
  }),
  {}
);

export function findServiceSupportedCountry(countryCode: string): SupportedCountry | AllCountry {
  const foundCountries = serviceOffersSupportedCountries.filter((item: any) => {
    return item.countryCode === countryCode;
  });
  if (!foundCountries || foundCountries.length === 0) {
    return undefined;
  }
  return foundCountries[0];
}

export function isPricingFilter() {
  return false;
}
