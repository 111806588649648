import React, { useState } from 'react';
import {
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  Stack,
  Text,
  FontIcon,
  DefaultButton,
  ButtonType,
  TextField,
  Checkbox,
  mergeStyleSets,
} from '@fluentui/react';

import type {
  IDialogContentStyles,
  IDialogContentProps,
  IModalProps,
  IStackTokens,
  ITextFieldStyles,
  IButtonStyles,
} from '@fluentui/react';

import { NeutralColors, SharedColors } from '@fluentui/theme';
import type { ICSSRule, ICSSPixelUnitRule } from '@fluentui/merge-styles';
import { useBoolean } from '@fluentui/react-hooks';
import { ExternalLink } from '@shared/components/externalLink';
import { INpsSurveyAnswer, ratingValues } from '@shared/Models';
import { ILocContext, ILocParamsContext } from '@shared/interfaces/context';
import { useTelemetry } from '@shared/hooks/useTelemetry';
import { Constants } from '@shared/utils/constants';

interface INpsSurveyModalProps {
  hidden: boolean;
  email: string;
  onDismiss: () => void;
  onSubmit: (answer: INpsSurveyAnswer) => void;
  context: ILocContext & ILocParamsContext;
}

interface INpsRatingsProps {
  selectedRating: number;
  onChange: (rating: number) => void;
  context: ILocContext & ILocParamsContext;
}

interface INpsDescriptionProps {
  value: string;
  onChange: (value: string) => void;
  context: ILocContext & ILocParamsContext;
}

interface INpsTermsAndPrivacyProps {
  context: ILocContext & ILocParamsContext;
}

const termsUrl = 'https://azure.microsoft.com/support/legal/marketplace-terms';
const privacyUrl = 'https://privacy.microsoft.com/en-us/privacystatement';

const modalMinWidth: ICSSRule | ICSSPixelUnitRule = '560px';
const modalProps: IModalProps = { isDarkOverlay: false, isBlocking: true };
const stackTokens: IStackTokens = { childrenGap: 16 };

const classNames = mergeStyleSets({
  externalLink: [{ display: 'inline-block', color: `${SharedColors.cyanBlue10} !important` }],
  defaultText: [{ color: NeutralColors.gray130 }],
  divider: [{ fontSize: 16, color: NeutralColors.gray160, lineHeight: 20 }],
});

const dialogContentStyles: Partial<IDialogContentStyles> = {
  header: { color: NeutralColors.gray160 },
  subText: { color: NeutralColors.gray160, fontWeight: 600 },
};

const dialogTextFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { height: 96 } };

const buttonStyles: Partial<IButtonStyles> = {
  root: { width: 40, height: 48, minWidth: 0, border: '1px solid #E1E3E5' },
  rootChecked: { color: NeutralColors.white, backgroundColor: SharedColors.cyanBlue10 },
  rootCheckedHovered: { color: NeutralColors.white, backgroundColor: SharedColors.cyanBlue10 },
};

const dialogContentProps = (context: ILocContext & ILocParamsContext): IDialogContentProps => {
  return {
    type: DialogType.close,
    title: context.loc('Nps_SurveyModal_Title', 'We’d love your feedback'),
    subText: context.loc('Nps_SurveyModal_SubHeader_Text', 'How likely are you to recommend AppSource to a friend or colleague?'),
    closeButtonAriaLabel: context.loc('Nps_SurveyModal_CloseButton_AriaText', 'Close'),
    styles: dialogContentStyles,
  };
};

const checkboxLabelDefaultText = (email: string) => `It’s OK to contact me at ${email} about my feedback`;

const additionalInfoDefaultText =
  'For specific concerns or issues regarding the use of an application, please contact the publisher via the support link on the application product page.';

const NpsTermsAndPrivacy: React.FunctionComponent<INpsTermsAndPrivacyProps> = ({ context }: INpsTermsAndPrivacyProps) => {
  return (
    <Stack horizontal>
      <ExternalLink className={classNames.externalLink} accessibilityEnabled href={termsUrl} target="_blank" rel="noreferrer">
        {context.loc('Nps_SurveyModal_Terms_Link_Text', 'Terms')}
      </ExternalLink>
      <FontIcon iconName="Separator" className={classNames.divider} />
      <ExternalLink className={classNames.externalLink} accessibilityEnabled href={privacyUrl} target="_blank" rel="noreferrer">
        {context.loc('Nps_SurveyModal_PrivacyStatement_Link_Text', 'Privacy statement')}
      </ExternalLink>
    </Stack>
  );
};

const NpsDescription: React.FunctionComponent<INpsDescriptionProps> = ({ value, onChange, context }: INpsDescriptionProps) => {
  return (
    <TextField
      label={context.loc('Nps_SurveyModal_Description_Label_Text', 'What can we do to improve your experience?')}
      multiline
      resizable={false}
      placeholder={context.loc('Nps_SurveyModal_Description_Placeholder_Text', 'Tell us what you think of AppSource.')}
      value={value}
      onChange={(_, newValue) => onChange(newValue || '')}
      styles={dialogTextFieldStyles}
    />
  );
};

const NpsRatings: React.FunctionComponent<INpsRatingsProps> = ({ selectedRating, onChange, context }: INpsRatingsProps) => {
  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Stack horizontal horizontalAlign="space-between">
        <Text variant="smallPlus" className={classNames.defaultText}>
          {context.loc('Nps_SurveyModal_Ratings_Unsatisfied_Text', 'Not at all likely')}
        </Text>
        <Text variant="smallPlus" className={classNames.defaultText}>
          {context.loc('Nps_SurveyModal_Ratings_Satisfied_Text', 'Extremely likely')}
        </Text>
      </Stack>
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        {ratingValues.map((ratingValue) => (
          <DefaultButton
            toggle
            buttonType={selectedRating === ratingValue ? ButtonType.primary : ButtonType.default}
            checked={selectedRating === ratingValue}
            styles={buttonStyles}
            key={ratingValue}
            text={`${ratingValue}`}
            onClick={() => onChange(ratingValue)}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export const NpsSurveyModal: React.FunctionComponent<INpsSurveyModalProps> = ({
  hidden,
  onDismiss,
  onSubmit,
  context,
  email = '',
}: INpsSurveyModalProps) => {
  const [contactCheckboxChecked, { toggle: toggleContactCheckbox }] = useBoolean(false);
  const [selectedRating, setSelectedRating] = useState(null);
  const [description, setDescription] = useState('');
  const [{ pageAction }] = useTelemetry();

  const handleSubmit = () => {
    onSubmit({
      description,
      email: contactCheckboxChecked && email ? email : '',
      rating: selectedRating,
    });

    pageAction(null, {
      behavior: 142,
      actionType: 'CL',
      contentTags: {
        id: Constants.Telemetry.Id.Nps,
        name: Constants.Telemetry.FeedbackName.Nps,
        fbnm: Constants.Telemetry.FeedbackName.Nps,
        vtbm: description,
        rate: `${selectedRating}`,
        sat: '0-10',
      },
    });
  };

  return (
    <Dialog
      hidden={hidden}
      dialogContentProps={dialogContentProps(context)}
      minWidth={modalMinWidth}
      modalProps={modalProps}
      onDismiss={onDismiss}
    >
      <Stack tokens={stackTokens}>
        <NpsRatings selectedRating={selectedRating} onChange={setSelectedRating} context={context} />
        <NpsDescription value={description} onChange={setDescription} context={context} />
        <Text>{context.loc('Nps_SurveyModal_AdditionalInfo_Text', additionalInfoDefaultText)}</Text>
        {email && (
          <Checkbox
            label={context.locParams('Nps_SurveyModal_CheckboxLabel_Text', [email], checkboxLabelDefaultText(email))}
            checked={contactCheckboxChecked}
            onChange={toggleContactCheckbox}
          />
        )}
        <NpsTermsAndPrivacy context={context} />
      </Stack>
      <DialogFooter>
        <PrimaryButton
          text={context.loc('Nps_SurveyModal_SubmitButton_Text', 'Submit')}
          onClick={handleSubmit}
          disabled={selectedRating === null}
        />
      </DialogFooter>
    </Dialog>
  );
};
