import * as React from 'react';
import * as PropTypes from 'prop-types';
import { ILocContext, IBuildHrefContext, ICommonContext } from '../interfaces/context';
import { InternalLink } from './internalLink';
import { Constants } from '../utils/constants';
import SpzaComponent from './spzaComponent';
import { IFilterItemProps, handleFilterToggle, getFilterText } from '../utils/filterItemUtils';

export default class FilterPillItem extends SpzaComponent<IFilterItemProps, any> {
  context: ILocContext & IBuildHrefContext & ICommonContext;

  getClassName() {
    let className: string = this.props.className || 'spza_filterItem';
    className += this.props.isActive ? ' spza_activeFilter' : '';
    return className;
  }

  renderImpl() {
    const filter = this.props.filter;
    const filterText = getFilterText(this.context, filter, this.props.showExpandedIcon, this.props.href);
    const className: string = this.getClassName();
    const role: string = this.props.role || 'checkbox';
    const filterId = (filter as any).FilterID || null;
    const isActive = !!this.props.isActive;

    const clickHandler =
      this.props.onClickHandle ||
      handleFilterToggle(
        this.props.category,
        this.props.queryValue,
        isActive,
        this.props.href,
        Constants.Telemetry.ActionModifier.FilterPane,
        this.props.onClick
      );

    const otherProps = { ...this.props.otherProps };

    const selectedAriaAttribute = role === 'checkbox' ? 'aria-checked' : 'aria-selected';
    otherProps[`${selectedAriaAttribute}`] = isActive;

    const filterClassName: string = 'filterText ' + (this.props.isEmbed ? 'embedFilterButton' : '') + this.props.filterTextClass;
    const isActiveClass = isActive ? 'active ' : '';

    return (
      <div className={isActiveClass + className + ' filterItem'}>
        <label className="filterItemName">
          <InternalLink
            accEnabled={this.props.accEnabled}
            href={this.props.href}
            onClick={clickHandler}
            key={filterId}
            role={role}
            style={{ float: 'left' }}
            {...otherProps}
          >
            {this.props.iconClassName && (
              <div className="productIcon">
                <div className={this.props.iconClassName}></div>
              </div>
            )}
            <span className={filterClassName} title={filterText}>
              {filterText}
            </span>
          </InternalLink>
        </label>
      </div>
    );
  }
}

(FilterPillItem as any).contextTypes = {
  loc: PropTypes.func,
  buildHref: PropTypes.func,
  renderErrorModal: PropTypes.func,
};
