import { mergeStyleSets } from '@fluentui/react';

export const openInNewWindowButtonStyles = mergeStyleSets({
  openInNewWindowButton: {
    '& .ms-Button-flexContainer': {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
  },
});
